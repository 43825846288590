import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StockRoutingModule } from './stock-routing.module';
import { StockComponent } from './stock/stock.component';
import { StockSortieComponent } from './stock-sortie/stock-sortie.component';
import { StockEntreeComponent } from './stock-entree/stock-entree.component';
import { StockByDateComponent } from './stock-by-date/stock-by-date.component';
import { DetailsStockComponent } from './details-stock/details-stock.component';
import { UpdateStockComponent } from './update-stock/update-stock.component';
import { ArmoiresEnFabricationComponent } from './armoires-en-fabrication/armoires-en-fabrication.component';
import { NavbarModule } from '../shared/navbar/navbar.module';
import { DecimalFormattModule } from '../shared/decimal-formatt/decimal-formatt.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ControleMessagesModule } from '../shared/controle-messages/controle-messages.module';
import { ControleMessagesRoutingModule } from '../shared/controle-messages/controle-messages-routing.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxLoadingModule } from 'ngx-loading';
import { Ng5SliderModule } from 'ng5-slider';
import { NguiAutoCompleteModule } from '@ngui/auto-complete';
import { SearchStockPipe } from 'src/app/search/filterstock.pipe';
import { StockGuard } from 'src/app/guards/stock.guard';


@NgModule({
  declarations: [StockComponent, StockSortieComponent,SearchStockPipe, StockEntreeComponent, StockByDateComponent, DetailsStockComponent, UpdateStockComponent, ArmoiresEnFabricationComponent],
  imports: [
    CommonModule,
    StockRoutingModule,
    NavbarModule,
    DecimalFormattModule,
    Ng5SliderModule,
    FormsModule, ReactiveFormsModule,
    ControleMessagesModule,
    ControleMessagesRoutingModule,
    NgxPaginationModule,
    NgbModule,
    NguiAutoCompleteModule,
    InfiniteScrollModule,
    NgxLoadingModule,
  ],
  providers:[StockGuard]
})
export class StockModule { }
