<app-navbar></app-navbar>
<br>
<div id="content-nav-page" class="row" *ngIf="fournisseur && utilisateur">
    <div class=" col-3 titre-menu">
      <a routerLink="/fournisseur" class=" icon-f-left"></a>
      <b class="title-haut">{{fournisseur.denomination}}<img class="ml-2" src="assets/images/show-a.png"></b>
    </div>
    <div class="col-9 sous-menu">
      <b class="sous-menu-active1" *ngIf="utilisateur.role === 'Super admin' || utilisateur.role === 'Administration'|| utilisateur.role === 'Admin' || utilisateur.role === 'Utilisateur-fournisseur'" [routerLink]="['/fournisseur']">FOURNISSEUR</b>
      
    </div>
  </div>
  <div class="row" *ngIf="fournisseur">
  
    <div class="col-xl-2 col-lg-3  col-md-3 col-sm-4 left-content-scrolbar ">
  
      <div class="col col-content ">
        <button class="tablink2 btn-liste" id="defaultOpen2" (click)="modifPart1()">
          INFORMATIONS FOURNISSEUR</button>
      </div>
  
      <div class="col col-content">
        <button class="tablink2 btn-liste " (click)="modifPart2()"> INFORMATIONS
          COMPLÉMENTAIRES </button>
      </div>
  
      <div class="col col-content" *ngIf="utilisateur.mail != 'laurence.amanou@krendel.fr'">
        <button class="tablink2 btn-liste " (click)="modifPart3()"> ÉTABLISSEMENTS
        </button>
      </div>
  
      <div class="col col-content" *ngIf="roleAdministration">
        <button class="tablink2 btn-liste " (click)="modifPart4()"> COORDONNÉES BANCAIRES
        </button>
      </div>
      <div class="col col-content" *ngIf="utilisateur.mail != 'laurence.amanou@krendel.fr'">
        <button class="tablink2 btn-liste " (click)="modifPart5()"> CATALOGUES
        </button>
      </div>
      <div class="col col-content" >
        <button class="tablink2 btn-liste " (click)="modifPart6()"> COMMANDES
        </button>
      </div>
      <div class="col col-content" >
        <button class="tablink2 btn-liste " (click)="modifPart7()"> FACTURES
        </button>
      </div>
    </div>
  
    <div class="col-xl-10 col-lg-9  col-md-9 col-sm-8 right-content-scrolbar">
      <div class="card p-3">
        <div *ngIf="showPart()==1" class="col-content-affiche tabcontent2" id="information" style="padding-top: 2%">
  
          <div>
            <div class="card-body">
  
              <div class="row">
                <div class="col-4">
                  <div class="title">
                    <h4>Identifiant unique</h4>
                    <span class="font-weight-bold">{{fournisseur.siren[0]}}{{fournisseur.siren[1]}}{{fournisseur.siren[2]}}{{fournisseur.siren[3]}}{{fournisseur.siren[4]}}{{fournisseur.siren[5]}}{{fournisseur.siren[6]}}{{fournisseur.siren[7]}}{{fournisseur.siren[8]}}
                      {{fournisseur.siren[9]}}{{fournisseur.siren[10]}}{{fournisseur.siren[11]}}{{fournisseur.siren[12]}}{{fournisseur.siren[13]}}
                    </span>
                  </div>
  
                  <br />
                  <div class="title ">
                    <h4>Acronyme</h4>
                    <span class="font-weight-bold"> {{fournisseur.acronyme}}</span>
                  </div>
                  <br />
  
                  <div class="title">
                    <h4>Enseigne</h4>
                    <span class="font-weight-bold"> {{fournisseur.enseigne}}</span>
                  </div>
                  <br />
                  <div class="title">
                    <h4>Code activité</h4>
                    <span class="font-weight-bold">{{fournisseur.indicatif}}</span>
  
                  </div>
                  <br />
                  <div class="title">
                    <h4>Dénomination</h4>
                    <span class="font-weight-bold">{{fournisseur.denomination}}</span>
  
                  </div>
                </div>
                <div class="col-4 text-xl-center">
                  <div class="text-center">
                    <img src="{{fournisseur.linkLogo}}" style="width: 50%;padding-top: 20%">
                  </div>
                </div>
                <div class="col-4">
                  <div class="title">
                    <h4>Type</h4>
                    <span class="font-weight-bold"> {{fournisseur.type}}</span>
                  </div>
                  <br />
  
                  <div class="title">
                    <h4>Nom commercial</h4>
                    <span class="font-weight-bold">{{fournisseur.nomc}} </span>
                  </div>
                  <br />
  
                  <div class="title">
                    <h4>Statut</h4>
                    <span class="font-weight-bold"> {{fournisseur.statut}}</span>
                  </div>
                  <br />
  
                  <div class="title" *ngIf="fournisseur.statut === 'Radiée'">
                    <h4>Date de radiation</h4>
                    <span class="font-weight-bold">{{fournisseur.dateRadiation | date : 'dd/MM/yyyy' }}</span>
                  </div>
  
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <ng-template #customLoadingTemplate></ng-template>
        <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading>
        <div *ngIf="showPart()==2" class="col-content-affiche tabcontent2" id="information_compl" style="padding-top: 2%">
  
          <div>
            <div class="card-body">
  
              <div class="row">
                <div class="col-4">
                  <div class="title">
                    <h4>Email</h4>
                    <span class="font-weight-bold">{{fournisseur.mail}} </span>
                  </div>
  
                  <br />
                  <div class="title ">
                    <h4>Téléphone</h4>
                    <span class="font-weight-bold">{{fournisseur.tel}} </span>
                  </div>
                  <br />
  
                  <div class="title">
                    <h4>Pays</h4>
                    <span class="font-weight-bold">{{fournisseur.adresse.pays}}</span>
                  </div>
                  <br />
                  <div class="title">
                    <h4>Code postal</h4>
                    <span class="font-weight-bold">{{fournisseur.adresse.codePostal}}</span>
  
                  </div>
                </div>
                <div class="col-4">
                  <div class="title">
                    <h4>Rue</h4>
                    <span class="font-weight-bold">{{fournisseur.adresse.rue}} </span>
                  </div>
  
                  <br />
                  <div class="title ">
                    <h4>Numéro</h4>
                    <span class="font-weight-bold">{{fournisseur.adresse.n}} </span>
                  </div>
                  <br />
  
                  <div class="title">
                    <h4>Ville</h4>
                    <span class="font-weight-bold">{{fournisseur.adresse.ville}}</span>
                  </div>
                  <br />
                </div>
  
                <div class="col-4">
                  <div class="title">
                    <h4>Facebook</h4>
                    <span class="font-weight-bold"><a href="{{fournisseur.adresseWeb.facebook}}" target="_blank">Lien</a></span>
                  </div>
                  <br />
  
                  <div class="title">
                    <h4>Linkedin</h4>
                    <span class="font-weight-bold"><a href="{{fournisseur.adresseWeb.linkedin}}" target="_blank">Lien</a></span>
                  </div>
                  <br />
  
                  <div class="title">
                    <h4>RNE</h4>
                    <span class="font-weight-bold"><a href="{{fournisseur.adresseWeb.infogreffe}}" target="_blank">Lien</a></span>
                  </div>
  
                  <br />
                  <div class="title">
                    <h4>Site web</h4>
                    <span class="font-weight-bold"><a href="{{fournisseur.adresseWeb.siteWeb}}" target="_blank">Lien</a> </span>
                  </div>
  
                </div>
              </div>
              <br><br><br>
            </div>
          </div>
        </div>
  
        <div *ngIf="showPart()==3" class="col-content-affiche tabcontent2" id="etablissement" style="padding-top: 2%">
  
          <div>
            <div class="title">
              <h4>Multi établissement</h4>
              <span class="font-weight-bold"> {{fournisseur.multiEtablissement}} </span>
            </div>
            <div class="row ligne-table2  d-flex justify-content-center">
              <table class="table-responsive">
                <tr >
                  <td class="col-3 p-l-0">
                    Nom
                  </td>
                  <td class="col-3 p-l-0">
                    Type d'établissement
                  </td>
                  <td class="col-3 p-l-0">
                    Email
                  </td>
                  <td class="col-3 p-l-0">
                    Téléphone
                  </td>
                  <td class="col-3 p-l-0">
                    Statut
                  </td>
                  <td class="col-3 p-l-0">
                    Date de radiation
                  </td>
                </tr>
                <tr *ngFor="let etablissement of fournisseur.etablissements| paginate: { itemsPerPage: 4, currentPage: p }">
                  <td *ngIf="(etablissement.statutEtab != 'Radiée' && utilisateur && utilisateur.role != 'Super admin') || ( utilisateur && utilisateur.role === 'Super admin')">{{etablissement.nom}}</td>
                  <td *ngIf="(etablissement.statutEtab != 'Radiée' && utilisateur && utilisateur.role != 'Super admin') || ( utilisateur && utilisateur.role === 'Super admin')">{{etablissement.type}}</td>
                  <td *ngIf="(etablissement.statutEtab != 'Radiée' && utilisateur && utilisateur.role != 'Super admin') || ( utilisateur && utilisateur.role === 'Super admin')">{{etablissement.mail}}</td>
                  <td *ngIf="(etablissement.statutEtab != 'Radiée' && utilisateur && utilisateur.role != 'Super admin') || ( utilisateur && utilisateur.role === 'Super admin')">{{etablissement.tel}}</td>
                  <td *ngIf="(etablissement.statutEtab != 'Radiée' && utilisateur && utilisateur.role != 'Super admin') || ( utilisateur && utilisateur.role === 'Super admin')">{{etablissement.statutEtab}}</td>
                  <td *ngIf="(etablissement.statutEtab != 'Radiée' && utilisateur && utilisateur.role != 'Super admin') || ( utilisateur && utilisateur.role === 'Super admin')"><span
                      *ngIf="etablissement.statutEtab === 'Radiée'">{{etablissement.dateRadiationEtab | date : 'dd/MM/yyyy' }}</span></td>
                </tr>
  
              </table>
            </div>
  
          </div>
        </div>
  
  
        <div *ngIf="showPart()==4 && roleAdministration" class="col-content-affiche tabcontent2" id="coordonnees_bancaires" style="padding-top: 2%">
  
          <div>
            <div class="card-body">
  
              <div class="row">
                <div class="col-6">
                  <div class="title">
                    <h4>Nom</h4>
                    <span class="font-weight-bold">{{fournisseur.informationbancaire.nom}} </span>
                  </div>
  
                  <br />
                  <div class="title ">
                    <h4>Swift</h4>
                    <span class="font-weight-bold">{{fournisseur.informationbancaire.swift}} </span>
                  </div>
                  <br />
  
                  <div class="title">
                    <h4>Bic</h4>
                    <span class="font-weight-bold">{{fournisseur.informationbancaire.bic}}</span>
                  </div>
  
                </div>
  
                <div class="col-6">
  
                  <div class="title">
                    <h4>Iban</h4>
                    <span class="font-weight-bold">{{fournisseur.informationbancaire.iban}}</span>
                  </div>
  
                  <br />
                  <div class="title">
                    <h4>Rib</h4>
                    <span class="font-weight-bold">{{fournisseur.informationbancaire.rib}} </span>
                  </div>
  
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div *ngIf="showPart()==5" class="col-content-affiche tabcontent2" id="static">
          <h3 align="center"> Catalogue {{fournisseur.denomination}}</h3>
          <div>
            <div class="card-body">
              <div>
                <div class="row ligne-table2  d-flex justify-content-center table-padding">
                  <table class="table-responsive" style="text-align: center;">
                    <tr>
                      <th>N°</th>
                      <th>Année</th>
                      <th>Date de début</th>
                      <th>Date de fin</th>
                      <th>Total des articles</th>
                    </tr>
                    <tr *ngFor="let data of years| paginate: { itemsPerPage: 10, currentPage: p } ;let i = index" (click)="goToCatalogue(data)">
                      <td style="text-align: left !important;">
                        {{i+1}}
                      </td>
                      <td style="text-align: left !important;">
                        {{data.year}}
                      </td>
                      <td style="text-align: left !important;">
                        {{data.datec | date : 'dd/MM/yyyy' }}
                      </td>
                      <td style="text-align: left !important;">
                        {{data.dateFin | date : 'dd/MM/yyyy' }}
                      </td>
                      <td style="text-align: right !important;">
                        {{data.refCount | number: '1.2-2'| replace:',':' ' | replace:'.':',' | replace:',00':' '}}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td><a (click)="showAddCatalogueFunction()"  title="Ajouter"><img src="assets/images/add-cmd.png"></a>
                      </td>
                    </tr>
                  </table>
                  <pagination-controls previousLabel="Précédent" nextLabel="Suivant" (pageChange)="p = $event" #api></pagination-controls>
                </div>
              </div>
              <br><br>
              <div id="sevices-item-form" class="row" *ngIf="showAddCatalogue">
                <div class="title" style="border:none">
                  <h4 style="position: relative;left: 17%;">Créer un catalogue</h4>
                </div>
                <br>
                <form [formGroup]="form" class="form-horizontal" role="form">
                  <fieldset>
                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-sm-10">
                        <div class="title" style="border:none">
                          <input type="date" class="font-weight-bold" [(ngModel)]="dateDebut" formControlName="datec" id="datec" name="datec" style="display: initial;">
                          <control-messages [control]="form.controls.datec"></control-messages>
                        </div>
                        <br>
                        <div class="title" style="border:none">
                          <input type="date" class="font-weight-bold" [min]="getDateDebut()" formControlName="dateFin" id="dateFin" name="dateFin" style="display: initial;">
                          <control-messages [control]="form.controls.dateFin"></control-messages>
                          <p *ngIf="errorDate" style="color:red">Vérifie les dates !</p>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-sm-10">
                        <div class="title" style="border:none">
                          <label for="file"></label>
                          <input class="input-btn-file " (change)="onFileChange($event)" formControlName="file" type="file" id="file" accept=".xlsx, .xls, .csv" [(ngModel)]="fileToChoose" placeholder="Logo" style="position: relative;top: -20px;">
                          <control-messages [control]="form.controls.file"></control-messages>
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-md-12 col-sm-12">
                      <p *ngIf="addedLength > 0" style="font-size: 15px;font-weight: bold;color: black;">Le nombre des lignes du fichier ajouté est : {{addedLength}} lignes</p>
                      <p *ngIf="tarifGlobal > 0" style="font-size: 15px;font-weight: bold;color: black;">Le tarif gloab du fichier ajouté est : {{tarifGlobal | number: '1.2-2'| replace:',':' ' | replace:'.':',' }} € </p>
                      <p *ngIf="errorObjet" style="font-size: 15px;font-weight: bold;color:red;">{{errorObjet}}</p>
                    </div>
                    <div class=" col d-flex justify-content-center" style="margin: 1%;margin-top: -2%">
                      <div class="col-auto nbr-dossier ">
                        <table>
                          <tr>
                            <td style="padding-right: 30px;">
                              <button class="btn-valider validate-catalogue" (click)="saveData()" [disabled]="!form.valid">Enregistrer <img src="assets/images/save.png"></button>
  
                            </td>
                            <td>
                              <button class="btn-annule annulate-catalogue" routerLink="/fournisseur">Annuler <img src="assets/images/btn-annule.png"></button>
  
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </fieldset>
                </form>
              </div>
  
            </div>
          </div>
        </div>
        <div *ngIf="showPart()==6" class="col-content-affiche tabcontent2" id="commande">
          <h3 align="center"> Commandes {{fournisseur.denomination}}</h3>
          <div>
            <div class="card-body">
              <div>
                <div class="row ligne-table2  d-flex justify-content-center table-padding">
                  <table class="table-responsive" style="text-align: center;">
                    <tr>
                      <th class="left-padding">Année</th>
                      <th class="left-padding">Nombre des commandes</th>
                      <th class="left-padding">Somme prix Net HT</th>
                      <th class="left-padding">Somme montant TVA</th>
                      <th class="left-padding">Somme de prix net TTC</th>
  
                    </tr>
                    <tr class="text-center" *ngFor="let commande of commandes| paginate: { itemsPerPage: 10, currentPage: p }">
                      <td class="left-padding"[routerLink]="['/details/fournisseur', fournisseur.id,commande.yeardateCommande]">{{commande.yeardateCommande}}</td>
                      <td class="right-padding" [routerLink]="['/details/fournisseur', fournisseur.id,commande.yeardateCommande]">{{commande.number | number: '1.2-2'| replace:',':' ' | replace:'.':',' | replace:',00':' '}}</td>
                      <td class="right-padding" [routerLink]="['/details/fournisseur', fournisseur.id,commande.yeardateCommande]">{{commande.totalSommePrixNet| number: '1.2-2'| replace:',':' ' | replace:'.':','}}</td>
                      <td class="right-padding" [routerLink]="['/details/fournisseur', fournisseur.id,commande.yeardateCommande]">{{commande.totalmontantTVA| number: '1.2-2'| replace:',':' ' | replace:'.':','}}</td>
                      <td class="right-padding" [routerLink]="['/details/fournisseur', fournisseur.id,commande.yeardateCommande]">{{commande.totalPrix| number: '1.2-2'| replace:',':' ' | replace:'.':','}}</td>
                    </tr>
                  </table>
                  <pagination-controls previousLabel="Précédent" nextLabel="Suivant" (pageChange)="p = $event" #api></pagination-controls>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="showPart()==7" class="col-content-affiche tabcontent2" id="factures">
          <h3 align="center"> Factures {{fournisseur.denomination}}</h3>
          <a *ngIf="utilisateur && utilisateur.role == 'Super admin'" (click)="toFileXLS()" class="btn-connexion" style="border: 0px;color: white;padding: 10px;background-color: #067302;text-transform: uppercase;border-radius: 8px;text-decoration: none;margin-top: 20PX;cursor: pointer;position: absolute;right: 45px;top: -10px;">Exporter<img class="ml-1" src="assets/images/excel.png"></a>
          <div>
            <div class="card-body">
              <div>
                <div class="row ligne-table2  d-flex justify-content-center table-padding">
                  <table class="table-responsive" style="text-align: center;">
                    <tr>
                      <th class="left-padding">Année</th>
                      <th class="left-padding">Nombre des factures</th>
                      <th class="left-padding">Somme prix Net HT</th>
                      <th class="left-padding">Somme montant TVA</th>
                      <th class="left-padding">Somme de prix net TTC</th>
  
                    </tr>
                    <tr class="text-center" *ngFor="let facture of factures| paginate: { itemsPerPage: 10, currentPage: p }">
                      <td class="left-padding" [routerLink]="['/details/fournisseur/factures/details', fournisseur.id,facture.yeardateFacture]">{{facture.yeardateFacture}}</td>
                      <td class="right-padding" [routerLink]="['/details/fournisseur/factures/details', fournisseur.id,facture.yeardateFacture]">{{facture.number | number: '1.2-2'| replace:',':' ' | replace:'.':',' | replace:',00':' '}}</td>
                      <td class="right-padding" [routerLink]="['/details/fournisseur/factures/details', fournisseur.id,facture.yeardateFacture]">{{facture.totalSommePrixNet| number: '1.2-2'| replace:',':' ' | replace:'.':','}}</td>
                      <td class="right-padding" [routerLink]="['/details/fournisseur/factures/details', fournisseur.id,facture.yeardateFacture]">{{facture.totalmontantTVA| number: '1.2-2'| replace:',':' ' | replace:'.':','}}</td>
                      <td class="right-padding" [routerLink]="['/details/fournisseur/factures/details', fournisseur.id,facture.yeardateFacture]">{{facture.totalPrix| number: '1.2-2'| replace:',':' ' | replace:'.':','}}</td>
                    </tr>
  
                  </table>
                  <pagination-controls previousLabel="Précédent" nextLabel="Suivant" (pageChange)="p = $event" #api></pagination-controls>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
