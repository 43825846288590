<app-navbar></app-navbar>  
<br>
<div class="container">
  <div class="row">
    <div class="col">
      <h1 class="center m-2" *ngIf="isvoguel">VOGUEL CONSULTING</h1>
      <h1 class="center m-2" *ngIf="!isvoguel">HOTEL CARAVANSÉRAIL</h1>
    </div>
    <div class="col">
      <img src="assets/images/logo.png" *ngIf="isvoguel" style="margin: -15px auto;" class=" img-responsive img-logo-krendel" alt="Logo">
      <img src="assets/images/logocaravanserail.jpeg" *ngIf="!isvoguel" style="margin: -15px auto;height: 100px;" class=" img-responsive img-logo-krendel" alt="Logo">
    </div>
  </div>
</div>
<br><br><br>
<div class="container" *ngIf="isvoguel">
  <div class="row">
    <div class="col border-right">
      <h4> <span>SIÈGE SOCIAL :</span> 12 et 15 rue neuve <br><br> 1000 Bruxelles</h4><br>
      <h4><span>SIRET :</span> 795 236 954 25364 </h4><br>
      <h4><span>NOM COMMERCIAL :</span> VOGUEL <br> <br>CONSULTING</h4><br>
    </div>
    <div class="col border-right">
      <h4> <span>FORME JURIDIQUE :</span> Société anonyme</h4><br>
      <h4><span>ACTIVITÉ (CODE NAF) :</span> 2712Z : <br><br> société d'ingénierie et de conseils <br><br> en technologies de l'information </h4><br>
      <h4><span>SITE WEB :</span> <a href="http://www.voguelconsulting.com/" style="color:black" target="_blank">Lien</a></h4><br>

    </div>
    <div class="col">
      <h4><span>INSCRIPTION :</span> Immatriculée le <br><br> 10/01/2017 </h4><br>
      <h4> <span>N° DE TÉLÉPHONE :</span> +216 1 84 45 25 94 </h4><br>
      <h4><span>EMAIL :</span> contact@voguelconsulting.com </h4><br>
    </div>
  </div>
</div>

<div class="container" *ngIf="!isvoguel">
  <div class="row">
    <div class="col border-right">
      <h4> <span>SIÈGE SOCIAL :</span> VV9F+F26 Palmeraie de، Zone Touristique, Naftah 2240</h4><br>
      <h4><span>SIRET :</span> 123 456 789 12345 </h4><br>
      <h4><span>NOM COMMERCIAL :</span> HOTEL <br> <br>CARAVANSÉRAIL</h4><br>
    </div>
    <div class="col border-right">
      <h4> <span>FORME JURIDIQUE :</span> Société anonyme</h4><br>
      <h4><span>ACTIVITÉ (CODE NAF) :</span> <br><br> Option & ÉQUIPEMENTS <br><br> </h4><br>
      <h4><span>SITE WEB :</span> <a href="https://hotelcaravanserail.com/" style="color:black" target="_blank">Lien</a></h4><br>

    </div>
    <div class="col">
      <h4><span>INSCRIPTION :</span> Immatriculée le <br><br> dd/mm/yyyy </h4><br>
      <h4> <span>N° DE TÉLÉPHONE :</span> +216 76 430 399 | +216 90 272 304 </h4><br>
      <h4><span>EMAIL :</span> contact@hotelcaravanserail.com </h4><br>
    </div>
  </div>
</div>
<br><br><br><br><br>