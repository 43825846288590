import { Component, OnInit } from '@angular/core';
import * as XLSX from "xlsx";
import { CatalogueService } from "../../../services/catalogue.service";
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { FournisseurService } from "../../../services/fournisseur.service";
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
import { UserService } from "../../../services/user.service";
import { ValidationService } from '../../../services/validate/validate-error.service';

@Component({
  selector: 'app-update-catalogue',
  templateUrl: './update-catalogue.component.html',
  styleUrls: ['../../utilisateur/ajout-utilisateur/ajout-utilisateur.component.scss'],
})
export class UpdateCatalogueComponent implements OnInit {
  article: any;
  form: any;
  selectedFournisseur:any;
  fournisseurs:any;
  errorDate = false
  showMyContainer = 1;
  id:any;
  utilisateur:any;
  bigDate = "9999-12-31"
  loading:any
  constructor(private userService:UserService,private activatedRoute: ActivatedRoute, private catalogueService: CatalogueService, private formBuilder: FormBuilder, private fournisseurService: FournisseurService, private router: Router) {
    this.form = this.formBuilder.group({
      fournisseur: ['', Validators.required],
      datec: ['', [ Validators.required,ValidationService.dateValidatorr]],
      dateFin: ['', [ Validators.required,ValidationService.dateValidatorr]],
      mqe: ['', [Validators.minLength(2), Validators.maxLength(25)]],
      refCiel: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(100)]],
      gamme: ['', [Validators.minLength(2), Validators.maxLength(25)]],
      designation25: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(500)]],
      tarif: ['', [Validators.required,Validators.maxLength(20)]],
      qt: ['', [Validators.minLength(2), Validators.maxLength(25)]],
      hautp: ['', [Validators.minLength(2), Validators.maxLength(25)]],
      largp: ['', [Validators.minLength(2), Validators.maxLength(25)]],
      profp: ['', [Validators.minLength(2), Validators.maxLength(25)]],
      poidsp: ['', [Validators.minLength(2), Validators.maxLength(25)]],
      diam: ['', [Validators.minLength(2), Validators.maxLength(25)]],
      statut: ['', [Validators.minLength(2), Validators.maxLength(25)]],
      typ: ['', [Validators.minLength(2), Validators.maxLength(25)]],
      delaiLiv: ['', [Validators.minLength(2), Validators.maxLength(25)]],
      dateLancement: [''],
      dateArret: [''],
      ean13: ['', [Validators.minLength(2), Validators.maxLength(25)]],
      garantie: ['', [Validators.minLength(2), Validators.maxLength(25)]],
      edi: ['', [Validators.minLength(2), Validators.maxLength(25)]],
      mkt1: ['', [Validators.minLength(2), Validators.maxLength(25)]],
      mktl1: ['', [Validators.minLength(2), Validators.maxLength(25)]],
      mkt2: ['', [Validators.minLength(2), Validators.maxLength(25)]],
      mktl2: ['', [Validators.minLength(2), Validators.maxLength(25)]],
      mkt3: ['', [Validators.minLength(2), Validators.maxLength(25)]],
      mktl3: ['', [Validators.minLength(2), Validators.maxLength(25)]],
      mkt4: ['', [Validators.minLength(2), Validators.maxLength(25)]],
      mktl4: ['', [Validators.minLength(2), Validators.maxLength(25)]],
      mkt5: ['', [Validators.minLength(2), Validators.maxLength(25)]],
      mktl5: ['', [Validators.minLength(2), Validators.maxLength(25)]],
    })
  }
  ngOnInit() {
    this.id = localStorage.getItem('idUser')
    this.userService.getUserByID(this.id).subscribe(utilisateur => {
      this.utilisateur = utilisateur;
      if(!this.utilisateur){
        localStorage.removeItem('idUser');
        localStorage.clear();
        this.router.navigate(['/login']);
      }
      if(this.utilisateur.sousRole == 'gr-acces'){
        this.router.navigate(["accueil"]);
        return false;
      }
    },
      err => {
        return false;
      });
    let params: any = this.activatedRoute.snapshot.params;
    this.catalogueService.getArticleById(params.id).subscribe(data => {
      this.article = data
      if(this.article){
        this.selectedFournisseur = {
          "denomination":this.article.denomination,
          "idFournisseur":this.article.idFournisseur,
        }
      }
    },
      err => {
        return false;
      });
    this.getAllFournisseurs()
  }
  getAllFournisseurs() {
    this.fournisseurService.getAllUsers().then(data => {
      this.fournisseurs = data;
    },
      err => {
        return false;
      });
  }
  getDateDebut(): string {
    return this.article.datec
  }
  getBigDate(): string {
      return this.bigDate
    }
  updatePrinNett(vzlue){
    if(vzlue < 0 ||vzlue > 999999999 || (vzlue && vzlue.toString().length >15)){
      this.article.tarif = ""
    }
  }

  saveCatalogue(article) {
    Swal.fire({
      title: 'VOULEZ-VOUS ENREGISTRER VOS MODIFICATIONS?',
      imageUrl: 'assets/images/modifier.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      showCancelButton: true,
      width: 800,
      confirmButtonColor: '#059e00',
      cancelButtonColor: '#FF9100',
      cancelButtonText: 'ANNULER',
      confirmButtonText: 'ENREGISTRER'
    }).then((result) => {
      if (!result.dismiss) {
        this.errorDate = false;
        article.denomination = this.selectedFournisseur.denomination
        article.idFournisseur = this.selectedFournisseur.idFournisseur
        if (article.datec > article.dateFin) {
          this.errorDate = true;
          return false;
        }
        article.fournisseur = undefined
        this.catalogueService.editArticleById(article)
          .subscribe(resp => {
            Swal.fire( {
                imageUrl: 'assets/images/statut-valide-hover.png',
                imageWidth: 100,
                imageHeight: 100,
                imageAlt: 'Custom image',
                width: 800,
                title: 'BIEN MODIFIÉ',
                timer: 1500,
                showConfirmButton: false
              }
            );
            this.router.navigateByUrl('/catalogue');
          }, err => {
          })

      }
    } );

  }
  modifPart1() {
    this.showMyContainer = 1;
  }
  modifPart2(){
    this.showMyContainer = 2;
  }
  showPart() {
    return this.showMyContainer;
  }
}
