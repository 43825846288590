import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
const headers = new HttpHeaders({Authorization: 'Basic ' + btoa('UserkrendelAdminnew:krl2021!!??**SoC24-415fr-a410-ttx9J8NbPecEL6k4BkAXvGE3t8f3nuEZmXa5RNJBAdrnh6XqB2')})
.set('Content-Type', 'application/json')
.set('Accept', 'application/json');
headers.append('Access-Control-Allow-Origin', '*');
import 'rxjs/add/operator/map';

@Injectable({
  providedIn: 'root'
})
export class StockService {

  constructor(private http: HttpClient) {
  }

  saveAllData(client) {
    return this.http.post(environment.host + '/stock/addall', JSON.stringify(client), {headers: headers})
  }
  getAllData(){
    return new Promise(resolve => {
      this.http.get(environment.host + '/stock', {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }

  addinventaireconfig(inventaire) {
    return this.http.post(environment.host + '/inventaireconfig/addOne', JSON.stringify(inventaire), { headers: headers })
  }

  editinventaireconfig(inventaireconfig) {
    return this.http.put(environment.host + '/inventaireconfig/' + inventaireconfig.id, JSON.stringify(inventaireconfig), { headers: headers })
  }
  getAllDataConfig(){
    return new Promise(resolve => {
      this.http.get(environment.host + '/inventaireconfig', {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
getUniqueDateInventaire(){
  return new Promise(resolve => {
    this.http.get(environment.host + '/stock/getUniqueDateInventaire', {headers: headers}).subscribe(data => {
      resolve(data);
    }, err => {
    });
  });
}
  getAllDataByRef(){
    return new Promise(resolve => {
      this.http.get(environment.host + '/stock/getallstockByref', {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getAllDataByRefByYear(year){
    return new Promise(resolve => {
      this.http.get(environment.host + '/stock/getallstockByrefByYear/'+year, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }

  getoneDataByRefByYear(year){
    return new Promise(resolve => {
      this.http.get(environment.host + '/stock/getonestockByrefByYear/'+year, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  deleteAllData(){
    return new Promise(resolve => {
      this.http.delete(environment.host + '/stock/deleteall', {headers: headers} ).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  deleteArticleById(id){
    return new Promise(resolve => {
      this.http.delete(environment.host + '/stock/' + id,{headers: headers} ).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getArticleById(id){
    return this.http.get(environment.host + '/stock/'+id,{headers: headers})
  }
  getArticleByRefArt(refArt){
    return new Promise(resolve => {
      this.http.get(environment.host + '/stock/byRef/'+refArt, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getArticleByRefArtAndDate(refArt,date){
    return new Promise(resolve => {
      this.http.get(environment.host + '/stock/byRef/AndDate/'+refArt+'/'+date, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }

  getArticleByRefArtAndDateInventaire(refArt,date){
    return new Promise(resolve => {
      this.http.get(environment.host + '/stock/byRef/AndDateInventaire/'+date+'/ref/'+refArt+'/art', {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getAllFournisseurofArticles(){
    return new Promise(resolve => {
      this.http.get(environment.host + '/stock/groupedBy/fournisseurId', {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getAllFournisseurofArticlesWithYear(id){
    return new Promise(resolve => {
      this.http.get(environment.host + '/stock/groupedBy/fournisseurId/and/year/'+id, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }

  getAllDataByDateByFournisseur(id,date){
    return new Promise(resolve => {
      this.http.get(environment.host + '/stock/getByDateFournisseur/'+id+'/'+date, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getAllDataByDate(date){
    return new Promise(resolve => {
      this.http.get(environment.host + '/stock/getByDate/'+date, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }

  getAllDataByDateAndFilter(date){
    return new Promise(resolve => {
      this.http.get(environment.host + '/stock/filtre/getByDate/'+date, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  filterInventaireByDatec(){
    return new Promise(resolve => {
      this.http.get(environment.host + '/stock/group/inventaire', {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  filterInventaireBySingleDatec(year){
    return new Promise(resolve => {
      this.http.get(environment.host + '/stock/group/inventaire/singleDate/'+year, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  editInventairerById(inventaire){
    return this.http.put(environment.host + '/stock/' + inventaire.id, JSON.stringify(inventaire), { headers: headers })
  }

  getAllDataGroiupByDate(){
    return new Promise(resolve => {
      this.http.get(environment.host + '/stock/groupByDate', {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }

  getAllDataByDateInventaire(date){
    return new Promise(resolve => {
      this.http.get(environment.host + '/stock/getAllDataByDateInventaire/'+date, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
}
