import { Component, OnInit } from '@angular/core';
import * as XLSX from "xlsx";
import { InventaireService } from "../../../services/inventaire.service";
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from "../../../services/user.service";
import * as moment from 'moment';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'app-inventaire-by-year-without-four',
  templateUrl: './inventaire-by-year-without-four.component.html',
  styleUrls: ['../../utilisateur/utilisateur/utilisateur.component.css'],
})
export class InventaireByYearWithoutFourComponent implements OnInit {
  fileReaded: any;
  listeInventaire: any
  loading = false;
  sizeLimitOne = 30000000; //30MB
  numberData = 25;
  params:any;
  listeInventaireExcel:any[]=[]
  utilisateur:any;
  id:any;
  filterinventaire:any;
  p = 1
  role:any;
  hasRolestock = false
  hasRoleAdd = false
  hasRoleUpdate = false
  hasRoleDelete = false
  constructor(private roleService:RoleService,private inventaireService: InventaireService, private activatedRoute:ActivatedRoute,private userService:UserService,private router:Router) {
    this.params = this.activatedRoute.snapshot.params;
    this.id = localStorage.getItem('idUser')
    this.userService.getUserByID(this.id).subscribe(utilisateur => {
      this.utilisateur = utilisateur;
      if(!this.utilisateur){
        localStorage.removeItem('idUser');
        localStorage.clear();
        this.router.navigate(['/login']);
      }
      this.roleService.getRoleById(this.utilisateur.idRole).subscribe(role => {
        this.role = role;
        if (this.role && this.role.privileges && this.role.privileges.length > 0) {
          let founddeleteUtilisateur = this.role.privileges.find(x => x.name == "GET_ALL_STOCK")
          let founddeleteUtilisateuradd = this.role.privileges.find(x => x.name == "ADD_INVENTAIRE")
          let founddeleteUtilisateurupdate = this.role.privileges.find(x => x.name == "UPDATE_INVENTAIRE")
          let founddeleteUtilisateurdelete = this.role.privileges.find(x => x.name == "DELETE_INVENTAIRE")
          if (founddeleteUtilisateur) {
            this.hasRolestock = true;
          }
          if (founddeleteUtilisateuradd) {
            this.hasRoleAdd = true;
          }
          if (founddeleteUtilisateurupdate) {
            this.hasRoleUpdate = true;
          }
          if (founddeleteUtilisateurdelete) {
            this.hasRoleDelete = true;
          }
        }
      },
        err => {
          return false;
        }); 
        this.inventaireService.getinventaireactivationByDate(this.params.date)
          .then(data => {
            if(data && data["desactive"]){
              this.router.navigate(['/inventaire']);
            }
          }, err => {
          })
      
    },
      err => {
        return false;
      });
    this.loading = true;
    this.inventaireService.getAllDataByDate(this.params.date).then(data => {
      this.listeInventaire = data
      this.loading = false;
    },
      err => {
        return false;
      });
  }
  ngOnInit() {
  }
  removebible(article) {
    if (!article.id) {
      Swal.fire({
      title: 'VOULEZ-VOUS SUPPRIMER CET ARTICLE?',
      imageUrl: 'assets/images/supp-modal.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      showCancelButton: true,
      width: 800,
      confirmButtonColor: '#059e00',
      cancelButtonColor: '#FF9100',
      cancelButtonText: 'ANNULER',
      confirmButtonText: 'VALIDER'
    }).then((result) => {
      if (!result.dismiss) {
        Swal.fire( {
            imageUrl: 'assets/images/supp-modal.png',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            width: 800,
            title: 'BIEN SUPPRIMÉ',
            timer: 1500,
            showConfirmButton: false
          }
        );
        this.listeInventaire.splice(this.listeInventaire.indexOf(article), 1);
        return true;
      }
    } );
    }
    Swal.fire({
    title: 'VOULEZ-VOUS SUPPRIMER CET ARTICLE?',
    imageUrl: 'assets/images/supp-modal.png',
    imageWidth: 100,
    imageHeight: 100,
    imageAlt: 'Custom image',
    showCancelButton: true,
    width: 800,
    confirmButtonColor: '#059e00',
    cancelButtonColor: '#FF9100',
    cancelButtonText: 'ANNULER',
    confirmButtonText: 'VALIDER'
  }).then((result) => {
    if (!result.dismiss) {
      Swal.fire( {
          imageUrl: 'assets/images/supp-modal.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 800,
          title: 'BIEN SUPPRIMÉ',
          timer: 1500,
          showConfirmButton: false
        }
      );
      this.inventaireService.deleteArticleById(article.id).then(data => {
      });
      this.listeInventaire.splice(this.listeInventaire.indexOf(article), 1);
    }
  } );
  }
exportDataToExcel(){
  if(this.listeInventaire.length < 1){
    return false;
  }
  this.loading = true;
  for(var i =0; i<this.listeInventaire.length;i++){
    if(this.listeInventaire[i].dateCommande){
      this.listeInventaire[i].dateCommande = moment(this.listeInventaire[i].dateCommande).format('DD/MM/YYYY')
    }
    if(this.listeInventaire[i].dateFacture){
      this.listeInventaire[i].dateFacture = moment(this.listeInventaire[i].dateFacture).format('DD/MM/YYYY')
    }
    var devExcel = {
      "Date de l'inventaire": moment(this.listeInventaire[i].datec).format('DD/MM/YYYY'),
      "Fournisseur":this.listeInventaire[i].denomination,
      "Référence":this.listeInventaire[i].refArt,
      "Désignation":this.listeInventaire[i].designation,
      "Prix Tarif":Number(this.listeInventaire[i].pritar).toFixed(2),
      "Coefficient d'achat	":Math.round(this.listeInventaire[i].coefAchat * 10000) / 10000,
      "Prix net	":Number(this.listeInventaire[i].prinet).toFixed(2),
      "Quantité emballée":this.listeInventaire[i].quantiteEmballe,
      "Quantité nom emballée":this.listeInventaire[i].quantiteNonEmballe,
      "Quantité":this.listeInventaire[i].quantite,
      "Étage":this.listeInventaire[i].etage,
      "Montant":Number(this.listeInventaire[i].montant).toFixed(2),
      "Numéro de commande":this.listeInventaire[i].numeroCommande,
      "Date de commande":this.listeInventaire[i].dateCommande,
      "Numéro de facture":this.listeInventaire[i].numeroFacture,
      "Date de facture":this.listeInventaire[i].dateFacture,
      "Observation": this.listeInventaire[i].observation
    }
    this.listeInventaireExcel.push(devExcel)
  }
  const workBook = XLSX.utils.book_new(); // create a new blank book
  const workSheet = XLSX.utils.json_to_sheet(this.listeInventaireExcel);

  XLSX.utils.book_append_sheet(workBook, workSheet, 'data'); // add the worksheet to the book
  var mydate =  this.listeInventaire[0].datec[8]+this.listeInventaire[0].datec[9]+this.listeInventaire[0].datec[5]+this.listeInventaire[0].datec[6]+this.listeInventaire[0].datec[2]+this.listeInventaire[0].datec[3]
  XLSX.writeFile(workBook, 'VoWork-Inventaire-'+ mydate  +'.xlsx'); // initiate a file download in browser
  this.loading = false
}


}

