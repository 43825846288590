import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DossierRoutingModule } from './dossier-routing.module';
import { DossierComponent } from './dossier/dossier.component';
import { AjoutDossierComponent } from './ajout-dossier/ajout-dossier.component';
import { AffectationDossierComponent } from './affectation-dossier/affectation-dossier.component';
import { DetailsDossierComponent } from './details-dossier/details-dossier.component';
import { DetailsDossierCommandeComponent } from './details-dossier-commande/details-dossier-commande.component';
import { UpdateDossierComponent } from './update-dossier/update-dossier.component';
import { NavbarModule } from '../shared/navbar/navbar.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ControleMessagesModule } from '../shared/controle-messages/controle-messages.module';
import { ControleMessagesRoutingModule } from '../shared/controle-messages/controle-messages-routing.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxLoadingModule } from 'ngx-loading';
import { DecimalFormattModule } from '../shared/decimal-formatt/decimal-formatt.module';
import { DossierGuard } from 'src/app/guards/dossier.guard';



@NgModule({
  declarations: [DossierComponent, AjoutDossierComponent, AffectationDossierComponent, DetailsDossierComponent, DetailsDossierCommandeComponent, UpdateDossierComponent],
  imports: [
    CommonModule,
    DossierRoutingModule,
    NavbarModule,
    DecimalFormattModule,
    FormsModule, ReactiveFormsModule,
    ControleMessagesModule,
    ControleMessagesRoutingModule,
    NgxPaginationModule,
    NgbModule,
    InfiniteScrollModule,
    NgxLoadingModule
  ],
  providers:[DossierGuard]
})
export class DossierModule { }
