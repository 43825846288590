import {environment} from '../../environments/environment';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/publish';
const headers = new HttpHeaders({Authorization: 'Basic ' + btoa('UserkrendelAdminnew:krl2021!!??**SoC24-415fr-a410-ttx9J8NbPecEL6k4BkAXvGE3t8f3nuEZmXa5RNJBAdrnh6XqB2')})
.set('Content-Type', 'application/json')
  .set('Accept', 'application/json');
headers.append('Access-Control-Allow-Origin', '*');


@Injectable({
  providedIn: 'root'
})
export class ChargeAffaireService {
  constructor(public http: HttpClient) {
  }

  //get all produits
  getAllUsers() {
    return new Promise(resolve => {
      this.http.get(environment.host + '/chargeaffaire',{headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  //get all produits
  getAllUsersByClient() {
    return new Promise(resolve => {
      this.http.get(environment.host + '/chargeaffaire/andClient',{headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  addChargeAffaire(chargeAffaire) {
    return this.http.post(environment.host + '/chargeaffaire/newChargeaffaire', JSON.stringify(chargeAffaire), {headers: headers})

  }
  // verify existance email
  verifyEmail(chargeAffaire) {
    return this.http.post(environment.host + '/chargeaffaire/verifiedEmail', JSON.stringify(chargeAffaire), { headers: headers })
  }
  private extractData(res: Response) {
    let body = res.json();
    return body || {};
  }


  getAllChargeAffaire(page, size) {
    return new Promise(resolve => {
      this.http.get(environment.host + '/chargeaffaire/getDetailChargeAffaires/' + page + '/' + size).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }


  getChargeAffaireById(id) {
    return this.http.get(environment.host + '/chargeaffaire/'+id,{headers: headers})
  }


  deleteChargeAffaireById(id) {
    return new Promise(resolve => {
      this.http.delete(environment.host + '/chargeaffaire/' + id,{headers: headers} ).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }

  editChargeAffaireById(chargeAffaire) {
    return this.http.put(environment.host + '/chargeaffaire/' + chargeAffaire.id, JSON.stringify(chargeAffaire), { headers: headers })
  }


  getChargeAffairesByIdClient(id) {
    return new Promise(resolve => {
      this.http.get(environment.host + '/chargeaffaire/getChargeAffairesByClient/' + id,{headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }


  getFiltredChargeAffairesByNom(nom, page, size) {
    return new Promise(resolve => {
      this.http.get(environment.host + '/chargeaffaire/findByNom/' + nom + '/' + page + '/' + size).subscribe(data => {
        resolve(data);
        console.log(data);
      }, err => {
      });
    });
  }


  deleteEnfantByIdChargeAndIndex(id, index) {
    return new Promise(resolve => {
      this.http.delete(environment.host + '/chargeaffaire/deleteEnfantByIdChargeAndIndex/' + id + '/' + index).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }

  editEnfantByIdChargeAndIndex(id, index, enfant) {
    return new Promise(resolve => {
      this.http.put(environment.host + '/chargeaffaire/editEnfantByIdChargeAndIndex/' + id + '/' + index, enfant).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getAllUsersByClientByPage(page,size){
    return new Promise(resolve => {
      this.http.get(environment.host + '/chargeaffaire/byPage/'+page+'/'+size, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getAllDataCount(){
    return new Promise(resolve => {
      this.http.get(environment.host + '/chargeaffaire/count', { headers: headers }).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getAllDataByPageAndRecherche(event){
    return new Promise(resolve => {
      this.http.get(environment.host + '/chargeaffaire/getchargeaffaire/recherche/'+event, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
}
