import { Component, OnInit } from '@angular/core';
import { CatalogueService } from "../../../services/catalogue.service";
import { Router, ActivatedRoute } from '@angular/router';
import { FournisseurService } from "../../../services/fournisseur.service";
import { UserService } from "../../../services/user.service";

@Component({
  selector: 'app-catalogue-by-year',
  templateUrl: './catalogue-by-year.component.html',
  styleUrls: ['../../utilisateur/utilisateur/utilisateur.component.css'],
})
export class CatalogueByYearComponent implements OnInit {
  years: any[] = [];
  lengthdata = 0
  id:any;
  utilisateur:any;
  fournisseur:any;
  numberData = 10
  public filterArticle: any = '';
  p = 1;
  loading:any;
  constructor(private catalogueService: CatalogueService, private activatedRoute: ActivatedRoute, private fournisseurService: FournisseurService, private userService:UserService,private router:Router) {

  }
  ngOnInit() {
    this.id = localStorage.getItem('idUser')
    this.userService.getUserByID(this.id).subscribe(utilisateur => {
      this.utilisateur = utilisateur;
      if(!this.utilisateur){
        localStorage.removeItem('idUser');
        localStorage.clear();
        this.router.navigate(['/login']);
      }
    },
      err => {
        this.router.navigateByUrl('/accueil');
        return false;
      });
    let params: any = this.activatedRoute.snapshot.params;
    this.catalogueService.getAllFournisseurofArticlesWithYear(params.id).then(data => {
      if (data && data["result"]) {
        for (var j = 0; j < data["result"].length; j++) {
          var dataObject = {
            "year" : data["result"][j].datec,
            "datec": data["result"][j]._id.datec,
            "dateFin": data["result"][j]._id.dateFin,
            "refCount": data["result"][j].refCount
          }
          if (this.years.indexOf(dataObject) === -1) {
            this.lengthdata = this.lengthdata + dataObject.refCount
            this.years.push(dataObject)
          }
        }
      this.years.sort(this.triYear);
      }
    },
      err => {
        return false;
      });
      this.fournisseurService.getFournisseurByID(params.id).subscribe(fournisseur => {
        this.fournisseur = fournisseur;
      },
        err => {
          return false;
        });
  }

  triYear(a, b) {
    if (a.datec > b.datec) return -1;
    else if (a.datec == b.datec) return 0;
    else return 1;
  }
  goToCatalogue(data) {
    let params: any = this.activatedRoute.snapshot.params;
    this.router.navigate(['/catalogue', data.year, params.id,data.datec,data.dateFin]);

  }
}
