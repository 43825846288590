export function  triCodes(a,b)
{
 if (a.fields.code_naf < b.fields.code_naf) return -1;
 else if (a.fields.code_naf == b.fields.code_naf) return 0;
 else return 1;
}
export const codesNav = [
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7e6c054f5386e4d40711c9bbd1ef556f3da83d1e",
    "fields": {
      "intitule_naf_65": "Fabrication de produits à base de tabac",
      "intitule_naf": "Fabrication de produits à base de tabac",
      "code_naf": "120",
      "intitule_naf_40": "Fabrication de produits à base de tabac"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c8a0c76a45e20be3105a87a4878ddfee6402c869",
    "fields": {
      "intitule_naf_65": "Traitement primaire des récoltes",
      "intitule_naf": "Traitement primaire des récoltes",
      "code_naf": "163",
      "intitule_naf_40": "Traitement primaire des récoltes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "327a34ca6063f9637a38114069494613fe45809d",
    "fields": {
      "intitule_naf_65": "Fabrication d'étoffes à mailles",
      "intitule_naf": "Fabrication d'étoffes à mailles",
      "code_naf": "1391Z",
      "intitule_naf_40": "Fabrication d'étoffes à mailles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ba15bd908276ca394130171ae8ce2f2161a0f39d",
    "fields": {
      "intitule_naf_65": "Culture de la canne à sucre",
      "intitule_naf": "Culture de la canne à sucre",
      "code_naf": "114",
      "intitule_naf_40": "Culture de la canne à sucre"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ad3b864c3b1c7e26ef8929db0fe3ecb7ba43c150",
    "fields": {
      "intitule_naf_65": "Sciage et rabotage du bois",
      "intitule_naf": "Sciage et rabotage du bois",
      "code_naf": "161",
      "intitule_naf_40": "Sciage et rabotage du bois"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "59d6c670b55a0ca17c772035eecd10b97fa19712",
    "fields": {
      "intitule_naf_65": "Exploitation forestière",
      "intitule_naf": "Exploitation forestière",
      "code_naf": "0220Z",
      "intitule_naf_40": "Exploitation forestière"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "dab6572873661e2cfa1c1281218e73f8dcee460e",
    "fields": {
      "intitule_naf_65": "Fabrication de boissons",
      "intitule_naf": "Fabrication de boissons",
      "code_naf": "11",
      "intitule_naf_40": "Fabrication de boissons"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0d6213a989732f26b61f7054069e5d32b43f6a6e",
    "fields": {
      "intitule_naf_65": "Industries alimentaires",
      "intitule_naf": "Industries alimentaires",
      "code_naf": "10",
      "intitule_naf_40": "Industries alimentaires"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "29d2b89b76d1516c074c087982421befea2b7797",
    "fields": {
      "intitule_naf_65": "Fabrication de sucre",
      "intitule_naf": "Fabrication de sucre",
      "code_naf": "1081",
      "intitule_naf_40": "Fabrication de sucre"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2d3b1328fbcc4e5d5e6b65eff4a9e7cbf3e12abe",
    "fields": {
      "intitule_naf_65": "Tissage",
      "intitule_naf": "Tissage",
      "code_naf": "1320",
      "intitule_naf_40": "Tissage"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "76e84b561c2f2c6fa141246cd8336476098e593a",
    "fields": {
      "intitule_naf_65": "Fab. machines équipts bureau (sf ordinateurs & équipts périph.)",
      "intitule_naf": "Fabrication de machines et d'équipements de bureau (à l'exception des ordinateurs et équipements périphériques)",
      "code_naf": "2823Z",
      "intitule_naf_40": "Fab. machine équipt bureau (sf ordinat.)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "77dde4a2d0c02e42f935f06f155a33f972d56e7c",
    "fields": {
      "intitule_naf_65": "Comm. détail textiles habillt & chaussures s/éventaires & marchés",
      "intitule_naf": "Commerce de détail de textiles, d'habillement et de chaussures sur éventaires et marchés",
      "code_naf": "4782",
      "intitule_naf_40": "Cd textiles habillt & chauss. s/marchés"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "12c5d576525c3e020b6e27ab64fd9d3ab4d8ced5",
    "fields": {
      "intitule_naf_65": "Comm. détail d'articles médicaux & orthopédiques en magasin spéc.",
      "intitule_naf": "Commerce de détail d'articles médicaux et orthopédiques en magasin spécialisé",
      "code_naf": "4774",
      "intitule_naf_40": "Com. dét. art. médicaux & orthopéd. (ms)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "08458eba93709b2c1e0271528195c28096bea14d",
    "fields": {
      "intitule_naf_65": "Interm. comm. machines, équipts industriels, navires et avions",
      "intitule_naf": "Intermédiaires du commerce en machines, équipements industriels, navires et avions",
      "code_naf": "4614Z",
      "intitule_naf_40": "Int. comm. équipt indus., navire & avion"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b772f2ab1e902ad78882d6f2829a84cfa37f5c96",
    "fields": {
      "intitule_naf_65": "Fabrication de moteurs, génératrices, transformateurs électriques",
      "intitule_naf": "Fabrication de moteurs, génératrices et transformateurs électriques",
      "code_naf": "2711Z",
      "intitule_naf_40": "Fab. moteur génér. transfo. & mat. élec."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "be1ff8fed95c9abbb3b78bd0594ced77c5064f3b",
    "fields": {
      "intitule_naf_65": "Comm. détail enreg. musicaux & vidéo (magasin spécialisé)",
      "intitule_naf": "Commerce de détail d'enregistrements musicaux et vidéo en magasin spécialisé",
      "code_naf": "4763Z",
      "intitule_naf_40": "Com. dét. enreg. musicaux & vidéo (ms)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f6aaceead4fce8fdc211f98664ff4981df0cec95",
    "fields": {
      "intitule_naf_65": "Commerce de gros d'autres produits intermédiaires",
      "intitule_naf": "Commerce de gros (commerce interentreprises) d'autres produits intermédiaires",
      "code_naf": "4676Z",
      "intitule_naf_40": "Commerce gros d'aut. prod. intermédiaire"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "bb7c4f090d20ca6151467db2fa668853b0b61097",
    "fields": {
      "intitule_naf_65": "Commerce de gros de parfumerie et de produits de beauté",
      "intitule_naf": "Commerce de gros de parfumerie et de produits de beauté",
      "code_naf": "4645",
      "intitule_naf_40": "Com. gros parfumerie & produit de beauté"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d06ee65bb1b8d95f2ac4c76662ce6ecd3c607d35",
    "fields": {
      "intitule_naf_65": "Autre commerce de détail en magasin non spécialisé",
      "intitule_naf": "Autre commerce de détail en magasin non spécialisé",
      "code_naf": "4719",
      "intitule_naf_40": "Autre comm. détail en magasin non spéc."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "fb06bddeca1a01e37e81fbda99bba9b6a289b821",
    "fields": {
      "intitule_naf_65": "Fabrication d'éléments en métal pour la construction",
      "intitule_naf": "Fabrication d'éléments en métal pour la construction",
      "code_naf": "251",
      "intitule_naf_40": "Fab. élément en métal pour la construct."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "09edbb61d2d4026cd4434b76eae74a16db896e65",
    "fields": {
      "intitule_naf_65": "Transform. & conserv. poisson, crustacés & mollusques",
      "intitule_naf": "Transformation et conservation de poisson, de crustacés et de mollusques",
      "code_naf": "1020Z",
      "intitule_naf_40": "Transf. & conserv. poisson, crust., etc."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f002daac558ce0c4987f9699f9d4ef2836cec8e9",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres articles en papier ou en carton",
      "intitule_naf": "Fabrication d'autres articles en papier ou en carton",
      "code_naf": "1729",
      "intitule_naf_40": "Fab. aut. article en papier ou en carton"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "37dd82a96a9c97317e0c950657451c959677544b",
    "fields": {
      "intitule_naf_65": "Exploitation de laiteries et fabrication de fromage",
      "intitule_naf": "Exploitation de laiteries et fabrication de fromage",
      "code_naf": "1051",
      "intitule_naf_40": "Expl. de laiteries et fabric. de fromage"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5987c4787582212d57c0d2e9af09e25407c24b44",
    "fields": {
      "intitule_naf_65": "Fabrication d'appareils ménagers non électriques",
      "intitule_naf": "Fabrication d'appareils ménagers non électriques",
      "code_naf": "2752Z",
      "intitule_naf_40": "Fab. appareils ménagers non électriques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "eb55eaa2ca173b31d5e01078e2857726640ea0c9",
    "fields": {
      "intitule_naf_65": "Fabrication d'appareils d'éclairage électrique",
      "intitule_naf": "Fabrication d'appareils d'éclairage électrique",
      "code_naf": "2740Z",
      "intitule_naf_40": "Fabric. appareils d'éclairage électrique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4fd55b2524f47de715bfd26900a553db9055d7ae",
    "fields": {
      "intitule_naf_65": "Fabrication de composants et cartes électroniques",
      "intitule_naf": "Fabrication de composants et cartes électroniques",
      "code_naf": "261",
      "intitule_naf_40": "Fab. de composants & cartes électroniq."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1b4a8dfeface543f198c6c63e79a0ece1c134b29",
    "fields": {
      "intitule_naf_65": "Taille, façonnage et finissage de pierres",
      "intitule_naf": "Taille, façonnage et finissage de pierres",
      "code_naf": "2370",
      "intitule_naf_40": "Taille, façonnage & finissage de pierres"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "6711a67a3c4409e3fdde5785256db516df163f90",
    "fields": {
      "intitule_naf_65": "Fabrication de papier et de carton",
      "intitule_naf": "Fabrication de papier et de carton",
      "code_naf": "1712Z",
      "intitule_naf_40": "Fabrication de papier et de carton"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "793df226f543c1fe5bce77a87848accbb4bbde68",
    "fields": {
      "intitule_naf_65": "Fabrication de fours et brûleurs",
      "intitule_naf": "Fabrication de fours et brûleurs",
      "code_naf": "2821",
      "intitule_naf_40": "Fabrication de fours et brûleurs"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9eb2bd76794dfc0fa774025f00d69392b74360d8",
    "fields": {
      "intitule_naf_65": "Exploit gravieres & sablieres, extraction argiles & kaolin",
      "intitule_naf": "Exploitation de gravières et sablières, extraction d'argiles et de kaolin",
      "code_naf": "812",
      "intitule_naf_40": "Exploit. gravière & sabl., extr. argile"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f05ae6acba55e33e3a9a50162fe2792d6a8fa38c",
    "fields": {
      "intitule_naf_65": "Traitement et élimination des déchets dangereux",
      "intitule_naf": "Traitement et élimination des déchets dangereux",
      "code_naf": "3822",
      "intitule_naf_40": "Traitmnt & élimination déchets dangereux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "37b37ed75e14a5d01c085e84dcc0df6a6995a876",
    "fields": {
      "intitule_naf_65": "Construction de navires et de structures flottantes",
      "intitule_naf": "Construction de navires et de structures flottantes",
      "code_naf": "3011Z",
      "intitule_naf_40": "Construct. navires & structure flottante"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0c3e29b9959e97199ed3979d5159f3869c7f990d",
    "fields": {
      "intitule_naf_65": "Distribution de combustibles gazeux par conduites",
      "intitule_naf": "Distribution de combustibles gazeux par conduites",
      "code_naf": "3522",
      "intitule_naf_40": "Distrib. combustible gazeux pr conduites"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f003732d7de4cd4d4f565155674def6a0916da24",
    "fields": {
      "intitule_naf_65": "Fabrication de matériel de levage et de manutention",
      "intitule_naf": "Fabrication de matériel de levage et de manutention",
      "code_naf": "2822",
      "intitule_naf_40": "Fab. matériel de levage & de manutention"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1a1f77f91644d34ecfdc176c681bd5adf0c5cb29",
    "fields": {
      "intitule_naf_65": "Commerce d'autres véhicules automobiles",
      "intitule_naf": "Commerce d'autres véhicules automobiles",
      "code_naf": "4519",
      "intitule_naf_40": "Commerce d'autres véhicules automobiles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "cc8e74fb538d664bd9fb67ebbcb60d4247b06178",
    "fields": {
      "intitule_naf_65": "Autres activités manufacturières n.c.a.",
      "intitule_naf": "Autres activités manufacturières n.c.a.",
      "code_naf": "3299",
      "intitule_naf_40": "Autres activités manufacturières n.c.a."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "6f36d9498f188aa081faef9d8f97c7f9a600a862",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres machines-outils",
      "intitule_naf": "Fabrication d'autres machines-outils",
      "code_naf": "2849",
      "intitule_naf_40": "Fabrication d'autres machines-outils"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "bdb2522edec8492982a058ff3f1e5ae890636cf8",
    "fields": {
      "intitule_naf_65": "Construction de ponts et tunnels",
      "intitule_naf": "Construction de ponts et tunnels",
      "code_naf": "4213",
      "intitule_naf_40": "Construction de ponts et tunnels"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ca745cefb4624cfbe2f0546d480727485a91b976",
    "fields": {
      "intitule_naf_65": "Fabrication de motocycles",
      "intitule_naf": "Fabrication de motocycles",
      "code_naf": "3091",
      "intitule_naf_40": "Fabrication de motocycles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ebba599466649f7ab37db0a2bd9dba7bda60730d",
    "fields": {
      "intitule_naf_65": "Aut. activ. auxil. serv. financ., hors assur. & caisse retr. nca.",
      "intitule_naf": "Autres activités auxiliaires de services financiers, hors assurance et caisses de retraite, n.c.a.",
      "code_naf": "6619B",
      "intitule_naf_40": "Aut. aux. sce financ. hs ass. retr. nca."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e6b257959ea0a82fcd42fbd74cff54147f695202",
    "fields": {
      "intitule_naf_65": "Services auxiliaires des transports terrestres",
      "intitule_naf": "Services auxiliaires des transports terrestres",
      "code_naf": "5221",
      "intitule_naf_40": "Sces auxiliaires de transport terrestre"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5ae6bb595295ec14c3d690747bb569014f318bf8",
    "fields": {
      "intitule_naf_65": "Transports maritimes et côtiers de passagers",
      "intitule_naf": "Transports maritimes et côtiers de passagers",
      "code_naf": "501",
      "intitule_naf_40": "Transport maritime & côtier de passagers"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "6747261bf36eba649a7fddd78b3e68c229fcaaae",
    "fields": {
      "intitule_naf_65": "Transports urbains et suburbains de voyageurs",
      "intitule_naf": "Transports urbains et suburbains de voyageurs",
      "code_naf": "4931Z",
      "intitule_naf_40": "Transport urbain & suburbain de voyageur"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ddd73afcd5c960eee514e2776dabb5ea6c690e70",
    "fields": {
      "intitule_naf_65": "Autres activités de poste et de courrier",
      "intitule_naf": "Autres activités de poste et de courrier",
      "code_naf": "532",
      "intitule_naf_40": "Autres activités de poste et de courrier"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e0f81d32b53c95e1831542d6db959b09952e9307",
    "fields": {
      "intitule_naf_65": "Administration de marchés financiers",
      "intitule_naf": "Administration de marchés financiers",
      "code_naf": "6611Z",
      "intitule_naf_40": "Administration de marchés financiers"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "770fd73e5b649d15308e334eab88ab8c14d3bdb8",
    "fields": {
      "intitule_naf_65": "Transports aériens de passagers",
      "intitule_naf": "Transports aériens de passagers",
      "code_naf": "5110Z",
      "intitule_naf_40": "Transports aériens de passagers"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "242915908d48804419dbae3db45ff8d5e394f6a4",
    "fields": {
      "intitule_naf_65": "Autres activités informatiques",
      "intitule_naf": "Autres activités informatiques",
      "code_naf": "6209",
      "intitule_naf_40": "Autres activités informatiques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "dfa448aa5dba702f7aa2b5a73537e543408cfff9",
    "fields": {
      "intitule_naf_65": "Programmation informatique",
      "intitule_naf": "Programmation informatique",
      "code_naf": "6201",
      "intitule_naf_40": "Programmation informatique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "bd77babf25978e9292302a93d1b2eb7a07e9f711",
    "fields": {
      "intitule_naf_65": "Portails Internet",
      "intitule_naf": "Portails Internet",
      "code_naf": "6312",
      "intitule_naf_40": "Portails Internet"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2c35b1890b901932a7ef65423c7be0a44b73a163",
    "fields": {
      "intitule_naf_65": "Activ. de recouvrement factures & d'info. financ. s/la clientèle",
      "intitule_naf": "Activités des agences de recouvrement de factures et des sociétés d'information financière sur la clientèle",
      "code_naf": "8291Z",
      "intitule_naf_40": "Act. recouv. fac. & info. fin. s/client."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d925f5e7e24e3fd16b8993289c2cda59878d6f97",
    "fields": {
      "intitule_naf_65": "Bibliothèques, archives, musées et autres activités culturelles",
      "intitule_naf": "Bibliothèques, archives, musées et autres activités culturelles",
      "code_naf": "91",
      "intitule_naf_40": "Biblioth. archive musée & aut. act. cul."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "89285d549d70d4f4b430bd7b950c0bdedd28ff0b",
    "fields": {
      "intitule_naf_65": "Location et location-bail d'articles de loisirs et de sport",
      "intitule_naf": "Location et location-bail d'articles de loisirs et de sport",
      "code_naf": "7721",
      "intitule_naf_40": "Loc. & loc.-bail article loisir & sport"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7abd52ce21afe532f20d043c0332d284363ba985",
    "fields": {
      "intitule_naf_65": "Activités des organisations politiques",
      "intitule_naf": "Activités des organisations politiques",
      "code_naf": "9492",
      "intitule_naf_40": "Activités des organisations politiques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e61b163a72907a13f54366a7b365cba1f881fe41",
    "fields": {
      "intitule_naf_65": "Activités des sièges sociaux",
      "intitule_naf": "Activités des sièges sociaux",
      "code_naf": "7010",
      "intitule_naf_40": "Activités des sièges sociaux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "269b5faf0209eb2cb8dbb7ec292feef6c3efa812",
    "fields": {
      "intitule_naf_65": "Entretien corporel",
      "intitule_naf": "Entretien corporel",
      "code_naf": "9604Z",
      "intitule_naf_40": "Entretien corporel"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8da0c52a09d3f3b56d45364a6ac94299635f6268",
    "fields": {
      "intitule_naf_65": "Traitement primaire des récoltes",
      "intitule_naf": "Traitement primaire des récoltes",
      "code_naf": "0163Z",
      "intitule_naf_40": "Traitement primaire des récoltes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9e5fd94cda869910064e677ee2218e9abfc6e763",
    "fields": {
      "intitule_naf_65": "Culture de plantes à boissons",
      "intitule_naf": "Culture de plantes à boissons",
      "code_naf": "127",
      "intitule_naf_40": "Culture de plantes à boissons"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2afd800ad88d4082800e15fb99db53a35ed1f93d",
    "fields": {
      "intitule_naf_65": "Exploitation forestière",
      "intitule_naf": "Exploitation forestière",
      "code_naf": "220",
      "intitule_naf_40": "Exploitation forestière"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8b5daf12dccbd06002de0a321408da034efe66e6",
    "fields": {
      "intitule_naf_65": "Reproduction de plantes",
      "intitule_naf": "Reproduction de plantes",
      "code_naf": "13",
      "intitule_naf_40": "Reproduction de plantes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5e719791220978adac5c862930aa734018c6e0ce",
    "fields": {
      "intitule_naf_65": "Culture du riz",
      "intitule_naf": "Culture du riz",
      "code_naf": "0112Z",
      "intitule_naf_40": "Culture du riz"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d6afaa1d5072f4815cfc87620363bdd5ad6e2208",
    "fields": {
      "intitule_naf_65": "Fabrication d'éléments matières plastiques pour la construction",
      "intitule_naf": "Fabrication d'éléments en matières plastiques pour la construction",
      "code_naf": "2223",
      "intitule_naf_40": "Fab. élément mat. plastiq. pr construct."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "442eab5c6bf4fec027c4c2ba4efef815dc201c89",
    "fields": {
      "intitule_naf_65": "Fabrication briques tuiles & prod. de construction en terre cuite",
      "intitule_naf": "Fabrication de briques, tuiles et produits de construction, en terre cuite",
      "code_naf": "2332Z",
      "intitule_naf_40": "Fab. produit  construct. en terre cuite"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b9d120fcae1f0c258fefbdfcedd2bc0ef57dac27",
    "fields": {
      "intitule_naf_65": "Fabrication & façonnage autres articles verre yc verre technique",
      "intitule_naf": "Fabrication et façonnage d'autres articles en verre, y compris verre technique",
      "code_naf": "2319Z",
      "intitule_naf_40": "Fab. & façonnage aut. article en verre"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "72da14b20e864fdfa3ea2845a823dbf6f88d04fc",
    "fields": {
      "intitule_naf_65": "Fabrication de charpentes et d'autres menuiseries",
      "intitule_naf": "Fabrication de charpentes et d'autres menuiseries",
      "code_naf": "1623",
      "intitule_naf_40": "Fab. charpentes et autres menuiseries"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "69e8418b411de964a90b59d9e0a3f3b8b25d0a42",
    "fields": {
      "intitule_naf_65": "Sciage et rabotage du bois, hors imprégnation",
      "intitule_naf": "Sciage et rabotage du bois, hors imprégnation",
      "code_naf": "1610A",
      "intitule_naf_40": "Sciage & rabotage bois, sf imprégnation"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5f5a4170e35142601e6b0864488b15809435851b",
    "fields": {
      "intitule_naf_65": "Fabrication de placage et de panneaux de bois",
      "intitule_naf": "Fabrication de placage et de panneaux de bois",
      "code_naf": "1621",
      "intitule_naf_40": "Fabric.  placage et panneaux de bois"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d22f21bbd4ad397098cafdffc9f1456992ee0899",
    "fields": {
      "intitule_naf_65": "Fabrication de caoutchouc synthétique",
      "intitule_naf": "Fabrication de caoutchouc synthétique",
      "code_naf": "2017Z",
      "intitule_naf_40": "Fabrication de caoutchouc synthétique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "04c9b4c9657de8cc78843a3e4fefe3208b2b0a11",
    "fields": {
      "intitule_naf_65": "Imprégnation du bois",
      "intitule_naf": "Imprégnation du bois",
      "code_naf": "1610B",
      "intitule_naf_40": "Imprégnation du bois"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "aa1877b7f9134d6384e9008121c4fa89954a624b",
    "fields": {
      "intitule_naf_65": "Imprimerie de journaux",
      "intitule_naf": "Imprimerie de journaux",
      "code_naf": "1811Z",
      "intitule_naf_40": "Imprimerie de journaux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "51c59d4149f0433cc215f82e95b8d1dad08bd530",
    "fields": {
      "intitule_naf_65": "Raffinage du pétrole",
      "intitule_naf": "Raffinage du pétrole",
      "code_naf": "1920Z",
      "intitule_naf_40": "Raffinage du pétrole"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e101897634c606ae3b8841e51d8b996b1e16df92",
    "fields": {
      "intitule_naf_65": "Fabrication de margarine et graisses comestibles similaires",
      "intitule_naf": "Fabrication de margarine et graisses comestibles similaires",
      "code_naf": "1042",
      "intitule_naf_40": "Fab. de margarine & graisses similaires"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "16347503618a83290b4e7fa58086bdcf5ed936bb",
    "fields": {
      "intitule_naf_65": "Activités de soutien à l'extraction d'hydrocarbures",
      "intitule_naf": "Activités de soutien à l'extraction d'hydrocarbures",
      "code_naf": "0910Z",
      "intitule_naf_40": "Act. de soutien à l'extr. hydrocarbures"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0ca1794fa8bb37737c1104d127e598abc0051089",
    "fields": {
      "intitule_naf_65": "Production de boissons alcooliques distillées",
      "intitule_naf": "Production de boissons alcooliques distillées",
      "code_naf": "1101",
      "intitule_naf_40": "Prod. de boissons alcooliques distillées"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ea1add379ae336ac89eaeea7a7ab1b65a6019844",
    "fields": {
      "intitule_naf_65": "Fabrication d'aliments pour animaux de compagnie",
      "intitule_naf": "Fabrication d'aliments pour animaux de compagnie",
      "code_naf": "1092",
      "intitule_naf_40": "Fab. aliments pour animaux de compagnie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "19c2a2676a5c160bfd4a2a7e792540bebe03f401",
    "fields": {
      "intitule_naf_65": "Autres activités extractives n.c.a.",
      "intitule_naf": "Autres activités extractives n.c.a.",
      "code_naf": "899",
      "intitule_naf_40": "Autres activités extractives n.c.a."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "feb9cc4285a7496d1ee3405bd3a05b3028b08b25",
    "fields": {
      "intitule_naf_65": "Fabrication de vêtements de dessus",
      "intitule_naf": "Fabrication de vêtements de dessus",
      "code_naf": "1413Z",
      "intitule_naf_40": "Fabrication de vêtements de dessus"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1ce01edcdedb59e2ee47d7128ab205063e64051e",
    "fields": {
      "intitule_naf_65": "Fabrication d'articles en fourrure",
      "intitule_naf": "Fabrication d'articles en fourrure",
      "code_naf": "1420",
      "intitule_naf_40": "Fabrication d'articles en fourrure"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "309e457ba5e4b06498589dc4b1bbf846b0813b47",
    "fields": {
      "intitule_naf_65": "Fabrication de glaces et sorbets",
      "intitule_naf": "Fabrication de glaces et sorbets",
      "code_naf": "1052",
      "intitule_naf_40": "Fabrication de glaces et sorbets"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0cad9923cb38ad6a6b7befed768fd088eeaa15b1",
    "fields": {
      "intitule_naf_65": "Fabrication de plats préparés",
      "intitule_naf": "Fabrication de plats préparés",
      "code_naf": "1085Z",
      "intitule_naf_40": "Fabrication de plats préparés"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "699ab02d5f66328130eaeeaf6ec6b99a3f6def76",
    "fields": {
      "intitule_naf_65": "Pêche en mer",
      "intitule_naf": "Pêche en mer",
      "code_naf": "311",
      "intitule_naf_40": "Pêche en mer"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ede506d3ac3e70b891bd22cff43bf6c4529833f6",
    "fields": {
      "intitule_naf_65": "Fabrication équipements aérauliques et frigorifiques industriels",
      "intitule_naf": "Fabrication d'équipements aérauliques et frigorifiques industriels",
      "code_naf": "2825",
      "intitule_naf_40": "Fab. équipt aérauliq. & frigorifiq. ind."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "6a357e148aaadef2b973f63beac7297992c43190",
    "fields": {
      "intitule_naf_65": "Fabrication d'éléments en plâtre pour la construction",
      "intitule_naf": "Fabrication d'éléments en plâtre pour la construction",
      "code_naf": "2362",
      "intitule_naf_40": "Fab. élément en plâtre pour la construc."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "986a649a5e459e36742f11a6a24e633c16e35a39",
    "fields": {
      "intitule_naf_65": "Fabrication d'ordinateurs et d'équipements périphériques",
      "intitule_naf": "Fabrication d'ordinateurs et d'équipements périphériques",
      "code_naf": "2620",
      "intitule_naf_40": "Fab. ordinateur & équipement périphériq."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "63c1e589c3c0acbe010230b89f61781c8948ed6c",
    "fields": {
      "intitule_naf_65": "Fabrication de matériel d'installation électrique",
      "intitule_naf": "Fabrication de matériel d'installation électrique",
      "code_naf": "2733Z",
      "intitule_naf_40": "Fabric. matériel installation électrique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1c836b46aab367fb2da6c44c9b8cc6d85bbc48cd",
    "fields": {
      "intitule_naf_65": "Fabrication d'emballages métalliques légers",
      "intitule_naf": "Fabrication d'emballages métalliques légers",
      "code_naf": "2592Z",
      "intitule_naf_40": "Fabric. d'emballages métalliques légers"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0cb1e95812280eaa5e276f2771bec821ae8d2b55",
    "fields": {
      "intitule_naf_65": "Fabrication de meubles de cuisine",
      "intitule_naf": "Fabrication de meubles de cuisine",
      "code_naf": "3102",
      "intitule_naf_40": "Fabrication de meubles de cuisine"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "097fc499e966bf4330dd4aca330abd48da24a3ca",
    "fields": {
      "intitule_naf_65": "Fabrication de produits abrasifs",
      "intitule_naf": "Fabrication de produits abrasifs",
      "code_naf": "2391",
      "intitule_naf_40": "Fabrication de produits abrasifs"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "09624580e5fe2c91d50cc0b592ec28a3b674885a",
    "fields": {
      "intitule_naf_65": "Hébergement touristique et autre hébergement de courte durée",
      "intitule_naf": "Hébergement touristique et autre hébergement de courte durée",
      "code_naf": "5520Z",
      "intitule_naf_40": "Hébergt tourist. & aut. hbt courte durée"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f9fb3bed068db57ba3a493d2e58948ce0e970431",
    "fields": {
      "intitule_naf_65": "Fonds de placement et entités financières similaires",
      "intitule_naf": "Fonds de placement et entités financières similaires",
      "code_naf": "6430Z",
      "intitule_naf_40": "Fonds placement & entité financ. simil."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1fb36098e4ba08eaf6c238e99871eb62126165c9",
    "fields": {
      "intitule_naf_65": "Supports juridiques de gestion de patrimoine mobilier",
      "intitule_naf": "Supports juridiques de gestion de patrimoine mobilier",
      "code_naf": "6619A",
      "intitule_naf_40": "Support juridiq. gest. patrimoine mobil."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "83973bfac35f79a3419ca7de40eed5fc0f2b3d74",
    "fields": {
      "intitule_naf_65": "Entreposage et services auxiliaires des transports",
      "intitule_naf": "Entreposage et services auxiliaires des transports",
      "code_naf": "52",
      "intitule_naf_40": "Entreposage & sce auxiliaire des transp."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f5d63226c79d32121dfb46a99bfc4cd72dd1c6bd",
    "fields": {
      "intitule_naf_65": "Édition de logiciels système et de réseau",
      "intitule_naf": "Édition de logiciels système et de réseau",
      "code_naf": "5829A",
      "intitule_naf_40": "Édition de logiciel système et de réseau"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1aef8092404e1a685cdc96908be412e5042f2e76",
    "fields": {
      "intitule_naf_65": "Courtage de valeurs mobilières et de marchandises",
      "intitule_naf": "Courtage de valeurs mobilières et de marchandises",
      "code_naf": "6612",
      "intitule_naf_40": "Courtage valeur mobilière & marchandise"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8a917d3ae9b815709a10818a844f03710a25c7e6",
    "fields": {
      "intitule_naf_65": "Analyses, essais et inspections techniques",
      "intitule_naf": "Analyses, essais et inspections techniques",
      "code_naf": "7120B",
      "intitule_naf_40": "Analyses, essais & inspection technique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "10402f11d16b85434bf01b5adfb2487bb6a3065e",
    "fields": {
      "intitule_naf_65": "Edition de chaînes généralistes",
      "intitule_naf": "Edition de chaînes généralistes",
      "code_naf": "6020A",
      "intitule_naf_40": "Edition de chaînes généralistes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c8f23d837f9374fefb2b6b34d4b584ad096da427",
    "fields": {
      "intitule_naf_65": "Édition de logiciels",
      "intitule_naf": "Édition de logiciels",
      "code_naf": "582",
      "intitule_naf_40": "Édition de logiciels"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b22233e4cba74291f159f7aae81b577517cd4fa0",
    "fields": {
      "intitule_naf_65": "Autres assurances",
      "intitule_naf": "Autres assurances",
      "code_naf": "6512Z",
      "intitule_naf_40": "Autres assurances"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "39ce0b09e3c8bd38c1b672eda94e920349212523",
    "fields": {
      "intitule_naf_65": "Activ. ménages : prod. biens & services (usage propre)",
      "intitule_naf": "Activités indifférenciées des ménages en tant que producteurs de biens et services pour usage propre",
      "code_naf": "98",
      "intitule_naf_40": "Act. ménage : prod. b.&s. (usage propre)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c150ed253fec4447b2be0b7126ecd0fa4aab4af8",
    "fields": {
      "intitule_naf_65": "Location de courte durée voitures & véhicules auto. légers",
      "intitule_naf": "Location de courte durée de voitures et de véhicules automobiles légers",
      "code_naf": "7711A",
      "intitule_naf_40": "Loc. courte durée voit. & v. auto. léger"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5e666e31ea22cd021f23531f34bd1fd0e0575702",
    "fields": {
      "intitule_naf_65": "Activités des organisations et organismes extraterritoriaux",
      "intitule_naf": "Activités des organisations et organismes extraterritoriaux",
      "code_naf": "9900",
      "intitule_naf_40": "Act. organisations extraterritoriales"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "866ef9803817d5caf76f9bba288b9560bc391917",
    "fields": {
      "intitule_naf_65": "Location de vidéocassettes et disques vidéo",
      "intitule_naf": "Location de vidéocassettes et disques vidéo",
      "code_naf": "7722Z",
      "intitule_naf_40": "Location de vidéocassette & disque vidéo"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "496723cf1dc6cb7dd7826f96dd9fa232b4034bae",
    "fields": {
      "intitule_naf_65": "Organisation de salons professionnels et congrès",
      "intitule_naf": "Organisation de salons professionnels et congrès",
      "code_naf": "823",
      "intitule_naf_40": "Organisation salon profession. & congrès"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "59ac4c27c13c7d5746e139ae478969687c580b21",
    "fields": {
      "intitule_naf_65": "Études de marché et sondages",
      "intitule_naf": "Études de marché et sondages",
      "code_naf": "7320",
      "intitule_naf_40": "Études de marché et sondages"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "49ef2d9cfa87f3e6ab88444616efe21de23a20bf",
    "fields": {
      "intitule_naf_65": "Activités photographiques",
      "intitule_naf": "Activités photographiques",
      "code_naf": "7420Z",
      "intitule_naf_40": "Activités photographiques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5e5e1b386ecebd27689b0f6feeda7e173ba72c39",
    "fields": {
      "intitule_naf_65": "Enseignement culturel",
      "intitule_naf": "Enseignement culturel",
      "code_naf": "8552Z",
      "intitule_naf_40": "Enseignement culturel"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2c5b9e0f67089ec8637f8f2f32129ca3d80499b0",
    "fields": {
      "intitule_naf_65": "Fabrication d'articles de joaillerie et bijouterie",
      "intitule_naf": "Fabrication d'articles de joaillerie et bijouterie",
      "code_naf": "3212Z",
      "intitule_naf_40": "Fab. article de joaillerie et bijouterie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "cdea78afe7e91a92cc9a6776fd4c68336befa234",
    "fields": {
      "intitule_naf_65": "Commerce de combustibles gazeux par conduites",
      "intitule_naf": "Commerce de combustibles gazeux par conduites",
      "code_naf": "3523Z",
      "intitule_naf_40": "Commerce combustible gazeux par conduite"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9654cad53bf66fc2a9f3850a54bd8af7c7a2bb71",
    "fields": {
      "intitule_naf_65": "Commerce et réparation de motocycles",
      "intitule_naf": "Commerce et réparation de motocycles",
      "code_naf": "454",
      "intitule_naf_40": "Commerce et réparation de motocycles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ff90375e7db437e49f562c84c3cdd4fa6d3dc013",
    "fields": {
      "intitule_naf_65": "Fabrication d'articles de brosserie",
      "intitule_naf": "Fabrication d'articles de brosserie",
      "code_naf": "3291Z",
      "intitule_naf_40": "Fabrication d'articles de brosserie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "84d21a02d47aee950f3bd0d074959fc487c7df7d",
    "fields": {
      "intitule_naf_65": "Collecte des déchets non dangereux",
      "intitule_naf": "Collecte des déchets non dangereux",
      "code_naf": "3811Z",
      "intitule_naf_40": "Collecte des déchets non dangereux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "88e55f0e9c6506ba4c661171d5230066ca3251bb",
    "fields": {
      "intitule_naf_65": "Collecte des déchets non dangereux",
      "intitule_naf": "Collecte des déchets non dangereux",
      "code_naf": "3811",
      "intitule_naf_40": "Collecte des déchets non dangereux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c8915d865972c0c6f30d11689a240f912b7c8df4",
    "fields": {
      "intitule_naf_65": "Promotion immobilière de bureaux",
      "intitule_naf": "Promotion immobilière de bureaux",
      "code_naf": "4110B",
      "intitule_naf_40": "Promotion immobilière de bureaux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "70e08bb7c2495f251f8ae2e07321a7393675b60c",
    "fields": {
      "intitule_naf_65": "Fabrication d'articles de sport",
      "intitule_naf": "Fabrication d'articles de sport",
      "code_naf": "3230",
      "intitule_naf_40": "Fabrication d'articles de sport"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "6eca375420ec6fb7ca04385ced6ad19241683df2",
    "fields": {
      "intitule_naf_65": "Autres travaux de finition",
      "intitule_naf": "Autres travaux de finition",
      "code_naf": "4339",
      "intitule_naf_40": "Autres travaux de finition"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "41a468a2a1c69026f8b408b74b2aae4b065e8e24",
    "fields": {
      "intitule_naf_65": "Promotion immobilière",
      "intitule_naf": "Promotion immobilière",
      "code_naf": "411",
      "intitule_naf_40": "Promotion immobilière"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a3ae57173718f1f3cd50c38084d0776e4f7c5357",
    "fields": {
      "intitule_naf_65": "Comm. de gros céréales, tabac non manuf. et aliments pour bétail",
      "intitule_naf": "Commerce de gros de céréales, de tabac non manufacturé, de semences et d'aliments pour le bétail",
      "code_naf": "4621",
      "intitule_naf_40": "Com gros céréal. tab. brt & alim. bétail"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4a4ec20730c53f7f92d077c481a730413c0fd903",
    "fields": {
      "intitule_naf_65": "Comm de gros de machines pour l'extrac, la constr, le génie civil",
      "intitule_naf": "Commerce de gros (commerce interentreprises) de machines pour l'extraction, la construction et le génie civil",
      "code_naf": "4663Z",
      "intitule_naf_40": "Com. gros machine pr extrac., constr. GC"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "316b676de26b80d1b1080d7e75feccf67abca9e5",
    "fields": {
      "intitule_naf_65": "Commerce de gros de produits à base de tabac",
      "intitule_naf": "Commerce de gros (commerce interentreprises) de produits à base de tabac",
      "code_naf": "4635Z",
      "intitule_naf_40": "Comm. gros de produits à base de tabac"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4c4bf2e535d60331df74694d7e03bbe975ef7ec5",
    "fields": {
      "intitule_naf_65": "Commerce de gros de sucre, chocolat et confiserie",
      "intitule_naf": "Commerce de gros de sucre, chocolat et confiserie",
      "code_naf": "4636",
      "intitule_naf_40": "Com. gros de sucre chocolat & confiserie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8a32047a189ef2e7579d2a7cabba6c9731677972",
    "fields": {
      "intitule_naf_65": "Commerce de gros (commerce interentreprises) de machines-outils",
      "intitule_naf": "Commerce de gros (commerce interentreprises) de machines-outils",
      "code_naf": "4662Z",
      "intitule_naf_40": "Commerce de gros de machines-outils"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "954a2dd72221b4b34f67e5f088842c9665da0412",
    "fields": {
      "intitule_naf_65": "Commerce de gros de produits chimiques",
      "intitule_naf": "Commerce de gros (commerce interentreprises) de produits chimiques",
      "code_naf": "4675Z",
      "intitule_naf_40": "Commerce de gros de produits chimiques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "668963b77b279a226fe55d3dede5b49a564fbff9",
    "fields": {
      "intitule_naf_65": "Commerce de gros de minerais et métaux",
      "intitule_naf": "Commerce de gros de minerais et métaux",
      "code_naf": "4672",
      "intitule_naf_40": "Commerce de gros de minerais et métaux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3871604363240e7dcd3a1a3154276e925e93d0bf",
    "fields": {
      "intitule_naf_65": "Commerce et réparation de motocycles",
      "intitule_naf": "Commerce et réparation de motocycles",
      "code_naf": "4540",
      "intitule_naf_40": "Commerce et réparation de motocycles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f41f727caab690f62be91233831c0e77b5f34152",
    "fields": {
      "intitule_naf_65": "Commerce de gros (commerce interentreprises) de boissons",
      "intitule_naf": "Commerce de gros (commerce interentreprises) de boissons",
      "code_naf": "4634Z",
      "intitule_naf_40": "Commerce de gros  de boissons"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4366b66924f919d1c9ed81ff6cfbeef239377622",
    "fields": {
      "intitule_naf_65": "Comm. dét. fleurs, plantes, etc, animaux de cie et leurs aliments",
      "intitule_naf": "Commerce de détail de fleurs, plantes, graines, engrais, animaux de compagnie et aliments pour ces animaux en magasin spécialisé",
      "code_naf": "4776Z",
      "intitule_naf_40": "Com. dét. fleur plante anim. cie + alim."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7978717bd1a462bb289b9127329378e9c9248b57",
    "fields": {
      "intitule_naf_65": "Comm. détail ordinateurs unités périph. & logiciels (magas. spéc)",
      "intitule_naf": "Commerce de détail d'ordinateurs, d'unités périphériques et de logiciels en magasin spécialisé",
      "code_naf": "4741",
      "intitule_naf_40": "Com. dét ordi. un. périph. & logicl (ms)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7361fb674801602a89049f51633e25a4a1213bf5",
    "fields": {
      "intitule_naf_65": "Comm. détail de parfumerie & produits de beauté en magasin spéc.",
      "intitule_naf": "Commerce de détail de parfumerie et de produits de beauté en magasin spécialisé",
      "code_naf": "4775",
      "intitule_naf_40": "Com. dét. parfumerie & prodt beauté (ms)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ae7a9cd1acfd1b8d8399779361cf34a0d5702da8",
    "fields": {
      "intitule_naf_65": "Comm. détail viandes & produits à base de viande (magas. spéc.)",
      "intitule_naf": "Commerce de détail de viandes et de produits à base de viande en magasin spécialisé",
      "code_naf": "4722Z",
      "intitule_naf_40": "Com. dét. viande & prdt avec viande (ms)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "301b5d4bd72939c2b679d0ccf1ca4034eab226b0",
    "fields": {
      "intitule_naf_65": "Comm. détail matériels télécommunication (magasin spécialisé)",
      "intitule_naf": "Commerce de détail de matériels de télécommunication en magasin spécialisé",
      "code_naf": "4742Z",
      "intitule_naf_40": "Comm. dét. matériel télécom. (ms)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ccc4b15261f5b93899d12cd8a3d38b4805423e7d",
    "fields": {
      "intitule_naf_65": "Transports urbains et suburbains de voyageurs",
      "intitule_naf": "Transports urbains et suburbains de voyageurs",
      "code_naf": "4931",
      "intitule_naf_40": "Transport urbain & suburbain de voyageur"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1d699cb5343b2a32bb2a898c316a4c3d804ccba8",
    "fields": {
      "intitule_naf_65": "Commerce de détail de meubles",
      "intitule_naf": "Commerce de détail de meubles",
      "code_naf": "4759A",
      "intitule_naf_40": "Commerce de détail de meubles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0a7afcd4e9fd386c3a1e915f2fdc647afcb848dc",
    "fields": {
      "intitule_naf_65": "Transports aériens",
      "intitule_naf": "Transports aériens",
      "code_naf": "51",
      "intitule_naf_40": "Transports aériens"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "127eef92f78934be342a666f5d9ac92bbf74ca0d",
    "fields": {
      "intitule_naf_65": "Activités de soutien aux autres industries extractives",
      "intitule_naf": "Activités de soutien aux autres industries extractives",
      "code_naf": "990",
      "intitule_naf_40": "Act. de soutien aut. indus. extractives"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8f0c94e534fe94a0f8e0df9fa3e105f168586141",
    "fields": {
      "intitule_naf_65": "Reproduction de plantes",
      "intitule_naf": "Reproduction de plantes",
      "code_naf": "0130Z",
      "intitule_naf_40": "Reproduction de plantes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "13febe745cdb91bd2b815b4fc00cb10d7a88a0cc",
    "fields": {
      "intitule_naf_65": "Apprêt, tannage des cuirs; préparation et teinture des fourrures",
      "intitule_naf": "Apprêt et tannage des cuirs ; préparation et teinture des fourrures",
      "code_naf": "1511Z",
      "intitule_naf_40": "Prépa. cuirs; prép. & teinture fourrures"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "877d05287c59b5e5b704351268d6fccf83dd4a81",
    "fields": {
      "intitule_naf_65": "Fabrication de portes et fenêtres en métal",
      "intitule_naf": "Fabrication de portes et fenêtres en métal",
      "code_naf": "2512Z",
      "intitule_naf_40": "Fabric. de portes et fenêtres en métal"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a909adc4b9f292c232b520f3ebd87b0abd5a5bca",
    "fields": {
      "intitule_naf_65": "Fabrication de béton prêt à l'emploi",
      "intitule_naf": "Fabrication de béton prêt à l'emploi",
      "code_naf": "2363",
      "intitule_naf_40": "Fabrication de béton prêt à l'emploi"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "83c8a50ecc5d119bf76710a318948d8763a8db26",
    "fields": {
      "intitule_naf_65": "Métallurgie du plomb, du zinc ou de l'étain",
      "intitule_naf": "Métallurgie du plomb, du zinc ou de l'étain",
      "code_naf": "2443",
      "intitule_naf_40": "Métallurgie du Pb, du Zn ou du Sn"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f0d98fd90ba019b372df8d746047b916ca6c05ee",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres textiles n.c.a.",
      "intitule_naf": "Fabrication d'autres textiles n.c.a.",
      "code_naf": "1399Z",
      "intitule_naf_40": "Fabrication d'autres textiles n.c.a."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f31a574cfc6b8e39f6c62957b738370699218048",
    "fields": {
      "intitule_naf_65": "Fabrication de produits amylacés",
      "intitule_naf": "Fabrication de produits amylacés",
      "code_naf": "1062",
      "intitule_naf_40": "Fabrication de produits amylacés"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9fadff87ebfd7f896ca8c0eef3faf900d0a6f1b9",
    "fields": {
      "intitule_naf_65": "Métallurgie du cuivre",
      "intitule_naf": "Métallurgie du cuivre",
      "code_naf": "2444",
      "intitule_naf_40": "Métallurgie du cuivre"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2b0b2bca4e7f0fd0e284a260867f0ea0420df886",
    "fields": {
      "intitule_naf_65": "Fabrication de sucre",
      "intitule_naf": "Fabrication de sucre",
      "code_naf": "1081Z",
      "intitule_naf_40": "Fabrication de sucre"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1cac429eb1ca5e6920bd70173fa5346881bbec5d",
    "fields": {
      "intitule_naf_65": "Décolletage",
      "intitule_naf": "Décolletage",
      "code_naf": "2562A",
      "intitule_naf_40": "Décolletage"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0e2b51d87f3967876c721d235885c4769046f5f7",
    "fields": {
      "intitule_naf_65": "Interm. comm. en meubles, articles de ménage et quincaillerie",
      "intitule_naf": "Intermédiaires du commerce en meubles, articles de ménage et quincaillerie",
      "code_naf": "4615",
      "intitule_naf_40": "Int. comm. meuble, art. ménage & quinc."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "162a501368336734a0692a2ebf8bf4f9730670aa",
    "fields": {
      "intitule_naf_65": "Installation de machines et équipements mécaniques",
      "intitule_naf": "Installation de machines et équipements mécaniques",
      "code_naf": "3320B",
      "intitule_naf_40": "Instal. machines & équipement mécanique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8b8874f530bf3ff418e01bb60f0bff9987df6f8c",
    "fields": {
      "intitule_naf_65": "Construction de navires et de structures flottantes",
      "intitule_naf": "Construction de navires et de structures flottantes",
      "code_naf": "3011",
      "intitule_naf_40": "Construct. navires & structure flottante"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4e815267b95c716ba0c6d0a6ce625ec8266a3cde",
    "fields": {
      "intitule_naf_65": "Commerce de gros d'équipements automobiles",
      "intitule_naf": "Commerce de gros d'équipements automobiles",
      "code_naf": "4531Z",
      "intitule_naf_40": "Commerce de gros d'équipement automobile"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "6122e148c0011816faf9047e8a4f89db907652d3",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres équipements automobiles",
      "intitule_naf": "Fabrication d'autres équipements automobiles",
      "code_naf": "2932",
      "intitule_naf_40": "Fabric. d'autres équipements automobiles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "245f43886a86e01e61c2d411ebb652711dacd3e0",
    "fields": {
      "intitule_naf_65": "Fabrication de meubles de bureau et de magasin",
      "intitule_naf": "Fabrication de meubles de bureau et de magasin",
      "code_naf": "3101",
      "intitule_naf_40": "Fab. de meubles de bureau et de magasin"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "44de7bd60768222f45373a3edfc0ed0c75ac94b1",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres matériels de transport",
      "intitule_naf": "Fabrication d'autres matériels de transport",
      "code_naf": "30",
      "intitule_naf_40": "Fabric. d'autres matériels de transport"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "811c8f0e4d2f95a1bde402f442eb87f31c64ff4c",
    "fields": {
      "intitule_naf_65": "Travaux de construction spécialisés",
      "intitule_naf": "Travaux de construction spécialisés",
      "code_naf": "43",
      "intitule_naf_40": "Travaux de construction spécialisés"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "17766681196e23162d623e1ac365122ccff8f295",
    "fields": {
      "intitule_naf_65": "Réparation d'autres équipements",
      "intitule_naf": "Réparation d'autres équipements",
      "code_naf": "3319Z",
      "intitule_naf_40": "Réparation d'autres équipements"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2be0aea506dd1a6a991175904bea7c37314060e1",
    "fields": {
      "intitule_naf_65": "Récupération",
      "intitule_naf": "Récupération",
      "code_naf": "383",
      "intitule_naf_40": "Récupération"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b6998731428c3faa95e040b06c4d4ce59ab98ee6",
    "fields": {
      "intitule_naf_65": "Fabric. instruments & d'appareils mesure, essai & de navigation",
      "intitule_naf": "Fabrication d'instruments et d'appareils de mesure, d'essai et de navigation",
      "code_naf": "2651",
      "intitule_naf_40": "Fab. instrum. de mesure & de navigation"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e8f01db0607ef19cff8900fd4cd57029dfd3811b",
    "fields": {
      "intitule_naf_65": "Fabrication de coutellerie, d'outillage et de quincaillerie",
      "intitule_naf": "Fabrication de coutellerie, d'outillage et de quincaillerie",
      "code_naf": "257",
      "intitule_naf_40": "Fab. coutellerie, outillage & quincaill."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3ec396eef310216f6ce804e81220d25498f92eaf",
    "fields": {
      "intitule_naf_65": "Fabrication de matériel de levage et de manutention",
      "intitule_naf": "Fabrication de matériel de levage et de manutention",
      "code_naf": "2822Z",
      "intitule_naf_40": "Fab. matériel de levage & de manutention"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d9d4bd546042336d89101729186a147aa2f79ea0",
    "fields": {
      "intitule_naf_65": "Fabrication d'appareils d'éclairage électrique",
      "intitule_naf": "Fabrication d'appareils d'éclairage électrique",
      "code_naf": "274",
      "intitule_naf_40": "Fabric. appareils d'éclairage électrique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "629b3b7d7f4f688e36acff6462b889d20ffa3f9a",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres machines d'usage général",
      "intitule_naf": "Fabrication d'autres machines d'usage général",
      "code_naf": "282",
      "intitule_naf_40": "Fabric. autres machines d'usage général"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "88c5ae2b3103619a1b0bb0741b8363f616cf1094",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres matériels électriques",
      "intitule_naf": "Fabrication d'autres matériels électriques",
      "code_naf": "279",
      "intitule_naf_40": "Fabric. d'autres matériels électriques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "aee1af5162ca740c9d8e3512927d7ff2093ae996",
    "fields": {
      "intitule_naf_65": "Fabrication de machines d'usage général",
      "intitule_naf": "Fabrication de machines d'usage général",
      "code_naf": "281",
      "intitule_naf_40": "Fabrication de machines d'usage général"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3a3778dc177543bc4cd3c32384de8716a3a0961d",
    "fields": {
      "intitule_naf_65": "Construction de véhicules automobiles",
      "intitule_naf": "Construction de véhicules automobiles",
      "code_naf": "291",
      "intitule_naf_40": "Construction de véhicules automobiles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "985b2909de7564e650bd4ea51a7be0ac5ac0b8d2",
    "fields": {
      "intitule_naf_65": "Fabrication de vis et de boulons",
      "intitule_naf": "Fabrication de vis et de boulons",
      "code_naf": "2594",
      "intitule_naf_40": "Fabrication de vis et de boulons"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "49c0051ed76f692570db6ec75deb06f2a3b261aa",
    "fields": {
      "intitule_naf_65": "Horlogerie",
      "intitule_naf": "Horlogerie",
      "code_naf": "2652",
      "intitule_naf_40": "Horlogerie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5e35f4b74dde9bb5b3d0b5a3bbde7d8c7be32fed",
    "fields": {
      "intitule_naf_65": "Autre mise à disposition de ressources humaines",
      "intitule_naf": "Autre mise à disposition de ressources humaines",
      "code_naf": "783",
      "intitule_naf_40": "Aut. mise à dispo. de ressource humaine"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5a03f65d314b61dadf79247b825664c25950c9de",
    "fields": {
      "intitule_naf_65": "Location et location-bail de camions",
      "intitule_naf": "Location et location-bail de camions",
      "code_naf": "7712Z",
      "intitule_naf_40": "Location et location-bail de camions"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7bb5409d8dac1c41dd5aa1a27e23737cb0b6947e",
    "fields": {
      "intitule_naf_65": "Publicité et études de marché",
      "intitule_naf": "Publicité et études de marché",
      "code_naf": "73",
      "intitule_naf_40": "Publicité et études de marché"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f55405a5787276328aa27bf1d00e8882113d51ee",
    "fields": {
      "intitule_naf_65": "Activités vétérinaires",
      "intitule_naf": "Activités vétérinaires",
      "code_naf": "75",
      "intitule_naf_40": "Activités vétérinaires"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9b009dc77ba3877d14e5963b756ab4879cbbd4e8",
    "fields": {
      "intitule_naf_65": "Caisses de retraite",
      "intitule_naf": "Caisses de retraite",
      "code_naf": "6530Z",
      "intitule_naf_40": "Caisses de retraite"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a853f3b347557dadcfa6616e904c18e5209e8dac",
    "fields": {
      "intitule_naf_65": "Réassurance",
      "intitule_naf": "Réassurance",
      "code_naf": "6520",
      "intitule_naf_40": "Réassurance"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5c8bc5c59365fc8a88f3b8364bd25017494d9b49",
    "fields": {
      "intitule_naf_65": "Publicité",
      "intitule_naf": "Publicité",
      "code_naf": "731",
      "intitule_naf_40": "Publicité"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "da46ba734dbdbe4102833fa57feee0994053c75f",
    "fields": {
      "intitule_naf_65": "Vente par automate, aut. com. dét. hors mag., éventaire ou marché",
      "intitule_naf": "Vente par automates et autres commerces de détail hors magasin, éventaires ou marchés n.c.a.",
      "code_naf": "4799B",
      "intitule_naf_40": "Vente par automate, aut. cd hors magasin"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1816632df0d33a2aee8f7b8ad492b7f3ef0cde2b",
    "fields": {
      "intitule_naf_65": "Transports maritimes et côtiers de passagers",
      "intitule_naf": "Transports maritimes et côtiers de passagers",
      "code_naf": "5010Z",
      "intitule_naf_40": "Transport maritime & côtier de passagers"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a04bd59aec7815faf37b162f6ba06206320fc56b",
    "fields": {
      "intitule_naf_65": "Transports terrestres et transport par conduites",
      "intitule_naf": "Transports terrestres et transport par conduites",
      "code_naf": "49",
      "intitule_naf_40": "Transport terrest. & trans. par conduite"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7e19670cba003eadfbafe4fd4cb71fd2983991d9",
    "fields": {
      "intitule_naf_65": "Enregistrement sonore et édition musicale",
      "intitule_naf": "Enregistrement sonore et édition musicale",
      "code_naf": "5920",
      "intitule_naf_40": "Enregistrement sonore & édition musicale"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "47b395e1f55ba06d2c95c4c5d5ccfe6aefad201b",
    "fields": {
      "intitule_naf_65": "Enregistrement sonore et édition musicale",
      "intitule_naf": "Enregistrement sonore et édition musicale",
      "code_naf": "592",
      "intitule_naf_40": "Enregistrement sonore & édition musicale"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5ce19386645d88baa204e49a74e892e344dbf950",
    "fields": {
      "intitule_naf_65": "Commerce de gros de biens domestiques",
      "intitule_naf": "Commerce de gros de biens domestiques",
      "code_naf": "464",
      "intitule_naf_40": "Commerce de gros de biens domestiques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "6e3a083a03df63e388f82a099f511bf3d160c18b",
    "fields": {
      "intitule_naf_65": "Autres activités d'édition",
      "intitule_naf": "Autres activités d'édition",
      "code_naf": "5819",
      "intitule_naf_40": "Autres activités d'édition"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "edb5191e9c1163a0153820264a21c5974599eceb",
    "fields": {
      "intitule_naf_65": "Transports par conduites",
      "intitule_naf": "Transports par conduites",
      "code_naf": "495",
      "intitule_naf_40": "Transports par conduites"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c332bc882b45942fe43609cec4b719f2828a79d7",
    "fields": {
      "intitule_naf_65": "Services d'information",
      "intitule_naf": "Services d'information",
      "code_naf": "63",
      "intitule_naf_40": "Services d'information"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a062bdd23f1afddd4162274a8d46eae66a9a074a",
    "fields": {
      "intitule_naf_65": "Hébergement",
      "intitule_naf": "Hébergement",
      "code_naf": "55",
      "intitule_naf_40": "Hébergement"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "47574f1bc3e26e54ecb8b3245dcd72fb6e9f7b34",
    "fields": {
      "intitule_naf_65": "Hébergement social pour adultes, familles en difficultés et autre",
      "intitule_naf": "Hébergement social pour adultes et familles en difficultés et autre hébergement social",
      "code_naf": "8790B",
      "intitule_naf_40": "Hébgt soc. adult., famille en difficulté"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9e549d96dea15a277877b5d570b8dcb982efd078",
    "fields": {
      "intitule_naf_65": "Autres activités nettoyage des bâtiments et nettoyage industriel",
      "intitule_naf": "Autres activités de nettoyage des bâtiments et nettoyage industriel",
      "code_naf": "8122Z",
      "intitule_naf_40": "Aut. act. nettoyage bâtim. & nett. ind."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b30f0be694b9dd0c550a293376b1ad601a8b8083",
    "fields": {
      "intitule_naf_65": "Activités des agences de voyage et voyagistes",
      "intitule_naf": "Activités des agences de voyage et voyagistes",
      "code_naf": "791",
      "intitule_naf_40": "Activités agences de voyage & voyagistes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9923cded4785ebb29a587de23d16c18eee449f1e",
    "fields": {
      "intitule_naf_65": "Activités des agences de voyage",
      "intitule_naf": "Activités des agences de voyage",
      "code_naf": "7911Z",
      "intitule_naf_40": "Activités des agences de voyage"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4f06b7266327bd46d5445a97f50278f44666ff25",
    "fields": {
      "intitule_naf_65": "Autres activités d'enseignement",
      "intitule_naf": "Autres activités d'enseignement",
      "code_naf": "855",
      "intitule_naf_40": "Autres activités d'enseignement"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "6e08718c35d0292be545502495165bb344c3dfe0",
    "fields": {
      "intitule_naf_65": "Services d'aménagement paysager",
      "intitule_naf": "Services d'aménagement paysager",
      "code_naf": "813",
      "intitule_naf_40": "Services d'aménagement paysager"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "576b621cea00f3601c33d32352a6396a5594ff1e",
    "fields": {
      "intitule_naf_65": "Sécurité sociale obligatoire",
      "intitule_naf": "Sécurité sociale obligatoire",
      "code_naf": "8430",
      "intitule_naf_40": "Sécurité sociale obligatoire"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5696d19107f1043a1d3e0d3c8a405b535a02063b",
    "fields": {
      "intitule_naf_65": "Sécurité sociale obligatoire",
      "intitule_naf": "Sécurité sociale obligatoire",
      "code_naf": "843",
      "intitule_naf_40": "Sécurité sociale obligatoire"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5096c2742ad5403a16d3492a0c48f600ee8aadb2",
    "fields": {
      "intitule_naf_65": "Gestion sites monuments historiques & attractions tourist. simil.",
      "intitule_naf": "Gestion des sites et monuments historiques et des attractions touristiques similaires",
      "code_naf": "9103Z",
      "intitule_naf_40": "Gestion site histor. & attraction simil."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c95a5d029016bbf7e773755017c376704cfe7669",
    "fields": {
      "intitule_naf_65": "Autres organisations fonctionnant par adhésion volontaire",
      "intitule_naf": "Autres organisations fonctionnant par adhésion volontaire",
      "code_naf": "9499Z",
      "intitule_naf_40": "Aut. org. fonctionnant par adhé. volont."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "13104d5a76c9b459f4796bd253c42d73d08f6150",
    "fields": {
      "intitule_naf_65": "Réparation d'ordinateurs et de biens personnels et domestiques",
      "intitule_naf": "Réparation d'ordinateurs et de biens personnels et domestiques",
      "code_naf": "95",
      "intitule_naf_40": "Répar. ordi. & bien perso. & domestique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "cff6d71f0889435fb95ce7275b377fc47d6cd60d",
    "fields": {
      "intitule_naf_65": "Activités des centres de culture physique",
      "intitule_naf": "Activités des centres de culture physique",
      "code_naf": "9313Z",
      "intitule_naf_40": "Activité des centres de culture physique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5a7d5a528326149a2c7bc46c2d7f1c1cdbeb9b75",
    "fields": {
      "intitule_naf_65": "Réparation d'équipements de communication",
      "intitule_naf": "Réparation d'équipements de communication",
      "code_naf": "9512Z",
      "intitule_naf_40": "Réparation équipements de communication"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "61a2daa4138d5f5cb9ba840758d5bb1f166b71f7",
    "fields": {
      "intitule_naf_65": "Création artistique relevant des arts plastiques",
      "intitule_naf": "Création artistique relevant des arts plastiques",
      "code_naf": "9003A",
      "intitule_naf_40": "Création artistique (arts plastiques)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8c1bba03e0ea4bdae0e812fa8d3c37aa89ab2dba",
    "fields": {
      "intitule_naf_65": "Activités des organisations associatives",
      "intitule_naf": "Activités des organisations associatives",
      "code_naf": "94",
      "intitule_naf_40": "Activités des organisations associatives"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7bf1e1f22364f0ce0e6b5c7d2aa6a8b4a91fb527",
    "fields": {
      "intitule_naf_65": "Arts du spectacle vivant",
      "intitule_naf": "Arts du spectacle vivant",
      "code_naf": "9001",
      "intitule_naf_40": "Arts du spectacle vivant"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8c88b1fafc05b598d7200d124882d03ffaf88a5c",
    "fields": {
      "intitule_naf_65": "Fabrication équipements aérauliques et frigorifiques industriels",
      "intitule_naf": "Fabrication d'équipements aérauliques et frigorifiques industriels",
      "code_naf": "2825Z",
      "intitule_naf_40": "Fab. équipt aérauliq. & frigorifiq. ind."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e20baf08f703bc9bccda6a5d38fed89034422058",
    "fields": {
      "intitule_naf_65": "Installation structures métalliques, chaudronnées et tuyauterie",
      "intitule_naf": "Installation de structures métalliques, chaudronnées et de tuyauterie",
      "code_naf": "3320A",
      "intitule_naf_40": "Instal. struct. métal., chaudr. & tuyau."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "209fa86f8bcbcf74b39da32b9c7adfb9c6d635a6",
    "fields": {
      "intitule_naf_65": "Construction de bâtiments résidentiels et non résidentiels",
      "intitule_naf": "Construction de bâtiments résidentiels et non résidentiels",
      "code_naf": "4120",
      "intitule_naf_40": "Construc. bâtimnt résid. & non résident."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ac2a73bd8b2c9a40a2395e6de8d07fe367437987",
    "fields": {
      "intitule_naf_65": "Fabrication de machines pour les industries du papier et carton",
      "intitule_naf": "Fabrication de machines pour les industries du papier et du carton",
      "code_naf": "2895",
      "intitule_naf_40": "Fab. machine pr indus. papier & carton"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8f9d9fc57afeeb366a4dece61817f028516dc140",
    "fields": {
      "intitule_naf_65": "Production et distribution de combustibles gazeux",
      "intitule_naf": "Production et distribution de combustibles gazeux",
      "code_naf": "352",
      "intitule_naf_40": "Prod. & distrib. de combustibles gazeux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "300e02f2856c7e6245f304da21b47a11a73e5911",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres machines d'usage général",
      "intitule_naf": "Fabrication d'autres machines d'usage général",
      "code_naf": "2829B",
      "intitule_naf_40": "Fab. d'autres machines d'usage général"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "6782a11b22cdc64c1fc5d99f1b97d11b2ae91eac",
    "fields": {
      "intitule_naf_65": "Fabrication de vis et de boulons",
      "intitule_naf": "Fabrication de vis et de boulons",
      "code_naf": "2594Z",
      "intitule_naf_40": "Fabrication de vis et de boulons"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c6f06345bc4fa20fad348d67cb4de5374865650c",
    "fields": {
      "intitule_naf_65": "Récupération de déchets triés",
      "intitule_naf": "Récupération de déchets triés",
      "code_naf": "3832Z",
      "intitule_naf_40": "Récupération de déchets triés"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5ec5cb9624a638c56f79a060169efae9859c692c",
    "fields": {
      "intitule_naf_65": "Construction de bâtiments",
      "intitule_naf": "Construction de bâtiments",
      "code_naf": "41",
      "intitule_naf_40": "Construction de bâtiments"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e4d800e2feb93fda3921455fd03ff39f1d40ba84",
    "fields": {
      "intitule_naf_65": "Fabrication de matelas",
      "intitule_naf": "Fabrication de matelas",
      "code_naf": "3103Z",
      "intitule_naf_40": "Fabrication de matelas"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c136978b599b1b8f2bec73e8653fd508f8104529",
    "fields": {
      "intitule_naf_65": "Fabric. produits métalliques, sf machines & équipements",
      "intitule_naf": "Fabrication de produits métalliques, à l'exception des machines et des équipements",
      "code_naf": "25",
      "intitule_naf_40": "Fab. prod. métalliq. sf machine & équipt"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a5fdfbab00e900dfe68ad7f6508d214ee61233a4",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres produits céramiques à usage technique",
      "intitule_naf": "Fabrication d'autres produits céramiques à usage technique",
      "code_naf": "2344",
      "intitule_naf_40": "Fab. aut. prod. céram. à usage technique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "71aefbb3abdb6e8343941267420c409a0d441f60",
    "fields": {
      "intitule_naf_65": "Fabrication d'appareils sanitaires en céramique",
      "intitule_naf": "Fabrication d'appareils sanitaires en céramique",
      "code_naf": "2342Z",
      "intitule_naf_40": "Fab. appareil sanitaire en céramique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c7d4da95cb1fe6c87f0930cd472092bf4d150669",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres articles en caoutchouc",
      "intitule_naf": "Fabrication d'autres articles en caoutchouc",
      "code_naf": "2219",
      "intitule_naf_40": "Fabric. d'autres articles en caoutchouc"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "6b0c3eb9bc266a683d5f479994f22856c897d660",
    "fields": {
      "intitule_naf_65": "Imprimerie et reproduction d'enregistrements",
      "intitule_naf": "Imprimerie et reproduction d'enregistrements",
      "code_naf": "18",
      "intitule_naf_40": "Imprimerie & reprod. d'enregistrements"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d0d7c961b779d3fce4ca024cfb5460fb59ddf389",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres articles à mailles",
      "intitule_naf": "Fabrication d'autres articles à mailles",
      "code_naf": "1439",
      "intitule_naf_40": "Fabrication d'autres articles à mailles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "57a4117dc7023ecb98c9ac51d4310874da990de5",
    "fields": {
      "intitule_naf_65": "Forge, estampage, matriçage; métallurgie des poudres",
      "intitule_naf": "Forge, estampage, matriçage ; métallurgie des poudres",
      "code_naf": "2550A",
      "intitule_naf_40": "Forge; métallurgie des poudres"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "55af12f1d130e9a901121876774cd54a4e9fdb1c",
    "fields": {
      "intitule_naf_65": "Fabrication d'articles de papeterie",
      "intitule_naf": "Fabrication d'articles de papeterie",
      "code_naf": "1723Z",
      "intitule_naf_40": "Fabrication d'articles de papeterie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c5034e9777f3beaed65af83ccc7aa4ba24fcce9b",
    "fields": {
      "intitule_naf_65": "Fabrication de coutellerie",
      "intitule_naf": "Fabrication de coutellerie",
      "code_naf": "2571Z",
      "intitule_naf_40": "Fabrication de coutellerie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b9e494271e05f5414a9246aedcefa45f60ab0163",
    "fields": {
      "intitule_naf_65": "Industrie chimique",
      "intitule_naf": "Industrie chimique",
      "code_naf": "20",
      "intitule_naf_40": "Industrie chimique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "88155b090cf49c600871ba47900055e24c18b05b",
    "fields": {
      "intitule_naf_65": "Interm. comm. combustibles métaux minéraux & produits chimiques",
      "intitule_naf": "Intermédiaires du commerce en combustibles, métaux, minéraux et produits chimiques",
      "code_naf": "4612",
      "intitule_naf_40": "Int. comm. comb. mét. minér. & pd. chim."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "52a997a75bb23b1da8313ce6e0761027684e238f",
    "fields": {
      "intitule_naf_65": "Comm. détail pain pâtisserie & confiserie (magasin spécialisé)",
      "intitule_naf": "Commerce de détail de pain, pâtisserie et confiserie en magasin spécialisé",
      "code_naf": "4724Z",
      "intitule_naf_40": "Comm. dét. pain pâtiss. & confiser. (ms)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "6025a59c416630ba15a71e367580a12170c00987",
    "fields": {
      "intitule_naf_65": "Commerce de détail d'articles de sport en magasin spécialisé",
      "intitule_naf": "Commerce de détail d'articles de sport en magasin spécialisé",
      "code_naf": "4764Z",
      "intitule_naf_40": "Com. dét. articles de sport en mag. spé."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "31c2df196cfa030d8deb8756eec9b3625d38a7e1",
    "fields": {
      "intitule_naf_65": "Commerce de détail d'équipements automobiles",
      "intitule_naf": "Commerce de détail d'équipements automobiles",
      "code_naf": "4532Z",
      "intitule_naf_40": "Commerce de détail équipement automobile"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "fd288bf354abe2677bf2689c0978381f90067b19",
    "fields": {
      "intitule_naf_65": "Transport ferroviaire interurbain de voyageurs",
      "intitule_naf": "Transport ferroviaire interurbain de voyageurs",
      "code_naf": "491",
      "intitule_naf_40": "Transport ferrov. interurbain voyageur"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d3bcdf1f7f5dc77430851837dff18ccd30869080",
    "fields": {
      "intitule_naf_65": "Autres commerces de gros spécialisés",
      "intitule_naf": "Autres commerces de gros spécialisés",
      "code_naf": "467",
      "intitule_naf_40": "Autres commerces de gros spécialisés"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "fdafdaad58fea35fa59f7730e2b387f8bc030cbb",
    "fields": {
      "intitule_naf_65": "Services de déménagement",
      "intitule_naf": "Services de déménagement",
      "code_naf": "4942",
      "intitule_naf_40": "Services de déménagement"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "81bc8390b9cec1f8183f00baf63726d73283b1dd",
    "fields": {
      "intitule_naf_65": "Production d'autres boissons fermentées non distillées",
      "intitule_naf": "Production d'autres boissons fermentées non distillées",
      "code_naf": "1104Z",
      "intitule_naf_40": "Prod. aut. boisson fermentée non distil."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2855397c47db22ff4c3ec4b348dbf400b5186d2d",
    "fields": {
      "intitule_naf_65": "Transformation et conservation de la viande de volaille",
      "intitule_naf": "Transformation et conservation de la viande de volaille",
      "code_naf": "1012",
      "intitule_naf_40": "Transf. & conserv. de viande de volaille"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e900c66e293cc394ab5ee01309b0c8a59862503a",
    "fields": {
      "intitule_naf_65": "Activités de soutien aux autres industries extractives",
      "intitule_naf": "Activités de soutien aux autres industries extractives",
      "code_naf": "99",
      "intitule_naf_40": "Act. de soutien aut. indus. extractives"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "35183769af98dc192737f5219a155af32156ad51",
    "fields": {
      "intitule_naf_65": "Fabrication d'aliments pour animaux de compagnie",
      "intitule_naf": "Fabrication d'aliments pour animaux de compagnie",
      "code_naf": "1092Z",
      "intitule_naf_40": "Fab. aliments pour animaux de compagnie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "81713c4606459ecc1645b667fbb122edf1f6a70d",
    "fields": {
      "intitule_naf_65": "Sylviculture et autres activités forestières",
      "intitule_naf": "Sylviculture et autres activités forestières",
      "code_naf": "0210Z",
      "intitule_naf_40": "Sylviculture & autres act. forestières"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b4d4620e6f43740e0bff6b41cfc33a2b82d252a0",
    "fields": {
      "intitule_naf_65": "Chasse, piégeage et services annexes",
      "intitule_naf": "Chasse, piégeage et services annexes",
      "code_naf": "0170Z",
      "intitule_naf_40": "Chasse, piégeage et services annexes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2c32816f7cfd333d001a519b9e031a9459ffef33",
    "fields": {
      "intitule_naf_65": "Extraction de minerais de fer",
      "intitule_naf": "Extraction de minerais de fer",
      "code_naf": "710",
      "intitule_naf_40": "Extraction de minerais de fer"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "454a3ab1fe4ce3b7c8798e4377908fed7527df44",
    "fields": {
      "intitule_naf_65": "Extraction de lignite",
      "intitule_naf": "Extraction de lignite",
      "code_naf": "520",
      "intitule_naf_40": "Extraction de lignite"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "419da77ecda6ae8b8877e51ca21fbf556712653b",
    "fields": {
      "intitule_naf_65": "Aut. accueil ou accompgnt sans hébergt d'enfants et adolescents",
      "intitule_naf": "Autre accueil ou accompagnement sans hébergement d'enfants\n et d'adolescents",
      "code_naf": "8899A",
      "intitule_naf_40": "Aut. accueil sans hébrgt enfants & ado."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e9a7fbc117b3c4e6d049e30cc7ee3628f7973ab3",
    "fields": {
      "intitule_naf_65": "Autres activités de soutien aux entreprises n.c.a.",
      "intitule_naf": "Autres activités de soutien aux entreprises n.c.a.",
      "code_naf": "8299Z",
      "intitule_naf_40": "Autre activité de soutien aux entr. nca."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b5cc7e781421edd54c3c3f5fd4852f07cf190ac4",
    "fields": {
      "intitule_naf_65": "Recherche-développement en sciences humaines et sociales",
      "intitule_naf": "Recherche-développement en sciences humaines et sociales",
      "code_naf": "7220Z",
      "intitule_naf_40": "R&D en sciences humaines et sociales"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "85e721d766b8662d20acda8612070450d48fce1f",
    "fields": {
      "intitule_naf_65": "Activités de radiodiagnostic et de radiothérapie",
      "intitule_naf": "Activités de radiodiagnostic et de radiothérapie",
      "code_naf": "8622A",
      "intitule_naf_40": "Act. radiodiagnostic et de radiothérapie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "04ca2b97b079e0779796c5fb00a7baa442299790",
    "fields": {
      "intitule_naf_65": "Location et location-bail de véhicules automobiles",
      "intitule_naf": "Location et location-bail de véhicules automobiles",
      "code_naf": "771",
      "intitule_naf_40": "Loc. & loc.-bail de véhicule automobile"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "531ae818458cc6c0ce426da247115d0cb264cc8f",
    "fields": {
      "intitule_naf_65": "Activités des agents et courtiers d'assurances",
      "intitule_naf": "Activités des agents et courtiers d'assurances",
      "code_naf": "6622",
      "intitule_naf_40": "Act. des agents & courtiers d'assurances"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "bdad70409fffe3a0df71259985c4d570a2951704",
    "fields": {
      "intitule_naf_65": "Activités de santé humaine non classées ailleurs",
      "intitule_naf": "Activités de santé humaine non classées ailleurs",
      "code_naf": "8690F",
      "intitule_naf_40": "Activités de santé humaine nca."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ef2aa393fd3c281bb74c5ec349a3e8de631f7d10",
    "fields": {
      "intitule_naf_65": "Activité des géomètres",
      "intitule_naf": "Activité des géomètres",
      "code_naf": "7112A",
      "intitule_naf_40": "Activité des géomètres"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3a9480fbb2079e8be49d497739480d1a5cfb79a4",
    "fields": {
      "intitule_naf_65": "Activités immobilières",
      "intitule_naf": "Activités immobilières",
      "code_naf": "68",
      "intitule_naf_40": "Activités immobilières"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "248e072f0b276cd7a7bb4bd4591353f768034e1c",
    "fields": {
      "intitule_naf_65": "Gestion des musées",
      "intitule_naf": "Gestion des musées",
      "code_naf": "9102",
      "intitule_naf_40": "Gestion des musées"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1b7cfe12cda7ea39ace1737cf76188d93d429e8f",
    "fields": {
      "intitule_naf_65": "Activ. poste dans le cadre d'une obligation de service universel",
      "intitule_naf": "Activités de poste dans le cadre d'une obligation de service universel",
      "code_naf": "531",
      "intitule_naf_40": "Activ. poste (obligation sce universel)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "dce079ca53f92d30c20eb1d5fd89c78032c839ed",
    "fields": {
      "intitule_naf_65": "Programmation, conseil et autres activités informatiques",
      "intitule_naf": "Programmation, conseil et autres activités informatiques",
      "code_naf": "62",
      "intitule_naf_40": "Pgmtion conseil & aut. act. informatique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d0220e5375315cf17b0639b4495ee42355e156e0",
    "fields": {
      "intitule_naf_65": "Services auxiliaires des transports terrestres",
      "intitule_naf": "Services auxiliaires des transports terrestres",
      "code_naf": "5221Z",
      "intitule_naf_40": "Sces auxiliaires de transport terrestre"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a4ff57948238c6d89c5e804eb6f10961c9957364",
    "fields": {
      "intitule_naf_65": "Transports maritimes et côtiers de passagers",
      "intitule_naf": "Transports maritimes et côtiers de passagers",
      "code_naf": "5010",
      "intitule_naf_40": "Transport maritime & côtier de passagers"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7cf9641b8fc6047284cf51d4affdcba250a1af5a",
    "fields": {
      "intitule_naf_65": "Services auxiliaires des transports par eau",
      "intitule_naf": "Services auxiliaires des transports par eau",
      "code_naf": "5222",
      "intitule_naf_40": "Sces auxiliaires des transports par eau"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7b430c66e1d738c7266b69c171e4b82b7832661b",
    "fields": {
      "intitule_naf_65": "Restaurants et services de restauration mobile",
      "intitule_naf": "Restaurants et services de restauration mobile",
      "code_naf": "561",
      "intitule_naf_40": "Restaurant & serv. de restaurat. mobile"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e5a5f88b502375be0c23313f3edba02e885c2286",
    "fields": {
      "intitule_naf_65": "Activités des sociétés holding",
      "intitule_naf": "Activités des sociétés holding",
      "code_naf": "6420Z",
      "intitule_naf_40": "Activités des sociétés holding"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c2ed7aa1b6832046734addc97c734b06475018a2",
    "fields": {
      "intitule_naf_65": "Restauration traditionnelle",
      "intitule_naf": "Restauration traditionnelle",
      "code_naf": "5610A",
      "intitule_naf_40": "Restauration traditionnelle"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7d033bd319b7eddd9e3472e34437b9dddf3226b0",
    "fields": {
      "intitule_naf_65": "Débits de boissons",
      "intitule_naf": "Débits de boissons",
      "code_naf": "5630",
      "intitule_naf_40": "Débits de boissons"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1b209421a9897a8c0c03888f70e4867982600919",
    "fields": {
      "intitule_naf_65": "Assurance",
      "intitule_naf": "Assurance",
      "code_naf": "65",
      "intitule_naf_40": "Assurance"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1e3daaac06521b901b5e3516cb2da739e6d5b1b0",
    "fields": {
      "intitule_naf_65": "Réparation d'autres biens personnels et domestiques",
      "intitule_naf": "Réparation d'autres biens personnels et domestiques",
      "code_naf": "9529",
      "intitule_naf_40": "Répar. aut. biens personnel & domestique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e7ed2dedda9823b345ebdc2f1c8b21076425f0ed",
    "fields": {
      "intitule_naf_65": "Culture de fruits oléagineux",
      "intitule_naf": "Culture de fruits oléagineux",
      "code_naf": "0126Z",
      "intitule_naf_40": "Culture de fruits oléagineux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3fb6e4999e0b23d51dd6a14993bc30d152300c4e",
    "fields": {
      "intitule_naf_65": "Culture de fruits oléagineux",
      "intitule_naf": "Culture de fruits oléagineux",
      "code_naf": "126",
      "intitule_naf_40": "Culture de fruits oléagineux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b55ebda4fac27a0e304fb49aee0b73a43acb6779",
    "fields": {
      "intitule_naf_65": "Culture de la canne à sucre",
      "intitule_naf": "Culture de la canne à sucre",
      "code_naf": "0114Z",
      "intitule_naf_40": "Culture de la canne à sucre"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5f530ae37fff9cb02a13663422134519524d502e",
    "fields": {
      "intitule_naf_65": "Élevage d'autres animaux",
      "intitule_naf": "Élevage d'autres animaux",
      "code_naf": "0149Z",
      "intitule_naf_40": "Élevage d'autres animaux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1a44863af7b486a8429299c80df0d8f8d84c545b",
    "fields": {
      "intitule_naf_65": "Pêche et aquaculture",
      "intitule_naf": "Pêche et aquaculture",
      "code_naf": "03",
      "intitule_naf_40": "Pêche et aquaculture"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4a98f9747132c1bf5fc8403a811bd71761c62a59",
    "fields": {
      "intitule_naf_65": "Culture d'agrumes",
      "intitule_naf": "Culture d'agrumes",
      "code_naf": "123",
      "intitule_naf_40": "Culture d'agrumes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0af8627a1ba49641049399e6f39f2d7fa49e2ebd",
    "fields": {
      "intitule_naf_65": "Fabrication de biscuits, biscottes et pâtisseries de conservation",
      "intitule_naf": "Fabrication de biscuits, biscottes et pâtisseries de conservation",
      "code_naf": "1072",
      "intitule_naf_40": "Fab. pain, biscuit & pâtiss. de conserv."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "766475fe0ddd0748afd7b3ddd52e484279787dfa",
    "fields": {
      "intitule_naf_65": "Fabrication de fibres artificielles ou synthétiques",
      "intitule_naf": "Fabrication de fibres artificielles ou synthétiques",
      "code_naf": "206",
      "intitule_naf_40": "Fab.  fibre artificielle ou synthétique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7062f6b25ee36ef5e60f57d433771249a8634760",
    "fields": {
      "intitule_naf_65": "Fabrication de peintures, vernis, encres et mastics",
      "intitule_naf": "Fabrication de peintures, vernis, encres et mastics",
      "code_naf": "2030Z",
      "intitule_naf_40": "Fab. de peinture, vernis, encre & mastic"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2ca93f05283598eadf94da98e692eefd8c4db799",
    "fields": {
      "intitule_naf_65": "Transformation et conservation de fruits",
      "intitule_naf": "Transformation et conservation de fruits",
      "code_naf": "1039B",
      "intitule_naf_40": "Transformation et conservation de fruits"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7b4f6c8c64d4a010a832a801b071abed437119f7",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres produits alimentaires n.c.a.",
      "intitule_naf": "Fabrication d'autres produits alimentaires n.c.a.",
      "code_naf": "1089Z",
      "intitule_naf_40": "Fab. d'autres prod. alimentaires n.c.a."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d0435cd3806127cb1693054a94325f95154f39c1",
    "fields": {
      "intitule_naf_65": "Fabrication d'aliments pour animaux de ferme",
      "intitule_naf": "Fabrication d'aliments pour animaux de ferme",
      "code_naf": "1091",
      "intitule_naf_40": "Fabric. d'aliments pour animaux de ferme"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5b285a2c19b9f52ca158e59e81604577f6e1b1e7",
    "fields": {
      "intitule_naf_65": "Extraction de pierres, de sables et d'argiles",
      "intitule_naf": "Extraction de pierres, de sables et d'argiles",
      "code_naf": "81",
      "intitule_naf_40": "Extr. de pierres, de sables et d'argiles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "396e44120eecd51ceb5b145a22c811819e5bdbf7",
    "fields": {
      "intitule_naf_65": "Autres activités du travail des grains",
      "intitule_naf": "Autres activités du travail des grains",
      "code_naf": "1061B",
      "intitule_naf_40": "Autres activités du travail des grains"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e27bdc0a2e5b78e388130d5423c6e5da40333f01",
    "fields": {
      "intitule_naf_65": "Fabrication d'huiles et graisses brutes",
      "intitule_naf": "Fabrication d'huiles et graisses brutes",
      "code_naf": "1041A",
      "intitule_naf_40": "Fabrication d'huiles et graisses brutes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b2072c3b330ead70bd2aa3ec9b347a488ed141a0",
    "fields": {
      "intitule_naf_65": "Fabrication de gaz industriels",
      "intitule_naf": "Fabrication de gaz industriels",
      "code_naf": "2011",
      "intitule_naf_40": "Fabrication de gaz industriels"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2ad2a7bf492ca2c98cdc4d8e74665fe6fd5fcbbe",
    "fields": {
      "intitule_naf_65": "Fabrication d'éléments en béton pour la construction",
      "intitule_naf": "Fabrication d'éléments en béton pour la construction",
      "code_naf": "2361",
      "intitule_naf_40": "Fab. élément en béton pour la construct."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "227f875163230c68999a46084266ed8b6fca120d",
    "fields": {
      "intitule_naf_65": "Fabrication de produits électroniques grand public",
      "intitule_naf": "Fabrication de produits électroniques grand public",
      "code_naf": "2640Z",
      "intitule_naf_40": "Fab. produit électronique grand public"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2eaf0112bb79a8a8cde9bbc7937f3e85857a7a49",
    "fields": {
      "intitule_naf_65": "Fabrication d'emballages en matières plastiques",
      "intitule_naf": "Fabrication d'emballages en matières plastiques",
      "code_naf": "2222Z",
      "intitule_naf_40": "Fab. d'emballage en matière plastique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0762400c2c74426128414cdb387a84ff9f64d219",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres pompes et compresseurs",
      "intitule_naf": "Fabrication d'autres pompes et compresseurs",
      "code_naf": "2813Z",
      "intitule_naf_40": "Fabric. d'autres pompes et compresseurs"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b4e07b266ff0d11532132a35fef4da46dc9e8009",
    "fields": {
      "intitule_naf_65": "Fabrication d'appareils électroménagers",
      "intitule_naf": "Fabrication d'appareils électroménagers",
      "code_naf": "2751",
      "intitule_naf_40": "Fabrication d'appareils électroménagers"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "33bc47e19e9a37f2d729b5401ada94f99ceb72e4",
    "fields": {
      "intitule_naf_65": "Fabrication de béton prêt à l'emploi",
      "intitule_naf": "Fabrication de béton prêt à l'emploi",
      "code_naf": "2363Z",
      "intitule_naf_40": "Fabrication de béton prêt à l'emploi"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f912591e375c45b9d9505e6b785d4deb5b7bc890",
    "fields": {
      "intitule_naf_65": "Fabrication de serrures et de ferrures",
      "intitule_naf": "Fabrication de serrures et de ferrures",
      "code_naf": "2572",
      "intitule_naf_40": "Fabrication de serrures et de ferrures"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "09e634efe367f52aa355a1f07a0a21746fbf09ac",
    "fields": {
      "intitule_naf_65": "Fabrication de fours et brûleurs",
      "intitule_naf": "Fabrication de fours et brûleurs",
      "code_naf": "2821Z",
      "intitule_naf_40": "Fabrication de fours et brûleurs"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b2d219781a82d548cc81a09f8c1ab80e842149a3",
    "fields": {
      "intitule_naf_65": "Traitement et élimination des déchets dangereux",
      "intitule_naf": "Traitement et élimination des déchets dangereux",
      "code_naf": "3822Z",
      "intitule_naf_40": "Traitmnt & élimination déchets dangereux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "398454b5f344a8ebcea14f99863adbc05fc75155",
    "fields": {
      "intitule_naf_65": "Réparation de matériels électroniques et optiques",
      "intitule_naf": "Réparation de matériels électroniques et optiques",
      "code_naf": "3313",
      "intitule_naf_40": "Répar. matériel électronique & optique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "82ea3b910bbb318e09a21ffc9cec823affca29e3",
    "fields": {
      "intitule_naf_65": "Distribution de combustibles gazeux par conduites",
      "intitule_naf": "Distribution de combustibles gazeux par conduites",
      "code_naf": "3522Z",
      "intitule_naf_40": "Distrib. combustible gazeux pr conduites"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8d88c9c343da978b3a3d20ebc9c9c39fc6282940",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres équipements automobiles",
      "intitule_naf": "Fabrication d'autres équipements automobiles",
      "code_naf": "2932Z",
      "intitule_naf_40": "Fabric. d'autres équipements automobiles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "024dd1729f1f3e40cd0c14c10fbdbf4a091beabe",
    "fields": {
      "intitule_naf_65": "Construction de véhicules militaires de combat",
      "intitule_naf": "Construction de véhicules militaires de combat",
      "code_naf": "304",
      "intitule_naf_40": "Constr. véhicules militaires de combat"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "dfe5b53cb3e169564e03e65f72b0aff5c2ab3d74",
    "fields": {
      "intitule_naf_65": "Collecte et traitement des eaux usées",
      "intitule_naf": "Collecte et traitement des eaux usées",
      "code_naf": "37",
      "intitule_naf_40": "Collecte et traitement des eaux usées"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "140488aa75a7ef2573be881cb22c72000291af8d",
    "fields": {
      "intitule_naf_65": "Fabrication de machines d'imprimerie",
      "intitule_naf": "Fabrication de machines d'imprimerie",
      "code_naf": "2899A",
      "intitule_naf_40": "Fabrication de machines d'imprimerie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "547e27638a79f47d370e6949719dc57778a4034a",
    "fields": {
      "intitule_naf_65": "Commerce d'équipements automobiles",
      "intitule_naf": "Commerce d'équipements automobiles",
      "code_naf": "453",
      "intitule_naf_40": "Commerce d'équipements automobiles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2f0c5f48cc0ae69dce736e589ef3abf8150173f9",
    "fields": {
      "intitule_naf_65": "Fabrication d'articles de sport",
      "intitule_naf": "Fabrication d'articles de sport",
      "code_naf": "323",
      "intitule_naf_40": "Fabrication d'articles de sport"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3737ce153953f0bffe0a3ff5dd344342f9e92f20",
    "fields": {
      "intitule_naf_65": "Fabrication de jeux et jouets",
      "intitule_naf": "Fabrication de jeux et jouets",
      "code_naf": "3240Z",
      "intitule_naf_40": "Fabrication de jeux et jouets"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0053092f5a3b526be4d08f55e119bfb476528da8",
    "fields": {
      "intitule_naf_65": "Comm. détail tapis, moquettes & revêts murs & sols (magas. spéc.)",
      "intitule_naf": "Commerce de détail de tapis, moquettes et revêtements de murs et de sols en magasin spécialisé",
      "code_naf": "4753Z",
      "intitule_naf_40": "Cd tapis moquette & revêt. mur sol (ms)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "34068bc08f2d5dc5e0557a9c4015607177f9297b",
    "fields": {
      "intitule_naf_65": "Commerce de détail de journaux et papeterie en magasin spécialisé",
      "intitule_naf": "Commerce de détail de journaux et papeterie en magasin spécialisé",
      "code_naf": "4762",
      "intitule_naf_40": "Comm. détail journaux & papeterie (ms)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a68c4d09d580b3450c58e966a994abe45ba09c93",
    "fields": {
      "intitule_naf_65": "Commerce de gros d'habillement et de chaussures",
      "intitule_naf": "Commerce de gros (commerce interentreprises) d'habillement et de chaussures",
      "code_naf": "4642Z",
      "intitule_naf_40": "Commerce gros d'habillement & chaussures"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f265654cfd4aee9376db52fea80c83d1c9bdbdca",
    "fields": {
      "intitule_naf_65": "Commerce de détail de biens d'occasion en magasin",
      "intitule_naf": "Commerce de détail de biens d'occasion en magasin",
      "code_naf": "4779",
      "intitule_naf_40": "Comm. détail biens d'occasion en magasin"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "aa5090f3fbf6c55abb22607c0a88458f23ae848e",
    "fields": {
      "intitule_naf_65": "Commerce de gros d'autres équipements industriels",
      "intitule_naf": "Commerce de gros d'autres équipements industriels",
      "code_naf": "466",
      "intitule_naf_40": "Commerce de gros autres équipts indust."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1220605d764ec09992d621d77497e9a07efb2010",
    "fields": {
      "intitule_naf_65": "Commerce de gros (commerce interentreprises) de textiles",
      "intitule_naf": "Commerce de gros (commerce interentreprises) de textiles",
      "code_naf": "4641Z",
      "intitule_naf_40": "Commerce de gros de textiles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "49e9e1eb203a7e6fe55b28b3c00874bcc02e64aa",
    "fields": {
      "intitule_naf_65": "Centrales d'achat non alimentaires",
      "intitule_naf": "Centrales d'achat non alimentaires",
      "code_naf": "4619A",
      "intitule_naf_40": "Centrales d'achat non alimentaires"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b4a01324d0e7638f54863c2396165d8e5a71728b",
    "fields": {
      "intitule_naf_65": "Vente à distance",
      "intitule_naf": "Vente à distance",
      "code_naf": "4791",
      "intitule_naf_40": "Vente à distance"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e65f961337800278b127764972500762b5245fbb",
    "fields": {
      "intitule_naf_65": "Adm. pub. tutelle santé form. cult. & social (aut que sécu. soc.)",
      "intitule_naf": "Administration publique (tutelle) de la santé, de la formation, de la culture et des services sociaux, autre que sécurité sociale",
      "code_naf": "8412Z",
      "intitule_naf_40": "A. p. santé form. cult. & soc. (sf sécu)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2d239a567245f10c4589916741d525d7d10c8167",
    "fields": {
      "intitule_naf_65": "Activ. de recouvrement factures & d'info. financ. s/la clientèle",
      "intitule_naf": "Activités des agences de recouvrement de factures et des sociétés d'information financière sur la clientèle",
      "code_naf": "8291",
      "intitule_naf_40": "Act. recouv. fac. & info. fin. s/client."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a56fc4cb4974de900b2838eb655e28938999579b",
    "fields": {
      "intitule_naf_65": "Photocopie prépa. documents & aut. activ. spéc. soutien de bureau",
      "intitule_naf": "Photocopie, préparation de documents et autres activités spécialisées de soutien de bureau",
      "code_naf": "8219",
      "intitule_naf_40": "Photocopie & aut. act. spé. sout. bureau"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d3a039346011c6c7b04464369708fdb4d9f84e79",
    "fields": {
      "intitule_naf_65": "Action soc. sans héberg. pers. âgées & handicapées",
      "intitule_naf": "Action sociale sans hébergement pour personnes âgées et pour personnes handicapées",
      "code_naf": "881",
      "intitule_naf_40": "Action soc. sans hbgt, per. âgée & hand."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f062a33993b82e05cfc88ed2acfcf84f901b4384",
    "fields": {
      "intitule_naf_65": "Accueil ou accompagnement sans hébergement d'enfants handicapés",
      "intitule_naf": "Accueil ou accompagnement sans hébergement d'enfants handicapés",
      "code_naf": "8891B",
      "intitule_naf_40": "Accueil sans hébergt d'enfant handicap"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8c6cced158520ca2799e3bc607a525669642623d",
    "fields": {
      "intitule_naf_65": "Services relatifs aux bâtiments et aménagement paysager",
      "intitule_naf": "Services relatifs aux bâtiments et aménagement paysager",
      "code_naf": "81",
      "intitule_naf_40": "Sces relatifs bâtimnt & aménagt paysager"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d1006ec62e208ae839e5d7b7125a5b32032b102b",
    "fields": {
      "intitule_naf_65": "Enseignement secondaire technique ou professionnel",
      "intitule_naf": "Enseignement secondaire technique ou professionnel",
      "code_naf": "8532Z",
      "intitule_naf_40": "Enseignemt secondaire techn. ou profess."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9eefe456b012a480f495f4e7993572b9faa1bd8a",
    "fields": {
      "intitule_naf_65": "Activité des médecins et des dentistes",
      "intitule_naf": "Activité des médecins et des dentistes",
      "code_naf": "862",
      "intitule_naf_40": "Activité des médecins et des dentistes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7e41f3789b6c440e622a44116e15485c0275f146",
    "fields": {
      "intitule_naf_65": "Activités de soutien à l'enseignement",
      "intitule_naf": "Activités de soutien à l'enseignement",
      "code_naf": "8560Z",
      "intitule_naf_40": "Activités de soutien à l'enseignement"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3c4046ba17302f64f9b661eeca1e908dc417e51b",
    "fields": {
      "intitule_naf_65": "Aide à domicile",
      "intitule_naf": "Aide à domicile",
      "code_naf": "8810A",
      "intitule_naf_40": "Aide à domicile"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0d71038c0af37d33a2fb0b2daa712f783fc41513",
    "fields": {
      "intitule_naf_65": "Loc-bail de propriété intell. & sf oeuvres soumises à copyright",
      "intitule_naf": "Location-bail de propriété intellectuelle et de produits similaires, à l'exception des œuvres soumises à copyright",
      "code_naf": "774",
      "intitule_naf_40": "Loc-bail propr. intel., sf oeuvre avec \u00a9"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "fd3cbcad474d84e03743c7987158aa12c599277a",
    "fields": {
      "intitule_naf_65": "Tierce maintenance de systèmes et d'applications informatiques",
      "intitule_naf": "Tierce maintenance de systèmes et d'applications informatiques",
      "code_naf": "6202B",
      "intitule_naf_40": "Tierce mainten. syst. & appli. nformati."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "acdd56c2dadd8765c972e8944115ba6e136bc320",
    "fields": {
      "intitule_naf_65": "Transports maritimes et côtiers de fret",
      "intitule_naf": "Transports maritimes et côtiers de fret",
      "code_naf": "5020",
      "intitule_naf_40": "Transports maritimes et côtiers de fret"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "21cefd964a1b8913b5ff64e0a01832ba5b54fabe",
    "fields": {
      "intitule_naf_65": "Restauration collective sous contrat",
      "intitule_naf": "Restauration collective sous contrat",
      "code_naf": "5629A",
      "intitule_naf_40": "Restauration collective sous contrat"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "6f0ce12939ebc3ee3b2e41ba780f5c79280156d1",
    "fields": {
      "intitule_naf_65": "Activités de sécurité privée",
      "intitule_naf": "Activités de sécurité privée",
      "code_naf": "8010",
      "intitule_naf_40": "Activités de sécurité privée"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8a79873f2bda5a23f07e76b7f648b5e1cff7b517",
    "fields": {
      "intitule_naf_65": "Edition et distribution vidéo",
      "intitule_naf": "Edition et distribution vidéo",
      "code_naf": "5913B",
      "intitule_naf_40": "Edition et distribution vidéo"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1638d1e7a63d131c9a6459ba78d87d755a1af953",
    "fields": {
      "intitule_naf_65": "Études de marché et sondages",
      "intitule_naf": "Études de marché et sondages",
      "code_naf": "732",
      "intitule_naf_40": "Études de marché et sondages"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3898bab02a826b0ef2515e952f6d1981db330175",
    "fields": {
      "intitule_naf_65": "Télécommunications filaires",
      "intitule_naf": "Télécommunications filaires",
      "code_naf": "6110Z",
      "intitule_naf_40": "Télécommunications filaires"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ecaa611c4c5ba07fc3a0dbea6f143c337e505d39",
    "fields": {
      "intitule_naf_65": "Conseil de gestion",
      "intitule_naf": "Conseil de gestion",
      "code_naf": "702",
      "intitule_naf_40": "Conseil de gestion"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "6d38697b69b320c1c4d4148b7e3e2128771e9cf3",
    "fields": {
      "intitule_naf_65": "Assurance vie",
      "intitule_naf": "Assurance vie",
      "code_naf": "6511",
      "intitule_naf_40": "Assurance vie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d675d12771f42da53233264b954519b20cdc503e",
    "fields": {
      "intitule_naf_65": "Activités des organisations patronales et consulaires",
      "intitule_naf": "Activités des organisations patronales et consulaires",
      "code_naf": "9411Z",
      "intitule_naf_40": "Act. organisations patronale & consul."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8ddf66dbdb5541ce3e08d85b0b41802b9b21d419",
    "fields": {
      "intitule_naf_65": "Activités des organisations professionnelles",
      "intitule_naf": "Activités des organisations professionnelles",
      "code_naf": "9412",
      "intitule_naf_40": "Act. des organisations professionnelles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c6824d113d0db94799f4f6cdcce2e66f07f06e9e",
    "fields": {
      "intitule_naf_65": "Activités des organisations religieuses",
      "intitule_naf": "Activités des organisations religieuses",
      "code_naf": "9491",
      "intitule_naf_40": "Activités des organisations religieuses"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "91ecce63aa9f37096615437f16967b34b4f4f114",
    "fields": {
      "intitule_naf_65": "Autres services personnels n.c.a.",
      "intitule_naf": "Autres services personnels n.c.a.",
      "code_naf": "9609",
      "intitule_naf_40": "Autres services personnels n.c.a."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "17ef289ec3eb8d6a15cf8cf71ad8f15ba8e8254d",
    "fields": {
      "intitule_naf_65": "Gestion de salles de spectacles",
      "intitule_naf": "Gestion de salles de spectacles",
      "code_naf": "9004Z",
      "intitule_naf_40": "Gestion de salles de spectacles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "79b44da63f7a96cd9cf22dd93ffdd108f55b9432",
    "fields": {
      "intitule_naf_65": "Activités de clubs de sports",
      "intitule_naf": "Activités de clubs de sports",
      "code_naf": "9312Z",
      "intitule_naf_40": "Activités de clubs de sports"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0a5601d0e2e8277907a1993db9d564b47b0a71cf",
    "fields": {
      "intitule_naf_65": "Création artistique",
      "intitule_naf": "Création artistique",
      "code_naf": "9003",
      "intitule_naf_40": "Création artistique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9834e38654316465750d82d39eac1bb9c2325a90",
    "fields": {
      "intitule_naf_65": "Activités de soutien à la production animale",
      "intitule_naf": "Activités de soutien à la production animale",
      "code_naf": "162",
      "intitule_naf_40": "Activités de soutien à la prod. animale"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4e1feec1b38b87904991e773d9becc455eb39999",
    "fields": {
      "intitule_naf_65": "Sylviculture et autres activités forestières",
      "intitule_naf": "Sylviculture et autres activités forestières",
      "code_naf": "21",
      "intitule_naf_40": "Sylviculture & autres act. forestières"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "32aa58c1df41fe78bf74c664fddac44fde4b40dd",
    "fields": {
      "intitule_naf_65": "Fabrication d'huiles et graisses raffinées",
      "intitule_naf": "Fabrication d'huiles et graisses raffinées",
      "code_naf": "1041B",
      "intitule_naf_40": "Fab. d'huiles et graisses raffinées"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4a37ff55ff49f0c2b922457e6d2c83454a5caf9e",
    "fields": {
      "intitule_naf_65": "Fabrication de pâtes alimentaires",
      "intitule_naf": "Fabrication de pâtes alimentaires",
      "code_naf": "1073",
      "intitule_naf_40": "Fabrication de pâtes alimentaires"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9dcd708b3b3881b663958bc0c461deecd2d4f7d4",
    "fields": {
      "intitule_naf_65": "Élevage de vaches laitières",
      "intitule_naf": "Élevage de vaches laitières",
      "code_naf": "141",
      "intitule_naf_40": "Élevage de vaches laitières"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "991d86179cc93c601ca5ae8f8cb1397577579f5c",
    "fields": {
      "intitule_naf_65": "Aquaculture en eau douce",
      "intitule_naf": "Aquaculture en eau douce",
      "code_naf": "322",
      "intitule_naf_40": "Aquaculture en eau douce"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "282a6e2178a2f62840404ff44f2cfa96f18dd2f1",
    "fields": {
      "intitule_naf_65": "Aquaculture en mer",
      "intitule_naf": "Aquaculture en mer",
      "code_naf": "321",
      "intitule_naf_40": "Aquaculture en mer"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0636a32c26ef6cc8a4aac9c43a3b6a83b9bb0e6b",
    "fields": {
      "intitule_naf_65": "Culture du tabac",
      "intitule_naf": "Culture du tabac",
      "code_naf": "0115Z",
      "intitule_naf_40": "Culture du tabac"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e2826e93f5942f4c9145975417dc5ace34680ca5",
    "fields": {
      "intitule_naf_65": "Fabrication objets divers en bois, liège, vannerie et sparterie",
      "intitule_naf": "Fabrication d'objets divers en bois ; fabrication d'objets en liège, vannerie et sparterie",
      "code_naf": "1629Z",
      "intitule_naf_40": "Fab. objet div. bois, liège, vann., etc."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b80081b9bea5bb127c58c2d07201aee670883397",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres produits minéraux non métalliques n.c.a.",
      "intitule_naf": "Fabrication d'autres produits minéraux non métalliques n.c.a.",
      "code_naf": "2399Z",
      "intitule_naf_40": "Fab. aut. prod. minéraux non métal. nca."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "fd196abeeb552576bdc0403b05cbbd75c27e1d07",
    "fields": {
      "intitule_naf_65": "Traitement et élimination des déchets non dangereux",
      "intitule_naf": "Traitement et élimination des déchets non dangereux",
      "code_naf": "3821Z",
      "intitule_naf_40": "Traitmnt & élimin. déchets non dangereux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "bc0d673f803dd2dd7d6269cf98801b7c9ff88662",
    "fields": {
      "intitule_naf_65": "Production, transport et distribution d'électricité",
      "intitule_naf": "Production, transport et distribution d'électricité",
      "code_naf": "351",
      "intitule_naf_40": "Prod., transport & distrib. électricité"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9fd2ca4b7a3c5d734899d6666ebd38195ac99b45",
    "fields": {
      "intitule_naf_65": "Construction d'autres ouvrages de génie civil",
      "intitule_naf": "Construction d'autres ouvrages de génie civil",
      "code_naf": "429",
      "intitule_naf_40": "Construc. autres ouvrages de génie civil"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "08a744824331f024e71a55cfa5ca4078bd7c2afb",
    "fields": {
      "intitule_naf_65": "Travaux de revêtement des sols et des murs",
      "intitule_naf": "Travaux de revêtement des sols et des murs",
      "code_naf": "4333Z",
      "intitule_naf_40": "Travaux revêtement des sols et des murs"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "78c26c45d26274aa3a2393759fa9a726622d902f",
    "fields": {
      "intitule_naf_65": "Collecte et traitement des eaux usées",
      "intitule_naf": "Collecte et traitement des eaux usées",
      "code_naf": "370",
      "intitule_naf_40": "Collecte et traitement des eaux usées"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8a94e2203c24cc88bef93eee45ad9be099a61ee5",
    "fields": {
      "intitule_naf_65": "Travaux de préparation des sites",
      "intitule_naf": "Travaux de préparation des sites",
      "code_naf": "4312",
      "intitule_naf_40": "Travaux de préparation des sites"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7c6835a755730b7a1b640e191d040bf450d56764",
    "fields": {
      "intitule_naf_65": "Commerce d'électricité",
      "intitule_naf": "Commerce d'électricité",
      "code_naf": "3514",
      "intitule_naf_40": "Commerce d'électricité"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9b7e31d923a424c38a8991bfc4587a55389f2956",
    "fields": {
      "intitule_naf_65": "Fabrication de meubles",
      "intitule_naf": "Fabrication de meubles",
      "code_naf": "31",
      "intitule_naf_40": "Fabrication de meubles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "68e46f833d0eab80ae5ad0722ad3e0d65ecec370",
    "fields": {
      "intitule_naf_65": "Fabrication d'équipements d'aide à la navigation",
      "intitule_naf": "Fabrication d'équipements d'aide à la navigation",
      "code_naf": "2651A",
      "intitule_naf_40": "Fab. équipement d'aide à la navigation"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "bcdc84e5913f9cfa3e254448012c48a21d26b7f4",
    "fields": {
      "intitule_naf_65": "Fabrication de condiments et assaisonnements",
      "intitule_naf": "Fabrication de condiments et assaisonnements",
      "code_naf": "1084",
      "intitule_naf_40": "Fabric. de condiments et assaisonnements"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5c314a864a1f233b097bb6ac9a4e7c97f89387b3",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres articles de robinetterie",
      "intitule_naf": "Fabrication d'autres articles de robinetterie",
      "code_naf": "2814Z",
      "intitule_naf_40": "Fabric. autres articles de robinetterie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5a8e1248ee06e15507771c98214a0ff7518ba4ac",
    "fields": {
      "intitule_naf_65": "Profilage à froid par formage ou pliage",
      "intitule_naf": "Profilage à froid par formage ou pliage",
      "code_naf": "2433",
      "intitule_naf_40": "Profilage à froid par formage ou pliage"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3bd6808dd466421fc13b15ca3c57e7b98e11bef0",
    "fields": {
      "intitule_naf_65": "Fabrication de produits pharmaceutiques de base",
      "intitule_naf": "Fabrication de produits pharmaceutiques de base",
      "code_naf": "211",
      "intitule_naf_40": "Fab. de produits pharmaceutiques de base"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "de6d7f561f8a216ae617db3bcdd78a9cdf9f1f20",
    "fields": {
      "intitule_naf_65": "Fabrication de serrures et de ferrures",
      "intitule_naf": "Fabrication de serrures et de ferrures",
      "code_naf": "2572Z",
      "intitule_naf_40": "Fabrication de serrures et de ferrures"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2c993bd12d38e876f2d59e32ab5e5548df936ed8",
    "fields": {
      "intitule_naf_65": "Fabrication de vêtements en cuir",
      "intitule_naf": "Fabrication de vêtements en cuir",
      "code_naf": "1411Z",
      "intitule_naf_40": "Fabrication de vêtements en cuir"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4fc99158f87975359042ea35eff0069b579264b3",
    "fields": {
      "intitule_naf_65": "Fabrication de malt",
      "intitule_naf": "Fabrication de malt",
      "code_naf": "1106",
      "intitule_naf_40": "Fabrication de malt"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0edd3b59b2dd6a5a74f7502ead90afeb76d10108",
    "fields": {
      "intitule_naf_65": "Fonderie de fonte",
      "intitule_naf": "Fonderie de fonte",
      "code_naf": "2451",
      "intitule_naf_40": "Fonderie de fonte"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7a362c172b213126466dd485571bfc69248c068d",
    "fields": {
      "intitule_naf_65": "Tissage",
      "intitule_naf": "Tissage",
      "code_naf": "1320Z",
      "intitule_naf_40": "Tissage"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3a1371b26fee2edf8b3510dede05caf642b51f8e",
    "fields": {
      "intitule_naf_65": "Comm. détail enreg. musicaux & vidéo (magasin spécialisé)",
      "intitule_naf": "Commerce de détail d'enregistrements musicaux et vidéo en magasin spécialisé",
      "code_naf": "4763",
      "intitule_naf_40": "Comm. dét. enreg. musicaux & vidéo (ms)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "fa608c2f809a35a3a1cefc3260a1c205aeac368a",
    "fields": {
      "intitule_naf_65": "Activ. poste dans le cadre d'une obligation de service universel",
      "intitule_naf": "Activités de poste dans le cadre d'une obligation de service universel",
      "code_naf": "5310",
      "intitule_naf_40": "Activ. poste (obligation sce universel)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e81945af1c114528fdf7ab31acce141cba2b318d",
    "fields": {
      "intitule_naf_65": "Commerce de détail de journaux et papeterie en magasin spécialisé",
      "intitule_naf": "Commerce de détail de journaux et papeterie en magasin spécialisé",
      "code_naf": "4762Z",
      "intitule_naf_40": "Comm. détail journaux & papeterie (ms)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c1e343c4d8e97264bb8ace3306cb33acd225b1fe",
    "fields": {
      "intitule_naf_65": "Commerce de gros (commerce interentreprises) de fleurs et plantes",
      "intitule_naf": "Commerce de gros (commerce interentreprises) de fleurs et plantes",
      "code_naf": "4622Z",
      "intitule_naf_40": "Commerce de gros de fleurs et plantes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8d4031bf8fcd5bf44838705cbfd584241947b710",
    "fields": {
      "intitule_naf_65": "Commerce de gros d'autres biens domestiques",
      "intitule_naf": "Commerce de gros (commerce interentreprises) d'autres biens domestiques",
      "code_naf": "4649Z",
      "intitule_naf_40": "Commerce gros d'autres biens domestiques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7d5903dd3fd870091e3544ae7e2644d3063235b9",
    "fields": {
      "intitule_naf_65": "Commerce de détail de biens d'occasion en magasin",
      "intitule_naf": "Commerce de détail de biens d'occasion en magasin",
      "code_naf": "4779Z",
      "intitule_naf_40": "Comm. détail biens d'occasion en magasin"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "59355e293ea92f1bd2e10ffc1e2e1c653896fc4f",
    "fields": {
      "intitule_naf_65": "Location de camions avec chauffeur",
      "intitule_naf": "Location de camions avec chauffeur",
      "code_naf": "4941C",
      "intitule_naf_40": "Location de camions avec chauffeur"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ea59f4bd7c7969c317e41484f9d3abbcfba70de0",
    "fields": {
      "intitule_naf_65": "Activ. agences voyage, voyagistes, serv. résa. & activ. connexes",
      "intitule_naf": "Activités des agences de voyage, voyagistes, services de réservation et activités connexes",
      "code_naf": "79",
      "intitule_naf_40": "Act .ag. voyage voyagiste sv. résa. etc."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c4e9c4084676d175df161263a71c559f8d00772f",
    "fields": {
      "intitule_naf_65": "Activités combinées de soutien lié aux bâtiments",
      "intitule_naf": "Activités combinées de soutien lié aux bâtiments",
      "code_naf": "8110Z",
      "intitule_naf_40": "Act. combinée soutien lié aux bâtiments"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a50ee56fd601c55272ae0e06b79051b65c5565b0",
    "fields": {
      "intitule_naf_65": "Location et location-bail de matériels de transport par eau",
      "intitule_naf": "Location et location-bail de matériels de transport par eau",
      "code_naf": "7734",
      "intitule_naf_40": "Loc. & loc.-bail mat. transport par eau"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ba0baa65b540d016f5684b1cea7fab2ddf26891a",
    "fields": {
      "intitule_naf_65": "Recherche-développement en sciences humaines et sociales",
      "intitule_naf": "Recherche-développement en sciences humaines et sociales",
      "code_naf": "722",
      "intitule_naf_40": "R&D en sciences humaines et sociales"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f53b2b4b84f6643784257b4b88ffd8236de0d48b",
    "fields": {
      "intitule_naf_65": "Autres activités de télécommunication",
      "intitule_naf": "Autres activités de télécommunication",
      "code_naf": "619",
      "intitule_naf_40": "Autres activités de télécommunication"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ba7ba00574c713584a7969540208dcdf49bcc997",
    "fields": {
      "intitule_naf_65": "Enseignement post-secondaire non supérieur",
      "intitule_naf": "Enseignement post-secondaire non supérieur",
      "code_naf": "8541Z",
      "intitule_naf_40": "Enseignement post-secondaire non sup."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "35bcc8aabea68f53a4c958ae7bdbe76685f9496b",
    "fields": {
      "intitule_naf_65": "Activités de location et location-bail",
      "intitule_naf": "Activités de location et location-bail",
      "code_naf": "77",
      "intitule_naf_40": "Activités de location et location-bail"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a78e427b95aaa49def9c8850067fec7654b6e093",
    "fields": {
      "intitule_naf_65": "Restauration de type rapide",
      "intitule_naf": "Restauration de type rapide",
      "code_naf": "5610C",
      "intitule_naf_40": "Restauration de type rapide"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f4b59bce11539c0d61d7de9ac4c2d71580f4d329",
    "fields": {
      "intitule_naf_65": "Édition de livres",
      "intitule_naf": "Édition de livres",
      "code_naf": "5811",
      "intitule_naf_40": "Édition de livres"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3b34387553f5829409f7f24482402011dc8d559c",
    "fields": {
      "intitule_naf_65": "Activ. indifférenciées ménages : producteurs biens (usage propre)",
      "intitule_naf": "Activités indifférenciées des ménages en tant que producteurs de biens pour usage propre",
      "code_naf": "9810Z",
      "intitule_naf_40": "Act. ménage : prod. biens (usage propre)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3bc09531e071dacf472c06741bfa276fb27d86cc",
    "fields": {
      "intitule_naf_65": "Activités des organisations religieuses",
      "intitule_naf": "Activités des organisations religieuses",
      "code_naf": "9491Z",
      "intitule_naf_40": "Activités des organisations religieuses"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "20876db6cecb1904769e3707eb5bf40bf74a6bb2",
    "fields": {
      "intitule_naf_65": "Activités de soutien à l'enseignement",
      "intitule_naf": "Activités de soutien à l'enseignement",
      "code_naf": "856",
      "intitule_naf_40": "Activités de soutien à l'enseignement"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "bc3b2754ccee8e788842052a00dc2a738d31b1a8",
    "fields": {
      "intitule_naf_65": "Hébergement médico-social et social",
      "intitule_naf": "Hébergement médico-social et social",
      "code_naf": "87",
      "intitule_naf_40": "Hébergement médico-social et social"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2be9772f18dffcd96c7983e01f5ab03071a74b62",
    "fields": {
      "intitule_naf_65": "Activités pour la santé humaine",
      "intitule_naf": "Activités pour la santé humaine",
      "code_naf": "86",
      "intitule_naf_40": "Activités pour la santé humaine"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "bbc1269ff2801f4c77969c1c554145edf14d0b15",
    "fields": {
      "intitule_naf_65": "Services funéraires",
      "intitule_naf": "Services funéraires",
      "code_naf": "9603Z",
      "intitule_naf_40": "Services funéraires"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f8db3ece2b7b590aadd20b37a63a888db2e3c304",
    "fields": {
      "intitule_naf_65": "Ambulances",
      "intitule_naf": "Ambulances",
      "code_naf": "8690A",
      "intitule_naf_40": "Ambulances"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "569291ab5869f342b2b04f2865189ed09b4c6fdf",
    "fields": {
      "intitule_naf_65": "Coiffure",
      "intitule_naf": "Coiffure",
      "code_naf": "9602A",
      "intitule_naf_40": "Coiffure"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a5b638e788eaff30a6dc729d63a2b4c8414ea8dc",
    "fields": {
      "intitule_naf_65": "Fabrication moteurs & turbines sf moteurs d'avions & de véhicules",
      "intitule_naf": "Fabrication de moteurs et turbines, à l'exception des moteurs d'avions et de véhicules",
      "code_naf": "2811",
      "intitule_naf_40": "Fab. moteur & turb. sf pr avion & véhic."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "220844a9a3547936feba9a5cc50d08be686c7b77",
    "fields": {
      "intitule_naf_65": "Fabric. tubes, tuyaux, profilés creux & accessoir. corresp. acier",
      "intitule_naf": "Fabrication de tubes, tuyaux, profilés creux et accessoires correspondants en acier",
      "code_naf": "2420Z",
      "intitule_naf_40": "Fab. tube, profilé creux etc. en acier"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "273f4b704e35dbc55e32e3421d4cbe239c307134",
    "fields": {
      "intitule_naf_65": "Fabric. tubes, tuyaux, profilés creux & accessoir. corresp. acier",
      "intitule_naf": "Fabrication de tubes, tuyaux, profilés creux et accessoires correspondants en acier",
      "code_naf": "242",
      "intitule_naf_40": "Fab. tube, profilé creux etc. en acier"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a61f882f0edafd024d76273ae8af46c439e0f8be",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres fils et câbles électroniques ou électriques",
      "intitule_naf": "Fabrication d'autres fils et câbles électroniques ou électriques",
      "code_naf": "2732Z",
      "intitule_naf_40": "Fab. aut. fil & câble éltron. ou éltriq."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "cc55fec72666d44a9faae4862c6e26f8f6894192",
    "fields": {
      "intitule_naf_65": "Fabrication de fûts et emballages métalliques similaires",
      "intitule_naf": "Fabrication de fûts et emballages métalliques similaires",
      "code_naf": "2591Z",
      "intitule_naf_40": "Fab. fût & emballage métalliq. similaire"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ff3cf59f75a7433b6321f99e309b64eee7bf72e4",
    "fields": {
      "intitule_naf_65": "Fabrication de matériels optique et photographique",
      "intitule_naf": "Fabrication de matériels optique et photographique",
      "code_naf": "267",
      "intitule_naf_40": "Fab. matériel optique et photographique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4dc6fb81d8c45cbaa9244c8a6841b69541f392b1",
    "fields": {
      "intitule_naf_65": "Fabrication de produits électroniques grand public",
      "intitule_naf": "Fabrication de produits électroniques grand public",
      "code_naf": "2640",
      "intitule_naf_40": "Fab. produit électronique grand public"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2d6ba12d729c2d75543bd0ff50e340b95aad587c",
    "fields": {
      "intitule_naf_65": "Traitement et revêtement des métaux; usinage",
      "intitule_naf": "Traitement et revêtement des métaux ; usinage",
      "code_naf": "256",
      "intitule_naf_40": "Traitmnt & revêtmnt des métaux; usinage"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "6a51fcdb5d927b477f5e7e22bf4d1835578dfa46",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres matériels électriques",
      "intitule_naf": "Fabrication d'autres matériels électriques",
      "code_naf": "2790Z",
      "intitule_naf_40": "Fabric. d'autres matériels électriques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "71090c5def901b5539c23ac4c3b6894bae8564de",
    "fields": {
      "intitule_naf_65": "Fabrication et rechapage de pneumatiques",
      "intitule_naf": "Fabrication et rechapage de pneumatiques",
      "code_naf": "2211",
      "intitule_naf_40": "Fabrication et rechapage de pneumatiques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8081cfd00c57a30028fc8f76362107318e5cde81",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres produits chimiques organiques de base",
      "intitule_naf": "Fabrication d'autres produits chimiques organiques de base",
      "code_naf": "2014Z",
      "intitule_naf_40": "Fab. aut. prod. chimique org. de base"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "fd3a49d399bdc7355135939300a71a3be5b81b0a",
    "fields": {
      "intitule_naf_65": "Fabrication de vêtements, autres qu'en fourrure",
      "intitule_naf": "Fabrication de vêtements, autres qu'en fourrure",
      "code_naf": "141",
      "intitule_naf_40": "Fab. de vêtements, autres qu'en fourrure"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "32b7a565ec8fa8e9c5e12359b628ce85ce06d919",
    "fields": {
      "intitule_naf_65": "Fabrication de produits en caoutchouc et en plastique",
      "intitule_naf": "Fabrication de produits en caoutchouc et en plastique",
      "code_naf": "22",
      "intitule_naf_40": "Fab. prod. en caoutchouc & en plastique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "79e5e93cbb8234487f69f51df1042470c3e5bc41",
    "fields": {
      "intitule_naf_65": "Fabrication de non-tissés, sauf habillement",
      "intitule_naf": "Fabrication de non-tissés, sauf habillement",
      "code_naf": "1395",
      "intitule_naf_40": "Fabric. de non-tissés, sauf habillement"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "744fd36f8cd5e3e476536913c0039f881f524eff",
    "fields": {
      "intitule_naf_65": "Fabrication de colorants et de pigments",
      "intitule_naf": "Fabrication de colorants et de pigments",
      "code_naf": "2012Z",
      "intitule_naf_40": "Fabrication de colorants et de pigments"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "dff8a34c702ec418664acb5710786f818ba65605",
    "fields": {
      "intitule_naf_65": "Fabrication de ficelles, cordes et filets",
      "intitule_naf": "Fabrication de ficelles, cordes et filets",
      "code_naf": "1394",
      "intitule_naf_40": "Fabric. de ficelles, cordes et filets"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9ec08105b85613b3789ef8c64d82f38a0dd5ceb5",
    "fields": {
      "intitule_naf_65": "Fabrication de vêtements de travail",
      "intitule_naf": "Fabrication de vêtements de travail",
      "code_naf": "1412",
      "intitule_naf_40": "Fabrication de vêtements de travail"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9a448ee2d8cc09c49f1e432112477084b6b69bae",
    "fields": {
      "intitule_naf_65": "Fabrication de chaussures",
      "intitule_naf": "Fabrication de chaussures",
      "code_naf": "1520",
      "intitule_naf_40": "Fabrication de chaussures"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7343ab47e7686d4a03ddd5201acff3b0b9711e97",
    "fields": {
      "intitule_naf_65": "Autre imprimerie (labeur)",
      "intitule_naf": "Autre imprimerie (labeur)",
      "code_naf": "1812",
      "intitule_naf_40": "Autre imprimerie (labeur)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "eeffc6c804d176562ecf5eaa30748584a577d496",
    "fields": {
      "intitule_naf_65": "Fabrication de beurre",
      "intitule_naf": "Fabrication de beurre",
      "code_naf": "1051B",
      "intitule_naf_40": "Fabrication de beurre"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "83e77b5f8169819bdee52920e84f483dd1ca6720",
    "fields": {
      "intitule_naf_65": "Collecte, traitement et élimination des déchets ; récupération",
      "intitule_naf": "Collecte, traitement et élimination des déchets ; récupération",
      "code_naf": "38",
      "intitule_naf_40": "Collecte, gestion déchets ; récupération"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "cbb9e1df46eb9b2d9683637c1fa2b0a0cc5bd893",
    "fields": {
      "intitule_naf_65": "Fabrication d'engrenages et d'organes mécaniques de transmission",
      "intitule_naf": "Fabrication d'engrenages et d'organes mécaniques de transmission",
      "code_naf": "2815",
      "intitule_naf_40": "Fab. engrenage & organe méca. transmis."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "cd7e31d99ab456f4aa6011d93d9f173bb1a2e8d3",
    "fields": {
      "intitule_naf_65": "Travaux d'installation électrique sur la voie publique",
      "intitule_naf": "Travaux d'installation électrique sur la voie publique",
      "code_naf": "4321B",
      "intitule_naf_40": "Travaux instal. électriq. sr voie publi."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "cb0319df5a585e7542d2522c2ea67e11bd3811f7",
    "fields": {
      "intitule_naf_65": "Construction de véhicules militaires de combat",
      "intitule_naf": "Construction de véhicules militaires de combat",
      "code_naf": "3040",
      "intitule_naf_40": "Constr. véhicules militaires de combat"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2a815a9fef3912497dc4b4f09028f1332771fbc7",
    "fields": {
      "intitule_naf_65": "Construction de bateaux de plaisance",
      "intitule_naf": "Construction de bateaux de plaisance",
      "code_naf": "3012",
      "intitule_naf_40": "Construction de bateaux de plaisance"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4871e9c9ad59392723bcae7b739beee85cfab122",
    "fields": {
      "intitule_naf_65": "Réparation d'ouvrages en métaux",
      "intitule_naf": "Réparation d'ouvrages en métaux",
      "code_naf": "3311",
      "intitule_naf_40": "Réparation d'ouvrages en métaux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "485ce2c843cfe85f82bb523c4cd878f2f819402d",
    "fields": {
      "intitule_naf_65": "Construction navale",
      "intitule_naf": "Construction navale",
      "code_naf": "301",
      "intitule_naf_40": "Construction navale"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "357fa7a01f2c2331245c07d59b18e364903335eb",
    "fields": {
      "intitule_naf_65": "Commerce de gros d'appareils électroménagers",
      "intitule_naf": "Commerce de gros (commerce interentreprises) d'appareils électroménagers",
      "code_naf": "4643Z",
      "intitule_naf_40": "Commerce de gros appareil électroménager"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f875743b490730b35e44b544f9f34cb4379f302c",
    "fields": {
      "intitule_naf_65": "Entretien et réparation de véhicules automobiles légers",
      "intitule_naf": "Entretien et réparation de véhicules automobiles légers",
      "code_naf": "4520A",
      "intitule_naf_40": "Entretien & répar. véhicule auto. léger"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b437f9459c03ed0b74a5591f9d6c6e63cbbaf0a8",
    "fields": {
      "intitule_naf_65": "Commerce de gros de viandes et de produits à base de viande",
      "intitule_naf": "Commerce de gros de viandes et de produits à base de viande",
      "code_naf": "4632",
      "intitule_naf_40": "Comm. gros viande & prod. à base  viande"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e6d4a27df47eb9ba90657593e844b8548e878758",
    "fields": {
      "intitule_naf_65": "Comm. gros produits alimentaires boissons & tabac",
      "intitule_naf": "Commerce de gros de produits alimentaires, de boissons et de tabac",
      "code_naf": "463",
      "intitule_naf_40": "Comm. gros prod. alim. boisson & tabac"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "55ef31df4af90a0ffd9b202de9a1d4eca6d4a1d5",
    "fields": {
      "intitule_naf_65": "Commerce et réparation d'automobiles et de motocycles",
      "intitule_naf": "Commerce et réparation d'automobiles et de motocycles",
      "code_naf": "45",
      "intitule_naf_40": "Commerce & répar. automobile & motocycle"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "00a854b3dd0beaa584c7fe62ebac4191baa634e7",
    "fields": {
      "intitule_naf_65": "Commerce de gros (commerce interentreprises) de cuirs et peaux",
      "intitule_naf": "Commerce de gros (commerce interentreprises) de cuirs et peaux",
      "code_naf": "4624Z",
      "intitule_naf_40": "Commerce de gros de cuirs et peaux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7e4abc792d17435c8d71e573f1c94a37ad62d209",
    "fields": {
      "intitule_naf_65": "Autres travaux de construction spécialisés",
      "intitule_naf": "Autres travaux de construction spécialisés",
      "code_naf": "439",
      "intitule_naf_40": "Autres travaux construction spécialisés"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0b3c17f24805c0ba6f1f80359ce4aaefd977ad32",
    "fields": {
      "intitule_naf_65": "Commerce de gros de matériel agricole",
      "intitule_naf": "Commerce de gros de matériel agricole",
      "code_naf": "4661",
      "intitule_naf_40": "Commerce de gros de matériel agricole"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a55af24d597d4f82e8c9b4599cd132cc3c318dc2",
    "fields": {
      "intitule_naf_65": "Autres travaux de finition",
      "intitule_naf": "Autres travaux de finition",
      "code_naf": "4339Z",
      "intitule_naf_40": "Autres travaux de finition"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "00309e8056af1c3d2cea499c32d1193858dc3485",
    "fields": {
      "intitule_naf_65": "Culture plantes à épices aromatiques médicinales pharmaceutiques",
      "intitule_naf": "Culture de plantes à épices, aromatiques, médicinales et pharmaceutiques",
      "code_naf": "128",
      "intitule_naf_40": "Cult. plante aromatiq. médicin. pharma."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9ccd066e7f17f54176ea6c8117668bd4076a5c34",
    "fields": {
      "intitule_naf_65": "Culture d'aut. fruits d'arbres ou d'arbustes et de fruits a coque",
      "intitule_naf": "Culture d'autres fruits d'arbres ou d'arbustes et de fruits à coque",
      "code_naf": "0125Z",
      "intitule_naf_40": "Cult. d'aut. fruits & de fruits à coque"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a42e78126e6e49fac3c4a16bd27ea648824729a0",
    "fields": {
      "intitule_naf_65": "Élevage de chameaux et d'autres camélidés",
      "intitule_naf": "Élevage de chameaux et d'autres camélidés",
      "code_naf": "144",
      "intitule_naf_40": "Élevage de chameaux & d'autres camélidés"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "38c7b1106a6a831cce77498e24154c1e3cd84cf7",
    "fields": {
      "intitule_naf_65": "Préparation de jus de fruits et légumes",
      "intitule_naf": "Préparation de jus de fruits et légumes",
      "code_naf": "1032",
      "intitule_naf_40": "Préparation de jus de fruits et légumes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "bd1de48ff0b54466ad5dd6c4373d1acbfe2a404e",
    "fields": {
      "intitule_naf_65": "Culture de fruits à pépins et à noyau",
      "intitule_naf": "Culture de fruits à pépins et à noyau",
      "code_naf": "0124Z",
      "intitule_naf_40": "Culture de fruits à pépins et à noyau"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3fee572e3552b6fbc382eb33f3c6b90424c49e29",
    "fields": {
      "intitule_naf_65": "Extraction de lignite",
      "intitule_naf": "Extraction de lignite",
      "code_naf": "52",
      "intitule_naf_40": "Extraction de lignite"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c79cfa03f44313f36ef4a10e1ced4d3d0c647c1c",
    "fields": {
      "intitule_naf_65": "Commerce de détail de fruits et légumes en magasin spécialisé",
      "intitule_naf": "Commerce de détail de fruits et légumes en magasin spécialisé",
      "code_naf": "4721",
      "intitule_naf_40": "Com. détail fruit & légume en mag. spéc."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9cc9e63724a93d2c6f75b8767b8fd27b2502816c",
    "fields": {
      "intitule_naf_65": "Comm. détail de produits à base de tabac en magasin spécialisé",
      "intitule_naf": "Commerce de détail de produits à base de tabac en magasin spécialisé",
      "code_naf": "4726Z",
      "intitule_naf_40": "Comm. dét. produit à base de tabac (ms)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0f4d3c73c4ff1de160328bb1fcaa813fe813ffd3",
    "fields": {
      "intitule_naf_65": "Comm. détail quincaillerie peintures & verres (magasin spéc.)",
      "intitule_naf": "Commerce de détail de quincaillerie, peintures et verres en magasin spécialisé",
      "code_naf": "4752",
      "intitule_naf_40": "Comm. dét. quinca. peinture & verre (ms)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f3c697af1729c8fe8ffadabbf4144912c439dae5",
    "fields": {
      "intitule_naf_65": "Commerce de détail d'habillement en magasin spécialisé",
      "intitule_naf": "Commerce de détail d'habillement en magasin spécialisé",
      "code_naf": "4771Z",
      "intitule_naf_40": "Com. dét. habillement en mag. spécialisé"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9fa2d75862fd0f0afa3393cabf03f1767ef7dd94",
    "fields": {
      "intitule_naf_65": "Services de déménagement",
      "intitule_naf": "Services de déménagement",
      "code_naf": "4942Z",
      "intitule_naf_40": "Services de déménagement"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "87cbd75d13ef9296eb07b760da0ff65b983ac43f",
    "fields": {
      "intitule_naf_65": "Hébergement touristique et autre hébergement de courte durée",
      "intitule_naf": "Hébergement touristique et autre hébergement de courte durée",
      "code_naf": "552",
      "intitule_naf_40": "Hébergt tourist. & aut. hbt courte durée"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0d1e638bff10eec9b3418519bcc23069b13ce819",
    "fields": {
      "intitule_naf_65": "Autres services d'information",
      "intitule_naf": "Autres services d'information",
      "code_naf": "639",
      "intitule_naf_40": "Autres services d'information"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "dcef9cd1851e5f6d1cbf8c5e584d74db843b034c",
    "fields": {
      "intitule_naf_65": "Autres services d'information n.c.a.",
      "intitule_naf": "Autres services d'information n.c.a.",
      "code_naf": "6399Z",
      "intitule_naf_40": "Autres services d'information n.c.a."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b17857b434fe840291046dcf38704f723ee94da8",
    "fields": {
      "intitule_naf_65": "Crédit-bail",
      "intitule_naf": "Crédit-bail",
      "code_naf": "6491Z",
      "intitule_naf_40": "Crédit-bail"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "52135f589f2b70bdce557559908631b26b8b95ab",
    "fields": {
      "intitule_naf_65": "Location et exploitation de biens immobiliers propres ou loués",
      "intitule_naf": "Location et exploitation de biens immobiliers propres ou loués",
      "code_naf": "6820",
      "intitule_naf_40": "Loc. & exploi. bien immo. propre ou loué"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a0a9f573f57c48eb90a34177fcdf172e4e3d6719",
    "fields": {
      "intitule_naf_65": "Activ.  architecture & ingénierie; contrôle & analyses techniques",
      "intitule_naf": "Activités d'architecture et d'ingénierie ; activités de contrôle et analyses techniques",
      "code_naf": "71",
      "intitule_naf_40": "Architec. & ingénierie; ctrle ana. tech."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ad31dc5b4ff698cb9d5c9703b7a48f6d5d165e2d",
    "fields": {
      "intitule_naf_65": "Recherche-développement scientifique",
      "intitule_naf": "Recherche-développement scientifique",
      "code_naf": "72",
      "intitule_naf_40": "Recherche-développement scientifique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "db8b290d27a3356b1af9d03d1ed9337ef60a0636",
    "fields": {
      "intitule_naf_65": "Location et location-bail machines bureau & matériel informatique",
      "intitule_naf": "Location et location-bail de machines de bureau et de matériel informatique",
      "code_naf": "7733",
      "intitule_naf_40": "Loc. & loc.-bail mach. bur. & mat. info."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3387ea8e92fec56a4ceda9b0256598880eb64fdf",
    "fields": {
      "intitule_naf_65": "Activités des agences de placement de main-d'oeuvre",
      "intitule_naf": "Activités des agences de placement de main-d'œuvre",
      "code_naf": "7810Z",
      "intitule_naf_40": "Activ. agence placement de main-d'oeuvre"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4354abb21f31ad8ce5e2923c814281fc8ddf5368",
    "fields": {
      "intitule_naf_65": "Autre mise à disposition de ressources humaines",
      "intitule_naf": "Autre mise à disposition de ressources humaines",
      "code_naf": "7830",
      "intitule_naf_40": "Aut. mise à dispo. de ressource humaine"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d3598f201df3a741da52c57601e0a99c417991f7",
    "fields": {
      "intitule_naf_65": "Administration publique (tutelle) des activités économiques",
      "intitule_naf": "Administration publique (tutelle) des activités économiques",
      "code_naf": "8413",
      "intitule_naf_40": "Adm. publique des activités économiques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "fc98514c4a059b01dcebe23398e73af8fcc638ad",
    "fields": {
      "intitule_naf_65": "Enseignement",
      "intitule_naf": "Enseignement",
      "code_naf": "85",
      "intitule_naf_40": "Enseignement"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "07fb2db36489b5d9829eeb378a7f0971236eaacc",
    "fields": {
      "intitule_naf_65": "Enseignement secondaire technique ou professionnel",
      "intitule_naf": "Enseignement secondaire technique ou professionnel",
      "code_naf": "8532",
      "intitule_naf_40": "Enseignemt secondaire techn. ou profess."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "07825e450754e50e862fa141b5123b699435b0a7",
    "fields": {
      "intitule_naf_65": "Hébergement médicalisé",
      "intitule_naf": "Hébergement médicalisé",
      "code_naf": "871",
      "intitule_naf_40": "Hébergement médicalisé"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "331564af23ce8f9a22272975163d613c31348f96",
    "fields": {
      "intitule_naf_65": "Activités des organisations patronales et consulaires",
      "intitule_naf": "Activités des organisations patronales et consulaires",
      "code_naf": "9411",
      "intitule_naf_40": "Act. organisations patronale & consul."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1489443a40b5333f464ebfc2f2ec31a0b3b4e826",
    "fields": {
      "intitule_naf_65": "Activités des organisations et organismes extraterritoriaux",
      "intitule_naf": "Activités des organisations et organismes extraterritoriaux",
      "code_naf": "990",
      "intitule_naf_40": "Act. organisations extraterritoriales"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "317102204c75fef8fdb569e8869b0535555704c1",
    "fields": {
      "intitule_naf_65": "Commerce de gros d'autres produits intermédiaires",
      "intitule_naf": "Commerce de gros d'autres produits intermédiaires",
      "code_naf": "4676",
      "intitule_naf_40": "Commerce gros d'aut. prod. intermédiaire"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e09cefe19441b389393c33d1ea253295165db439",
    "fields": {
      "intitule_naf_65": "Magasins multi-commerces",
      "intitule_naf": "Magasins multi-commerces",
      "code_naf": "4711E",
      "intitule_naf_40": "Magasins multi-commerces"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3cf8530c11c88fcb6e5edf9bd9ff942fd4499234",
    "fields": {
      "intitule_naf_65": "Récolte prod. forestiers non ligneux poussant à l'état sauvage",
      "intitule_naf": "Récolte de produits forestiers non ligneux poussant à l'état sauvage",
      "code_naf": "230",
      "intitule_naf_40": "Récolte prodts forestiers non ligneux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b036bd18e353603e14b7ced05229aa63ded8a4c4",
    "fields": {
      "intitule_naf_65": "Extraction d'autres minerais de métaux non ferreux",
      "intitule_naf": "Extraction d'autres minerais de métaux non ferreux",
      "code_naf": "0729Z",
      "intitule_naf_40": "Extr. aut. minerai de métaux non ferreux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1326deb03d27d3f8acd737e0540bc5d932b057ba",
    "fields": {
      "intitule_naf_65": "Activités de soutien à la production animale",
      "intitule_naf": "Activités de soutien à la production animale",
      "code_naf": "0162Z",
      "intitule_naf_40": "Activités de soutien à la prod. animale"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c10e1934861164484bb0ca0fb2a0dee03da81154",
    "fields": {
      "intitule_naf_65": "Élevage de chevaux et d'autres équidés",
      "intitule_naf": "Élevage de chevaux et d'autres équidés",
      "code_naf": "0143Z",
      "intitule_naf_40": "Élevage de chevaux et d'autres équidés"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8bb7e6fb0b650356b373860bcb8ee16483437100",
    "fields": {
      "intitule_naf_65": "Élevage de vaches laitières",
      "intitule_naf": "Élevage de vaches laitières",
      "code_naf": "0141Z",
      "intitule_naf_40": "Élevage de vaches laitières"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3f6d8667b41cc6c0800f773c457ad6d5b9cab939",
    "fields": {
      "intitule_naf_65": "Extraction de gaz naturel",
      "intitule_naf": "Extraction de gaz naturel",
      "code_naf": "62",
      "intitule_naf_40": "Extraction de gaz naturel"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7b48bcdf811790e11598adde8f1a3672459fbe17",
    "fields": {
      "intitule_naf_65": "Élevage d'autres animaux",
      "intitule_naf": "Élevage d'autres animaux",
      "code_naf": "149",
      "intitule_naf_40": "Élevage d'autres animaux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ff203ea23f40768cb8727668a0bbf8e5cdbe7bb4",
    "fields": {
      "intitule_naf_65": "Fabrication de pièces techniques à base de matières plastiques",
      "intitule_naf": "Fabrication de pièces techniques à base de matières plastiques",
      "code_naf": "2229A",
      "intitule_naf_40": "Fab. pièce techniq. base mat. plastiq."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a8378505d4aa2866bebd4f690321f760ce315355",
    "fields": {
      "intitule_naf_65": "Fabrication de pesticides et d'autres produits agrochimiques",
      "intitule_naf": "Fabrication de pesticides et d'autres produits agrochimiques",
      "code_naf": "2020",
      "intitule_naf_40": "Fab. pesticide & aut. prod. agrochimique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "21d1a4983e04c9608a325992aee42d4c85853374",
    "fields": {
      "intitule_naf_65": "Fabrication de fibres artificielles ou synthétiques",
      "intitule_naf": "Fabrication de fibres artificielles ou synthétiques",
      "code_naf": "2060",
      "intitule_naf_40": "Fab.  fibre artificielle ou synthétique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e49992b9684ab935476ad82fff3ab732fadf69e6",
    "fields": {
      "intitule_naf_65": "Travail des grains ; fabrication de produits amylacés",
      "intitule_naf": "Travail des grains ; fabrication de produits amylacés",
      "code_naf": "106",
      "intitule_naf_40": "Travail des grains ; fab. prod. amylacé"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4486e25f3471b35da9d3a6a90d0a7d3a0d901dda",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres produits chimiques organiques de base",
      "intitule_naf": "Fabrication d'autres produits chimiques organiques de base",
      "code_naf": "2014",
      "intitule_naf_40": "Fab. aut. prod. chimique org. de base"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "244ad47126bbcadb0a975dc8471c2c26c307fb12",
    "fields": {
      "intitule_naf_65": "Fabrication d'articles chaussants à mailles",
      "intitule_naf": "Fabrication d'articles chaussants à mailles",
      "code_naf": "1431Z",
      "intitule_naf_40": "Fabric. d'articles chaussants à mailles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4002c260eaebb3584a1d21064abc84039d510d52",
    "fields": {
      "intitule_naf_65": "Fabrication de ficelles, cordes et filets",
      "intitule_naf": "Fabrication de ficelles, cordes et filets",
      "code_naf": "1394Z",
      "intitule_naf_40": "Fabric. de ficelles, cordes et filets"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0006180e1714d101e9e734297ace2f16ac13eb2a",
    "fields": {
      "intitule_naf_65": "Fabrication de produits à base de tabac",
      "intitule_naf": "Fabrication de produits à base de tabac",
      "code_naf": "12",
      "intitule_naf_40": "Fabrication de produits à base de tabac"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "60c87645ac7b32a216d974b712b7c09051db2675",
    "fields": {
      "intitule_naf_65": "Transformation du thé et du café",
      "intitule_naf": "Transformation du thé et du café",
      "code_naf": "1083Z",
      "intitule_naf_40": "Transformation du thé et du café"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0fdf1f18aaaf890ab1d5564e8d3434fcea931186",
    "fields": {
      "intitule_naf_65": "Sciage et rabotage du bois",
      "intitule_naf": "Sciage et rabotage du bois",
      "code_naf": "1610",
      "intitule_naf_40": "Sciage et rabotage du bois"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c473282ff732bbe7238e60170b1bd0c404e0069c",
    "fields": {
      "intitule_naf_65": "Fabric. générateurs vapeur sf chaudières pour chauffage central",
      "intitule_naf": "Fabrication de générateurs de vapeur, à l'exception des chaudières pour le chauffage central",
      "code_naf": "2530",
      "intitule_naf_40": "Fab. générat. vapeur sf pr chauff. ctral"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0a11e053f880d19ef7849bce83b9656630a5929c",
    "fields": {
      "intitule_naf_65": "Production de métaux précieux et d'autres métaux non ferreux",
      "intitule_naf": "Production de métaux précieux et d'autres métaux non ferreux",
      "code_naf": "244",
      "intitule_naf_40": "Prod. métaux précieux & aut. m. non fer."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4fdc6d44cdf793df392217bfc1e8d8f89205f1e0",
    "fields": {
      "intitule_naf_65": "Fabrication de supports magnétiques et optiques",
      "intitule_naf": "Fabrication de supports magnétiques et optiques",
      "code_naf": "268",
      "intitule_naf_40": "Fab. de supports magnétiques et optiques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f9e72f28c20c0ad0fd89a5bc40fb037a5667ad53",
    "fields": {
      "intitule_naf_65": "Fabrication d'équipements de communication",
      "intitule_naf": "Fabrication d'équipements de communication",
      "code_naf": "2630",
      "intitule_naf_40": "Fabric. d'équipements de communication"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0609638de2df1b80b49d4d448b06784d565ca136",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres matériels électriques",
      "intitule_naf": "Fabrication d'autres matériels électriques",
      "code_naf": "2790",
      "intitule_naf_40": "Fabric. d'autres matériels électriques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5c709ddcf322694b84ce6aec79b67448d203f291",
    "fields": {
      "intitule_naf_65": "Construction de véhicules automobiles",
      "intitule_naf": "Construction de véhicules automobiles",
      "code_naf": "2910",
      "intitule_naf_40": "Construction de véhicules automobiles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2cd6b5e674a2f40e9a68463c0b2b1db984b8c6e0",
    "fields": {
      "intitule_naf_65": "Fonderie d'autres métaux non ferreux",
      "intitule_naf": "Fonderie d'autres métaux non ferreux",
      "code_naf": "2454Z",
      "intitule_naf_40": "Fonderie d'autres métaux non ferreux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7c4688cf4c343a9466ee0084b00b9779778ada4d",
    "fields": {
      "intitule_naf_65": "Mécanique industrielle",
      "intitule_naf": "Mécanique industrielle",
      "code_naf": "2562B",
      "intitule_naf_40": "Mécanique industrielle"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "140834608fed203b5a366bbd8f4b02fa49005736",
    "fields": {
      "intitule_naf_65": "Instal. éqpts électriq, mat. électro. et optiq. ou aut. matériels",
      "intitule_naf": "Installation d'équipements électriques, de matériels électroniques et optiques ou d'autres matériels",
      "code_naf": "3320D",
      "intitule_naf_40": "Inst. éqpt élec. électro. optiq. ou aut."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "cccf6641987e7585f0eaf391a70a4e5b5122537a",
    "fields": {
      "intitule_naf_65": "Construction de réseaux électriques et de télécommunications",
      "intitule_naf": "Construction de réseaux électriques et de télécommunications",
      "code_naf": "4222Z",
      "intitule_naf_40": "Const. réseaux électriq. & de télécom."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a7cab00263f39db57b595f90629193d92b7fd7e7",
    "fields": {
      "intitule_naf_65": "Travaux install. électrique plomberie & autres travaux d'install.",
      "intitule_naf": "Travaux d'installation électrique, plomberie et autres travaux d'installation",
      "code_naf": "432",
      "intitule_naf_40": "Travaux install. élect. plomberie & aut."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "63aaa4072ce82160e62452973bdd15daa9b2cb98",
    "fields": {
      "intitule_naf_65": "Construction de voies ferrées de surface et souterraines",
      "intitule_naf": "Construction de voies ferrées de surface et souterraines",
      "code_naf": "4212Z",
      "intitule_naf_40": "Const. voie ferrée surface & souterraine"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5358b14fef19860ca2ccad12d51265b24670373b",
    "fields": {
      "intitule_naf_65": "Réparation et installation de machines et d'équipements",
      "intitule_naf": "Réparation et installation de machines et d'équipements",
      "code_naf": "33",
      "intitule_naf_40": "Réparation & install. machine & équipt"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b589f8a4f7666b0ca015064c7a36e44b51eb097f",
    "fields": {
      "intitule_naf_65": "Construction d'ouvrages maritimes et fluviaux",
      "intitule_naf": "Construction d'ouvrages maritimes et fluviaux",
      "code_naf": "4291",
      "intitule_naf_40": "Construc. ouvrages maritimes et fluviaux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a630acd4db8bc302523e4672ce7143c99e7bfe2e",
    "fields": {
      "intitule_naf_65": "Intermédiaires du commerce de gros",
      "intitule_naf": "Intermédiaires du commerce de gros",
      "code_naf": "461",
      "intitule_naf_40": "Intermédiaires du commerce de gros"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4162ccea222d5775af4c97151a4a776a354c58b7",
    "fields": {
      "intitule_naf_65": "Commerce de gros de boissons",
      "intitule_naf": "Commerce de gros de boissons",
      "code_naf": "4634",
      "intitule_naf_40": "Commerce de gros de boissons"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5c62e46edb2afebb48c5f16b82559e3102602d50",
    "fields": {
      "intitule_naf_65": "Production d'électricité",
      "intitule_naf": "Production d'électricité",
      "code_naf": "3511Z",
      "intitule_naf_40": "Production d'électricité"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d951f86fa0dd8d5494d593d018bd350c9e150bcd",
    "fields": {
      "intitule_naf_65": "Forages et sondages",
      "intitule_naf": "Forages et sondages",
      "code_naf": "4313Z",
      "intitule_naf_40": "Forages et sondages"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c366d18dc52f83d5f526e27ec2c8d6783b5f4c94",
    "fields": {
      "intitule_naf_65": "Comm. détail poissons crustacés & mollusques (magasin spécialisé)",
      "intitule_naf": "Commerce de détail de poissons, crustacés et mollusques en magasin spécialisé",
      "code_naf": "4723",
      "intitule_naf_40": "Comm. détail poisson crustacé etc. (ms)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f859a6ff49b2878ba81b5407c27d649f48a61963",
    "fields": {
      "intitule_naf_65": "Commerce de gros de bois et de matériaux de construction",
      "intitule_naf": "Commerce de gros (commerce interentreprises) de bois et de matériaux de construction",
      "code_naf": "4673A",
      "intitule_naf_40": "Com. gros bois & matériaux construction"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "065edf143d62caf974aa335fbf685ab96507d064",
    "fields": {
      "intitule_naf_65": "Commerce de gros d'autres machines et équipements de bureau",
      "intitule_naf": "Commerce de gros d'autres machines et équipements de bureau",
      "code_naf": "4666",
      "intitule_naf_40": "Com. gros autre machine & équipt bureau"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e43f735d3378c6993e2c59fc525afab9e84d4807",
    "fields": {
      "intitule_naf_65": "Comm. gros équipts de l'information et de la communication",
      "intitule_naf": "Commerce de gros d'équipements de l'information et de la communication",
      "code_naf": "465",
      "intitule_naf_40": "Comm. gros équipt. information & com."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "35efb08c2b6e9d942e6adcf3864ad28c9f7d37e6",
    "fields": {
      "intitule_naf_65": "Autres commerces de détail spécialisés divers",
      "intitule_naf": "Autres commerces de détail spécialisés divers",
      "code_naf": "4778C",
      "intitule_naf_40": "Autre commerce détail spécialisé divers"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "870935fcfc0c02efa4228e0a74205e1d9032736c",
    "fields": {
      "intitule_naf_65": "Commerce de gros d'appareils électroménagers",
      "intitule_naf": "Commerce de gros d'appareils électroménagers",
      "code_naf": "4643",
      "intitule_naf_40": "Commerce de gros appareil électroménager"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1c9d6baf7cb14d4ff74b8b09c779abceea1ae6c6",
    "fields": {
      "intitule_naf_65": "Commerce de détail de produits surgelés",
      "intitule_naf": "Commerce de détail de produits surgelés",
      "code_naf": "4711A",
      "intitule_naf_40": "Commerce de détail de produits surgelés"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "304573c60b4634c45e88aa1ef05345f6710cfcec",
    "fields": {
      "intitule_naf_65": "Autres transports terrestres de voyageurs",
      "intitule_naf": "Autres transports terrestres de voyageurs",
      "code_naf": "493",
      "intitule_naf_40": "Autres transports terrestres de voyageur"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7c3bea3f11c792c2cd79636f5dc670dbbad5c800",
    "fields": {
      "intitule_naf_65": "Activ. services financiers, hors assurance & caisses de retraite",
      "intitule_naf": "Activités des services financiers, hors assurance et caisses de retraite",
      "code_naf": "64",
      "intitule_naf_40": "Act. financ. hs assur. & cais. retraite"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "02c40950fe67a27c757562a9331be7e58d3eb634",
    "fields": {
      "intitule_naf_65": "Édition de répertoires et de fichiers d'adresses",
      "intitule_naf": "Édition de répertoires et de fichiers d'adresses",
      "code_naf": "5812",
      "intitule_naf_40": "Édition répertoires & fichiers d'adresse"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5a1e18735575de116a2019cc2442c991b80ab63a",
    "fields": {
      "intitule_naf_65": "Conseil en relations publiques et communication",
      "intitule_naf": "Conseil en relations publiques et communication",
      "code_naf": "7021Z",
      "intitule_naf_40": "Conseil en relation publique & communic."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f22be6e90749484e252331149bd572e0e9c01c0f",
    "fields": {
      "intitule_naf_65": "Autres activités de poste et de courrier",
      "intitule_naf": "Autres activités de poste et de courrier",
      "code_naf": "5320Z",
      "intitule_naf_40": "Autres activités de poste et de courrier"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a05419f7565f30a5c2692480b97167492de4b790",
    "fields": {
      "intitule_naf_65": "Autres activités de télécommunication",
      "intitule_naf": "Autres activités de télécommunication",
      "code_naf": "6190Z",
      "intitule_naf_40": "Autres activités de télécommunication"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5a0496a8609d9e4ed3a4607407f294e274edf0db",
    "fields": {
      "intitule_naf_65": "Activités d'architecture et d'ingénierie",
      "intitule_naf": "Activités d'architecture et d'ingénierie",
      "code_naf": "711",
      "intitule_naf_40": "Activités d'architecture et d'ingénierie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2ecf4c2e6548d8f3b5d720c8aa6d5dd33d6f0d08",
    "fields": {
      "intitule_naf_65": "Transports de voyageurs par taxis",
      "intitule_naf": "Transports de voyageurs par taxis",
      "code_naf": "4932Z",
      "intitule_naf_40": "Transports de voyageurs par taxis"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0c713f048742f3ebb5eb68bcb29b599508553c5b",
    "fields": {
      "intitule_naf_65": "Autre distribution de crédit",
      "intitule_naf": "Autre distribution de crédit",
      "code_naf": "6492Z",
      "intitule_naf_40": "Autre distribution de crédit"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "63a968055761d1f159ec61221ccf58c2ee8716d9",
    "fields": {
      "intitule_naf_65": "Activités de banque centrale",
      "intitule_naf": "Activités de banque centrale",
      "code_naf": "6411Z",
      "intitule_naf_40": "Activités de banque centrale"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "21a77a9025e2bcf16ae87870b68fca1b5334a948",
    "fields": {
      "intitule_naf_65": "Édition de journaux",
      "intitule_naf": "Édition de journaux",
      "code_naf": "5813Z",
      "intitule_naf_40": "Édition de journaux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "10b2736ddc742dc033fa927b3a856fad6268fd14",
    "fields": {
      "intitule_naf_65": "Autres activités spécialisées, scientifiques et techniques n.c.a.",
      "intitule_naf": "Autres activités spécialisées, scientifiques et techniques n.c.a.",
      "code_naf": "749",
      "intitule_naf_40": "Aut. act. spéc. scientif. & techn. nca."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "631dea095eb95f864c6ecb49744f3a030248f184",
    "fields": {
      "intitule_naf_65": "Activ. administratives & autres activ. soutien aux entreprises",
      "intitule_naf": "Activités administratives et autres activités de soutien aux entreprises",
      "code_naf": "82",
      "intitule_naf_40": "Act. admin. & aut. act. soutien aux ent."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c8162e252ea8f4538a8b68c6a94b95bb8bbacadc",
    "fields": {
      "intitule_naf_65": "Recherche-développement en sciences physiques et naturelles",
      "intitule_naf": "Recherche-développement en sciences physiques et naturelles",
      "code_naf": "721",
      "intitule_naf_40": "R&D en sciences physiques et naturelles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "73f40d754a3566b6ce5a2394cb3a926eb29f8bc4",
    "fields": {
      "intitule_naf_65": "Activités liées aux systèmes de sécurité",
      "intitule_naf": "Activités liées aux systèmes de sécurité",
      "code_naf": "8020",
      "intitule_naf_40": "Activités liées aux systèmes de sécurité"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1876a9539c58264b7eb717fcb921231e921bb297",
    "fields": {
      "intitule_naf_65": "Services administratifs combinés de bureau",
      "intitule_naf": "Services administratifs combinés de bureau",
      "code_naf": "8211",
      "intitule_naf_40": "Services admin. combinés de bureau"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "6b6cd90ad5c434e3c1d98dd73323c5476826f69c",
    "fields": {
      "intitule_naf_65": "Location et location-bail de camions",
      "intitule_naf": "Location et location-bail de camions",
      "code_naf": "7712",
      "intitule_naf_40": "Location et location-bail de camions"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "bc5b70b896046a9b5414a21ec064fbefaca3206e",
    "fields": {
      "intitule_naf_65": "Régie publicitaire de médias",
      "intitule_naf": "Régie publicitaire de médias",
      "code_naf": "7312Z",
      "intitule_naf_40": "Régie publicitaire de médias"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "595992a16d58ac86175e42f8ce15ee3a7c8d899f",
    "fields": {
      "intitule_naf_65": "Enseignement de la conduite",
      "intitule_naf": "Enseignement de la conduite",
      "code_naf": "8553Z",
      "intitule_naf_40": "Enseignement de la conduite"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d7edf725dcae925b68854c131a8eebcec56784aa",
    "fields": {
      "intitule_naf_65": "Activités administratives",
      "intitule_naf": "Activités administratives",
      "code_naf": "821",
      "intitule_naf_40": "Activités administratives"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "411da37407908dbab6640e0f0732dd4ea43c9fbf",
    "fields": {
      "intitule_naf_65": "Enseignement culturel",
      "intitule_naf": "Enseignement culturel",
      "code_naf": "8552",
      "intitule_naf_40": "Enseignement culturel"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "262122888b0d17addaa05a018e69bca60ee07ea8",
    "fields": {
      "intitule_naf_65": "Activité profess. rééducation appareillage & pédicures-podologues",
      "intitule_naf": "Activités des professionnels de la rééducation, de l'appareillage et des pédicures-podologues",
      "code_naf": "8690E",
      "intitule_naf_40": "Act. rééduc. appareillag. & pédic.-podo."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "777a52cc71224b1bf7ab61b570d808c2738bf95b",
    "fields": {
      "intitule_naf_65": "Activités créatives, artistiques et de spectacle",
      "intitule_naf": "Activités créatives, artistiques et de spectacle",
      "code_naf": "90",
      "intitule_naf_40": "Act. créativ. artistiques & de spectacle"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "eefa2442ac0ee5511e862730cb396d63d44a2a53",
    "fields": {
      "intitule_naf_65": "Activités des centres de culture physique",
      "intitule_naf": "Activités des centres de culture physique",
      "code_naf": "9313",
      "intitule_naf_40": "Activité des centres de culture physique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "90237e50429955dacb579267c7478cffee7eeecd",
    "fields": {
      "intitule_naf_65": "Activités récréatives et de loisirs",
      "intitule_naf": "Activités récréatives et de loisirs",
      "code_naf": "932",
      "intitule_naf_40": "Activités récréatives et de loisirs"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "883a3a353d209e3713582d48f003a15f3403a1f2",
    "fields": {
      "intitule_naf_65": "Autres services personnels",
      "intitule_naf": "Autres services personnels",
      "code_naf": "960",
      "intitule_naf_40": "Autres services personnels"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "73e59fd58fc0d6f191a1316ff87eb4e268fae080",
    "fields": {
      "intitule_naf_65": "Activités de soutien à l'extraction d'hydrocarbures",
      "intitule_naf": "Activités de soutien à l'extraction d'hydrocarbures",
      "code_naf": "91",
      "intitule_naf_40": "Act. de soutien à l'extr. hydrocarbures"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3e22178a9d27ff26cc5e28e455b29302035d521a",
    "fields": {
      "intitule_naf_65": "Chasse, piégeage et services annexes",
      "intitule_naf": "Chasse, piégeage et services annexes",
      "code_naf": "17",
      "intitule_naf_40": "Chasse, piégeage et services annexes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a1dd5639e17da2becbd50567097836fa7c8550f9",
    "fields": {
      "intitule_naf_65": "Autres activités extractives n.c.a.",
      "intitule_naf": "Autres activités extractives n.c.a.",
      "code_naf": "0899Z",
      "intitule_naf_40": "Autres activités extractives n.c.a."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8bb07ee095bdb69087594e1f89e39befa673ed1b",
    "fields": {
      "intitule_naf_65": "Culture et élevage associés",
      "intitule_naf": "Culture et élevage associés",
      "code_naf": "0150Z",
      "intitule_naf_40": "Culture et élevage associés"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a2b8e037c9a4d7a589c756ed4a408edb0025d4b9",
    "fields": {
      "intitule_naf_65": "Prépa. des cuir & fourrure; fabric. art. voyage maroq. & sellerie",
      "intitule_naf": "Apprêt et tannage des cuirs ; préparation et teinture des fourrures ; fabrication d'articles de voyage, de maroquinerie et de sellerie",
      "code_naf": "151",
      "intitule_naf_40": "Indust. cuir & fourrure; maroq. & selle."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f4a5e20da8edbb2cd4b821a724867cedab2d7d85",
    "fields": {
      "intitule_naf_65": "Industrie des eaux embouteillées et des boissons rafraîchissantes",
      "intitule_naf": "Industrie des eaux minérales et autres eaux embouteillées et des boissons rafraîchissantes",
      "code_naf": "1107",
      "intitule_naf_40": "Ind. eaux & boissons rafraîchissantes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "6ac6ae018d588dd8231875ed161c29d80747eff7",
    "fields": {
      "intitule_naf_65": "Autre transformation et conservation de fruits et légumes",
      "intitule_naf": "Autre transformation et conservation de fruits et légumes",
      "code_naf": "1039",
      "intitule_naf_40": "Aut. transf. & cons. de fruit et légume"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "111f78cb17f0d394b860d420a31809985ddd28f2",
    "fields": {
      "intitule_naf_65": "Transformation et conservation de la viande de volaille",
      "intitule_naf": "Transformation et conservation de la viande de volaille",
      "code_naf": "1012Z",
      "intitule_naf_40": "Transf. & conserv. de viande de volaille"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9937ba3d34f2dd5eacb5fa343b58bf0a3e5ac429",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres vêtements et accessoires",
      "intitule_naf": "Fabrication d'autres vêtements et accessoires",
      "code_naf": "1419",
      "intitule_naf_40": "Fabric. autres vêtements et accessoires"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e55d1eb7fb98d04ce08b9b6a24fac2fa07bfc117",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres produits laitiers",
      "intitule_naf": "Fabrication d'autres produits laitiers",
      "code_naf": "1051D",
      "intitule_naf_40": "Fabrication d'autres produits laitiers"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e1a11064e978ea586af59811b0e245ecfa3f520f",
    "fields": {
      "intitule_naf_65": "Boulangerie et boulangerie-pâtisserie",
      "intitule_naf": "Boulangerie et boulangerie-pâtisserie",
      "code_naf": "1071C",
      "intitule_naf_40": "Boulangerie et boulangerie-pâtisserie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "af1a5b466a4d94f9988e01da6a2611ed0393f2c2",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres textiles n.c.a.",
      "intitule_naf": "Fabrication d'autres textiles n.c.a.",
      "code_naf": "1399",
      "intitule_naf_40": "Fabrication d'autres textiles n.c.a."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "54a37db7fa343b11b89e70b378046deba5e2c6fe",
    "fields": {
      "intitule_naf_65": "Industrie du papier et du carton",
      "intitule_naf": "Industrie du papier et du carton",
      "code_naf": "17",
      "intitule_naf_40": "Industrie du papier et du carton"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0b00a2d27f51e92663f70b5ebf02a30fe5ff1976",
    "fields": {
      "intitule_naf_65": "Fabrication d'emballages en bois",
      "intitule_naf": "Fabrication d'emballages en bois",
      "code_naf": "1624",
      "intitule_naf_40": "Fabrication d'emballages en bois"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7a01fedf3f86204f189a4acef611e6bb489b47d7",
    "fields": {
      "intitule_naf_65": "Fabrication d'articles en papier à usage sanitaire ou domestique",
      "intitule_naf": "Fabrication d'articles en papier à usage sanitaire ou domestique",
      "code_naf": "1722",
      "intitule_naf_40": "Fab. article papier sanit. ou domestique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7ff8f1701e93ea09bcb07da5f2b58030cf61fbbe",
    "fields": {
      "intitule_naf_65": "Fabrication d'éléments en béton pour la construction",
      "intitule_naf": "Fabrication d'éléments en béton pour la construction",
      "code_naf": "2361Z",
      "intitule_naf_40": "Fab. élément en béton pour la construct."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3343fab47dc9b54fa1679f59f5d55e859cea50b9",
    "fields": {
      "intitule_naf_65": "Fabrication d'appareils sanitaires en céramique",
      "intitule_naf": "Fabrication d'appareils sanitaires en céramique",
      "code_naf": "2342",
      "intitule_naf_40": "Fab. appareil sanitaire en céramique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3a97b3cf265dd92f757295be84d38050f7bfd3ed",
    "fields": {
      "intitule_naf_65": "Fabrication de préparations pharmaceutiques",
      "intitule_naf": "Fabrication de préparations pharmaceutiques",
      "code_naf": "2120",
      "intitule_naf_40": "Fabric. de préparations pharmaceutiques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "37f85e66c63056aee4f0da1f96fca3aaa1bc22ac",
    "fields": {
      "intitule_naf_65": "Fabrication d'ouvrages en fibre-ciment",
      "intitule_naf": "Fabrication d'ouvrages en fibre-ciment",
      "code_naf": "2365Z",
      "intitule_naf_40": "Fabrication d'ouvrages en fibre-ciment"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e2bb2d851b49a5572c082c2ccee003b62523785d",
    "fields": {
      "intitule_naf_65": "Fabrication  de produits en plastique",
      "intitule_naf": "Fabrication  de produits en plastique",
      "code_naf": "222",
      "intitule_naf_40": "Fabrication  de produits en plastique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "aa8d9ec984eaddc232488e9d8d8d002ca9a3add9",
    "fields": {
      "intitule_naf_65": "Fabrication de bicyclettes et de véhicules pour invalides",
      "intitule_naf": "Fabrication de bicyclettes et de véhicules pour invalides",
      "code_naf": "3092",
      "intitule_naf_40": "Fab. bicyclette & véhic. pour invalides"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a6e91be63dec932f68db48915248ffec81499a6b",
    "fields": {
      "intitule_naf_65": "Fabrication de supports magnétiques et optiques",
      "intitule_naf": "Fabrication de supports magnétiques et optiques",
      "code_naf": "2680Z",
      "intitule_naf_40": "Fab. de supports magnétiques et optiques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "bdb3493cf0eeb5b76b6445616aedb09c5e6f2c9a",
    "fields": {
      "intitule_naf_65": "Fabrication de portes et fenêtres en métal",
      "intitule_naf": "Fabrication de portes et fenêtres en métal",
      "code_naf": "2512",
      "intitule_naf_40": "Fabric. de portes et fenêtres en métal"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "880ec27883593450baad7719fdff2a1af3d101d3",
    "fields": {
      "intitule_naf_65": "Fabrication de carrosseries et remorques",
      "intitule_naf": "Fabrication de carrosseries et remorques",
      "code_naf": "2920Z",
      "intitule_naf_40": "Fabrication de carrosseries et remorques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "54892cecd4fc4ab8dd2d066b4dd28d4ed0eadbc6",
    "fields": {
      "intitule_naf_65": "Fabrication de composants électroniques",
      "intitule_naf": "Fabrication de composants électroniques",
      "code_naf": "2611",
      "intitule_naf_40": "Fabrication de composants électroniques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "23224904f7ddc8a4d8b138249fb7f206793fc23a",
    "fields": {
      "intitule_naf_65": "Réparation d'équipements électriques",
      "intitule_naf": "Réparation d'équipements électriques",
      "code_naf": "3314Z",
      "intitule_naf_40": "Réparation d'équipements électriques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7520c6400ef5308dfdebdedf9986a3fc93f5a50c",
    "fields": {
      "intitule_naf_65": "Fabrication d'instruments de musique",
      "intitule_naf": "Fabrication d'instruments de musique",
      "code_naf": "3220Z",
      "intitule_naf_40": "Fabrication d'instruments de musique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "749551a7281af852aaac3486f038931bd823e25c",
    "fields": {
      "intitule_naf_65": "Fabrication de fibres de verre",
      "intitule_naf": "Fabrication de fibres de verre",
      "code_naf": "2314Z",
      "intitule_naf_40": "Fabrication de fibres de verre"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "259f352ad6a133022c754ee6c4c63c935aabf7d1",
    "fields": {
      "intitule_naf_65": "Découpage, emboutissage",
      "intitule_naf": "Découpage, emboutissage",
      "code_naf": "2550B",
      "intitule_naf_40": "Découpage, emboutissage"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1a17526996400060779d0b7d0bc0dedbf695fd7c",
    "fields": {
      "intitule_naf_65": "Sidérurgie",
      "intitule_naf": "Sidérurgie",
      "code_naf": "241",
      "intitule_naf_40": "Sidérurgie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4acb602992d72a24cc1f3e61ff6b665147730958",
    "fields": {
      "intitule_naf_65": "Distribution films cinémat. vidéo & programmes de télévision",
      "intitule_naf": "Distribution de films cinématographiques, de vidéo et de programmes de télévision",
      "code_naf": "5913",
      "intitule_naf_40": "Distribution film vidéo & pgm. télé."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f9250a892dabda8b09f18446e9e1c3e34a602815",
    "fields": {
      "intitule_naf_65": "Commerce de détail de textiles en magasin spécialisé",
      "intitule_naf": "Commerce de détail de textiles en magasin spécialisé",
      "code_naf": "4751",
      "intitule_naf_40": "Com. dét. textiles en magasin spécialisé"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b71b51a95a706d525917c5c2510dd5a3e9f539df",
    "fields": {
      "intitule_naf_65": "Services auxiliaires des transports par eau",
      "intitule_naf": "Services auxiliaires des transports par eau",
      "code_naf": "5222Z",
      "intitule_naf_40": "Sces auxiliaires des transports par eau"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "229dd1e599eedb23711004e8a92bfe29e5b3f20c",
    "fields": {
      "intitule_naf_65": "Transports maritimes et côtiers de fret",
      "intitule_naf": "Transports maritimes et côtiers de fret",
      "code_naf": "5020Z",
      "intitule_naf_40": "Transports maritimes et côtiers de fret"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f10671bed6c6250d109a0647b3911b2f35d8290d",
    "fields": {
      "intitule_naf_65": "Activités de poste et de courrier",
      "intitule_naf": "Activités de poste et de courrier",
      "code_naf": "53",
      "intitule_naf_40": "Activités de poste et de courrier"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f2c3b152845174d573618c56c546ec6f80626a81",
    "fields": {
      "intitule_naf_65": "Autres activités informatiques",
      "intitule_naf": "Autres activités informatiques",
      "code_naf": "6209Z",
      "intitule_naf_40": "Autres activités informatiques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "392066040f23c4afb84951ff186c550df24fb1d6",
    "fields": {
      "intitule_naf_65": "Édition d'autres logiciels",
      "intitule_naf": "Édition d'autres logiciels",
      "code_naf": "5829",
      "intitule_naf_40": "Édition d'autres logiciels"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "6c3dfef05fa8594edfa15554362289d63fc5e792",
    "fields": {
      "intitule_naf_65": "Services des traiteurs",
      "intitule_naf": "Services des traiteurs",
      "code_naf": "5621",
      "intitule_naf_40": "Services des traiteurs"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "129436781126f10a28058b6fbbbb1f2550569f69",
    "fields": {
      "intitule_naf_65": "Restauration",
      "intitule_naf": "Restauration",
      "code_naf": "56",
      "intitule_naf_40": "Restauration"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "beb951419d12cc55619d181133b70445063d4527",
    "fields": {
      "intitule_naf_65": "Interm. comm. textiles, habillt, fourrures, chaussures & art cuir",
      "intitule_naf": "Intermédiaires du commerce en textiles, habillement, fourrures, chaussures et articles en cuir",
      "code_naf": "4616Z",
      "intitule_naf_40": "Int. comm. textile, habillt & assimil."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0ec336ee295a7f8c89e85643d75ac135094fb631",
    "fields": {
      "intitule_naf_65": "Fabrication de moteurs, génératrices, transformateurs électriques",
      "intitule_naf": "Fabrication de moteurs, génératrices et transformateurs électriques",
      "code_naf": "2711",
      "intitule_naf_40": "Fab. moteur génér. transfo. & mat. élec."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9f8fc0060e30b7f20ee9922d80bd69137251e2f3",
    "fields": {
      "intitule_naf_65": "Interm. comm. en meubles, articles de ménage et quincaillerie",
      "intitule_naf": "Intermédiaires du commerce en meubles, articles de ménage et quincaillerie",
      "code_naf": "4615Z",
      "intitule_naf_40": "Int. comm. meuble, art. ménage & quinc."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "89723bdca162585b6dfda39024f4fa7e1830f33c",
    "fields": {
      "intitule_naf_65": "Fabrication instruments & fournitures à usage médical & dentaire",
      "intitule_naf": "Fabrication d'instruments et de fournitures à usage médical et dentaire",
      "code_naf": "325",
      "intitule_naf_40": "Fab. inst. & fournit. usage méd. & dent."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1ae9f231374202e63cec1d528e74f62617817ce8",
    "fields": {
      "intitule_naf_65": "Commerce de voitures et de véhicules automobiles légers",
      "intitule_naf": "Commerce de voitures et de véhicules automobiles légers",
      "code_naf": "4511",
      "intitule_naf_40": "Comm. de voiture & véhicule auto. léger"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "79b68318a7e48669ef6292ec5c9b60643c828219",
    "fields": {
      "intitule_naf_65": "Production et distribution de vapeur et d'air conditionné",
      "intitule_naf": "Production et distribution de vapeur et d'air conditionné",
      "code_naf": "3530",
      "intitule_naf_40": "Prod. & distrib. vapeur et air condit."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "59669f6cb8362718d45eccea3703fe016616e2b9",
    "fields": {
      "intitule_naf_65": "Autres travaux spécialisés de construction",
      "intitule_naf": "Autres travaux spécialisés de construction",
      "code_naf": "4399D",
      "intitule_naf_40": "Aut. travaux spécialisés de construction"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "cea3612701055f69c8a9f5f1a224a4659edc2c0e",
    "fields": {
      "intitule_naf_65": "Collecte et traitement des eaux usées",
      "intitule_naf": "Collecte et traitement des eaux usées",
      "code_naf": "3700Z",
      "intitule_naf_40": "Collecte et traitement des eaux usées"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2558150f82a47fe783fc2ff20b469f7192b65f9a",
    "fields": {
      "intitule_naf_65": "Commerce de gros de machines-outils",
      "intitule_naf": "Commerce de gros de machines-outils",
      "code_naf": "4662",
      "intitule_naf_40": "Commerce de gros de machines-outils"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9fabce1b81645dda81a55ad2cdf7bc34eef2ae9e",
    "fields": {
      "intitule_naf_65": "Travaux de démolition",
      "intitule_naf": "Travaux de démolition",
      "code_naf": "4311",
      "intitule_naf_40": "Travaux de démolition"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a5e37b140315a871e5c8b5d093153e9950dcad3e",
    "fields": {
      "intitule_naf_65": "Activ. auxil. serv. financiers, hors assurance & caisses retraite",
      "intitule_naf": "Activités auxiliaires de services financiers, hors assurance et caisses de retraite",
      "code_naf": "661",
      "intitule_naf_40": "Act. aux. sce financ. hs ass. & retraite"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "94c9b6a75ed43eec1270ff003a71c1aaa04e7b2c",
    "fields": {
      "intitule_naf_65": "Autres services de réservation et activités connexes",
      "intitule_naf": "Autres services de réservation et activités connexes",
      "code_naf": "799",
      "intitule_naf_40": "Autre serv. réservation & activ. connexe"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "efe6a815998ee985ea6bdb5870bed38d1c15d646",
    "fields": {
      "intitule_naf_65": "Activité des économistes de la construction",
      "intitule_naf": "Activité des économistes de la construction",
      "code_naf": "7490A",
      "intitule_naf_40": "Activ des économistes de la construction"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e6b0e68c5535e74b0ad8abdba5e7eec727835a11",
    "fields": {
      "intitule_naf_65": "Activités des marchands de biens immobiliers",
      "intitule_naf": "Activités des marchands de biens immobiliers",
      "code_naf": "681",
      "intitule_naf_40": "Activité marchands de biens immobiliers"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4cc106ce8442c3a88ae18367a564249ebaa1d2b5",
    "fields": {
      "intitule_naf_65": "Activités de soutien aux entreprises n.c.a.",
      "intitule_naf": "Activités de soutien aux entreprises n.c.a.",
      "code_naf": "829",
      "intitule_naf_40": "Activités soutien aux entreprises nca."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b5929dc1392c980893e5bb6059bc7c97c3448698",
    "fields": {
      "intitule_naf_65": "Activités spécialisées de design",
      "intitule_naf": "Activités spécialisées de design",
      "code_naf": "741",
      "intitule_naf_40": "Activités spécialisées de design"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0fa1cff4135de6e9b76ce51e8e23e40bc2a4ad6b",
    "fields": {
      "intitule_naf_65": "Activités des voyagistes",
      "intitule_naf": "Activités des voyagistes",
      "code_naf": "7912Z",
      "intitule_naf_40": "Activités des voyagistes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a70ace0cb21818bf745219d9fd0d00c9a4f2c88b",
    "fields": {
      "intitule_naf_65": "Location de logements",
      "intitule_naf": "Location de logements",
      "code_naf": "6820A",
      "intitule_naf_40": "Location de logements"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4064d8eee80d07abd7fd875e589cf1c68970cbf6",
    "fields": {
      "intitule_naf_65": "Réassurance",
      "intitule_naf": "Réassurance",
      "code_naf": "6520Z",
      "intitule_naf_40": "Réassurance"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9913d0d2bece0dfd44d1df95a0ff88f70ad78186",
    "fields": {
      "intitule_naf_65": "Réassurance",
      "intitule_naf": "Réassurance",
      "code_naf": "652",
      "intitule_naf_40": "Réassurance"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "be4414adbbe7686a1b1bf2a6b1caeaa054684dd3",
    "fields": {
      "intitule_naf_65": "Activ. indifférenciées ménages : producteurs biens (usage propre)",
      "intitule_naf": "Activités indifférenciées des ménages en tant que producteurs de biens pour usage propre",
      "code_naf": "9810",
      "intitule_naf_40": "Act. ménage : prod. biens (usage propre)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5d8c73b77d9910d8251a59e533c1d5264442e748",
    "fields": {
      "intitule_naf_65": "Activ. organisations économiques, patronales et professionnelles",
      "intitule_naf": "Activités des organisations économiques, patronales et professionnelles",
      "code_naf": "941",
      "intitule_naf_40": "Act. organisations écon. patron. & prof."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "caeced92b4ea5ff005068d3d33770dbb51bd046f",
    "fields": {
      "intitule_naf_65": "Autres activités de soutien aux entreprises n.c.a.",
      "intitule_naf": "Autres activités de soutien aux entreprises n.c.a.",
      "code_naf": "8299",
      "intitule_naf_40": "Autre activité de soutien aux entr. nca."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "6b4ec72e511d4700368358298cd1e7bf6e5cd87e",
    "fields": {
      "intitule_naf_65": "Activités d'ordre public et de sécurité",
      "intitule_naf": "Activités d'ordre public et de sécurité",
      "code_naf": "8424Z",
      "intitule_naf_40": "Activités d'ordre public et de sécurité"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "17d2840fe4f22fd0301e708f86e567ce1e512a53",
    "fields": {
      "intitule_naf_65": "Autre action sociale sans hébergement n.c.a.",
      "intitule_naf": "Autre action sociale sans hébergement n.c.a.",
      "code_naf": "8899",
      "intitule_naf_40": "Autre action sociale sans hébrgemnt nca."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e43e478b3188f07ec4c8d2b05de49810f411e318",
    "fields": {
      "intitule_naf_65": "Services du feu et de secours",
      "intitule_naf": "Services du feu et de secours",
      "code_naf": "8425Z",
      "intitule_naf_40": "Services du feu et de secours"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "32ff93c24f190fbc21ca22ea6f9397cedfd31155",
    "fields": {
      "intitule_naf_65": "Défense",
      "intitule_naf": "Défense",
      "code_naf": "8422",
      "intitule_naf_40": "Défense"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4017dbc85153fd8d8575cbd302680b8e3dd69dde",
    "fields": {
      "intitule_naf_65": "Fabrication produits boulangerie-pâtisserie & pâtes alimentaires",
      "intitule_naf": "Fabrication de produits de boulangerie-pâtisserie et de pâtes alimentaires",
      "code_naf": "107",
      "intitule_naf_40": "Fab. prod. boulangerie-pâtis. & pâtes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2d707ee74c6aa199730d154a0c6cdea3f8b9a5b2",
    "fields": {
      "intitule_naf_65": "Transformation et conservation de la viande de boucherie",
      "intitule_naf": "Transformation et conservation de la viande de boucherie",
      "code_naf": "1011Z",
      "intitule_naf_40": "Transf. & conserv.  viande de boucherie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "72fb8f5f57aa4140b3913d664aa1d90cf88898eb",
    "fields": {
      "intitule_naf_65": "Fabrication de lait liquide et de produits frais",
      "intitule_naf": "Fabrication de lait liquide et de produits frais",
      "code_naf": "1051A",
      "intitule_naf_40": "Fab. de lait liquide & de produits frais"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d52e79a35ff1fac57daf7d63f51f1f0ba78763b6",
    "fields": {
      "intitule_naf_65": "Fabrication de carton ondulé",
      "intitule_naf": "Fabrication de carton ondulé",
      "code_naf": "1721A",
      "intitule_naf_40": "Fabrication de carton ondulé"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7a8e7a30b72708b03bdaaa4e6ecb8823cfb39353",
    "fields": {
      "intitule_naf_65": "Culture et élevage associés",
      "intitule_naf": "Culture et élevage associés",
      "code_naf": "150",
      "intitule_naf_40": "Culture et élevage associés"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2bd031cf9adfcd6b6bf6045d20ca259460e5aa90",
    "fields": {
      "intitule_naf_65": "Autres cultures permanentes",
      "intitule_naf": "Autres cultures permanentes",
      "code_naf": "0129Z",
      "intitule_naf_40": "Autres cultures permanentes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5e924f3a9dd87e7c3b452f46ec88003fefede7a0",
    "fields": {
      "intitule_naf_65": "Fabrication de fromage",
      "intitule_naf": "Fabrication de fromage",
      "code_naf": "1051C",
      "intitule_naf_40": "Fabrication de fromage"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9da15668e242c28f5c0bd9332867b3eeafd0ec3e",
    "fields": {
      "intitule_naf_65": "Cultures permanentes",
      "intitule_naf": "Cultures permanentes",
      "code_naf": "12",
      "intitule_naf_40": "Cultures permanentes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4de23aa7e45a0882de99d0e1f1b9e5fb33be68b0",
    "fields": {
      "intitule_naf_65": "Fabrication & façonnage autres articles verre yc verre technique",
      "intitule_naf": "Fabrication et façonnage d'autres articles en verre, y compris verre technique",
      "code_naf": "2319",
      "intitule_naf_40": "Fab. & façonnage aut. article en verre"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7147a55204a0894c409a808cf9803c76821b9fbb",
    "fields": {
      "intitule_naf_65": "Fabrication d'articles céramiques à usage domestique, ornemental",
      "intitule_naf": "Fabrication d'articles céramiques à usage domestique ou ornemental",
      "code_naf": "2341",
      "intitule_naf_40": "Fab. art. céramiq. usage domest. & déco."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7c584d831123ec4937c8fd0493a5007861235b29",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres ouvrages en béton, en ciment ou en plâtre",
      "intitule_naf": "Fabrication d'autres ouvrages en béton, en ciment ou en plâtre",
      "code_naf": "2369",
      "intitule_naf_40": "Fab. aut. ouvrage béton, ciment, plâtre"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7b445df6594af012827477db236fe5d3da799a33",
    "fields": {
      "intitule_naf_65": "Fabrication de peintures, vernis, encres et mastics",
      "intitule_naf": "Fabrication de peintures, vernis, encres et mastics",
      "code_naf": "2030",
      "intitule_naf_40": "Fab. de peinture, vernis, encre & mastic"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "fab5091f3f2e5aa5c1a78f47748a0e8358a46636",
    "fields": {
      "intitule_naf_65": "Fabrication de peintures, vernis, encres et mastics",
      "intitule_naf": "Fabrication de peintures, vernis, encres et mastics",
      "code_naf": "203",
      "intitule_naf_40": "Fab. de peinture, vernis, encre & mastic"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "320efd0d4dd335ffb7ad08e6e72d3efeff2d3493",
    "fields": {
      "intitule_naf_65": "Fabrication de préparations pharmaceutiques",
      "intitule_naf": "Fabrication de préparations pharmaceutiques",
      "code_naf": "212",
      "intitule_naf_40": "Fabric. de préparations pharmaceutiques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a9ab21299e2208217b0b9bfc3f05b3a1dddf7b67",
    "fields": {
      "intitule_naf_65": "Fabrication de chaux et plâtre",
      "intitule_naf": "Fabrication de chaux et plâtre",
      "code_naf": "2352",
      "intitule_naf_40": "Fabrication de chaux et plâtre"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "6b09b6b48a59a4fd269156884ec8206ec62f3074",
    "fields": {
      "intitule_naf_65": "Fabrication de coutellerie",
      "intitule_naf": "Fabrication de coutellerie",
      "code_naf": "2571",
      "intitule_naf_40": "Fabrication de coutellerie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "6850afa2175f867769127da646b944507ca90e23",
    "fields": {
      "intitule_naf_65": "Autre imprimerie (labeur)",
      "intitule_naf": "Autre imprimerie (labeur)",
      "code_naf": "1812Z",
      "intitule_naf_40": "Autre imprimerie (labeur)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "39f718f8969fbeebb6f1dccca42763e4b16b2f2c",
    "fields": {
      "intitule_naf_65": "Métallurgie du cuivre",
      "intitule_naf": "Métallurgie du cuivre",
      "code_naf": "2444Z",
      "intitule_naf_40": "Métallurgie du cuivre"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "153b438fe0c7cf0c6e3e4de5233fe261319a0cfe",
    "fields": {
      "intitule_naf_65": "Fab. moteurs générat. transfo. élec., mat. distrib. & cde électr.",
      "intitule_naf": "Fabrication de moteurs, génératrices et transformateurs électriques et de matériel de distribution et de commande électrique",
      "code_naf": "271",
      "intitule_naf_40": "Fab. moteur génér. transfo. & mat. élec."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5ad68438b2bd2d78e099b62920ff03ec1a15cdd8",
    "fields": {
      "intitule_naf_65": "Fabrication équipts emballage, conditionnement & pesage",
      "intitule_naf": "Fabrication d'équipements d'emballage, de conditionnement et de pesage",
      "code_naf": "2829A",
      "intitule_naf_40": "Fab. éqpt emballage condition. & pesage"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5b105e4d960fb04b1ad2c6f4483b3ff566c39d9c",
    "fields": {
      "intitule_naf_65": "Réparation et maintenance d'aéronefs et d'engins spatiaux",
      "intitule_naf": "Réparation et maintenance d'aéronefs et d'engins spatiaux",
      "code_naf": "3316Z",
      "intitule_naf_40": "Répar. & maint. aéronef & eng. spatiaux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9ccfacc5fc7e25e5e131dea0eaf9c736003e4593",
    "fields": {
      "intitule_naf_65": "Construction d'ouvrages maritimes et fluviaux",
      "intitule_naf": "Construction d'ouvrages maritimes et fluviaux",
      "code_naf": "4291Z",
      "intitule_naf_40": "Construc. ouvrages maritimes et fluviaux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5bf31f20e69f0b534336469cd874df94528a5db4",
    "fields": {
      "intitule_naf_65": "Fabrication de matériels de transport n.c.a.",
      "intitule_naf": "Fabrication de matériels de transport n.c.a.",
      "code_naf": "309",
      "intitule_naf_40": "Fabric. de matériels de transport n.c.a."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8543b8b57e58da8320d4dc21a2435405e7acd079",
    "fields": {
      "intitule_naf_65": "Construction et entretien de tunnels",
      "intitule_naf": "Construction et entretien de tunnels",
      "code_naf": "4213B",
      "intitule_naf_40": "Construction et entretien de tunnels"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "524bf7599fa76db6fab2af024c0251d985cff1c9",
    "fields": {
      "intitule_naf_65": "Fabrication d'articles de brosserie",
      "intitule_naf": "Fabrication d'articles de brosserie",
      "code_naf": "3291",
      "intitule_naf_40": "Fabrication d'articles de brosserie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7d5fd090db3ea4b799c92178a4c6d2bbec242669",
    "fields": {
      "intitule_naf_65": "Travaux de peinture et vitrerie",
      "intitule_naf": "Travaux de peinture et vitrerie",
      "code_naf": "4334",
      "intitule_naf_40": "Travaux de peinture et vitrerie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a8b200aba34ca24e04c327b82e99276055ac5f63",
    "fields": {
      "intitule_naf_65": "Administration publique (tutelle) des activités économiques",
      "intitule_naf": "Administration publique (tutelle) des activités économiques",
      "code_naf": "8413Z",
      "intitule_naf_40": "Adm. publique des activités économiques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4decfa4aeec416928704a5ecf4b09b7c4e4aedac",
    "fields": {
      "intitule_naf_65": "Réparation de produits électroniques grand public",
      "intitule_naf": "Réparation de produits électroniques grand public",
      "code_naf": "9521Z",
      "intitule_naf_40": "Réparation prdts électroniq. grd public"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d85ae9795de33732ade8c82baeca349b12e75eb4",
    "fields": {
      "intitule_naf_65": "Activités des agences de placement de main-d'oeuvre",
      "intitule_naf": "Activités des agences de placement de main-d'œuvre",
      "code_naf": "7810",
      "intitule_naf_40": "Activ. agence placement de main-d'oeuvre"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "309f5f39d33fd77edc7b977ade0aeada741b14ee",
    "fields": {
      "intitule_naf_65": "Activités créatives, artistiques et de spectacle",
      "intitule_naf": "Activités créatives, artistiques et de spectacle",
      "code_naf": "900",
      "intitule_naf_40": "Act. créativ. artistiques & de spectacle"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2b1681d1782b9c52796f5123adc0ad04910ba2f4",
    "fields": {
      "intitule_naf_65": "Organisation de jeux de hasard et d'argent",
      "intitule_naf": "Organisation de jeux de hasard et d'argent",
      "code_naf": "920",
      "intitule_naf_40": "Organisation jeux de hasard & d'argent"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2ae044a7b7ade589f4312f1f685b73d23bff432c",
    "fields": {
      "intitule_naf_65": "Enseignement post-secondaire non supérieur",
      "intitule_naf": "Enseignement post-secondaire non supérieur",
      "code_naf": "8541",
      "intitule_naf_40": "Enseignement post-secondaire non sup."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9673fbd8238dbcf4478f7636b44924a4b3a87dc2",
    "fields": {
      "intitule_naf_65": "Administration publique générale",
      "intitule_naf": "Administration publique générale",
      "code_naf": "8411Z",
      "intitule_naf_40": "Administration publique générale"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d654848756bb56303fd516b70588164cb8727727",
    "fields": {
      "intitule_naf_65": "Administration publique générale",
      "intitule_naf": "Administration publique générale",
      "code_naf": "8411",
      "intitule_naf_40": "Administration publique générale"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "94fc2e1bb06333b0866c45410633604255c70e36",
    "fields": {
      "intitule_naf_65": "Traduction et interprétation",
      "intitule_naf": "Traduction et interprétation",
      "code_naf": "7430",
      "intitule_naf_40": "Traduction et interprétation"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c66b5b90ba77fe96792ee77b97709ef41d5ea3f0",
    "fields": {
      "intitule_naf_65": "Justice",
      "intitule_naf": "Justice",
      "code_naf": "8423Z",
      "intitule_naf_40": "Justice"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "50fe79dd6ad4771ae79a87fa80b5aaf3c1c578a9",
    "fields": {
      "intitule_naf_65": "Location et exploitation de biens immobiliers propres ou loués",
      "intitule_naf": "Location et exploitation de biens immobiliers propres ou loués",
      "code_naf": "682",
      "intitule_naf_40": "Loc. & exploi. bien immo. propre ou loué"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2f1f9a49a31a7b71ea6ff54d8bb46497c7acae84",
    "fields": {
      "intitule_naf_65": "Administration de biens immobiliers",
      "intitule_naf": "Administration de biens immobiliers",
      "code_naf": "6832",
      "intitule_naf_40": "Administration de biens immobiliers"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "fb552b40fcbfbc306c0627d1f90b18f31fb9b13a",
    "fields": {
      "intitule_naf_65": "Services auxiliaires des transports",
      "intitule_naf": "Services auxiliaires des transports",
      "code_naf": "522",
      "intitule_naf_40": "Services auxiliaires des transports"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2a6ea448216ed415f4fab3f2059423208de2caab",
    "fields": {
      "intitule_naf_65": "Télécommunications sans fil",
      "intitule_naf": "Télécommunications sans fil",
      "code_naf": "612",
      "intitule_naf_40": "Télécommunications sans fil"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "79d6c18fd2dc466d19eb8dfc6a7a0ab0f1c82b21",
    "fields": {
      "intitule_naf_65": "Télécommunications filaires",
      "intitule_naf": "Télécommunications filaires",
      "code_naf": "611",
      "intitule_naf_40": "Télécommunications filaires"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "012c2147e17e11d8dcd0d922d8b66aeb99acb446",
    "fields": {
      "intitule_naf_65": "Régie publicitaire de médias",
      "intitule_naf": "Régie publicitaire de médias",
      "code_naf": "7312",
      "intitule_naf_40": "Régie publicitaire de médias"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e996c62ba0a0892bf20f4e18e667aecf8e712c48",
    "fields": {
      "intitule_naf_65": "Transports aériens de fret",
      "intitule_naf": "Transports aériens de fret",
      "code_naf": "5121",
      "intitule_naf_40": "Transports aériens de fret"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c296977f42c509fb7150991efd7309925bdefcf4",
    "fields": {
      "intitule_naf_65": "Programmation informatique",
      "intitule_naf": "Programmation informatique",
      "code_naf": "6201Z",
      "intitule_naf_40": "Programmation informatique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "740302dec22eb5782b196c39f46ef5b6fd55c0c8",
    "fields": {
      "intitule_naf_65": "Services des traiteurs",
      "intitule_naf": "Services des traiteurs",
      "code_naf": "5621Z",
      "intitule_naf_40": "Services des traiteurs"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e3a1a1016a90535b4bd55cfe24cfd04f9de168fd",
    "fields": {
      "intitule_naf_65": "Activités juridiques",
      "intitule_naf": "Activités juridiques",
      "code_naf": "691",
      "intitule_naf_40": "Activités juridiques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "563f5de83f08d7e24ce0a31ee17dd3533b8d6fee",
    "fields": {
      "intitule_naf_65": "Interm. du comm. en m.p. agricoles & textiles, animaux vivants",
      "intitule_naf": "Intermédiaires du commerce en matières premières agricoles, animaux vivants, matières premières textiles et produits semi-finis",
      "code_naf": "4611Z",
      "intitule_naf_40": "Interm. du comm. en produits agricoles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8064409beaec89fcb57dced958e419753c559c19",
    "fields": {
      "intitule_naf_65": "Comm. de gros d'éqpts et composants électroniques et de télécomm.",
      "intitule_naf": "Commerce de gros de composants et d'équipements électroniques et de télécommunication",
      "code_naf": "4652",
      "intitule_naf_40": "Cg éqpt & composant électron. & télécom."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8d73cf7a345639995dc7026624a3dad2f76aca4a",
    "fields": {
      "intitule_naf_65": "Comm. détail autres équipts du foyer (magasin spécialisé)",
      "intitule_naf": "Commerce de détail d'autres équipements du foyer en magasin spécialisé",
      "code_naf": "475",
      "intitule_naf_40": "Com. dét. aut. éqpt du foyer (mag. spé.)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1b625675861f08b433444a45163cc61ac1dabacb",
    "fields": {
      "intitule_naf_65": "Commerce de gros non spécialisé de denrées, boissons et tabac",
      "intitule_naf": "Commerce de gros non spécialisé de denrées, boissons et tabac",
      "code_naf": "4639",
      "intitule_naf_40": "Com. gros non spéc. denrée boisson tabac"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "67e4b63c9b51e43e365073350654c98c2f990507",
    "fields": {
      "intitule_naf_65": "Commerce de gros (commerce interentreprises) de fruits et légumes",
      "intitule_naf": "Commerce de gros (commerce interentreprises) de fruits et légumes",
      "code_naf": "4631Z",
      "intitule_naf_40": "Commerce de gros de fruits et légumes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f00feca26aa68e211b5946086684bd910acb9076",
    "fields": {
      "intitule_naf_65": "Commerce de gros de produits à base de viande",
      "intitule_naf": "Commerce de gros (commerce interentreprises) de produits à base de viande",
      "code_naf": "4632B",
      "intitule_naf_40": "Comm. gros de produits à base de viande"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a6a7ca514fdc32c40f02ab024e2baffd02184480",
    "fields": {
      "intitule_naf_65": "Commerce de gros de café, thé, cacao et épices",
      "intitule_naf": "Commerce de gros de café, thé, cacao et épices",
      "code_naf": "4637",
      "intitule_naf_40": "Comm. gros de café, thé, cacao et épices"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "dfb5e6f9d7dfa9d3f8be9d2249d6b3fb4557305f",
    "fields": {
      "intitule_naf_65": "Commerce de gros de textiles",
      "intitule_naf": "Commerce de gros de textiles",
      "code_naf": "4641",
      "intitule_naf_40": "Commerce de gros de textiles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "325f75778521b4a9e9648073f1398ecf5dce4eba",
    "fields": {
      "intitule_naf_65": "Supermarchés",
      "intitule_naf": "Supermarchés",
      "code_naf": "4711D",
      "intitule_naf_40": "Supermarchés"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "6f2e40edab0b645d5c5d9f647c75ff47e0169f66",
    "fields": {
      "intitule_naf_65": "Activ. indifférenciées ménages  : produc. services (usage propre)",
      "intitule_naf": "Activités indifférenciées des ménages en tant que producteurs de services pour usage propre",
      "code_naf": "9820",
      "intitule_naf_40": "Act. ménage : prod. serv. (usage propre)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f6a2604a561017111126bfa7d3e04b979e95d836",
    "fields": {
      "intitule_naf_65": "Préparation industrielle de produits à base de viande",
      "intitule_naf": "Préparation industrielle de produits à base de viande",
      "code_naf": "1013A",
      "intitule_naf_40": "Prépa. indust. produits à base de viande"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ef01488fd84ae7d76ad372fee67dd8a72980e0ab",
    "fields": {
      "intitule_naf_65": "Culture et production animale, chasse et services annexes",
      "intitule_naf": "Culture et production animale, chasse et services annexes",
      "code_naf": "01",
      "intitule_naf_40": "Cult. & prod. animale, chasse & sce ann."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f5bae0cb3ba98fa1a320936a10da2d072b356ce0",
    "fields": {
      "intitule_naf_65": "Culture de plantes à fibres",
      "intitule_naf": "Culture de plantes à fibres",
      "code_naf": "116",
      "intitule_naf_40": "Culture de plantes à fibres"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "fa9ce2046db1bee9800e9eb0db3488ab1012abf3",
    "fields": {
      "intitule_naf_65": "Cultures non permanentes",
      "intitule_naf": "Cultures non permanentes",
      "code_naf": "11",
      "intitule_naf_40": "Cultures non permanentes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "53aa99d1642a6684b46b424a89cd112d8a17d43f",
    "fields": {
      "intitule_naf_65": "Exploitation forestière",
      "intitule_naf": "Exploitation forestière",
      "code_naf": "22",
      "intitule_naf_40": "Exploitation forestière"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f60d6fc0f228d2cbaf66750e1af2839d7652bf52",
    "fields": {
      "intitule_naf_65": "Traitement des semences",
      "intitule_naf": "Traitement des semences",
      "code_naf": "164",
      "intitule_naf_40": "Traitement des semences"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "72f8592ad2cd1e5592deb485580acd2aad979074",
    "fields": {
      "intitule_naf_65": "Culture d'agrumes",
      "intitule_naf": "Culture d'agrumes",
      "code_naf": "0123Z",
      "intitule_naf_40": "Culture d'agrumes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e1c996677f2b830bf3e73f95c5fc0d809a05f29d",
    "fields": {
      "intitule_naf_65": "Pêche en mer",
      "intitule_naf": "Pêche en mer",
      "code_naf": "0311Z",
      "intitule_naf_40": "Pêche en mer"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4705e76844a2be8879aea04abccb57cd6af6c415",
    "fields": {
      "intitule_naf_65": "Pêche",
      "intitule_naf": "Pêche",
      "code_naf": "31",
      "intitule_naf_40": "Pêche"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b261a660b89ba739bb29045363d595480e03c73d",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres machines d'usage spécifique n.c.a.",
      "intitule_naf": "Fabrication d'autres machines d'usage spécifique n.c.a.",
      "code_naf": "2899",
      "intitule_naf_40": "Fab. autre machine usage spécifi. n.c.a."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0593846367414b6cf2362d366d13e7af51e9958c",
    "fields": {
      "intitule_naf_65": "Fabrication d'outillage portatif à moteur incorporé",
      "intitule_naf": "Fabrication d'outillage portatif à moteur incorporé",
      "code_naf": "2824Z",
      "intitule_naf_40": "Fab. outillage portatif à moteur incorp."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "23e76a965a9b5e5cb252220590ee8e92744f4887",
    "fields": {
      "intitule_naf_65": "Fabrication de machines agricoles et forestières",
      "intitule_naf": "Fabrication de machines agricoles et forestières",
      "code_naf": "283",
      "intitule_naf_40": "Fab. machines agricoles et forestières"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ad01a36c866ef957b31785571ac1b6a1fba6ba5e",
    "fields": {
      "intitule_naf_65": "Fabrication d'équipements de communication",
      "intitule_naf": "Fabrication d'équipements de communication",
      "code_naf": "2630Z",
      "intitule_naf_40": "Fabric. d'équipements de communication"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0ded2e0f66cfb8eba60122d9e4548406fa1f7f7f",
    "fields": {
      "intitule_naf_65": "Autres activités manufacturières n.c.a.",
      "intitule_naf": "Autres activités manufacturières n.c.a.",
      "code_naf": "3299Z",
      "intitule_naf_40": "Autres activités manufacturières n.c.a."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "357087ed91b195a392a33336f775959fb16914b6",
    "fields": {
      "intitule_naf_65": "Construction de véhicules automobiles",
      "intitule_naf": "Construction de véhicules automobiles",
      "code_naf": "2910Z",
      "intitule_naf_40": "Construction de véhicules automobiles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c73dc70fa38630c66eb962404e91b3445a4a71d5",
    "fields": {
      "intitule_naf_65": "Réparation d'autres équipements",
      "intitule_naf": "Réparation d'autres équipements",
      "code_naf": "3319",
      "intitule_naf_40": "Réparation d'autres équipements"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "83df9223291ecb8fa278d8a17626f6ca3e57929c",
    "fields": {
      "intitule_naf_65": "Distribution d'électricité",
      "intitule_naf": "Distribution d'électricité",
      "code_naf": "3513Z",
      "intitule_naf_40": "Distribution d'électricité"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8c4cc65b2ca18e9b24a945e6cb8d515c52722ac7",
    "fields": {
      "intitule_naf_65": "Démantèlement d'épaves",
      "intitule_naf": "Démantèlement d'épaves",
      "code_naf": "3831Z",
      "intitule_naf_40": "Démantèlement d'épaves"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8c38d222f70488915ce10f007ee26eff41fc51a7",
    "fields": {
      "intitule_naf_65": "Commerce d'électricité",
      "intitule_naf": "Commerce d'électricité",
      "code_naf": "3514Z",
      "intitule_naf_40": "Commerce d'électricité"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7cac5a9ed2b956974a5a0b40ab6e711343e7d810",
    "fields": {
      "intitule_naf_65": "Comm. gros (interentr.) machines pour industrie textile & habill.",
      "intitule_naf": "Commerce de gros de machines pour l'industrie textile et l'habillement",
      "code_naf": "4664",
      "intitule_naf_40": "Com. gros machine pr ind. text. & habil."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f12e1d914db952145d4e0754dde309cf7c634420",
    "fields": {
      "intitule_naf_65": "Travaux d'installation d'eau et de gaz en tous locaux",
      "intitule_naf": "Travaux d'installation d'eau et de gaz en tous locaux",
      "code_naf": "4322A",
      "intitule_naf_40": "Travaux instal. eau & gaz en tous locaux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "46ddffe74292b3ef58a92d2b1a2715a4c763c1fb",
    "fields": {
      "intitule_naf_65": "Entretien et réparation d'autres véhicules automobiles",
      "intitule_naf": "Entretien et réparation d'autres véhicules automobiles",
      "code_naf": "4520B",
      "intitule_naf_40": "Entretien & répar. autre véhicule auto."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "717b281372ad7910d1de8131dd8fd9ecc1a12b83",
    "fields": {
      "intitule_naf_65": "Entretien et réparation de véhicules automobiles",
      "intitule_naf": "Entretien et réparation de véhicules automobiles",
      "code_naf": "452",
      "intitule_naf_40": "Entretien & répar. de véhicule auto."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3f3746e4ece75f50436bf6efcb7a70358295686a",
    "fields": {
      "intitule_naf_65": "Travaux de menuiserie métallique et serrurerie",
      "intitule_naf": "Travaux de menuiserie métallique et serrurerie",
      "code_naf": "4332B",
      "intitule_naf_40": "Travaux menuiserie métal. & serrurerie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "48128eca013b93b07f15fc1bf05d010aa6bd9429",
    "fields": {
      "intitule_naf_65": "Travaux de montage de structures métalliques",
      "intitule_naf": "Travaux de montage de structures métalliques",
      "code_naf": "4399B",
      "intitule_naf_40": "Travaux montage de structure métallique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "fd72631f83af7732e745385722c40784bb05753c",
    "fields": {
      "intitule_naf_65": "Construction de réseaux pour fluides",
      "intitule_naf": "Construction de réseaux pour fluides",
      "code_naf": "4221Z",
      "intitule_naf_40": "Construction de réseaux pour fluides"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "96531e9269365bd3d3eb7f175ff50a83925c7776",
    "fields": {
      "intitule_naf_65": "Promotion immobilière",
      "intitule_naf": "Promotion immobilière",
      "code_naf": "4110",
      "intitule_naf_40": "Promotion immobilière"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a91581d4828af6d63b8e0ccd5c069097b6721d42",
    "fields": {
      "intitule_naf_65": "Commerce de gros de fournitures pour la plomberie et le chauffage",
      "intitule_naf": "Commerce de gros (commerce interentreprises) de fournitures pour la plomberie et le chauffage",
      "code_naf": "4674B",
      "intitule_naf_40": "Cg fourniture pour plomberie & chauffage"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1e88ef1d7dff5858b096129b858d968ad9389b7d",
    "fields": {
      "intitule_naf_65": "Commerce de gros de combustibles et de produits annexes",
      "intitule_naf": "Commerce de gros (commerce interentreprises) de combustibles et de produits annexes",
      "code_naf": "4671Z",
      "intitule_naf_40": "Com. gros combustible & produits annexes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8aefefee7aa714329b579ecc4befbabdfa679723",
    "fields": {
      "intitule_naf_65": "Commerce de détail de jeux et jouets en magasin spécialisé",
      "intitule_naf": "Commerce de détail de jeux et jouets en magasin spécialisé",
      "code_naf": "4765Z",
      "intitule_naf_40": "Com. dét. jeux & jouets en mag. spécial."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4cafdbf9228b13cb8964175f61e743ad3656940e",
    "fields": {
      "intitule_naf_65": "Commerce de gros (commerce interentreprises) de déchets et débris",
      "intitule_naf": "Commerce de gros (commerce interentreprises) de déchets et débris",
      "code_naf": "4677Z",
      "intitule_naf_40": "Commerce de gros de déchets et débris"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b7a41269553263fd55099000917a68cd029a4389",
    "fields": {
      "intitule_naf_65": "Commerce de détail hors magasin, éventaires ou marchés",
      "intitule_naf": "Commerce de détail hors magasin, éventaires ou marchés",
      "code_naf": "479",
      "intitule_naf_40": "Com. dét. hors mag. éventair. ou marchés"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b93fd7f0daafdde7a1ae02bd51da21a686f05f2b",
    "fields": {
      "intitule_naf_65": "Commerce de détail de maroquinerie et d'articles de voyage",
      "intitule_naf": "Commerce de détail de maroquinerie et d'articles de voyage",
      "code_naf": "4772B",
      "intitule_naf_40": "Com. dét. maroquinerie & article  voyage"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "83a89a3f383ec4e84141e555ad5c4616111cf0fa",
    "fields": {
      "intitule_naf_65": "Autres commerces de détail sur éventaires et marchés",
      "intitule_naf": "Autres commerces de détail sur éventaires et marchés",
      "code_naf": "4789Z",
      "intitule_naf_40": "Aut. com. dét. sur éventaires & marchés"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "28e439760c354d9519b3e6f7c42515eaa162cdfd",
    "fields": {
      "intitule_naf_65": "Transports maritimes et côtiers de fret",
      "intitule_naf": "Transports maritimes et côtiers de fret",
      "code_naf": "502",
      "intitule_naf_40": "Transports maritimes et côtiers de fret"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "44df18bd4abf3d1fc297c125f730a8b0a49fc797",
    "fields": {
      "intitule_naf_65": "Transports fluviaux de passagers",
      "intitule_naf": "Transports fluviaux de passagers",
      "code_naf": "5030",
      "intitule_naf_40": "Transports fluviaux de passagers"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "54fec6c734660e38d3bd738e253db59efc7bdea8",
    "fields": {
      "intitule_naf_65": "Transports ferroviaires de fret",
      "intitule_naf": "Transports ferroviaires de fret",
      "code_naf": "4920",
      "intitule_naf_40": "Transports ferroviaires de fret"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1e1ad215302ab263438b4578ec73a5e44bb27bd5",
    "fields": {
      "intitule_naf_65": "Autre mise à disposition de ressources humaines",
      "intitule_naf": "Autre mise à disposition de ressources humaines",
      "code_naf": "7830Z",
      "intitule_naf_40": "Aut. mise à dispo. de ressource humaine"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "930b2037f1cd8723da458101b8e7d211c4209c68",
    "fields": {
      "intitule_naf_65": "Activités de soutien au spectacle vivant",
      "intitule_naf": "Activités de soutien au spectacle vivant",
      "code_naf": "9002Z",
      "intitule_naf_40": "Activités de soutien au spectacle vivant"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ab7473c07240236b2287fc39d4301a8e6f79327b",
    "fields": {
      "intitule_naf_65": "Activités sportives, récréatives et de loisirs",
      "intitule_naf": "Activités sportives, récréatives et de loisirs",
      "code_naf": "93",
      "intitule_naf_40": "Act. sportiv., récréatives & de loisirs"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e2d77fde76008dafbd17dacc8ac1c7dbbe5a4f6b",
    "fields": {
      "intitule_naf_65": "Organisation de jeux de hasard et d'argent",
      "intitule_naf": "Organisation de jeux de hasard et d'argent",
      "code_naf": "9200",
      "intitule_naf_40": "Organisation jeux de hasard & d'argent"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9e70139ce3390ae7f0cc7c33a4d6b10050bd381f",
    "fields": {
      "intitule_naf_65": "Activités des syndicats de salariés",
      "intitule_naf": "Activités des syndicats de salariés",
      "code_naf": "942",
      "intitule_naf_40": "Activités des syndicats de salariés"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "cec55be7627764eb9e00f5087b220f4364d69ba8",
    "fields": {
      "intitule_naf_65": "Gestion de salles de spectacles",
      "intitule_naf": "Gestion de salles de spectacles",
      "code_naf": "9004",
      "intitule_naf_40": "Gestion de salles de spectacles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a0cf7d750ffb332cd0822cb9657bdd64999423c4",
    "fields": {
      "intitule_naf_65": "Activités liées au sport",
      "intitule_naf": "Activités liées au sport",
      "code_naf": "931",
      "intitule_naf_40": "Activités liées au sport"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3234752cc23d77d0faec8618792ee825085b77ff",
    "fields": {
      "intitule_naf_65": "Accueil de jeunes enfants",
      "intitule_naf": "Accueil de jeunes enfants",
      "code_naf": "8891A",
      "intitule_naf_40": "Accueil de jeunes enfants"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "cc365c1c37886ae6282a759ab0a6224bc6f36570",
    "fields": {
      "intitule_naf_65": "Fab. prod. chimiq. base, azoté, engrais, plast. & caoutch. synth.",
      "intitule_naf": "Fabrication de produits chimiques de base, de produits azotés et d'engrais, de matières plastiques de base et de caoutchouc synthétique",
      "code_naf": "201",
      "intitule_naf_40": "Fab. prod. chimi., plast. & caout. synt."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "62c8835b72fb26c78fb05241f340f1cbe6f4c86f",
    "fields": {
      "intitule_naf_65": "Fabric. d'autres produits chimiques inorganiques de base n.c.a.",
      "intitule_naf": "Fabrication d'autres produits chimiques inorganiques de base n.c.a.",
      "code_naf": "2013B",
      "intitule_naf_40": "Fab. aut. prod. chim. inorg. base n.c.a."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "990e8e2efdfd3b3956e4b47da52b5f83b739c5c4",
    "fields": {
      "intitule_naf_65": "Fabrication de parfums et de produits pour la toilette",
      "intitule_naf": "Fabrication de parfums et de produits pour la toilette",
      "code_naf": "2042Z",
      "intitule_naf_40": "Fab. parfum & produit pour la toilette"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "200a305f97b71f06a6afecaa8b4041e5e41eed72",
    "fields": {
      "intitule_naf_65": "Fabrication de condiments et assaisonnements",
      "intitule_naf": "Fabrication de condiments et assaisonnements",
      "code_naf": "1084Z",
      "intitule_naf_40": "Fabric. de condiments et assaisonnements"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b5af7f3caeb10a251b55eb2f94bb19d8fdfba81d",
    "fields": {
      "intitule_naf_65": "Fabrication de produits azotés et d'engrais",
      "intitule_naf": "Fabrication de produits azotés et d'engrais",
      "code_naf": "2015",
      "intitule_naf_40": "Fabric. de produits azotés et d'engrais"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f746eccef5740fcb139976035d700a3e59d81dbe",
    "fields": {
      "intitule_naf_65": "Production de boissons rafraîchissantes",
      "intitule_naf": "Production de boissons rafraîchissantes",
      "code_naf": "1107B",
      "intitule_naf_40": "Production de boissons rafraîchissantes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "6d7cae388ddec9eceecc87e8da9192e52783a532",
    "fields": {
      "intitule_naf_65": "Préparation de fibres textiles et filature",
      "intitule_naf": "Préparation de fibres textiles et filature",
      "code_naf": "1310Z",
      "intitule_naf_40": "Prépa. de fibres textiles et filature"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9373e6bafbc38872ba48fc7c140446efcd3fbc50",
    "fields": {
      "intitule_naf_65": "Reliure et activités connexes",
      "intitule_naf": "Reliure et activités connexes",
      "code_naf": "1814Z",
      "intitule_naf_40": "Reliure et activités connexes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ed02595fbfdc4a3739006dd265eff607fffd65f3",
    "fields": {
      "intitule_naf_65": "Fabrication de boissons",
      "intitule_naf": "Fabrication de boissons",
      "code_naf": "110",
      "intitule_naf_40": "Fabrication de boissons"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "bfdc37878bdb6cff5c57db5b8a737255a4ac1478",
    "fields": {
      "intitule_naf_65": "Fabrication de colles",
      "intitule_naf": "Fabrication de colles",
      "code_naf": "2052",
      "intitule_naf_40": "Fabrication de colles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a72203902cda687522865d4e7596d131f8bfacc4",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres réservoirs, citernes, conteneurs métalliques",
      "intitule_naf": "Fabrication d'autres réservoirs, citernes et conteneurs métalliques",
      "code_naf": "2529Z",
      "intitule_naf_40": "Fab. aut. réservr, citerne, etc. métal."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "42e8f6d7b50a155c3d87b2ecbb362b79e5e8ab48",
    "fields": {
      "intitule_naf_65": "Fabrication produits de consommation courante en plastique",
      "intitule_naf": "Fabrication de produits de consommation courante en matières plastiques",
      "code_naf": "2229B",
      "intitule_naf_40": "Fab. prod. conso. courante en plastique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5be2e47084ddd77856dbf589638c6b4b0b3c2d62",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres ouvrages en béton, en ciment ou en plâtre",
      "intitule_naf": "Fabrication d'autres ouvrages en béton, en ciment ou en plâtre",
      "code_naf": "2369Z",
      "intitule_naf_40": "Fab. aut. ouvrage béton, ciment, plâtre"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "53a18c4d22c352ee05b9d0aa7ade3f8396f6c408",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres produits en céramique et en porcelaine",
      "intitule_naf": "Fabrication d'autres produits en céramique et en porcelaine",
      "code_naf": "234",
      "intitule_naf_40": "Fab. aut .prod. en céramiq. & porcelaine"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "84b5367922f6e35c01521266bf9a3c964130127e",
    "fields": {
      "intitule_naf_65": "Fabrication de structures métalliques et de parties de structures",
      "intitule_naf": "Fabrication de structures métalliques et de parties de structures",
      "code_naf": "2511Z",
      "intitule_naf_40": "Fab. structure métal. & partie structure"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "de50d17c7988e5c9abaeb2713f118ead258e43da",
    "fields": {
      "intitule_naf_65": "Taille, façonnage et finissage de pierres",
      "intitule_naf": "Taille, façonnage et finissage de pierres",
      "code_naf": "2370Z",
      "intitule_naf_40": "Taille, façonnage & finissage de pierres"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e04cdfe544c89a099a6cfb30518dcc86e2eca685",
    "fields": {
      "intitule_naf_65": "Industrie pharmaceutique",
      "intitule_naf": "Industrie pharmaceutique",
      "code_naf": "21",
      "intitule_naf_40": "Industrie pharmaceutique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "802fff60efd35d3f0f38ebf024aed8856857be30",
    "fields": {
      "intitule_naf_65": "Fabrication de ciment",
      "intitule_naf": "Fabrication de ciment",
      "code_naf": "2351",
      "intitule_naf_40": "Fabrication de ciment"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8fbcc046f8dd5ab8ce546ffc58e1461f1ab6b81d",
    "fields": {
      "intitule_naf_65": "Autres activ. serv. financiers, hors assurance & caisses retraite",
      "intitule_naf": "Autres activités des services financiers, hors assurance et caisses de retraite",
      "code_naf": "649",
      "intitule_naf_40": "Aut. act. finan. hs assur. & c. retrait."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "588da37862b583d05eb73e7ec714d25638203d44",
    "fields": {
      "intitule_naf_65": "Édition de livres et périodiques et autres activités d'édition",
      "intitule_naf": "Édition de livres et périodiques et autres activités d'édition",
      "code_naf": "581",
      "intitule_naf_40": "Édition livre & périodiq. & aut. édition"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e33f3c07bccc5e6212d324e928ed863a4627d565",
    "fields": {
      "intitule_naf_65": "Fonds de placement et entités financières similaires",
      "intitule_naf": "Fonds de placement et entités financières similaires",
      "code_naf": "6430",
      "intitule_naf_40": "Fonds placement & entité financ. simil."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "495ee5089b16854986aa61f50751fcb2b39369d8",
    "fields": {
      "intitule_naf_65": "Activités des agents et courtiers d'assurances",
      "intitule_naf": "Activités des agents et courtiers d'assurances",
      "code_naf": "6622Z",
      "intitule_naf_40": "Act. des agents & courtiers d'assurances"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "bfd7047a856052ab30cc28abdb6a9c8b6b460f2b",
    "fields": {
      "intitule_naf_65": "Autres activités de télécommunication",
      "intitule_naf": "Autres activités de télécommunication",
      "code_naf": "6190",
      "intitule_naf_40": "Autres activités de télécommunication"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c31fb6b57f89982e11240c2d4462f3557ff0c83b",
    "fields": {
      "intitule_naf_65": "Activités spécialisées de design",
      "intitule_naf": "Activités spécialisées de design",
      "code_naf": "7410Z",
      "intitule_naf_40": "Activités spécialisées de design"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ae53703786946c9395fa916dcdd8809648e284bf",
    "fields": {
      "intitule_naf_65": "Activités juridiques et comptables",
      "intitule_naf": "Activités juridiques et comptables",
      "code_naf": "69",
      "intitule_naf_40": "Activités juridiques et comptables"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f7a7560a00e89cc0e0163769ea9172fd12e91c03",
    "fields": {
      "intitule_naf_65": "Transports aériens de passagers",
      "intitule_naf": "Transports aériens de passagers",
      "code_naf": "511",
      "intitule_naf_40": "Transports aériens de passagers"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "49f56968a88d2fc4304b6fcbd9e472a68dcb04eb",
    "fields": {
      "intitule_naf_65": "Agences immobilières",
      "intitule_naf": "Agences immobilières",
      "code_naf": "6831Z",
      "intitule_naf_40": "Agences immobilières"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "05a04fecfaefc2925b8a20d311856708212e44ed",
    "fields": {
      "intitule_naf_65": "Sylviculture et autres activités forestières",
      "intitule_naf": "Sylviculture et autres activités forestières",
      "code_naf": "210",
      "intitule_naf_40": "Sylviculture & autres act. forestières"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "fb65dc50612cd59e9ba49c7013938dcc9da341af",
    "fields": {
      "intitule_naf_65": "Sylviculture et exploitation forestière",
      "intitule_naf": "Sylviculture et exploitation forestière",
      "code_naf": "02",
      "intitule_naf_40": "Sylviculture et exploitation forestière"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2ebeaff56104f79c028a4697ec9791b437c2349d",
    "fields": {
      "intitule_naf_65": "Autres industries extractives",
      "intitule_naf": "Autres industries extractives",
      "code_naf": "08",
      "intitule_naf_40": "Autres industries extractives"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a14c2ddc914c1e8993a6deb36d054fd95cbbef0b",
    "fields": {
      "intitule_naf_65": "Culture et élevage associés",
      "intitule_naf": "Culture et élevage associés",
      "code_naf": "15",
      "intitule_naf_40": "Culture et élevage associés"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7a8870f2acac36928da4fc95ef4b4f0de03870eb",
    "fields": {
      "intitule_naf_65": "Extraction de gaz naturel",
      "intitule_naf": "Extraction de gaz naturel",
      "code_naf": "0620Z",
      "intitule_naf_40": "Extraction de gaz naturel"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0aa18ff32c32d8655684eda8fbb3e303aafb88c5",
    "fields": {
      "intitule_naf_65": "Élevage de volailles",
      "intitule_naf": "Élevage de volailles",
      "code_naf": "147",
      "intitule_naf_40": "Élevage de volailles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "15b6c7480b491a7c6530b896afa44e1dbd56e077",
    "fields": {
      "intitule_naf_65": "Extraction de houille",
      "intitule_naf": "Extraction de houille",
      "code_naf": "51",
      "intitule_naf_40": "Extraction de houille"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "310d6b5c29dbb9789a8d761a1ab6b5d9bb958e25",
    "fields": {
      "intitule_naf_65": "Trav. bois; fab. art. bois liège (sf mbles), vannerie & sparterie",
      "intitule_naf": "Travail du bois et fabrication d'articles en bois et en liège, à l'exception des meubles ; fabrication d'articles en vannerie et sparterie",
      "code_naf": "16",
      "intitule_naf_40": "Trav. bois; fab. article bois, vannerie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "23c313a2bfc6ec0e997022f8e5f3a0c0e0da4312",
    "fields": {
      "intitule_naf_65": "Fabric. tubes, tuyaux, profilés creux & accessoir. corresp. acier",
      "intitule_naf": "Fabrication de tubes, tuyaux, profilés creux et accessoires correspondants en acier",
      "code_naf": "2420",
      "intitule_naf_40": "Fab. tube, profilé creux etc. en acier"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "efe679b3e4d990d2fad7da890b971659741decb9",
    "fields": {
      "intitule_naf_65": "Fabrication d'éléments matières plastiques pour la construction",
      "intitule_naf": "Fabrication d'éléments en matières plastiques pour la construction",
      "code_naf": "2223Z",
      "intitule_naf_40": "Fab. élément mat. plastiq. pr construct."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "924fda2df52ab5ee3ef9a3bbd03d21476773a856",
    "fields": {
      "intitule_naf_65": "Fabrication d'éléments en plâtre pour la construction",
      "intitule_naf": "Fabrication d'éléments en plâtre pour la construction",
      "code_naf": "2362Z",
      "intitule_naf_40": "Fab. élément en plâtre pour la construc."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c7c69b90469d856aebd2f7c87df5c1eaafdcd92f",
    "fields": {
      "intitule_naf_65": "Exploit gravieres & sablieres, extraction argiles & kaolin",
      "intitule_naf": "Exploitation de gravières et sablières, extraction d'argiles et de kaolin",
      "code_naf": "0812Z",
      "intitule_naf_40": "Exploit. gravière & sabl., extr. argile"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2d162717a086ce330e0b9d5ca8eb8c66ebec4894",
    "fields": {
      "intitule_naf_65": "Fabrication de cacao, chocolat et de produits de confiserie",
      "intitule_naf": "Fabrication de cacao, chocolat et de produits de confiserie",
      "code_naf": "1082Z",
      "intitule_naf_40": "Fabric. de cacao, chocolat & confiseries"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "284b76a4742e423140f60c54a02f06edfb5ac0a6",
    "fields": {
      "intitule_naf_65": "Fabrication de carreaux en céramique",
      "intitule_naf": "Fabrication de carreaux en céramique",
      "code_naf": "2331",
      "intitule_naf_40": "Fabrication de carreaux en céramique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "36f89a05f371eae8fb169184cbcb739d8a787ae1",
    "fields": {
      "intitule_naf_65": "Fabrication de papiers peints",
      "intitule_naf": "Fabrication de papiers peints",
      "code_naf": "1724Z",
      "intitule_naf_40": "Fabrication de papiers peints"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7911ad6d6771533bc20c5d86bf4ba1cfb6237a57",
    "fields": {
      "intitule_naf_65": "Activités extractives n.c.a.",
      "intitule_naf": "Activités extractives n.c.a.",
      "code_naf": "89",
      "intitule_naf_40": "Activités extractives n.c.a."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c8fbc3906380061f2f37714f1cfd7da0bf5dbbc8",
    "fields": {
      "intitule_naf_65": "Étirage à froid de barres",
      "intitule_naf": "Étirage à froid de barres",
      "code_naf": "2431Z",
      "intitule_naf_40": "Étirage à froid de barres"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7287807836d6f93539b53b7460ae5eaf81145767",
    "fields": {
      "intitule_naf_65": "Fabrication de bicyclettes et de véhicules pour invalides",
      "intitule_naf": "Fabrication de bicyclettes et de véhicules pour invalides",
      "code_naf": "3092Z",
      "intitule_naf_40": "Fab. bicyclette & véhic. pour invalides"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9dd8c4b19f4d10ac5d955100cccfc9b9ad3121a5",
    "fields": {
      "intitule_naf_65": "Fabrication d'emballages métalliques légers",
      "intitule_naf": "Fabrication d'emballages métalliques légers",
      "code_naf": "2592",
      "intitule_naf_40": "Fabric. d'emballages métalliques légers"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "47e26ce9789eb9eef0882222c8d0585ab8d5b378",
    "fields": {
      "intitule_naf_65": "Fabrication de carrosseries et remorques",
      "intitule_naf": "Fabrication de carrosseries et remorques",
      "code_naf": "2920",
      "intitule_naf_40": "Fabrication de carrosseries et remorques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "43bcbd15184e121e758338ceb791518db76b3950",
    "fields": {
      "intitule_naf_65": "Fabrication de machines de formage des métaux",
      "intitule_naf": "Fabrication de machines de formage des métaux",
      "code_naf": "2841",
      "intitule_naf_40": "Fab. de machines de formage des métaux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b01cf5b5ac3c6d89775ebff2e8d8fe614e39f71a",
    "fields": {
      "intitule_naf_65": "Fabrication de câbles de fibres optiques",
      "intitule_naf": "Fabrication de câbles de fibres optiques",
      "code_naf": "2731",
      "intitule_naf_40": "Fabrication de câbles de fibres optiques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3ae1aa912b20b8f00b56b890136eee1d2a3b6ea4",
    "fields": {
      "intitule_naf_65": "Fonderie d'autres métaux non ferreux",
      "intitule_naf": "Fonderie d'autres métaux non ferreux",
      "code_naf": "2454",
      "intitule_naf_40": "Fonderie d'autres métaux non ferreux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "dc72e616dd81977534c94fb2f3cd5bb794323f76",
    "fields": {
      "intitule_naf_65": "Horlogerie",
      "intitule_naf": "Horlogerie",
      "code_naf": "2652Z",
      "intitule_naf_40": "Horlogerie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "6cca6247b2930e7024c944dcf46f16e4248af0c7",
    "fields": {
      "intitule_naf_65": "Usinage",
      "intitule_naf": "Usinage",
      "code_naf": "2562",
      "intitule_naf_40": "Usinage"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d1d069b34a3247cbd323035e4b65a12e6098ee9f",
    "fields": {
      "intitule_naf_65": "Interm. comm. machines, équipts industriels, navires et avions",
      "intitule_naf": "Intermédiaires du commerce en machines, équipements industriels, navires et avions",
      "code_naf": "4614",
      "intitule_naf_40": "Int. comm. équipt indus., navire & avion"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "41851da943dac4a88655d6312d03339c0e5cbed6",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres équipements de transport n.c.a.",
      "intitule_naf": "Fabrication d'autres équipements de transport n.c.a.",
      "code_naf": "3099",
      "intitule_naf_40": "Fab. aut. équipement de transport n.c.a."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a81e760a17e8e23dce1559317702147e879fe293",
    "fields": {
      "intitule_naf_65": "Production et distribution de vapeur et d'air conditionné",
      "intitule_naf": "Production et distribution de vapeur et d'air conditionné",
      "code_naf": "353",
      "intitule_naf_40": "Prod. & distrib. vapeur et air condit."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c11a697be807196955f8f3f38d8aaaf6d8af3acb",
    "fields": {
      "intitule_naf_65": "Commerce de combustibles gazeux par conduites",
      "intitule_naf": "Commerce de combustibles gazeux par conduites",
      "code_naf": "3523",
      "intitule_naf_40": "Commerce combustible gazeux par conduite"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d3b2ca2d84533599706543458350dba3adaaf293",
    "fields": {
      "intitule_naf_65": "Commerce de gros de fleurs et plantes",
      "intitule_naf": "Commerce de gros de fleurs et plantes",
      "code_naf": "4622",
      "intitule_naf_40": "Commerce de gros de fleurs et plantes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4e0d77404fa37348acd026c97584bb71c091d118",
    "fields": {
      "intitule_naf_65": "Commerce de gros de mobilier de bureau",
      "intitule_naf": "Commerce de gros de mobilier de bureau",
      "code_naf": "4665",
      "intitule_naf_40": "Commerce de gros de mobilier de bureau"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4d50c8c264f9e5bc647a624be53fde45e3a85943",
    "fields": {
      "intitule_naf_65": "Construction de routes et autoroutes",
      "intitule_naf": "Construction de routes et autoroutes",
      "code_naf": "4211Z",
      "intitule_naf_40": "Construction de routes et autoroutes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c1dea11beb9afa0b62dc814ff82a3da3889615c2",
    "fields": {
      "intitule_naf_65": "Fabrication d'instruments de musique",
      "intitule_naf": "Fabrication d'instruments de musique",
      "code_naf": "3220",
      "intitule_naf_40": "Fabrication d'instruments de musique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "34a426fc08ce35960dfce763fa2da0aad4a1504e",
    "fields": {
      "intitule_naf_65": "Centrales d'achat alimentaires",
      "intitule_naf": "Centrales d'achat alimentaires",
      "code_naf": "4617A",
      "intitule_naf_40": "Centrales d'achat alimentaires"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "442d164bfbafacab172e81356a6077234305d2ed",
    "fields": {
      "intitule_naf_65": "Distribution d'électricité",
      "intitule_naf": "Distribution d'électricité",
      "code_naf": "3513",
      "intitule_naf_40": "Distribution d'électricité"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3f5b0b067e31b557557a4f925f706cf96d24a399",
    "fields": {
      "intitule_naf_65": "Comm. détail poissons crustacés & mollusques (magasin spécialisé)",
      "intitule_naf": "Commerce de détail de poissons, crustacés et mollusques en magasin spécialisé",
      "code_naf": "4723Z",
      "intitule_naf_40": "Comm. détail poisson crustacé etc. (ms)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f0e0a700c934d0977078324127714413e996bb53",
    "fields": {
      "intitule_naf_65": "Commerce de gros de fournitures et équipements industriels divers",
      "intitule_naf": "Commerce de gros (commerce interentreprises) de fournitures et équipements industriels divers",
      "code_naf": "4669B",
      "intitule_naf_40": "Com. gros fourniture & équipt ind. div."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "15a149c56271aaa06fbacb3f73508388a6bf3812",
    "fields": {
      "intitule_naf_65": "Commerce de détail de carburants en magasin spécialisé",
      "intitule_naf": "Commerce de détail de carburants en magasin spécialisé",
      "code_naf": "473",
      "intitule_naf_40": "Comm. détail carburant en mag. spéciali."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a61ee956a811da315b6ebbc777cf83b33e84c037",
    "fields": {
      "intitule_naf_65": "Vente à distance sur catalogue spécialisé",
      "intitule_naf": "Vente à distance sur catalogue spécialisé",
      "code_naf": "4791B",
      "intitule_naf_40": "Vente à distance sur catalogue spécialis"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8c90ac5eb539b5a8aaceb85f7f0c5b07ac629ab6",
    "fields": {
      "intitule_naf_65": "Autres transports terrestres de voyageurs n.c.a.",
      "intitule_naf": "Autres transports terrestres de voyageurs n.c.a.",
      "code_naf": "4939",
      "intitule_naf_40": "Aut. transport terrestre voyageurs nca."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b8ec640955496ded8b73fd426941280703a831f2",
    "fields": {
      "intitule_naf_65": "Distribution de films cinématographiques",
      "intitule_naf": "Distribution de films cinématographiques",
      "code_naf": "5913A",
      "intitule_naf_40": "Distribution de films cinématographiques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1dfb41e6e3c196461900bf7c69bf0fdb39cc5286",
    "fields": {
      "intitule_naf_65": "Transports routiers réguliers de voyageurs",
      "intitule_naf": "Transports routiers réguliers de voyageurs",
      "code_naf": "4939A",
      "intitule_naf_40": "Transport routier régulier de voyageurs"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "cc9f7e4e264945efc94e9481b5b6e603d3ea0b1e",
    "fields": {
      "intitule_naf_65": "Activités des sociétés holding",
      "intitule_naf": "Activités des sociétés holding",
      "code_naf": "6420",
      "intitule_naf_40": "Activités des sociétés holding"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e2a956b32c52d9a17065a132f620064a00828a87",
    "fields": {
      "intitule_naf_65": "Location et location-bail d'articles de loisirs et de sport",
      "intitule_naf": "Location et location-bail d'articles de loisirs et de sport",
      "code_naf": "7721Z",
      "intitule_naf_40": "Loc. & loc.-bail article loisir & sport"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f25a6658e60ffec4497be91ed0d561671b462c92",
    "fields": {
      "intitule_naf_65": "Activités des marchands de biens immobiliers",
      "intitule_naf": "Activités des marchands de biens immobiliers",
      "code_naf": "6810",
      "intitule_naf_40": "Activité marchands de biens immobiliers"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "624c439d4e30a9465fc54ffd6532b0303873a58f",
    "fields": {
      "intitule_naf_65": "Activités des agences de travail temporaire",
      "intitule_naf": "Activités des agences de travail temporaire",
      "code_naf": "7820Z",
      "intitule_naf_40": "Activ. des agences de travail temporaire"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d9040887c01490d471dcba84df08d1b7486c1ccc",
    "fields": {
      "intitule_naf_65": "Administration générale, économique et sociale",
      "intitule_naf": "Administration générale, économique et sociale",
      "code_naf": "841",
      "intitule_naf_40": "Admin. générale, économique & sociale"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "37a69107bb6bba81a09d71936a2f9c1491fea2e5",
    "fields": {
      "intitule_naf_65": "Activité des médecins généralistes",
      "intitule_naf": "Activité des médecins généralistes",
      "code_naf": "8621Z",
      "intitule_naf_40": "Activité des médecins généralistes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0d1fdf918dfe85be70bb9c0df679d0034c9dbbf2",
    "fields": {
      "intitule_naf_65": "Activité des médecins généralistes",
      "intitule_naf": "Activité des médecins généralistes",
      "code_naf": "8621",
      "intitule_naf_40": "Activité des médecins généralistes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1a20af69a58f8a35ce0b1f2c42b2ddcf46d8d1e2",
    "fields": {
      "intitule_naf_65": "Activités des agences de publicité",
      "intitule_naf": "Activités des agences de publicité",
      "code_naf": "7311Z",
      "intitule_naf_40": "Activités des agences de publicité"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c42919bb88782b60c9c88d4ad3bdaee9cdf87b4d",
    "fields": {
      "intitule_naf_65": "Activités des agences de publicité",
      "intitule_naf": "Activités des agences de publicité",
      "code_naf": "7311",
      "intitule_naf_40": "Activités des agences de publicité"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a0c0f23e3d82624bbb7dd9c8b3bd5a9ec008d8ac",
    "fields": {
      "intitule_naf_65": "Activités de sécurité privée",
      "intitule_naf": "Activités de sécurité privée",
      "code_naf": "8010Z",
      "intitule_naf_40": "Activités de sécurité privée"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b2cf6e71bc8bbe02a431300e4115bef0bcffd422",
    "fields": {
      "intitule_naf_65": "Agences immobilières",
      "intitule_naf": "Agences immobilières",
      "code_naf": "6831",
      "intitule_naf_40": "Agences immobilières"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "eee98cfce4902b0aaa96e6196fe72ce7289b6693",
    "fields": {
      "intitule_naf_65": "Préparation de jus de fruits et légumes",
      "intitule_naf": "Préparation de jus de fruits et légumes",
      "code_naf": "1032Z",
      "intitule_naf_40": "Préparation de jus de fruits et légumes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e425adcea923d248cd53caf2d787ac80e03fd181",
    "fields": {
      "intitule_naf_65": "Fabric. fils câbles & matériel d'installation électrique",
      "intitule_naf": "Fabrication de fils et câbles et de matériel d'installation électrique",
      "code_naf": "273",
      "intitule_naf_40": "Fab. fil câble & mat. install. électriq."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9f630931ccdba3347877c689247f2d8a4471efcd",
    "fields": {
      "intitule_naf_65": "Autres intermédiaires du commerce en denrées, boissons et tabac",
      "intitule_naf": "Autres intermédiaires du commerce en denrées, boissons et tabac",
      "code_naf": "4617B",
      "intitule_naf_40": "Autre ic en denrées, boissons et tabac"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ae5e374832a2a35bd05e32d44cb0f78c1f139eb7",
    "fields": {
      "intitule_naf_65": "Fabrication articles bijouterie fantaisie & articles similaires",
      "intitule_naf": "Fabrication d'articles de bijouterie fantaisie et articles similaires",
      "code_naf": "3213Z",
      "intitule_naf_40": "Fab. art. bijout. fantaisie & similaire"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "40f86fa7246a91ef0d24de746c0212f7c589de44",
    "fields": {
      "intitule_naf_65": "Construction de bâtiments résidentiels et non résidentiels",
      "intitule_naf": "Construction de bâtiments résidentiels et non résidentiels",
      "code_naf": "412",
      "intitule_naf_40": "Construc. bâtimnt résid. & non résident."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "dd617b98d4aae6fb66e7258bbfaf73c4ce631afc",
    "fields": {
      "intitule_naf_65": "Commerce de gros (commerce interentreprises) de matériel agricole",
      "intitule_naf": "Commerce de gros (commerce interentreprises) de matériel agricole",
      "code_naf": "4661Z",
      "intitule_naf_40": "Commerce de gros de matériel agricole"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c5985eb6c5fca1395890ea23df2f52b877bd73eb",
    "fields": {
      "intitule_naf_65": "Fabrication de machines-outils pour le travail des métaux",
      "intitule_naf": "Fabrication de machines-outils pour le travail des métaux",
      "code_naf": "2841Z",
      "intitule_naf_40": "Fab. de machines de formage des métaux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "026deb767ef24016e14e3d5d21f2b54de04c0195",
    "fields": {
      "intitule_naf_65": "Commerce de gros de viandes de boucherie",
      "intitule_naf": "Commerce de gros (commerce interentreprises) de viandes de boucherie",
      "code_naf": "4632A",
      "intitule_naf_40": "Commerce de gros de viandes de boucherie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9d575dd27002993465170e5ecabdbd279887f8e3",
    "fields": {
      "intitule_naf_65": "Commerce de gros de produits chimiques",
      "intitule_naf": "Commerce de gros de produits chimiques",
      "code_naf": "4675",
      "intitule_naf_40": "Commerce de gros de produits chimiques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "fd8b046ff9d328fe2050c5f03c8ab9d4c3912322",
    "fields": {
      "intitule_naf_65": "Commerce d'alimentation générale",
      "intitule_naf": "Commerce d'alimentation générale",
      "code_naf": "4711B",
      "intitule_naf_40": "Commerce d'alimentation générale"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2ff048638f974d5eff58dcfa011ae26987d78338",
    "fields": {
      "intitule_naf_65": "Travaux d'étanchéification",
      "intitule_naf": "Travaux d'étanchéification",
      "code_naf": "4399A",
      "intitule_naf_40": "Travaux d'étanchéification"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2d915346e85f35d49a4d72c573a109f841e7c2de",
    "fields": {
      "intitule_naf_65": "Autres activités spécialisées, scientifiques et techniques n.c.a.",
      "intitule_naf": "Autres activités spécialisées, scientifiques et techniques n.c.a.",
      "code_naf": "7490",
      "intitule_naf_40": "Aut. act. spéc. scientif. & techn. nca."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0c5e5d8e19506374b047f1c9ba83a95cb5c80d21",
    "fields": {
      "intitule_naf_65": "Hébergement touristique et autre hébergement de courte durée",
      "intitule_naf": "Hébergement touristique et autre hébergement de courte durée",
      "code_naf": "5520",
      "intitule_naf_40": "Hébergt tourist. & aut. hbt courte durée"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "43dea5c8286ec2d88c69076c468979b7af71cb9a",
    "fields": {
      "intitule_naf_65": "Transports routiers de fret et services de déménagement",
      "intitule_naf": "Transports routiers de fret et services de déménagement",
      "code_naf": "494",
      "intitule_naf_40": "Transport routier de fret & sce déménagt"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "48f9ebc43962a74317a206ea9d05f2e53ec0a3c6",
    "fields": {
      "intitule_naf_65": "Conseil pour les affaires et autres conseils de gestion",
      "intitule_naf": "Conseil pour les affaires et autres conseils de gestion",
      "code_naf": "7022",
      "intitule_naf_40": "Conseil pr affaire & aut. cons. gestion"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3935c393566320ff4ff8662296a4a8d8e6eb7c19",
    "fields": {
      "intitule_naf_65": "Programmation de télévision et télédiffusion",
      "intitule_naf": "Programmation de télévision et télédiffusion",
      "code_naf": "6020",
      "intitule_naf_40": "Programmation de télévision & télédiff."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a06f481b2dcd403365dbfc521fd946e3e77cc978",
    "fields": {
      "intitule_naf_65": "Services auxiliaires des transports aériens",
      "intitule_naf": "Services auxiliaires des transports aériens",
      "code_naf": "5223",
      "intitule_naf_40": "Sces auxiliaires des transports aériens"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "00b6b11f0ddd597553e592a15365c545cb6d4abf",
    "fields": {
      "intitule_naf_65": "Gestion d'installations informatiques",
      "intitule_naf": "Gestion d'installations informatiques",
      "code_naf": "6203Z",
      "intitule_naf_40": "Gestion d'installations informatiques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "da5ecb8d4151d8287eee72b16d3ce488fb0bfad2",
    "fields": {
      "intitule_naf_65": "Autres intermédiations monétaires",
      "intitule_naf": "Autres intermédiations monétaires",
      "code_naf": "6419",
      "intitule_naf_40": "Autres intermédiations monétaires"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a8db8b1eb158d8f50ab8a157f8b7d1d300b93c9c",
    "fields": {
      "intitule_naf_65": "Hôtels et hébergement similaire",
      "intitule_naf": "Hôtels et hébergement similaire",
      "code_naf": "551",
      "intitule_naf_40": "Hôtels et hébergement similaire"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0c4da45a0d9bdbfb8746e80e24e341f4a66b4e35",
    "fields": {
      "intitule_naf_65": "Transports aériens de fret",
      "intitule_naf": "Transports aériens de fret",
      "code_naf": "5121Z",
      "intitule_naf_40": "Transports aériens de fret"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "158c50c56ec935f45f7cc5ff4d3599b82c9b0021",
    "fields": {
      "intitule_naf_65": "Dépollution et autres services de gestion des déchets",
      "intitule_naf": "Dépollution et autres services de gestion des déchets",
      "code_naf": "390",
      "intitule_naf_40": "Dépollution & autre sces gestion déchets"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "726158cfd8e2aa26c94ac7935f3b496f191e44d4",
    "fields": {
      "intitule_naf_65": "Production et distribution de vapeur et d'air conditionné",
      "intitule_naf": "Production et distribution de vapeur et d'air conditionné",
      "code_naf": "3530Z",
      "intitule_naf_40": "Prod. & distrib. vapeur et air condit."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0a5f8812d8381c9e02c581c88309698b839bb62d",
    "fields": {
      "intitule_naf_65": "Fabrication de supports magnétiques et optiques",
      "intitule_naf": "Fabrication de supports magnétiques et optiques",
      "code_naf": "2680",
      "intitule_naf_40": "Fab. de supports magnétiques et optiques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "de7b0353a09875061012b770e6ed7884db154573",
    "fields": {
      "intitule_naf_65": "Fabrication de machines pour les industries textiles",
      "intitule_naf": "Fabrication de machines pour les industries textiles",
      "code_naf": "2894Z",
      "intitule_naf_40": "Fab. machine pour industries textiles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "fe8c7f87f45ce9cbfe36cebdbf4c2d5bf794dc01",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres produits métalliques n.c.a.",
      "intitule_naf": "Fabrication d'autres produits métalliques n.c.a.",
      "code_naf": "2599",
      "intitule_naf_40": "Fabric. autres prod. métalliques n.c.a."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "29329ab7ae6ce329800707083b97920546d6f5a7",
    "fields": {
      "intitule_naf_65": "Travaux de terrassement spécialisés ou de grande masse",
      "intitule_naf": "Travaux de terrassement spécialisés ou de grande masse",
      "code_naf": "4312B",
      "intitule_naf_40": "Travaux de terrassement spécialisés"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4e12365126bb051d22058753233921a649deb1d2",
    "fields": {
      "intitule_naf_65": "Fabrication de meubles de bureau et de magasin",
      "intitule_naf": "Fabrication de meubles de bureau et de magasin",
      "code_naf": "3101Z",
      "intitule_naf_40": "Fab. de meubles de bureau et de magasin"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4476f3d22effcdc1df340e275d0b62b7cde2e915",
    "fields": {
      "intitule_naf_65": "Extraction de minerais de métaux non ferreux",
      "intitule_naf": "Extraction de minerais de métaux non ferreux",
      "code_naf": "72",
      "intitule_naf_40": "Extr. de minerais de métaux non ferreux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f45c5df1eb60d88b58a29bc9a18148f4133b0ae3",
    "fields": {
      "intitule_naf_65": "Construction d'autres bâtiments",
      "intitule_naf": "Construction d'autres bâtiments",
      "code_naf": "4120B",
      "intitule_naf_40": "Construction d'autres bâtiments"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f83811008b2b8d884dcf26be56a9ec2216238777",
    "fields": {
      "intitule_naf_65": "Génie civil",
      "intitule_naf": "Génie civil",
      "code_naf": "42",
      "intitule_naf_40": "Génie civil"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "53b0e47db02a5b1a9dea04ad97c6e5423adb83d1",
    "fields": {
      "intitule_naf_65": "Prod. & distribution électricité gaz vapeur & air conditionné",
      "intitule_naf": "Production et distribution d'électricité, de gaz, de vapeur et d'air conditionné",
      "code_naf": "35",
      "intitule_naf_40": "Prdn & distr. élec. gaz vap. & air cond."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d833d21dd9baea931848d20f477178eb97b95284",
    "fields": {
      "intitule_naf_65": "Commerce de détail de fruits et légumes en magasin spécialisé",
      "intitule_naf": "Commerce de détail de fruits et légumes en magasin spécialisé",
      "code_naf": "4721Z",
      "intitule_naf_40": "Com. détail fruit & légume en mag. spéc."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e4c6be9afa61e2e0a7f7e392b1f63e6136674a58",
    "fields": {
      "intitule_naf_65": "Hébergement social pour personnes âgées ou handicapées physiques",
      "intitule_naf": "Hébergement social pour personnes âgées ou handicapées physiques",
      "code_naf": "8730",
      "intitule_naf_40": "Hébgt soc. perso. âgées, hand. physiques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5fcb9f22bb243d93e8f09f7cfbb808fd3edf77dc",
    "fields": {
      "intitule_naf_65": "Hébergement médicalisé pour enfants handicapés",
      "intitule_naf": "Hébergement médicalisé pour enfants handicapés",
      "code_naf": "8710B",
      "intitule_naf_40": "Hébergt médicalisé pr enfants handicapés"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f8f3891ae362893024ef19a642fe1cf74028201d",
    "fields": {
      "intitule_naf_65": "Autres activités récréatives et de loisirs",
      "intitule_naf": "Autres activités récréatives et de loisirs",
      "code_naf": "9329Z",
      "intitule_naf_40": "Autres activités récréative & de loisirs"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b39956093bd0331c1442ed4374033f4951a44f54",
    "fields": {
      "intitule_naf_65": "Activités de clubs de sports",
      "intitule_naf": "Activités de clubs de sports",
      "code_naf": "9312",
      "intitule_naf_40": "Activités de clubs de sports"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d347fae2927b391b756efe73609f885720a3d0d5",
    "fields": {
      "intitule_naf_65": "Enseignement supérieur",
      "intitule_naf": "Enseignement supérieur",
      "code_naf": "8542",
      "intitule_naf_40": "Enseignement supérieur"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c8442d5aaefea99af6b9531735202274a43eb871",
    "fields": {
      "intitule_naf_65": "Enseignement primaire",
      "intitule_naf": "Enseignement primaire",
      "code_naf": "8520Z",
      "intitule_naf_40": "Enseignement primaire"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "67b54b017c6be69b9d8effe8fb56ff338ddc7891",
    "fields": {
      "intitule_naf_65": "Autres enseignements",
      "intitule_naf": "Autres enseignements",
      "code_naf": "8559B",
      "intitule_naf_40": "Autres enseignements"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7d138e8e6f4031e0f8e8a72183ae8d1a1aab4999",
    "fields": {
      "intitule_naf_65": "Soins de beauté",
      "intitule_naf": "Soins de beauté",
      "code_naf": "9602B",
      "intitule_naf_40": "Soins de beauté"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f496a24eee5a84b0688f616542d1bf5e854af867",
    "fields": {
      "intitule_naf_65": "Fabrication de caoutchouc synthétique",
      "intitule_naf": "Fabrication de caoutchouc synthétique",
      "code_naf": "2017",
      "intitule_naf_40": "Fabrication de caoutchouc synthétique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2b379368991ff35c74b7524ff35e1b9b853d3f42",
    "fields": {
      "intitule_naf_65": "Fabrication d'ouvrages en fibre-ciment",
      "intitule_naf": "Fabrication d'ouvrages en fibre-ciment",
      "code_naf": "2365",
      "intitule_naf_40": "Fabrication d'ouvrages en fibre-ciment"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f68baa5ec2e467ab08c85dfd7d3a0d901d054bfc",
    "fields": {
      "intitule_naf_65": "Cuisson de produits de boulangerie",
      "intitule_naf": "Cuisson de produits de boulangerie",
      "code_naf": "1071B",
      "intitule_naf_40": "Cuisson de produits de boulangerie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "457d50ab87c5530e605467c579d647ce40d0dce2",
    "fields": {
      "intitule_naf_65": "Fabrication de vêtements en cuir",
      "intitule_naf": "Fabrication de vêtements en cuir",
      "code_naf": "1411",
      "intitule_naf_40": "Fabrication de vêtements en cuir"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4f6c3156b0f2da2b308ea8c28ee31627dbd468b5",
    "fields": {
      "intitule_naf_65": "Laminage à froid de feuillards",
      "intitule_naf": "Laminage à froid de feuillards",
      "code_naf": "2432",
      "intitule_naf_40": "Laminage à froid de feuillards"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1bc500243835e979a0024f3c37d03662d8020f96",
    "fields": {
      "intitule_naf_65": "Imprimerie et services annexes",
      "intitule_naf": "Imprimerie et services annexes",
      "code_naf": "181",
      "intitule_naf_40": "Imprimerie et services annexes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7a93db541d45bc854d501a736052159cea5ac140",
    "fields": {
      "intitule_naf_65": "Reproduction d'enregistrements",
      "intitule_naf": "Reproduction d'enregistrements",
      "code_naf": "1820",
      "intitule_naf_40": "Reproduction d'enregistrements"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7843a06a2bd6f8ce1126390f9288447a534dfff4",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres textiles",
      "intitule_naf": "Fabrication d'autres textiles",
      "code_naf": "139",
      "intitule_naf_40": "Fabrication d'autres textiles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "bbbad1f2f4efa16b3f5e85b7c8e1bfc41f5c884d",
    "fields": {
      "intitule_naf_65": "Industrie des eaux de table",
      "intitule_naf": "Industrie des eaux de table",
      "code_naf": "1107A",
      "intitule_naf_40": "Industrie des eaux de table"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "366b026832d85e3476fc0ab24795de377f1c5da2",
    "fields": {
      "intitule_naf_65": "Pâtisserie",
      "intitule_naf": "Pâtisserie",
      "code_naf": "1071D",
      "intitule_naf_40": "Pâtisserie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "95dcf0028502b6b8a7cb698d0011902d85017a95",
    "fields": {
      "intitule_naf_65": "Concept. d'ens. & assembl s/site d'éqpts ctrle des processus ind.",
      "intitule_naf": "Conception d'ensemble et assemblage sur site industriel d'équipements de contrôle des processus industriels",
      "code_naf": "3320C",
      "intitule_naf_40": "Instal. éqpts ctrle des processus indus."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a5af7b3bd29ec37c5b174421a6bb7d17256ccb96",
    "fields": {
      "intitule_naf_65": "Photocopie prépa. documents & aut. activ. spéc. soutien de bureau",
      "intitule_naf": "Photocopie, préparation de documents et autres activités spécialisées de soutien de bureau",
      "code_naf": "8219Z",
      "intitule_naf_40": "Photocopie & aut. act. spé. sout. bureau"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9c376c1281e9635b48bb3b8931d27f6563a22d35",
    "fields": {
      "intitule_naf_65": "Réparation appareils électromén. & équipts maison & jardin",
      "intitule_naf": "Réparation d'appareils électroménagers et d'équipements pour la maison et le jardin",
      "code_naf": "9522",
      "intitule_naf_40": "Répar. électromén. & éqpt maison & jard."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "87869557ebe08a59f021f2bb160065e02698e623",
    "fields": {
      "intitule_naf_65": "Commerce de gros de vaisselle, verrerie et produits d'entretien",
      "intitule_naf": "Commerce de gros (commerce interentreprises) de vaisselle, verrerie et produits d'entretien",
      "code_naf": "4644Z",
      "intitule_naf_40": "Com. gros vaisselle verrerie prod. entr."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0877c4ff84bad23af4b3609200d68b4b601bed94",
    "fields": {
      "intitule_naf_65": "Activ. ménages : employeurs de personnel domestique",
      "intitule_naf": "Activités des ménages en tant qu'employeurs de personnel domestique",
      "code_naf": "97",
      "intitule_naf_40": "Act. ménage: empl. de person. domestique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "df484fe5e8321528fcd3012e156d15b4d6c122d8",
    "fields": {
      "intitule_naf_65": "Élevage d'ovins et de caprins",
      "intitule_naf": "Élevage d'ovins et de caprins",
      "code_naf": "145",
      "intitule_naf_40": "Élevage d'ovins et de caprins"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9664037724a5fd96011c261f84ffe8b71c67e427",
    "fields": {
      "intitule_naf_65": "Élevage de porcins",
      "intitule_naf": "Élevage de porcins",
      "code_naf": "146",
      "intitule_naf_40": "Élevage de porcins"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a247c188cc72f24135d36724d581e19fda4a961a",
    "fields": {
      "intitule_naf_65": "Fabrication équipements électriques et électroniques automobiles",
      "intitule_naf": "Fabrication d'équipements électriques et électroniques automobiles",
      "code_naf": "2931",
      "intitule_naf_40": "Fab. équipt électriq. & électron. auto."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "222de83411387e01481e51443950859da02d0ab3",
    "fields": {
      "intitule_naf_65": "Construction locomotives & autre matériel ferroviaire roulant",
      "intitule_naf": "Construction de locomotives et d'autre matériel ferroviaire roulant",
      "code_naf": "3020Z",
      "intitule_naf_40": "Const. loco. & autre mat. ferro. roulant"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b69a22bff63ac8bbb84f679ec99895a15233f107",
    "fields": {
      "intitule_naf_65": "Fabrication de matériel de distribution et de commande électrique",
      "intitule_naf": "Fabrication de matériel de distribution et de commande électrique",
      "code_naf": "2712",
      "intitule_naf_40": "Fab. mat. de distrib. & de cde électri."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3e11433c80b86cac99c529c8d65ec3ded505a1bc",
    "fields": {
      "intitule_naf_65": "Fabrication d'équipements hydrauliques et pneumatiques",
      "intitule_naf": "Fabrication d'équipements hydrauliques et pneumatiques",
      "code_naf": "2812Z",
      "intitule_naf_40": "Fab. équipement hydraulique & pneumatiq."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b4787e51548883fca1d5851191319bebc4d0a8e1",
    "fields": {
      "intitule_naf_65": "Commerce de détail d'équipements automobiles",
      "intitule_naf": "Commerce de détail d'équipements automobiles",
      "code_naf": "4532",
      "intitule_naf_40": "Commerce de détail équipement automobile"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0680d8669d09ecee6d33be658263e2e295efd74c",
    "fields": {
      "intitule_naf_65": "Traitement et élimination des déchets",
      "intitule_naf": "Traitement et élimination des déchets",
      "code_naf": "382",
      "intitule_naf_40": "Traitement et élimination des déchets"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "55e620830112792f71ed5404a0fb630ed034a43b",
    "fields": {
      "intitule_naf_65": "Fabrication d'articles de sport",
      "intitule_naf": "Fabrication d'articles de sport",
      "code_naf": "3230Z",
      "intitule_naf_40": "Fabrication d'articles de sport"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "efcaa7970cf7afe34f45a4c962d5968edee76273",
    "fields": {
      "intitule_naf_65": "Fabrication de jeux et jouets",
      "intitule_naf": "Fabrication de jeux et jouets",
      "code_naf": "324",
      "intitule_naf_40": "Fabrication de jeux et jouets"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d08daf2b9f0d4bd87e9f8cdbf0e528c546dd03cd",
    "fields": {
      "intitule_naf_65": "Autres travaux d'installation",
      "intitule_naf": "Autres travaux d'installation",
      "code_naf": "4329",
      "intitule_naf_40": "Autres travaux d'installation"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "cdfd2a991dedb23d580eb6022a065cc33e7a7685",
    "fields": {
      "intitule_naf_65": "Travaux de charpente",
      "intitule_naf": "Travaux de charpente",
      "code_naf": "4391A",
      "intitule_naf_40": "Travaux de charpente"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1ec4cc1cdfe7135c9b9bad5fde9368b708537c07",
    "fields": {
      "intitule_naf_65": "Commerce de détail de matériels audio/vidéo en magasin spécialisé",
      "intitule_naf": "Commerce de détail de matériels audio et vidéo en magasin spécialisé",
      "code_naf": "4743Z",
      "intitule_naf_40": "Comm. dét. matériels audio/vidéo (ms)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1c02149f91507580811b4faab4f870f562844fad",
    "fields": {
      "intitule_naf_65": "Commerce de détail de jeux et jouets en magasin spécialisé",
      "intitule_naf": "Commerce de détail de jeux et jouets en magasin spécialisé",
      "code_naf": "4765",
      "intitule_naf_40": "Com. dét. jeux & jouets en mag. spécial."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b4d43bcfa38b7fe4504f357c4ea001f3fd3a9db2",
    "fields": {
      "intitule_naf_65": "Commerce de gros de meubles, de tapis et d'appareils d'éclairage",
      "intitule_naf": "Commerce de gros de meubles, de tapis et d'appareils d'éclairage",
      "code_naf": "4647",
      "intitule_naf_40": "Com. gros meuble tapis appareil éclaira."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "bf16fcccbd67ef1f4ab1b5da4c6cc594df41a47e",
    "fields": {
      "intitule_naf_65": "Autres intermédiaires du commerce en produits divers",
      "intitule_naf": "Autres intermédiaires du commerce en produits divers",
      "code_naf": "4619B",
      "intitule_naf_40": "Autre interm. commerce en prodts divers"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c39072decd813e488b3cdef7f215edddd205698d",
    "fields": {
      "intitule_naf_65": "Commerce de gros d'autres biens domestiques",
      "intitule_naf": "Commerce de gros d'autres biens domestiques",
      "code_naf": "4649",
      "intitule_naf_40": "Commerce gros d'autres biens domestiques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2b19e6a1d14861c38f6457bc683bc453de1914ac",
    "fields": {
      "intitule_naf_65": "Commerce de gros de produits pharmaceutiques",
      "intitule_naf": "Commerce de gros de produits pharmaceutiques",
      "code_naf": "4646",
      "intitule_naf_40": "Comm. gros de produits pharmaceutiques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b1a66a92b3399308fb8527cfc3aa82d6408ceb0f",
    "fields": {
      "intitule_naf_65": "Commerce de gros de cuirs et peaux",
      "intitule_naf": "Commerce de gros de cuirs et peaux",
      "code_naf": "4624",
      "intitule_naf_40": "Commerce de gros de cuirs et peaux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b7b7b00de5c3ee99a67990c76d8148f438b6b04f",
    "fields": {
      "intitule_naf_65": "Hypermarchés",
      "intitule_naf": "Hypermarchés",
      "code_naf": "4711F",
      "intitule_naf_40": "Hypermarchés"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2a6569e5865de7f1c5c5312748efaa5b660376ee",
    "fields": {
      "intitule_naf_65": "Activités des sièges sociaux ; conseil de gestion",
      "intitule_naf": "Activités des sièges sociaux ; conseil de gestion",
      "code_naf": "70",
      "intitule_naf_40": "Act. sièges sociaux ; conseil de gestion"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "39bdaab91e0c5d0a7f01ad588f35c0cd764b8d5b",
    "fields": {
      "intitule_naf_65": "Transports aériens de fret et transports spatiaux",
      "intitule_naf": "Transports aériens de fret et transports spatiaux",
      "code_naf": "512",
      "intitule_naf_40": "Transp. aérien de fret & trans. spatiaux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f4de1b59e6ee8c5b504d449391f0e131960fed1a",
    "fields": {
      "intitule_naf_65": "Évaluation des risques et dommages",
      "intitule_naf": "Évaluation des risques et dommages",
      "code_naf": "6621Z",
      "intitule_naf_40": "Évaluation des risques et dommages"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "30c4fc8aa68b5ccd583267618b909933376e91c9",
    "fields": {
      "intitule_naf_65": "Télécommunications par satellite",
      "intitule_naf": "Télécommunications par satellite",
      "code_naf": "6130Z",
      "intitule_naf_40": "Télécommunications par satellite"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4c2c777e87f0ff41d81e4115f84e1bfcf5d855b5",
    "fields": {
      "intitule_naf_65": "Activités des agences de presse",
      "intitule_naf": "Activités des agences de presse",
      "code_naf": "6391Z",
      "intitule_naf_40": "Activités des agences de presse"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "85e75d0725775ce87e74e9b1c14539044d2db6ca",
    "fields": {
      "intitule_naf_65": "Transports par conduites",
      "intitule_naf": "Transports par conduites",
      "code_naf": "4950Z",
      "intitule_naf_40": "Transports par conduites"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "acc4929285b86e521a233f04bd73224e2ca186a1",
    "fields": {
      "intitule_naf_65": "Activités comptables",
      "intitule_naf": "Activités comptables",
      "code_naf": "6920",
      "intitule_naf_40": "Activités comptables"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "cca9d7cd73938aba071ae9f6277c09d7f664fbbb",
    "fields": {
      "intitule_naf_65": "Autres hébergements",
      "intitule_naf": "Autres hébergements",
      "code_naf": "559",
      "intitule_naf_40": "Autres hébergements"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9f5f2c37d5d4e73447954a960291c706ef2f474f",
    "fields": {
      "intitule_naf_65": "Transports spatiaux",
      "intitule_naf": "Transports spatiaux",
      "code_naf": "5122Z",
      "intitule_naf_40": "Transports spatiaux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0648e84e94e9b440cdbaba45615b8f43ad5fb6cc",
    "fields": {
      "intitule_naf_65": "Édition de livres",
      "intitule_naf": "Édition de livres",
      "code_naf": "5811Z",
      "intitule_naf_40": "Édition de livres"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d9847d398d285c4749b624a49a2d89d64723ab7b",
    "fields": {
      "intitule_naf_65": "Loc-bail de propriété intell. & sf oeuvres soumises à copyright",
      "intitule_naf": "Location-bail de propriété intellectuelle et de produits similaires, à l'exception des œuvres soumises à copyright",
      "code_naf": "7740Z",
      "intitule_naf_40": "Loc-bail propr. intel., sf oeuvre avec \u00a9"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f28f02d7eb817da78592b8b0ad51143ccfa1fadf",
    "fields": {
      "intitule_naf_65": "Location de longue durée voitures & véhicules automobiles légers",
      "intitule_naf": "Location de longue durée de voitures et de véhicules automobiles légers",
      "code_naf": "7711B",
      "intitule_naf_40": "Loc. longue durée voit. & v. auto. léger"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "de8e3dbb89ed64b3152ce39c7eb3c0402e405e20",
    "fields": {
      "intitule_naf_65": "Activités spécialisées, scientifiques et techniques diverses",
      "intitule_naf": "Activités spécialisées, scientifiques et techniques diverses",
      "code_naf": "7490B",
      "intitule_naf_40": "Act. spéc. scientif. & techniq. diverses"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "95b3f04c3a5fabae19de74feda8522b6f02aaf2e",
    "fields": {
      "intitule_naf_65": "Location et location-bail d'autres machines, équipements et biens",
      "intitule_naf": "Location et location-bail d'autres machines, équipements et biens",
      "code_naf": "773",
      "intitule_naf_40": "Loc. & loc.-bail aut. mach., éqpt & bien"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "901b983351b16ce020a95359b4b295a9a6fb247b",
    "fields": {
      "intitule_naf_65": "Location et location-bail de matériels de transport par eau",
      "intitule_naf": "Location et location-bail de matériels de transport par eau",
      "code_naf": "7734Z",
      "intitule_naf_40": "Loc. & loc.-bail mat. transport par eau"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a096083151f01d9203f2bdb75a66c00a46da6993",
    "fields": {
      "intitule_naf_65": "Activités liées aux systèmes de sécurité",
      "intitule_naf": "Activités liées aux systèmes de sécurité",
      "code_naf": "802",
      "intitule_naf_40": "Activités liées aux systèmes de sécurité"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "12d2b9ac29519efc5a28a082e29fa1ebca173a7c",
    "fields": {
      "intitule_naf_65": "Autres services de réservation et activités connexes",
      "intitule_naf": "Autres services de réservation et activités connexes",
      "code_naf": "7990Z",
      "intitule_naf_40": "Autre serv. réservation & activ. connexe"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "877aeaf51a2c85bb9c7310f6c5498668d8d3251c",
    "fields": {
      "intitule_naf_65": "Location et location-bail de matériels de transport aérien",
      "intitule_naf": "Location et location-bail de matériels de transport aérien",
      "code_naf": "7735Z",
      "intitule_naf_40": "Loc. & loc.-bail mat. transport aérien"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2052659b3a3ae2a48b286e816d20d42910d2c4f6",
    "fields": {
      "intitule_naf_65": "Activités des sièges sociaux",
      "intitule_naf": "Activités des sièges sociaux",
      "code_naf": "7010Z",
      "intitule_naf_40": "Activités des sièges sociaux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "49f54a91f2501a7c881d3925788b53631f6c0ad1",
    "fields": {
      "intitule_naf_65": "Fabrication de margarine et graisses comestibles similaires",
      "intitule_naf": "Fabrication de margarine et graisses comestibles similaires",
      "code_naf": "1042Z",
      "intitule_naf_40": "Fab. de margarine & graisses similaires"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c87481ce918dd3e3b86d5100480ff8328370b472",
    "fields": {
      "intitule_naf_65": "Production d'autres boissons fermentées non distillées",
      "intitule_naf": "Production d'autres boissons fermentées non distillées",
      "code_naf": "1104",
      "intitule_naf_40": "Prod. aut. boisson fermentée non distil."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5de0cf107d30001d91bcf91017d4ca9b2fe89fcb",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres textiles techniques et industriels",
      "intitule_naf": "Fabrication d'autres textiles techniques et industriels",
      "code_naf": "1396",
      "intitule_naf_40": "Fab. autre textile techniq. & industriel"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c30b8201d4494159a1a791f1f87db368cddfeb44",
    "fields": {
      "intitule_naf_65": "Extraction des minéraux chimiques et d'engrais minéraux",
      "intitule_naf": "Extraction des minéraux chimiques et d'engrais minéraux",
      "code_naf": "891",
      "intitule_naf_40": "Extr. minéraux chimiq. & engrais min."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a752feac712785ecaa4dd5657840545343162123",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres produits alimentaires n.c.a.",
      "intitule_naf": "Fabrication d'autres produits alimentaires n.c.a.",
      "code_naf": "1089",
      "intitule_naf_40": "Fab. d'autres prod. alimentaires n.c.a."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "04dc2aa12fc51d72e3d545fbbb0d396cfee26fc6",
    "fields": {
      "intitule_naf_65": "Fabrication de produits à base de tabac",
      "intitule_naf": "Fabrication de produits à base de tabac",
      "code_naf": "1200",
      "intitule_naf_40": "Fabrication de produits à base de tabac"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "38dbad9f8b4e2ead64c53977c6152a7e8cd88c26",
    "fields": {
      "intitule_naf_65": "Activités de soutien aux cultures",
      "intitule_naf": "Activités de soutien aux cultures",
      "code_naf": "0161Z",
      "intitule_naf_40": "Activités de soutien aux cultures"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "687bd5b2f29879d62b66cfb37a30523307ca75a9",
    "fields": {
      "intitule_naf_65": "Fabrication d'articles en fourrure",
      "intitule_naf": "Fabrication d'articles en fourrure",
      "code_naf": "142",
      "intitule_naf_40": "Fabrication d'articles en fourrure"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9f867e6ab814c1fd7176ba58afef315d40766a49",
    "fields": {
      "intitule_naf_65": "Fabrication de textiles",
      "intitule_naf": "Fabrication de textiles",
      "code_naf": "13",
      "intitule_naf_40": "Fabrication de textiles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "beb77ad64c8a4b72d67624624f4724de8fabd9a3",
    "fields": {
      "intitule_naf_65": "Pêche en eau douce",
      "intitule_naf": "Pêche en eau douce",
      "code_naf": "0312Z",
      "intitule_naf_40": "Pêche en eau douce"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "99db132ea8355a5eb37eff127a443d6930614b00",
    "fields": {
      "intitule_naf_65": "Fabrication objets divers en bois, liège, vannerie et sparterie",
      "intitule_naf": "Fabrication d'objets divers en bois ; fabrication d'objets en liège, vannerie et sparterie",
      "code_naf": "1629",
      "intitule_naf_40": "Fab. objet div. bois, liège, vann., etc."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c8747d63464ff52088241cb0ffffa4abd575a641",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres produits chimiques inorganiques de base",
      "intitule_naf": "Fabrication d'autres produits chimiques inorganiques de base",
      "code_naf": "2013",
      "intitule_naf_40": "Fab. aut. prod. chimique inorg. de base"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "46c90f89e535a8381d2c0d5409c89542c58a7811",
    "fields": {
      "intitule_naf_65": "Fabrication d'articles de voyage, de maroquinerie et de sellerie",
      "intitule_naf": "Fabrication d'articles de voyage, de maroquinerie et de sellerie",
      "code_naf": "1512",
      "intitule_naf_40": "Fab. art. voyage, maroquin., & sellerie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1e12a2b267a988403e5ac66429ae3332f352e67f",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres articles en papier ou en carton",
      "intitule_naf": "Fabrication d'autres articles en papier ou en carton",
      "code_naf": "1729Z",
      "intitule_naf_40": "Fab. aut. article en papier ou en carton"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "af87072cddb82d7599737145b13751b56eeaca08",
    "fields": {
      "intitule_naf_65": "Fabrication de pâte à papier, de papier et de carton",
      "intitule_naf": "Fabrication de pâte à papier, de papier et de carton",
      "code_naf": "171",
      "intitule_naf_40": "Fab. de pâte à papier, papier et carton"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "dab2f3923b7966bf7683271dd3cbcd6d05024828",
    "fields": {
      "intitule_naf_65": "Fabrication de produits en caoutchouc",
      "intitule_naf": "Fabrication de produits en caoutchouc",
      "code_naf": "221",
      "intitule_naf_40": "Fabrication de produits en caoutchouc"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d092a3ce5b97f17f4e3615d1a0cdb4d0d50a2f8c",
    "fields": {
      "intitule_naf_65": "Fabrication de gaz industriels",
      "intitule_naf": "Fabrication de gaz industriels",
      "code_naf": "2011Z",
      "intitule_naf_40": "Fabrication de gaz industriels"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ea986afe47ebf76d266665f5e597b58e0ffec734",
    "fields": {
      "intitule_naf_65": "Reproduction d'enregistrements",
      "intitule_naf": "Reproduction d'enregistrements",
      "code_naf": "1820Z",
      "intitule_naf_40": "Reproduction d'enregistrements"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "cccbd4f17f426abbfba50fcfb7f865546094377f",
    "fields": {
      "intitule_naf_65": "Fabrication de cartonnages",
      "intitule_naf": "Fabrication de cartonnages",
      "code_naf": "1721B",
      "intitule_naf_40": "Fabrication de cartonnages"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b3e80485f75f8766f3849adbc887e7eca104d78b",
    "fields": {
      "intitule_naf_65": "Imprimerie de journaux",
      "intitule_naf": "Imprimerie de journaux",
      "code_naf": "1811",
      "intitule_naf_40": "Imprimerie de journaux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "84f5c9d40dea379ac1736f15aee35ac6bc49a6cf",
    "fields": {
      "intitule_naf_65": "Fabrication de matériaux de construction en terre cuite",
      "intitule_naf": "Fabrication de matériaux de construction en terre cuite",
      "code_naf": "233",
      "intitule_naf_40": "Fab. matériaux de constr. en terre cuite"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8956786ab06194993d98dc3e15e689eb2dbf1c31",
    "fields": {
      "intitule_naf_65": "Fabrication de cartes électroniques assemblées",
      "intitule_naf": "Fabrication de cartes électroniques assemblées",
      "code_naf": "2612",
      "intitule_naf_40": "Fab de cartes électroniques assemblées"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "fe2c219c6c4c3b39584af8d214a26aa5c0e3d43a",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres articles en caoutchouc",
      "intitule_naf": "Fabrication d'autres articles en caoutchouc",
      "code_naf": "2219Z",
      "intitule_naf_40": "Fabric. d'autres articles en caoutchouc"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d1ae0516d3296e2d7c1a2111d9c0753c2ee51bc2",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres articles métalliques",
      "intitule_naf": "Fabrication d'autres articles métalliques",
      "code_naf": "2599B",
      "intitule_naf_40": "Fabric. d'autres articles métalliques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ae648e8992047ada5992e5fc3c2fd20d9e1d5e4a",
    "fields": {
      "intitule_naf_65": "Fabrication de produits réfractaires",
      "intitule_naf": "Fabrication de produits réfractaires",
      "code_naf": "2320Z",
      "intitule_naf_40": "Fabrication de produits réfractaires"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0ac8ba6f2ebb5608e65b9c92aaad557a637733dd",
    "fields": {
      "intitule_naf_65": "Fabrication de produits réfractaires",
      "intitule_naf": "Fabrication de produits réfractaires",
      "code_naf": "2320",
      "intitule_naf_40": "Fabrication de produits réfractaires"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "952372e56c4810a7b46124cd5f4cba3d7bf264de",
    "fields": {
      "intitule_naf_65": "Fabrication d'armes et de munitions",
      "intitule_naf": "Fabrication d'armes et de munitions",
      "code_naf": "2540Z",
      "intitule_naf_40": "Fabrication d'armes et de munitions"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "173e0a506685702db85f42635fe958dceb21667b",
    "fields": {
      "intitule_naf_65": "Adm. pub. tutelle santé form. cult. & social (aut que sécu. soc.)",
      "intitule_naf": "Administration publique (tutelle) de la santé, de la formation, de la culture et des services sociaux, autre que sécurité sociale",
      "code_naf": "8412",
      "intitule_naf_40": "A. p. santé form. cult. & soc. (sf sécu)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e83cd362a93699e7b9855d26b41449fe95014590",
    "fields": {
      "intitule_naf_65": "Héberg. social person. handicap. & malades mentales & toxicomanes",
      "intitule_naf": "Hébergement social pour personnes handicapées mentales, malades mentales et toxicomanes",
      "code_naf": "8720",
      "intitule_naf_40": "Hébrgt soc. hand., mal. mental & toxico."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2cc3bffc9e68c45a2a4f3bbf98b9331fbc3e77b7",
    "fields": {
      "intitule_naf_65": "Activités des ménages  : employeurs de personnel domestique",
      "intitule_naf": "Activités des ménages en tant qu'employeurs de personnel domestique",
      "code_naf": "9700Z",
      "intitule_naf_40": "Act. ménage: empl. de person. domestique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4a519fdf1c37764283fa1dc1fe2690ac3e7f06fe",
    "fields": {
      "intitule_naf_65": "Enseignement supérieur",
      "intitule_naf": "Enseignement supérieur",
      "code_naf": "8542Z",
      "intitule_naf_40": "Enseignement supérieur"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "651537f30928f6d297ec7de5d16f65b7f68f8f91",
    "fields": {
      "intitule_naf_65": "Comm. gros de fournitures & équipts divers pour commerces & sces",
      "intitule_naf": "Commerce de gros (commerce interentreprises) de fournitures et équipements divers pour le commerce et les services",
      "code_naf": "4669C",
      "intitule_naf_40": "Cg fournit. & équipt div. pr com. & sces"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0c082bbd74091507597d3c07f57496c30e7eca41",
    "fields": {
      "intitule_naf_65": "Autres commerces de détail alimentaires en magasin spécialisé",
      "intitule_naf": "Autres commerces de détail alimentaires en magasin spécialisé",
      "code_naf": "4729Z",
      "intitule_naf_40": "Aut. com. détail alim. en mag. spéciali."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3510f3d3eb0bbee10cfd8b05ac199c57ea7dc0b6",
    "fields": {
      "intitule_naf_65": "Commerce de détail alimentaire en magasin spécialisé",
      "intitule_naf": "Commerce de détail alimentaire en magasin spécialisé",
      "code_naf": "472",
      "intitule_naf_40": "Com. dét. aliment. en magasin spécialisé"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "74e235a508a8a1253254b7f653357b5acfcf5d60",
    "fields": {
      "intitule_naf_65": "Construction de voies ferrées de surface et souterraines",
      "intitule_naf": "Construction de voies ferrées de surface et souterraines",
      "code_naf": "4212",
      "intitule_naf_40": "Const. voie ferrée surface & souterraine"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f7a0c73c689441ce25eb133013233b2706f9a1d0",
    "fields": {
      "intitule_naf_65": "Commerce de gros d'autres machines et équipements",
      "intitule_naf": "Commerce de gros d'autres machines et équipements",
      "code_naf": "4669",
      "intitule_naf_40": "Commerce de gros autre machine & équipt"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a7235a96d64c1aca09537712ff5292b4e7101301",
    "fields": {
      "intitule_naf_65": "Construction de réseaux pour fluides",
      "intitule_naf": "Construction de réseaux pour fluides",
      "code_naf": "4221",
      "intitule_naf_40": "Construction de réseaux pour fluides"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ad3d729e0a97e93cf7750ff5da2f97d0a592e879",
    "fields": {
      "intitule_naf_65": "Centrales d'achat de carburant",
      "intitule_naf": "Centrales d'achat de carburant",
      "code_naf": "4612A",
      "intitule_naf_40": "Centrales d'achat de carburant"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "6215568695a3341778ec7d1ac9a2393fef4436a4",
    "fields": {
      "intitule_naf_65": "Agencement de lieux de vente",
      "intitule_naf": "Agencement de lieux de vente",
      "code_naf": "4332C",
      "intitule_naf_40": "Agencement de lieux de vente"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "6766bf5a9cc248c37b16c28fd3748872fbadfa58",
    "fields": {
      "intitule_naf_65": "Travaux de démolition",
      "intitule_naf": "Travaux de démolition",
      "code_naf": "4311Z",
      "intitule_naf_40": "Travaux de démolition"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0916fd57e17b864e794fa537dbcb20d74324cb58",
    "fields": {
      "intitule_naf_65": "Autres activités nettoyage des bâtiments et nettoyage industriel",
      "intitule_naf": "Autres activités de nettoyage des bâtiments et nettoyage industriel",
      "code_naf": "8122",
      "intitule_naf_40": "Aut. act. nettoyage bâtim. & nett. ind."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a584f09031ac4d58c2384be1ca2b86283ae4a926",
    "fields": {
      "intitule_naf_65": "Location et location-bail autres biens personnels et domestiques",
      "intitule_naf": "Location et location-bail d'autres biens personnels et domestiques",
      "code_naf": "7729",
      "intitule_naf_40": "Loc. & loc.-bail aut. bien perso. & dom."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a20e1a0d1b3c26e84341d30c45120d9b49c8495f",
    "fields": {
      "intitule_naf_65": "Programmation de télévision et télédiffusion",
      "intitule_naf": "Programmation de télévision et télédiffusion",
      "code_naf": "602",
      "intitule_naf_40": "Programmation de télévision & télédiff."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5a685a0d2f108c2a7d3e055896b1ca532780b4df",
    "fields": {
      "intitule_naf_65": "Autres activités d'hébergement social",
      "intitule_naf": "Autres activités d'hébergement social",
      "code_naf": "8790",
      "intitule_naf_40": "Autres activités d'hébergement social"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8e96a5a6c1b6320f679c051a9c664d559f93df8f",
    "fields": {
      "intitule_naf_65": "Action sociale sans hébergement n.c.a.",
      "intitule_naf": "Action sociale sans hébergement n.c.a.",
      "code_naf": "8899B",
      "intitule_naf_40": "Action sociale sans hébergement n.c.a."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "adf083d1b7d13da3bea8f7ed3f46b42ade6effef",
    "fields": {
      "intitule_naf_65": "Hôtels et hébergement similaire",
      "intitule_naf": "Hôtels et hébergement similaire",
      "code_naf": "5510",
      "intitule_naf_40": "Hôtels et hébergement similaire"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3c1d9cb0da0ae3ea5a6f0e5511e9ce24befd40dd",
    "fields": {
      "intitule_naf_65": "Autre distribution de crédit",
      "intitule_naf": "Autre distribution de crédit",
      "code_naf": "6492",
      "intitule_naf_40": "Autre distribution de crédit"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4823ea68c6158e853b5e2e6028b97a89cccaaf4d",
    "fields": {
      "intitule_naf_65": "Télécommunications sans fil",
      "intitule_naf": "Télécommunications sans fil",
      "code_naf": "6120Z",
      "intitule_naf_40": "Télécommunications sans fil"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ec6b4f33db56de9cbf267484692232637847ddbc",
    "fields": {
      "intitule_naf_65": "Activités de banque centrale",
      "intitule_naf": "Activités de banque centrale",
      "code_naf": "6411",
      "intitule_naf_40": "Activités de banque centrale"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "fe736cae5be7be5c060aa0d3b2a4318a567e239a",
    "fields": {
      "intitule_naf_65": "Traduction et interprétation",
      "intitule_naf": "Traduction et interprétation",
      "code_naf": "7430Z",
      "intitule_naf_40": "Traduction et interprétation"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d3339313408b023878e33e136db45d824e8bc6fe",
    "fields": {
      "intitule_naf_65": "Autre commerce de détail de biens neufs en magasin spécialisé",
      "intitule_naf": "Autre commerce de détail de biens neufs en magasin spécialisé",
      "code_naf": "4778",
      "intitule_naf_40": "Aut. comm. dét. biens neufs en mag. spé."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "18e72969cd9c61a9fadb9521182b8eb6704a3762",
    "fields": {
      "intitule_naf_65": "Comm. détail matériels télécommunication (magasin spécialisé)",
      "intitule_naf": "Commerce de détail de matériels de télécommunication en magasin spécialisé",
      "code_naf": "4742",
      "intitule_naf_40": "Comm. dét. matériel télécom. (ms)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1001e3772e07d151f38b16b80829ee35275ce0a4",
    "fields": {
      "intitule_naf_65": "Commerce de détail de textiles en magasin spécialisé",
      "intitule_naf": "Commerce de détail de textiles en magasin spécialisé",
      "code_naf": "4751Z",
      "intitule_naf_40": "Com. dét. textiles en magasin spécialisé"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ea23dfb74f2c192aba250fde717cdcb932eb0ccd",
    "fields": {
      "intitule_naf_65": "Commerce de détail de livres en magasin spécialisé",
      "intitule_naf": "Commerce de détail de livres en magasin spécialisé",
      "code_naf": "4761",
      "intitule_naf_40": "Comm. dét. livres en magasin spécialisé"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "cc3b2b0f277b05db9f74f9aab54ef4e7e909154a",
    "fields": {
      "intitule_naf_65": "Transport ferroviaire interurbain de voyageurs",
      "intitule_naf": "Transport ferroviaire interurbain de voyageurs",
      "code_naf": "4910",
      "intitule_naf_40": "Transport ferrov. interurbain voyageur"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4fec5be65e4bbe5871b85f351713020b636d259a",
    "fields": {
      "intitule_naf_65": "Transports aériens de passagers",
      "intitule_naf": "Transports aériens de passagers",
      "code_naf": "5110",
      "intitule_naf_40": "Transports aériens de passagers"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5e1e3066d069e2f01d08db1476e57138c7b409dc",
    "fields": {
      "intitule_naf_65": "Transports routiers de fret",
      "intitule_naf": "Transports routiers de fret",
      "code_naf": "4941",
      "intitule_naf_40": "Transports routiers de fret"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "95d9de7e7555172607d7ce2a63f03a4bc67684cd",
    "fields": {
      "intitule_naf_65": "Transports fluviaux de fret",
      "intitule_naf": "Transports fluviaux de fret",
      "code_naf": "504",
      "intitule_naf_40": "Transports fluviaux de fret"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5b5b551bf503d10f9e4507fad987662f01d5dbff",
    "fields": {
      "intitule_naf_65": "Transports par conduites",
      "intitule_naf": "Transports par conduites",
      "code_naf": "4950",
      "intitule_naf_40": "Transports par conduites"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "162e9213d497eca0250880de61d0c3b086e495d3",
    "fields": {
      "intitule_naf_65": "Entreposage et stockage",
      "intitule_naf": "Entreposage et stockage",
      "code_naf": "5210",
      "intitule_naf_40": "Entreposage et stockage"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c5458c9733614cbdcbc861c53e2fd6c178059795",
    "fields": {
      "intitule_naf_65": "Activ. indifférenciées ménages  : produc. services (usage propre)",
      "intitule_naf": "Activités indifférenciées des ménages en tant que producteurs de services pour usage propre",
      "code_naf": "9820Z",
      "intitule_naf_40": "Act. ménage : prod. serv. (usage propre)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9d22809e7592d26d3d148d405bdbf6387d0ee0b8",
    "fields": {
      "intitule_naf_65": "Réparation d'ordinateurs et d'équipements de communication",
      "intitule_naf": "Réparation d'ordinateurs et d'équipements de communication",
      "code_naf": "951",
      "intitule_naf_40": "Répar. ordinateur & équipt communication"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ccc955a08fe07e3597f90bd7736336ff9fa66625",
    "fields": {
      "intitule_naf_65": "Réparation de meubles et d'équipements du foyer",
      "intitule_naf": "Réparation de meubles et d'équipements du foyer",
      "code_naf": "9524",
      "intitule_naf_40": "Réparation meubles & d'équipt du foyer"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5a63885657872166ae81688ceff3fc1d64e8fb5c",
    "fields": {
      "intitule_naf_65": "Activités des autres organisations associatives",
      "intitule_naf": "Activités des autres organisations associatives",
      "code_naf": "949",
      "intitule_naf_40": "Act. autres organisations associatives"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ede8f027f6182ac11241b44724e856f101554a51",
    "fields": {
      "intitule_naf_65": "Services de soutien à l'exploitation forestière",
      "intitule_naf": "Services de soutien à l'exploitation forestière",
      "code_naf": "24",
      "intitule_naf_40": "Services de soutien à l'expl. forestière"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b4950d3e3e34a1648aabaf1188603d21c7a3bcae",
    "fields": {
      "intitule_naf_65": "Traitement des semences",
      "intitule_naf": "Traitement des semences",
      "code_naf": "0164Z",
      "intitule_naf_40": "Traitement des semences"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a30ce321cea1ca7ff54da65509bab6353d7382d9",
    "fields": {
      "intitule_naf_65": "Culture du riz",
      "intitule_naf": "Culture du riz",
      "code_naf": "112",
      "intitule_naf_40": "Culture du riz"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "72c66d97225a094eab6ddd58895cc3aaf1439fad",
    "fields": {
      "intitule_naf_65": "Aquaculture",
      "intitule_naf": "Aquaculture",
      "code_naf": "32",
      "intitule_naf_40": "Aquaculture"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b1f096eed345668c713b9b150bf67e9692f8160a",
    "fields": {
      "intitule_naf_65": "Fabrication de cacao, chocolat et de produits de confiserie",
      "intitule_naf": "Fabrication de cacao, chocolat et de produits de confiserie",
      "code_naf": "1082",
      "intitule_naf_40": "Fabric. de cacao, chocolat & confiseries"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "829e732f9e48e703cac14eecf13e584833a8a00e",
    "fields": {
      "intitule_naf_65": "Transformation et conservation de fruits et légumes",
      "intitule_naf": "Transformation et conservation de fruits et légumes",
      "code_naf": "103",
      "intitule_naf_40": "Transf. et conserv. de fruits et légumes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7480a2a9da092058f86e6d688f2a32d1aa17e889",
    "fields": {
      "intitule_naf_65": "Transformation et conservation de pommes de terre",
      "intitule_naf": "Transformation et conservation de pommes de terre",
      "code_naf": "1031Z",
      "intitule_naf_40": "Transf. et conserv. de pommes de terre"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a06ead1634edffd9f33348727b3fb2d0d48b4615",
    "fields": {
      "intitule_naf_65": "Fabrication de charpentes et d'autres menuiseries",
      "intitule_naf": "Fabrication de charpentes et d'autres menuiseries",
      "code_naf": "1623Z",
      "intitule_naf_40": "Fab. charpentes et autres menuiseries"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "014fd2de5e25861a7628493b18d0f5443734baa8",
    "fields": {
      "intitule_naf_65": "Fabrication de cidre et de vins de fruits",
      "intitule_naf": "Fabrication de cidre et de vins de fruits",
      "code_naf": "1103",
      "intitule_naf_40": "Fabrication de cidre & de vins de fruits"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f8601280b3523b8942ba66851c8377197c17237e",
    "fields": {
      "intitule_naf_65": "Fabrication de vêtements de dessous",
      "intitule_naf": "Fabrication de vêtements de dessous",
      "code_naf": "1414",
      "intitule_naf_40": "Fabrication de vêtements de dessous"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "fcb1c437625e20071819d902131d4ed729e04981",
    "fields": {
      "intitule_naf_65": "Fabrication de produits laitiers",
      "intitule_naf": "Fabrication de produits laitiers",
      "code_naf": "105",
      "intitule_naf_40": "Fabrication de produits laitiers"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8c202ce35b05c898659b895cf4d4c4a136f9e156",
    "fields": {
      "intitule_naf_65": "Fabrication de papiers peints",
      "intitule_naf": "Fabrication de papiers peints",
      "code_naf": "1724",
      "intitule_naf_40": "Fabrication de papiers peints"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "adea7966d7d4633f9748d5d3776951f42faa77c5",
    "fields": {
      "intitule_naf_65": "Fabrication de bière",
      "intitule_naf": "Fabrication de bière",
      "code_naf": "1105",
      "intitule_naf_40": "Fabrication de bière"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "6da794ccbcc57b9249a8304ec984a83d9ab16c74",
    "fields": {
      "intitule_naf_65": "Raffinage du pétrole",
      "intitule_naf": "Raffinage du pétrole",
      "code_naf": "1920",
      "intitule_naf_40": "Raffinage du pétrole"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ab1d88758c4014410524f7a758f3051020477f95",
    "fields": {
      "intitule_naf_65": "Fabrication plaques, feuilles, tubes et profilés en plastique",
      "intitule_naf": "Fabrication de plaques, feuilles, tubes et profilés en matières plastiques",
      "code_naf": "2221Z",
      "intitule_naf_40": "Fab. plaque, feuille, tube,  etc. plast."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e569c3a1e87cb81dcaae098ee30c9e68ca492e03",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres articles en matières plastiques",
      "intitule_naf": "Fabrication d'autres articles en matières plastiques",
      "code_naf": "2229",
      "intitule_naf_40": "Fab. autre article en matière plastique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7de540c12cfc2a9fea26975c91212e1a488dc54d",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres produits chimiques n.c.a.",
      "intitule_naf": "Fabrication d'autres produits chimiques n.c.a.",
      "code_naf": "2059Z",
      "intitule_naf_40": "Fabric. autres produits chimiques n.c.a."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f48ee44854690fe702e7fbb9938514856eef23fb",
    "fields": {
      "intitule_naf_65": "Taille, façonnage et finissage de pierres",
      "intitule_naf": "Taille, façonnage et finissage de pierres",
      "code_naf": "237",
      "intitule_naf_40": "Taille, façonnage & finissage de pierres"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "dc86248498ea046ca5ee32765ae4d3fa6c6ad289",
    "fields": {
      "intitule_naf_65": "Fabrication d'huiles essentielles",
      "intitule_naf": "Fabrication d'huiles essentielles",
      "code_naf": "2053Z",
      "intitule_naf_40": "Fabrication d'huiles essentielles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5e562d298a0cd9ca4e1806fe0d3fc4a9c242f484",
    "fields": {
      "intitule_naf_65": "Métallurgie de l'aluminium",
      "intitule_naf": "Métallurgie de l'aluminium",
      "code_naf": "2442Z",
      "intitule_naf_40": "Métallurgie de l'aluminium"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "bdea6d016897db031a2d47105473e967c0c998bc",
    "fields": {
      "intitule_naf_65": "Étirage à froid de barres",
      "intitule_naf": "Étirage à froid de barres",
      "code_naf": "2431",
      "intitule_naf_40": "Étirage à froid de barres"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "913b6b213019c07c3e1f482f93cac8a4cae28b4e",
    "fields": {
      "intitule_naf_65": "Fonderie de métaux légers",
      "intitule_naf": "Fonderie de métaux légers",
      "code_naf": "2453",
      "intitule_naf_40": "Fonderie de métaux légers"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "156dd2813d28989c701282f7beefd4ca3b185833",
    "fields": {
      "intitule_naf_65": "Fabrication de verre plat",
      "intitule_naf": "Fabrication de verre plat",
      "code_naf": "2311Z",
      "intitule_naf_40": "Fabrication de verre plat"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3f134f1f90b93ee287d865684ef243b6c20cda4c",
    "fields": {
      "intitule_naf_65": "Fabrication de matériel de distribution et de commande électrique",
      "intitule_naf": "Fabrication de matériel de distribution et de commande électrique",
      "code_naf": "2712Z",
      "intitule_naf_40": "Fab. mat. de distrib. & de cde électri."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "327283c529bf00e2a85e7344c8c7c33746951f0e",
    "fields": {
      "intitule_naf_65": "Fabrication d'ordinateurs et d'équipements périphériques",
      "intitule_naf": "Fabrication d'ordinateurs et d'équipements périphériques",
      "code_naf": "262",
      "intitule_naf_40": "Fab. ordinateur & équipement périphériq."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9a31482f0661c2e79325f904d48924b4a4f24eec",
    "fields": {
      "intitule_naf_65": "Fabrication de matériels optique et photographique",
      "intitule_naf": "Fabrication de matériels optique et photographique",
      "code_naf": "2670Z",
      "intitule_naf_40": "Fab. matériel optique et photographique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f721aa495771b22c0e91d06adabd4c5d386027df",
    "fields": {
      "intitule_naf_65": "Réparation de matériels électroniques et optiques",
      "intitule_naf": "Réparation de matériels électroniques et optiques",
      "code_naf": "3313Z",
      "intitule_naf_40": "Répar. matériel électronique & optique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "789868fd79d780a057111584d7ef671117ebb8cd",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres ouvrages en métaux",
      "intitule_naf": "Fabrication d'autres ouvrages en métaux",
      "code_naf": "259",
      "intitule_naf_40": "Fabrication d'autres ouvrages en métaux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3890494e6cf923143433c7e3368480929d351947",
    "fields": {
      "intitule_naf_65": "Captage, traitement et distribution d'eau",
      "intitule_naf": "Captage, traitement et distribution d'eau",
      "code_naf": "36",
      "intitule_naf_40": "Captage, traitement & distribution d'eau"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2f04e884ffeda4ec87e5d6b6339de2a1a12b06ab",
    "fields": {
      "intitule_naf_65": "Construction de maisons individuelles",
      "intitule_naf": "Construction de maisons individuelles",
      "code_naf": "4120A",
      "intitule_naf_40": "Construction de maisons individuelles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4a1ed1e00ec725ec580f9bcd998fed0039212560",
    "fields": {
      "intitule_naf_65": "Fabrication d'armes et de munitions",
      "intitule_naf": "Fabrication d'armes et de munitions",
      "code_naf": "2540",
      "intitule_naf_40": "Fabrication d'armes et de munitions"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "dbc8ac99957e6e4399ef01879b5de0e30c78566f",
    "fields": {
      "intitule_naf_65": "Comm. gros autres prod. alim. yc poissons crustacés mollusques",
      "intitule_naf": "Commerce de gros d'autres produits alimentaires, y compris poissons, crustacés et mollusques",
      "code_naf": "4638",
      "intitule_naf_40": "Com. gros aut. alim. yc poisson crustacé"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d72c9bf712cb3bedf3c227973bb6c5df56e653ce",
    "fields": {
      "intitule_naf_65": "Comm. détail équipts de l'information & communic. (magas. spéc.)",
      "intitule_naf": "Commerce de détail d'équipements de l'information et de la communication en magasin spécialisé",
      "code_naf": "474",
      "intitule_naf_40": "Com. dét. équipt information & com. (ms)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a7de1f33b3835717f2ddd6cd9b06ec1a8816971f",
    "fields": {
      "intitule_naf_65": "Comm. détail de parfumerie & produits de beauté en magasin spéc.",
      "intitule_naf": "Commerce de détail de parfumerie et de produits de beauté en magasin spécialisé",
      "code_naf": "4775Z",
      "intitule_naf_40": "Com. dét. parfumerie & prodt beauté (ms)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d47de6916aee52a37ccbc72c6d77e40d31cf03d1",
    "fields": {
      "intitule_naf_65": "Interm. comm. textiles, habillt, fourrures, chaussures & art cuir",
      "intitule_naf": "Intermédiaires du commerce en textiles, habillement, fourrures, chaussures et articles en cuir",
      "code_naf": "4616",
      "intitule_naf_40": "Int. comm. textile, habillt & assimil."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4a3b73510cd4ec72948f85c0cc2ed2193f67d92e",
    "fields": {
      "intitule_naf_65": "Fabrication papier & carton ondulés & emballages papier ou carton",
      "intitule_naf": "Fabrication de papier et carton ondulés et d'emballages en papier ou en carton",
      "code_naf": "1721",
      "intitule_naf_40": "Fab. papier & carton & emball. pap.-car."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "985715c2b83a28634f9af91f710d3cc14d595a89",
    "fields": {
      "intitule_naf_65": "Comm. gros quincaillerie & fournitures pour plomberie & chauffage",
      "intitule_naf": "Commerce de gros de quincaillerie et fournitures pour plomberie et chauffage",
      "code_naf": "4674",
      "intitule_naf_40": "Cg quincail. & fournit. plombr. & chauf."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "30323d5075a6fa1eae51a2a921f828f93042d3af",
    "fields": {
      "intitule_naf_65": "Fabrication instruments & fournitures à usage médical & dentaire",
      "intitule_naf": "Fabrication d'instruments et de fournitures à usage médical et dentaire",
      "code_naf": "3250",
      "intitule_naf_40": "Fab. inst. & fournit. usage méd. & dent."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c9c114aa9630a23ed6ca3fe378b56dbd156cdf9e",
    "fields": {
      "intitule_naf_65": "Commerce de gros de sucre, chocolat et confiserie",
      "intitule_naf": "Commerce de gros (commerce interentreprises) de sucre, chocolat et confiserie",
      "code_naf": "4636Z",
      "intitule_naf_40": "Com. gros de sucre chocolat & confiserie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "92dc34d70d916d22164ba2325b16d1800f09e994",
    "fields": {
      "intitule_naf_65": "Commerce de détail alimentaire sur éventaires et marchés",
      "intitule_naf": "Commerce de détail alimentaire sur éventaires et marchés",
      "code_naf": "4781Z",
      "intitule_naf_40": "Cd alimentaire sur éventaire & marché"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f2c8e5a018c4a00f20169bb392b6dcc33dba8783",
    "fields": {
      "intitule_naf_65": "Commerce de gros (commerce interentreprises) d'animaux vivants",
      "intitule_naf": "Commerce de gros (commerce interentreprises) d'animaux vivants",
      "code_naf": "4623Z",
      "intitule_naf_40": "Commerce de gros d'animaux vivants"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1efd3b2bbbbd482f97d42898859129cab7130342",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres produits céramiques à usage technique",
      "intitule_naf": "Fabrication d'autres produits céramiques à usage technique",
      "code_naf": "2344Z",
      "intitule_naf_40": "Fab. aut. prod. céram. à usage technique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3f71c6b792b75a488cca365bc0da886fadc26994",
    "fields": {
      "intitule_naf_65": "Fabrication articles bijouterie fantaisie & articles similaires",
      "intitule_naf": "Fabrication d'articles de bijouterie fantaisie et articles similaires",
      "code_naf": "3213",
      "intitule_naf_40": "Fab. art. bijout. fantaisie & similaire"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7969226fb541000008d7f1874b6d0607b7a58741",
    "fields": {
      "intitule_naf_65": "Fabrication d'aliments homogénéisés et diététiques",
      "intitule_naf": "Fabrication d'aliments homogénéisés et diététiques",
      "code_naf": "1086Z",
      "intitule_naf_40": "Fab. d'aliment homogénéisé & diététique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "75530c96d3448d3b1bca876e404fa3d2019119e5",
    "fields": {
      "intitule_naf_65": "Fabrication de savons, de produits d'entretien et de parfums",
      "intitule_naf": "Fabrication de savons, de produits d'entretien et de parfums",
      "code_naf": "204",
      "intitule_naf_40": "Fab. savon, prod. entretien & parfum"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "fd9c705fe938f20c844f4c1df3025aeb4e997891",
    "fields": {
      "intitule_naf_65": "Réparation d'équipements électriques",
      "intitule_naf": "Réparation d'équipements électriques",
      "code_naf": "3314",
      "intitule_naf_40": "Réparation d'équipements électriques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4c9418d1b40cccb8cef18ae95079a0f06e431ce0",
    "fields": {
      "intitule_naf_65": "Production de combustibles gazeux",
      "intitule_naf": "Production de combustibles gazeux",
      "code_naf": "3521",
      "intitule_naf_40": "Production de combustibles gazeux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "72f097338c9eb10a31f2d891c84b7f1372118b2e",
    "fields": {
      "intitule_naf_65": "Réparation d'ouvrages en métaux",
      "intitule_naf": "Réparation d'ouvrages en métaux",
      "code_naf": "3311Z",
      "intitule_naf_40": "Réparation d'ouvrages en métaux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c439cd3aceee4ebe6ccd645f66a714253ba202fa",
    "fields": {
      "intitule_naf_65": "Construction d'ouvrages d'art",
      "intitule_naf": "Construction d'ouvrages d'art",
      "code_naf": "4213A",
      "intitule_naf_40": "Construction d'ouvrages d'art"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "cd99ef0d35787b94395ffc894f7f06af95fc010d",
    "fields": {
      "intitule_naf_65": "Travaux de couverture",
      "intitule_naf": "Travaux de couverture",
      "code_naf": "4391",
      "intitule_naf_40": "Travaux de couverture"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "36cc224ac3f3e078af9dd921cdeab1ec6fb99eea",
    "fields": {
      "intitule_naf_65": "Fabrication de meubles",
      "intitule_naf": "Fabrication de meubles",
      "code_naf": "310",
      "intitule_naf_40": "Fabrication de meubles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "987a3567b1d9f7429c56908f045b2463da737630",
    "fields": {
      "intitule_naf_65": "Terrains de camping et parcs pour caravanes, véhicules de loisirs",
      "intitule_naf": "Terrains de camping et parcs pour caravanes ou véhicules de loisirs",
      "code_naf": "5530Z",
      "intitule_naf_40": "Terrain camping & parc pr caravane etc."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c26125754b006efb0f58082760213d924034b2f6",
    "fields": {
      "intitule_naf_65": "Services auxiliaires des transports aériens",
      "intitule_naf": "Services auxiliaires des transports aériens",
      "code_naf": "5223Z",
      "intitule_naf_40": "Sces auxiliaires des transports aériens"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4c572b038046007dd4ac9e8c2c671e2ce66fb6c1",
    "fields": {
      "intitule_naf_65": "Vente à distance sur catalogue général",
      "intitule_naf": "Vente à distance sur catalogue général",
      "code_naf": "4791A",
      "intitule_naf_40": "Vente à distance sur catalogue général"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c147dbc6cf9f83c5f9cb2c10310db51631f01dfb",
    "fields": {
      "intitule_naf_65": "Téléphériques et remontées mécaniques",
      "intitule_naf": "Téléphériques et remontées mécaniques",
      "code_naf": "4939C",
      "intitule_naf_40": "Téléphériques et remontées mécaniques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "239480579298308f170fa717c9476ca3f1729e10",
    "fields": {
      "intitule_naf_65": "Autres transports routiers de voyageurs",
      "intitule_naf": "Autres transports routiers de voyageurs",
      "code_naf": "4939B",
      "intitule_naf_40": "Autres transports routiers de voyageurs"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8a8ccdc29d3b956c0de1466999b8e31424e15f6d",
    "fields": {
      "intitule_naf_65": "Cafétérias et autres libres-services",
      "intitule_naf": "Cafétérias et autres libres-services",
      "code_naf": "5610B",
      "intitule_naf_40": "Cafétérias et autres libres-services"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ba55a8af962a2b6defcf5505832fcd010c78c4f0",
    "fields": {
      "intitule_naf_65": "Autres services de restauration",
      "intitule_naf": "Autres services de restauration",
      "code_naf": "5629",
      "intitule_naf_40": "Autres services de restauration"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "6a975cf71364a3649a2dbb5373f4e085c7b4542e",
    "fields": {
      "intitule_naf_65": "Édition de jeux électroniques",
      "intitule_naf": "Édition de jeux électroniques",
      "code_naf": "5821",
      "intitule_naf_40": "Édition de jeux électroniques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a3354ce5e88b3f96896596f050843c1261d31e14",
    "fields": {
      "intitule_naf_65": "Édition de journaux",
      "intitule_naf": "Édition de journaux",
      "code_naf": "5813",
      "intitule_naf_40": "Édition de journaux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8f6d9d1b5c97f366c6ef0baf20a17713443796b5",
    "fields": {
      "intitule_naf_65": "Vente à domicile",
      "intitule_naf": "Vente à domicile",
      "code_naf": "4799A",
      "intitule_naf_40": "Vente à domicile"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a8e7316ad1e5a7d99fc2a3fbc5fe34de6043a8dc",
    "fields": {
      "intitule_naf_65": "Fabrication d'articles textiles, sauf habillement",
      "intitule_naf": "Fabrication d'articles textiles, sauf habillement",
      "code_naf": "1392",
      "intitule_naf_40": "Fab. d'article textile, sauf habillement"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "194a9c733212d211452b0a9cf8fab0d1fbc399f3",
    "fields": {
      "intitule_naf_65": "Construction de véhicules militaires de combat",
      "intitule_naf": "Construction de véhicules militaires de combat",
      "code_naf": "3040Z",
      "intitule_naf_40": "Constr. véhicules militaires de combat"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "524655c8bf9748e4e41ee9e2767df0268be06972",
    "fields": {
      "intitule_naf_65": "Fabrication d'équipements de communication",
      "intitule_naf": "Fabrication d'équipements de communication",
      "code_naf": "263",
      "intitule_naf_40": "Fabric. d'équipements de communication"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ba1288172f3fbbb63631781cf1c06bcf90a41d41",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres pompes et compresseurs",
      "intitule_naf": "Fabrication d'autres pompes et compresseurs",
      "code_naf": "2813",
      "intitule_naf_40": "Fabric. d'autres pompes et compresseurs"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "749e6fbbefa60a73af132f787fd124ee81167bb7",
    "fields": {
      "intitule_naf_65": "Fabrication de colorants et de pigments",
      "intitule_naf": "Fabrication de colorants et de pigments",
      "code_naf": "2012",
      "intitule_naf_40": "Fabrication de colorants et de pigments"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "948a5f92f1c9487bbe6ac20bd45b6bf66483dc24",
    "fields": {
      "intitule_naf_65": "Fabrication d'équipements électriques",
      "intitule_naf": "Fabrication d'équipements électriques",
      "code_naf": "27",
      "intitule_naf_40": "Fabrication d'équipements électriques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1ce093064420b746324b6d06760aab72ac42ffc0",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres machines-outils",
      "intitule_naf": "Fabrication d'autres machines-outils",
      "code_naf": "2849Z",
      "intitule_naf_40": "Fabrication d'autres machines-outils"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "700dec9f04094bb60c2af70a985b6d76a07a28fc",
    "fields": {
      "intitule_naf_65": "Fabrication d'huiles essentielles",
      "intitule_naf": "Fabrication d'huiles essentielles",
      "code_naf": "2053",
      "intitule_naf_40": "Fabrication d'huiles essentielles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "afbffb70ee41cde6a55489d05e7bbca62cc9b665",
    "fields": {
      "intitule_naf_65": "Fabrication de pâte à papier",
      "intitule_naf": "Fabrication de pâte à papier",
      "code_naf": "1711Z",
      "intitule_naf_40": "Fabrication de pâte à papier"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4c09a690d95251414fb5d11bbb36f2299a2df0c1",
    "fields": {
      "intitule_naf_65": "Extraction de houille",
      "intitule_naf": "Extraction de houille",
      "code_naf": "0510Z",
      "intitule_naf_40": "Extraction de houille"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "02f3c4cc84a2495b15778f96a87452d01302998e",
    "fields": {
      "intitule_naf_65": "Traitement de données, hébergement et activités connexes",
      "intitule_naf": "Traitement de données, hébergement et activités connexes",
      "code_naf": "6311Z",
      "intitule_naf_40": "Traitt donnée, hébergt & activ. connexe"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "941510b0ab9ebbbae19410a98d9e9ff380142c04",
    "fields": {
      "intitule_naf_65": "Centres de collecte et banques d'organes",
      "intitule_naf": "Centres de collecte et banques d'organes",
      "code_naf": "8690C",
      "intitule_naf_40": "Centres de collecte et banques d'organes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "cc23eda448fbcd79eedd53f322d6ea0dfe3aab5b",
    "fields": {
      "intitule_naf_65": "Activités des sociétés holding",
      "intitule_naf": "Activités des sociétés holding",
      "code_naf": "642",
      "intitule_naf_40": "Activités des sociétés holding"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9b62c5762d2737574b1c6477340bcc50a0112994",
    "fields": {
      "intitule_naf_65": "Activités de centres d'appels",
      "intitule_naf": "Activités de centres d'appels",
      "code_naf": "822",
      "intitule_naf_40": "Activités de centres d'appels"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "6d85f97465e9d0c9716977a38142f16857c84077",
    "fields": {
      "intitule_naf_65": "Activités de conditionnement",
      "intitule_naf": "Activités de conditionnement",
      "code_naf": "8292",
      "intitule_naf_40": "Activités de conditionnement"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f30c51ccc859ba4008779e73e331bc26af87fbc6",
    "fields": {
      "intitule_naf_65": "Enseignement de la conduite",
      "intitule_naf": "Enseignement de la conduite",
      "code_naf": "8553",
      "intitule_naf_40": "Enseignement de la conduite"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ce698b2ccb9fdc1b206d3e1b095c9c62b237cfad",
    "fields": {
      "intitule_naf_65": "Activités photographiques",
      "intitule_naf": "Activités photographiques",
      "code_naf": "7420",
      "intitule_naf_40": "Activités photographiques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a34b3d101e1c2af59329318cbca2d65732336735",
    "fields": {
      "intitule_naf_65": "Enseignement primaire",
      "intitule_naf": "Enseignement primaire",
      "code_naf": "8520",
      "intitule_naf_40": "Enseignement primaire"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7e5dda01396962329e3a7b40518d012dc753cf2b",
    "fields": {
      "intitule_naf_65": "Pratique dentaire",
      "intitule_naf": "Pratique dentaire",
      "code_naf": "8623",
      "intitule_naf_40": "Pratique dentaire"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d8415c7ecff1652168dc31e207c3c29efc938b91",
    "fields": {
      "intitule_naf_65": "Crédit-bail",
      "intitule_naf": "Crédit-bail",
      "code_naf": "6491",
      "intitule_naf_40": "Crédit-bail"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4c9a70edc52c03a9fe61a17a1de1747a990841c5",
    "fields": {
      "intitule_naf_65": "Comm. de gros céréales, tabac non manuf. et aliments pour bétail",
      "intitule_naf": "Commerce de gros (commerce interentreprises) de céréales, de tabac non manufacturé, de semences et d'aliments pour le bétail",
      "code_naf": "4621Z",
      "intitule_naf_40": "Com gros céréal. tab. brt & alim. bétail"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "08c746dc0987b95a35331fe9268747cd0077a3e7",
    "fields": {
      "intitule_naf_65": "Fab. éqpts d'irradiation médic. électromédic. & électrothérapeut.",
      "intitule_naf": "Fabrication d'équipements d'irradiation médicale, d'équipements électromédicaux et électrothérapeutiques",
      "code_naf": "2660",
      "intitule_naf_40": "Fab. éqpt irrad. médic. & électromedic."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "136ac9e1803166b3170027d42e2ce67b9a955e0e",
    "fields": {
      "intitule_naf_65": "Hébergement social pour enfants en difficultés",
      "intitule_naf": "Hébergement social pour enfants en difficultés",
      "code_naf": "8790A",
      "intitule_naf_40": "Hébergt social pr enfants en difficultés"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "207bf58cbdbfc4ad720073a12a89d59b282aeb31",
    "fields": {
      "intitule_naf_65": "Activités des organisations et organismes extraterritoriaux",
      "intitule_naf": "Activités des organisations et organismes extraterritoriaux",
      "code_naf": "9900Z",
      "intitule_naf_40": "Act. organisations extraterritoriales"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "10db6e3862e5f4e559ec29638d46c68fd48977e8",
    "fields": {
      "intitule_naf_65": "Activités des parcs d'attractions et parcs à thèmes",
      "intitule_naf": "Activités des parcs d'attractions et parcs à thèmes",
      "code_naf": "9321",
      "intitule_naf_40": "Act. parcs attractions & parcs à thèmes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e8ac197f53f1d89621621d895086cb37cfc56c10",
    "fields": {
      "intitule_naf_65": "Autres services personnels n.c.a.",
      "intitule_naf": "Autres services personnels n.c.a.",
      "code_naf": "9609Z",
      "intitule_naf_40": "Autres services personnels n.c.a."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f2e487ac1dcbda11b6de4ff56470b9a29b2d30e5",
    "fields": {
      "intitule_naf_65": "Gestion d'installations sportives",
      "intitule_naf": "Gestion d'installations sportives",
      "code_naf": "9311",
      "intitule_naf_40": "Gestion d'installations sportives"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7cd531302e4a0a298966a028f5b8929f46a0968c",
    "fields": {
      "intitule_naf_65": "Hébergement médicalisé",
      "intitule_naf": "Hébergement médicalisé",
      "code_naf": "8710",
      "intitule_naf_40": "Hébergement médicalisé"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "95f685a7b03716e333e0efd564898bfda1f538ab",
    "fields": {
      "intitule_naf_65": "Extraction d'autres minerais de métaux non ferreux",
      "intitule_naf": "Extraction d'autres minerais de métaux non ferreux",
      "code_naf": "729",
      "intitule_naf_40": "Extr. aut. minerai de métaux non ferreux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "69a76a16c96567f2f523501d444dae74c783cfa8",
    "fields": {
      "intitule_naf_65": "Transformation et conservation de pommes de terre",
      "intitule_naf": "Transformation et conservation de pommes de terre",
      "code_naf": "1031",
      "intitule_naf_40": "Transf. et conserv. de pommes de terre"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5615c1e1e9a091d2b919c5f831499a41319f666e",
    "fields": {
      "intitule_naf_65": "Fabrication d'articles en papier ou en carton",
      "intitule_naf": "Fabrication d'articles en papier ou en carton",
      "code_naf": "172",
      "intitule_naf_40": "Fab. d'articles en papier ou en carton"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8be96d12feba78583bde948ec4b2c9fcf2809bcf",
    "fields": {
      "intitule_naf_65": "Extraction de minerais d'uranium et de thorium",
      "intitule_naf": "Extraction de minerais d'uranium et de thorium",
      "code_naf": "721",
      "intitule_naf_40": "Extr. de minerais d'uranium & de thorium"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c034172d99ceec13723ee080cccb0018bb728446",
    "fields": {
      "intitule_naf_65": "Fabrication de produits réfractaires",
      "intitule_naf": "Fabrication de produits réfractaires",
      "code_naf": "232",
      "intitule_naf_40": "Fabrication de produits réfractaires"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "eefd1f0634edccaf0a0a2b4d15048e6663912b49",
    "fields": {
      "intitule_naf_65": "Extraction de houille et de lignite",
      "intitule_naf": "Extraction de houille et de lignite",
      "code_naf": "05",
      "intitule_naf_40": "Extraction de houille et de lignite"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8fbd043db65f71d3a9305db91f44880bdd078988",
    "fields": {
      "intitule_naf_65": "Production de vin (de raisin)",
      "intitule_naf": "Production de vin (de raisin)",
      "code_naf": "1102",
      "intitule_naf_40": "Production de vin (de raisin)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7f8ea16101b2b8630f135cdb4057d1af1f05ed70",
    "fields": {
      "intitule_naf_65": "Cokéfaction et raffinage",
      "intitule_naf": "Cokéfaction et raffinage",
      "code_naf": "19",
      "intitule_naf_40": "Cokéfaction et raffinage"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "26298bae88a9a256c5d72fbd0f90777f41071772",
    "fields": {
      "intitule_naf_65": "Ennoblissement textile",
      "intitule_naf": "Ennoblissement textile",
      "code_naf": "1330Z",
      "intitule_naf_40": "Ennoblissement textile"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8f54f7d0dc7141b2653c46d54a011ba4a64193ed",
    "fields": {
      "intitule_naf_65": "Travail des grains",
      "intitule_naf": "Travail des grains",
      "code_naf": "1061",
      "intitule_naf_40": "Travail des grains"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "402f71a5f574eefa22da0b34583652768b9be9a2",
    "fields": {
      "intitule_naf_65": "Fabrication de fûts et emballages métalliques similaires",
      "intitule_naf": "Fabrication de fûts et emballages métalliques similaires",
      "code_naf": "2591",
      "intitule_naf_40": "Fab. fût & emballage métalliq. similaire"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9dba72c650da1f939735b5094973453a196e5f31",
    "fields": {
      "intitule_naf_65": "Fabrication de machines pour les industries du papier et carton",
      "intitule_naf": "Fabrication de machines pour les industries du papier et du carton",
      "code_naf": "2895Z",
      "intitule_naf_40": "Fab. machine pr indus. papier & carton"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4c946890ca1e4b93c64e7fbd6997dd3dcb04e4eb",
    "fields": {
      "intitule_naf_65": "Fabrication de machines pour l'extraction ou la construction",
      "intitule_naf": "Fabrication de machines pour l'extraction ou la construction",
      "code_naf": "2892",
      "intitule_naf_40": "Fab. machine pour extraction ou constr."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "70c54a5d34ab2068651874e95e7d1d419baac840",
    "fields": {
      "intitule_naf_65": "Fabrication de produits électroniques grand public",
      "intitule_naf": "Fabrication de produits électroniques grand public",
      "code_naf": "264",
      "intitule_naf_40": "Fab. produit électronique grand public"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "50c784f815dabe3a84c50b854513cfe6bef2317b",
    "fields": {
      "intitule_naf_65": "Fabrication de piles et d'accumulateurs électriques",
      "intitule_naf": "Fabrication de piles et d'accumulateurs électriques",
      "code_naf": "272",
      "intitule_naf_40": "Fabric. pile & accumulateur électrique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f94df78b3acbe3d8298b7e82db37c034a8e5e214",
    "fields": {
      "intitule_naf_65": "Fabrication de mortiers et bétons secs",
      "intitule_naf": "Fabrication de mortiers et bétons secs",
      "code_naf": "2364Z",
      "intitule_naf_40": "Fabrication de mortiers et bétons secs"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0ac74ee4a3077dca34f9bbf6ab3262528fba7cbb",
    "fields": {
      "intitule_naf_65": "Fabrication de produits abrasifs",
      "intitule_naf": "Fabrication de produits abrasifs",
      "code_naf": "2391Z",
      "intitule_naf_40": "Fabrication de produits abrasifs"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "dc13d7195af10a0aa382386a642b2a3e210140b9",
    "fields": {
      "intitule_naf_65": "Tréfilage à froid",
      "intitule_naf": "Tréfilage à froid",
      "code_naf": "2434Z",
      "intitule_naf_40": "Tréfilage à froid"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "51b71247dc9c377c8c77d87fc739abc2422c1cca",
    "fields": {
      "intitule_naf_65": "Fonderie",
      "intitule_naf": "Fonderie",
      "code_naf": "245",
      "intitule_naf_40": "Fonderie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1114be8388aea0469a988751a4fb0324ccf52642",
    "fields": {
      "intitule_naf_65": "Com. gros produits laitiers, oeufs, huiles & mat. grasses comest.",
      "intitule_naf": "Commerce de gros de produits laitiers, œufs, huiles et matières grasses comestibles",
      "code_naf": "4633",
      "intitule_naf_40": "Com. gros prod. laitier oeuf & mat. grse"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "403698ab1f04d61bcaa74d178afc3cb4743e790a",
    "fields": {
      "intitule_naf_65": "Intermédiaires du commerce en bois et matériaux de construction",
      "intitule_naf": "Intermédiaires du commerce en bois et matériaux de construction",
      "code_naf": "4613",
      "intitule_naf_40": "Interm. comm. bois & matériaux construc."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "05f5742307ea2b6429754cd98bc48f5978068096",
    "fields": {
      "intitule_naf_65": "Installation de machines et d'équipements industriels",
      "intitule_naf": "Installation de machines et d'équipements industriels",
      "code_naf": "3320",
      "intitule_naf_40": "Install. de machines & équipt industriel"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "525f46f800fdf3373ba63d310a41fca111ab6cd2",
    "fields": {
      "intitule_naf_65": "Commerce de gros d'habillement et de chaussures",
      "intitule_naf": "Commerce de gros d'habillement et de chaussures",
      "code_naf": "4642",
      "intitule_naf_40": "Commerce gros d'habillement & chaussures"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d05eacdf3c60058be79cc02a0d2be57806c256f9",
    "fields": {
      "intitule_naf_65": "Commerce de gros d'équipements automobiles",
      "intitule_naf": "Commerce de gros d'équipements automobiles",
      "code_naf": "4531",
      "intitule_naf_40": "Commerce de gros d'équipement automobile"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "30328559152da887c86b1743a40cd0bfe6eb7a88",
    "fields": {
      "intitule_naf_65": "Commerce de gros de fruits et légumes",
      "intitule_naf": "Commerce de gros de fruits et légumes",
      "code_naf": "4631",
      "intitule_naf_40": "Commerce de gros de fruits et légumes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8564f6b7eaccbf3b6abc132aa63273356e8a9be5",
    "fields": {
      "intitule_naf_65": "Travaux de couverture par éléments",
      "intitule_naf": "Travaux de couverture par éléments",
      "code_naf": "4391B",
      "intitule_naf_40": "Travaux de couverture par éléments"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "40d7d890358c582ceef8146f8d635ef5a6ba9f19",
    "fields": {
      "intitule_naf_65": "Construction de routes et autoroutes",
      "intitule_naf": "Construction de routes et autoroutes",
      "code_naf": "4211",
      "intitule_naf_40": "Construction de routes et autoroutes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e860b7a2052790b02c88adcf71545419c1ab6e28",
    "fields": {
      "intitule_naf_65": "Fabrication de lunettes",
      "intitule_naf": "Fabrication de lunettes",
      "code_naf": "3250B",
      "intitule_naf_40": "Fabrication de lunettes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8e9d8fce0a742e72fc8368447a1a2cb0c1577fc8",
    "fields": {
      "intitule_naf_65": "Travaux d'isolation",
      "intitule_naf": "Travaux d'isolation",
      "code_naf": "4329A",
      "intitule_naf_40": "Travaux d'isolation"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b068b29a77a9a6eb293a61a4d694374ce2301fc0",
    "fields": {
      "intitule_naf_65": "Comm. détail de quincaillerie, peintures et verres (mag.< 400 m2)",
      "intitule_naf": "Commerce de détail de quincaillerie, peintures et verres en petites surfaces (moins de 400 m2)",
      "code_naf": "4752A",
      "intitule_naf_40": "Com. dét. quinc. pein. etc. (mag.<400m2)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1d96f4a7cdcb43f345894adb853468586f1ae1f7",
    "fields": {
      "intitule_naf_65": "Comm. détail de produits à base de tabac en magasin spécialisé",
      "intitule_naf": "Commerce de détail de produits à base de tabac en magasin spécialisé",
      "code_naf": "4726",
      "intitule_naf_40": "Comm. dét. produit à base de tabac (ms)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "48bea386f3a34309f844e088347d83fe95bb3450",
    "fields": {
      "intitule_naf_65": "Autres commerces de détail hors magasin, éventaires ou marchés",
      "intitule_naf": "Autres commerces de détail hors magasin, éventaires ou marchés",
      "code_naf": "4799",
      "intitule_naf_40": "Aut. comm. dét. hors mag. évent., marché"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "feea01e793f0c8b93ba2f5ac4ccd2b93ee98e3e4",
    "fields": {
      "intitule_naf_65": "Commerce de détail de matériels audio/vidéo en magasin spécialisé",
      "intitule_naf": "Commerce de détail de matériels audio/vidéo en magasin spécialisé",
      "code_naf": "4743",
      "intitule_naf_40": "Comm. dét. matériels audio/vidéo (ms)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "bc4cd72b6bb317f3d3906352c9064eaeb14b6f22",
    "fields": {
      "intitule_naf_65": "Autres commerces de détail sur éventaires et marchés",
      "intitule_naf": "Autres commerces de détail sur éventaires et marchés",
      "code_naf": "4789",
      "intitule_naf_40": "Aut. com. dét. sur éventaires & marchés"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c6c4fbf284e212e983eebac86acfdcd8cbc7ad14",
    "fields": {
      "intitule_naf_65": "Transports routiers de fret interurbains",
      "intitule_naf": "Transports routiers de fret interurbains",
      "code_naf": "4941A",
      "intitule_naf_40": "Transports routiers de fret interurbains"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "56a7e7e122f06131335c0c218d69da05441ea7cc",
    "fields": {
      "intitule_naf_65": "Manutention non portuaire",
      "intitule_naf": "Manutention non portuaire",
      "code_naf": "5224B",
      "intitule_naf_40": "Manutention non portuaire"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "6172959d9561a32a601ae670cc6215047ba549df",
    "fields": {
      "intitule_naf_65": "Culture de légumes, de melons, de racines et de tubercules",
      "intitule_naf": "Culture de légumes, de melons, de racines et de tubercules",
      "code_naf": "113",
      "intitule_naf_40": "Cult. légume, melon, racine & tubercule"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "18d522102d7c846edf9f5d010912805a8314f033",
    "fields": {
      "intitule_naf_65": "Gest. des jardins botaniques et zoolog. et des réserv. naturelles",
      "intitule_naf": "Gestion des jardins botaniques et zoologiques et des réserves naturelles",
      "code_naf": "9104Z",
      "intitule_naf_40": "Gest. jardin bota. & zoo. & réserv. nat."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "280988e96ef6122211658eba0a26af4aa651d962",
    "fields": {
      "intitule_naf_65": "Hébergement social pour handicapés mentaux et malades mentaux",
      "intitule_naf": "Hébergement social pour handicapés mentaux et malades mentaux",
      "code_naf": "8720A",
      "intitule_naf_40": "Hébrgt soc. hand. mental & malade mental"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5bd5fc582b1f0bc4127ddf85a70311b9057b3f3f",
    "fields": {
      "intitule_naf_65": "Action sociale sans hébergement pour jeunes enfants",
      "intitule_naf": "Action sociale sans hébergement pour jeunes enfants",
      "code_naf": "8891",
      "intitule_naf_40": "Action soc. sans hbrgt pr jeunes enfants"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2fea946f841bb080ba2b56a923f3b8f5afbe5e4d",
    "fields": {
      "intitule_naf_65": "Réparation de chaussures et d'articles en cuir",
      "intitule_naf": "Réparation de chaussures et d'articles en cuir",
      "code_naf": "9523Z",
      "intitule_naf_40": "Réparation chaussures & articles en cuir"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "053e2db2b85963897f10986ce8169f32bc773727",
    "fields": {
      "intitule_naf_65": "Services administratifs combinés de bureau",
      "intitule_naf": "Services administratifs combinés de bureau",
      "code_naf": "8211Z",
      "intitule_naf_40": "Services admin. combinés de bureau"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d09d73d1dc7960d3a212a3e0075def7ad592fb41",
    "fields": {
      "intitule_naf_65": "Activités spécialisées de design",
      "intitule_naf": "Activités spécialisées de design",
      "code_naf": "7410",
      "intitule_naf_40": "Activités spécialisées de design"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5de1fb4aa2fa0e97708dfc3fe5580d708c1f8717",
    "fields": {
      "intitule_naf_65": "Blanchisserie-teinturerie de gros",
      "intitule_naf": "Blanchisserie-teinturerie de gros",
      "code_naf": "9601A",
      "intitule_naf_40": "Blanchisserie-teinturerie de gros"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b47fcd87f9cdb903f652a8b3011f41e3f7153d91",
    "fields": {
      "intitule_naf_65": "Enseignement secondaire général",
      "intitule_naf": "Enseignement secondaire général",
      "code_naf": "8531Z",
      "intitule_naf_40": "Enseignement secondaire général"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "343baac0614e06d1930627b016e912daa021605f",
    "fields": {
      "intitule_naf_65": "Activités des agences de voyage",
      "intitule_naf": "Activités des agences de voyage",
      "code_naf": "7911",
      "intitule_naf_40": "Activités des agences de voyage"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c00fee9d37f63d56a27125c1f6ba08a692a19670",
    "fields": {
      "intitule_naf_65": "Blanchisserie-teinturerie",
      "intitule_naf": "Blanchisserie-teinturerie",
      "code_naf": "9601",
      "intitule_naf_40": "Blanchisserie-teinturerie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "563481dc0240764b3fb86525b11b7766ffb43bac",
    "fields": {
      "intitule_naf_65": "Terrains de camping et parcs pour caravanes, véhicules de loisirs",
      "intitule_naf": "Terrains de camping et parcs pour caravanes ou véhicules de loisirs",
      "code_naf": "5530",
      "intitule_naf_40": "Terrain camping & parc pr caravane etc."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e48b1fdbd264d0224d3da437c442c6a1b12fbbea",
    "fields": {
      "intitule_naf_65": "Supports juridiques de gestion de patrimoine immobilier",
      "intitule_naf": "Supports juridiques de gestion de patrimoine immobilier",
      "code_naf": "6832B",
      "intitule_naf_40": "Support juridi. gestion patrimoine immo."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7f94291a46f2ce2892ca69d05ddcc60097b3b5d9",
    "fields": {
      "intitule_naf_65": "Recherche-développement en sciences humaines et sociales",
      "intitule_naf": "Recherche-développement en sciences humaines et sociales",
      "code_naf": "7220",
      "intitule_naf_40": "R&D en sciences humaines et sociales"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7e33e42ae0603c54c146065b1a01e383b61c6701",
    "fields": {
      "intitule_naf_65": "Édition et diffusion de programmes radio",
      "intitule_naf": "Édition et diffusion de programmes radio",
      "code_naf": "6010",
      "intitule_naf_40": "Édition et diffusion de programmes radio"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "05d4a7db643e9f05ecaae0c9da9c3438bd1e8ccb",
    "fields": {
      "intitule_naf_65": "Administration de marchés financiers",
      "intitule_naf": "Administration de marchés financiers",
      "code_naf": "6611",
      "intitule_naf_40": "Administration de marchés financiers"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "69674bfc966561409e3d015270cfaafb3aa0503c",
    "fields": {
      "intitule_naf_65": "Production de films pour le cinéma",
      "intitule_naf": "Production de films pour le cinéma",
      "code_naf": "5911C",
      "intitule_naf_40": "Production de films pour le cinéma"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3c2cbd00ab43e81809a062fd65b65af599f8cb1d",
    "fields": {
      "intitule_naf_65": "Contrôle technique automobile",
      "intitule_naf": "Contrôle technique automobile",
      "code_naf": "7120A",
      "intitule_naf_40": "Contrôle technique automobile"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "71743242fc1d0e9825fb8f342f4c95890fb7bc2a",
    "fields": {
      "intitule_naf_65": "Activités comptables",
      "intitule_naf": "Activités comptables",
      "code_naf": "6920Z",
      "intitule_naf_40": "Activités comptables"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "00f50387ef406eb052c8126e7a7aad93dba945b6",
    "fields": {
      "intitule_naf_65": "Portails Internet",
      "intitule_naf": "Portails Internet",
      "code_naf": "6312Z",
      "intitule_naf_40": "Portails Internet"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "40e43b7596710a09a588711e1ceaccf3c295b729",
    "fields": {
      "intitule_naf_65": "Gestion de fonds",
      "intitule_naf": "Gestion de fonds",
      "code_naf": "663",
      "intitule_naf_40": "Gestion de fonds"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "579e4ad02c5440ff1d466d63fb88c32e5d27cc0b",
    "fields": {
      "intitule_naf_65": "Action soc. sans héberg. pers. âgées & handicapées",
      "intitule_naf": "Action sociale sans hébergement pour personnes âgées et pour personnes handicapées",
      "code_naf": "8810",
      "intitule_naf_40": "Action soc. sans hbgt, per. âgée & hand."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "85f213a098f2098ddd0daa3c57edc0e67416cdbf",
    "fields": {
      "intitule_naf_65": "Administration publique et défense ; sécurité sociale obligatoire",
      "intitule_naf": "Administration publique et défense ; sécurité sociale obligatoire",
      "code_naf": "84",
      "intitule_naf_40": "Admin. publi. & défense; séc. soc. obli."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4ad19c2169e97e671460facfb996dd99252d188f",
    "fields": {
      "intitule_naf_65": "Location et location-bail machines & équipts pour la construction",
      "intitule_naf": "Location et location-bail de machines et équipements pour la construction",
      "code_naf": "7732",
      "intitule_naf_40": "Loc. & loc.-bail mach. & éqpt pr constr."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "06d860d5093cd0e1fc25d2980bd171b9a0ab56bb",
    "fields": {
      "intitule_naf_65": "Activités combinées de soutien lié aux bâtiments",
      "intitule_naf": "Activités combinées de soutien lié aux bâtiments",
      "code_naf": "8110",
      "intitule_naf_40": "Act. combinée soutien lié aux bâtiments"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ca7a9b98ae5589d830516a2ddc2677106fea1cfa",
    "fields": {
      "intitule_naf_65": "Action sociale sans hébergement",
      "intitule_naf": "Action sociale sans hébergement",
      "code_naf": "88",
      "intitule_naf_40": "Action sociale sans hébergement"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ba59be5bc40700dbdfaf0ddaee9119e215938ad7",
    "fields": {
      "intitule_naf_65": "Formation continue d'adultes",
      "intitule_naf": "Formation continue d'adultes",
      "code_naf": "8559A",
      "intitule_naf_40": "Formation continue d'adultes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "455cab61b5ff58fbb5fa1914bd877674296501e7",
    "fields": {
      "intitule_naf_65": "Enseignement pré-primaire",
      "intitule_naf": "Enseignement pré-primaire",
      "code_naf": "8510",
      "intitule_naf_40": "Enseignement pré-primaire"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "bdd28224375a11fbcf22679f077e0d5d5b42b0d4",
    "fields": {
      "intitule_naf_65": "Aide par le travail",
      "intitule_naf": "Aide par le travail",
      "code_naf": "8810C",
      "intitule_naf_40": "Aide par le travail"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "51f8f4bcceda92f6a84e3ff28f7166c8a1a48d55",
    "fields": {
      "intitule_naf_65": "Activités d'enquête",
      "intitule_naf": "Activités d'enquête",
      "code_naf": "8030",
      "intitule_naf_40": "Activités d'enquête"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8a16f86cc17bbbd93ecbaa229e036d7fd10d4d67",
    "fields": {
      "intitule_naf_65": "Gestion des musées",
      "intitule_naf": "Gestion des musées",
      "code_naf": "9102Z",
      "intitule_naf_40": "Gestion des musées"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "fb8d8f9522cbfeda3c993b2e59b36acbc2300c41",
    "fields": {
      "intitule_naf_65": "Commerce de détail appareils électroménagers (magasin spécialisé)",
      "intitule_naf": "Commerce de détail d'appareils électroménagers en magasin spécialisé",
      "code_naf": "4754",
      "intitule_naf_40": "Comm. dét.  appareil électroménager (ms)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a84019cc9290acfa2ead7658849a3294777e3e0b",
    "fields": {
      "intitule_naf_65": "Commerce de détail d'habillement en magasin spécialisé",
      "intitule_naf": "Commerce de détail d'habillement en magasin spécialisé",
      "code_naf": "4771",
      "intitule_naf_40": "Com. dét. habillement en mag. spécialisé"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "31407555e5c8c451170d7db96e6010e19f6150bc",
    "fields": {
      "intitule_naf_65": "Commerce de détail de livres en magasin spécialisé",
      "intitule_naf": "Commerce de détail de livres en magasin spécialisé",
      "code_naf": "4761Z",
      "intitule_naf_40": "Comm. dét. livres en magasin spécialisé"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "42c9ab11fd074d32c9289740615c098a0ed29271",
    "fields": {
      "intitule_naf_65": "Administration d'immeubles et autres biens immobiliers",
      "intitule_naf": "Administration d'immeubles et autres biens immobiliers",
      "code_naf": "6832A",
      "intitule_naf_40": "Administrat. immeuble & autre bien immo."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9847a00f2b4c8442b35256a0f206c63ea4a53375",
    "fields": {
      "intitule_naf_65": "Activités auxiliaires d'assurance et de caisses de retraite",
      "intitule_naf": "Activités auxiliaires d'assurance et de caisses de retraite",
      "code_naf": "662",
      "intitule_naf_40": "Act. auxil. assurance & caisse retraite"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "20187538aba4deec7cdc78da64e393b7d7cfa2c9",
    "fields": {
      "intitule_naf_65": "Autres intermédiations monétaires",
      "intitule_naf": "Autres intermédiations monétaires",
      "code_naf": "6419Z",
      "intitule_naf_40": "Autres intermédiations monétaires"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ab243a3b8e74ce68e6271eedafc63a564e062e8b",
    "fields": {
      "intitule_naf_65": "Transports fluviaux de passagers",
      "intitule_naf": "Transports fluviaux de passagers",
      "code_naf": "5030Z",
      "intitule_naf_40": "Transports fluviaux de passagers"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "542230cff1ffb066407411f046fc3ec5db1068c4",
    "fields": {
      "intitule_naf_65": "Activités vétérinaires",
      "intitule_naf": "Activités vétérinaires",
      "code_naf": "7500Z",
      "intitule_naf_40": "Activités vétérinaires"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "cc9c243343b489de2319f46a7a1a50f1f6bcc59e",
    "fields": {
      "intitule_naf_65": "Autres hébergements",
      "intitule_naf": "Autres hébergements",
      "code_naf": "5590",
      "intitule_naf_40": "Autres hébergements"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2123ed305afe6a12d44ee1cb328637a59889d734",
    "fields": {
      "intitule_naf_65": "Débits de boissons",
      "intitule_naf": "Débits de boissons",
      "code_naf": "5630Z",
      "intitule_naf_40": "Débits de boissons"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "cbdadf0f52cec68b83b4de995f8a7c070e6ad8f9",
    "fields": {
      "intitule_naf_65": "Apprêt, tannage des cuirs; préparation et teinture des fourrures",
      "intitule_naf": "Apprêt et tannage des cuirs ; préparation et teinture des fourrures",
      "code_naf": "1511",
      "intitule_naf_40": "Prépa. cuirs; prép. & teinture fourrures"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8d3cab2e0b75392c1c8d175edc381143e83cf810",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres produits chimiques n.c.a.",
      "intitule_naf": "Fabrication d'autres produits chimiques n.c.a.",
      "code_naf": "2059",
      "intitule_naf_40": "Fabric. autres produits chimiques n.c.a."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8eaa6dd54489a0a6db19e8ba3da43c91030e6737",
    "fields": {
      "intitule_naf_65": "Fabrication de matières plastiques de base",
      "intitule_naf": "Fabrication de matières plastiques de base",
      "code_naf": "2016",
      "intitule_naf_40": "Fabric. de matières plastiques de base"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "23edaa02d91b8b213539604c94a6652b3bff01a8",
    "fields": {
      "intitule_naf_65": "Fabrication et rechapage de pneumatiques",
      "intitule_naf": "Fabrication et rechapage de pneumatiques",
      "code_naf": "2211Z",
      "intitule_naf_40": "Fabrication et rechapage de pneumatiques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "783568285b86802a019ceb22a5ffd87aa12f4e8d",
    "fields": {
      "intitule_naf_65": "Fabrication de ciment, chaux et plâtre",
      "intitule_naf": "Fabrication de ciment, chaux et plâtre",
      "code_naf": "235",
      "intitule_naf_40": "Fabrication de ciment, chaux et plâtre"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f8d063c33faaa97ba8f5ac66e35452e1f880dc44",
    "fields": {
      "intitule_naf_65": "Industrie du cuir et de la chaussure",
      "intitule_naf": "Industrie du cuir et de la chaussure",
      "code_naf": "15",
      "intitule_naf_40": "Industrie du cuir et de la chaussure"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8f288df4af61608cc615eef8a8bce5a58a699726",
    "fields": {
      "intitule_naf_65": "Fabrication de vêtements de dessous",
      "intitule_naf": "Fabrication de vêtements de dessous",
      "code_naf": "1414Z",
      "intitule_naf_40": "Fabrication de vêtements de dessous"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0616728f1375ef485a055d1f4bb9dd65f0d94027",
    "fields": {
      "intitule_naf_65": "Reproduction d'enregistrements",
      "intitule_naf": "Reproduction d'enregistrements",
      "code_naf": "182",
      "intitule_naf_40": "Reproduction d'enregistrements"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d7e46b216dc3a88604dbc1b8b35cb99438f13624",
    "fields": {
      "intitule_naf_65": "Fabrication de chaussures",
      "intitule_naf": "Fabrication de chaussures",
      "code_naf": "1520Z",
      "intitule_naf_40": "Fabrication de chaussures"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "51a57a86e4cae66cb9f504526b5692162d63ecac",
    "fields": {
      "intitule_naf_65": "Raffinage du pétrole",
      "intitule_naf": "Raffinage du pétrole",
      "code_naf": "192",
      "intitule_naf_40": "Raffinage du pétrole"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e1ae28f86be2ef952fb82cc8db6334b191b5bf51",
    "fields": {
      "intitule_naf_65": "Transform. & conserv. viande & préparation prod. à base de viande",
      "intitule_naf": "Transformation et conservation de la viande et préparation de produits à base de viande",
      "code_naf": "101",
      "intitule_naf_40": "Transf. & conserv. viande & prép. viande"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "712c190b36869b0cd409b60a69ded4882941506e",
    "fields": {
      "intitule_naf_65": "Production de boissons alcooliques distillées",
      "intitule_naf": "Production de boissons alcooliques distillées",
      "code_naf": "1101Z",
      "intitule_naf_40": "Prod. de boissons alcooliques distillées"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5caa5213d36d91f8f4a515cb0e18695721105fe0",
    "fields": {
      "intitule_naf_65": "Autre transformation et conservation de légumes",
      "intitule_naf": "Autre transformation et conservation de légumes",
      "code_naf": "1039A",
      "intitule_naf_40": "Autre transf. et conserv. de légumes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "68b8a9ad17b458ea031f429f0c40d94744bb7a1a",
    "fields": {
      "intitule_naf_65": "Préparation de fibres textiles et filature",
      "intitule_naf": "Préparation de fibres textiles et filature",
      "code_naf": "131",
      "intitule_naf_40": "Prépa. de fibres textiles et filature"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f065c1de62a62d415e634f8145953a7c54525f33",
    "fields": {
      "intitule_naf_65": "Extraction de pétrole brut",
      "intitule_naf": "Extraction de pétrole brut",
      "code_naf": "610",
      "intitule_naf_40": "Extraction de pétrole brut"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9f3fe71fef65f50821c6c7c741dcc57abf612535",
    "fields": {
      "intitule_naf_65": "Extraction d'hydrocarbures",
      "intitule_naf": "Extraction d'hydrocarbures",
      "code_naf": "06",
      "intitule_naf_40": "Extraction d'hydrocarbures"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d87b27b2a23b512eaddc75f56aeb537592ef15a5",
    "fields": {
      "intitule_naf_65": "Ennoblissement textile",
      "intitule_naf": "Ennoblissement textile",
      "code_naf": "133",
      "intitule_naf_40": "Ennoblissement textile"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "50a4d3c1681c950aa74f6528307e49a9c27e2390",
    "fields": {
      "intitule_naf_65": "Extraction de lignite",
      "intitule_naf": "Extraction de lignite",
      "code_naf": "0520Z",
      "intitule_naf_40": "Extraction de lignite"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "eea3f888d730f68ef5bdd786cb0d19b333c1add2",
    "fields": {
      "intitule_naf_65": "Aquaculture en mer",
      "intitule_naf": "Aquaculture en mer",
      "code_naf": "0321Z",
      "intitule_naf_40": "Aquaculture en mer"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c883b5f62305a75d44dd3d1aa0739330717b9f58",
    "fields": {
      "intitule_naf_65": "Tissage",
      "intitule_naf": "Tissage",
      "code_naf": "132",
      "intitule_naf_40": "Tissage"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "421929aa556e47415b1697a631cddbaa386d296d",
    "fields": {
      "intitule_naf_65": "Fabrication radiateurs et chaudières pour le chauffage central",
      "intitule_naf": "Fabrication de radiateurs et de chaudières pour le chauffage central",
      "code_naf": "2521Z",
      "intitule_naf_40": "Fab. radiat. & chaudière pr chauf. ctral"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5f3120818be3e8cdade6442c7abb61f030d85c83",
    "fields": {
      "intitule_naf_65": "Fabrication de structures métalliques et de parties de structures",
      "intitule_naf": "Fabrication de structures métalliques et de parties de structures",
      "code_naf": "2511",
      "intitule_naf_40": "Fab. structure métal. & partie structure"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "65b86213a4ac88123ee9364785d6cd14ddb470eb",
    "fields": {
      "intitule_naf_65": "Fabrication de sièges d'ameublement d'intérieur",
      "intitule_naf": "Fabrication de sièges d'ameublement d'intérieur",
      "code_naf": "3109A",
      "intitule_naf_40": "Fabric. sièges d'ameublement d'intérieur"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "bb88d527d6aa039a79a643837bc1fb6daeb6ab84",
    "fields": {
      "intitule_naf_65": "Construction aéronautique et spatiale",
      "intitule_naf": "Construction aéronautique et spatiale",
      "code_naf": "303",
      "intitule_naf_40": "Construction aéronautique et spatiale"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d09347fd35171e7a6e09d8d695a87677e1f0acfc",
    "fields": {
      "intitule_naf_65": "Métallurgie du plomb, du zinc ou de l'étain",
      "intitule_naf": "Métallurgie du plomb, du zinc ou de l'étain",
      "code_naf": "2443Z",
      "intitule_naf_40": "Métallurgie du Pb, du Zn ou du Sn"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "72b726cb6722c6d85a9cac49a08babca9a2d7e24",
    "fields": {
      "intitule_naf_65": "Fabrication d'instruments de musique",
      "intitule_naf": "Fabrication d'instruments de musique",
      "code_naf": "322",
      "intitule_naf_40": "Fabrication d'instruments de musique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "575765c7c3486fce55261012c5358d035bc80efe",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres meubles",
      "intitule_naf": "Fabrication d'autres meubles",
      "code_naf": "3109",
      "intitule_naf_40": "Fabrication d'autres meubles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "cc6573e5c41a2fac1b0e1de53b004e4e662b737a",
    "fields": {
      "intitule_naf_65": "Commerce de gros de vaisselle, verrerie et produits d'entretien",
      "intitule_naf": "Commerce de gros de vaisselle, verrerie et produits d'entretien",
      "code_naf": "4644",
      "intitule_naf_40": "Com. gros vaisselle verrerie prod. entr."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "fa238cad2b2cb7ce5efa88168b2bc22ef9026b6e",
    "fields": {
      "intitule_naf_65": "Commerce de détail de carburants en magasin spécialisé",
      "intitule_naf": "Commerce de détail de carburants en magasin spécialisé",
      "code_naf": "4730",
      "intitule_naf_40": "Comm. détail carburant en mag. spéciali."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "cbde5d1eb2b78ce32010eec0b0d65465444de59b",
    "fields": {
      "intitule_naf_65": "Réparation et maintenance d'autres équipements de transport",
      "intitule_naf": "Réparation et maintenance d'autres équipements de transport",
      "code_naf": "3317Z",
      "intitule_naf_40": "Répar. & maint. d'aut. équipt transport"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3a23ec77840fac0be838986f32f11990904efb23",
    "fields": {
      "intitule_naf_65": "Commerce de gros, à l'exception des automobiles et des motocycles",
      "intitule_naf": "Commerce de gros, à l'exception des automobiles et des motocycles",
      "code_naf": "46",
      "intitule_naf_40": "Commerce gros hors auto. & motocycle"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7b780156ed04796f9155351a425d61846f90131a",
    "fields": {
      "intitule_naf_65": "Location avec opérateur de matériel de construction",
      "intitule_naf": "Location avec opérateur de matériel de construction",
      "code_naf": "4399E",
      "intitule_naf_40": "Location avec opérateur mat. de constr."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9e2162ceb7b7d95d67b1953e2f9b6047c5ecd87b",
    "fields": {
      "intitule_naf_65": "Réparation de machines et équipements mécaniques",
      "intitule_naf": "Réparation de machines et équipements mécaniques",
      "code_naf": "3312",
      "intitule_naf_40": "Répar. machine & équipement mécaniques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "82640e670d9af318daf43f337c8567cf50f61a51",
    "fields": {
      "intitule_naf_65": "Commerce de véhicules automobiles",
      "intitule_naf": "Commerce de véhicules automobiles",
      "code_naf": "451",
      "intitule_naf_40": "Commerce de véhicules automobiles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "37c8eda932f9c7cfd167676b7ae357a32383fa67",
    "fields": {
      "intitule_naf_65": "Commerce de gros d'animaux vivants",
      "intitule_naf": "Commerce de gros d'animaux vivants",
      "code_naf": "4623",
      "intitule_naf_40": "Commerce de gros d'animaux vivants"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "271d25d645a1909e45d342528d13099bccb71837",
    "fields": {
      "intitule_naf_65": "Supérettes",
      "intitule_naf": "Supérettes",
      "code_naf": "4711C",
      "intitule_naf_40": "Supérettes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9516311695c4525efc5fe07dd17f5784a9fc8f92",
    "fields": {
      "intitule_naf_65": "Autres cultures non permanentes",
      "intitule_naf": "Autres cultures non permanentes",
      "code_naf": "0119Z",
      "intitule_naf_40": "Autres cultures non permanentes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c8849ee39f2e055d52b746fafcfc46adceb52590",
    "fields": {
      "intitule_naf_65": "Culture de la vigne",
      "intitule_naf": "Culture de la vigne",
      "code_naf": "121",
      "intitule_naf_40": "Culture de la vigne"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2f11bc9a4060d5dcfe3a6441c40fa2f56b6136e9",
    "fields": {
      "intitule_naf_65": "Activités des parcs d'attractions et parcs à thèmes",
      "intitule_naf": "Activités des parcs d'attractions et parcs à thèmes",
      "code_naf": "9321Z",
      "intitule_naf_40": "Act. parcs attractions & parcs à thèmes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0c80e15241d9957fb7dc7a298e74fba3176cd834",
    "fields": {
      "intitule_naf_65": "Activités des organisations et organismes extraterritoriaux",
      "intitule_naf": "Activités des organisations et organismes extraterritoriaux",
      "code_naf": "99",
      "intitule_naf_40": "Act. organisations extraterritoriales"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9d202d536f73bd673cff3e35d8e84815c66d98bd",
    "fields": {
      "intitule_naf_65": "Construction locomotives & autre matériel ferroviaire roulant",
      "intitule_naf": "Construction de locomotives et d'autre matériel ferroviaire roulant",
      "code_naf": "3020",
      "intitule_naf_40": "Const. loco. & autre mat. ferro. roulant"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3ad7edc35e7d2ff231c51d3ef75679893e90ef62",
    "fields": {
      "intitule_naf_65": "Fabrication d'articles en bois, liège, vannerie et sparterie",
      "intitule_naf": "Fabrication d'articles en bois, liège, vannerie et sparterie",
      "code_naf": "162",
      "intitule_naf_40": "Fab. article bois, liège, vannerie, etc."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c25060a033308e21e1449e08612b3493aab8bfed",
    "fields": {
      "intitule_naf_65": "Fabrication de machines pour l'industrie agro-alimentaire",
      "intitule_naf": "Fabrication de machines pour l'industrie agro-alimentaire",
      "code_naf": "2893Z",
      "intitule_naf_40": "Fab. machine pour l'indus. agro-aliment."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c7645170ce590a6b417ffd98b6da2fc650cab5e9",
    "fields": {
      "intitule_naf_65": "Fabrication de matériels optique et photographique",
      "intitule_naf": "Fabrication de matériels optique et photographique",
      "code_naf": "2670",
      "intitule_naf_40": "Fab. matériel optique et photographique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f9bedbd2bd83e3b8803c8dc4dd202c9bbf4244cd",
    "fields": {
      "intitule_naf_65": "Fabrication d'articles métalliques ménagers",
      "intitule_naf": "Fabrication d'articles métalliques ménagers",
      "code_naf": "2599A",
      "intitule_naf_40": "Fabric. d'articles métalliques ménagers"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "23ec2de72e6bd624ec57b3ba5950cdff430e9376",
    "fields": {
      "intitule_naf_65": "Travaux de revêtement des sols et des murs",
      "intitule_naf": "Travaux de revêtement des sols et des murs",
      "code_naf": "4333",
      "intitule_naf_40": "Travaux revêtement des sols et des murs"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f827f17f31ff9cdf8f9164ebee30684cb4b8c2c5",
    "fields": {
      "intitule_naf_65": "Fabrication de verre et d'articles en verre",
      "intitule_naf": "Fabrication de verre et d'articles en verre",
      "code_naf": "231",
      "intitule_naf_40": "Fabric de verre et d'articles en verre"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "08e2d7e2fece6f384579ae8d548a5c4dbb5e3839",
    "fields": {
      "intitule_naf_65": "Fabrication de chaux et plâtre",
      "intitule_naf": "Fabrication de chaux et plâtre",
      "code_naf": "2352Z",
      "intitule_naf_40": "Fabrication de chaux et plâtre"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f34254a5bf87bc465fcf893df37432a189f3b7ef",
    "fields": {
      "intitule_naf_65": "Transport d'électricité",
      "intitule_naf": "Transport d'électricité",
      "code_naf": "3512",
      "intitule_naf_40": "Transport d'électricité"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e16722380c49caa8dc42e967d00b3dcdf247f508",
    "fields": {
      "intitule_naf_65": "Cokéfaction",
      "intitule_naf": "Cokéfaction",
      "code_naf": "1910",
      "intitule_naf_40": "Cokéfaction"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f5201d178a1586b8bf4e0809f288f9c7be7737f4",
    "fields": {
      "intitule_naf_65": "Aut. activ. auxil. serv. financ., hors assur. & caisses retraite",
      "intitule_naf": "Autres activités auxiliaires de services financiers, hors assurance et caisses de retraite",
      "code_naf": "6619",
      "intitule_naf_40": "Aut. act. aux. sce fin., hs ass. & retr."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7939c321e98f6ebd1a5b3ada24f246ba7f84d7a1",
    "fields": {
      "intitule_naf_65": "Location et location-bail machines & équipts pour la construction",
      "intitule_naf": "Location et location-bail de machines et équipements pour la construction",
      "code_naf": "7732Z",
      "intitule_naf_40": "Loc. & loc.-bail mach. & éqpt pr constr."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c645de87ab079eeebf625302a349b737f3f61ef2",
    "fields": {
      "intitule_naf_65": "Recherche-développement : autres sciences physiques et naturelles",
      "intitule_naf": "Recherche-développement en autres sciences physiques et naturelles",
      "code_naf": "7219",
      "intitule_naf_40": "R&D : aut. sciences physique & naturelle"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "874f3ecd9b0029c1d3907988b679a47d9702da62",
    "fields": {
      "intitule_naf_65": "Activités de contrôle et analyses techniques",
      "intitule_naf": "Activités de contrôle et analyses techniques",
      "code_naf": "712",
      "intitule_naf_40": "Activité de contrôle & analyse technique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5e8324a91f52c44d98e4f75de0ec223bc6903f1a",
    "fields": {
      "intitule_naf_65": "Location de terrains et d'autres biens immobiliers",
      "intitule_naf": "Location de terrains et d'autres biens immobiliers",
      "code_naf": "6820B",
      "intitule_naf_40": "Location terrain & autre bien immobilier"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0b9a4be4b5d930f18ffd31cbcc5a6e5317a9c66c",
    "fields": {
      "intitule_naf_65": "Services d'aménagement paysager",
      "intitule_naf": "Services d'aménagement paysager",
      "code_naf": "8130",
      "intitule_naf_40": "Services d'aménagement paysager"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e6fb0756a62132c9b9c6c8c4295e519b0cee2895",
    "fields": {
      "intitule_naf_65": "Activités d'architecture",
      "intitule_naf": "Activités d'architecture",
      "code_naf": "7111Z",
      "intitule_naf_40": "Activités d'architecture"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d18bc09cf31aed02aba67c03905696d57db2b458",
    "fields": {
      "intitule_naf_65": "Enquêtes et sécurité",
      "intitule_naf": "Enquêtes et sécurité",
      "code_naf": "80",
      "intitule_naf_40": "Enquêtes et sécurité"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9ec99550943a0dcf9d845613587291b0dba2f126",
    "fields": {
      "intitule_naf_65": "Activités comptables",
      "intitule_naf": "Activités comptables",
      "code_naf": "692",
      "intitule_naf_40": "Activités comptables"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4bfebfee4325021cc1d1af6e8ee835816e9ea158",
    "fields": {
      "intitule_naf_65": "Autres assurances",
      "intitule_naf": "Autres assurances",
      "code_naf": "6512",
      "intitule_naf_40": "Autres assurances"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "681da28f7def36fca6b595fceaabdc224d8340f7",
    "fields": {
      "intitule_naf_65": "Réparation d'ordinateurs et d'équipements périphériques",
      "intitule_naf": "Réparation d'ordinateurs et d'équipements périphériques",
      "code_naf": "9511Z",
      "intitule_naf_40": "Répar. ordinateur & équipt périphérique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4352f2964056d1769a7b94ddac404d42a37813ee",
    "fields": {
      "intitule_naf_65": "Enseignement supérieur et post-secondaire non supérieur",
      "intitule_naf": "Enseignement supérieur et post-secondaire non supérieur",
      "code_naf": "854",
      "intitule_naf_40": "Enseigmnt sup. & post-second. non sup."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e7a187bcd42794ad5f216e50956f4f7cb20a8821",
    "fields": {
      "intitule_naf_65": "Organisation de salons professionnels et congrès",
      "intitule_naf": "Organisation de salons professionnels et congrès",
      "code_naf": "8230",
      "intitule_naf_40": "Organisation salon profession. & congrès"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0a1cfc2875bac898be9d44d6f1eb50fe01b81558",
    "fields": {
      "intitule_naf_65": "Gestion des bibliothèques et des archives",
      "intitule_naf": "Gestion des bibliothèques et des archives",
      "code_naf": "9101Z",
      "intitule_naf_40": "Gestion des bibliothèques & des archives"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "fb76e5b5e1b10fc5804ee1dc0b398e0423b0d763",
    "fields": {
      "intitule_naf_65": "Services du feu et de secours",
      "intitule_naf": "Services du feu et de secours",
      "code_naf": "8425",
      "intitule_naf_40": "Services du feu et de secours"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0bddbf996c5d056d3772d1ae550be29b02804c96",
    "fields": {
      "intitule_naf_65": "Coiffure et soins de beauté",
      "intitule_naf": "Coiffure et soins de beauté",
      "code_naf": "9602",
      "intitule_naf_40": "Coiffure et soins de beauté"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "12b0e039fcdcf97861c8bd0178aac447071f84c8",
    "fields": {
      "intitule_naf_65": "Activités chirurgicales",
      "intitule_naf": "Activités chirurgicales",
      "code_naf": "8622B",
      "intitule_naf_40": "Activités chirurgicales"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2a9b0f0c2a9efbc5eb8f4f1211cb2da9feabdec2",
    "fields": {
      "intitule_naf_65": "Fabrication industrielle de pain et de pâtisserie fraîche",
      "intitule_naf": "Fabrication industrielle de pain et de pâtisserie fraîche",
      "code_naf": "1071A",
      "intitule_naf_40": "Fab. indus. de pain & pâtisserie fraîche"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0c4e3322d8cdc95512b4a006321b1c9ae8dd980b",
    "fields": {
      "intitule_naf_65": "Fabrication de placage et de panneaux de bois",
      "intitule_naf": "Fabrication de placage et de panneaux de bois",
      "code_naf": "1621Z",
      "intitule_naf_40": "Fabric.  placage et panneaux de bois"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "47e3fd11deb7bf42580a12873bb91cd3792ff3db",
    "fields": {
      "intitule_naf_65": "Fabrication de produits à base de tabac",
      "intitule_naf": "Fabrication de produits à base de tabac",
      "code_naf": "1200Z",
      "intitule_naf_40": "Fabrication de produits à base de tabac"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5d01765fc16acb54ab1cb742612620d47cc9dd41",
    "fields": {
      "intitule_naf_65": "Fabrication de vêtements de travail",
      "intitule_naf": "Fabrication de vêtements de travail",
      "code_naf": "1412Z",
      "intitule_naf_40": "Fabrication de vêtements de travail"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "addaf0ff4c6d41eccc53a9cc1fb5b1993bcefefd",
    "fields": {
      "intitule_naf_65": "Fabrication de parquets assemblés",
      "intitule_naf": "Fabrication de parquets assemblés",
      "code_naf": "1622Z",
      "intitule_naf_40": "Fabrication de parquets assemblés"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4a14d7858c10b3a58572a0d16d147bee8876452b",
    "fields": {
      "intitule_naf_65": "Élevage d'ovins et de caprins",
      "intitule_naf": "Élevage d'ovins et de caprins",
      "code_naf": "0145Z",
      "intitule_naf_40": "Élevage d'ovins et de caprins"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2e2cde2317105d5204c6f9b12e4f438bf3f2db59",
    "fields": {
      "intitule_naf_65": "Culture de plantes à boissons",
      "intitule_naf": "Culture de plantes à boissons",
      "code_naf": "0127Z",
      "intitule_naf_40": "Culture de plantes à boissons"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8a3fa9b6ed9fc74dae30117902ebd679e2ecbe12",
    "fields": {
      "intitule_naf_65": "Reproduction de plantes",
      "intitule_naf": "Reproduction de plantes",
      "code_naf": "130",
      "intitule_naf_40": "Reproduction de plantes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "aad4921e6f43b7880486fce8142999f7ac4a9b34",
    "fields": {
      "intitule_naf_65": "Production de sel",
      "intitule_naf": "Production de sel",
      "code_naf": "0893Z",
      "intitule_naf_40": "Production de sel"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7151761c8c168cd3fd25739a8aa06dcee016a30e",
    "fields": {
      "intitule_naf_65": "Production de sel",
      "intitule_naf": "Production de sel",
      "code_naf": "893",
      "intitule_naf_40": "Production de sel"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "99a8eae22dc5a0a38b7d4cb5c477a6370e0a38f2",
    "fields": {
      "intitule_naf_65": "Culture de céréales (sf riz) légumineuses, graines oléagineuses",
      "intitule_naf": "Culture de céréales (à l'exception du riz), de légumineuses et de graines oléagineuses",
      "code_naf": "111",
      "intitule_naf_40": "Cult céréale, légumineuse, graine oléag."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b1e6d092a11ea4ec57553d40a095a81f7dbf9739",
    "fields": {
      "intitule_naf_65": "Comm. détail d'articles médicaux & orthopédiques en magasin spéc.",
      "intitule_naf": "Commerce de détail d'articles médicaux et orthopédiques en magasin spécialisé",
      "code_naf": "4774Z",
      "intitule_naf_40": "Com. dét. art. médicaux & orthopéd. (ms)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "890f8ca5267cfb5d05965253c73717ed8203f9b0",
    "fields": {
      "intitule_naf_65": "Comm. détail pain pâtisserie & confiserie (magasin spécialisé)",
      "intitule_naf": "Commerce de détail de pain, pâtisserie et confiserie en magasin spécialisé",
      "code_naf": "4724",
      "intitule_naf_40": "Comm. dét. pain pâtiss. & confiser. (ms)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "510b1d7378abdffa8a26e198dc6dbed08b80adec",
    "fields": {
      "intitule_naf_65": "Interm. spécialisés commerce d'autres produits spécifiques",
      "intitule_naf": "Intermédiaires spécialisés dans le commerce d'autres produits spécifiques",
      "code_naf": "4618",
      "intitule_naf_40": "Int. spécialis. comm. aut. prod. spécif."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "83abffb1291f13214267375e13f62a222374c43c",
    "fields": {
      "intitule_naf_65": "Comm. détail biens culturels & loisirs (magasin spécialisé)",
      "intitule_naf": "Commerce de détail de biens culturels et de loisirs en magasin spécialisé",
      "code_naf": "476",
      "intitule_naf_40": "Comm. dét. biens culturels & loisir (ms)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f86512de5f831c5467ee6766557971d8707f6539",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres réservoirs, citernes, conteneurs métalliques",
      "intitule_naf": "Fabrication d'autres réservoirs, citernes et conteneurs métalliques",
      "code_naf": "2529",
      "intitule_naf_40": "Fab. aut. réservr, citerne, etc. métal."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "759474b853940a2d27149cff3704a6e80692d16c",
    "fields": {
      "intitule_naf_65": "Commerce de gros de combustibles et de produits annexes",
      "intitule_naf": "Commerce de gros de combustibles et de produits annexes",
      "code_naf": "4671",
      "intitule_naf_40": "Com. gros combustible & produits annexes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0f5a590fcff7fed285413546be883c03e7438825",
    "fields": {
      "intitule_naf_65": "Commerce de gros (commerce interentreprises) non spécialisé",
      "intitule_naf": "Commerce de gros (commerce interentreprises) non spécialisé",
      "code_naf": "4690Z",
      "intitule_naf_40": "Commerce de gros non spécialisé"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4df14db4a66997d1172160e680322151b6aa708b",
    "fields": {
      "intitule_naf_65": "Transports ferroviaires de fret",
      "intitule_naf": "Transports ferroviaires de fret",
      "code_naf": "492",
      "intitule_naf_40": "Transports ferroviaires de fret"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1ce8369c029017387171cce2ae21271be4462e05",
    "fields": {
      "intitule_naf_65": "Trt de données, hébergement & activ. connexes; portails Internet",
      "intitule_naf": "Traitement de données, hébergement et activités connexes ; portails Internet",
      "code_naf": "631",
      "intitule_naf_40": "Trt donnée, hébrgt etc.; portail Internt"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7256542944a5f324d2c1cbcfd7eceac379b3ad70",
    "fields": {
      "intitule_naf_65": "Production de films institutionnels et publicitaires",
      "intitule_naf": "Production de films institutionnels et publicitaires",
      "code_naf": "5911B",
      "intitule_naf_40": "Prod. film institutionnel & publicitaire"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e5480ef73f80a3e068b2d276ed8c6ef7832725b1",
    "fields": {
      "intitule_naf_65": "Traiteurs et autres services de restauration",
      "intitule_naf": "Traiteurs et autres services de restauration",
      "code_naf": "562",
      "intitule_naf_40": "Traiteur & autre service de restauration"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "06a3c21eea8863a9a9d641e291855ec7e4528c41",
    "fields": {
      "intitule_naf_65": "Autres services auxiliaires des transports",
      "intitule_naf": "Autres services auxiliaires des transports",
      "code_naf": "5229",
      "intitule_naf_40": "Autre service auxiliaires des transports"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "25118bb081a062bd6b176243689bd58a5383ddba",
    "fields": {
      "intitule_naf_65": "Enregistrement sonore et édition musicale",
      "intitule_naf": "Enregistrement sonore et édition musicale",
      "code_naf": "5920Z",
      "intitule_naf_40": "Enregistrement sonore & édition musicale"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8ce9581475ff154c0fcb6b2332975a88c5937a59",
    "fields": {
      "intitule_naf_65": "Restaurants et services de restauration mobile",
      "intitule_naf": "Restaurants et services de restauration mobile",
      "code_naf": "5610",
      "intitule_naf_40": "Restaurant & serv. de restaurat. mobile"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "61edb5369c680b7e24aa02637050d9d84ddcb677",
    "fields": {
      "intitule_naf_65": "Autres services de restauration n.c.a.",
      "intitule_naf": "Autres services de restauration n.c.a.",
      "code_naf": "5629B",
      "intitule_naf_40": "Autres services de restauration n.c.a."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a6adbe8af1a566f36d1d198b61b584c5d6202286",
    "fields": {
      "intitule_naf_65": "Transports de voyageurs par taxis",
      "intitule_naf": "Transports de voyageurs par taxis",
      "code_naf": "4932",
      "intitule_naf_40": "Transports de voyageurs par taxis"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "215f78c9cac62a7d7a7cc3aeaa5bfc249e418cd1",
    "fields": {
      "intitule_naf_65": "Edition de logiciels applicatifs",
      "intitule_naf": "Edition de logiciels applicatifs",
      "code_naf": "5829C",
      "intitule_naf_40": "Edition de logiciels applicatifs"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4601355513b733c6316c40eca048825a99ddb79b",
    "fields": {
      "intitule_naf_65": "Conseil informatique",
      "intitule_naf": "Conseil informatique",
      "code_naf": "6202",
      "intitule_naf_40": "Conseil informatique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "691ac7bc80c6f5bccfeb28ee4773378f008a1fe9",
    "fields": {
      "intitule_naf_65": "Fabrication de biscuits, biscottes et pâtisseries de conservation",
      "intitule_naf": "Fabrication de biscuits, biscottes et pâtisseries de conservation",
      "code_naf": "1072Z",
      "intitule_naf_40": "Fab. pain, biscuit & pâtiss. de conserv."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5fec8c4cf498007920bc63622cc1e95260e038f2",
    "fields": {
      "intitule_naf_65": "Services de soutien à l'exploitation forestière",
      "intitule_naf": "Services de soutien à l'exploitation forestière",
      "code_naf": "240",
      "intitule_naf_40": "Services de soutien à l'expl. forestière"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1e2db139f071c3b51263eca0cdd301c66f3b074c",
    "fields": {
      "intitule_naf_65": "Fabrication de savons, détergents et produits d'entretien",
      "intitule_naf": "Fabrication de savons, détergents et produits d'entretien",
      "code_naf": "2041",
      "intitule_naf_40": "Fab. savon, détergent & prod. entretien"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5146eefd4e9eecb4416c8ff2a68dffe94da046f5",
    "fields": {
      "intitule_naf_65": "Fabrication d'outillage portatif à moteur incorporé",
      "intitule_naf": "Fabrication d'outillage portatif à moteur incorporé",
      "code_naf": "2824",
      "intitule_naf_40": "Fab. outillage portatif à moteur incorp."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ad1e5d2a5e2d54c35a9eff7d4e780a1309b34253",
    "fields": {
      "intitule_naf_65": "Fabrication de cartes électroniques assemblées",
      "intitule_naf": "Fabrication de cartes électroniques assemblées",
      "code_naf": "2612Z",
      "intitule_naf_40": "Fab. de cartes électroniques assemblées"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c38b528d8e94a80ba1ee4a41c62d9f1a710daa28",
    "fields": {
      "intitule_naf_65": "Fabrication d'instrumentation scientifique et technique",
      "intitule_naf": "Fabrication d'instrumentation scientifique et technique",
      "code_naf": "2651B",
      "intitule_naf_40": "Fab. instrumentation scientifiq. & tech."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e0708ae6b4e002e1e72f8cf961ec03085f50039e",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres machines d'usage spécifique",
      "intitule_naf": "Fabrication d'autres machines d'usage spécifique",
      "code_naf": "289",
      "intitule_naf_40": "Fabric. autre machine d'usage spécifique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "00d6e3981237df3dccfb06d17f3e13d37f22f9ae",
    "fields": {
      "intitule_naf_65": "Fabrication d'emballages en matières plastiques",
      "intitule_naf": "Fabrication d'emballages en matières plastiques",
      "code_naf": "2222",
      "intitule_naf_40": "Fab. d'emballage en matière plastique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d1b27c8494cef294be72154f8c6596039a0e53dd",
    "fields": {
      "intitule_naf_65": "Fabrication d'armes et de munitions",
      "intitule_naf": "Fabrication d'armes et de munitions",
      "code_naf": "254",
      "intitule_naf_40": "Fabrication d'armes et de munitions"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0bdeef6624bb182c6b739e4d33537a9039f7fecb",
    "fields": {
      "intitule_naf_65": "Production de métaux précieux",
      "intitule_naf": "Production de métaux précieux",
      "code_naf": "2441",
      "intitule_naf_40": "Production de métaux précieux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d94deecd03c686df8ee84cb14cd9d74af2e570fe",
    "fields": {
      "intitule_naf_65": "Fonderie de métaux légers",
      "intitule_naf": "Fonderie de métaux légers",
      "code_naf": "2453Z",
      "intitule_naf_40": "Fonderie de métaux légers"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "dc575cc26f6484b6ed97ad6e285e36493a57d8be",
    "fields": {
      "intitule_naf_65": "Fonderie d'acier",
      "intitule_naf": "Fonderie d'acier",
      "code_naf": "2452Z",
      "intitule_naf_40": "Fonderie d'acier"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "be5664e71a48fcec977e24f8e40d9ac42ac596cf",
    "fields": {
      "intitule_naf_65": "Réparation d'ordinateurs et d'équipements périphériques",
      "intitule_naf": "Réparation d'ordinateurs et d'équipements périphériques",
      "code_naf": "9511",
      "intitule_naf_40": "Répar. ordinateur & équipt périphérique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "44a28f0f6d99f7503af16ff392f0c4276297fb77",
    "fields": {
      "intitule_naf_65": "Activités des organisations professionnelles",
      "intitule_naf": "Activités des organisations professionnelles",
      "code_naf": "9412Z",
      "intitule_naf_40": "Act. des organisations professionnelles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2401eb754ef11e8a23e172387751d376bb799f1f",
    "fields": {
      "intitule_naf_65": "Activités des organisations politiques",
      "intitule_naf": "Activités des organisations politiques",
      "code_naf": "9492Z",
      "intitule_naf_40": "Activités des organisations politiques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7a231ef5be8011eb6c42b5ab7fe2ab3a9c0b3fde",
    "fields": {
      "intitule_naf_65": "Fabrication de produits pharmaceutiques de base",
      "intitule_naf": "Fabrication de produits pharmaceutiques de base",
      "code_naf": "2110Z",
      "intitule_naf_40": "Fab. de produits pharmaceutiques de base"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b87818ad13c036a19fa302bfbd6177d72cde26f0",
    "fields": {
      "intitule_naf_65": "Fabrication de non-tissés, sauf habillement",
      "intitule_naf": "Fabrication de non-tissés, sauf habillement",
      "code_naf": "1395Z",
      "intitule_naf_40": "Fabric. de non-tissés, sauf habillement"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "bdbd7070398661f24bb5c4f7ab631c2504b54b73",
    "fields": {
      "intitule_naf_65": "Extraction de minerais d'uranium et de thorium",
      "intitule_naf": "Extraction de minerais d'uranium et de thorium",
      "code_naf": "0721Z",
      "intitule_naf_40": "Extr. de minerais d'uranium & de thorium"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0b553bddf6bcabab63b01bc3c639ea1dafd7a672",
    "fields": {
      "intitule_naf_65": "Préparation de fibres textiles et filature",
      "intitule_naf": "Préparation de fibres textiles et filature",
      "code_naf": "1310",
      "intitule_naf_40": "Prépa. de fibres textiles et filature"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8d259ed53303df0a72b83c9c4aefb909e93258a4",
    "fields": {
      "intitule_naf_65": "Fabrication d'aliments pour animaux",
      "intitule_naf": "Fabrication d'aliments pour animaux",
      "code_naf": "109",
      "intitule_naf_40": "Fabrication d'aliments pour animaux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "529b099728cd8f0f550ccc7a82e4fde80dc9b7df",
    "fields": {
      "intitule_naf_65": "Fabrication de vêtements de dessus",
      "intitule_naf": "Fabrication de vêtements de dessus",
      "code_naf": "1413",
      "intitule_naf_40": "Fabrication de vêtements de dessus"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "51afa7997a638b94aba2900a70b3d5bdf8567f8b",
    "fields": {
      "intitule_naf_65": "Fabrication de produits explosifs",
      "intitule_naf": "Fabrication de produits explosifs",
      "code_naf": "2051Z",
      "intitule_naf_40": "Fabrication de produits explosifs"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "216c725f86cd420e66d9e1cce1f68ce0bfec1f28",
    "fields": {
      "intitule_naf_65": "Fabrication d'articles à mailles",
      "intitule_naf": "Fabrication d'articles à mailles",
      "code_naf": "143",
      "intitule_naf_40": "Fabrication d'articles à mailles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "02435a4bf0e27720d4899845500da25f51b6238c",
    "fields": {
      "intitule_naf_65": "Fabrication de verre creux",
      "intitule_naf": "Fabrication de verre creux",
      "code_naf": "2313",
      "intitule_naf_40": "Fabrication de verre creux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "99987a116f156e8521fd37d0fb417f5d98b6df2d",
    "fields": {
      "intitule_naf_65": "Élevage de volailles",
      "intitule_naf": "Élevage de volailles",
      "code_naf": "0147Z",
      "intitule_naf_40": "Élevage de volailles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1ea84b9cd12136d7584c498c2ae8aaa8f84c3a34",
    "fields": {
      "intitule_naf_65": "Commerce de gros d'appareils sanitaires et produits de décoration",
      "intitule_naf": "Commerce de gros (commerce interentreprises) d'appareils sanitaires et de produits de décoration",
      "code_naf": "4673B",
      "intitule_naf_40": "Cg appareil sanitaire & prod. décoration"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9cfccc7cd52c6039fb972001fcdd106abe55b8ea",
    "fields": {
      "intitule_naf_65": "Aut. activités auxiliaires d'assurance et de caisses de retraite",
      "intitule_naf": "Autres activités auxiliaires d'assurance et de caisses de retraite",
      "code_naf": "6629Z",
      "intitule_naf_40": "Aut. act. aux. assur. & caisse retraite"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "61b74fc2b3af6d422ed94168b58620f38881a23c",
    "fields": {
      "intitule_naf_65": "Location et location-bail autres biens personnels et domestiques",
      "intitule_naf": "Location et location-bail d'autres biens personnels et domestiques",
      "code_naf": "7729Z",
      "intitule_naf_40": "Loc. & loc.-bail aut. bien perso. & dom."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c0e01a7fa49a06a486b7b905184c000b3abc2188",
    "fields": {
      "intitule_naf_65": "Activités générales de sécurité sociale",
      "intitule_naf": "Activités générales de sécurité sociale",
      "code_naf": "8430A",
      "intitule_naf_40": "Activités générales de sécurité sociale"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "da46528368d19ce573294164d28c4fe8ed8f0977",
    "fields": {
      "intitule_naf_65": "Courtage de valeurs mobilières et de marchandises",
      "intitule_naf": "Courtage de valeurs mobilières et de marchandises",
      "code_naf": "6612Z",
      "intitule_naf_40": "Courtage valeur mobilière & marchandise"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "aa6b5620ff675e87c402fa6ee66199df156647cd",
    "fields": {
      "intitule_naf_65": "Activités de soutien au spectacle vivant",
      "intitule_naf": "Activités de soutien au spectacle vivant",
      "code_naf": "9002",
      "intitule_naf_40": "Activités de soutien au spectacle vivant"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e8a4843d5cf2815cceeb396f6cf248fcde96ca09",
    "fields": {
      "intitule_naf_65": "Recherche-développement en biotechnologie",
      "intitule_naf": "Recherche-développement en biotechnologie",
      "code_naf": "7211",
      "intitule_naf_40": "Recherche-développemnt en biotechnologie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "efd5fde0401e990180d6dd6fab3ca7578e0aa295",
    "fields": {
      "intitule_naf_65": "Nettoyage courant des bâtiments",
      "intitule_naf": "Nettoyage courant des bâtiments",
      "code_naf": "8121Z",
      "intitule_naf_40": "Nettoyage courant des bâtiments"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3d232946434c1414d72d75315a7df405cbe75264",
    "fields": {
      "intitule_naf_65": "Services de prérogative publique",
      "intitule_naf": "Services de prérogative publique",
      "code_naf": "842",
      "intitule_naf_40": "Services de prérogative publique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2bcfd1d099830a53eb35430799c4f6e217ae0517",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres produits minéraux non métalliques n.c.a.",
      "intitule_naf": "Fabrication d'autres produits minéraux non métalliques n.c.a.",
      "code_naf": "2399",
      "intitule_naf_40": "Fab. aut. prod. minéraux non métal. nca."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "453ddaf52e63ed077b77501d1ffb479fd4483737",
    "fields": {
      "intitule_naf_65": "Construction locomotives et autre matériel ferroviaire roulant",
      "intitule_naf": "Construction de locomotives et d'autre matériel ferroviaire roulant",
      "code_naf": "302",
      "intitule_naf_40": "Const. loco. & autre mat. ferro. roulant"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "64a325db5408c89821ff8796aebfd466bec65ecf",
    "fields": {
      "intitule_naf_65": "Commerce de voitures et de véhicules automobiles légers",
      "intitule_naf": "Commerce de voitures et de véhicules automobiles légers",
      "code_naf": "4511Z",
      "intitule_naf_40": "Comm. de voiture & véhicule auto. léger"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "6b51c84b426c398e99509c1797ca109e2bba2da3",
    "fields": {
      "intitule_naf_65": "Intermédiaires du commerce en denrées, boissons et tabac",
      "intitule_naf": "Intermédiaires du commerce en denrées, boissons et tabac",
      "code_naf": "4617",
      "intitule_naf_40": "Int. comm. en denrées, boissons & tabac"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a892d23c0481ce91a073cc2a1bc06302be591afe",
    "fields": {
      "intitule_naf_65": "Commerce de gros de minerais et métaux",
      "intitule_naf": "Commerce de gros (commerce interentreprises) de minerais et métaux",
      "code_naf": "4672Z",
      "intitule_naf_40": "Commerce de gros de minerais et métaux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f75db608cd66a47f7d3b7f374f4d2dd50ed36aee",
    "fields": {
      "intitule_naf_65": "Intermédiaires du commerce en produits divers",
      "intitule_naf": "Intermédiaires du commerce en produits divers",
      "code_naf": "4619",
      "intitule_naf_40": "Interm. du commerce en produits divers"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "61d3d7fd7496721afe5177d4206106afa50e217f",
    "fields": {
      "intitule_naf_65": "Entretien et réparation de véhicules automobiles",
      "intitule_naf": "Entretien et réparation de véhicules automobiles",
      "code_naf": "4520",
      "intitule_naf_40": "Entretien & répar. de véhicule auto."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f8899479e873710bebc2806bb9516dd21c68c3fb",
    "fields": {
      "intitule_naf_65": "Travaux de menuiserie bois et PVC",
      "intitule_naf": "Travaux de menuiserie bois et PVC",
      "code_naf": "4332A",
      "intitule_naf_40": "Travaux de menuiserie bois et PVC"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8aaf54b4859083143dc8235a2e7478d7dcc94405",
    "fields": {
      "intitule_naf_65": "Production de combustibles gazeux",
      "intitule_naf": "Production de combustibles gazeux",
      "code_naf": "3521Z",
      "intitule_naf_40": "Production de combustibles gazeux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4e12df7367584a3c950e5cc709d7970162a130e5",
    "fields": {
      "intitule_naf_65": "Installation électrique",
      "intitule_naf": "Installation électrique",
      "code_naf": "4321",
      "intitule_naf_40": "Installation électrique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1c5adee6ad35323a821f9ceced4fe00b3b45e691",
    "fields": {
      "intitule_naf_65": "Comm. gros (interentr.) machines pour industrie textile & habill.",
      "intitule_naf": "Commerce de gros (commerce interentreprises) de machines pour l'industrie textile et l'habillement",
      "code_naf": "4664Z",
      "intitule_naf_40": "Com. gros machine pr ind. text. & habil."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c456e6d2a2301d37005952636571ebd0d4cd42a6",
    "fields": {
      "intitule_naf_65": "Comm. détail d'articles horlogerie & bijouterie (magas. spéc.)",
      "intitule_naf": "Commerce de détail d'articles d'horlogerie et de bijouterie en magasin spécialisé",
      "code_naf": "4777",
      "intitule_naf_40": "Com. dét. art. horlogerie & bijout. (ms)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "85a1305c267c9f5c52fddc53dd914adb5b3f3609",
    "fields": {
      "intitule_naf_65": "Autres commerces de détail alimentaires en magasin spécialisé",
      "intitule_naf": "Autres commerces de détail alimentaires en magasin spécialisé",
      "code_naf": "4729",
      "intitule_naf_40": "Aut. com. détail alim. en mag. spéciali."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9ac4bb640d60c82d9f06cd6f0ae8d9d0c30d82d7",
    "fields": {
      "intitule_naf_65": "Fabrication autres produits 1ere transformation de l'acier",
      "intitule_naf": "Fabrication d'autres produits de première transformation de l'acier",
      "code_naf": "243",
      "intitule_naf_40": "Fab. aut. prod. de 1ère transform. acier"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8fa3ae37c53f6885aa936dc1fd28e6a7cf42fcbc",
    "fields": {
      "intitule_naf_65": "Édition de répertoires et de fichiers d'adresses",
      "intitule_naf": "Édition de répertoires et de fichiers d'adresses",
      "code_naf": "5812Z",
      "intitule_naf_40": "Édition répertoires & fichiers d'adresse"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8f0622e10cb3885ac0742502c41e4b0711a9beae",
    "fields": {
      "intitule_naf_65": "Conseil en systèmes et logiciels informatiques",
      "intitule_naf": "Conseil en systèmes et logiciels informatiques",
      "code_naf": "6202A",
      "intitule_naf_40": "Conseil en système & logiciel informati."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c8cc72d5b20794c49147c2b6b406758678c5fafb",
    "fields": {
      "intitule_naf_65": "Projection de films cinématographiques",
      "intitule_naf": "Projection de films cinématographiques",
      "code_naf": "5914Z",
      "intitule_naf_40": "Projection de films cinématographiques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4243541e3ac2ee5c8a59eb5f128f7723f8ae6f6d",
    "fields": {
      "intitule_naf_65": "Transport ferroviaire interurbain de voyageurs",
      "intitule_naf": "Transport ferroviaire interurbain de voyageurs",
      "code_naf": "4910Z",
      "intitule_naf_40": "Transport ferrov. interurbain voyageur"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "75cf81026b4e78be820b758dbad5ff241ffd1528",
    "fields": {
      "intitule_naf_65": "Assurance vie",
      "intitule_naf": "Assurance vie",
      "code_naf": "6511Z",
      "intitule_naf_40": "Assurance vie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c76cc77e273a2f12b74edf5fceafef6136b19dea",
    "fields": {
      "intitule_naf_65": "Manutention",
      "intitule_naf": "Manutention",
      "code_naf": "5224",
      "intitule_naf_40": "Manutention"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ce7e621cf63dddef613cbbe77ac9c81a974d3866",
    "fields": {
      "intitule_naf_65": "Activités de soutien aux autres industries extractives",
      "intitule_naf": "Activités de soutien aux autres industries extractives",
      "code_naf": "0990Z",
      "intitule_naf_40": "Act. de soutien aut. indus. extractives"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a187c02f51c8eed0eb8f2ee8f1ef10c9a9b8d619",
    "fields": {
      "intitule_naf_65": "Élevage de chameaux et d'autres camélidés",
      "intitule_naf": "Élevage de chameaux et d'autres camélidés",
      "code_naf": "0144Z",
      "intitule_naf_40": "Élevage de chameaux & d'autres camélidés"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3d8a1971b76cdef56ad2cf87138d80e93e59324e",
    "fields": {
      "intitule_naf_65": "Extraction de minerais métalliques",
      "intitule_naf": "Extraction de minerais métalliques",
      "code_naf": "07",
      "intitule_naf_40": "Extraction de minerais métalliques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5754067215031648ca3b0f468d2158948cdc1ef7",
    "fields": {
      "intitule_naf_65": "Extraction de pétrole brut",
      "intitule_naf": "Extraction de pétrole brut",
      "code_naf": "0610Z",
      "intitule_naf_40": "Extraction de pétrole brut"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ba4d63f70876516d577d91cc4eafa8e663a77ab7",
    "fields": {
      "intitule_naf_65": "Élevage de porcins",
      "intitule_naf": "Élevage de porcins",
      "code_naf": "0146Z",
      "intitule_naf_40": "Élevage de porcins"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "6b02049b6031a24922e7aa56bd41352320d70b97",
    "fields": {
      "intitule_naf_65": "Transform. & conserv. poisson, crustacés & mollusques",
      "intitule_naf": "Transformation et conservation de poisson, de crustacés et de mollusques",
      "code_naf": "1020",
      "intitule_naf_40": "Transf. & conserv. poisson, crust., etc."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "852f5b264b68c63b3b12601006e1c1f9dba9f56f",
    "fields": {
      "intitule_naf_65": "Fabrication d'articles de voyage, de maroquinerie et de sellerie",
      "intitule_naf": "Fabrication d'articles de voyage, de maroquinerie et de sellerie",
      "code_naf": "1512Z",
      "intitule_naf_40": "Fab. art. voyage, maroquin., & sellerie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c6de96e942fbfad63fe4979cca5e6c8b20b97d1e",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres textiles techniques et industriels",
      "intitule_naf": "Fabrication d'autres textiles techniques et industriels",
      "code_naf": "1396Z",
      "intitule_naf_40": "Fab. autre textile techniq. & industriel"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e7a0c5b0af7805cd6eb6f268f225538434a9472d",
    "fields": {
      "intitule_naf_65": "Transformation et conservation de la viande de boucherie",
      "intitule_naf": "Transformation et conservation de la viande de boucherie",
      "code_naf": "1011",
      "intitule_naf_40": "Transf. & conserv.  viande de boucherie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "bf7128c90f108a0f34478c0cd5c209c40b114f74",
    "fields": {
      "intitule_naf_65": "Fabrication d'articles textiles, sauf habillement",
      "intitule_naf": "Fabrication d'articles textiles, sauf habillement",
      "code_naf": "1392Z",
      "intitule_naf_40": "Fab. d'article textile, sauf habillement"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d00172204c83c354c7c6c267f3dbab062795e86c",
    "fields": {
      "intitule_naf_65": "Fabrication d'aliments pour animaux de ferme",
      "intitule_naf": "Fabrication d'aliments pour animaux de ferme",
      "code_naf": "1091Z",
      "intitule_naf_40": "Fabric. d'aliments pour animaux de ferme"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "18a52c3b68c77cf63ee2f36d60a9aa58fd9c5952",
    "fields": {
      "intitule_naf_65": "Fabrication de papier et de carton",
      "intitule_naf": "Fabrication de papier et de carton",
      "code_naf": "1712",
      "intitule_naf_40": "Fabrication de papier et de carton"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3255cb454b3e5ae108b7f556b4d66afaa5777888",
    "fields": {
      "intitule_naf_65": "Fabrication d'emballages en papier",
      "intitule_naf": "Fabrication d'emballages en papier",
      "code_naf": "1721C",
      "intitule_naf_40": "Fabrication d'emballages en papier"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3ef86199bdec8427ad07d5cdc646fe212d375317",
    "fields": {
      "intitule_naf_65": "Fabrication d'emballages en bois",
      "intitule_naf": "Fabrication d'emballages en bois",
      "code_naf": "1624Z",
      "intitule_naf_40": "Fabrication d'emballages en bois"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a0718bcae8fc8df22966f11bcfbbe60571895307",
    "fields": {
      "intitule_naf_65": "Fabrication de chaussures",
      "intitule_naf": "Fabrication de chaussures",
      "code_naf": "152",
      "intitule_naf_40": "Fabrication de chaussures"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8008a34ee5a017529d321906a99cf1f31f228867",
    "fields": {
      "intitule_naf_65": "Fabrication moteurs & turbines sf moteurs d'avions & de véhicules",
      "intitule_naf": "Fabrication de moteurs et turbines, à l'exception des moteurs d'avions et de véhicules",
      "code_naf": "2811Z",
      "intitule_naf_40": "Fab. moteur & turb. sf pr avion & véhic."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a31c280daa9e62d740b6d034944c6f488bb3e8d7",
    "fields": {
      "intitule_naf_65": "Fabrication d'articles céramiques à usage domestique, ornemental",
      "intitule_naf": "Fabrication d'articles céramiques à usage domestique ou ornemental",
      "code_naf": "2341Z",
      "intitule_naf_40": "Fab. art. céramiq. usage domest. & déco."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "71e62585e47e84b1591318a639ea6dc008d2232f",
    "fields": {
      "intitule_naf_65": "Fabrication de machines pour l'extraction ou la construction",
      "intitule_naf": "Fabrication de machines pour l'extraction ou la construction",
      "code_naf": "2892Z",
      "intitule_naf_40": "Fab. machine pour extraction ou constr."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "6d84baf113e688c83f660b531504c462cb9998e8",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres produits minéraux non métalliques",
      "intitule_naf": "Fabrication d'autres produits minéraux non métalliques",
      "code_naf": "23",
      "intitule_naf_40": "Fab. aut. prod. minéraux non métalliques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c469f894acdc02cbfdcbf2d0a2ea884c2038998b",
    "fields": {
      "intitule_naf_65": "Fabrication d'équipements hydrauliques et pneumatiques",
      "intitule_naf": "Fabrication d'équipements hydrauliques et pneumatiques",
      "code_naf": "2812",
      "intitule_naf_40": "Fab. équipement hydraulique & pneumatiq."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "68dc9a2ae9b3211e3edf15a332d18b247e8f56b4",
    "fields": {
      "intitule_naf_65": "Fabrication d'appareils ménagers",
      "intitule_naf": "Fabrication d'appareils ménagers",
      "code_naf": "275",
      "intitule_naf_40": "Fabrication d'appareils ménagers"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c36daaa5d0f875a6e63ed297f6c7d763e63cb86b",
    "fields": {
      "intitule_naf_65": "Production de métaux précieux",
      "intitule_naf": "Production de métaux précieux",
      "code_naf": "2441Z",
      "intitule_naf_40": "Production de métaux précieux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "28ec1a5be3c64633464652909f4725befe579eea",
    "fields": {
      "intitule_naf_65": "Sidérurgie",
      "intitule_naf": "Sidérurgie",
      "code_naf": "2410",
      "intitule_naf_40": "Sidérurgie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "331d7d0d24fb3b2b858c65c36abc765066d61ad7",
    "fields": {
      "intitule_naf_65": "Autres interm. comm. combustibles métaux minéraux prod. chimiques",
      "intitule_naf": "Autres intermédiaires du commerce en combustibles, métaux, minéraux et produits chimiques",
      "code_naf": "4612B",
      "intitule_naf_40": "Aut. ic comb. mét. minér. & prod. chim."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "23e3d17379720976b9031122c2d43fd2b0c9151c",
    "fields": {
      "intitule_naf_65": "Commerce de gros d'articles d'horlogerie et de bijouterie",
      "intitule_naf": "Commerce de gros (commerce interentreprises) d'articles d'horlogerie et de bijouterie",
      "code_naf": "4648Z",
      "intitule_naf_40": "Com. gros artic. horlogerie & bijouterie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a801ca31c64031e6574e3b5fe3387ed0b3943710",
    "fields": {
      "intitule_naf_65": "Construction de réseaux électriques et de télécommunications",
      "intitule_naf": "Construction de réseaux électriques et de télécommunications",
      "code_naf": "4222",
      "intitule_naf_40": "Const. réseaux électriq. & de télécom."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "58f8647210871f8b780dac9994ff4e019d091a48",
    "fields": {
      "intitule_naf_65": "Commerce de gros (commerce interentreprises) de produits surgelés",
      "intitule_naf": "Commerce de gros (commerce interentreprises) de produits surgelés",
      "code_naf": "4639A",
      "intitule_naf_40": "Commerce de gros de produits surgelés"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "97cb40d502705ac3cd7f049f53350f4bb71d60bc",
    "fields": {
      "intitule_naf_65": "Réparation et maintenance d'aéronefs et d'engins spatiaux",
      "intitule_naf": "Réparation et maintenance d'aéronefs et d'engins spatiaux",
      "code_naf": "3316",
      "intitule_naf_40": "Répar. & maint. aéronef & eng. spatiaux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "77929e507c97b9859b1c1280f7741d65f2c89991",
    "fields": {
      "intitule_naf_65": "Commerce de gros de volailles et gibier",
      "intitule_naf": "Commerce de gros (commerce interentreprises) de volailles et gibier",
      "code_naf": "4632C",
      "intitule_naf_40": "Commerce de gros de volailles et gibier"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e94a85856833bcd76ee10c0352623edc23cc35a9",
    "fields": {
      "intitule_naf_65": "Installation de machines et d'équipements industriels",
      "intitule_naf": "Installation de machines et d'équipements industriels",
      "code_naf": "332",
      "intitule_naf_40": "Install. de machines & équipt industriel"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d555b8471ab1c69d439a30c8766d16ad59995104",
    "fields": {
      "intitule_naf_65": "Commerce de gros de produits à base de tabac",
      "intitule_naf": "Commerce de gros de produits à base de tabac",
      "code_naf": "4635",
      "intitule_naf_40": "Comm. gros de produits à base de tabac"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "eaaebd42ded2ebec78bec0f64da4a3b557df9d89",
    "fields": {
      "intitule_naf_65": "Travaux de terrassement courants et travaux préparatoires",
      "intitule_naf": "Travaux de terrassement courants et travaux préparatoires",
      "code_naf": "4312A",
      "intitule_naf_40": "Travaux de terrassement courants"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ee46f3a10be07f3e6aac2a8e738d2c8f4fe1e32b",
    "fields": {
      "intitule_naf_65": "Collecte des déchets",
      "intitule_naf": "Collecte des déchets",
      "code_naf": "381",
      "intitule_naf_40": "Collecte des déchets"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "dc44c725edf73a82052f48f3f8d46d4246d53706",
    "fields": {
      "intitule_naf_65": "Commerce de détail de boissons en magasin spécialisé",
      "intitule_naf": "Commerce de détail de boissons en magasin spécialisé",
      "code_naf": "4725Z",
      "intitule_naf_40": "Com. détail boisson en magasin spéciali."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e8e65594bb3c5ee81896fdffbbc09057c9a93e6a",
    "fields": {
      "intitule_naf_65": "Commerce de gros de matériel électrique",
      "intitule_naf": "Commerce de gros (commerce interentreprises) de matériel électrique",
      "code_naf": "4669A",
      "intitule_naf_40": "Commerce de gros de matériel électrique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "500619c6cefff0d64f6a1a4cb66e8cc79860138d",
    "fields": {
      "intitule_naf_65": "Commerce de gros de mobilier de bureau",
      "intitule_naf": "Commerce de gros (commerce interentreprises) de mobilier de bureau",
      "code_naf": "4665Z",
      "intitule_naf_40": "Commerce de gros de mobilier de bureau"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7014f67e64eb4ece3cce1b03942c5d1f68a7b5b0",
    "fields": {
      "intitule_naf_65": "Commerce de gros de déchets et débris",
      "intitule_naf": "Commerce de gros de déchets et débris",
      "code_naf": "4677",
      "intitule_naf_40": "Commerce de gros de déchets et débris"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "bb7a65abd65962a0ebf5551150ef95e0ee9481ba",
    "fields": {
      "intitule_naf_65": "Entreposage et stockage frigorifique",
      "intitule_naf": "Entreposage et stockage frigorifique",
      "code_naf": "5210A",
      "intitule_naf_40": "Entreposage et stockage frigorifique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1a2e6ebb0e528421a8d7362460782a4c05b7f63e",
    "fields": {
      "intitule_naf_65": "Édition de revues et périodiques",
      "intitule_naf": "Édition de revues et périodiques",
      "code_naf": "5814Z",
      "intitule_naf_40": "Édition de revues et périodiques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "07b0d18cc16208bc61e596169d9aece47accd398",
    "fields": {
      "intitule_naf_65": "Grands magasins",
      "intitule_naf": "Grands magasins",
      "code_naf": "4719A",
      "intitule_naf_40": "Grands magasins"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4741b8d4f4655334958aafc21f4757903a21263e",
    "fields": {
      "intitule_naf_65": "Édition",
      "intitule_naf": "Édition",
      "code_naf": "58",
      "intitule_naf_40": "Édition"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4204e237463fc19a7074c477cbde9d201321e0cd",
    "fields": {
      "intitule_naf_65": "Aut. activités auxiliaires d'assurance et de caisses de retraite",
      "intitule_naf": "Autres activités auxiliaires d'assurance et de caisses de retraite",
      "code_naf": "6629",
      "intitule_naf_40": "Aut. act. aux. assur. & caisse retraite"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "6a45463873f71b84b397a3c60da3d122d37a1966",
    "fields": {
      "intitule_naf_65": "Activités auxiliaires de services financiers et d'assurance",
      "intitule_naf": "Activités auxiliaires de services financiers et d'assurance",
      "code_naf": "66",
      "intitule_naf_40": "Act. auxiliaire sces financ. & d'assur."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5bd92e03271ab7a97bacf744a101b3852367a3f0",
    "fields": {
      "intitule_naf_65": "Fonds de placement et entités financières similaires",
      "intitule_naf": "Fonds de placement et entités financières similaires",
      "code_naf": "643",
      "intitule_naf_40": "Fonds placement & entité financ. simil."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c316c0f5579880a957fb5430ac7f6a8297905188",
    "fields": {
      "intitule_naf_65": "Location de vidéocassettes et disques vidéo",
      "intitule_naf": "Location de vidéocassettes et disques vidéo",
      "code_naf": "7722",
      "intitule_naf_40": "Location de vidéocassette & disque vidéo"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ea0c6b5fd6388a891a56b148901b7d40cce394db",
    "fields": {
      "intitule_naf_65": "Activités immobilières pour compte de tiers",
      "intitule_naf": "Activités immobilières pour compte de tiers",
      "code_naf": "683",
      "intitule_naf_40": "Activité immobilière pour cpte de tiers"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3fc1219810eb2b72c22481310f9f23334c132183",
    "fields": {
      "intitule_naf_65": "Autres activités d'édition",
      "intitule_naf": "Autres activités d'édition",
      "code_naf": "5819Z",
      "intitule_naf_40": "Autres activités d'édition"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "faba72719a24387c9d6162a16522e3314f181cfe",
    "fields": {
      "intitule_naf_65": "Activités liées à l'emploi",
      "intitule_naf": "Activités liées à l'emploi",
      "code_naf": "78",
      "intitule_naf_40": "Activités liées à l'emploi"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1d1fb066349752f40d3ddea6eb9ef4f1ba72c9c1",
    "fields": {
      "intitule_naf_65": "Activités d'architecture",
      "intitule_naf": "Activités d'architecture",
      "code_naf": "7111",
      "intitule_naf_40": "Activités d'architecture"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "66bc0d921601664d0af17c128356afc3e25b2f94",
    "fields": {
      "intitule_naf_65": "Activités juridiques",
      "intitule_naf": "Activités juridiques",
      "code_naf": "6910Z",
      "intitule_naf_40": "Activités juridiques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "76f471a910a9ea511f3e4cffa69803de01faff7b",
    "fields": {
      "intitule_naf_65": "Caisses de retraite",
      "intitule_naf": "Caisses de retraite",
      "code_naf": "653",
      "intitule_naf_40": "Caisses de retraite"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b38edea2f49b7e98311b1cd4f8e83ccbf0755a11",
    "fields": {
      "intitule_naf_65": "Héberg. médicalisé adultes handicapés & autre héberg. médicalisé",
      "intitule_naf": "Hébergement médicalisé pour adultes handicapés et autre hébergement médicalisé",
      "code_naf": "8710C",
      "intitule_naf_40": "Hébrgt médic. adul. hand. & aut. ht méd."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "01e616f317d5bc9745cc61437eec4373b6a0e9ff",
    "fields": {
      "intitule_naf_65": "Activités des infirmiers et des sages-femmes",
      "intitule_naf": "Activités des infirmiers et des sages-femmes",
      "code_naf": "8690D",
      "intitule_naf_40": "Act. des infirmiers et des sages-femmes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5a2a842b3ed8cc5a69fe0eeb9039b99d02122987",
    "fields": {
      "intitule_naf_65": "Activité des médecins spécialistes",
      "intitule_naf": "Activité des médecins spécialistes",
      "code_naf": "8622",
      "intitule_naf_40": "Activité des médecins spécialistes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "6876b86d44724c0dbb5d856a1b95c8a5d06230a1",
    "fields": {
      "intitule_naf_65": "Distribution sociale de revenus",
      "intitule_naf": "Distribution sociale de revenus",
      "code_naf": "8430C",
      "intitule_naf_40": "Distribution sociale de revenus"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f0f66970391a21c5bbe34b010b8e7c8c31f9a830",
    "fields": {
      "intitule_naf_65": "Enseignement pré-primaire",
      "intitule_naf": "Enseignement pré-primaire",
      "code_naf": "8510Z",
      "intitule_naf_40": "Enseignement pré-primaire"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "19adaf284de499b2579de3cc2e5ce488183b3493",
    "fields": {
      "intitule_naf_65": "Enseignement pré-primaire",
      "intitule_naf": "Enseignement pré-primaire",
      "code_naf": "851",
      "intitule_naf_40": "Enseignement pré-primaire"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9b7d360094f5b8376ca5633bab67d6be064ae27e",
    "fields": {
      "intitule_naf_65": "Activités hospitalières",
      "intitule_naf": "Activités hospitalières",
      "code_naf": "8610Z",
      "intitule_naf_40": "Activités hospitalières"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "156c3b75cd38a6a697ebb123fabe7f2197dca7d7",
    "fields": {
      "intitule_naf_65": "Activités hospitalières",
      "intitule_naf": "Activités hospitalières",
      "code_naf": "861",
      "intitule_naf_40": "Activités hospitalières"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "6c718f7ca6497c4212971706e3b8c8891ae9ee5d",
    "fields": {
      "intitule_naf_65": "Activités des ménages  : employeurs de personnel domestique",
      "intitule_naf": "Activités des ménages en tant qu'employeurs de personnel domestique",
      "code_naf": "9700",
      "intitule_naf_40": "Act. ménage: empl. de person. domestique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d2607312d560b367653d6f559a58d160539d3ad6",
    "fields": {
      "intitule_naf_65": "Autres activités récréatives et de loisirs",
      "intitule_naf": "Autres activités récréatives et de loisirs",
      "code_naf": "9329",
      "intitule_naf_40": "Autres activités récréative & de loisirs"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3bfecf6e63d6eba94198aef157496d528194e1aa",
    "fields": {
      "intitule_naf_65": "Réparation de chaussures et d'articles en cuir",
      "intitule_naf": "Réparation de chaussures et d'articles en cuir",
      "code_naf": "9523",
      "intitule_naf_40": "Réparation chaussures & articles en cuir"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ad7a328c492ee2757b7d440c9a12d1c96b5244c0",
    "fields": {
      "intitule_naf_65": "Organisation de jeux de hasard et d'argent",
      "intitule_naf": "Organisation de jeux de hasard et d'argent",
      "code_naf": "9200Z",
      "intitule_naf_40": "Organisation jeux de hasard & d'argent"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c92a102d6d716d9ed242f9066e798d13ee8e083e",
    "fields": {
      "intitule_naf_65": "Autres activités d'hébergement social",
      "intitule_naf": "Autres activités d'hébergement social",
      "code_naf": "879",
      "intitule_naf_40": "Autres activités d'hébergement social"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "592dc9a95bf1cc74002e298ba3ebc6ff1d2e0892",
    "fields": {
      "intitule_naf_65": "Culture de céréales (sf riz) légumineuses, graines oléagineuses",
      "intitule_naf": "Culture de céréales (à l'exception du riz), de légumineuses et de graines oléagineuses",
      "code_naf": "0111Z",
      "intitule_naf_40": "Cult céréale, légumineuse, graine oléag."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "254684782212d73374d459c9f638f2a38f5b2825",
    "fields": {
      "intitule_naf_65": "Récolte produits forestiers non ligneux poussant à l'état sauvage",
      "intitule_naf": "Récolte de produits forestiers non ligneux poussant à l'état sauvage",
      "code_naf": "23",
      "intitule_naf_40": "Récolte prodts forestiers non ligneux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5c20cfc9132511872e5af6399ec5ea88d1418d37",
    "fields": {
      "intitule_naf_65": "Fabrication de savons, détergents et produits d'entretien",
      "intitule_naf": "Fabrication de savons, détergents et produits d'entretien",
      "code_naf": "2041Z",
      "intitule_naf_40": "Fab. savon, détergent & prod. entretien"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "398d080ff9ccd473137a4cf32c9e1468ea19b793",
    "fields": {
      "intitule_naf_65": "Fabrication de produits pharmaceutiques de base",
      "intitule_naf": "Fabrication de produits pharmaceutiques de base",
      "code_naf": "2110",
      "intitule_naf_40": "Fab. de produits pharmaceutiques de base"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1eef65470c82a3530aca34a26f35ff1bcd51140d",
    "fields": {
      "intitule_naf_65": "Services de soutien aux industries extractives",
      "intitule_naf": "Services de soutien aux industries extractives",
      "code_naf": "09",
      "intitule_naf_40": "Sces de soutien aux indust. extractives"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d4ea4f837d8f9aef59f27798f7a70f1cb42895c8",
    "fields": {
      "intitule_naf_65": "Préparation de produits à base de viande",
      "intitule_naf": "Préparation de produits à base de viande",
      "code_naf": "1013",
      "intitule_naf_40": "Préparation de produits à base de viande"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ccfb0b470c50b97b36713fce5761b0ccab7f5472",
    "fields": {
      "intitule_naf_65": "Fabrication de préparations pharmaceutiques",
      "intitule_naf": "Fabrication de préparations pharmaceutiques",
      "code_naf": "2120Z",
      "intitule_naf_40": "Fabric. de préparations pharmaceutiques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c5c2fe25907df2010f8b3e3bd16993798ef63f74",
    "fields": {
      "intitule_naf_65": "Fabrication de matières plastiques de base",
      "intitule_naf": "Fabrication de matières plastiques de base",
      "code_naf": "2016Z",
      "intitule_naf_40": "Fabric. de matières plastiques de base"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4f91606ea19aa7ca0418290bea820f96f511b9ac",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres produits chimiques",
      "intitule_naf": "Fabrication d'autres produits chimiques",
      "code_naf": "205",
      "intitule_naf_40": "Fabrication d'autres produits chimiques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b427fcb7221389dd50ccc961db2d2ebcc40baa9d",
    "fields": {
      "intitule_naf_65": "Extraction de pétrole brut",
      "intitule_naf": "Extraction de pétrole brut",
      "code_naf": "61",
      "intitule_naf_40": "Extraction de pétrole brut"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "fc878b5e33930442c20366f2e5d48bd2e040eb44",
    "fields": {
      "intitule_naf_65": "Charcuterie",
      "intitule_naf": "Charcuterie",
      "code_naf": "1013B",
      "intitule_naf_40": "Charcuterie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a2f98a003fa0fbc51a2120f89de6f3b999b09f45",
    "fields": {
      "intitule_naf_65": "Fabric. générateurs vapeur sf chaudières pour chauffage central",
      "intitule_naf": "Fabrication de générateurs de vapeur, à l'exception des chaudières pour le chauffage central",
      "code_naf": "2530Z",
      "intitule_naf_40": "Fab. générat. vapeur sf pr chauff. ctral"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "bc1747d7ce2eaeda83de0408e6a8ed31654e20b0",
    "fields": {
      "intitule_naf_65": "Fabric. générateurs vapeur sf chaudières pour chauffage central",
      "intitule_naf": "Fabrication de générateurs de vapeur, à l'exception des chaudières pour le chauffage central",
      "code_naf": "253",
      "intitule_naf_40": "Fab. générat. vapeur sf pr chauff. ctral"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "53a803c992f4a767bd0acb46cd9bce5604bde59b",
    "fields": {
      "intitule_naf_65": "Fabrication de réservoirs, citernes et conteneurs métalliques",
      "intitule_naf": "Fabrication de réservoirs, citernes et conteneurs métalliques",
      "code_naf": "252",
      "intitule_naf_40": "Fab. réservr, citerne & conteneur métal."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0a20573a3596bf611484e17efef9973cf5ef9126",
    "fields": {
      "intitule_naf_65": "Fabrication de piles et d'accumulateurs électriques",
      "intitule_naf": "Fabrication de piles et d'accumulateurs électriques",
      "code_naf": "2720",
      "intitule_naf_40": "Fabric. pile & accumulateur électrique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "97361e7fa0e43eb78e7b54de76f6a09aa875b066",
    "fields": {
      "intitule_naf_65": "Fabrication d'appareils d'éclairage électrique",
      "intitule_naf": "Fabrication d'appareils d'éclairage électrique",
      "code_naf": "2740",
      "intitule_naf_40": "Fabric. appareils d'éclairage électrique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ea1e22e6e0098cf07a024c3f21811d00c4ea4703",
    "fields": {
      "intitule_naf_65": "Laminage à froid de feuillards",
      "intitule_naf": "Laminage à froid de feuillards",
      "code_naf": "2432Z",
      "intitule_naf_40": "Laminage à froid de feuillards"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7153120b2261056eb2b16eb976ffd29ea472c021",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres outillages",
      "intitule_naf": "Fabrication d'autres outillages",
      "code_naf": "2573B",
      "intitule_naf_40": "Fabrication d'autres outillages"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5f1748e0e3b61f459c33341923328443f11cfa82",
    "fields": {
      "intitule_naf_65": "Métallurgie de l'aluminium",
      "intitule_naf": "Métallurgie de l'aluminium",
      "code_naf": "2442",
      "intitule_naf_40": "Métallurgie de l'aluminium"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "cac23f027a293d5268d7c5cf2a562fead6958e9a",
    "fields": {
      "intitule_naf_65": "Interm. spécialisés commerce d'autres produits spécifiques",
      "intitule_naf": "Intermédiaires spécialisés dans le commerce d'autres produits spécifiques",
      "code_naf": "4618Z",
      "intitule_naf_40": "Int. spécialis. comm. aut. prod. spécif."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "341f26eb19175535ea30e07aaffb8d8b5edcce7f",
    "fields": {
      "intitule_naf_65": "Réparation et maintenance d'autres équipements de transport",
      "intitule_naf": "Réparation et maintenance d'autres équipements de transport",
      "code_naf": "3317",
      "intitule_naf_40": "Répar. & maint. d'aut. équipt transport"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f3b698e90116a2b4a6ffcde04da1013abcca77a1",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres équipements de transport n.c.a.",
      "intitule_naf": "Fabrication d'autres équipements de transport n.c.a.",
      "code_naf": "3099Z",
      "intitule_naf_40": "Fab. aut. équipement de transport n.c.a."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4f197d9d9d5d11844e59b014b1c049e9f51ccabf",
    "fields": {
      "intitule_naf_65": "Fabrication de machines diverses d'usage général",
      "intitule_naf": "Fabrication de machines diverses d'usage général",
      "code_naf": "2829",
      "intitule_naf_40": "Fab. machines diverses d'usage général"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "605c5ff3125a3e529287e00f40576e153a0409cd",
    "fields": {
      "intitule_naf_65": "Fabrication de carrosseries et remorques",
      "intitule_naf": "Fabrication de carrosseries et remorques",
      "code_naf": "292",
      "intitule_naf_40": "Fabrication de carrosseries et remorques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "28ac11c9c9873408bc8e0bca8be066573183c3f0",
    "fields": {
      "intitule_naf_65": "Captage, traitement et distribution d'eau",
      "intitule_naf": "Captage, traitement et distribution d'eau",
      "code_naf": "3600Z",
      "intitule_naf_40": "Captage, traitement & distribution d'eau"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e5fe9ec10591b5ad93d8868e77732f5196dbfda3",
    "fields": {
      "intitule_naf_65": "Construction aéronautique et spatiale",
      "intitule_naf": "Construction aéronautique et spatiale",
      "code_naf": "3030Z",
      "intitule_naf_40": "Construction aéronautique et spatiale"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "bc95e8a7954acd811e9a354d25b999a101bb3d49",
    "fields": {
      "intitule_naf_65": "Collecte des déchets dangereux",
      "intitule_naf": "Collecte des déchets dangereux",
      "code_naf": "3812Z",
      "intitule_naf_40": "Collecte des déchets dangereux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7199a261a1d02b18251eb2e08f1a5d6cfd2402bc",
    "fields": {
      "intitule_naf_65": "Collecte des déchets dangereux",
      "intitule_naf": "Collecte des déchets dangereux",
      "code_naf": "3812",
      "intitule_naf_40": "Collecte des déchets dangereux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "787dcfbb9ab5578d1ad97f83ef9ebf70e829ad22",
    "fields": {
      "intitule_naf_65": "Travaux de finition",
      "intitule_naf": "Travaux de finition",
      "code_naf": "433",
      "intitule_naf_40": "Travaux de finition"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "64690eeef35d4177b435f1ce21ded8b2f80bfdc3",
    "fields": {
      "intitule_naf_65": "Com. gros produits laitiers, oeufs, huiles & mat. grasses comest.",
      "intitule_naf": "Commerce de gros (commerce interentreprises) de produits laitiers, œufs, huiles et matières grasses comestibles",
      "code_naf": "4633Z",
      "intitule_naf_40": "Com. gros prod. laitier oeuf & mat. grse"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4d635a86f4164382a3377ce51222aba22a26ab79",
    "fields": {
      "intitule_naf_65": "Comm. détail, sf automobiles et motocycles",
      "intitule_naf": "Commerce de détail, à l'exception des automobiles et des motocycles",
      "code_naf": "47",
      "intitule_naf_40": "Com. détail, sf automobiles & motocycles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1885aeb9c0de89cb8ded06dfd6e3a5528d4de3c6",
    "fields": {
      "intitule_naf_65": "Commerce de gros d'articles d'horlogerie et de bijouterie",
      "intitule_naf": "Commerce de gros d'articles d'horlogerie et de bijouterie",
      "code_naf": "4648",
      "intitule_naf_40": "Com. gros artic. horlogerie & bijouterie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f25109d1fa418034bc1f21fb5990b4db9077e11a",
    "fields": {
      "intitule_naf_65": "Commerces de détail de charbons et combustibles",
      "intitule_naf": "Commerces de détail de charbons et combustibles",
      "code_naf": "4778B",
      "intitule_naf_40": "Comm. détail de charbons & combustibles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ce55bf95c7fdf1fbcec2594ae646d6ea72dfaeeb",
    "fields": {
      "intitule_naf_65": "Edition de chaînes thématiques",
      "intitule_naf": "Edition de chaînes thématiques",
      "code_naf": "6020B",
      "intitule_naf_40": "Edition de chaînes thématiques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "35c74795b8a31c076409fbd9ccda8d75b09a7e27",
    "fields": {
      "intitule_naf_65": "Télécommunications filaires",
      "intitule_naf": "Télécommunications filaires",
      "code_naf": "6110",
      "intitule_naf_40": "Télécommunications filaires"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3bdec810b54242b9f5a54d038e558c64637a6e95",
    "fields": {
      "intitule_naf_65": "Débits de boissons",
      "intitule_naf": "Débits de boissons",
      "code_naf": "563",
      "intitule_naf_40": "Débits de boissons"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "33be4020b847a0d1daa884ec0f19d5fe0a4f042a",
    "fields": {
      "intitule_naf_65": "Transports spatiaux",
      "intitule_naf": "Transports spatiaux",
      "code_naf": "5122",
      "intitule_naf_40": "Transports spatiaux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1037c9d78a309d1981beeaff3e38f229e051e70d",
    "fields": {
      "intitule_naf_65": "Assurance",
      "intitule_naf": "Assurance",
      "code_naf": "651",
      "intitule_naf_40": "Assurance"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ab59279838d40da1c2efc2c14e60e6de6f7f9b91",
    "fields": {
      "intitule_naf_65": "Location et location-bail machines, équipements et biens divers",
      "intitule_naf": "Location et location-bail d'autres machines, équipements et biens matériels n.c.a.",
      "code_naf": "7739",
      "intitule_naf_40": "Loc. & loc.-bail machi. éqpt & bien div."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "308b8789b081abeb52505a8c3b9957ae0f994570",
    "fields": {
      "intitule_naf_65": "Location et location-bail de machines et équipements agricoles",
      "intitule_naf": "Location et location-bail de machines et équipements agricoles",
      "code_naf": "7731Z",
      "intitule_naf_40": "Loc. & loc.-bail machine & éqpt agricole"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2e91433621c292fd99d0b75797774203a86d6809",
    "fields": {
      "intitule_naf_65": "Activités d'ordre public et de sécurité",
      "intitule_naf": "Activités d'ordre public et de sécurité",
      "code_naf": "8424",
      "intitule_naf_40": "Activités d'ordre public et de sécurité"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "011cdb34936d307cfae581d96c9ef9309f3070a7",
    "fields": {
      "intitule_naf_65": "Recherche-développement en biotechnologie",
      "intitule_naf": "Recherche-développement en biotechnologie",
      "code_naf": "7211Z",
      "intitule_naf_40": "Recherche-développemnt en biotechnologie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4e3c4e6b78ee5ac3f1d448942b76939d858c43b6",
    "fields": {
      "intitule_naf_65": "Activités des agences de travail temporaire",
      "intitule_naf": "Activités des agences de travail temporaire",
      "code_naf": "782",
      "intitule_naf_40": "Activ. des agences de travail temporaire"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "48934828fa51fb7708acd301d425fbeaac47a14a",
    "fields": {
      "intitule_naf_65": "Études de marché et sondages",
      "intitule_naf": "Études de marché et sondages",
      "code_naf": "7320Z",
      "intitule_naf_40": "Études de marché et sondages"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4acd9dc9d8a3a64c81c6ddd9f552c47e041a9dac",
    "fields": {
      "intitule_naf_65": "Gestion de fonds",
      "intitule_naf": "Gestion de fonds",
      "code_naf": "6630Z",
      "intitule_naf_40": "Gestion de fonds"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "6c2e740336c37e8b36def2633d8b6200c0aa3d61",
    "fields": {
      "intitule_naf_65": "Défense",
      "intitule_naf": "Défense",
      "code_naf": "8422Z",
      "intitule_naf_40": "Défense"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "96e6adb6c91759187fdd58afb18a64fb90e361ef",
    "fields": {
      "intitule_naf_65": "Réparation de produits électroniques grand public",
      "intitule_naf": "Réparation de produits électroniques grand public",
      "code_naf": "9521",
      "intitule_naf_40": "Réparation prdts électroniq. grd public"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9df24ca0acc8d9d71022cf849c0a553adce2680a",
    "fields": {
      "intitule_naf_65": "Hébergement social pour personnes âgées",
      "intitule_naf": "Hébergement social pour personnes âgées",
      "code_naf": "8730A",
      "intitule_naf_40": "Hébergement social pour personnes âgées"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5e2391ae150eb2ba7f65f45a96d2a372c67ff7c7",
    "fields": {
      "intitule_naf_65": "Activités de soutien à l'enseignement",
      "intitule_naf": "Activités de soutien à l'enseignement",
      "code_naf": "8560",
      "intitule_naf_40": "Activités de soutien à l'enseignement"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "caac50ab32a084bc862657af4530e5858220c710",
    "fields": {
      "intitule_naf_65": "Autre création artistique",
      "intitule_naf": "Autre création artistique",
      "code_naf": "9003B",
      "intitule_naf_40": "Autre création artistique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "20ee79ee84eba85e20ef29ac79759b99ef99696e",
    "fields": {
      "intitule_naf_65": "Activités hospitalières",
      "intitule_naf": "Activités hospitalières",
      "code_naf": "8610",
      "intitule_naf_40": "Activités hospitalières"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c09b703d276e8e536ea963419ec12dba0d0f3a7b",
    "fields": {
      "intitule_naf_65": "Enseignement primaire",
      "intitule_naf": "Enseignement primaire",
      "code_naf": "852",
      "intitule_naf_40": "Enseignement primaire"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0944a63480bf80cf3fb4ab681cb9ce3f2edc3b1f",
    "fields": {
      "intitule_naf_65": "Pratique dentaire",
      "intitule_naf": "Pratique dentaire",
      "code_naf": "8623Z",
      "intitule_naf_40": "Pratique dentaire"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5b96e866bb26106d9d83a6b8f8ab5b852f680201",
    "fields": {
      "intitule_naf_65": "Comm. de gros d'ordinat., d'éqpts informatiq. périph. & logiciels",
      "intitule_naf": "Commerce de gros d'ordinateurs, d'équipements informatiques périphériques et de logiciels",
      "code_naf": "4651",
      "intitule_naf_40": "Comm. gros ordi. éqpt périph. & logiciel"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "02c8b7192db69431f3a007be91d186c34a37c8b7",
    "fields": {
      "intitule_naf_65": "Commerce de gros de parfumerie et de produits de beauté",
      "intitule_naf": "Commerce de gros (commerce interentreprises) de parfumerie et de produits de beauté",
      "code_naf": "4645Z",
      "intitule_naf_40": "Com. gros parfumerie & produit de beauté"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ca6e39379da1ced5c1aef0f9520666db793d8636",
    "fields": {
      "intitule_naf_65": "Commerce de gros de café, thé, cacao et épices",
      "intitule_naf": "Commerce de gros (commerce interentreprises) de café, thé, cacao et épices",
      "code_naf": "4637Z",
      "intitule_naf_40": "Comm. gros de café, thé, cacao et épices"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "de3a7e6dc853b4fe1b562c835f9d3f0f9b87ddc0",
    "fields": {
      "intitule_naf_65": "Commerce de gros de produits pharmaceutiques",
      "intitule_naf": "Commerce de gros (commerce interentreprises) de produits pharmaceutiques",
      "code_naf": "4646Z",
      "intitule_naf_40": "Comm. gros de produits pharmaceutiques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3c69d11a8a5abcc07714b64987b3aed5d6826dde",
    "fields": {
      "intitule_naf_65": "Construction de routes et de voies ferrées",
      "intitule_naf": "Construction de routes et de voies ferrées",
      "code_naf": "421",
      "intitule_naf_40": "Construction de route & de voies ferrées"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8c66a9124e9b41d91229c2dd76ea683f88597252",
    "fields": {
      "intitule_naf_65": "Commerce de gros (commerce interentreprises) de quincaillerie",
      "intitule_naf": "Commerce de gros (commerce interentreprises) de quincaillerie",
      "code_naf": "4674A",
      "intitule_naf_40": "Commerce de gros de quincaillerie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c2f33c82c9c02de906e04ccff8b6cf5d965e9a33",
    "fields": {
      "intitule_naf_65": "Construction de réseaux et de lignes",
      "intitule_naf": "Construction de réseaux et de lignes",
      "code_naf": "422",
      "intitule_naf_40": "Construction de réseaux et de lignes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f7e6c068182206f8bb4046fda4677c62aa6645d0",
    "fields": {
      "intitule_naf_65": "Réparation et maintenance navale",
      "intitule_naf": "Réparation et maintenance navale",
      "code_naf": "3315",
      "intitule_naf_40": "Réparation et maintenance navale"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7f97519ae3fe7ebaa79486ab960359a467c703e8",
    "fields": {
      "intitule_naf_65": "Supports juridiques de programmes",
      "intitule_naf": "Supports juridiques de programmes",
      "code_naf": "4110D",
      "intitule_naf_40": "Supports juridiques de programmes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e2049a621df18799d9a61846b48b5c4465a34f96",
    "fields": {
      "intitule_naf_65": "Récupération de déchets triés",
      "intitule_naf": "Récupération de déchets triés",
      "code_naf": "3832",
      "intitule_naf_40": "Récupération de déchets triés"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "96a3cedd0924cfee1777cf8fd68acdb52f42d035",
    "fields": {
      "intitule_naf_65": "Comm. dét. fleurs, plantes, etc, animaux de cie et leurs aliments",
      "intitule_naf": "Commerce de détail de fleurs, plantes, graines, engrais, animaux de compagnie et aliments pour ces animaux en magasin spécialisé",
      "code_naf": "4776",
      "intitule_naf_40": "Com. dét. fleur plante anim. cie + alim."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d548ec7f859eeb1c2bd6931f62444052c51956d4",
    "fields": {
      "intitule_naf_65": "Comm. détail en magasin non spécialisé à prédominance alimentaire",
      "intitule_naf": "Commerce de détail en magasin non spécialisé à prédominance alimentaire",
      "code_naf": "4711",
      "intitule_naf_40": "Comm. dét. mag. non spéc. à prédo. alim."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f45f6a928552e513a9fcd984b3e980a9b7769a34",
    "fields": {
      "intitule_naf_65": "Comm. détail textiles habillt & chaussures s/éventaires & marchés",
      "intitule_naf": "Commerce de détail de textiles, d'habillement et de chaussures sur éventaires et marchés",
      "code_naf": "4782Z",
      "intitule_naf_40": "Cd textiles habillt & chauss. s/marchés"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5e938a0f6603272dbbf88457e1f54346aeeeaaf2",
    "fields": {
      "intitule_naf_65": "Comm. détail viandes & produits à base de viande (magas. spéc.)",
      "intitule_naf": "Commerce de détail de viandes et de produits à base de viande en magasin spécialisé",
      "code_naf": "4722",
      "intitule_naf_40": "Com. dét. viande & prdt avec viande (ms)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2e89a09e46a212ebaf0ff5db6eb264d245731c07",
    "fields": {
      "intitule_naf_65": "Commerce de détail appareils électroménagers (magasin spécialisé)",
      "intitule_naf": "Commerce de détail d'appareils électroménagers en magasin spécialisé",
      "code_naf": "4754Z",
      "intitule_naf_40": "Comm. dét.  appareil électroménager (ms)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9b78aae8d94f3374fe5f68eef692c6411d5cc6a2",
    "fields": {
      "intitule_naf_65": "Commerce de détail d'articles de sport en magasin spécialisé",
      "intitule_naf": "Commerce de détail d'articles de sport en magasin spécialisé",
      "code_naf": "4764",
      "intitule_naf_40": "Com. dét. articles de sport en mag. spé."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3d1e1f768da3ca81de6a7f0c6f6259cfcf9b84d4",
    "fields": {
      "intitule_naf_65": "Commerce de détail alimentaire sur éventaires et marchés",
      "intitule_naf": "Commerce de détail alimentaire sur éventaires et marchés",
      "code_naf": "4781",
      "intitule_naf_40": "Cd alimentaire sur éventaire & marché"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "6c827479fad952d4faf2a6d873588198e806dbbb",
    "fields": {
      "intitule_naf_65": "Commerces de détail d'optique",
      "intitule_naf": "Commerces de détail d'optique",
      "code_naf": "4778A",
      "intitule_naf_40": "Commerces de détail d'optique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c8802c95ff2f55c5afc34c4b2a25d0c99520b210",
    "fields": {
      "intitule_naf_65": "Activ. indifférenciées ménages : produc. services (usage propre)",
      "intitule_naf": "Activités indifférenciées des ménages en tant que producteurs de services pour usage propre",
      "code_naf": "982",
      "intitule_naf_40": "Act. ménage : prod. serv. (usage propre)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0b854bda1adf2eec8e0ac9d7be44f65540643f25",
    "fields": {
      "intitule_naf_65": "Activ. indifférenciées ménages : produc. biens (usage propre)",
      "intitule_naf": "Activités indifférenciées des ménages en tant que producteurs de biens pour usage propre",
      "code_naf": "981",
      "intitule_naf_40": "Act. ménage : prod. biens (usage propre)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c0202b37b141e97c2bbfbbab71bffc28a00a706a",
    "fields": {
      "intitule_naf_65": "Activ. soutien à l'agriculture & trt primaire des récoltes",
      "intitule_naf": "Activités de soutien à l'agriculture et traitement primaire des récoltes",
      "code_naf": "16",
      "intitule_naf_40": "Act. soutien agr. & trait. prim. récolt."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "39542483c5756380fc29f9dba4f8abaeabc126f4",
    "fields": {
      "intitule_naf_65": "Transform. & conserv. poisson, crustacés & mollusques",
      "intitule_naf": "Transformation et conservation de poisson, de crustacés et de mollusques",
      "code_naf": "102",
      "intitule_naf_40": "Transf. & conserv. poisson, crust., etc."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3f3f32a05bda61b560738a44f73a32141c8b7b48",
    "fields": {
      "intitule_naf_65": "Fabrication d'aliments homogénéisés et diététiques",
      "intitule_naf": "Fabrication d'aliments homogénéisés et diététiques",
      "code_naf": "1086",
      "intitule_naf_40": "Fab. d'aliment homogénéisé & diététique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e49a9a1325dab4ed8491bbb169b2e6dd4600d871",
    "fields": {
      "intitule_naf_65": "Extraction des minéraux chimiques et d'engrais minéraux",
      "intitule_naf": "Extraction des minéraux chimiques et d'engrais minéraux",
      "code_naf": "0891Z",
      "intitule_naf_40": "Extr. minéraux chimiq. & engrais min."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f7e306699a8958eb1f6ef6cc3e440e88a2485f4e",
    "fields": {
      "intitule_naf_65": "Culture de fruits à pépins et à noyau",
      "intitule_naf": "Culture de fruits à pépins et à noyau",
      "code_naf": "124",
      "intitule_naf_40": "Culture de fruits à pépins et à noyau"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f4940096b6e46fe642194b44d2367f41436b1d04",
    "fields": {
      "intitule_naf_65": "Culture de plantes à fibres",
      "intitule_naf": "Culture de plantes à fibres",
      "code_naf": "0116Z",
      "intitule_naf_40": "Culture de plantes à fibres"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b4840d091e578d632c59f1e70f866eeadbd72810",
    "fields": {
      "intitule_naf_65": "Extraction de tourbe",
      "intitule_naf": "Extraction de tourbe",
      "code_naf": "0892Z",
      "intitule_naf_40": "Extraction de tourbe"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "50229e19e69bb3188ee2cf286513370f2fc0d47d",
    "fields": {
      "intitule_naf_65": "Fabrication plaques, feuilles, tubes et profilés en plastique",
      "intitule_naf": "Fabrication de plaques, feuilles, tubes et profilés en matières plastiques",
      "code_naf": "2221",
      "intitule_naf_40": "Fab. plaque, feuille, tube,  etc. plast."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "54b8dd3d4584187dbf0b7072a83c8b06e79bf30a",
    "fields": {
      "intitule_naf_65": "Fabrication d'ouvrages en béton, en ciment ou en plâtre",
      "intitule_naf": "Fabrication d'ouvrages en béton, en ciment ou en plâtre",
      "code_naf": "236",
      "intitule_naf_40": "Fabric. ouvrage en béton, ciment, plâtre"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3ec88c10378042f2f6c0a6e3fc8c00c3a5c8a83e",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres articles à mailles",
      "intitule_naf": "Fabrication d'autres articles à mailles",
      "code_naf": "1439Z",
      "intitule_naf_40": "Fabrication d'autres articles à mailles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "30539c610fb470da0218a863b331b5789cd6ea47",
    "fields": {
      "intitule_naf_65": "Fabrication de vins effervescents",
      "intitule_naf": "Fabrication de vins effervescents",
      "code_naf": "1102A",
      "intitule_naf_40": "Fabrication de vins effervescents"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "80116f653a297717a2a81fa39e213d299e522fff",
    "fields": {
      "intitule_naf_65": "Fabrication d'étoffes à mailles",
      "intitule_naf": "Fabrication d'étoffes à mailles",
      "code_naf": "1391",
      "intitule_naf_40": "Fabrication d'étoffes à mailles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "138ecbb866a9629f8773e05b65c11dd846d6bbb5",
    "fields": {
      "intitule_naf_65": "Fabrication de fibres de verre",
      "intitule_naf": "Fabrication de fibres de verre",
      "code_naf": "2314",
      "intitule_naf_40": "Fabrication de fibres de verre"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5dbc0aa3be9e25e17d6a29f8ab05ff0f1d43ee89",
    "fields": {
      "intitule_naf_65": "Industrie de l'habillement",
      "intitule_naf": "Industrie de l'habillement",
      "code_naf": "14",
      "intitule_naf_40": "Industrie de l'habillement"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "283c552949147d2858b4179c6876daefc4425a57",
    "fields": {
      "intitule_naf_65": "Fabrication de colles",
      "intitule_naf": "Fabrication de colles",
      "code_naf": "2052Z",
      "intitule_naf_40": "Fabrication de colles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "33ef38e0191558ad9a629ae181c90c4109360553",
    "fields": {
      "intitule_naf_65": "Fabrication de ciment",
      "intitule_naf": "Fabrication de ciment",
      "code_naf": "2351Z",
      "intitule_naf_40": "Fabrication de ciment"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "fe3d6f92d0eb94d4df38d97fd73c3dd3a7244f1e",
    "fields": {
      "intitule_naf_65": "Vinification",
      "intitule_naf": "Vinification",
      "code_naf": "1102B",
      "intitule_naf_40": "Vinification"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "73ba7ee91e42b0b088141dbcd514f1ffc7dd06ea",
    "fields": {
      "intitule_naf_65": "Fabrication autres meubles & industries connexes de l'ameublement",
      "intitule_naf": "Fabrication d'autres meubles et industries connexes de l'ameublement",
      "code_naf": "3109B",
      "intitule_naf_40": "Fab. aut. meub. & ind. connexe ameublmnt"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "6332791df03fa5bfe82f02cd7cdd7f1466f69b57",
    "fields": {
      "intitule_naf_65": "Fabrication d'ordinateurs et d'équipements périphériques",
      "intitule_naf": "Fabrication d'ordinateurs et d'équipements périphériques",
      "code_naf": "2620Z",
      "intitule_naf_40": "Fab. ordinateur & équipement périphériq."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "720f1f9bf19c51ed2ed8251d0f270496445b3f10",
    "fields": {
      "intitule_naf_65": "Fabrication d'articles de joaillerie et bijouterie",
      "intitule_naf": "Fabrication d'articles de joaillerie et bijouterie",
      "code_naf": "3212",
      "intitule_naf_40": "Fab. article de joaillerie et bijouterie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "58e3aaee77be46877973ede3279bc91538b0abd9",
    "fields": {
      "intitule_naf_65": "Fabrication de machines pour l'industrie agro-alimentaire",
      "intitule_naf": "Fabrication de machines pour l'industrie agro-alimentaire",
      "code_naf": "2893",
      "intitule_naf_40": "Fab. machine pour l'indus. agro-aliment."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9bbe72bf6595b55e90812e2188e2063c9660c287",
    "fields": {
      "intitule_naf_65": "Forge, emboutissage, estampage; métallurgie des poudres",
      "intitule_naf": "Forge, emboutissage, estampage ; métallurgie des poudres",
      "code_naf": "2550",
      "intitule_naf_40": "Forge, etc.; métallurgie des poudres"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "dbf92a5b15ec37164ac6070d9c4060452f0337b8",
    "fields": {
      "intitule_naf_65": "Métallurgie des autres métaux non ferreux",
      "intitule_naf": "Métallurgie des autres métaux non ferreux",
      "code_naf": "2445",
      "intitule_naf_40": "Métallurgie autres métaux non ferreux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "242d9bbfe442a902f288292a1abbc983c6f29b88",
    "fields": {
      "intitule_naf_65": "Fabrication d'appareils électroménagers",
      "intitule_naf": "Fabrication d'appareils électroménagers",
      "code_naf": "2751Z",
      "intitule_naf_40": "Fabrication d'appareils électroménagers"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ddadb1250e35c9b083658d71cccfed95daccf80b",
    "fields": {
      "intitule_naf_65": "Fonderie d'acier",
      "intitule_naf": "Fonderie d'acier",
      "code_naf": "2452",
      "intitule_naf_40": "Fonderie d'acier"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0b51eef557283e74ed7e42c5e0acae5fbaceea3f",
    "fields": {
      "intitule_naf_65": "Prod. films cinémat. vidéo & prog.TV; enrg. sonore & éd. musicale",
      "intitule_naf": "Production de films cinématographiques, de vidéo et de programmes de télévision ; enregistrement sonore et édition musicale",
      "code_naf": "59",
      "intitule_naf_40": "Prod. films ; enr. sonore & éd. musicale"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4eab13fcb39d4ff3abfbf0b6b976c6a7bd745601",
    "fields": {
      "intitule_naf_65": "Post-production films cinématograph. vidéo & prog. de télévision",
      "intitule_naf": "Post-production de films cinématographiques, de vidéo et de programmes de télévision",
      "code_naf": "5912",
      "intitule_naf_40": "Post-production film & prog. télévision"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d58d56d74073e46d659294201349622771943fd4",
    "fields": {
      "intitule_naf_65": "Programmation, conseil et autres activités informatiques",
      "intitule_naf": "Programmation, conseil et autres activités informatiques",
      "code_naf": "620",
      "intitule_naf_40": "Pgmtion conseil & aut. act. informatique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e78d6b389fd1f78e0efdcceea94fd17b56f2d9cd",
    "fields": {
      "intitule_naf_65": "Activités cinématographiques, vidéo et de télévision",
      "intitule_naf": "Activités cinématographiques, vidéo et de télévision",
      "code_naf": "591",
      "intitule_naf_40": "Activ. cinématogra., vidéo & télévision"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1aaa6628c43ee2f036d5b14947233249234541c1",
    "fields": {
      "intitule_naf_65": "Édition et diffusion de programmes radio",
      "intitule_naf": "Édition et diffusion de programmes radio",
      "code_naf": "6010Z",
      "intitule_naf_40": "Édition et diffusion de programmes radio"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "040b8a52bcaaba2e69ca6f379d6701f7e315aada",
    "fields": {
      "intitule_naf_65": "Évaluation des risques et dommages",
      "intitule_naf": "Évaluation des risques et dommages",
      "code_naf": "6621",
      "intitule_naf_40": "Évaluation des risques et dommages"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1a170ba41e0946122c51705aa07da85bf05f3350",
    "fields": {
      "intitule_naf_65": "Télécommunications sans fil",
      "intitule_naf": "Télécommunications sans fil",
      "code_naf": "6120",
      "intitule_naf_40": "Télécommunications sans fil"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a65ee5a9afff94527df07e5efc622256905035bb",
    "fields": {
      "intitule_naf_65": "Programmation et diffusion",
      "intitule_naf": "Programmation et diffusion",
      "code_naf": "60",
      "intitule_naf_40": "Programmation et diffusion"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2f1cb9ed8fb33349ff691bd3b860af1e338619a2",
    "fields": {
      "intitule_naf_65": "Intermédiation monétaire",
      "intitule_naf": "Intermédiation monétaire",
      "code_naf": "641",
      "intitule_naf_40": "Intermédiation monétaire"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f40464e7c51408d41e9b6f915d5f014f445b8663",
    "fields": {
      "intitule_naf_65": "Activités juridiques",
      "intitule_naf": "Activités juridiques",
      "code_naf": "6910",
      "intitule_naf_40": "Activités juridiques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "858bf00fb4e9560ec9c8d1a0aba10e15bcf85d4a",
    "fields": {
      "intitule_naf_65": "Autres activités spécialisées, scientifiques et techniques",
      "intitule_naf": "Autres activités spécialisées, scientifiques et techniques",
      "code_naf": "74",
      "intitule_naf_40": "Aut. act. spécial. scientifique & techn."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b2d5e46780fa933eca648d28306795541f4cc568",
    "fields": {
      "intitule_naf_65": "Location et location-bail de biens personnels et domestiques",
      "intitule_naf": "Location et location-bail de biens personnels et domestiques",
      "code_naf": "772",
      "intitule_naf_40": "Loc. & loc.-bail bien perso. & domestiq."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5c06cef3d3dec96bc17aea148e3b4947dc5b93eb",
    "fields": {
      "intitule_naf_65": "Activités de contrôle et analyses techniques",
      "intitule_naf": "Activités de contrôle et analyses techniques",
      "code_naf": "7120",
      "intitule_naf_40": "Activité de contrôle & analyse technique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "72f86e2f38050de5220b5d777512ae0e6f807cb6",
    "fields": {
      "intitule_naf_65": "Autres activités pour la santé humaine",
      "intitule_naf": "Autres activités pour la santé humaine",
      "code_naf": "8690",
      "intitule_naf_40": "Autres activités pour la santé humaine"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d199337c57aa2d62c49267358e4ef1de2c321d15",
    "fields": {
      "intitule_naf_65": "Enseignement secondaire général",
      "intitule_naf": "Enseignement secondaire général",
      "code_naf": "8531",
      "intitule_naf_40": "Enseignement secondaire général"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5bf24794ff97a2df682c4815a53e7a03c9aee06f",
    "fields": {
      "intitule_naf_65": "Activités de centres d'appels",
      "intitule_naf": "Activités de centres d'appels",
      "code_naf": "8220Z",
      "intitule_naf_40": "Activités de centres d'appels"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "49058f45992300e190ef0656c0738ee47ce4fdf4",
    "fields": {
      "intitule_naf_65": "Traduction et interprétation",
      "intitule_naf": "Traduction et interprétation",
      "code_naf": "743",
      "intitule_naf_40": "Traduction et interprétation"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "884becaad3b65893763c715783e6dab7517b1589",
    "fields": {
      "intitule_naf_65": "Arts du spectacle vivant",
      "intitule_naf": "Arts du spectacle vivant",
      "code_naf": "9001Z",
      "intitule_naf_40": "Arts du spectacle vivant"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0ebba7aab62ef42ef2580a58bfbd760b2dfffb71",
    "fields": {
      "intitule_naf_65": "Activités vétérinaires",
      "intitule_naf": "Activités vétérinaires",
      "code_naf": "750",
      "intitule_naf_40": "Activités vétérinaires"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "98fe72d2bd86dc8a9c586cb53655aafc50f3ca67",
    "fields": {
      "intitule_naf_65": "Justice",
      "intitule_naf": "Justice",
      "code_naf": "8423",
      "intitule_naf_40": "Justice"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f9cfaa0f60f00ee9e9cc088db114ee9b9deaeef9",
    "fields": {
      "intitule_naf_65": "Culture de légumes, de melons, de racines et de tubercules",
      "intitule_naf": "Culture de légumes, de melons, de racines et de tubercules",
      "code_naf": "0113Z",
      "intitule_naf_40": "Cult. légume, melon, racine & tubercule"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "6ca847ed58c99a3a999520873c1acc83003dc7f8",
    "fields": {
      "intitule_naf_65": "Culture de fruits tropicaux et subtropicaux",
      "intitule_naf": "Culture de fruits tropicaux et subtropicaux",
      "code_naf": "122",
      "intitule_naf_40": "Culture fruits tropicaux et subtropicaux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c2f2b9a45b14fbe9ad811ff3e196b31257ffd6db",
    "fields": {
      "intitule_naf_65": "Culture de la vigne",
      "intitule_naf": "Culture de la vigne",
      "code_naf": "0121Z",
      "intitule_naf_40": "Culture de la vigne"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "32a24c74bd9c0d5badf310d30dcdbeddef90c24f",
    "fields": {
      "intitule_naf_65": "Fabrication d'articles en papier à usage sanitaire ou domestique",
      "intitule_naf": "Fabrication d'articles en papier à usage sanitaire ou domestique",
      "code_naf": "1722Z",
      "intitule_naf_40": "Fab. article papier sanit. ou domestique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d77e2df2f62803e57d2c7c1f4e295174ad71fc11",
    "fields": {
      "intitule_naf_65": "Fabrication de pesticides et d'autres produits agrochimiques",
      "intitule_naf": "Fabrication de pesticides et d'autres produits agrochimiques",
      "code_naf": "2020Z",
      "intitule_naf_40": "Fab. pesticide & aut. prod. agrochimique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0a8d03c16d086f35fcd788a4a49e3539a9ded7d4",
    "fields": {
      "intitule_naf_65": "Activités de soutien à l'extraction d'hydrocarbures",
      "intitule_naf": "Activités de soutien à l'extraction d'hydrocarbures",
      "code_naf": "910",
      "intitule_naf_40": "Act. de soutien à l'extr. hydrocarbures"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "67a21489e10613260bd117dd5908db51b8e4e069",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres produits céramiques",
      "intitule_naf": "Fabrication d'autres produits céramiques",
      "code_naf": "2349Z",
      "intitule_naf_40": "Fabrication d'autres produits céramiques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c8a286da38295f1f9c6dc7731933db796d291186",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres produits céramiques",
      "intitule_naf": "Fabrication d'autres produits céramiques",
      "code_naf": "2349",
      "intitule_naf_40": "Fabrication d'autres produits céramiques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "03ead344a674cd7d2701e806d8fc6a6200d9c0e6",
    "fields": {
      "intitule_naf_65": "Fabrication de plats préparés",
      "intitule_naf": "Fabrication de plats préparés",
      "code_naf": "1085",
      "intitule_naf_40": "Fabrication de plats préparés"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b77d4788abe18dd2119ce1c26617ab22802262d0",
    "fields": {
      "intitule_naf_65": "Fabrication de pâte à papier",
      "intitule_naf": "Fabrication de pâte à papier",
      "code_naf": "1711",
      "intitule_naf_40": "Fabrication de pâte à papier"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "fb2114ffc969345e5f3492301d0c11be1b15025a",
    "fields": {
      "intitule_naf_65": "Activités de pré-presse",
      "intitule_naf": "Activités de pré-presse",
      "code_naf": "1813Z",
      "intitule_naf_40": "Activités de pré-presse"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e38a95471b1f85fe9639c5acb8bfcb6cecb4a729",
    "fields": {
      "intitule_naf_65": "Fabrication de verre plat",
      "intitule_naf": "Fabrication de verre plat",
      "code_naf": "2311",
      "intitule_naf_40": "Fabrication de verre plat"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8d4dfa3ff42b7718f6c1d62b18a9a6225d408783",
    "fields": {
      "intitule_naf_65": "Fabrication de malt",
      "intitule_naf": "Fabrication de malt",
      "code_naf": "1106Z",
      "intitule_naf_40": "Fabrication de malt"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b87a0bc8b3b8d365a12411c2f97d04b772cce783",
    "fields": {
      "intitule_naf_65": "Fabric. prod. abrasifs & produits minéraux non métalliques n.c.a.",
      "intitule_naf": "Fabrication de produits abrasifs et de produits minéraux non métalliques n.c.a.",
      "code_naf": "239",
      "intitule_naf_40": "Fab. pdt abrasif & minér. non métal nca."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8ab1e1b007be657ee81468ea9531fbfeaab0357e",
    "fields": {
      "intitule_naf_65": "Fabrication radiateurs et chaudières pour le chauffage central",
      "intitule_naf": "Fabrication de radiateurs et de chaudières pour le chauffage central",
      "code_naf": "2521",
      "intitule_naf_40": "Fab. radiat. & chaudière pr chauf. ctral"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "df908cd88aec248245bf074414aa3dddb4e313a8",
    "fields": {
      "intitule_naf_65": "Fabrication de produits informatiques, électroniques et optiques",
      "intitule_naf": "Fabrication de produits informatiques, électroniques et optiques",
      "code_naf": "26",
      "intitule_naf_40": "Fab. prod. informat., électroniq. & opt."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e2ea1c2c00d1923cebad4b26a6701278c33f45f0",
    "fields": {
      "intitule_naf_65": "Élaboration et transformation de matières nucléaires",
      "intitule_naf": "Élaboration et transformation de matières nucléaires",
      "code_naf": "2446",
      "intitule_naf_40": "Élaboration et transform. mat. nucléaire"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "704d27ca2d01a00245e7d868c1ce7fd6777096b4",
    "fields": {
      "intitule_naf_65": "Traitement et élimination des déchets non dangereux",
      "intitule_naf": "Traitement et élimination des déchets non dangereux",
      "code_naf": "3821",
      "intitule_naf_40": "Traitmnt & élimin. déchets non dangereux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3c7b8b6d3780a673d6743cec0a1a00094a0473f8",
    "fields": {
      "intitule_naf_65": "Fabrication de matériel d'installation électrique",
      "intitule_naf": "Fabrication de matériel d'installation électrique",
      "code_naf": "2733",
      "intitule_naf_40": "Fabric. matériel installation électrique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "34d1f8c0c49c4677fd70653de6e919267623e3aa",
    "fields": {
      "intitule_naf_65": "Traitement et revêtement des métaux",
      "intitule_naf": "Traitement et revêtement des métaux",
      "code_naf": "2561",
      "intitule_naf_40": "Traitement et revêtement des métaux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "80fefa7f151822485eff467639a45c0aeb5a4c61",
    "fields": {
      "intitule_naf_65": "Fabrication de moules et modèles",
      "intitule_naf": "Fabrication de moules et modèles",
      "code_naf": "2573A",
      "intitule_naf_40": "Fabrication de moules et modèles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b54f19ffead0f1e717846d779af2829dd0e0b9be",
    "fields": {
      "intitule_naf_65": "Comm. détail meubles appareils éclairage & autres art. ménage",
      "intitule_naf": "Commerce de détail de meubles, appareils d'éclairage et autres articles de ménage en magasin spécialisé",
      "code_naf": "4759",
      "intitule_naf_40": "Com. dét. meub. éclair. & aut. art. mén."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a45de4a3317befc6aea666898347bdfe79121aec",
    "fields": {
      "intitule_naf_65": "Comm. détail ordinateurs unités périph. & logiciels (magas. spéc)",
      "intitule_naf": "Commerce de détail d'ordinateurs, d'unités périphériques et de logiciels en magasin spécialisé",
      "code_naf": "4741Z",
      "intitule_naf_40": "Com. dét ordi. un. périph. & logicl (ms)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "6919b95944e936080c8f91619ff74925ddb6950a",
    "fields": {
      "intitule_naf_65": "Comm. détail tapis, moquettes & revêts murs & sols (magas. spéc.)",
      "intitule_naf": "Commerce de détail de tapis, moquettes et revêtements de murs et de sols en magasin spécialisé",
      "code_naf": "4753",
      "intitule_naf_40": "Cd tapis moquette & revêt. mur sol (ms)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "30c3489d906b2928968be83d099c12c35d9a0d31",
    "fields": {
      "intitule_naf_65": "Comm. détail d'articles horlogerie & bijouterie (magas. spéc.)",
      "intitule_naf": "Commerce de détail d'articles d'horlogerie et de bijouterie en magasin spécialisé",
      "code_naf": "4777Z",
      "intitule_naf_40": "Com. dét. art. horlogerie & bijout. (ms)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "254ee9f3147c344a1a00554c15b05c4134357af3",
    "fields": {
      "intitule_naf_65": "Comm. détail chaussures & articles cuir (magasin spécialisé)",
      "intitule_naf": "Commerce de détail de chaussures et d'articles en cuir en magasin spécialisé",
      "code_naf": "4772",
      "intitule_naf_40": "Comm. dét. chaussure & article cuir (ms)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "226dce215670b6213b6a781a00d39aa2496fd92e",
    "fields": {
      "intitule_naf_65": "Terrains camping & parcs pour caravanes ou véhicules de loisirs",
      "intitule_naf": "Terrains de camping et parcs pour caravanes ou véhicules de loisirs",
      "code_naf": "553",
      "intitule_naf_40": "Terrain camping & parc pr caravane etc."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5618de8e7d5022945a6f7a2d903d816c90e7f6db",
    "fields": {
      "intitule_naf_65": "Commerce de détail en magasin non spécialisé",
      "intitule_naf": "Commerce de détail en magasin non spécialisé",
      "code_naf": "471",
      "intitule_naf_40": "Comm. détail en magasin non spécialisé"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2ae66e56c37f3b8b4bbd0b5945cde5d00e750209",
    "fields": {
      "intitule_naf_65": "Entreposage et stockage non frigorifique",
      "intitule_naf": "Entreposage et stockage non frigorifique",
      "code_naf": "5210B",
      "intitule_naf_40": "Entreposage et stockage non frigorifique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "484ee3c6ca34f4caca948d933b84ce1104f9f716",
    "fields": {
      "intitule_naf_65": "Affrètement et organisation des transports",
      "intitule_naf": "Affrètement et organisation des transports",
      "code_naf": "5229B",
      "intitule_naf_40": "Affrètement & organisation des transp."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "725b84c5e56f06682119994f6a0108004a61ef37",
    "fields": {
      "intitule_naf_65": "Transports routiers de fret de proximité",
      "intitule_naf": "Transports routiers de fret de proximité",
      "code_naf": "4941B",
      "intitule_naf_40": "Transports routiers de fret de proximité"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b241fa4e7e0e4ead70f0624176129e9502bf7ab4",
    "fields": {
      "intitule_naf_65": "Organisation de foires, salons professionnels et congrès",
      "intitule_naf": "Organisation de foires, salons professionnels et congrès",
      "code_naf": "8230Z",
      "intitule_naf_40": "Organisation salon profession. & congrès"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "eaf3782480012c5e31579fd53ae940009dca91b1",
    "fields": {
      "intitule_naf_65": "Activités des agences de placement de main-d'oeuvre",
      "intitule_naf": "Activités des agences de placement de main-d'œuvre",
      "code_naf": "781",
      "intitule_naf_40": "Activ. agence placement de main-d'oeuvre"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "88981ac2b52c7cf88468235a8572a8201660829d",
    "fields": {
      "intitule_naf_65": "Autres activités des médecins spécialistes",
      "intitule_naf": "Autres activités des médecins spécialistes",
      "code_naf": "8622C",
      "intitule_naf_40": "Autre activité des médecins spécialistes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ffdaabd8eb9ea0c73ac7d37a26bfca7a3754619f",
    "fields": {
      "intitule_naf_65": "Désinfection, désinsectisation, dératisation",
      "intitule_naf": "Désinfection, désinsectisation, dératisation",
      "code_naf": "8129A",
      "intitule_naf_40": "Désinfection désinsectisatn dératisation"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8841e83b7272d30141f8c4a25cca21f4266f8d83",
    "fields": {
      "intitule_naf_65": "Activités des agences de travail temporaire",
      "intitule_naf": "Activités des agences de travail temporaire",
      "code_naf": "7820",
      "intitule_naf_40": "Activ. des agences de travail temporaire"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a0fd1a32d1a46eaf47906bcfa0e34caf13a1da58",
    "fields": {
      "intitule_naf_65": "Services d'aménagement paysager",
      "intitule_naf": "Services d'aménagement paysager",
      "code_naf": "8130Z",
      "intitule_naf_40": "Services d'aménagement paysager"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "92bd1fec2aa156119ce04220f5d4a960e91e3b70",
    "fields": {
      "intitule_naf_65": "Activités de centres d'appels",
      "intitule_naf": "Activités de centres d'appels",
      "code_naf": "8220",
      "intitule_naf_40": "Activités de centres d'appels"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3e87bfde4325811a2d6fc65011071e5a15b601fc",
    "fields": {
      "intitule_naf_65": "Affaires étrangères",
      "intitule_naf": "Affaires étrangères",
      "code_naf": "8421Z",
      "intitule_naf_40": "Affaires étrangères"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "10bc51d9ac517888f0b24c38490b3c6faf791775",
    "fields": {
      "intitule_naf_65": "Accueil accompagn. sans héberg. adultes handicapés ou pers. âgées",
      "intitule_naf": "Accueil ou accompagnement sans hébergement d'adultes handicapés ou de  personnes âgées",
      "code_naf": "8810B",
      "intitule_naf_40": "Accueil ss hbgt adult. hand., pers. âgée"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5c34bf37ab30f9c25252b9fdb2f0b86467918971",
    "fields": {
      "intitule_naf_65": "Héberg. social person. handicap. & malades mentales & toxicomanes",
      "intitule_naf": "Hébergement social pour personnes handicapées mentales, malades mentales et toxicomanes",
      "code_naf": "872",
      "intitule_naf_40": "Hébrgt soc. hand., mal. mental & toxico."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "de047bb162741c4d16ff9931004ef2db987aecaf",
    "fields": {
      "intitule_naf_65": "Gest. des jardins botaniques et zoolog. et des réserv. naturelles",
      "intitule_naf": "Gestion des jardins botaniques et zoologiques et des réserves naturelles",
      "code_naf": "9104",
      "intitule_naf_40": "Gest. jardin bota. & zoo. & réserv. nat."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "828cf25b9a5674d77f79417f2dd0fb58b7363c70",
    "fields": {
      "intitule_naf_65": "Hébergement médicalisé pour personnes âgées",
      "intitule_naf": "Hébergement médicalisé pour personnes âgées",
      "code_naf": "8710A",
      "intitule_naf_40": "Hébergt médicalisé pour personnes âgées"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "28396b2cc603fc15fb58701ac0f778e894138db9",
    "fields": {
      "intitule_naf_65": "Réparation d'équipements de communication",
      "intitule_naf": "Réparation d'équipements de communication",
      "code_naf": "9512",
      "intitule_naf_40": "Réparation équipements de communication"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d3e2c34a69e41be5cd975d1001369678fc718a6c",
    "fields": {
      "intitule_naf_65": "Autre action sociale sans hébergement",
      "intitule_naf": "Autre action sociale sans hébergement",
      "code_naf": "889",
      "intitule_naf_40": "Autre action sociale sans hébergement"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c4686d875ba1d811374c743c78920b924b277ab5",
    "fields": {
      "intitule_naf_65": "Interm. du comm. en m.p. agricoles & textiles, animaux vivants",
      "intitule_naf": "Intermédiaires du commerce en matières premières agricoles, animaux vivants, matières premières textiles et produits semi-finis",
      "code_naf": "4611",
      "intitule_naf_40": "Interm. du comm. en produits agricoles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "94f7c48ffa309aff16f2a549646125521021cfcb",
    "fields": {
      "intitule_naf_65": "Comm. de gros d'ordinat., d'éqpts informatiq. périph. & logiciels",
      "intitule_naf": "Commerce de gros (commerce interentreprises) d'ordinateurs, d'équipements informatiques périphériques et de logiciels",
      "code_naf": "4651Z",
      "intitule_naf_40": "Comm. gros ordi. éqpt périph. & logiciel"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "cc8770c31275e9d6efbf0c57096acfe15d57b04a",
    "fields": {
      "intitule_naf_65": "Comm. de gros d'éqpts et composants électroniques et de télécomm.",
      "intitule_naf": "Commerce de gros (commerce interentreprises) de composants et d'équipements électroniques et de télécommunication",
      "code_naf": "4652Z",
      "intitule_naf_40": "Cg éqpt & composant électron. & télécom."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "cb0e39f4df4b9da0778fe90a793335926eb66984",
    "fields": {
      "intitule_naf_65": "Commerce de gros de meubles, de tapis et d'appareils d'éclairage",
      "intitule_naf": "Commerce de gros (commerce interentreprises) de meubles, de tapis et d'appareils d'éclairage",
      "code_naf": "4647Z",
      "intitule_naf_40": "Com. gros meuble tapis appareil éclaira."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "34c4913ba153396c95a8666a424a6b76f33c53fc",
    "fields": {
      "intitule_naf_65": "Commerce de gros d'autres machines et équipements de bureau",
      "intitule_naf": "Commerce de gros (commerce interentreprises) d'autres machines et équipements de bureau",
      "code_naf": "4666Z",
      "intitule_naf_40": "Com. gros autre machine & équipt bureau"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "716118850e4a3f21bc6baf974403266d9fcebd41",
    "fields": {
      "intitule_naf_65": "Comm. gros bois, matériaux de construction & appareils sanitaires",
      "intitule_naf": "Commerce de gros de bois, de matériaux de construction et d'appareils sanitaires",
      "code_naf": "4673",
      "intitule_naf_40": "Cg bois, mat. construc. & app. sanitaire"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "98d0686d9d0dcb9f97135ab48cb074711eb2a847",
    "fields": {
      "intitule_naf_65": "Dépollution et autres services de gestion des déchets",
      "intitule_naf": "Dépollution et autres services de gestion des déchets",
      "code_naf": "3900Z",
      "intitule_naf_40": "Dépollution & autre sces gestion déchets"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ab499b049c221a9f3a6f5309720cbca5b8aa79f4",
    "fields": {
      "intitule_naf_65": "Promotion immobilière de logements",
      "intitule_naf": "Promotion immobilière de logements",
      "code_naf": "4110A",
      "intitule_naf_40": "Promotion immobilière de logements"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "16102c689e4291b52f8e1725c742eea6778ce126",
    "fields": {
      "intitule_naf_65": "Commerce de gros non spécialisé",
      "intitule_naf": "Commerce de gros non spécialisé",
      "code_naf": "469",
      "intitule_naf_40": "Commerce de gros non spécialisé"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a00eb6074cd1c00f0066bc304fa1092ec561156a",
    "fields": {
      "intitule_naf_65": "Production films cinémat. vidéo & programmes de télévision",
      "intitule_naf": "Production de films cinématographiques, de vidéo et de programmes de télévision",
      "code_naf": "5911",
      "intitule_naf_40": "Prod. film cinémato. vidéo & pgm. télé."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2437a9069083d39d08ba5e685263b38b791e92fc",
    "fields": {
      "intitule_naf_65": "Location et location-bail machines, équipements et biens divers",
      "intitule_naf": "Location et location-bail d'autres machines, équipements et biens matériels n.c.a.",
      "code_naf": "7739Z",
      "intitule_naf_40": "Loc. & loc.-bail mach., éqpt & bien div."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "598087147ff66e034731b4ed9269ea6ee8c4319c",
    "fields": {
      "intitule_naf_65": "Location et location-bail machines bureau & matériel informatique",
      "intitule_naf": "Location et location-bail de machines de bureau et de matériel informatique",
      "code_naf": "7733Z",
      "intitule_naf_40": "Loc. & loc.-bail mach. bur. & mat. info."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9fffdf40843affca4ff60d7a69a0b92ea639b34a",
    "fields": {
      "intitule_naf_65": "Location & location-bail voitures & véhicules automobiles légers",
      "intitule_naf": "Location et location-bail de voitures et de véhicules automobiles légers",
      "code_naf": "7711",
      "intitule_naf_40": "Loc. & loc.-bail voit. & v. auto. léger"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1603f503c9d8269913c360175e0bdf52575b9e1c",
    "fields": {
      "intitule_naf_65": "Production de films et de programmes pour la télévision",
      "intitule_naf": "Production de films et de programmes pour la télévision",
      "code_naf": "5911A",
      "intitule_naf_40": "Prod. film & progm. pour la télévision"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "44df85c434084417932ea7cca84ec4d98f6f20ea",
    "fields": {
      "intitule_naf_65": "Conseil pour les affaires et autres conseils de gestion",
      "intitule_naf": "Conseil pour les affaires et autres conseils de gestion",
      "code_naf": "7022Z",
      "intitule_naf_40": "Conseil pr affaire & aut. cons. gestion"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9e7169f7432bcf72454b129b28f342cc90252e51",
    "fields": {
      "intitule_naf_65": "Télécommunications par satellite",
      "intitule_naf": "Télécommunications par satellite",
      "code_naf": "613",
      "intitule_naf_40": "Télécommunications par satellite"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4d865f51cd3b9f0051b15479dd99fd881518eba3",
    "fields": {
      "intitule_naf_65": "Télécommunications",
      "intitule_naf": "Télécommunications",
      "code_naf": "61",
      "intitule_naf_40": "Télécommunications"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5cedc2df57d95fd2ed718ea8ec86f11cf77fc4f9",
    "fields": {
      "intitule_naf_65": "Autres hébergements",
      "intitule_naf": "Autres hébergements",
      "code_naf": "5590Z",
      "intitule_naf_40": "Autres hébergements"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f192faa97098d11154d51307c409daf48be54088",
    "fields": {
      "intitule_naf_65": "Activités des syndicats de salariés",
      "intitule_naf": "Activités des syndicats de salariés",
      "code_naf": "9420",
      "intitule_naf_40": "Activités des syndicats de salariés"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9a9b5db13bb491b574e0498c793e53985cd84f5a",
    "fields": {
      "intitule_naf_65": "Entretien corporel",
      "intitule_naf": "Entretien corporel",
      "code_naf": "9604",
      "intitule_naf_40": "Entretien corporel"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "6ea7752a5d5e5ad5c3310ad73086674c12eb8ede",
    "fields": {
      "intitule_naf_65": "Autres cultures permanentes",
      "intitule_naf": "Autres cultures permanentes",
      "code_naf": "129",
      "intitule_naf_40": "Autres cultures permanentes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c97c7ee4b026b201cd00a5918319ca83b7a1a09a",
    "fields": {
      "intitule_naf_65": "Fabrication d'articles en fils métalliques, chaînes et ressorts",
      "intitule_naf": "Fabrication d'articles en fils métalliques, de chaînes et de ressorts",
      "code_naf": "2593",
      "intitule_naf_40": "Fab. art.  fil métal., chaîne & ressort"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8a0c1cfa43a5ab4e0f3be38bd817e833537d0e2e",
    "fields": {
      "intitule_naf_65": "Fabrication de produits azotés et d'engrais",
      "intitule_naf": "Fabrication de produits azotés et d'engrais",
      "code_naf": "2015Z",
      "intitule_naf_40": "Fabric. de produits azotés et d'engrais"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "520a9c48545333e6d53080486282ce4eef4594a4",
    "fields": {
      "intitule_naf_65": "Métallurgie des autres métaux non ferreux",
      "intitule_naf": "Métallurgie des autres métaux non ferreux",
      "code_naf": "2445Z",
      "intitule_naf_40": "Métallurgie autres métaux non ferreux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0cd3f3aa93c40cb714cb1bb56e646f015e375bd6",
    "fields": {
      "intitule_naf_65": "Activités de soutien aux cultures",
      "intitule_naf": "Activités de soutien aux cultures",
      "code_naf": "161",
      "intitule_naf_40": "Activités de soutien aux cultures"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "48310b016ae6d0ea6900ea58969259214dbca724",
    "fields": {
      "intitule_naf_65": "Fabrication de tapis et moquettes",
      "intitule_naf": "Fabrication de tapis et moquettes",
      "code_naf": "1393Z",
      "intitule_naf_40": "Fabrication de tapis et moquettes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "43a37772dee42ce186e1737e835fc618f2b9f9d0",
    "fields": {
      "intitule_naf_65": "Fabrication de tapis et moquettes",
      "intitule_naf": "Fabrication de tapis et moquettes",
      "code_naf": "1393",
      "intitule_naf_40": "Fabrication de tapis et moquettes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "91568c03c534f09c9c344e58b2ab1f343fe85cef",
    "fields": {
      "intitule_naf_65": "Extraction de tourbe",
      "intitule_naf": "Extraction de tourbe",
      "code_naf": "892",
      "intitule_naf_40": "Extraction de tourbe"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d55746239a407e55e1824171957b5b30b706d3cd",
    "fields": {
      "intitule_naf_65": "Intermédiaires du commerce en bois et matériaux de construction",
      "intitule_naf": "Intermédiaires du commerce en bois et matériaux de construction",
      "code_naf": "4613Z",
      "intitule_naf_40": "Interm. comm. bois & matériaux construc."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a613d5362e8c61f394cd8e73731642539b0d4214",
    "fields": {
      "intitule_naf_65": "Commerce de gros alimentaire non spécialisé",
      "intitule_naf": "Commerce de gros (commerce interentreprises) alimentaire non spécialisé",
      "code_naf": "4639B",
      "intitule_naf_40": "Comm de gros alimentaire non spécialisé"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8fb2cf8b234660a6563157e0a9160954e8d68a9f",
    "fields": {
      "intitule_naf_65": "Travaux plomberie, install. chauffage & conditionnement d'air",
      "intitule_naf": "Travaux de plomberie et installation de chauffage et de conditionnement d'air",
      "code_naf": "4322",
      "intitule_naf_40": "Trav. plomberie chauffage & condit. air"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e1e5d1458d73dbd03f7524ecf98d5f1bcc2d4575",
    "fields": {
      "intitule_naf_65": "Construction d'autres ouvrages de génie civil n.c.a.",
      "intitule_naf": "Construction d'autres ouvrages de génie civil n.c.a.",
      "code_naf": "4299",
      "intitule_naf_40": "Constr. aut. ouvrage de génie civil nca."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "277a7ce7d0f3394987f27e56cf620ca7366e31f2",
    "fields": {
      "intitule_naf_65": "Promotion immobilière d'autres bâtiments",
      "intitule_naf": "Promotion immobilière d'autres bâtiments",
      "code_naf": "4110C",
      "intitule_naf_40": "Promotion immobilière d'autres bâtiments"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f5fd39decd0d53f42151e9dbde9d9ce66339155f",
    "fields": {
      "intitule_naf_65": "Commerce d'autres véhicules automobiles",
      "intitule_naf": "Commerce d'autres véhicules automobiles",
      "code_naf": "4519Z",
      "intitule_naf_40": "Commerce d'autres véhicules automobiles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3cc3c43d5664e92ec787f556a3b199ddfafd1494",
    "fields": {
      "intitule_naf_65": "Démolition et préparation des sites",
      "intitule_naf": "Démolition et préparation des sites",
      "code_naf": "431",
      "intitule_naf_40": "Démolition et préparation des sites"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3bf92338607a6f04f6c784efeae4508d92eee0f4",
    "fields": {
      "intitule_naf_65": "Démantèlement d'épaves",
      "intitule_naf": "Démantèlement d'épaves",
      "code_naf": "3831",
      "intitule_naf_40": "Démantèlement d'épaves"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d45d255f2c301224cbaade7f14934eb279a35dba",
    "fields": {
      "intitule_naf_65": "Travaux de menuiserie",
      "intitule_naf": "Travaux de menuiserie",
      "code_naf": "4332",
      "intitule_naf_40": "Travaux de menuiserie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4df4d1d41542ae688bb8b829fd6256abc049785c",
    "fields": {
      "intitule_naf_65": "Forages et sondages",
      "intitule_naf": "Forages et sondages",
      "code_naf": "4313",
      "intitule_naf_40": "Forages et sondages"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d0f4256f00e7d1382df95c97446bb6fa25a6ed1f",
    "fields": {
      "intitule_naf_65": "Autres activ. serv. financiers sf assurance & c. de retraite, nca",
      "intitule_naf": "Autres activités des services financiers, hors assurance et caisses de retraite, n.c.a.",
      "code_naf": "6499",
      "intitule_naf_40": "Aut. act. finan. hs as. & c. retra. nca."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "93a3fece320625e1ef41584eb131839c52b9c991",
    "fields": {
      "intitule_naf_65": "Edition de logiciels outils de développement et de langages",
      "intitule_naf": "Edition de logiciels outils de développement et de langages",
      "code_naf": "5829B",
      "intitule_naf_40": "Edit. logiciel outil dévelop. & langage"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "6a141ea82fa5ab0f4874c926b09a22daca290f26",
    "fields": {
      "intitule_naf_65": "Activités combinées de soutien lié aux bâtiments",
      "intitule_naf": "Activités combinées de soutien lié aux bâtiments",
      "code_naf": "811",
      "intitule_naf_40": "Act. combinée soutien lié aux bâtiments"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c5fa35c3838f0f58f6caa8ea0a7f48136cadafd4",
    "fields": {
      "intitule_naf_65": "Conseil en relations publiques et communication",
      "intitule_naf": "Conseil en relations publiques et communication",
      "code_naf": "7021",
      "intitule_naf_40": "Conseil en relation publique & communic."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "764bd18c8984a263ed819ecfd6ff68d469ab444d",
    "fields": {
      "intitule_naf_65": "Projection de films cinématographiques",
      "intitule_naf": "Projection de films cinématographiques",
      "code_naf": "5914",
      "intitule_naf_40": "Projection de films cinématographiques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0a1d7c36d96b08c88e7eb167ac6aae0508183af4",
    "fields": {
      "intitule_naf_65": "Ingénierie, études techniques",
      "intitule_naf": "Ingénierie, études techniques",
      "code_naf": "7112B",
      "intitule_naf_40": "Ingénierie, études techniques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c40d93e9841d9e58bcb9afb9518680b1463c7de9",
    "fields": {
      "intitule_naf_65": "Activités des sièges sociaux",
      "intitule_naf": "Activités des sièges sociaux",
      "code_naf": "701",
      "intitule_naf_40": "Activités des sièges sociaux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "019c7f0a29e65d109c311f7d1aadb0e1ee1363bb",
    "fields": {
      "intitule_naf_65": "Activités des voyagistes",
      "intitule_naf": "Activités des voyagistes",
      "code_naf": "7912",
      "intitule_naf_40": "Activités des voyagistes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b2d68d7a0ddb422d4cd0622948640e9b6c443582",
    "fields": {
      "intitule_naf_65": "Activités de nettoyage",
      "intitule_naf": "Activités de nettoyage",
      "code_naf": "812",
      "intitule_naf_40": "Activités de nettoyage"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c440e0a2b8e48ad73aa2de5270b15c41a156ab30",
    "fields": {
      "intitule_naf_65": "Caisses de retraite",
      "intitule_naf": "Caisses de retraite",
      "code_naf": "6530",
      "intitule_naf_40": "Caisses de retraite"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1385c6e9d3ede603854a201bb40322dd4c038201",
    "fields": {
      "intitule_naf_65": "Activ. ménages : employeurs de personnel domestique",
      "intitule_naf": "Activités des ménages en tant qu'employeurs de personnel domestique",
      "code_naf": "970",
      "intitule_naf_40": "Act. ménage: empl. de person. domestique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "06b141bfab84348b1990f2262d219c6f281bd3a4",
    "fields": {
      "intitule_naf_65": "Enseignement de disciplines sportives et d'activités de loisirs",
      "intitule_naf": "Enseignement de disciplines sportives et d'activités de loisirs",
      "code_naf": "8551Z",
      "intitule_naf_40": "Enseigmnt discipl. sport. & act. loisir."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "287f4f6dae378a6e84e8a545b773a9f7630512c2",
    "fields": {
      "intitule_naf_65": "Activités des organisations associatives n.c.a.",
      "intitule_naf": "Activités des organisations associatives n.c.a.",
      "code_naf": "9499",
      "intitule_naf_40": "Act. organisations associatives nca."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "fd502ad89aa9aa30a17c49f937ecdf5d698bfd22",
    "fields": {
      "intitule_naf_65": "Organisation de jeux de hasard et d'argent",
      "intitule_naf": "Organisation de jeux de hasard et d'argent",
      "code_naf": "92",
      "intitule_naf_40": "Organisation jeux de hasard & d'argent"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "70bb63466ccee715edd79355c6c1a35d18266f6c",
    "fields": {
      "intitule_naf_65": "Blanchisserie-teinturerie de détail",
      "intitule_naf": "Blanchisserie-teinturerie de détail",
      "code_naf": "9601B",
      "intitule_naf_40": "Blanchisserie-teinturerie de détail"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "cb461555b1a32f7ed155a0b3913b8ba9cc3792f4",
    "fields": {
      "intitule_naf_65": "Hébergement social pour toxicomanes",
      "intitule_naf": "Hébergement social pour toxicomanes",
      "code_naf": "8720B",
      "intitule_naf_40": "Hébergement social pour toxicomanes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e7f0241d5c7bb7f2d7097edd8dd158a0e6434da2",
    "fields": {
      "intitule_naf_65": "Laboratoires d'analyses médicales",
      "intitule_naf": "Laboratoires d'analyses médicales",
      "code_naf": "8690B",
      "intitule_naf_40": "Laboratoires d'analyses médicales"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d798a780ddea3702e2d84e570043e976ef74c05e",
    "fields": {
      "intitule_naf_65": "Gestion d'installations sportives",
      "intitule_naf": "Gestion d'installations sportives",
      "code_naf": "9311Z",
      "intitule_naf_40": "Gestion d'installations sportives"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9f92aa5e13a6155285ebd1d554fa179eb8675c91",
    "fields": {
      "intitule_naf_65": "Fabric. machines pour le travail du caoutchouc ou des plastiques",
      "intitule_naf": "Fabrication de machines pour le travail du caoutchouc ou des plastiques",
      "code_naf": "2896",
      "intitule_naf_40": "Fab. machine pr trav. du caoutch, plast."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f9ce40f560a96c2a27fef6a784c74f92c5516ebc",
    "fields": {
      "intitule_naf_65": "Réparation de machines et équipements mécaniques",
      "intitule_naf": "Réparation de machines et équipements mécaniques",
      "code_naf": "3312Z",
      "intitule_naf_40": "Répar. machine & équipement mécaniques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "98f225e66f4b8cf2f30e42cc8e34fa8470cfafba",
    "fields": {
      "intitule_naf_65": "Fabrication de machines agricoles et forestières",
      "intitule_naf": "Fabrication de machines agricoles et forestières",
      "code_naf": "2830Z",
      "intitule_naf_40": "Fab. machines agricoles et forestières"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d26f591e7a3aeb2411aba8843fc4db60016077d1",
    "fields": {
      "intitule_naf_65": "Fabrication de machines agricoles et forestières",
      "intitule_naf": "Fabrication de machines agricoles et forestières",
      "code_naf": "2830",
      "intitule_naf_40": "Fab. machines agricoles et forestières"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5f3488dee10463791bdd49ac9250a62a1791583e",
    "fields": {
      "intitule_naf_65": "Fabrication de machines pour la métallurgie",
      "intitule_naf": "Fabrication de machines pour la métallurgie",
      "code_naf": "2891Z",
      "intitule_naf_40": "Fabric. de machines pour la métallurgie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1277aa9bbedff403ce9f716577c7c44ba1103491",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres machines spécialisées",
      "intitule_naf": "Fabrication d'autres machines spécialisées",
      "code_naf": "2899B",
      "intitule_naf_40": "Fabric. d'autres machines spécialisées"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a4a3fc8f32e5df4c735694bde5bce8596140c847",
    "fields": {
      "intitule_naf_65": "Collecte et traitement des eaux usées",
      "intitule_naf": "Collecte et traitement des eaux usées",
      "code_naf": "3700",
      "intitule_naf_40": "Collecte et traitement des eaux usées"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a5d74c22d18187e00c824723391567d16c901d94",
    "fields": {
      "intitule_naf_65": "Transport d'électricité",
      "intitule_naf": "Transport d'électricité",
      "code_naf": "3512Z",
      "intitule_naf_40": "Transport d'électricité"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d50512ea50aa059737a1800ba0f1924f59a933e2",
    "fields": {
      "intitule_naf_65": "Tréfilage à froid",
      "intitule_naf": "Tréfilage à froid",
      "code_naf": "2434",
      "intitule_naf_40": "Tréfilage à froid"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "fd40032c647b1a9b4df0cd88aec494a74ec2bce2",
    "fields": {
      "intitule_naf_65": "Métallurgie",
      "intitule_naf": "Métallurgie",
      "code_naf": "24",
      "intitule_naf_40": "Métallurgie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "af15c21328c8dd770e3751eb4698b69705770b3c",
    "fields": {
      "intitule_naf_65": "Fab. éqpts d'irradiation médic. électromédic. & électrothérapeut.",
      "intitule_naf": "Fabrication d'équipements d'irradiation médicale, d'équipements électromédicaux et électrothérapeutiques",
      "code_naf": "2660Z",
      "intitule_naf_40": "Fab. éqpt irrad. médic. & électromedic."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7bb2fda72e7efdfcfab1c3fe5eca7122b9073bae",
    "fields": {
      "intitule_naf_65": "Fab. éqpts d'irradiation médic. électromédic. & électrothérapeut.",
      "intitule_naf": "Fabrication d'équipements d'irradiation médicale, d'équipements électromédicaux et électrothérapeutiques",
      "code_naf": "266",
      "intitule_naf_40": "Fab. éqpt irrad. médic. & électromedic."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "42a5d62c69f3319d94c5bb4735229c10c527fc2b",
    "fields": {
      "intitule_naf_65": "Comm de gros de machines pour l'extrac, la constr, le génie civil",
      "intitule_naf": "Commerce de gros de machines pour l'extraction, la construction et le génie civil",
      "code_naf": "4663",
      "intitule_naf_40": "Com. gros machine pr extrac., constr. GC"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0511801f56e5361fccc3867e2175979a8c3fb01f",
    "fields": {
      "intitule_naf_65": "Commerce de détail produits pharmaceutiques (magasin spécialisé)",
      "intitule_naf": "Commerce de détail de produits pharmaceutiques en magasin spécialisé",
      "code_naf": "4773Z",
      "intitule_naf_40": "Comm. dét. produits pharmaceutiques (ms)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "190fee3316fecdb5b2323597e0ebf562c5e482a3",
    "fields": {
      "intitule_naf_65": "Autres commerces de détail en magasin spécialisé",
      "intitule_naf": "Autres commerces de détail en magasin spécialisé",
      "code_naf": "477",
      "intitule_naf_40": "Autre comm. détail en magasin spécialisé"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "79744d563e9ac29668fc4233faf1223799cfe0b2",
    "fields": {
      "intitule_naf_65": "Commerce de détail d'autres équipements du foyer",
      "intitule_naf": "Commerce de détail d'autres équipements du foyer",
      "code_naf": "4759B",
      "intitule_naf_40": "Comm. détail autres équipements du foyer"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e33ea98dfee40417109f2edeaeeb4c1631ea37aa",
    "fields": {
      "intitule_naf_65": "Transports fluviaux de passagers",
      "intitule_naf": "Transports fluviaux de passagers",
      "code_naf": "503",
      "intitule_naf_40": "Transports fluviaux de passagers"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "408ea0d7cdc7732684ace2cf67d960d40fbd2706",
    "fields": {
      "intitule_naf_65": "Entreposage et stockage",
      "intitule_naf": "Entreposage et stockage",
      "code_naf": "521",
      "intitule_naf_40": "Entreposage et stockage"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "80612c15a12ddea4a053fa03d13ec736c8223b26",
    "fields": {
      "intitule_naf_65": "Manutention portuaire",
      "intitule_naf": "Manutention portuaire",
      "code_naf": "5224A",
      "intitule_naf_40": "Manutention portuaire"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c58ab01de5bfe6447636e2cf65da8cb8944810f2",
    "fields": {
      "intitule_naf_65": "Fab. machines équipts bureau (sf ordinateurs & équipts périph.)",
      "intitule_naf": "Fabrication de machines et d'équipements de bureau (à l'exception des ordinateurs et équipements périphériques)",
      "code_naf": "2823",
      "intitule_naf_40": "Fab. machine équipt bureau (sf ordinat.)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "25e37a18c2076ee65b45ea7d89abae99e7c9b80c",
    "fields": {
      "intitule_naf_65": "Fabric. machines pour le travail du caoutchouc ou des plastiques",
      "intitule_naf": "Fabrication de machines pour le travail du caoutchouc ou des plastiques",
      "code_naf": "2896Z",
      "intitule_naf_40": "Fab. machine pr trav. du caoutch, plast."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "83dc9157e1ecf87b39960a494ec79dc686dd8db9",
    "fields": {
      "intitule_naf_65": "Fabrication équipements électriques et électroniques automobiles",
      "intitule_naf": "Fabrication d'équipements électriques et électroniques automobiles",
      "code_naf": "2931Z",
      "intitule_naf_40": "Fab. équipt électriq. & électron. auto."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ca4be812f74b11ec4f555ee73844dd8e9116a007",
    "fields": {
      "intitule_naf_65": "Fabrication d'engrenages et d'organes mécaniques de transmission",
      "intitule_naf": "Fabrication d'engrenages et d'organes mécaniques de transmission",
      "code_naf": "2815Z",
      "intitule_naf_40": "Fab. engrenage & organe méca. transmis."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9b99ee3a78ed638bae162f7b3f7299fc50d9e138",
    "fields": {
      "intitule_naf_65": "Fabrication machines de formage des métaux & machines-outils",
      "intitule_naf": "Fabrication de machines de formage des métaux et de machines-outils",
      "code_naf": "284",
      "intitule_naf_40": "Fab. mach. formage métaux & mach.-outil"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "659c3ed0f7f7449f5d98133002fbc49b43d0f4db",
    "fields": {
      "intitule_naf_65": "Fabrication de piles et d'accumulateurs électriques",
      "intitule_naf": "Fabrication de piles et d'accumulateurs électriques",
      "code_naf": "2720Z",
      "intitule_naf_40": "Fabric. pile & accumulateur électrique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "28e9d49ebd8feef7a9f0b7f1fc738d08668c266c",
    "fields": {
      "intitule_naf_65": "Fabrication de machines pour la métallurgie",
      "intitule_naf": "Fabrication de machines pour la métallurgie",
      "code_naf": "2891",
      "intitule_naf_40": "Fabric. de machines pour la métallurgie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a57a7f50c1624f0d0d41d6e52c870ef0d08b573a",
    "fields": {
      "intitule_naf_65": "Fabrication de machines et équipements n.c.a.",
      "intitule_naf": "Fabrication de machines et équipements n.c.a.",
      "code_naf": "28",
      "intitule_naf_40": "Fabric. de machines & équipements n.c.a."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "836ddb69b689ca5ab80b33bdd95531f9bb0b14dd",
    "fields": {
      "intitule_naf_65": "Construction de bateaux de plaisance",
      "intitule_naf": "Construction de bateaux de plaisance",
      "code_naf": "3012Z",
      "intitule_naf_40": "Construction de bateaux de plaisance"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "98281e914fb7e8b42be27a3b3ecedc24015e5e78",
    "fields": {
      "intitule_naf_65": "Fabrication d'outillage",
      "intitule_naf": "Fabrication d'outillage",
      "code_naf": "2573",
      "intitule_naf_40": "Fabrication d'outillage"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c29fd8bb39fe873e5cfbd7aa7694718c6376b32a",
    "fields": {
      "intitule_naf_65": "Travaux de maçonnerie générale et gros oeuvre de bâtiment",
      "intitule_naf": "Travaux de maçonnerie générale et gros œuvre de bâtiment",
      "code_naf": "4399C",
      "intitule_naf_40": "Trav. maçon. gle & gros oeuvre bâtiment"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f1699d16d5bc2482944b3e9d849be54b50849410",
    "fields": {
      "intitule_naf_65": "Fabrication articles joaillerie, bijouterie & articles similaires",
      "intitule_naf": "Fabrication d'articles de joaillerie, bijouterie et articles similaires",
      "code_naf": "321",
      "intitule_naf_40": "Fab. artic. joaillerie, bijout. & simil."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f4b0bcec2a33a4224d7089260b610063c96419d7",
    "fields": {
      "intitule_naf_65": "Dépollution et autres services de gestion des déchets",
      "intitule_naf": "Dépollution et autres services de gestion des déchets",
      "code_naf": "3900",
      "intitule_naf_40": "Dépollution & autre sces gestion déchets"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a67873398bb62d782310ea2bbe283598af2f7616",
    "fields": {
      "intitule_naf_65": "Dépollution et autres services de gestion des déchets",
      "intitule_naf": "Dépollution et autres services de gestion des déchets",
      "code_naf": "39",
      "intitule_naf_40": "Dépollution & autre sces gestion déchets"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7bb69fc699b50968b77c415c8dd53193bcd0bb2c",
    "fields": {
      "intitule_naf_65": "Travaux d'installation électrique dans tous locaux",
      "intitule_naf": "Travaux d'installation électrique dans tous locaux",
      "code_naf": "4321A",
      "intitule_naf_40": "Travaux instal. électriq. ds tous locaux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "38629160232968e1816fa10f793d6afe5e16c342",
    "fields": {
      "intitule_naf_65": "Autres travaux de construction spécialisés\u00a0n.c.a.",
      "intitule_naf": "Autres travaux de construction spécialisés\u00a0n.c.a.",
      "code_naf": "4399",
      "intitule_naf_40": "Aut. travaux construc. spécialisés\u00a0nca."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d8b5e4f74141729c29418a7268358cf0049ebb10",
    "fields": {
      "intitule_naf_65": "Construction aéronautique et spatiale",
      "intitule_naf": "Construction aéronautique et spatiale",
      "code_naf": "3030",
      "intitule_naf_40": "Construction aéronautique et spatiale"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "50828149e1d998c16c107c78bab329a9be47a73f",
    "fields": {
      "intitule_naf_65": "Autres travaux d'installation n.c.a.",
      "intitule_naf": "Autres travaux d'installation n.c.a.",
      "code_naf": "4329B",
      "intitule_naf_40": "Autres travaux d'installation n.c.a."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e4ffd5ff82957f5be2c79c9837d8c23f7b8bfd63",
    "fields": {
      "intitule_naf_65": "Fabrication de jeux et jouets",
      "intitule_naf": "Fabrication de jeux et jouets",
      "code_naf": "3240",
      "intitule_naf_40": "Fabrication de jeux et jouets"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a377ec5969e84cb5f8fdbfb6824582d36441d0d9",
    "fields": {
      "intitule_naf_65": "Frappe de monnaie",
      "intitule_naf": "Frappe de monnaie",
      "code_naf": "3211Z",
      "intitule_naf_40": "Frappe de monnaie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "32d41c8c632d29c4b8b5ecf924c1b7a6ede0dd0e",
    "fields": {
      "intitule_naf_65": "Commerce de gros de produits agricoles bruts et d'animaux vivants",
      "intitule_naf": "Commerce de gros de produits agricoles bruts et d'animaux vivants",
      "code_naf": "462",
      "intitule_naf_40": "Com. gros prod. agric. brut & anim. viv."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "74147c6fecf5f4e283f07bac91c419f4a1a690b9",
    "fields": {
      "intitule_naf_65": "Commerce de détail de carburants en magasin spécialisé",
      "intitule_naf": "Commerce de détail de carburants en magasin spécialisé",
      "code_naf": "4730Z",
      "intitule_naf_40": "Comm. détail carburant en mag. spéciali."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "17ca1cf3d7197436d7a14be367656a294942b83c",
    "fields": {
      "intitule_naf_65": "Autres commerces de détail en magasin non spécialisé",
      "intitule_naf": "Autres commerces de détail en magasin non spécialisé",
      "code_naf": "4719B",
      "intitule_naf_40": "Autres comm. détail en magasin non spéc."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "54c880a8596221d538a0333da1e952649009f445",
    "fields": {
      "intitule_naf_65": "Commerce de détail sur éventaires et marchés",
      "intitule_naf": "Commerce de détail sur éventaires et marchés",
      "code_naf": "478",
      "intitule_naf_40": "Commerce détail sur éventaires & marchés"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "781ef18f05584442008ad6a8047bbfbaab0872b6",
    "fields": {
      "intitule_naf_65": "Commerce et réparation de motocycles",
      "intitule_naf": "Commerce et réparation de motocycles",
      "code_naf": "4540Z",
      "intitule_naf_40": "Commerce et réparation de motocycles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8de01bae9d83c18c8240664eaf292ae08a1ef23d",
    "fields": {
      "intitule_naf_65": "Transports fluviaux de fret",
      "intitule_naf": "Transports fluviaux de fret",
      "code_naf": "5040Z",
      "intitule_naf_40": "Transports fluviaux de fret"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4efa8089f60a7e6a1debcba20345fc679e712c91",
    "fields": {
      "intitule_naf_65": "Transports fluviaux de fret",
      "intitule_naf": "Transports fluviaux de fret",
      "code_naf": "5040",
      "intitule_naf_40": "Transports fluviaux de fret"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d70e03382c0aa7dc9d99fc476a324a41b970aef2",
    "fields": {
      "intitule_naf_65": "Loc-bail de propriété intell. & sf oeuvres soumises à copyright",
      "intitule_naf": "Location-bail de propriété intellectuelle et de produits similaires, à l'exception des œuvres soumises à copyright",
      "code_naf": "7740",
      "intitule_naf_40": "Loc-bail propr. intel., sf oeuvre avec \u00a9"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0c964859f8ded76d27518d672e16b30722c4a994",
    "fields": {
      "intitule_naf_65": "Autres activ. serv. financiers sf assurance & c. de retraite, nca",
      "intitule_naf": "Autres activités des services financiers, hors assurance et caisses de retraite, n.c.a.",
      "code_naf": "6499Z",
      "intitule_naf_40": "Aut. act. finan. hs as. & c. retra. nca."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f7a8bdbce51e003d0126c89774b87a9577101b21",
    "fields": {
      "intitule_naf_65": "Recherche-développement : autres sciences physiques et naturelles",
      "intitule_naf": "Recherche-développement en autres sciences physiques et naturelles",
      "code_naf": "7219Z",
      "intitule_naf_40": "R&D : aut. sciences physique & naturelle"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "944a614c6cba1aab6a9564fbb20370202e4634b0",
    "fields": {
      "intitule_naf_65": "Location et location-bail de machines et équipements agricoles",
      "intitule_naf": "Location et location-bail de machines et équipements agricoles",
      "code_naf": "7731",
      "intitule_naf_40": "Loc. & loc.-bail machine & éqpt agricole"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "24fad845ec871c5735069038fe22c4593000ae71",
    "fields": {
      "intitule_naf_65": "Location et location-bail de matériels de transport aérien",
      "intitule_naf": "Location et location-bail de matériels de transport aérien",
      "code_naf": "7735",
      "intitule_naf_40": "Loc. & loc.-bail mat. transport aérien"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f2f2cdb5b257562e81d656da155767407319b480",
    "fields": {
      "intitule_naf_65": "Édition de jeux électroniques",
      "intitule_naf": "Édition de jeux électroniques",
      "code_naf": "5821Z",
      "intitule_naf_40": "Édition de jeux électroniques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "680ea8d0d81e9fb023df28e0f42104aae45b5f6e",
    "fields": {
      "intitule_naf_65": "Activités photographiques",
      "intitule_naf": "Activités photographiques",
      "code_naf": "742",
      "intitule_naf_40": "Activités photographiques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "79eeb9052167970faa0682ff1644d3d6351a6619",
    "fields": {
      "intitule_naf_65": "Activités d'enquête",
      "intitule_naf": "Activités d'enquête",
      "code_naf": "8030Z",
      "intitule_naf_40": "Activités d'enquête"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ffe8e12bc4c1d5fb70a73b46281475c909cd3346",
    "fields": {
      "intitule_naf_65": "Activités d'enquête",
      "intitule_naf": "Activités d'enquête",
      "code_naf": "803",
      "intitule_naf_40": "Activités d'enquête"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "776124685d83377c8afab853dfca8eee21a507b6",
    "fields": {
      "intitule_naf_65": "Élaboration et transformation de matières nucléaires",
      "intitule_naf": "Élaboration et transformation de matières nucléaires",
      "code_naf": "2446Z",
      "intitule_naf_40": "Élaboration et transform. mat. nucléaire"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "179a0f72f089750ca4725af40018e5893aeea08b",
    "fields": {
      "intitule_naf_65": "Enrichissement et retraitement de matières nucléaires",
      "intitule_naf": "Enrichissement et  retraitement de matières nucléaires",
      "code_naf": "2013A",
      "intitule_naf_40": "Enrichissment & retrait. mat. nucléaire"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "81a6d8378ace18f18be0928cf0b8260e2cd9d540",
    "fields": {
      "intitule_naf_65": "Fabrication de mortiers et bétons secs",
      "intitule_naf": "Fabrication de mortiers et bétons secs",
      "code_naf": "2364",
      "intitule_naf_40": "Fabrication de mortiers et bétons secs"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d0c49d875afd071ef4d14bd6e3eb8515b6e5db07",
    "fields": {
      "intitule_naf_65": "Fabrication de carreaux en céramique",
      "intitule_naf": "Fabrication de carreaux en céramique",
      "code_naf": "2331Z",
      "intitule_naf_40": "Fabrication de carreaux en céramique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "29c421ff9cfb0870f60b07ebe9a32d3a7347aac4",
    "fields": {
      "intitule_naf_65": "Traitement et revêtement des métaux",
      "intitule_naf": "Traitement et revêtement des métaux",
      "code_naf": "2561Z",
      "intitule_naf_40": "Traitement et revêtement des métaux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "600ee86db8d6af2e1d514dacc0ce03d7010e5b30",
    "fields": {
      "intitule_naf_65": "Fabrication d'articles de papeterie",
      "intitule_naf": "Fabrication d'articles de papeterie",
      "code_naf": "1723",
      "intitule_naf_40": "Fabrication d'articles de papeterie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "138a6a70ec43b4231823efa6b0d51b27451ca7e4",
    "fields": {
      "intitule_naf_65": "Fabrication de produits explosifs",
      "intitule_naf": "Fabrication de produits explosifs",
      "code_naf": "2051",
      "intitule_naf_40": "Fabrication de produits explosifs"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d3e6f6da67412cbfd090e8ad282928495ce5ae23",
    "fields": {
      "intitule_naf_65": "Activités de pré-presse",
      "intitule_naf": "Activités de pré-presse",
      "code_naf": "1813",
      "intitule_naf_40": "Activités de pré-presse"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "30bbd7bc41d9e4a979952d3907538c2480a6a63e",
    "fields": {
      "intitule_naf_65": "Fonderie de fonte",
      "intitule_naf": "Fonderie de fonte",
      "code_naf": "2451Z",
      "intitule_naf_40": "Fonderie de fonte"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "86aefa74375c9e5ed8f3e5eb9cb09dc5e494d40e",
    "fields": {
      "intitule_naf_65": "Réparation d'autres biens personnels et domestiques",
      "intitule_naf": "Réparation d'autres biens personnels et domestiques",
      "code_naf": "9529Z",
      "intitule_naf_40": "Répar. aut. biens personnel & domestique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5307371186a251a30e36e42e9e98cb25dfe7fe7c",
    "fields": {
      "intitule_naf_65": "Réparation d'articles d'horlogerie et de bijouterie",
      "intitule_naf": "Réparation d'articles d'horlogerie et de bijouterie",
      "code_naf": "9525Z",
      "intitule_naf_40": "Répar.articles horlogerie & bijouterie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "aaeb7cdc270b093ff2799d90c169ccf02f4ad20a",
    "fields": {
      "intitule_naf_65": "Fabrication de cidre et de vins de fruits",
      "intitule_naf": "Fabrication de cidre et de vins de fruits",
      "code_naf": "1103Z",
      "intitule_naf_40": "Fabrication de cidre & de vins de fruits"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b7aa5c7f38d2747e29777c229a749a9b19ef7fd1",
    "fields": {
      "intitule_naf_65": "Élevage d'autres bovins et de buffles",
      "intitule_naf": "Élevage d'autres bovins et de buffles",
      "code_naf": "142",
      "intitule_naf_40": "Élevage d'autres bovins et de buffles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "55df59cb09247e5426a68c62e9e17afdd3963741",
    "fields": {
      "intitule_naf_65": "Fabrication de pâtes alimentaires",
      "intitule_naf": "Fabrication de pâtes alimentaires",
      "code_naf": "1073Z",
      "intitule_naf_40": "Fabrication de pâtes alimentaires"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ce7c7779369c1bf41f708728a41508b8ba6d997b",
    "fields": {
      "intitule_naf_65": "Extraction de gaz naturel",
      "intitule_naf": "Extraction de gaz naturel",
      "code_naf": "620",
      "intitule_naf_40": "Extraction de gaz naturel"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d028cad3c7685cbec1298dbf6ed4f914cb0c1a5a",
    "fields": {
      "intitule_naf_65": "Culture du tabac",
      "intitule_naf": "Culture du tabac",
      "code_naf": "115",
      "intitule_naf_40": "Culture du tabac"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f361bcef4053c2b31bcd4e1f2114e82fd3ecb408",
    "fields": {
      "intitule_naf_65": "Gestion des bibliothèques et des archives",
      "intitule_naf": "Gestion des bibliothèques et des archives",
      "code_naf": "9101",
      "intitule_naf_40": "Gestion des bibliothèques & des archives"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3cb1f26d8f5765caea11f0dc5cac067b0ab850a9",
    "fields": {
      "intitule_naf_65": "Gestion des retraites complémentaires",
      "intitule_naf": "Gestion des retraites complémentaires",
      "code_naf": "8430B",
      "intitule_naf_40": "Gestion des retraites complémentaires"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a34fca17655f11a1be94770d059c77c399a7f5e1",
    "fields": {
      "intitule_naf_65": "Activités des syndicats de salariés",
      "intitule_naf": "Activités des syndicats de salariés",
      "code_naf": "9420Z",
      "intitule_naf_40": "Activités des syndicats de salariés"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d0424804a3c51745f2b528e618a037d1b7e319b1",
    "fields": {
      "intitule_naf_65": "Autres activités liées au sport",
      "intitule_naf": "Autres activités liées au sport",
      "code_naf": "9319Z",
      "intitule_naf_40": "Autres activités liées au sport"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "532935ea632f21baeacdb69f8373683e7efc063c",
    "fields": {
      "intitule_naf_65": "Enseignement secondaire",
      "intitule_naf": "Enseignement secondaire",
      "code_naf": "853",
      "intitule_naf_40": "Enseignement secondaire"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4f6fcd2c85a29755cc70005c54431af774da10f6",
    "fields": {
      "intitule_naf_65": "Autres cultures non permanentes",
      "intitule_naf": "Autres cultures non permanentes",
      "code_naf": "119",
      "intitule_naf_40": "Autres cultures non permanentes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4aadc3a68c5434168f364735d5242aca5a686154",
    "fields": {
      "intitule_naf_65": "Récolte prod. forestiers non ligneux poussant à l'état sauvage",
      "intitule_naf": "Récolte de produits forestiers non ligneux poussant à l'état sauvage",
      "code_naf": "0230Z",
      "intitule_naf_40": "Récolte prodts forestiers non ligneux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f932c5d7a486899e2cd15a85fcb4dfe793036bc4",
    "fields": {
      "intitule_naf_65": "Fabrication d'huiles et graisses végétales et animales",
      "intitule_naf": "Fabrication d'huiles et graisses végétales et animales",
      "code_naf": "104",
      "intitule_naf_40": "Fab. huile et graisse végétale & animale"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9c7f33ef3054ad5879de858e2ad47a2f537320ba",
    "fields": {
      "intitule_naf_65": "Culture d'aut. fruits d'arbres ou d'arbustes et de fruits a coque",
      "intitule_naf": "Culture d'autres fruits d'arbres ou d'arbustes et de fruits à coque",
      "code_naf": "125",
      "intitule_naf_40": "Cult. d'aut. fruits & de fruits à coque"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b7242748687c4c685ae74ee34e4d014da2c0e49c",
    "fields": {
      "intitule_naf_65": "Élevage de chevaux et d'autres équidés",
      "intitule_naf": "Élevage de chevaux et d'autres équidés",
      "code_naf": "143",
      "intitule_naf_40": "Élevage de chevaux et d'autres équidés"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1f4310c5921c6aa938e869cb9f25bdbd744a17b6",
    "fields": {
      "intitule_naf_65": "Élevage d'autres bovins et de buffles",
      "intitule_naf": "Élevage d'autres bovins et de buffles",
      "code_naf": "0142Z",
      "intitule_naf_40": "Élevage d'autres bovins et de buffles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b91f89d07ef7b35de5e86fb8f45f04fc72f3172d",
    "fields": {
      "intitule_naf_65": "Chasse, piégeage et services annexes",
      "intitule_naf": "Chasse, piégeage et services annexes",
      "code_naf": "170",
      "intitule_naf_40": "Chasse, piégeage et services annexes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "600793624e6c2bdbc6c58c95488b2cfbecdd4f9d",
    "fields": {
      "intitule_naf_65": "Fabrication d'huiles et graisses",
      "intitule_naf": "Fabrication d'huiles et graisses",
      "code_naf": "1041",
      "intitule_naf_40": "Fabrication d'huiles et graisses"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1ba39b9603d24a62d44be3a46e3df69605164688",
    "fields": {
      "intitule_naf_65": "Aquaculture en eau douce",
      "intitule_naf": "Aquaculture en eau douce",
      "code_naf": "0322Z",
      "intitule_naf_40": "Aquaculture en eau douce"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "29bc25847d631c2aa89bf5bb15f315e5d2e36ded",
    "fields": {
      "intitule_naf_65": "Pêche en eau douce",
      "intitule_naf": "Pêche en eau douce",
      "code_naf": "312",
      "intitule_naf_40": "Pêche en eau douce"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f73323b56518f1ea9d873fb599e142ab4a260128",
    "fields": {
      "intitule_naf_65": "Production animale",
      "intitule_naf": "Production animale",
      "code_naf": "14",
      "intitule_naf_40": "Production animale"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2b33789ed2283eaa8798f02aa336fb22be3f879b",
    "fields": {
      "intitule_naf_65": "Fabrication briques tuiles & prod. de construction en terre cuite",
      "intitule_naf": "Fabrication de briques, tuiles et produits de construction, en terre cuite",
      "code_naf": "2332",
      "intitule_naf_40": "Fab. produit  construct. en terre cuite"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "90f89c90bc1f5348602e525aa46a6c5423ca915a",
    "fields": {
      "intitule_naf_65": "Fabrication d'isolateurs et pièces isolantes en céramique",
      "intitule_naf": "Fabrication d'isolateurs et pièces isolantes en céramique",
      "code_naf": "2343Z",
      "intitule_naf_40": "Fab. isolateur & pièce isolante céramiq."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5d821f69e2ce7f94720cfc31717c620fcab120f7",
    "fields": {
      "intitule_naf_65": "Façonnage et transformation du verre plat",
      "intitule_naf": "Façonnage et transformation du verre plat",
      "code_naf": "2312Z",
      "intitule_naf_40": "Façonnage & transformation du verre plat"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4cff1f82c12f276523f6ccf94549529019cfec8b",
    "fields": {
      "intitule_naf_65": "Façonnage et transformation du verre plat",
      "intitule_naf": "Façonnage et transformation du verre plat",
      "code_naf": "2312",
      "intitule_naf_40": "Façonnage & transformation du verre plat"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f5a91e3558f4a07c47f221de38a428690da8f8d1",
    "fields": {
      "intitule_naf_65": "Fabrication d'articles chaussants à mailles",
      "intitule_naf": "Fabrication d'articles chaussants à mailles",
      "code_naf": "1431",
      "intitule_naf_40": "Fabric. d'articles chaussants à mailles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "151c3cdcf76363ea90a8cf0d9cb379ea38c926f2",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres produits alimentaires",
      "intitule_naf": "Fabrication d'autres produits alimentaires",
      "code_naf": "108",
      "intitule_naf_40": "Fabric. d'autres produits alimentaires"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9ae275903c9d0a032610cc6f32bbf2f7205e2171",
    "fields": {
      "intitule_naf_65": "Fabrication de parquets assemblés",
      "intitule_naf": "Fabrication de parquets assemblés",
      "code_naf": "1622",
      "intitule_naf_40": "Fabrication de parquets assemblés"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b62a9fa62ee3835e6b750c6ff526bd51aff17b43",
    "fields": {
      "intitule_naf_65": "Fabrication de bière",
      "intitule_naf": "Fabrication de bière",
      "code_naf": "1105Z",
      "intitule_naf_40": "Fabrication de bière"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "897df801594328472c38b51086b2369ce70063ca",
    "fields": {
      "intitule_naf_65": "Cokéfaction",
      "intitule_naf": "Cokéfaction",
      "code_naf": "191",
      "intitule_naf_40": "Cokéfaction"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "bbcc7115a6cd8fc4e5f682ce47933b8a44548f6d",
    "fields": {
      "intitule_naf_65": "Meunerie",
      "intitule_naf": "Meunerie",
      "code_naf": "1061A",
      "intitule_naf_40": "Meunerie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9753bbe9ae36640c02e198d48af34ac625d3aca7",
    "fields": {
      "intitule_naf_65": "Fab. instrum. & appar. de mesure, essai & navigation; horlogerie",
      "intitule_naf": "Fabrication d'instruments et d'appareils de mesure, d'essai et de navigation ; horlogerie",
      "code_naf": "265",
      "intitule_naf_40": "Fab. instr. mesure, navigat.; horlogerie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "edf27615d27586d2291fe22bb689cf64bae3ebc1",
    "fields": {
      "intitule_naf_65": "Fabrication d'articles en fils métalliques, chaînes et ressorts",
      "intitule_naf": "Fabrication d'articles en fils métalliques, de chaînes et de ressorts",
      "code_naf": "2593Z",
      "intitule_naf_40": "Fab. art.  fil métal., chaîne & ressort"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "dd1f6dca8366830adafaa3230e87f7647c181489",
    "fields": {
      "intitule_naf_65": "Fabrication de machines pour les industries textiles",
      "intitule_naf": "Fabrication de machines pour les industries textiles",
      "code_naf": "2894",
      "intitule_naf_40": "Fab. machine pour industries textiles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "bac19b6c2f1c6b01b70702734791331654778d9c",
    "fields": {
      "intitule_naf_65": "Fabrication de composants électroniques",
      "intitule_naf": "Fabrication de composants électroniques",
      "code_naf": "2611Z",
      "intitule_naf_40": "Fabrication de composants électroniques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9c454a1d12bde37712f73d374111f4f420f9ad81",
    "fields": {
      "intitule_naf_65": "Fabrication d'équipements automobiles",
      "intitule_naf": "Fabrication d'équipements automobiles",
      "code_naf": "293",
      "intitule_naf_40": "Fabrication d'équipements automobiles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "45f5df77cee59bfdf943dd4d62ef32abc8d41247",
    "fields": {
      "intitule_naf_65": "Réparation et maintenance navale",
      "intitule_naf": "Réparation et maintenance navale",
      "code_naf": "3315Z",
      "intitule_naf_40": "Réparation et maintenance navale"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4f08f046035fc76755ebec9ff8a7e612a71051c8",
    "fields": {
      "intitule_naf_65": "Production d'électricité",
      "intitule_naf": "Production d'électricité",
      "code_naf": "3511",
      "intitule_naf_40": "Production d'électricité"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ed8bc9bc5b5e72710b26b685bed1533debc4d4c4",
    "fields": {
      "intitule_naf_65": "Fabrication de matelas",
      "intitule_naf": "Fabrication de matelas",
      "code_naf": "3103",
      "intitule_naf_40": "Fabrication de matelas"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "093f01a7430e215fd6f8962c23ea462c7e12bfab",
    "fields": {
      "intitule_naf_65": "Comm. détail de quincaillerie, peintures et verres (mag.> 400 m2)",
      "intitule_naf": "Commerce de détail de quincaillerie, peintures et verres en grandes surfaces (400 m2et plus)",
      "code_naf": "4752B",
      "intitule_naf_40": "Com. dét. quinc. pein. etc. (mag.>400m2)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "71564375b4ec02cedbe8ec59d3fda97067dd52e7",
    "fields": {
      "intitule_naf_65": "Gestion sites monuments historiques & attractions tourist. simil.",
      "intitule_naf": "Gestion des sites et monuments historiques et des attractions touristiques similaires",
      "code_naf": "9103",
      "intitule_naf_40": "Gestion site histor. & attraction simil."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a53d4c3a43ff2065c3e95a3cba3452467901770c",
    "fields": {
      "intitule_naf_65": "Hébergement social pour personnes âgées ou handicapées physiques",
      "intitule_naf": "Hébergement social pour personnes âgées ou handicapées physiques",
      "code_naf": "873",
      "intitule_naf_40": "Hébgt soc. perso. âgées, hand. physiques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "baaceff2af2036a9377b3f22a52ef92143e78ff5",
    "fields": {
      "intitule_naf_65": "Commerce de détail de boissons en magasin spécialisé",
      "intitule_naf": "Commerce de détail de boissons en magasin spécialisé",
      "code_naf": "4725",
      "intitule_naf_40": "Com. détail boisson en magasin spéciali."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b50a0903b11694c31a544c5300f21140759c3197",
    "fields": {
      "intitule_naf_65": "Activités liées aux systèmes de sécurité",
      "intitule_naf": "Activités liées aux systèmes de sécurité",
      "code_naf": "8020Z",
      "intitule_naf_40": "Activités liées aux systèmes de sécurité"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2c2c9ec2c6ac2191c673f5e5ec86d5ace4fbc42a",
    "fields": {
      "intitule_naf_65": "Hébergement social pour handicapés  physiques",
      "intitule_naf": "Hébergement social pour handicapés  physiques",
      "code_naf": "8730B",
      "intitule_naf_40": "Hébergt social pour handicapés physiques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "98eb1422d3cb89491dea689141bcecb33d1e938c",
    "fields": {
      "intitule_naf_65": "Réparation d'articles d'horlogerie et de bijouterie",
      "intitule_naf": "Réparation d'articles d'horlogerie et de bijouterie",
      "code_naf": "9525",
      "intitule_naf_40": "Répar.articles horlogerie & bijouterie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8c311d76fe250981a9457133c6f6528812a00b4a",
    "fields": {
      "intitule_naf_65": "Autres activités de poste et de courrier",
      "intitule_naf": "Autres activités de poste et de courrier",
      "code_naf": "5320",
      "intitule_naf_40": "Autres activités de poste et de courrier"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ae7a5225fa88e5ea8b0ffd13c6c5dfcb4c73bfc4",
    "fields": {
      "intitule_naf_65": "Autres activités liées au sport",
      "intitule_naf": "Autres activités liées au sport",
      "code_naf": "9319",
      "intitule_naf_40": "Autres activités liées au sport"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9d59b08888ffe3b7ad87156a0754df05565a9791",
    "fields": {
      "intitule_naf_65": "Autres services personnels",
      "intitule_naf": "Autres services personnels",
      "code_naf": "96",
      "intitule_naf_40": "Autres services personnels"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "519dcb262b7945008edd962ae23ec6d9359e1eca",
    "fields": {
      "intitule_naf_65": "Services funéraires",
      "intitule_naf": "Services funéraires",
      "code_naf": "9603",
      "intitule_naf_40": "Services funéraires"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a5d724bc4ce4cdaee99949a7226c9486088752e0",
    "fields": {
      "intitule_naf_65": "Autres activités de nettoyage n.c.a.",
      "intitule_naf": "Autres activités de nettoyage n.c.a.",
      "code_naf": "8129B",
      "intitule_naf_40": "Autres activités de nettoyage n.c.a."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c7066b9aefda247fd39b19711dc0dbad14de8fc6",
    "fields": {
      "intitule_naf_65": "Autres services d'information n.c.a.",
      "intitule_naf": "Autres services d'information n.c.a.",
      "code_naf": "6399",
      "intitule_naf_40": "Autres services d'information n.c.a."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "ce12c693fc9c536539209b921f9c9bc229721d3f",
    "fields": {
      "intitule_naf_65": "Commerce de détail de la chaussure",
      "intitule_naf": "Commerce de détail de la chaussure",
      "code_naf": "4772A",
      "intitule_naf_40": "Commerce de détail de la chaussure"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "29e58c29fde55c3d7f5139a9fe0c3e11a8b98150",
    "fields": {
      "intitule_naf_65": "Édition de revues et périodiques",
      "intitule_naf": "Édition de revues et périodiques",
      "code_naf": "5814",
      "intitule_naf_40": "Édition de revues et périodiques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "03cc58e651049e3e38a27ac656cfaf7c0c7646b8",
    "fields": {
      "intitule_naf_65": "Hôtels et hébergement similaire",
      "intitule_naf": "Hôtels et hébergement similaire",
      "code_naf": "5510Z",
      "intitule_naf_40": "Hôtels et hébergement similaire"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "0453912991022daab31c30389bef3e03f2474540",
    "fields": {
      "intitule_naf_65": "Autres activités de nettoyage",
      "intitule_naf": "Autres activités de nettoyage",
      "code_naf": "8129",
      "intitule_naf_40": "Autres activités de nettoyage"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8ed4a35dce1164617b2fd2d9b3d3278e6bd1f8cd",
    "fields": {
      "intitule_naf_65": "Messagerie, fret express",
      "intitule_naf": "Messagerie, fret express",
      "code_naf": "5229A",
      "intitule_naf_40": "Messagerie, fret express"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "cb3c8807418b9af8331eb8e202aa6599f7022410",
    "fields": {
      "intitule_naf_65": "Activités d'ingénierie",
      "intitule_naf": "Activités d'ingénierie",
      "code_naf": "7112",
      "intitule_naf_40": "Activités d'ingénierie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "7a5d71f06c649904fe89950df46bb3fd9e04b662",
    "fields": {
      "intitule_naf_65": "Affaires étrangères",
      "intitule_naf": "Affaires étrangères",
      "code_naf": "8421",
      "intitule_naf_40": "Affaires étrangères"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "498689380148dd5dad47bbbe3210ffb3b2db3311",
    "fields": {
      "intitule_naf_65": "Transports par eau",
      "intitule_naf": "Transports par eau",
      "code_naf": "50",
      "intitule_naf_40": "Transports par eau"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5afb015798ab6084be025c0ac38c89dde48aa1ca",
    "fields": {
      "intitule_naf_65": "Réparation appareils électromén. & équipts maison & jardin",
      "intitule_naf": "Réparation d'appareils électroménagers et d'équipements pour la maison et le jardin",
      "code_naf": "9522Z",
      "intitule_naf_40": "Répar. électromén. & éqpt maison & jard."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "00a6f86c82eb3e0470b14ba87948ca971ee073ff",
    "fields": {
      "intitule_naf_65": "Post-production films cinématograph. vidéo & prog. de télévision",
      "intitule_naf": "Post-production de films cinématographiques, de vidéo et de programmes de télévision",
      "code_naf": "5912Z",
      "intitule_naf_40": "Post-production film & prog. télévision"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "325b8d8702d27ba0c036aa8b99cbb095770d8884",
    "fields": {
      "intitule_naf_65": "Culture plantes à épices aromatiques médicinales pharmaceutiques",
      "intitule_naf": "Culture de plantes à épices, aromatiques, médicinales et pharmaceutiques",
      "code_naf": "0128Z",
      "intitule_naf_40": "Cult. plante aromatiq. médicin. pharma."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2a99728d50669f720b92e710a3d0015f46f02586",
    "fields": {
      "intitule_naf_65": "Services de soutien à l'exploitation forestière",
      "intitule_naf": "Services de soutien à l'exploitation forestière",
      "code_naf": "0240Z",
      "intitule_naf_40": "Services de soutien à l'expl. forestière"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "a5094b4310c01a12a37f70d904da0b44a6ed3b02",
    "fields": {
      "intitule_naf_65": "Culture de fruits tropicaux et subtropicaux",
      "intitule_naf": "Culture de fruits tropicaux et subtropicaux",
      "code_naf": "0122Z",
      "intitule_naf_40": "Culture fruits tropicaux et subtropicaux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4331597a10ab5edf3579f0e6f342296525637619",
    "fields": {
      "intitule_naf_65": "Extraction de minerais de fer",
      "intitule_naf": "Extraction de minerais de fer",
      "code_naf": "0710Z",
      "intitule_naf_40": "Extraction de minerais de fer"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "191e70947ae7d14c367af00ed5e3f8a74d1ff4a9",
    "fields": {
      "intitule_naf_65": "Extraction de minerais de fer",
      "intitule_naf": "Extraction de minerais de fer",
      "code_naf": "71",
      "intitule_naf_40": "Extraction de minerais de fer"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "436815e1373ecf7333ca46ab471bd74171dba96a",
    "fields": {
      "intitule_naf_65": "Extraction de houille",
      "intitule_naf": "Extraction de houille",
      "code_naf": "510",
      "intitule_naf_40": "Extraction de houille"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "965ac672282be3cd6b5fee02fb73928a916c6c56",
    "fields": {
      "intitule_naf_65": "Extraction pierres ornement. construc. calcaire industriel, gypse",
      "intitule_naf": "Extraction de pierres ornementales et de construction, de calcaire industriel, de gypse, de craie et d'ardoise",
      "code_naf": "0811Z",
      "intitule_naf_40": "Extr. pierre ornement. & construct. etc."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "745159e0eeb42a9dfa85b101fa43c82be79db138",
    "fields": {
      "intitule_naf_65": "Extraction pierres ornement. construc. calcaire industriel, gypse",
      "intitule_naf": "Extraction de pierres ornementales et de construction, de calcaire industriel, de gypse, de craie et d'ardoise",
      "code_naf": "811",
      "intitule_naf_40": "Extr. pierre ornement. & construct. etc."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3c6356dd1548b52d908d1caff54b1177fde88c31",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres vêtements et accessoires",
      "intitule_naf": "Fabrication d'autres vêtements et accessoires",
      "code_naf": "1419Z",
      "intitule_naf_40": "Fabric. autres vêtements et accessoires"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8906f1fddff4ec8ddde66c69a65e39554c3d339c",
    "fields": {
      "intitule_naf_65": "Fabrication de pain et de pâtisserie fraîche",
      "intitule_naf": "Fabrication de pain et de pâtisserie fraîche",
      "code_naf": "1071",
      "intitule_naf_40": "Fab. de pain et de pâtisserie fraîche"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c3fcfaf422907ace9ee0f0d4153972cb8dbc81b0",
    "fields": {
      "intitule_naf_65": "Transformation du thé et du café",
      "intitule_naf": "Transformation du thé et du café",
      "code_naf": "1083",
      "intitule_naf_40": "Transformation du thé et du café"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "bf29f760d60cf8b0dc1511fd6fae743906e21359",
    "fields": {
      "intitule_naf_65": "Fabrication d'articles en fourrure",
      "intitule_naf": "Fabrication d'articles en fourrure",
      "code_naf": "1420Z",
      "intitule_naf_40": "Fabrication d'articles en fourrure"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b9edb99536a5034eaa568e338559ea29c798b13c",
    "fields": {
      "intitule_naf_65": "Fabrication de produits amylacés",
      "intitule_naf": "Fabrication de produits amylacés",
      "code_naf": "1062Z",
      "intitule_naf_40": "Fabrication de produits amylacés"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "1835c9d9850b842ff4fde2228483a348e5f93fd5",
    "fields": {
      "intitule_naf_65": "Fabrication de glaces et sorbets",
      "intitule_naf": "Fabrication de glaces et sorbets",
      "code_naf": "1052Z",
      "intitule_naf_40": "Fabrication de glaces et sorbets"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "6c40bedcb8239ec282ea23262dff9d14a6e52089",
    "fields": {
      "intitule_naf_65": "Reliure et activités connexes",
      "intitule_naf": "Reliure et activités connexes",
      "code_naf": "1814",
      "intitule_naf_40": "Reliure et activités connexes"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "11fc04ae1a99af350561b3b43d1d2183dfee6c1c",
    "fields": {
      "intitule_naf_65": "Ennoblissement textile",
      "intitule_naf": "Ennoblissement textile",
      "code_naf": "1330",
      "intitule_naf_40": "Ennoblissement textile"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d043582936756841f6bfa60e787d7bc794c6a7ac",
    "fields": {
      "intitule_naf_65": "Fabrication de pesticides et d'autres produits agrochimiques",
      "intitule_naf": "Fabrication de pesticides et d'autres produits agrochimiques",
      "code_naf": "202",
      "intitule_naf_40": "Fab. pesticide & aut. prod. agrochimique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f52d30e48fac525ee97f76bcb391d6f95bf38b49",
    "fields": {
      "intitule_naf_65": "Fabrication d'isolateurs et pièces isolantes en céramique",
      "intitule_naf": "Fabrication d'isolateurs et pièces isolantes en céramique",
      "code_naf": "2343",
      "intitule_naf_40": "Fab. isolateur & pièce isolante céramiq."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f8e01fa8269a0f826a5077b1a196bed8773306e1",
    "fields": {
      "intitule_naf_65": "Fabrication de fibres artificielles ou synthétiques",
      "intitule_naf": "Fabrication de fibres artificielles ou synthétiques",
      "code_naf": "2060Z",
      "intitule_naf_40": "Fab.  fibre artificielle ou synthétique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "36d3b8f77b086f382ec704779414fe898cb3706a",
    "fields": {
      "intitule_naf_65": "Fabrication de parfums et de produits pour la toilette",
      "intitule_naf": "Fabrication de parfums et de produits pour la toilette",
      "code_naf": "2042",
      "intitule_naf_40": "Fab. parfum & produit pour la toilette"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d87be0a5c7ad14adea8dba77832998f7df83f270",
    "fields": {
      "intitule_naf_65": "Profilage à froid par formage ou pliage",
      "intitule_naf": "Profilage à froid par formage ou pliage",
      "code_naf": "2433Z",
      "intitule_naf_40": "Profilage à froid par formage ou pliage"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9bdbd5c288bd691d1659619541355b7babc11d84",
    "fields": {
      "intitule_naf_65": "Fabrication de verre creux",
      "intitule_naf": "Fabrication de verre creux",
      "code_naf": "2313Z",
      "intitule_naf_40": "Fabrication de verre creux"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d07350a46a1edd332f936edc7f0171db59564b11",
    "fields": {
      "intitule_naf_65": "Cokéfaction",
      "intitule_naf": "Cokéfaction",
      "code_naf": "1910Z",
      "intitule_naf_40": "Cokéfaction"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2d0c5ed5f8fcb1758fe7dc0586578c2c9a06210e",
    "fields": {
      "intitule_naf_65": "Sidérurgie",
      "intitule_naf": "Sidérurgie",
      "code_naf": "2410Z",
      "intitule_naf_40": "Sidérurgie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "eb7dd67af676dc9f88c0c524d5fcbaaf6c44d1ac",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres fils et câbles électroniques ou électriques",
      "intitule_naf": "Fabrication d'autres fils et câbles électroniques ou électriques",
      "code_naf": "2732",
      "intitule_naf_40": "Fab. aut. fil & câble éltron. ou éltriq."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b540270bd5bc469da34f71248279784a994601d4",
    "fields": {
      "intitule_naf_65": "Fabrication d'appareils ménagers non électriques",
      "intitule_naf": "Fabrication d'appareils ménagers non électriques",
      "code_naf": "2752",
      "intitule_naf_40": "Fab. appareils ménagers non électriques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "25e4d6332feb4abb11964ae4792f5fe24f993295",
    "fields": {
      "intitule_naf_65": "Fabrication d'autres articles de robinetterie",
      "intitule_naf": "Fabrication d'autres articles de robinetterie",
      "code_naf": "2814",
      "intitule_naf_40": "Fabric. autres articles de robinetterie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "21ee63dbfd3153a40b65838509fd8ac4ab511e95",
    "fields": {
      "intitule_naf_65": "Forge, emboutissage, estampage; métallurgie des poudres",
      "intitule_naf": "Forge, emboutissage, estampage ; métallurgie des poudres",
      "code_naf": "255",
      "intitule_naf_40": "Forge, etc.; métallurgie des poudres"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e174b30a4616da1e2cfefa0325b76bb50003accf",
    "fields": {
      "intitule_naf_65": "Fabrication de câbles de fibres optiques",
      "intitule_naf": "Fabrication de câbles de fibres optiques",
      "code_naf": "2731Z",
      "intitule_naf_40": "Fabrication de câbles de fibres optiques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c77d3ce829ee4508fe8ae0fa51e9753fc26b463e",
    "fields": {
      "intitule_naf_65": "Autres industries manufacturières",
      "intitule_naf": "Autres industries manufacturières",
      "code_naf": "32",
      "intitule_naf_40": "Autres industries manufacturières"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2e88af64d952b1751cfdd7872febe1d23cc05004",
    "fields": {
      "intitule_naf_65": "Fabrication de meubles de cuisine",
      "intitule_naf": "Fabrication de meubles de cuisine",
      "code_naf": "3102Z",
      "intitule_naf_40": "Fabrication de meubles de cuisine"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f1794ceceb120cdabeda6862e2c461c65fa5d99e",
    "fields": {
      "intitule_naf_65": "Fabrication de motocycles",
      "intitule_naf": "Fabrication de motocycles",
      "code_naf": "3091Z",
      "intitule_naf_40": "Fabrication de motocycles"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "aac7a095df5c4c96a68644a65766e6fb00c54a6c",
    "fields": {
      "intitule_naf_65": "Industrie automobile",
      "intitule_naf": "Industrie automobile",
      "code_naf": "29",
      "intitule_naf_40": "Industrie automobile"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "8b75742a9f31a68d66f0409feca404ea8cee9231",
    "fields": {
      "intitule_naf_65": "Réparation d'ouvrages en métaux, de machines et d'équipements",
      "intitule_naf": "Réparation d'ouvrages en métaux, de machines et d'équipements",
      "code_naf": "331",
      "intitule_naf_40": "Répar. ouvrage en métaux, mach. & équipt"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9a6210ba07cb5ac21785f98b090518423b8fc970",
    "fields": {
      "intitule_naf_65": "Travaux d'installation équipements thermiques et climatisation",
      "intitule_naf": "Travaux d'installation d'équipements thermiques et de climatisation",
      "code_naf": "4322B",
      "intitule_naf_40": "Travaux instal. équipt thermique & clim."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "be2d0984f4894b2e9759ade7ceba213e88b8026c",
    "fields": {
      "intitule_naf_65": "Fabrication de matériel médico-chirurgical et dentaire",
      "intitule_naf": "Fabrication de matériel médico-chirurgical et dentaire",
      "code_naf": "3250A",
      "intitule_naf_40": "Fab. matériel médico-chirurg. & dentaire"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4cfaea7c3c5cf5f838f28f6756fe72a9e037fb92",
    "fields": {
      "intitule_naf_65": "Construction d'autres ouvrages de génie civil n.c.a.",
      "intitule_naf": "Construction d'autres ouvrages de génie civil n.c.a.",
      "code_naf": "4299Z",
      "intitule_naf_40": "Constr. aut. ouvrage de génie civil nca."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f93bc59f81539e1e874e9ed815ea5dadd7c0b47a",
    "fields": {
      "intitule_naf_65": "Captage, traitement et distribution d'eau",
      "intitule_naf": "Captage, traitement et distribution d'eau",
      "code_naf": "3600",
      "intitule_naf_40": "Captage, traitement & distribution d'eau"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "45bc92f036cf1967f9ef73f1793cdcc2edc086f1",
    "fields": {
      "intitule_naf_65": "Captage, traitement et distribution d'eau",
      "intitule_naf": "Captage, traitement et distribution d'eau",
      "code_naf": "360",
      "intitule_naf_40": "Captage, traitement & distribution d'eau"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9c69d2809d7c8288f28211c6ca512a5408839b78",
    "fields": {
      "intitule_naf_65": "Activités manufacturières n.c.a.",
      "intitule_naf": "Activités manufacturières n.c.a.",
      "code_naf": "329",
      "intitule_naf_40": "Activités manufacturières n.c.a."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2334caad1d661e405002cc2a82d6111db7e05ca4",
    "fields": {
      "intitule_naf_65": "Travaux de plâtrerie",
      "intitule_naf": "Travaux de plâtrerie",
      "code_naf": "4331Z",
      "intitule_naf_40": "Travaux de plâtrerie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "755b340639e722a9454c0e74df24f6fcd9db7a20",
    "fields": {
      "intitule_naf_65": "Travaux de plâtrerie",
      "intitule_naf": "Travaux de plâtrerie",
      "code_naf": "4331",
      "intitule_naf_40": "Travaux de plâtrerie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "2a289cf491e901fa86b1bae55d69da148e580302",
    "fields": {
      "intitule_naf_65": "Frappe de monnaie",
      "intitule_naf": "Frappe de monnaie",
      "code_naf": "3211",
      "intitule_naf_40": "Frappe de monnaie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e7de4d73f6e080e90fef5fcdb9f49f9e1d49ad08",
    "fields": {
      "intitule_naf_65": "Commerce de gros de poissons, crustacés et mollusques",
      "intitule_naf": "Commerce de gros (commerce interentreprises) de poissons, crustacés et mollusques",
      "code_naf": "4638A",
      "intitule_naf_40": "Com. gros aut. alim. yc poisson crustacé"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "dd487146bf8f6966925c9c822ef9ecbd0d770a83",
    "fields": {
      "intitule_naf_65": "Commerce de détail produits pharmaceutiques (magasin spécialisé)",
      "intitule_naf": "Commerce de détail de produits pharmaceutiques en magasin spécialisé",
      "code_naf": "4773",
      "intitule_naf_40": "Comm. dét. produits pharmaceutiques (ms)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "c2ad17f44decaf7a9dff507afeae632a3113a07d",
    "fields": {
      "intitule_naf_65": "Commerce de gros alimentaire spécialisé divers",
      "intitule_naf": "Commerce de gros (commerce interentreprises) alimentaire spécialisé divers",
      "code_naf": "4638B",
      "intitule_naf_40": "Comm. gros alimentaire spécialisé divers"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "9eaf714b386f4b62840a89fca5939d0a607e34c5",
    "fields": {
      "intitule_naf_65": "Activ. poste dans le cadre d'une obligation de service universel",
      "intitule_naf": "Activités de poste dans le cadre d'une obligation de service universel",
      "code_naf": "5310Z",
      "intitule_naf_40": "Activ. poste (obligation sce universel)"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "79c451f12f95ce07cc64a00b67d250e56df60239",
    "fields": {
      "intitule_naf_65": "Édition et diffusion de programmes radio",
      "intitule_naf": "Édition et diffusion de programmes radio",
      "code_naf": "601",
      "intitule_naf_40": "Édition et diffusion de programmes radio"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "99ec3d29f90f2cb6346a99cb61ee869ea8fb3aa2",
    "fields": {
      "intitule_naf_65": "Commerce de gros non spécialisé",
      "intitule_naf": "Commerce de gros non spécialisé",
      "code_naf": "4690",
      "intitule_naf_40": "Commerce de gros non spécialisé"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "868c54d5470551fdaeba948f596d7a109c7caba6",
    "fields": {
      "intitule_naf_65": "Travaux de peinture et vitrerie",
      "intitule_naf": "Travaux de peinture et vitrerie",
      "code_naf": "4334Z",
      "intitule_naf_40": "Travaux de peinture et vitrerie"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "82cdadfeef88814e60eada188cc2fd90a463d7b2",
    "fields": {
      "intitule_naf_65": "Transports ferroviaires de fret",
      "intitule_naf": "Transports ferroviaires de fret",
      "code_naf": "4920Z",
      "intitule_naf_40": "Transports ferroviaires de fret"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b13c446d171a9ec780ea1599d9c22c6f91f5f01b",
    "fields": {
      "intitule_naf_65": "Traitement de données, hébergement et activités connexes",
      "intitule_naf": "Traitement de données, hébergement et activités connexes",
      "code_naf": "6311",
      "intitule_naf_40": "Traitt donnée, hébergt & activ. connexe"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e9bbbe76e52f81ecb050925814cd26c7d2dd630c",
    "fields": {
      "intitule_naf_65": "Autres services de réservation et activités connexes",
      "intitule_naf": "Autres services de réservation et activités connexes",
      "code_naf": "7990",
      "intitule_naf_40": "Autre serv. réservation & activ. connexe"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "b2f41b7b9bb188c6b3469aa9acf2d453ef2b9fee",
    "fields": {
      "intitule_naf_65": "Activités des marchands de biens immobiliers",
      "intitule_naf": "Activités des marchands de biens immobiliers",
      "code_naf": "6810Z",
      "intitule_naf_40": "Activité marchands de biens immobiliers"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "01e9e5f81167e1b068dc2e87917603148c3b596d",
    "fields": {
      "intitule_naf_65": "Gestion d'installations informatiques",
      "intitule_naf": "Gestion d'installations informatiques",
      "code_naf": "6203",
      "intitule_naf_40": "Gestion d'installations informatiques"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "dff1638e26b8752a3e15971a4774a87bcddb0a9e",
    "fields": {
      "intitule_naf_65": "Télécommunications par satellite",
      "intitule_naf": "Télécommunications par satellite",
      "code_naf": "6130",
      "intitule_naf_40": "Télécommunications par satellite"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "23fec00b4651d16c28568478cf61c0a74e921a4d",
    "fields": {
      "intitule_naf_65": "Activités des agences de presse",
      "intitule_naf": "Activités des agences de presse",
      "code_naf": "6391",
      "intitule_naf_40": "Activités des agences de presse"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "5662fd1057a177ab70e4254a121d4a7d165e4a78",
    "fields": {
      "intitule_naf_65": "Nettoyage courant des bâtiments",
      "intitule_naf": "Nettoyage courant des bâtiments",
      "code_naf": "8121",
      "intitule_naf_40": "Nettoyage courant des bâtiments"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "3e23921c5270ce38a380e57f164560f21749ba9a",
    "fields": {
      "intitule_naf_65": "Activités de sécurité privée",
      "intitule_naf": "Activités de sécurité privée",
      "code_naf": "801",
      "intitule_naf_40": "Activités de sécurité privée"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "4bd3f6f421716e6f10950894f9e9e5c1d6a4151b",
    "fields": {
      "intitule_naf_65": "Activités vétérinaires",
      "intitule_naf": "Activités vétérinaires",
      "code_naf": "7500",
      "intitule_naf_40": "Activités vétérinaires"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e81bdbdfa4919d941a04f17bee3dde364df4f19d",
    "fields": {
      "intitule_naf_65": "Gestion de fonds",
      "intitule_naf": "Gestion de fonds",
      "code_naf": "6630",
      "intitule_naf_40": "Gestion de fonds"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "fb7c1600f86d034f80665ac39e12576bab1aa50f",
    "fields": {
      "intitule_naf_65": "Bibliothèques, archives, musées et autres activités culturelles",
      "intitule_naf": "Bibliothèques, archives, musées et autres activités culturelles",
      "code_naf": "910",
      "intitule_naf_40": "Biblioth. archive musée & aut. act. cul."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "d0b88372e542a5b3870438c8ae386d82d782cc79",
    "fields": {
      "intitule_naf_65": "Enseignement de disciplines sportives et d'activités de loisirs",
      "intitule_naf": "Enseignement de disciplines sportives et d'activités de loisirs",
      "code_naf": "8551",
      "intitule_naf_40": "Enseigmnt discipl. sport. & act. loisir."
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "bf69cb54610f7fa13b42ae1dfd2e6b761a460baf",
    "fields": {
      "intitule_naf_65": "Réparation de meubles et d'équipements du foyer",
      "intitule_naf": "Réparation de meubles et d'équipements du foyer",
      "code_naf": "9524Z",
      "intitule_naf_40": "Réparation meubles & d'équipt du foyer"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "f5e54190aef102d52ece33beae35303e9963dde6",
    "fields": {
      "intitule_naf_65": "Réparation de biens personnels et domestiques",
      "intitule_naf": "Réparation de biens personnels et domestiques",
      "code_naf": "952",
      "intitule_naf_40": "Réparation  biens personnel & domestique"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "88b6790f805c09904529999b0272e17022a839d4",
    "fields": {
      "intitule_naf_65": "Autres activités pour la santé humaine",
      "intitule_naf": "Autres activités pour la santé humaine",
      "code_naf": "869",
      "intitule_naf_40": "Autres activités pour la santé humaine"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "fae0891f2622b2c2f279a448d9614df6028f7e40",
    "fields": {
      "intitule_naf_65": "Activités de conditionnement",
      "intitule_naf": "Activités de conditionnement",
      "code_naf": "8292Z",
      "intitule_naf_40": "Activités de conditionnement"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  },
  {
    "datasetid": "nomenclature-dactivites-francaise-naf-rev-2-code-ape",
    "recordid": "e5adba045562d95c31f0e04d9c79f83321749c8d",
    "fields": {
      "intitule_naf_65": "Enseignements divers",
      "intitule_naf": "Enseignements divers",
      "code_naf": "8559",
      "intitule_naf_40": "Enseignements divers"
    },
    "record_timestamp": "2017-09-21T12:39:51+02:00"
  }
]
