import { Component, OnInit } from '@angular/core';
import { CatalogueService } from "../../../services/catalogue.service";
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from "../../../services/user.service";

@Component({
  selector: 'app-details-catalogue',
  templateUrl: './details-catalogue.component.html',
  styleUrls: ['../../utilisateur/details-utilisateur/details-utilisateur.component.css'],
})
export class DetailsCatalogueComponent implements OnInit {
  article: any;
  id: any;
  utilisateur: any;
  showMyContainer = 1;
  constructor(private catalogueService: CatalogueService, private activatedRoute: ActivatedRoute, private userService: UserService, private router: Router) {

  }
  ngOnInit() {
    this.id = localStorage.getItem('idUser')
    this.userService.getUserByID(this.id).subscribe(utilisateur => {
      this.utilisateur = utilisateur;
      if(!this.utilisateur){
        localStorage.removeItem('idUser');
        localStorage.clear();
        this.router.navigate(['/login']);
      }
    },
      err => {
        this.router.navigateByUrl('/accueil');
        return false;
      });
    let params: any = this.activatedRoute.snapshot.params;
    this.catalogueService.getArticleById(params.id).subscribe(data => {
      this.article = data
    },
      err => {
        return false;
      });
  }

  modifPart1() {
    this.showMyContainer = 1;
  }
  modifPart2() {
    this.showMyContainer = 2;
  }
  showPart() {
    return this.showMyContainer;
  }

}
