<app-navbar></app-navbar>
<br>
<div id="content-nav-page" class="row">
    <div class=" col-3 titre-menu-ajoutDossier" *ngIf="dossier">
      <a [routerLink]="['/dossier']" class="icon-f-left"></a>
      <b>{{dossier.numero}}</b><a>
        <img class="mb-3 mt-2 ml-2" src="assets/images/modifier-a.png" alt="">
      </a>
    </div>
    <div class="col-9 sous-menu">
      <b class="sous-menu-active1"  [routerLink]="['/dossier']">DOSSIER</b>
      <b class="sous-menu-active2 ml-4" [routerLink]="['/commande']" *ngIf="utilisateur && utilisateur.role === 'Super admin' || utilisateur && utilisateur.role === 'Administration' || utilisateur && utilisateur.role === 'Admin' || utilisateur && utilisateur.role === 'Responsable commandes' || (utilisateur && utilisateur.role === 'Equipe Atelier' && utilisateur.sousRole === 'Responsable magasin') || utilisateur && utilisateur.role === 'Utilisateur-client'"
        style="cursor: pointer">COMMANDE</b>
    </div>
  </div>
  <div class="col d-flex justify-content-start">
    <b class="obligatoir-text">(*) Ce champ est obligatoire</b>
  </div>
  <ng-template #customLoadingTemplate></ng-template>
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading>
  <form [formGroup]="form" class="form-horizontal" role="form">
    <!-- Content-show-dossier -->
    <div id="content-show-dossier" *ngIf="dossier">
      <div class="row content-cols-show">
        <div class="col-lg-4 col-md-12">
          <select class="select-statut" formControlName="client" name="client" [(ngModel)]="dossier.client" (change)="selectClient(dossier)">
            <option [ngValue]="dossier.client" selected>{{dossier.client.denomination}}</option>
            <option *ngFor="let client of clients" [ngValue]="client">{{client.denomination}}</option>
          </select>
          <a [routerLink]="['/ajoutClient']" title="Ajouter un nouveau client">
            <div class="icon-client-plus"></div>
          </a>
          <control-messages [control]="form.controls.client"></control-messages>
          <p style="color:red;" *ngIf="myClient && myClient.statut === 'Radiée'">Ce client est radié</p>
        </div>
        <div class="col-lg-4 col-md-12">
          <select class="select-statut" formControlName="etablissement" [(ngModel)]="etablissement" name="etablissement" (change)="selectEtablissement()">
            <option [ngValue]="dossier.etablissement" selected>{{dossier.etablissement}}</option>
            <option *ngFor="let etablissement of etablissements" [ngValue]="etablissement">{{etablissement.nom}}</option>
          </select>
          <control-messages [control]="form.controls.etablissement"></control-messages>
          <p *ngIf="showDirectionEtablissement && selectedClient" style="color: red;">Aucun établissement secondaire trouvé !
            <a [routerLink]="['/client/details',selectedClient.id]" title="Ajouter un nouveau établissement pour ce client">
              <div class="icon-client-plus"></div>
            </a>
          </p>
          <div class="form-group col-md-12 col-sm-12 etablissement-info-affichage" *ngIf="selectedEtablissement">
            <label for="prenom" class="titre-info-etablissement bold">Type et l'adresse d'établissement</label>
            <li style="list-style-type: none;"><span class="bold">Type</span> : {{selectedEtablissement.type}}</li>
            <li style="list-style-type: none;"><span class="bold">Pays</span> : {{selectedEtablissement.adresse.pays}}</li>
            <li style="list-style-type: none;"><span class="bold">Ville</span> : {{selectedEtablissement.adresse.ville}}</li>
            <li style="list-style-type: none;"><span class="bold">Rue</span> : {{selectedEtablissement.adresse.n}} {{selectedEtablissement.adresse.rue}} {{selectedEtablissement.adresse.codePostal}}</li>
            <li style="list-style-type: none;"><span class="bold">Statut</span> : {{selectedEtablissement.statutEtab}} <span *ngIf="selectedEtablissement.statutEtab === '' || !selectedEtablissement.statutEtab">Active</span></li>
            <br>
          </div>
        </div>
        <div class="col-lg-4 col-md-12">
          <select class="select-statut " formControlName="chargeAffaire" [(ngModel)]="dossier.chargeAffaire" name="chargeAffaire" (change)="showChargeAffaireDetails()">
            <option [ngValue]="dossier.chargeAffaire" *ngIf="dossier.chargeAffaire" selected>{{dossier.chargeAffaire.nom}} {{dossier.chargeAffaire.prenom}}</option>
            <option *ngFor="let chgeAff of AllselectedchargeAffaires" [ngValue]="chgeAff">{{chgeAff.nom}} {{chgeAff.prenom}}</option>
          </select>
          <control-messages [control]="form.controls.chargeAffaire"></control-messages>
          <a [routerLink]="['/ajoutChargeAffaire']" title="Ajouter un nouveau chargé d'affaires">
            <div class="icon-chargé-plus"></div>
          </a>
          <div class="form-group col-md-12 col-sm-12 etablissement-info-affichage" *ngIf="selectedChargeAffaires">
            <label for="prenom" class="titre-info-etablissement bold">Détails du chargé d'affaires</label>
            <li style="list-style-type: none;"><span class="bold">Nom & Prénom</span> : {{selectedEtablissement.type}}</li>
            <li style="list-style-type: none;"><span class="bold">Email</span> : {{selectedEtablissement.adresse.pays}}</li>
            <li style="list-style-type: none;"><span class="bold">Téléphone</span> : {{selectedEtablissement.adresse.ville}}</li>
            <li style="list-style-type: none;" *ngIf="selectedChargeAffaires.client"><span class="bold">Client</span> : {{selectedChargeAffaires.client.denomination}} - {{selectedChargeAffaires.client.acronyme }}</li>
            <br>
          </div>
        </div>
      </div>
      <div class="row content-cols-show">
        <div class="col-lg-4 col-md-12">
          <input class="input-show" type="text" formControlName="numero" name="numero" [(ngModel)]="dossier.numero" value="{{dossier.numero}}" placeholder="NUMÉRO DU DOSSIER">
          <control-messages [control]="form.controls.numero"></control-messages>
          <p style="color:red;" *ngIf="errorNumber">Ce numéro existe déjà !</p>
          <p *ngIf="form.controls['numero'].touched && form.controls['numero'].hasError('maxlength')" style="color:red">
            25 caractères maximum
          </p>
  
        </div>
        <div class="col-lg-4 col-md-12">
          <select class="select-statut " formControlName="type" name="type" [(ngModel)]="dossier.type">
            <option value="" disabled selected>TYPE*</option>
            <option>Etude</option>
            <option>Réalisation</option>
            <option>Intervention</option>
            <option>Fournitures</option>
          </select>
        </div>
        <div class="col-lg-4 col-md-12">
          <input class="input-show" type="date" formControlName="dateReception" name="dateReception" [(ngModel)]="dossier.dateReception" value="{{dossier.dateReception}}" placeholder="DATE DE RÉCEPTION DOSSIER" [max]="getBigDate()">
          <control-messages [control]="form.controls.dateReception"></control-messages>
        </div>
      </div>
      <div class="row content-cols-show">
        <div class="col-lg-4 col-md-12">
          <input class="input-show" type="text" formControlName="nom" name="nom" [(ngModel)]="dossier.nom" value="{{dossier.nom}}" placeholder="NOM DU DOSSIER">
          <control-messages [control]="form.controls.nom"></control-messages>
          <p *ngIf="form.controls['nom'].touched && form.controls['nom'].hasError('maxlength')" style="color:red">
                  100 caractères maximum
         </p>
        </div>
        <div class="col-lg-4 col-md-12">
          <input class="input-show" type="date" formControlName="datel" name="datel" [min]="getDateReceptionDossier()" [(ngModel)]="dossier.datel" value="{{dossier.datel}}" placeholder="DATE LIMITE" [max]="getBigDate()">
          <control-messages [control]="form.controls.datel"></control-messages>
          <p *ngIf="errorDate" style="color:red">La date limite ne doit pas être antérieur à la date de réception de dossier</p>
        </div>
        <div class="col-lg-4 col-md-12">
          <input class="input-show" type="text" formControlName="coef" name="coef" [(ngModel)]="dossier.coef" value="{{dossier.coef}}" placeholder="COEFICIENT DU DOSSIER">
          <control-messages [control]="form.controls.coef"></control-messages>
        </div>
      </div>
      <div class="row content-cols-show">
        <div class="col-lg-6 col-md-12 col-sm-12">
          <div id="drop_file_zone">
            <div id="drag_upload_file">
              <b>FICHIERS</b>
              <p></p>
              <div class="btn-file">
                <button class="btn-select-file">Choisir...</button>
                <input type="file" name="upfile" (change)="selectFile($event)" />
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12  mt-4">
            <h5 *ngFor=" let file of dossier.listfiles"> <strong>Fichier {{dossier.listfiles.indexOf(file)+1}}</strong> <span><a href="{{file.photo}}" style="cursor: pointer;text-decoration: underline;" target="_blank"><span *ngIf="!file.name">Lien 1</span> <span *ngIf="file.name">{{file.name}}</span></a></span><img src="assets/images/supp-modal.png" title="Supprimer ce fichier"  (click)="removeFichier(file)" class="delete-img" style="width:6%" alt=""></h5>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12">
          <div id="drop_file_zone">
            <div id="drag_upload_file">
              <b>MAIL</b>
              <p></p>
              <div class="btn-file">
                <button class="btn-select-file">Choisir...</button>
                <input type="file" name="upfile" (change)="selectFileEmail($event)" />
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12  mt-4" >
            <h5 *ngFor=" let file of dossier.listfilesEmail"> <strong>Fichier {{dossier.listfilesEmail.indexOf(file)+1}}</strong> <span><a href="{{file.photo}}" style="cursor: pointer;text-decoration: underline;" target="_blank"><span *ngIf="!file.name">Lien 1</span> <span *ngIf="file.name">{{file.name}}</span></a></span> <img src="assets/images/supp-modal.png" title="Supprimer ce fichier"  (click)="removeMail(file)" class="delete-img" style="width:6%" alt=""></h5>
          </div>
        </div>
  
      </div>
      <div class="row content-cols-show">
        <div class="col-lg-6 col-md-12 col-sm-12">
          <div id="drop_file_zone">
            <div id="drag_upload_file">
              <b>CCTP</b>
              <p></p>
              <div class="btn-file">
                <button class="btn-select-file">Choisir...</button>
                <input type="file" name="upfile" (change)="selectFileCCTP($event)" />
              </div>
            </div>
  
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12  mt-4">
            <h5 *ngFor=" let file of dossier.listfilesCCTP"> <strong>Fichier {{dossier.listfilesCCTP.indexOf(file)+1}}</strong> <span><a href="{{file.photo}}" style="cursor: pointer;text-decoration: underline;" target="_blank"><span *ngIf="!file.name">Lien 1</span> <span *ngIf="file.name">{{file.name}}</span></a></span> <img src="assets/images/supp-modal.png" title="Supprimer ce fichier"  (click)="removeCCTP(file)" class="delete-img" style="width:6%" alt=""></h5>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12">
          <div id="drop_file_zone">
            <div id="drag_upload_file">
              <b>PLAN</b>
              <p></p>
              <div class="btn-file">
                <button class="btn-select-file">Choisir...</button>
                <input type="file" name="upfile" (change)="selectFilePlan($event)" />
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12  mt-4">
            <h5 *ngFor=" let file of dossier.listfilesPlan"> <strong>Fichier {{dossier.listfilesPlan.indexOf(file)+1}}</strong> <span><a href="{{file.photo}}" style="cursor: pointer;text-decoration: underline;" target="_blank"><span *ngIf="!file.name">Lien 1</span> <span *ngIf="file.name">{{file.name}}</span></a></span> <img src="assets/images/supp-modal.png" title="Supprimer ce fichier" (click)="removePlan(file)" class="delete-img" style="width:6%" alt=""></h5>
          </div>
        </div>
      </div>
      <div class=" col d-flex justify-content-center" style="padding-bottom: 1%">
        <div class="col-auto nbr-dossier ">
          <table>
            <tr>
              <td style="padding-right: 30px;">
                <button class="btn-annule" style="width: 200px;height: 50px;" [routerLink]="['/dossier']">Annuler <img src="assets/images/btn-annule.png"> </button>
  
              </td>
              <td style="padding-right: 30px;">
                <button class="btn-valider" style="width: 200px;height: 50px;" [disabled]="!form.valid" (click)="onUpdateDossier(dossier)">Modifier <img src="assets/images/btn-valider.png"></button>
              </td>
            </tr>
          </table>
        </div>
  
      </div>
    </div>
  </form>
  