import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http} from '@angular/http';
import { ClientService } from "../../../services/client.service";
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { ValidationService } from '../../../services/validate/validate-error.service';
import { codesNav } from '../../../pays/codesNav';
import { triCodes } from '../../../pays/codesNav';
import { pays } from '../../../pays/pays';
import { tri } from '../../../pays/pays';
import { codepostale } from '../../../pays/villes';
import { ValidateService } from '../../../services/validate/validate.service';
import { environment } from '../../../../environments/environment';
import { UploadFileService } from "../../../services/upload-file-service.service";
import { isNumeric } from 'rxjs/util/isNumeric';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
import * as moment from 'moment';

@Component({
  selector: 'app-ajout-client',
  templateUrl: './ajout-client.component.html',
  styleUrls: ['../../utilisateur/ajout-utilisateur/ajout-utilisateur.component.scss'],
})
export class AjoutClientComponent implements OnInit {

  client: any;
  form: any;
  email: any;
  codes: any[] = [];
  pys: any[] = [];
  vlls: any[] = [];
  clients: any[] = [];
  successRegister = false;
  errorEmail = false;
  code: any;
  secteur: any;
  formCom: any;
  formEtablissement: any;
  addEtablissments = false;
  etablissements: any[] = [];
  nom: any;
  type = "Établissement principal";
  mail: any;
  tel: any;
  p = "Tunisie";
  codePostal: any;
  rue: any;
  n: any;
  ville: any;
  formBancaire: any;
  successAddEtab = false;
  formAddEtablissement: any;
  errorNom = false;
  errorType = false;
  errorMail = false;
  showChamps = false;
  errorSiret = false;
  disabled = false;
  roleAdministration = true;
  showAnnulationBouton = false;
  role: any;
  errorLengthSiren = false;
  showAndHoideDateRadiation = false;
  showAndHoideDateRadiationEtab= false;
  myfiles:any;
  sizeLimitOne = 10000000; //10MB
  loading = false
  sirenClient:any;
  showMyContainer = 1;
  etablissement:any;
  formAddEtablissementUpdate:any;
  dateNow = moment().subtract(1,'d').format('YYYY-MM-DD');
  bigDate = "9999-12-31"
  errorFistPart = ""
  errorSecondPart = ""
  errorThirdPart = ""
  errorFourthPart = ""
  errorOnchange = false;
  constructor(private http: Http, private uploadFileService:UploadFileService, private clientService: ClientService, private formBuilder: FormBuilder, private validateService: ValidateService,
    private router: Router) {
    this.form = this.formBuilder.group({
      type: ['Professionnel', [Validators.required]],
      denomination: ['', [Validators.required, Validators.minLength(4), ValidationService.validateEmptyField, Validators.maxLength(100)]],
      acronyme: ['', [Validators.minLength(4), ValidationService.validateEmptyField, Validators.maxLength(100)]],
      nomc: ['',],
      enseigne: ['',],
      siren: ['', Validators.max(99999999999999)],
      formeJuridique: ['Société anonyme SA', [Validators.required]],
      codeNaf: ['', Validators.required],
      datec: ['', ValidationService.dateValidator],
      statut:['Active'],
      dateRadiation:[''],
      etablissements: []
    })
    this.formCom = this.formBuilder.group({
      mail: ['', [Validators.required, ValidationService.emailValidator, ValidationService.nospaceValidator]],
      tel: ['', Validators.required],
      adresse: this.formBuilder.group({
        pays: ['Tunisie', [Validators.required]],
        codePostal: ['', [ValidationService.numberValidator,Validators.min(0),Validators.max(9999999)]],
        rue: ['',],
        n: ['', [ValidationService.numberValidator,Validators.min(0),Validators.max(9999999)]],
        ville: ['',],
      }),
      adresseWeb: this.formBuilder.group({
        facebook: ['',],
        linkedin: ['',],
        siteWeb: ['',],
        infogreffe: ['',]
      })
    })
    this.formEtablissement = this.formBuilder.group({
      multiEtablissement: ['Non', Validators.required],
    })
    this.formAddEtablissement = this.formBuilder.group({
      nom: ['', [Validators.required, Validators.minLength(4), ValidationService.validateEmptyField]],
      type: ['Établissement principal', Validators.required],
      mail: ['', [Validators.required, ValidationService.emailValidator, ValidationService.nospaceValidator]],
      tel: [''],
      statutEtab:['Active'],
      dateRadiationEtab:[''],
      adresse: this.formBuilder.group({
        pays: ['Tunisie',],
        codePostal: ['', [ValidationService.numberValidator,Validators.min(0),Validators.max(9999999)]],
        rue: ['',],
        n: ['', [ValidationService.numberValidator,Validators.min(0),Validators.max(9999999)]],
        ville: ['',],
      }),
    })

    this.formAddEtablissementUpdate = this.formBuilder.group({
      nom: ['', [Validators.required, Validators.minLength(4), ValidationService.validateEmptyField]],
      type: ['Établissement principal', Validators.required],
      mail: ['', [Validators.required, ValidationService.emailValidator, ValidationService.nospaceValidator]],
      tel: [''],
      statutEtab:['Active'],
      dateRadiationEtab:[''],
      adresse: this.formBuilder.group({
        pays: ['Tunisie',],
        codePostal: ['', [ValidationService.numberValidator,Validators.min(0),Validators.max(9999999)]],
        rue: ['',],
        n: ['', [ValidationService.numberValidator,Validators.min(0),Validators.max(9999999)]],
        ville: ['',],
      }),
    })
    this.formBancaire = this.formBuilder.group({
      nom: ['', Validators.maxLength(100)],
      iban: ['', Validators.maxLength(100)],
      swift: ['', Validators.maxLength(100)],
      rib: ['', Validators.maxLength(100)],
      bic: ['', Validators.maxLength(100)],
    });

  }
  ngOnInit() {
    this.codes = codesNav.sort(triCodes);
    this.pys = pays.sort(tri);
    this.pys = this.pys.filter(x => x.name === "Tunisie")
    this.vlls = codepostale
    this.role = localStorage.getItem('roleUser')
    if (this.role) {
      this.roleAdministration = false;
    }
  }
  getdatenow(): string {
    return this.dateNow
  }
  // selet type client
  selectType() {
    this.showChamps = !this.showChamps
  }
  getBigDate(): string {
      return this.bigDate
    }
  //verify siret exist or no
  verifySiret() {
   if (!this.form.value.siren) {
      return false;
    }
    if (this.form.value.siren.toString().length != 14 || !isNumeric(this.form.value.siren) || this.form.value.siren < 0) {
      this.errorLengthSiren = true;
      return false;
    }
     else {
       this.errorLengthSiren = false;
      this.clientService.verifySiret(this.form.value).subscribe(data => {
        if (data) {
          this.errorSiret = true;
          return false;
        }
        else {
          this.errorSiret = false;
          this.disabled = true;
          this.showChamps = true;
          this.showAnnulationBouton = true;
        }
      })
    }
  }
  // annuler le choix du siret
  annulerSiret() {
    this.disabled = false;
    this.showChamps = false;
    this.showAnnulationBouton = false;
  }
  showSociete() {
    this.secteur = this.form.value.codeNaf.fields['intitule_naf'];
  }
  showEtablissment() {
    if (this.formEtablissement.value.multiEtablissement === "Non") {
      this.addEtablissments = false;
    }
    if (this.formEtablissement.value.multiEtablissement === "Oui") {
      this.addEtablissments = true;
    }
    else {
      this.addEtablissments = false;
    }
  }

  // ajuter des etablissments
  addnewEtablissment() {
    this.etablissements.push(this.formAddEtablissement.value);
    this.formAddEtablissement.reset();
  }
  // supprimer un établissement
  removeEtablissement(etablissement) {
    Swal.fire({
          title: 'VOULEZ-VOUS SUPPRIMER CET ÉTABLISSEMENT?',
          imageUrl: 'assets/images/supp-modal.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          showCancelButton: true,
          width: 800,
          confirmButtonColor: '#059e00',
          cancelButtonColor: '#FF9100',
          cancelButtonText: 'ANNULER',
          confirmButtonText: 'VALIDER'
        }).then((result) => {
          if (!result.dismiss) {
            Swal.fire( {
                imageUrl: 'assets/images/supp-modal.png',
                imageWidth: 100,
                imageHeight: 100,
                imageAlt: 'Custom image',
                width: 800,
                title: 'BIEN SUPPRIMÉ',
                timer: 1500,
                showConfirmButton: false
              }
            );
            this.etablissements.splice(this.etablissements.indexOf(etablissement), 1);
          }
        } );

  }
  onKeyUp(event){
    this.errorOnchange = false;
    if(this.form.value.siren === null || this.form.value.siren === 0){
      this.form.controls['siren'].setValue("");
      this.errorOnchange = true;
    }
    if(this.form.value.siren && (this.form.value.siren.toString().indexOf("1e+") > -1 ||  this.form.value.siren.toString().indexOf(".") > -1)){
      this.form.controls['siren'].setValue("");
      this.errorOnchange = true;
    }
    if(this.form.value.siren && this.form.value.siren.toString().length > 14){
      this.form.controls['siren'].setValue(this.form.value.siren.toString().substr(0, 14));
      this.errorOnchange = true;
    }
    if(this.form.value.siren && this.form.value.siren.toString().indexOf("e") > -1){
      this.errorOnchange = true;
    }
  }
  // update un établissement
  editEtablissment(etablissement) {
    if (etablissement.nom == undefined || etablissement.nom.length < 3) {
      this.errorNom = true;
      this.errorType = false;
      this.errorMail = false;
      return false;
    }
    if (etablissement.type == undefined) {
      this.errorNom = false;
      this.errorType = true;
      this.errorMail = false;
      return false;
    }
    if (etablissement.mail == undefined || !this.validateService.validateEmail(etablissement.mail)) {
      this.errorNom = false;
      this.errorType = false;
      this.errorMail = true;
      return false;
    }
    else {
      this.errorNom = false;
      this.errorType = false;
      this.errorMail = false;
      return true;
    }
  }

  onRegisterClient() {
    if(this.formBancaire.value && this.formBancaire.value.nom && this.formBancaire.value.nom.length > 100){
      this.errorFourthPart = "Merci de vérifier le champ nom (maximum 100 caractères)"
      return false;
    }
    if(this.formBancaire.value && this.formBancaire.value.iban && this.formBancaire.value.iban.length > 100){
      this.errorFourthPart = "Merci de vérifier le champ iban (maximum 100 caractères)"
      return false;
    }
    if(this.formBancaire.value && this.formBancaire.value.swift && this.formBancaire.value.swift.length > 100){
      this.errorFourthPart = "Merci de vérifier le champ swift (maximum 100 caractères)"
      return false;
    }
    if(this.formBancaire.value && this.formBancaire.value.rib && this.formBancaire.value.rib.length > 100){
      this.errorFourthPart = "Merci de vérifier le champ rib (maximum 100 caractères)"
      return false;
    }
    if(this.formBancaire.value && this.formBancaire.value.bic && this.formBancaire.value.bic.length > 100){
      this.errorFourthPart = "Merci de vérifier le champ bic (maximum 100 caractères)"
      return false;
    }
    this.errorFourthPart = ""
    Swal.fire({
          title: 'VOULEZ-VOUS CONFIRMER?',
          imageUrl: 'assets/images/client-plus.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          showCancelButton: true,
          width: 800,
          confirmButtonColor: '#008000',
          cancelButtonColor: '#FFA500',
          confirmButtonText: 'CONFIRMER',

          cancelButtonText: 'ANNULER',
        }).then((result) => {
          if (!result.dismiss) {
            if(this.formEtablissement.value.multiEtablissement === "Non") {
              this.form.value.etablissements = [];
            }
            if (this.formEtablissement.value.multiEtablissement === "Oui") {
              this.form.value.etablissements = this.etablissements
            }
            this.form.value.informationbancaire = this.formBancaire.value;
            this.form.value.multiEtablissement = this.formEtablissement.value.multiEtablissement;
            this.form.value.adresse = this.formCom.value.adresse;
            this.form.value.adresseWeb = this.formCom.value.adresseWeb;
            this.form.value.mail = this.formCom.value.mail;
            this.form.value.tel = this.formCom.value.tel;
            if (this.form.value.codeNaf) {
              this.form.value.indicatif = this.form.value.codeNaf.fields.code_naf
              this.form.value.activite = this.secteur
            }
            if (this.myfiles && this.myfiles.length > 0) {
              this.loading = true;
              for (var x = 0; x < this.myfiles.length; x++) {
                var fileExtensionn = this.myfiles[x].name.substr((this.myfiles[x].name.lastIndexOf('.') + 1));
                //var photo = this.utilisateur.id + i + '.' + fileExtension
                var randomVariable = "logo_"+this.form.value.denomination.replace(/\s+/g, '-');
                this.form.value.nameLogo = this.myfiles[x].name;
                this.form.value.linkLogo = environment.filesPath + randomVariable + "." + fileExtensionn
                this.uploadFileService.pushFilesToStorage(this.myfiles[x], randomVariable).subscribe(event => {
                }, err => {
                })
              }
           }
            this.clientService.addClient(this.form.value).subscribe(resp => {
            }, err => {
            })
            this.form.reset();
            this.router.navigateByUrl('/client');
            Swal.fire( {
                imageUrl: 'assets/images/client-plus.png',
                imageWidth: 100,
                imageHeight: 100,
                imageAlt: 'Custom image',
                width: 800,
                title: 'BIEN AJOUTÉ',
                timer: 1500,
                showConfirmButton: false
              }
            );
          }
        } );
  }
  showAndHoideDateRadiationFunc(){
    this.showAndHoideDateRadiation = false;
    if(this.form.value.statut === 'Radiée'){
      this.showAndHoideDateRadiation = true;
    }else{
      this.showAndHoideDateRadiation = false;
    }
  }
  showAndHoideDateRadiationFuncEtab(){
    this.showAndHoideDateRadiationEtab = false;
    if(this.formAddEtablissement.value.statutEtab === 'Radiée'){
      this.showAndHoideDateRadiationEtab = true;
    }else{
      this.showAndHoideDateRadiationEtab = false;
    }
  }
  // controler le logo
  selectFiles(event) {
    this.myfiles = event.target.files
    if (this.myfiles && this.myfiles.length > 0) {
      if(this.myfiles[0].size > this.sizeLimitOne) {
        this.myfiles = undefined;
        Swal.fire({
          imageUrl: 'assets/images/error.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 600,
          title: 'Un fichier sélectionné est trop volumineux.La taille maximale est 10 Mo.',
          /*timer: 1500,*/
          showConfirmButton: true,
          confirmButtonColor: '#8B0000',
          confirmButtonWidth: 100,

        }
        );
        return false;
      }
      if(this.myfiles[0].type != "image/png" && this.myfiles[0].type != "image/jpeg" && this.myfiles[0].type != "image/jpg"){
        this.myfiles = undefined;
        Swal.fire({
          imageUrl: 'assets/images/error.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 600,
          title: 'Impossible de télécharger ce fichier.',
          /*timer: 1500,*/
          showConfirmButton: true,
          confirmButtonColor: '#8B0000',
          confirmButtonWidth: 100,

        }
        );
        return false;
      }
    } else {
      return false;
    }
  }
  modifPart1() {
    this.showMyContainer = 1;
  }
  modifPart2(){
    if(this.form.value.type === "Particulier" && this.form.value.siren && this.form.value.siren.toString().length != 14){
      this.errorFistPart = "Merci de vérifier le champ identifiant unique"
      return false;
    }
    if(!this.form.value.denomination || this.form.value.denomination.length < 4 || this.form.value.denomination.length > 100){
      this.errorFistPart = "Merci de vérifier le champ dénomination (minimum 4 caractères, maximum 100 caractères)"
      return false;
    }
    if(this.form.value.acronyme && (this.form.value.acronyme.length < 4 || this.form.value.acronyme.length > 100)){
      this.errorFistPart = "Merci de vérifier le champ acronyme (minimum 4 caractères, maximum 100 caractères)"
      return false;
    }
    if(!this.form.value.formeJuridique ){
      this.errorFistPart = "Merci de vérifier le champ forme juridique"
      return false;
    }
    if(!this.form.value.codeNaf ){
      this.errorFistPart = "Merci de vérifier le champ code activité"
      return false;
    }
    if(this.form.value.datec &&  this.form.value.datec > this.dateNow){
      this.errorFistPart = "Merci de vérifier le champ immatriculé le"
      return false;
    }
    this.errorFistPart = ""
    this.showMyContainer = 2;
  }
  modifPart3(){
    if(!this.etablissements || this.etablissements.length < 1){
      this.formEtablissement.controls['multiEtablissement'].setValue("Non");
    }
    this.showMyContainer = 3;
  }
  modifPart4(){
    if(!this.formCom.value.mail || (this.formCom.value.mail && (this.formCom.value.mail.length <2 || this.formCom.value.mail.length > 100 || !this.validateService.validateEmail(this.formCom.value.mail)) )){
      this.errorSecondPart = "Merci de vérifier le champ Email"
      return false;
    }
    if(!this.formCom.value.tel || (this.formCom.value.tel && this.formCom.value.tel.length != 12  )){
      this.errorSecondPart = "Merci de vérifier le champ N° fixe"
      return false;
    }
    
    if((this.formCom.value.adresse.codePostal && (!Number(this.formCom.value.adresse.codePostal) || this.formCom.value.adresse.codePostal < 0 || this.formCom.value.adresse.codePostal > 99999 || this.formCom.value.adresse.codePostal.toString().indexOf('e') > -1)) ){
      this.errorSecondPart = "Merci de vérifier le champ code postal"
      return false;
    }
    if((this.formCom.value.adresse.n && (!Number(this.formCom.value.adresse.n) || this.formCom.value.adresse.n < 0 || this.formCom.value.adresse.n > 99999 || this.formCom.value.adresse.n.toString().indexOf('e') > -1 )) ){
      this.errorSecondPart = "Merci de vérifier le champ numéro"
      return false;
    }
    this.errorSecondPart = ""
    this.showMyContainer = 4;
  }
  showPart() {
    return this.showMyContainer;
  }
  seteblissement(etablissement){
    this.etablissement = etablissement
  }
}
