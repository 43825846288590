import { Component, OnInit } from '@angular/core';
import * as XLSX from "xlsx";
import { InventaireService } from "../../../services/inventaire.service";
import { FormBuilder,  Validators } from '@angular/forms';
import { FournisseurService } from "../../../services/fournisseur.service";
import { Router, ActivatedRoute } from '@angular/router';
import { isNumeric } from 'rxjs/util/isNumeric';
import { CatalogueService } from "../../../services/catalogue.service";
import { UserService } from "../../../services/user.service";
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-ajout-inventaire',
  templateUrl: './ajout-inventaire.component.html',
  styleUrls: ['../../utilisateur/ajout-utilisateur/ajout-utilisateur.component.scss'],
})
export class AjoutInventaireComponent implements OnInit {
  fileReaded: any;
  totalInventories: any;
  AOA: any;
  listeArtciels: any[]=[]
  listeAllArtciels:any;
  loading = false;
  sizeLimitOne = 30000000; //30MB
  allowInformations = true;
  allowInformationsComp = false;
  numberData = 10;
  text: any;
  tarifGlobal = 0;
  addedLength = 0;
  errorObjet: any;
  fileToChoose: any;
  arraydesN = ["C", "U", "M"]
  filterArticle:any;
  form:any;
  fournisseurs:any;
  selectedFounisseur:any;
  errorDate = false
  dateDebut = new Date().toISOString().split('T')[0]
  allArticles:any;
  numberErrorLine = 0 ;
  id:any;
  utilisateur:any;
  inventiareactivationdata:any;
  monthNumbers = ["01","02","03","04","05","06","07","08","09","10","11","12"]
  showFileZone = false
  showMyContainer = 1;
  bigDate = "9999-12-31"
  constructor(private catalogueService:CatalogueService,private userService:UserService,private inventaireService: InventaireService,private formBuilder:FormBuilder,private fournisseurService:FournisseurService,private router:Router) {
    this.form = this.formBuilder.group({
      fournisseur: ['', Validators.required],
      datec: ['', Validators.required]
    })
  }
  ngOnInit() {

    this.id = localStorage.getItem('idUser')
    this.userService.getUserByID(this.id).subscribe(utilisateur => {
      this.utilisateur = utilisateur;
      if(!this.utilisateur){
        localStorage.removeItem('idUser');
        localStorage.clear();
        this.router.navigate(['/login']);
      }
      if (this.utilisateur && this.utilisateur.mail == 'suren.vijey@krendel.fr') {
        this.router.navigateByUrl('/accueil');
        return false;
      }
      if(this.utilisateur && this.utilisateur.role != 'Super admin'){
        this.inventaireService.getAllDataConfigActivation()
          .then(data => {
            this.inventiareactivationdata = data
            var ext = this.inventiareactivationdata.find(x=>x.desactive == false);
            if(!ext){
              this.router.navigateByUrl('/inventaire');
            }
          }, err => {
          })
      }
    },
      err => {
        return false;
      });
    this.getAllFournisseurs()
  }
  getAllFournisseurs() {
    this.fournisseurService.getAllUsers().then(data => {
      this.fournisseurs = data;
    },
      err => {
        return false;
      });

  }
  getBigDate(): string {
      return this.bigDate
    }

getMyDataByFounrisseur(){
  if(this.form.value.fournisseur && this.form.value.fournisseur.id){
    var splitDateCommande = this.form.value.datec.split('-');
    var yearDateInventaire = splitDateCommande[0]
    var monthDateCommandee = splitDateCommande[1]
    var indexMonth = this.monthNumbers.indexOf(monthDateCommandee)
    if(indexMonth <=5){
      this.getDataFirstSemester(yearDateInventaire)
    }
    //semester 1
    if(indexMonth > 5){
      this.getDatasecondSemester(yearDateInventaire)
    }
  }else{
    Swal.fire({
      imageUrl: 'assets/images/error.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      width: 600,
      title: 'Erreur lors de la récuépration du fournisseur et la date !',
      /*timer: 1500,*/
      showConfirmButton: true,
      confirmButtonColor: '#8B0000',
      confirmButtonWidth: 100,

    }
    );
    return false;
  }
}
getDataFirstSemester(yearDateInventaire){
  /*var middleYear = yearDateInventaire + "-06-30"
  var firstDayYear = yearDateInventaire + "-01-01"
  var lastDayYear = yearDateInventaire + "-12-31"*/
  this.loading = true;
  this.catalogueService.getAllDataByFirstSemesterAndFournisseur(yearDateInventaire,this.form.value.fournisseur.id).then(data => {
    this.allArticles = data
    this.loading = false;
    this.showFileZone = true
  },
    err => {
      return false;
    });
}
getDatasecondSemester(yearDateInventaire){
 this.loading = true;
  this.catalogueService.getAllDataBySecondSemesterAndFournisseur(yearDateInventaire,this.form.value.fournisseur.id).then(data => {
    this.allArticles = data
    this.loading = false;
    this.showFileZone = true
  },
    err => {
      return false;
    });
}
  // get data
  onFileChange(file: any) {
    // Vider le tableau et réinitialiser l'affichage
    /* wire up file reader */

    var myfile = file.target.files.item(0)
    if (myfile) {
      this.fileToChoose = undefined
      this.listeArtciels = []
      this.tarifGlobal = 0;
      this.addedLength = 0;
      this.errorObjet = " "
      if (!myfile.type.match('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet|application/vnd.ms-excel')) {
        file = undefined
        Swal.fire({
          imageUrl: 'assets/images/error.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 600,
          title: 'Impossible de télécharger ce fichier.',
          /*timer: 1500,*/
          showConfirmButton: true,
          confirmButtonColor: '#8B0000',
          confirmButtonWidth: 100,

        }
        );
        return false;
      }
      if (myfile.size > this.sizeLimitOne) {
        file = undefined
        Swal.fire({
          imageUrl: 'assets/images/error.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 600,
          title: 'Un fichier sélectionné est trop volumineux.La taille maximale est 30 Mo.',
          /*timer: 1500,*/
          showConfirmButton: true,
          confirmButtonColor: '#8B0000',
          confirmButtonWidth: 100,

        }
        );
        return false;
      }
      this.loading = true;
      const target: DataTransfer = <DataTransfer>(file.target);
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        var data = (XLSX.utils.sheet_to_json(ws, { header: 1 }));
        for (var i = 1; i < data.length; i++) {
          var object = {
            "refArt": data[i][0], "designation": data[i][1], "pritar": data[i][2], "coefAchat": data[i][3], "prinet": data[i][4], "quantite": data[i][5], "montant": Number(data[i][4]*data[i][5]).toFixed(2),
            "datec": this.form.value.datec,"exist":false,"ean13Art":"","denomination":this.form.value.fournisseur.denomination,
             "idFournisseur":this.form.value.fournisseur.id
          }
          if (object.refArt && this.allArticles.length > 0) {
            if(!isNumeric(object.pritar) || !isNumeric(object.prinet) || isNaN(object.quantite) || object.quantite < 0){
              this.errorObjet = " Merci de vérifier le prix tarif, le prix net et la quantité de la ligne "+ Number(i + 1 )
              this.tarifGlobal = 0;
              this.listeArtciels = [];
              this.loading = false;
              return false;
            }
            let objectIndex = this.allArticles.findIndex(article => article.refCiel === object.refArt);
            if(objectIndex != -1 ){
              object.exist = true;
              object.ean13Art = this.allArticles[objectIndex].ean13
            }
            if(objectIndex === -1 ){
              this.numberErrorLine++
            }
            this.listeArtciels.push(object)
            this.tarifGlobal = this.tarifGlobal + Number(data[i][2])
          }
        }
        this.addedLength = this.listeArtciels.length
        this.loading = false;
      };
      if (target.files.length > 0) {

        reader.readAsBinaryString(target.files[0]);
      }
    } else {

      return false;
    }

  }


  saveData() {
    this.errorDate = false;
    if (this.loading) {
      Swal.fire({
        imageUrl: 'assets/images/error.png',
        imageWidth: 100,
        imageHeight: 100,
        imageAlt: 'Custom image',
        width: 600,
        title: 'L\'upload des données est en cours ! Veuillez patienter',
        /*timer: 1500,*/
        showConfirmButton: true,
        confirmButtonColor: '#8B0000',
        confirmButtonWidth: 100,

      }
      );
      return false;
    }
    if ( this.listeArtciels.length < 1) {
      return false;
    }
    this.loading = true;
     this.inventaireService.saveAllData(this.listeArtciels)
      .subscribe(resp => {

      }, err => {
      })
    setTimeout(() => {
      /** spinner ends after 6 seconds **/
      this.loading = false;
      Swal.fire( {
            imageUrl: 'assets/images/add-catalogue.png',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            width: 800,
            title: 'BIEN INTÉGRÉ',
            timer: 1500,
            showConfirmButton: false
          }
        );
      this.router.navigateByUrl('/inventaire');
    }, 20000);
    this.listeArtciels = []
    this.fileToChoose = undefined
  }
selectFournisseur(){
  this.selectedFounisseur = this.form.value.fournisseur
}
getDateDebut(): string {
  return this.dateDebut
}
 isFloat(n){
    return Number(n) === n && n % 1 !== 0;
}
isInt(n){
    return Number(n) === n && n % 1 === 0;
}
modifPart1() {
  this.showMyContainer = 1;
}
modifPart2(){
  this.showMyContainer = 2;
}
showPart() {
  return this.showMyContainer;
}
}
