<app-navbar></app-navbar>
<br>
<div id="content-nav-page" class="row">
    <div class=" col-3 titre-menu">
      <a routerLink="/inventaire" class=" icon-f-left"></a>
      <b style="margin-right: 10%">Nouvel Inventaire</b>
      <img src="assets/images/add-catalogue.png">
    </div>
    <div class="col-9 sous-menu">
      <b class="sous-menu-active1" routerLink="/inventaire">INVENTAIRE</b>
    </div>
  </div>
  <div class="card p-3">
    <div class="col d-flex justify-content-start">
      <b class="obligatoir-text">(*) Ce champ est obligatoire</b>
    </div>
    <div class="card-header " style="border: 1px solid green;margin-bottom: 1%;">
      <b style="color:grey">INFORMATIONS</b>
    </div>
  
    <div>
      <div class="card-body">
        <form [formGroup]="form" class="form-horizontal" role="form">
          <div class="row" style="margin-bottom: -60px;margin-top: 20px" *ngIf="!inventaireConfigObj">
            <div class="col-6" style="margin-left: 20%">
              <div class="title">
                <input class="font-weight-bold" type="date" placeholder="Date*" [max]="getBigDate()" [(ngModel)]="dateDebut" formControlName="datec" name="datec" [attr.disabled]="showArray == true ? true : null">
              </div>
            </div>
            <div class="col-6" style="margin-left: 30%">
              <table>
                <tr>
                  <td style="padding-right: 30px;">
                    <button class="btn-annule" style="width: 200px;height: 50px;" [routerLink]="['/inventaire']">Annuler <img src="assets/images/btn-annule.png"> </button>
  
                  </td>
                  <td style="padding-right: 30px;">
                    <button class="btn-valider" style="width: 200px;height: 50px;" (click)="showArrayFunction()" [disabled]="!form.valid">Valider <img src="assets/images/save.png"></button>
  
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="row" *ngIf="inventaireConfigObj && inventaireConfigObj.dateInventaireChosie">
            <div class="col-12 " style="text-align: center;font-weight: bold;color: #237302;font-size: 20px;">
              <label for="nom">Date : {{inventaireConfigObj.dateInventaireChosie | date : 'dd/MM/yyyy'}}</label>
            </div>
          </div>
        </form>
      </div>
      <div class="row" style="margin-bottom: -5%" *ngIf="showArray">
        <div class="col-12" style="padding-bottom: 2%;margin-top: 3%">
          <div>
            <div>
              <div style="margin-top: 30px">
                <div class="row ">
                  <table class="table-ligne table-responsive">
                    <tr>
                      <th class="text-center">DATE DE L'INVENTAIRE</th>
                      <th class="text-center">FOURNISSEUR</th>
                      <th class="text-center">RÉFÉRENCE*</th>
                      <th class="text-center">DÉSIGNATION </th>
                      <th class="text-center">ÉTAGE*</th>
                      <th class="text-center">QUANTITÉ EMBALLÉE</th>
                      <th class="text-center">QUANTITÉ NON EMBALLÉE</th>
                      <th class="text-center">QUANTITÉ *</th>
                      <th class="text-center">OBSERVATIONS</th>
                      <th class="text-center ">ACTIONS</th>
                    </tr>
                    <tr *ngFor="let field of fieldArrayy; let i = index">
                      <td>
                        {{field.datec | date : 'dd/MM/yyyy'}}
                      </td>
                      <td>
                        {{field.denomination}}
                      </td>
                      <td>
                        {{field.refArt}}
                      </td>
                      <td>
                        {{field.designation}}
                      </td>
                      <td>
                        {{field.etage}}
                      </td>
                      <td>
                        {{field.quantiteEmballe | number: '1.2-2'| replace:',':' ' | replace:'.':',' | replace:',00':' '}}
                      </td>
                      <td>
                        {{field.quantiteNonEmballe | number: '1.2-2'| replace:',':' ' | replace:'.':',' | replace:',00':' '}}
                      </td>
                      <td>
                        {{field.quantite | number: '1.2-2'| replace:',':' ' | replace:'.':',' | replace:',00':' '}}
                      </td>
                      <td>
                        {{field.observation}}
                      </td>
                      <td>
                        <a title="Supprimer" (click)="deleteFieldValue(i)" ><img src="assets/images/supp-modal.png" style="width: 25px;"></a>
                      </td>
                    </tr>
  
                    <tr>
                      <td>
  
                        <span style="margin-top: 20px;">{{dateDebut | date : 'dd/MM/yyyy'}}</span>
                      </td>
                      <td>
  
                      </td>
                      <td>
                        <input class="font-weight-bold" style="position: relative;top: -35px;" type="text" id="newAttributePrice" [(ngModel)]="newAttribute.refArt" name="newAttributerefArt" />
                      </td>
                      <td>
  
                      </td>
                      <td>
                        <select class="form-control input-sm select-options" name="etage" [(ngModel)]="newAttribute.etage" style="position: relative;top: -57px;">
                          <option>-2</option>
                          <option>-1</option>
                          <option>0</option>
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                        </select>
                      </td>
                      <td>
                        <input class="font-weight-bold" type="number" min="0" style="position: relative;top: -35px;" id="newAttributequantite" [(ngModel)]="newAttribute.quantiteEmballe" name="quantiteEmballe" (keyup)="updateQteEmballe(newAttribute)" (change)="updateQteEmballe(newAttribute)" value="0" />
                      </td>
                      <td>
                        <input class="font-weight-bold" type="number" min="0" style="position: relative;top: -35px;" id="newAttributequantite" [(ngModel)]="newAttribute.quantiteNonEmballe" name="quantiteNonEmballe" (keyup)="updateQteNonEmballe(newAttribute)" (change)="updateQteNonEmballe(newAttribute)" value="0" />
                      </td>
                      <td>
                        <input class="font-weight-bold" type="number" min="0" style="position: relative;top: -35px;" id="newAttributequantite" [(ngModel)]="newAttribute.quantite" name="newAttributequantite" value="0" />
                      </td>
                      <td>
                        <textarea  rows="4" class="font-weight-bold" [(ngModel)]="newAttribute.observation" name="observation" cols="50"></textarea>
                      </td>
                      <td>
                        <a title="Ajouter" (click)="addFieldValue()" style="margin-top: 20px;"><img src="assets/images/add-cmd.png"></a>
                      </td>
                    </tr>
  
  
                  </table>
                </div>
  
              </div>
            </div>
          </div>
        </div>
      </div>
      <p *ngIf="showErrorData" style="color:red;position: relative;top: 60px;"> Merci de vérifier les champs de référence et de la quantite</p>
      <p *ngIf="showErrorData" style="color:red;position: relative;top: 60px;">(*) Les champs sont obligatoires</p>
      <p *ngIf="errorQauntite" style="color:red;position: relative;top: 60px;"> Il faudrait que la quantité = quantité emballée + quantité non emballée </p>
  
      <div class=" col d-flex justify-content-center" style="padding-bottom: 1%">
        <div class="col-auto nbr-dossier ">
          <table>
            <tr>
              <td style="padding-right: 30px;">
                <button class="btn-annule" style="width: 200px;height: 50px;" *ngIf="showArray" routerLink="/inventaire">Annuler <img src="assets/images/btn-annule.png"> </button>
  
              </td>
              <td style="padding-right: 30px;">
                <button class="btn-valider" style="width: 200px;height: 50px;" *ngIf="showArray" (click)="displayTable()">Ajouter <img src="assets/images/save.png"></button>
  
              </td>
  
            </tr>
  
          </table>
        </div>
  
      </div>
    </div>
  </div>
  <div id="myModal" class="modal fade">
  
    <!-- Modal content -->
    <div class="modal-content">
      <span class="close">&times;</span>
      <h3 class="titre-modal-statut">
        NOUVELLE RÉFÉRENCE
      </h3>
      <form method="GET" action="ajouter-inventaire.component.html">
        <div class="col d-flex justify-content-start">
          <b class="obligatoir-text">(*) Ce champ est obligatoire</b>
        </div>
        <div class="row" style="margin-top: 25px;">
          <div class="col-6">
            <div class="title">
              <input class="font-weight-bold" placeholder="Fournisseur*">
            </div>
  
            <br />
            <div class="title">
              <input class="font-weight-bold" placeholder="Référence*">
            </div>
  
  
          </div>
  
          <div class="col-6">
            <div class="title">
              <input class="font-weight-bold" placeholder="Désignation*">
            </div>
            <br />
  
            <div class="title">
              <input class="font-weight-bold" placeholder="Quantité*">
            </div>
          </div>
        </div>
        <div class=" col d-flex justify-content-center" style="padding-bottom: 1%; margin-bottom: 20px">
          <div class="col-auto nbr-dossier ">
            <table>
              <tr>
                <td style="padding-right: 30px;">
                  <button class="btn-valider" style="width: 200px;height: 50px;" >Ajouter <img src="assets/images/save.png"></button>
  
                </td>
                <td style="padding-right: 30px;">
                  <button class="btn-annule" style="width: 200px;height: 50px;" >Annuler <img src="assets/images/btn-annule.png"> </button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </form>
    </div>
  </div>
  