import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DossierService } from "../../../services/dossier.service";
import { UserService } from "../../../services/user.service";
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';

@Component({
  selector: 'app-affectation-dossier',
  templateUrl: './affectation-dossier.component.html',
  styleUrls: ['../../utilisateur/ajout-utilisateur/ajout-utilisateur.component.scss','../ajout-dossier/ajout-dossier.component.css'],
})
export class AffectationDossierComponent implements OnInit {
  dossier: any;
  utilisateur: any;
  id: any;
  formPreparationChiffrage: any;
  utilisateurs: any;
  personnesOfDepartement:any[]=[]
  selectedPersonne:any;
  showSelect = true;
  constructor(private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute, private dossierService: DossierService,
    private router: Router, private userService: UserService) {

    this.formPreparationChiffrage = this.formBuilder.group({
      departement: ['Administration', Validators.required],
      personne: ['', Validators.required]
    })
  }
  ngOnInit() {
    let params: any = this.activatedRoute.snapshot.params;
    this.dossierService.getDossierById(params.id).subscribe(data => {
      this.dossier = data;
      if(!this.dossier){
        this.router.navigate(["dossier"]);
        return false;
      }
        if(this.dossier.statut === 'Clôture'){
          this.router.navigate(["dossier"]);
          return false;
        }
      this.getAllUsers();
      this.selectedPersonne = this.dossier.personneVisee
      if(!this.dossier.personneVisee){
        this.showSelect = false;
        this.dossier.personneVisee = {
          "nom":"",
          "prenom":"",
          "mail":"",
          "tel":"",
          "departement":"Administration"
        }
      }
    },
      err => {
        return false;
      });
    this.id = localStorage.getItem('idUser')
    if (this.id) {
      this.userService.getUserByID(this.id).subscribe(utilisateur => {
        this.utilisateur = utilisateur;
        if(!this.utilisateur){
          localStorage.removeItem('idUser');
          localStorage.clear();
          this.router.navigate(['/login']);
        }
        if (!this.utilisateur) {
          this.router.navigateByUrl('/dossier');
        }
      },
        err => {
          return false;
        });
    } else {
      return false;
    }
  }
  getAllUsers() {
    this.userService.getAllUsers().then(data => {
      this.utilisateurs = data;
      for(var i=0;i<this.utilisateurs.length;i++){
        if(this.utilisateurs[i].role === "Equipe chiffrage"){
          this.personnesOfDepartement.push(this.utilisateurs[i])
        }
      }
      this.personnesOfDepartement = this.personnesOfDepartement.sort(this.triUtilisateur);
    },
      err => {
        return false;
      });
  }

  showPersonnesOfDepartement(){
    this.personnesOfDepartement = [];
    this.selectedPersonne = ""
    this.showSelect = false;
    if(this.formPreparationChiffrage.value.departement === 'Administration'){
      this.filterPersonne('Administration')
    }
    if(this.formPreparationChiffrage.value.departement === 'Equipe commerciale'){
      this.filterPersonne('Equipe commerciale')
    }
    if(this.formPreparationChiffrage.value.departement === 'Equipe chiffrage'){
      this.filterPersonne('Equipe chiffrage')
    }
    if(this.formPreparationChiffrage.value.departement === 'Equipe d\'approvisionnement'){
      this.filterPersonne('Equipe d\'approvisionnement')
    }
    if(this.formPreparationChiffrage.value.departement === 'Equipe Atelier'){
      this.filterPersonne('Equipe Atelier')
    }
  }
  // filtrer les utilisateurs selon le choix de département
  filterPersonne(nomDepartement){
    for(var i=0;i<this.utilisateurs.length;i++){
      if(this.utilisateurs[i].role === nomDepartement){
        this.personnesOfDepartement.push(this.utilisateurs[i])
      }
    }
  }
  // show personne détails
  showPersonneDetails(){
    this.selectedPersonne = this.formPreparationChiffrage.value.personne
  }
  onUpdateDossier(dossier) {
    Swal.fire({
      title: 'VOULEZ-VOUS CONFIRMER?',
      imageUrl: 'assets/images/client-plus.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      showCancelButton: true,
      width: 800,
      confirmButtonColor: '#008000',
      cancelButtonColor: '#FFA500',
      confirmButtonText: 'CONFIRMER',

      cancelButtonText: 'ANNULER',
    }).then((result) => {
      if (!result.dismiss) {
        var d = new Date();
        dossier.dateMod = d.toLocaleString();
        dossier.statut = " 01 - Vide"
        var personneVisee = {
          "nom":this.formPreparationChiffrage.value.personne.nom,
          "prenom":this.formPreparationChiffrage.value.personne.prenom,
          "mail":this.formPreparationChiffrage.value.personne.mail,
          "tel":this.formPreparationChiffrage.value.personne.tel,
          "departement":this.formPreparationChiffrage.value.departement,
          "id":this.formPreparationChiffrage.value.personne.id
        }
        dossier.personneVisee = personneVisee
        this.dossierService.editDossier(dossier)
          .subscribe(resp => {
          }, err => {

          })
        Swal.fire( {
            imageUrl: 'assets/images/client-plus.png',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            width: 800,
            title: 'BIEN AFFECTÉ',
            timer: 1500,
            showConfirmButton: false
          }
        );
        this.router.navigateByUrl('/dossier');
      }
    } );

  }
  // trier liste des utilisateur
  triUtilisateur(a, b) {
    if (a.nom.toLowerCase() < b.nom.toLowerCase()) return -1;
    else if (a.nom.toLowerCase() == b.nom.toLowerCase()) return 0;
    else return 1;
  }
}
