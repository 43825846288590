<app-navbar></app-navbar>
<br>

<div id="content-nav-page" class="row">
  <div class=" col-3 titre-menu">
    <a routerLink="/stock" class=" icon-f-left"></a>
    <b style="margin-right: 10%" *ngIf="article">{{article.refArt}} </b>
  </div>
  <div class="col-9 sous-menu" *ngIf="utilisateur">
    <b class="sous-menu-active1 ml-4" routerLink="/stock">STOCK</b>
  </div>
</div>

<div class="card p-3">
  <div class="col d-flex justify-content-start">
    <b class="obligatoir-text">(*) Ce champ est obligatoire</b>
  </div>
  <div class="card-header " style="border: 1px solid green;margin-bottom: 3%;text-align: center" >
    <b style="color:grey;text-align: center" >INFORMATIONS </b>
  </div>
  <form [formGroup]="form"  role="form" >
  <div class="card-body" *ngIf="article">

    <div class="row">
      <div class="col-4">
        <div class="title">
          <input class="font-weight-bold" formControlName="refArt" placeholder="Référence*" [(ngModel)]="article.refArt" (keyup)="valuechange($event)" value="{{article.refArt}}" name="refArt" style="color: #1b1e21">
          <control-messages [control]="form.controls.refArt"></control-messages>
        </div>
      </div>
      <div class="col-4">
        <div class="title">
          <input class="font-weight-bold" formControlName="designation" [(ngModel)]="article.designation"  value="{{article.designation}}" name="designation"  placeholder="Désignation*" style="color: #1b1e21">
          <control-messages [control]="form.controls.designation"></control-messages>
        </div>
      </div>
      <div class="col-4">
        <div class="title">
          <input class="font-weight-bold" placeholder="Prix Tarif*" type="number" min="0" formControlName="pritar" [(ngModel)]="article.pritar"  value="{{article.pritar}}" name="pritar" style="color: #1b1e21">
          <control-messages [control]="form.controls.pritar"></control-messages>
        </div>
      </div>
    </div>
  </div>
  <div class=" col d-flex justify-content-center" style="padding-bottom: 1%">
    <div class="col-auto nbr-dossier " >
      <table>
        <tr>
          <td>
            <button class="btn-annule" style="width: 200px;height: 50px;" routerLink="/stock" >Annuler <img src="assets/images/btn-annule.png"> </button>
          </td>
          <td style="padding-right: 30px;">
            <button class="btn-valider" style="width: 200px;height: 50px;" [disabled]="!form.valid" (click)="updatearticle(article)" >Enregistrer<img src="assets/images/save.png"></button>
          </td>
        </tr>
      </table>
    </div>
  </div>
  </form>
</div>
