import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Http } from '@angular/http';
import { ClientService } from "../../../services/client.service";
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { ValidationService } from '../../../services/validate/validate-error.service';
import { ValidateService } from '../../../services/validate/validate.service';
import { ChargeAffaireService } from "../../../services/charge-affaire.service";
import { UserService } from "../../../services/user.service";
import { DossierService } from "../../../services/dossier.service";
import { UploadFileService } from "../../../services/upload-file-service.service";
import { environment } from '../../../../environments/environment';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';

@Component({
  selector: 'app-ajout-dossier',
  templateUrl: './ajout-dossier.component.html',
  styleUrls: ['../../utilisateur/ajout-utilisateur/ajout-utilisateur.component.scss','./ajout-dossier.component.css']
})
export class AjoutDossierComponent implements OnInit {
  clients: any
  chargeAffaires: any
  selectedChargeAffaires: any;
  selectedClient: any;
  selectedEtablissement: any;
  admin: any;
  form: any;
  formPreparationChiffrage: any;
  utilisateur: any;
  utilisateurs: any;
  personnesOfDepartement: any[] = [];
  AllselectedchargeAffaires: any[] = [];
  etablissements: any[] = [];
  listfiles: any[] = [];
  listfilesEmail: any[] = [];
  listfilesCCTP: any[] = [];
  listfilesPlan: any[] = [];
  id: any;
  selectedFiles: any;
  selectedEmails: any;
  selectedcctp: any;
  currentFileUpload: File
  sizeLimitOne = 10000000; //10MB
  public loading = false;
  roleAdministration = true;
  errorNumber = false;
  role: any;
  selectedplan: any;
  showDirectionEtablissement = false;
  etablissement =  "Établissement*"
  errorDate = false;
  myClient: any;
  dateReceptionDossier = new Date().toISOString().split('T')[0]
  datraa: any;
  bigDate = "9999-12-31"
  showEt = false;
  constructor(private uploadFileService: UploadFileService, private dossierService: DossierService, private chargeAffaireService: ChargeAffaireService, private validationService: ValidationService, private http: Http, private clientService: ClientService, private formBuilder: FormBuilder, private validateService: ValidateService,
    private router: Router, private userService: UserService) {
    this.form = this.formBuilder.group({
      numero: ['', [Validators.required, Validators.minLength(4), ValidationService.validateEmptyField, Validators.maxLength(25)]],
      type: ['Etude', [Validators.required]],
      nom: ['', [Validators.required, Validators.minLength(2), ValidationService.validateEmptyField, Validators.maxLength(100)]],
      datel: ['', [Validators.required, ValidationService.dateValidatorr]],
      dateReception: ['', [Validators.required, ValidationService.dateValidatorr]],
      client: ['', Validators.required],
      chargeAffaire: ['', Validators.required],
      etablissement: ['', Validators.required]
    })

  }
  ngOnInit() {

    this.getAllChargesAffaires();
    this.role = localStorage.getItem('roleUser')
    if (this.role) {
      this.roleAdministration = false;
    }
    this.id = localStorage.getItem('idUser')
    if (this.id) {
      this.userService.getUserByID(this.id).subscribe(utilisateur => {
        this.utilisateur = utilisateur;
        if(!this.utilisateur){
          localStorage.removeItem('idUser');
          localStorage.clear();
          this.router.navigate(['/login']);
        }
        if (!this.utilisateur) {
          this.router.navigateByUrl('/dossier');
        }
        if (this.utilisateur && this.utilisateur.role === "Super admin") {
          this.getAllClients();
        }
        if (this.utilisateur && this.utilisateur.role != "Super admin") {
          this.getNotRadiAllClients();
        }
        if(this.utilisateur.sousRole == 'gr-acces'){
          this.router.navigate(["accueil"]);
          return false;
        }
      },
        err => {
          return false;
        });
    } else {
      return false;
    }
  }

  // recuperer la liste des chargés d'ChargeAffaire
  getAllChargesAffaires() {
    this.chargeAffaireService.getAllUsers().then(data => {
      this.chargeAffaires = data;
    },
      err => {
        return false;
      });
  }
  getBigDate(): string {
      return this.bigDate
    }
  getNotRadiAllClients() {
    this.clients = []
    this.clientService.getAllUsers().then(data => {
      this.datraa = data;
      if (this.datraa) {
        for (var j = 0; j < this.datraa.length; j++) {
          if (this.datraa[j].statut === 'Active' && this.datraa[j].etablissements && this.datraa[j].etablissements.length > 0) {
            var etb = [];
            for (var i = 0; i < this.datraa[j].etablissements.length; i++) {
              if (this.datraa[j].etablissements[i].statutEtab === 'Active') {
                etb.push(this.datraa[j].etablissements[i])
              }
              if (i === Number(this.datraa[j].etablissements.length - 1)) {
                this.datraa[j].etablissements = etb
                this.clients.push(this.datraa[j])
              }
            }

          }

          if (this.datraa[j].statut === 'Active' && this.datraa[j].etablissements && this.datraa[j].etablissements.length < 1) {
            this.clients.push(this.datraa[j])
          }
        }

      }
    },
      err => {
        return false;
      });
  }
  // recuperer tous les clients
  getAllClients() {
    this.clientService.getAllUsers().then(data => {
      this.clients = data;
      this.clients = this.clients.sort(this.triClient);
    },
      err => {
        return false;
      });
  }
  selectClient() {
    this.myClient = this.form.value.client
    this.showDirectionEtablissement = false;
    this.selectedEtablissement = false;
    this.etablissements = this.form.value.client.etablissements
    this.selectedClient = this.form.value.client;
    this.AllselectedchargeAffaires = []
    if (this.etablissements.length < 1) {
      var newEtabl = {
        "nom": this.form.value.client.denomination,
        "type": "Etablissement principale",
        "adresse": this.form.value.client.adresse,
        "tel": this.form.value.client.tel,
        "mail": this.form.value.client.mail,
      }
      this.etablissements.push(newEtabl)
      return true;
    }
    if (this.etablissements.length > 0) {
      var newEtabl = {
        "nom": this.form.value.client.denomination,
        "type": "Etablissement principale",
        "adresse": this.form.value.client.adresse,
        "tel": this.form.value.client.tel,
        "mail": this.form.value.client.mail,
      }
      this.etablissement = this.form.value.client.etablissements
      this.etablissements.push(newEtabl)

      return true;
    }
    else {
      return false;
    }
  }
  showChargeAffaireDetails() {
    this.selectedChargeAffaires = this.form.value.chargeAffaire
  }
  // select établissemnt
  selectEtablissement() {
    this.showEt = true;
    this.selectedEtablissement = this.form.value.etablissement
    var etb = {
      "nom": this.form.value.etablissement.nom,
      "type": this.form.value.etablissement.type,
      "adresse": this.form.value.etablissement.adresse
    }
    if (this.chargeAffaires) {
      this.AllselectedchargeAffaires = [];
      this.selectedChargeAffaires = undefined
      for (var i = 0; i < this.chargeAffaires.length; i++) {
        if (this.chargeAffaires[i].client.etablissement) {
          var newEt = {
            "nom": this.chargeAffaires[i].client.etablissement.nom,
            "type": this.chargeAffaires[i].client.etablissement.type,
            "adresse": this.chargeAffaires[i].client.etablissement.adresse
          }
          if ((this.chargeAffaires[i].client.id === this.form.value.client.id) && (JSON.stringify(etb) === JSON.stringify(newEt))) {
            this.AllselectedchargeAffaires.push(this.chargeAffaires[i])
          }
        }
      }
      this.AllselectedchargeAffaires = this.AllselectedchargeAffaires.sort(this.triChargesAffaires);
    }
  }
  // ajouter  des fichiers

  selectFile(event) {
    const file = event.target.files.item(0)
    if (file) {
      /*if (!file.type.match('text.*|image.*|application.*')) {
        event = undefined
        alert("Impossible de télécharger ce fichier.")
        return false;
      }*/
      if (file.size > this.sizeLimitOne) {
        event = undefined
        Swal.fire({
          imageUrl: 'assets/images/error.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 600,
          title: 'Un fichier sélectionné est trop volumineux.La taille maximale est 10 Mo.',
          /*timer: 1500,*/
          showConfirmButton: true,
          confirmButtonColor: '#8B0000',
          confirmButtonWidth: 100,

        }
        );
        return false;
      }
      else {
        this.selectedFiles = file;
      }
    } else {
      return false;
    }
  }
  //  ajouter un mail
  selectFileEmail(event) {
    const file = event.target.files.item(0)
    if (file) {
      /*if (!file.type.match('message.*')) {
        event = undefined
        alert("Impossible de télécharger ce fichier.")
        return false;
      }*/
      if (file.size > this.sizeLimitOne) {
        event = undefined
        Swal.fire({
          imageUrl: 'assets/images/error.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 600,
          title: 'Un fichier sélectionné est trop volumineux.La taille maximale est 10 Mo.',
          /*timer: 1500,*/
          showConfirmButton: true,
          confirmButtonColor: '#8B0000',
          confirmButtonWidth: 100,

        }
        );
        return false;
      }
      else {
        this.selectedEmails = file
      }
    } else {
      return false;
    }

  }
  // ajouter CCTP
  selectFileCCTP(event) {
    const file = event.target.files.item(0)
    if (file) {
      /*if (!file.type.match('application/pdf') && !file.type.match('application/msword') && !file.type.match('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
        event = undefined
        alert("Impossible de télécharger ce fichier.")
        return false;
      }*/
      if (file.size > this.sizeLimitOne) {
        event = undefined
        Swal.fire({
          imageUrl: 'assets/images/error.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 600,
          title: 'Un fichier sélectionné est trop volumineux.La taille maximale est 10 Mo.',
          /*timer: 1500,*/
          showConfirmButton: true,
          confirmButtonColor: '#8B0000',
          confirmButtonWidth: 100,

        }
        );
        return false;
      }
      else {
        this.selectedcctp = file
      }
    } else {
      return false;
    }

  }
  // ajouter Plan
  selectFilePlan(event) {
    const file = event.target.files.item(0)
    if (file) {
      /*if (!file.type.match('text.*|image.*|application.*')) {
        event = undefined
        alert("Impossible de télécharger ce fichier.")
        return false;
      }*/
      if (file.size > this.sizeLimitOne) {
        event = undefined
        Swal.fire({
          imageUrl: 'assets/images/error.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 600,
          title: 'Un fichier sélectionné est trop volumineux.La taille maximale est 10 Mo.',
          /*timer: 1500,*/
          showConfirmButton: true,
          confirmButtonColor: '#8B0000',
          confirmButtonWidth: 100,

        }
        );
        return false;
      }
      else {
        this.selectedplan = file
      }
    } else {
      return false;
    }
  }
  onRegisterDossier() {
    Swal.fire({
      title: 'VOULEZ-VOUS CONFIRMER?',
      imageUrl: 'assets/images/dossier.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      showCancelButton: true,
      width: 800,
      confirmButtonColor: '#008000',
      cancelButtonColor: '#FFA500',
      confirmButtonText: 'CONFIRMER',

      cancelButtonText: 'ANNULER',
    }).then((result) => {
      if (!result.dismiss) {
        if (this.form.value.dateReception > this.form.value.datel) {
          this.errorDate = true;
          return false;
        }
        this.errorDate = false;
        var d = new Date();
        this.form.value.datec = d.toLocaleString();
        if (this.selectedFiles) {
          var fileExtension = this.selectedFiles.name.substr((this.selectedFiles.name.lastIndexOf('.') + 1));
          //var photo = this.utilisateur.id + i + '.' + fileExtension
          var randomVariable = this.form.value.numero.replace(/\./g, "") + "_Fichier"
          var imgObject = {
            randomVariable: randomVariable,
            name: this.selectedFiles.name,
            photo: environment.filesPath + randomVariable + "." + fileExtension
          }
          this.listfiles.push(imgObject)
        }
        if (this.selectedEmails) {
          var fileExtensionn = this.selectedEmails.name.substr((this.selectedEmails.name.lastIndexOf('.') + 1));
          //var photo = this.utilisateur.id + i + '.' + fileExtension
          var randomVariable = this.form.value.numero.replace(/\./g, "") + "_Mail"
          var imgObject = {
            randomVariable: randomVariable,
            name: this.selectedEmails.name,
            photo: environment.filesPath + randomVariable + "." + fileExtensionn
          }
          this.listfilesEmail.push(imgObject)
        }
        if (this.selectedcctp) {
          var fileExtensionn = this.selectedcctp.name.substr((this.selectedcctp.name.lastIndexOf('.') + 1));
          //var photo = this.utilisateur.id + i + '.' + fileExtension
          var randomVariable = this.form.value.numero.replace(/\./g, "") + "_CCTP"
          var imgObject = {
            randomVariable: randomVariable,
            name: this.selectedcctp.name,
            photo: environment.filesPath + randomVariable + "." + fileExtensionn
          }
          this.listfilesCCTP.push(imgObject)
        }
        if (this.selectedplan) {
          var fileExtensionn = this.selectedplan.name.substr((this.selectedplan.name.lastIndexOf('.') + 1));
          //var photo = this.utilisateur.id + i + '.' + fileExtension
          var randomVariable = this.form.value.numero.replace(/\./g, "") + "_Plan"
          var imgObject = {
            randomVariable: randomVariable,
            name: this.selectedplan.name,
            photo: environment.filesPath + randomVariable + "." + fileExtensionn
          }
          this.listfilesPlan.push(imgObject)
        }
        var newClient = {
          "denomination": this.form.value.client.denomination,
          "acronyme": this.form.value.client.acronyme,
          "id": this.form.value.client.id
        }
        var newChargeAffaire = {
          "nom": this.form.value.chargeAffaire.nom,
          "prenom": this.form.value.chargeAffaire.prenom,
          "id": this.form.value.chargeAffaire.id,
          "mail": this.form.value.chargeAffaire.mail,
          "tel": this.form.value.chargeAffaire.tel
        }
        var newUser = {
          "nom": this.utilisateur.nom,
          "prenom": this.utilisateur.prenom,
          "id": this.utilisateur.id
        }
        this.form.value.client = newClient
        this.form.value.chargeAffaire = newChargeAffaire
        this.form.value.user = newUser
        this.form.value.listfiles = this.listfiles
        this.form.value.listfilesEmail = this.listfilesEmail
        this.form.value.listfilesCCTP = this.listfilesCCTP
        this.form.value.listfilesPlan = this.listfilesPlan
        this.form.value.etablissement = this.form.value.etablissement.nom

        this.dossierService.verifyNumero(this.form.value).subscribe(data => {
          if (data) {
            this.errorNumber = true;
            this.listfiles = [];
            this.listfilesEmail = [];
            this.listfilesPlan = [];
            this.listfilesCCTP = [];
            return false;
          }
          else {
            this.loading = true;
            this.errorNumber = false;
            this.dossierService.addDossier(this.form.value).subscribe(resp => {
            }, err => {
            })

            if (this.selectedFiles) {
              this.currentFileUpload = this.selectedFiles
              this.uploadFileService.pushFilesToStorage(this.currentFileUpload, this.form.value.listfiles[0].randomVariable).subscribe(event => {
              }, err => {
              })
            }
            if (this.selectedEmails) {
              this.currentFileUpload = this.selectedEmails
              this.uploadFileService.pushFilesToStorage(this.currentFileUpload, this.form.value.listfilesEmail[0].randomVariable).subscribe(event => {
              }, err => {
              })
            }
            if (this.selectedcctp) {
              this.currentFileUpload = this.selectedcctp
              this.uploadFileService.pushFilesToStorage(this.currentFileUpload, this.form.value.listfilesCCTP[0].randomVariable).subscribe(event => {
              }, err => {
              })
            }
            if (this.selectedplan) {
              this.currentFileUpload = this.selectedplan
              this.uploadFileService.pushFilesToStorage(this.currentFileUpload, this.form.value.listfilesPlan[0].listfilesPlan).subscribe(event => {
              }, err => {
              })
            }
            this.form.reset();
            setTimeout(() => {
              /** spinner ends after 6 seconds **/
              this.loading = false;
              Swal.fire({
                imageUrl: 'assets/images/dossier.png',
                imageWidth: 100,
                imageHeight: 100,
                imageAlt: 'Custom image',
                width: 800,
                title: 'BIEN AJOUTÉ',
                timer: 1500,
                showConfirmButton: false
              }
              );
              this.router.navigate(['/dossier'])
            }, 5000);
          }

        })
      }
    });
  }
  // trier le client
  triClient(a, b) {
    if (a.denomination.toLowerCase() < b.denomination.toLowerCase()) return -1;
    else if (a.denomination.toLowerCase() == b.denomination.toLowerCase()) return 0;
    else return 1;
  }
  // trier le chargés d'affaires
  triChargesAffaires(a, b) {
    if (a.nom.toLowerCase() < b.nom.toLowerCase()) return -1;
    else if (a.nom.toLowerCase() == b.nom.toLowerCase()) return 0;
    else return 1;
  }
  getDateReceptionDossier(): string {
    return this.dateReceptionDossier
  }
}
