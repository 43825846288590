import { Component, OnInit } from '@angular/core';
import { Router}from '@angular/router';
import { UserService } from "../../../services/user.service";
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
utilisateur:any;
id:any;
isvoguel = true;
constructor(private router:Router, private userService:UserService ) {

}
    ngOnInit(){
      let host = window.location.hostname
     if(host.indexOf("hotelcaravanserail.com") > -1 || host.indexOf("localhost") > -1){
      this.isvoguel = false;
    } 
      this.id = localStorage.getItem('idUser')
      if(!this.id || this.id === undefined || this.id === 'undefined'){
        localStorage.removeItem('idUser');
        localStorage.clear();
        this.router.navigate(['/login']);
        return false
      }
      this.userService.getUserByID(this.id).subscribe(utilisateur => {
        this.utilisateur = utilisateur;
        if(!this.utilisateur){
          localStorage.removeItem('idUser');
          localStorage.clear();
          this.router.navigate(['/login']);
        }
      },
        err => {
          return false;
        });

    }


}
