import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InventaireByFournisseurComponent } from './inventaire-by-fournisseur/inventaire-by-fournisseur.component';
import { InventaireComponent } from './inventaire/inventaire.component';
import { AjoutInventaireBycatalogueYearComponent } from './ajout-inventaire-bycatalogue-year/ajout-inventaire-bycatalogue-year.component';
import { AjoutInventaireManuelComponent } from './ajout-inventaire-manuel/ajout-inventaire-manuel.component';
import { AjoutInventaireComponent } from './ajout-inventaire/ajout-inventaire.component';
import { DetailsInventaireComponent } from './details-inventaire/details-inventaire.component';
import { UpdateInventaireComponent } from './update-inventaire/update-inventaire.component';
import { InventaireByYearComponent } from './inventaire-by-year/inventaire-by-year.component';
import { InventaireByYearWithoutFourComponent } from './inventaire-by-year-without-four/inventaire-by-year-without-four.component';
import { InventaireGuard } from 'src/app/guards/inventaire.guard';

const routes: Routes = [
      { path: 'inventaire/:date/:id', component: InventaireComponent, canActivate: [InventaireGuard]},
      { path: 'inventaire/fournisseur/list/:date', component: InventaireByYearWithoutFourComponent, canActivate: [InventaireGuard]},
      { path: 'inventaire', component: InventaireByFournisseurComponent, canActivate: [InventaireGuard]},
      { path: 'inventaire/fournisseurs/years/:year', component: InventaireByYearComponent, canActivate: [InventaireGuard]},
      { path: 'ajoutInventaire/:years/:id', component: AjoutInventaireBycatalogueYearComponent, canActivate: [InventaireGuard]},
      { path: 'ajoutInventaire/manuel', component: AjoutInventaireManuelComponent, canActivate: [InventaireGuard]},
      { path: 'ajoutInventaire', component: AjoutInventaireComponent, canActivate: [InventaireGuard]},
      { path: 'details/inventaire/:id', component: DetailsInventaireComponent, canActivate: [InventaireGuard]},
      { path: 'update/:id', component: UpdateInventaireComponent, canActivate: [InventaireGuard]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InventaireRoutingModule { }
