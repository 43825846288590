import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http } from '@angular/http';
import { AgenceService } from "../../../services/agence.service";
import { environment } from '../../../../environments/environment';
import { codesNav } from '../../../pays/codesNav';
import { triCodes } from '../../../pays/codesNav';
import { pays } from '../../../pays/pays';
import { tri } from '../../../pays/pays';
import { villes } from '../../../pays/villes';
import { ValidateService } from '../../../services/validate/validate.service';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { ValidationService } from '../../../services/validate/validate-error.service';
import { UploadFileService } from "../../../services/upload-file-service.service";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
import * as moment from 'moment';
import { codepostale } from '../../../pays/villes';
@Component({
  selector: 'app-update-agence',
  templateUrl: './update-agence.component.html',
  styleUrls: ['../../utilisateur/update-utilisateur/update-utilisateur.component.scss']
})
export class UpdateAgenceComponent implements OnInit {

  client: any;
  codes: any[] = [];
  pys: any[] = [];
  vlls: any[] = [];
  nom: any;
  type = "Établissement principal";
  mail: any;
  tel: any;
  p: any;
  codePostal: any;
  rue: any;
  n: any;
  ville: any;
  secteur: any;
  addEtablissments: any;
  formAddEtablissement: any;
  formAddEtablissementAdd: any;
  errorNom = false;
  errorType = false;
  errorMail = false;
  roleAdministration = true;
  role: any;
  showAndHoideDateRadiation = false
  showAndHoideDateRadiationEtab = false;
  myfiles: any;
  sizeLimitOne = 10000000; //10MB
  loading = false
  savedAdresseClient: any;
  showMyContainer = 0;
  etablissement:any;
  dateNow = moment().subtract(1,'d').format('YYYY-MM-DD');
  bigDate = "9999-12-31"
  errorSaveFirstPartie = ""
  errorSaveSecondePartie = ""
  errorSaveFourthPartie = ""
  constructor(private uploadFileService: UploadFileService, private formBuilder: FormBuilder, private http: Http, private clientService: AgenceService, private activatedRoute: ActivatedRoute, private validateService: ValidateService,
    private router: Router) {
    this.formAddEtablissement = this.formBuilder.group({
      nom: ['', [Validators.required, Validators.minLength(4),Validators.maxLength(100)]],
      type: ['Établissement principal', Validators.required],
      mail: ['', [Validators.required, ValidationService.emailValidator,Validators.maxLength(100)]],
      tel: ['+216'],
      statutEtab: ['Active'],
      dateRadiationEtab: [''],
      adresse: this.formBuilder.group({
        pays: ['Tunisie'],
        codePostal: ['', [ValidationService.numberValidator, Validators.min(0), Validators.max(9999999)]],
        rue: ['',[Validators.maxLength(100)]],
        n: ['', [ValidationService.numberValidator, Validators.min(0), Validators.max(9999999)]],
        ville: ['',[Validators.maxLength(100)]],
      }),
    })

    this.formAddEtablissementAdd = this.formBuilder.group({
      nom: ['', [Validators.required, Validators.minLength(4),Validators.maxLength(100)]],
      type: ['Établissement principal', Validators.required],
      mail: ['', [Validators.required, ValidationService.emailValidator,Validators.maxLength(100)]],
      tel: ['+216'],
      statutEtab: ['Active'],
      dateRadiationEtab: [''],
      adresse: this.formBuilder.group({
        pays: ['Tunisie'],
        codePostal: ['', [ValidationService.numberValidator, Validators.min(0), Validators.max(9999999)]],
        rue: ['',[Validators.maxLength(100)]],
        n: ['', [ValidationService.numberValidator, Validators.min(0), Validators.max(9999999)]],
        ville: ['',[Validators.maxLength(100)]],
      }),
    })
  }
  ngOnInit() {
    this.codes = codesNav.sort(triCodes);
    this.pys = pays.sort(tri);
    this.pys = this.pys.filter(x => x.name === "Tunisie")
    this.vlls = codepostale
    this.role = localStorage.getItem('roleUser')
    if (this.role) {
      this.roleAdministration = false;
    }
    let params: any = this.activatedRoute.snapshot.params;
    this.clientService.getClientByID(params.id).subscribe(client => {
      this.client = client;
      if (this.client) {
        if (this.client.statut === 'Radiée') {
          this.showAndHoideDateRadiation = true;
        }
        if (!this.client.historiquesAdresses) {
          this.client.historiquesAdresses = [];
        }
        this.savedAdresseClient = JSON.parse(JSON.stringify(this.client.adresse));
        this.secteur = this.client.activite
        if (this.client.multiEtablissement === "Non") {
          this.addEtablissments = false;
        }
        if (this.client.multiEtablissement === "Oui") {
          this.addEtablissments = true;
        }
        else {
          this.addEtablissments = false;
        }
      }
    },
      err => {
        return false;
      });
  }
  getdatenow(): string {
    return this.dateNow
  }
  getBigDate(): string {
      return this.bigDate
    }
  showSociete() {
    this.secteur = this.client.codeNaf.fields['intitule_naf'];
  }
  showEtablissment() {
    if (this.client.multiEtablissement === "Non") {
      this.addEtablissments = false;
    }
    if (this.client.multiEtablissement === "Oui") {
      this.addEtablissments = true;
    }
    else {
      this.addEtablissments = false;
    }
  }


  // ajuter des etablissments
  addnewEtablissment() {
    this.client.etablissements.push(this.formAddEtablissementAdd.value);
    this.formAddEtablissementAdd.reset();
  }
  // supprimer un établissement
  removeEtablissement(etablissement) {
    Swal.fire({
          title: 'VOULEZ-VOUS SUPPRIMER CET ÉTABLISSEMENT?',
          imageUrl: 'assets/images/supp-modal.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          showCancelButton: true,
          width: 800,
          confirmButtonColor: '#059e00',
          cancelButtonColor: '#FF9100',
          cancelButtonText: 'ANNULER',
          confirmButtonText: 'VALIDER'
        }).then((result) => {
          if (!result.dismiss) {
            Swal.fire( {
                imageUrl: 'assets/images/supp-modal.png',
                imageWidth: 100,
                imageHeight: 100,
                imageAlt: 'Custom image',
                width: 800,
                title: 'BIEN SUPPRIMÉ',
                timer: 1500,
                showConfirmButton: false
              }
            );
            this.client.etablissements.splice(this.client.etablissements.indexOf(etablissement), 1);
          }
        } );

  }
  // update un établissement
  editEtablissment(etablissement) {
    if (etablissement.nom == undefined || etablissement.nom.length < 3) {
      this.errorNom = true;
      this.errorType = false;
      this.errorMail = false;
      return false;
    }
    if (etablissement.type == undefined) {
      this.errorNom = false;
      this.errorType = true;
      this.errorMail = false;
      return false;
    }
    if (etablissement.mail == undefined || !this.validateService.validateEmail(etablissement.mail)) {
      this.errorNom = false;
      this.errorType = false;
      this.errorMail = true;
      return false;
    }
    else {
      this.errorNom = false;
      this.errorType = false;
      this.errorMail = false;
      return true;
    }
  }
  // update client
  onUpdateClient(client) {
    if(!this.client.etablissements || this.client.etablissements.length < 1){
      this.client.multiEtablissement = "Non"
    }
    if(this.showMyContainer === 1){
      if((client.type === "Particulier" && client.siren && client.siren.toString().length != 14) || (client.type === "Professionnel"  && client.siren.toString().length != 14) ){
        this.errorSaveFirstPartie = "Merci de vérifier le champ identifiant unique"
        return false;
      }
      if(!client.denomination || client.denomination.length < 4 || client.denomination.length > 100){
        this.errorSaveFirstPartie = "Merci de vérifier le champ dénomination (minimum 4 caractères, maximum 100 caractères)"
        return false;
      }
      if(client.acronyme && (client.acronyme.length < 4 || client.acronyme.length > 100)){
        this.errorSaveFirstPartie = "Merci de vérifier le champ acronyme (minimum 4 caractères, maximum 100 caractères)"
        return false;
      }
      if(!client.formeJuridique ){
        this.errorSaveFirstPartie = "Merci de vérifier le champ forme juridique"
        return false;
      }
      if(!client.codeNaf ){
        this.errorSaveFirstPartie = "Merci de vérifier le champ code activité"
        return false;
      }
      if(client.datec &&  client.datec > this.dateNow){
        this.errorSaveFirstPartie = "Merci de vérifier le champ immatriculé le"
        return false;
      }
      this.errorSaveFirstPartie = ""
    }
    if(this.showMyContainer === 2){
      if(!client.mail || (client.mail && (client.mail.length <2 || client.mail.length > 100 || !this.validateService.validateEmail(client.mail)) )){
        this.errorSaveSecondePartie = "Merci de vérifier le champ Email"
        return false;
      }
      if(!client.tel || (client.tel && client.tel.length != 12  )){
        this.errorSaveSecondePartie = "Merci de vérifier le champ N° fixe"
        return false;
      }
     
      if((client.adresse.codePostal && (!Number(client.adresse.codePostal) || client.adresse.codePostal < 0 || client.adresse.codePostal > 99999 || client.adresse.codePostal.toString().indexOf('e') > -1)) ){
        this.errorSaveSecondePartie = "Merci de vérifier le champ code postal"
        return false;
      }
      if((client.adresse.n && (!Number(client.adresse.n) || client.adresse.n < 0 || client.adresse.n > 99999 || client.adresse.n.toString().indexOf('e') > -1 )) ){
        this.errorSaveSecondePartie = "Merci de vérifier le champ numéro"
        return false;
      }
      this.errorSaveSecondePartie = ""
    }
    if(this.showMyContainer === 4){
      if(client.informationbancaire && client.informationbancaire.nom && client.informationbancaire.nom.length > 100){
        this.errorSaveFourthPartie = "Merci de vérifier le champ nom (maximum 100 caractères)"
        return false;
      }
      if(client.informationbancaire && client.informationbancaire.iban && client.informationbancaire.iban.length > 100){
        this.errorSaveFourthPartie = "Merci de vérifier le champ iban (maximum 100 caractères)"
        return false;
      }
      if(client.informationbancaire && client.informationbancaire.swift && client.informationbancaire.swift.length > 100){
        this.errorSaveFourthPartie = "Merci de vérifier le champ swift (maximum 100 caractères)"
        return false;
      }
      if(client.informationbancaire && client.informationbancaire.rib && client.informationbancaire.rib.length > 100){
        this.errorSaveFourthPartie = "Merci de vérifier le champ rib (maximum 100 caractères)"
        return false;
      }
      if(client.informationbancaire && client.informationbancaire.bic && client.informationbancaire.bic.length > 100){
        this.errorSaveFourthPartie = "Merci de vérifier le champ bic (maximum 100 caractères)"
        return false;
      }
      this.errorSaveFourthPartie = ""
    }
    Swal.fire({
      title: 'VOULEZ-VOUS CONFIRMER?',
      imageUrl: 'assets/images/client-plus.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      showCancelButton: true,
      width: 800,
      confirmButtonColor: '#008000',
      cancelButtonColor: '#FFA500',
      confirmButtonText: 'CONFIRMER',

      cancelButtonText: 'ANNULER',
    }).then((result) => {
      if (!result.dismiss) {
          if (client.codeNaf) {
            client.indicatif = client.codeNaf.fields.code_naf
            client.activite = this.secteur
          }
          if (this.myfiles && this.myfiles.length > 0) {
            this.loading = true;
            for (var x = 0; x < this.myfiles.length; x++) {
              var fileExtensionn = this.myfiles[x].name.substr((this.myfiles[x].name.lastIndexOf('.') + 1));
              //var photo = this.utilisateur.id + i + '.' + fileExtension
              var randomVariable = "logo_" + client.denomination.replace(/\s+/g, '-');
              client.nameLogo = this.myfiles[x].name;
              client.linkLogo = environment.filesPath + randomVariable + "." + fileExtensionn
              this.uploadFileService.pushFilesToStorage(this.myfiles[x], randomVariable).subscribe(event => {
              }, err => {
              })
            }
          }
          if (client.adresse.n != this.savedAdresseClient.n || client.adresse.rue != this.savedAdresseClient.rue || client.adresse.ville != this.savedAdresseClient.ville || client.adresse.codePostal != this.savedAdresseClient.codePostal || client.adresse.pays != this.savedAdresseClient.pays) {
            client.historiquesAdresses.push(this.savedAdresseClient)
          }
          this.clientService.editClientById(client)
            .subscribe(resp => {
            }, err => {

            })
          this.router.navigateByUrl('/agence-voyage');
          Swal.fire({
            imageUrl: 'assets/images/client-plus.png',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            width: 800,
            title: 'BIEN MODIFIÉ',
            timer: 1500,
            showConfirmButton: false
          }
          );
        }
      } );
  }
  showAndHoideDateRadiationFunc(value) {
    this.showAndHoideDateRadiation = false;
    if (value === 'Radiée') {
      this.showAndHoideDateRadiation = true;
    } else {
      this.showAndHoideDateRadiation = false;
    }
  }
  showAndHoideDateRadiationFuncEtab() {
    this.showAndHoideDateRadiationEtab = false;
    if (this.formAddEtablissementAdd.value.statutEtab === 'Radiée') {
      this.showAndHoideDateRadiationEtab = true;
    } else {
      this.showAndHoideDateRadiationEtab = false;
    }
  }
  // controler le logo
  selectFiles(event) {
    this.myfiles = event.target.files
    if (this.myfiles && this.myfiles.length > 0) {
      if (this.myfiles[0].size > this.sizeLimitOne) {
        this.myfiles = undefined;
        Swal.fire({
          imageUrl: 'assets/images/error.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 600,
          title: 'Un fichier sélectionné est trop volumineux.La taille maximale est 10 Mo.',
          /*timer: 1500,*/
          showConfirmButton: true,
          confirmButtonColor: '#8B0000',
          confirmButtonWidth: 100,

        }
        );
        return false;
      }
      if (this.myfiles[0].type != "image/png" && this.myfiles[0].type != "image/jpeg" && this.myfiles[0].type != "image/jpg") {
        this.myfiles = undefined;
        Swal.fire({
          imageUrl: 'assets/images/error.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 600,
          title: 'Impossible de télécharger ce fichier.',
          /*timer: 1500,*/
          showConfirmButton: true,
          confirmButtonColor: '#8B0000',
          confirmButtonWidth: 100,

        }
        );
        return false;
      }
    } else {
      return false;
    }
  }
  deleteLogo(client) {
    Swal.fire({
      title: 'VOULEZ-VOUS SUPPRIMER CE LOGO?',
      imageUrl: 'assets/images/supp-modal.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      showCancelButton: true,
      width: 800,
      confirmButtonColor: '#059e00',
      cancelButtonColor: '#FF9100',
      cancelButtonText: 'ANNULER',
      confirmButtonText: 'VALIDER'
    }).then((result) => {
      if (!result.dismiss) {
        Swal.fire({
          imageUrl: 'assets/images/supp-modal.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 800,
          title: 'BIEN SUPPRIMÉ',
          timer: 1500,
          showConfirmButton: false
        }
        );
        client.linkLogo = null
      }
    });
  }
  modifPart1() {
    if (this.showMyContainer !== 1) {
      this.showMyContainer = 1;
    }
    else {
      this.showMyContainer = 0;
    }
  }
  modifPart2() {
    if (this.showMyContainer !== 2) {
      this.showMyContainer = 2;
    }
    else {
      this.showMyContainer = 0;
    }
  }
  modifPart3() {
    if (this.showMyContainer !== 3) {
      this.showMyContainer = 3;
    }
    else {
      this.showMyContainer = 0;
    }
  }
  modifPart4() {
    if (this.showMyContainer !== 4) {
      this.showMyContainer = 4;
    }
    else {
      this.showMyContainer = 0;
    }
  }
  showPart() {
    return this.showMyContainer;
  }
seteblissement(etablissement){
  this.etablissement = etablissement
}
}

