import { Component, OnInit } from '@angular/core';
import { InventaireService } from "../../../services/inventaire.service";
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { FournisseurService } from "../../../services/fournisseur.service";
import { Router } from '@angular/router';
import { CatalogueService } from "../../../services/catalogue.service";
import { UserService } from "../../../services/user.service";
import { HistoriqueService } from "../../../services/historique.service";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
import { FamilleService } from "../../../services/famille.service";
import { codesSchneider } from '../../../pays/codesSchneider';


@Component({
  selector: 'app-ajout-inventaire-manuel',
  templateUrl: './ajout-inventaire-manuel.component.html',
  styleUrls: ['../../utilisateur/ajout-utilisateur/ajout-utilisateur.component.scss'],
})
export class AjoutInventaireManuelComponent implements OnInit {
  form: any;
  dateDebut = new Date().toISOString().split('T')[0]
  showArray = false;
   fieldArray: Array<any> = [];
   newAttribute: any = {};
  loading = false;
  showErrorData = false
   fieldArrayy: Array<any> = [];
  inventaireConfigObj: any;
  id: any;
  utilisateur: any;
  inventiareactivationdata:any;
  errorQauntite = false;
  familles:any;
  bigDate = "9999-12-31"
  constructor(private familleService:FamilleService,private catalogueService: CatalogueService, private inventaireService: InventaireService, private formBuilder: FormBuilder, private fournisseurService: FournisseurService, private router: Router, private userService: UserService,
    private historiqueService: HistoriqueService) {
    this.form = this.formBuilder.group({
      datec: ['', Validators.required]
    })

  }
  ngOnInit() {
    this.id = localStorage.getItem('idUser')
    this.userService.getUserByID(this.id).subscribe(utilisateur => {
      this.utilisateur = utilisateur;
      if(!this.utilisateur){
        localStorage.removeItem('idUser');
        localStorage.clear();
        this.router.navigate(['/login']);
      }
      if (!this.utilisateur) {
        this.router.navigateByUrl('/inventaire');
      }
      if (this.utilisateur && this.utilisateur.mail == 'suren.vijey@krendel.fr') {
        this.router.navigateByUrl('/accueil');
        return false;
      }
      if(this.utilisateur && this.utilisateur.role != 'Super admin'){
        this.inventaireService.getAllDataConfigActivation()
          .then(data => {
            this.inventiareactivationdata = data
            var ext = this.inventiareactivationdata.find(x=>x.desactive == false);
            if(!ext){
              this.router.navigateByUrl('/inventaire');
            }
          }, err => {
          })
      }
    },
      err => {
        return false;
      });

    this.inventaireService.getAllDataConfig().then(data => {
      if (data && data[0]) {
        this.inventaireConfigObj = data[0]
        if (this.inventaireConfigObj && this.inventaireConfigObj.dateInventaireChosie) {
          this.showArray = true;
          this.form.value.datec = this.inventaireConfigObj.dateInventaireChosie
          this.dateDebut = this.inventaireConfigObj.dateInventaireChosie
        }
      }
    },
      err => {
        return false;
      });

      this.familleService.getAllData().then(data => {
        this.familles = data
      },
        err => {
          return false;
        });
  }

  showArrayFunction() {
    this.showArray = true;
  }
  getBigDate(): string {
      return this.bigDate
    }
  getDateDebut(): string {
    return this.dateDebut
  }
  updateQteEmballe(newAttribute){
    if (!this.newAttribute.quantiteEmballe || this.newAttribute.quantiteEmballe == undefined || this.newAttribute.quantiteEmballe == '' || this.newAttribute.quantiteEmballe < 0 || this.newAttribute.quantiteEmballe > 99999) {
      this.newAttribute.quantiteEmballe = 0
      return false;
    }
    this.calculateQuantite()
  }
  updateQteNonEmballe(newAttribute){
    if (!this.newAttribute.quantiteNonEmballe || this.newAttribute.quantiteNonEmballe == undefined || this.newAttribute.quantiteNonEmballe == '' || this.newAttribute.quantiteNonEmballe < 0 || this.newAttribute.quantiteNonEmballe > 99999) {
      this.newAttribute.quantiteNonEmballe = 0
      return false;
    }
    this.calculateQuantite()
  }
  calculateQuantite(){
    if (!this.newAttribute.quantiteNonEmballe || this.newAttribute.quantiteNonEmballe == undefined || this.newAttribute.quantiteNonEmballe == '' || this.newAttribute.quantiteNonEmballe < 0 || this.newAttribute.quantiteNonEmballe > 99999) {
      return false;
    }
    if (!this.newAttribute.quantiteEmballe || this.newAttribute.quantiteEmballe == undefined || this.newAttribute.quantiteEmballe == '' || this.newAttribute.quantiteEmballe < 0 || this.newAttribute.quantiteEmballe > 99999) {
      return false;
    }
    this.newAttribute.quantite = this.newAttribute.quantiteEmballe + this.newAttribute.quantiteNonEmballe
    this.newAttribute.quantite = Math.round( this.newAttribute.quantite * 100) / 100
  }
  addFieldValue() {
    this.errorQauntite = false;
    this.newAttribute.datec = this.dateDebut
    this.newAttribute.exist = false;
    this.showErrorData = false;
    if (!this.newAttribute.refArt || this.newAttribute.refArt == undefined || this.newAttribute.refArt == '' || this.newAttribute.refArt.length > 100) {
      this.showErrorData = true;
      return false;
    }
    if (!this.newAttribute.quantite || this.newAttribute.quantite == undefined || this.newAttribute.quantite == '' || this.newAttribute.quantite < 0 || this.newAttribute.quantite > 99999) {
      this.showErrorData = true;
      return false;
    }
    if (!this.newAttribute.etage || this.newAttribute.etage == undefined || this.newAttribute.etage == '') {
      this.showErrorData = true;
      return false;
    }
    if (!this.newAttribute.quantiteEmballe || this.newAttribute.quantiteEmballe == undefined || this.newAttribute.quantiteEmballe == '') {
      this.newAttribute.quantiteEmballe = 0
    }
    if (!this.newAttribute.quantiteNonEmballe || this.newAttribute.quantiteNonEmballe == undefined || this.newAttribute.quantiteNonEmballe == '') {
      this.newAttribute.quantiteNonEmballe = 0
    }
    if(Number(this.newAttribute.quantite) != Number(this.newAttribute.quantiteNonEmballe + this.newAttribute.quantiteEmballe)){
      this.errorQauntite = true;
      return false;
    }
    this.showErrorData = false;
    this.inventaireService.getArticleByRefArtAndDate(this.newAttribute.refArt,this.newAttribute.etage, this.dateDebut).then((dataInventiare: any[]) => {

      if (dataInventiare && dataInventiare.length > 0) {
        dataInventiare[dataInventiare.length - 1].quantiteEmballe = dataInventiare[dataInventiare.length - 1].quantiteEmballe + this.newAttribute.quantiteEmballe
        dataInventiare[dataInventiare.length - 1].quantiteNonEmballe = dataInventiare[dataInventiare.length - 1].quantiteNonEmballe + this.newAttribute.quantiteNonEmballe
        dataInventiare[dataInventiare.length - 1].quantite = dataInventiare[dataInventiare.length - 1].quantite + this.newAttribute.quantite
        this.newAttribute.denomination = dataInventiare[dataInventiare.length - 1].denomination
        this.newAttribute.designation = dataInventiare[dataInventiare.length - 1].designation
        this.fieldArrayy.push(this.newAttribute)
        var hist = {
          "idUser": this.utilisateur.id,
          "date": new Date().toISOString().split('T')[0],
          "type": "Inventaire",
          "dataScan": [
            {
              "datec": this.newAttribute.datec,
              "quantite": this.newAttribute.quantite,
              "refArt": this.newAttribute.refArt,
              "designation": this.newAttribute.designation
            }
          ]
        }
        this.inventaireService.editInventairerById(dataInventiare[dataInventiare.length - 1])
          .subscribe(resp => {
            this.historiqueService.getDataByDateAndUser(hist.date, hist.idUser,hist.type)
              .then((dataHist: any[]) => {
                if (dataHist && dataHist.length > 0) {
                  dataHist[0].dataScan.push(...hist.dataScan);
                  this.historiqueService.editIhistoriquerById(dataHist[0])
                    .subscribe(resp => {
                    }, err => {
                    })
                } else {
                  this.historiqueService.addhistorique(hist)
                    .subscribe(resp => {

                    }, err => {
                    })
                }
              }, err => {
              })
          }, err => {
          })
        this.newAttribute = {};
      } else {
        this.catalogueService.getAllDataByRefOrEAN(this.newAttribute.refArt).then((data: any[]) => {
          if (data && data.length > 0) {
            var myresult = data.filter(x => x.datec <= this.newAttribute.datec)
            myresult.sort(this.triYearCommande);
            this.newAttribute.denomination = myresult[0].denomination
            this.newAttribute.designation = myresult[0].designation25
            this.newAttribute.idFournisseur = myresult[0].idFournisseur
            this.newAttribute.refArt = myresult[0].refCiel
            this.newAttribute.exist = true;
            this.newAttribute.ean13Art = myresult[0].ean13
            this.newAttribute.pritar = myresult[0].tarif
            this.newAttribute.mkt5 = myresult[0].mkt5
            if( this.newAttribute.denomination == "SCHNEIDER ELECTRIC Tunisie"){

              if(this.newAttribute.mkt5){
                var mymkt = this.newAttribute.mkt5
                var foundFamillee = codesSchneider.filter(x => x.refFamille.toLowerCase().startsWith(mymkt[0].toLowerCase() + mymkt[1].toLowerCase()))
                if(foundFamillee && foundFamillee.length == 1){
                  this.newAttribute.codFamille = foundFamillee[0].codeFamille
                  this.newAttribute.libelleFamille = foundFamillee[0].libelle
                  this.newAttribute.coefFamille = foundFamillee[0].coefFamille
                }
                if(foundFamillee && foundFamillee.length > 1 && mymkt[2]){
                  var foundFamilleSecond = codesSchneider.filter(x => x.refFamille.toLowerCase().startsWith(mymkt[0].toLowerCase() + mymkt[1].toLowerCase() + mymkt[2].toLowerCase()))
                  if(!foundFamilleSecond){
                    this.newAttribute.codFamille = foundFamillee[0].codeFamille
                    this.newAttribute.libelleFamille = foundFamillee[0].libelle
                    this.newAttribute.coefFamille = foundFamillee[0].coefFamille
                  }
                  if(foundFamilleSecond && foundFamilleSecond.length < 1){
                    this.newAttribute.codFamille = foundFamillee[0].codeFamille
                    this.newAttribute.libelleFamille = foundFamillee[0].libelle
                    this.newAttribute.coefFamille = foundFamillee[0].coefFamille
                  }
                  if(foundFamilleSecond && foundFamilleSecond.length == 1){
                    this.newAttribute.codFamille = foundFamilleSecond[0].codeFamille
                    this.newAttribute.libelleFamille = foundFamilleSecond[0].libelle
                    this.newAttribute.coefFamille = foundFamilleSecond[0].coefFamille
                  }
                  if(foundFamillee && foundFamillee.length > 1 && mymkt[3]){
                    var foundFamilleThird = codesSchneider.filter(x => x.refFamille.toLowerCase().startsWith(mymkt[0].toLowerCase() + mymkt[1].toLowerCase() + mymkt[2].toLowerCase() + mymkt[3].toLowerCase()))
                    if(!foundFamilleThird){
                      this.newAttribute.codFamille = foundFamilleSecond[0].codeFamille
                      this.newAttribute.libelleFamille = foundFamilleSecond[0].libelle
                      this.newAttribute.coefFamille = foundFamilleSecond[0].coefFamille
                    }
                    if(foundFamilleThird && foundFamilleThird.length < 1){
                      if(foundFamilleSecond && foundFamilleSecond[0]){
                        this.newAttribute.codFamille = foundFamilleSecond[0].codeFamille
                        this.newAttribute.libelleFamille = foundFamilleSecond[0].libelle
                        this.newAttribute.coefFamille = foundFamilleSecond[0].coefFamille
                      }
                    }
                    if(foundFamilleThird && foundFamilleThird.length == 1){
                      this.newAttribute.codFamille = foundFamilleThird[0].codeFamille
                      this.newAttribute.libelleFamille = foundFamilleThird[0].libelle
                      this.newAttribute.coefFamille = foundFamilleThird[0].coefFamille
                    }
                  }
                }
              }
            }
            if(this.newAttribute.denomination != "SCHNEIDER ELECTRIC Tunisie"  && this.newAttribute.denomination != "PHOENIX CONTACT"){
              if(this.newAttribute.denomination){
                var foundFamille = this.familles.filter(x => this.newAttribute.denomination.toLowerCase().indexOf(x.libelle.toLowerCase()) !== -1 )
              }
              if(foundFamille && foundFamille.length > 0){
                this.newAttribute.codFamille = foundFamille[0].codFamille
                this.newAttribute.libelleFamille = foundFamille[0].libelle
                this.newAttribute.coefFamille = foundFamille[0].coefFamille
              }
            }
            if(this.newAttribute.denomination == "PHOENIX CONTACT"){
              var foundFamille = this.familles.filter(x => x.libelle == "PHOENIX NET" )
              if(foundFamille && foundFamille.length > 0){
                this.newAttribute.codFamille = foundFamille[0].codFamille
                this.newAttribute.libelleFamille = foundFamille[0].libelle
                this.newAttribute.coefFamille = foundFamille[0].coefFamille
              }
            }
            this.fieldArrayy.push(this.newAttribute)
            this.fieldArray.push(this.newAttribute)
            this.newAttribute = {};
            return true;
          } else {
            this.fieldArrayy.push(this.newAttribute)
            this.fieldArray.push(this.newAttribute)
            this.newAttribute = {};
            return true;
          }
        },
          err => {
            return false;
          });
      }
    },
      err => {
        return false;
      });


  }

  deleteFieldValue(index) {
    var filedToDelete = this.fieldArrayy[index]
    if (filedToDelete) {
      this.inventaireService.getArticleByRefArt(filedToDelete.refArt).then((dataInventiaree: any[]) => {
        if (dataInventiaree && dataInventiaree.length > 0) {
          dataInventiaree[dataInventiaree.length - 1].quantiteEmballe = dataInventiaree[dataInventiaree.length - 1].quantiteEmballe - filedToDelete.quantiteEmballe
          dataInventiaree[dataInventiaree.length - 1].quantiteNonEmballe = dataInventiaree[dataInventiaree.length - 1].quantiteNonEmballe - filedToDelete.quantiteNonEmballe
          dataInventiaree[dataInventiaree.length - 1].quantite = dataInventiaree[dataInventiaree.length - 1].quantite - filedToDelete.quantite
          this.inventaireService.editInventairerById(dataInventiaree[dataInventiaree.length - 1])
            .subscribe(resp => {
              var hist = {
                "idUser": this.utilisateur.id,
                "date": new Date().toISOString().split('T')[0],
                "type": "Inventaire",
                "dataScan": [
                  {
                    "datec": dataInventiaree[dataInventiaree.length - 1].datec,
                    "quantite": "-" + filedToDelete.quantite,
                    "refArt": dataInventiaree[dataInventiaree.length - 1].refArt,
                    "designation": dataInventiaree[dataInventiaree.length - 1].designation
                  }
                ]
              }
              this.historiqueService.getDataByDateAndUser(hist.date, hist.idUser,hist.type)
                .then((dataHist: any[]) => {
                  if (dataHist && dataHist.length > 0) {
                    dataHist[0].dataScan.push(...hist.dataScan);
                    this.historiqueService.editIhistoriquerById(dataHist[0])
                      .subscribe(resp => {
                      }, err => {
                      })
                  } else {
                    this.historiqueService.addhistorique(hist)
                      .subscribe(resp => {

                      }, err => {
                      })
                  }
                }, err => {
                })

            }, err => {

            })
        }

      }, err => {

      })
      var objectTodelete = this.fieldArray.find(x => x.refArt == filedToDelete.refArt && x.quantite == filedToDelete.quantite);
      if (objectTodelete) {
        this.fieldArray.splice(this.fieldArray.indexOf(objectTodelete), 1);
      }

    }
    this.fieldArrayy.splice(index, 1);
  }
  displayTable() {
    Swal.fire({
      title: 'VOULEZ-VOUS CONFIRMER?',
      imageUrl: 'assets/images/add-catalogue.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      showCancelButton: true,
      width: 800,
      confirmButtonColor: '#008000',
      cancelButtonColor: '#FFA500',
      confirmButtonText: 'CONFIRMER',

      cancelButtonText: 'ANNULER',
    }).then((result) => {
      if (!result.dismiss) {
        var myArray = []
        if (this.fieldArray && this.fieldArray.length < 1 && this.fieldArrayy && this.fieldArrayy.length < 1) {
          return false;
        }
        this.loading = true;
        if (this.fieldArray && this.fieldArray.length > 0) {
          for (var j = 0; j < this.fieldArray.length; j++) {
            var myObj = myArray.find(article => article.refArt === this.fieldArray[j].refArt && article.etage === this.fieldArray[j].etage)
            if (!myObj) {
              myArray.push(this.fieldArray[j])
            }
            if (myObj) {
              myObj.quantiteNonEmballe = myObj.quantiteNonEmballe + this.fieldArray[j].quantiteNonEmballe
              myObj.quantiteEmballe = myObj.quantiteEmballe + this.fieldArray[j].quantiteEmballe
              myObj.quantite = myObj.quantite + this.fieldArray[j].quantite
            }
            if (j == this.fieldArray.length - 1) {
              this.inventaireService.saveAllData(myArray)
                .subscribe(resp => {
                  var hist = {
                    "idUser": this.utilisateur.id,
                    "date": new Date().toISOString().split('T')[0],
                    "type": "Inventaire",
                    "dataScan": myArray
                  }
                  this.historiqueService.getDataByDateAndUser(hist.date, hist.idUser,hist.type)
                    .then((dataHist: any[]) => {
                      if (dataHist && dataHist.length > 0) {
                        dataHist[0].dataScan.push(...hist.dataScan);
                        this.historiqueService.editIhistoriquerById(dataHist[0])
                          .subscribe(resp => {
                          }, err => {
                          })
                      } else {
                        this.historiqueService.addhistorique(hist)
                          .subscribe(resp => {

                          }, err => {
                          })
                      }
                    }, err => {
                    })
                }, err => {
                })
            }
          }

        }
        setTimeout(() => {
          /** spinner ends after 6 seconds **/
          this.loading = false;
          Swal.fire( {
              imageUrl: 'assets/images/add-catalogue.png',
              imageWidth: 100,
              imageHeight: 100,
              imageAlt: 'Custom image',
              width: 800,
              title: 'BIEN AJOUTÉ',
              timer: 1500,
              showConfirmButton: false
            }
          );
          this.router.navigateByUrl('/inventaire');
        }, 5000);

      }
    } );

  }
  triYearCommande(a, b) {
    if (a.datec > b.datec) return -1;
    else if (a.datec == b.datec) return 0;
    else return 1;
  }
}

