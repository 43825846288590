import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
const headers = new HttpHeaders({ Authorization: 'Basic ' + btoa('UserkrendelAdminnew:krl2021!!??**SoC24-415fr-a410-ttx9J8NbPecEL6k4BkAXvGE3t8f3nuEZmXa5RNJBAdrnh6XqB2') })
  .set('Content-Type', 'application/json')
  .set('Accept', 'application/json');
headers.append('Access-Control-Allow-Origin', '*');
import 'rxjs/add/operator/map';

@Injectable({
  providedIn: 'root'
})
export class DevisService {
  constructor(public http: HttpClient) {
  }


  getDevisById(id) {
    return this.http.get(environment.host + '/devis/'+id, {headers: headers})
  }

  getMaterielproductionBydevis(idDevis){
    return this.http.get(environment.host + '/materielproduction/idDevis/'+idDevis, {headers: headers})
  }

  addDevis(devis) {
    return this.http.post(environment.host + '/devis/addOne', JSON.stringify(devis), {headers: headers})
  }
  addMaterielproduction(objeTosave){
    return this.http.post(environment.host + '/materielproduction/addOne', JSON.stringify(objeTosave), {headers: headers})
  }

  dupliqueDevisByid(id) {
    return this.http.post(environment.host + '/devis/dupliqueDevisByid/' + id, {headers});
  }

  deleteDevisById(id) {
    return new Promise(resolve => {
      this.http.delete(environment.host + '/devis/' + id, { headers: headers }).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  editDevis(devis) {
    return this.http.put(environment.host + '/devis/' + devis.id, JSON.stringify(devis), { headers: headers })
  }
  editMaterielproduction(materielproduction) {
    return this.http.put(environment.host + '/materielproduction/' + materielproduction.id, JSON.stringify(materielproduction), { headers: headers })
  }
getDevisSupCmd(){
  return new Promise(resolve => {
    this.http.get(environment.host + '/devis/super/cmd',{headers: headers}).subscribe(data => {
      resolve(data);
    }, err => {
    });
  });
}
getArmoiresEnFabrication(){
  return new Promise(resolve => {
    this.http.get(environment.host + '/devis/query/armoires/enfabrication',{headers: headers}).subscribe(data => {
      resolve(data);
    }, err => {
    });
  });
}

  getArmoireByIdDossier(id) {
    return new Promise(resolve => {
      this.http.get(environment.host + '/armoire/getArmoireByIdDossier/' + id).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }

  getDetailAllArmoire() {
    return new Promise(resolve => {
      this.http.get(environment.host + '/armoire/getDetailAllArmoire/').subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }


  getDetailArmoireById(id) {
    return new Promise(resolve => {
      this.http.get(environment.host + '/armoire/getDetailArmoireById/' + id).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }


  editArmoire(id, armoire) {
    return this.http.put(environment.host + '/armoire/' + id + '/' + localStorage.getItem('id-user'), armoire, {headers});
  }


  getAllDevisByDossier(idDossier) {
    return new Promise(resolve => {
      this.http.get(environment.host + '/devis/getByIdDossier/' + idDossier, { headers: headers }).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }

}
