import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { User } from '../model/user';

const headers = new HttpHeaders({Authorization: 'Basic ' + btoa('UserkrendelAdminnew:krl2021!!??**SoC24-415fr-a410-ttx9J8NbPecEL6k4BkAXvGE3t8f3nuEZmXa5RNJBAdrnh6XqB2')})
  .set('Content-Type', 'application/json')
  .set('Accept', 'application/json');

@Injectable({
  providedIn: 'root'
})
export class AuthentificationService {

  static isLoggedIn = false;
  constructor(public http: HttpClient) {
  }

  authenticateAdmin(admin): Observable<any> {
    const authEndpoint = `${environment.host}/users/getByLoginAndPassword`;
    return this.http.post<any>(authEndpoint,JSON.stringify(admin), {  headers: headers  })
  }
  logout() {
    localStorage.clear();
  }
  verifytoken(token) {
    const authEndpoint = `${environment.host}/users/getByToken/${token}`;
    return this.http.get<User>(authEndpoint, { headers });
  }
  verifytokenPasswordRecovery(token) {
    const authEndpoint = `${environment.host}/users/getByPasswordToken/${token}`;
    return this.http.get<User>(authEndpoint, { headers });
  }
  getUserByEmail(mail) {
    const authEndpoint = `${environment.host}/users/findUserByMail/${mail}`;
    return this.http.get<User>(authEndpoint, { headers });
  }
  private handleError<T>(operation = 'operation', result?: T) {

  }
  private log(message: string) {
    //  console.log(message);
  }
}
