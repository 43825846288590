<app-navbar></app-navbar>
<br>
<div id="content-nav-page" class="row">
    <div class=" col-3 titre-menu">
      <a routerLink="/rh" class=" icon-f-left"></a>
      <b style="margin-right: 10%" class="title-haut">Nouveau Salarié</b>
      <img src="assets/images/charge-plus.png">
    </div>
    <div class="col-9 sous-menu">
      <b class="sous-menu-active1" routerLink="/rh">SALARIÈS</b>
    </div>
  </div>
  <div class="card p-3">
    <div class="col d-flex justify-content-start">
      <b class="obligatoir-text">(*) Ce champ est obligatoire</b>
    </div>
    <div class="card-header " style="border: 1px solid green;margin-bottom: 1%;">
      <b style="color:grey">INFORMATIONS PERSONNELLES</b>
    </div>
  
    <div *ngIf="showPart()==1">
      <form [formGroup]="form" role="form">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-10">
              <div class="title">
                <input class="font-weight-bold" placeholder="Nom*" formControlName="nom" name="nom">
                <control-messages [control]="form.controls.nom"></control-messages>
                <p *ngIf="form.controls['nom'].touched && form.controls['nom'].hasError('maxlength')" style="color:red">
                  25 caractères maximum
                </p>
              </div>
            </div>
            <br>
            <div class="col-lg-4 col-md-6 col-sm-10">
              <div class="title ">
                <input class="font-weight-bold" placeholder="Prénom*" formControlName="prenom" name="prenom">
                <control-messages [control]="form.controls.prenom"></control-messages>
                <p *ngIf="form.controls['prenom'].touched && form.controls['prenom'].hasError('maxlength')" style="color:red">
                  25 caractères maximum
                </p>
              </div>
            </div>
            <br>
            <div class="col-lg-4 col-md-6 col-sm-10">
              <div class="title" style="margin-top:2px">
                <international-phone-number class="mystyle" formControlName="telPro" placeholder="N° fixe" [maxlength]="13" [defaultCountry]="'tn'" [locale]="'fr'" [required]="true" name="telPro"></international-phone-number>
                <a id="placeholderTel">N° fixe*</a>
                <control-messages [control]="form.controls.telPro"></control-messages>
              </div>
            </div>
            <br>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-10">
              <div class="title" style="margin-top:23px">
                <international-phone-number class="mystyle" formControlName="telPerso" placeholder="N° mobile" [maxlength]="13" [defaultCountry]="'tn'" [locale]="'fr'" [required]="true" name="telPerso"></international-phone-number>
                <a id="placeholderTel">N° mobile*</a>
                <control-messages [control]="form.controls.telPerso"></control-messages>
              </div>
            </div>
            <br>
            <div class="col-lg-4 col-md-6 col-sm-10">
              <div class="title" style="margin-top:-11px">
                <input type="date" class="font-weight-bold" placeholder="Date de naissance" [max]="getDateNaissance()" formControlName="dateNaissance" name="dateNaissance">
                <control-messages [control]="form.controls.dateNaissance"></control-messages>
              </div>
            </div>
            <br>
            <div class="col-lg-4 col-md-6 col-sm-10">
              <div class="title">
                <select class="form-control input-sm select-options" id="sexe" formControlName="sexe" name="sexe">
                  <option>Masculin</option>
                  <option>Féminin</option>
                </select>
                <control-messages [control]="form.controls.sexe"></control-messages>
              </div>
            </div>
            <br>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-10">
              <div class="title" style="margin-top:-46px">
                <select class="form-control input-sm select-options" id="nationalite" formControlName="nationalite" name="nationalite" placeholder="Nationalité">
                  <option *ngFor="let py of pays" [attr.value]="py.name" [attr.selected]="py.name == 'Tunisie' ? true : null">{{py.name}}</option>
                </select>
                <control-messages [control]="form.controls.nationalite"></control-messages>
              </div>
            </div>
            <br>
            <div class="col-lg-4 col-md-6 col-sm-10">
              <div class="title" style="margin-top:-46px">
                <select class="form-control input-sm select-options" id="typeIdentite" formControlName="typeIdentite" name="typeIdentite" placeholder="Type d'identité*">
                  <option>CIN</option>
                  <option>Passeport</option>
                </select>
                <control-messages [control]="form.controls.typeIdentite"></control-messages>
              </div>
            </div>
            <br>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-10">
              <div class="title">
                <input class="font-weight-bold" id="numIdentite" formControlName="numIdentite" name="numIdentite" placeholder="Numéro de pièce d'identité*">
                <control-messages [control]="form.controls.numIdentite"></control-messages>
              </div>
            </div>
            <br>
            <div class="col-lg-4 col-md-6 col-sm-10">
              <div class="title">
                <input class="font-weight-bold" id="mailPro" formControlName="mailPro" name="mailPro" placeholder="Email professionnel*">
                <control-messages [control]="form.controls.mailPro"></control-messages>
                <p style="color:red" *ngIf="showErrorEmail">Cet Email est dèjà utilisé !</p>
  
              </div>
            </div>
            <br>
            <div class="col-lg-4 col-md-6 col-sm-10">
              <div class="title">
                <input class="font-weight-bold" id="mailPerso" formControlName="mailPerso" name="mailPerso" placeholder="Email personnel">
                <control-messages [control]="form.controls.mailPerso"></control-messages>
              </div>
            </div>
            <br>
          </div>
          <div class="" formGroupName="adresse">
            <div class="row">
              <div class="col-lg-4 col-md-6 col-sm-10">
                <div class="title">
                  <select class="form-control input-sm select-options" id="pays" formControlName="pays" name="pays" placeholder="Pays*">
                    <option *ngFor="let py of pays" [attr.value]="py.name" [attr.selected]="py.name == 'Tunisie' ? true : null">{{py.name}}</option>
                  </select>
                  <control-messages [control]="form.controls.adresse.controls.pays"></control-messages>
                </div>
              </div>
              <br>
              <div class="col-lg-4 col-md-6 col-sm-10">
                <div class="title" style="margin-top:-12px">
                  <input class="font-weight-bold" type="number" min="0" formControlName="codePostal" name="codePostal" placeholder="Code postal">
                  <control-messages [control]="form.controls.adresse.controls.codePostal"></control-messages>
                </div>
              </div>
              <br>
              <div class="col-lg-4 col-md-6 col-sm-10">
                <div class="title" style="margin-top:-12px">
                  <input class="font-weight-bold" formControlName="rue" name="rue" placeholder="Rue">
                  <control-messages [control]="form.controls.adresse.controls.rue"></control-messages>
                </div>
              </div>
              <br>
            </div>
            <div class="row">
              <div class="col-lg-4 col-md-6 col-sm-10">
                <div class="title" style="margin-top:-30px">
                  <input class="font-weight-bold" type="number" min="0" formControlName="n" name="n" placeholder="Numéro">
                  <control-messages [control]="form.controls.adresse.controls.n"></control-messages>
                </div>
              </div>
              <br>
              <div class="col-lg-4 col-md-6 col-sm-10">
                <div class="title" style="margin-top:-49px">
                  <select class="form-control input-sm select-options" id="etat" formControlName="ville" name="ville" placeholder="Ville">
                    <option *ngFor="let ville of vlls">{{ville.ville}}</option>
                  </select>
                  <control-messages [control]="form.controls.adresse.controls.ville"></control-messages>
                </div>
              </div>
              <br>
            </div>
          </div>
          <div class="row" formGroupName="posteObject">
            <div class="col-lg-4 col-md-6 col-sm-10">
              <div class="title" style="margin-top:-12px">
                <input class="font-weight-bold" formControlName="titre" name="titre" placeholder="Titre">
              </div>
            </div>
            <br>
            <div class="col-lg-4 col-md-6 col-sm-10">
              <div class="title" style="margin-top:-12px">
                <input class="font-weight-bold" formControlName="departement" name="departement" placeholder="Département">
              </div>
            </div>
            <br>
            <div class="col-lg-4 col-md-6 col-sm-10">
              <div class="title" style="margin-top:-12px">
                <input class="font-weight-bold" formControlName="bureau" name="bureau" placeholder="Bureau">
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-10">
              <div class="title" style="margin-top:-12px">
                <input class="font-weight-bold" type="date" formControlName="dated"  [max]="getdateFinPoste()" name="dated" placeholder="Date de début">
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-10">
              <div class="title" style="margin-top:-12px">
                <input class="font-weight-bold" type="date" formControlName="datef" name="datef" [max]="getBigDate()" [(ngModel)]="dateFinPoste" placeholder="Date de fin">
              </div>
            </div>
            <br>
          </div>
        </div>
        <div class=" col d-flex justify-content-center" style="padding-bottom: 1%">
          <div class="col-auto nbr-dossier ">
            <table>
              <tr>
                <td style="padding-right: 30px;">
                  <button class="btn-valider" style="width: 200px;height: 50px;" [disabled]="!form.valid" (click)="modifPart2()">Suivant <img src="assets/images/btn-valider.png"></button>
                </td>
  
              </tr>
  
            </table>
          </div>
  
        </div>
      </form>
    </div>
    <div class="card-header " style="border: 1px solid green;margin-bottom: 1%;">
      <b style="color:grey">INFORMATIONS COMPLÉMENTAIRES</b>
    </div>
    <div *ngIf="showPart()==2">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-10">
            <div class="title ">
              <select class="form-control input-sm select-options" (change)="showOthersSituation()" [(ngModel)]="situationFamiliale" name="situationFamiliale" placeholder="Situation familiale*">
                <option>Célibataire</option>
                <option>Marié(e)</option>
                <option>Divorcé(e)</option>
                <option>Veuf(ve)</option>
              </select>
            </div>
            <br />
          </div>
          <br>
        </div>
        <div class="row" *ngIf="addConjointAndEnfants">
          <form [formGroup]="formConjoint" style="width:100%" role="form">
            <div class="col-lg-4 col-md-6 col-sm-10">
              <div class="title">
                <input class="font-weight-bold" formControlName="nom" name="nom" placeholder="Nom du conjoint" style="color: #1b1e21">
                <control-messages [control]="formConjoint.controls.nom"></control-messages>
              </div>
              <br />
              <div class="title">
                <input class="font-weight-bold" formControlName="prenom" name="prenom" placeholder="Prénom du conjoint" style="color: #1b1e21">
                <control-messages [control]="formConjoint.controls.prenom"></control-messages>
              </div>
              <br />
              <div class="title">
                <input class="font-weight-bold" formControlName="mail" name="mail" placeholder="Email du conjoint" style="color: #1b1e21">
                <control-messages [control]="formConjoint.controls.mail"></control-messages>
              </div>
              <br>
            </div>
            <br>
            <div class="col-lg-4 col-md-6 col-sm-10">
              <div class="title" style="margin-top:3px">
                <international-phone-number class="mystyle" formControlName="tel" placeholder="N° mobile" [maxlength]="13" [defaultCountry]="'tn'" [locale]="'fr'" [required]="true" name="tel"></international-phone-number>
                <a id="placeholderTel">N° mobile*</a>
                <control-messages [control]="formConjoint.controls.tel"></control-messages>
              </div>
              <div class="title" style="margin-top:15px">
                <input class="font-weight-bold" placeholder="Nationalité du conjoint" style="color: #1b1e21">
              </div>
              <br />
              <div class="title">
                <input class="font-weight-bold" placeholder="Organisme de santé du conjoint" style="color: #1b1e21">
              </div>
            </div>
            <br>
            <div class="col-lg-4 col-md-6 col-sm-10">
  
            </div>
            <br>
          </form>
        </div>
      </div>
      <div class=" col d-flex justify-content-center" style="padding-bottom: 1%">
        <div class="col-auto nbr-dossier ">
          <table>
            <tr>
              <td style="padding-right: 30px;">
                <button class="btn-annule" style="width: 200px;height: 50px;" (click)="modifPart1()">Précédent <img src="assets/images/btn-annule.png"> </button>
  
              </td>
              <td style="padding-right: 30px;">
                <button class="btn-valider" style="width: 200px;height: 50px;" (click)="modifPart4()">Suivant <img src="assets/images/btn-valider.png"></button>
  
              </td>
  
  
            </tr>
  
          </table>
        </div>
  
      </div>
    </div>
    <div class="card-header " style="border: 1px solid green;margin-bottom: 1%;">
      <b style="color:grey">PARCOURS ACADÉMIQUES</b>
    </div>
    <div *ngIf="showPart()==4" style="padding-top: 2%">
      <div class="card-body">
        <div>
          <div class="col-12" style="padding-bottom: 5%">
            <div>
              <div>
                <div class="title title-parcours">
                  <h4>Les parcours académiques</h4>
  
                </div>
                <div class="row" style="margin-bottom: 0">
                  <div class="col-12" style="padding-bottom: 2%;margin-top: 3%">
                    <div>
                      <div>
                        <div style="margin-top: 30px">
                          <div class="row ">
                            <table class="table-ligne table-responsive">
                              <tr>
                                <th class="text-center">NOM D'ÉTABLISSEMENT</th>
                                <th class="text-center">NOM DU DIPLOME </th>
                                <th class="text-center">TYPE DU DIPLOME</th>
                                <th class="text-center">DATE D'OBTENTION </th>
                                <th class="text-center">DESCRIPTION </th>
                                <th class="text-center ">ACTIONS</th>
  
  
                              </tr>
                              <tr class="text-center" *ngFor="let parcour of parcours| paginate: { id:'second',itemsPerPage: 8, currentPage: secondp }">
                                <td>{{parcour.etablissement}}</td>
                                <td>{{parcour.diplomen}}</td>
                                <td>{{parcour.diplomet}}</td>
                                <td>{{parcour.date | date : 'dd/MM/yyyy' }}</td>
                                <td>{{parcour.desc}}</td>
                                <td>
                                  <a (click)="removeParcours(parcour)" title="Supprimer"><img src="assets/images/supp-modal.png" class="delete-img" alt=""></a>
                                </td>
                              </tr>
  
                              <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td><a href="#" title="Ajouter" data-toggle="modal" data-target="#myModal"><img src="assets/images/add-cmd.png"></a>
                                </td>
  
                              </tr>
  
                            </table>
                            <div class="row">
                              <div class="col col-xs-6">
                                <pagination-controls previousLabel="Précédent" nextLabel="Suivant" class=“custom-pagination” (pageChange)="secondp = $event" id="second"></pagination-controls>
                              </div>
                            </div>
                          </div>
  
                        </div>
  
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="col-12" style="padding-bottom: 5%">
            <div>
              <div class="title">
                <h4>Les expériences professionnelles</h4>
  
              </div>
              <div class="row" style="margin-bottom: 0">
                <div class="col-12" style="padding-bottom: 2%;margin-top: 3%">
                  <div>
                    <div>
                      <div style="margin-top: 30px">
                        <div class="row ">
                          <table class="table-ligne table-responsive">
                            <tr>
                              <th class="text-center">NOM DE L'ENTREPRISE</th>
                              <th class="text-center">NOM DU POSTE </th>
                              <th class="text-center">DATE DE DEBUT</th>
                              <th class="text-center">DATE DE FIN </th>
                              <th class="text-center">DESCRIPTION </th>
                              <th class="text-center ">ACTIONS</th>
                            </tr>
                            <tr class="text-center" *ngFor="let experience of experiences| paginate: { id:'third',itemsPerPage: 8, currentPage: thirdp }">
                              <td>{{experience.entreprise}}</td>
                              <td>{{experience.poste}}</td>
                              <td>{{experience.dated | date : 'dd/MM/yyyy' }}</td>
                              <td>{{experience.datef | date : 'dd/MM/yyyy' }}</td>
                              <td>{{experience.desc}}</td>
                              <td>
                                <a (click)="removeExperience(experience)" title="Supprimer"><img src="assets/images/supp-modal.png" class="delete-img"></a>
                              </td>
                            </tr>
  
                            <tr>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td><a href="#" title="Ajouter" data-toggle="modal" data-target="#myModal2"><img src="assets/images/add-cmd.png"></a>
                              </td>
                            </tr>
                          </table>
                          <div class="row">
                            <div class="col col-xs-6">
                              <pagination-controls previousLabel="Précédent" nextLabel="Suivant" class=“custom-pagination” (pageChange)="thirdp = $event" id="third"></pagination-controls>
                            </div>
                          </div>
  
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="col-12">
  
  
            <div>
              <div>
                <div class="title">
                  <h4>Les langues</h4>
  
                </div>
                <div class="row" style="margin-bottom: 0">
                  <div class="col-12" style="padding-bottom: 2%;margin-top: 3%">
                    <div>
                      <div>
                        <div style="margin-top: 30px">
                          <div class="row ">
                            <table class="table-ligne table-responsive">
                              <tr>
                                <th class="text-center">LANGUE</th>
                                <th class="text-center ">ACTIONS</th>
                              </tr>
                              <tr class="text-center" *ngFor="let langue of langues| paginate: { itemsPerPage: 8, currentPage: p }">
                                <td style="text-transform: capitalize;">{{langue}}</td>
                                <td>
                                  <a (click)="removeLangue(langue)" title="Supprimer"><img src="assets/images/supp-modal.png" class="delete-img"></a>
                                </td>
                              </tr>
  
                              <tr>
                                <td></td>
                                <td><a href="#" title="Ajouter"  (click)="resetErrorLangue()" data-toggle="modal" data-target="#myModal3"><img src="assets/images/add-cmd.png"></a>
                                </td>
  
                              </tr>
  
                            </table>
                          </div>
  
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
            </div>
  
            <div>
  
              <div style="align-items: center; padding-top: 50px;">
                <div class="title">
                  <h4>Les compétences</h4>
                </div>
                <div class="row" style="margin-bottom: 0">
                  <div class="col-12" style="padding-bottom: 2%;margin-top: 3%">
                    <div>
                      <div>
                        <div style="margin-top: 30px">
                          <div class="row ">
                            <table class="table-ligne table-responsive">
                              <tr>
                                <th class="text-center">COMPETENCE</th>
                                <th class="text-center ">ACTIONS</th>
                              </tr>
                              <tr class="text-center" *ngFor="let competence of competences| paginate: { itemsPerPage: 8, currentPage: p }">
                                <td>{{competence}}</td>
                                <td>
                                  <a (click)="removeCompetence(competence)" title="Supprimer"><img src="assets/images/supp-modal.png" class="delete-img" alt=""></a>
                                </td>
                              </tr>
  
                              <tr>
                                <td></td>
                                <td><a href="#" title="Ajouter" (click)="resetErrorCompetence()" data-toggle="modal" data-target="#myModal4"><img src="assets/images/add-cmd.png"></a>
                                </td>
  
                              </tr>
  
                            </table>
                          </div>
  
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
            </div>
          </div>
        </div>
  
      </div>
      <div class=" col d-flex justify-content-center" style="padding-bottom: 1%">
        <div class="col-auto nbr-dossier ">
          <table>
            <tr>
              <td>
                <button class="btn-annule" style="width: 200px;height: 50px;" (click)="modifPart2()">Précédent <img src="assets/images/btn-annule.png"> </button>
  
              </td>
              <td style="padding-right: 30px; ">
                <button class="btn-valider" style="width: 200px;height: 50px;" (click)="registerSalarie()">Enregistrer <img src="assets/images/btn-valider.png"></button>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
  
  
  
  
  
  
  
  <!-- --------------------------modal scan ----------------------- -->
  
  
  <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModal" aria-hidden="true" style="overflow-y: hidden;">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 1000px">
      <div class="modal-content modal-content-scan" style="width: 200%; top: -170px">
        <div class="modal-header d-flex justify-content-center">
          <h3 class="titre-modal-statut">
            NOUVEAU PARCOURS ACADÉMIQUE
          </h3>
        </div>
        <div class="modal-body">
          <div class="col d-flex justify-content-start">
            <b class="obligatoir-text">(*) Ce champ est obligatoire</b>
          </div>
          <form [formGroup]="formParcours">
            <div class="row" style="margin-top: 25px;">
  
              <div class="col-lg-6 col-md-6 col-sm-10">
                <div class="title">
                  <input class="font-weight-bold" formControlName="etablissement" name="etablissement" placeholder="Nom d'établissement*">
                  <control-messages [control]="formParcours.controls.etablissement"></control-messages>
                </div>
  
                <br />
                <div class="title">
                  <input class="font-weight-bold" formControlName="diplomen" name="diplomen" placeholder="Nom du diplôme*">
                  <control-messages [control]="formParcours.controls.diplomen"></control-messages>
                </div>
                <br />
                <div class="title" style="margin-top: -45px;">
                  <select class="form-control input-sm select-options" id="pays" formControlName="diplomet" name="diplomet" placeholder="Type du diplôme*">
                    <option>Ouvrier</option>
                    <option> Technicien</option>
                    <option>Technicien Supérieur</option>
                    <option>Master</option>
                    <option>Ingénieur</option>
                    <option>Docteur</option>
                  </select>
                  <control-messages [control]="formParcours.controls.diplomet"></control-messages>
                </div>
              </div>
              <br>
              <div class="col-lg-6 col-md-6 col-sm-10" style="margin-top: -2px">
                <div class="title">
                  <input class="font-weight-bold" type="date" formControlName="date" name="date" placeholder="Date d'obtention*" [max]="getBigDate()">
                  <control-messages [control]="formParcours.controls.date"></control-messages>
                </div>
                <br />
                <div class="title">
                  <input class="font-weight-bold" formControlName="desc" name="desc" placeholder="Description">
                </div>
              </div>
              <br>
            </div>
          </form>
          <div class="modal-footer d-flex justify-content-center">
            <div class=" col d-flex justify-content-center" style="padding-bottom: 1%; margin-bottom: 20px">
              <div class="col-auto nbr-dossier ">
                <table>
                  <tr>
                    <td style="padding-right: 30px;">
                      <button class="btn-valider" style="width: 200px;height: 50px;" data-dismiss="modal" (click)="addnewParcours()" [disabled]="!formParcours.valid">Ajouter <img src="assets/images/save.png"></button>
                    </td>
                    <td style="padding-right: 30px;">
                      <button class="btn-annule" style="width: 200px;height: 50px;" data-dismiss="modal">Annuler <img src="assets/images/btn-annule.png"> </button>
  
                    </td>
  
                  </tr>
  
                </table>
              </div>
  
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  
  <!-- --------------------------modal scan ----------------------- -->
  <div class="modal fade" id="myModal2" tabindex="-1" role="dialog" aria-labelledby="myModal2" aria-hidden="true" style="overflow-y: hidden;">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 1000px">
      <div class="modal-content modal-content-scan" style="width: 200%; top: -170px">
        <div class="modal-header d-flex justify-content-center">
          <h3 class="titre-modal-statut">
            NOUVELLE EXPÉRIENCE PROFESSIONNELLE
          </h3>
        </div>
        <div class="modal-body">
          <div class="col d-flex justify-content-start">
            <b class="obligatoir-text">(*) Ce champ est obligatoire</b>
          </div>
          <form [formGroup]="formExperience">
            <div class="row" style="margin-top: 25px;">
              <div class="col-lg-6 col-md-6 col-sm-10">
                <div class="title">
                  <input class="font-weight-bold" formControlName="entreprise" name="entreprise" placeholder="Nom de l'entreprise*">
                  <control-messages [control]="formExperience.controls.entreprise"></control-messages>
                </div>
  
                <br />
                <div class="title">
                  <input class="font-weight-bold" formControlName="poste" name="poste" placeholder="Nom du poste*">
                  <control-messages [control]="formExperience.controls.poste"></control-messages>
                </div>
  
                <br />
  
                <div class="title">
                  <input class="font-weight-bold" formControlName="dated" name="dated" type="date" placeholder="Date de début*" [max]="getBigDate()">
                  <control-messages [control]="formExperience.controls.dated"></control-messages>
                  <p style="color:red" *ngIf="errorDates">Vérifiez les dates !</p>
  
                </div>
              </div>
              <br>
              <div class="col-lg-6 col-md-6 col-sm-10" style="margin-top: -2px">
                <div class="title">
                  <input class="font-weight-bold" formControlName="datef" name="datef" type="date" placeholder="Date de fin*" [max]="getBigDate()">
                  <control-messages [control]="formExperience.controls.datef"></control-messages>
                </div>
                <br />
                <div class="title">
                  <input class="font-weight-bold" formControlName="desc" name="desc" placeholder="Description">
                </div>
              </div>
              <br>
            </div>
          </form>
          <div class="modal-footer d-flex justify-content-center">
            <div class=" col d-flex justify-content-center" style="padding-bottom: 1%; margin-bottom: 20px">
              <div class="col-auto nbr-dossier ">
                <table>
                  <tr>
                    <td style="padding-right: 30px;">
                      <button class="btn-valider" style="width: 200px;height: 50px;" [disabled]="!formExperience.valid"  (click)="addnewExperience()">Ajouter <img src="assets/images/save.png"></button>
                    </td>
                    <td style="padding-right: 30px;">
                      <button class="btn-annule" style="width: 200px;height: 50px;" data-dismiss="modal">Annuler <img src="assets/images/btn-annule.png"> </button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- --------------------------modal scan ----------------------- -->
  
  
  <div class="modal fade" id="myModal3" tabindex="-1" role="dialog" aria-labelledby="myModal3" aria-hidden="true" style="overflow-y: hidden;">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content modal-content-scan" style="width: 200%; top: -170px">
        <div class="modal-header d-flex justify-content-center">
          <h3 class="titre-modal-statut">
            NOUVELLE LANGUE
          </h3>
        </div>
        <div class="modal-body">
          <div class="col d-flex justify-content-start">
            <b class="obligatoir-text">(*) Ce champ est obligatoire</b>
          </div>
          <div class="row" style="margin-top: 25px;">
            <p *ngIf="errorLangue" style="color:red;margin-left: 60px;">{{errorLangue}}</p>
            <div class="col-lg-8 col-md-8 col-sm-8" style="margin-right: 30%">
              <select class="selectpicker select-statut-detail " [(ngModel)]="langue" style="text-transform: capitalize;width: 100%;margin: 57px 25%;" name="langue" (change)="addLangues($event.target.value)">
                <option *ngFor="let language of languages" style="text-transform: capitalize;">{{language.French}}</option>
              </select>
            </div>
            <div class="col-lg-8 col-md-8 col-sm-8" style="margin-right: 30%;margin-bottom: 15px;">
              <li *ngFor="let onlangue of languesChosed" style="text-transform: capitalize;">{{onlangue}}</li>
            </div>
            <br>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <div class=" col d-flex justify-content-center" style="padding-bottom: 1%; margin-bottom: 20px">
              <div class="col-auto nbr-dossier ">
                <table>
                  <tr>
                    <td style="padding-right: 30px;">
                      <button class="btn-valider" style="width: 200px;height: 50px;" (click)="saveChoosedLangues()" data-dismiss="modal">Ajouter <img src="assets/images/save.png"></button>
                    </td>
                    <td style="padding-right: 30px;">
                      <button class="btn-annule" style="width: 200px;height: 50px;" data-dismiss="modal">Annuler <img src="assets/images/btn-annule.png"> </button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- --------------------------modal scan COMPÉTENCE ----------------------- -->
  <div class="modal fade" id="myModal4" tabindex="-1" role="dialog" aria-labelledby="myModal4" aria-hidden="true" style="overflow-y: hidden;">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content modal-content-scan" style="width: 200%; top: -170px">
        <div class="modal-header d-flex justify-content-center">
          <h3 class="titre-modal-statut">
            NOUVELLE COMPÉTENCE
          </h3>
        </div>
        <div class="modal-body">
          <div class="col d-flex justify-content-start">
            <b class="obligatoir-text">(*) Ce champ est obligatoire</b>
          </div>
          <div class="row" style="margin-top: 25px;">
            <p *ngIf="errorCompetence" style="color:red;margin-left: 60px;">{{errorCompetence}}</p>
            <div class="col-lg-8 col-md-8 col-sm-8" style="margin-right: 30%">
              <select class="selectpicker select-statut-detail " [(ngModel)]="competence" name="competence" (change)="addCompetences($event.target.value)">
                <option>Adaptation au changement</option>
                <option>Analyse</option>
                <option>Collaboration</option>
                <option>Communication</option>
                <option>Confiance en soi</option>
                <option>Curiosité intellectuelle</option>
                <option>Ecoute</option>
                <option>Esprit de synthèse</option>
                <option>Gestion du stress</option>
                <option> Influence</option>
                <option>Jugement</option>
                <option>Mobilisation</option>
                <option>Négociation</option>
                <option>Organisation du travail</option>
                <option>Rigueur</option>
                <option>Travail d'équipe</option>
              </select>
            </div>
            <div class="col-lg-8 col-md-8 col-sm-8" style="margin-right: 30%;margin-bottom: 15px;">
              <li *ngFor="let oncompetence of competencesChosed" style="text-transform: capitalize;">{{oncompetence}}</li>
            </div>
            <br>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <div class=" col d-flex justify-content-center" style="padding-bottom: 1%; margin-bottom: 20px">
              <div class="col-auto nbr-dossier ">
                <table>
                  <tr>
                    <td style="padding-right: 30px;">
                      <button class="btn-valider" style="width: 200px;height: 50px;" (click)="saveChoosedCompetence()" data-dismiss="modal">Ajouter <img src="assets/images/save.png"></button>
                    </td>
                    <td style="padding-right: 30px;">
                      <button class="btn-annule" style="width: 200px;height: 50px;" data-dismiss="modal">Annuler <img src="assets/images/btn-annule.png"> </button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  