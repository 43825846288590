import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DecimalFormattRoutingModule } from './decimal-formatt-routing.module';
import { DecimalFormattComponent } from './decimal-formatt/decimal-formatt.component';


@NgModule({
  declarations: [DecimalFormattComponent],
  imports: [
    CommonModule,
    DecimalFormattRoutingModule
  ],
  exports: [
    DecimalFormattComponent
]
})
export class DecimalFormattModule { }
