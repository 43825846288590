<app-navbar></app-navbar>
<br>
<div id="content-nav-page" class="row">
    <div class=" col-3 titre-menu">
      <a routerLink="/catalogue" class=" icon-f-left"></a>
      <b style="margin-right: 10%">Nouveau Catalogue</b>
      <img src="assets/images/add-catalogue.png" >
    </div>
    <div class="col-9 sous-menu" *ngIf="utilisateur">
      <b class="sous-menu-active1" routerLink="/catalogue">CATALOGUE</b>
    </div>
  </div>
  <br><br>
  <ng-template #customLoadingTemplate></ng-template>
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading>
  
  <div class="card p-3">
    <div class="col d-flex justify-content-start">
      <b class="obligatoir-text">(*) Ce champ est obligatoire</b>
      <a style="color: #343434;font-size: 15px;cursor: pointer;text-decoration: underline;position: absolute;right: 25px;top: 25px;" href="assets/templates/catalogue.xlsx"> Télécharger le modèle</a>
    </div>
    <div class="card-header " style="border: 1px solid green;margin-bottom: 1%;" >
      <b style="color:grey">INFORMATIONS</b>
    </div>
    <div *ngIf="showPart()==1">
      <form [formGroup]="form"  role="form">
  
        <div class="card-body">
        <div class="row">
          <div class="col-4" >
            <div class="title" style="margin-top: -46px;margin-bottom: -61px;">
              <select class="form-control input-sm select-options" id="type" formControlName="fournisseur"  name="fournisseur" (change)="selectFournisseur()">
                <option value="" disabled selected>Fournisseur*</option>
              <option *ngFor="let fournisseur of fournisseurs" [ngValue]="fournisseur">{{fournisseur.denomination}} </option>
            </select>
            <control-messages [control]="form.controls.fournisseur"></control-messages>
            </div>
            <br/>
          </div>
          <div class="col-4" style="margin-top:-30px">
            <div class="title" *ngIf="selectedFounisseur">
              <input class="font-weight-bold input-show"   type="date" [(ngModel)]="dateDebut" formControlName="datec" id="datec" name="datec" placeholder="Date de début*" [max]="getBigDate()" >
              <control-messages [control]="form.controls.datec"></control-messages>
            </div>
            <br/>
          </div>
          <div class="col-4">
            <div class="title" *ngIf="selectedFounisseur">
              <input class="font-weight-bold input-show" style="margin-top: 33px;" type="date"  [min]="getDateDebut()" [max]="getBigDate()" formControlName="dateFin" id="dateFin" name="dateFin" placeholder="Date de fin*" >
              <control-messages [control]="form.controls.dateFin"></control-messages>
            <p *ngIf="errorDate" style="color:red">Vérifie les dates !</p>
  
            </div>
            <br/>
          </div>
        </div>
        <div class="row">
          <div class="col-4" >
            <div class="title" style="margin-top: 35px;margin-bottom: -61px;" *ngIf="selectedFounisseur">
              <input class="input-btn-file " (change)="onFileChange($event)" formControlName="file" id="file" accept=".xlsx, .xls, .csv" [(ngModel)]="fileToChoose" type="file" id="file" style="position: relative;top: -20px;">
              <control-messages [control]="form.controls.file"></control-messages>
            </div>
            <br/>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-12">
            <p *ngIf="addedLength > 0" style="font-size: 15px;font-weight: bold;">Le nombre des lignes du fichier ajouté est : {{addedLength | number}} lignes <br></p> <br>
          </div>
          <div class="col-12">
            <p *ngIf="tarifGlobal > 0" style="font-size: 15px;font-weight: bold;">Le tarif global du fichier ajouté est : {{tarifGlobal | number: '1.2-2'| replace:',':' ' | replace:'.':',' | replace:',00':'' }} € <br></p><br>
          </div>
          <div class="col-12">
            <p *ngIf="errorObjet" style="font-size: 15px;font-weight: bold;color:red;">{{errorObjet}}</p><br>
          </div>
        </div>
  
      </div>
      <div class=" col d-flex justify-content-center" style="padding-bottom: 1%">
        <div class="col-auto nbr-dossier " >
          <table>
            <tr>
              <td style="padding-right: 30px;">
                <button class="btn-annule" style="width: 200px;height: 50px;"  [routerLink]="['/catalogue']">Annuler <img src="assets/images/btn-annule.png"> </button>
              </td>
              <td style="padding-right: 30px;">
                <button class="btn-valider" style="width: 200px;height: 50px;" (click)="saveData()" [disabled]="!form.valid" >Ajouter <img src="assets/images/save.png"></button>
  
              </td>
            </tr>
  
          </table>
        </div>
  
      </div>
  
      </form>
    </div>
  
  </div>
  
