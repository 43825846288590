import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
const headers = new HttpHeaders({Authorization: 'Basic ' + btoa('UserkrendelAdminnew:krl2021!!??**SoC24-415fr-a410-ttx9J8NbPecEL6k4BkAXvGE3t8f3nuEZmXa5RNJBAdrnh6XqB2')})
.set('Content-Type', 'application/json')
.set('Accept', 'application/json');
headers.append('Access-Control-Allow-Origin', '*');
import { Http, Headers } from '@angular/http';
import 'rxjs/add/operator/map';


@Injectable({
  providedIn: 'root'
})
export class CatalogueService {

  constructor(private http: HttpClient) {
  }
  saveAllData(client) {
    return this.http.post(environment.host + '/articles/addall', JSON.stringify(client), {headers: headers})
  }
  saveData(client) {
    return this.http.post(environment.host + '/articles/newReference', JSON.stringify(client), {headers: headers})
  }
  getAllData(){
    return new Promise(resolve => {
      this.http.get(environment.host + '/articles', {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  editArticleById(article){
    return this.http.put(environment.host + '/articles/' + article.id, JSON.stringify(article), { headers: headers })
  }
  getAllDataByYearByFournisseur(id,year){
    return new Promise(resolve => {
      this.http.get(environment.host + '/articles/getByYearFournisseur/'+id+'/'+year, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
getAllDataByFirstSemesterAndFournisseur(yearDateInventaire,id){
  return new Promise(resolve => {
    this.http.get(environment.host + '/articles/getByYearFournisseur/and/firstsemester/'+id+'/'+yearDateInventaire, {headers: headers}).subscribe(data => {
      resolve(data);
    }, err => {
    });
  });
}

getAllDataBySecondSemesterAndFournisseur(yearDateInventaire,id){
  return new Promise(resolve => {
    this.http.get(environment.host + '/articles/getByYearFournisseur/and/second/semester/'+id+'/'+yearDateInventaire, {headers: headers}).subscribe(data => {
      resolve(data);
    }, err => {
    });
  });
}

  getAllDataByRefOrEANAndFournisseur(ref,fournisseur){
    return new Promise(resolve => {
      this.http.get(environment.host + '/articles/refer/fournisseur/'+ref+'/'+fournisseur, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }

  getRefByRefAndFourDesc(ref,fournisseur){
    return new Promise(resolve => {
      this.http.get(environment.host + '/articles/refer/fournisseur/desc/'+fournisseur+'/ref/'+ref+'/art', {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }

  getRefByRefDesc(ref){
    return new Promise(resolve => {
      this.http.get(environment.host + '/articles/refer/desc/ref/'+ref+'/art', {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
 // get article by date  and fournisseur and refArt and yearofCommande
  getAllDataByRefOrEANAndFournisseurAndCommandeYear(ref,fournisseur,yearCommande){
    return new Promise(resolve => {
      this.http.get(environment.host + '/articles/refer/fournisseur/yearCommande/refArt/'+fournisseur+'/'+yearCommande+'/ref/'+ref+'/art', {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }

  // get article by date  and fournisseur and refArt and yearofCommande
   getAllDataByRefOrEANAndFournisseurAndCommandeLimitDate(ref,fournisseur,yearCommande){
     return new Promise(resolve => {
       this.http.get(environment.host + '/articles/refer/fournisseur/yearCommande/refArt/between/dates/'+fournisseur+'/'+yearCommande+'/ref/'+ref+'/art', {headers: headers}).subscribe(data => {
         resolve(data);
       }, err => {
       });
     });
   }

getAllDataByRefOrEANAndDate(ref,datec){
  return new Promise(resolve => {
    this.http.get(environment.host + '/articles/get/by/ref/ean/date/'+datec+'/cod/'+ref+'/art', {headers: headers}).subscribe(data => {
      resolve(data);
    }, err => {
    });
  });
}
matchingWithBibleCodArtRefArtDate(cod,ref,datec){
  return new Promise(resolve => {
    this.http.get(environment.host + '/articles/get/by/ref/ean/date/'+datec+'/cod/'+cod+'/art/'+ref+'/ref', {headers: headers}).subscribe(data => {
      resolve(data);
    }, err => {
    });
  });
}
  getAllDataByYearByFournisseurByPage(id,datec,dateFin,page,size){
    return new Promise(resolve => {
      this.http.get(environment.host + '/articles/getByYearFournisseur/byPage/'+id+'/'+datec+'/'+dateFin+'/'+page+'/'+size, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getAllDataByYearByFournisseurCount(id,datec,dateFin){
    return new Promise(resolve => {
      this.http.get(environment.host + '/articles/getByYearFournisseur/byPage/'+id+'/'+datec+'/'+dateFin+'/getCount', {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }

  getAllDataByYearByFournisseurByPageAndRecherche(id,datec,dateFin,event){
    return new Promise(resolve => {
      this.http.get(environment.host + '/articles/getByYearFournisseur/recherche/'+id+'/'+datec+'/'+dateFin+'/'+event, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }

  getAllDataByRef(){
    return new Promise(resolve => {
      this.http.get(environment.host + '/articles/getallArticlesByref', {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getAllDataByRefByYear(year){
    return new Promise(resolve => {
      this.http.get(environment.host + '/articles/getallArticlesByrefByYear/'+year, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  deleteAllData(){
    return new Promise(resolve => {
      this.http.delete(environment.host + '/articles/deleteall', {headers: headers} ).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  deleteArticleById(id){
    return new Promise(resolve => {
      this.http.delete(environment.host + '/articles/' + id,{headers: headers} ).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getArticleById(id){
    return this.http.get(environment.host + '/articles/'+id,{headers: headers})
  }
  getAllFournisseurofArticles(){
    return new Promise(resolve => {
      this.http.get(environment.host + '/articles/groupedBy/fournisseurId', {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getAllFournisseurofArticlesWithYear(id){
    return new Promise(resolve => {
      this.http.get(environment.host + '/articles/groupedBy/fournisseurId/and/year/'+id, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getArticleByRefByIdFourByDatec(datec,idFournisseur,refArt){
    return new Promise(resolve => {
      this.http.get(environment.host + '/articles/getByYearFournisseur/'+datec+'/'+idFournisseur+'/'+refArt, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getAllDataByRefOrEAN(ref){
    return new Promise(resolve => {
      this.http.get(environment.host + '/articles/get/by/ref/ean/ref/'+ref+'/art', {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }

  getAllDataByRefAndDate(ref,datechosed){
    return new Promise(resolve => {
      this.http.get(environment.host + '/articles/get/by/ref/date/'+datechosed+'/ref/'+ref+'/art', {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
}
