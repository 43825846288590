<app-navbar></app-navbar>
<br>
<div id="content-nav-page" class="row">
    <div class=" col-3 titre-menu">
      <a routerLink="/catalogue/fournisseurs/years/{{params.id}}" class=" icon-f-left" *ngIf="params"></a>
      <b style="margin-right: 10%" class="title-haut" *ngIf="listeArtciels && listeArtciels[0]">{{listeArtciels[0].denomination}} <img class="ml-2" src="assets/images/show-a.png"> </b>
      <div class="dropdown" style="left:45px" *ngIf="hasRoleAdd">
        <button class="btn icon-catalogue" type="button" id="dropdownMenuButton" data-toggle="dropdown">
        </button>
        <div class="dropdown-menu show-catalogue" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item drop-dup-option" routerLink="/ajoutCatalogue/manuel">Manuel</a>
          <a class="dropdown-item drop-dup-option-b" routerLink="/ajoutCatalogue">Fichier XLS</a>
        </div>
      </div>
    </div>
    <!-- NOMBRE DE DOSSIER PAR TYPES ET PAR ANNÉE -->
    <ng-template #customLoadingTemplate></ng-template>
    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading>
  
  
    <div class="col-9 sous-menu" *ngIf="utilisateur">
      <b class="sous-menu-active1" routerLink="/catalogue">CATALOGUE</b>
    </div>
  </div>
  
  
  <div id="content-page" class="containe-fluid">
  
    <div class="row  ">
      <div class="col-lg-4 col-md-10 col-sm-10  search">
        <input class="search-bar" type="text" placeholder="Rechercher.." [(ngModel)]="filterArticle" name="filterArticle" (keyup)="valuechange($event)">
      </div>
      <div class="col-lg-1 col-md-2 col-sm-2">
        <select class="select-input-nombre" name="numberData" [(ngModel)]="numberData">
          <option>5</option>
          <option selected>10</option>
          <option>25</option>
          <option>50</option>
        </select>
      </div>
  
      <div class=" col d-flex justify-content-end">
        <div class="col-auto nbr-dossier ">
          <table>
            <tr>
              <td>
                <b>Articles</b>
              </td>
            </tr>
            <tr>
              <td>
                <b class="sous-nbr-dossier d-flex justify-content-center" *ngIf="lenghtData">{{lenghtData}}</b>
              </td>
            </tr>
          </table>
        </div>
  
        <div class="col-auto btn-devis ">
          <br>
        </div>
      </div>
    </div>
    <div class="row" style="overflow-x:auto;">
      <table class="table table-striped table-hover">
        <th  class="left" >MARQUE</th>
        <th class="left" >REFCIAL</th>
        <th class="left"  >DÉSIGNATION</th>
        <th class="right"  >TARIF</th>
        <th class="left"  >DATE DE DEBUT</th>
        <th class="left"  >DATE DE FIN</th>
        <th class="left"  >FOURNISSEUR</th>
        <th class="left"  >STATUT</th>
  
        <th class="text-center "  >RÉGLAGES</th>
        <tr class="text-center" *ngFor="let article of listeArtciels | paginate: { itemsPerPage: numberData, currentPage: p }">
            <td class="left" [routerLink]="['/details/article', article.id]">{{article.mqe}}</td>
            <td class="left" [routerLink]="['/details/article', article.id]">{{article.refCiel}}</td>
            <td class="left" [routerLink]="['/details/article', article.id]">{{article.designation25}}</td>
            <td class="right" [routerLink]="['/details/article', article.id]">{{article.tarif | number: '1.2-2'| replace:',':' ' | replace:'.':',' }}</td>
            <td class="left" [routerLink]="['/details/article', article.id]">{{article.datec | date : 'dd/MM/yyyy'}} </td>
            <td class="left" [routerLink]="['/details/article', article.id]">{{article.dateFin | date : 'dd/MM/yyyy'}} </td>
            <td class="left" [routerLink]="['/details/article', article.id]">{{article.denomination}} </td>
            <td class="left" [routerLink]="['/details/article', article.id]">{{article.statut}} </td>
            <td >
              <div class="dropdown" >
              <button class="btn dropdown-toggle btn-options" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                OPTIONS
              </button>
              <div class="dropdown-menu reglageMenu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item option"  [routerLink]="['/details/article', article.id]" >
                  <div class="options-show-icon" ></div>
                  <div class="option-text"> Afficher</div>
                </a>
  
                <a class="dropdown-item option"  title="Modifier" *ngIf="hasRoleUpdate" [routerLink]="['/article/details', article.id]">
                  <div class="options-modif-icon"></div>
                  <div class="option-text" > Modifier</div>
                </a>
                <a class="dropdown-item" *ngIf="hasRoleDelete" title="Supprimer" (click)="removearticle(article)">
                  <div class="options-supp-icon"></div>
                  <div class="option-text"> Supprimer</div>
                </a>
              </div>
            </div>
            </td>
          </tr>
  
  
      </table>
    </div>
    <div class="row">
      <pagination-controls previousLabel="Précédent" nextLabel="Suivant" class=“custom-pagination” (pageChange)="p = $event" #api></pagination-controls>
    </div>
  </div>
  
  <br><br><br><br><br><br>
  <!-- end  liste des utilisateurs -->
  <div
        class="search-results"
        infiniteScroll
        [infiniteScrollDistance]="1"
        [infiniteScrollThrottle]="50"
        (scrolled)="onScroll()"
      ></div>
  