import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChargeAffaireRoutingModule } from './charge-affaire-routing.module';
import { ChargeAffaireComponent } from './charge-affaire/charge-affaire.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ControleMessagesModule } from '../shared/controle-messages/controle-messages.module';
import { ControleMessagesRoutingModule } from '../shared/controle-messages/controle-messages-routing.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NavbarModule } from '../shared/navbar/navbar.module';
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { SearchChargeAffairePipe } from 'src/app/search/searchChargeAffaire.pipe';
import { AjoutChargeAffaireComponent } from './ajout-charge-affaire/ajout-charge-affaire.component';
import { DetailsChargeAffairesComponent } from './details-charge-affaires/details-charge-affaires.component';
import { UpdateChargeAffaireComponent } from './update-charge-affaire/update-charge-affaire.component';
import { InternationalPhoneNumberModule } from 'ng-phone-number';
import { NgxLoadingModule } from "ngx-loading";
import { CaGuard } from 'src/app/guards/ca.guard';

@NgModule({
  declarations: [ChargeAffaireComponent,SearchChargeAffairePipe, AjoutChargeAffaireComponent, DetailsChargeAffairesComponent, UpdateChargeAffaireComponent],
  imports: [
    CommonModule,
    NavbarModule,
    FormsModule, ReactiveFormsModule,
    ControleMessagesModule,
    ControleMessagesRoutingModule,
    NgxPaginationModule,
    NgbModule,
    InfiniteScrollModule,
    ChargeAffaireRoutingModule,
    InternationalPhoneNumberModule,
    NgxLoadingModule
  ],
  providers:[CaGuard]
})
export class ChargeAffaireModule { }
