// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  
  host: '/krendel',

  socket: '/krendel/socket',

  imagePath :'/krendel/files/',

  filesPath :'/krendel/files/multiple/',

  recaptcha: {
    //local
    //siteKey: '6LdaIqooAAAAAM-wgxC1Bm8c6f3eZRxpTHfaLUt7',
    //prod 
    siteKey: '6LdR-XsjAAAAAO0kwnon1UbzEpU86ORF1FcxeZWs',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
