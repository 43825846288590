import { Component, OnInit } from '@angular/core';
import { RoleService } from 'src/app/services/role.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['../../utilisateur/utilisateur/utilisateur.component.css'],
})
export class RoleComponent implements OnInit {
  roles:any[]=[];
  public searched: any = '';
  p:any;
  numberData = 25;
  hasRoleUpdate = false;
  hasRoleDelete = false
  role:any;
  utilisateur:any;
  id:any;
  hasRoleAdd = false;
  constructor(private roleService:RoleService,private userService:UserService, private router:Router) { }

  ngOnInit(): void {
    this.id = localStorage.getItem('idUser')
    this.userService.getUserByID(this.id).subscribe(utilisateur => {
      this.utilisateur = utilisateur;
      if(!this.utilisateur || (this.utilisateur && !this.utilisateur.idRole)){
        this.router.navigate(["login"]);
        return false;
      }
      this.roleService.getAllRole().subscribe((data: any[]) => {
        this.roles = data;
        this.roleService.getRoleById(this.utilisateur.idRole).subscribe(role => {
          this.role = role;
          if (this.role && this.role.privileges && this.role.privileges.length > 0) {
            let founddeleteUtilisateur = this.role.privileges.find(x => x.name == "DELETE_ROLE")
            let foundupdateUtilisateur = this.role.privileges.find(x => x.name == "UPDATE_ROLE")
            let foundupdateUtilisateuradd = this.role.privileges.find(x => x.name == "ADD_ROLE")
            if (founddeleteUtilisateur) {
              this.hasRoleDelete = true;
            }
            if (foundupdateUtilisateur) {
              this.hasRoleUpdate = true;
            }
            if (foundupdateUtilisateuradd) {
              this.hasRoleAdd = true;
            }
          }
        },
          err => {
            return false;
          });
      },
        err => {
          return false;
        });
    
    },
    err => {
      return false;
    });
    
  }

  removeRole(role){
    Swal.fire({
      title: 'VOULEZ-VOUS SUPPRIMER CE RÔLE?',
      imageUrl: 'assets/images/supp-modal.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      showCancelButton: true,
      width: 800,
      confirmButtonColor: '#059e00',
      cancelButtonColor: '#FF9100',
      cancelButtonText: 'ANNULER',
      confirmButtonText: 'VALIDER'
    }).then((result) => {
      if (!result.dismiss) {
        Swal.fire( {
            imageUrl: 'assets/images/supp-modal.png',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            width: 800,
            title: 'RÔLE SUPPRIMÉ',
            timer: 1500,
            showConfirmButton: false
          }
        );
        this.roleService.deleteById(role.id).subscribe(data => {
          this.roles.splice(this.roles.indexOf(role), 1);
         });
         
      }
    } );
  }
}
