import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {
  static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
    let config = {
      'required': 'Champ invalide',
      'invalidCreditCard': 'Is invalid credit card number',
      'invalidEmailAddress': 'Email invalide. Réessayez !',
      'nospace': 'Email invalide. Réessayez !',
      'invalidImage': 'Le fichier sélectionné doit être de type(png,jpeg,jpg). La taille maximale est 2 Mo.',
      'invalidPassword': 'Invalid password. Password must be at least 6 characters long, and contain a number.',
      'minlength': `${validatorValue.requiredLength} caractères minimum`,
      'maxLength': `${validatorValue.requiredLength} caractères maximum`,
      'invalidNumber': 'Champ invalide',
      'invalidDate': 'Champ invalide',

    };

    return config[validatorName];
  }

  static creditCardValidator(control) {
    // Visa, MasterCard, American Express, Diners Club, Discover, JCB
    if (control.value.match(/^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/)) {
      return null;
    } else {
      return { 'invalidCreditCard': true };
    }
  }

  static numberValidator(control) {
    // Visa, MasterCard, American Express, Diners Club, Discover, JCB
    if (isNaN(control.value)) {
      return { 'invalidNumber': true };
    } else {
      return null;
    }
  }
  static emailValidator(control) {
    // RFC 2822 compliant regex
    if (control.value) {
      if (control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
        return null;
      } else {
        return { 'invalidEmailAddress': true };
      }
    }
  }

  static nospaceValidator(control): { [s: string]: boolean } {
  let re = / /;
  if (control.value && control.value.match(re)) {
    return { nospace: true };
  }
  }
  static validateEmptyField(c) {
    return c.value && !c.value.trim() ? {
      required: {
        valid: false
      }
    } : null;
  }
  static passwordValidator(control) {
    // {6,100}           - Assert password is between 6 and 100 characters
    // (?=.*[0-9])       - Assert a string has at least one number
    if (control.value.match(/^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,100}$/)) {
      return null;
    } else {
      return { 'invalidPassword': true };
    }
  }


  static dateValidator(control) {

   let today = new Date().toISOString().slice(0, 10)
    if(control.value){
    if (control.value >= today) {
      return { 'invalidDate': true };

    } else {

      return null;

    }
  }
  }
  static dateValidatorYear(control) {

   let today = "9999-12-30"
    if(control.value){
    if (control.value >= today) {
      return { 'invalidDate': true };

    } else {

      return null;

    }
  }
  }
  static dateValidatorr(control) {
    if(control.value){
    if (control.value.length != 10 || control.value <= "1950" || control.value >= "2050") {
      return { 'invalidDate': true };

    } else {

      return null;

    }
  }
}
}
