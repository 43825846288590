<app-navbar></app-navbar>
<br>
<div id="content-nav-page" class="row" *ngIf="dossier">
    <div class=" col-3 titre-menu">
      <a routerLink="/dossier" class=" icon-f-left"></a>
      <b class="title-haut">{{dossier.prenom}} {{dossier.nom}}<img class="ml-2" src="assets/images/show-a.png"></b>
    </div>
    <div class="col-9 sous-menu">
      <b class="sous-menu-active1"  [routerLink]="['/dossier']">DOSSIER</b>
      <b class="sous-menu-active2 ml-4" [routerLink]="['/commande']" *ngIf="utilisateur && utilisateur.role === 'Super admin' || utilisateur && utilisateur.role === 'Administration' || utilisateur && utilisateur.role === 'Admin' && utilisateur.mail != 'r.diop@krendel.fr' && utilisateur.mail != 's.zhang@krendel.fr' && utilisateur.mail != 'a.krichen@krendel.fr' && utilisateur.mail != 'philippe.belton@krendel.fr'  && utilisateur.mail != 'claude.delaoliva@krendel.fr' || utilisateur && utilisateur.role === 'Responsable commandes' || (utilisateur && utilisateur.role === 'Equipe Atelier' && utilisateur.sousRole === 'Responsable magasin') || utilisateur && utilisateur.role === 'Utilisateur-client'"
        style="cursor: pointer">COMMANDE</b>
  
    </div>
  </div>
  <div class="row" *ngIf="dossier">
  
    <div class="col-xl-2 col-lg-3  col-md-3 col-sm-4 left-content-scrolbar ">
  
      <div class="col col-content ">
        <button class="tablink2 btn-liste" id="defaultOpen2" (click)="modifPart1()">
          COORDONNÉES</button>
      </div>
  
      <div class="col col-content" *ngIf="roleAdministration">
        <button class="tablink2 btn-liste " (click)="modifPart2()"> COORDONNÉES
          COMPLÉMENTAIRES </button>
      </div>
  
      <div class="col col-content">
        <button class="tablink2 btn-liste " (click)="modifPart3()"> RESPONSABLE CHIFFRAGE
        </button>
      </div>
  
      <div class="col col-content">
        <button class="tablink2 btn-liste " (click)="modifPart4()"> FICHIERS
        </button>
      </div>
      <div class="col col-content">
        <button class="tablink2 btn-liste " (click)="modifPart5()"> MATÉRIELS COMMANDÉS
        </button>
      </div>
      <div class="col col-content" *ngIf="utilisateur && utilisateur.role === 'Super admin'">
        <button class="tablink2 btn-liste " (click)="modifPart7()"> RAPPRO
        </button>
      </div>
    </div>
    <div class="col-xl-10 col-lg-9  col-md-9 col-sm-8 right-content-scrolbar">
      <div class="card p-3">
        <div *ngIf="showPart()==1" class="col-content-affiche tabcontent2" id="information">
  
          <div>
            <div class="card-body">
  
              <div class="row">
                <div class="col-6">
                  <div class="title">
                    <h4>Numéro du dossier</h4>
                    <span class="font-weight-bold">{{dossier.numero}}</span>
                  </div>
  
                  <br />
                  <div class="title ">
                    <h4>Type</h4>
                    <span class="font-weight-bold" *ngIf="dossier.type">{{dossier.type[0]}} </span>
                  </div>
                  <br />
  
                  <div class="title">
                    <h4>Nom du dossier</h4>
                    <span class="font-weight-bold">{{dossier.nom}}</span>
                  </div>
                  <br />
                  <div class="title">
                    <h4>Coefficient du dossier</h4>
                    <span class="font-weight-bold">{{dossier.coef}}</span>
  
                  </div>
  
                </div>
  
                <div class="col-6" style="margin-top: 23px;">
                  <div class="title" style="margin-top: -22px;">
                    <h4>Date de création</h4>
                    <span>
                      {{dossier.datec.substring(0, dossier.datec.length-3)}}
                    </span>
                  </div>
                  <br />
  
                  <div class="title">
                    <h4>Date limite</h4>
                    <span>{{dossier.datel | date : 'dd/MM/yyyy'}}</span>
                  </div>
                  <br />
                  <div class="title" style="margin-top: 0px;">
                    <h4>Date de réception du dossier</h4>
                    <span class="font-weight-bold">{{dossier.dateReception | date : 'dd/MM/yyyy'}}</span>
                  </div>
                  <br>
                  <div class="title">
                    <h4>Délai du traitement</h4>
                    <span class="font-weight-bold">J-{{dossier.delai}}</span>
                  </div>
                  <div class="title">
                    <h4>Compteur des jours</h4>
                    <span class="font-weight-bold">{{compteur}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="showPart()==2 && roleAdministration" class="col-content-affiche tabcontent2" id="information_compl">
  
          <div>
            <div class="card-body">
  
              <div class="row">
                <div class="col-6">
                  <div class="title" *ngIf="dossier.client">
                    <h4>Client</h4>
                    <span class="font-weight-bold">{{dossier.client.denomination}} - {{dossier.client.acronyme}} </span>
                  </div>
  
                  <br />
                  <div class="title ">
                    <h4>Etablissement</h4>
                    <span class="font-weight-bold"> {{dossier.etablissement}} </span>
                  </div>
                  <br />
  
                  <div class="title" *ngIf="dossier.chargeAffaire">
                    <h4>Chargé d'affaires</h4>
                    <span class="font-weight-bold">{{dossier.chargeAffaire.nom}} {{dossier.chargeAffaire.prenom}} <br> <br> Email : {{dossier.chargeAffaire.mail}} <br> <br>Téléphone : {{dossier.chargeAffaire.tel}}</span>
                  </div>
                  <br />
                  <div class="title" *ngIf="dossier.user">
                    <h4>Créé par</h4>
                    <span class="font-weight-bold" *ngIf="dossier.user">{{dossier.user.prenom[0]}}{{dossier.user.nom[0]}}</span>
  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="showPart()==3" class="col-content-affiche tabcontent2" id="etablissement">
  
          <div>
            <div class="card-body">
  
              <div class="row" *ngIf="dossier.personneVisee">
                <div class="col-6">
                  <div class="title">
                    <h4>Nom</h4>
                    <span class="font-weight-bold" *ngIf="dossier.personneVisee">{{dossier.personneVisee.nom[0]}} {{dossier.personneVisee.prenom[0]}}</span>
                  </div>
  
                  <br />
                  <div class="title">
                    <h4>Email</h4>
                    <span class="font-weight-bold">{{dossier.personneVisee.mail}}</span>
                  </div>
                  <br />
                  <div class="title">
                    <h4>Téléphone</h4>
                    <span class="font-weight-bold">{{dossier.personneVisee.tel}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ng-template #customLoadingTemplate></ng-template>
        <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading>
  
        <div *ngIf="showPart()==4" class="col-content-affiche tabcontent2" id="comp">
          <div>
            <div class="card-body">
  
              <div class="row">
                <div class="col-6">
                  <div class="title" *ngFor=" let file of dossier.listfiles">
                    <h4>Fichier {{dossier.listfiles.indexOf(file)+1}}</h4>
                    <span class="font-weight-bold"><a href="{{file.photo}}" style="cursor: pointer;text-decoration: underline;" target="_blank"><span *ngIf="!file.name">Lien 1</span> <span *ngIf="file.name">{{file.name}}</span></a> </span>
                  </div>
  
                  <br />
                  <div class="title " *ngFor=" let file of dossier.listfilesEmail">
                    <h4>Email</h4>
                    <span class="font-weight-bold"> <a href="{{file.photo}}" style="cursor: pointer;text-decoration: underline;" target="_blank"><span *ngIf="!file.name">Lien 2</span> <span *ngIf="file.name">{{file.name}}</span></a> </span>
                  </div>
                  <br />
  
                  <div class="title" *ngFor=" let file of dossier.listfilesCCTP">
                    <h4>CCTP</h4>
                    <span class="font-weight-bold"><a href="{{file.photo}}" style="cursor: pointer;text-decoration: underline;" target="_blank"><span *ngIf="!file.name">Lien 3</span> <span *ngIf="file.name">{{file.name}}</span></a></span>
                  </div>
                  <br />
                  <div class="title" *ngFor=" let file of dossier.listfilesPlan">
                    <h4>Plan</h4>
                    <span class="font-weight-bold"><a href="{{file.photo}}" style="cursor: pointer;text-decoration: underline;" target="_blank"><span *ngIf="!file.name">Lien 4</span><span *ngIf="file.name">{{file.name}}</span></a> </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="showPart()==5" class="col-content-affiche tabcontent2" id="com">
          <div *ngIf="dossier">
            <div class="card-body">
              <div class="row ligne-table2  d-flex justify-content-center">
                <table class="table-responsive">
                  <tr style="text-align: left;">
                    <td class="padding-dix">
                      Numéro de commande
                    </td>
                    <td class="col-1 p-l-0 padding-dix">
                      Numéro de commande GPMI
                    </td>
                    <td class="col-2 p-l-0 padding-dix">
                      Fournisseur
                    </td>
                    <td class="col-1 p-l-0 padding-dix">
                      Date de commande
                    </td>
                    <td class="col-2 p-l-0 padding-dix">
                      Date de livraison
                    </td>
                    <td class="col-2 p-l-0 padding-dix right">
                      Prix net total HT
                    </td>
                    <td class="col-2 p-l-0 padding-dix right">
                      Montant de la TVA
                    </td>
                    <td class="col-2 p-l-0 padding-dix right">
                      Prix net total TTC
                    </td>
                    <td class="col-1 p-l-0 padding-dix">
                      Statut
                    </td>
                  </tr>
                  <tr  *ngFor="let commande of commandes | paginate: { itemsPerPage: 25, currentPage: p } " style="cursor:pointer">
                    <td class="padding-dix" [routerLink]="['/details/dossier/commande', commande.id, dossier.id]">{{commande.numeroCommande}}</td>
                    <td class="padding-dix" [routerLink]="['/details/dossier/commande', commande.id, dossier.id]">{{commande.numeroCommandeGPMI}}</td>
                    <td class="padding-dix" [routerLink]="['/details/dossier/commande', commande.id, dossier.id]">{{commande.denomination}}</td>
                    <td class="padding-dix" [routerLink]="['/details/dossier/commande', commande.id, dossier.id]"><span *ngIf="commande.dateCommande">{{commande.dateCommande | date : 'dd/MM/yyyy'}}</span></td>
                    <td class="padding-dix" [routerLink]="['/details/dossier/commande', commande.id, dossier.id]"><span *ngIf="commande.dateLivraison">{{commande.dateLivraison | date : 'dd/MM/yyyy'}}</span></td>
                    <td class="padding-dix right" [routerLink]="['/details/dossier/commande', commande.id, dossier.id]"><span *ngIf="commande.sommePrixNet && commande.sommePrixNet != 'NaN'">{{commande.sommePrixNet | number: '1.2-2'| replace:',':' ' | replace:'.':','}}</span>
                    </td>
                    <td class="padding-dix right" [routerLink]="['/details/dossier/commande', commande.id, dossier.id]"><span *ngIf="commande.montantTVA && commande.montantTVA != 'NaN'">{{commande.montantTVA | number: '1.2-2'| replace:',':' ' | replace:'.':','}}</span></td>
                    <td class="padding-dix right" [routerLink]="['/details/dossier/commande', commande.id, dossier.id]"><span
                        *ngIf="commande.sommePrixNetTTC && commande.sommePrixNetTTC != 'NaN'">{{commande.sommePrixNetTTC| number: '1.2-2'| replace:',':' ' | replace:'.':','}}</span></td>
                    <td class="padding-dix" [routerLink]="['/details/dossier/commande', commande.id, dossier.id]">{{commande.etat}}</td>
                  </tr>
                </table>
                <div class="row">
                  <div class="col col-xs-6">
                    <pagination-controls previousLabel="Précédent" nextLabel="Suivant" (pageChange)="p = $event" #api></pagination-controls>
                  </div>
                </div>
  
              </div>
            </div>
          </div>
        </div>
        <div class="col-content-affiche tabcontent2" id="RAPPRO" *ngIf="showPart()==7">
          <h5 style="color:green">Rappro</h5>
          <div class="row ">
            <table class="table table-responsive d-flex justify-content-center" style="display: block !important;">
              <tr>
                <th class="left">Code Article</th>
                <th class="left">Référence</th>
                <th class="left">Libellé</th>
                <th class="left">Arm</th>
                <th class="right">Qté</th>
                <th class="right">Qté acheté</th>
                <th class="right">Écart</th>
              </tr>
              <tr *ngFor="let dat of displayLastDevisArray" class="text-center">
                <td class="left">{{dat.codArt}}</td>
                <td class="left">
                  <li *ngFor="let qte of dat.quantites" style="list-style-type: none;">1 * {{qte.quantite}}</li>
                  <li style="list-style-type: none;">{{dat.refArt}}</li>
                </td>
                <td class="left">{{dat.libDev}}</td>
                <td class="left">
                  <li *ngFor="let qteee of dat.quantites" style="list-style-type: none;">{{qteee.armoireNumero}}</li>
                  <li style="list-style-type: none;">TOT</li>
                </td>
                <td class="right">
                  <li *ngFor="let qte of dat.quantites" style="list-style-type: none;">{{qte.quantite | number: '1.2-2'| replace:',':' ' | replace:'.':',' | replace:',00':' '}}</li>
                  <li style="list-style-type: none;">{{dat.sum}}</li>
                </td>
                <td class="right">
                  {{dat.qteAchete}}
                </td>
                <td class="right">
                  {{dat.sum - dat.qteAchete}}
                </td>
              </tr>
              <tr class="text-center" *ngIf="arrayNewArticles && arrayNewArticles.length > 0">
                <td colspan="7">Nouveaux articles</td>
              </tr>
              <tr *ngFor="let dat of arrayNewArticles" class="text-center">
                <td class="left">{{dat.codArt}}</td>
                <td class="left">
                  <li style="list-style-type: none;">{{dat.refArt}}</li>
                </td>
                <td class="left">{{dat.designation}}</td>
                <td class="left">
                </td>
                <td class="right">
  
                </td>
                <td class="right">
                  {{dat.quantite}}
                </td>
                <td class="right">
  
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  