import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {UserService} from "./services/user.service";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  email:any;
  password:any;
  idUser:any;
  utilisateur:any;
  href = "accueil"
  closeResult: string;
  constructor( private userService:UserService, private router: Router,) { }
  ngOnInit(): void {
    this.idUser = localStorage.getItem('idUser');
    if(this.idUser){
      this.userService.getUserByID(this.idUser).subscribe(utilisateur => {
        this.utilisateur = utilisateur;
        this.href = this.router.url;
        if(this.utilisateur){
          if(this.href == "/"){
            this.router.navigate(['/accueil']);
            return false;
          }else{
            this.router.navigate([this.href]);
            return false;
          }
          
        }
        if(!this.utilisateur){
          this.router.navigate(['/login']);
          return false;
        }
      },
        err => {
          return false;
        });
    }else{
      this.router.navigate(['/login']);
      return ;
    }
  }
}
