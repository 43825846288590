import { Component, OnInit } from '@angular/core';
import { AuthentificationService } from '../../services/authentification.service';
import {ValidateService} from '../../services/validate/validate.service';
import { Router } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
import { Md5 } from 'ts-md5/dist/md5';
import {UserService} from "../../services/user.service";
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
declare var grecaptcha: any;

@Component({
  selector: 'app-authentification',
  templateUrl: './authentification.component.html',
  styleUrls: ['./authentification.component.css']
})
export class AuthentificationComponent implements OnInit {

  email:any;
  password:any;
  idUser:any;
  utilisateur:any;
  title = 'appBootstrap';
  tokenRecaptcha: string|undefined;
  isvoguel = true;
  closeResult: string;
  constructor( private modalService: NgbModal,private userService:UserService,private flashMessage:FlashMessagesService,private validateService:ValidateService, private router: Router,private authentificationService:AuthentificationService) { }
  ngOnInit() {
    let host = window.location.hostname
     if(host.indexOf("hotelcaravanserail.com") > -1 || host.indexOf("localhost") > -1){
      this.isvoguel = false;
    } 
    this.idUser = localStorage.getItem('idUser');
    if(this.idUser){
      this.userService.getUserByID(this.idUser).subscribe(utilisateur => {
        this.utilisateur = utilisateur;
        if(this.utilisateur){
          this.router.navigate(['/accueil']);
        }
      },
        err => {
          return false;
        });
    }
    this.tokenRecaptcha = undefined;
  }

  //connexion admin
  loginAdmin() {
    
    const admin = {
      mail: this.email,
      password: this.password
    }
    if (admin.mail == undefined) {
      this.flashMessage.show('Ajouter votre Email ', { cssClass: 'alert-msg-danger', timeout: 3000 });
      return false;
    }
    if (!this.validateService.validateEmail(admin.mail)) {
      this.flashMessage.show('Vérifier votre Email', { cssClass: 'alert-msg-danger', timeout: 3000 });
      return false;
    }
    if (admin.password == undefined) {
      this.flashMessage.show('Ajouter votre mot de passe', { cssClass: 'alert-msg-danger', timeout: 3000 });
      return false;
    }
    if(!this.tokenRecaptcha || this.tokenRecaptcha == undefined || this.tokenRecaptcha == ""){
      this.flashMessage.show('Recaptcha est obligatoire', { cssClass: 'alert-msg-danger', timeout: 3000 });
      return false;
    }
    const response = grecaptcha.getResponse();
    if (response.length === 0) {
      this.flashMessage.show('Recaptcha est obligatoire', { cssClass: 'alert-msg-danger', timeout: 3000 });
      return false;
    }
    else {
      admin.password = Md5.hashAsciiStr(admin.password);
      this.authentificationService.authenticateAdmin(admin).subscribe((result) => {
        if(result && result.message == "invalide"){
          this.flashMessage.show('Vérifier vos informations', { cssClass: 'alert-msg-danger', timeout: 3000 });
          return false;
        }
        if(result && result.length < 1){
          this.flashMessage.show('Vérifier vos informations', { cssClass: 'alert-msg-danger', timeout: 3000 });
          return false;
        }
        if(result){
          if(result.blocked){
            this.flashMessage.show('Vérifier vos informations', { cssClass: 'alert-msg-danger', timeout: 3000 });
            return false;
          }else{
          document.getElementById("disBtnn").click();
          localStorage.setItem('idUser', result['id']);
          if(result['role'] === 'Administration'){
            localStorage.setItem('roleUser', result['role']);
          }
          localStorage.setItem('token', result.token);
          this.router.navigate(['/accueil']);
          }
        }else{
          this.flashMessage.show('Vérifier vos informations', { cssClass: 'alert-msg-danger', timeout: 3000 });
          return false;
        }
      }, (err) => {
        localStorage.clear();
        // Navigate to the login page with extras
        this.router.navigate(['/login']);
        return false;
      });
    }
  }

  initiliaseModal(){
    document.getElementById("disBtnn").click();

  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
}
