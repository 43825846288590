import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CatalogueRoutingModule } from './catalogue-routing.module';
import { CatalogueComponent } from './catalogue/catalogue.component';
import { DetailsCatalogueComponent } from './details-catalogue/details-catalogue.component';
import { UpdateCatalogueComponent } from './update-catalogue/update-catalogue.component';
import { AjoutCatalogueComponent } from './ajout-catalogue/ajout-catalogue.component';
import { AjoutCatalogueManuelComponent } from './ajout-catalogue-manuel/ajout-catalogue-manuel.component';
import { CatalogueByFournisseurComponent } from './catalogue-by-fournisseur/catalogue-by-fournisseur.component';
import { CatalogueByYearComponent } from './catalogue-by-year/catalogue-by-year.component';
import { NavbarModule } from '../shared/navbar/navbar.module';
import { DecimalFormattModule } from '../shared/decimal-formatt/decimal-formatt.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ControleMessagesRoutingModule } from '../shared/controle-messages/controle-messages-routing.module';
import { ControleMessagesModule } from '../shared/controle-messages/controle-messages.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxLoadingModule } from 'ngx-loading';
import { SearchCatalogueYearPipe } from 'src/app/search/searchCatalogueYear.pipe';
import { SearchCataloguePipe } from 'src/app/search/searchCatalogue.pipe';
import { CatalogueGuard } from 'src/app/guards/catalogue.guard';
@NgModule({
  declarations: [CatalogueComponent,SearchCatalogueYearPipe,SearchCataloguePipe, DetailsCatalogueComponent, UpdateCatalogueComponent, AjoutCatalogueComponent, AjoutCatalogueManuelComponent, CatalogueByFournisseurComponent, CatalogueByYearComponent],
  imports: [
    CommonModule,
    CatalogueRoutingModule,
    NavbarModule,
    DecimalFormattModule,
    FormsModule, ReactiveFormsModule,
    ControleMessagesModule,
    ControleMessagesRoutingModule,
    NgxPaginationModule,
    NgbModule,
    InfiniteScrollModule,
    NgxLoadingModule
  ],
  providers:[CatalogueGuard]
})
export class CatalogueModule { }
