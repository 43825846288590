import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthentificationRoutingModule } from './authentification-routing.module';
import { AuthentificationComponent } from './authentification/authentification.component';
import { FlashMessagesModule } from 'angular2-flash-messages';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { LogoutComponent } from './logout.component';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
@NgModule({
  declarations: [AuthentificationComponent,LogoutComponent],
  imports: [
    CommonModule,
    AuthentificationRoutingModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    FlashMessagesModule.forRoot(),
    RecaptchaModule,
    RecaptchaFormsModule,

  ],
  providers: [{
    provide: RECAPTCHA_SETTINGS,
    useValue: {
      siteKey: environment.recaptcha.siteKey,
    } as RecaptchaSettings,
  },],
})
export class AuthentificationModule { }
