import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
const headers = new HttpHeaders({Authorization: 'Basic ' + btoa('UserkrendelAdminnew:krl2021!!??**SoC24-415fr-a410-ttx9J8NbPecEL6k4BkAXvGE3t8f3nuEZmXa5RNJBAdrnh6XqB2')})
.set('Content-Type', 'application/json')
.set('Accept', 'application/json');
headers.append('Access-Control-Allow-Origin', '*');
import 'rxjs/add/operator/map';


@Injectable({
  providedIn: 'root'
})
export class CommandeService {
  constructor(private http: HttpClient) {
  }


  getAllData(){
    return new Promise(resolve => {
      this.http.get(environment.host + '/commandes', {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getAllCommandeByDossier(numero,page,size){
    return new Promise(resolve => {
      this.http.get(environment.host + '/commandes/numero/'+page+'/'+size+'/'+numero+'/art', {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getAllCommandeByDossierCount(numero){
    return new Promise(resolve => {
      this.http.get(environment.host + '/commandes/numero/count/'+numero+'/art', {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getAllDataSortDESCDateCommandeByPage(page,size){
    return new Promise(resolve => {
      this.http.get(environment.host + '/commandes/desc/dateCommande/'+page+'/'+size, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getAllDataSortDESCDateCommande(){
    return new Promise(resolve => {
      this.http.get(environment.host + '/commandes/desc/dateCommande', {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getAllCommandeLowerThanDateDESC(date){
    return new Promise(resolve => {
      this.http.get(environment.host + '/commandes/desc/dateCommande/lower/'+date, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getAllCommandeLowerThanDateDESCCount(date){
    return new Promise(resolve => {
      this.http.get(environment.host + '/commandes/count/lower/date/'+date, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }

  getAllCommandeCount(){
    return new Promise(resolve => {
      this.http.get(environment.host + '/commandes/getCommande/getCount', {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getAllCommandeCountByYearFounisseur(id){
    return new Promise(resolve => {
      this.http.get(environment.host + '/commandes/getCommande/byYear/'+id, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getAllFactureByYearFounisseur(id){
    return new Promise(resolve => {
      this.http.get(environment.host + '/commandes/getFacture/byYear/'+id, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getAllFactureByYearFounisseurDateFacture(id){
    return new Promise(resolve => {
      this.http.get(environment.host + '/commandes/getFacture/byYear/dateFacture/'+id, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getAllCommandeCountByMonthFounisseur(idFournisseur,year){
    return new Promise(resolve => {
      this.http.get(environment.host + '/commandes/getCommande/byMonth/'+idFournisseur+'/'+year, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getAllFactureCountByMonthFounisseur(idFournisseur,year){
    return new Promise(resolve => {
      this.http.get(environment.host + '/commandes/getFacture/byMonth/'+idFournisseur+'/'+year, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }

  getAllFactureCountByMonthFounisseurDateFacture(idFournisseur,year){
    return new Promise(resolve => {
      this.http.get(environment.host + '/commandes/getFacture/byMonth/dateFacture/'+idFournisseur+'/'+year, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }

  getAllCommandeByMonthAndYearFounisseur(idFournisseur,year,month){
    return new Promise(resolve => {
      this.http.get(environment.host + '/commandes/getCommande/byMonthAndYear/'+idFournisseur+'/'+year+'/'+month, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getAllFactureByMonthAndYearFounisseur(idFournisseur,year,month){
    return new Promise(resolve => {
      this.http.get(environment.host + '/commandes/getFacture/byMonthAndYear/'+idFournisseur+'/'+year+'/'+month, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getAllFactureByMonthAndYearFounisseurDateFacture(idFournisseur,year,month){
    return new Promise(resolve => {
      this.http.get(environment.host + '/commandes/getFacture/byMonthAndYear/dateFacture/'+idFournisseur+'/'+year+'/'+month, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getAllCommandeByYearFounisseur(yeardateCommande,idFournisseur){
    return new Promise(resolve => {
      this.http.get(environment.host + '/commandes/getAllCommande/byYear/'+idFournisseur+'/'+yeardateCommande, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getAllCommandeByYearFounisseurFactures(yeardateCommande,idFournisseur){
    return new Promise(resolve => {
      this.http.get(environment.host + '/commandes/getAllCommande/byYear/factures/'+idFournisseur+'/'+yeardateCommande, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getAllCommandeByYearFounisseurFacturesDateFacture(yeardateCommande,idFournisseur){
    return new Promise(resolve => {
      this.http.get(environment.host + '/commandes/getAllCommande/byYear/factures/dateFacture/'+idFournisseur+'/'+yeardateCommande, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getCommandessommePrixNetTTCNull(){
    return new Promise(resolve => {
      this.http.get(environment.host + '/commandes/get/commande/null/sommePrixNetTTC', {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  addcommande(icommandes) {
    return this.http.post(environment.host + '/commandes/addOne', JSON.stringify(icommandes), { headers: headers })
  }
  saveAllData(client) {
    return this.http.post(environment.host + '/commandes/addall', JSON.stringify(client), {headers: headers})
  }
  deleteArticleById(id){
    return new Promise(resolve => {
      this.http.delete(environment.host + '/commandes/' + id,{headers: headers} ).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getArticleById(id){
    return this.http.get(environment.host + '/commandes/'+id,{headers: headers})
  }
  editIcommandesrById(icommandes){
    return this.http.put(environment.host + '/commandes/' + icommandes.id, JSON.stringify(icommandes), { headers: headers })
  }
  verifyNumeroGPMI(commande) {
    return this.http.post(environment.host + '/commandes/verifiedNumeroGPMI', JSON.stringify(commande), { headers: headers })
  }

  getAllDataByPageAndRecherche(event){
    return new Promise(resolve => {
      this.http.get(environment.host + '/commandes/getCommandes/recherche/'+event, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getAllDataByPageAndRechercheWithLimite(event){
    return new Promise(resolve => {
      this.http.get(environment.host + '/commandes/getCommandes/recherche/limite/'+event, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getAllCommandesByPage(page,size){
    return new Promise(resolve => {
      this.http.get(environment.host + '/commandes/byPage/'+page+'/'+size, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getAllLignesByPage(page,size){
    return new Promise(resolve => {
      this.http.get(environment.host + '/commandes/desc/dateCommande/'+page+'/'+size+'/explod', {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getAllFacturesByPage(page,size){
    return new Promise(resolve => {
      this.http.get(environment.host + '/commandes/desc/dateCommande/'+page+'/'+size+'/explod/facture', {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getAllFacturesByPageGroupedByGpmi(page,size){
    return new Promise(resolve => {
      this.http.get(environment.host + '/commandes/desc/dateCommande/'+page+'/'+size+'/explod/facture/grouped/gpmi', {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getFacturesCommaandesWithrecherche(event){
    return new Promise(resolve => {
      this.http.get(environment.host + '/commandes/desc/dateCommande/explod/facture/'+event, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getAllCommandeLowerThanDateDESCWithPage(date,page,size){
    return new Promise(resolve => {
      this.http.get(environment.host + '/commandes/byPage/lower/'+date+'/'+page+'/'+size, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getAllDataCount(){
    return new Promise(resolve => {
      this.http.get(environment.host + '/commandes/count', { headers: headers }).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }

  getAllLignesCount(){
    return new Promise(resolve => {
      this.http.get(environment.host + '/commandes/lignes/count', { headers: headers }).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getAllDataFactureCount(){
    return new Promise(resolve => {
      this.http.get(environment.host + '/commandes/factures/count', { headers: headers }).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
}
