import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { UserService } from "../../../services/user.service";
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { ValidationService } from '../../../services/validate/validate-error.service';
import { pays } from '../../../pays/pays';
import { tri } from '../../../pays/pays';
import { languages } from '../../../pays/langues';
import { Md5 } from 'ts-md5/dist/md5';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'app-update-utilisateur',
  templateUrl: './update-utilisateur.component.html',
  styleUrls: ['./update-utilisateur.component.scss']
})
export class UpdateUtilisateurComponent implements OnInit {
  utilisateur: any;
  form: any;
  email: any;
  pays: any[] = [];
  users: any[] = [];
  enfants: any[] = [];
  successRegister = false;
  errorEmail = false;
  formConjoint: any;
  addConjointAndEnfants = false;
  addEnfants = false;
  situationFamiliale = 'Célibataire';
  situationEnfant = 'Non';
  formEnfant: any;
  formExperience: any;
  formParcours: any;
  parcours: any[] = [];
  experiences: any[] = [];
  langues: any[] = [];
  languages: any[] = [];
  competences: any[] = [];
  competence: any;
  langue = "";
  nom: any;
  prenom: any;
  dateNaissance: any;
  sexe = 'Masculin';
  errorDates = false;
  errorPassword = false;
  formBancaire: any;
  formConnexion: any;
  dateNaissancee = new Date().toISOString().split('T')[0]
  bigDate = "9999-12-31"
  showMyContainer = 0;
  errorLangue = ""
  languesChosed: any[] = []
  savedRoles: any[] = []
  competencesChosed: any[] = []
  errorCompetence = ""
  errorPartie1 = ""
  errorPartie2 = ""
  errorPartie3 = ""
  errorPartie4 = ""
  errorPartie5 = ""
  secondp = 1;
  thirdp = 1;
  p = 1;
  constructor(private http: Http, private userService: UserService, private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute,private roleService:RoleService,
    private router: Router) {
    this.form = this.formBuilder.group({
      nom: ['', [Validators.required, Validators.minLength(2), ValidationService.validateEmptyField, Validators.maxLength(25)]],
      prenom: ['', [Validators.required, Validators.minLength(2), ValidationService.validateEmptyField, Validators.maxLength(25)]],
      dateNaissance: ['', ValidationService.dateValidator],
      dateRec: [''],
      tel: ['+216', Validators.required],
      telMobile: ['+216', Validators.required],
      sexe: ['Masculin', [Validators.required]],
      nationalite: ['Tunisie',],
      typeIdentite: ['CIN', [Validators.required]],
      numIdentite: ['', [Validators.required]],
      adresse: this.formBuilder.group({
        pays: ['Tunisie', [Validators.required]],
        codePostal: ['', [ValidationService.numberValidator, Validators.min(0), Validators.max(9999999)]],
        rue: ['',],
        n: ['', [ValidationService.numberValidator, Validators.min(0), Validators.max(9999999)]],
        ville: ['',],
      }),
      poste: this.formBuilder.group({
        titre: [''],
        departement: [''],
        bureau: [''],
      }),
    })
    this.formConjoint = this.formBuilder.group({
      nom: ['', [Validators.minLength(2)]],
      prenom: ['', [Validators.minLength(2)]],
      tel: ['+216',],
      mail: ['', [ValidationService.emailValidator]],
      OrganismeDeSante: ['',],
      nationalite: ['Tunisie']
    })
    this.formParcours = this.formBuilder.group({
      etablissement: ['', [Validators.required, Validators.minLength(4)]],
      diplomen: ['', [Validators.required, Validators.minLength(4)]],
      diplomet: ['Ouvrier', Validators.required],
      date: ['', [ValidationService.dateValidator]],
      desc: ['',]
    })
    this.formExperience = this.formBuilder.group({
      entreprise: ['', [Validators.required, Validators.minLength(4)]],
      poste: ['', [Validators.required, Validators.minLength(4)]],
      dated: ['', [ValidationService.dateValidator]],
      datef: ['',],
      desc: ['',]
    })
    this.formBancaire = this.formBuilder.group({
      nom: ['',],
      iban: ['',],
      swift: ['',],
      rib: ['',],
      bic: ['',],
    });
    this.formConnexion = this.formBuilder.group({
      role: ['Super admin', [Validators.required]],
      sousRole: ['']
    })
    this.formEnfant = this.formBuilder.group({
      nom: ['', [Validators.required, Validators.minLength(2)]],
      prenom: ['', [Validators.required, Validators.minLength(2)]],
      dateNaissance: ['', [Validators.required, ValidationService.dateValidator]],
      sexe: ['Masculin', [Validators.required]],
    })
  }
  ngOnInit() {
    this.pays = pays.sort(tri);
    this.languages = languages
    let params: any = this.activatedRoute.snapshot.params;
    if (params) {
      this.userService.getUserByID(params.id).subscribe(utilisateur => {
        this.utilisateur = utilisateur;
        if (!this.utilisateur) {
          localStorage.removeItem('idUser');
          localStorage.clear();
          this.router.navigate(['/login']);
        }
        if (!this.utilisateur) {
          this.router.navigateByUrl('/utilisateur');
        }
        if (this.utilisateur.situationFamiliale != "Célibataire") {
          this.addConjointAndEnfants = true;
        }
        if (this.utilisateur.situationEnfant === "Oui") {
          this.addEnfants = true;
        }
      },
        err => {
          return false;
        });
    } else {
      return false;
    }

    this.roleService.getAllRole().subscribe(data => {
      this.savedRoles = data
    })

  }
  showOthersSituation() {
    if (this.utilisateur.situationFamiliale === "Célibataire") {
      this.addConjointAndEnfants = false;
    }
    else {
      this.addConjointAndEnfants = true;
    }
  }
  showOthersSituationEnfant() {
    if (this.utilisateur.situationEnfant === "Non") {
      this.addEnfants = false;
    }
    else {
      this.addEnfants = true;
    }
  }
  getBigDate(): string {
      return this.bigDate
    }
  // ajouter des parcours
  addnewParcours() {
    this.utilisateur.parcours.push(this.formParcours.value);
    this.formParcours.reset();
  }
  // supprimer parcours
  removeParcours(parcour) {
    Swal.fire({
      title: 'VOULEZ-VOUS SUPPRIMER CE PARCOURS?',
      imageUrl: 'assets/images/supp-modal.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      showCancelButton: true,
      width: 800,
      confirmButtonColor: '#059e00',
      cancelButtonColor: '#FF9100',
      cancelButtonText: 'ANNULER',
      confirmButtonText: 'VALIDER'
    }).then((result) => {
      if (!result.dismiss) {
        Swal.fire({
          imageUrl: 'assets/images/supp-modal.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 800,
          title: 'BIEN SUPPRIMÉ',
          timer: 1500,
          showConfirmButton: false
        }
        );
        this.utilisateur.parcours.splice(this.utilisateur.parcours.indexOf(parcour), 1);

      }
    });
  }
  // add enfants
  addnewEnfant() {
    this.utilisateur.enfants.push(this.formEnfant.value);
    this.formEnfant.reset();
  }
  removeEnfant(enfant) {
    if (window.confirm("Voulez vous vraiment supprimer cet enfant?")) {
      this.utilisateur.enfants.splice(this.utilisateur.enfants.indexOf(enfant), 1);
    }
  }
  // remove enfant
  // ajouter des parcours
  addnewExperience() {
    if (this.formExperience.value.dated >= this.formExperience.value.datef) {
      this.errorDates = true;
      return false;
    }
    if (this.formExperience.value.datef.toString().length > 10) {
      this.errorDates = true;
      return false;
    }
    this.utilisateur.experiences.push(this.formExperience.value);
    this.formExperience.reset();
    this.errorDates = false;
  }
  // supprimer parcours
  removeExperience(experience) {
    Swal.fire({
      title: 'VOULEZ-VOUS SUPPRIMER CETTE EXPÉRIENCE?',
      imageUrl: 'assets/images/supp-modal.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      showCancelButton: true,
      width: 800,
      confirmButtonColor: '#059e00',
      cancelButtonColor: '#FF9100',
      cancelButtonText: 'ANNULER',
      confirmButtonText: 'VALIDER'
    }).then((result) => {
      if (!result.dismiss) {
        Swal.fire({
          imageUrl: 'assets/images/supp-modal.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 800,
          title: 'BIEN SUPPRIMÉ',
          timer: 1500,
          showConfirmButton: false
        }
        );
        this.utilisateur.experiences.splice(this.utilisateur.experiences.indexOf(experience), 1);
      }
    });
  }
  // AJOUET IUN LANGUE
  addLangues(langue) {
    this.errorLangue = ""
    if (this.utilisateur.languages.indexOf(langue) > -1 || this.languesChosed.indexOf(langue) > -1) {
      this.errorLangue = "Cette langue existe déjà dans la liste"
      return false;
    } else {
      this.languesChosed.push(langue);
    }
  }
  resetErrorLangue() {
    this.languesChosed = []
    while (this.languesChosed.length > 0) {
      this.languesChosed.pop();
    }
    this.errorLangue = ""
    this.langue = ""
  }
  saveChoosedLangues() {
    this.utilisateur.languages.push(...this.languesChosed);
  }



  // SUPPRIMER IUN LANGUE

  removeLangue(langue) {
    Swal.fire({
      title: 'VOULEZ-VOUS SUPPRIMER CETTE LANGUE?',
      imageUrl: 'assets/images/supp-modal.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      showCancelButton: true,
      width: 800,
      confirmButtonColor: '#059e00',
      cancelButtonColor: '#FF9100',
      cancelButtonText: 'ANNULER',
      confirmButtonText: 'VALIDER'
    }).then((result) => {
      if (!result.dismiss) {
        Swal.fire({
          imageUrl: 'assets/images/supp-modal.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 800,
          title: 'BIEN SUPPRIMÉ',
          timer: 1500,
          showConfirmButton: false
        }
        );
        this.utilisateur.languages.splice(this.utilisateur.languages.indexOf(langue), 1);

      }
    });
  }
  // AJOUET IUN competence

  addCompetences(competence) {
    this.errorCompetence = ""
    if (this.utilisateur.competences.indexOf(competence) > -1 || this.competencesChosed.indexOf(competence) > -1) {
      this.errorCompetence = "Cette compétence existe déjà dans la liste"
      return false;
    } else {
      this.competencesChosed.push(competence);
    }
  }
  resetErrorCompetence() {
    this.competencesChosed = []
    while (this.competencesChosed.length > 0) {
      this.competencesChosed.pop();
    }
    this.errorCompetence = ""
    this.competence = ""
  }
  saveChoosedCompetence() {
    this.utilisateur.competences.push(...this.competencesChosed);
  }
  // supprimer IUN competence
  removeCompetence(competence) {
    Swal.fire({
      title: 'VOULEZ-VOUS SUPPRIMER CETTE COMPÉTENCE?',
      imageUrl: 'assets/images/supp-modal.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      showCancelButton: true,
      width: 800,
      confirmButtonColor: '#059e00',
      cancelButtonColor: '#FF9100',
      cancelButtonText: 'ANNULER',
      confirmButtonText: 'VALIDER'
    }).then((result) => {
      if (!result.dismiss) {
        Swal.fire({
          imageUrl: 'assets/images/supp-modal.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 800,
          title: 'BIEN SUPPRIMÉ',
          timer: 1500,
          showConfirmButton: false
        }
        );
        this.utilisateur.competences.splice(this.utilisateur.competences.indexOf(competence), 1);

      }
    });
  }

  //ajouter un utilisateur
  onUpdateUtilisateur(utilisateur) {
    if (utilisateur.situationFamiliale === "Célibataire") {
      utilisateur.situationFamiliale = "Célibataire";
      utilisateur.enfants = [];
      utilisateur.conjoint = {
        nom: "",
        prenom: "",
        nationalite: "",
        tel: "",
        mail: "",
        OrganismeDeSante: ""
      }
    }
    else {
      if (utilisateur.situationEnfant === "Non") {
        utilisateur.enfants = [];
      }
    }
    Swal.fire({
      title: 'VOULEZ-VOUS CONFIRMER?',
      imageUrl: 'assets/images/client-plus.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      showCancelButton: true,
      width: 800,
      confirmButtonColor: '#008000',
      cancelButtonColor: '#FFA500',
      confirmButtonText: 'CONFIRMER',

      cancelButtonText: 'ANNULER',
    }).then((result) => {
      if (!result.dismiss) {
        Swal.fire({
          imageUrl: 'assets/images/client-plus.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 800,
          title: 'BIEN MODIFIÉ',
          timer: 1500,
          showConfirmButton: false
        }
        );
        utilisateur.age = this.getAge(utilisateur.dateNaissance)
        utilisateur.idRole = utilisateur.role.id
        utilisateur.role = utilisateur.role.name
        this.userService.editInformationsUtilisateur(utilisateur).subscribe(data => {
          this.users.push(data);
          this.form.reset();
        });
        this.router.navigateByUrl('/utilisateur');
      }
    });
  }
  getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }
  getDateNaissance(): string {
    return this.dateNaissancee
  }
  modifPart1() {
    if (this.showMyContainer !== 1) {
      this.showMyContainer = 1;
    }
    else {
      this.showMyContainer = 0;
    }
  }
  modifPart2() {
    if (this.showMyContainer !== 2) {
      this.showMyContainer = 2;
    }
    else {
      this.showMyContainer = 0;
    }
  }
  modifPart3() {
    if (this.showMyContainer !== 3) {
      this.showMyContainer = 3;
    }
    else {
      this.showMyContainer = 0;
    }
  }
  modifPart4() {
    if (this.showMyContainer !== 4) {
      this.showMyContainer = 4;
    }
    else {
      this.showMyContainer = 0;
    }
  }
  modifPart5() {
    if (this.showMyContainer !== 5) {
      this.showMyContainer = 5;
    }
    else {
      this.showMyContainer = 0;
    }
  }
  modifPart6() {
    if (this.showMyContainer !== 6) {
      this.showMyContainer = 6;
    }
    else {
      this.showMyContainer = 0;
    }
  }
  modifPart7() {
    if (this.showMyContainer !== 7) {
      this.showMyContainer = 7;
    }
    else {
      this.showMyContainer = 0;
    }
  }

  showPart() {
    return this.showMyContainer;
  }
}
