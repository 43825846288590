<app-navbar></app-navbar>  
<br>

<div id="content-nav-page" class="row">
    <div class=" col-3 titre-menu">
      <b style="margin-right: 10%" class="title-haut">UTILISATEUR</b>
      <img src="assets/images/charge-plus.png" routerLink="/ajoutUtilisateur" *ngIf="hasRoleAdd">
    </div>
    <div class="col-9 sous-menu">
      <b class="sous-menu-active1" routerLink="/utilisateur">UTILISATEUR</b>
    </div>
  </div>
  <div id="content-page" class="containe-fluid">
    <div class="row  ">
      <div class="col-lg-4 col-md-10 col-sm-10  search">
        <input class="search-bar" type="search" placeholder="Rechercher.." [(ngModel)]="search" name="search">
      </div>
      <div class="col-lg-1 col-md-2 col-sm-2">
        <select class="select-input-nombre" name="numberData" [(ngModel)]="numberData">
          <option>5</option>
          <option selected>10</option>
          <option>25</option>
          <option>50</option>
        </select>
      </div>
      <div class=" col d-flex justify-content-end">
        <div class="col-auto nbr-dossier ">
          <table>
            <tr>
              <td>
                <b>Utilisateurs</b>
              </td>
            </tr>
            <tr>
              <td>
                <b class="sous-nbr-dossier d-flex justify-content-center" *ngIf="utilisateurs">{{utilisateurs.length}}</b>
              </td>
            </tr>
          </table>
        </div>
  
        <div class="col-auto btn-devis ">
          <a href="#" class="btn-connexion" (click)="toFileXLS()" *ngIf="utilisateur && utilisateur.role == 'Super admin'">Exporter<img class="ml-1" src="assets/images/excel.png"></a>
        </div>
      </div>
    </div>
    <!-- tableau -->
    <div class="row" style="overflow-x:auto;">
      <table class="table table-striped table-hover">
        <th class="left" id="firstThTable">NOM</th>
        <th class="left">PRÉNOM</th>
        <th class="left">POSTE</th>
        <th class="left">TÉLÉPHONE</th>
        <th class="left">EMAIL</th>
        <th class="left">RÔLE</th>
        <th class="text-center" *ngIf="hasRoleBloque">BLOQUER</th>
        <th class="text-center ">RÉGLAGES</th>
        <tr  *ngFor="let myuser of utilisateurs| paginate: { itemsPerPage: numberData, currentPage: p }|searching  : search.toString().toLowerCase()">
          <td class="left"  [routerLink]="['/details/utilisateur', myuser.id]">{{myuser.nom}}</td>
          <td class="left"  [routerLink]="['/details/utilisateur', myuser.id]">{{myuser.prenom}}</td>
          <td class="left"  [routerLink]="['/details/utilisateur', myuser.id]"> {{myuser.poste.titre}}</td>
          <td class="left"  [routerLink]="['/details/utilisateur', myuser.id]">{{myuser.tel}}</td>
          <td class="left"  [routerLink]="['/details/utilisateur', myuser.id]">{{myuser.mail}}</td>
          <td class="left"  [routerLink]="['/details/utilisateur', myuser.id]">{{myuser.role}}</td>
          <td class="text-center" *ngIf="hasRoleBloque">
            <label class="switch" >
              <input type="checkbox"  [(ngModel)]="myuser.blocked" [value]="myuser.blocked" (change)="bloquerUtilisateur(myuser)" title="Bloquer/Débloquer" />
              <div class="slider round"></div>
            </label>
  
          </td>
          <td class="text-center" >
            <div class="dropdown">
              <button class="btn dropdown-toggle btn-options" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                OPTIONS
              </button>
              <div class="dropdown-menu reglageMenu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item option" [routerLink]="['/details/utilisateur', myuser.id]" >
                  <div class="options-show-icon"></div>
                  <div class="option-text"> Afficher</div>
                </a>
  
                <a class="dropdown-item option" [routerLink]="['/utilisateur/details', myuser.id]" title="Modifier rôle" *ngIf="hasRoleUpdate">
                  <div class="options-modif-icon"></div>
                  <div class="option-text"> Modifier</div>
                </a>
                <a class="dropdown-item" (click)="removeUtilisateur(myuser)" *ngIf="hasRoleDelete && utilisateur && myuser && myuser.id != utilisateur.id" title="Supprimer">
                  <div class="options-supp-icon"></div>
                  <div class="option-text"> Supprimer</div>
                </a>
              </div>
            </div>
          </td>
  
        </tr>
      </table>
    </div>
    <div style="padding-top: 50px;color: green">
      <pagination-controls previousLabel="Précédent" nextLabel="Suivant" class=“custom-pagination” (pageChange)="p = $event" #api></pagination-controls>
    </div>
  </div>
  
