import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'searchRole'
})
export class SearchRolePipe implements PipeTransform {
  transform(value: any, searchText?: any): any {
    if(!value) return [];
    if(!searchText) return value;
    searchText = searchText.toLowerCase();
    return value.filter( it => {
      return it.name.toLowerCase().includes(searchText) || it.slug.toLowerCase().includes(searchText)
    });
  }
}
