<app-navbar></app-navbar>
<br>
<div id="content-nav-page" class="row">
    <div class=" col-3 titre-menu">
      <b style="margin-right: 10%">INVENTAIRE</b>
      <div class="dropdown" *ngIf="hasRoleAdd">
        <button class="btn icon-catalogue" type="button" id="dropdownMenuButton" data-toggle="dropdown">
        </button>
        <div class="dropdown-menu show-catalogue" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item drop-dup-option" routerLink="/ajoutInventaire/manuel">Manuel</a>
          <a class="dropdown-item drop-dup-option-b" routerLink="/ajoutInventaire">Fichier XLS</a>
        </div>
      </div>
    </div>
    <div class="col-9 sous-menu">
      <b class="sous-menu-active1" routerLink="/inventaire">INVENTAIRE</b>
      <b class="sous-menu-active2 ml-4" routerLink="/stock" *ngIf="hasRolestock" style="cursor: pointer">STOCK</b>
    </div>
  </div>
  <div id="content-page" class="containe-fluid">
    <div class="row" >
      <div class="col-3">
        <button type="button" name="button" class="btn btn-primary inventaire-btn-add" data-toggle="dropdown" (click)="showAndHideStock()">Créer mon stock initial </button>
        <br><br>
      </div>
      <div class="col-3" *ngIf="showStock">
        <input type="date" name="selectedDate" [(ngModel)]="selectedDate" class="form-control" id="selectedDate" style="display:flex;position: relative;top: -30px;" [max]="getBigDate()">
        <p style="color:red;margin-top: 2px;position: relative;top: -40px;" *ngIf="errorDate">Merci de choisir une date </p>
        <p style="color:red;margin-top: 2px;position: relative;top: -40px;" *ngIf="errorStcpok">Stock existe !</p>
        <p style="color:red;margin-top: 2px;position: relative;top: -40px;" *ngIf="errorNotExistInvetiare">Aucun inventaire correspond à cette date !</p>
      </div>
      <div class="col-3" *ngIf="showStock">
        <button type="button" name="button" class="btn btn-primary inventaire-btn-add"  (click)="createNewStock()">Valider</button>
      </div>
    </div>
    <div class="row" >
      <div class="col-3">
        <button type="button" name="button" class="btn btn-primary inventaire-btn-add"  (click)="showAndHideDate()">Date d'inventaire par défaut</button>
        <br><br>
      </div>
      <div class="col-3" *ngIf="showDate">
        <input type="date" name="dateInventaireChosie" [(ngModel)]="inventaireConfigObj.dateInventaireChosie" class="form-control" style="display:flex;position: relative;top: -30px;" [max]="getBigDate()">
        <p style="color:red;margin-top:2px" *ngIf="errorDate">Merci de choisir une date </p>
      </div>
      <div class="col-3" *ngIf="showDate">
        <button type="button" name="button"  class="btn btn-primary inventaire-btn-add"  (click)="createDefaultdate(inventaireConfigObj)">Valider</button>
      </div>
    </div>
    <ng-template #customLoadingTemplate></ng-template>
    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading>
    <!-- tableau -->
    <div class="row" style="overflow-x:auto;">
      <table class="table table-striped table-hover">
        <th class="left" id="firstThTable">DATE DE L'INVENTAIRE</th>
        <th class="right">NOMBRE DES FOURNISSEURS</th>
        <th class="right">NOMBRE DES RÉFÉRENCES</th>
        <th class="right">SOMME QUANTITÉ </th>
        <th class="right" >SOMME PRIX TARIF </th>
        <th class="right" >SOMME PRIX NET </th>
        <th class="right" >MONTANT TOTAL PAR PÉRIODE </th>
        <th class="right" >POURCENTAGE GPMI/REF </th>
        <th class="right" >POURCENTAGE FACT/REF </th>
        <th class="text-center" >BLOQUER L'INVENTAIRE </th>
        <th class="text-center" >MAJ L'INVENTAIRE</th>
        <th class="left">DATE DERNIERE MAJ</th>
        <th class="text-center ">RÉGLAGES</th>
        <tr class="text-center" *ngFor="let inventaire of inventaires| paginate: { itemsPerPage: 25, currentPage: p } ">
          <td class="left" s  [routerLink]="['/inventaire/fournisseurs/years',inventaire.date]">
            {{inventaire.date | date : 'dd/MM/yyyy'}}
          </td>
          <td class="right"  [routerLink]="['/inventaire/fournisseurs/years',inventaire.date]">
            {{inventaire.nombreFr}}
          </td>
          <td class="right"  [routerLink]="['/inventaire/fournisseurs/years',inventaire.date]">
            {{inventaire.nbreReferceParFounrPardateTotal | number: '1.2-2'| replace:',':' ' | replace:'.':',' | replace:',00':' '}}
          </td>
          <td class="right"  [routerLink]="['/inventaire/fournisseurs/years',inventaire.date]">
            {{inventaire.totalquantiteParFounrPardateTotal | number: '1.2-2'| replace:',':' ' | replace:'.':',' | replace:',00':' '}}
          </td>
          <td class="right" style="width: 145px;"  [routerLink]="['/inventaire/fournisseurs/years',inventaire.date]">
            {{inventaire.totalpritarParFounrPardateTotal | number: '1.2-2'| replace:',':' ' | replace:'.':','}}
          </td>
          <td class="right" style="width: 145px;"  [routerLink]="['/inventaire/fournisseurs/years',inventaire.date]">
            {{inventaire.totalprinetParFounrPardateTotal | number: '1.2-2'| replace:',':' ' | replace:'.':','}}
          </td>
          <td class="right" style="width: 145px;"  [routerLink]="['/inventaire/fournisseurs/years',inventaire.date]">
            {{inventaire.totalmontantPardate | number: '1.2-2'| replace:',':' ' | replace:'.':','}}
          </td>
          <td class="right"  [routerLink]="['/inventaire/fournisseurs/years',inventaire.date]">
            {{inventaire.totalCommandeGPMI / inventaire.nbreReferceParFounrPardateTotal * 100 | number: '1.2-2'| replace:',':' ' | replace:'.':','}} %
          </td>
          <td class="right"  [routerLink]="['/inventaire/fournisseurs/years',inventaire.date]">
            {{inventaire.totalFacture / inventaire.nbreReferceParFounrPardateTotal * 100 | number: '1.2-2'| replace:',':' ' | replace:'.':','}} %
          </td>
          <td style="text-align: center;" >
            <label class="switch" style="margin-left: 30%;background: none;">
              <input type="checkbox" class="default" [(ngModel)]="inventaire.desactive" [value]="inventaire.desactive" (change)="bloquerInvenatire(inventaire)" title="Bloquer l'inventaire">
              <span class="slider round" style="height:37px"></span>
            </label>
          </td>
          <td style="text-align: center;" >
            <img src="assets/images/maj.png" style="height: 20px; cursor: pointer" title="MAJ inventaire" (click)="majInventiare(inventaire.date)" alt="">
          </td>
          <td class="left" >
            {{inventaire.dateMaj | date:"dd/MM/yyyy HH:mm"}}
          </td>
          <td  [routerLink]="['/inventaire/fournisseurs/years',inventaire.date]">
            <button class="btn-affiche"><a [routerLink]="['/inventaire/fournisseurs/years',inventaire.date]"> <img src="assets/images/show-white.png"> Afficher </a> </button>
          </td>
        </tr>
      </table>
    </div>
    <div style="padding-top: 50px;color: green">
      <pagination-controls previousLabel="Précédent" nextLabel="Suivant" (pageChange)="p = $event" #api></pagination-controls>
    </div>
  </div>
  