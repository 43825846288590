<app-navbar></app-navbar>
<br>
<div id="content-nav-page" class="row" *ngIf="salarie">
    <div class=" col-3 titre-menu">
      <a routerLink="/rh" class=" icon-f-left"></a>
      <b class="title-haut">{{salarie.prenom}} {{salarie.nom}}<img class="ml-2" src="assets/images/show-a.png"></b>
    </div>
    <div class="col-9 sous-menu">
      <b class="sous-menu-active1" routerLink="/rh">SALARIÈS</b>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-2 col-lg-3  col-md-3 col-sm-4 left-content-scrolbar ">
      <div class="col col-content ">
        <button class="tablink2 btn-liste" id="defaultOpen2" (click)="modifPart1()">
          INFORMATIONS PERSONNELLES</button>
      </div>
      <div class="col col-content">
        <button class="tablink2 btn-liste " (click)="modifPart2()"> INFORMATIONS
          COMPLÉMENTAIRES </button>
      </div>
      <div class="col col-content">
        <button class="tablink2 btn-liste " (click)="modifPart3()"> PARCOURS ACADÉMIQUES
        </button>
      </div>
      <div class="col col-content">
        <button class="tablink2 btn-liste " (click)="modifPart4()"> ÉXPERIENCES PROFESSIONNELLES
        </button>
      </div>
      <div class="col col-content">
        <button class="tablink2 btn-liste " (click)="modifPart5()"> LANGUES ET COMPÉTENCES
        </button>
      </div>
  
    </div>
    <div class="col-xl-10 col-lg-9  col-md-9 col-sm-8 right-content-scrolbar" *ngIf="salarie">
      <div class="card p-3">
        <div *ngIf="showPart()==1" class="col-content-affiche tabcontent2" id="information">
          <div>
            <div class="card-body">
  
              <div class="row">
                <div class="col-6">
                  <div class="title">
                    <h4>Nom</h4>
                    <span class="font-weight-bold">{{salarie.nom}}</span>
                  </div>
  
                  <br />
                  <div class="title ">
                    <h4>Prénom</h4>
                    <span class="font-weight-bold">{{salarie.prenom}} </span>
                  </div>
                  <br />
  
                  <div class="title">
                    <h4>Email professionnel</h4>
                    <span class="font-weight-bold">{{salarie.mailPro}}</span>
                  </div>
                  <br />
                  <div class="title">
                    <h4>Email personnel</h4>
                    <span class="font-weight-bold">{{salarie.mailPerso}}</span>
                  </div>
                  <br />
                  <div class="title">
                    <h4>Téléphone professionnel</h4>
                    <span class="font-weight-bold">{{salarie.telPro}}</span>
  
                  </div>
  
                </div>
  
                <div class="col-6">
                  <div class="title">
                    <h4>Téléphone personnel</h4>
                    <span class="font-weight-bold">{{salarie.telPerso}}</span>
  
                  </div>
                  <br>
                  <div class="title">
                    <h4>Sexe</h4>
                    <span class="font-weight-bold">{{salarie.sexe}}</span>
  
                  </div>
                  <br />
                  <div class="title">
                    <h4>Adresse</h4>
                    <span class="font-weight-bold">{{salarie.adresse.n}} {{salarie.adresse.rue}} {{salarie.adresse.codePostal}} {{salarie.adresse.ville}} </span>
                  </div>
                  <br />
  
                  <div class="title">
                    <h4>Pays</h4>
                    <span class="font-weight-bold">{{salarie.adresse.pays}}</span>
                  </div>
                  <br />
                  <div class="title">
                    <h4>Poste</h4>
                    <span class="font-weight-bold"><span *ngIf="!salarie.posteObject">{{salarie.poste}}</span> <span *ngIf="salarie.posteObject">{{salarie.posteObject.titre}} {{salarie.posteObject.departement}} {{salarie.posteObject.bureau}}</span></span>
                  </div>
                  <br />
                  <div class="title">
                    <h4>LinkedIn</h4>
                    <span class="font-weight-bold"><span><a href="{{salarie.linkedin}}" target="_blank">Cliquer ici</a></span> </span>
                  </div>
                </div>
              </div>
              <br>
            </div>
          </div>
        </div>
        <div *ngIf="showPart()==2" class="col-content-affiche tabcontent2" id="information_compl">
  
          <div>
            <div class="card-body">
  
              <div class="row">
                <div class="col-6">
                  <div class="title">
                    <h4>Situation familiale</h4>
                    <span class="font-weight-bold">{{salarie.situationFamiliale}} </span>
                  </div>
  
                  <br />
                  <div class="title " *ngIf="salarie.conjoint">
                    <h4>Nom du conjoint</h4>
                    <span class="font-weight-bold"> {{salarie.conjoint.nom}} </span>
                  </div>
                  <br />
  
                  <div class="title" *ngIf="salarie.conjoint">
                    <h4>Prénom du conjoint</h4>
                    <span class="font-weight-bold"> {{salarie.conjoint.prenom}} </span>
                  </div>
                  <br />
                  <div class="title" *ngIf="salarie.conjoint">
                    <h4>Email du conjoint</h4>
                    <span class="font-weight-bold">{{salarie.conjoint.mail}}</span>
  
                  </div>
                </div>
                <div class="col-6" *ngIf="salarie.conjoint">
                  <div class="title">
                    <h4>Téléphone du conjoint</h4>
                    <span class="font-weight-bold">{{salarie.conjoint.tel}}</span>
                  </div>
                  <br />
  
                  <div class="title" *ngIf="salarie.conjoint">
                    <h4>Nationalité du conjoint</h4>
                    <span class="font-weight-bold">{{salarie.conjoint.nationalite}}</span>
                  </div>
                  <br />
  
                  <div class="title" *ngIf="salarie.conjoint">
                    <h4>Organisme de santé du conjoint</h4>
                    <span class="font-weight-bold">{{salarie.conjoint.OrganismeDeSante}}</span>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="salarie.enfantss">
                <h3>Les enfants</h3>
                <table class="table table-striped table-hover" id="myTable">
                  <th class="left">Nom</th>
                  <th class="left">Prénom</th>
                  <th class="left">Date de naissance</th>
                  <th class="left">Sexe</th>
                  <tr class="text-center" *ngFor="let enfant of salarie.enfants| paginate: { id:'enfantsPag',itemsPerPage: 4, currentPage: enfantp }">
                    <td class="left">{{enfant.nom}}</td>
                    <td class="left">{{enfant.prenom}}</td>
                    <td class="left">{{enfant.dateNaissance | date : 'dd/MM/yyyy' }}</td>
                    <td class="left">{{enfant.sexe}}</td>
                  </tr>
                </table>
                <div class="row">
                  <div class="col col-xs-6">
                    <pagination-controls previousLabel="Précédent" nextLabel="Suivant" (pageChange)="enfantp = $event" id="enfantsPag"></pagination-controls>
                  </div>
                </div>
              </div>
  
            </div>
          </div>
        </div>
        <div *ngIf="showPart()==3" class="col-content-affiche tabcontent2" id="etablissement">
  
          <div>
            <div class="card-body">
              <!--<div class="title">
              <h4>Multi établissement</h4>
              <span class="font-weight-bold"> Non </span>
            </div>-->
              <div class="row ligne-table2  d-flex justify-content-center">
                <table class="table-responsive" *ngIf="salarie.parcours">
                  <tr >
                    <td class="col-3 p-l-0">
                      Nom d'établissement
                    </td>
                    <td class="col-3 p-l-0">
                      Nom du diplôme
                    </td>
                    <td class="col-3 p-l-0">
                      Type du diplôme
                    </td>
                    <td class="col-3 p-l-0">
                      Date d'obtention
                    </td>
                    <td class="col-3 p-l-0">
                      Description
                    </td>
                  </tr>
                  <tr  *ngFor="let parcour of salarie.parcours| paginate: {id:'first', itemsPerPage: 4, currentPage: fp }">
                    <td>{{parcour.etablissement}}</td>
                    <td>{{parcour.diplomen}}</td>
                    <td>{{parcour.diplomet}}</td>
                    <td>{{parcour.date | date : 'dd/MM/yyyy' }}</td>
                    <td>{{parcour.desc}}</td>
                  </tr>
                </table>
                <div class="row">
                  <div class="col col-xs-6">
                    <pagination-controls previousLabel="Précédent" nextLabel="Suivant" (pageChange)="fp = $event" id="first"></pagination-controls>
                  </div>
                </div>
  
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="showPart()==4" class="col-content-affiche tabcontent2" id="comp">
  
          <div>
            <div class="card-body">
              <div class="row ligne-table2  d-flex justify-content-center">
                <table class="table-responsive" *ngIf="salarie.experiences">
                  <tr >
                    <td class="col-3 p-l-0">
                      Nom de l'entreprise
                    </td>
                    <td class="col-3 p-l-0">
                      Nom du poste
                    </td>
                    <td class="col-3 p-l-0">
                      Date de début
                    </td>
                    <td class="col-3 p-l-0">
                      Date de fin
                    </td>
                    <td class="col-3 p-l-0">
                      Description
                    </td>
                  </tr>
                  <tr  *ngFor="let experience of salarie.experiences| paginate: { id:'second',itemsPerPage: 4, currentPage: sp }">
                    <td>{{experience.entreprise}}</td>
                    <td>{{experience.poste}}</td>
                    <td>{{experience.dated| date: 'dd/MM/yyyy à HH:mm'}}</td>
                    <td>{{experience.datedf| date: 'dd/MM/yyyy à HH:mm'}}</td>
                    <td>{{experience.desc}}</td>
                  </tr>
                </table>
                <div class="row">
                  <div class="col col-xs-6">
                    <pagination-controls previousLabel="Précédent" nextLabel="Suivant" (pageChange)="sp = $event" id="second"></pagination-controls>
                  </div>
                </div>
  
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="showPart()==5" class="col-content-affiche tabcontent2" id="com">
  
          <div>
            <div class="card-body">
              <div>
                <div class="title">
                  <h4>Langues</h4>
                </div>
                <div class="row ligne-table2  d-flex justify-content-center">
                  <table class="table-responsive" *ngIf="salarie.languages">
                    <tr>
                      <td class="col-12 p-l-0">
                        Les langues
                      </td>
                    </tr>
                    <tr  *ngFor="let langue of salarie.languages| paginate: {id:'third', itemsPerPage: 4, currentPage: ddd }">
                      <td >{{langue}}</td>
                    </tr>
                  </table>
                  <div class="row">
                    <div class="col col-xs-6">
                      <pagination-controls previousLabel="Précédent" nextLabel="Suivant" (pageChange)="ddd = $event" id="third"></pagination-controls>
                    </div>
                  </div>
  
                </div>
              </div>
              <div style="align-items: center; padding-top: 50px;">
                <div class="title">
                  <h4>Les compétences</h4>
                </div>
                <div class="row ligne-table2  d-flex justify-content-center">
                  <table class="table-responsive">
                    <tr>
                      <td class="col-12 p-l-0">
                        Compétences
                      </td>
                    </tr>
                    <tr  *ngFor="let competence of salarie.competences| paginate: { itemsPerPage: 4, currentPage: pc }">
                      <td>{{competence}}</td>
                    </tr>
                  </table>
                  <div class="row">
                    <div class="col col-xs-6">
                      <pagination-controls previousLabel="Précédent" nextLabel="Suivant" (pageChange)="pc = $event" #api></pagination-controls>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="showPart()==6" class="col-content-affiche tabcontent2" id="coordonnees_bancaires" style="padding-top: 2%">
          <div>
            <div class="card-body">
  
              <div class="row">
                <div class="col-6">
                  <div class="title" *ngIf="salarie.informationbancaire">
                    <h4>Nom</h4>
                    <span class="font-weight-bold">{{salarie.informationbancaire.nom}} </span>
                  </div>
  
                  <br />
                  <div class="title " *ngIf="salarie.informationbancaire">
                    <h4>Swift</h4>
                    <span class="font-weight-bold">{{salarie.informationbancaire.swift}} </span>
                  </div>
                  <br />
  
                  <div class="title" *ngIf="salarie.informationbancaire">
                    <h4>Bic</h4>
                    <span class="font-weight-bold">{{salarie.informationbancaire.bic}}</span>
                  </div>
  
                </div>
  
                <div class="col-6">
  
                  <div class="title" *ngIf="salarie.informationbancaire">
                    <h4>Iban</h4>
                    <span class="font-weight-bold">{{salarie.informationbancaire.iban}}</span>
                  </div>
  
                  <br />
                  <div class="title" *ngIf="salarie.informationbancaire">
                    <h4>Rib</h4>
                    <span class="font-weight-bold">{{salarie.informationbancaire.rib}} </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
  