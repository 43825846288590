import { Component, OnInit } from '@angular/core';
import { CatalogueService } from "../../../services/catalogue.service";
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { FournisseurService } from "../../../services/fournisseur.service";
import { Router, ActivatedRoute } from '@angular/router';
import { ValidationService } from '../../../services/validate/validate-error.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
import { UserService } from "../../../services/user.service";

@Component({
  selector: 'app-ajout-catalogue-manuel',
  templateUrl: './ajout-catalogue-manuel.component.html',
  styleUrls: ['../../utilisateur/ajout-utilisateur/ajout-utilisateur.component.scss'],
})
export class AjoutCatalogueManuelComponent implements OnInit {
  form:any;
  fournisseurs:any;
  dateDebut = new Date().toISOString().split('T')[0]
  errorDate = false;
  tarifArticle:any;
  showMyContainer = 1;
  id:any;
  utilisateur:any;
  bigDate = "9999-12-31"
  loading :any;
  article:any;
    constructor(private userService:UserService,private catalogueService: CatalogueService,private formBuilder:FormBuilder,private fournisseurService:FournisseurService,private router:Router) {
      this.form = this.formBuilder.group({
        fournisseur: ['', Validators.required],
        datec: ['', [ Validators.required,ValidationService.dateValidatorr]],
        dateFin: ['', [ Validators.required,ValidationService.dateValidatorr]],
        mqe: ['', [Validators.minLength(2),Validators.maxLength(25)]],
        refCiel: ['', [ Validators.required,Validators.minLength(2),Validators.maxLength(100)]],
        gamme: ['', [Validators.minLength(2),Validators.maxLength(25)]],
        designation25: ['', [Validators.required,Validators.minLength(2),Validators.maxLength(500)]],
        tarif: ['', [Validators.required]],
        qt: ['',],
        hautp: ['', [Validators.minLength(2),Validators.maxLength(25)]],
        largp: ['', [Validators.minLength(2),Validators.maxLength(25)]],
        profp: ['', [Validators.minLength(2),Validators.maxLength(25)]],
        poidsp: ['', [Validators.minLength(2),Validators.maxLength(25)]],
        diam: ['', [Validators.minLength(2),Validators.maxLength(25)]],
        statut: ['', [Validators.minLength(2),Validators.maxLength(25)]],
        typ: ['', [Validators.minLength(2),Validators.maxLength(25)]],
        delaiLiv: ['', [Validators.minLength(2),Validators.maxLength(25)]],
        dateLancement: [''],
        dateArret: [''],
        ean13: ['', [Validators.minLength(2),Validators.maxLength(25)]],
        garantie: ['', [Validators.minLength(2),Validators.maxLength(25)]],
        edi: ['', [Validators.minLength(2),Validators.maxLength(25)]],
        mkt1: ['', [Validators.minLength(2),Validators.maxLength(25)]],
        mktl1: ['', [Validators.minLength(2),Validators.maxLength(25)]],
        mkt2: ['', [Validators.minLength(2),Validators.maxLength(25)]],
        mktl2: ['', [Validators.minLength(2),Validators.maxLength(25)]],
        mkt3: ['', [Validators.minLength(2),Validators.maxLength(25)]],
        mktl3: ['', [Validators.minLength(2),Validators.maxLength(25)]],
        mkt4: ['', [Validators.minLength(2),Validators.maxLength(25)]],
        mktl4: ['', [Validators.minLength(2),Validators.maxLength(25)]],
        mkt5: ['', [Validators.minLength(2),Validators.maxLength(25)]],
        mktl5: ['', [Validators.minLength(2),Validators.maxLength(25)]],
      })
    }
  
    ngOnInit() {
      this.id = localStorage.getItem('idUser')
      this.userService.getUserByID(this.id).subscribe(utilisateur => {
        this.utilisateur = utilisateur;
        if(!this.utilisateur){
          localStorage.removeItem('idUser');
          localStorage.clear();
          this.router.navigate(['/login']);
        }
        if(this.utilisateur.sousRole == 'gr-acces'){
          this.router.navigate(["accueil"]);
          return false;
        }
      },
        err => {
          return false;
        });
      this.getAllFournisseurs()
    }
    getBigDate(): string {
        return this.bigDate
      }
    getAllFournisseurs() {
      this.fournisseurService.getAllUsers().then(data => {
        this.fournisseurs = data;
      },
        err => {
          return false;
        });
    }
    getDateDebut(): string {
      return this.dateDebut
    }
  updatePrinNett(){
  
    if(this.tarifArticle < 0 ||this.tarifArticle > 999999999 || (this.tarifArticle && this.tarifArticle.toString().length >15)){
      this.tarifArticle = ""
    }
  }
    saveCatalogue() {
      if(this.form.value.qt && (this.form.value.qt < 0 || this.form.value.qt > 9999999999999999)){
        return false;
      }
      Swal.fire({
            title: 'VOULEZ-VOUS CONFIRMER?',
            imageUrl: 'assets/images/client-plus.png',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            showCancelButton: true,
            width: 800,
            confirmButtonColor: '#008000',
            cancelButtonColor: '#FFA500',
            confirmButtonText: 'CONFIRMER',
  
            cancelButtonText: 'ANNULER',
          }).then((result) => {
            if (!result.dismiss) {
              this.errorDate = false;
              this.form.value.denomination = this.form.value.fournisseur.denomination
              this.form.value.idFournisseur = this.form.value.fournisseur.id
              if (this.form.value.datec > this.form.value.dateFin) {
                this.errorDate = true;
                return false;
              }
              this.form.value.fournisseur = undefined
              this.catalogueService.saveData(this.form.value)
                .subscribe(resp => {
                  this.router.navigateByUrl('/catalogue');
                  Swal.fire( {
                      imageUrl: 'assets/images/client-plus.png',
                      imageWidth: 100,
                      imageHeight: 100,
                      imageAlt: 'Custom image',
                      width: 800,
                      title: 'BIEN AJOUTÉ',
                      timer: 1500,
                      showConfirmButton: false
                    }
                  );
                }, err => {
                })
  
            }
          } );
    }
    modifPart1() {
      this.showMyContainer = 1;
    }
    modifPart2(){
      this.showMyContainer = 2;
    }
    showPart() {
      return this.showMyContainer;
    }
  }
  