import { Component, OnInit } from '@angular/core';
import * as XLSX from "xlsx";
import { CatalogueService } from "../../../services/catalogue.service";
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from "../../../services/user.service";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'app-catalogue',
  templateUrl: './catalogue.component.html',
  styleUrls: ['../../utilisateur/utilisateur/utilisateur.component.css'],
})
export class CatalogueComponent implements OnInit {
  fileReaded: any;
  totalInventories: any;
  AOA: any;
  myDatta: any;
  listeArtciels: any[] = []
  loading = false;
  sizeLimitOne = 30000000; //30MB
  allowInformations = true;
  allowInformationsComp = false;
  numberData = 25;
  text: any;
  tarifGlobal = 0;
  addedLength = 0;
  listeAddedArtciels: any[] = [];
  errorObjet: any;
  fileToChoose: any;
  arraydesN = ["C", "U", "M"]
  filterArticle: any;
  listeArtcielsss: any;
  lenghtData: any = 0;
  pageNumber = 0
  timeout: any = null;
  params: any;
  id:any;
  utilisateur:any;
  p = 1;
  role:any;
  hasRoleUpdate = false;
  hasRoleDelete = false
  hasRoleAdd = false;
  constructor(private roleService:RoleService,private catalogueService: CatalogueService, private activatedRoute: ActivatedRoute,private userService:UserService,private router:Router) {
    this.params = this.activatedRoute.snapshot.params;
    //this.loading = true;
    this.pageNumber = 0
    this.catalogueService.getAllDataByYearByFournisseurByPage(this.params.id, this.params.datec, this.params.dateFin, this.pageNumber, 10).then(data => {
      if (data) {
        this.myDatta = data
        for (var j = 0; j < this.myDatta.length; j++) {
          this.listeArtciels.push(this.myDatta[j])
        }
      }
      //this.loading = false;
    },
      err => {
        return false;
      });

    this.catalogueService.getAllDataByYearByFournisseurCount(this.params.id, this.params.datec, this.params.dateFin).then(data => {
      this.lenghtData = data
      //this.loading = false;
    },
      err => {
        return false;
      });
  }
  ngOnInit() {
    this.id = localStorage.getItem('idUser')
    this.userService.getUserByID(this.id).subscribe(utilisateur => {
      this.utilisateur = utilisateur;
      if(!this.utilisateur || (this.utilisateur && !this.utilisateur.idRole)){
        localStorage.removeItem('idUser');
        localStorage.clear();
        this.router.navigate(['/login']);
      }
      this.roleService.getRoleById(this.utilisateur.idRole).subscribe(role => {
        this.role = role;
        if (this.role && this.role.privileges && this.role.privileges.length > 0) {
          let founddeleteUtilisateur = this.role.privileges.find(x => x.name == "DELETE_CATALOGUE")
          let foundupdateUtilisateur = this.role.privileges.find(x => x.name == "UPDATE_CATALOGUE")
          let foundupdateUtilisateuradd = this.role.privileges.find(x => x.name == "ADD_CATALOGUE")
          if (founddeleteUtilisateur) {
            this.hasRoleDelete = true;
          }
          if (foundupdateUtilisateur) {
            this.hasRoleUpdate = true;
          }
          if (foundupdateUtilisateuradd) {
            this.hasRoleAdd = true;
          }
        }
      },
        err => {
          return false;
        });
    },
      err => {
        this.router.navigateByUrl('/accueil');
        return false;
      });
  }

  reinitialiseData() {
    this.tarifGlobal = 0;
    if (this.listeArtciels.length < 1) {
      return false;
    }
    if (window.confirm("Voulez vous vraiment réinitialiser le tableau ?")) {
      this.catalogueService.deleteAllData().then(data => {
      });
      this.listeArtciels = []
    }
  }
  removearticle(article) {
    if (!article.id) {
      Swal.fire({
            title: 'VOULEZ-VOUS SUPPRIMER CET ARTICLE?',
            imageUrl: 'assets/images/supp-modal.png',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            showCancelButton: true,
            width: 800,
            confirmButtonColor: '#059e00',
            cancelButtonColor: '#FF9100',
            cancelButtonText: 'ANNULER',
            confirmButtonText: 'VALIDER'
          }).then((result) => {
            if (!result.dismiss) {
              Swal.fire( {
                  imageUrl: 'assets/images/supp-modal.png',
                  imageWidth: 100,
                  imageHeight: 100,
                  imageAlt: 'Custom image',
                  width: 800,
                  title: 'BIEN SUPPRIMÉ',
                  timer: 1500,
                  showConfirmButton: false
                }
              );
              this.listeArtciels.splice(this.listeArtciels.indexOf(article), 1);
              return true;
            }
          } );
    }
      Swal.fire({
            title: 'VOULEZ-VOUS SUPPRIMER CE ARTICLE?',
            imageUrl: 'assets/images/supp-modal.png',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            showCancelButton: true,
            width: 800,
            confirmButtonColor: '#059e00',
            cancelButtonColor: '#FF9100',
            cancelButtonText: 'ANNULER',
            confirmButtonText: 'VALIDER'
          }).then((result) => {
            if (!result.dismiss) {
              Swal.fire( {
                  imageUrl: 'assets/images/supp-modal.png',
                  imageWidth: 100,
                  imageHeight: 100,
                  imageAlt: 'Custom image',
                  width: 800,
                  title: 'BIEN SUPPRIMÉ',
                  timer: 1500,
                  showConfirmButton: false
                }
              );
              this.catalogueService.deleteArticleById(article.id).then(data => {
              });
              this.listeArtciels.splice(this.listeArtciels.indexOf(article), 1);
            }
          } );

  }

  // show and hide informations personnelles
  showInformations() {
    this.allowInformations = true;
    this.allowInformationsComp = false;
  }
  // show and hide informations personnelles
  showInformationsComp() {
    this.allowInformations = false;
    this.allowInformationsComp = true;
  }
  onScroll() {
    setTimeout(() => {    //<<<---    using ()=> syntax
      this.addData();
    }, 1000);

  }

  addData() {
    if (this.listeArtciels && this.lenghtData === this.listeArtciels.length) {
      return false;
    }
    this.pageNumber = this.pageNumber + 1

    this.catalogueService.getAllDataByYearByFournisseurByPage(this.params.id, this.params.datec, this.params.dateFin, this.pageNumber, 10).then(data => {
      if (data) {
        this.myDatta = data
        for (var j = 0; j < this.myDatta.length; j++) {
          this.listeArtciels.push(this.myDatta[j])
        }
      }
      //this.loading = false;
    },
      err => {
        return false;
      });
  }


  valuechange(event) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function() {
      if (event.keyCode != 13) {
        $this.getRechercheResult(event.target.value.toUpperCase())
      }
    }, 1000);
  }

  getRechercheResult(event) {
    this.loading = true;
    if (event && event.length > 0 && event.length < 3) {
      this.loading = false;
      return false;
    }
    this.listeArtciels = [];
    while (this.listeArtciels.length) {
      this.listeArtciels.pop();
    }
    if (!event || event === "") {
      this.pageNumber = 0
      this.catalogueService.getAllDataByYearByFournisseurByPage(this.params.id, this.params.datec, this.params.dateFin, this.pageNumber, 10).then(data => {
        if (data) {
          this.myDatta = data
          for (var j = 0; j < this.myDatta.length; j++) {
            this.listeArtciels.push(this.myDatta[j])
          }
          this.loading = false;
        }
        //this.loading = false;
        this.loading = false;
      },
        err => {
          this.loading = false;
          return false;
        });
      return false;
    }
    this.catalogueService.getAllDataByYearByFournisseurByPageAndRecherche(this.params.id, this.params.datec, this.params.dateFin, event).then(data => {
      if (data) {
        this.myDatta = data
        for (var j = 0; j < this.myDatta.length; j++) {
          this.listeArtciels.push(this.myDatta[j])
        }
        this.loading = false;
      }
      //this.loading = false;
      this.loading = false;
    },
      err => {
        this.loading = false;
        return false;
      });
  }

}
