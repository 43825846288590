<app-navbar></app-navbar>
<br>

<div id="content-nav-page" class="row" *ngIf="chargeAffaire">
    <div class=" col-3 titre-menu">
      <a routerLink="/chargeAffaire" class=" icon-f-left"></a>
      <b class="title-haut">{{chargeAffaire.prenom}} {{chargeAffaire.nom}}<img class="ml-2" src="assets/images/modif-hover.png"></b>
    </div>
    <div class="col-9 sous-menu">
      <b class="sous-menu-active1 ml-4" [routerLink]="['/chargeAffaire']">CHARGÉ D'AFFAIRES</b>
    </div>
  </div>
  <div class="card p-3" *ngIf="chargeAffaire">
    <div class="col d-flex justify-content-start">
      <b class="obligatoir-text">(*) Ce champ est obligatoire</b>
    </div>
    <div class="card-header " style="border: 1px solid green;margin-bottom: 1%;" (click)="modifPart1()">
      <b style="color:grey">SÉLECTIONNER UN CLIENT</b>
    </div>
    <div *ngIf="showPart()==1" class="part">
      <form [formGroup]="formClient"role="form">
      <div class="card-body">
  
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-10" >
            <div class="title">
              <select class="form-control input-sm select-options" formControlName="client" (change)="selectClient()" [(ngModel)]="chargeAffaire.client" name="client" placeholder="Client*" style="width:93%">
                <option [ngValue]="chargeAffaire.client" selected>{{chargeAffaire.client.denomination}}</option>
                      <option *ngFor="let client of clients" [ngValue]="client">{{client.denomination}} </option>
              </select>
              <control-messages [control]="formClient.controls.client"></control-messages>
                <a [routerLink]="['/ajoutClient']" title="Ajouter un nouveau client" [ngStyle]="{'pointer-events': administration ? 'none' : null}" style="float: right;margin-top: -82px;font-size: 20px;"><img src="assets/images/add-cmd.png"></a>
                <p style="color:red;margin-top: 15px;" *ngIf="myClient && myClient.statut === 'Radiée'">Ce client est radié</p>
            </div>
            <br/>
            <div class="title ">
              <select class="form-control input-sm select-options" formControlName="etablissement" (change)="selectEtablissement()"  name="etablissement"   [(ngModel)]="chargeAffaire.client.etablissement" [attr.disabled]="disabled ? '' : null" style="width:93%">
                <option [ngValue]="chargeAffaire.client.etablissement" *ngIf="chargeAffaire.client.etablissement" selected>{{chargeAffaire.client.etablissement.nom}}</option>
                  <option *ngFor="let etablissement of etablissements" [ngValue]="etablissement" >{{etablissement.nom}}</option>
  
                </select>
                <control-messages [control]="formClient.controls.etablissement"></control-messages>
                <div class="form-group col-md-12 col-sm-12" *ngIf="chargeAffaire.client.etablissement" style="text-align:initial">
                  <label for="prenom">Le type et l'adresse d'établissement</label>
                  <li  >Type : {{chargeAffaire.client.etablissement.type}}</li>
                  <li  >Pays : {{chargeAffaire.client.etablissement.adresse.pays}}</li>
                  <li >Ville : {{chargeAffaire.client.etablissement.adresse.ville}}</li>
                  <li >Rue : {{chargeAffaire.client.etablissement.adresse.n}} {{chargeAffaire.client.etablissement.adresse.rue}} {{chargeAffaire.client.etablissement.adresse.codePostal}}</li>
                  <li >Statut : {{chargeAffaire.client.etablissement.statutEtab}} <span *ngIf="chargeAffaire.client.etablissement.statutEtab === '' || !chargeAffaire.client.etablissement.statutEtab">Active</span></li>
                  <br>
                </div>
  
            </div>
            <br/>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-10">
            <br>
            <div class="title" style="margin-top: -18px;">
              <input class="font-weight-bold"  placeholder="Email du CA*" disabled value="{{chargeAffaire.mail}}" [(ngModel)]="chargeAffaire.mail" formControlName="mail"  name="mail" *ngIf="!roleAdministration">
              <input class="font-weight-bold"  placeholder="Email du CA*" formControlName="mail"  [(ngModel)]="chargeAffaire.mail" name="mail" value="{{chargeAffaire.mail}}" *ngIf="roleAdministration">
            </div>
            <br/>
          </div>
        </div>
      </div>
      <div class="row">
        <p *ngIf="errorInformationsComp" style="color: red;position: relative;left: 80px;">Merci de vérifier le champ Email du CA</p>
      </div>
      <div class=" col d-flex justify-content-center" style="margin: 1%;margin-top: -2%">
        <div class="col-auto nbr-dossier " >
          <table>
            <tr>
              <td style="padding-right: 30px;">
                <button class="btn-valider"  (click)="onUpdatechargeAffaire(chargeAffaire)">Enregistrer <img src="assets/images/save.png"></button>
              </td>
              <td>
                <button class="btn-annule" routerLink="/chargeAffaire">Annuler <img src="assets/images/btn-annule.png"></button>
              </td>
            </tr>
  
          </table>
        </div>
  
      </div>
      </form>
    </div>
    <div class="card-header " style="border: 1px solid green;margin-bottom: 1%;" (click)="modifPart2()">
      <b style="color:grey">INFORMATIONS PERSONNELLES</b>
    </div>
    <div *ngIf="showPart()==2" class="part">
      <form  role="form">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-10">
              <div class="title">
                <input class="font-weight-bold" placeholder="Nom*" [(ngModel)]="chargeAffaire.nom" name="chargeAffaire.nom" value="{{chargeAffaire.nom}}">
              </div>
            </div>
            <br>
            <div class="col-lg-4 col-md-6 col-sm-10">
              <div class="title ">
                <input class="font-weight-bold" placeholder="Prénom*" [(ngModel)]="chargeAffaire.prenom" name="chargeAffaire.prenom" value="{{chargeAffaire.prenom}}">
              </div>
            </div>
            <br>
            <div class="col-lg-4 col-md-6 col-sm-10">
              <div class="title" style="margin-top:20px">
                <international-phone-number class="mystyle"  [(ngModel)]="chargeAffaire.tel" name="chargeAffaire.tel" placeholder="N° fixe" [maxlength]="13" [defaultCountry]="'tn'" [locale]="'fr'" [required]="true"></international-phone-number>
                <a id="placeholderTel">N° fixe*</a>
              </div>
            </div>
            <br>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-10">
              <div class="title" style="margin-top:23px">
                <international-phone-number class="mystyle" [(ngModel)]="chargeAffaire.telMobile" name="chargeAffaire.telMobile" placeholder="N° mobile" [maxlength]="13" [defaultCountry]="'tn'" [locale]="'fr'" [required]="true"></international-phone-number>
                <a id="placeholderTel">N° mobile*</a>
              </div>
            </div>
            <br>
            <div class="col-lg-4 col-md-6 col-sm-10">
              <div class="title" style="margin-top:-11px">
                <input type="date" class="font-weight-bold" placeholder="Date de naissance" [max]="getDateNaissance()" [(ngModel)]="chargeAffaire.dateNaissance" name="chargeAffaire.dateNaissance" value="{{chargeAffaire.dateNaissance}}">
              </div>
            </div>
            <br>
            <div class="col-lg-4 col-md-6 col-sm-10">
              <div class="title" style="margin-top: -30px;">
                <select class="form-control input-sm select-options" id="sexe" [(ngModel)]="chargeAffaire.sexe" name="chargeAffaire.sexe">
                  <option>Masculin</option>
                  <option>Féminin</option>
                </select>
              </div>
            </div>
            <br>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-10">
              <div class="title ">
                <input class="font-weight-bold" placeholder="Email*" disabled value="{{chargeAffaire.mail}}">
              </div>
            </div>
            <br>
            <div class="col-lg-4 col-md-6 col-sm-10">
              <div class="title" style="margin-top:-33px">
                <select class="form-control input-sm select-options" id="nationalite" [(ngModel)]="chargeAffaire.nationalite" name="chargeAffaire.nationalite"  style="width: 100%;">
               <option *ngFor="let py of pys">{{py.name}}</option>
             </select>
  
              </div>
            </div>
            <br>
            <div class="col-lg-4 col-md-6 col-sm-10">
              <div class="title" style="margin-top:-33px">
                <select class="form-control input-sm select-options" id="identite" [(ngModel)]="chargeAffaire.typeIdentite" name="chargeAffaire.typeIdentite"  >
                <option>CIN</option>
                <option>Passeport</option>
              </select>
  
              </div>
            </div>
            <br>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-10">
              <div class="title">
                <input class="font-weight-bold" id="numIdentite" [(ngModel)]="chargeAffaire.numIdentite" name="chargeAffaire.numIdentite" value="{{chargeAffaire.numIdentite}}" placeholder="Numéro de pièce d'identité*">
              </div>
            </div>
            <br>
          </div>
          <div class="">
            <div class="row">
              <div class="col-lg-4 col-md-6 col-sm-10">
                <div class="title">
                  <select class="form-control input-sm select-options" id="pays" style="width: 102%;" [(ngModel)]="chargeAffaire.adresse.pays" name="chargeAffaire.adresse.pays" style="width: 100%;">
                    <option *ngFor="let py of pys">{{py.name}}</option>
                  </select>
  
                </div>
              </div>
              <br>
              <div class="col-lg-4 col-md-6 col-sm-10">
                <div class="title" style="margin-top:6px">
                  <input class="font-weight-bold" type="number" min="0" [(ngModel)]="chargeAffaire.adresse.codePostal" name="chargeAffaire.adresse.codePostal" value="{{chargeAffaire.adresse.codePostal}}" placeholder="Code postal">
                </div>
              </div>
              <br>
              <div class="col-lg-4 col-md-6 col-sm-10">
                <div class="title" style="margin-top:6px">
                  <input class="font-weight-bold" [(ngModel)]="chargeAffaire.adresse.rue" name="chargeAffaire.adresse.rue" value="{{chargeAffaire.adresse.rue}}" placeholder="Rue">
                </div>
              </div>
              <br>
            </div>
            <div class="row">
              <div class="col-lg-4 col-md-6 col-sm-10">
                <div class="title" style="margin-top:-30px">
                  <input class="font-weight-bold" type="number" min="0" [(ngModel)]="chargeAffaire.adresse.n" name="chargeAffaire.adresse.n" value="{{chargeAffaire.adresse.n}}" placeholder="Numéro">
                </div>
              </div>
              <br>
              <div class="col-lg-4 col-md-6 col-sm-10">
                <div class="title" style="margin-top:-49px">
                  <select class="form-control input-sm select-options" id="etat" [(ngModel)]="chargeAffaire.adresse.ville" name="chargeAffaire.adresse.ville" value="{{chargeAffaire.adresse.ville}}">
                    <option value="chargeAffaire.adresse.ville" selected>{{chargeAffaire.adresse.ville}}</option>
                    <option *ngFor="let ville of vlls">{{ville.ville}}</option>
                  </select>
                </div>
              </div>
              <br>
            </div>
          </div>
          <div class="" >
            <div class="row" >
              <div class="col-lg-4 col-md-6 col-sm-10">
                <div class="title" style="margin-top:-12px">
                  <input class="font-weight-bold" [(ngModel)]="chargeAffaire.poste.titre"  name="chargeAffaire.poste.titre" value="{{chargeAffaire.poste.titre}}"  placeholder="Titre">
                </div>
              </div>
              <br>
              <div class="col-lg-4 col-md-6 col-sm-10">
                <div class="title" style="margin-top:-12px">
                  <input class="font-weight-bold" [(ngModel)]="chargeAffaire.poste.departement" name="chargeAffaire.poste.departement" value="{{chargeAffaire.poste.departement}}" placeholder="Département">
                </div>
              </div>
              <br>
              <div class="col-lg-4 col-md-6 col-sm-10">
                <div class="title" style="margin-top:-12px">
                  <input class="font-weight-bold" [(ngModel)]="chargeAffaire.poste.bureau" name="chargeAffaire.poste.bureau" value="{{chargeAffaire.poste.bureau}}" placeholder="Bureau">
                </div>
              </div>
              <br>
            </div>
            <div class="row">
              <div class="col-lg-4 col-md-6 col-sm-10">
                <div class="title" style="margin-top:-12px">
                  <input class="font-weight-bold" type="date" [(ngModel)]="chargeAffaire.poste.dated" [max]="getdateFinPoste()" name="chargeAffaire.poste.dated" value="{{chargeAffaire.poste.dated}}" placeholder="Date de début">
                </div>
              </div>
              <br>
              <div class="col-lg-4 col-md-6 col-sm-10">
                <div class="title" style="margin-top:-12px">
                  <input class="font-weight-bold" type="date" [(ngModel)]="chargeAffaire.poste.datef" name="chargeAffaire.poste.datef" value="{{chargeAffaire.poste.datef}}" placeholder="Date de fin" [max]="getBigDate()">
                </div>
              </div>
              <br>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-10">
              <div class="title" style="margin-top:-12px">
                <input class="font-weight-bold" [(ngModel)]="chargeAffaire.linkedin" name="chargeAffaire.linkedin" value="{{chargeAffaire.linkedin}}" placeholder="LinkedIn">
              </div>
            </div>
            <br>
          </div>
        </div>
        <div class=" col d-flex justify-content-center" style="margin: 1%;margin-top: -2%">
          <div class="col-auto nbr-dossier " >
            <table>
              <tr>
                <td style="padding-right: 30px;">
                  <button class="btn-valider"  (click)="onUpdatechargeAffaire(chargeAffaire)">Enregistrer <img src="assets/images/save.png"></button>
                </td>
                <td>
                  <button class="btn-annule" routerLink="/chargeAffaire">Annuler <img src="assets/images/btn-annule.png"></button>
                </td>
              </tr>
            </table>
          </div>
  
        </div>
      </form>
    </div>
  
    <div class="card-header " style="border: 1px solid green;margin-bottom: 1%;" (click)="modifPart6()">
      <b style="color:grey">INFORMATIONS COMPLÉMENTAIRES</b>
    </div>
    <div *ngIf="showPart()==6">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-10">
            <div class="title ">
              <select class="form-control input-sm select-options" [(ngModel)]="chargeAffaire.situationFamiliale" name="chargeAffaire.situationFamiliale"  (change)="showOthersSituation()" placeholder="Situation familiale*">
                <option>Célibataire</option>
                <option>Marié(e)</option>
                <option>Divorcé(e)</option>
                <option>Veuf(ve)</option>
              </select>
            </div>
            <br />
          </div>
          <br>
        </div>
        <div class="row" *ngIf="addConjointAndEnfants">
          <form  style="width:100%" role="form">
            <div class="col-lg-4 col-md-6 col-sm-10">
              <div class="title">
                <input class="font-weight-bold" [(ngModel)]="chargeAffaire.conjoint.nom" name="chargeAffaire.conjoint.nom" value="{{chargeAffaire.conjoint.nom}}" placeholder="Nom du conjoint" style="color: #1b1e21">
              </div>
              <br />
              <div class="title">
                <input class="font-weight-bold" [(ngModel)]="chargeAffaire.conjoint.prenom" name="chargeAffaire.conjoint.prenom" value="{{chargeAffaire.conjoint.prenom}}" placeholder="Prénom du conjoint" style="color: #1b1e21">
              </div>
              <br />
              <div class="title">
                <input class="font-weight-bold" [(ngModel)]="chargeAffaire.conjoint.mail" name="chargeAffaire.conjoint.mail" value="{{chargeAffaire.conjoint.mail}}" placeholder="Email du conjoint" style="color: #1b1e21">
              </div>
              <br>
            </div>
            <br>
            <div class="col-lg-4 col-md-6 col-sm-10">
              <div class="title" style="margin-top:3px">
                <international-phone-number class="mystyle" [(ngModel)]="chargeAffaire.conjoint.tel" name="chargeAffaire.conjoint.tel" placeholder="N° mobile" [maxlength]="13" [defaultCountry]="'tn'" [locale]="'fr'" [required]="true"></international-phone-number>
                <a id="placeholderTel">N° mobile*</a>
              </div>
              <div class="title" style="margin-top:15px">
                <select class="form-control input-sm select-options" id="nationalite" placeholder="Nationalité" [(ngModel)]="chargeAffaire.conjoint.nationalite" name="chargeAffaire.conjoint.nationalite" style="width: 100%;">
                    <option *ngFor="let py of pys">{{py.name}}</option>
                  </select>
              </div>
              <br />
              <div class="title">
                <input class="font-weight-bold" placeholder="Organisme de santé du conjoint" [(ngModel)]="chargeAffaire.conjoint.organismeDeSante" name="chargeAffaire.conjoint.organismeDeSante" value="{{chargeAffaire.conjoint.organismeDeSante}}" style="color: #1b1e21">
              </div>
            </div>
            <br>
            <div class="col-lg-4 col-md-6 col-sm-10">
  
            </div>
            <br>
          </form>
        </div>
      </div>
      <div class=" col d-flex justify-content-center" style="padding-bottom: 1%">
        <div class="col-auto nbr-dossier ">
          <table>
            <tr>
              <td style="padding-right: 30px;">
                <button class="btn-valider"  (click)="onUpdatechargeAffaire(chargeAffaire)">Enregistrer <img src="assets/images/save.png"></button>
              </td>
              <td>
                <button class="btn-annule" routerLink="/chargeAffaire">Annuler <img src="assets/images/btn-annule.png"></button>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  
    <div class="card-header " style="border: 1px solid green;margin-bottom: 1%;" (click)="modifPart3()">
      <b style="color:grey">PARCOURS ACADÉMIQUES</b>
    </div>
    <div *ngIf="showPart()==3" class="part">
      <div class="row"  style="margin-bottom: 0">
        <div class="col-12" style="padding-bottom: 2%;margin-top: -2%">
          <div>
            <div>
              <div style="margin-top: 30px">
                <div class="row ">
                  <table class="table-ligne table-responsive">
                    <tr>
                      <th class="text-center">NOM D'ÉTABLISSEMENT</th>
                      <th class="text-center">NOM DU DIPLOME </th>
                      <th class="text-center">TYPE DU DIPLOME</th>
                      <th class="text-center">DATE D'OBTENTION </th>
                      <th class="text-center">DESCRIPTION </th>
                      <th class="text-center ">ACTIONS</th>
  
  
                    </tr>
                    <tr class="text-center" *ngFor="let parcour of chargeAffaire.parcours| paginate: { id:'second',itemsPerPage: 8, currentPage: secondp }">
                      <td>{{parcour.etablissement}}</td>
                      <td>{{parcour.diplomen}}</td>
                      <td>{{parcour.diplomet}}</td>
                      <td>{{parcour.date | date : 'dd/MM/yyyy' }}</td>
                      <td>{{parcour.desc}}</td>
                      <td>
                        <a (click)="removeParcours(parcour)" title="Supprimer"><img src="assets/images/supp-modal.png" class="delete-img" alt=""></a>
                      </td>
                    </tr>
  
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td><a href="#" title="Ajouter" data-toggle="modal" data-target="#myModal"><img src="assets/images/add-cmd.png"></a>
                      </td>
  
                    </tr>
  
                  </table>
                  <div class="row">
                    <div class="col col-xs-6">
                      <pagination-controls previousLabel="Précédent" nextLabel="Suivant" (pageChange)="secondp = $event" id="second"></pagination-controls>
                    </div>
                  </div>
                </div>
  
              </div>
            </div></div>
        </div>
      </div>
      <div class=" col d-flex justify-content-center" style="margin: 1%;margin-top: -2%">
        <div class="col-auto nbr-dossier " >
          <table>
            <tr>
              <td style="padding-right: 30px;">
                <button class="btn-valider"  (click)="onUpdatechargeAffaire(chargeAffaire)">Enregistrer <img src="assets/images/save.png"></button>
              </td>
              <td>
                <button class="btn-annule" routerLink="/chargeAffaire">Annuler <img src="assets/images/btn-annule.png"></button>
              </td>
            </tr>
          </table>
        </div>
  
      </div>
  
    </div>
  
    <div class="card-header " style="border: 1px solid green;margin-bottom: 1%;" (click)="modifPart4()">
      <b style="color:grey">ÉXPERIENCES PROFESSIONNELLES</b>
    </div>
  
    <div *ngIf="showPart()==4" class="part">
      <div class="row"  style="margin-bottom: 0">
        <div class="col-12" style="padding-bottom: 2%;margin-top: -2%">
          <div>
            <div>
              <div style="margin-top: 30px">
                <div class="row ">
                  <table class="table-ligne table-responsive">
                    <tr>
                      <th class="text-center">NOM DE L'ENTREPRISE</th>
                      <th class="text-center">NOM DU POSTE </th>
                      <th class="text-center">DATE DE DEBUT</th>
                      <th class="text-center">DATE DE FIN </th>
                      <th class="text-center">DESCRIPTION </th>
                      <th class="text-center ">ACTIONS</th>
                    </tr>
                    <tr class="text-center" *ngFor="let experience of chargeAffaire.experiences| paginate: { id:'third',itemsPerPage: 8, currentPage: thirdp }">
                      <td>{{experience.entreprise}}</td>
                      <td>{{experience.poste}}</td>
                      <td>{{experience.dated | date : 'dd/MM/yyyy' }}</td>
                      <td>{{experience.datef | date : 'dd/MM/yyyy' }}</td>
                      <td>{{experience.desc}}</td>
                      <td>
                        <a (click)="removeExperience(experience)" title="Supprimer"><img src="assets/images/supp-modal.png" class="delete-img"></a>
                      </td>
                    </tr>
  
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td><a href="#" title="Ajouter" data-toggle="modal" data-target="#myModal2"><img src="assets/images/add-cmd.png"></a>
                      </td>
                    </tr>
                  </table>
                  <div class="row">
                    <div class="col col-xs-6">
                      <pagination-controls previousLabel="Précédent" nextLabel="Suivant" (pageChange)="thirdp = $event" id="third"></pagination-controls>
                    </div>
                  </div>
                </div>
  
              </div>
            </div></div>
        </div>
      </div>
  
      <div class=" col d-flex justify-content-center" style="margin: 1%;margin-top: -2%">
        <div class="col-auto nbr-dossier " >
          <table>
            <tr>
              <td style="padding-right: 30px;">
                <button class="btn-valider"  (click)="onUpdatechargeAffaire(chargeAffaire)">Enregistrer <img src="assets/images/save.png"></button>
              </td>
              <td>
                <button class="btn-annule" routerLink="/chargeAffaire">Annuler <img src="assets/images/btn-annule.png"></button>
              </td>
            </tr>
          </table>
        </div>
  
      </div>
  
    </div>
    <div class="card-header " style="border: 1px solid green;margin-bottom: 1%;" (click)="modifPart5()">
      <b style="color:grey">LANGUES ET COMPÉTENCES</b>
    </div>
    <div *ngIf="showPart()==5" class="part">
      <div class="col-12">
  
  
        <div style="padding-top: 2%">
          <div>
            <div class="title">
              <h4>Les langues</h4>
  
            </div>
            <div class="row"  style="margin-bottom: 0">
              <div class="col-12" style="padding-bottom: 2%;margin-top: 3%">
                <div>
                  <div>
                    <div style="margin-top: 30px">
                      <div class="row ">
                        <table class="table-ligne table-responsive">
                          <tr>
                            <th class="text-center">LANGUE</th>
                            <th class="text-center ">ACTIONS</th>
                          </tr>
                          <tr class="text-center" *ngFor="let langue of chargeAffaire.languages| paginate: { itemsPerPage: 8, currentPage: p }">
                            <td style="text-transform: capitalize;">{{langue}}</td>
                            <td>
                              <a (click)="removeLangue(langue)" title="Supprimer"><img src="assets/images/supp-modal.png" class="delete-img"></a>
                            </td>
                          </tr>
  
                          <tr>
                            <td></td>
                            <td><a href="#" title="Ajouter" (click)="resetErrorLangue()" data-toggle="modal" data-target="#myModal3"><img src="assets/images/add-cmd.png"></a>
                            </td>
  
                          </tr>
  
                        </table>
                      </div>
  
                    </div>
                  </div></div>
              </div>
            </div>
  
          </div>
  
        </div>
  
        <div >
  
          <div style="align-items: center; padding-top: 50px;">
            <div class="title">
              <h4 >Les compétences</h4>
            </div>
            <div class="row"  style="margin-bottom: 0">
              <div class="col-12" style="padding-bottom: 2%;margin-top: 3%">
                <div>
                  <div>
                    <div style="margin-top: 30px">
                      <div class="row ">
                        <table class="table-ligne table-responsive">
                          <tr>
                            <th class="text-center">COMPETENCE</th>
                            <th class="text-center ">ACTIONS</th>
                          </tr>
                          <tr class="text-center" *ngFor="let competence of chargeAffaire.competences| paginate: { itemsPerPage: 8, currentPage: p }">
                            <td>{{competence}}</td>
                            <td>
                              <a (click)="removeCompetence(competence)" title="Supprimer"><img src="assets/images/supp-modal.png" class="delete-img" alt=""></a>
                            </td>
                          </tr>
  
                          <tr>
                            <td></td>
                            <td><a href="#" title="Ajouter" (click)="resetErrorCompetence()" data-toggle="modal" data-target="#myModal4"><img src="assets/images/add-cmd.png"></a>
                            </td>
  
                          </tr>
  
                        </table>
                      </div>
  
                    </div>
                  </div></div>
              </div>
            </div>
          </div>
  
        </div>
      </div>
  
  
      <div class=" col d-flex justify-content-center" style="margin: 1%;margin-top: -2%">
        <div class="col-auto nbr-dossier " >
          <table>
            <tr>
              <td style="padding-right: 30px;">
                <button class="btn-valider"  (click)="onUpdatechargeAffaire(chargeAffaire)">Enregistrer <img src="assets/images/save.png"></button>
              </td>
              <td>
                <button class="btn-annule" routerLink="/chargeAffaire">Annuler <img src="assets/images/btn-annule.png"></button>
              </td>
            </tr>
          </table>
        </div>
  
      </div>
  
    </div>
  </div>
  
  <!-- --------------------------modal scan ----------------------- -->
  
  
  <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModal" aria-hidden="true" style="overflow-y: hidden;">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 1000px">
      <div class="modal-content modal-content-scan" style="width: 200%; top: -170px">
        <div class="modal-header d-flex justify-content-center">
          <h3 class="titre-modal-statut">
            NOUVEAU PARCOURS ACADÉMIQUE
          </h3>
        </div>
        <div class="modal-body">
          <div class="col d-flex justify-content-start">
            <b class="obligatoir-text">(*) Ce champ est obligatoire</b>
          </div>
          <form [formGroup]="formParcours">
            <div class="row" style="margin-top: 25px;">
  
              <div class="col-lg-6 col-md-6 col-sm-10">
                <div class="title">
                  <input class="font-weight-bold" formControlName="etablissement" name="etablissement" placeholder="Nom d'établissement*">
                  <control-messages [control]="formParcours.controls.etablissement"></control-messages>
                </div>
  
                <br />
                <div class="title">
                  <input class="font-weight-bold" formControlName="diplomen" name="diplomen" placeholder="Nom du diplôme*">
                  <control-messages [control]="formParcours.controls.diplomen"></control-messages>
                </div>
                <br />
                <div class="title" style="margin-top: -45px;">
                  <select class="form-control input-sm select-options" id="pays" formControlName="diplomet" name="diplomet" placeholder="Type du diplôme*">
                    <option>Ouvrier</option>
                    <option> Technicien</option>
                    <option>Technicien Supérieur</option>
                    <option>Master</option>
                    <option>Ingénieur</option>
                    <option>Docteur</option>
                  </select>
                  <control-messages [control]="formParcours.controls.diplomet"></control-messages>
                </div>
              </div>
              <br>
              <div class="col-lg-6 col-md-6 col-sm-10" style="margin-top: -2px">
                <div class="title">
                  <input class="font-weight-bold" type="date" formControlName="date" name="date" placeholder="Date d'obtention*" [max]="getBigDate()">
                  <control-messages [control]="formParcours.controls.date"></control-messages>
                </div>
                <br />
                <div class="title">
                  <input class="font-weight-bold" formControlName="desc" name="desc" placeholder="Description">
                </div>
              </div>
              <br>
            </div>
          </form>
          <div class="modal-footer d-flex justify-content-center">
            <div class=" col d-flex justify-content-center" style="padding-bottom: 1%; margin-bottom: 20px">
              <div class="col-auto nbr-dossier ">
                <table>
                  <tr>
                    <td style="padding-right: 30px;">
                      <button class="btn-valider" style="width: 200px;height: 50px;" data-dismiss="modal" (click)="addnewParcours()" [disabled]="!formParcours.valid">Ajouter <img src="assets/images/save.png"></button>
                    </td>
                    <td style="padding-right: 30px;">
                      <button class="btn-annule" style="width: 200px;height: 50px;" data-dismiss="modal">Annuler <img src="assets/images/btn-annule.png"> </button>
  
                    </td>
  
                  </tr>
  
                </table>
              </div>
  
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  
  <!-- --------------------------modal scan ----------------------- -->
  <div class="modal fade" id="myModal2" tabindex="-1" role="dialog" aria-labelledby="myModal2" aria-hidden="true" style="overflow-y: hidden;">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 1000px">
      <div class="modal-content modal-content-scan" style="width: 200%; top: -170px">
        <div class="modal-header d-flex justify-content-center">
          <h3 class="titre-modal-statut">
            NOUVELLE EXPÉRIENCE PROFESSIONNELLE
          </h3>
        </div>
        <div class="modal-body">
          <div class="col d-flex justify-content-start">
            <b class="obligatoir-text">(*) Ce champ est obligatoire</b>
          </div>
          <form [formGroup]="formExperience">
            <div class="row" style="margin-top: 25px;">
              <div class="col-lg-6 col-md-6 col-sm-10">
                <div class="title">
                  <input class="font-weight-bold" formControlName="entreprise" name="entreprise" placeholder="Nom de l'entreprise*">
                  <control-messages [control]="formExperience.controls.entreprise"></control-messages>
                </div>
  
                <br />
                <div class="title">
                  <input class="font-weight-bold" formControlName="poste" name="poste" placeholder="Nom du poste*">
                  <control-messages [control]="formExperience.controls.poste"></control-messages>
                </div>
  
                <br />
  
                <div class="title">
                  <input class="font-weight-bold" formControlName="dated" name="dated" type="date" placeholder="Date de début*" [max]="getBigDate()">
                  <control-messages [control]="formExperience.controls.dated"></control-messages>
                  <p style="color:red" *ngIf="errorDates">Vérifiez les dates !</p>
  
                </div>
              </div>
              <br>
              <div class="col-lg-6 col-md-6 col-sm-10" style="margin-top: -2px">
                <div class="title">
                  <input class="font-weight-bold" formControlName="datef" name="datef" type="date" placeholder="Date de fin*" [max]="getBigDate()">
                  <control-messages [control]="formExperience.controls.datef"></control-messages>
                </div>
                <br />
                <div class="title">
                  <input class="font-weight-bold" formControlName="desc" name="desc" placeholder="Description">
                </div>
              </div>
              <br>
            </div>
          </form>
          <div class="modal-footer d-flex justify-content-center">
            <div class=" col d-flex justify-content-center" style="padding-bottom: 1%; margin-bottom: 20px">
              <div class="col-auto nbr-dossier ">
                <table>
                  <tr>
                    <td style="padding-right: 30px;">
                      <button class="btn-valider" style="width: 200px;height: 50px;"  (click)="addnewExperience()">Ajouter <img src="assets/images/save.png"></button>
                    </td>
                    <td style="padding-right: 30px;">
                      <button class="btn-annule" style="width: 200px;height: 50px;" data-dismiss="modal">Annuler <img src="assets/images/btn-annule.png"> </button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- --------------------------modal scan ----------------------- -->
  
  
  <div class="modal fade" id="myModal3" tabindex="-1" role="dialog" aria-labelledby="myModal3" aria-hidden="true" style="overflow-y: hidden;">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content modal-content-scan" style="width: 200%; top: -170px">
        <div class="modal-header d-flex justify-content-center">
          <h3 class="titre-modal-statut">
            NOUVELLE LANGUE
          </h3>
        </div>
        <div class="modal-body">
          <div class="col d-flex justify-content-start">
            <b class="obligatoir-text">(*) Ce champ est obligatoire</b>
          </div>
          <div class="row" style="margin-top: 25px;">
            <p *ngIf="errorLangue" style="color:red;margin-left: 60px;">{{errorLangue}}</p>
            <div class="col-lg-8 col-md-8 col-sm-8" style="margin-right: 30%">
              <select class="selectpicker select-statut-detail " [(ngModel)]="langue" style="text-transform: capitalize;width: 100%;margin: 57px 25%;" name="langue" (change)="addLangues($event.target.value)">
                <option *ngFor="let language of languages" style="text-transform: capitalize;">{{language.French}}</option>
              </select>
            </div>
            <div class="col-lg-8 col-md-8 col-sm-8" style="margin-right: 30%;margin-bottom: 15px;">
              <li *ngFor="let onlangue of languesChosed" style="text-transform: capitalize;">{{onlangue}}</li>
            </div>
            <br>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <div class=" col d-flex justify-content-center" style="padding-bottom: 1%; margin-bottom: 20px">
              <div class="col-auto nbr-dossier ">
                <table>
                  <tr>
                    <td style="padding-right: 30px;">
                      <button class="btn-valider" style="width: 200px;height: 50px;" (click)="saveChoosedLangues()" data-dismiss="modal">Ajouter <img src="assets/images/save.png"></button>
                    </td>
                    <td style="padding-right: 30px;">
                      <button class="btn-annule" style="width: 200px;height: 50px;" data-dismiss="modal">Annuler <img src="assets/images/btn-annule.png"> </button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- --------------------------modal scan COMPÉTENCE ----------------------- -->
  <div class="modal fade" id="myModal4" tabindex="-1" role="dialog" aria-labelledby="myModal4" aria-hidden="true" style="overflow-y: hidden;">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content modal-content-scan" style="width: 200%; top: -170px">
        <div class="modal-header d-flex justify-content-center">
          <h3 class="titre-modal-statut">
            NOUVELLE COMPÉTENCE
          </h3>
        </div>
        <div class="modal-body">
          <div class="col d-flex justify-content-start">
            <b class="obligatoir-text">(*) Ce champ est obligatoire</b>
          </div>
          <div class="row" style="margin-top: 25px;">
            <p *ngIf="errorCompetence" style="color:red;margin-left: 60px;">{{errorCompetence}}</p>
            <div class="col-lg-8 col-md-8 col-sm-8" style="margin-right: 30%">
              <select class="selectpicker select-statut-detail " [(ngModel)]="competence" name="competence" (change)="addCompetences($event.target.value)">
                <option>Adaptation au changement</option>
                <option>Analyse</option>
                <option>Collaboration</option>
                <option>Communication</option>
                <option>Confiance en soi</option>
                <option>Curiosité intellectuelle</option>
                <option>Ecoute</option>
                <option>Esprit de synthèse</option>
                <option>Gestion du stress</option>
                <option> Influence</option>
                <option>Jugement</option>
                <option>Mobilisation</option>
                <option>Négociation</option>
                <option>Organisation du travail</option>
                <option>Rigueur</option>
                <option>Travail d'équipe</option>
              </select>
            </div>
            <div class="col-lg-8 col-md-8 col-sm-8" style="margin-right: 30%;margin-bottom: 15px;">
              <li *ngFor="let oncompetence of competencesChosed" style="text-transform: capitalize;">{{oncompetence}}</li>
            </div>
            <br>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <div class=" col d-flex justify-content-center" style="padding-bottom: 1%; margin-bottom: 20px">
              <div class="col-auto nbr-dossier ">
                <table>
                  <tr>
                    <td style="padding-right: 30px;">
                      <button class="btn-valider" style="width: 200px;height: 50px;" (click)="saveChoosedCompetence()" data-dismiss="modal">Ajouter <img src="assets/images/save.png"></button>
                    </td>
                    <td style="padding-right: 30px;">
                      <button class="btn-annule" style="width: 200px;height: 50px;" data-dismiss="modal">Annuler <img src="assets/images/btn-annule.png"> </button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  