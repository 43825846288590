import { Component, OnInit } from '@angular/core';
import * as XLSX from "xlsx";
import { InventaireService } from "../../../services/inventaire.service";
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { FournisseurService } from "../../../services/fournisseur.service";
import { Router, ActivatedRoute } from '@angular/router';
import { isNumeric } from 'rxjs/util/isNumeric';
import { CatalogueService } from "../../../services/catalogue.service";

@Component({
  selector: 'app-ajout-inventaire-bycatalogue-year',
  templateUrl: './ajout-inventaire-bycatalogue-year.component.html',
  styleUrls: ['../../utilisateur/utilisateur/utilisateur.component.css'],
})
export class AjoutInventaireBycatalogueYearComponent implements OnInit {
  fileReaded: any;
  totalInventories: any;
  AOA: any;
  listeArtciels: any[]=[]
  listeAllArtciels:any;
  loading = false;
  sizeLimitOne = 30000000; //30MB
  allowInformations = true;
  allowInformationsComp = false;
  numberData = 10;
  text: any;
  tarifGlobal = 0;
  addedLength = 0;
  errorObjet: any;
  fileToChoose: any;
  arraydesN = ["C", "U", "M"]
  filterArticle:any;
  form:any;
  fournisseurs:any;
  selectedFounisseur:any;
  errorDate = false
  dateDebut = new Date().toISOString().split('T')[0]
  allArticles:any;
  params:any;
  numberErrorLine = 0 ;
  bigDate = "9999-12-31"
  constructor(private catalogueService:CatalogueService,private inventaireService: InventaireService,private formBuilder:FormBuilder,private fournisseurService:FournisseurService,private router:Router,private activatedRoute:ActivatedRoute) {
    this.form = this.formBuilder.group({
      datec: ['', Validators.required],
      file: ['']
    })
  }
  ngOnInit() {
    this.params = this.activatedRoute.snapshot.params;
    if(this.params){
    this.loading = true;
    this.catalogueService.getAllDataByYearByFournisseur(this.params.id,this.params.years).then(data => {
      this.allArticles = data
      this.loading = false;
    },
      err => {
        return false;
      });
     }
  }
  getAllFournisseurs() {


  }
  getBigDate(): string {
      return this.bigDate
    }
  // get data
  onFileChange(file: any) {
    // Vider le tableau et réinitialiser l'affichage
    /* wire up file reader */

    var myfile = file.target.files.item(0)
    if (myfile) {
      this.fileToChoose = undefined
      this.listeArtciels = []
      this.tarifGlobal = 0;
      this.addedLength = 0;
      this.errorObjet = " "
      if (!myfile.type.match('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet|application/vnd.ms-excel')) {
        file = undefined
        alert("Impossible de télécharger ce fichier.")
        return false;
      }
      if (myfile.size > this.sizeLimitOne) {
        file = undefined
        alert("Un fichier sélectionné est trop volumineux.La taille maximale est 30 Mo.")
        return false;
      }
      this.loading = true;
      const target: DataTransfer = <DataTransfer>(file.target);
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        var data = (XLSX.utils.sheet_to_json(ws, { header: 1 }));
        for (var i = 1; i < data.length; i++) {
          var object = {
            "refArt": data[i][0], "designation": data[i][1], "pritar": data[i][2], "coefAchat": data[i][3], "prinet": data[i][4], "quantite": data[i][5], "montant": data[i][4]*data[i][5],
            "datec": this.form.value.datec,"exist":false,"ean13Art":"",
             "idFournisseur":this.params.id
          }
          if (object.refArt && this.allArticles.length > 0) {
            if(!isNumeric(object.pritar) || !isNumeric(object.prinet) || isNaN(object.quantite)){
              this.errorObjet = " Merci de vérifier le prix tarif, le prix net et la quantité de la ligne "+ Number(i + 1 )
              this.tarifGlobal = 0;
              this.listeArtciels = [];
              this.loading = false;
              return false;
            }
            let objectIndex = this.allArticles.findIndex(article => article.refCiel === object.refArt);
            if(objectIndex != -1 ){
              object.exist = true;
              object.ean13Art = this.allArticles[objectIndex].ean13
            }
            if(objectIndex === -1 ){
              this.numberErrorLine++
            }
            this.listeArtciels.push(object)
            this.tarifGlobal = this.tarifGlobal + Number(data[i][2])
          }
        }
        this.addedLength = this.listeArtciels.length
        this.loading = false;
      };
      if (target.files.length > 0) {

        reader.readAsBinaryString(target.files[0]);
      }
    } else {

      return false;
    }

  }


  saveData() {
    this.errorDate = false;
    if (this.loading) {
      alert('L\'upload des données est en cours ! Veuillez patienter')
      return false;
    }
    if ( this.listeArtciels.length < 1) {
      return false;
    }
    if (this.form.value.datec.substring(0, 4) != this.params.years) {
      this.errorDate = true;
      return false;
    }
    this.loading = true;
     this.inventaireService.saveAllData(this.listeArtciels)
      .subscribe(resp => {

      }, err => {
      })
    setTimeout(() => {
      /** spinner ends after 6 seconds **/
      this.loading = false;
      alert('Votre fichier est bien intégré')
      this.router.navigate(['/inventaire', this.params.years,this.params.id]);
    }, 20000);
    this.listeArtciels = []
    this.fileToChoose = undefined
  }
getDateDebut(): string {
  return this.dateDebut
}
 isFloat(n){
    return Number(n) === n && n % 1 !== 0;
}
isInt(n){
    return Number(n) === n && n % 1 === 0;
}
}

