<app-navbar></app-navbar>
<br>

<div id="content-nav-page" class="row" *ngIf="client">
    <div class=" col-3 titre-menu">
      <a routerLink="/agence-voyage" class=" icon-f-left"></a>
      <b class="title-haut">{{client.denomination}}<img class="ml-2" src="assets/images/modif-hover.png"></b>
    </div>
    <div class="col-9 sous-menu">
      <b class="sous-menu-active1" [routerLink]="['/agence-voyage']">AGENCES DE VOYAGE</b>
    </div>
  </div>
  <div class="card p-3">
    <div class="col d-flex justify-content-start">
      <b class="obligatoir-text">(*) Ce champ est obligatoire</b>
    </div>
    <div class="card-header " style="border: 1px solid green;margin-bottom: 1%;" (click)="modifPart1()">
      <b style="color:grey">INFORMATIONS AGENCE DE VOYAGE</b>
    </div>
    <div *ngIf="showPart()==1 && client" class="part">
      <form role="form">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-10">
              <div class="title">
                <select class="form-control input-sm select-options" id="type" [(ngModel)]="client.type" name="client.type">
                  <option>Particulier</option>
                  <option>Professionnel</option>
                </select>
              </div>
              <br>
              <div class="title" style="margin-top: -39px;">
                <input class="font-weight-bold" [(ngModel)]="client.denomination" name="client.denomination" value="{{client.denomination}}" placeholder="Dénomination*">
              </div>
              <br />
              <div class="title">
                <input class="font-weight-bold" placeholder="Nom commercial" [(ngModel)]="client.acronyme" name="client.acronyme" value="{{client.acronyme}}">
              </div>
              <br />
              <div class="title">
                <select class="form-control input-sm select-options" id="formeJuridique" placeholder="Forme juridique*" [(ngModel)]="client.formeJuridique" name="client.formeJuridique">
                  <option>Société anonyme SA</option>
                  <option >Société anonyme à directoire et conseil de surveillance</option>
                  <option>Société à responsabilité limitée SARL</option>
                  <option>Société à responsabilité limitée à associé unique SARLAU</option>
                  <option>Société par actions simplifiée SAS</option>
                  <option>Société par actions simplifiée à associé unique SASAU</option>
                  <option>Entreprise unipersonnelle à responsabilité limitée EURL</option>
                  <option>Société coopérative à forme anonyme SCFA</option>
                </select>
  
              </div>
              <br />
              <div class="title" style="margin-top: -30px;">
                <input class="font-weight-bold" type="date" [(ngModel)]="client.datec" [max]="getdatenow()" name="client.datec" value="{{client.datec}}" placeholder="Immatriculé le">
              </div>
              <br />
              <div class="title">
                <select class="form-control input-sm select-options" id="statut" [(ngModel)]="client.statut" name="client.statut" value="{{client.statut}}" (change)="showAndHoideDateRadiationFunc(client.statut)">
                  <option>Active</option>
                  <option>Radiée</option>
                </select>
              </div>
            </div>
            <br>
            <div class="col-lg-6 col-md-6 col-sm-10">
              <div class="title">
                <input class="font-weight-bold" style="margin-top:50px"  placeholder="Identifiant unique*" type="number" min="0" value="{{client.siren}}" disabled>
              </div>
              <br />
              <div class="title ">
                <input class="font-weight-bold" [(ngModel)]="client.nomc" name="client.nomc" value="{{client.nomc}}" placeholder="Acronyme">
              </div>
              <br />
              <div class="title">
                <input class="font-weight-bold" placeholder="Enseigne" [(ngModel)]="client.enseigne" name="client.enseigne" value="{{client.enseigne}}">
              </div>
              <br />
              <div class="title">
                <select class="form-control input-sm select-options" id="codeNaf" [(ngModel)]="client.codeNaf" name="client.codeNaf" (change)="showSociete()">
                  <option [ngValue]="client.codeNaf" selected *ngIf="client.codeNaf">{{client.codeNaf.fields.code_naf}}</option>
                  <option *ngFor="let code of codes" [ngValue]="code">{{code.fields.code_naf}}</option>
                </select>
                <p style="position: relative;top: -42px;margin-bottom: -55px;" *ngIf="secteur">{{secteur}}</p>
              </div>
              <br />
              <div class="title">
                <label for="file"></label>
                <input class="input-btn-file " (change)="selectFiles($event)" type="file" id="file" accept="image/*" placeholder="Logo" style="position: relative;top: -20px;">
                <a id="placeholderTel" style="top: -95px;left:100px;">Logo</a>
                <img src="{{client.linkLogo}}" alt="" style="position: relative;top: -35px;width: 3%;cursor: pointer;"> <img src="assets/images/supp-modal.png" class="delete-img" alt="" *ngIf="client.linkLogo" style="position: relative;top: -35px;width: 4%;"
                  title="Supprimer le logo" (click)="deleteLogo(client)">
              </div>
              <br />
              <div class="title" *ngIf="showAndHoideDateRadiation">
                <input class="font-weight-bold" placeholder="Date de radiation" type="date" [max]="getBigDate()"  [(ngModel)]="client.dateRadiation" name="client.dateRadiation" value="{{client.dateRadiation}}" style="margin-top: -50px;">
              </div>
            </div>
            <br>
          </div>
        </div>
        <div class="">
          <p style="color:red;position: relative;left: 50px;top: 40px;" *ngIf="errorSaveFirstPartie">{{errorSaveFirstPartie}}</p>
        </div>
        <div class=" col d-flex justify-content-center">
          <div class="col-auto nbr-dossier ">
            <table>
              <tr>
                <td style="padding-right: 30px;">
                  <button class="btn-valider" (click)="onUpdateClient(client)">Enregistrer <img src="assets/images/save.png"></button>
                </td>
                <td>
                  <button class="btn-annule" routerLink="/agence-voyage">Annuler <img src="assets/images/btn-annule.png"></button>
  
                </td>
              </tr>
            </table>
          </div>
        </div>
        <br><br>
      </form>
    </div>
    <div class="card-header " style="border: 1px solid green;margin-bottom: 1%;" (click)="modifPart2()">
      <b style="color:grey">INFORMATIONS COMPLÉMENTAIRES</b>
    </div>
    <div *ngIf="showPart()==2 && client" class="part">
      <form role="form">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-10">
              <div class="title ">
                <input class="font-weight-bold" [(ngModel)]="client.mail" name="client.mail" value="{{client.mail}}" placeholder="Email*" style="color: #1b1e21">
              </div>
              <br />
              <div class="title" style="margin-top: 2px;">
                <international-phone-number class="mystyle" [(ngModel)]="client.tel" name="client.tel" placeholder="N° fixe*" [maxlength]="13" [defaultCountry]="'tn'" [locale]="'fr'" [required]="true"></international-phone-number>
                <a id="placeholderTel">N° fixe*</a>
              </div>
              <br />
              
            </div>
            <br>
            <div class="col-lg-4 col-md-6 col-sm-10" style="margin-top: -20px;">
              <div class="title">
                <select class="form-control input-sm select-options" id="pays" [(ngModel)]="client.adresse.pays" name="client.adresse.pays" value="{{client.tel}}" style="width: 99%;">
                  <option *ngFor="let py of pys">{{py.name}}</option>
                </select>
              </div>
              <br />
              <div class="title" style="margin-top: -60px;">
                <select class="form-control input-sm select-options" id="ville" [(ngModel)]="client.adresse.ville" name="client.adresse.ville" value="{{client.adresse.ville}}" style="width: 99%;margin-top: 34px;margin-bottom: 31px;">
                  <option value="{{client.adresse.ville}}" selected>{{client.adresse.ville}}</option>
                  <option *ngFor="let ville of vlls" [attr.value]="ville.ville">{{ville.ville}}</option>
                </select>
              </div>
              <br />
              <div class="title">
                <input class="font-weight-bold" type="number" min="0" [(ngModel)]="client.adresse.n" name="client.adresse.n" value="{{client.adresse.n}}" placeholder="Numéro" style="color: #1b1e21">
              </div>
              <br />
              <div class="title">
                <input class="font-weight-bold" placeholder="Rue" [(ngModel)]="client.adresse.rue" name="client.adresse.rue" value="{{client.adresse.rue}}" style="color: #1b1e21">
              </div>
              <br />
              <div class="title">
                <input class="font-weight-bold" placeholder="Code postal" type="number" min="0" [(ngModel)]="client.adresse.codePostal" name="client.adresse.codePostal" value="{{client.adresse.codePostal}}" style="color: #1b1e21">
              </div>
            </div>
            <br>
            <div class="col-lg-4 col-md-6 col-sm-10">
              <div class="title">
                <input class="font-weight-bold" placeholder="Facebook" [(ngModel)]="client.adresseWeb.facebook" name="client.adresseWeb.facebook" value="{{client.adresseWeb.facebook}}" style="color: #1b1e21">
              </div>
              <br />
              <div class="title" style="margin-top: -37px;">
                <input class="font-weight-bold" placeholder="Linkedin" [(ngModel)]="client.adresseWeb.siteWeb" name="client.adresseWeb.siteWeb" value="{{client.adresseWeb.siteWeb}}" style="color: #1b1e21">
              </div>
              <br />
  
              <div class="title">
                <input class="font-weight-bold" [(ngModel)]="client.adresseWeb.infogreffe" name="client.adresseWeb.infogreffe" value="{{client.adresseWeb.infogreffe}}" placeholder="RNE" style="color: #1b1e21">
              </div>
  
              <br />
              <div class="title">
                <input class="font-weight-bold" [(ngModel)]="client.adresseWeb.siteWeb" name="client.adresseWeb.siteWeb" value="{{client.adresseWeb.siteWeb}}" placeholder="Site web" style="color: #1b1e21">
              </div>
  
            </div>
            <br>
          </div>
        </div>
        <div class="">
          <p style="color:red;position: relative;left: 50px;top: 40px;" *ngIf="errorSaveSecondePartie">{{errorSaveSecondePartie}}</p>
        </div>
        <div class=" col d-flex justify-content-center" style="margin: 1%;margin-top: -2%">
          <div class="col-auto nbr-dossier ">
            <table>
              <tr>
                <td style="padding-right: 30px;">
                  <button class="btn-valider" (click)="onUpdateClient(client)">Enregistrer <img src="assets/images/save.png"></button>
  
                </td>
                <td>
                  <button class="btn-annule" routerLink="/agence-voyage">Annuler <img src="assets/images/btn-annule.png"></button>
  
                </td>
              </tr>
            </table>
          </div>
        </div>
      </form>
    </div>
    <div class="card-header " style="border: 1px solid green;margin-bottom: 1%;" (click)="modifPart3()">
      <b style="color:grey">ÉTABLISSEMENTS</b>
    </div>
    <div *ngIf="showPart()==3 && client" class="part">
      <div class="col-12">
        <div class="row">
          <div class="col-6" style="margin-left: 23%">
            <div class="title ">
              <h4>Multi établissement</h4>
              <select class="form-control input-sm select-options" id="multiEtablissement" [(ngModel)]="client.multiEtablissement" name="client.multiEtablissement" style="width: 99%;" (change)="showEtablissment()">
                <option>Non</option>
                <option>Oui</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row" style="margin-bottom: -5%" *ngIf="addEtablissments">
          <div class="col-12" style="padding-bottom: 2%;margin-top: 3%">
            <div>
              <div>
                <div style="margin-top: 30px">
                  <div class="row ">
                    <table class="table-ligne table-responsive">
                      <tr>
                        <th class="text-center">NOM</th>
                        <th class="text-center">TYPE D'ÉTABLISSEMENT</th>
                        <th class="text-center">EMAIL </th>
                        <th class="text-center">STATUT </th>
                        <th class="text-center ">ACTIONS</th>
                      </tr>
                      <tr class="text-center" *ngFor="let etablissement of client.etablissements">
                        <td>{{etablissement.nom}}</td>
                        <td>{{etablissement.type}}</td>
                        <td>{{etablissement.mail}}</td>
                        <td>{{etablissement.statutEtab}}</td>
                        <td>
                          <a (click)="removeEtablissement(etablissement)" title="Supprimer"><img src="assets/images/supp-modal.png" class="delete-img" alt=""></a>
                          <!-- <a (click)="seteblissement(etablissement);updateModal.open(etablissement)" title="Modifier"><img src="assets/images/modif-hover.png" class="delete-img" alt=""></a> -->
  
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><a href="#" style="cursor: pointer !important;height: 40px !important;display: inline-block;" title="Ajouter" data-toggle="modal" data-target="#myModal"><img src="assets/images/add-cmd.png"></a>
                        </td>
  
                      </tr>
  
                    </table>
                  </div>
  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class=" col d-flex justify-content-center" style="margin: 1%;margin-top: 25px;">
        <div class="col-auto nbr-dossier ">
          <table>
            <tr>
              <td style="padding-right: 30px;">
                <button class="btn-valider" (click)="onUpdateClient(client)">Enregistrer <img src="assets/images/save.png"></button>
              </td>
              <td>
                <button class="btn-annule" routerLink="/agence-voyage">Annuler <img src="assets/images/btn-annule.png"></button>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="card-header " style="border: 1px solid green;margin-bottom: 1%;" (click)="modifPart4()" *ngIf="roleAdministration">
      <b style="color:grey">COORDONNÉES BANCAIRES</b>
    </div>
    <div *ngIf="showPart()==4 && roleAdministration && client" class="part" >
      <form   role="form">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-10">
            <div class="title">
              <input class="font-weight-bold" placeholder="Nom" style="color: #1b1e21" [(ngModel)]="client.informationbancaire.nom" name="client.informationbancaire.nom" value="client.informationbancaire.nom" >
            </div>
            <br />
            <div class="title ">
              <input class="font-weight-bold" placeholder="Swift" [(ngModel)]="client.informationbancaire.swift" name="client.informationbancaire.swift" value="client.informationbancaire.swift" style="color: #1b1e21">
            </div>
            <br />
  
            <div class="title">
              <input class="font-weight-bold" placeholder="Bic" [(ngModel)]="client.informationbancaire.bic" name="client.informationbancaire.bic" value="client.informationbancaire.bic" style="color: #1b1e21">
            </div>
          </div>
          <br>
          <div class="col-lg-6 col-md-6 col-sm-10">
            <div class="title">
              <input class="font-weight-bold" placeholder="Iban" [(ngModel)]="client.informationbancaire.iban" name="client.informationbancaire.iban" value="client.informationbancaire.iban" style="color: #1b1e21">
            </div>
  
            <br />
            <div class="title">
              <input class="font-weight-bold" placeholder="Rib" [(ngModel)]="client.informationbancaire.rib" name="client.informationbancaire.rib" value="client.informationbancaire.rib" style="color: #1b1e21">
            </div>
  
          </div>
          <br>
        </div>
      </div>
      <div class="">
        <p style="color:red;position: relative;left: 50px;top: 40px;" *ngIf="errorSaveFourthPartie">{{errorSaveFourthPartie}}</p>
      </div>
      <div class=" col d-flex justify-content-center" style="margin: 1%;margin-top: -2%">
        <div class="col-auto nbr-dossier ">
          <table>
            <tr>
              <td style="padding-right: 30px;">
                <button class="btn-valider" (click)="onUpdateClient(client)">Enregistrer <img src="assets/images/save.png"></button>
  
              </td>
              <td>
                <button class="btn-annule" routerLink="/agence-voyage">Annuler <img src="assets/images/btn-annule.png"></button>
  
              </td>
            </tr>
  
  
          </table>
        </div>
  
      </div>
      </form>
    </div>
  
    <!-- --------------------------modal scan ----------------------- -->
  
    <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModal" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 1000px">
        <div class="modal-content modal-content-scan" style="width: 200%; top: -170px;height: 700px;">
          <div class="modal-header d-flex justify-content-center">
            <h3 class="titre-modal-statut">
              NOUVEL ÉTABLISSEMENT
            </h3>
          </div>
          <div class="modal-body" style="height: 400px;overflow: scroll;">
            <div class="col d-flex justify-content-start">
              <b class="obligatoir-text">(*) Ce champ est obligatoire</b>
            </div>
            <form [formGroup]="formAddEtablissementAdd">
            <div class="row" style="margin-top: 25px;">
              <div class="col-lg-6 col-md-6 col-sm-10">
                <div class="title">
                  <input class="font-weight-bold" placeholder="Nom*" formControlName="nom" name="nom">
                  <control-messages [control]="formAddEtablissementAdd.controls.nom"></control-messages>
                </div>
  
                <br />
                <div class="title">
                  <select class="form-control input-sm select-options" id="etat" formControlName="type" name="type" >
                  <option >Établissement principal</option>
                  <option> Etablissement secondaire</option>
                </select>
                <control-messages [control]="formAddEtablissementAdd.controls.type"></control-messages>
                </div>
              </div>
              <br>
              <div class="col-lg-6 col-md-6 col-sm-10">
                <div class="title">
                  <input class="font-weight-bold" placeholder="Email*" formControlName="mail" name="mail">
                  <control-messages [control]="formAddEtablissementAdd.controls.mail"></control-messages>
                </div>
                <br />
                
                <div class="title">
                  <select class="form-control input-sm select-options" id="statut" formControlName="statutEtab" name="statutEtab" (change)="showAndHoideDateRadiationFuncEtab()">
                  <option>Active</option>
                  <option>Radiée</option>
                </select>
                </div>
              </div>
            </div>
            <div class="row" formGroupName="adresse">
              <div class="col-lg-6 col-md-6 col-sm-10">
                <div class="title" style="margin-top: -50px;">
                  <select class="form-control input-sm select-options" id="etat" formControlName="pays" name="pays" >
                  <option value="Tunisie" selected>Tunisie</option>
                  <option *ngFor="let py of pys">{{py.name}}</option>
                </select>
                </div>
                <br />
                <div class="title" style="margin-top: -56px;">
                  <input class="font-weight-bold" placeholder="Code postal" type="number" formControlName="codePostal" name="codePostal">
                </div>
                <br>
                <div class="title">
                  <input class="font-weight-bold" placeholder="Rue" type="text" formControlName="rue" name="rue">
                </div>
              </div>
              <br>
              <div class="col-lg-6 col-md-6 col-sm-10">
                <div class="title" >
                  <input class="font-weight-bold" placeholder="Numéro" type="number" formControlName="n" name="n">
                </div>
                <br>
                <div class="title" style="margin-top: -3px;">
                  <select class="form-control input-sm select-options" id="ville"  formControlName="ville" name="ville" style="width: 99%;margin-top: 9px;margin-bottom: 31px;">
                    <option *ngFor="let ville of vlls" [attr.value]="ville.ville">{{ville.ville}}</option>
                  </select>
                </div>
              </div>
              <br>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-10">
                <br>
                <div class="title" *ngIf="showAndHoideDateRadiationEtab">
                  <input class="font-weight-bold" placeholder="Date de radiation" type="date" [max]="getBigDate()"  formControlName="dateRadiationEtab" name="dateRadiationEtab">
                </div>
                <br>
              </div>
              <br>
            </div>
            <div class="modal-footer d-flex justify-content-center">
              <div class=" col d-flex justify-content-center" style="padding-bottom: 1%; margin-bottom: 20px">
                <div class="col-auto nbr-dossier " style="padding-top: 0px;">
                  <table>
                    <tr>
                      <td style="padding-right: 30px;">
                        <button class="btn-valider" style="width: 200px;height: 50px;" data-dismiss="modal" (click)="addnewEtablissment()" [disabled]="!formAddEtablissementAdd.valid">Ajouter <img src="assets/images/save.png"></button>
  
                      </td>
                      <td style="padding-right: 30px;">
                        <button class="btn-annule" style="width: 200px;height: 50px;" data-dismiss="modal">Annuler <img src="assets/images/btn-annule.png"> </button>
  
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  
  </div>
  <!-- --------------------------modal scan ----------------------- -->
  
  <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 1000px">
      <div class="modal-content modal-content-scan" style="width: 200%; top: -170px">
        <div class="modal-header d-flex justify-content-center">
          <h3 class="titre-modal-statut">
            NOUVEL ÉTABLISSEMENT
          </h3>
        </div>
        <div class="modal-body" style="height: 400px;overflow: scroll;">
          <div class="col d-flex justify-content-start">
            <b class="obligatoir-text">(*) Ce champ est obligatoire</b>
          </div>
          <div class="row" style="margin-top: 25px;">
            <div class="col-6">
              <div class="title">
                <input class="font-weight-bold" placeholder="Nom*">
              </div>
              <br />
              <div class="title">
                <input class="font-weight-bold" placeholder="Type d'établissement*">
              </div>
            </div>
            <div class="col-6">
              <div class="title">
                <input class="font-weight-bold" placeholder="Email*">
              </div>
              <br />
  
              <div class="title">
                <input class="font-weight-bold" placeholder="Statut*">
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <div class=" col d-flex justify-content-center" style="padding-bottom: 1%; margin-bottom: 20px">
              <div class="col-auto nbr-dossier" style="padding-top: 0px;">
                <table>
                  <tr>
                    <td style="padding-right: 30px;">
                      <button class="btn-valider" style="width: 200px;height: 50px;" >Ajouter <img src="assets/images/save.png"></button>
  
                    </td>
                    <td style="padding-right: 30px;">
                      <button class="btn-annule" style="width: 200px;height: 50px;" >Annuler <img src="assets/images/btn-annule.png"> </button>
  
                    </td>
  
                  </tr>
  
                </table>
              </div>
  
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <!--   ---------------------- modal update client etablissement ---- -->
  
  <!-- modal add nouvelle établissement -->
  <div class="row" >
  
    <bs-modal style="margin-top: -85px;" #updateModal>
  
      <div class="modal-content" style="width: 200%;top: 0px;left: -235px;">
        <div class="col d-flex justify-content-start">
          <b class="obligatoir-text">(*) Ce champ est obligatoire</b>
        </div>
        <form [formGroup]="formAddEtablissement">
          <div class="row" style="margin-top: 25px;" *ngIf="etablissement">
            <div class="col-lg-6 col-md-6 col-sm-10">
              <div class="title">
                <input class="font-weight-bold" placeholder="Nom*" [(ngModel)]="etablissement.nom" name="etablissement.nom" value="etablissement.nom" formControlName="nom">
                <control-messages [control]="formAddEtablissement.controls.nom"></control-messages>
              </div>
  
              <br />
              <div class="title">
                <select class="form-control input-sm select-options" id="etat" [(ngModel)]="etablissement.type" name="etablissement.type" value="etablissement.type" formControlName="type">
                  <option>Établissement principal</option>
                  <option> Etablissement secondaire</option>
                </select>
                <control-messages [control]="formAddEtablissement.controls.type"></control-messages>
              </div>
            </div>
            <br>
            <div class="col-lg-6 col-md-6 col-sm-10">
              <div class="title">
                <input class="font-weight-bold" placeholder="Email*" [(ngModel)]="etablissement.mail" name="etablissement.mail" value="etablissement.mail" formControlName="mail">
                <control-messages [control]="formAddEtablissement.controls.mail"></control-messages>
              </div>
              <br />
              
              <div class="title">
                <select class="form-control input-sm select-options" id="statut" formControlName="statutEtab" [(ngModel)]="etablissement.statutEtab" name="etablissement.statutEtab" value="etablissement.statutEtab">
                  <option>Active</option>
                  <option>Radiée</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row" formGroupName="adresse" *ngIf="etablissement">
            <div class="col-lg-6 col-md-6 col-sm-10">
              <div class="title" style="margin-top: -50px;">
                <select class="form-control input-sm select-options" id="etat" formControlName="pays" [(ngModel)]="etablissement.adresse.pays" name="etablissement.adresse.pays">
                  <option value="Tunisie" selected>Tunisie</option>
                  <option *ngFor="let py of pys">{{py.name}}</option>
                </select>
              </div>
              <br />
              <div class="title" style="margin-top: -56px;">
                <input class="font-weight-bold" placeholder="Code postal" type="number" formControlName="codePostal" [(ngModel)]="etablissement.adresse.codePostal" name="etablissement.adresse.codePostal"
                  value="etablissement.adresse.codePostal">
              </div>
              <br>
              <div class="title">
                <input class="font-weight-bold" placeholder="Rue" type="text" formControlName="rue" [(ngModel)]="etablissement.adresse.rue" name="etablissement.adresse.rue" value="etablissement.adresse.rue">
              </div>
            </div>
            <br>
            <div class="col-lg-6 col-md-6 col-sm-10">
              <div class="title">
                <input class="font-weight-bold" placeholder="Numéro" type="number" formControlName="n" [(ngModel)]="etablissement.adresse.n" name="etablissement.adresse.n" value="etablissement.adresse.n">
              </div>
              <br>
              <div class="title" style="margin-top: -3px;">
                <select class="form-control input-sm select-options" id="ville" formControlName="ville" [(ngModel)]="etablissement.adresse.ville" name="etablissement.adresse.ville" value="etablissement.adresse.ville" style="width: 99%;margin-top: 9px;margin-bottom: 31px;">
                  <option value="{{etablissement.adresse.ville}}">{{etablissement.adresse.ville}}</option>
                  <option *ngFor="let ville of vlls" [attr.value]="ville.ville">{{ville.ville}}</option>
                </select>
              </div>
            </div>
            <br>
          </div>
          <div class="row" *ngIf="etablissement">
            <div class="col-lg-6 col-md-6 col-sm-10">
              <br>
              <div class="title" *ngIf="etablissement.statutEtab === 'Radiée'">
                <input class="font-weight-bold" placeholder="Date de radiation" type="date" formControlName="dateRadiationEtab" [(ngModel)]="etablissement.dateRadiationEtab" name="etablissement.dateRadiationEtab"
                  value="etablissement.dateRadiationEtab" [max]="getBigDate()" >
              </div>
              <br>
            </div>
            <br>
          </div>
          <div class="modal-footer d-flex justify-content-center" *ngIf="etablissement">
            <div class=" col d-flex justify-content-center" style="padding-bottom: 1%; margin-bottom: 20px">
              <div class="col-auto nbr-dossier ">
                <table>
                  <tr>
                    <td style="padding-right: 30px;">
                      <button class="btn-valider" style="width: 200px;height: 50px;" data-dismiss="modal" (click)="editEtablissment(etablissement)" [disabled]="!formAddEtablissement.valid">Modifier <img
                          src="assets/images/save.png"></button>
  
                    </td>
                    <td style="padding-right: 30px;">
                      <button class="btn-annule" style="width: 200px;height: 50px;" data-dismiss="modal">Annuler <img src="assets/images/btn-annule.png"> </button>
  
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </form>
      </div>
  
  
    </bs-modal>
  </div>
  <!-- modal add nouvelle établissement -->
  