<app-navbar></app-navbar>
<br>
<div id="content-nav-page" class="row">
    <div class=" col-3 titre-menu">
      <b style="margin-right: 10%" class="title-haut">SALARIÈS</b>
      <img src="assets/images/charge-plus.png" *ngIf="hasRoleAdd" routerLink="/ajoutSalarie">
    </div>
    <div class="col-9 sous-menu">
      <b class="sous-menu-active1">SALARIÈS</b>
    </div>
  </div>
  <div id="content-page" class="containe-fluid">
    <div class="row  ">
      <div class="col-lg-4 col-md-10 col-sm-10  search">
        <input class="search-bar" type="search" placeholder="Rechercher.." [(ngModel)]="searched" name="searched">
      </div>
      <div class="col-lg-1 col-md-2 col-sm-2">
        <select class="select-input-nombre" name="numberData" [(ngModel)]="numberData">
          <option>5</option>
          <option selected>10</option>
          <option>25</option>
          <option>50</option>
        </select>
      </div>
      <div class=" col d-flex justify-content-end">
        <div class="col-auto nbr-dossier ">
          <table>
            <tr>
              <td>
                <b>Salariés</b>
              </td>
            </tr>
            <tr>
              <td>
                <b class="sous-nbr-dossier d-flex justify-content-center" *ngIf="salaries">{{salaries.length}}</b>
              </td>
            </tr>
          </table>
        </div>
  
        <div class="col-auto btn-devis ">
        </div>
      </div>
    </div>
    <!-- tableau -->
    <div class="row" style="overflow-x:auto;">
      <table class="table table-striped table-hover">
        <th class="left" id="firstThTable">NOM</th>
        <th class="left">PRÉNOM</th>
        <th class="left">POSTE</th>
        <th class="left">SOCIÉTÉ</th>
        <th class="left">CONTRAT</th>
        <th class="left">EMAIL PROFESSIONNEL</th>
        <th class="left">EMAIL PERSONNEL</th>
        <th class="left">TÉLÉPHONE PROFESSIONNEL</th>
        <th class="left">TÉLÉPHONE PERSONNEL</th>
        <th class="left">DATE DE CRÉATION</th>
        <th class="text-center " >RÉGLAGES</th>
        <tr class="text-center" *ngFor="let salarie of salaries|searchSalarie  : searched.toString().toLowerCase()| paginate: { itemsPerPage: numberData, currentPage: p }">
  
            <td class="left" [routerLink]="['/details/salarie', salarie.id]">{{salarie.nom}}</td>
            <td class="left" [routerLink]="['/details/salarie', salarie.id]">{{salarie.prenom}}</td>
            <td class="left" [routerLink]="['/details/salarie', salarie.id]"><span *ngIf="!salarie.posteObject">{{salarie.poste}}</span> <span *ngIf="salarie.posteObject">{{salarie.posteObject.titre}}</span></td>
            <td class="left" [routerLink]="['/details/salarie', salarie.id]">{{salarie.societe}}</td>
            <td class="left" [routerLink]="['/details/salarie', salarie.id]">{{salarie.contrat}}</td>
            <td class="left" [routerLink]="['/details/salarie', salarie.id]">{{salarie.mailPro}}</td>
            <td class="left" [routerLink]="['/details/salarie', salarie.id]">{{salarie.mailPerso}}</td>
            <td class="left" [routerLink]="['/details/salarie', salarie.id]">{{salarie.telPro}}</td>
            <td class="left" [routerLink]="['/details/salarie', salarie.id]">{{salarie.telPerso}}</td>
            <td class="left" [routerLink]="['/details/salarie', salarie.id]">{{salarie.datec}}</td>
            <td >
              <div class="dropdown">
                <button class="btn dropdown-toggle btn-options" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  OPTIONS
                </button>
                <div class="dropdown-menu reglageMenu" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item option" [routerLink]="['/details/salarie', salarie.id]">
                    <div class="options-show-icon"></div>
                    <div class="option-text"> Afficher</div>
                  </a>
  
                  <a class="dropdown-item option" *ngIf="hasRoleUpdate" [routerLink]="['/salarie/details', salarie.id]" title="Modifier">
                    <div class="options-modif-icon"></div>
                    <div class="option-text"> Modifier</div>
                  </a>
  
                  <a class="dropdown-item" *ngIf="hasRoleDelete" (click)="removesalarie(salarie)" title="Supprimer">
                    <div class="options-supp-icon"></div>
                    <div class="option-text"> Supprimer</div>
                  </a>
                </div>
              </div>
           </td>
         </tr>
  
  
      </table>
    </div>
    <div style="padding-top: 50px;color: green">
      <pagination-controls previousLabel="Précédent" nextLabel="Suivant" (pageChange)="p = $event" #api></pagination-controls>
    </div>
  </div>
  