<app-navbar></app-navbar>
<br />
<div style="padding-bottom: 55px">
  <div class="box-left-mini"></div>
  <div class="behind_container" *ngIf="selectrole">
    <div class="behind">
      <form [formGroup]="addForm" id="updateForm" style="position: relative">
        <div
          *ngIf="f.name.touched && f.name.invalid"
          class="alert alert-danger errormessage"
          style="margin-top: 20px;width: 42%;margin-left: 2%;background: none;border: none;"
        >
          <div
            *ngIf="(f.name.touched && f.name.invalid) || f.name.errors.required" style="color: red;"
          >
            Le nom est obligatoire et il doit comporter uniquement des
            miniscules et des caractères numériques.
          </div>
        </div>
        <div
          *ngIf="f.slug.touched && f.slug.invalid"
          class="alert alert-danger errormessage"
          style="margin-top: 20px;width: 42%;margin-left: 2%;background: none;border: none;"
        >
          <div
            *ngIf="(f.slug.touched && f.slug.invalid) || f.slug.errors.required" style="color: red;"
          >
            Le slug est obligatoire.
          </div>
        </div>
        <div class="row card">
          <div class="pb-5 pl-5 pr-5 pt-4">
            <label for="nom" class="e0sqo4xj">Nom:</label>
            <input
              formControlName="name"
              [(ngModel)]="selectrole.name"  value="{{selectrole.name}}"
              id="nom"
              type="text"
              placeholder="Nom*"
              class="form-control e0ts9tij"
            />
            <br />
            <label for="slug" class="e0sqo4xj">Slug:</label>
            <input
              formControlName="slug"
              [(ngModel)]="selectrole.slug"  value="{{selectrole.slug}}"
              id="slug"
              type="text"
              placeholder="Slug*"
              class="form-control e0ts9tij"
            />
          </div>
        </div>
        <br />
        <div class="row mglr">
          <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="card card-stats">
              <div class="card-header">Gestion des utilisateurs</div>
              <div class="card-body">
                <div class="behind_container">
                  <div class="" style="padding-bottom: 18px !important">
                    <div class="col-md-12" style="margin-left: 16px">
                      <div class="float-right" style="margin-top: -18px">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          [checked]="this.selecteduserall"
                          id="tous"
                          (change)="changeall()"
                        />
                        <label class="form-check-label lab" for="tous">
                          TOUT COCHER/DÉCOCHER
                        </label>
                      </div>

                      <div
                        class="afficher"
                        style="display: block; margin-top: 4px"
                        *ngFor="let item of list"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          [disabled]="item.show == true"
                          [checked]="item.checked"
                          id="{{ item.id }}"
                          (change)="
                            item.checked = !this.item.checked;
                            changeitem(item.id)
                          "
                        />
                        <label
                          class="form-check-label lab2"
                          for="{{ item.id }}"
                        >
                          {{ item.title }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="card card-stats" style="height: 100%">
              <div class="card-header">Gestion des clients</div>
              <div class="card-body">
                <div class="behind_container">
                  <div class="" style="padding-bottom: 18px !important">
                    <div class="col-md-12" style="margin-left: 16px">
                      <div class="float-right" style="margin-top: -18px">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          [checked]="this.selecteduserall4"
                          id="tous4"
                          (change)="changeall4()"
                        />
                        <label class="form-check-label lab" for="tous4">
                          TOUT COCHER/DÉCOCHER
                        </label>
                      </div>

                      <div
                        class="afficher"
                        style="display: block; margin-top: 4px"
                        *ngFor="let item of listclients"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          [disabled]="item.show == true"
                          [checked]="item.checked"
                          id="{{ item.id }}"
                          (change)="
                            item.checked = !this.item.checked;
                            changeitem4(item.id)
                          "
                        />
                        <label
                          class="form-check-label lab2"
                          for="{{ item.id }}"
                        >
                          {{ item.title }}
                        </label>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="card card-stats" style="height: 100%">
              <div class="card-header">Gestion des chargés d'affaires</div>

              <div class="card-body">
                <div class="behind_container">
                  <div class="" style="padding-bottom: 18px !important">
                    <div class="col-md-12" style="margin-left: 16px">
                      <div class="float-right" style="margin-top: -18px">
                        <input
                          class="form-check-input intous"
                          type="checkbox"
                          [checked]="this.selecteduserall3"
                          id="tous3"
                          (change)="changeall3()"
                        />
                        <label class="form-check-label lab" for="tous3">
                          TOUT COCHER/DÉCOCHER
                        </label>
                      </div>

                      <div
                        class="afficher"
                        *ngFor="let itemchoix of listca"
                        style="margin-top: 4px"
                      >
                        <input
                          class="form-check-input intous"
                          type="checkbox"
                          [disabled]="itemchoix.show == true"
                          [checked]="itemchoix.checked"
                          id="{{ itemchoix.id }}"
                          (change)="
                            itemchoix.checked = !this.itemchoix.checked;
                            changeitem3(itemchoix.id)
                          "
                        />
                        <label
                          class="form-check-label lab2"
                          for="{{ itemchoix.id }}"
                        >
                          {{ itemchoix.title }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="card card-stats" style="height: 100%">
              <div class="card-header">Gestion des fournisseurs</div>
              <div class="card-body">
                <div class="behind_container">
                  <div class="" style="padding-bottom: 18px !important">
                    <div class="col-md-12" style="margin-left: 16px">
                      <div class="float-right" style="margin-top: -18px">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          [checked]="this.selecteduserall2"
                          id="tousfr"
                          (change)="changeall2()"
                        />
                        <label class="form-check-label lab" for="tousfr">
                          TOUT COCHER/DÉCOCHER
                        </label>
                      </div>

                      <div
                        class="afficher"
                        style="display: block; margin-top: 4px"
                        *ngFor="let item of listfournisseurs"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          [disabled]="item.show == true"
                          [checked]="item.checked"
                          id="{{ item.id }}"
                          (change)="
                            item.checked = !this.item.checked;
                            changeitem2(item.id)
                          "
                        />
                        <label
                          class="form-check-label lab2"
                          for="{{ item.id }}"
                        >
                          {{ item.title }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br>
        <div class="row mglr">

          <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="card card-stats" style="height: 100%">
              <div class="card-header">Gestion des dossiers</div>
              <div class="card-body">
                <div class="behind_container">
                  <div class="" style="padding-bottom: 18px !important">
                    <div class="col-md-12" style="margin-left: 16px">
                      <div class="float-right" style="margin-top: -18px">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          [checked]="this.selecteduserall6"
                          id="tousdossiers"
                          (change)="changeall6()"
                        />
                        <label class="form-check-label lab" for="tousdossiers">
                          TOUT COCHER/DÉCOCHER
                        </label>
                      </div>

                      <div
                        class="afficher"
                        style="display: block; margin-top: 4px"
                        *ngFor="let item of listdossiers"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          [disabled]="item.show == true"
                          [checked]="item.checked"
                          id="{{ item.id }}"
                          (change)="
                            item.checked = !this.item.checked;
                            changeitem6(item.id)
                          "
                        />
                        <label
                          class="form-check-label lab2"
                          for="{{ item.id }}"
                        >
                          {{ item.title }}
                        </label>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="card card-stats" style="height: 100%">
              <div class="card-header">Gestion des commandes</div>
              <div class="card-body">
                <div class="behind_container">
                  <div class="" style="padding-bottom: 18px !important">
                    <div class="col-md-12" style="margin-left: 16px">
                      <div class="float-right" style="margin-top: -18px">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          [checked]="this.selecteduserall7"
                          id="touscommandes"
                          (change)="changeall7()"
                        />
                        <label class="form-check-label lab" for="touscommandes">
                          TOUT COCHER/DÉCOCHER
                        </label>
                      </div>

                      <div
                        class="afficher"
                        style="display: block; margin-top: 4px"
                        *ngFor="let item of listcommandes"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          [disabled]="item.show == true"
                          [checked]="item.checked"
                          id="{{ item.id }}"
                          (change)="
                            item.checked = !this.item.checked;
                            changeitem7(item.id)
                          "
                        />
                        <label
                          class="form-check-label lab2"
                          for="{{ item.id }}"
                        >
                          {{ item.title }}
                        </label>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="card card-stats" style="height: 100%">
              <div class="card-header">Gestion des catalogues</div>
              <div class="card-body">
                <div class="behind_container">
                  <div class="" style="padding-bottom: 18px !important">
                    <div class="col-md-12" style="margin-left: 16px">
                      <div class="float-right" style="margin-top: -18px">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          [checked]="this.selecteduserall8"
                          id="touscatalogues"
                          (change)="changeall8()"
                        />
                        <label class="form-check-label lab" for="touscatalogues">
                          TOUT COCHER/DÉCOCHER
                        </label>
                      </div>

                      <div
                        class="afficher"
                        style="display: block; margin-top: 4px"
                        *ngFor="let item of listcatalogues"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          [disabled]="item.show == true"
                          [checked]="item.checked"
                          id="{{ item.id }}"
                          (change)="
                            item.checked = !this.item.checked;
                            changeitem8(item.id)
                          "
                        />
                        <label
                          class="form-check-label lab2"
                          for="{{ item.id }}"
                        >
                          {{ item.title }}
                        </label>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="card card-stats" style="height: 100%">
              <div class="card-header">Gestion des inventaires</div>
              <div class="card-body">
                <div class="behind_container">
                  <div class="" style="padding-bottom: 18px !important">
                    <div class="col-md-12" style="margin-left: 16px">
                      <div class="float-right" style="margin-top: -18px">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          [checked]="this.selecteduserall9"
                          id="tousinventaires"
                          (change)="changeall9()"
                        />
                        <label class="form-check-label lab" for="tousinventaires">
                          TOUT COCHER/DÉCOCHER
                        </label>
                      </div>

                      <div
                        class="afficher"
                        style="display: block; margin-top: 4px"
                        *ngFor="let item of listinventaires"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          [disabled]="item.show == true"
                          [checked]="item.checked"
                          id="{{ item.id }}"
                          (change)="
                            item.checked = !this.item.checked;
                            changeitem9(item.id)
                          "
                        />
                        <label
                          class="form-check-label lab2"
                          for="{{ item.id }}"
                        >
                          {{ item.title }}
                        </label>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <br>
        <div class="row mglr">
            <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="card card-stats" style="height: 100%">
                <div class="card-header">Gestion des stocks</div>
                <div class="card-body">
                  <div class="behind_container">
                    <div class="" style="padding-bottom: 18px !important">
                      <div class="col-md-12" style="margin-left: 16px">
                        <div class="float-right" style="margin-top: -18px">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            [checked]="this.selecteduserall10"
                            id="tousstocks"
                            (change)="changeall10()"
                          />
                          <label class="form-check-label lab" for="tousstocks">
                            TOUT COCHER/DÉCOCHER
                          </label>
                        </div>
  
                        <div
                          class="afficher"
                          style="display: block; margin-top: 4px"
                          *ngFor="let item of liststocks"
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            [disabled]="item.show == true"
                            [checked]="item.checked"
                            id="{{ item.id }}"
                            (change)="
                              item.checked = !this.item.checked;
                              changeitem10(item.id)
                            "
                          />
                          <label
                            class="form-check-label lab2"
                            for="{{ item.id }}"
                          >
                            {{ item.title }}
                          </label>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="card card-stats" style="height: 100%">
                <div class="card-header">Gestion des RH</div>
                <div class="card-body">
                  <div class="behind_container">
                    <div class="" style="padding-bottom: 18px !important">
                      <div class="col-md-12" style="margin-left: 16px">
                        <div class="float-right" style="margin-top: -18px">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            [checked]="this.selecteduserall11"
                            id="tousrh"
                            (change)="changeall11()"
                          />
                          <label class="form-check-label lab" for="tousrh">
                            TOUT COCHER/DÉCOCHER
                          </label>
                        </div>
  
                        <div
                          class="afficher"
                          style="display: block; margin-top: 4px"
                          *ngFor="let item of listrh"
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            [disabled]="item.show == true"
                            [checked]="item.checked"
                            id="{{ item.id }}"
                            (change)="
                              item.checked = !this.item.checked;
                              changeitem11(item.id)
                            "
                          />
                          <label
                            class="form-check-label lab2"
                            for="{{ item.id }}"
                          >
                            {{ item.title }}
                          </label>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="card card-stats" style="height: 100%">
                <div class="card-header">Gestion des rôles</div>
                <div class="card-body">
                  <div class="behind_container">
                    <div class="" style="padding-bottom: 18px !important">
                      <div class="col-md-12" style="margin-left: 16px">
                        <div class="float-right" style="margin-top: -18px">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            [checked]="this.selecteduserall12"
                            id="tousroles"
                            (change)="changeall12()"
                          />
                          <label class="form-check-label lab" for="tousroles">
                            TOUT COCHER/DÉCOCHER
                          </label>
                        </div>
  
                        <div
                          class="afficher"
                          style="display: block; margin-top: 4px"
                          *ngFor="let item of listroles"
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            [disabled]="item.show == true"
                            [checked]="item.checked"
                            id="{{ item.id }}"
                            (change)="
                              item.checked = !this.item.checked;
                              changeitem12(item.id)
                            "
                          />
                          <label
                            class="form-check-label lab2"
                            for="{{ item.id }}"
                          >
                            {{ item.title }}
                          </label>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="card card-stats" style="height: 100%">
                <div class="card-header">Dashboard</div>
                <div class="card-body">
                  <div class="behind_container">
                    <div class="" style="padding-bottom: 18px !important">
                      <div class="col-md-12" style="margin-left: 16px">
                        <div class="float-right" style="margin-top: -18px">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            [checked]="true"
                            [disabled]="true"
                          />
                          <label class="form-check-label lab" for="tousdashboard">
                            TOUT COCHER/DÉCOCHER
                          </label>
                        </div>
  
                        <div
                          class="afficher"
                          style="display: block; margin-top: 4px"
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            [disabled]="true"
                            [checked]="true"
                            id="iddashb"
                          />
                          <label
                            class="form-check-label lab2"
                            for="iddashb"
                          >
                            Afficher
                          </label>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <br /><br />

        <div
          class="col d-flex justify-content-center"
          style="padding-bottom: 1%"
        >
          <div class="col-auto nbr-dossier">
            <table>
              <tr>
                <td>
                  <button
                    class="btn-annule"
                    style="width: 200px; height: 50px"
                    (click)="annuler()"
                  >
                    Annuler <img src="assets/images/btn-annule.png" />
                  </button>
                </td>
                <td style="padding-right: 30px">
                  <button
                    class="btn-valider"
                    style="width: 200px; height: 50px"
                    (click)="add(addForm.value)"
                    [disabled]="!addForm.valid"
                  >
                    Modifier <img src="assets/images/save.png" />
                  </button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
