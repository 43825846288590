import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DossierService } from "../../../services/dossier.service";
import { ClientService } from "../../../services/client.service";
import { ChargeAffaireService } from "../../../services/charge-affaire.service";
import { UserService } from "../../../services/user.service";
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { UploadFileService } from "../../../services/upload-file-service.service";
import { ValidationService } from '../../../services/validate/validate-error.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';

@Component({
  selector: 'app-update-dossier',
  templateUrl: './update-dossier.component.html',
  styleUrls: ['../../utilisateur/ajout-utilisateur/ajout-utilisateur.component.scss','../ajout-dossier/ajout-dossier.component.css']
})
export class UpdateDossierComponent implements OnInit {
  dossier: any;
  utilisateur: any;
  id: any;
  clients: any;
  chargeAffaires: any;
  selectedChargeAffaires: any
  form: any;
  formPreparationChiffrage: any;
  utilisateurs: any;
  personnesOfDepartement: any[] = []
  selectedPersonne: any;
  AllselectedchargeAffaires: any[] = [];
  selectedFiles:any;
  selectedEmails: any;
  selectedcctp: any;
  selectedplan:any;
  sizeLimitOne = 10000000; //10MB
  currentFileUpload: File
  public loading = false;
  selectedEtablissement:any;
  etablissements:any[]=[];
  showDirectionEtablissement = false;
  selectedClient: any;
  etablissement:any;
  showselectedEtab = false
  errorDate = false;
  myClient:any;
  datraa:any;
  initialNumeroDossier:any;
  errorNumber = false;
  bigDate = "9999-12-31"
  constructor(private uploadFileService: UploadFileService,private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute, private dossierService: DossierService, private chargeAffaireService: ChargeAffaireService, private clientService: ClientService,
    private router: Router, private userService: UserService) {
    this.form = this.formBuilder.group({
      numero: ['', [Validators.required, Validators.minLength(4), ValidationService.validateEmptyField, Validators.maxLength(25)]],
      type: ['Etude', [Validators.required]],
      nom: ['', [Validators.required, Validators.minLength(2), ValidationService.validateEmptyField, Validators.maxLength(100)]],
      coef: [''],
      datel: ['', [Validators.required,ValidationService.dateValidatorr]],
      dateReception: ['', [Validators.required,ValidationService.dateValidatorr]],
      client: ['', Validators.required],
      chargeAffaire: ['', Validators.required],
      etablissement: ['', Validators.required]
    })

  }
  ngOnInit() {
    this.getAllChargesAffaires();
    let params: any = this.activatedRoute.snapshot.params;
    this.dossierService.getDossierById(params.id).subscribe(data => {
      this.dossier = data;
      if(this.dossier){
        this.filterEtablissement(this.dossier);
        this.etablissement = this.dossier.etablissement
        this.initialNumeroDossier = this.dossier.numero
      }
    },
      err => {
        return false;
      });
    this.id = localStorage.getItem('idUser')
    if (this.id) {
      this.userService.getUserByID(this.id).subscribe(utilisateur => {
        this.utilisateur = utilisateur;
        if(!this.utilisateur){
          localStorage.removeItem('idUser');
          localStorage.clear();
          this.router.navigate(['/login']);
        }
        if (!this.utilisateur) {
          this.router.navigateByUrl('/dossier');
        }
        if(this.utilisateur && this.utilisateur.role === "Super admin"){
          this.getAllClients();
        }
        if(this.utilisateur && this.utilisateur.role != "Super admin"){
          this.getNotRadiAllClients();
        }
        if(this.utilisateur.sousRole == 'gr-acces'){
          this.router.navigate(["accueil"]);
          return false;
        }
      },
        err => {
          return false;
        });
    } else {
      return false;
    }
  }


  getNotRadiAllClients(){
    this.clients = []
    this.clientService.getAllUsers().then(data => {
      this.datraa = data;
      if(this.datraa){
        for(var j=0;j<this.datraa.length;j++){
          if(this.datraa[j].statut === 'Active'){
            for(var i=0;i<this.datraa[j].etablissements.length;i++){
              var etb = [];
              if(this.datraa[j].etablissements[i].statutEtab === 'Active'){
                etb.push(this.datraa[j].etablissements[i])
              }
              if(i === this.datraa[j].etablissements.length - 1){
                this.datraa[j].etablissements = etb
              }
            }
            this.clients.push(this.datraa[j])
          }
        }

      }
    },
      err => {
        return false;
      });
  }
  // recuperer la liste des chargés d'ChargeAffaire
  getAllChargesAffaires() {
    this.chargeAffaireService.getAllUsers().then(data => {
      this.chargeAffaires = data;
    },
      err => {
        return false;
      });
  }
  getBigDate(): string {
      return this.bigDate
    }
  // recuperer tous les clients
  getAllClients() {
    this.clientService.getAllUsers().then(data => {
      this.clients = data;
      this.clients = this.clients.sort(this.triClient);

    },
      err => {
        return false;
      });
  }
  // initialiser la liste des établissemnts d'un client choisi
  filterEtablissement(dossier){
    if(this.clients){
    for (var i = 0; i < this.clients.length; i++) {
      if (this.clients[i].id === dossier.client.id) {
        this.etablissements = this.clients[i].etablissements
      }
    }
  }
  }
  selectClient(dossier) {
    this.myClient = this.form.value.client
    this.showDirectionEtablissement = false;
    this.selectedEtablissement = false;
    dossier.etablissement = ''
    this.showselectedEtab = true
    this.etablissements = this.form.value.client.etablissements
    this.selectedClient = this.form.value.client;
    this.AllselectedchargeAffaires = []
    if(this.etablissements.length < 1){
      var newEtabl = {
        "nom": this.form.value.client.denomination,
        "type": "Etablissement principale",
        "adresse": this.form.value.client.adresse,
        "tel": this.form.value.client.tel,
        "mail": this.form.value.client.mail,
      }
      this.etablissements.push(newEtabl)
      return true;
    }
    if(this.etablissements.length > 0){
      var newEtabl = {
        "nom": this.form.value.client.denomination,
        "type": "Etablissement principale",
        "adresse": this.form.value.client.adresse,
        "tel": this.form.value.client.tel,
        "mail": this.form.value.client.mail,
      }
    this.etablissement = this.form.value.client.etablissements
    this.etablissements.push(newEtabl)
      return true;
    }else {
      return false;
    }

  }
  showChargeAffaireDetails() {
    this.selectedChargeAffaires = this.form.value.chargeAffaire
  }
  // select établissemnt
  selectEtablissement(){
  this.selectedEtablissement =  this.form.value.etablissement
  var etb = {
    "nom":this.form.value.etablissement.nom,
    "type":this.form.value.etablissement.type,
    "adresse":this.form.value.etablissement.adresse
  }
  if (this.chargeAffaires) {
    this.AllselectedchargeAffaires = [];
    this.selectedChargeAffaires = undefined
    this.dossier.chargeAffaire = undefined
    for (var i = 0; i < this.chargeAffaires.length; i++) {
      if(this.chargeAffaires[i].client.etablissement){
        var newEt = {
          "nom":this.chargeAffaires[i].client.etablissement.nom,
          "type":this.chargeAffaires[i].client.etablissement.type,
          "adresse":this.chargeAffaires[i].client.etablissement.adresse
        }
      if ((this.chargeAffaires[i].client.id === this.form.value.client.id) && (JSON.stringify(etb) === JSON.stringify(newEt))) {
        this.AllselectedchargeAffaires.push(this.chargeAffaires[i])
    }
      }
    }
    this.AllselectedchargeAffaires = this.AllselectedchargeAffaires.sort(this.triChargesAffaires);
  }
  }
  // ajouter  des fichiers

  selectFile(event) {
      const file = event.target.files.item(0)
      if (file) {
        /*if (!file.type.match('text.*|image.*|application.*')) {
          event = undefined
          alert("Impossible de télécharger ce fichier.")
          return false;
        }*/
        if (file.size > this.sizeLimitOne) {
          event = undefined
          Swal.fire({
            imageUrl: 'assets/images/error.png',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            width: 600,
            title: 'Un fichier sélectionné est trop volumineux.La taille maximale est 10 Mo.',
            /*timer: 1500,*/
            showConfirmButton: true,
            confirmButtonColor: '#8B0000',
            confirmButtonWidth: 100,

          }
          );
          return false;
        }
        else {
          this.selectedFiles = file;
        }
      } else {
        return false;
      }
  }
  //  ajouter un mail
  selectFileEmail(event) {
    const file = event.target.files.item(0)
    if (file) {
      /*if (!file.type.match('message.*')) {
        event = undefined
        alert("Impossible de télécharger ce fichier.")
        return false;
      }*/
      if (file.size > this.sizeLimitOne) {
        event = undefined
        Swal.fire({
          imageUrl: 'assets/images/error.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 600,
          title: 'Un fichier sélectionné est trop volumineux.La taille maximale est 10 Mo.',
          /*timer: 1500,*/
          showConfirmButton: true,
          confirmButtonColor: '#8B0000',
          confirmButtonWidth: 100,

        }
        );
        return false;
      }
      else {
        this.selectedEmails = file
      }
    } else {
      return false;
    }

  }
  // ajouter CCTP
  selectFileCCTP(event) {
    const file = event.target.files.item(0)
    if (file) {
    /*  if (!file.type.match('application/pdf') && !file.type.match('application/msword') && !file.type.match('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
        event = undefined
        alert("Impossible de télécharger ce fichier.")
        return false;
      }*/
      if (file.size > this.sizeLimitOne) {
        event = undefined
        Swal.fire({
          imageUrl: 'assets/images/error.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 600,
          title: 'Un fichier sélectionné est trop volumineux.La taille maximale est 10 Mo.',
          /*timer: 1500,*/
          showConfirmButton: true,
          confirmButtonColor: '#8B0000',
          confirmButtonWidth: 100,

        }
        );
        return false;
      }
      else {
        this.selectedcctp = file
      }
    } else {
      return false;
    }

  }
  // ajouter Plan
  selectFilePlan(event){
    const file = event.target.files.item(0)
    if (file) {
      /*if (!file.type.match('text.*|image.*|application.*')) {
        event = undefined
        alert("Impossible de télécharger ce fichier.")
        return false;
      }*/
      if (file.size > this.sizeLimitOne) {
        event = undefined
        Swal.fire({
          imageUrl: 'assets/images/error.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 600,
          title: 'Un fichier sélectionné est trop volumineux.La taille maximale est 10 Mo.',
          /*timer: 1500,*/
          showConfirmButton: true,
          confirmButtonColor: '#8B0000',
          confirmButtonWidth: 100,

        }
        );
        return false;
      }
      else {
        this.selectedplan = file
      }
    } else {
      return false;
    }
  }
  onUpdateDossier(dossier) {
    Swal.fire({
      title: 'VOULEZ-VOUS ENREGISTRER VOS MODIFICATIONS?',
      imageUrl: 'assets/images/modifier.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      showCancelButton: true,
      width: 800,
      confirmButtonColor: '#059e00',
      cancelButtonColor: '#FF9100',
      cancelButtonText: 'ANNULER',
      confirmButtonText: 'ENREGISTRER'
    }).then((result) => {
      if (!result.dismiss) {
        if(this.initialNumeroDossier == this.dossier.numero){
          this.pousuivreSavingDossier(dossier);
          return true;
        }else{
          this.dossierService.verifyNumero(this.form.value).subscribe(data => {
            if (data) {
              this.errorNumber = true;
              return false;
            }
            else {
              this.pousuivreSavingDossier(dossier);
              return true;
            }

          })

        }

      }
    } );

  }

  pousuivreSavingDossier(dossier){
    this.errorNumber = false;
    if(dossier.dateReception > dossier.datel){
      this.errorDate = true;
      return false;
    }
    this.errorDate = false;
    var d = new Date();
    dossier.dateMod = d.toLocaleString();
    this.loading = true;
    if (this.selectedFiles) {
      var fileExtensionn = this.selectedFiles.name.substr((this.selectedFiles.name.lastIndexOf('.') + 1));
      //var photo = this.utilisateur.id + i + '.' + fileExtension
      var randomVariable = dossier.numero.replace(/\./g, "") + "_Fichier"
      if(dossier.listfiles && dossier.listfiles.length > 0){
        var randomVariable = dossier.numero.replace(/\./g, "") + "_Fichier("+ dossier.listfiles.length  +")"
      }
      var imgObject = {
        randomVariable: randomVariable,
        name:this.selectedFiles.name,
        photo: environment.filesPath + randomVariable + "." + fileExtensionn
      }
      dossier.listfiles.push(imgObject)
    }
    if (this.selectedEmails) {
      var fileExtensionn = this.selectedEmails.name.substr((this.selectedEmails.name.lastIndexOf('.') + 1));
      //var photo = this.utilisateur.id + i + '.' + fileExtension
      var randomVariable = dossier.numero.replace(/\./g, "") + "_Mail"
      if(dossier.listfilesEmail && dossier.listfilesEmail.length > 0){
        var randomVariable = dossier.numero.replace(/\./g, "") + "_Mail("+ dossier.listfilesEmail.length  +")"
      }
      var imgObject = {
        randomVariable:randomVariable,
        name:this.selectedEmails.name,
        photo: environment.filesPath + randomVariable + "." + fileExtensionn
      }
      dossier.listfilesEmail.push(imgObject)
    }
    if (this.selectedcctp) {
      var fileExtensionn = this.selectedcctp.name.substr((this.selectedcctp.name.lastIndexOf('.') + 1));
      //var photo = this.utilisateur.id + i + '.' + fileExtension
      var randomVariable = dossier.numero.replace(/\./g, "") + "_CCTP"
      if(dossier.listfilesCCTP && dossier.listfilesCCTP.length > 0){
        var randomVariable = dossier.numero.replace(/\./g, "") + "_CCTP("+ dossier.listfilesCCTP.length  +")"
      }
      var imgObject = {
        randomVariable:randomVariable,
        name:this.selectedcctp.name,
        photo: environment.filesPath + randomVariable + "." + fileExtensionn
      }
      dossier.listfilesCCTP.push(imgObject)
    }
    if (this.selectedplan) {
      var fileExtensionn = this.selectedplan.name.substr((this.selectedplan.name.lastIndexOf('.') + 1));
      //var photo = this.utilisateur.id + i + '.' + fileExtension
      var randomVariable = dossier.numero.replace(/\./g, "") + "_Plan"
      if(dossier.listfilesPlan && dossier.listfilesPlan.length > 0){
        var randomVariable = dossier.numero.replace(/\./g, "") + "_Plan("+ dossier.listfilesPlan.length +")"
      }
      var imgObject = {
        randomVariable:randomVariable,
        name:this.selectedplan.name,
        photo: environment.filesPath + randomVariable + "." + fileExtensionn
      }
      dossier.listfilesPlan.push(imgObject)
    }
    var newClient = {
      "denomination": this.form.value.client.denomination,
      "acronyme": this.form.value.client.acronyme,
      "id": this.form.value.client.id
    }
    var newChargeAffaire = {
      "nom": this.form.value.chargeAffaire.nom,
      "prenom": this.form.value.chargeAffaire.prenom,
      "id": this.form.value.chargeAffaire.id,
      "mail": this.form.value.chargeAffaire.mail,
      "tel": this.form.value.chargeAffaire.tel
    }
    var newUser = {
      "nom": this.utilisateur.nom,
      "prenom": this.utilisateur.prenom,
      "id": this.utilisateur.id
    }
    dossier.client = newClient
    dossier.chargeAffaire = newChargeAffaire
    dossier.user = newUser
    if(this.etablissement.nom){
      dossier.etablissement = this.etablissement.nom
    }
    this.dossierService.editDossier(dossier)
      .subscribe(resp => {
      }, err => {

      })
    if (this.selectedFiles) {
      this.currentFileUpload = this.selectedFiles
      this.uploadFileService.pushFilesToStorage(this.currentFileUpload, dossier.listfiles[dossier.listfiles.length - 1].randomVariable).subscribe(event => {
      }, err => {
      })
    }
    if (this.selectedEmails) {
      this.currentFileUpload = this.selectedEmails
      this.uploadFileService.pushFilesToStorage(this.currentFileUpload, dossier.listfilesEmail[dossier.listfilesEmail.length - 1].randomVariable).subscribe(event => {
      }, err => {
      })
    }
    if (this.selectedcctp) {
      this.currentFileUpload = this.selectedcctp
      this.uploadFileService.pushFilesToStorage(this.currentFileUpload, dossier.listfilesCCTP[dossier.listfilesCCTP.length - 1].randomVariable).subscribe(event => {
      }, err => {
      })
    }
    if (this.selectedplan) {
      this.currentFileUpload = this.selectedplan
      this.uploadFileService.pushFilesToStorage(this.currentFileUpload, dossier.listfilesPlan[dossier.listfilesPlan.length - 1].randomVariable).subscribe(event => {
      }, err => {
      })
    }
    setTimeout(() => {
      /** spinner ends after 6 seconds **/
      this.loading = false;
      this.router.navigate(['/dossier'])
      Swal.fire( {
          imageUrl: 'assets/images/statut-valide-hover.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 800,
          title: 'BIEN MODIFIÉ',
          timer: 1500,
          showConfirmButton: false
        }
      );
    }, 5000);
  }
  // trier le client
  triClient(a, b) {
    if (a.denomination.toLowerCase() < b.denomination.toLowerCase()) return -1;
    else if (a.denomination.toLowerCase() == b.denomination.toLowerCase()) return 0;
    else return 1;
  }
  // trier le chargés d'affaires
  triChargesAffaires(a, b) {
    if (a.nom.toLowerCase() < b.nom.toLowerCase()) return -1;
    else if (a.nom.toLowerCase() == b.nom.toLowerCase()) return 0;
    else return 1;
  }
  removeFichier(fichiersFile){
    Swal.fire({
          title: 'VOULEZ-VOUS SUPPRIMER CE FICHIER?',
          imageUrl: 'assets/images/supp-modal.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          showCancelButton: true,
          width: 800,
          confirmButtonColor: '#059e00',
          cancelButtonColor: '#FF9100',
          cancelButtonText: 'ANNULER',
          confirmButtonText: 'VALIDER'
        }).then((result) => {
          if (!result.dismiss) {
            Swal.fire( {
                imageUrl: 'assets/images/supp-modal.png',
                imageWidth: 100,
                imageHeight: 100,
                imageAlt: 'Custom image',
                width: 800,
                title: 'BIEN SUPPRIMÉ',
                timer: 1500,
                showConfirmButton: false
              }
            );
            this.dossier.listfiles.splice(this.dossier.listfiles.indexOf(fichiersFile), 1);

          }
        } );
  }
  // supprimer le fichier mail
  removeMail(emailFile){
    Swal.fire({
          title: 'VOULEZ-VOUS SUPPRIMER CE FICHIER?',
          imageUrl: 'assets/images/supp-modal.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          showCancelButton: true,
          width: 800,
          confirmButtonColor: '#059e00',
          cancelButtonColor: '#FF9100',
          cancelButtonText: 'ANNULER',
          confirmButtonText: 'VALIDER'
        }).then((result) => {
          if (!result.dismiss) {
            Swal.fire( {
                imageUrl: 'assets/images/supp-modal.png',
                imageWidth: 100,
                imageHeight: 100,
                imageAlt: 'Custom image',
                width: 800,
                title: 'BIEN SUPPRIMÉ',
                timer: 1500,
                showConfirmButton: false
              }
            );
            this.dossier.listfilesEmail.splice(this.dossier.listfilesEmail.indexOf(emailFile), 1);

          }
        } );
  }
  removeCCTP(cctpFile){
    Swal.fire({
          title: 'VOULEZ-VOUS SUPPRIMER CE FICHIER?',
          imageUrl: 'assets/images/supp-modal.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          showCancelButton: true,
          width: 800,
          confirmButtonColor: '#059e00',
          cancelButtonColor: '#FF9100',
          cancelButtonText: 'ANNULER',
          confirmButtonText: 'VALIDER'
        }).then((result) => {
          if (!result.dismiss) {
            Swal.fire( {
                imageUrl: 'assets/images/supp-modal.png',
                imageWidth: 100,
                imageHeight: 100,
                imageAlt: 'Custom image',
                width: 800,
                title: 'BIEN SUPPRIMÉ',
                timer: 1500,
                showConfirmButton: false
              }
            );
            this.dossier.listfilesCCTP.splice(this.dossier.listfilesCCTP.indexOf(cctpFile), 1);

          }
        } );
  }
  removePlan(planFile){
    Swal.fire({
          title: 'VOULEZ-VOUS SUPPRIMER CE FICHIER?',
          imageUrl: 'assets/images/supp-modal.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          showCancelButton: true,
          width: 800,
          confirmButtonColor: '#059e00',
          cancelButtonColor: '#FF9100',
          cancelButtonText: 'ANNULER',
          confirmButtonText: 'VALIDER'
        }).then((result) => {
          if (!result.dismiss) {
            Swal.fire( {
                imageUrl: 'assets/images/supp-modal.png',
                imageWidth: 100,
                imageHeight: 100,
                imageAlt: 'Custom image',
                width: 800,
                title: 'BIEN SUPPRIMÉ',
                timer: 1500,
                showConfirmButton: false
              }
            );
            this.dossier.listfilesPlan.splice(this.dossier.listfilesPlan.indexOf(planFile), 1);
          }
        } );
  }
  getDateReceptionDossier(): string {
    return this.dossier.dateReception
  }
}

