import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http } from '@angular/http';
import { UserService } from "../../../services/user.service";
import * as XLSX from "xlsx";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
import * as moment from 'moment';
import { RoleService } from 'src/app/services/role.service';
import { AgenceService } from 'src/app/services/agence.service';
@Component({
  selector: 'app-agence-voyage',
  templateUrl: './agence-voyage.component.html',
  styleUrls: ['../../utilisateur/utilisateur/utilisateur.component.css'],
})
export class AgenceVoyageComponent implements OnInit {

  public searched: any = '';
  agences: any;
  agence: any;
  showSuccessUpdate = false;
  allowInformations = true;
  allowInformationsComp = false;
  allowInformationsBanc = false;
  allowEtablissements = false;
  allowReseauxSociaux = false;
  administration = false;
  role: any;
  numberData = 25;
  utilisateur: any;
  id: any;
  listeagenceExcel: any[] = []
  dateagence: any;
  p = 1
  hasRoleDelete = false;
  hasRoleUpdate = false;
  hasRoleca = false;
  hasRoleAdd = false;
  constructor(private http: Http, private agenceService: AgenceService, private userService: UserService, private roleService: RoleService,
    private router: Router) {

  }
  ngOnInit() {
    this.id = localStorage.getItem('idUser')
    this.userService.getUserByID(this.id).subscribe(utilisateur => {
      this.utilisateur = utilisateur;
      if (!this.utilisateur || (this.utilisateur && !this.utilisateur.idRole)) {
        localStorage.removeItem('idUser');
        localStorage.clear();
        this.router.navigate(['/login']);
      }
      this.roleService.getRoleById(this.utilisateur.idRole).subscribe(role => {
        this.role = role;
        if (this.role && this.role.privileges && this.role.privileges.length > 0) {
          let founddeleteUtilisateur = this.role.privileges.find(x => x.name == "DELETE_CLIENT")
          let foundupdateUtilisateur = this.role.privileges.find(x => x.name == "UPDATE_CLIENT")
          let foundaddclinet = this.role.privileges.find(x => x.name == "ADD_CLIENT")
          let foundaddca = this.role.privileges.find(x => x.name == "GET_ALL_CA")
          if (founddeleteUtilisateur) {
            this.hasRoleDelete = true;
          }
          if (foundupdateUtilisateur) {
            this.hasRoleUpdate = true;
          }
          if (foundaddclinet) {
            this.hasRoleAdd = true;
          }
          if (foundaddca) {
            this.hasRoleca = true;
          }
          
        }
      },
        err => {
          return false;
        });
    },
      err => {
        return false;
      });
    this.getAllClients();
    this.role = localStorage.getItem('roleUser')
    if (this.role) {
      this.administration = true;
    }

  }
  // recuperer tous les clients
  getAllClients() {
    this.agenceService.getAllUsers().then(data => {
      this.agences = data;
      //this.toFileXLS()
    },
      err => {
        return false;
      });
  }
  // supprimer un client
  removeagence(client) {
    if (this.administration) {
      return false
    } else {
      Swal.fire({
        title: 'VOULEZ-VOUS SUPPRIMER CETTE AGENCE?',
        imageUrl: 'assets/images/supp-modal.png',
        imageWidth: 100,
        imageHeight: 100,
        imageAlt: 'Custom image',
        showCancelButton: true,
        width: 800,
        confirmButtonColor: '#059e00',
        cancelButtonColor: '#FF9100',
        cancelButtonText: 'ANNULER',
        confirmButtonText: 'VALIDER'
      }).then((result) => {
        if (!result.dismiss) {
          Swal.fire({
            imageUrl: 'assets/images/supp-modal.png',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            width: 800,
            title: 'BIEN SUPPRIMÉ',
            timer: 1500,
            showConfirmButton: false
          }
          );
          this.agenceService.deleteClientById(client.id).then(data => {
          });
          this.agences.splice(this.agences.indexOf(client), 1);
          this.getAllClients()
        }
      });
    }
  }


  toFileXLS() {

    for (var i = 0; i < this.agences.length; i++) {
      if (this.agences[i].datec && this.agences[i].datec.indexOf('à') > -1) {
        this.dateagence = this.agences[i].datec.split(' à')[0]
      }
      if (this.agences[i].datec && this.agences[i].datec.indexOf('à') < 0) {
        this.dateagence = moment(this.agences[i].datec).format('DD/MM/YYYY');
      }
      var devExcel = {
        "SIRET": this.agences[i].siren,
        "Date de création": this.dateagence,
        "Dénomination": this.agences[i].denomination,
        "Acronyme": this.agences[i].acronyme,
        "Forme juridique": this.agences[i].formeJuridique,
        "Code NAF": this.agences[i].indicatif,
        "Type": this.agences[i].type,
        "Email": this.agences[i].mail,
        "Téléphone": this.agences[i].tel,
        "Adresse": this.agences[i].adresse.n + ' ' + this.agences[i].adresse.rue + ' ' + this.agences[i].adresse.codePostal,
        "Ville": this.agences[i].adresse.ville,
        "Pays": this.agences[i].adresse.pays
      }
      this.listeagenceExcel.push(devExcel)
    }

    const workBook = XLSX.utils.book_new(); // create a new blank book
    const workSheet = XLSX.utils.json_to_sheet(this.listeagenceExcel);

    XLSX.utils.book_append_sheet(workBook, workSheet, 'data'); // add the worksheet to the book
    var currentDate = new Date().toISOString().split('T')[0]
    var mydate = currentDate[8] + currentDate[9] + currentDate[5] + currentDate[6] + currentDate[2] + currentDate[3]
    XLSX.writeFile(workBook, 'VoWork-agences-' + mydate + '.xlsx'); // initiate a file download in browser
  }
}

