<div class="content">
  <div class="row">
    <img src="assets/images/logo.png" style="width: 210px;" *ngIf="isvoguel" />
    <img src="assets/images/logocaravanserail.jpeg" style="width: 157px;position: relative;top: 20px;" *ngIf="!isvoguel" />
  </div>
  <div class="row ">
    <div class="col-lg-6 col-md-12  content-page">
      <div class="titre">
        Gérez vos données en toute sécurité
      </div>
      <div class="sous-titre">
        Accès rapide, un contôle des erreurs et une réduction des tâches quotidiennes et de saisie à effectuer.<br>
        Cette plateforme permet la gestion des clients, la gestion des catalogues, la gestion des commandes, organiser
        et unifier les processus de gestion des dossiers et des devis.
      </div>
      <div>
        <!-- <button data-toggle="modal" data-target="#ModalWrong" class="btn-connexion" > CONNEXION </button> -->
        <button data-toggle="modal" data-target="#ModalSuccess" (click)="open(mymodal)" class="btn-connexion"> CONNEXION
        </button>

      </div>
    </div>


    <div class="col-lg-6 col-md-12 ">
      <img class="image-connexion" src="assets/images/gestion.png" width="600px" />
    </div>

  </div>

  <!-- Modal Success-->
  <ng-template #mymodal let-modal>
    <div class="modal-header" style="height: 50px;">
      <button type="button" class="close disBtn" aria-label="Close" (click)="modal.dismiss('Cross click')" id="disBtnn">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <form (submit)="loginAdmin()">
        <div class="msgClassAlerte">
          <flash-messages style="font-size:15px;color:white" class="alert-msg-danger"></flash-messages>
        </div>
        <input type="text" placeholder="Email" name="email" [(ngModel)]="email" />
        <input type="password" placeholder="Mot de passe" name="password" [(ngModel)]="password" />
        <div class="row mt-3 ml-5">
          <div class="col-sm-12 mb-2">
            <re-captcha id="recaptcha"  name="recaptcha" #recaptcha="ngModel" [(ngModel)]="tokenRecaptcha" required [class.is-invalid]="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)"></re-captcha>
          </div>
        </div>
        <!--<p class="MDP pull-right"><a [routerLink]="['/passwordRecovery']" (click)="initiliaseModal()">Mot de passe oublié ?</a></p>-->
        <div>
          <button class="btn-connexion btn-modal" type="submit">CONNEXION</button>
          <button id="closeModalButton" [hidden]="true" data-toggle="modal" data-target="#myModal"
            class="btn btn-default" data-dismiss="modal" style="visibility: hidden;">Annuler</button>
        </div>
      </form>
    </div>

  </ng-template>


  <!-- ------------------------------------------->

  <!-- ------------------------------------------->
</div>