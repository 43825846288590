<app-navbar></app-navbar>  
<br>
<div id="content-nav-page" class="row" >
    <div class=" col-3 titre-menu">
      <b style="margin-right: 10%" class="title-haut">RÔLES</b>
      <img src="assets/images/client-plus.png"  *ngIf="hasRoleAdd" routerLink="/ajout/role">
    </div>
    <div class="col-9 sous-menu">
      <b class="sous-menu-active2 ml-4"  [routerLink]="['/role']"
        style="cursor: pointer">RÔLES</b>
    </div>
  </div>
  <div id="content-page" class="containe-fluid " >
    <div class="row  ">
      <div class="col-lg-4 col-md-10 col-sm-10  search">
        <input class="search-bar" type="text" placeholder="Rechercher.." [(ngModel)]="searched" name="searched">
      </div>
      <div class="col-lg-1 col-md-2 col-sm-2">
        <select class="select-input-nombre" name="numberData" [(ngModel)]="numberData">
          <option>5</option>
          <option selected>10</option>
          <option>25</option>
          <option>50</option>
        </select>
      </div>
      <div class=" col d-flex justify-content-end">
        <div class="col-auto nbr-dossier ">
          <table>
            <tr>
              <td>
                <b>Rôles</b>
              </td>
            </tr>
            <tr>
              <td>
                <b class="sous-nbr-dossier d-flex justify-content-center" *ngIf="roles">{{roles.length}}</b>
              </td>
            </tr>
          </table>
        </div>
        <div class="col-auto btn-devis ">
        </div>
      </div>
    </div>
    <!-- tableau -->
    <div class="row" style="overflow-x:auto;">
      <table class="table table-striped table-hover">
        <th class="left">NOM</th>
        <th class="left">SLUG</th>
        <th class="text-center " style="margin-left: 100px; text-align: right" >RÉGLAGES</th>
        <tr class="text-center" *ngFor="let role of roles|searchRole  : searched.toString().toLowerCase()| paginate: { itemsPerPage: numberData, currentPage: p }">
        <td class="left"  >{{role.name}}</td>
        <td class="left" >{{role.slug}}</td>
        <td >
            <div class="dropdown">
              <button class="btn dropdown-toggle btn-options" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                OPTIONS
              </button>
              <div class="dropdown-menu reglageMenu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item option" title="Modifier" [routerLink]="['/role/details', role.id]" title="Modifier" *ngIf="hasRoleUpdate" >
                  <div class="options-modif-icon"></div>
                  <div class="option-text"> Modifier</div>
                </a>
                <a class="dropdown-item" title="Supprimer" (click)="removeRole(role)" title="Supprimer" *ngIf="hasRoleDelete">
                  <div class="options-supp-icon"></div>
                  <div class="option-text"> Supprimer</div>
                </a>
              </div>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div class="row">
     <div class="col col-xs-6">
          <pagination-controls previousLabel="Précédent" nextLabel="Suivant" (pageChange)="p = $event" #api></pagination-controls>
     </div>
   </div>
  </div>
  