import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FournisseurService } from "../../../services/fournisseur.service";
import * as XLSX from "xlsx";
import { CatalogueService } from "../../../services/catalogue.service";
import { UserService } from "../../../services/user.service";
import { CommandeService } from "../../../services/commande.service";
@Component({
  selector: 'app-details-fournisseur-factures-by-year-and-month',
  templateUrl: './details-fournisseur-factures-by-year-and-month.component.html',
  styleUrls: ['../../utilisateur/details-utilisateur/details-utilisateur.component.css'],
})
export class DetailsFournisseurFacturesByYearAndMonthComponent implements OnInit {
  fournisseur: any;
  roleAdministration = true;
  role: any;
  form:any;
  dateDebut = new Date().toISOString().split('T')[0]
  listeArtciels: any[]=[]
  lignesXLS: any[]=[]
  listeAllArtciels:any;
  loading = false;
  sizeLimitOne = 30000000; //30MB
  tarifGlobal = 0;
  addedLength = 0;
  errorObjet: any;
  errorDate = false
  fileToChoose: any;
  id:any;
  utilisateur:any;
  years: any[] = [];
  lengthdata = 0
  showAddCatalogue = false;
  commandes:any;
  params:any;
  p=1
  constructor(private commandeService:CommandeService, private fournisseurService: FournisseurService, private activatedRoute: ActivatedRoute,private router:Router,private catalogueService:CatalogueService,private userService:UserService) {

  }
  ngOnInit() {
    this.role = localStorage.getItem('roleUser')
    if (this.role) {
      this.roleAdministration = false;
    }
    this.id = localStorage.getItem('idUser')
    this.userService.getUserByID(this.id).subscribe(utilisateur => {
      this.utilisateur = utilisateur;
      if(!this.utilisateur){
        localStorage.removeItem('idUser');
        localStorage.clear();
        this.router.navigate(['/login']);
      }
    },
      err => {
        return false;
      });
    this.params = this.activatedRoute.snapshot.params;
    this.fournisseurService.getFournisseurByID(this.params.id).subscribe(fournisseur => {
      this.fournisseur = fournisseur;
      if(this.fournisseur){
        this.getAllCommandesByFounrisseurAndYear(this.fournisseur.id, this.params.year, this.params.month)
      }
    },
      err => {
        return false;
      });
}
getAllCommandesByFounrisseurAndYear(idFournisseur, year,month){
  this.loading = true;
  this.commandeService.getAllFactureByMonthAndYearFounisseurDateFacture(idFournisseur,year,month).then(data => {
    if(data && data["result"]) {
    this.commandes = data["result"]
    this.commandes.sort(this.triCommandeByYearDesc)
    this.loading = false;
  }
  this.loading = false;
  },
    err => {
      return false;
    });
}
triCommandeByYearDesc(a, b) {
  if (a.factures.dateFacture > b.factures.dateFacture) return -1;
  else if (a.factures.dateFacture == b.factures.dateFacture) return 0;
  else return 1;
}
toFileXLS(){
  console.log(this.commandes[0].factures.montantTVA)
  if(this.commandes.length < 1){
    return false;
  }
  this.loading = true;
  for(var i =0; i<this.commandes.length;i++){
    if(this.commandes[i].factures.sommePrixNetTTC == "" ||  this.commandes[i].factures.sommePrixNetTTC == "NaN"){
      this.commandes[i].factures.sommePrixNetTTC =  0
    }
    if(this.commandes[i].factures.sommePrixNet == "" ||  this.commandes[i].factures.sommePrixNet == "NaN"){
      this.commandes[i].factures.sommePrixNet =  0
    }
    if( this.commandes[i].factures.montantTVA == "" ||  this.commandes[i].factures.montantTVA == "NaN"){
      this.commandes[i].factures.montantTVA =  0
    }
    var devExcel = {
      "Numéro de commande GPMI": this.commandes[i].numeroCommandeGPMI,
      "Numéro de facture": this.commandes[i].factures.numFacture,
      "Date de facture": this.commandes[i].factures.dateFacture,
      "Somme prix Net HT":  Math.round(this.commandes[i].factures.sommePrixNet  * 100) / 100,
      "Somme montant TVA": Math.round(this.commandes[i].factures.montantTVA * 100) / 100,
      "Somme de prix net TTC": Math.round(this.commandes[i].factures.sommePrixNetTTC * 100) / 100,
      }

    this.lignesXLS.push(devExcel)
  }
  const workBook = XLSX.utils.book_new(); // create a new blank book
  const workSheet = XLSX.utils.json_to_sheet(this.lignesXLS);

  XLSX.utils.book_append_sheet(workBook, workSheet, 'data'); // add the worksheet to the book
  XLSX.writeFile(workBook, 'VoWork-Factures-'+ this.fournisseur.denomination+"-"+this.params.year+"-"+this.params.month+'.xlsx'); // initiate a file download in browser
  this.loading = false
}
}
