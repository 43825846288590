import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CaGuard } from 'src/app/guards/ca.guard';
import { ChargeAffaireComponent } from './charge-affaire/charge-affaire.component';
import { AjoutChargeAffaireComponent } from './ajout-charge-affaire/ajout-charge-affaire.component';
import { UpdateChargeAffaireComponent } from './update-charge-affaire/update-charge-affaire.component';
import { DetailsChargeAffairesComponent } from './details-charge-affaires/details-charge-affaires.component';

const routes: Routes = [
  { path: 'chargeAffaire', component: ChargeAffaireComponent, canActivate: [CaGuard]},
  { path: 'ajoutChargeAffaire', component: AjoutChargeAffaireComponent, canActivate: [CaGuard]},
  { path: 'chargeAffaire/details/:id', component: UpdateChargeAffaireComponent, canActivate: [CaGuard]},
  { path: 'details/chargeAffaire/:id', component: DetailsChargeAffairesComponent, canActivate: [CaGuard]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ChargeAffaireRoutingModule { }
