<app-navbar></app-navbar>
<br>
<div id="content-nav-page" class="row" *ngIf="utilisateur">
    <div class=" col-3 titre-menu">
      <b style="margin-right: 10%" class="title-haut">CHARGÉ D'AFFAIRES</b>
      <img src="assets/images/charge-plus.png" [routerLink]="['/ajoutChargeAffaire']" *ngIf="hasRoleAdd">
    </div>
    <div class="col-9 sous-menu">
      <b class="sous-menu-active2" *ngIf="hasRoleclient" [routerLink]="['/client']">CLIENT</b>
      <b class="sous-menu-active1 ml-4"  [routerLink]="['/chargeAffaire']" style="cursor: pointer">CHARGÉ D'AFFAIRES</b>
    </div>
  </div>
  <div id="content-page" class="containe-fluid">
    <div class="row  ">
      <div class="col-lg-4 col-md-10 col-sm-10  search">
        <input class="search-bar" type="text" [(ngModel)]="filterCA" name="filterCA" (keyup)="valuechange($event)" placeholder="Rechercher..">
      </div>
      <div class="col-lg-1 col-md-2 col-sm-2">
        <select class="select-input-nombre" name="numberData" [(ngModel)]="numberData">
          <option>5</option>
          <option selected>10</option>
          <option>25</option>
          <option>50</option>
        </select>
      </div>
  
      <div class=" col d-flex justify-content-end">
        <div class="col-auto nbr-dossier ">
          <table>
            <tr>
              <td>
                <b>Chargés d'affaires</b>
              </td>
            </tr>
            <tr>
              <td>
                <b class="sous-nbr-dossier d-flex justify-content-center" *ngIf="lenghtData">{{lenghtData}}</b>
              </td>
            </tr>
          </table>
        </div>
  
        <div class="col-auto btn-devis ">
          <a *ngIf="utilisateur && utilisateur.role == 'Super admin'" (click)="toFileXLS()" class="btn-connexion">Exporter<img class="ml-1" src="assets/images/excel.png"></a>
        </div>
      </div>
    </div>
    <!-- tableau -->
    <div class="row" style="overflow-x:auto;">
      <table class="table table-striped table-hover">
        <th class="left">NOM</th>
        <th class="left">PRÉNOM</th>
        <th class="left">CLIENT</th>
        <th class="left">POSTE</th>
        <th class="left">EMAIL</th>
        <th class="left">TÉLÉPHONE</th>
        <th class="left" *ngIf="!administration && utilisateur && utilisateur.role != 'Admin' && utilisateur.role != 'Responsable finance'">ADRESSE</th>
        <th class="left" *ngIf="administration">VILLE</th>
        <th class="left">PAYS</th>
        <th *ngIf="!administration && utilisateur && utilisateur.role != 'Admin' && utilisateur.role != 'Responsable finance'">RÉGLAGES</th>
        <tr class="text-center" *ngFor="let chargeAffaire of chargeAffaires|searchChargeAffaires  : filterCA.toString().toLowerCase()|paginate: { itemsPerPage: numberData, currentPage: p }">
          <td class="left" [routerLink]="['/details/chargeAffaire', chargeAffaire.id]">{{chargeAffaire.nom}}</td>
          <td class="left" [routerLink]="['/details/chargeAffaire', chargeAffaire.id]">{{chargeAffaire.prenom}}</td>
          <td  class="left" [routerLink]="['/details/chargeAffaire', chargeAffaire.id]"><span *ngIf="chargeAffaire.clients && chargeAffaire.clients[0]">{{chargeAffaire.clients[0].denomination}}</span> <span
              *ngIf="chargeAffaire.clients && chargeAffaire.clients.length === 0 && chargeAffaire.client">{{chargeAffaire.client.denomination}}</span></td>
          <td class="left" [routerLink]="['/details/chargeAffaire', chargeAffaire.id]">{{chargeAffaire.poste.titre}}</td>
          <td class="left" [routerLink]="['/details/chargeAffaire', chargeAffaire.id]">{{chargeAffaire.mail}}</td>
          <td class="left" [routerLink]="['/details/chargeAffaire', chargeAffaire.id]">{{chargeAffaire.tel}}</td>
          <td class="left" *ngIf="!administration && utilisateur && utilisateur.role != 'Admin' && utilisateur.role != 'Responsable finance'" [routerLink]="['/details/chargeAffaire', chargeAffaire.id]">
            <span *ngIf="chargeAffaire.client.etablissement">
              <span *ngIf="chargeAffaire.client.etablissement.id === null">{{chargeAffaire.client.etablissement.adresse.n}} {{chargeAffaire.client.etablissement.adresse.rue}} {{chargeAffaire.client.etablissement.adresse.codePostal}}
                {{chargeAffaire.client.etablissement.adresse.ville}} </span>
              <span *ngIf="chargeAffaire.client.etablissement.id === undefined && chargeAffaire.clients[0] ">{{chargeAffaire.clients[0].adresse.n}} {{chargeAffaire.clients[0].adresse.rue}} {{chargeAffaire.clients[0].adresse.codePostal}} {{chargeAffaire.clients[0].adresse.ville}}
              </span>
            </span>
          </td>
          <td class="left" *ngIf="administration" [routerLink]="['/details/chargeAffaire', chargeAffaire.id]">
            <span *ngIf="chargeAffaire.client.etablissement">
              <span *ngIf="chargeAffaire.client.etablissement.id === null">{{chargeAffaire.client.etablissement.adresse.ville}} </span>
              <span *ngIf="chargeAffaire.client.etablissement.id === undefined && chargeAffaire.clients[0] "> {{chargeAffaire.clients[0].adresse.ville}} </span>
            </span>
          </td>
          <td class="left" [routerLink]="['/details/chargeAffaire', chargeAffaire.id]">
            <span *ngIf="chargeAffaire.client.etablissement">
              <span *ngIf="chargeAffaire.client.etablissement.id === undefined && chargeAffaire.clients[0] ">{{chargeAffaire.clients[0].adresse.pays[0]| uppercase}}{{chargeAffaire.clients[0].adresse.pays[1] | uppercase}}</span>
              <span *ngIf="chargeAffaire.client.etablissement.id === null">{{chargeAffaire.client.etablissement.adresse.pays[0]| uppercase}}{{chargeAffaire.client.etablissement.adresse.pays[1]| uppercase}}</span>
            </span>
          </td>
          <td *ngIf="!administration && utilisateur && utilisateur.role != 'Admin' && utilisateur.role != 'Responsable finance'">
            <div class="dropdown">
              <button class="btn dropdown-toggle btn-options" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                OPTIONS
              </button>
              <div class="dropdown-menu reglageMenu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item option" [routerLink]="['/details/chargeAffaire', chargeAffaire.id]">
                  <div class="options-show-icon"></div>
                  <div class="option-text"> Afficher</div>
                </a>
  
                <a class="dropdown-item option" title="Modifier" [routerLink]="['/chargeAffaire/details', chargeAffaire.id]" *ngIf="hasRoleUpdate">
                  <div class="options-modif-icon"></div>
                  <div class="option-text"> Modifier</div>
                </a>
                <a class="dropdown-item" (click)="removechargAff(chargeAffaire)" title="Supprimer" *ngIf="hasRoleDelete">
                  <div class="options-supp-icon"></div>
                  <div class="option-text"> Supprimer</div>
                </a>
              </div>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div class="row">
      <div class="col col-xs-6">
        <pagination-controls previousLabel="Précédent" nextLabel="Suivant" (pageChange)="p = $event" #api></pagination-controls>
      </div>
    </div>
  
  </div>
  <br><br><br><br><br><br>
  <!-- end  liste des CA -->
  
  <div class="search-results" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50" (scrolled)="onScroll()"></div>
  