import {HttpClient, HttpEvent, HttpRequest,HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {environment} from '../../environments/environment';
const headers = new HttpHeaders({Authorization: 'Basic ' + btoa('UserkrendelAdminnew:krl2021!!??**SoC24-415fr-a410-ttx9J8NbPecEL6k4BkAXvGE3t8f3nuEZmXa5RNJBAdrnh6XqB2')})
  .set('Accept', 'application/json');

@Injectable({
  providedIn: 'root'
})
export class UploadFileService {

  constructor(private http: HttpClient) {
  }


  pushFileToStorage(file: File,id): Observable<HttpEvent<{}>> {
  let formdata: FormData = new FormData();

  formdata.append('file', file);
  const req = new HttpRequest('POST', environment.host +'/post/'+id, formdata, {
    reportProgress: true,
    responseType: 'text',
    headers: headers
  });

  return this.http.request(req);
}
getFiles(): any {
    return this.http.get(environment.host +'/getallfiles',{ headers: headers })
  }
getOneFiles(file):any {
  return this.http.get(environment.host +'/files/multiple/JCMT6FU0Z0F3VBAR8B2.pdf',{ headers: headers })
}

// push multiple files

pushFilesToStorage(file: File,id): Observable<HttpEvent<{}>> {
let formdata: FormData = new FormData();

formdata.append('file', file);
const req = new HttpRequest('POST', environment.host +'/post/multiple/files/'+id, formdata, {
  reportProgress: true,
  responseType: 'text',
  headers: headers
});

return this.http.request(req);
}

}
