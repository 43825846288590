import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
const headers = new HttpHeaders({Authorization: 'Basic ' + btoa('UserkrendelAdminnew:krl2021!!??**SoC24-415fr-a410-ttx9J8NbPecEL6k4BkAXvGE3t8f3nuEZmXa5RNJBAdrnh6XqB2')})
.set('Content-Type', 'application/json')
.set('Accept', 'application/json');
headers.append('Access-Control-Allow-Origin', '*');
import 'rxjs/add/operator/map';

@Injectable({
  providedIn: 'root'
})
export class RhService {
  constructor(public http: HttpClient) {
  }

    registerSalarie(salarie) {
      return this.http.post(environment.host + '/salarie/newSalarie', JSON.stringify(salarie), {headers: headers})
    }
    getAllUsers() {
      return new Promise(resolve => {
        this.http.get(environment.host + '/salarie', {headers: headers}).subscribe(data => {
          resolve(data);
        }, err => {
        });
      });
    }
    getSalarieByID(id){
      return this.http.get(environment.host + '/salarie/'+id, {headers: headers})
    }
    editSalarieById(salarie){
      return this.http.put(environment.host + '/salarie/' + salarie.id, JSON.stringify(salarie), { headers: headers })
    }
    deleteSalarieById(id) {
      return new Promise(resolve => {
        this.http.delete(environment.host + '/salarie/' + id, {headers: headers}).subscribe(data => {
          resolve(data);
        }, err => {
        });
      });
    }
    verifyEmail(salarie) {
      return this.http.post(environment.host + '/salarie/verifiedEmail', JSON.stringify(salarie), { headers: headers })
    }

}
