<app-navbar></app-navbar>
<br>
<div id="content-nav-page" class="row">
    <div class=" col-3 titre-menu">
      <a routerLink="/commande" class=" icon-f-left"></a>
      <b style="margin-right: 10%" class="title-haut">Nouvelle Commande</b>
      <img src="assets/images/commande.png">
    </div>
    <div class="col-9 sous-menu" *ngIf="utilisateur">
      <b class="sous-menu-active2" [routerLink]="['/dossier']" *ngIf="utilisateur.role === 'Super admin' || utilisateur.role === 'Admin' || utilisateur.role === 'Equipe chiffrage' || utilisateur.role === 'Administration' || (utilisateur.role === 'Equipe Atelier' && utilisateur.sousRole === 'Responsable magasin') || utilisateur.role === 'Chiffreur'">DOSSIER</b>
      <b class="sous-menu-active1 ml-4" [routerLink]="['/commande']" style="cursor: pointer">COMMANDE</b>
  
    </div>
  </div>
  <ng-template #customLoadingTemplate></ng-template>
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading>
  <div class="card p-3">
    <div class="col d-flex justify-content-start">
      <b class="obligatoir-text">(*) Ce champ est obligatoire</b>
    </div>
    <div class="card-header " style="border: 1px solid green;margin-bottom: 1%;">
      <b style="color:grey">INFORMATIONS</b>
    </div>
  
    <div *ngIf="showPart()==1">
      <form [formGroup]="form" role="form">
        <div class="card-body">
  
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-10">
              <div class="title">
                <select class="select-statut" [attr.disabled]="showArray == true && lignes.length > 0 ?  true : null" formControlName="fournisseur" name="fournisseur"  (change)="myCallback($event)" style="width: 100%;background: #ffffff;position: relative;left: -9px;">
                  <option value="" disabled selected>FOURNISSEUR*</option>
                  <option *ngFor="let fournisseur of fournisseurs" [ngValue]="fournisseur">{{fournisseur.denomination}}</option>
                </select>
                <control-messages [control]="form.controls.fournisseur"></control-messages>
                <p style="color:red" *ngIf="errorFournisseur">Champ invalide</p>
              </div>
              <br />
              <div class="title ">
                <input class="font-weight-bold" placeholder="Numéro de commande GPMI*" formControlName="numeroCommandeGPMI" id="numeroCommandeGPMI" name="numeroCommandeGPMI" [attr.disabled]="showArray == true && lignes.length > 0 ?  true : null">
                <control-messages [control]="form.controls.numeroCommandeGPMI"></control-messages>
                <p style="color:red;" *ngIf="errorNumeroCommandeGPMI">Ce numéro existe déjà !</p>
                <p *ngIf="form.controls['numeroCommandeGPMI'].touched && form.controls['numeroCommandeGPMI'].hasError('maxlength')" style="color:red">
                  25 caractères maximum
                </p>
  
              </div>
              <br />
            </div>
            <br>
            <div class="col-lg-6 col-md-6 col-sm-10">
              <div class="title" style="margin-top: -1px;">
                <input class="font-weight-bold" type="date" placeholder="Date de commande*" [max]="getBigDate()" [(ngModel)]="dateCommande" formControlName="dateCommande" name="dateCommande" [attr.disabled]="showArray == true && lignes.length > 0 ?  true : null">
                <control-messages [control]="form.controls.dateCommande"></control-messages>
              </div>
              <br />
              <div class="title" style="margin-top: -5px;">
                <input class="font-weight-bold" type="date" placeholder="Date de livraison*" [(ngModel)]="dateLivraison" [min]="getDateCommande()" [max]="getBigDate()" formControlName="dateLivraison" id="dateLivraison" name="dateLivraison"
                  [attr.disabled]="showArray == true && lignes.length > 0 ?  true : null">
                <control-messages [control]="form.controls.dateLivraison"></control-messages>
                <p style="color:red" *ngIf="errorDate">Vérifier la date de livraison</p>
              </div>
              <br />
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-10">
              <div class="col-lg-12 col-md-12 col-sm-12" style="margin-bottom: -2px;margin-top: -10px;"
                *ngIf="(utilisateur && utilisateur.role === 'Super admin') || (utilisateur && utilisateur.role === 'Administration') ||  (utilisateur && utilisateur.role === 'Responsable commandes')">
                <img src="assets/images/add-cmd.png" style="margin-left: 25px;cursor: pointer;" *ngIf="form.controls.factures.controls.length < 30" (click)="addFacture()" title="Ajouter une facture">
              </div>
              <div class="col-lg-12 col-md-12 col-sm-12" formArrayName="factures"
                *ngIf="(utilisateur && utilisateur.role === 'Super admin') || (utilisateur && utilisateur.role === 'Administration') || (utilisateur && utilisateur.role === 'Responsable commandes')" style="margin-bottom: -5px;">
                <div class="col-lg-12 col-md-12 col-sm-12" *ngFor="let facture of form.controls.factures.controls; let i=index">
                  <div class="col-lg-12 col-md-12 col-sm-10" [formGroupName]="i">
                    <div class="col-lg-6 col-md-6 col-sm-10">
                      <input type="text" class="font-weight-bold" formControlName="numFacture" id="numFacture" name="numFacture"  placeholder="Numéro de facture" style="width: 93%;color:green" (change)="updateNumFacture()" (keyup)="updateNumFacture()">
                      <img src="assets/images/supp-modal.png" title="Supprimer une facture" (click)="removeFacture(i)" *ngIf="form.controls.factures.controls.length > 1" class="delete-img" style="width:4%">
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-10">
                      <input type="date" class="font-weight-bold" placeholder="Date de facture" [min]="getDateFacture()" [max]="getBigDate()" formControlName="dateFacture" id="dateFacture" name="dateFacture"  style="width: 93%;color:green">
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-sm-12" style="margin-top:-2%">
                <p style="color:red" *ngIf="errorDateFacture">Vérifier les dates de facture</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <p style="color:red;position: relative;left: 50px;top: 40px;" *ngIf="errorNumFacture">{{errorNumFacture}}</p>
        </div>
        <div class=" col d-flex justify-content-center" style="padding-bottom: 1%">
          <div class="col-auto nbr-dossier ">
            <table>
              <tr>
                <td style="padding-right: 30px;">
                  <button class="btn-valider" style="width: 200px;height: 50px;" [disabled]="!form.valid" (click)="showArrayFunction()">Suivant <img src="assets/images/btn-valider.png"></button>
                </td>
  
              </tr>
  
            </table>
          </div>
  
        </div>
      </form>
    </div>
  
  
  
    <div class="card-header " style="border: 1px solid green;margin-bottom: 1%;">
      <b style="color:grey">LIGNES </b>
    </div>
    <div *ngIf="showPart()==2">
      <div class="card-body">
  
        <div class="row" style="margin-bottom: -5%">
          <div class="col-12" style="padding-bottom: 2%;margin-top: 3%">
            <div>
              <div>
                <div class="title">
                  <h4>Les lignes</h4>
  
                </div>
  
  
                <div style="margin-top: 80px">
                  <div class="row ">
                    <table class="table-ligne table-responsive">
                      <tr>
                        <th>POSTE</th>
                        <th>N° DOSSIER</th>
                        <th>NOM DOSSIER</th>
                        <th>CLIENT</th>
                        <th>RÉFÉRENCE</th>
                        <th>DÉSIGNATION</th>
                        <th>PRIX TARIF HT</th>
                        <th>PRIX NET HT</th>
                        <th>COEF D'ACHAT</th>
                        <th>QUANTITÉ </th>
                        <th>PRIX NET HT TOTAL </th>
                        <th>ACTIONS</th>
                      </tr>
                      <tr *ngFor="let field of lignes; let i = index">
                        <td>
                          {{i+1}}0
                        </td>
                        <td *ngIf="!this['demandeUpdate' + i]">
                          {{field.numero}}
                        </td>
                        <td *ngIf="this['demandeUpdate' + i]">
  
                          <input *ngIf="this['demandeUpdate' + i]" type="text" [(ngModel)]="field.numero" name="numero" (click)="initiliaseDossierr(field)" min-chars="3" style="height: 32px;text-align: center;"
                            (valueChanged)="valuechangee($event,field)" no-match-found-text="Aucun élément" max-num-list="10" ngui-auto-complete [source]="dossiers" select-value-of="dossier" display-property-name="dossier" class="form-control input-sm"
                            id="dossier" placeholder="Numéro du dossier">
                        </td>
                        <td>
                          {{field.nomDossier}}
                        </td>
                        <td>
                          {{field.client}}
                        </td>
                        <td *ngIf="!this['demandeUpdate' + i]">
                          {{field.refArt}}
                        </td>
  
                        <td *ngIf="this['demandeUpdate' + i]">
                          <input type="text" [(ngModel)]="field.refArt" name="refArt" style="height: 32px;text-align: center;" (keyup)="valuechangeReferencee(field.refArt,field)" class="form-control input-sm" placeholder="Référence">
                        </td>
  
                        <td>
                          {{field.designation}}
                        </td>
                        <td>
                          {{field.pritar | number: '1.2-2'| replace:',':' ' | replace:'.':','}}
                        </td>
                        <td *ngIf="!this['demandeUpdate' + i]">
                          <span> {{field.prinet | number: '1.2-2'| replace:',':' ' | replace:'.':','}}</span>
  
                        </td>
                        <td *ngIf="this['demandeUpdate' + i]">
                          <input type="number" [(ngModel)]="field.prinet" name="prinet" min="0" value="0" style="height: 32px;width: 85px;text-align: center;" (keyup)="updatePrinNett(field.prinet,field)" (change)="updatePrinNett(field.prinet,field)"
                            class="form-control input-sm" placeholder="Prix net">
                        </td>
                        <td *ngIf="!this['demandeUpdate' + i]">
                          <span> {{field.coef | number: '1.4-4'| replace:',':' ' | replace:'.':','}}</span>
                        </td>
                        <td *ngIf="this['demandeUpdate' + i]">
                          <input type="number" [(ngModel)]="field.coef" name="coef" min="0" value="0" max="1" style="height: 32px;text-align: center;" (keyup)="updateCoeff(field.coef,field)" (change)="updateCoeff(field.coef,field)"
                            class="form-control input-sm" placeholder="Coefficient d'achat">
                        </td>
                        <td *ngIf="!this['demandeUpdate' + i]">
                          <span> {{field.quantite}}</span>
                        </td>
                        <td *ngIf="this['demandeUpdate' + i]">
                          <input type="number" min="0" value="0" [(ngModel)]="field.quantite" name="quantite" style="height: 32px;text-align: center;" (keyup)="updatequantitee(field.quantite,field)" (change)="updatequantitee(field.quantite,field)"
                            class="form-control input-sm" placeholder="Quantité">
                        </td>
                        <td>
                          {{field.prinetTotal | number: '1.2-2'| replace:',':' ' | replace:'.':','}}
                        </td>
                        <td>
                          <a  *ngIf="this['demandeUpdate' + i]" type="button" (click)="enregisterField(field,i)" title="Enregistrer"><img src="assets/images/save-cmd.png" style="width:100%" class="delete-img"></a>
                          <a  *ngIf="this['demandeUpdate' + i]" type="button" (click)="annulerField(field,i)" title="Annuler"><img src="assets/images/annule-cmd.png" style="width:88%" class="delete-img"></a>
                          <a  *ngIf="!this['demandeUpdate' + i]" type="button" (click)="updateField(field,i)" title="Modifier"><img src="assets/images/modif-hover.png" style="width: 77%;margin-bottom: 6px;" class="delete-img"></a>
                          <a  *ngIf="!this['demandeUpdate' + i]" type="button" (click)="deleteFieldValue(field)" title="Supprimer"><img src="assets/images/supp-modal.png" style="width:32%" class="delete-img"></a>
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>
                          <input type="text" [(ngModel)]="newAttribute.numero" name="numero" (click)="initiliaseDossier()" min-chars="3" style="height: 32px;text-align: center;" (valueChanged)="valuechange($event)" no-match-found-text="Aucun élément"
                            max-num-list="10" ngui-auto-complete [source]="dossiers" select-value-of="dossier" display-property-name="dossier" class="form-control input-sm" id="dossier" placeholder="N°">
  
                        </td>
                        <td>
                          {{newAttribute.nomDossier}}
                        </td>
                        <td>
                          {{newAttribute.client}}
                        </td>
  
                        <td>
                          <input type="text" [(ngModel)]="newAttribute.refArt" name="refArt" style="height: 32px;text-align: center;" (keyup)="valuechangeReference(newAttribute.refArt)" class="form-control input-sm" placeholder="Réf">
  
                        </td>
                        <td>
                          {{newAttribute.designation}}
                        </td>
                        <td>
                          {{newAttribute.pritar | number: '1.2-2'| replace:',':' ' | replace:'.':','}}
                        </td>
                        <td>
                          <input type="number" min="0" value="0" [(ngModel)]="newAttribute.prinet" name="prinet" style="height: 32px;width: 85px;text-align: center;" (keyup)="updatePrinNet(newAttribute.prinet)" (change)="updatePrinNet(newAttribute.prinet)"
                            class="form-control input-sm" placeholder="Prix net">
  
                        </td>
                        <td>
                          <input type="number" min="0" max="1" value="0" [(ngModel)]="newAttribute.coef" name="coef" style="height: 32px;text-align: center;" (keyup)="updateCoef(newAttribute.coef)" (change)="updateCoef(newAttribute.coef)"
                            class="form-control input-sm" placeholder="Coef">
  
                        </td>
                        <td>
                          <input type="number" min="0" value="0" [(ngModel)]="newAttribute.quantite" name="quantite" style="height: 32px;text-align: center;" (keyup)="updatequantite(newAttribute.quantite)"
                            (change)="updatequantite(newAttribute.quantite)" class="form-control input-sm" placeholder="Qté">
  
                        </td>
                        <td>
  
                        </td>
                        <td>
  
                          <a  (click)="addFieldValue()"><img src="assets/images/add-cmd.png"></a>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <p *ngIf="showErrorData" style="color:red"> Tous les champs sont obligatoires</p>
                  <p style="color:red" *ngIf="errorRef">La référence n’existe pas</p>
                  <p *ngIf="sommePrixNet">La somme du prix net total HT : {{sommePrixNet | number: '1.0-2'}}</p>
                  <p *ngIf="sommePrixNet">Montant de la TVA : {{sommePrixNet * 0.2 | number: '1.0-2'}}</p>
                  <p *ngIf="sommePrixNet">La somme du prix net total TTC : {{sommePrixNet * 0.2 + sommePrixNet | number: '1.0-2'}}</p>
  
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class=" col d-flex justify-content-center" style="padding-bottom: 1%">
          <div class="col-auto nbr-dossier ">
            <table>
              <tr>
                <td style="padding-right: 30px;">
                  <button class="btn-annule" style="width: 200px;height: 50px;" (click)="modifPart1()">Précédent <img src="assets/images/btn-annule.png"> </button>
  
                </td>
                <td style="padding-right: 30px;">
                  <button class="btn-valider" style="width: 200px;height: 50px;" (click)="modifPart4()">Suivant <img src="assets/images/btn-valider.png"></button>
                </td>
              </tr>
  
            </table>
          </div>
  
        </div>
      </div>
  
    </div>
    <div class="card-header " style="border: 1px solid green;">
      <b style="color:grey">FICHIERS</b>
    </div>
    <div *ngIf="showPart()==4">
      <div class="card-body ">
        <div class="row content-cols-show">
          <div class="col-lg-6 col-md-12 col-sm-12">
            <div id="drop_file_zone">
              <div id="drag_upload_file">
                <b>COMMANDE(S)</b>
                <p></p>
                <div class="btn-file">
                  <button class="btn-select-file">Choisir...</button>
                  <input type="file" (change)="selectFiles($event)" multiple accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"/>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12">
            <div id="drop_file_zone">
              <div id="drag_upload_file">
                <b>FACTURE(S)</b>
                <p></p>
                <div class="btn-file">
                  <button class="btn-select-file">Choisir...</button>
                  <input type="file" (change)="selectFilesFactures($event)" multiple accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"/>
                </div>
              </div>
            </div>
          </div>
  
        </div>
        <div class="row content-cols-show">
          <div class="col-lg-6 col-md-12 col-sm-12">
            <div id="drop_file_zone">
              <div id="drag_upload_file">
                <b>ACCUSÉ DE RÉCEPTION</b>
                <p></p>
                <div class="btn-file">
                  <button class="btn-select-file">Choisir...</button>
                  <input type="file" (change)="selectFilesAccuse($event)" multiple accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf" />
                </div>
              </div>
  
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12">
            <div id="drop_file_zone">
              <div id="drag_upload_file">
                <b>BON(S) DE LIVRAISON</b>
                <p></p>
                <div class="btn-file">
                  <button class="btn-select-file">Choisir...</button>
                  <input type="file"  (change)="selectFilesBonLivraison($event)" multiple accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p *ngIf="errorLignes" style="color:red">Ajouter au minimum une ligne !</p>
      <p *ngIf="errorDuplicate" style="color: red">Erreur duplication des factures !</p>
      <div class=" col d-flex justify-content-center" style="padding-bottom: 1%">
        <div class="col-auto nbr-dossier ">
          <table>
            <tr>
              <td>
                <button class="btn-annule" style="width: 200px;height: 50px;" (click)="modifPart2()">Précédent <img src="assets/images/btn-annule.png"></button>
              </td>
              <td style="padding-right: 30px; ">
                <button class="btn-valider" style="width: 200px;height: 50px;" (click)="saveCommande()">Ajouter <img src="assets/images/save.png"></button>
              </td>
            </tr>
  
          </table>
        </div>
  
      </div>
    </div>
  </div>
  <!-- --------------------------modal scan ----------------------- -->
  
  <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 1000px">
      <div class="modal-content modal-content-scan" style="width: 200%; top: -170px">
        <div class="modal-header d-flex justify-content-center">
          <h3 class="titre-modal-statut">
            NOUVELLE LIGNE
          </h3>
        </div>
        <div class="modal-body">
          <div class="col d-flex justify-content-start">
            <b class="obligatoir-text">(*) Ce champ est obligatoire</b>
          </div>
          <div class="row" style="margin-top: 25px;">
            <div class="col-lg-6 col-md-6 col-sm-10">
              <div class="title">
                <input class="font-weight-bold" placeholder="Poste*">
              </div>
  
              <br />
              <div class="title">
                <input class="font-weight-bold" placeholder="N° Dossier*">
              </div>
  
              <br />
              <div class="title">
                <input class="font-weight-bold" placeholder="Nom Dossier*">
              </div>
  
              <br />
              <div class="title">
                <input class="font-weight-bold" placeholder="Client*">
              </div>
  
              <br />
              <div class="title">
                <input class="font-weight-bold" placeholder="Référence*">
              </div>
  
              <br />
              <div class="title">
                <input class="font-weight-bold" placeholder="Désignation*">
              </div>
  
  
  
            </div>
  
            <div class="col-lg-6 col-md-6 col-sm-10">
              <div class="title">
                <input class="font-weight-bold" placeholder="Prix Tarif HT*">
              </div>
              <br />
  
              <div class="title">
                <input class="font-weight-bold" placeholder="Prix Net HT*">
              </div>
  
              <br />
              <div class="title">
                <input class="font-weight-bold" placeholder="Coefficient d'achat*">
              </div>
  
              <br />
              <div class="title">
                <input class="font-weight-bold" placeholder="Quantité*">
              </div>
  
              <br />
              <div class="title">
                <input class="font-weight-bold" placeholder="Total*">
              </div>
  
  
            </div>
  
          </div>
  
          <div class="modal-footer d-flex justify-content-center">
            <div class=" col d-flex justify-content-center" style="padding-bottom: 1%; margin-bottom: 20px">
              <div class="col-auto nbr-dossier ">
                <table>
                  <tr>
                    <td style="padding-right: 30px;">
                      <button class="btn-valider" style="width: 200px;height: 50px;" >Ajouter <img src="assets/images/save.png"></button>
  
                    </td>
                    <td style="padding-right: 30px;">
                      <button class="btn-annule" style="width: 200px;height: 50px;" >Annuler <img src="assets/images/btn-annule.png"> </button>
  
                    </td>
  
                  </tr>
  
                </table>
              </div>
  
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  