import { Component, OnInit } from '@angular/core';
import { StockService } from "../../../services/stock.service";
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from "../../../services/user.service";


@Component({
  selector: 'app-details-stock',
  templateUrl: './details-stock.component.html',
  styleUrls: ['../../utilisateur/details-utilisateur/details-utilisateur.component.css']
})
export class DetailsStockComponent implements OnInit {
  article:any;
  id:any;
  inventaireConfigObj:any;
  utilisateur:any;
  constructor(private router:Router,private stockService: StockService,private activatedRoute: ActivatedRoute,private userService:UserService) {

  }
  ngOnInit() {
    let params: any = this.activatedRoute.snapshot.params;
    this.stockService.getArticleById(params.id).subscribe(data => {
      this.article = data
    },
      err => {
        return false;
      });

      this.id = localStorage.getItem('idUser')
      this.userService.getUserByID(this.id).subscribe(utilisateur => {
        this.utilisateur = utilisateur;
        if(!this.utilisateur){
          localStorage.removeItem('idUser');
          localStorage.clear();
          this.router.navigate(['/login']);
        }
      },
        err => {
          return false;
        });
  }



}