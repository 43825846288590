import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidateService {
  constructor() { }

  validateName(nom){
    if(nom == undefined){
      return false;
    }
    if(nom.length<3){
      return false;
    }
    else {
      return true;
    }
  }
  validateEmail(email){
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  
  validateLengthPassword(password){
    if(password.length<6)
    {
      return false;
    }
    else{return true;}
  }

    validateLengthDescriptif(descriptif){
      if(descriptif.length>50)
      {
        return false;
      }
      else{return true;}
    }
  validatePassword(password){
    if(password== undefined){
      return false;
    }
    else {
      return true;
    }
  }
  validateTel(tel){
    //const re  = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/;
    if(isNaN(tel)){ return false;}
    else { return true;}
    //return re.test(tel);
  }
   dateValidator(date) {
   let today = new Date().toISOString().slice(0, 10)
    if(date){
    if (date>= today) {
      return { 'invalidDate': true };

    } else {

      return null;

    }
  }
  }

}
