import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http } from '@angular/http';
import { RhService } from "../../../services/rh.service";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
import { UserService } from "../../../services/user.service";
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'app-rh',
  templateUrl: './rh.component.html',
  styleUrls: ['../../utilisateur/utilisateur/utilisateur.component.css'],
})
export class RhComponent implements OnInit {
  salaries: any;
  public searched: string = '';
  numberData = 25;
  utilisateur:any;
  id:any;
  p = 1
  role:any;
  hasRoleDelete = false;
  hasRoleUpdate = false;
  hasRoleAdd = false;
  constructor(private userService:UserService,private http: Http, private rhService: RhService,private roleService:RoleService,
    private router: Router) {

  }
  ngOnInit() {
    this.id = localStorage.getItem('idUser')
    if (this.id) {
      this.userService.getUserByID(this.id).subscribe(utilisateur => {
        this.utilisateur = utilisateur;
        if(!this.utilisateur || (this.utilisateur && !this.utilisateur.idRole)){
          localStorage.removeItem('idUser');
          localStorage.clear();
          this.router.navigate(['/login']);
        }
        this.roleService.getRoleById(this.utilisateur.idRole).subscribe(role => {
          this.role = role;
          if (this.role && this.role.privileges && this.role.privileges.length > 0) {
            let founddeleteUtilisateur = this.role.privileges.find(x => x.name == "DELETE_RH")
            let foundupdateUtilisateur = this.role.privileges.find(x => x.name == "UPDATE_RH")
            let foundupdateUtilisateuradd = this.role.privileges.find(x => x.name == "ADD_RH")
            if (founddeleteUtilisateur) {
              this.hasRoleDelete = true;
            }
            if (foundupdateUtilisateur) {
              this.hasRoleUpdate = true;
            }
            if (foundupdateUtilisateuradd) {
              this.hasRoleAdd = true;
            }
          }
        },
          err => {
            return false;
          });
      },
        err => {
          return false;
        });
    }
    this.getAllSalaries();
  }
  // recuperer tous les clients
  getAllSalaries() {
    this.rhService.getAllUsers().then(data => {
      this.salaries = data;
    },
      err => {
        return false;
      });
  }
  // supprimer un client
  removesalarie(salarie) {
    Swal.fire({
     title: 'VOULEZ-VOUS SUPPRIMER CE SALARIÉ?',
     imageUrl: 'assets/images/supp-modal.png',
     imageWidth: 100,
     imageHeight: 100,
     imageAlt: 'Custom image',
     showCancelButton: true,
     width: 800,
     confirmButtonColor: '#059e00',
     cancelButtonColor: '#FF9100',
     cancelButtonText: 'ANNULER',
     confirmButtonText: 'VALIDER'
   }).then((result) => {
     if (!result.dismiss) {
       Swal.fire( {
           imageUrl: 'assets/images/supp-modal.png',
           imageWidth: 100,
           imageHeight: 100,
           imageAlt: 'Custom image',
           width: 800,
           title: 'BIEN SUPPRIMÉ',
           timer: 1500,
           showConfirmButton: false
         }
       );
       this.rhService.deleteSalarieById(salarie.id).then(data => {
        });
        this.salaries.splice(this.salaries.indexOf(salarie), 1);
     }
   } );
  }

}
