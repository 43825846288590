<app-navbar></app-navbar>
<br>
<div id="content-nav-page" class="row">
    <div class=" col-3 titre-menu">
      <a routerLink="/inventaire" class=" icon-f-left"></a>
      <b style="margin-right: 10%">INVENTAIRE</b>
      <div class="dropdown" *ngIf="hasRoleAdd">
        <button class="btn icon-catalogue" type="button" id="dropdownMenuButton" data-toggle="dropdown">
        </button>
        <div class="dropdown-menu show-catalogue" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item drop-dup-option" routerLink="/ajoutInventaire/manuel">Manuel</a>
          <a class="dropdown-item drop-dup-option-b" routerLink="/ajoutInventaire">Fichier XLS</a>
  
        </div>
      </div>
    </div>
    <div class="col-9 sous-menu">
      <b class="sous-menu-active1" routerLink="/inventaire">INVENTAIRE</b>
      <b class="sous-menu-active2 ml-4" *ngIf="hasRolestock" routerLink="/stock" style="cursor: pointer">STOCK</b>
    </div>
  </div>
  <div id="content-page" class="containe-fluid">
    <div class="row  ">
      <div class="col-lg-4 col-md-10 col-sm-10  search">
        <br>
      </div>
      <div class="col-lg-1 col-md-2 col-sm-2">
      </div>
  
      <div class=" col d-flex justify-content-end" >
        <div class="col-auto btn-devis ">
          <a (click)="exportDataToExcel()" class="btn-connexion">Exporter<img class="ml-1" src="assets/images/excel.png"></a>
          <br><br>
        </div>
      </div>
    </div>
    <ng-template #customLoadingTemplate></ng-template>
    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading>
    <!-- tableau -->
    <div class="row" style="overflow-x:auto;">
      <table class="table table-striped table-hover">
        <th  class="left" id="firstThTable">DATE DE L'INVENTAIRE</th>
        <th class="left" >FOURNISSEUR</th>
        <th class="right"  >NOMBRE DES  RÉFÉRENCES</th>
        <th class="right"  >SOMME QUANTITÉ </th>
        <th class="right"  >SOMME PRIX TARIF </th>
        <th class="right"  >SOMME PRIX NET </th>
        <th class="right"  >MONTANT TOTAL PAR FOURNISSEUR </th>
        <th class="right"  >POURCENTAGE GPMI/REF </th>
        <th class="right"  >POURCENTAGE FACT/REF </th>
        <th class="text-center "  >RÉGLAGES</th>
        <tr *ngFor="let inventaire of inventaires| paginate: { itemsPerPage: 10, currentPage: p }" (click)="goToInventaire(inventaire._id.datec, inventaire._id.idFournisseur)">
            <td class="left">
              {{inventaire._id.datec| date : 'dd/MM/yyyy'}}
            </td>
            <td class="left">
              {{inventaire._id.denomination}}
            </td>
            <td class="right">
              {{inventaire.nbreReferceParFounrPardate| number: '1.2-2'| replace:',':' ' | replace:'.':',' | replace:',00':' '}}
            </td>
            <td class="right">
              {{inventaire.totalquantiteParFounrPardate| number: '1.2-2'| replace:',':' ' | replace:'.':',' | replace:',00':' '}}
            </td>
            <td class="right"  style="width: 145px;">
              {{inventaire.totalpritarParFounrPardate | number: '1.2-2'| replace:',':' ' | replace:'.':','}}
            </td>
            <td class="right"  style="width: 145px;">
              {{inventaire.totalprinetParFounrPardate | number: '1.2-2'| replace:',':' ' | replace:'.':','}}
            </td>
            <td class="right"  style="width: 145px;">
              {{inventaire.totalmontantParFounrPardate | number: '1.2-2'| replace:',':' ' | replace:'.':','}}
            </td>
            <td class="right" >
              {{inventaire.totalCommandeGPMI / inventaire.nbreReferceParFounrPardate * 100 | number: '1.2-2'| replace:',':' ' | replace:'.':','}} %
            </td>
            <td class="right" >
              {{inventaire.totalFacture / inventaire.nbreReferceParFounrPardate * 100 | number: '1.2-2'| replace:',':' ' | replace:'.':','}} %
            </td>
            <button class="btn-affiche"><a (click)="goToInventaire(inventaire._id.datec, inventaire._id.idFournisseur)"> <img src="assets/images/show-white.png"> Afficher </a> </button>
          </tr>
      </table>
    </div>
    <div style="padding-top: 50px;color: green">
      <pagination-controls previousLabel="Précédent" nextLabel="Suivant" (pageChange)="p = $event" #api></pagination-controls>
    </div>
  </div>
  