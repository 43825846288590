<app-navbar></app-navbar>
<br>
<div id="content-nav-page" class="row">
    <div class=" col-3 titre-menu">
      <a routerLink="/catalogue" class=" icon-f-left"></a>
      <b style="margin-right: 10%" class="title-haut" *ngIf="fournisseur">{{fournisseur.denomination}} <img class="ml-2" src="assets/images/show-a.png"> </b>
      <div class="dropdown" style="left:45px" >
        <button class="btn icon-catalogue" type="button" id="dropdownMenuButton" data-toggle="dropdown">
        </button>
        <div class="dropdown-menu show-catalogue" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item drop-dup-option" routerLink="/ajoutCatalogue/manuel">Manuel</a>
          <a class="dropdown-item drop-dup-option-b" routerLink="/ajoutCatalogue">Fichier XLS</a>
        </div>
      </div>
    </div>
    <!-- NOMBRE DE DOSSIER PAR TYPES ET PAR ANNÉE -->
    <ng-template #customLoadingTemplate></ng-template>
    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading>
  
  
    <div class="col-9 sous-menu" *ngIf="utilisateur">
      <b class="sous-menu-active1" routerLink="/catalogue">CATALOGUE</b>
    </div>
  </div>
  
  
  <div id="content-page" class="containe-fluid">
  
    <div class="row  ">
      <div class="col-lg-4 col-md-10 col-sm-10  search">
        <input class="search-bar" type="text" placeholder="Rechercher.." [(ngModel)]="filterArticle" name="filterArticle">
      </div>
      <div class="col-lg-1 col-md-2 col-sm-2">
        <select class="select-input-nombre" name="numberData" [(ngModel)]="numberData">
          <option>5</option>
          <option selected>10</option>
          <option>25</option>
          <option>50</option>
        </select>
      </div>
  
      <div class=" col d-flex justify-content-end">
        <div class="col-auto nbr-dossier ">
          <table>
            <tr>
              <td>
                <b>Catalogues</b>
              </td>
            </tr>
            <tr>
              <td>
                <b class="sous-nbr-dossier d-flex justify-content-center" *ngIf="years">{{years.length}}</b>
              </td>
            </tr>
          </table>
        </div>
  
        <div class="col-auto btn-devis ">
          <br>
        </div>
      </div>
    </div>
    <div class="row" style="overflow-x:auto;">
      <table class="table table-striped table-hover">
        <th class="left">ANNÉE</th>
        <th class="left">DATE DE DÉBUT</th>
        <th class="left">DATE DE FIN</th>
        <th class="right">TOTAL DES ARTICLES</th>
        <th class="text-center ">RÉGLAGES</th>
        <tr *ngFor="let data of years  | paginate: { itemsPerPage: numberData, currentPage: p } |searchingCatalogueYear  : filterArticle.toString().toLowerCase() ;let i = index" (click)="goToCatalogue(data)">
            <td class="left">
              {{data.year}}
            </td>
            <td class="left">
              {{data.datec | date : 'dd/MM/yyyy'}}
            </td>
            <td class="left">
              {{data.dateFin | date : 'dd/MM/yyyy'}}
            </td>
            <td class="right">
              {{data.refCount | number: '1.2-2'| replace:',':' ' | replace:'.':',' | replace:',00':' '}}
            </td>
            <td style="text-align: center;">
            <button class="btn-affiche"><a (click)="goToCatalogue(data)"> <img src="assets/images/show-white.png"> Afficher </a> </button>
            </td>
          </tr>
  
      </table>
    </div>
    <div class="row">
      <pagination-controls previousLabel="Précédent" nextLabel="Suivant" class=“custom-pagination” (pageChange)="p = $event" #api></pagination-controls>
    </div>
  </div>
  