import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { environment } from '../../../../environments/environment';
import { UserService } from "../../../services/user.service";
import { CommandeService } from "../../../services/commande.service";
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { FournisseurService } from "../../../services/fournisseur.service";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { DossierService } from "../../../services/dossier.service";
import { CatalogueService } from "../../../services/catalogue.service";
import { UploadFileService } from "../../../services/upload-file-service.service";
import { ValidationService } from '../../../services/validate/validate-error.service';
import { HistoriqueService } from "../../../services/historique.service";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';

@Component({
  selector: 'app-update-commande',
  templateUrl: './update-commande.component.html',
  styleUrls: ['./update-commande.component.css']
})
export class UpdateCommandeComponent implements OnInit {
  utilisateur: any;
  id: any;
  commande: any;
  form: any;
  lignes: any[] = []
  sommePrixNet = 0
  errorLignes = false;
  showErrorData = false
  sizeLimitOne = 10000000; //10MB
  myfiles: any;
  myfilesAccuse: any;
  myfilesFactures: any;
  myfilesBonLivraison: any;
  fieldArray: Array<any> = [];
  newAttribute: any = {};
  loading = false
  dossiers: any;
  firstDateReception: any;
  timeout: any = null;
  errorDate = false;
  savedField: any;
  errorRef = false
  monthNumbers = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"]
  errorNumeroCommandeGPMI = false;
  savecNuleroGPMI: any;
  errorDateFacture = false;
  savedCommande:any;
  fournisseurs:any;
  selectedFounrisseur:any;
  showedValue:any;
  errorDuplicate = false;
  errorNumFacture = ""
  bigDate = "9999-12-31"
  constructor(private historiqueService:HistoriqueService,private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute, private http: Http, private router: Router, private catalogueService: CatalogueService, private userService: UserService, private commandeService: CommandeService, private uploadFileService: UploadFileService,
    private fournisseurService: FournisseurService, private _sanitizer: DomSanitizer, private dossierService: DossierService) {
    this.form = this.formBuilder.group({
      fournisseur: [''],
      numeroCommandeGPMI: ['', [Validators.required, Validators.minLength(2), ValidationService.validateEmptyField, Validators.maxLength(25)]],
      dateCommande: ['',Validators.required],
      dateLivraison: ['', [Validators.required,ValidationService.dateValidatorr]],
      numFacture: ['',Validators.maxLength(25)],
      dateFacture: [''],
      numFacturee: ['',Validators.maxLength(25)],
      dateFacturee: [''],
      factures: this.formBuilder.array([
        this.initFacture(),
      ])
    })
  }
  ngOnInit() {
    this.id = localStorage.getItem('idUser')
    let params: any = this.activatedRoute.snapshot.params;
    this.userService.getUserByID(this.id).subscribe(utilisateur => {
      this.utilisateur = utilisateur;
      if(!this.utilisateur){
        localStorage.removeItem('idUser');
        localStorage.clear();
        this.router.navigate(['/login']);
      }
      if(this.utilisateur.sousRole == 'gr-acces'){
        this.router.navigate(["accueil"]);
        return false;
      }
    },
      err => {
        return false;
      });
    this.commandeService.getArticleById(params.id).subscribe(data => {
      this.commande = data;
      if (this.commande) {
        this.savedCommande =  JSON.parse(JSON.stringify(this.commande))
        this.firstDateReception = this.commande.dateLivraison
        this.savecNuleroGPMI = this.commande.numeroCommandeGPMI
        this.selectedFounrisseur = {
          "id":this.commande.idFournisseur,
          "denomination":this.commande.denomination
        }
        this.showedValue = this.commande.denomination
        if(this.commande.numeroCommandeGPMI == null){
          this.savecNuleroGPMI = ""
        }
        if(this.commande.factures && this.commande.factures.length > 0){
          for(var j=0;j<this.commande.factures.length;j++){
            if(this.commande.factures[j].dateFacture == "" && this.commande.factures[j].numFacture == ""){
              this.commande.factures.splice(this.commande.factures.indexOf(this.commande.factures[j]), 1);
            }
          }
        }
      }
    },
      err => {
        return false;
      });
    this.fournisseurService.getAllUsers().then(data => {
        this.fournisseurs = data;
        if(this.commande && this.commande.idFournisseur){
          this.selectedFounrisseur = this.fournisseurs.find(x => x.id == this.commande.idFournisseur)
        }
      },
      err => {
        return false;
      });
    this.dossierService.getUniqueNumeros().then(data => {
      this.dossiers = data;
    },
      err => {
        return false;
      });
  }
  initFacture(){
    return this.formBuilder.group({
      numFacture: ['',Validators.maxLength(25)],
      dateFacture: ['',ValidationService.dateValidatorr]
    });
  }
  deleteFieldValue(field) {
    this.commande.sommePrixNet = this.commande.sommePrixNet - Number(field.prinet * field.quantite)
    this.commande.lignes.splice(this.commande.lignes.indexOf(field), 1);
  }
  getBigDate(): string {
      return this.bigDate
    }
  updateField(field, i) {
    this.savedField = { ...field };
    this['demandeUpdate' + i] = !this['demandeUpdate' + i]
  }
  annulerField(field, i) {
    this.commande.lignes[i] = this.savedField
    this['demandeUpdate' + i] = !this['demandeUpdate' + i]
  }

  initiliaseDossier(field) {
    field.nomDossier = ""
    field.client = ""
    field.idClient = ""
  }
  valuechange(event, field) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function() {
      if (event.keyCode != 13) {
        $this.getDossierByNumero(event, field)
      }
    }, 2000);
  }

  myCallback(event) {
    this.selectedFounrisseur = this.form.value.fournisseur
    if (this.selectedFounrisseur) {
      this.commande.idFournisseur = this.selectedFounrisseur.id
      this.commande.denomination = this.selectedFounrisseur.denomination
    }
  }
  getDossierByNumero(event, field) {
    field.nomDossier = ""
    field.client = ""
    field.idClient = ""
    if (!event || event == "") {
      return false;
    }
    this.dossierService.getDetailDossierByNumero(event).then(dossier => {
      if (dossier && dossier["client"]) {
        field.nomDossier = dossier["nom"]
        field.client = dossier["client"]["denomination"]
        field.idClient = dossier["client"]["id"]
      }
    },
      err => {
        return false;
      });
  }

  valuechangeReference(event, field) {
    this.errorRef = false;
    field.coef = ""
    field.prinet = ""
    field.designation = ""
    field.pritar = ""
    if (!event) {
      return false;
    }
    if (event && event.length < 3) {
      return false;
    }
    var me = this;
    var splitDateCommande = this.commande.dateCommande.split('-');
    var yearDateCommande = splitDateCommande[0]
    var monthDateCommandee = splitDateCommande[1]
    setTimeout(function() {
      me.pousuivreReferChange(event, field, me.commande.dateCommande, monthDateCommandee)

    }, 2000);
  }
  pousuivreReferChange(event, field, yearDateCommande, monthDateCommandee) {
    this.catalogueService.getAllDataByRefOrEANAndFournisseurAndCommandeLimitDate(event, field.idFournisseur, yearDateCommande).then((data: any[]) => {
      if (data && data.length > 0) {
        var catalogueMatching = data.find(x => x.datec <= this.commande.dateCommande && x.dateFin >= this.commande.dateCommande);
        if (catalogueMatching) {
          field.designation = catalogueMatching.designation25
          field.idFournisseur = catalogueMatching.idFournisseur
          field.refArt = catalogueMatching.refCiel
          field.pritar = catalogueMatching.tarif
          this.errorRef = false;
          return true;
        }
        if (!catalogueMatching) {
          this.errorRef = true;
          return true;
        }

      } else {
        this.errorRef = true;
        return true;
      }
    },
      err => {
        this.errorRef = false;
        return false;
      });
  }
  updatePrinNet(prinet, field) {
    if (!field.pritar || field.pritar == "") {
      field.prinet = ""
      return false;
    }
    if (!prinet) {
      return false;
    }
    if (prinet && prinet == "") {
      return false;
    }
    if (prinet < 0) {
      field.prinet = ""
      return false;
    }
    var me = this;
    setTimeout(function() {
      field.coef = Number((prinet / field.pritar).toFixed(4));
      field.prinetTotal = prinet * field.quantite
      if (field.coef < 0 || field.coef > 1) {
        field.coef = ""
      }
    }, 2000);

  }

  updateCoef(coef, field) {
    if (!field.pritar || field.pritar == "") {
      field.coef = ""
      return false;
    }
    if (!coef) {
      return false;
    }
    if (coef && coef == "") {
      return false;
    }
    if (coef < 0 || coef > 1) {
      field.coef = ""
      return false;
    }
    if (coef && coef.toString().length > 6) {
      field.coef = ""
      return false;
    }
    var me = this;
    setTimeout(function() {
      field.prinet = (coef * field.pritar).toFixed(2);
      field.prinetTotal = (field.prinet * field.quantite).toFixed(2);
    }, 2000);
  }

  updatequantite(quantite, field) {
    if (quantite < 0 || quantite > 99999) {
      field.quantite = ""
      return false;
    }
    if (!Number(quantite)) {
      field.quantite = ""
      return false;
    }
    if (quantite && (quantite.toString().indexOf(".") > -1 || quantite.toString().indexOf(",") > -1)) {
      field.quantite = ""
      return false;
    }
    field.prinetTotal = quantite * field.prinet
  }


  // from add commandes
  valuechangee(event) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function() {
      if (event.keyCode != 13) {
        $this.getDossierByNumeroo(event)
      }
    }, 2000);
  }

  getDossierByNumeroo(event) {
    this.newAttribute.nomDossier = ""
    this.newAttribute.client = ""
    this.newAttribute.idClient = ""
    if (!event || event == "") {
      return false;
    }
    this.dossierService.getDetailDossierByNumero(event).then(dossier => {
      if (dossier && dossier["client"]) {
        this.newAttribute.nomDossier = dossier["nom"]
        this.newAttribute.client = dossier["client"]["denomination"]
        this.newAttribute.idClient = dossier["client"]["id"]
      }
    },
      err => {
        return false;
      });
  }
  initiliaseDossierr() {
    this.newAttribute.nomDossier = ""
    this.newAttribute.client = ""
    this.newAttribute.idClient = ""
  }
  valuechangeReferencee(event) {
    this.errorRef = false;
    this.newAttribute.coef = ""
    this.newAttribute.prinet = ""
    this.newAttribute.designation = ""
    this.newAttribute.pritar = ""
    if (!event) {
      return false;
    }
    if (event && event.length < 3) {
      return false;
    }
    var me = this;
    var splitDateCommande = this.commande.dateCommande.split('-');
    var yearDateCommande = splitDateCommande[0]
    var monthDateCommandee = splitDateCommande[1]
    setTimeout(function() {
      me.pousuivreReferChangee(event, me.commande.dateCommande, monthDateCommandee)

    }, 2000);
  }
  pousuivreReferChangee(event, yearDateCommande, monthDateCommandee) {
    this.catalogueService.getAllDataByRefOrEANAndFournisseurAndCommandeLimitDate(event, this.commande.idFournisseur, yearDateCommande).then((data: any[]) => {
      if (data && data.length > 0) {
        var catalogueMatching = data.find(x => x.datec <= this.commande.dateCommande && x.dateFin >= this.commande.dateCommande);
        if (catalogueMatching) {
          this.newAttribute.designation = catalogueMatching.designation25
          this.newAttribute.idFournisseur = catalogueMatching.idFournisseur
          this.newAttribute.refArt = catalogueMatching.refCiel
          this.newAttribute.pritar = catalogueMatching.tarif
          this.errorRef = false;
          return true;
        }
        if (!catalogueMatching) {
          this.errorRef = true;
          return true;
        }

      } else {
        this.errorRef = true;
        return true;
      }
    },
      err => {
        this.errorRef = false;
        return false;
      });
  }
  updatePrinNett(prinet) {
    if (!this.newAttribute.pritar || this.newAttribute.pritar == "") {
      this.newAttribute.prinet = ""
      return false;
    }
    if (!prinet) {
      return false;
    }
    if (prinet && prinet == "") {
      return false;
    }
    if (prinet < 0) {
      this.newAttribute.prinet = ""
      return false;
    }
    var me = this;
    setTimeout(function() {
      me.newAttribute.coef = Number((prinet / me.newAttribute.pritar).toFixed(4));
      if (me.newAttribute.coef < 0 || me.newAttribute.coef > 1) {
        me.newAttribute.coef = ""
      }
    }, 2000);

  }

  updateCoeff(coef) {
    if (!this.newAttribute.pritar || this.newAttribute.pritar == "") {
      this.newAttribute.coef = ""
      return false;
    }
    if (!coef) {
      return false;
    }
    if (coef && coef == "") {
      return false;
    }
    if (coef < 0 || coef > 1) {
      this.newAttribute.coef = ""
      return false;
    }
    if (coef && coef.toString().length > 6) {
      this.newAttribute.coef = ""
      return false;
    }
    var me = this;
    setTimeout(function() {
      me.newAttribute.prinet = (coef * me.newAttribute.pritar).toFixed(2);

    }, 2000);
  }

  updatequantitee(quantite) {
    if (quantite < 0 || quantite > 99999) {
      this.newAttribute.quantite = ""
      return false;
    }
    if (!Number(quantite)) {
      this.newAttribute.quantite = ""
      return false;
    }
    if (quantite && (quantite.toString().indexOf(".") > -1 || quantite.toString().indexOf(",") > -1)) {
      this.newAttribute.quantite = ""
      return false;
    }
  }
  addFieldValue() {
    this.showErrorData = false;
    if (!this.newAttribute.numero || this.newAttribute.numero == "" || !this.newAttribute.nomDossier || this.newAttribute.nomDossier == "" || !this.newAttribute.refArt || this.newAttribute.refArt == "" || !this.newAttribute.pritar || this.newAttribute.pritar == ""
      || !this.newAttribute.prinet || this.newAttribute.prinet == "" || !this.newAttribute.coef || this.newAttribute.coef == "" || !this.newAttribute.quantite || this.newAttribute.quantite == "") {
      this.showErrorData = true;
      return false;
    }
    this.newAttribute.prinetTotal = this.newAttribute.prinet * this.newAttribute.quantite
    this.commande.lignes.push(this.newAttribute)
    this.newAttribute = {};
  }
  // end from add commande

  //save commande
  saveCommande(commande) {
    this.errorNumFacture = ""
    if(this.form.value.factures && this.form.value.factures.length > 0){
      for(var j = 0; j < this.form.value.factures.length;j++){
        if(this.form.value.factures[j].numFacture && this.form.value.factures[j].numFacture.length > 25){
          this.errorNumFacture = "Merci de vérifier le(s) numéro(s) de facture(s), maximum 25 caractères"
          return false;
        }
      }
    }
    if(this.commande.factures && this.commande.factures.length > 0){
      for(var j = 0; j < this.commande.factures.length;j++){
        if(this.commande.factures[j].numFacture && this.commande.factures[j].numFacture.length > 25){
          this.errorNumFacture = "Merci de vérifier le(s) numéro(s) de facture(s), maximum 25 caractères"
          return false;
        }
      }
    }
    this.errorNumFacture = ""
    Swal.fire({
      title: 'VOULEZ-VOUS ENREGISTRER VOS MODIFICATIONS?',
      imageUrl: 'assets/images/modifier.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      showCancelButton: true,
      width: 800,
      confirmButtonColor: '#059e00',
      cancelButtonColor: '#FF9100',
      cancelButtonText: 'ANNULER',
      confirmButtonText: 'ENREGISTRER'
    }).then((result) => {
      if (!result.dismiss) {
        this.errorDateFacture = false;
        if ((this.utilisateur && this.utilisateur.role === 'Super admin') || (this.utilisateur && this.utilisateur.role === 'Administration') || (this.utilisateur && this.utilisateur.role === 'Responsable commandes') ) {
          for(var j=0;j<this.form.value.factures.length;j++){
            if((this.form.value.factures[j].dateFacture && this.form.value.factures[j].dateFacture != "") || (this.form.value.factures[j].numFacture && this.form.value.factures[j].numFacture != "")){
              if(this.form.value.factures[j].dateFacture && this.form.value.factures[j].dateFacture != "" && this.form.value.factures[j].dateFacture < commande.dateCommande){
                this.errorDateFacture = true;
                return false;
              }
              if(!commande.factures){
                commande.factures =  [];
              }
              commande.factures.push(this.form.value.factures[j])
            }
          }

        }
        if (this.savecNuleroGPMI && (this.savecNuleroGPMI == commande.numeroCommandeGPMI)) {
          this.errorDateFacture = false;
          this.suiveSaveCommande(commande)
        }
        if ((this.savecNuleroGPMI && this.savecNuleroGPMI != commande.numeroCommandeGPMI) || (!this.savecNuleroGPMI)) {
          this.commandeService.verifyNumeroGPMI(commande).subscribe(data => {
            if (data) {
              this.errorNumeroCommandeGPMI = true;
              return false;
            } else {
              this.errorNumeroCommandeGPMI = false;
              this.errorDateFacture = false;
              this.suiveSaveCommande(commande)
            }
          })
        }
      }
    } );

  }
  updateNumFacturee(){
    this.errorNumFacture = ""
    if(this.form.value.factures && this.form.value.factures.length > 0){
      for(var j = 0; j < this.form.value.factures.length;j++){
        if(this.form.value.factures[j].numFacture && this.form.value.factures[j].numFacture.length > 25){
          this.errorNumFacture = "Merci de vérifier le(s) numéro(s) de facture(s), maximum 25 caractères"
          return false;
        }
      }
    }
    this.errorNumFacture = ""
  }
  suiveSaveCommande(commande) {
    this.errorNumFacture = ""
    if(this.form.value.factures && this.form.value.factures.length > 0){
      for(var j = 0; j < this.form.value.factures.length;j++){
        if(this.form.value.factures[j].numFacture && this.form.value.factures[j].numFacture.length > 25){
          this.errorNumFacture = "Merci de vérifier le(s) numéro(s) de facture(s), maximum 25 caractères"
          return false;
        }
      }
    }
    this.errorNumFacture = ""
    this.errorLignes = false;
    this.errorDate = false;
    this.showErrorData = false;
    this.errorNumeroCommandeGPMI = false;
    if (commande.dateLivraison < commande.dateCommande) {
      this.errorDate = true;
      return false;
    }
    if (commande.lignes.length < 1) {
      this.errorLignes = true;
      return false;
    }
    for (var j = 0; j < commande.lignes.length; j++) {
      if (!commande.lignes[j].numero || commande.lignes[j].numero == "" || !commande.lignes[j].nomDossier || commande.lignes[j].nomDossier == "" || !commande.lignes[j].refArt || commande.lignes[j].refArt == "" || !commande.lignes[j].pritar || commande.lignes[j].pritar == ""
        || !commande.lignes[j].prinet || commande.lignes[j].prinet == "" || !commande.lignes[j].coef || commande.lignes[j].coef == "" || !commande.lignes[j].quantite || commande.lignes[j].quantite == "") {
        this.showErrorData = true;
        return false;
      }
    }
    if(!commande.factures){
      commande.factures = []
    }
    var valueArr = commande.factures.map(function(item){ return item.numFacture });
    var isDuplicate = valueArr.some(function(item, idx){
      return valueArr.indexOf(item) != idx
    });
    if(isDuplicate){
      this.errorDuplicate = true;
      return false;
    }
    this.errorDuplicate = false;
    if (!commande.allDateLivraison) {
      commande.allDateLivraison = []
    }
    if (commande.dateLivraison != this.firstDateReception) {
      if (commande.allDateLivraison && commande.allDateLivraison.indexOf(this.firstDateReception) === -1) {
        commande.allDateLivraison.push(this.firstDateReception)
      }
    }
    commande.sommePrixNet = 0
    for (var j = 0; j < commande.lignes.length; j++) {
      commande.sommePrixNet = commande.sommePrixNet + Number(commande.lignes[j].prinet * commande.lignes[j].quantite)
      commande.montantTVA = commande.sommePrixNet * 0.2
      commande.sommePrixNetTTC = commande.sommePrixNet + commande.montantTVA
    }
    if ((!this.myfiles || (this.myfiles && this.myfiles.length < 1)) && (!this.myfilesAccuse || (this.myfilesAccuse && this.myfilesAccuse.length < 1)) && (!this.myfilesFactures || (this.myfilesFactures && this.myfilesFactures.length < 1)) && (!this.myfilesBonLivraison || (this.myfilesBonLivraison && this.myfilesBonLivraison.length < 1))) {
      this.loading = true;
      this.commandeService.editIcommandesrById(commande).subscribe(resp => {
        if(resp){
          this.saveHistoriquesCommandes(resp)
        }
      }, err => {
      })
      setTimeout(() => {
        /** spinner ends after 3 seconds **/
        this.loading = false;
        Swal.fire( {
            imageUrl: 'assets/images/statut-valide-hover.png',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            width: 800,
            title: 'BIEN MODIFIÉ',
            timer: 1500,
            showConfirmButton: false
          }
        );
        this.router.navigate(['/commande'])
      }, 3000);

    }
    else {
      if (this.myfiles && this.myfiles.length > 0) {
        this.loading = true;
        var listfiles = []
        for (var t = 0; t < this.myfiles.length; t++) {
          var fileExtensionn = this.myfiles[t].name.substr((this.myfiles[t].name.lastIndexOf('.') + 1));
          var numberlistefiles = 0
          if(commande.listfiles && commande.listfiles.length > 0){
            numberlistefiles = commande.listfiles.length
          }
          //var photo = this.utilisateur.id + i + '.' + fileExtension
          //var randomVariable = Math.floor(Math.random() * (999999999999 - 100000000000)) + 100000000000;
          var randomVariable = this.form.value.numeroCommandeGPMI + "_CMD("+Number(t+numberlistefiles)+")"
          if(Number(t+numberlistefiles) == 0){
            var randomVariable = this.form.value.numeroCommandeGPMI + "_CMD"
          }
          var imgObject = {
            randomVariable: randomVariable,
            name: this.myfiles[t].name,
            photo: environment.filesPath + randomVariable + "." + fileExtensionn
          }
          if(!commande.listfiles){
            commande.listfiles = []
          }
          commande.listfiles.push(imgObject)
          listfiles.push(imgObject)
          this.uploadFileService.pushFilesToStorage(this.myfiles[t], listfiles[t].randomVariable).subscribe(event => {
          }, err => {
          })
        }
      }
      if (this.myfilesAccuse && this.myfilesAccuse.length > 0) {
        this.loading = true;
        var listfilesAcc = []
        for (var x = 0; x < this.myfilesAccuse.length; x++) {
          var fileExtensionn = this.myfilesAccuse[x].name.substr((this.myfilesAccuse[x].name.lastIndexOf('.') + 1));
          var numberlistfilesAccuse = 0
          if(commande.listfilesAccuse && commande.listfilesAccuse.length > 0){
            numberlistfilesAccuse = commande.listfilesAccuse.length
          }
          //var photo = this.utilisateur.id + i + '.' + fileExtension
          //var randomVariable = Math.floor(Math.random() * (999999999999 - 100000000000)) + 100000000000;
          var randomVariable = this.form.value.numeroCommandeGPMI + "_AR("+Number(x+numberlistfilesAccuse)+")"
          if(Number(x+numberlistfilesAccuse) == 0){
            var randomVariable = this.form.value.numeroCommandeGPMI + "_AR"
          }
          var imgObject = {
            randomVariable: randomVariable,
            name: this.myfilesAccuse[x].name,
            photo: environment.filesPath + randomVariable + "." + fileExtensionn
          }
          if(!commande.listfilesAccuse){
            commande.listfilesAccuse = []
          }
          commande.listfilesAccuse.push(imgObject)
          listfilesAcc.push(imgObject)
          this.uploadFileService.pushFilesToStorage(this.myfilesAccuse[x], listfilesAcc[x].randomVariable).subscribe(event => {
          }, err => {
          })
        }
      }

      if (this.myfilesFactures && this.myfilesFactures.length > 0) {
        this.loading = true;
        var listfilesFactures = []
        for (var s = 0; s < this.myfilesFactures.length; s++) {
          var fileExtensionn = this.myfilesFactures[s].name.substr((this.myfilesFactures[s].name.lastIndexOf('.') + 1));
          //var photo = this.utilisateur.id + i + '.' + fileExtension
          //var randomVariable = Math.floor(Math.random() * (999999999999 - 100000000000)) + 100000000000;
          var numberlistfilesFactures = 0
          if(commande.listfilesFactures && commande.listfilesFactures.length > 0){
            numberlistfilesFactures = commande.listfilesFactures.length
          }
          var randomVariable = this.form.value.numeroCommandeGPMI + "_F("+Number(s+numberlistfilesFactures)+")"
          if(Number(s+numberlistfilesFactures) == 0){
            var randomVariable = this.form.value.numeroCommandeGPMI + "_F"
          }
          var imgObject = {
            randomVariable: randomVariable,
            name: this.myfilesFactures[s].name,
            photo: environment.filesPath + randomVariable + "." + fileExtensionn
          }
          if(!commande.listfilesFactures){
            commande.listfilesFactures = []
          }
          commande.listfilesFactures.push(imgObject)
          listfilesFactures.push(imgObject)
          this.uploadFileService.pushFilesToStorage(this.myfilesFactures[s], listfilesFactures[s].randomVariable).subscribe(event => {
          }, err => {
          })
        }
      }

      if (this.myfilesBonLivraison && this.myfilesBonLivraison.length > 0) {
        this.loading = true;
        var listfilesBonLivraison = []
        for (var k = 0; k < this.myfilesBonLivraison.length; k++) {
          var fileExtensionn = this.myfilesBonLivraison[k].name.substr((this.myfilesBonLivraison[k].name.lastIndexOf('.') + 1));
          var numberlistfilesBonLivraison = 0
          if(commande.listfilesBonLivraison && commande.listfilesBonLivraison.length > 0){
            numberlistfilesBonLivraison = commande.listfilesBonLivraison.length
          }
          var randomVariable = this.form.value.numeroCommandeGPMI + "_BL("+Number(k+numberlistfilesBonLivraison)+")"
          if(Number(k+numberlistfilesBonLivraison) == 0){
            var randomVariable = this.form.value.numeroCommandeGPMI + "_BL"
          }
          var imgObject = {
            randomVariable: randomVariable,
            name: this.myfilesBonLivraison[k].name,
            photo: environment.filesPath + randomVariable + "." + fileExtensionn
          }
          if(!commande.listfilesBonLivraison){
            commande.listfilesBonLivraison = []
          }
          commande.listfilesBonLivraison.push(imgObject)
          listfilesBonLivraison.push(imgObject)
          this.uploadFileService.pushFilesToStorage(this.myfilesBonLivraison[k], listfilesBonLivraison[k].randomVariable).subscribe(event => {
          }, err => {
          })
        }
      }

      this.commandeService.editIcommandesrById(commande).subscribe(resp => {
        if(resp){
          this.saveHistoriquesCommandes(resp)
        }
      }, err => {
      })
      setTimeout(() => {
        /** spinner ends after 6 seconds **/
        this.loading = false;
        Swal.fire( {
            imageUrl: 'assets/images/statut-valide-hover.png',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            width: 800,
            title: 'BIEN MODIFIÉ',
            timer: 1500,
            showConfirmButton: false
          }
        );
        this.router.navigate(['/commande'])
      }, 10000);
    }
  }
  selectFiles(event) {
    this.myfiles = event.target.files
    if (this.myfiles) {
      if (this.myfiles.length > 5) {
        this.myfiles = undefined;
        Swal.fire({
          imageUrl: 'assets/images/error.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 600,
          title: 'Vous pouvez ajouter seulement 5 fichiers.',
          /*timer: 1500,*/
          showConfirmButton: true,
          confirmButtonColor: '#8B0000',
          confirmButtonWidth: 100,

        }
        );
        return false;
      }
      for (var j = 0; j < this.myfiles.length; j++) {
        if (this.myfiles[j].size > this.sizeLimitOne) {
          this.myfiles = undefined;
          Swal.fire({
            imageUrl: 'assets/images/error.png',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            width: 600,
            title: 'Tous les fichiers sélectionnés ne doivent pas dépasser 10 Mo.',
            /*timer: 1500,*/
            showConfirmButton: true,
            confirmButtonColor: '#8B0000',
            confirmButtonWidth: 100,

          }
          );
          return false;
        }
      }
    } else {
      return false;
    }
  }
  // controler les fichiers accuses
  selectFilesAccuse(event) {
    this.myfilesAccuse = event.target.files
    if (this.myfilesAccuse) {
      if (this.myfilesAccuse.length > 5) {
        this.myfilesAccuse = undefined;
        Swal.fire({
          imageUrl: 'assets/images/error.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 600,
          title: 'Vous pouvez ajouter seulement 5 fichiers.',
          /*timer: 1500,*/
          showConfirmButton: true,
          confirmButtonColor: '#8B0000',
          confirmButtonWidth: 100,

        }
        );
        return false;
      }
      for (var j = 0; j < this.myfilesAccuse.length; j++) {
        if (this.myfilesAccuse[j].size > this.sizeLimitOne) {
          this.myfilesAccuse = undefined;
          Swal.fire({
            imageUrl: 'assets/images/error.png',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            width: 600,
            title: 'Tous les fichiers sélectionnés ne doivent pas dépasser 10 Mo.',
            /*timer: 1500,*/
            showConfirmButton: true,
            confirmButtonColor: '#8B0000',
            confirmButtonWidth: 100,

          }
          );
          return false;
        }
      }
    } else {
      return false;
    }
  }

  // controler les fichiers factures
  selectFilesFactures(event) {
    this.myfilesFactures = event.target.files
    if (this.myfilesFactures) {
      if (this.myfilesFactures.length > 30) {
        this.myfilesFactures = undefined;
        Swal.fire({
          imageUrl: 'assets/images/error.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 600,
          title: 'Vous pouvez ajouter seulement 30 fichiers.',
          /*timer: 1500,*/
          showConfirmButton: true,
          confirmButtonColor: '#8B0000',
          confirmButtonWidth: 100,

        }
        );
        return false;
      }
      for (var j = 0; j < this.myfilesFactures.length; j++) {
        if (this.myfilesFactures[j].size > this.sizeLimitOne) {
          this.myfilesFactures = undefined;
          Swal.fire({
            imageUrl: 'assets/images/error.png',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            width: 600,
            title: 'Tous les fichiers sélectionnés ne doivent pas dépasser 10 Mo.',
            /*timer: 1500,*/
            showConfirmButton: true,
            confirmButtonColor: '#8B0000',
            confirmButtonWidth: 100,

          }
          );
          return false;
        }
      }
    } else {
      return false;
    }
  }

  selectFilesBonLivraison(event) {
    this.myfilesBonLivraison = event.target.files
    if (this.myfilesBonLivraison) {
      if (this.myfilesBonLivraison.length > 5) {
        this.myfilesBonLivraison = undefined;
        Swal.fire({
          imageUrl: 'assets/images/error.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 600,
          title: 'Vous pouvez ajouter seulement 5 fichiers.',
          /*timer: 1500,*/
          showConfirmButton: true,
          confirmButtonColor: '#8B0000',
          confirmButtonWidth: 100,

        }
        );
        return false;
      }
      for (var j = 0; j < this.myfilesBonLivraison.length; j++) {
        if (this.myfilesBonLivraison[j].size > this.sizeLimitOne) {
          this.myfilesBonLivraison = undefined;
          Swal.fire({
            imageUrl: 'assets/images/error.png',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            width: 600,
            title: 'Tous les fichiers sélectionnés ne doivent pas dépasser 10 Mo.',
            /*timer: 1500,*/
            showConfirmButton: true,
            confirmButtonColor: '#8B0000',
            confirmButtonWidth: 100,

          }
          );
          return false;
        }
      }
    } else {
      return false;
    }
  }
  getDateCommande(): string {
    return this.commande.dateCommande
  }
  getDateFacture(): string {
    return this.commande.dateCommande
  }
  addFacture(){
    const control = <FormArray>this.form.controls['factures'];
    control.push(this.initFacture());
  }
  removeFacture(i: number) {
    const control = <FormArray>this.form.controls['factures'];
    control.removeAt(i);
  }
  removeFactureCommande(facture){
    this.commande.factures.splice(this.commande.factures.indexOf(facture), 1);
  }
  saveHistoriquesCommandes(resp){
    var newHistoroques = {
      "module":"commande",
      "type":"modification",
      "date":new Date().toISOString(),
      "idModule": resp.id,
      "idUser":this.utilisateur.id,
      "modifications":[{
        "ancienModule":this.savedCommande,
        "nouveauModule":resp
      }]
    }
    this.historiqueService.addhistorique(newHistoroques)
      .subscribe(data => {
      }, err => {
      })
  }
  // supprimer fichier commandes
  deleteFileCommande(file){
    Swal.fire({
      title: 'VOULEZ-VOUS SUPPRIMER CE FICHIER?',
      imageUrl: 'assets/images/supp-modal.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      showCancelButton: true,
      width: 800,
      confirmButtonColor: '#059e00',
      cancelButtonColor: '#FF9100',
      cancelButtonText: 'ANNULER',
      confirmButtonText: 'VALIDER'
    }).then((result) => {
      if (!result.dismiss) {
        Swal.fire( {
            imageUrl: 'assets/images/supp-modal.png',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            width: 800,
            title: 'BIEN SUPPRIMÉ',
            timer: 1500,
            showConfirmButton: false
          }
        );
        this.commande.listfiles.splice(this.commande.listfiles.indexOf(file), 1);

      }
    } );
  }

  // supprimer fichier AR
  deleteFileAccuseReception(file){
    Swal.fire({
      title: 'VOULEZ-VOUS SUPPRIMER CE FICHIER?',
      imageUrl: 'assets/images/supp-modal.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      showCancelButton: true,
      width: 800,
      confirmButtonColor: '#059e00',
      cancelButtonColor: '#FF9100',
      cancelButtonText: 'ANNULER',
      confirmButtonText: 'VALIDER'
    }).then((result) => {
      if (!result.dismiss) {
        Swal.fire( {
            imageUrl: 'assets/images/supp-modal.png',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            width: 800,
            title: 'BIEN SUPPRIMÉ',
            timer: 1500,
            showConfirmButton: false
          }
        );
        this.commande.listfilesAccuse.splice(this.commande.listfilesAccuse.indexOf(file), 1);

      }
    } );

  }

  // supprimer fichier factures
  deleteFileFacture(file){
    Swal.fire({
      title: 'VOULEZ-VOUS SUPPRIMER CE FICHIER?',
      imageUrl: 'assets/images/supp-modal.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      showCancelButton: true,
      width: 800,
      confirmButtonColor: '#059e00',
      cancelButtonColor: '#FF9100',
      cancelButtonText: 'ANNULER',
      confirmButtonText: 'VALIDER'
    }).then((result) => {
      if (!result.dismiss) {
        Swal.fire( {
            imageUrl: 'assets/images/supp-modal.png',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            width: 800,
            title: 'BIEN SUPPRIMÉ',
            timer: 1500,
            showConfirmButton: false
          }
        );
        this.commande.listfilesFactures.splice(this.commande.listfilesFactures.indexOf(file), 1);

      }
    } );
  }

  // supprimer fichier BL
  deleteFileBL(file){
    Swal.fire({
      title: 'VOULEZ-VOUS SUPPRIMER CE FICHIER?',
      imageUrl: 'assets/images/supp-modal.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      showCancelButton: true,
      width: 800,
      confirmButtonColor: '#059e00',
      cancelButtonColor: '#FF9100',
      cancelButtonText: 'ANNULER',
      confirmButtonText: 'VALIDER'
    }).then((result) => {
      if (!result.dismiss) {
        Swal.fire( {
            imageUrl: 'assets/images/supp-modal.png',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            width: 800,
            title: 'BIEN SUPPRIMÉ',
            timer: 1500,
            showConfirmButton: false
          }
        );
        this.commande.listfilesBonLivraison.splice(this.commande.listfilesBonLivraison.indexOf(file), 1);
      }
    } );
  }

  valuechangeNumFacture(event, j) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function() {
      if (event.keyCode != 13) {
        $this.updateNumFacture(event, j)
      }
    }, 2000);
  }
  updateNumFacture(event, j){
    this.commande.factures[j].numFacture = event.target.value

  }

  valuechangedateFacture(event, j) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function() {
      if (event.keyCode != 13) {
        $this.updateDateFacture(event, j)
      }
    }, 2000);
  }
  updateDateFacture(event, j){
    if(this.commande.dateCommande > event.target.value){
      event = undefined
      return false
    }
    this.commande.factures[j].dateFacture = event.target.value
  }
}

