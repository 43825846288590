import { Component, OnInit, ViewChild, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { DossierService } from "../../../services/dossier.service";
import { UserService } from "../../../services/user.service";
import { UploadFileService } from "../../../services/upload-file-service.service";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
import * as XLSX from "xlsx";
import * as moment from 'moment';
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'app-dossier',
  templateUrl: './dossier.component.html',
  styleUrls: ['./dossier.component.css','../../utilisateur/utilisateur/utilisateur.component.css']
})
export class DossierComponent implements OnInit {
  dossiers: any;
  listedossiersExcel: any[] = [];
  myDatta: any;
  admin: any;
  allowFiles = false;
  public search: string = '';
  id: any;
  utilisateur: any;
  successRegister = false;
  roleAdministration = true;
  role: any;
  loading = false;
  statut = "02- NE";
  showRemarqueDate = false;
  remarques: any;
  dateRelance: any;
  administration = false;
  numberData = 25;
  pageNumber = 0
  lenghtData: any = 0;
  filterDossier: any;
  timeout: any = null;
  daterece:any;
  hasRoleDelete = false;
  hasRoleUpdate = false;
  hasRoleAdd = false;
  hasRolecommande = false;
  @ViewChildren('closeBtnInfoDetails') closeBtnInfoDetails: QueryList<ElementRef>;
  p=1
  constructor(private roleService:RoleService,private router: Router, private dossierService: DossierService, private userService: UserService, private uploadFileService: UploadFileService) {

  }
  ngOnInit() {
    this.getAllDossiers()
    this.role = localStorage.getItem('roleUser')
    if (this.role) {
      this.roleAdministration = false;
      this.administration = true;
    }
    this.id = localStorage.getItem('idUser')
    if (this.id) {
      this.userService.getUserByID(this.id).subscribe(utilisateur => {
        this.utilisateur = utilisateur;
        if(!this.utilisateur || (this.utilisateur && !this.utilisateur.idRole)){
          localStorage.removeItem('idUser');
          localStorage.clear();
          this.router.navigate(['/login']);
        }
        this.roleService.getRoleById(this.utilisateur.idRole).subscribe(role => {
          this.role = role;
          if (this.role && this.role.privileges && this.role.privileges.length > 0) {
            let founddeleteUtilisateur = this.role.privileges.find(x => x.name == "DELETE_DOSSIER")
            let foundupdateUtilisateur = this.role.privileges.find(x => x.name == "UPDATE_DOSSIER")
            let foundupdateUtilisateuradd = this.role.privileges.find(x => x.name == "ADD_DOSSIER")
            let foundupdateUtilisateurcommande = this.role.privileges.find(x => x.name == "GET_ALL_COMMANDE")
            if (founddeleteUtilisateur) {
              this.hasRoleDelete = true;
            }
            if (foundupdateUtilisateur) {
              this.hasRoleUpdate = true;
            }
            if (foundupdateUtilisateuradd) {
              this.hasRoleAdd = true;
            }
            if (foundupdateUtilisateurcommande) {
              this.hasRolecommande = true;
            }
          }
        },
          err => {
            return false;
          });
      },
        err => {
          return false;
        });
    } else {
      return false;
    }
  }
  // get all dossiers
  getAllDossiers() {
    this.loading = true;
    this.pageNumber = 0
    this.dossierService.getAllDossiersByPage(this.pageNumber, 10).then(data => {
      this.dossiers = data
      this.loading = false;
    },
      err => {
        return false;
      });


    this.dossierService.getAllDataCount().then(data => {
      this.lenghtData = data
      //this.loading = false;
    },
      err => {
        return false;
      });
  }
  // supprimer un dossier
  removeDossier(dossier) {
    Swal.fire({
      title: 'VOULEZ-VOUS SUPPRIMER CE DOSSIER?',
      imageUrl: 'assets/images/supp-modal.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      showCancelButton: true,
      width: 800,
      confirmButtonColor: '#059e00',
      cancelButtonColor: '#FF9100',
      cancelButtonText: 'ANNULER',
      confirmButtonText: 'VALIDER'
    }).then((result) => {
      if (!result.dismiss) {
        Swal.fire( {
            imageUrl: 'assets/images/supp-modal.png',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            width: 800,
            title: 'BIEN SUPPRIMÉ',
            timer: 1500,
            showConfirmButton: false
          }
        );
        this.dossierService.deleteDossierById(dossier.id).then(data => {
        });
        this.dossiers.splice(this.dossiers.indexOf(dossier), 1);
      }
    } );

  }
  // cloture un dossier
  cloturer(dossier) {
    if (window.confirm("Voulez vous vraiment clôturer ce dossier?")) {
      dossier.statut = "11 - Livrée"
      this.dossierService.editDossier(dossier)
        .subscribe(resp => {
        }, err => {

        })
    }
  }


  // update réponse client
  updateReponseClientDossier(dossier) {
    var d = new Date();
    dossier.dateMod = d.toLocaleString();
    this.dossierService.editDossier(dossier)
      .subscribe(resp => {
        Swal.fire( {
            imageUrl: 'assets/images/statut-valide-hover.png',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            width: 800,
            title: 'BIEN MODIFIÉ',
            timer: 1500,
            showConfirmButton: false
          }
        );
        this.closeBtnInfoDetails.toArray().forEach(el => {
          el.nativeElement.click();
        });
      }, err => {
      })

    //this.successRegister = true
  }
  // show and hide remaque and date
  selectStatut(statutActuel) {
    if (statutActuel === "03 - Null" || statutActuel === "05 - En attente" || statutActuel === "07 - Perdu" || statutActuel === "08 - DM") {
      this.showRemarqueDate = true;
    } else {
      this.showRemarqueDate = false;
    }
  }
  initialise(dossier) {
    this.successRegister = false
    if (dossier.statut === "03 - Null" || dossier.statut === "05 - En attente" || dossier.statut === "07 - Perdu" || dossier.statut === "08 - DM") {
      this.showRemarqueDate = true;
    } else {
      this.showRemarqueDate = false;
    }
  }

  onScroll() {
    setTimeout(() => {    //<<<---    using ()=> syntax
      this.addData();
    }, 1000);

  }

  addData() {
    if (this.dossiers && this.lenghtData === this.dossiers.length) {
      return false;
    }
    this.pageNumber = this.pageNumber + 1

    this.dossierService.getAllDossiersByPage(this.pageNumber, 10).then(data => {
      if (data) {
        this.myDatta = data
        for (var j = 0; j < this.myDatta.length; j++) {
          var dataArray = this.myDatta[j].devis.filter( x=> x.etat == "D4 - Envoyé")
          dataArray.sort(this.triDevisDateEnvoie)
          this.myDatta[j].dataDevis  = dataArray
          this.myDatta[j].delai = this.calculateBusinessDays(moment(this.myDatta[j].dateReception),moment(this.myDatta[j].datel))
          this.dossiers.push(this.myDatta[j])
        }
      }
    },
      err => {
        return false;
      });
  }


  valuechange(event) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function() {
      if (event.keyCode != 13) {
        $this.getRechercheResult(event.target.value)
      }
    }, 1000);
  }

  getRechercheResult(event) {
    this.loading = true;
    if (event && event.length > 0 && event.length < 3) {
      this.loading = false;
      return false;
    }
    this.dossiers = [];
    while (this.dossiers.length) {
      this.dossiers.pop();
    }
    if (!event || event === "") {
      this.pageNumber = 0
      this.dossierService.getAllDossiersByPage(this.pageNumber, 10).then(data => {
        if (data) {
          this.myDatta = data
          for (var j = 0; j < this.myDatta.length; j++) {
            var dataArray = this.myDatta[j].devis.filter( x=> x.etat == "D4 - Envoyé")
            dataArray.sort(this.triDevisDateEnvoie)
            this.myDatta[j].dataDevis  = dataArray
            this.myDatta[j].delai = this.calculateBusinessDays(moment(this.myDatta[j].dateReception),moment(this.myDatta[j].datel))
            this.dossiers.push(this.myDatta[j])
          }
          this.loading = false;
        }
        this.loading = false;
        //this.loading = false;
      },
        err => {
          this.loading = false;
          return false;
        });
      return false;
    }
    this.dossierService.getAllDataByPageAndRecherche(event).then(data => {
      if (data) {
        this.myDatta = data
        for (var j = 0; j < this.myDatta.length; j++) {
          var dataArray = this.myDatta[j].devis.filter( x=> x.etat == "D4 - Envoyé")
          dataArray.sort(this.triDevisDateEnvoie)
          this.myDatta[j].dataDevis  = dataArray
          this.myDatta[j].delai = this.calculateBusinessDays(moment(this.myDatta[j].dateReception),moment(this.myDatta[j].datel))
          this.dossiers.push(this.myDatta[j])
        }
        this.loading = false;
      }
      this.loading = false;
      //this.loading = false;
    },
      err => {
        this.loading = false;
        return false;
      });
  }
  toFileXLS() {
    this.loading = true;
    this.dossierService.getAllDossiers().then(data => {
      if (data) {
        this.poursuivrexls(data);
      }
    },
      err => {
        this.loading = false;
        return false;
      });

  }
  poursuivrexls(contacts){
    for (var i = 0; i < contacts.length; i++) {

      if(contacts[i].dateReception && contacts[i].dateReception != null){
         this.daterece = moment(contacts[i].dateReception).format('DD/MM/YYYY')
      }
      if(contacts[i].dateReception == null){
         this.daterece = contacts[i].dateReception
      }
      if(contacts[i].personneVisee){
        var devExcel = {
          "Numéro du dossier": contacts[i].numero,
          "Type": contacts[i].type,
          "Nom du dossier": contacts[i].nom,
          "Coefficient du dossier": contacts[i].coef,
          "Client": contacts[i].client.denomination,
          "Chargé d'affaires": contacts[i].chargeAffaire.nom + " "+ contacts[i].chargeAffaire.prenom,
          "Créé par": contacts[i].user.nom + " "+ contacts[i].user.prenom,
          "Date limite": moment(contacts[i].datel).format('DD/MM/YYYY'),
          "Date de réception du dossier":this.daterece,
          "Chiffreur":contacts[i].personneVisee.prenom + " " + contacts[i].personneVisee.nom,
          "Statut":contacts[i].statut,
          "Réponse client	":contacts[i].reponseClient,
          "Date de création	":contacts[i].datec,
          "Date de modification	":contacts[i].dateMod,
        }
      }
      if(!contacts[i].personneVisee){
        var devExcel = {
          "Numéro du dossier": contacts[i].numero,
          "Type": contacts[i].type,
          "Nom du dossier": contacts[i].nom,
          "Coefficient du dossier": contacts[i].coef,
          "Client": contacts[i].client.denomination,
          "Chargé d'affaires": contacts[i].chargeAffaire.nom + " "+ contacts[i].chargeAffaire.prenom,
          "Créé par": contacts[i].user.nom + " "+ contacts[i].user.prenom,
          "Date limite": moment(contacts[i].datel).format('DD/MM/YYYY'),
          "Date de réception du dossier":this.daterece,
          "Chiffreur":"",
          "Statut":contacts[i].statut,
          "Réponse client	":contacts[i].reponseClient,
          "Date de création	":contacts[i].datec,
          "Date de modification	":contacts[i].dateMod,
        }
      }

      this.listedossiersExcel.push(devExcel)
    }
    this.loading = false;
    const workBook = XLSX.utils.book_new(); // create a new blank book
    const workSheet = XLSX.utils.json_to_sheet(this.listedossiersExcel);

    XLSX.utils.book_append_sheet(workBook, workSheet, 'data'); // add the worksheet to the book
    var currentDate = new Date().toISOString().split('T')[0]
    var mydate =  currentDate[8]+currentDate[9]+currentDate[5]+currentDate[6]+currentDate[2]+currentDate[3]
    XLSX.writeFile(workBook, 'VoWork-Dossiers-'+mydate+'.xlsx'); // initiate a file download in browser
  }
  triDevis(a, b) {
    if (a.datec > b.datec) return -1;
    else if (a.datec == b.datec) return 0;
    else return 1;
  }
  triDevisDateEnvoie(a, b) {
    if(a.dateEnvoie && b.dateEnvoie){
      if (a.dateEnvoie > b.dateEnvoie) return -1;
      else if (a.dateEnvoie == b.dateEnvoie) return 0;
      else return 1;
    }

  }
  calculateBusinessDays(start_date, end_date){
    const d1 = start_date.clone();
    let num_days = 0;
    while(end_date.diff(d1.add(1, 'days')) > 0) {
            if ([0, 6].includes(d1.day())) {
                    // Don't count the days
            } else {
                    num_days++;
            }
    }
    return num_days;
  }
}
