import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SupAuthGuard } from './guards/supAuth.guard'

const routes: Routes = [

  {
    path: '',
    loadChildren: () => import('./authentification/authentification.module')
      .then(mod => mod.AuthentificationModule)
  },
  {
    path: '',
    loadChildren: () => import('./dashboard/home/home.module')
      .then(mod => mod.HomeModule)
  },
  {
    path: '',
    loadChildren: () => import('./dashboard/utilisateur/utilisateur.module')
      .then(mod => mod.UtilisateurModule)
  },
  {
    path: '',
    loadChildren: () => import('./dashboard/client/client.module')
      .then(mod => mod.CLIENTModule)
  },
  {
    path: '',
    loadChildren: () => import('./dashboard/charge-affaire/charge-affaire.module')
      .then(mod => mod.ChargeAffaireModule)
  },
  {
    path: '',
    loadChildren: () => import('./dashboard/fournisseur/fournisseur.module')
      .then(mod => mod.FournisseurModule)
  },
  {
    path: '',
    loadChildren: () => import('./dashboard/dossier/dossier.module')
      .then(mod => mod.DossierModule)
  },
  {
    path: '',
    loadChildren: () => import('./dashboard/commande/commande.module')
      .then(mod => mod.CommandeModule)
  },
  {
    path: '',
    loadChildren: () => import('./dashboard/catalogue/catalogue.module')
      .then(mod => mod.CatalogueModule)
  },
  {
    path: '',
    loadChildren: () => import('./dashboard/inventaire/inventaire.module')
      .then(mod => mod.InventaireModule)
  },
  {
    path: '',
    loadChildren: () => import('./dashboard/stock/stock.module')
      .then(mod => mod.StockModule)
  },
  {
    path: '',
    loadChildren: () => import('./dashboard/rh/rh.module')
      .then(mod => mod.RhModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module')
      .then(mod => mod.AdminModule),canActivate: [SupAuthGuard] 
  },
  {
    path: '',
    loadChildren: () => import('./dashboard/agence-voyage/agence-voyage.module')
      .then(mod => mod.AgenceVoyageModule)
  },
  { path: '**', redirectTo: '/login' }
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
