import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ClientService } from "../../../services/client.service";
import { DossierService } from "../../../services/dossier.service";
import { UserService } from "../../../services/user.service";

@Component({
  selector: 'app-details-client',
  templateUrl: './details-client.component.html',
  styleUrls: ['../../utilisateur/details-utilisateur/details-utilisateur.component.css'],
})
export class DetailsClientComponent implements OnInit {
  client: any;
  roleAdministration = true;
  role: any;
  dossiers:any
  clientAllDossiers:any[]=[]
  loading = false;
  nbreDossiersByYears = []
  filterBytype:any[]=[]
  allyears:any;
  filterByCA:any;
  filterByStatut:any[]=[]
  dossierbyidClientFilterBYCA:any;
  dossierbyidClientFilterBYCH:any;
  idAllCA:any[]=[]
  idAllCH:any[]=[]
  allUser:any;
  utilisateur:any;
  id:any;
  showMyContainer = 1;
  enfantpp = 1;
  fp = 1
  enfantp = 1;
  sp = 1;
  ddd = 1;
  pc = 1;
  p=1;
  constructor(private clientService: ClientService, private activatedRoute: ActivatedRoute,private dossierService:DossierService,private userService:UserService,
    private router: Router) {

  }
  ngOnInit() {
    this.role = localStorage.getItem('roleUser')
    if (this.role) {
      this.roleAdministration = false;
    }
    this.id = localStorage.getItem('idUser')
    this.userService.getUserByID(this.id).subscribe(utilisateur => {
      this.utilisateur = utilisateur;
      if(!this.utilisateur){
        localStorage.removeItem('idUser');
        localStorage.clear();
        this.router.navigate(['/login']);
      }
      if(this.utilisateur.sousRole == 'gr-acces'){
        this.router.navigate(["accueil"]);
        return false;
      }
    },
      err => {
        return false;
      });
    let params: any = this.activatedRoute.snapshot.params;
    this.loading = true;
    this.clientService.getClientByID(params.id).subscribe(client => {
      this.client = client;
      if(this.client){
        this.getAllDossierByClient(params.id)
      }
    },
      err => {
        return false;
      });
  }
getAllDossierByClient(idClient){
  this.dossierService.getAllDossiers().then(data => {
    this.dossiers = data;
    if(this.dossiers){
      this.filterDossierByClient(this.dossiers,idClient)
    }
  },
    err => {
      return false;
    });
}
 filterDossierByClient(dossiers,idClient){
   for(var j=0;j<dossiers.length;j++){
     if(dossiers[j].client && (dossiers[j].client.id === idClient)){
       this.clientAllDossiers.push(dossiers[j]);
     }
   }
   if(this.clientAllDossiers.length > 0){
     this.filterDossierByYears(this.clientAllDossiers)
     this.loading = false;
   }
   if(this.clientAllDossiers.length < 1){
     this.loading = false;
   }
 }

 // filter dossiers by years
filterDossierByYears(dossiers){
  let params: any = this.activatedRoute.snapshot.params;
  var dossiersByeayrs = {};
  var years = [];
 dossiers.forEach(function (a) {
   if(a.dateReception){
   dossiersByeayrs[a.dateReception.substr(0, 4)] = dossiersByeayrs[a.dateReception.substr(0, 4)] || [];
   if(years.indexOf(a.dateReception.substr(0, 4)) === -1){
     years.push(a.dateReception.substr(0, 4))
   }
   dossiersByeayrs[a.dateReception.substr(0, 4)].push(a);
 }
 });
 this.allyears = years.sort(this.triYears);
 for(var d =0;d<years.length;d++){
   this.filterBytype[years[d]] = {
     "Etude":[],
     "Réalisation":[],
     "Intervention":[],
     "Fournitures":[]
   }
   this.filterByStatut[years[d]] = {
     "01 - Non affecté":[],
     "02- Vide":[],
     "03 - Null":[],
     "04 - En cours":[],
     "05 - En attente":[],
     "06 - Envoyé":[],
     "07 - Perdu":[],
     "08 - DM":[],
     "09 - CDE":[],
     "10 - FAB":[],
     "11 - Livrée":[]

   }
   for(var t=0;t<dossiersByeayrs[years[d]].length;t++){
     if (dossiersByeayrs[years[d]][t].type === "Etude") {
       this.filterBytype[years[d]]["Etude"].push(dossiersByeayrs[years[d]][t])

     }
     if (dossiersByeayrs[years[d]][t].type === "Réalisation") {
      this.filterBytype[years[d]]["Réalisation"].push(dossiersByeayrs[years[d]][t])
     }
     if (dossiersByeayrs[years[d]][t].type === "Intervention") {
       this.filterBytype[years[d]]["Intervention"].push(dossiersByeayrs[years[d]][t])
     }
     if (dossiersByeayrs[years[d]][t].type === "Fournitures") {
       this.filterBytype[years[d]]["Fournitures"].push(dossiersByeayrs[years[d]][t])
     }

     if (dossiersByeayrs[years[d]][t].statut === "01 - Non affecté") {
       this.filterByStatut[years[d]]["01 - Non affecté"].push(dossiersByeayrs[years[d]][t])
     }
     if (dossiersByeayrs[years[d]][t].statut === "02- Vide") {
       this.filterByStatut[years[d]]["02- Vide"].push(dossiersByeayrs[years[d]][t])
     }
     if (dossiersByeayrs[years[d]][t].statut === "03 - Null") {
       this.filterByStatut[years[d]]["03 - Null"].push(dossiersByeayrs[years[d]][t])
     }
     if (dossiersByeayrs[years[d]][t].statut === "04 - En cours") {
       this.filterByStatut[years[d]]["04 - En cours"].push(dossiersByeayrs[years[d]][t])
     }
     if (dossiersByeayrs[years[d]][t].statut === "05 - En attente") {
       this.filterByStatut[years[d]]["05 - En attente"].push(dossiersByeayrs[years[d]][t])
     }
     if (dossiersByeayrs[years[d]][t].statut === "06 - Envoyé") {
       this.filterByStatut[years[d]]["06 - Envoyé"].push(dossiersByeayrs[years[d]][t])
     }
     if (dossiersByeayrs[years[d]][t].statut === "07 - Perdu") {
       this.filterByStatut[years[d]]["07 - Perdu"].push(dossiersByeayrs[years[d]][t])
     }
     if (dossiersByeayrs[years[d]][t].statut === "08 - DM") {
       this.filterByStatut[years[d]]["08 - DM"].push(dossiersByeayrs[years[d]][t])
     }
     if (dossiersByeayrs[years[d]][t].statut === "09 - CDE") {
       this.filterByStatut[years[d]]["09 - CDE"].push(dossiersByeayrs[years[d]][t])
     }
     if (dossiersByeayrs[years[d]][t].statut === "10 - FAB") {
       this.filterByStatut[years[d]]["10 - FAB"].push(dossiersByeayrs[years[d]][t])
     }
     if (dossiersByeayrs[years[d]][t].statut === "11 - Livrée") {
       this.filterByStatut[years[d]]["11 - Livrée"].push(dossiersByeayrs[years[d]][t])
     }
   }
 }

 this.dossierService.getDossierbyidClientFilterBYCA(params.id).then(data => {
   var testDossier = {};
   var testyears = []
   if(data && data["result"].length >0){
     data["result"].forEach(function (a) {
       if(a.yeardateReception.idCA){
       testDossier[a.yeardateReception.idCA] = testDossier[a.yeardateReception.idCA] || [];
       if(testyears.indexOf(a.yeardateReception.idCA) === -1){
        testyears.push(a.yeardateReception.idCA)
       }
       testDossier[a.yeardateReception.idCA].push(a);

     }
     });
   }
   this.idAllCA = testyears
   this.dossierbyidClientFilterBYCA = testDossier

 },
   err => {
     return false;
   });

   this.dossierService.getDossierbyidClientFilterBYChiffreur(params.id).then(data => {
     var testDossierr = {};
     var testyearss = []
     if(data && data["result"].length >0){
       data["result"].forEach(function (a) {
         if(a.yeardateReception.mailCH){
         testDossierr[a.yeardateReception.mailCH] = testDossierr[a.yeardateReception.mailCH] || [];
         if(testyearss.indexOf(a.yeardateReception.mailCH) === -1){
          testyearss.push(a.yeardateReception.mailCH)
         }
         testDossierr[a.yeardateReception.mailCH].push(a);
       }
       });
     }
     this.idAllCH = testyearss
     this.dossierbyidClientFilterBYCH = testDossierr
   },
     err => {
       return false;
     });

  return true;
}

triYears(a,b){
      if (a < b) return -1;
      else if (a == b) return 0;
      else return 1;
}
filterByCHiffreur(idAllCH){

}
modifPart1() {
    this.showMyContainer = 1;

  }
  modifPart2(){
    this.showMyContainer = 2;
  }
  modifPart3(){
    this.showMyContainer = 3;
  }
  modifPart4(){
    this.showMyContainer = 4;
  }
  modifPart5(){
    this.showMyContainer = 5;
  }
  showPart() {
    return this.showMyContainer;
  }
}
