import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http } from '@angular/http';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { ValidationService } from '../../../services/validate/validate-error.service';
import { pays } from '../../../pays/pays';
import { tri } from '../../../pays/pays';
import { ValidateService } from '../../../services/validate/validate.service';
import { languages } from '../../../pays/langues';
import { ClientService } from "../../../services/client.service";
import { ChargeAffaireService } from "../../../services/charge-affaire.service";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
import { codepostale } from 'src/app/pays/villes';

@Component({
  selector: 'app-ajout-charge-affaire',
  templateUrl: './ajout-charge-affaire.component.html',
  styleUrls: ['../../utilisateur/ajout-utilisateur/ajout-utilisateur.component.scss'],
})
export class AjoutChargeAffaireComponent implements OnInit {
  pys: any[] = [];
  vlls: any[] = [];
  clients: any;
  parcours: any[] = [];
  experiences: any[] = [];
  langues: any[] = [];
  languages: any[] = [];
  competences: any[] = [];
  competence: any;
  form: any;
  langue = "";
  formConjoint: any;
  formExperience: any;
  formParcours: any;
  addConjointAndEnfants = false;
  addEnfants = false;
  situationFamiliale = 'Célibataire';
  situationEnfant = 'Non';
  nom: any;
  prenom: any;
  dateNaissance: any;
  sexe = 'Masculin';
  errorDates = false;
  formClient: any;
  formEnfant: any;
  enfants: any[] = [];
  roleAdministration = true;
  role: any;
  showButtonClientForm = false;
  selectedClient: any;
  selectedClientExp:any;
  showErrorEtabClient = false;
  testEmail: any;
  showSuivantBouton = false;
  showErrorEmail = false;
  disabled = false;
  selectedEmail: any;
  etablissements: any[] = []
  etablissement:any =  "Établissement*"
  showEt = false;
  etablissementsExp: any[] = []
  etablissementExp: any;
  administration = false;
  dateFinPoste = new Date().toISOString().split('T')[0]
  myClient:any;
  dateNaissancee = new Date().toISOString().split('T')[0]
  showMyContainer = 1;
  errorLangue = ""
  languesChosed: any[] = []
  competencesChosed: any[] = []
  today = new Date().toISOString().split('T')[0]
  errorCompetence = ""
  errorPartie1 = ""
  errorPartie2 = ""
  bigDate = "9999-12-31"
  errorValidation:any;
  secondp = 1;
  thirdp = 1;
  p = 1;
  constructor(private chargeAffaireService: ChargeAffaireService, private clientService: ClientService, private http: Http, private formBuilder: FormBuilder, private validateService: ValidateService,
    private router: Router) {
    this.form = this.formBuilder.group({
      nom: ['', [Validators.required, Validators.minLength(2), ValidationService.validateEmptyField, Validators.maxLength(25)]],
      prenom: ['', [Validators.required, Validators.minLength(2), ValidationService.validateEmptyField, Validators.maxLength(25)]],
      tel: ['', [Validators.required]],
      telMobile: ['', [Validators.required]],
      sexe: ['Masculin', Validators.required,],
      typeIdentite: ['CIN'],
      numIdentite: [''],
      nationalite: ['Tunisie',],
      dateNaissance: ['', ValidationService.dateValidator],
      adresse: this.formBuilder.group({
        pays: ['Tunisie',],
        codePostal: ['', [ValidationService.numberValidator,Validators.min(0),Validators.max(9999999)]],
        rue: ['',],
        n: ['', [ValidationService.numberValidator,Validators.min(0),Validators.max(9999999)]],
        ville: ['',],
      }),
      poste: this.formBuilder.group({
        titre: [''],
        departement: [''],
        bureau: [''],
        dated: [''],
        datef: ['',]
      }),
      linkedin: ['']
    });
    const currentDate = new Date().toISOString().substring(0, 10);
    this.form.controls['poste'].controls['dated'].setValue(currentDate);
    this.formConjoint = this.formBuilder.group({
      nom: ['', [Validators.minLength(2)]],
      prenom: ['', [Validators.minLength(2)]],
      tel: ['',],
      mail: ['', [ValidationService.emailValidator, ValidationService.nospaceValidator]],
      OrganismeDeSante: ['',],
      nationalite: ['Tunisie']
    })
    this.formParcours = this.formBuilder.group({
      etablissement: ['', [Validators.required, Validators.minLength(4), ValidationService.validateEmptyField]],
      diplomen: ['', [Validators.required, Validators.minLength(4), ValidationService.validateEmptyField]],
      diplomet: ['Ouvrier', Validators.required],
      date: ['', [ValidationService.dateValidator]],
      desc: ['',]
    })
    this.formExperience = this.formBuilder.group({
      entreprise: ['', Validators.required],
      etablissementExp: ['', Validators.required],
      poste: ['', [Validators.required, Validators.minLength(4), ValidationService.validateEmptyField]],
      dated: ['', [ValidationService.dateValidator]],
      datef: ['',],
      departement: [''],
      bureau: [''],
      desc: ['',]
    })
    this.formClient = this.formBuilder.group({
      client: ['', Validators.required],
      mail: ['', [Validators.required, ValidationService.emailValidator, ValidationService.nospaceValidator]],
      etablissement: ['', Validators.required],
    })
    this.formEnfant = this.formBuilder.group({
      nom: ['', [Validators.required, Validators.minLength(2), ValidationService.validateEmptyField]],
      prenom: ['', [Validators.required, Validators.minLength(2), ValidationService.validateEmptyField]],
      dateNaissance: ['', [Validators.required, ValidationService.dateValidator]],
      sexe: ['Masculin', [Validators.required]],
    })
  }
  ngOnInit() {
    this.pys = pays.sort(tri);
    this.pys = this.pys.filter(x => x.name === "Tunisie")
    this.vlls = codepostale
    this.languages = languages
    this.getAllClients();
    this.role = localStorage.getItem('roleUser')
    if (this.role) {
      this.roleAdministration = false;
      this.administration = true;
    }
  }
  // recuperer tous les clients
  getAllClients() {
    this.clientService.getAllUsers().then(data => {
      this.clients = data;
      this.clients = this.clients.sort(this.triClient);
    },
      err => {
        return false;
      });
  }
  getBigDate(): string {
      return this.bigDate
    }
  // verify email chargés d'affaires
  verifyEmailChargAffaire() {
    if(!this.validateService.validateEmail(this.formClient.value.mail) || !this.formClient.value.mail || this.formClient.value.mail === "" || this.formClient.value.mail == null){
      this.errorValidation = "Merci de vérifier le champ Email du CA"
      return false;
    }
    if( !this.formClient.value.etablissement || this.formClient.value.etablissement === "" || this.formClient.value.etablissement == null){
      this.errorValidation = "Merci de vérifier le champ Établissement"
      return false;
    }
    this.showErrorEmail = false;
    this.showSuivantBouton = false;
    this.chargeAffaireService.verifyEmail(this.formClient.value).subscribe(data => {
      if (data) {
        this.showErrorEmail = true;
        this.showSuivantBouton = false;
        return false;
      }
      else {
        this.showErrorEmail = false;
        this.showSuivantBouton = true;
        this.disabled = true;
        this.selectedEmail = this.formClient.value.mail;
        this.form.value.adresse.ville = this.formClient.value.client.adresse.ville
        this.form.value.adresse.pays = this.formClient.value.client.adresse.pays
        this.form.value.adresse.n = this.formClient.value.client.adresse.n
        this.form.value.adresse.rue = this.formClient.value.client.adresse.rue
        this.form.value.adresse.codePostal = this.formClient.value.client.adresse.codePostal
      }

    })
  }
  // select client
  selectClient() {
    this.etablissement =  ""
    this.myClient = this.formClient.value.client
    this.showErrorEtabClient = false;
    this.etablissement = undefined
    while(this.etablissements.length > 0) {
    this.etablissements.pop();
    }
    if (this.formClient.value.client.etablissements.length < 1) {
      var newEtabl = {
        "nom": this.formClient.value.client.denomination,
        "type": "Etablissement principale",
        "adresse": this.formClient.value.client.adresse,
        "tel": this.formClient.value.client.tel,
        "mail": this.formClient.value.client.mail,
        "statutEtab":this.formClient.value.client.statut,
        "dateRadiationEtab":this.formClient.value.client.dateRadiation
      }
      this.etablissements.push(newEtabl)
      this.showButtonClientForm = true;
      return true;
    }
    if (this.formClient.value.client.etablissements.length > 0) {
      var newEtabl = {
        "nom": this.formClient.value.client.denomination,
        "type": "Etablissement principale",
        "adresse": this.formClient.value.client.adresse,
        "tel": this.formClient.value.client.tel,
        "mail": this.formClient.value.client.mail,
        "statutEtab":this.formClient.value.client.statut,
        "dateRadiationEtab":this.formClient.value.client.dateRadiation
      }
      for(var i = 0;i<this.formClient.value.client.etablissements.length;i++){
        this.etablissements.push(this.formClient.value.client.etablissements[i])
      }
      this.etablissements.push(newEtabl)
      this.showButtonClientForm = true;
      return true;
    }
    else {
      this.showButtonClientForm = true;
    }
  }
  selectClientExp() {
    this.etablissementExp = undefined
    while(this.etablissementsExp.length > 0) {
    this.etablissementsExp.pop();
    }
    if (this.formExperience.value.entreprise.etablissements.length < 1) {
      var newEtabl = {
        "nom": this.formExperience.value.entreprise.denomination,
        "type": "Etablissement principale",
        "adresse": this.formExperience.value.entreprise.adresse,
        "tel": this.formExperience.value.entreprise.tel,
        "mail": this.formExperience.value.entreprise.mail,
      }
      this.etablissementsExp.push(newEtabl)
      return true;
    }
    if (this.formExperience.value.entreprise.etablissements.length > 0) {
      var newEtabl = {
        "nom": this.formExperience.value.entreprise.denomination,
        "type": "Etablissement principale",
        "adresse": this.formExperience.value.entreprise.adresse,
        "tel": this.formExperience.value.entreprise.tel,
        "mail": this.formExperience.value.entreprise.mail,
      }
      for(var i = 0;i<this.formExperience.value.entreprise.etablissements.length;i++){
        this.etablissementsExp.push(this.formExperience.value.entreprise.etablissements[i])
      }
      this.etablissementsExp.push(newEtabl)
      return true;
    }
    else {
      this.showButtonClientForm = true;
    }
  }
  selectEtablissement(){
    this.showEt = true;
    this.selectedClient = this.formClient.value.etablissement;
  }
  selectEtablissementExp(){
    this.etablissementExp = this.formExperience.value.etablissementExp;
  }
  showOthersSituationEnfant() {
    if (this.situationEnfant === "Non") {
      this.addEnfants = false;
    }
    else {
      this.addEnfants = true;
    }
  }

  // add enfants
  addnewEnfant() {
    this.enfants.push(this.formEnfant.value);
    this.formEnfant.reset();
  }
  removeEnfant(enfant) {
    if (window.confirm("Voulez vous vraiment supprimer cet enfant?")) {
      this.enfants.splice(this.enfants.indexOf(enfant), 1);
    }
  }
  showOthersSituation() {
    if (this.situationFamiliale === "Célibataire") {
      this.addConjointAndEnfants = false;
    }
    else {
      this.addConjointAndEnfants = true;
    }
  }
  // ajouter des parcours
  addnewParcours() {
    this.parcours.push(this.formParcours.value);
    this.formParcours.reset();
  }
  // supprimer parcours
  removeParcours(parcour) {
    Swal.fire({
          title: 'VOULEZ-VOUS SUPPRIMER CE PARCOURS?',
          imageUrl: 'assets/images/supp-modal.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          showCancelButton: true,
          width: 800,
          confirmButtonColor: '#059e00',
          cancelButtonColor: '#FF9100',
          cancelButtonText: 'ANNULER',
          confirmButtonText: 'VALIDER'
        }).then((result) => {
          if (!result.dismiss) {
            Swal.fire( {
                imageUrl: 'assets/images/supp-modal.png',
                imageWidth: 100,
                imageHeight: 100,
                imageAlt: 'Custom image',
                width: 800,
                title: 'BIEN SUPPRIMÉ',
                timer: 1500,
                showConfirmButton: false
              }
            );
            this.parcours.splice(this.parcours.indexOf(parcour), 1);

          }
        } );
  }

  // ajouter des parcours
  addnewExperience() {
    if (this.formExperience.value.dated >= this.formExperience.value.datef) {
      this.errorDates = true;
      return false;
    }
    if (this.formExperience.value.datef.toString().length > 10) {
      this.errorDates = true;
      return false;
    }
    this.formExperience.value.idClient = this.formExperience.value.entreprise.id
    this.formExperience.value.entreprise = this.formExperience.value.entreprise.denomination
    this.formExperience.value.etablissement = this.formExperience.value.etablissementExp
    this.formExperience.value.etablissementExp = undefined
    this.experiences.push(this.formExperience.value);
    this.formExperience.reset();
    this.errorDates = false;
    this.etablissementExp = undefined
  }
  // supprimer parcours
  removeExperience(experience) {
    Swal.fire({
          title: 'VOULEZ-VOUS SUPPRIMER CETTE EXPÉRIENCE?',
          imageUrl: 'assets/images/supp-modal.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          showCancelButton: true,
          width: 800,
          confirmButtonColor: '#059e00',
          cancelButtonColor: '#FF9100',
          cancelButtonText: 'ANNULER',
          confirmButtonText: 'VALIDER'
        }).then((result) => {
          if (!result.dismiss) {
            Swal.fire( {
                imageUrl: 'assets/images/supp-modal.png',
                imageWidth: 100,
                imageHeight: 100,
                imageAlt: 'Custom image',
                width: 800,
                title: 'BIEN SUPPRIMÉ',
                timer: 1500,
                showConfirmButton: false
              }
            );
            this.experiences.splice(this.experiences.indexOf(experience), 1);

          }
        } );
  }

  // AJOUET IUN LANGUE
  addLangues(langue) {
    this.errorLangue = ""
    if (this.langues.indexOf(langue) > -1 || this.languesChosed.indexOf(langue) > -1) {
      this.errorLangue = "Cette langue existe déjà dans la liste"
      return false;
    } else {
      this.languesChosed.push(langue);
    }
  }
  resetErrorLangue() {
    this.languesChosed = []
    while (this.languesChosed.length > 0) {
      this.languesChosed.pop();
    }
    this.errorLangue = ""
    this.langue = ""
  }
  saveChoosedLangues() {
    this.langues.push(...this.languesChosed);
  }

  removeLangue(langue) {
    Swal.fire({
          title: 'VOULEZ-VOUS SUPPRIMER CETTE LANGUE?',
          imageUrl: 'assets/images/supp-modal.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          showCancelButton: true,
          width: 800,
          confirmButtonColor: '#059e00',
          cancelButtonColor: '#FF9100',
          cancelButtonText: 'ANNULER',
          confirmButtonText: 'VALIDER'
        }).then((result) => {
          if (!result.dismiss) {
            Swal.fire( {
                imageUrl: 'assets/images/supp-modal.png',
                imageWidth: 100,
                imageHeight: 100,
                imageAlt: 'Custom image',
                width: 800,
                title: 'BIEN SUPPRIMÉ',
                timer: 1500,
                showConfirmButton: false
              }
            );
            this.langues.splice(this.langues.indexOf(langue), 1);

          }
        } );
  }
  addCompetences(competence) {
    this.errorCompetence = ""
  if (this.competences.indexOf(competence) > -1 || this.competencesChosed.indexOf(competence) > -1) {
    this.errorCompetence = "Cette compétence existe déjà dans la liste"
    return false;
  } else {
    this.competencesChosed.push(competence);
  }
}
resetErrorCompetence() {
  this.competencesChosed = []
  while (this.competencesChosed.length > 0) {
    this.competencesChosed.pop();
  }
  this.errorCompetence = ""
  this.competence = ""
}
saveChoosedCompetence() {
  this.competences.push(...this.competencesChosed);
}
  removeCompetence(competence) {
    Swal.fire({
          title: 'VOULEZ-VOUS SUPPRIMER CETTE COMPÉTENCE?',
          imageUrl: 'assets/images/supp-modal.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          showCancelButton: true,
          width: 800,
          confirmButtonColor: '#059e00',
          cancelButtonColor: '#FF9100',
          cancelButtonText: 'ANNULER',
          confirmButtonText: 'VALIDER'
        }).then((result) => {
          if (!result.dismiss) {
            Swal.fire( {
                imageUrl: 'assets/images/supp-modal.png',
                imageWidth: 100,
                imageHeight: 100,
                imageAlt: 'Custom image',
                width: 800,
                title: 'BIEN SUPPRIMÉ',
                timer: 1500,
                showConfirmButton: false
              }
            );
            this.competences.splice(this.competences.indexOf(competence), 1);

          }
        } );
  }
  // add experience via détails du poste
  onRegisterChargeAffaires() {
    var newExperience = {
      entreprise: this.formClient.value.client.denomination,
      poste: this.form.value.poste.titre,
      departement: this.form.value.poste.departement,
      bureau: this.form.value.poste.bureau,
      dated: this.form.value.poste.dated,
      datef: this.form.value.poste.datef,
      etablissement : this.formClient.value.etablissement,
      idClient : this.formClient.value.client.id,
      mail : this.formClient.value.mail,
      tel :  this.form.value.tel,
    }
    this.experiences.push(newExperience);
    var newClient = {
      "denomination": this.formClient.value.client.denomination,
      "acronyme": this.formClient.value.client.acronyme,
      "id": this.formClient.value.client.id,
      "etablissement": this.formClient.value.etablissement
    }

    if (this.situationFamiliale === "Célibataire") {
      this.form.value.situationFamiliale = "Célibataire";
      this.enfants = [];
      this.form.value.conjoint = {
        nom: "",
        prenom: "",
        nationalite: "",
        tel: "",
        mail: "",
        OrganismeDeSante: ""
      }
    }
    else {
      if (this.situationEnfant === "Non") {
        this.enfants = [];
      }
      this.form.value.situationFamiliale = this.situationFamiliale
      this.form.value.conjoint = this.formConjoint.value;
    }
    this.form.value.parcours = this.parcours;
    this.form.value.experiences = this.experiences;
    this.form.value.languages = this.langues;
    this.form.value.competences = this.competences;
    this.form.value.client = newClient
    this.form.value.client.etablissement = this.formClient.value.etablissement
    this.form.value.enfants = this.enfants;
    this.form.value.situationEnfant = this.situationEnfant;
    this.form.value.mail = this.formClient.value.mail
    Swal.fire({
      title: 'VOULEZ-VOUS CONFIRMER?',
      imageUrl: 'assets/images/client-plus.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      showCancelButton: true,
      width: 800,
      confirmButtonColor: '#008000',
      cancelButtonColor: '#FFA500',
      confirmButtonText: 'CONFIRMER',

      cancelButtonText: 'ANNULER',
    }).then((result) => {
      if (!result.dismiss) {

        this.chargeAffaireService.addChargeAffaire(this.form.value)
          .subscribe(resp => { }, err => {
            Swal.fire( {
                imageUrl: 'assets/images/client-plus.png',
                imageWidth: 100,
                imageHeight: 100,
                imageAlt: 'Custom image',
                width: 800,
                title: 'BIEN AJOUTÉ',
                timer: 1500,
                showConfirmButton: false
              }
            );
          })
        this.form.reset();
        this.router.navigateByUrl('/chargeAffaire');
      }
    } );


    //this.router.navigate(['/contact']);
  }
  // trier le client
  triClient(a, b) {
    if (a.denomination.toLowerCase() < b.denomination.toLowerCase()) return -1;
    else if (a.denomination.toLowerCase() == b.denomination.toLowerCase()) return 0;
    else return 1;
  }
  getdateFinPoste(){
    return this.dateFinPoste
  }
  getDateNaissance(): string {
    return this.dateNaissancee
  }
  modifPart1() {
    this.showMyContainer = 1;
  }
  modifPart2(){
    this.showMyContainer = 2;
  }
  modifPart3(){
    if(!this.form.value.nom || (this.form.value.nom && (this.form.value.nom.length <2 || this.form.value.nom.length > 25) )){
      this.errorPartie1 = "Merci de vérifier le champ nom (minimum 2 caractères, maximum 25 caractères)"
      return false;
    }
    if(!this.form.value.prenom || (this.form.value.prenom && (this.form.value.prenom.length <2 || this.form.value.prenom.length > 25) )){
      this.errorPartie1 = "Merci de vérifier le champ prénom (minimum 2 caractères, maximum 25 caractères)"
      return false;
    }
    if( this.form.value.dateNaissance &&  this.form.value.dateNaissance >= this.today){
      this.errorPartie1 = "Merci de vérifier le champ date de naissance"
      return false;
    }
    if(!this.form.value.tel || (this.form.value.tel && this.form.value.tel.length != 12  )){
      this.errorPartie1 = "Merci de vérifier le champ N° fixe"
      return false;
    }
    if(!this.form.value.telMobile || (this.form.value.telMobile && this.form.value.telMobile.length != 12  )){
      this.errorPartie1 = "Merci de vérifier le champ N° mobile"
      return false;
    }
    if(!this.form.value.sexe ){
      this.errorPartie1 = "Merci de vérifier le champ sexe"
      return false;
    }
    if(!this.form.value.typeIdentite ){
      this.errorPartie1 = "Merci de vérifier le champ type d'identité"
      return false;
    }
    if(!this.form.value.numIdentite || (this.form.value.numIdentite && (this.form.value.numIdentite.length <6 || this.form.value.prenom.numIdentite > 12)) ){
      this.errorPartie1 = "Merci de vérifier le champ numéro de pièce d'identité"
      return false;
    }
    if((this.form.value.adresse.codePostal && (!Number(this.form.value.adresse.codePostal) || this.form.value.adresse.codePostal < 0 || this.form.value.adresse.codePostal > 99999 || this.form.value.adresse.codePostal.toString().indexOf('e') > -1)) ){
      this.errorPartie1 = "Merci de vérifier le champ code postal"
      return false;
    }
    if((this.form.value.adresse.n && (!Number(this.form.value.adresse.n) || this.form.value.adresse.n < 0 || this.form.value.adresse.n > 99999 || this.form.value.adresse.n.toString().indexOf('e') > -1 )) ){
      this.errorPartie1 = "Merci de vérifier le champ numéro"
      return false;
    }
    if(this.form.value.poste && this.form.value.poste.dated && this.form.value.poste.datef &&   this.form.value.poste.dated >= this.form.value.poste.datef ){
      this.errorPartie1 = "Merci de vérifier le champ date de début"
      return false;
    }
    this.errorPartie1 = ""
    this.showMyContainer = 3;
  }
  modifPart4(){
    if(this.formConjoint.value.mail &&  !this.validateService.validateEmail(this.formConjoint.value.mail)){
      this.errorPartie2 = "Merci de vérifier le champ Email"
      return false;
    }
    this.errorPartie2 = ""
    this.showMyContainer = 4;
  }
  modifPart5(){
    this.showMyContainer = 5;
  }
  showPart() {
    return this.showMyContainer;
  }
}
