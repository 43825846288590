import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
const headers = new HttpHeaders({Authorization: 'Basic ' + btoa('UserkrendelAdminnew:krl2021!!??**SoC24-415fr-a410-ttx9J8NbPecEL6k4BkAXvGE3t8f3nuEZmXa5RNJBAdrnh6XqB2')})
.set('Content-Type', 'application/json')
  .set('Accept', 'application/json');
headers.append('Access-Control-Allow-Origin', '*');
import 'rxjs/add/operator/map';

@Injectable({
  providedIn: 'root'
})
export class FournisseurService {
  constructor(public http: HttpClient) {
  }
  addFournisseur(fournisseur) {
    return this.http.post(environment.host + '/fournisseur/addOne', JSON.stringify(fournisseur), { headers: headers })
  }
  getAllUsers() {
    return new Promise(resolve => {
      this.http.get(environment.host + '/fournisseur', { headers: headers }).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getFournisseurByID(id){
    return this.http.get(environment.host + '/fournisseur/'+id, { headers: headers })
  }
  editFournisseurById(fournisseur){
    return this.http.put(environment.host + '/fournisseur/' + fournisseur.id, JSON.stringify(fournisseur), { headers: headers })
  }
  deleteFournisseurById(id) {
    return new Promise(resolve => {
      this.http.delete(environment.host + '/fournisseur/' + id, { headers: headers }).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  // verifier existance de siret dans la BD
 verifySiret(client) {
   return this.http.post(environment.host + '/fournisseur/verifiedSiret', JSON.stringify(client), { headers: headers })
  }
  getAllUniqueDenomination(){
    return new Promise(resolve => {
      this.http.get(environment.host + '/fournisseur/unique/denomination', { headers: headers }).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
}