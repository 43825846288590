import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home/home.component';
import { NavbarModule } from '../shared/navbar/navbar.module';
import { AjoutRoleComponent } from './ajout-role/ajout-role.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RoleComponent } from './role/role.component';
import { SearchRolePipe } from 'src/app/search/searchRole.pipe';
import { NgxPaginationModule } from 'ngx-pagination';
import { RoleGuard } from 'src/app/guards/role.guard';
import { ModificationRoleComponent } from './modification-role/modification-role.component';

@NgModule({
  declarations: [HomeComponent, AjoutRoleComponent, RoleComponent,SearchRolePipe, ModificationRoleComponent],
  imports: [
    CommonModule,
    HomeRoutingModule,
    NavbarModule,
    NgxPaginationModule,
    FormsModule, ReactiveFormsModule,
  ],
  providers:[RoleGuard]
})
export class HomeModule { }
