<app-navbar></app-navbar>
<br>
<div id="content-nav-page" class="row" *ngIf="utilisateur">
    <div class=" col-3 titre-menu">
      <b style="margin-right: 10%" class="title-haut">FOURNISSEUR</b>
      <img src="assets/images/client-plus.png" *ngIf="hasRoleAdd" routerLink="/ajoutFournisseur">
    </div>
    <div class="col-9 sous-menu">
      <b class="sous-menu-active1" [routerLink]="['/fournisseur']">FOURNISSEUR</b>
    </div>
  </div>
  <div id="content-page" class="containe-fluid " *ngIf="utilisateur">
    <div class="row  ">
      <div class="col-lg-4 col-md-10 col-sm-10  search">
        <input class="search-bar" type="text" placeholder="Rechercher.." [(ngModel)]="search" name="search">
      </div>
      <div class="col-lg-1 col-md-2 col-sm-2">
        <select class="select-input-nombre" name="numberData" [(ngModel)]="numberData">
          <option>5</option>
          <option selected>10</option>
          <option>25</option>
          <option>50</option>
        </select>
      </div>
      <div class=" col d-flex justify-content-end">
        <div class="col-auto nbr-dossier ">
          <table>
            <tr>
              <td>
                <b>Fournisseurs</b>
              </td>
            </tr>
            <tr>
              <td>
                <b class="sous-nbr-dossier d-flex justify-content-center" *ngIf="fournisseurs">{{fournisseurs.length}}</b>
              </td>
            </tr>
          </table>
        </div>
        <div class="col-auto btn-devis ">
          <a (click)="toFileXLS()" *ngIf="utilisateur && utilisateur.role == 'Super admin'" class="btn-connexion">Exporter<img class="ml-1" src="assets/images/excel.png"></a>
        </div>
      </div>
    </div>
    <!-- tableau -->
    <div class="row" style="overflow-x:auto;">
      <table class="table table-striped table-hover">
        <th class="left" id="firstThTable">LOGO</th>
        <th class="left">IDENTIFIANT UNIQUE</th>
        <th class="left">DATE DE CRÉATION</th>
        <th class="left">DÉNOMINATION</th>
        <th class="left">CODE ACTIVITÉ</th>
        <th class="left">VILLE</th>
        <th class="left">CODE POSTAL</th>
        <th class="left" *ngIf="!administration && utilisateur && utilisateur.role != 'Admin' && utilisateur.role != 'Responsable finance' && utilisateur.mail != 'laurence.amanou@krendel.fr'">RADIÉE</th>
        <th class="text-center " style="margin-left: 100px; text-align: right" *ngIf="!administration && utilisateur && utilisateur.role != 'Admin' && utilisateur.role != 'Responsable finance' && utilisateur.mail != 'laurence.amanou@krendel.fr'">RÉGLAGES</th>
        <tr class="text-center" *ngFor="let fournisseur of fournisseurs| paginate: { itemsPerPage: numberData, currentPage: p }|searchFournisseur  : search.toString().toLowerCase()">
          <td class="left" *ngIf="(fournisseur.statut != 'Radiée' && utilisateur.role != 'Super admin') || (utilisateur.role === 'Super admin')">
            <div >
              <img src="{{fournisseur.linkLogo}}" style="height: 60px;max-width: 80px">
            </div>
          </td>
          <td class="left" [routerLink]="['/details/fournisseur', fournisseur.id]" *ngIf="(fournisseur.statut != 'Radiée' && utilisateur.role != 'Super admin') || (utilisateur.role === 'Super admin')">
            {{fournisseur.siren[0]}}{{fournisseur.siren[1]}}{{fournisseur.siren[2]}}{{fournisseur.siren[3]}}{{fournisseur.siren[4]}}{{fournisseur.siren[5]}}{{fournisseur.siren[6]}}{{fournisseur.siren[7]}}{{fournisseur.siren[8]}}
            {{fournisseur.siren[9]}}{{fournisseur.siren[10]}}{{fournisseur.siren[11]}}{{fournisseur.siren[12]}}{{fournisseur.siren[13]}}</td>
          <td class="left" [routerLink]="['/details/fournisseur', fournisseur.id]" *ngIf="(fournisseur.statut != 'Radiée' && utilisateur.role != 'Super admin') || (utilisateur.role === 'Super admin')"> <span *ngIf="fournisseur.datec.indexOf('à') > -1">{{fournisseur.datec}}</span> <span *ngIf="fournisseur.datec.indexOf('à') < 0">{{fournisseur.datec | date : 'dd/MM/yyyy'}}</span></td>
          <td class="left" [routerLink]="['/details/fournisseur', fournisseur.id]" *ngIf="(fournisseur.statut != 'Radiée' && utilisateur.role != 'Super admin') || (utilisateur.role === 'Super admin')">{{fournisseur.denomination}}</td>
          <td class="left" [routerLink]="['/details/fournisseur', fournisseur.id]" *ngIf="(fournisseur.statut != 'Radiée' && utilisateur.role != 'Super admin') || (utilisateur.role === 'Super admin')">{{fournisseur.indicatif}}</td>
          <td class="left" [routerLink]="['/details/fournisseur', fournisseur.id]" *ngIf="(fournisseur.statut != 'Radiée' && utilisateur.role != 'Super admin') || (utilisateur.role === 'Super admin')">{{fournisseur.adresse.ville}}</td>
          <td class="left" [routerLink]="['/details/fournisseur', fournisseur.id]" *ngIf="(fournisseur.statut != 'Radiée' && utilisateur.role != 'Super admin') || (utilisateur.role === 'Super admin')">{{fournisseur.adresse.codePostal}}</td>
          <td class="text-center" [routerLink]="['/details/fournisseur', fournisseur.id]" *ngIf="!administration && utilisateur && utilisateur.role != 'Admin' && utilisateur.role != 'Responsable finance' && utilisateur.mail != 'laurence.amanou@krendel.fr'">
            <i class="" style="color:red;font-size: 20px;" *ngIf="fournisseur.statut === 'Radiée'" title="Radiée">★</i>
          </td>
          <td *ngIf="!administration && utilisateur && utilisateur.role != 'Admin' && utilisateur.role != 'Responsable finance' && utilisateur.mail != 'laurence.amanou@krendel.fr'">
            <div class="dropdown">
              <button class="btn dropdown-toggle btn-options" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                OPTIONS
              </button>
              <div class="dropdown-menu reglageMenu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item option" [routerLink]="['/details/fournisseur', fournisseur.id]">
                  <div class="options-show-icon"></div>
                  <div class="option-text"> Afficher</div>
                </a>
  
                <a class="dropdown-item option" title="Modifier" [routerLink]="['/fournisseur/details', fournisseur.id]" title="Modifier" *ngIf="hasRoleUpdate" [ngStyle]="{'pointer-events': administration ? 'none' : null}">
                  <div class="options-modif-icon"></div>
                  <div class="option-text"> Modifier</div>
                </a>
                <a class="dropdown-item" title="Supprimer" (click)="removeFournisseur(fournisseur)" title="Supprimer" *ngIf="hasRoleDelete" [ngStyle]="{'pointer-events': administration ? 'none' : null}">
                  <div class="options-supp-icon"></div>
                  <div class="option-text"> Supprimer</div>
                </a>
              </div>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div class="row">
      <div class="col col-xs-6">
        <pagination-controls previousLabel="Précédent" nextLabel="Suivant" (pageChange)="p = $event" #api></pagination-controls>
      </div>
    </div>
  </div>
  