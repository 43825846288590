import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { FournisseurService } from "../../../services/fournisseur.service";
import { codesNav } from '../../../pays/codesNav';
import { triCodes } from '../../../pays/codesNav';
import { pays } from '../../../pays/pays';
import { tri } from '../../../pays/pays';
import { ValidateService } from '../../../services/validate/validate.service';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { ValidationService } from '../../../services/validate/validate-error.service';
import { environment } from '../../../../environments/environment';
import { UploadFileService } from "../../../services/upload-file-service.service";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
import * as moment from 'moment';
import { codepostale } from '../../../pays/villes';

@Component({
  selector: 'app-update-fournisseur',
  templateUrl: './update-fournisseur.component.html',
  styleUrls: ['../../utilisateur/update-utilisateur/update-utilisateur.component.scss']
})
export class UpdateFournisseurComponent implements OnInit {

  fournisseur: any;
  codes: any[] = [];
  pys: any[] = [];
  vlls: any[] = [];
  nom: any;
  type = "Etablissement principale";
  mail: any;
  tel: any;
  p: any;
  codePostal: any;
  rue: any;
  n: any;
  ville: any;
  secteur:any;
  addEtablissments:any;
  errorInformations = false;
  bigDate = "9999-12-31"
  errorInformationsComp = false;
  formAddEtablissement:any;
  errorNom = false;
  errorType =false;
  errorMail = false;
  roleAdministration = true;
  showAnnulationBouton =  false;
  role: any;
  showAndHoideDateRadiation = false
  showAndHoideDateRadiationEtab= false;
  myfiles:any;
  sizeLimitOne = 10000000; //10MB
  loading = false
  showMyContainer = 0;
  etablissement:any;
  formAddEtablissementUpdate:any;
  dateNow = moment().subtract(1,'d').format('YYYY-MM-DD');
  errorSaveFirstPartie = ""
  errorSaveSecondePartie = ""
  errorSaveFourthPartie = ""
  constructor(private uploadFileService:UploadFileService,private formBuilder:FormBuilder,private http: Http, private fournisseurService: FournisseurService, private activatedRoute: ActivatedRoute,private validateService:ValidateService,
    private router: Router) {
      this.formAddEtablissement = this.formBuilder.group({
        nom: ['', [Validators.required, Validators.minLength(4)]],
        type: ['Etablissement principale', Validators.required],
        mail:['', [Validators.required, ValidationService.emailValidator]],
        tel: ['+216'],
        statutEtab:['Active'],
        dateRadiationEtab:[''],
        adresse: this.formBuilder.group({
          pays: ['Tunisie',],
          codePostal: ['', ValidationService.numberValidator],
          rue: ['',],
          n: ['', ValidationService.numberValidator],
          ville: ['',],
        }),
      })

      this.formAddEtablissementUpdate = this.formBuilder.group({
        nom: ['', [Validators.required, Validators.minLength(4)]],
        type: ['Etablissement principale', Validators.required],
        mail:['', [Validators.required, ValidationService.emailValidator]],
        tel: ['+216'],
        statutEtab:['Active'],
        dateRadiationEtab:[''],
        adresse: this.formBuilder.group({
          pays: ['Tunisie',],
          codePostal: ['', ValidationService.numberValidator],
          rue: ['',],
          n: ['', ValidationService.numberValidator],
          ville: ['',],
        }),
      })
  }
  ngOnInit() {
    this.codes = codesNav.sort(triCodes);
    this.pys = pays.sort(tri);
    this.pys = this.pys.filter(x => x.name === "Tunisie")
    this.vlls = codepostale
    this.role = localStorage.getItem('roleUser')
    if (this.role) {
      this.roleAdministration = false;
    }
    let params: any = this.activatedRoute.snapshot.params;
    this.fournisseurService.getFournisseurByID(params.id).subscribe(fournisseur => {
      this.fournisseur = fournisseur;
      if(this.fournisseur){
        if(this.fournisseur.statut === 'Radiée'){
          this.showAndHoideDateRadiation = true;
        }
        this.secteur = this.fournisseur.activite
      if (this.fournisseur.multiEtablissement === "Non") {
        this.addEtablissments = false;
      }
      if (this.fournisseur.multiEtablissement === "Oui") {
        this.addEtablissments = true;
      }
      else {
        this.addEtablissments = false;
      }
    }
    },
      err => {
        return false;
      });
  }
  showSociete() {
    this.secteur = this.fournisseur.codeNaf.fields['intitule_naf'];
  }
  getBigDate(): string {
      return this.bigDate
    }
  showEtablissment() {
    if (this.fournisseur.multiEtablissement === "Non") {
      this.addEtablissments = false;
    }
    if (this.fournisseur.multiEtablissement === "Oui") {
      this.addEtablissments = true;
    }
    else {
      this.addEtablissments = false;
    }
  }

  getdatenow(): string {
    return this.dateNow
  }
  // ajuter des etablissments
  addnewEtablissment() {
      this.fournisseur.etablissements.push(this.formAddEtablissement.value);
      this.formAddEtablissement.reset();
  }
  // supprimer un établissement
  removeEtablissement(etablissement) {
    Swal.fire({
          title: 'VOULEZ-VOUS SUPPRIMER CET ÉTABLISSEMENT?',
          imageUrl: 'assets/images/supp-modal.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          showCancelButton: true,
          width: 800,
          confirmButtonColor: '#059e00',
          cancelButtonColor: '#FF9100',
          cancelButtonText: 'ANNULER',
          confirmButtonText: 'VALIDER'
        }).then((result) => {
          if (!result.dismiss) {
            Swal.fire( {
                imageUrl: 'assets/images/supp-modal.png',
                imageWidth: 100,
                imageHeight: 100,
                imageAlt: 'Custom image',
                width: 800,
                title: 'ÉTABLISSEMNT SUPPRIMÉ',
                timer: 1500,
                showConfirmButton: false
              }
            );
            this.fournisseur.etablissements.splice(this.fournisseur.etablissements.indexOf(etablissement), 1);
          }
        } );

  }
  // update un établissement
  editEtablissment(etablissement) {
    if (etablissement.nom == undefined || etablissement.nom.length < 3) {
      this.errorNom = true;
      this.errorType =false;
      this.errorMail = false;
      return false;
    }
    if (etablissement.type == undefined) {
      this.errorNom = false;
      this.errorType =true;
      this.errorMail = false;
      return false;
    }
    if (etablissement.mail == undefined || !this.validateService.validateEmail(etablissement.mail)) {
      this.errorNom = false;
      this.errorType =false;
      this.errorMail = true;
      return false;
    }
    else {
      this.errorNom = false;
      this.errorType =false;
      this.errorMail = false;
      return true;
    }
  }
// update fournisseur
onUpdateFournisseur(fournisseur){
  if(!this.fournisseur.etablissements || this.fournisseur.etablissements.length < 1){
    this.fournisseur.multiEtablissement = "Non"
  }
  if(this.showMyContainer === 1){
    if((fournisseur.type === "Particulier" && fournisseur.siren && fournisseur.siren.toString().length != 14) || (fournisseur.type === "Professionnel"  && fournisseur.siren.toString().length != 14) ){
      this.errorSaveFirstPartie = "Merci de vérifier le champ Identifiant unique"
      return false;
    }
    if(!fournisseur.denomination || fournisseur.denomination.length < 4 || fournisseur.denomination.length > 100){
      this.errorSaveFirstPartie = "Merci de vérifier le champ dénomination (minimum 4 caractères, maximum 100 caractères)"
      return false;
    }
    if(fournisseur.acronyme && (fournisseur.acronyme.length < 4 || fournisseur.acronyme.length > 100)){
      this.errorSaveFirstPartie = "Merci de vérifier le champ acronyme (minimum 4 caractères, maximum 100 caractères)"
      return false;
    }
    if(!fournisseur.formeJuridique ){
      this.errorSaveFirstPartie = "Merci de vérifier le champ forme juridique"
      return false;
    }
    if(!fournisseur.codeNaf ){
      this.errorSaveFirstPartie = "Merci de vérifier le champ code activité"
      return false;
    }
    if(fournisseur.datec &&  fournisseur.datec > this.dateNow){
      this.errorSaveFirstPartie = "Merci de vérifier le champ immatriculé le"
      return false;
    }
    this.errorSaveFirstPartie = ""
  }
  if(this.showMyContainer === 2){
    if(!fournisseur.mail || (fournisseur.mail && (fournisseur.mail.length <2 || fournisseur.mail.length > 100 || !this.validateService.validateEmail(fournisseur.mail)) )){
      this.errorSaveSecondePartie = "Merci de vérifier le champ Email"
      return false;
    }
    if(!fournisseur.tel || (fournisseur.tel && fournisseur.tel.length != 12  )){
      this.errorSaveSecondePartie = "Merci de vérifier le champ N° fixe"
      return false;
    }
    
    if((fournisseur.adresse.codePostal && (!Number(fournisseur.adresse.codePostal) || fournisseur.adresse.codePostal < 0 || fournisseur.adresse.codePostal > 99999 || fournisseur.adresse.codePostal.toString().indexOf('e') > -1)) ){
      this.errorSaveSecondePartie = "Merci de vérifier le champ code postal"
      return false;
    }
    if((fournisseur.adresse.n && (!Number(fournisseur.adresse.n) || fournisseur.adresse.n < 0 || fournisseur.adresse.n > 99999 || fournisseur.adresse.n.toString().indexOf('e') > -1 )) ){
      this.errorSaveSecondePartie = "Merci de vérifier le champ numéro"
      return false;
    }
    this.errorSaveSecondePartie = ""
  }
  if(this.showMyContainer === 4){
    if(fournisseur.informationbancaire && fournisseur.informationbancaire.nom && fournisseur.informationbancaire.nom.length > 100){
      this.errorSaveFourthPartie = "Merci de vérifier le champ nom (maximum 100 caractères)"
      return false;
    }
    if(fournisseur.informationbancaire && fournisseur.informationbancaire.iban && fournisseur.informationbancaire.iban.length > 100){
      this.errorSaveFourthPartie = "Merci de vérifier le champ iban (maximum 100 caractères)"
      return false;
    }
    if(fournisseur.informationbancaire && fournisseur.informationbancaire.swift && fournisseur.informationbancaire.swift.length > 100){
      this.errorSaveFourthPartie = "Merci de vérifier le champ swift (maximum 100 caractères)"
      return false;
    }
    if(fournisseur.informationbancaire && fournisseur.informationbancaire.rib && fournisseur.informationbancaire.rib.length > 100){
      this.errorSaveFourthPartie = "Merci de vérifier le champ rib (maximum 100 caractères)"
      return false;
    }
    if(fournisseur.informationbancaire && fournisseur.informationbancaire.bic && fournisseur.informationbancaire.bic.length > 100){
      this.errorSaveFourthPartie = "Merci de vérifier le champ bic (maximum 100 caractères)"
      return false;
    }
    this.errorSaveFourthPartie = ""
  }
  Swal.fire({
      title: 'VOULEZ-VOUS ENREGISTRER VOS MODIFICATIONS?',
      imageUrl: 'assets/images/modifier.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      showCancelButton: true,
      width: 800,
      confirmButtonColor: '#059e00',
      cancelButtonColor: '#FF9100',
      cancelButtonText: 'ANNULER',
      confirmButtonText: 'ENREGISTRER'
    }).then((result) => {
      if (!result.dismiss) {
        if(fournisseur.siren == undefined|| fournisseur.siren.length < 4 ||fournisseur.denomination == undefined || fournisseur.denomination.length < 4){
          this.errorInformations = true;
          this.errorInformationsComp = false;
          return false;
        }
        if(fournisseur.mail == undefined || !this.validateService.validateEmail(fournisseur.mail) || fournisseur.tel == undefined){
          this.errorInformationsComp = true;
          this.errorInformations = false;
          return false;
        }
        else{
          if(fournisseur.codeNaf){
            fournisseur.indicatif = fournisseur.codeNaf.fields.code_naf
            fournisseur.activite = this.secteur
          }
          if (this.myfiles && this.myfiles.length > 0) {
            this.loading = true;
            for (var x = 0; x < this.myfiles.length; x++) {
              var fileExtensionn = this.myfiles[x].name.substr((this.myfiles[x].name.lastIndexOf('.') + 1));
              //var photo = this.utilisateur.id + i + '.' + fileExtension
              var randomVariable = "logo_"+fournisseur.denomination.replace(/\s+/g, '-');
              fournisseur.nameLogo = this.myfiles[x].name;
              fournisseur.linkLogo = environment.filesPath + randomVariable + "." + fileExtensionn
              this.uploadFileService.pushFilesToStorage(this.myfiles[x], randomVariable).subscribe(event => {
              }, err => {
              })
            }
         }
          this.fournisseurService.editFournisseurById(fournisseur)
                .subscribe(resp => {
                }, err => {

                })
                Swal.fire( {
                    imageUrl: 'assets/images/statut-valide-hover.png',
                    imageWidth: 100,
                    imageHeight: 100,
                    imageAlt: 'Custom image',
                    width: 800,
                    title: 'FOURNISSEUR MODIFIÉ',
                    timer: 1500,
                    showConfirmButton: false
                  }
                );
                this.router.navigateByUrl('/fournisseur');

        }

      }
    } );


}
showAndHoideDateRadiationFunc(value){
  this.showAndHoideDateRadiation = false;
  if(value === 'Radiée'){
    this.showAndHoideDateRadiation = true;
  }else{
    this.showAndHoideDateRadiation = false;
  }
}
showAndHoideDateRadiationFuncEtab(){
  this.showAndHoideDateRadiationEtab = false;
  if(this.formAddEtablissement.value.statutEtab === 'Radiée'){
    this.showAndHoideDateRadiationEtab = true;
  }else{
    this.showAndHoideDateRadiationEtab = false;
  }
}
// controler le logo
selectFiles(event) {
  this.myfiles = event.target.files
  if (this.myfiles && this.myfiles.length > 0) {
    if(this.myfiles[0].size > this.sizeLimitOne) {
      this.myfiles = undefined;
      Swal.fire({
        imageUrl: 'assets/images/error.png',
        imageWidth: 100,
        imageHeight: 100,
        imageAlt: 'Custom image',
        width: 600,
        title: 'Un fichier sélectionné est trop volumineux.La taille maximale est 10 Mo.',
        /*timer: 1500,*/
        showConfirmButton: true,
        confirmButtonColor: '#8B0000',
        confirmButtonWidth: 100,

      }
      );
      return false;
    }
    if(this.myfiles[0].type != "image/png" && this.myfiles[0].type != "image/jpeg" && this.myfiles[0].type != "image/jpg"){
      this.myfiles = undefined;
      Swal.fire({
        imageUrl: 'assets/images/error.png',
        imageWidth: 100,
        imageHeight: 100,
        imageAlt: 'Custom image',
        width: 600,
        title: 'Impossible de télécharger ce fichier.',
        /*timer: 1500,*/
        showConfirmButton: true,
        confirmButtonColor: '#8B0000',
        confirmButtonWidth: 100,

      }
      );
      return false;
    }
  } else {
    return false;
  }
}
deleteLogo(fournisseur){
   Swal.fire({
     title: 'VOULEZ-VOUS SUPPRIMER CE LOGO?',
     imageUrl: 'assets/images/supp-modal.png',
     imageWidth: 100,
     imageHeight: 100,
     imageAlt: 'Custom image',
     showCancelButton: true,
     width: 800,
     confirmButtonColor: '#059e00',
     cancelButtonColor: '#FF9100',
     cancelButtonText: 'ANNULER',
     confirmButtonText: 'VALIDER'
   }).then((result) => {
     if (!result.dismiss) {
       Swal.fire({
         imageUrl: 'assets/images/supp-modal.png',
         imageWidth: 100,
         imageHeight: 100,
         imageAlt: 'Custom image',
         width: 800,
         title: 'LOGO SUPPRIMÉ',
         timer: 1500,
         showConfirmButton: false
       }
       );
       fournisseur.linkLogo = null
     }
   });
}
modifPart1() {
  if (this.showMyContainer !== 1) {
    this.showMyContainer = 1;
  }
  else {
    this.showMyContainer = 0;
  }
}
modifPart2() {
  if (this.showMyContainer !== 2) {
    this.showMyContainer = 2;
  }
  else {
    this.showMyContainer = 0;
  }
}
modifPart3() {
  if (this.showMyContainer !== 3) {
    this.showMyContainer = 3;
  }
  else {
    this.showMyContainer = 0;
  }
}
modifPart4() {
  if (this.showMyContainer !== 4) {
    this.showMyContainer = 4;
  }
  else {
    this.showMyContainer = 0;
  }
}
showPart() {
  return this.showMyContainer;
}
seteblissement(etablissement){
  this.etablissement = etablissement
}
}
