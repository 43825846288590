import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthentificationModule } from './authentification/authentification.module';
import { HomeModule } from './dashboard/home/home.module';
import { UtilisateurModule } from './dashboard/utilisateur/utilisateur.module';
import { HttpModule } from '@angular/http';
import { NavbarModule } from './dashboard/shared/navbar/navbar.module';
import { ControleMessagesComponent } from './dashboard/shared/controle-messages/controle-messages.component';
import { ControleMessagesModule } from './dashboard/shared/controle-messages/controle-messages.module';
import { CLIENTModule } from './dashboard/client/client.module';
import { ChargeAffaireModule } from './dashboard/charge-affaire/charge-affaire.module';
import { FournisseurModule } from './dashboard/fournisseur/fournisseur.module';
import { DecimalFormattModule } from './dashboard/shared/decimal-formatt/decimal-formatt.module';
import { DossierModule } from './dashboard/dossier/dossier.module';
import { CommandeModule } from './dashboard/commande/commande.module';
import { CatalogueModule } from './dashboard/catalogue/catalogue.module';
import { InventaireModule } from './dashboard/inventaire/inventaire.module';
import { StockModule } from './dashboard/stock/stock.module';
import { RhModule } from './dashboard/rh/rh.module';
import { AdminModule } from './admin/admin.module';
import { AgenceVoyageModule } from './dashboard/agence-voyage/agence-voyage.module';
@NgModule({
  declarations: [
    AppComponent,
    ControleMessagesComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    AuthentificationModule,
    HomeModule,
    HttpModule,
    UtilisateurModule,
    NavbarModule,
    ControleMessagesModule,
    CLIENTModule,
    ChargeAffaireModule,
    FournisseurModule,
    DecimalFormattModule,
    DossierModule,
    CommandeModule,
    CatalogueModule,
    InventaireModule,
    StockModule,
    RhModule,
    AdminModule,
    AgenceVoyageModule
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
