import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
 name: 'searching'
})
export class SearchPipe implements PipeTransform {
  transform(value, args: string[]){
   if (!value) return value;
   if (value.filter((item) => item.nom.toLowerCase().includes(args)) != '') {
     return value.filter((item) => item.nom.toLowerCase().includes(args));}
     if (value.filter((item) => item.prenom.toLowerCase().includes(args)) != '') {
       return value.filter((item) => item.prenom.toLowerCase().includes(args));}
     if (value.filter((item) => item.mail.toLowerCase().includes(args)) != '') {
     return value.filter((item) => item.mail.toLowerCase().includes(args));}
     if (value.filter((item) => item.sexe.toLowerCase().includes(args)) != '') {
     return value.filter((item) => item.sexe.toLowerCase().includes(args));}
     if (value.filter((item) => item.tel.toLowerCase().includes(args)) != '') {
     return value.filter((item) => item.tel.toLowerCase().includes(args));}
     if (value.filter((item) => item.nationalite.toLowerCase().includes(args)) != '') {
     return value.filter((item) => item.nationalite.toLowerCase().includes(args));}
     if (value.filter((item) => item.adresse.ville.toLowerCase().includes(args)) != '') {
     return value.filter((item) => item.adresse.ville.toLowerCase().includes(args));}
     if (value.filter((item) => item.adresse.rue.toLowerCase().includes(args)) != '') {
     return value.filter((item) => item.adresse.rue.toLowerCase().includes(args));}
     if (value.filter((item) => item.adresse.n.toLowerCase().includes(args)) != '') {
     return value.filter((item) => item.adresse.n.toLowerCase().includes(args));}
     if (value.filter((item) => item.adresse.codePostal.toLowerCase().includes(args)) != '') {
     return value.filter((item) => item.adresse.codePostal.toLowerCase().includes(args));}
     if (value.filter((item) => item.role.toLowerCase().includes(args)) != '') {
     return value.filter((item) => item.role.toLowerCase().includes(args));}
 }}
