import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
const headers = new HttpHeaders({Authorization: 'Basic ' + btoa('UserkrendelAdminnew:krl2021!!??**SoC24-415fr-a410-ttx9J8NbPecEL6k4BkAXvGE3t8f3nuEZmXa5RNJBAdrnh6XqB2')})
.set('Content-Type', 'application/json')
.set('Accept', 'application/json');
headers.append('Access-Control-Allow-Origin', '*');

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) {
  }
  //get all users
  getAllUsers() {
    return new Promise(resolve => {
      this.http.get(environment.host + '/users',{
      headers: headers
    }).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getUserByID(id){
    return this.http.get(environment.host + '/users/'+id,{headers: headers})
  }
  editUserById(user){
    return this.http.put(environment.host + '/users/' + user.id, JSON.stringify(user), { headers: headers })
  }
  // bloquer / debloquqer utilisateur
  editUtilisateur(utilisateur) {

     return this.http.put(environment.host + '/users/bloquer/utitilisateur/' + utilisateur.id, JSON.stringify(utilisateur), { headers: headers })
   }
   // update les informations utilisateurs

   editInformationsUtilisateur(utilisateur) {
      return this.http.put(environment.host + '/users/edit/utitilisateur/' + utilisateur.id, JSON.stringify(utilisateur), { headers: headers })
    }
   // ajouter un utilisateur
  registerUtilisateur(newAdmin){
      return this.http.post(environment.host + '/users/newUtilisateur', JSON.stringify(newAdmin), {headers: headers})
  }
  // verifier existance d'un email dans la BD
 verifyEmail(utilisateur) {
   return this.http.post(environment.host + '/users/verifiedEmail', JSON.stringify(utilisateur), { headers: headers })
 }
 // verify token still active or no ( authentification for the first time)
  verifytoken(utilisateur, token) {
    return this.http.put(environment.host + '/users/authentificate/' + token, JSON.stringify(utilisateur), { headers: headers })
  }
  // send mail
  sendMail(utilisateur){

    return this.http.post(environment.host + '/users/sendMailPasswordRecovery', JSON.stringify(utilisateur), { headers: headers })
  }
  deleteUserById(id) {
    return new Promise(resolve => {
      this.http.delete(environment.host + '/users/delete/' + id, { headers: headers }).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
}
