<app-navbar></app-navbar>
<br>
<div id="content-nav-page" class="row">
    <div class=" col-3 titre-menu-ajoutDossier">
      <a [routerLink]="['/dossier']" class="icon-f-left"></a>
      <b>DOSSIER</b><a>
        <img class="mb-3" src="assets/images/dossier.png" alt="">
      </a>
    </div>
    <div class="col-9 sous-menu">
      <b class="sous-menu-active1"  [routerLink]="['/dossier']">DOSSIER</b>
      <b class="sous-menu-active2 ml-4" [routerLink]="['/commande']" *ngIf="utilisateur && utilisateur.role === 'Super admin' || utilisateur && utilisateur.role === 'Administration' || utilisateur && utilisateur.role === 'Admin' || utilisateur && utilisateur.role === 'Responsable commandes' || (utilisateur && utilisateur.role === 'Equipe Atelier' && utilisateur.sousRole === 'Responsable magasin') || utilisateur && utilisateur.role === 'Utilisateur-client'"
        style="cursor: pointer">COMMANDE</b>
  
    </div>
  </div>
  <div class="col d-flex justify-content-start">
    <b class="obligatoir-text">(*) Ce champ est obligatoire</b>
  </div>
  <ng-template #customLoadingTemplate></ng-template>
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading>
  <form [formGroup]="form" class="" role="form">
    <!-- Content-show-dossier -->
    <div id="content-show-dossier">
      <div class="row content-cols-show">
        <div class="col-lg-4 col-md-12">
          <select class="select-statut" formControlName="client" name="client" (change)="selectClient()">
            <option value="" disabled selected>CLIENT*</option>
            <option *ngFor="let client of clients" [ngValue]="client">{{client.denomination}}</option>
          </select>
          <a [routerLink]="['/ajoutClient']" title="Ajouter un nouveau client">
            <div class="icon-client-plus"></div>
          </a>
          <control-messages [control]="form.controls.client"></control-messages>
          <p style="color:red;" *ngIf="myClient && myClient.statut === 'Radiée'">Ce client est radié</p>
        </div>
        <div class="col-lg-4 col-md-12">
          <select class="select-statut" formControlName="etablissement" [(ngModel)]="etablissement" name="etablissement" (change)="selectEtablissement()">
            <option value="Établissement*" disabled selected>ÉTABLISSEMENT*</option>
            <option *ngFor="let etablissement of etablissements" [ngValue]="etablissement">{{etablissement.nom}}</option>
          </select>
          <control-messages [control]="form.controls.etablissement"></control-messages>
          <div class="form-group col-md-12 col-sm-12 etablissement-info-affichage" *ngIf="selectedEtablissement && showEt">
            <label for="prenom" class="titre-info-etablissement bold">Type et l'adresse d'établissement</label>
            <li style="list-style-type: none;"><span class="bold">Type</span> : {{selectedEtablissement.type}}</li>
            <li style="list-style-type: none;"><span class="bold">Pays</span> : {{selectedEtablissement.adresse.pays}}</li>
            <li style="list-style-type: none;"><span class="bold">Ville</span> : {{selectedEtablissement.adresse.ville}}</li>
            <li style="list-style-type: none;"><span class="bold">Rue</span> : {{selectedEtablissement.adresse.n}} {{selectedEtablissement.adresse.rue}} {{selectedEtablissement.adresse.codePostal}}</li>
            <li style="list-style-type: none;"><span class="bold">Statut</span> : {{selectedEtablissement.statutEtab}} <span *ngIf="selectedEtablissement.statutEtab === '' || !selectedEtablissement.statutEtab">Active</span></li>
            <br>
          </div>
        </div>
        <div class="col-lg-4 col-md-12">
          <select class="select-statut " formControlName="chargeAffaire" name="chargeAffaire" (change)="showChargeAffaireDetails()">
            <option value="" disabled selected>CHARGÉ D'AFFAIRES*</option>
            <option *ngFor="let chgeAff of AllselectedchargeAffaires" [ngValue]="chgeAff">{{chgeAff.nom}} {{chgeAff.prenom}}</option>
          </select>
          <control-messages [control]="form.controls.chargeAffaire"></control-messages>
          <a [routerLink]="['/ajoutChargeAffaire']" title="Ajouter un nouveau chargé d'affaires">
            <div class="icon-chargé-plus"></div>
          </a>
          <div class="form-group col-md-12 col-sm-12 etablissement-info-affichage" *ngIf="selectedChargeAffaires">
            <label for="prenom" class="titre-info-etablissement bold">Détails du chargé d'affaires</label>
            <li style="list-style-type: none;"><span class="bold">Nom & Prénom</span> : {{selectedEtablissement.type}}</li>
            <li style="list-style-type: none;"><span class="bold">Email</span> : {{selectedEtablissement.adresse.pays}}</li>
            <li style="list-style-type: none;"><span class="bold">Téléphone</span> : {{selectedEtablissement.adresse.ville}}</li>
            <li style="list-style-type: none;" *ngIf="selectedChargeAffaires.client"><span class="bold">Client</span> : {{selectedChargeAffaires.client.denomination}} - {{selectedChargeAffaires.client.acronyme }}</li>
            <br>
          </div>
        </div>
      </div>
      <div class="row content-cols-show">
        <div class="col-lg-4 col-md-12">
          <input class="input-show" type="text" formControlName="numero" name="numero" placeholder="NUMÉRO DU DOSSIER*">
          <control-messages [control]="form.controls.numero"></control-messages>
          <p style="color:red;" *ngIf="errorNumber">Ce numéro existe déjà !</p>
          <p *ngIf="form.controls['numero'].touched && form.controls['numero'].hasError('maxlength')" style="color:red">
                25 caractères maximum
              </p>
        </div>
        <div class="col-lg-4 col-md-12">
          <select class="select-statut " formControlName="type" name="type">
            <option value="" disabled selected>TYPE*</option>
            <option>Etude</option>
            <option>Réalisation</option>
            <option>Intervention</option>
            <option>Fournitures</option>
          </select>
        </div>
        <div class="col-lg-4 col-md-12">
          <input class="input-show" type="date" formControlName="dateReception" [(ngModel)]="dateReceptionDossier" name="dateReception" [max]="getBigDate()" placeholder="DATE DE RÉCEPTION DOSSIER*">
          <control-messages [control]="form.controls.dateReception"></control-messages>
        </div>
      </div>
      <div class="row content-cols-show">
        <div class="col-lg-4 col-md-12">
          <input class="input-show" type="text" formControlName="nom" name="nom" placeholder="NOM DU DOSSIER*">
          <control-messages [control]="form.controls.nom"></control-messages>
          <p *ngIf="form.controls['nom'].touched && form.controls['nom'].hasError('maxlength')" style="color:red">
                100 caractères maximum
           </p>
        </div>
        <div class="col-lg-4 col-md-12">
          <input class="input-show" type="date" formControlName="datel" [min]="getDateReceptionDossier()" name="datel" placeholder="DATE LIMITE*" [max]="getBigDate()">
          <control-messages [control]="form.controls.datel"></control-messages>
          <p *ngIf="errorDate" style="color:red">La date limite ne doit pas être antérieur à la date de réception de dossier</p>
        </div>
      </div>
      <div class="row content-cols-show">
        <div class="col-lg-6 col-md-12 col-sm-12">
          <div id="drop_file_zone">
            <div id="drag_upload_file">
              <b>FICHIERS</b>
              <p></p>
              <div class="btn-file">
                <button class="btn-select-file">Choisir...</button>
                <input type="file" name="upfile" (change)="selectFile($event)"/>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12">
          <div id="drop_file_zone">
            <div id="drag_upload_file">
              <b>MAIL</b>
              <p></p>
              <div class="btn-file">
                <button class="btn-select-file">Choisir...</button>
                <input type="file" name="upfile" (change)="selectFileEmail($event)"/>
              </div>
            </div>
          </div>
        </div>
  
      </div>
      <div class="row content-cols-show">
        <div class="col-lg-6 col-md-12 col-sm-12">
          <div id="drop_file_zone">
            <div id="drag_upload_file">
              <b>CCTP</b>
              <p></p>
              <div class="btn-file">
                <button class="btn-select-file">Choisir...</button>
                <input type="file" name="upfile" (change)="selectFileCCTP($event)"/>
              </div>
            </div>
  
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12">
          <div id="drop_file_zone">
            <div id="drag_upload_file">
              <b>PLAN</b>
              <p></p>
              <div class="btn-file">
                <button class="btn-select-file">Choisir...</button>
                <input type="file" name="upfile" (change)="selectFilePlan($event)"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class=" col d-flex justify-content-center" style="padding-bottom: 1%">
        <div class="col-auto nbr-dossier ">
          <table>
            <tr>
              <td style="padding-right: 30px;">
                <button class="btn-annule" style="width: 200px;height: 50px;" [routerLink]="['/dossier']">Annuler <img src="assets/images/btn-annule.png"> </button>
  
              </td>
              <td style="padding-right: 30px;">
                <button class="btn-valider" style="width: 200px;height: 50px;" [disabled]="!form.valid" (click)="onRegisterDossier()">Ajouter <img src="assets/images/btn-valider.png"></button>
              </td>
            </tr>
          </table>
        </div>
  
      </div>
    </div>
  </form>
  