import { Component, OnInit } from '@angular/core';
import * as XLSX from "xlsx";
import { InventaireService } from "../../../services/inventaire.service";
import { StockService } from "../../../services/stock.service";
import { UserService } from "../../../services/user.service";
import { Router, ActivatedRoute } from '@angular/router';
import { CommandeService } from "../../../services/commande.service";
import { CatalogueService } from "../.././../services/catalogue.service";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'app-inventaire-by-fournisseur',
  templateUrl: './inventaire-by-fournisseur.component.html',
  styleUrls: ['../../utilisateur/utilisateur/utilisateur.component.css'],
})
export class InventaireByFournisseurComponent implements OnInit {
  inventaires: any[] = [];
  allDt: any;
  inventaireConfigObj = {
    "dateInventaireChosie": null
  };
  showStock = false
  loading = false;
  errorDate = false;
  selectedDate = new Date().toISOString().split('T')[0]
  initialStock: any[] = []
  initialdata: any;
  id: any;
  utilisateur: any;
  savedAcitvation: any;
  showDate = false
  errorStcpok = false;
  showInvenytaireAdd = false;
  alldataInventaires: any;
  commandes: any[] = [];;
  inventiareactivationdata: any;
  allDataCtalogue: any;
  dataToEdit: any[] = []
  otherdata:any;
  lengthCommandes:any;
  savedDateInventaire:any;
  errorNotExistInvetiare = false;
  bigDate = "9999-12-31"
  p = 1
  role:any;
  hasRolestock = false;
  hasRoleAdd = false;
  constructor(private roleService:RoleService,private router:Router,private inventaireService: InventaireService, private activatedRoute: ActivatedRoute, private stockService: StockService, private userService: UserService, private commandeService: CommandeService, private catalogueService: CatalogueService) {

  }
  ngOnInit() {
    this.id = localStorage.getItem('idUser')
    this.userService.getUserByID(this.id).subscribe(utilisateur => {
      this.utilisateur = utilisateur;
      if(!this.utilisateur){
        localStorage.removeItem('idUser');
        localStorage.clear();
        this.router.navigate(['/login']);
      }
      this.roleService.getRoleById(this.utilisateur.idRole).subscribe(role => {
        this.role = role;
        if (this.role && this.role.privileges && this.role.privileges.length > 0) {
          let founddeleteUtilisateur = this.role.privileges.find(x => x.name == "GET_ALL_STOCK")
          let founddeleteUtilisateuradd = this.role.privileges.find(x => x.name == "ADD_INVENTAIRE")
          if (founddeleteUtilisateur) {
            this.hasRolestock = true;
          }
          if (founddeleteUtilisateuradd) {
            this.hasRoleAdd = true;
          }
        }
      },
        err => {
          return false;
        });
        this.inventaireService.getAllDataConfigActivation()
          .then(data => {
            this.inventiareactivationdata = data
            var ext = this.inventiareactivationdata.find(x => x.desactive == false);
            if (ext) {
              this.showInvenytaireAdd = true;
            }
          }, err => {
          })
      
    },
      err => {
        return false;
      });

    this.inventaireService.getAllDataConfigActivation().then(dataActivation => {
      this.savedAcitvation = dataActivation
      this.inventaireService.filterInventaireByDatec().then(data => {
        this.allDt = data
        this.allDt.forEach((eachObj,index) => {
          var newObject = {
            "date": eachObj.result[0]._id.datec,
            "nombreFr": eachObj.result.length,
            "nbreReferceParFounrPardateTotal": 0,
            "totalquantiteParFounrPardateTotal": 0,
            "totalpritarParFounrPardateTotal": 0,
            "totalprinetParFounrPardateTotal": 0,
            "desactive": false,
            "existe": false,
            "dateMaj":""
          }
          var existObj = this.savedAcitvation.find(x => x.date == eachObj.result[0]._id.datec);
          if (existObj) {
            newObject.desactive = existObj.desactive;
            newObject.existe = true;
          }
          for (var i = 0; i < eachObj.result.length; i++) {
            newObject.nbreReferceParFounrPardateTotal = Number(newObject.nbreReferceParFounrPardateTotal + eachObj.result[i].nbreReferceParFounrPardate)
            newObject.totalquantiteParFounrPardateTotal = Number(newObject.totalquantiteParFounrPardateTotal + eachObj.result[i].totalquantiteParFounrPardate)
            newObject.totalpritarParFounrPardateTotal = Number(newObject.totalpritarParFounrPardateTotal + eachObj.result[i].totalpritarParFounrPardate)
            newObject.totalprinetParFounrPardateTotal = Number(newObject.totalprinetParFounrPardateTotal + eachObj.result[i].totalprinetParFounrPardate)
          }
          this.inventaires.push(newObject)
          if(index == Number(this.allDt.length - 1)){
            this.inventaires.sort(this.triYear);
            this.inventaireService.getAllDatemaj().then((data: any[])  => {
                if(data && data.length > 0){
                  for(var j = 0; j<this.inventaires.length;j++){
                    var founddates = data.filter(x => x.dateInventaire == this.inventaires[j].date)
                    if(founddates && founddates.length > 0){
                      founddates.sort(this.triYeardateMaj);
                      this.inventaires[j].dateMaj = founddates[0].dateMaj
                    }
                  }

                }
            },
              err => {
                return false;
              });
            this.getdataFirstDate(0)
          }

        })
      },
        err => {
          return false;
        });
    },
      err => {
        return false;
      });


    this.inventaireService.getAllDataConfig().then(data => {
      if (data && data[0]) {
        this.inventaireConfigObj = data[0]
      }
    },
      err => {
        return false;
      });

  }
  triYear(a, b) {
    if (a.date > b.date) return -1;
    else if (a.date == b.date) return 0;
    else return 1;
  }
  getBigDate(): string {
      return this.bigDate
  }
  triYeardateMaj(a, b) {
    if (a.dateMaj > b.dateMaj) return -1;
    else if (a.dateMaj == b.dateMaj) return 0;
    else return 1;
  }
  showAndHideStock() {
    this.showStock = !this.showStock
  }
  showAndHideDate() {
    this.showDate = !this.showDate
  }
  createNewStock() {
    this.errorStcpok = false;
    this.errorDate = false;
    this.errorNotExistInvetiare = false;
    if (!this.selectedDate || this.selectedDate === '') {
      this.errorDate = true;
      return false;
    }
    this.errorDate = false;
    this.stockService.getoneDataByRefByYear(this.selectedDate).then(data => {
      if (data) {
        this.errorStcpok = true;
        return false;
      }
      if (!data) {
        this.poursuivreCreatrionStcok()
        return true;
      }
    },
      err => {
        this.loading = false;
        return false;
      });


  }

  poursuivreCreatrionStcok() {
    this.errorNotExistInvetiare = false;
    let foundInventaireSearch = this.inventaires.find( x => x.date == this.selectedDate)
    if(!foundInventaireSearch){
      this.errorNotExistInvetiare = true;
      return false;
    }
    while (this.initialStock.length > 0) {
      this.initialStock.pop();
    }
    if (!this.selectedDate || this.selectedDate === '') {
      this.errorDate = true;
      return false;
    }
    this.errorDate = false;
    this.loading = true;
    this.inventaireService.getAllDataByDateAndFilter(this.selectedDate).then(data => {
      this.initialdata = data
      if (!this.initialdata) {
        this.loading = false;
      }
      if (this.initialdata && this.initialdata.length < 1) {
        this.loading = false;
      }

      if (this.initialdata && this.initialdata.length > 0) {
        this.initialdata[0].quantiteHis = []
        var qtHi = {
          "date": this.initialdata[0].datec,
          "quantite": 0,
          "quantiteSortie": 0,
          "initialQuantite": this.initialdata[0].quantite,
          "quantiteFinale": this.initialdata[0].quantite,
        }
        this.initialdata[0].dateInventaire = this.selectedDate
        this.initialdata[0].quantiteHis.push(qtHi)
        this.initialStock.push(this.initialdata[0])
        if (this.initialdata.length === 1) {
          this.saveMyStock(this.initialStock)
        }
        for (var j = 1; j < this.initialdata.length; j++) {
          this.initialdata[j].dateInventaire = this.selectedDate
          var existInventaire = this.initialStock.find(x => x.refArt == this.initialdata[j].refArt);
          if (existInventaire) {
            existInventaire.quantiteHis[0].initialQuantite = existInventaire.quantiteHis[0].initialQuantite + this.initialdata[j].quantite
            existInventaire.quantiteHis[0].quantiteFinale = existInventaire.quantiteHis[0].initialQuantite
          }
          if (!existInventaire) {
            this.initialdata[j].quantiteHis = []
            var qtHi = {
              "date": this.initialdata[j].datec,
              "quantite": 0,
              "quantiteSortie": 0,
              "initialQuantite": this.initialdata[j].quantite,
              "quantiteFinale": this.initialdata[j].quantite,
            }
            this.initialdata[j].quantiteHis.push(qtHi)
            this.initialStock.push(this.initialdata[j])
          }
          if (j === this.initialdata.length - 1) {
            this.saveMyStock(this.initialStock)
          }
        }

      }
    },
      err => {
        this.loading = false;
        return false;
      });
  }
  saveMyStock(initialStock) {
    if (initialStock && initialStock.length < 1) {
      this.loading = false;
    }
    if (initialStock && initialStock.length > 0) {
      this.stockService.saveAllData(initialStock)
        .subscribe(resp => {
          this.loading = false;
          Swal.fire( {
            imageUrl: 'assets/images/add-catalogue.png',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            width: 800,
            title: 'BIEN INITIALISÉ',
            timer: 1500,
            showConfirmButton: false
          }
        );
        }, err => {
          this.loading = false;
        })
    }
  }
  bloquerInvenatire(inventaire) {
    if (inventaire.existe) {
      this.inventaireService.getinventaireactivationByDate(inventaire.date)
        .then(data => {
          if (data) {
            data["desactive"] = inventaire.desactive
            this.inventaireService.editInventairerActivationById(data)
              .subscribe(resp => {
              }, err => {
              })
          }
        }, err => {
        })
    }
    if (!inventaire.existe) {
      var newActivation = {
        "desactive": true,
        "date": inventaire.date
      }
      this.inventaireService.addinventaireActivation(newActivation)
        .subscribe(resp => {
          inventaire.existe = true;
        }, err => {
        })
    }
  }
  createDefaultdate(inventaireConfigObj) {
    if (inventaireConfigObj.id) {
      this.inventaireService.editinventaireconfig(inventaireConfigObj)
        .subscribe(resp => {
          Swal.fire( {
            imageUrl: 'assets/images/add-catalogue.png',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            width: 800,
            title: 'BIEN ENREGISTRÉ',
            timer: 1500,
            showConfirmButton: false
          }
        );
        }, err => {

        })
    }
    if (!inventaireConfigObj.id) {
      this.inventaireService.addinventaireconfig(inventaireConfigObj)
        .subscribe(resp => {
          Swal.fire( {
            imageUrl: 'assets/images/add-catalogue.png',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            width: 800,
            title: 'BIEN ENREGISTRÉ',
            timer: 1500,
            showConfirmButton: false
          }
        );
        }, err => {

        })
    }
  }

  // Commencer la mise à jour de l'inventaire
  majInventiare(date) {
    // get inventaire data by date
    Swal.fire({
      title: 'VOULEZ-VOUS CONFIRMER?',
      imageUrl: 'assets/images/add-catalogue.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      showCancelButton: true,
      width: 800,
      confirmButtonColor: '#008000',
      cancelButtonColor: '#FFA500',
      confirmButtonText: 'CONFIRMER',

      cancelButtonText: 'ANNULER',
    }).then((result) => {
      if (!result.dismiss) {
        this.savedDateInventaire = date
        this.loading = true;
        this.inventaireService.getAllDataByDateAndFilter(date).then(data => {
          this.alldataInventaires = data
          if (this.alldataInventaires && this.alldataInventaires.length > 0) {
            this.getAllCommandeByDateInventaire(date)
          }
          if (!this.alldataInventaires || (this.alldataInventaires && this.alldataInventaires.length < 1)) {
            this.loading = false;
            Swal.fire({
              imageUrl: 'assets/images/error.png',
              imageWidth: 100,
              imageHeight: 100,
              imageAlt: 'Custom image',
              width: 600,
              title: 'Aucune référence trouvée !',
              /*timer: 1500,*/
              showConfirmButton: true,
              confirmButtonColor: '#8B0000',
              confirmButtonWidth: 100,

            }
            );
          }
        },
          err => {

            this.loading = false;
            return false;
          });

      }
    } );

  }
  getAllCommandeByDateInventaire(date) {
    this.commandeService.getAllCommandeLowerThanDateDESCCount(date).then(data => {
      this.lengthCommandes = data;
      if (this.lengthCommandes && this.lengthCommandes > 0) {
        this.startGettingCommandes(0,date);
        return true;
      }
      if (!this.lengthCommandes || (this.lengthCommandes && this.lengthCommandes < 1)) {
        this.loading = false;
        Swal.fire({
          imageUrl: 'assets/images/error.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 600,
          title: 'Aucune commande trouvée !',
          /*timer: 1500,*/
          showConfirmButton: true,
          confirmButtonColor: '#8B0000',
          confirmButtonWidth: 100,

        }
        );
      }
    },
      err => {

        this.loading = false;
        return false;
      });

  }
startGettingCommandes(numberPage,date){
  if (this.commandes.length === this.lengthCommandes) {
    this.startFiltring()
    return true;
  }
  this.commandeService.getAllCommandeLowerThanDateDESCWithPage(date,numberPage,2000).then((data: any[]) => {
    this.commandes.push(...data)
    numberPage++
    this.startGettingCommandes(numberPage,date);
    return true;
  },
    err => {
      this.loading = false;
      return false;
    });
}
  startFiltring() {
    //console.log("starting filter")
    this.filterAllInventaire(0)
  }
  filterAllInventaire(countInventaire) {
    //console.log("filterAllInventaire "+countInventaire)
    if (countInventaire === this.alldataInventaires.length) {
      this.updatingReferences()
      return true;
    }
    if (this.alldataInventaires[countInventaire] && this.alldataInventaires[countInventaire].idFournisseur) {
      this.createArrayToEdit(countInventaire, this.alldataInventaires[countInventaire], 0)
    }
    if (this.alldataInventaires[countInventaire] && !this.alldataInventaires[countInventaire].idFournisseur) {
      countInventaire++
      this.filterAllInventaire(countInventaire);
      return true;
    }
  }


  createArrayToEdit(j, data, countData) {
    console.log("createArrayToEdit "+j)
    setTimeout(()=>{                           //<<<---using ()=> syntax
      while(countData < this.commandes.length){
      var selectedCommandeRef = this.commandes[countData].lignes.find(x => x.refArt == data.refArt && x.idFournisseur == data.idFournisseur)
      if (selectedCommandeRef) {
        // update article en se basant sur une ligne des commandes
        data.designation = selectedCommandeRef.designation
        data.pritar = selectedCommandeRef.pritar
        data.coefAchat = selectedCommandeRef.coef
        data.prinet = selectedCommandeRef.prinet
        data.montant = (selectedCommandeRef.prinet * data.quantite).toFixed(2)
        data.idCommande = this.commandes[countData].id
        data.numeroCommande = this.commandes[countData].numeroCommandeGPMI
        data.dateCommande = this.commandes[countData].dateCommande
        if(this.commandes[countData].factures && this.commandes[countData].factures.length > 0){
          data.numeroFacture = this.commandes[countData].factures[this.commandes[countData].factures.length - 1].numFacture
          data.dateFacture = this.commandes[countData].factures[this.commandes[countData].factures.length -1 ].dateFacture
        }
        this.dataToEdit.push(data)
        this.filterAllInventaire(Number(j + 1))
        return true;
      }
      if (!selectedCommandeRef) {
        countData++
      }
      if (countData == Number(this.commandes.length-1)) {
        this.filterAllInventaire(Number(j + 1))
        return true;
      }
    }

 }, 1000);

  }

  updatingReferences() {
    //console.log(this.dataToEdit.length)
    if (this.dataToEdit.length < 1) {
      Swal.fire( {
          imageUrl: 'assets/images/add-catalogue.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 800,
          title: 'BIEN MODIFÉ',
          timer: 1500,
          showConfirmButton: false
        }
      );
      var objectInventaireMaj = {
        "dateInventaire":this.savedDateInventaire,
        "dateMaj":new Date().toISOString()
      }
      this.inventaireService.addInventairerDatesMAJ(objectInventaireMaj)
        .subscribe(resp => {
          var foundInventaire = this.inventaires.find(x => x.date == this.savedDateInventaire)
          if(foundInventaire){
            foundInventaire.dateMaj = new Date().toISOString()
          }
        }, err => {
          return false;
        })
      this.loading = false;
      return false;
    }
    this.updatingRferenceCommandes(0, this.dataToEdit)
    return true;
  }

  updatingRferenceCommandes(countme, dataToEdit) {
    //console.log("updatingRferenceCommandes" + countme)
    if (countme === dataToEdit.length) {
      this.foundNotExistedObhect(0, this.alldataInventaires)
      return true;
    }
    //console.log(dataToEdit[countme])
    this.inventaireService.editInventairerById(dataToEdit[countme])
      .subscribe(resp => {
        countme++
        this.updatingRferenceCommandes(countme, dataToEdit)
      }, err => {
        countme++
        this.updatingRferenceCommandes(countme, dataToEdit)
        return false;
      })
  }

  foundNotExistedObhect(counter, dataeee) {
    console.log("foundNotExistedObhect "+ counter)
    if (counter === dataeee.length) {
      Swal.fire( {
            imageUrl: 'assets/images/add-catalogue.png',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            width: 800,
            title: 'BIEN MODIFIÉ',
            timer: 1500,
            showConfirmButton: false
          }
        );
      var objectInventaireMaj = {
        "dateInventaire":this.savedDateInventaire,
        "dateMaj":new Date().toISOString()
      }
      this.inventaireService.addInventairerDatesMAJ(objectInventaireMaj)
        .subscribe(resp => {
          var foundInventaire = this.inventaires.find(x => x.date == this.savedDateInventaire)
          if(foundInventaire){
            foundInventaire.dateMaj = new Date().toISOString()
          }
        }, err => {
          return false;
        })
      this.loading = false;
      return;
    }
    var existedNewArticle = this.dataToEdit.find(x => x.refArt == dataeee[counter].refArt)
    if (!existedNewArticle) {
      var designationref = dataeee[counter].designation;
      var tarifRef = dataeee[counter].pritar
      this.catalogueService.getRefByRefAndFourDesc(dataeee[counter].refArt, dataeee[counter].idFournisseur).then(data => {
        this.allDataCtalogue = data
        if (this.allDataCtalogue && this.allDataCtalogue.length > 0) {
          if (this.allDataCtalogue[0] && (designationref != this.allDataCtalogue[0].designation25 || tarifRef != this.allDataCtalogue[0].tarif)) {
            dataeee[counter].designation = this.allDataCtalogue[0].designation25
            dataeee[counter].pritar = this.allDataCtalogue[0].tarif
            dataeee[counter].idCommande = null
            dataeee[counter].numeroCommande = null
            dataeee[counter].dateCommande = null
            dataeee[counter].coefAchat = null
            dataeee[counter].prinet = null
            dataeee[counter].montant = null
            this.inventaireService.editInventairerById(dataeee[counter])
              .subscribe(resp => {
                counter++
                this.foundNotExistedObhect(counter, this.alldataInventaires)
              }, err => {
                counter++
                this.foundNotExistedObhect(counter, this.alldataInventaires)
                this.loading = false;
                return false;
              })
          } else {
            counter++
            this.foundNotExistedObhect(counter, this.alldataInventaires)
          }
        }
        if (!this.allDataCtalogue || (this.allDataCtalogue && this.allDataCtalogue.length < 1)) {
          counter++
          this.foundNotExistedObhect(counter, this.alldataInventaires)
        }
      },
        err => {
          counter++
          this.foundNotExistedObhect(counter, this.alldataInventaires)
          this.loading = false;
          return false;
        });
    }
    if (existedNewArticle) {
      counter++
      this.foundNotExistedObhect(counter, this.alldataInventaires)
    }
  }

  getdataFirstDate(counter){
    this.loading = true;
    if (counter === this.inventaires.length) {
      this.loading = false;
      return;
    }
    if(this.otherdata){
      while(this.otherdata.length > 0) {
      this.otherdata.pop();
      }
    }
    if (!this.inventaires[counter].totalmontantPardate) {
      this.inventaires[counter].totalmontantPardate = 0
    }
    if (!this.inventaires[counter].totalCommandeGPMI) {
      this.inventaires[counter].totalCommandeGPMI = 0
    }
    if (!this.inventaires[counter].totalFacture) {
      this.inventaires[counter].totalFacture = 0
    }
    this.inventaireService.getDataBydate(this.inventaires[counter].date).then(data => {
      this.otherdata = data
      for(var j=0;j<this.otherdata.length;j++){
          this.inventaires[counter].totalmontantPardate = this.inventaires[counter].totalmontantPardate + Number(this.otherdata[j].prinet * this.otherdata[j].quantite )
          if(this.otherdata[j].numeroCommande){
            this.inventaires[counter].totalCommandeGPMI = this.inventaires[counter].totalCommandeGPMI + 1
          }
          if(this.otherdata[j].numeroFacture){
            this.inventaires[counter].totalFacture = this.inventaires[counter].totalFacture + 1
          }
        if (j === Number(this.otherdata.length - 1)) {
          counter++
          this.getdataFirstDate(counter)
          return true;
        }
      }
    },
      err => {
        return false;
      });
  }
  triYearCommande(a, b) {
    if (a.dateCommande > b.dateCommande) return -1;
    else if (a.dateCommande == b.dateCommande) return 0;
    else return 1;
  }
}
