import { Component, OnInit } from '@angular/core';
import { LocationStrategy, PlatformLocation, Location } from '@angular/common';
import { Router } from '@angular/router';
import {UserService} from "../../../services/user.service";
import { InventaireService } from "../../../services/inventaire.service";
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  idUser:any;
  utilisateur:any;
  inventaireConfigObj = {
    "desactive":false
  };
  role:any;
  profilzone = false
  hasRoleUtilisateur = false
  hasRoleClient = false;
  hasRoleFournisseur = false
  hasRoleDossier = false;
  hasRoleCatalgoue = false
  hasRoleInventaire = false
  hasRoleRH = false
  hasRoleRole = false
  isvoguel = true;
  constructor(private router: Router,private userService:UserService, private inventaireService:InventaireService, private roleService:RoleService) {

  }
  ngOnInit() {
    let host = window.location.hostname
     if(host.indexOf("hotelcaravanserail.com") > -1 || host.indexOf("localhost") > -1){
      this.isvoguel = false;
    } 
    this.idUser = localStorage.getItem('idUser');
    if(!this.idUser || this.idUser === undefined || this.idUser === 'undefined'){
      localStorage.removeItem('idUser');
      localStorage.clear();
      this.router.navigate(['/login']);
      return false
    }
    if(this.idUser){
      this.userService.getUserByID(this.idUser).subscribe(utilisateur => {
        this.utilisateur = utilisateur;
        if(!this.utilisateur || (this.utilisateur && !this.utilisateur.idRole)){
          localStorage.removeItem('idUser');
          localStorage.clear();
          this.router.navigate(['/login']);
          return false
        }
        if(this.utilisateur && !this.utilisateur.authenticated){
          localStorage.removeItem('idUser');
          localStorage.clear();
          this.updateUser(this.utilisateur)
          return true;
        }
        if(this.utilisateur && this.utilisateur.authenticated){
          this.getRoles(this.utilisateur)
          return true;
        }
      },
        err => {
          return false;
        });
    }else{
      localStorage.removeItem('idUser');
      localStorage.clear();
      this.router.navigate(['/login']);
      return false;
    }

    this.inventaireService.getAllDataConfig().then(data => {
      if(data && data[0] ){
        this.inventaireConfigObj = data[0]
      }
    },
      err => {
        return false;
      });

  }
updateUser(utilisateur){
  utilisateur.authenticated = true;
  this.userService.editInformationsUtilisateur(utilisateur).subscribe(data => {
    this.router.navigate(['/login']);
  });
}
shownAndHidelogout(){
  this.profilzone = !this.profilzone
}

saveRoute(route){
  localStorage.setItem("link",route)
}
// gestion des rôles utilisateur 

getRoles(utilisateur){
  // pour tester on mis admin comme rôle
  //let role = utilisateur.role
  //let role = "admin"
 
  this.roleService.getRoleById(this.utilisateur.idRole).subscribe(role => {
    this.role = role;
    if(this.role && this.role.privileges && this.role.privileges.length > 0){
      let foundutilisateurs = this.role.privileges.find(x => x.name == "GET_ALL_USER")
      let foundclients = this.role.privileges.find(x => x.name == "GET_ALL_CLIENT")
      let foundfournisseurs = this.role.privileges.find(x => x.name == "GET_ALL_FOURNISSEUR")
      let founddossiers = this.role.privileges.find(x => x.name == "GET_ALL_DOSSIER")
      let foundcatalogues = this.role.privileges.find(x => x.name == "GET_ALL_CATALOGUE")
      let foundinventaire = this.role.privileges.find(x => x.name == "GET_ALL_INVENTAIRE")
      let foundrh = this.role.privileges.find(x => x.name == "GET_ALL_RH")
      let foundrole = this.role.privileges.find(x => x.name == "GET_ALL_ROLE")
      if(foundutilisateurs){
        this.hasRoleUtilisateur = true;
      }
      if(foundclients){
        this.hasRoleClient = true;
      }
      if(foundfournisseurs){
        this.hasRoleFournisseur = true;
      }
      if(founddossiers){
        this.hasRoleDossier = true;
      }
      if(foundcatalogues){
        this.hasRoleCatalgoue = true;
      }
      if(foundinventaire){
        this.hasRoleInventaire = true;
      }
      if(foundrh){
        this.hasRoleRH = true;
      }
      if(foundrole){
        this.hasRoleRole = true;
      }
    }
  },
    err => {
      return false;
    });
   
}

}
