import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FournisseurService } from "../../../services/fournisseur.service";
import { FormBuilder,  Validators } from '@angular/forms';
import * as XLSX from "xlsx";
import { isNumeric } from 'rxjs/util/isNumeric';
import { CatalogueService } from "../../../services/catalogue.service";
import { UserService } from "../../../services/user.service";
import { CommandeService } from "../../../services/commande.service";

@Component({
  selector: 'app-details-fournisseur',
  templateUrl: './details-fournisseur.component.html',
  styleUrls: ['../../utilisateur/details-utilisateur/details-utilisateur.component.css'],
})
export class DetailsFournisseurComponent implements OnInit {
  fournisseur: any;
  roleAdministration = true;
  role: any;
  form: any;
  dateDebut = new Date().toISOString().split('T')[0]
  listeArtciels: any[] = []
  lignesXLS: any[] = []
  listeAllArtciels: any;
  loading = false;
  sizeLimitOne = 30000000; //30MB
  tarifGlobal = 0;
  addedLength = 0;
  errorObjet: any;
  errorDate = false
  fileToChoose: any;
  id: any;
  utilisateur: any;
  years: any[] = [];
  lengthdata = 0
  showAddCatalogue = false;
  commandes: any;
  factures: any;
  showMyContainer = 1;
  enfantpp = 1;
  fp = 1
  enfantp = 1;
  sp = 1;
  ddd = 1;
  pc = 1;
  p=1;
  constructor(private commandeService: CommandeService, private formBuilder: FormBuilder, private fournisseurService: FournisseurService, private activatedRoute: ActivatedRoute, private router: Router, private catalogueService: CatalogueService, private userService: UserService) {
    this.form = this.formBuilder.group({
      datec: ['', Validators.required],
      dateFin: ['', Validators.required],
      file: ['']
    })
  }
  ngOnInit() {
    this.role = localStorage.getItem('roleUser')
    if (this.role) {
      this.roleAdministration = false;
    }
    this.id = localStorage.getItem('idUser')
    this.userService.getUserByID(this.id).subscribe(utilisateur => {
      this.utilisateur = utilisateur;
      if(!this.utilisateur){
        localStorage.removeItem('idUser');
        localStorage.clear();
        this.router.navigate(['/login']);
      }
      if (this.utilisateur.sousRole == 'gr-acces') {
        this.router.navigate(["accueil"]);
        return false;
      }
    },
      err => {
        return false;
      });
    let params: any = this.activatedRoute.snapshot.params;
    this.fournisseurService.getFournisseurByID(params.id).subscribe(fournisseur => {
      this.fournisseur = fournisseur;
      if (this.fournisseur) {
        this.getCatalogueFournisseur(this.fournisseur.id)
        this.getCommandeFournisseur(this.fournisseur.id)
        this.getFactureFournisseur(this.fournisseur.id)
      }
    },
      err => {
        return false;
      });
  }
  getCatalogueFournisseur(id) {
    this.catalogueService.getAllFournisseurofArticlesWithYear(id).then(data => {
      if (data && data["result"]) {
        for (var j = 0; j < data["result"].length; j++) {
          var dataObject = {
            "year": data["result"][j].datec,
            "datec": data["result"][j]._id.datec,
            "dateFin": data["result"][j]._id.dateFin,
            "refCount": data["result"][j].refCount
          }
          if (this.years.indexOf(dataObject) === -1) {
            this.lengthdata = this.lengthdata + dataObject.refCount
            this.years.push(dataObject)
          }
        }
        this.years.sort(this.triYear);
      }
    },
      err => {
        return false;
      });
  }
  triYear(a, b) {
    if (a.datec > b.datec) return -1;
    else if (a.datec == b.datec) return 0;
    else return 1;
  }
  getDateDebut(): string {
    return this.dateDebut
  }

  // get data
  onFileChange(file: any) {
    // Vider le tableau et réinitialiser l'affichage
    /* wire up file reader */
    var myfile = file.target.files.item(0)
    if (myfile) {
      this.fileToChoose = undefined
      this.listeArtciels = []
      this.tarifGlobal = 0;
      this.addedLength = 0;
      this.errorObjet = " "
      if (!myfile.type.match('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet|application/vnd.ms-excel')) {
        file = undefined
        alert("Impossible de télécharger ce fichier.")
        return false;
      }
      if (myfile.size > this.sizeLimitOne) {
        file = undefined
        alert("Un fichier sélectionné est trop volumineux.La taille maximale est 30 Mo.")
        return false;
      }
      this.loading = true;
      const target: DataTransfer = <DataTransfer>(file.target);
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        var data = (XLSX.utils.sheet_to_json(ws, { header: 1 }));
        for (var i = 1; i < data.length; i++) {
          var object = {
            "mqe": data[i][0], "refCiel": data[i][1], "date_Valid": data[i][2], "n": data[i][3], "cent": data[i][4], "designation": data[i][5], "eco": data[i][6], "edi": data[i][7],
            "tarif": data[i][8], "qte_min_a": data[i][9], "codeif": data[i][10], "delai": data[i][11], "pap": data[i][12], "ean13": data[i][13], "qteD": data[i][14], "sub": data[i][15],
            "neww": data[i][16], "sta": data[i][17], "typ": data[i][18], "designation80": data[i][19], "qte2": data[i][20], "qte3": data[i][21], "qte4": data[i][22], "dateArret": data[i][23],
            "garaentie": data[i][24], "datec": this.form.value.datec, "dateFin": this.form.value.dateFin, "itemName": data[i][1], "denomination": this.fournisseur.denomination, "idFournisseur": this.fournisseur.id
          }
          if (object.refCiel) {
            //this.checkValidationObject(object, i);
            if (!isNumeric(object.tarif)) {
              this.errorObjet = " Merci de vérifier le prix tarif de la ligne " + Number(i + 1)
              this.tarifGlobal = 0;
              this.listeArtciels = [];
              this.loading = false;
              return false;
            }
            this.listeArtciels.push(object)
            this.tarifGlobal = this.tarifGlobal + Number(data[i][8])
          }
        }
        this.addedLength = this.listeArtciels.length
        this.loading = false;
      };
      if (target.files.length > 0) {

        reader.readAsBinaryString(target.files[0]);
      }
    } else {

      return false;
    }

  }
  saveData() {
    this.errorDate = false;
    if (this.loading) {
      alert('L\'upload des données est en cours ! Veuillez patienter')
      return false;
    }
    if (this.listeArtciels.length < 1) {
      return false;
    }
    if (this.form.value.datec > this.form.value.dateFin) {
      this.errorDate = true;
      return false;
    }
    if (window.confirm("Voulez vous vraiment intégrer ce fichier")) {
      this.loading = true;
      this.catalogueService.saveAllData(this.listeArtciels)
        .subscribe(resp => {

        }, err => {
        })
      setTimeout(() => {
        /** spinner ends after 6 seconds **/
        this.loading = false;
        alert('Votre fichier est bien intégré')
        this.router.navigateByUrl('/fournisseur');
      }, 20000);
      this.listeArtciels = []
      this.fileToChoose = undefined
    }
  }
  showAddCatalogueFunction() {
    this.showAddCatalogue = !this.showAddCatalogue
  }
  goToCatalogue(data) {
    if (this.fournisseur) {
      this.router.navigate(['/catalogue', data.year, this.fournisseur.id, data.datec, data.dateFin]);

    }
  }
  getCommandeFournisseur(idFournisseur) {
    this.commandeService.getAllCommandeCountByYearFounisseur(idFournisseur).then(data => {
      if (data && data["result"]) {
        this.commandes = data["result"]
        this.commandes.sort(this.triCommandeByYearDesc)
        this.getAllCommandeByFournisseurAndYear(this.commandes, idFournisseur, 0)
      }
    },
      err => {
        return false;
      });
  }
  getFactureFournisseur(idFournisseur) {
    this.commandeService.getAllFactureByYearFounisseurDateFacture(idFournisseur).then(data => {
      if (data && data["result"]) {
        this.factures = data["result"]
        this.factures.sort(this.triFactureByYearDesc)
        this.getAllCommandeByFournisseurAndYearFactures(this.factures, idFournisseur, 0)
      }
    },
      err => {
        return false;
      });
  }
  triCommandeByYearDesc(a, b) {
    if (a.yeardateCommande > b.yeardateCommande) return -1;
    else if (a.yeardateCommande == b.yeardateCommande) return 0;
    else return 1;
  }
  triFactureByYearDesc(a, b) {
    if (a.yeardateFacture > b.yeardateFacture) return -1;
    else if (a.yeardateFacture == b.yeardateFacture) return 0;
    else return 1;
  }
  getAllCommandeByFournisseurAndYear(commandes, idFournisseur, countme) {
    if (countme === this.commandes.length) {
      return true;
    }
    this.commandes[countme].allCommandes = []
    this.commandes[countme].totalSommePrixNet = 0
    this.commandes[countme].totalmontantTVA = 0
    this.commandes[countme].totalPrix = 0
    this.commandeService.getAllCommandeByYearFounisseur(this.commandes[countme].yeardateCommande, idFournisseur).then(data => {
      this.commandes[countme].allCommandes = data
      for (var j = 0; j < this.commandes[countme].allCommandes.length; j++) {
        if (this.commandes[countme].allCommandes[j].sommePrixNet != "NaN") {
          this.commandes[countme].totalSommePrixNet = this.commandes[countme].totalSommePrixNet + Number(this.commandes[countme].allCommandes[j].sommePrixNet)
          this.commandes[countme].totalSommePrixNet = Math.round(this.commandes[countme].totalSommePrixNet * 100) / 100 // 2 chiffres apres la virgule*/
        }
        if (this.commandes[countme].allCommandes[j].montantTVA != "NaN") {
          this.commandes[countme].totalmontantTVA = this.commandes[countme].totalmontantTVA + Number(this.commandes[countme].allCommandes[j].montantTVA)
          this.commandes[countme].totalmontantTVA = Math.round(this.commandes[countme].totalmontantTVA * 100) / 100 // 2 chiffres apres la virgule*/
        }
        if (this.commandes[countme].allCommandes[j].sommePrixNetTTC != "NaN") {
          this.commandes[countme].totalPrix = this.commandes[countme].totalPrix + Number(this.commandes[countme].allCommandes[j].sommePrixNetTTC)
          this.commandes[countme].totalPrix = Math.round(this.commandes[countme].totalPrix * 100) / 100 // 2 chiffres apres la virgule*/
        }
        if (j == Number(this.commandes[countme].allCommandes.length - 1)) {
          countme++
          this.getAllCommandeByFournisseurAndYear(this.commandes, idFournisseur, countme)
          return true;
        }
      }
    },
      err => {
        return false;
      });
  }

  getAllCommandeByFournisseurAndYearFactures(factures, idFournisseur, countme) {
    if (countme === this.factures.length) {
      return true;
    }
    this.factures[countme].allCommandes = []
    this.factures[countme].totalSommePrixNet = 0
    this.factures[countme].totalmontantTVA = 0
    this.factures[countme].totalPrix = 0
    this.commandeService.getAllCommandeByYearFounisseurFacturesDateFacture(this.factures[countme].yeardateFacture, idFournisseur).then(data => {
      if (data && data["result"] && data["result"].length > 0) {
        this.factures[countme].allCommandes = data["result"]
        for (var j = 0; j < this.factures[countme].allCommandes.length; j++) {
          if (this.factures[countme].allCommandes[j].factures.sommePrixNet && this.factures[countme].allCommandes[j].factures.sommePrixNet != "" && this.factures[countme].allCommandes[j].factures.sommePrixNet != "NaN") {
            this.factures[countme].totalSommePrixNet = this.factures[countme].totalSommePrixNet + Number(this.factures[countme].allCommandes[j].factures.sommePrixNet)
            this.factures[countme].totalSommePrixNet = Math.round(this.factures[countme].totalSommePrixNet * 100) / 100 // 2 chiffres apres la virgule*/
          }
          if (this.factures[countme].allCommandes[j].factures.montantTVA && this.factures[countme].allCommandes[j].factures.montantTVA != "" && this.factures[countme].allCommandes[j].factures.montantTVA != "NaN") {
            this.factures[countme].totalmontantTVA = this.factures[countme].totalmontantTVA + Number(this.factures[countme].allCommandes[j].factures.montantTVA)
            this.factures[countme].totalmontantTVA = Math.round(this.factures[countme].totalmontantTVA * 100) / 100 // 2 chiffres apres la virgule*/
          }
          if (this.factures[countme].allCommandes[j].factures.sommePrixNetTTC && this.factures[countme].allCommandes[j].factures.sommePrixNetTTC != "" && this.factures[countme].allCommandes[j].factures.sommePrixNetTTC != "NaN") {
            this.factures[countme].totalPrix = this.factures[countme].totalPrix + Number(this.factures[countme].allCommandes[j].factures.sommePrixNetTTC)
            this.factures[countme].totalPrix = Math.round(this.factures[countme].totalPrix * 100) / 100 // 2 chiffres apres la virgule*/
          }
          if (j == Number(this.factures[countme].allCommandes.length - 1)) {
            countme++
            this.getAllCommandeByFournisseurAndYearFactures(this.factures, idFournisseur, countme)
            return true;
          }
        }
      }
      if (!data || !data["result"] || (data["result"] && data["result"].length < 1)) {
        countme++
        this.getAllCommandeByFournisseurAndYearFactures(this.factures, idFournisseur, countme)
        return true;
      }

    },
      err => {
        return false;
      });
  }
  modifPart1() {
    this.showMyContainer = 1;

  }
  modifPart2() {
    this.showMyContainer = 2;
  }
  modifPart3() {
    this.showMyContainer = 3;
  }
  modifPart4() {
    this.showMyContainer = 4;
  }
  modifPart5() {
    this.showMyContainer = 5;
  }
  modifPart6() {
    this.showMyContainer = 6;
  }
  modifPart7() {
    this.showMyContainer = 7;
  }
  showPart() {
    return this.showMyContainer;
  }
  toFileXLS(){
    if(this.factures.length < 1){
      return false;
    }
    this.loading = true;
    for(var i =0; i<this.factures.length;i++){
      var devExcel = {
        "Année": this.factures[i].yeardateFacture,
        "Nombre des factures": this.factures[i].number,
        "Somme prix Net HT":  Math.round(this.factures[i].totalSommePrixNet * 100) / 100,
        "Somme montant TVA": Math.round(this.factures[i].totalmontantTVA * 100) / 100,
        "Somme de prix net TTC": Math.round(this.factures[i].totalPrix * 100) / 100,
        "Fournisseur": this.fournisseur.denomination
        }

      this.lignesXLS.push(devExcel)
    }
    const workBook = XLSX.utils.book_new(); // create a new blank book
    const workSheet = XLSX.utils.json_to_sheet(this.lignesXLS);

    XLSX.utils.book_append_sheet(workBook, workSheet, 'data'); // add the worksheet to the book
    XLSX.writeFile(workBook, 'VoWork-Factures-Annuel-'+ this.fournisseur.denomination+'.xlsx'); // initiate a file download in browser
    this.loading = false
  }
}
