import { Component, OnInit } from '@angular/core';
import * as XLSX from "xlsx";
import { StockService } from "../../../services/stock.service";
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from "../../../services/user.service";
import { FournisseurService } from "../../../services/fournisseur.service";
import { Options } from 'ng5-slider';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
import * as moment from 'moment';
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'app-stock',
  templateUrl: './stock.component.html',
  styleUrls: ['../../utilisateur/utilisateur/utilisateur.component.css'],
})
export class StockComponent implements OnInit {
  fileReaded: any;
  stock: any
  loading = false;
  sizeLimitOne = 30000000; //30MB
  numberData = 25;
  params:any;
  stockExcel:any[]=[]
  listeStockExcel:any[]=[]
  listeStock:any;
  constStock:any;
  id:any;
  utilisateur:any;
  unqieusdates:any;
  myDateInvetaire:any;
  fournisseurs:any;
  fournisseur:any;
  showMulti = false
  refArt:any;
  designation:any;
  ean:any;
  timeout: any = null;
  value: number = 0;
  highValue: number = 50000;
  options: Options = {
    floor: 0,
    ceil: 50000
  };
  public search: any = '';
  inventaireConfigObj:any;
  p = 1
  role:any;
  hasRoleDelete = false;
  hasRoleUpdate = false 
  hasRoleAdd= false;
  hasRoleinventaire = false;
  constructor(private roleService:RoleService,private stockService: StockService, private activatedRoute:ActivatedRoute,private userService:UserService,private router:Router,private fournisseurService:FournisseurService) {

  }
  ngOnInit() {
    let params: any = this.activatedRoute.snapshot.params;
    this.id = localStorage.getItem('idUser')
    this.userService.getUserByID(this.id).subscribe(utilisateur => {
      this.utilisateur = utilisateur;
      if(!this.utilisateur){
        localStorage.removeItem('idUser');
        localStorage.clear();
        this.router.navigate(['/login']);
      }
      if(this.utilisateur){
        this.stockService.getUniqueDateInventaire().then(data => {
          this.unqieusdates = data
          this.unqieusdates.sort(this.triYear)
          if(this.unqieusdates && this.utilisateur.role != "Super admin" && this.unqieusdates[0] != params.date && this.utilisateur.role != "Admin"){
            this.router.navigateByUrl('/stock');
            return false;
          }else{
            this.myDateInvetaire = this.unqieusdates[0]
            this.getMydate();
            return true;
          }

        },
          err => {
            return false;
          });

          this.roleService.getRoleById(this.utilisateur.idRole).subscribe(role => {
            this.role = role;
            if (this.role && this.role.privileges && this.role.privileges.length > 0) {
              let founddeleteUtilisateur = this.role.privileges.find(x => x.name == "DELETE_STOCK")
              let foundupdateUtilisateur = this.role.privileges.find(x => x.name == "UPDATE_STOCK")
              let foundupdateUtilisateurAdd = this.role.privileges.find(x => x.name == "ADD_STOCK")
              let foundupdateUtilisateurinventaire = this.role.privileges.find(x => x.name == "GET_ALL_INVENTAIRE")
              if (founddeleteUtilisateur) {
                this.hasRoleDelete = true;
              }
              if (foundupdateUtilisateur) {
                this.hasRoleUpdate = true;
              }
              if (foundupdateUtilisateurAdd) {
                this.hasRoleAdd = true;
              }
              if (foundupdateUtilisateurinventaire) {
                this.hasRoleinventaire = true;
              }
            }
          },
            err => {
              return false;
            });  
      }
    },
      err => {
        return false;
      });

      this.fournisseurService.getAllUniqueDenomination().then(data => {
        this.fournisseurs = data;
      },
        err => {
          return false;
        });
  }

 getMydate(){
   this.loading = true;
   let params: any = this.activatedRoute.snapshot.params;
   this.stockService.getAllDataByDateInventaire(params.date).then(data => {
     this.listeStock = data
     this.constStock = this.listeStock
     this.loading = false;
   },
     err => {
       this.loading = false;
       return false;
     });
 }
  removebible(article) {
    if (!article.id) {
      Swal.fire({
      title: 'VOULEZ-VOUS SUPPRIMER CET ARTICLE?',
      imageUrl: 'assets/images/supp-modal.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      showCancelButton: true,
      width: 800,
      confirmButtonColor: '#059e00',
      cancelButtonColor: '#FF9100',
      cancelButtonText: 'ANNULER',
      confirmButtonText: 'VALIDER'
    }).then((result) => {
      if (!result.dismiss) {
        Swal.fire( {
            imageUrl: 'assets/images/supp-modal.png',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            width: 800,
            title: 'BIEN SUPPRIMÉE',
            timer: 1500,
            showConfirmButton: false
          }
        );
        this.listeStock.splice(this.listeStock.indexOf(article), 1);
        return true;
      }
    } );
    }
    Swal.fire({
    title: 'VOULEZ-VOUS SUPPRIMER CET ARTICLE?',
    imageUrl: 'assets/images/supp-modal.png',
    imageWidth: 100,
    imageHeight: 100,
    imageAlt: 'Custom image',
    showCancelButton: true,
    width: 800,
    confirmButtonColor: '#059e00',
    cancelButtonColor: '#FF9100',
    cancelButtonText: 'ANNULER',
    confirmButtonText: 'VALIDER'
  }).then((result) => {
    if (!result.dismiss) {
      Swal.fire( {
          imageUrl: 'assets/images/supp-modal.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 800,
          title: 'BIEN SUPPRIMÉE',
          timer: 1500,
          showConfirmButton: false
        }
      );
      this.stockService.deleteArticleById(article.id).then(data => {
      });
      this.listeStock.splice(this.listeStock.indexOf(article), 1);
    }
  } );
  }
exportDataToExcel(){
 if(this.listeStock.length < 1){
    return false;
  }
  this.listeStockExcel = []
  while (this.listeStockExcel.length) {
    this.listeStockExcel.pop();
  }
  this.loading = true;
  for(var i =0; i<this.listeStock.length;i++){
    if(this.listeStock[i].dateCommande){
      this.listeStock[i].dateCommande = moment(this.listeStock[i].dateCommande).format('DD/MM/YYYY')
    }
    var devExcel = {
      "Fournisseur":this.listeStock[i].denomination,
      "Référence":this.listeStock[i].refArt,
      "Désignation":this.listeStock[i].designation,
      "Prix Tarif":Math.round(this.listeStock[i].pritar * 100) / 100,
      "Coefficient d'achat	": Math.round(this.listeStock[i].coefAchat * 10000) / 10000,
      "Prix net	":Math.round(this.listeStock[i].prinet * 100) / 100,
      "Quantité initiale":this.listeStock[i].quantiteHis[0].initialQuantite,
      "Quantité entrée ":this.listeStock[i].quantiteHis[0].quantite,
      "Quantité sortie ":this.listeStock[i].quantiteHis[0].quantiteSortie,
      "Quantité finale ":this.listeStock[i].quantiteHis[0].quantite + this.listeStock[i].quantiteHis[0].initialQuantite - this.listeStock[i].quantiteHis[0].quantiteSortie,
      "Montant":Math.round((this.listeStock[i].quantiteHis[0].quantite + this.listeStock[i].quantiteHis[0].initialQuantite - this.listeStock[i].quantiteHis[0].quantiteSortie) * this.listeStock[i].prinet * 100) / 100,
      "Étage":this.listeStock[i].etage,
      "Observation":this.listeStock[i].observation,
      "dateCommande":this.listeStock[i].dateCommande,
      "Famille":this.listeStock[i].libelleFamille,
      "Code famille ":this.listeStock[i].codFamille,
      "Coef famille ":this.listeStock[i].coefFamille
    }
    this.listeStockExcel.push(devExcel)
  }
  const workBook = XLSX.utils.book_new(); // create a new blank book
  const workSheet = XLSX.utils.json_to_sheet(this.listeStockExcel);

  XLSX.utils.book_append_sheet(workBook, workSheet, 'data'); // add the worksheet to the book
  var mydate =  this.listeStock[0].datec[8]+this.listeStock[0].datec[9]+this.listeStock[0].datec[5]+this.listeStock[0].datec[6]+this.listeStock[0].datec[2]+this.listeStock[0].datec[3]
  XLSX.writeFile(workBook, 'VoWork-Stock-'+ mydate +'.xlsx'); // initiate a file download in browser
  this.loading = false
}

triYear(a, b) {
  if (a > b) return -1;
  else if (a == b) return 0;
  else return 1;
}

// module serach stock
showAndHideMulti(){
  this.showMulti = !this.showMulti
}
//search by fournisseur
valuechangeFournisseur(event){
  clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      if (event.keyCode != 13) {
        if(!$this.fournisseur){
          $this.reinitialiseStock()
          return false;
        }
        if($this.fournisseur && $this.fournisseur.length <4){
         $this.reinitialiseStock()
          return false;
        }
      }
    },1000);
}
chooseFournisseur(event){
  this.loading = true;
  this.listeStock = this.listeStock.filter(element => element.denomination == this.fournisseur)
  this.loading = false;
}
//search by référence
valuechangeReference(event){
  clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      if (event.keyCode != 13) {
        if(!$this.refArt){
          $this.reinitialiseStock()
          return false;
        }
        if($this.refArt && $this.refArt.length <4){
         $this.reinitialiseStock()
          return false;
        }
        $this.loading = true;
        $this.reinitialiseStock()
        $this.listeStock = $this.listeStock.filter(element => element.refArt.toLowerCase().indexOf($this.refArt.toLowerCase()) > -1)
        $this.loading = false;
      }
    },1000);
}
// search by désignation

valuechangeDesignation(event){
  clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      if (event.keyCode != 13) {
        if(!$this.designation){
          $this.reinitialiseStock()
          return false;
        }
        if($this.designation && $this.designation.length <4){
         $this.reinitialiseStock()
          return false;
        }
        $this.loading = true;
        $this.reinitialiseStock()
        $this.listeStock = $this.listeStock.filter(element => element.designation.toLowerCase().includes($this.designation.toLowerCase()))
        $this.loading = false;
      }
    },1000);
}
//search by ean13Art
valuechangeean(event){
  clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      if (event.keyCode != 13) {
        if(!$this.ean){
          $this.reinitialiseStock()
          return false;
        }
        if($this.ean && $this.ean.length != 13){
         $this.reinitialiseStock()
          return false;
        }
        $this.loading = true;
        $this.reinitialiseStock()
        $this.listeStock = $this.listeStock.filter(element => element.ean13Art == $this.ean)
        $this.loading = false;
      }
    },1000);
}
// serach by prix
valueChangeRange(event){
  clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.reinitialiseStock()
        $this.loading = true;
        $this.listeStock = $this.listeStock.filter(element => element.pritar >= $this.value && element.pritar <= $this.highValue)
        $this.loading = false;
      }
    },2000);
}
// initialise stock afater search
reinitialiseStock(){
  this.loading = true;
  this.listeStock = this.constStock
  if(this.ean && this.ean.length == 13){
    this.listeStock = this.listeStock.filter(element => element.ean13Art == this.ean)
  }
  if(this.refArt && this.refArt.length >3){
    this.listeStock = this.listeStock.filter(element => element.refArt.toLowerCase().indexOf(this.refArt.toLowerCase()) > -1)
  }
  if(this.designation && this.designation.length >3){
    this.listeStock = this.listeStock.filter(element => ( element.designation && element.designation.toLowerCase().includes(this.designation.toLowerCase())))
  }
  if(this.fournisseur && this.fournisseur.length >3){
    this.listeStock = this.listeStock.filter(element => element.denomination == this.fournisseur)
  }
  this.listeStock = this.listeStock.filter(element => element.pritar >= this.value && element.pritar <= this.highValue)
  this.loading = false;
}
// reet stock
resetStcok(){
  this.loading = true;
  this.listeStock = this.constStock
  this.ean = "";
  this.refArt = "";
  this.designation = "";
  this.fournisseur = ""
  this.loading = false;
  this.highValue = 50000
  this.value = 0
}
}
