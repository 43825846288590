<app-navbar></app-navbar>
<br>

<div id="content-nav-page" class="row">
  <div class=" col-3 titre-menu">
    <a routerLink="/stock" class=" icon-f-left"></a>
    <b style="margin-right: 10%" *ngIf="article">{{article.refArt}} </b>
  </div>
  <div class="col-9 sous-menu" *ngIf="utilisateur">
    <b class="sous-menu-active1 ml-4" routerLink="/stock">STOCK</b>
  </div>
</div>


<div class="card p-3" *ngIf="article">
  <div class="card-header " style="border: 1px solid green;margin-bottom: 1%;text-align: center" >
    <b style="color:grey;text-align: center" >INFORMATIONS </b>
  </div>
  <div >
    <div class="card-body">

      <div class="row" >
        <div class="col-4" style="margin-left: 12%;margin-right: 10%">
          <div class="title">
            <h4>Référence </h4>
            <span class="font-weight-bold">{{article.refArt}}</span>
          </div>

          <br/>
          <div class="title ">
            <h4>Désignation </h4>
            <span class="font-weight-bold">{{article.designation}} </span>
          </div>
          <br/>

          <div class="title" *ngIf="utilisateur && utilisateur.role === 'Super admin'">
            <h4>Prix Tarif </h4>
            <span class="font-weight-bold">{{article.pritar | number: '1.2-2'| replace:',':' ' | replace:'.':','}}</span>
          </div>
          <br/>
          <div class="title" *ngIf="utilisateur && utilisateur.role === 'Super admin'">
            <h4>Coefficient d'achat</h4>
            <span class="font-weight-bold">{{article.coefAchat | number: '1.4-4'| replace:',':' ' | replace:'.':','}}</span>

          </div>
          <br/>
          <div class="title" *ngIf="utilisateur && utilisateur.role === 'Super admin'">
            <h4>Prix Net</h4>
            <span class="font-weight-bold">{{article.prinet | number: '1.2-2'| replace:',':' ' | replace:'.':','}}</span>
          </div>
          <br/>
          <div class="title">
            <h4>EAN</h4>
            <span class="font-weight-bold">{{article.ean13Art}}</span>

          </div>
          <br>
          <div class="title" *ngIf="utilisateur && utilisateur.role === 'Super admin'">
            <h4>Famille</h4>
            <span class="font-weight-bold">{{article.libelleFamille}}</span>

          </div>
          <br>
          <div class="title" *ngIf="utilisateur && utilisateur.role === 'Super admin'">
            <h4>Code famille</h4>
            <span class="font-weight-bold">{{article.codFamille}}</span>

          </div>
          <br>
          <div class="title">
            <h4>Étage</h4>
            <span class="font-weight-bold">{{article.etage}}</span>

          </div>
          <br>
          <div class="title">
            <h4>Date commande</h4>
            <span class="font-weight-bold">{{article.dateCommande | date : 'dd/MM/yyyy'}}</span>

          </div>
        </div>

        <div class="col-4">

          <div class="title">
            <h4>Quantité Initiale</h4>
            <span class="font-weight-bold">{{article.quantiteHis[0].initialQuantite | number: '1.2-2'| replace:',':' ' | replace:'.':',' | replace:',00':' '}}</span>

          </div>
          <br/>
          <div class="title">
            <h4>Quantité Entrée</h4>
            <span class="font-weight-bold">{{article.quantiteHis[0].quantite | number: '1.2-2'| replace:',':' ' | replace:'.':',' | replace:',00':' '}}</span>

          </div>
          <br/>
          <div class="title">
            <h4>Quantité Sortie</h4>
            <span class="font-weight-bold">{{article.quantiteHis[0].quantiteSortie | number: '1.2-2'| replace:',':' ' | replace:'.':',' | replace:',00':' '}}</span>

          </div>
          <br/>
          <div class="title">
            <h4>Quantité Finale</h4>
            <span class="font-weight-bold">{{article.quantiteHis[0].initialQuantite + article.quantiteHis[0].quantite - article.quantiteHis[0].quantiteSortie | number: '1.2-2'| replace:',':' ' | replace:'.':',' | replace:',00':' '}}</span>

          </div>
          <br/>
          <div class="title" *ngIf="utilisateur && utilisateur.role === 'Super admin'">
            <h4>Montant</h4>
            <span class="font-weight-bold">{{(article.quantiteHis[0].initialQuantite + article.quantiteHis[0].quantite - article.quantiteHis[0].quantiteSortie) * article.prinet | number: '1.2-2'| replace:',':' ' | replace:'.':','}} </span>
          </div>
          <br/>

          <div class="title">
            <h4>Date</h4>
            <span class="font-weight-bold">{{article.datec | date : 'dd/MM/yyyy'}}</span></div>
          <br/>

          <div class="title">
            <h4>Fournisseur</h4>
            <span class="font-weight-bold">{{article.denomination}}</span>
          </div>
          <br>
          <div class="title" *ngIf="utilisateur && utilisateur.role === 'Super admin'">
            <h4>Coef famille</h4>
            <span class="font-weight-bold">{{article.coefFamille}}</span>

          </div>
          <br>
          <div class="title" >
            <h4>Observation</h4>
            <span class="font-weight-bold">{{article.observation}}</span>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
