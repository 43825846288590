<app-navbar></app-navbar>
<br>
<div id="content-nav-page" class="row">
    <div class=" col-3 titre-menu">
      <b style="margin-right: 10%" class="title-haut">CATALOGUE</b>
      <div class="dropdown">
        <button class="btn icon-catalogue" type="button" id="dropdownMenuButton" data-toggle="dropdown">
        </button>
        <div class="dropdown-menu show-catalogue" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item drop-dup-option" routerLink="/ajoutCatalogue/manuel">Manuel</a>
          <a class="dropdown-item drop-dup-option-b" routerLink="/ajoutCatalogue">Fichier XLS</a>
        </div>
      </div>
    </div>
    <!-- NOMBRE DE DOSSIER PAR TYPES ET PAR ANNÉE -->
    <ng-template #customLoadingTemplate></ng-template>
    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading>
  
  
    <div class="col-9 sous-menu" *ngIf="utilisateur">
      <b class="sous-menu-active1" routerLink="/catalogue">CATALOGUE</b>
    </div>
  </div>
  
  
  <div id="content-page" class="containe-fluid">
  
    <div class="row  ">
      <div class="col-lg-4 col-md-10 col-sm-10  search">
      <input class="search-bar" type="text" placeholder="Rechercher.." [(ngModel)]="filterArticle" name="filterArticle">
      </div>
      <div class="col-lg-1 col-md-2 col-sm-2">
        <select class="select-input-nombre" name="numberData" [(ngModel)]="numberData">
          <option>5</option>
          <option selected>10</option>
          <option>25</option>
          <option>50</option>
        </select>
      </div>
  
      <div class=" col d-flex justify-content-end">
        <div class="col-auto nbr-dossier ">
          <table>
            <tr>
              <td>
                <b>Catalogues</b>
              </td>
            </tr>
            <tr>
              <td>
                <b class="sous-nbr-dossier d-flex justify-content-center" *ngIf="fournisseurs">{{fournisseurs.length}}</b>
              </td>
            </tr>
          </table>
        </div>
  
        <div class="col-auto btn-devis ">
          <br>
        </div>
      </div>
    </div>
    <div class="row" style="overflow-x:auto;">
      <table class="table table-striped table-hover">
        <th class="left">DÉNOMINATION</th>
        <th class="left">ACRONYME</th>
        <th class="right">TOTAL DES ARTICLES</th>
        <th class="text-center ">RÉGLAGES</th>
        <tr class="text-center" *ngFor="let data of fournisseurs|searchingCatalogue  : filterArticle.toString().toLowerCase()| paginate: { itemsPerPage: numberData, currentPage: p } ;let i = index" [routerLink]="['/catalogue/fournisseurs/years',data._id]">
          <td class="left">
            <span *ngIf="data.fournisseurs && data.fournisseurs.length > 0">{{data.fournisseurs[0].denomination}}</span>
          </td>
          <td class="left">
            <span *ngIf="data.fournisseurs && data.fournisseurs.length > 0">{{data.fournisseurs[0].acronyme}}</span>
          </td>
          <td class="right">
            {{data.nArticles | number: '1.2-2'| replace:',':' ' | replace:'.':',' | replace:',00':' '}}
          </td>
          <button class="btn-affiche"><a [routerLink]="['/catalogue/fournisseurs/years',data._id]"> <img src="assets/images/show-white.png"> Afficher </a> </button>
        </tr>
      </table>
    </div>
    <div class="row">
      <pagination-controls previousLabel="Précédent" nextLabel="Suivant" class=“custom-pagination” (pageChange)="p = $event" #api></pagination-controls>
    </div>
  </div>
  
