import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserGuard } from 'src/app/guards/user.guard';
import { AjoutUtilisateurComponent } from './ajout-utilisateur/ajout-utilisateur.component';
import { DetailsUtilisateurHistoriqueComponent } from './details-utilisateur-historique/details-utilisateur-historique.component';
import { DetailsUtilisateurComponent } from './details-utilisateur/details-utilisateur.component';
import { UpdateUtilisateurComponent } from './update-utilisateur/update-utilisateur.component';
import { UtilisateurComponent } from './utilisateur/utilisateur.component';

const routes: Routes = [
  { path: 'utilisateur', component: UtilisateurComponent, canActivate: [UserGuard] },
  { path: 'details/utilisateur/:id', component: DetailsUtilisateurComponent,canActivate: [UserGuard]},
  { path: 'ajoutUtilisateur', component: AjoutUtilisateurComponent, canActivate: [UserGuard]},
  { path: 'utilisateur/details/:id', component: UpdateUtilisateurComponent,canActivate: [UserGuard]},
  { path: 'details/utilisateur/historique/:id', component: DetailsUtilisateurHistoriqueComponent,canActivate: [UserGuard]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UtilisateurRoutingModule { }
