import { Component, OnInit } from '@angular/core';
import { StockService } from "../../../services/stock.service";
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from "../../../services/user.service";
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'app-stock-by-date',
  templateUrl: './stock-by-date.component.html',
  styleUrls: ['../../utilisateur/utilisateur/utilisateur.component.css'],
})
export class StockByDateComponent implements OnInit {
  fileReaded: any;
  stock: any
  loading = false;
  sizeLimitOne = 30000000; //30MB
  numberData = 25;
  params:any;
  stockExcel:any[]=[]
  listeStockExcel:any[]=[]
  listeStock:any;
  id:any;
  utilisateur:any;
  p = 1
  inventaireConfigObj:any;
  role:any;
  hasRoleinventaire = false;
  hasRoleAdd = false;
  constructor(private roleService:RoleService,private router:Router,private stockService: StockService, private activatedRoute:ActivatedRoute,private userService:UserService) {
    this.loading = true;
    this.stockService.getAllDataGroiupByDate().then(data => {
      if(data && data["result"]){
        this.listeStock = data["result"]
        this.listeStock.sort(this.triYear)
      }
      this.loading = false;
    },
      err => {
        this.loading = false;
        return false;
      });
  }
  ngOnInit() {
    this.id = localStorage.getItem('idUser')
    this.userService.getUserByID(this.id).subscribe(utilisateur => {
      this.utilisateur = utilisateur;
      if(!this.utilisateur){
        localStorage.removeItem('idUser');
        localStorage.clear();
        this.router.navigate(['/login']);
      }
      this.roleService.getRoleById(this.utilisateur.idRole).subscribe(role => {
        this.role = role;
        if (this.role && this.role.privileges && this.role.privileges.length > 0) {
          let foundupdateUtilisateuradd = this.role.privileges.find(x => x.name == "ADD_STOCK")
          let foundupdateUtilisateurinventaire = this.role.privileges.find(x => x.name == "GET_ALL_INVENTAIRE")
          if (foundupdateUtilisateuradd) {
            this.hasRoleAdd = true;
          }
          if (foundupdateUtilisateurinventaire) {
            this.hasRoleinventaire = true;
          }
        }
      },
        err => {
          return false;
        });
    },
      err => {
        return false;
      });
  }
  triYear(a, b) {
    if (a._id > b._id) return -1;
    else if (a._id == b._id) return 0;
    else return 1;
  }

}
