import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
const headers = new HttpHeaders({ Authorization: 'Basic ' + btoa('UserkrendelAdminnew:krl2021!!??**SoC24-415fr-a410-ttx9J8NbPecEL6k4BkAXvGE3t8f3nuEZmXa5RNJBAdrnh6XqB2') })
  .set('Content-Type', 'application/json')
  .set('Accept', 'application/json');
headers.append('Access-Control-Allow-Origin', '*');
import 'rxjs/add/operator/map';

@Injectable({
  providedIn: 'root'
})
export class DossierService {


  constructor(public http: HttpClient) {
  }

  getAllDossiersByIdClient(id) {
    return new Promise(resolve => {
      this.http.get(environment.host + '/dossiers/getAllDossiersByIdClient/' + id).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getAllDossiersByPage(page,size){
    return new Promise(resolve => {
      this.http.get(environment.host + '/dossiers/byPage/'+page+'/'+size, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getDossierbyidClientFilterBYCA(id) {
    return new Promise(resolve => {
      this.http.get(environment.host + '/dossiers/getDossier/byCA/year/' + id, { headers: headers }).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getAllDataCount(){
    return new Promise(resolve => {
      this.http.get(environment.host + '/dossiers/count', { headers: headers }).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }

  getUniqueNumeros(){
    return new Promise(resolve => {
      this.http.get(environment.host + '/dossiers/distinct/numero', { headers: headers }).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getDossierbyidClientFilterBYChiffreur(id){
    return new Promise(resolve => {
      this.http.get(environment.host + '/dossiers/getDossier/byChiffreur/year/' + id+'/data', { headers: headers }).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getAllDossiersByMailChiffreurAndFilter(mailUtilisateur){
    return new Promise(resolve => {
      this.http.get(environment.host + '/dossiers/get/by/mail/' + mailUtilisateur + '/test', { headers: headers }).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }

  getAllDataByPageAndRecherche(event){
    return new Promise(resolve => {
      this.http.get(environment.host + '/dossiers/recherche/byEvent/'+event, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getDossierbyidClientFilterBYChiffreurForCA(id){
    return new Promise(resolve => {
      this.http.get(environment.host + '/dossiers/getDossierForCA/byChiffreur/year/' + id+'/data', { headers: headers }).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getDossierbyidClientFilterBYChiffreurForChiffreur(mail){
    return new Promise(resolve => {
      this.http.get(environment.host + '/dossiers/getDossierForCA/byChiffreur/year/' + mail+'/data/chiffreur', { headers: headers }).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getAllDossiers() {
    return new Promise(resolve => {
      this.http.get(environment.host + '/dossiers', { headers: headers }).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
getAllDossiersGroupedByYears(){
  return new Promise(resolve => {
    this.http.get(environment.host + '/dossiers/groupedBy/years', { headers: headers }).subscribe(data => {
      resolve(data);
    }, err => {
    });
  });
}
  addDossier(dossier) {
    return this.http.post(environment.host + '/dossiers/addOne', JSON.stringify(dossier), { headers: headers })
  }


  getDossierById(id) {
    return this.http.get(environment.host + '/dossiers/' + id, { headers: headers })

  }

  getClientByDossier(id) {
    return new Promise(resolve => {
      this.http.get(environment.host + '/dossiers/getClientByDossier/' + id).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }

  getDetailDossierById(id) {
    return new Promise(resolve => {
      this.http.get(environment.host + '/dossiers/getDetailDossierById/' + id).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }

  getDetailDossierByNumero(numero) {
    return new Promise(resolve => {
      this.http.get(environment.host + '/dossiers/getDetailDossier/byNumero/' + numero,{ headers: headers }).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }

  deleteDossierById(id) {
    return new Promise(resolve => {
      this.http.delete(environment.host + '/dossiers/' + id, { headers: headers }).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }


  editDossier(dossier) {
    return this.http.put(environment.host + '/dossiers/' + dossier.id, JSON.stringify(dossier), { headers: headers })
  }


  getFiltredDossierByNom(nom, page, size) {
    return new Promise(resolve => {
      this.http.get(environment.host + '/dossiers/findByNom/' + nom + '/' + page + '/' + size).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }

  // vérifier l'existance d'un numéro du dossier
  verifyNumero(dossier) {
    return this.http.post(environment.host + '/dossiers/verifiedNumero', JSON.stringify(dossier), { headers: headers })
  }

}
