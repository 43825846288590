import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'searchFournisseur'
})
export class SearchFournisseurPipe implements PipeTransform {
  transform(value, args: string[]) {
    if (!value) return value;
    if (value.filter((item) => item.denomination.toLowerCase().includes(args)) != '') {
      return value.filter((item) => item.denomination.toLowerCase().includes(args));
    }
    if (value.filter((item) => item.acronyme.toLowerCase().includes(args)) != '') {
      return value.filter((item) => item.acronyme.toLowerCase().includes(args));
    }
    if (value.filter((item) => item.type.toLowerCase().includes(args)) != '') {
      return value.filter((item) => item.type.toLowerCase().includes(args));
    }
    if (value.filter((item) => item.mail.toLowerCase().includes(args)) != '') {
      return value.filter((item) => item.mail.toLowerCase().includes(args));
    }
    if (value.filter((item) => item.tel.toLowerCase().includes(args)) != '') {
      return value.filter((item) => item.tel.toLowerCase().includes(args));
    }
    if (value.filter((item) => item.formeJuridique.toLowerCase().includes(args)) != '') {
      return value.filter((item) => item.formeJuridique.toLowerCase().includes(args));
    }
    if (value.filter((item) => item.indicatif.toLowerCase().includes(args)) != '') {
      return value.filter((item) => item.indicatif.toLowerCase().includes(args));
    }
    if (value.filter((item) => item.adresse.pays.toLowerCase().includes(args)) != '') {
      return value.filter((item) => item.adresse.pays.toLowerCase().includes(args));
    }
    if (value.filter((item) => item.adresse.ville.toLowerCase().includes(args)) != '') {
      return value.filter((item) => item.adresse.ville.toLowerCase().includes(args));
    }
    if (value.filter((item) => item.adresse.rue.toLowerCase().includes(args)) != '') {
      return value.filter((item) => item.adresse.rue.toLowerCase().includes(args));
    }
    if (value.filter((item) => item.adresse.n.toLowerCase().includes(args)) != '') {
      return value.filter((item) => item.adresse.n.toLowerCase().includes(args));
    }
    if (value.filter((item) => item.adresse.codePostal.toLowerCase().includes(args)) != '') {
      return value.filter((item) => item.adresse.codePostal.toLowerCase().includes(args));
    }
    if (value.filter((item) => item.datec.toLowerCase().includes(args)) != '') {
      return value.filter((item) => item.datec.toLowerCase().includes(args));
    }
  }
}
