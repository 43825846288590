<app-navbar></app-navbar>
<br>
<div id="content-nav-page" class="row" *ngIf="fournisseur && utilisateur">
    <div class=" col-3 titre-menu">
      <a routerLink="/details/fournisseur/factures/details/{{fournisseur.id}}" class=" icon-f-left"></a>
      <b class="title-haut">{{fournisseur.denomination}}<img class="ml-2" src="assets/images/show-a.png"></b>
    </div>
    <div class="col-9 sous-menu">
      <b class="sous-menu-active1" *ngIf="utilisateur.role === 'Super admin' || utilisateur.role === 'Administration'|| utilisateur.role === 'Admin' || utilisateur.role === 'Utilisateur-fournisseur'" [routerLink]="['/fournisseur']">FOURNISSEUR</b>
      
    </div>
  </div>
  <div class="row" *ngIf="fournisseur">
    <h3 align="center" style=margin-left:25%> Factures {{fournisseur.denomination}} de l'année <span *ngIf="params">{{params.year}}</span></h3>
    <a *ngIf="utilisateur && utilisateur.role == 'Super admin'" (click)="toFileXLS()" class="btn-connexion" style="border: 0px;color: white;padding: 10px;background-color: #067302;text-transform: uppercase;border-radius: 8px;text-decoration: none;margin-top: 20PX;cursor: pointer;position: relative;right: -200px;top: -25px;">Exporter<img class="ml-1" src="assets/images/excel.png"></a>
    <div class="col-lg-12 col-md-6 right-content-scrolbar">
      <div class="card p-3">
        <div class="row ligne-table2  d-flex justify-content-center table-padding">
          <table class="table-responsive" style="text-align: center;">
            <tr>
              <th class="left-padding">Mois</th>
              <th class="left-padding">Nombre des factures</th>
              <th class="left-padding">Somme prix Net HT</th>
              <th class="left-padding">Somme montant TVA</th>
              <th class="left-padding">Somme de prix net TTC</th>
            </tr>
            <tr class="text-center" *ngFor="let commande of commandes| paginate: { itemsPerPage: 10, currentPage: p }">
              <td class="left-padding" [routerLink]="['/details/fournisseur/factures/details', fournisseur.id,params.year,commande.yeardateFacture]">{{commande.yeardateFacture}}</td>
              <td class="right-padding" [routerLink]="['/details/fournisseur/factures/details', fournisseur.id,params.year,commande.yeardateFacture]">{{commande.number | number: '1.2-2'| replace:',':' ' | replace:'.':',' | replace:',00':' '}}</td>
              <td class="right-padding" [routerLink]="['/details/fournisseur/factures/details', fournisseur.id,params.year,commande.yeardateFacture]">{{commande.totalSommePrixNet| number: '1.2-2'| replace:',':' ' | replace:'.':','}}</td>
              <td class="right-padding" [routerLink]="['/details/fournisseur/factures/details', fournisseur.id,params.year,commande.yeardateFacture]">{{commande.totalmontantTVA| number: '1.2-2'| replace:',':' ' | replace:'.':','}}</td>
              <td class="right-padding" [routerLink]="['/details/fournisseur/factures/details', fournisseur.id,params.year,commande.yeardateFacture]">{{commande.totalPrix| number: '1.2-2'| replace:',':' ' | replace:'.':','}}</td>
            </tr>
          </table>
          <pagination-controls previousLabel="Précédent" nextLabel="Suivant" (pageChange)="p = $event" #api></pagination-controls>
        </div>
        <ng-template #customLoadingTemplate></ng-template>
        <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading>
      </div>
    </div>
  </div>
  