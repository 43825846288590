import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
const headers = new HttpHeaders({Authorization: 'Basic ' + btoa('UserkrendelAdminnew:krl2021!!??**SoC24-415fr-a410-ttx9J8NbPecEL6k4BkAXvGE3t8f3nuEZmXa5RNJBAdrnh6XqB2')})
.set('Content-Type', 'application/json')
.set('Accept', 'application/json');
headers.append('Access-Control-Allow-Origin', '*');
import 'rxjs/add/operator/map';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  
  constructor(private http: HttpClient) { }


  getAllRole() {
    return this.http.get<any>(environment.host+"/api/role/all",{headers: headers});
  }


deleteById(id : string){
  return this.http.post<any>(environment.host+"/api/role/delete",id,{headers: headers});
}

add(data:any){

  return this.http.post<any>(environment.host+"/api/role/add",data,{headers: headers});
}

update(data:any){

  return this.http.post<any>(environment.host+"/api/role/update",data,{headers: headers});
}
getRoleById(id:string){
  return this.http.get<any>(environment.host+"/api/role/getbyid?id="+id,{headers: headers});
}

getNumberOfRole(){
  return this.http.get<any>(environment.host+"/api/role/number",{headers: headers});
}

}
