<app-navbar></app-navbar>
<br>
<div id="content-nav-page" class="row">
    <div class=" col-3 titre-menu">
      <b style="margin-right: 10%" class="title-haut">COMMANDE</b>
      <img src="assets/images/commande.png" [routerLink]="['/ajoutCommande']" *ngIf="hasRoleAdd" title="Passer une commande" >
    </div>
    <div class="col-9 sous-menu" *ngIf="utilisateur">
      <b class="sous-menu-active2" [routerLink]="['/dossier']" *ngIf="hasRoledossier" >DOSSIER</b>
      <b class="sous-menu-active1 ml-4" [routerLink]="['/commande']" style="cursor: pointer">COMMANDE</b>
    </div>
  </div>
  <div id="content-page" class="containe-fluid">
  
    <div class="row  ">
      <div class="col-xl-4  col-lg-10 col-md-10 col-sm-10 col-10 search">
        <input class="search-bar" type="search" placeholder="Rechercher.." [(ngModel)]="filterCommande" name="filterCommande" (keyup)="valuechange($event)">
      </div>
      <div class="col-xl-1 col-lg-1 col-md-2 col-sm-2 col-2">
        <select class="select-input-nombre" name="numberData" [(ngModel)]="numberData">
          <option>5</option>
          <option selected>10</option>
          <option>25</option>
          <option>50</option>
        </select>
      </div>
      <div class="col-xl-1 col-lg-3 col-md-2 col-sm-2 col-5">
    <div class="row mt-3">
      <div class="col">
        <div class="row">
          <div class="col-2"><img src="assets/images/statut-br.png" style="height: 20px"></div>
          <div class="col-2">
            <p style="font-size: 13px">BROUILLON</p>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <div class="col-2"><img src="assets/images/statut-valide.png" style="height: 20px"></div>
          <div class="col-2">
            <p  style="font-size: 13px">VALIDÉ</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-1 col-lg-2 col-md-2 col-sm-2  col-5">
    <div class="row mt-3">
      <div class="col ml-1">
        <div class="row">
          <div class="col-2"><img src="assets/images/partielLivre.png" style="height: 25px"></div>
          <div class="col-2">
            <p  style="font-size: 13px">PARTIELLEMENT LIVRÉE</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-1 col-lg-2 col-md-2 col-sm-2  col-5">
    <div class="row mt-3">
      <div class="col">
        <div class="row ml-4">
          <div class="col-2"><img src="assets/images/soldee.png" style="height: 25px"></div>
          <div class="col-2">
            <p  style="font-size: 13px">SOLDÉE</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Annulée -->
  <!--<div class="col-xl-1 col-lg-2 col-md-2 col-sm-2  col-5">
    <div class="row mt-3">
      <div class="col">
        <div class="row ml-3">
          <div class="col-2"><img src="assets/images/canceled.png" style="height: 25px"></div>
          <div class="col-2">
            <p  style="font-size: 13px">ANNULÉE</p>
          </div>
        </div>
      </div>
    </div>
  </div>-->
      <div class=" col d-flex justify-content-end">
        <div class="col-auto nbr-dossier nbre_commande">
          <table>
            <tr>
              <td>
                <b>Commandes</b>
              </td>
            </tr>
            <tr>
              <td>
                <b class="sous-nbr-dossier d-flex justify-content-center" *ngIf="lenghtData">{{lenghtData}}</b>
              </td>
            </tr>
          </table>
        </div>
  
        <div class="col-auto btn-devis ">
          <a *ngIf="utilisateur && utilisateur.role == 'Super admin'" (click)="toFileXLS()" class="btn-connexion">Exporter<img class="ml-1" src="assets/images/excel.png"></a>
        </div>
      </div>
    </div>
    <!-- tableau -->
    <ng-template #customLoadingTemplate></ng-template>
    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading>
  
    <div class="row" style="overflow-x:auto;">
      <table class="table table-striped table-hover">
        <th class="left" id="firstThTable">NUMÉRO DE COMMANDE</th>
        <th class="left">NUMÉRO DE COMMANDE GPMI</th>
        <th class="left">FOURNISSEUR</th>
        <th class="left">DATE COMMANDE</th>
        <th class="left">DATE DE LIVRAISON</th>
        <th class="right" >PRIX NET TOTAL HT €</th>
        <th class="right" >MONTANT DE LA TVA €</th>
        <th class="right" >PRIX NET TOTAL TTC €</th>
        <th class="text-center">STATUT</th>
        <th class="text-center " *ngIf="utilisateur && utilisateur.role != 'Responsable finance'">RÉGLAGES</th>
        <tr class="text-center" *ngFor="let commande of commandes | paginate: { itemsPerPage: numberData, currentPage: p } ">
          <td class="left" [routerLink]="['/details/commande', commande.id]">{{commande.numeroCommande}}</td>
          <td class="left" [routerLink]="['/details/commande', commande.id]">{{commande.numeroCommandeGPMI}}</td>
          <td class="left" [routerLink]="['/details/commande', commande.id]">{{commande.denomination}}</td>
          <td class="left" [routerLink]="['/details/commande', commande.id]"><span *ngIf="commande.dateCommande">{{commande.dateCommande | date : 'dd/MM/yyyy'}}</span></td>
          <td class="left" [routerLink]="['/details/commande', commande.id]"><span *ngIf="commande.dateLivraison">{{commande.dateLivraison | date : 'dd/MM/yyyy'}}</span></td>
          <td class="right" [routerLink]="['/details/commande', commande.id]" ><span *ngIf="commande.sommePrixNet && commande.sommePrixNet != 'NaN'">{{commande.sommePrixNet | number: '1.2-2'| replace:',':' ' | replace:'.':','}}</span></td>
          <td class="right" [routerLink]="['/details/commande', commande.id]" ><span *ngIf="commande.montantTVA && commande.montantTVA != 'NaN'">{{commande.montantTVA | number: '1.2-2'| replace:',':' ' | replace:'.':','}}</span></td>
          <td class="right" [routerLink]="['/details/commande', commande.id]" ><span *ngIf="commande.sommePrixNetTTC && commande.sommePrixNetTTC != 'NaN'">{{commande.sommePrixNetTTC| number: '1.2-2'| replace:',':' ' | replace:'.':','}}</span></td>
          <td [routerLink]="['/details/commande', commande.id]">
            <a href="#" data-toggle="modal" data-target="#myModal">
              <img *ngIf="commande.etat==='Brouillon'" src="assets/images/statut-br.png"> <img *ngIf="commande.etat==='Validé'" src="assets/images/statut-valide.png">
              <img *ngIf="commande.etat==='Soldée'" style="height: 30px" src="assets/images/soldee.png"> <img *ngIf="commande.etat==='Partiellement livrée'" src="assets/images/partielLivre.png" style="height: 30px">
  
            </a>
          </td>
          <td *ngIf="utilisateur && utilisateur.role != 'Responsable finance'">
            <div class="dropdown">
              <button class="btn dropdown-toggle btn-options" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                OPTIONS
              </button>
              <div class="dropdown-menu reglageMenu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item option" [routerLink]="['/details/commande', commande.id]">
                  <div class="options-show-icon"></div>
                  <div class="option-text"> Afficher</div>
                </a>
                <a class="dropdown-item option" title="Date" (click)="validecommande(commande)" title="Valider la commande" *ngIf="commande.etat === 'Brouillon' && hasRoleUpdate">
                  <div class="options-modif-icon"></div>
                  <div class="option-text"> Valider</div>
                </a>
  
                <a class="dropdown-item option" title="Date" (click)="initialise(commande);" data-toggle="modal" data-target="#myModal" title="Modifier la date de livraison" *ngIf="hasRoleUpdate">
                  <div class="options-date-icon"></div>
                  <div class="option-text"> Date</div>
                </a>
                <a class="dropdown-item option" title="Modifier" [routerLink]="['/update/commande', commande.id]"
                  *ngIf="hasRoleUpdate">
                  <div class="options-modif-icon" style="top:150px" *ngIf="commande.etat === 'Brouillon' && hasRoleUpdate"></div>
                  <div class="options-modif-icon" style="top:105px" *ngIf="commande.etat != 'Brouillon' || hasRoleUpdate"></div>
                  <div class="option-text"> Modifier</div>
                </a>
                <a class="dropdown-item" title="Supprimer" *ngIf="hasRoleDelete" (click)="removecommande(commande)">
                  <div class="options-supp-icon" style="top:195px" *ngIf="commande.etat === 'Brouillon' && hasRoleDelete"></div>
                  <div class="options-supp-icon"  *ngIf="commande.etat != 'Brouillon' || hasRoleDelete"></div>
                  <div class="option-text"> Supprimer</div>
                </a>
              </div>
            </div>
          </td>
          <!-- --------------------------modal scan ----------------------- -->
  
          <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModal" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 500px">
              <div class="modal-content modal-content-scan" style="width: 200%; top: -70px">
                <div class="modal-header d-flex justify-content-center">
                  <h3 class="titre-modal-statut">
                    MODIFIER LA DATE DE LIVRAISON
                  </h3>
                </div>
                <div class="modal-body">
  
                  <div class="row" style="margin-top: 25px;" *ngIf="mycommandeselected">
                    <input type="date" class="font-weight-bold" name="dateLivraison" [min]="getDateCommande(mycommandeselected)"  [max]="getBigDate()" [(ngModel)]="mycommandeselected.dateLivraison" value="" style="width: 75%;margin-left: 10%;">
                    <p style="color:red" *ngIf="dateLivraisonError">Champ invalide</p>
                    <p style="color:red" *ngIf="errorDate">Vérifier la date de livraison</p>
                  </div>
                  <div class="modal-footer d-flex justify-content-center" *ngIf="mycommandeselected">
                    <table>
                      <tr>
                        <td style="padding-right: 30px;background: white;">
                          <button class="btn-valider" style="width: 200px;height: 50px;" data-dismiss="modal" (click)="updateDateLivraison(mycommandeselected)">Modifier <img src="assets/images/save.png"></button>
                        </td>
                        <td style="padding-right: 30px;background: white;">
                          <button class="btn-annule" style="width: 200px;height: 50px;" data-dismiss="modal">Annuler <img src="assets/images/btn-annule.png"> </button>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </tr>
      </table>
      <div class="row">
        <div class="col col-xs-6">
          <pagination-controls previousLabel="Précédent" nextLabel="Suivant" (pageChange)="p = $event" #api></pagination-controls>
        </div>
      </div>
  
    </div>
  
  </div>
  
  <br><br><br><br><br><br>
  <!-- end  liste des utilisateurs -->
  
  <div class="search-results" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50" (scrolled)="onScroll()"></div>
  