import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ClientGuard } from 'src/app/guards/client.guard';
import { AgenceVoyageComponent } from './agence-voyage/agence-voyage.component';
import { AjoutAgenceComponent } from './ajout-agence/ajout-agence.component';
import { DetailsAgenceComponent } from './details-agence/details-agence.component';
import { UpdateAgenceComponent } from './update-agence/update-agence.component';



const routes: Routes = [
   { path: 'agence-voyage', component: AgenceVoyageComponent,canActivate: [ClientGuard]},
   { path: 'ajoutAgence', component: AjoutAgenceComponent,canActivate: [ClientGuard]},
   { path: 'agence-voyage/details/:id', component: UpdateAgenceComponent,canActivate: [ClientGuard]},
   { path: 'details/agence-voyage/:id', component: DetailsAgenceComponent,canActivate: [ClientGuard]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AgenceVoyageRoutingModule { }
