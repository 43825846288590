import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
const headers = new HttpHeaders({Authorization: 'Basic ' + btoa('UserkrendelAdminnew:krl2021!!??**SoC24-415fr-a410-ttx9J8NbPecEL6k4BkAXvGE3t8f3nuEZmXa5RNJBAdrnh6XqB2')})
.set('Content-Type', 'application/json')
.set('Accept', 'application/json');
headers.append('Access-Control-Allow-Origin', '*');


@Injectable({
  providedIn: 'root'
})
export class InventaireService {


  constructor(private http: HttpClient) {
  }

  saveAllData(client) {
    return this.http.post(environment.host + '/inventaires/addall', JSON.stringify(client), {headers: headers})
  }
  getAllData(){
    return new Promise(resolve => {
      this.http.get(environment.host + '/inventaires', {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }

  addinventaireActivation(inventaire) {
    return this.http.post(environment.host + '/inventaireactivation/addOne', JSON.stringify(inventaire), { headers: headers })
  }

  addinventaireconfig(inventaire) {
    return this.http.post(environment.host + '/inventaireconfig/addOne', JSON.stringify(inventaire), { headers: headers })
  }

  editinventaireconfig(inventaireconfig) {
    return this.http.put(environment.host + '/inventaireconfig/' + inventaireconfig.id, JSON.stringify(inventaireconfig), { headers: headers })
  }
  getAllDataConfig(){
    return new Promise(resolve => {
      this.http.get(environment.host + '/inventaireconfig', {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getAllDataConfigActivation(){
    return new Promise(resolve => {
      this.http.get(environment.host + '/inventaireactivation', {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getAllDataByRef(){
    return new Promise(resolve => {
      this.http.get(environment.host + '/inventaires/getallinventairesByref', {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getAllDataByRefByYear(year){
    return new Promise(resolve => {
      this.http.get(environment.host + '/inventaires/getallinventairesByrefByYear/'+year, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }


  getDataBydate(year){
    return new Promise(resolve => {
      this.http.get(environment.host + '/inventaires/getallArticlesByrefByYear/'+year, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  deleteAllData(){
    return new Promise(resolve => {
      this.http.delete(environment.host + '/inventaires/deleteall', {headers: headers} ).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  deleteArticleById(id){
    return new Promise(resolve => {
      this.http.delete(environment.host + '/inventaires/' + id,{headers: headers} ).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getArticleById(id){
    return this.http.get(environment.host + '/inventaires/'+id,{headers: headers})
  }
  getArticleByRefArt(refArt){
    return new Promise(resolve => {
      this.http.get(environment.host + '/inventaires/byRef/'+refArt, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getArticleByRefArtAndDate(refArt,etage,date){
    return new Promise(resolve => {
      this.http.get(environment.host + '/inventaires/byRef/AndDate/'+etage+'/'+date+'/ref/'+refArt+'/art', {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getAllFournisseurofArticles(){
    return new Promise(resolve => {
      this.http.get(environment.host + '/inventaires/groupedBy/fournisseurId', {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getAllFournisseurofArticlesWithYear(id){
    return new Promise(resolve => {
      this.http.get(environment.host + '/inventaires/groupedBy/fournisseurId/and/year/'+id, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }

  getAllDataByDateByFournisseur(id,date){
    return new Promise(resolve => {
      this.http.get(environment.host + '/inventaires/getByDateFournisseur/'+id+'/'+date, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getAllDataByDate(date){
    return new Promise(resolve => {
      this.http.get(environment.host + '/inventaires/getByDate/'+date, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }

  getinventaireactivationByDate(date){
    return new Promise(resolve => {
      this.http.get(environment.host + '/inventaireactivation/getByDate/'+date, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  editInventairerActivationById(inventaire){
    return this.http.put(environment.host + '/inventaireactivation/' + inventaire.id, JSON.stringify(inventaire), { headers: headers })
  }
  getAllDataByDateAndFilter(date){
    return new Promise(resolve => {
      this.http.get(environment.host + '/inventaires/filtre/getByDate/'+date, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  filterInventaireByDatec(){
    return new Promise(resolve => {
      this.http.get(environment.host + '/inventaires/group/inventaire', {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  filterInventaireBySingleDatec(year){
    return new Promise(resolve => {
      this.http.get(environment.host + '/inventaires/group/inventaire/singleDate/'+year, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  editInventairerById(inventaire){
    return this.http.put(environment.host + '/inventaires/' + inventaire.id, JSON.stringify(inventaire), { headers: headers })
  }
  addInventairerDatesMAJ(inventaire){
    return this.http.post(environment.host + '/inventairedatesmaj/addOne', JSON.stringify(inventaire), { headers: headers })
  }
  getAllDatemaj(){
    return new Promise(resolve => {
      this.http.get(environment.host + '/inventairedatesmaj', {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
}
