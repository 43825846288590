export const codesSchneider = [
{"refFamille": "1J0000", "codeFamille": "100", "tauxRbase": "198", "tauxVente": "198","libelle":"SCH 1J","coefFamille":"0,198"},
{"refFamille": "1K0000", "codeFamille": "101", "tauxRbase": "400", "tauxVente": "400","libelle":"SCH 1K","coefFamille":"0,4"},
{"refFamille": "1X3100", "codeFamille": "10000", "tauxRbase": "1000", "tauxVente": "330","libelle":"SCH SANS CONDITION","coefFamille":"1"},
{"refFamille": "1X3700", "codeFamille": "10000", "tauxRbase": "1000", "tauxVente": "351","libelle":"SCH SANS CONDITION","coefFamille":"1"},
{"refFamille": "1Y0000", "codeFamille": "102", "tauxRbase": "380", "tauxVente": "380","libelle":"SCH 1Y","coefFamille":"0,38"},
{"refFamille": "3A1100", "codeFamille": "300", "tauxRbase": "290", "tauxVente": "290","libelle":"SCH 3A11 TAR BLEU","coefFamille":"0,29"},
{"refFamille": "3A2200", "codeFamille": "301", "tauxRbase": "290", "tauxVente": "290","libelle":"SCH 3A22 R9","coefFamille":"0,29"},
{"refFamille": "3A2400", "codeFamille": "302", "tauxRbase": "290", "tauxVente": "290","libelle":"SCH 3A24 R9","coefFamille":"0,29"},
{"refFamille": "3A2500", "codeFamille": "303", "tauxRbase": "290", "tauxVente": "290","libelle":"SCH 3A25 R9","coefFamille":"0,29"},
{"refFamille": "3A2900", "codeFamille": "304", "tauxRbase": "290", "tauxVente": "290","libelle":"SCH 3A29 FUS","coefFamille":"0,29"},
{"refFamille": "3A3100", "codeFamille": "305", "tauxRbase": "290", "tauxVente": "290","libelle":"SCH 3A31 DISJ DIFF","coefFamille":"0,29"},
{"refFamille": "3A3200", "codeFamille": "306", "tauxRbase": "290", "tauxVente": "290","libelle":"SCH 3A32 INT DIFF","coefFamille":"0,29"},
{"refFamille": "3A3300", "codeFamille": "307", "tauxRbase": "290", "tauxVente": "290","libelle":"SCH 3A33 DET ARC","coefFamille":"0,29"},
{"refFamille": "3A4100", "codeFamille": "308", "tauxRbase": "290", "tauxVente": "290","libelle":"SCH 3A41 CDE TEL","coefFamille":"0,29"},
{"refFamille": "3A5100", "codeFamille": "309", "tauxRbase": "290", "tauxVente": "290","libelle":"SCH 3A51 ALIM","coefFamille":"0,29"},
{"refFamille": "3A7100", "codeFamille": "310", "tauxRbase": "290", "tauxVente": "290","libelle":"SCH 3A71 FOUDRE","coefFamille":"0,29"},
{"refFamille": "3A8300", "codeFamille": "311", "tauxRbase": "250", "tauxVente": "250","libelle":"SCH 3A83 COF TEC","coefFamille":"0,25"},
{"refFamille": "3A8500", "codeFamille": "312", "tauxRbase": "250", "tauxVente": "250","libelle":"SCH 3A85 COF PTERT","coefFamille":"0,25"},
{"refFamille": "3B0000", "codeFamille": "313", "tauxRbase": "195", "tauxVente": "195","libelle":"SCH 3B TERTIAIRE","coefFamille":"0,18"},
{"refFamille": "3C0000", "codeFamille": "314", "tauxRbase": "195", "tauxVente": "195","libelle":"SCH 3C TD","coefFamille":"0,18"},
{"refFamille": "3D0000", "codeFamille": "315", "tauxRbase": "195", "tauxVente": "195","libelle":"SCH 3D TGBT","coefFamille":"0,18"},
{"refFamille": "3E0000", "codeFamille": "316", "tauxRbase": "231", "tauxVente": "231","libelle":"SCH 3E SAREL","coefFamille":"0,231"},
{"refFamille": "3J0000", "codeFamille": "317", "tauxRbase": "149", "tauxVente": "145","libelle":"SCH 3J MESURE","coefFamille":"0,15"},
{"refFamille": "3M0000", "codeFamille": "318", "tauxRbase": "330", "tauxVente": "330","libelle":"SCH 3M AFI","coefFamille":"0,3"},
{"refFamille": "3Q0000", "codeFamille": "319", "tauxRbase": "262", "tauxVente": "262","libelle":"SCH 3Q PC INDUS","coefFamille":"0,262"},
{"refFamille": "3R0000", "codeFamille": "320", "tauxRbase": "262", "tauxVente": "262","libelle":"SCH 3R TRANSFO I","coefFamille":"0,262"},
{"refFamille": "3V0000", "codeFamille": "321", "tauxRbase": "296", "tauxVente": "296","libelle":"SCH 3V RECTIPH.","coefFamille":"0,296"},
{"refFamille": "5A0000", "codeFamille": "500", "tauxRbase": "566", "tauxVente": "566","libelle":"SCH 5A CONTR MA.","coefFamille":"0,566"},
{"refFamille": "5B0000", "codeFamille": "501", "tauxRbase": "566", "tauxVente": "566","libelle":"SCH 5B AUTOMATE","coefFamille":"0,566"},
{"refFamille": "5D0000", "codeFamille": "502", "tauxRbase": "670", "tauxVente": "670","libelle":"SCH 5D AFFICH.","coefFamille":"0,67"},
{"refFamille": "5G0000", "codeFamille": "503", "tauxRbase": "327", "tauxVente": "327","libelle":"SCH 5G PROT CDE","coefFamille":"0,327"},
{"refFamille": "5H0000", "codeFamille": "504", "tauxRbase": "360", "tauxVente": "360","libelle":"SCH 5H VARIAT","coefFamille":"0,36"},
{"refFamille": "5L0000", "codeFamille": "505", "tauxRbase": "164", "tauxVente": "565","libelle":"SCH 5L CDE SIGNA","coefFamille":"0,164"},
{"refFamille": "5L1500", "codeFamille": "505", "tauxRbase": "164", "tauxVente": "365","libelle":"SCH 5L CDE SIGNA","coefFamille":"0,164"},
{"refFamille": "5L2200", "codeFamille": "505", "tauxRbase": "164", "tauxVente": "290","libelle":"SCH 5L CDE SIGNA","coefFamille":"0,164"},
{"refFamille": "5L2600", "codeFamille": "505", "tauxRbase": "164", "tauxVente": "365","libelle":"SCH 5L CDE SIGNA","coefFamille":"0,164"},
{"refFamille": "5L3100", "codeFamille": "505", "tauxRbase": "164", "tauxVente": "365","libelle":"SCH 5L CDE SIGNA","coefFamille":"0,164"},
{"refFamille": "5L4100", "codeFamille": "505", "tauxRbase": "164", "tauxVente": "365","libelle":"SCH 5L CDE SIGNA","coefFamille":"0,164"},
{"refFamille": "5Q0000", "codeFamille": "506", "tauxRbase": "331", "tauxVente": "331","libelle":"SCH 5Q REL TR AL","coefFamille":"0,331"},
{"refFamille": "5S0000", "codeFamille": "507", "tauxRbase": "380", "tauxVente": "380","libelle":"SCH 5S SECURITE","coefFamille":"0,38"},
{"refFamille": "5U0000", "codeFamille": "508", "tauxRbase": "380", "tauxVente": "380","libelle":"SCH 5U SENSORS","coefFamille":"0,38"},
{"refFamille": "5V3100", "codeFamille": "509", "tauxRbase": "380", "tauxVente": "380","libelle":"SCH 5V31 INDUCTIFS","coefFamille":"0,38"},
{"refFamille": "5V5100", "codeFamille": "510", "tauxRbase": "380", "tauxVente": "380","libelle":"SCH 5V51 PHOTOELEC","coefFamille":"0,38"},
{"refFamille": "5V7100", "codeFamille": "511", "tauxRbase": "380", "tauxVente": "380","libelle":"SCH 5V71 CODEURS","coefFamille":"0,38"},
{"refFamille": "5V8100", "codeFamille": "512", "tauxRbase": "380", "tauxVente": "380","libelle":"SCH 5V81 SECURITE","coefFamille":"0,38"},
{"refFamille": "5W7170", "codeFamille": "513", "tauxRbase": "380", "tauxVente": "380","libelle":"SCH 5W717 XU","coefFamille":"0,38"},
{"refFamille": "5X1200", "codeFamille": "10000", "tauxRbase": "1000", "tauxVente": "500","libelle":"SCH SANS CONDITION","coefFamille":"1"}
]
