import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InventaireRoutingModule } from './inventaire-routing.module';
import { InventaireComponent } from './inventaire/inventaire.component';
import { AjoutInventaireComponent } from './ajout-inventaire/ajout-inventaire.component';
import { AjoutInventaireManuelComponent } from './ajout-inventaire-manuel/ajout-inventaire-manuel.component';
import { AjoutInventaireBycatalogueYearComponent } from './ajout-inventaire-bycatalogue-year/ajout-inventaire-bycatalogue-year.component';
import { DetailsInventaireComponent } from './details-inventaire/details-inventaire.component';
import { DecimalFormattModule } from '../shared/decimal-formatt/decimal-formatt.module';
import { NavbarModule } from '../shared/navbar/navbar.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ControleMessagesModule } from '../shared/controle-messages/controle-messages.module';
import { ControleMessagesRoutingModule } from '../shared/controle-messages/controle-messages-routing.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxLoadingModule } from 'ngx-loading';
import { InventaireByFournisseurComponent } from './inventaire-by-fournisseur/inventaire-by-fournisseur.component';
import { SearchInventairePipe } from 'src/app/search/filter.pipe';
import { InventaireByYearComponent } from './inventaire-by-year/inventaire-by-year.component';
import { InventaireByYearWithoutFourComponent } from './inventaire-by-year-without-four/inventaire-by-year-without-four.component';
import { UpdateInventaireComponent } from './update-inventaire/update-inventaire.component';
import { InventaireGuard } from 'src/app/guards/inventaire.guard';
@NgModule({
  declarations: [InventaireComponent, AjoutInventaireComponent,SearchInventairePipe, AjoutInventaireManuelComponent, AjoutInventaireBycatalogueYearComponent, DetailsInventaireComponent, InventaireByFournisseurComponent, InventaireByYearComponent, InventaireByYearWithoutFourComponent, UpdateInventaireComponent],
  imports: [
    CommonModule,
    InventaireRoutingModule,
    NavbarModule,
    DecimalFormattModule,
    FormsModule, ReactiveFormsModule,
    ControleMessagesModule,
    ControleMessagesRoutingModule,
    NgxPaginationModule,
    NgbModule,
    InfiniteScrollModule,
    NgxLoadingModule
  ],
  providers:[InventaireGuard]
})
export class InventaireModule { }
