<app-navbar></app-navbar>
<br>
<div id="content-nav-page" class="row" *ngIf="fournisseur && utilisateur && params">
    <div class=" col-3 titre-menu">
      <a routerLink="/details/fournisseur/factures/details/{{fournisseur.id}}/{{params.year}}" class=" icon-f-left"></a>
      <b class="title-haut">{{fournisseur.denomination}}<img class="ml-2" src="assets/images/show-a.png"></b>
    </div>
    <div class="col-9 sous-menu">
      <b class="sous-menu-active1" *ngIf="utilisateur.role === 'Super admin' || utilisateur.role === 'Administration'|| utilisateur.role === 'Admin' || utilisateur.role === 'Utilisateur-fournisseur'" [routerLink]="['/fournisseur']">FOURNISSEUR</b>
      
    </div>
  </div>
  <div class="row" *ngIf="fournisseur">
  
    <div class="col-lg-12 col-md-6 right-content-scrolbar">
      <div class="card p-3">
        <div class="row">
          <div class="col-auto btn-devis ">
            <a (click)="toFileXLS()" *ngIf="utilisateur && utilisateur.role == 'Super admin'" style="border: 0px;color: white;padding: 10px;background-color: #067302;text-transform: uppercase;border-radius: 8px;text-decoration: none;margin-top: 20PX;cursor: pointer;position: relative;top: 5px;left: 1260px;" class="btn-connexion">Exporter<img class="ml-1" src="assets/images/excel.png"></a>
          </div>
        </div>
        <div class="row ligne-table2  d-flex justify-content-center table-padding">
          <table class="table-responsive" style="text-align: center;">
            <tr>
              <th class="left-padding">Numéro de commande GPMI</th>
              <th class="left-padding">Numéro de facture</th>
              <th class="left-padding">Date de facture</th>
              <th class="left-padding">Prix net total HT</th>
              <th class="left-padding">Montant de la TVA</th>
              <th class="left-padding">Prix net total TTC</th>
            </tr>
            <tr class="text-center" *ngFor="let commande of commandes | paginate: { itemsPerPage: 25, currentPage: p } ">
              <td class="left-padding">{{commande.numeroCommandeGPMI}}</td>
              <td class="left-padding"><span *ngIf="commande.factures">{{commande.factures.numFacture}}</span></td>
              <td class="left-padding"><span *ngIf="commande.factures">{{commande.factures.dateFacture | date : 'dd/MM/yyyy' }}</span></td>
              <td class="right-padding"><span *ngIf="commande.factures.sommePrixNet && commande.factures.sommePrixNet != 'NaN'">{{commande.factures.sommePrixNet | number: '1.2-2'| replace:',':' ' | replace:'.':','}}</span></td>
              <td class="right-padding"><span *ngIf="commande.factures.montantTVA && commande.factures.montantTVA != 'NaN'">{{commande.factures.montantTVA | number: '1.2-2'| replace:',':' ' | replace:'.':','}}</span></td>
              <td class="right-padding"><span *ngIf="commande.factures.sommePrixNetTTC && commande.factures.sommePrixNetTTC != 'NaN'">{{commande.factures.sommePrixNetTTC| number: '1.2-2'| replace:',':' ' | replace:'.':','}}</span>
              </td>
            </tr>
          </table>
          <pagination-controls previousLabel="Précédent" nextLabel="Suivant" (pageChange)="p = $event" #api></pagination-controls>
        </div>
        <ng-template #customLoadingTemplate></ng-template>
        <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading>
      </div>
    </div>
  </div>
  