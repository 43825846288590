import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommandeGuard } from 'src/app/guards/commande.guard';
import { AjoutCommandeComponent } from './ajout-commande/ajout-commande.component';
import { CommandeComponent } from './commande/commande.component';
import { DetailsCommandeComponent } from './details-commande/details-commande.component';
import { UpdateCommandeComponent } from './update-commande/update-commande.component';


const routes: Routes = [
  { path: 'commande', component: CommandeComponent, canActivate: [CommandeGuard]},
  { path: 'ajoutCommande', component: AjoutCommandeComponent, canActivate: [CommandeGuard]},
  { path: 'details/commande/:id', component: DetailsCommandeComponent, canActivate: [CommandeGuard]},
  { path: 'update/commande/:id', component: UpdateCommandeComponent, canActivate: [CommandeGuard]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CommandeRoutingModule { }
