<nav class="navbar navbar-expand-lg navbar-light bg-light shadow-sm">
    <a class="navbar-brand" [routerLink]="['/accueil']" >
      <div class="logo" *ngIf="isvoguel"></div>
      <div class="logocara" *ngIf="!isvoguel"></div>
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
  
    <div class="collapse navbar-collapse" id="navbarSupportedContent" *ngIf="utilisateur">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item" *ngIf="hasRoleUtilisateur" >
          <a class="nav-link" [routerLink]="['/utilisateur']" routerLinkActive="active-link" >UTILISATEURS</a>
        </li>
        <li class="nav-item" *ngIf="hasRoleClient">
          <a class="nav-link " [routerLink]="['/client']" >CLIENTS</a>
        </li>
        <li class="nav-item" *ngIf="hasRoleClient">
          <a class="nav-link " [routerLink]="['/agence-voyage']" >AGENCES DE VOYAGE</a>
        </li>
        <li class="nav-item" *ngIf="hasRoleFournisseur">
          <a class="nav-link" [routerLink]="['/fournisseur']">FOURNISSEURS</a>
        </li>
        <li class="nav-item  "*ngIf="hasRoleDossier">
          <a class="nav-link" [routerLink]="['/dossier']" >DOSSIERS</a>
        </li>
        <li class="nav-item  "
          *ngIf="hasRoleCatalgoue">
          <a class="nav-link" [routerLink]="['/catalogue']" >CATALOGUES</a>
        </li>
        <li class="nav-item  "
          *ngIf="hasRoleInventaire">
          <a class="nav-link" [routerLink]="['/inventaire']" >INVENTAIRES</a>
        </li>
        <li class="nav-item  " *ngIf="hasRoleRH" >
          <a class="nav-link" [routerLink]="['/rh']" >SALARIÈS</a>
        </li>
        <li class="nav-item" *ngIf="hasRoleRole">
          <a class="nav-link" [routerLink]="['/role']" >RÔLES</a>
        </li>
      </ul>
      <ul class="navbar-nav mr-auto profile">
  
        <li id="profil" class="dropdown">
          <a class="btn dropdown-toggle btn-options-Profile" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="position: relative;top: 15px;">
            <p class="user-name2 " *ngIf="utilisateur">{{utilisateur.prenom}}</p>
  
          </a>
          <div class="dropdown-menu dropdown-menu2 reglageMenu" aria-labelledby="dropdownMenuButton" style="position: absolute;left: -40px;">
            <!--<a class="dropdown-item optionProf"  title="Profil" style="cursor: pointer;">
          <div class="option-text"> Profil</div>
        </a>-->
  
            <a class="dropdown-item optionProf" title="Déconnexion" style="cursor: pointer;">
              <div class="option-text" [routerLink]="['/logout']"> Déconnexion</div>
            </a>
  
          </div>
        </li>
  
      </ul>
    </div>
  
  </nav>
