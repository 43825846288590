import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommandeRoutingModule } from './commande-routing.module';
import { CommandeComponent } from './commande/commande.component';
import { AjoutCommandeComponent } from './ajout-commande/ajout-commande.component';
import { DetailsCommandeComponent } from './details-commande/details-commande.component';
import { UpdateCommandeComponent } from './update-commande/update-commande.component';
import { NavbarModule } from '../shared/navbar/navbar.module';
import { DecimalFormattModule } from '../shared/decimal-formatt/decimal-formatt.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ControleMessagesModule } from '../shared/controle-messages/controle-messages.module';
import { ControleMessagesRoutingModule } from '../shared/controle-messages/controle-messages-routing.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxLoadingModule } from 'ngx-loading';
import { NguiAutoCompleteModule } from '@ngui/auto-complete';
import { CommandeGuard } from 'src/app/guards/commande.guard';


@NgModule({
  declarations: [CommandeComponent, AjoutCommandeComponent, DetailsCommandeComponent, UpdateCommandeComponent],
  imports: [
    CommonModule,
    CommandeRoutingModule,
    NavbarModule,
    DecimalFormattModule,
    FormsModule, ReactiveFormsModule,
    ControleMessagesModule,
    ControleMessagesRoutingModule,
    NgxPaginationModule,
    NgbModule,
    InfiniteScrollModule,
    NgxLoadingModule,
    NguiAutoCompleteModule
  ],
  providers:[CommandeGuard]
})
export class CommandeModule { }
