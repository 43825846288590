<app-navbar></app-navbar>
<br>
<div id="content-nav-page" class="row" *ngIf="fournisseur && utilisateur && params">
    <div class=" col-3 titre-menu">
      <a routerLink="/details/fournisseur/{{fournisseur.id}}/{{params.year}}" class=" icon-f-left"></a>
      <b class="title-haut">{{fournisseur.denomination}}<img class="ml-2" src="assets/images/show-a.png"></b>
    </div>
    <div class="col-9 sous-menu">
      <b class="sous-menu-active1" *ngIf="utilisateur.role === 'Super admin' || utilisateur.role === 'Administration'|| utilisateur.role === 'Admin' || utilisateur.role === 'Utilisateur-fournisseur'" [routerLink]="['/fournisseur']">FOURNISSEUR</b>
      
    </div>
  </div>
  <div class="row" *ngIf="fournisseur">
    <div class="col-lg-12 col-md-6 right-content-scrolbar">
      <div class="card p-3">
        <div class="row ligne-table2  d-flex justify-content-center table-padding">
          <table class="table-responsive" style="text-align: center;">
            <tr>
              <th class="left-padding">Numéro de commande</th>
              <th class="left-padding">Numéro de commande GPMI</th>
              <th class="left-padding">Fournisseur</th>
              <th class="left-padding">Date de commande</th>
              <th class="left-padding">Date de livraison</th>
              <th class="left-padding">Prix net total HT</th>
              <th class="left-padding">Montant de la TVA</th>
              <th class="left-padding">Prix net total TTC</th>
              <th class="left-padding">Statut</th>
            </tr>
            <tr class="text-center" *ngFor="let commande of commandes | paginate: { itemsPerPage: 25, currentPage: p } ">
              <td class="left-padding" [routerLink]="['/details/commande', commande.id]">{{commande.numeroCommande}}</td>
              <td class="left-padding" [routerLink]="['/details/commande', commande.id]">{{commande.numeroCommandeGPMI}}</td>
              <td class="left-padding" [routerLink]="['/details/commande', commande.id]">{{commande.denomination}}</td>
              <td class="left-padding" [routerLink]="['/details/commande', commande.id]"><span *ngIf="commande.dateCommande">{{commande.dateCommande | date : 'dd/MM/yyyy' }}</span></td>
              <td class="left-padding" [routerLink]="['/details/commande', commande.id]"><span *ngIf="commande.dateLivraison">{{commande.dateLivraison | date : 'dd/MM/yyyy' }}</span></td>
              <td class="right-padding" [routerLink]="['/details/commande', commande.id]"><span *ngIf="commande.sommePrixNet && commande.sommePrixNet != 'NaN'">{{commande.sommePrixNet | number: '1.2-2'| replace:',':' ' | replace:'.':','}}</span></td>
              <td class="right-padding" [routerLink]="['/details/commande', commande.id]"><span *ngIf="commande.montantTVA && commande.montantTVA != 'NaN'">{{commande.montantTVA | number: '1.2-2'| replace:',':' ' | replace:'.':','}}</span></td>
              <td class="right-padding" [routerLink]="['/details/commande', commande.id]"><span *ngIf="commande.sommePrixNetTTC && commande.sommePrixNetTTC != 'NaN'">{{commande.sommePrixNetTTC| number: '1.2-2'| replace:',':' ' | replace:'.':','}}</span>
              </td>
              <td class="left-padding" [routerLink]="['/details/commande', commande.id]">{{commande.etat}}</td>
            </tr>
  
  
          </table>
          <pagination-controls previousLabel="Précédent" nextLabel="Suivant" (pageChange)="p = $event" #api></pagination-controls>
        </div>
        <ng-template #customLoadingTemplate></ng-template>
        <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading>
      </div>
    </div>
  </div>
  