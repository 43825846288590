<app-navbar></app-navbar>
<br>
<div id="content-nav-page" class="row">
    <div class=" col-3 titre-menu-ajoutDossier" *ngIf="dossier">
      <a [routerLink]="['/dossier']" class="icon-f-left"></a>
      <b>{{dossier.numero}}</b><a>
        <img class="mb-3 mt-2 ml-2" src="assets/images/modifier-a.png" alt="">
      </a>
    </div>
    <div class="col-9 sous-menu">
      <b class="sous-menu-active1"  [routerLink]="['/dossier']">DOSSIER</b>
      <b class="sous-menu-active2 ml-4" [routerLink]="['/commande']" *ngIf="utilisateur && utilisateur.role === 'Super admin' || utilisateur && utilisateur.role === 'Administration' || utilisateur && utilisateur.role === 'Admin' || utilisateur && utilisateur.role === 'Responsable commandes' || (utilisateur && utilisateur.role === 'Equipe Atelier' && utilisateur.sousRole === 'Responsable magasin') || utilisateur && utilisateur.role === 'Utilisateur-client'"
        style="cursor: pointer">COMMANDE</b>
        
    </div>
  </div>
  <div class="col d-flex justify-content-start">
    <b class="obligatoir-text">(*) Ce champ est obligatoire</b>
  </div>
  <div id="content-show-dossier" *ngIf="dossier">
    <form [formGroup]="formPreparationChiffrage" class="form-horizontal" role="form">
    <div class="row content-cols-show" style="width: 50%;margin-left: 20%;">
      <div class="col-lg-12 col-md-12">
        <select class="select-statut" formControlName="personne"  name="personne" [(ngModel)]="selectedPersonne" (change)="showPersonneDetails()">
          <option value="" disabled selected>PERSONNE VISÉE*</option>
          <option [ngValue]="dossier.personneVisee" *ngIf="showSelect" selected>{{dossier.personneVisee.nom}} {{dossier.personneVisee.prenom}}</option>
          <option *ngFor="let personneOfDepartement of personnesOfDepartement" [ngValue]="personneOfDepartement">{{personneOfDepartement.nom}} {{personneOfDepartement.prenom}}</option>
        </select>
        <control-messages [control]="formPreparationChiffrage.controls.personne"></control-messages>
        <div class="form-group col-md-12 col-sm-12 etablissement-info-affichage" *ngIf="selectedPersonne">
          <label for="prenom" class="titre-info-etablissement bold">Détails de la personne visée</label>
          <li style="list-style-type: none;"><span class="bold">Nom & Prénom</span> : {{selectedPersonne.nom}} {{selectedPersonne.prenom}}</li>
          <li style="list-style-type: none;"><span class="bold">Email</span> : {{selectedPersonne.mail}}</li>
          <li style="list-style-type: none;"><span class="bold">Téléphone</span> : {{selectedPersonne.tel}}</li>
          <br>
        </div>
      </div>
    </div>
    <div class=" col d-flex justify-content-center" style="padding-bottom: 1%">
      <div class="col-auto nbr-dossier ">
        <table>
          <tr>
            <td style="padding-right: 30px;">
              <button class="btn-annule" style="width: 200px;height: 50px;" [routerLink]="['/dossier']">Annuler <img src="assets/images/btn-annule.png"> </button>
  
            </td>
            <td style="padding-right: 30px;">
              <button class="btn-valider" style="width: 200px;height: 50px;" [disabled]="!formPreparationChiffrage.valid" (click)="onUpdateDossier(dossier)">Affecter <img src="assets/images/btn-valider.png"></button>
            </td>
          </tr>
        </table>
      </div>
  
    </div>
    </form>
  </div>
  