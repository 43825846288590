import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
 name: 'filter'
})
export class SearchInventairePipe implements PipeTransform {
    transform(value, args: string[]){
        if (!value) return value;
          if (value.filter((item) => item.datec.toLowerCase().includes(args)) != '') {
            return value.filter((item) => item.datec.toLowerCase().includes(args));}
            if (value.filter((item) => item.refArt.toLowerCase().includes(args)) != '') {
                return value.filter((item) => item.refArt.toLowerCase().includes(args));}
                
      }}