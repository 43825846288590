import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RHGuard } from 'src/app/guards/rh.guard';
import { AjoutSalarieComponent } from './ajout-salarie/ajout-salarie.component';
import { DetailsSalarieComponent } from './details-salarie/details-salarie.component';
import { RhComponent } from './rh/rh.component';
import { UpdateSalarieComponent } from './update-salarie/update-salarie.component';


const routes: Routes = [
  { path: 'rh', component: RhComponent, canActivate: [RHGuard]},
  { path: 'ajoutSalarie', component: AjoutSalarieComponent, canActivate: [RHGuard]},
  { path: 'salarie/details/:id', component: UpdateSalarieComponent, canActivate: [RHGuard]},
  { path: 'details/salarie/:id', component: DetailsSalarieComponent, canActivate: [RHGuard]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RhRoutingModule { }
