<app-navbar></app-navbar>
<br>
<div id="content-nav-page" class="row" *ngIf="commande">
    <div class=" col-3 titre-menu">
      <a routerLink="/commande" class=" icon-f-left"></a>
      <b class="title-haut">{{commande.numeroCommande}} <img class="ml-2" src="assets/images/modif-hover.png"></b>
    </div>
    <div class="col-9 sous-menu" *ngIf="utilisateur">
      <b class="sous-menu-active2" [routerLink]="['/dossier']" *ngIf="utilisateur.role === 'Super admin' || utilisateur.role === 'Admin' || utilisateur.role === 'Equipe chiffrage' || utilisateur.role === 'Administration' || (utilisateur.role === 'Equipe Atelier' && utilisateur.sousRole === 'Responsable magasin') || utilisateur.role === 'Chiffreur'">DOSSIER</b>
      <b class="sous-menu-active1 ml-4" [routerLink]="['/commande']" style="cursor: pointer">COMMANDE</b>
    </div>
  </div>
  
  <div class="card p-3" style="background-color: #f8f9fa!important " *ngIf="commande">
    <div class="col d-flex justify-content-start">
      <b class="obligatoir-text">(*) Ce champ est obligatoire</b>
    </div>
    <form [formGroup]="form" role="form">
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <div *ngIf="utilisateur && utilisateur.role === 'Super admin'">
          <select class="select-statut" formControlName="fournisseur" name="fournisseur" [(ngModel)]="selectedFounrisseur" (change)="myCallback($event)" style="width: 100%;background: #f8f9fa;position: relative;left: -11px;">
            <option [ngValue]="selectedFounrisseur">{{selectedFounrisseur.denomination}}</option>
            <option *ngFor="let fournisseur of fournisseurs" [ngValue]="fournisseur">{{fournisseur.denomination}}</option>
          </select>
          <control-messages [control]="form.controls.fournisseur"></control-messages>
        </div>
        <input class="input-btn-file" type="text"  style="color:green !important;" [(ngModel)]="commande.numeroCommandeGPMI" formControlName="numeroCommandeGPMI" id="numeroCommandeGPMI" name="numeroCommandeGPMI" placeholder="NUMÉRO DE COMMANDE GPMI*">
        <control-messages [control]="form.controls.numeroCommandeGPMI"></control-messages>
            <p style="color:red;" *ngIf="errorNumeroCommandeGPMI">Ce numéro existe déjà !</p>
            <p *ngIf="form.controls['numeroCommandeGPMI'].touched && form.controls['numeroCommandeGPMI'].hasError('maxlength')" style="color:red">
              25 caractères maximum
            </p>
        <input type="date"  placeholder="DATE DE COMMANDE*" style="color:green" [(ngModel)]="commande.dateCommande" [max]="getBigDate()" formControlName="dateCommande" id="dateCommande" name="dateCommande">
        <control-messages [control]="form.controls.dateCommande"></control-messages>
        <input type="date"  placeholder="DATE DE LIVRAISON*" style="color:green" [min]="getDateCommande()" [max]="getBigDate()" [(ngModel)]="commande.dateLivraison" formControlName="dateLivraison" id="dateLivraison" name="dateLivraison">
        <control-messages [control]="form.controls.dateLivraison"></control-messages>
            <p style="color:red" *ngIf="errorDate">Vérifier la date de livraison</p>
      </div>
      <div class="col-lg-6 col-md-12 fichier-commande">
        <div class="row d-flex justify-content-center titre-fichier-commande-modif mt-5"> <b> FOURNISSEUR </b>
        </div>
        <div class="row d-flex justify-content-center sous-titre-fichier-commande-modif"> {{commande.denomination}} </div>
  
        <div class="row d-flex justify-content-center titre-fichier-commande-modif"> <b> NUMÉRO DE LA
            COMMANDE</b></div>
        <div class="row d-flex justify-content-center sous-titre-fichier-commande-modif"> {{commande.numeroCommande}} </div>
  
        <div class="row d-flex justify-content-center titre-fichier-commande-modif"> <b> DATE DE COMMANDE </b>
        </div>
        <div class="row d-flex justify-content-center sous-titre-fichier-commande-modif"> {{commande.dateCommande | date : 'dd/MM/yyyy'}} </div>
      </div>
    </div>
    <div class="row">
      <div class="card-body ">
        <div class="row content-cols-show">
          <div class="col-lg-6 col-md-12 col-sm-12">
            <div id="drop_file_zone">
              <div id="drag_upload_file">
                <b>COMMANDE(S)</b>
                <p></p>
                <div class="btn-file">
                  <button class="btn-select-file" style="background-color: green;color: white;border: 2px solid white;height: 52px;">Choisir...</button>
                  <input type="file" style="left: -10px;top: -36px;opacity: 0;position: absolute;font-size: 90px;" (change)="selectFiles($event)" multiple accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"/>
                </div>
              </div>
            </div>
          <br><h5 *ngFor=" let file of commande.listfiles"> <strong>Fichier {{commande.listfiles.indexOf(file)+1}}</strong> <span><a href="{{file.photo}}" style="cursor: pointer;text-decoration: underline;" target="_blank"><span *ngIf="!file.name">Lien 1</span> <span *ngIf="file.name">{{file.name}}</span></a></span> <img src="assets/images/supp-cmd.png" title="Supprimer ce fichier" (click)="deleteFileCommande(file)" style="cursor: pointer;"></h5>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12">
            <div id="drop_file_zone">
              <div id="drag_upload_file">
                <b>FACTURE(S)</b>
                <p></p>
                <div class="btn-file">
                  <button class="btn-select-file" style="background-color: green;color: white;border: 2px solid white;height: 52px;">Choisir...</button>
                  <input type="file" style="left: -10px;top: -36px;opacity: 0;position: absolute;font-size: 90px;" (change)="selectFilesFactures($event)" multiple accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"/>
                </div>
              </div>
            </div>
            <br><h5 *ngFor=" let file of commande.listfilesFactures"> <strong>Fichier {{commande.listfilesFactures.indexOf(file)+1}}</strong> <span><a href="{{file.photo}}" style="cursor: pointer;text-decoration: underline;" target="_blank"><span *ngIf="!file.name">Lien 1</span> <span *ngIf="file.name">{{file.name}}</span></a></span>  <img src="assets/images/supp-cmd.png" title="Supprimer ce fichier" (click)="deleteFileFacture(file)" style="cursor: pointer;"></h5>
          </div>
  
        </div>
        <div class="row content-cols-show">
          <div class="col-lg-6 col-md-12 col-sm-12">
            <div id="drop_file_zone">
              <div id="drag_upload_file">
                <b>ACCUSÉ DE RÉCEPTION</b>
                <p></p>
                <div class="btn-file">
                  <button class="btn-select-file" style="background-color: green;color: white;border: 2px solid white;height: 52px;">Choisir...</button>
                  <input type="file" style="left: -10px;top: -36px;opacity: 0;position: absolute;font-size: 90px;" (change)="selectFilesAccuse($event)" multiple accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf" />
  
                </div>
              </div>
  
            </div>
          <br><h5 *ngFor=" let file of commande.listfilesAccuse"> <strong>Fichier {{commande.listfilesAccuse.indexOf(file)+1}}</strong> <span><a href="{{file.photo}}" style="cursor: pointer;text-decoration: underline;" target="_blank"><span *ngIf="!file.name">Lien 1</span> <span *ngIf="file.name">{{file.name}}</span></a></span> <img src="assets/images/supp-cmd.png" title="Supprimer ce fichier" (click)="deleteFileAccuseReception(file)" style="cursor: pointer;"></h5>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12">
            <div id="drop_file_zone">
              <div id="drag_upload_file">
                <b>BON(S) DE LIVRAISON</b>
                <p></p>
                <div class="btn-file">
                  <button class="btn-select-file" style="background-color: green;color: white;border: 2px solid white;height: 52px;">Choisir...</button>
                  <input type="file"  style="left: -10px;top: -36px;opacity: 0;position: absolute;font-size: 90px;" (change)="selectFilesBonLivraison($event)" multiple accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"/>
                </div>
              </div>
            </div>
          <br><h5 *ngFor=" let file of commande.listfilesBonLivraison"> <strong>Fichier {{commande.listfilesBonLivraison.indexOf(file)+1}}</strong> <span><a href="{{file.photo}}" style="cursor: pointer;text-decoration: underline;" target="_blank"><span *ngIf="!file.name">Lien 1</span> <span *ngIf="file.name">{{file.name}}</span></a></span> <img src="assets/images/supp-cmd.png" title="Supprimer ce fichier" (click)="deleteFileBL(file)" style="cursor: pointer;"></h5>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <br>
      <div class="col-lg-12 col-md-12 col-sm-10">
        <div class="col-lg-12 col-md-12 col-sm-12" style="margin-bottom: -2px;margin-top: -10px;" *ngIf="(utilisateur && utilisateur.role === 'Super admin') || (utilisateur && utilisateur.role === 'Administration') ||  (utilisateur && utilisateur.role === 'Responsable commandes')">
          <img src="assets/images/add-cmd.png" style="margin-left: 25px;cursor: pointer;" *ngIf="commande.factures && form.controls.factures.controls.length + commande.factures.length < 30" (click)="addFacture()" title="Ajouter une facture">
          <img src="assets/images/add-cmd.png" style="margin-left: 25px;cursor: pointer;" *ngIf="!commande.factures && form.controls.factures.controls.length < 30"  (click)="addFacture()" title="Ajouter une facture">
        </div>
  
  
        <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="(utilisateur && utilisateur.role === 'Super admin') || (utilisateur && utilisateur.role === 'Administration') || (utilisateur && utilisateur.role === 'Responsable commandes')" style="margin-bottom: -5px;">
          <div class="col-lg-12 col-md-12 col-sm-12" *ngFor="let facture of commande.factures; let j=index">
            <div class="col-lg-12 col-md-12 col-sm-10">
              <div class="col-lg-6 col-md-6 col-sm-10">
                <input type="text" class="font-weight-bold"    value="{{facture.numFacture}}" formControlName="numFacture" (keyup)="valuechangeNumFacture($event,j)"    placeholder="Numéro de facture" style="width: 105%;color:green">
                <img src="assets/images/supp-modal.png" title="Supprimer une facture" (click)="removeFactureCommande(facture)" class="delete-img" style="width:4%">
              </div>
              <div class="col-lg-6 col-md-6 col-sm-10">
                <input type="date" class="font-weight-bold" placeholder="Date de facture" [max]="getBigDate()" value="{{facture.dateFacture}}" formControlName="dateFacture" (keyup)="valuechangedateFacture($event,j)" (change)="valuechangedateFacture($event,j)"    [min]="getDateFacture()"   style="width: 105%;color:green">
              </div>
            </div>
          </div>
        </div>
  
        <div class="col-lg-12 col-md-12 col-sm-12" formArrayName="factures" *ngIf="(utilisateur && utilisateur.role === 'Super admin') || (utilisateur && utilisateur.role === 'Administration') || (utilisateur && utilisateur.role === 'Responsable commandes')" style="margin-bottom: -5px;">
          <div class="col-lg-12 col-md-12 col-sm-12" *ngFor="let facture of form.controls.factures.controls; let i=index">
            <div class="col-lg-12 col-md-12 col-sm-10" [formGroupName]="i">
              <div class="col-lg-6 col-md-6 col-sm-10">
                <input type="text" class="font-weight-bold" formControlName="numFacture" id="numFacture" name="numFacture"  placeholder="Numéro de facture" style="width: 105%;color:green" (change)="updateNumFacturee()" (keyup)="updateNumFacturee()">
                <img src="assets/images/supp-modal.png" title="Supprimer une facture" (click)="removeFacture(i)" *ngIf="form.controls.factures.controls.length > 1" class="delete-img" style="width:4%">
              </div>
              <div class="col-lg-6 col-md-6 col-sm-10">
                <input type="date" class="font-weight-bold" placeholder="Date de facture" [min]="getDateFacture()" [max]="getBigDate()" formControlName="dateFacture" id="dateFacture" name="dateFacture"
                  style="width: 105%;color:green">
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12" style="margin-top:-2%">
          <p style="color:red" *ngIf="errorDateFacture">Vérifier les dates de facture</p>
        </div>
      </div>
    </div>
    </form>
    <div class="row">
      <table class="table-commande table-responsive">
        <tr>
          <th>POSTE</th>
          <th>N° DOSSIER</th>
          <th>NOM DOSSIER</th>
          <th>CLIENT</th>
          <th>RÉFÉRENCE</th>
          <th>DÉSIGNATION</th>
          <th>PRIX TARIF HT</th>
          <th>PRIX NET HT</th>
          <th>COEF D'ACHAT</th>
          <th>QUANTITÉ </th>
          <th>PRIX NET HT TOTAL </th>
          <th>ACTIONS</th>
        </tr>
  
        <tr *ngFor="let field of commande.lignes; let i = index">
            <td>
              {{i + 1}}0
            </td>
              <td *ngIf="!this['demandeUpdate' + i]">
                {{field.numero}}
              </td>
              <td *ngIf="this['demandeUpdate' + i]">
  
                  <input *ngIf="this['demandeUpdate' + i]" type="text" [(ngModel)]="field.numero" name="numero" (click)="initiliaseDossier(field)" min-chars="3" style="height: 32px;text-align: center;" (valueChanged)="valuechange($event,field)" no-match-found-text="Aucun élément"  max-num-list="10" ngui-auto-complete [source]="dossiers"  select-value-of="dossier" display-property-name="dossier"  class="form-control input-sm" id="dossier"
                     placeholder="N°">
              </td>
              <td>
                {{field.nomDossier}}
              </td>
              <td>
                {{field.client}}
              </td>
              <td *ngIf="!this['demandeUpdate' + i]">
                {{field.refArt}}
              </td>
  
              <td *ngIf="this['demandeUpdate' + i]">
                <input  type="text" [(ngModel)]="field.refArt" name="refArt"  style="height: 32px;text-align: center;" (keyup)="valuechangeReference(field.refArt,field)"  class="form-control input-sm"  placeholder="Réf">
              </td>
  
              <td>
              {{field.designation}}
              </td>
              <td>
                  {{field.pritar | number: '1.2-2'| replace:',':' ' | replace:'.':','}}
              </td>
              <td *ngIf="!this['demandeUpdate' + i]">
                <span >  {{field.prinet | number: '1.2-2'| replace:',':' ' | replace:'.':','}}</span>
  
              </td>
              <td *ngIf="this['demandeUpdate' + i]" >
                  <input  type="number" [(ngModel)]="field.prinet" name="prinet" min="0" value="0" style="height: 32px;text-align: center;" (keyup)="updatePrinNet(field.prinet,field)" (change)="updatePrinNet(field.prinet,field)"  class="form-control input-sm"  placeholder="Prix net">
              </td>
              <td *ngIf="!this['demandeUpdate' + i]">
                <span >  {{field.coef | number: '1.4-4'| replace:',':' ' | replace:'.':','}}</span>
              </td>
              <td *ngIf="this['demandeUpdate' + i]">
                <input  type="number" [(ngModel)]="field.coef" name="coef" min="0" value="0" max="1"  style="height: 32px;text-align: center;" (keyup)="updateCoef(field.coef,field)" (change)="updateCoef(field.coef,field)"  class="form-control input-sm"  placeholder="Coef">
              </td>
              <td *ngIf="!this['demandeUpdate' + i]">
                  <span >  {{field.quantite}}</span>
              </td>
              <td *ngIf="this['demandeUpdate' + i]">
                  <input  type="number" min="0" value="0"  [(ngModel)]="field.quantite" name="quantite"  style="height: 32px;text-align: center;" (keyup)="updatequantite(field.quantite,field)"  (change)="updatequantite(field.quantite,field)"  class="form-control input-sm"  placeholder="Qté">
              </td>
              <td>
                {{field.prinetTotal | number: '1.2-2'| replace:',':' ' | replace:'.':','}}
              </td>
              <td>
                  <a  *ngIf="!this['demandeUpdate' + i]" type="button" (click)="updateField(field,i)" title="Modifier"><img src="assets/images/modif-hover.png" style="width: 77%;margin-bottom: 6px;" class="delete-img"></a>
                  <a *ngIf="this['demandeUpdate' + i]" type="button" (click)="annulerField(field,i)" title="Annuler"><img src="assets/images/annule-cmd.png"></a>
                  <a (click)="deleteFieldValue(field)" title="Supprimer"><img src="assets/images/supp-cmd.png"></a>
              </td>
          </tr>
          <tr>
            <td></td>
              <td>
                    <input type="text" [(ngModel)]="newAttribute.numero" name="numero" (click)="initiliaseDossierr()" min-chars="3" style="height: 32px;text-align: center;" (valueChanged)="valuechangee($event)" no-match-found-text="Aucun élément"  max-num-list="10" ngui-auto-complete [source]="dossiers"  select-value-of="dossier" display-property-name="dossier"  class="form-control input-sm" id="dossier"
                       placeholder="N°">
  
              </td>
              <td>
                {{newAttribute.nomDossier}}
              </td>
              <td>
                {{newAttribute.client}}
              </td>
  
              <td>
                    <input type="text" [(ngModel)]="newAttribute.refArt" name="refArt"  style="height: 32px;text-align: center;" (keyup)="valuechangeReferencee(newAttribute.refArt)"  class="form-control input-sm"  placeholder="Réf">
  
              </td>
              <td>
                {{newAttribute.designation}}
              </td>
              <td>
                {{newAttribute.pritar | number: '1.2-2'| replace:',':' ' | replace:'.':','}}
              </td>
              <td>
                    <input type="number" [(ngModel)]="newAttribute.prinet" min="0" value="0" name="prinet"  style="height: 32px;text-align: center;" (keyup)="updatePrinNett(newAttribute.prinet)"  (change)="updatePrinNett(newAttribute.prinet)" class="form-control input-sm"  placeholder="Prix net">
  
              </td>
              <td>
                    <input type="number" [(ngModel)]="newAttribute.coef" name="coef"  min="0" value="0" max="1" style="height: 32px;text-align: center;" (keyup)="updateCoeff(newAttribute.coef)" (change)="updateCoeff(newAttribute.coef)" class="form-control input-sm"  placeholder="Coef">
  
              </td>
              <td>
                    <input type="number" min="0" value="0" [(ngModel)]="newAttribute.quantite" name="quantite"  style="height: 32px;text-align: center;" (keyup)="updatequantitee(newAttribute.quantite)" (change)="updatequantitee(newAttribute.quantite)"  class="form-control input-sm"  placeholder="Qé">
  
              </td>
              <td>
  
              </td>
              <td>
                  <a  title="Ajouter" (click)="addFieldValue()"><img src="assets/images/add-cmd.png"></a>
              </td>
          </tr>
      </table>
      <p *ngIf="showErrorData" style="color:red"> Tous les champs sont obligatoires</p>
      <p style="color:red" *ngIf="errorRef">La référence n’existe pas</p>
      <p *ngIf="errorLignes" style="color:red">Ajouter au minimum une ligne !</p>
      <p *ngIf="errorDuplicate" style="color: red">Erreur duplication des factures !</p>
    </div>
    <div class="col">
      <p style="color:red;position: relative;left: 50px;top: 40px;" *ngIf="errorNumFacture">{{errorNumFacture}}</p>
    </div>
    <div class=" col d-flex justify-content-center" style="margin: 1%;margin-top: -2%">
      <div class="col-auto nbr-dossier ">
        <table>
          <tr>
            <td>
              <button class="btn-annule" routerLink="/commande">Annuler <img src="assets/images/btn-annule.png"></button>
  
            </td>
            <td style="padding-right: 30px;">
              <button class="btn-valider" (click)="saveCommande(commande)" [disabled]="!form.valid">Enregistrer <img src="assets/images/save.png"></button>
  
            </td>
  
          </tr>
  
        </table>
      </div>
  
    </div>
  
  
  </div>
  
  <!-- --------------------------modal scan ----------------------- -->
  
  <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 1000px">
      <div class="modal-content modal-content-scan" style="width: 200%; top: -170px">
        <div class="modal-header d-flex justify-content-center">
          <h3 class="titre-modal-statut">
            NOUVELLE LIGNE
          </h3>
        </div>
        <div class="modal-body">
          <div class="col d-flex justify-content-start">
            <b class="obligatoir-text">(*) Ce champ est obligatoire</b>
          </div>
          <div class="row" style="margin-top: 25px;">
            <div class="col-6">
              <div class="title">
                <input class="font-weight-bold" placeholder="Poste*">
              </div>
  
              <br />
              <div class="title">
                <input class="font-weight-bold" placeholder="N° Dossier*">
              </div>
  
              <br />
              <div class="title">
                <input class="font-weight-bold" placeholder="Nom Dossier*">
              </div>
  
              <br />
              <div class="title">
                <input class="font-weight-bold" placeholder="Client*">
              </div>
  
              <br />
              <div class="title">
                <input class="font-weight-bold" placeholder="Référence*">
              </div>
  
              <br />
              <div class="title">
                <input class="font-weight-bold" placeholder="Désignation*">
              </div>
  
  
  
            </div>
  
            <div class="col-6">
              <div class="title">
                <input class="font-weight-bold" placeholder="Prix Tarif HT*">
              </div>
              <br />
  
              <div class="title">
                <input class="font-weight-bold" placeholder="Prix Net HT*">
              </div>
  
              <br />
              <div class="title">
                <input class="font-weight-bold" placeholder="Coefficient d'achat*">
              </div>
  
              <br />
              <div class="title">
                <input class="font-weight-bold" placeholder="Quantité*">
              </div>
  
              <br />
              <div class="title">
                <input class="font-weight-bold" placeholder="Total*">
              </div>
  
  
            </div>
  
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <div class=" col d-flex justify-content-center" style="padding-bottom: 1%; margin-bottom: 20px">
              <div class="col-auto nbr-dossier ">
                <table>
                  <tr>
                    <td style="padding-right: 30px;">
                      <button class="btn-valider" style="width: 200px;height: 50px;" >Ajouter <img src="assets/images/save.png"></button>
  
                    </td>
                    <td style="padding-right: 30px;">
                      <button class="btn-annule" style="width: 200px;height: 50px;" >Annuler <img src="assets/images/btn-annule.png"> </button>
  
                    </td>
  
                  </tr>
  
                </table>
              </div>
  
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  