import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgenceVoyageRoutingModule } from './agence-voyage-routing.module';
import { NavbarModule } from '../shared/navbar/navbar.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ControleMessagesModule } from '../shared/controle-messages/controle-messages.module';
import { ControleMessagesRoutingModule } from '../shared/controle-messages/controle-messages-routing.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InternationalPhoneNumberModule } from 'ng-phone-number';
import { NgxLoadingModule } from 'ngx-loading';
import { ClientGuard } from 'src/app/guards/client.guard';
import { AgenceVoyageComponent } from './agence-voyage/agence-voyage.component';
import { SearchAgencePipe } from 'src/app/search/searchAgence.pipe';
import { AjoutAgenceComponent } from './ajout-agence/ajout-agence.component';
import { BsModalModule } from 'ng2-bs3-modal';
import { DetailsAgenceComponent } from './details-agence/details-agence.component';
import { UpdateAgenceComponent } from './update-agence/update-agence.component';



@NgModule({
  declarations: [AgenceVoyageComponent,SearchAgencePipe,AjoutAgenceComponent, DetailsAgenceComponent, UpdateAgenceComponent],
  imports: [
    CommonModule,
    NavbarModule,
    FormsModule, ReactiveFormsModule,
    AgenceVoyageRoutingModule,
    ControleMessagesModule,
    ControleMessagesRoutingModule,
    NgxPaginationModule,
    NgbModule,
    NgbModalModule,BsModalModule,
    InternationalPhoneNumberModule,
    NgxLoadingModule.forRoot({backdropBackgroundColour: 'rgba(0, 0, 0, 0.26)',
        fullScreenBackdrop:true,
        backdropBorderRadius: '4px',
        primaryColour: '#ffffff',
        secondaryColour: '#ffffff',
        tertiaryColour: '#ffffff'})
  ],
  providers:[ClientGuard]
})

export class AgenceVoyageModule { }
