import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UtilisateurRoutingModule } from './utilisateur-routing.module';
import { UtilisateurComponent } from './utilisateur/utilisateur.component';
import { NavbarModule } from '../shared/navbar/navbar.module';
import { NgxPaginationModule } from 'ngx-pagination';
import {SearchPipe} from '../../search/search.pipe';
import { DetailsUtilisateurComponent } from './details-utilisateur/details-utilisateur.component';
import { AjoutUtilisateurComponent } from './ajout-utilisateur/ajout-utilisateur.component';
import { UpdateUtilisateurComponent } from './update-utilisateur/update-utilisateur.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ControleMessagesRoutingModule } from '../shared/controle-messages/controle-messages-routing.module';
import { ControleMessagesModule } from '../shared/controle-messages/controle-messages.module';
import { InternationalPhoneNumberModule } from 'ng-phone-number';
import { DetailsUtilisateurHistoriqueComponent } from './details-utilisateur-historique/details-utilisateur-historique.component';
import { CurrencyFormat } from 'src/app/search/decimalFormat.pipe';
import { UserGuard } from 'src/app/guards/user.guard';
@NgModule({
  declarations: [UtilisateurComponent,SearchPipe,CurrencyFormat, DetailsUtilisateurComponent, AjoutUtilisateurComponent, UpdateUtilisateurComponent, DetailsUtilisateurHistoriqueComponent],
  imports: [
    CommonModule,
    UtilisateurRoutingModule,
    NavbarModule,
    ControleMessagesModule,
    ControleMessagesRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    InternationalPhoneNumberModule,
  ],
  providers:[UserGuard]
})
export class UtilisateurModule { }
