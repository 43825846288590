import { Component, OnInit } from '@angular/core';
import { CatalogueService } from "../../../services/catalogue.service";
import { Router, ActivatedRoute } from '@angular/router';
import { FournisseurService } from "../../../services/fournisseur.service";
import { UserService } from "../../../services/user.service";


@Component({
  selector: 'app-catalogue-by-fournisseur',
  templateUrl: './catalogue-by-fournisseur.component.html',
  styleUrls: ['../../utilisateur/utilisateur/utilisateur.component.css'],
})
export class CatalogueByFournisseurComponent implements OnInit {
  article:any;
  fournisseurs:any;
  id:any;
  utilisateur:any;
  loading = false;
  numberData = 10;
  public filterArticle: any = '';
  p = 1;
  constructor(private catalogueService: CatalogueService,private activatedRoute: ActivatedRoute,private fournisseurService:FournisseurService,private userService:UserService,private router:Router) {

  }
  ngOnInit() {
    this.id = localStorage.getItem('idUser')
    this.userService.getUserByID(this.id).subscribe(utilisateur => {
      this.utilisateur = utilisateur;
      if(!this.utilisateur){
        localStorage.removeItem('idUser');
        localStorage.clear();
        this.router.navigate(['/login']);
      }
    },
      err => {
        this.router.navigateByUrl('/accueil');
        return false;
      });
    this.loading = true;
    this.catalogueService.getAllFournisseurofArticles().then(data => {
      if(data && data["result"]){
        data["result"].sort(this.triFournisseur);
        this.fournisseurs = data["result"]
        this.loading = false;
      }
    },
      err => {
        this.loading = false;
        return false;
      });
  }

  triFournisseur(a,b){
        if (a.nArticles > b.nArticles) return -1;
        else if (a.nArticles == b.nArticles) return 0;
        else return 1;
  }

}

