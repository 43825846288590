import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'searchSalarie'
})
export class SearchSalariePipe implements PipeTransform {
  transform(value: any, searchText?: any): any {
    if(!value) return [];
    if(!searchText) return value;
    searchText = searchText.toLowerCase();
    return value.filter( it => {
      return it.nom.toLowerCase().includes(searchText) || it.prenom.toLowerCase().includes(searchText)|| it.mailPro.toLowerCase().includes(searchText)|| it.mailPerso.toLowerCase().includes(searchText)
      || it.telPro.toLowerCase().includes(searchText)|| it.telPerso.toLowerCase().includes(searchText)|| it.dateNaissance.toLowerCase().includes(searchText) || it.sexe.toLowerCase().includes(searchText)
      || it.adresse.toLowerCase().includes(searchText) || it.contrat.toLowerCase().includes(searchText) || it.poste.toLowerCase().includes(searchText) || it.salaireDepart.toLowerCase().includes(searchText)
      || it.dateEmbauche.toLowerCase().includes(searchText) || it.dateFin.toLowerCase().includes(searchText) 
    });
  }
}
