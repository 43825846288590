import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Http } from '@angular/http';
import { UserService } from "../../../services/user.service";
import { CommandeService } from "../../../services/commande.service";
import * as XLSX from "xlsx";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
import * as moment from 'moment';
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'app-commande',
  templateUrl: './commande.component.html',
  styleUrls: ['../../utilisateur/utilisateur/utilisateur.component.css','./commande.component.css'],
})
export class CommandeComponent implements OnInit {
  utilisateur: any;
  id: any;
  numberData = 25;
  commandes: any;
  successRegister = false
  firstDateReception:any;
  dateLivraisonError = false;
  errorDate = false
  loading = false;
  lenghtData:any
  lenghtDataLignes:any;
  pageNumber:any
  timeout: any = null;
  commandesXLS:any[]=[]
  commandesExcel:any[]=[]
  lignesXLS:any[]=[]
  datecmd:any;
  dateliv:any;
  prinTotalArticle:any;
  mycommandeselected:any;
  bigDate = "9999-12-31"
  p = 1;
  filterCommande:any;
  role:any;
  hasRoleDelete= false;
  hasRoleUpdate = false;
  hasRoleAdd = false;
  hasRoledossier = false;
  constructor(private roleService:RoleService,private http: Http, private router: Router, private userService: UserService, private commandeService: CommandeService) {

  }
  ngOnInit() {
    this.id = localStorage.getItem('idUser')
    this.userService.getUserByID(this.id).subscribe(utilisateur => {
      this.utilisateur = utilisateur;
      if(!this.utilisateur || (this.utilisateur && !this.utilisateur.idRole)){
        localStorage.removeItem('idUser');
        localStorage.clear();
        this.router.navigate(['/login']);
      }
      this.roleService.getRoleById(this.utilisateur.idRole).subscribe(role => {
        this.role = role;
        if (this.role && this.role.privileges && this.role.privileges.length > 0) {
          let founddeleteUtilisateur = this.role.privileges.find(x => x.name == "DELETE_COMMANDE")
          let foundupdateUtilisateur = this.role.privileges.find(x => x.name == "UPDATE_COMMANDE")
          let foundupdateUtilisateuradd = this.role.privileges.find(x => x.name == "ADD_COMMANDE")
          let foundupdateUtilisateurcommande = this.role.privileges.find(x => x.name == "GET_ALL_DOSSIER")
          if (founddeleteUtilisateur) {
            this.hasRoleDelete = true;
          }
          if (foundupdateUtilisateur) {
            this.hasRoleUpdate = true;
          }
          if (foundupdateUtilisateuradd) {
            this.hasRoleAdd = true;
          }
          if (foundupdateUtilisateurcommande) {
            this.hasRoledossier = true;
          }
        }
      },
        err => {
          return false;
        });
    },
      err => {
        return false;
      });



      this.pageNumber = 0
      this.loading = true;
      this.commandeService.getAllCommandesByPage(this.pageNumber, 10).then((data: any[])  => {
          this.commandes = data
          this.loading = false;
      },
        err => {
          return false;
        });


      this.commandeService.getAllDataCount().then(data => {
        this.lenghtData = data
        //this.loading = false;
      },
        err => {
          return false;
        });

        this.commandeService.getAllLignesCount().then(data => {
          if(data && data["result"] && data["result"].length > 0){
            this.lenghtDataLignes = data["result"][0].count
            //console.log(this.lenghtDataLignes)
          }

          //this.loading = false;
        },
          err => {
            return false;
          });

        //this.getAlldata(0)
  }
  getBigDate(): string {
      return this.bigDate
    }
  getAlldata(numberPage){
    this.loading = true;
    if(this.commandesXLS.length == this.lenghtDataLignes){
      this.startXLS()
      return true;
    }
    this.commandeService.getAllLignesByPage(numberPage,5000).then(data => {
        this.commandesXLS.push(...data["result"])
        numberPage++
        this.getAlldata(numberPage);
        return true;
    },
      err => {
        this.loading = false;
        return false;
      });
  }
  startXLS(){
    if(this.commandesXLS.length < 1){
      return false;
    }
    this.loading = true;
    for(var i =0; i<this.commandesXLS.length;i++){
      if(this.commandesXLS[i].dateCommande && this.commandesXLS[i].dateCommande != null && this.commandesXLS[i].dateCommande != ""){
         this.datecmd = moment(this.commandesXLS[i].dateCommande).format('DD/MM/YYYY')
      }
      if(this.commandesXLS[i].dateCommande == null || this.commandesXLS[i].dateCommande == ""){
         this.datecmd = this.commandesXLS[i].dateCommande
      }
      if(this.commandesXLS[i].dateLivraison && this.commandesXLS[i].dateLivraison != null && this.commandesXLS[i].dateLivraison != ""){
         this.dateliv = moment(this.commandesXLS[i].dateLivraison).format('DD/MM/YYYY')
      }
      if(this.commandesXLS[i].dateLivraison == null || this.commandesXLS[i].dateLivraison == ""){
         this.dateliv = this.commandesXLS[i].dateLivraison
      }
      if(this.commandesXLS[i].lignes.prinetTotal  && this.commandesXLS[i].lignes.prinetTotal != null && this.commandesXLS[i].lignes.prinetTotal != ""){
        this.prinTotalArticle = Math.round(this.commandesXLS[i].lignes.prinetTotal * 100) / 100
      }
      if(!this.commandesXLS[i].lignes.prinetTotal || this.commandesXLS[i].lignes.prinetTotal == null || this.commandesXLS[i].lignes.prinetTotal == "NaN" ||  this.commandesXLS[i].lignes.prinetTotal == ""){
        this.prinTotalArticle = 0
      }
      if(!this.commandesXLS[i].lignes.coef || this.commandesXLS[i].lignes.coef == null || this.commandesXLS[i].lignes.coef == "NaN" ||  this.commandesXLS[i].lignes.coef == "" ||  this.commandesXLS[i].lignes.coef == "Infinity"){
        this.commandesXLS[i].lignes.coef = 0
      }
      var devExcel = {
        "numero Commande": this.commandesXLS[i].numeroCommande,
        "numero CommandeGPMI": this.commandesXLS[i].numeroCommandeGPMI,
        "Dénomination": this.commandesXLS[i].denomination,
        "date Commande": this.datecmd ,
        "date Livraison": this.dateliv,
        "numero": this.commandesXLS[i].lignes.numero,
        "nom Dossier": this.commandesXLS[i].lignes.nomDossier,
        "client": this.commandesXLS[i].lignes.client,
        "refArt": this.commandesXLS[i].lignes.refArt,
        "designation": this.commandesXLS[i].lignes.designation,
        "pritar": Math.round(this.commandesXLS[i].lignes.pritar * 100) / 100,
        "coef": Math.round(this.commandesXLS[i].lignes.coef * 10000) / 10000,
        "prinet":Math.round(this.commandesXLS[i].lignes.prinet * 100) / 100,
        "quantite": this.commandesXLS[i].lignes.quantite,
        "prinetTotal": this.prinTotalArticle
      }
      this.lignesXLS.push(devExcel)
    }
    const workBook = XLSX.utils.book_new(); // create a new blank book
    const workSheet = XLSX.utils.json_to_sheet(this.lignesXLS);

    XLSX.utils.book_append_sheet(workBook, workSheet, 'data'); // add the worksheet to the book
    XLSX.writeFile(workBook, 'VoWork-Commandes-Lignes.xlsx'); // initiate a file download in browser
    this.loading = false
  }
  //valider une commande
  validecommande(commande) {
    Swal.fire({
      title: 'VOULEZ-VOUS VALIDER CETTE COMMANDE?',
      imageUrl: 'assets/images/modifier.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      showCancelButton: true,
      width: 800,
      confirmButtonColor: '#059e00',
      cancelButtonColor: '#FF9100',
      cancelButtonText: 'ANNULER',
      confirmButtonText: 'ENREGISTRER'
    }).then((result) => {
      if (!result.dismiss) {
          Swal.fire( {
              imageUrl: 'assets/images/statut-valide-hover.png',
              imageWidth: 100,
              imageHeight: 100,
              imageAlt: 'Custom image',
              width: 800,
              title: 'BIEN VALIDÉ',
              timer: 1500,
              showConfirmButton: false
            }
          );
          commande.etat = "Validé"
          this.commandeService.editIcommandesrById(commande).subscribe(resp => {
          }, err => {
          })
        }
      }
     );
  }
// supprimer une commande
  removecommande(commande) {
    Swal.fire({
      title: 'VOULEZ-VOUS SUPPRIMER CETTE COMMANDE?',
      imageUrl: 'assets/images/supp-modal.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      showCancelButton: true,
      width: 800,
      confirmButtonColor: '#059e00',
      cancelButtonColor: '#FF9100',
      cancelButtonText: 'ANNULER',
      confirmButtonText: 'VALIDER'
    }).then((result) => {
      if (!result.dismiss) {
        Swal.fire( {
            imageUrl: 'assets/images/supp-modal.png',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            width: 800,
            title: 'BIEN SUPPRIMÉ',
            timer: 1500,
            showConfirmButton: false
          }
        );
        this.commandeService.deleteArticleById(commande.id).then(data => {
        });
        this.commandes.splice(this.commandes.indexOf(commande), 1);
      }
    } );
  }
  initialise(commande){
    this.successRegister = false
    this.firstDateReception = commande.dateLivraison
    if(!commande.allDateLivraison){
      commande.allDateLivraison = []
    }
    this.mycommandeselected = commande
  }
  updateDateLivraison(commande){
    this.successRegister = false
    this.dateLivraisonError = false;
    this.errorDate = false;
    if(!commande.dateLivraison || commande.dateLivraison == ""){
      this.dateLivraisonError = true;
      return false
    }

    if (commande.dateLivraison < commande.dateCommande) {
      this.errorDate = true;
      return false;
    }
    if(commande.dateLivraison != this.firstDateReception){
      if(commande.allDateLivraison && commande.allDateLivraison.indexOf(this.firstDateReception) === -1){
        commande.allDateLivraison.push(this.firstDateReception)
      }
    }
    this.commandeService.editIcommandesrById(commande).subscribe(resp => {
      this.successRegister = true
    }, err => {
    })
  }

  getDateCommande(commande): string {
    return commande.dateCommande
  }
  triYear(a, b) {
    if (a.dateCommande > b.dateCommande) return -1;
    else if (a.dateCommande == b.dateCommande) return 0;
    else return 1;
  }
  onScroll() {
    setTimeout(() => {    //<<<---    using ()=> syntax
      this.addData();
    }, 1000);

  }

  addData() {
    if (this.commandes && this.lenghtData === this.commandes.length) {
      return false;
    }
    this.pageNumber = this.pageNumber + 1
    this.commandeService.getAllCommandesByPage(this.pageNumber, 10).then((data: any[])  => {
      this.commandes.push(...data)
    },
      err => {
        return false;
      });
  }

// filter and search commandes

valuechange(event){
  clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.getRechercheResult(event.target.value)
      }
    },1000);
}

getRechercheResult(event){
  this.loading = true;
  if(event && event.length >0 && event.length <4){
    this.loading = false;
    return false;
  }
  this.commandes = [];
  while (this.commandes.length) {
    this.commandes.pop();
  }
  if(!event || event === ""){
    this.pageNumber = 0
    this.commandeService.getAllCommandesByPage(this.pageNumber, 10).then((data: any[])  => {
      this.commandes = data
      this.loading = false;
    },
      err => {
        this.loading = false;
        return false;
      });
    return false;
  }
  this.commandeService.getAllDataByPageAndRechercheWithLimite(event).then((data: any[]) => {
    this.commandes = data
    this.loading = false;
      },
    err => {
      this.loading = false;
      return false;
    });
    return false;
}
toFileXLS(){
  this.getAlldata(0)
}
}

