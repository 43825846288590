import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { RhService } from "../../../services/rh.service";
import { FormBuilder,  Validators } from '@angular/forms';
import { ValidationService } from '../../../services/validate/validate-error.service';
import { pays } from '../../../pays/pays';
import { tri } from '../../../pays/pays';
import { languages } from '../../../pays/langues';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
import { codepostale } from 'src/app/pays/villes';

@Component({
  selector: 'app-update-salarie',
  templateUrl: './update-salarie.component.html',
  styleUrls: ['../../utilisateur/update-utilisateur/update-utilisateur.component.scss']
})
export class UpdateSalarieComponent implements OnInit {
  salarie: any;
  errorDate = false;
  pays: any[] = [];
  vlls: any[] = [];
  parcours: any[] = [];
  experiences: any[] = [];
  langues: any[] = [];
  languages: any[] = [];
  competences: any[] = [];
  competence: any;
  form: any;
  langue = "";
  formConjoint: any;
  formExperience: any;
  formParcours: any;
  addConjointAndEnfants = false;
  addEnfants = false;
  situationFamiliale = 'Célibataire';
  situationEnfant = 'Non';
  nom: any;
  prenom: any;
  dateNaissance: any;
  sexe = 'Masculin';
  errorDates = false;
  formClient: any;
  formEnfant: any;
  enfants: any[] = [];
  roleAdministration = true;
  role: any;
  showButtonClientForm = false;
  selectedClient: any;
  selectedClientExp: any;
  showErrorEtabClient = false;
  testEmail: any;
  showSuivantBouton = false;
  showErrorEmail = false;
  disabled = false;
  selectedEmail: any;
  etablissements: any[] = []
  etablissement: any;
  etablissementsExp: any[] = []
  etablissementExp: any;
  administration = false;
  dateFinPoste = new Date().toISOString().split('T')[0]
  bigDate = "9999-12-31"
  errorInformations = false;
  errorInformationsComp = false;
  errorDateNaissance = false;
  dateNaissancee = new Date().toISOString().split('T')[0]
  showMyContainer = 0;
  errorLangue = ""
  languesChosed: any[] = []
  competencesChosed: any[] = []
  errorCompetence = ""
  secondp = 1;
  thirdp = 1;
  p = 1;
  enfantp=1;
  
  constructor(private http: Http, private rhService: RhService, private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute,
    private router: Router) {
    this.form = this.formBuilder.group({
      nom: ['', [Validators.required, Validators.minLength(2), ValidationService.validateEmptyField]],
      prenom: ['', [Validators.required, Validators.minLength(2), ValidationService.validateEmptyField]],
      sexe: ['Masculin', [Validators.required]],
      dateNaissance: ['', ValidationService.dateValidator],
      mailPro: ['', [ValidationService.emailValidator, ValidationService.nospaceValidator]],
      mailPerso: ['', [ValidationService.emailValidator, ValidationService.nospaceValidator]],
      telPro: ['+216', Validators.required],
      telPerso: ['+216', Validators.required],
      adresse: this.formBuilder.group({
        pays: ['Tunisie',],
        codePostal: ['', ValidationService.numberValidator],
        rue: ['',],
        n: ['', ValidationService.numberValidator],
        ville: ['',],
      }),
      contrat: ['CDI', [Validators.required]],
      poste: ['', [Validators.required, Validators.minLength(2), ValidationService.validateEmptyField]],
      societe: ['', [Validators.required, Validators.minLength(2), ValidationService.validateEmptyField]],
      salaireDepart: ['', ValidationService.numberValidator],
      dateEmbauche: ['', [Validators.required, ValidationService.dateValidator]],
      dateFin: ['', Validators.required],
    })
    this.formParcours = this.formBuilder.group({
      etablissement: ['', [Validators.required, Validators.minLength(2)]],
      diplomen: ['', [Validators.required, Validators.minLength(2)]],
      diplomet: ['Ouvrier', Validators.required],
      date: ['', [ValidationService.dateValidator]],
      desc: ['',]
    })
    this.formExperience = this.formBuilder.group({
      entreprise: ['', Validators.required],
      poste: ['', [Validators.required, Validators.minLength(4), ValidationService.validateEmptyField]],
      dated: ['', [ValidationService.dateValidator]],
      datef: ['',],
      departement: [''],
      bureau: [''],
      desc: ['',]
    })
    this.formEnfant = this.formBuilder.group({
      nom: ['', [Validators.required, Validators.minLength(2)]],
      prenom: ['', [Validators.required, Validators.minLength(2)]],
      dateNaissance: ['', [Validators.required, ValidationService.dateValidator]],
      sexe: ['Masculin', [Validators.required]],
    })
  }
  ngOnInit() {
    this.languages = languages
    this.pays = pays.sort(tri);
    this.pays = this.pays.filter(x => x.name === "Tunisie")
    this.vlls = codepostale
    let params: any = this.activatedRoute.snapshot.params;
    this.rhService.getSalarieByID(params.id).subscribe(salarie => {
      this.salarie = salarie
      if (this.salarie && !this.salarie.posteObject) {
        this.salarie.posteObject = {
          "titre": this.salarie.poste,
          "departement": "",
          "bureau": "",
          "dated": "",
          "datef": "",
        }
      }
      if (this.salarie && !this.salarie.parcours) {
        this.salarie.parcours = []
      }
      if (this.salarie && !this.salarie.enfants) {
        this.salarie.enfants = []
      }
      if (this.salarie && !this.salarie.experiences) {
        this.salarie.experiences = []
      }
      if (this.salarie && !this.salarie.languages) {
        this.salarie.languages = []
      }
      if (this.salarie && !this.salarie.competences) {
        this.salarie.competences = []
      }
    },
      err => {
        return false;
      });
  }
  getBigDate(): string {
      return this.bigDate
    }
  onUpdatesalarie(salarie) {
    var today = new Date();

    if (salarie.nom == undefined || salarie.nom.length < 2 || salarie.prenom == undefined || salarie.prenom.length < 2 || salarie.telPro == undefined) {
      this.errorInformations = true;
      this.errorInformationsComp = false;
      this.errorDateNaissance = false;
      return false;
    }
    if (new Date(salarie.dateNaissance) > today) {
      this.errorInformationsComp = false;
      this.errorInformations = false;
      this.errorDateNaissance = true;
      return false;
    }
    else {
      //salarie.age = this.getAge(salarie.dateNaissance)
      this.errorDate = false;
      this.rhService.editSalarieById(salarie)
        .subscribe(resp => {
        }, err => {

        })
      this.router.navigateByUrl('/rh');
    }
  }

  showOthersSituationEnfant() {
    if (this.salarie.situationEnfant === "Non") {
      this.addEnfants = false;
    }
    else {
      this.addEnfants = true;
    }
  }

  // add enfants
  addnewEnfant() {
    this.salarie.enfants.push(this.formEnfant.value);
    this.formEnfant.reset();
  }
  removeEnfant(enfant) {
    if (window.confirm("Voulez vous vraiment supprimer cet enfant?")) {
      this.salarie.enfants.splice(this.salarie.enfants.indexOf(enfant), 1);
    }
  }
  showOthersSituation() {
    if (this.salarie.situationFamiliale === "Célibataire") {
      this.addConjointAndEnfants = false;
    }
    else {
      this.addConjointAndEnfants = true;
    }
  }
  // ajouter des parcours
  addnewParcours() {
    this.salarie.parcours.push(this.formParcours.value);
    this.formParcours.reset();
  }
  // supprimer parcours
  removeParcours(parcour) {
    Swal.fire({
      title: 'VOULEZ-VOUS SUPPRIMER CE PARCOURS?',
      imageUrl: 'assets/images/supp-modal.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      showCancelButton: true,
      width: 800,
      confirmButtonColor: '#059e00',
      cancelButtonColor: '#FF9100',
      cancelButtonText: 'ANNULER',
      confirmButtonText: 'VALIDER'
    }).then((result) => {
      if (!result.dismiss) {
        Swal.fire({
          imageUrl: 'assets/images/supp-modal.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 800,
          title: 'BIEN SUPPRIMÉ',
          timer: 1500,
          showConfirmButton: false
        }
        );
        this.salarie.parcours.splice(this.salarie.parcours.indexOf(parcour), 1);

      }
    });
  }

  // ajouter des parcours
  addnewExperience() {
    if (this.formExperience.value.dated >= this.formExperience.value.datef) {
      this.errorDates = true;
      return false;
    }
    if (this.formExperience.value.datef.toString().length > 10) {
      this.errorDates = true;
      return false;
    }
    this.salarie.experiences.push(this.formExperience.value);
    this.formExperience.reset();
    this.errorDates = false;
    this.etablissementExp = undefined
  }
  // supprimer parcours
  removeExperience(experience) {
    Swal.fire({
      title: 'VOULEZ-VOUS SUPPRIMER CETTE EXPÉRIENCE?',
      imageUrl: 'assets/images/supp-modal.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      showCancelButton: true,
      width: 800,
      confirmButtonColor: '#059e00',
      cancelButtonColor: '#FF9100',
      cancelButtonText: 'ANNULER',
      confirmButtonText: 'VALIDER'
    }).then((result) => {
      if (!result.dismiss) {
        Swal.fire({
          imageUrl: 'assets/images/supp-modal.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 800,
          title: 'BIEN SUPPRIMÉ',
          timer: 1500,
          showConfirmButton: false
        }
        );
        this.salarie.experiences.splice(this.salarie.experiences.indexOf(experience), 1);
      }
    });
  }

  addLangues(langue) {
    this.errorLangue = ""
    if (this.salarie.languages.indexOf(langue) > -1 || this.languesChosed.indexOf(langue) > -1) {
      this.errorLangue = "Cette langue existe déjà dans la liste"
      return false;
    } else {
      this.languesChosed.push(langue);
    }
  }
  resetErrorLangue() {
    this.languesChosed = []
    while (this.languesChosed.length > 0) {
      this.languesChosed.pop();
    }
    this.errorLangue = ""
    this.langue = ""
  }
  saveChoosedLangues() {
    this.salarie.languages.push(...this.languesChosed);
  }

  removeLangue(langue) {
    Swal.fire({
      title: 'VOULEZ-VOUS SUPPRIMER CETTE LANGUE?',
      imageUrl: 'assets/images/supp-modal.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      showCancelButton: true,
      width: 800,
      confirmButtonColor: '#059e00',
      cancelButtonColor: '#FF9100',
      cancelButtonText: 'ANNULER',
      confirmButtonText: 'VALIDER'
    }).then((result) => {
      if (!result.dismiss) {
        Swal.fire({
          imageUrl: 'assets/images/supp-modal.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 800,
          title: 'BIEN SUPPRIMÉ',
          timer: 1500,
          showConfirmButton: false
        }
        );
        this.salarie.languages.splice(this.salarie.languages.indexOf(langue), 1);

      }
    });
  }

  resetErrorCompetence() {
    this.competencesChosed = []
    while (this.competencesChosed.length > 0) {
      this.competencesChosed.pop();
    }
    this.errorCompetence = ""
    this.competence = ""
  }
  saveChoosedCompetence() {
    this.salarie.competences.push(...this.competencesChosed);
  }
  addCompetences(competence) {
    this.errorCompetence = ""
    if (this.salarie.competences.indexOf(competence) > -1 || this.competencesChosed.indexOf(competence) > -1) {
      this.errorCompetence = "Cette compétence existe déjà dans la liste"
      return false;
    } else {
      this.competencesChosed.push(competence);
    }
  }
  removeCompetence(competence) {
    Swal.fire({
      title: 'VOULEZ-VOUS SUPPRIMER CETTE COMPÉTENCE?',
      imageUrl: 'assets/images/supp-modal.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      showCancelButton: true,
      width: 800,
      confirmButtonColor: '#059e00',
      cancelButtonColor: '#FF9100',
      cancelButtonText: 'ANNULER',
      confirmButtonText: 'VALIDER'
    }).then((result) => {
      if (!result.dismiss) {
        Swal.fire({
          imageUrl: 'assets/images/supp-modal.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 800,
          title: 'BIEN SUPPRIMÉ',
          timer: 1500,
          showConfirmButton: false
        }
        );
        this.salarie.competences.splice(this.salarie.competences.indexOf(competence), 1);

      }
    });
  }
  getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }
  getdateFinPoste() {
    return this.salarie.posteObject.datef
  }
  getDateNaissance(): string {
    return this.dateNaissancee
  }
  modifPart1() {
    if (this.showMyContainer !== 1) {
      this.showMyContainer = 1;
    }
    else {
      this.showMyContainer = 0;
    }
  }
  modifPart2() {
    if (this.showMyContainer !== 2) {
      this.showMyContainer = 2;
    }
    else {
      this.showMyContainer = 0;
    }
  }
  modifPart3() {
    if (this.showMyContainer !== 3) {
      this.showMyContainer = 3;
    }
    else {
      this.showMyContainer = 0;
    }
  }
  modifPart4() {
    if (this.showMyContainer !== 4) {
      this.showMyContainer = 4;
    }
    else {
      this.showMyContainer = 0;
    }
  }
  modifPart5() {
    if (this.showMyContainer !== 5) {
      this.showMyContainer = 5;
    }
    else {
      this.showMyContainer = 0;
    }
  }
  modifPart6() {
    if (this.showMyContainer !== 6) {
      this.showMyContainer = 6;
    }
    else {
      this.showMyContainer = 0;
    }
  }
  modifPart7() {
    if (this.showMyContainer !== 7) {
      this.showMyContainer = 7;
    }
    else {
      this.showMyContainer = 0;
    }
  }

  showPart() {
    return this.showMyContainer;
  }
}
