<app-navbar></app-navbar>
<br>
<div id="content-nav-page" class="row" *ngIf="chargAff && utilisateur">
    <div class=" col-3 titre-menu">
      <a routerLink="/chargeAffaire" class=" icon-f-left"></a>
      <b class="title-haut">{{chargAff.chargeAffaire.prenom}} {{chargAff.chargeAffaire.nom}}<img class="ml-2" src="assets/images/show-a.png"></b>
    </div>
    <div class="col-9 sous-menu">
      <b class="sous-menu-active2" *ngIf="utilisateur.role === 'Super admin' || utilisateur.role === 'Administration'|| utilisateur.role === 'Admin' || utilisateur.role === 'Utilisateur-client'" [routerLink]="['/client']">CLIENT</b>
      <b class="sous-menu-active1 ml-4" *ngIf="utilisateur.role === 'Super admin' || utilisateur.role === 'Administration'|| utilisateur.role === 'Admin' || utilisateur.role === 'Utilisateur-client'" [routerLink]="['/chargeAffaire']"
        style="cursor: pointer">CHARGÉ D'AFFAIRES</b>
    </div>
  </div>
  <div class="row" *ngIf="chargAff">
  
    <div class="col-xl-2 col-lg-3  col-md-3 col-sm-4 left-content-scrolbar ">
  
      <div class="col col-content ">
        <button class="tablink2 btn-liste" id="defaultOpen2" (click)="modifPart1()">
          INFORMATIONS PERSONNELLES</button>
      </div>
  
      <div class="col col-content" *ngIf="roleAdministration">
        <button class="tablink2 btn-liste " (click)="modifPart2()"> INFORMATIONS
          COMPLÉMENTAIRES </button>
      </div>
  
      <div class="col col-content">
        <button class="tablink2 btn-liste " (click)="modifPart3()"> PARCOURS ACADÉMIQUES
        </button>
      </div>
  
      <div class="col col-content">
        <button class="tablink2 btn-liste " (click)="modifPart4()"> ÉXPERIENCES PROFESSIONNELLES
        </button>
      </div>
      <div class="col col-content">
        <button class="tablink2 btn-liste " (click)="modifPart5()"> LANGUES ET COMPÉTENCES
        </button>
      </div>
  
      <div class="col col-content">
        <button class="tablink2 btn-liste " (click)="modifPart6()"> LE CLIENT
        </button>
      </div>
      <div class="col col-content">
        <button class="tablink2 btn-liste " (click)="modifPart7()"> STATISTIQUES
        </button>
      </div>
  
    </div>
    <div class="col-xl-10 col-lg-9  col-md-9 col-sm-8 right-content-scrolbar">
      <div class="card p-3">
        <div *ngIf="showPart()==1" class="col-content-affiche tabcontent2" id="information">
  
          <div>
            <div class="card-body">
  
              <div class="row">
                <div class="col-6">
                  <div class="title">
                    <h4>Nom</h4>
                    <span class="font-weight-bold">{{chargAff.chargeAffaire.nom}}</span>
                  </div>
  
                  <br />
                  <div class="title ">
                    <h4>Prénom</h4>
                    <span class="font-weight-bold">{{chargAff.chargeAffaire.prenom}} </span>
                  </div>
                  <br />
  
                  <div class="title">
                    <h4>Email</h4>
                    <span class="font-weight-bold">{{chargAff.chargeAffaire.mail}}</span>
                  </div>
                  <br />
                  <div class="title">
                    <h4>Téléphone</h4>
                    <span class="font-weight-bold">{{chargAff.chargeAffaire.tel}}</span>
  
                  </div>
  
                </div>
  
                <div class="col-6">
                  <div class="title">
                    <h4>Adresse</h4>
                    <span class="font-weight-bold" *ngIf="chargAff.chargeAffaire.client.etablissement">
                      <span *ngIf="chargAff.chargeAffaire.client.etablissement.id === null">{{chargAff.chargeAffaire.client.etablissement.adresse.n}} {{chargAff.chargeAffaire.client.etablissement.adresse.rue}}
                        {{chargAff.chargeAffaire.client.etablissement.adresse.codePostal}} {{chargAff.chargeAffaire.client.etablissement.adresse.ville}} </span> <span
                        *ngIf="chargAff.chargeAffaire.client.etablissement.id === undefined">{{chargAff.client.adresse.n}} {{chargAff.client.adresse.rue}} {{chargAff.client.adresse.codePostal}} {{chargAff.client.adresse.ville}} </span>
                    </span>
  
                  </div>
                  <br />
  
                  <div class="title">
                    <h4>Pays</h4>
                    <span class="font-weight-bold" *ngIf="chargAff.chargeAffaire.client.etablissement">
                      <span *ngIf="chargAff.chargeAffaire.client.etablissement.id === undefined">{{chargAff.client.adresse.pays}}</span>
                      <span *ngIf="chargAff.chargeAffaire.client.etablissement.id === null">{{chargAff.chargeAffaire.client.etablissement.adresse.pays}}</span>
                    </span>
                  </div>
                  <br />
                  <div class="title">
                    <h4>Poste</h4>
                    <span class="font-weight-bold">{{chargAff.chargeAffaire.poste.titre}} - {{chargAff.chargeAffaire.poste.departement}} - {{chargAff.chargeAffaire.poste.bureau}}</span>
                  </div>
                  <br>
                  <div class="title">
                    <h4>LinkedIn</h4>
                    <span class="font-weight-bold"><a href="{{chargAff.linkedin}}" target="_blank">Cliquer ici</a></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="showPart()==2 && roleAdministration" class="col-content-affiche tabcontent2" id="information_compl">
  
          <div>
            <div class="card-body">
  
              <div class="row">
                <div class="col-6">
                  <div class="title">
                    <h4>Situation familiale</h4>
                    <span class="font-weight-bold">{{chargAff.chargeAffaire.situationFamiliale}} </span>
                  </div>
  
                  <br />
                  <div class="title " *ngIf="chargAff.chargeAffaire.conjoint">
                    <h4>Nom du conjoint</h4>
                    <span class="font-weight-bold"> {{chargAff.chargeAffaire.conjoint.nom}} </span>
                  </div>
                  <br />
  
                  <div class="title" *ngIf="chargAff.chargeAffaire.conjoint">
                    <h4>Prénom du conjoint</h4>
                    <span class="font-weight-bold"> {{chargAff.chargeAffaire.conjoint.prenom}} </span>
                  </div>
                  <br />
                  <div class="title" *ngIf="chargAff.chargeAffaire.conjoint">
                    <h4>Email du conjoint</h4>
                    <span class="font-weight-bold">{{chargAff.chargeAffaire.conjoint.mail}}</span>
  
                  </div>
                </div>
  
  
  
                <div class="col-6" *ngIf="chargAff.chargeAffaire.conjoint">
                  <div class="title">
                    <h4>Téléphone du conjoint</h4>
                    <span class="font-weight-bold">{{chargAff.chargeAffaire.conjoint.tel}}</span>
                  </div>
                  <br />
  
                  <div class="title">
                    <h4>Nationalité du conjoint</h4>
                    <span class="font-weight-bold">{{chargAff.chargeAffaire.conjoint.nationalite}}</span>
                  </div>
                  <br />
  
                  <div class="title">
                    <h4>Organisme de santé du conjoint</h4>
                    <span class="font-weight-bold">{{chargAff.chargeAffaire.conjoint.OrganismeDeSante}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="showPart()==3" class="col-content-affiche tabcontent2" id="etablissement">
  
          <div>
            <div class="card-body" *ngIf="chargAff.chargeAffaire.parcours">
              <div class="row ligne-table2  d-flex justify-content-center">
                <table class="table-responsive">
                  <tr >
                    <td class="col-3 p-l-0">
                      Nom d'établissement
                    </td>
                    <td class="col-3 p-l-0">
                      Nom du diplôme
                    </td>
                    <td class="col-3 p-l-0">
                      Type du diplôme
                    </td>
                    <td class="col-3 p-l-0">
                      Date d'obtention
                    </td>
                    <td class="col-3 p-l-0">
                      Description
                    </td>
                  </tr>
                  <tr  *ngFor="let parcour of chargAff.chargeAffaire.parcours| paginate: {id:'first', itemsPerPage: 4, currentPage: fp }">
                    <td >{{parcour.etablissement}}</td>
                    <td >{{parcour.diplomen}}</td>
                    <td >{{parcour.diplomet}}</td>
                    <td >{{parcour.date | date : 'dd/MM/yyyy'}}</td>
                    <td >{{parcour.desc}}</td>
                  </tr>
                </table>
                <div class="row">
                  <div class="col col-xs-6">
                    <pagination-controls previousLabel="Précédent" nextLabel="Suivant" (pageChange)="fp = $event" id="first"></pagination-controls>
                  </div>
                </div>
  
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="showPart()==4" class="col-content-affiche tabcontent2" id="comp">
  
          <div>
            <div class="card-body" *ngIf="chargAff.chargeAffaire.experiences">
              <div class="row ligne-table2  d-flex justify-content-center">
                <table class="table-responsive">
                  <tr >
                    <td class="col-3 p-l-0">
                      Date de début
                    </td>
                    <td class="col-3 p-l-0">
                      Date de fin
                    </td>
                    <td class="col-3 p-l-0">
                      Nom de l'entreprise
                    </td>
                    <td class="col-3 p-l-0">
                      Nom du poste
                    </td>
  
                    <td class="col-3 p-l-0">
                      Description
                    </td>
                  </tr>
                  <tr  *ngFor="let experience of chargAff.chargeAffaire.experiences| paginate: { id:'second',itemsPerPage: 4, currentPage: sp }">
                    <td >{{experience.dated| date: 'dd/MM/yyyy '}}</td>
                    <td >{{experience.datef| date: 'dd/MM/yyyy '}}</td>
                    <td >{{experience.entreprise}}</td>
                    <td >{{experience.poste}}</td>
                    <td >{{experience.desc}}</td>
                  </tr>
                </table>
                <div class="row">
                  <div class="col col-xs-6">
                    <pagination-controls previousLabel="Précédent" nextLabel="Suivant" (pageChange)="sp = $event" id="second"></pagination-controls>
                  </div>
                </div>
  
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="showPart()==5" class="col-content-affiche tabcontent2" id="com">
  
          <div>
            <div class="card-body" *ngIf="chargAff.chargeAffaire.languages">
              <div>
                <div class="title">
                  <h4>Langues</h4>
                </div>
                <div class="row ligne-table2  d-flex justify-content-center">
                  <table class="table-responsive">
                    <tr>
                      <td class="col-12 p-l-0">
                        Les langues
                      </td>
                    </tr>
                    <tr  *ngFor="let langue of chargAff.chargeAffaire.languages| paginate: {id:'third', itemsPerPage: 4, currentPage: ddd }">
                      <td >{{langue}}</td>
                    </tr>
                  </table>
                  <div class="row">
                    <div class="col col-xs-6">
                      <pagination-controls previousLabel="Précédent" nextLabel="Suivant" (pageChange)="ddd = $event" id="third"></pagination-controls>
                    </div>
                  </div>
  
                </div>
              </div>
              <div style="align-items: center; padding-top: 50px;">
                <div class="title">
                  <h4>Les compétences</h4>
                </div>
                <div class="row ligne-table2  d-flex justify-content-center">
                  <table class="table-responsive">
                    <tr>
                      <td class="col-12 p-l-0">
                        Compétences
                      </td>
  
                    </tr>
                    <tr  *ngFor="let competence of chargAff.chargeAffaire.competences| paginate: { itemsPerPage: 4, currentPage: pc }">
                      <td >{{competence}}</td>
                    </tr>
  
                  </table>
                  <div class="row">
                    <div class="col col-xs-6">
                      <pagination-controls previousLabel="Précédent" nextLabel="Suivant" (pageChange)="pc = $event" #api></pagination-controls>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ng-template #customLoadingTemplate></ng-template>
        <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading>
  
        <div *ngIf="showPart()==6" class="col-content-affiche tabcontent2" id="coordonnees_bancaires" style="padding-top: 2%">
  
          <div>
            <div class="card-body">
  
              <div class="row" *ngIf="chargAff.client">
                <div class="col-6">
                  <div class="title">
                    <h4>Dénomination</h4>
                    <span class="font-weight-bold">{{chargAff.client.denomination}} </span>
                  </div>
  
                  <br />
                  <div class="title ">
                    <h4>Acronyme</h4>
                    <span class="font-weight-bold">{{chargAff.client.acronyme}}</span>
                  </div>
                  <br />
                </div>
              </div>
              <div class="row" *ngIf="chargAff.chargeAffaire.client.etablissement">
                <div class="col-6" *ngIf="chargAff.chargeAffaire.client.etablissement.id === null">
  
                  <div class="title">
                    <h4>Nom</h4>
                    <span class="font-weight-bold">{{chargAff.chargeAffaire.client.etablissement.nom}}</span>
                  </div>
  
                  <br />
                  <div class="title">
                    <h4>Type</h4>
                    <span class="font-weight-bold"> {{chargAff.chargeAffaire.client.etablissement.type}}</span>
                  </div>
                  <br />
                  <div class="title">
                    <h4>Pays</h4>
                    <span class="font-weight-bold">{{chargAff.chargeAffaire.client.etablissement.adresse.pays}} </span>
                  </div>
                  <br />
                  <div class="title">
                    <h4>Ville</h4>
                    <span class="font-weight-bold">{{chargAff.chargeAffaire.client.etablissement.adresse.ville}} </span>
                  </div>
                  <br />
                  <div class="title">
                    <h4>Rue</h4>
                    <span class="font-weight-bold">{{chargAff.chargeAffaire.client.etablissement.adresse.n}} {{chargAff.chargeAffaire.client.etablissement.adresse.rue}} {{chargAff.chargeAffaire.client.etablissement.adresse.codePostal}} </span>
                  </div>
  
                </div>
                <div class="col-6" *ngIf="chargAff.client && chargAff.chargeAffaire.client.etablissement.id === undefined">
  
                  <div class="title">
                    <h4>Nom</h4>
                    <span class="font-weight-bold">{{chargAff.client.denomination}}</span>
                  </div>
  
                  <br />
                  <div class="title">
                    <h4>Type</h4>
                    <span class="font-weight-bold"> Etablissement principale</span>
                  </div>
                  <br />
                  <div class="title">
                    <h4>Pays</h4>
                    <span class="font-weight-bold">{{chargAff.client.adresse.pays}} </span>
                  </div>
                  <br />
                  <div class="title">
                    <h4>Ville</h4>
                    <span class="font-weight-bold">{{chargAff.client.adresse.ville}} </span>
                  </div>
                  <br />
                  <div class="title">
                    <h4>Rue</h4>
                    <span class="font-weight-bold">{{chargAff.client.adresse.n}} {{chargAff.client.adresse.rue}} {{chargAff.client.adresse.codePostal}} </span>
                  </div>
  
                </div>
              </div>
            </div>
            <br><br><br>
            <h4 id="hisClient">Historiques client</h4>
            <br>
            <div class="row ligne-table2  d-flex justify-content-center table-padding" *ngIf="chargAff && chargAff.chargeAffaire && chargAff.chargeAffaire.historiquesCLient && chargAff.chargeAffaire.historiquesCLient.length > 0">
              <table class="table-responsive">
                <tr>
                  <td style="padding:20px">Client</td>
                  <td style="padding:20px">Etablissement</td>
                  <td style="padding:20px">Email</td>
                </tr>
                <tr *ngFor="let exp of chargAff.chargeAffaire.historiquesCLient">
                  <td *ngIf="exp"> {{exp.denomination}}</td>
                  <td *ngIf="exp"> {{exp.etablissement}}</td>
                  <td *ngIf="exp"> {{exp.mail}}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div *ngIf="showPart()==7" class="col-content-affiche tabcontent2" id="static">
          <h3 align="center">{{chargAff.chargeAffaire.nom}} {{chargAff.chargeAffaire.prenom}} : <span *ngIf="clientAllDossiers">{{clientAllDossiers.length}}</span> Dossiers</h3>
          <div>
            <div class="card-body">
              <div>
                <div class="title">
                  <h4>Nombre des dossiers par type et par année</h4>
                </div>
                <div class="row ligne-table2  d-flex justify-content-center table-padding">
                  <table class="table-responsive" style="text-align: center;">
                    <tr>
                      <td class="left">
                        Types
                      </td>
                      <td *ngFor="let year of allyears" class="right">
                        {{year}}
                      </td>
                    </tr>
                    <tr class="text-center">
                      <td class="left">Etude</td>
                      <td *ngFor="let year of allyears" class="right">
                        {{filterBytype[year]["Etude"].length }}
                      </td>
  
                    </tr>
                    <tr class="text-center">
                      <td class="left">Réalisation</td>
                      <td *ngFor="let year of allyears" class="right">
                        {{filterBytype[year]["Réalisation"].length }}
                      </td>
                    </tr>
                    <tr class="text-center">
                      <td class="left">Intervention</td>
                      <td *ngFor="let year of allyears" class="right">
                        {{filterBytype[year]["Intervention"].length }}
                      </td>
  
                    </tr>
                    <tr class="text-center">
                      <td class="left">Fournitures</td>
                      <td *ngFor="let year of allyears" class="right">
                        {{filterBytype[year]["Fournitures"].length }}
                      </td>
                    </tr>
                    <tr class="text-center">
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="text-center">
                      <td class="left">Total</td>
                      <td *ngFor="let year of allyears" class="right">
                        {{filterBytype[year]["Etude"].length + filterBytype[year]["Réalisation"].length + filterBytype[year]["Intervention"].length + filterBytype[year]["Fournitures"].length }}
                      </td>
  
                    </tr>
                  </table>
                </div>
              </div>
              <div style="align-items: center; padding-top: 50px;">
                <div class="title">
                  <h4>Nombre des dossiers par statut et par année</h4>
                </div>
                <div class="row ligne-table2  d-flex justify-content-center table-padding">
                  <table class="table-responsive">
                    <tr>
                      <td class="left">Statut</td>
                      <td *ngFor="let year of allyears" class="right">{{year}}</td>
                    </tr>
                    <tr>
                      <td class="left">Non affecté</td>
                      <td *ngFor="let year of allyears" class="right">
                        {{filterByStatut[year]["01 - Non affecté"].length }}
                      </td>
                    </tr>
                    <tr>
                      <td class="left">Vide</td>
                      <td *ngFor="let year of allyears" class="right">
                        {{filterByStatut[year]["02- Vide"].length }}
                      </td>
                    </tr>
                    <tr>
                      <td class="left">NE</td>
                      <td *ngFor="let year of allyears" class="right">
                        {{filterByStatut[year]["03 - Null"].length }}
                      </td>
                    </tr>
                    <tr>
                      <td class="left">En cours</td>
                      <td *ngFor="let year of allyears" class="right">
                        {{filterByStatut[year]["04 - En cours"].length }}
                      </td>
                    </tr>
                    <tr>
                      <td class="left">En attente</td>
                      <td *ngFor="let year of allyears" class="right">
                        {{filterByStatut[year]["05 - En attente"].length }}
                      </td>
                    </tr>
                    <tr>
                      <td class="left">Envoyé</td>
                      <td *ngFor="let year of allyears" class="right">
                        {{filterByStatut[year]["06 - Envoyé"].length }}
                      </td>
                    </tr>
                    <tr>
                      <td class="left">Perdu</td>
                      <td *ngFor="let year of allyears" class="right">
                        {{filterByStatut[year]["07 - Perdu"].length }}
                      </td>
                    </tr>
  
                    <tr>
                      <td class="left">DM</td>
                      <td *ngFor="let year of allyears" class="right">
                        {{filterByStatut[year]["08 - DM"].length }}
                      </td>
                    </tr>
                    <tr>
                      <td class="left">CDE</td>
                      <td *ngFor="let year of allyears" class="right">
                        {{filterByStatut[year]["09 - CDE"].length }}
                      </td>
                    </tr>
                    <tr>
                      <td class="left">FAB</td>
                      <td *ngFor="let year of allyears" class="right">
                        {{filterByStatut[year]["10 - FAB"].length }}
                      </td>
                    </tr>
                    <tr>
                      <td class="left">Livrée</td>
                      <td *ngFor="let year of allyears" class="right">
                        {{filterByStatut[year]["11 - Livrée"].length }}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td class="left">Total</td>
                      <td *ngFor="let year of allyears" class="right">
                        {{filterByStatut[year]["01 - Non affecté"].length + filterByStatut[year]["02- Vide"].length + filterByStatut[year]["03 - Null"].length  + filterByStatut[year]["04 - En cours"].length
                          + filterByStatut[year]["05 - En attente"].length + filterByStatut[year]["06 - Envoyé"].length + filterByStatut[year]["07 - Perdu"].length +
                           filterByStatut[year]["08 - DM"].length + filterByStatut[year]["09 - CDE"].length + filterByStatut[year]["10 - FAB"].length + filterByStatut[year]["11 - Livrée"].length
  
                         }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
  
              <div style="align-items: center; padding-top: 50px;">
                <div class="title">
                  <h4>Nombre des dossiers par chiffreur et par année</h4>
                </div>
                <div class="row ligne-table2  d-flex justify-content-center table-padding">
                  <table class="table-responsive">
                    <tr>
                      <td class="left">chiffreur</td>
                      <td *ngFor="let year of allyears" class="right">{{year}}</td>
                    </tr>
                    <tr *ngFor="let idACH of idAllCH">
                      <th class="left">{{dossierbyidClientFilterBYCH[idACH][0].yeardateReception.prenomCH}} {{dossierbyidClientFilterBYCH[idACH][0].yeardateReception.nomCH}}</th>
                      <td *ngFor="let year of allyears;let i=index" class="right">
                        <span *ngFor="let liste of dossierbyidClientFilterBYCH[idACH]; let j = index">
                          <span *ngIf=" liste.yeardateReception.yeardateReception == allyears[i]">{{liste.number}}</span>
                        </span>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  