import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FournisseurRoutingModule } from './fournisseur-routing.module';
import { FournisseurComponent } from './fournisseur/fournisseur.component';
import { NavbarModule } from '../shared/navbar/navbar.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InternationalPhoneNumberModule } from 'ng-phone-number';
import { BsModalModule } from 'ng2-bs3-modal';
import { NgxLoadingModule } from 'ngx-loading';
import { SearchFournisseurPipe } from 'src/app/search/searchFournisseur.pipe';
import { AjoutFournisseurComponent } from './ajout-fournisseur/ajout-fournisseur.component';
import { DetailsFournisseurComponent } from './details-fournisseur/details-fournisseur.component';
import { DetailsFournisseurCommandeByMonthComponent } from './details-fournisseur-commande-by-month/details-fournisseur-commande-by-month.component';
import { DetailsFournisseurCommandesByYearAndMonthComponent } from './details-fournisseur-commandes-by-year-and-month/details-fournisseur-commandes-by-year-and-month.component';
import { DetailsFournisseurFactureByMonthComponent } from './details-fournisseur-facture-by-month/details-fournisseur-facture-by-month.component';
import { DetailsFournisseurFacturesByYearAndMonthComponent } from './details-fournisseur-factures-by-year-and-month/details-fournisseur-factures-by-year-and-month.component';
import { UpdateFournisseurComponent } from './update-fournisseur/update-fournisseur.component';
import { ControleMessagesRoutingModule } from '../shared/controle-messages/controle-messages-routing.module';
import { ControleMessagesModule } from '../shared/controle-messages/controle-messages.module';
import { DecimalFormattModule } from '../shared/decimal-formatt/decimal-formatt.module';
import { FournisseurGuard } from 'src/app/guards/fournisseur.guard';


@NgModule({
  declarations: [FournisseurComponent,SearchFournisseurPipe, AjoutFournisseurComponent, DetailsFournisseurComponent, DetailsFournisseurCommandeByMonthComponent, DetailsFournisseurCommandesByYearAndMonthComponent, DetailsFournisseurFactureByMonthComponent, DetailsFournisseurFacturesByYearAndMonthComponent, UpdateFournisseurComponent],
  imports: [
    CommonModule,
    FournisseurRoutingModule,
    NavbarModule,
    DecimalFormattModule,
    FormsModule, ReactiveFormsModule,
    ControleMessagesModule,
    ControleMessagesRoutingModule,
    NgxPaginationModule,
    NgbModule,
    InternationalPhoneNumberModule,
    NgbModalModule,BsModalModule,
    NgxLoadingModule.forRoot({backdropBackgroundColour: 'rgba(0, 0, 0, 0.26)',
        fullScreenBackdrop:true,
        backdropBorderRadius: '4px',
        primaryColour: '#ffffff',
        secondaryColour: '#ffffff',
        tertiaryColour: '#ffffff'})
  ],
  providers:[FournisseurGuard]
})
export class FournisseurModule { }
