import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'searchChargeAffaires'
})
export class SearchChargeAffairePipe implements PipeTransform {
    transform(value, args: string[]){
        if (!value) return value;
        if (value.filter((item) => item.nom.toLowerCase().includes(args)) != '') {
          return value.filter((item) => item.nom.toLowerCase().includes(args));}
          if (value.filter((item) => item.prenom.toLowerCase().includes(args)) != '') {
            return value.filter((item) => item.prenom.toLowerCase().includes(args));}
            if (value.filter((item) => item.mail.toLowerCase().includes(args)) != '') {
                return value.filter((item) => item.mail.toLowerCase().includes(args));}
                if (value.filter((item) => item.tel.toLowerCase().includes(args)) != '') {
                    return value.filter((item) => item.tel.toLowerCase().includes(args));}
      }}
     