<app-navbar></app-navbar>
<br>
<div id="content-nav-page" class="row" *ngIf="utilisateur">
    <div class=" col-3 titre-menu">
      <b style="margin-right: 10%" class="title-haut">DOSSIER</b>
      <img src="assets/images/dossier.png"  style="margin-top: -3%;" *ngIf="hasRoleAdd" [routerLink]="['/ajoutDossier']">
    </div>
    <div class="col-9 sous-menu">
      <b class="sous-menu-active1"  [routerLink]="['/dossier']">DOSSIER</b>
      <b class="sous-menu-active2 ml-4" [routerLink]="['/commande']" *ngIf="hasRolecommande"
        style="cursor: pointer">COMMANDE</b>
  </div>
  </div>
  <div id="content-page" class="containe-fluid " *ngIf="utilisateur">
    <div class="row  ">
      <div class="col-lg-4 col-md-10 col-sm-10  search">
        <input class="search-bar" type="text" placeholder="Rechercher.." [(ngModel)]="filterDossier" name="filterDossier" (keyup)="valuechange($event)">
      </div>
      <div class="col-lg-1 col-md-2 col-sm-2">
        <select class="select-input-nombre" name="numberData" [(ngModel)]="numberData">
          <option>5</option>
          <option selected>10</option>
          <option>25</option>
          <option>50</option>
        </select>
      </div>
      <div class=" col d-flex justify-content-end">
        <div class="col-auto nbr-dossier ">
          <table>
            <tr>
              <td>
                <b>Dossiers</b>
              </td>
            </tr>
            <tr>
              <td>
                <b class="sous-nbr-dossier d-flex justify-content-center" *ngIf="lenghtData">{{lenghtData}}</b>
              </td>
            </tr>
          </table>
        </div>
        <div class="col-auto btn-devis ">
          <a (click)="toFileXLS()" *ngIf="utilisateur && utilisateur.role == 'Super admin'" class="btn-connexion">Exporter<img class="ml-1" src="assets/images/excel.png"></a>
        </div>
        <div class="col-auto btn-devis ">
  
        </div>
      </div>
    </div>
    <ng-template #customLoadingTemplate></ng-template>
    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading>
    <!-- tableau -->
    <div class="row" style="overflow-x:auto;">
      <table class="table table-striped table-hover">
        <th class="left">N° DOSSIER</th>
        <th class="left" >DATE DE RÉCEPTION DU DOSSIER</th>
        <th class="left">CLIENT</th>
        <th class="left">NOM DU DOSSIER</th>
        <th class="left" *ngIf="!administration">TYPE</th>
        <th class="right" *ngIf="!administration">COEFFICIENT</th>
        <th class="left">CA</th>
        <th class="left" *ngIf="!administration">CRÉE PAR</th>
        <th class="left" >DATE LIMITE</th>
        <th class="left" >CHIFFREUR</th>
        <th class="left" *ngIf="!administration">DÉLAI DE TRAITEMENT</th>
        <th class="left" *ngIf="!administration">STATUT</th>
  
        <th class="left" *ngIf="!administration">DATE DE CRÉATION</th>
        <th class="left" *ngIf="!administration">DATE DE MODIFICATION</th>
        <th class="text-center" *ngIf="!administration && utilisateur && utilisateur.role != 'Responsable finance'">MODIFIER LE STATUT</th>
        <th class="text-center " style="margin-left: 100px; text-align: right" *ngIf="!administration && utilisateur && utilisateur.role != 'Responsable finance'">RÉGLAGES</th>
        <tr class="text-center" *ngFor="let dossier of dossiers| paginate: { itemsPerPage: numberData, currentPage: p }">
            <td class="left" [routerLink]="['/details/dossier',dossier.id]">{{dossier.numero}}</td>
            <td  class="left" [routerLink]="['/details/dossier',dossier.id]">{{dossier.dateReception | date : 'dd/MM/yyyy' }}</td>
            <td class="left" [routerLink]="['/details/dossier',dossier.id]">{{dossier.client.denomination}}</td>
            <td class="left" [routerLink]="['/details/dossier',dossier.id]">{{dossier.nom}}</td>
            <td class="left" [routerLink]="['/details/dossier',dossier.id]" *ngIf="!administration"><span *ngIf="dossier.type">{{dossier.type[0]}}</span></td>
            <td class="right" *ngIf="!administration" [routerLink]="['/details/dossier',dossier.id]">{{dossier.coef}}</td>
            <td class="left" [routerLink]="['/details/dossier',dossier.id]">{{dossier.chargeAffaire.nom}} {{dossier.chargeAffaire.prenom}}</td>
            <td class="left" *ngIf="!administration" [routerLink]="['/details/dossier',dossier.id]"><span *ngIf="dossier.user">{{dossier.user.prenom[0]}}{{dossier.user.nom[0]}}</span></td>
            <td class="left" [routerLink]="['/details/dossier',dossier.id]">{{dossier.datel | date : 'dd/MM/yyyy' }}</td>
            <td  class="left" [routerLink]="['/details/dossier',dossier.id]"><span *ngIf="dossier.personneVisee">{{dossier.personneVisee.prenom[0]| uppercase}}{{dossier.personneVisee.nom[0]| uppercase}}</span></td>
            <td class="left" *ngIf="!administration" [routerLink]="['/details/dossier',dossier.id]">J-{{dossier.delai}}</td>
            <td class="left" *ngIf="!administration" [routerLink]="['/details/dossier',dossier.id]">{{dossier.statut}}</td>
            <td class="left" *ngIf="!administration" [routerLink]="['/details/dossier',dossier.id]"> <span *ngIf="dossier.datec">{{dossier.datec.substring(0, dossier.datec.length-3)}}</span></td>
            <td class="left" *ngIf="!administration" [routerLink]="['/details/dossier',dossier.id]"><span *ngIf="dossier.dateMod">{{dossier.dateMod.substring(0, dossier.dateMod.length-3)}}</span></td>
            <td class="td-content-statut " *ngIf="!administration && utilisateur && utilisateur.role != 'Responsable finance'">
            <a (click)="initialise(dossier)" data-toggle="modal" data-target="#myModal" *ngIf="utilisateur.role != 'Equipe Atelier'" title="Statut du dossier " id="filtre">
              <img src="assets/images/statut-hover.png" style="width: 26px;position: relative;left: 30px;" alt="">
            </a>
           </td>
            <td *ngIf="!administration && utilisateur && utilisateur.role != 'Responsable finance'">
              <div class="dropdown" >
                  <button class="btn dropdown-toggle btn-options" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                   OPTIONS
                  </button>
                  <div class="dropdown-menu reglageMenu" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item option" [routerLink]="['/details/dossier',dossier.id]">
                        <div class="options-show-icon" ></div>
                        <div class="option-text"> Afficher</div>
                    </a>
                    <a class="dropdown-item option" [routerLink]="['/dossier/affectation',dossier.id]" title="Affectation" *ngIf="dossier.statut != 'Clôture' && hasRoleUpdate">
                      <div class="options-affect-icon"></div>
                      <div class="option-text">  Affecter</div>
                     </a>
  
                    <a class="dropdown-item option" [routerLink]="['/dossier/details',dossier.id]" title="Modifier" *ngIf="hasRoleUpdate">
                      <div class="options-modif-icon" ></div>
                      <div class="option-text" > Modifier</div>
                    </a>
                    <a class="dropdown-item" (click)="removeDossier(dossier)" title="Supprimer" *ngIf="hasRoleDelete">
                      <div class="options-supp-icon"></div>
                      <div class="option-text"> Supprimer</div>
                    </a>
  
                  </div>
                </div>
                <!--  modal update  réponse client -->
                <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModal" aria-hidden="true" style="overflow-y: hidden;">
                  <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 1000px">
                    <div class="modal-content modal-content-scan" style="width: 67%;top: 0px;left: 18%;">
                      <div class="modal-header d-flex justify-content-center">
                        <h3 class="titre-modal-statut">
                          STATUT DU DOSSIER
                        </h3>
                      </div>
                      <div class="modal-body">
                        <div class="col d-flex justify-content-start">
                          <b class="obligatoir-text" style="color: #FF9100;">(*) Ce champ est obligatoire</b>
                        </div>
                        <form *ngIf="dossier">
                          <p style="color:green;margin: 10px 0 10px 15px;" *ngIf="successRegister">La modification a été effectuée avec succès !</p>
                          <div class="col-md-12" style="margin-top: 5%;">
                            <label for="tel" class="bold" style="color:green">Statut*</label>
                            <select class="form-control input-sm select-modal-input" [(ngModel)]="dossier.statut" (change)="selectStatut(dossier.statut)" name="dossier.statut" style="width: 99%;border: none;border-bottom: 2px solid green;color: green;">
                              <option>02- Vide</option>
                              <option>03 - Null</option>
                              <option>05 - En attente</option>
                              <option>07 - Perdu</option>
                              <option>08 - DM</option>
                            </select>
                          </div>
                          <br>
                          <div class="col-md-12" *ngIf="showRemarqueDate" style="margin-top: 3px;">
                            <label for="prenom" class="bold" style="color:green">Remarques</label>
                            <textarea name="remarques" placeholder="Taper une description" style="border: 2px solid green;color: green;" class="form-control input-sm select-modal-input" rows="8" cols="80" [(ngModel)]="dossier.remarques"></textarea>
                          </div>
                          <br>
                          <div class="col-md-12" *ngIf="showRemarqueDate" style="margin-top: 3px;">
                            <label for="prenom" class="bold" style="color:green">Date de relance</label>
                            <input type="date" placeholder="Date de relance" class="form-control input-sm select-modal-input" name="dateRelance" [(ngModel)]="dossier.dateRelance" value="">
                          </div>
                          <div class="form-group col-md-12 d-flex justify-content-center">
                            <button type="button" (click)="updateReponseClientDossier(dossier)" data-dismiss="modal" class="btn btn-primary btn-connexion">Modifier <img src="assets/images/save.png" alt=""> </button>
                            <button class="btn-annule" style="width: 200px;height: 50px;width: 140px;border: 0px;color: white;padding: 10px;background-color: #FF9100;text-transform: uppercase;border-radius: 8px;text-decoration: none;position: relative;top: 18px;left: 15px;" data-dismiss="modal">Annuler <img src="assets/images/btn-annule.png"> </button>
  
                          </div>
                          <div class="modal-footer">
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
          <!-- modal update  réponse client -->
            </td>
            </tr>
      </table>
    </div>
    <div class="row">
     <div class="col col-xs-6">
          <pagination-controls previousLabel="Précédent" nextLabel="Suivant" (pageChange)="p = $event" #api></pagination-controls>
     </div>
   </div>
  </div>
  <br><br><br><br><br><br>
  <!-- end  liste des utilisateurs -->
  
  <div
        class="search-results"
        infiniteScroll
        [infiniteScrollDistance]="1"
        [infiniteScrollThrottle]="50"
        (scrolled)="onScroll()"
      ></div>
  