import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from "../../../services/user.service";
import { DossierService } from "../../../services/dossier.service";
import { HistoriqueService } from "../../../services/historique.service";

@Component({
  selector: 'app-details-utilisateur-historique',
  templateUrl: './details-utilisateur-historique.component.html',
  styleUrls: ['../details-utilisateur/details-utilisateur.component.css'],
})
export class DetailsUtilisateurHistoriqueComponent implements OnInit {
  enfantp = 1
  enfantpp = 1
  utilisateur: any;
  historique: any;
  showMyContainer = 1;
  constructor(private userService: UserService, private activatedRoute: ActivatedRoute, private dossierService: DossierService, private historiqueService: HistoriqueService,
    private router: Router) {

  }
  ngOnInit() {
    let params: any = this.activatedRoute.snapshot.params;
    if (params) {
      this.historiqueService.getArticleById(params.id).subscribe(dataHist => {
        this.historique = dataHist
      }, err => {
        return false;
      });
    } else {
      return false;
    }
  }
  modifPart1(){
    this.showMyContainer = 1;
  }
  modifPart2(){
    this.showMyContainer = 2;
  }
  modifPart3(){
    this.showMyContainer = 3;
  }
  showPart() {
    return this.showMyContainer;
  }
}
