import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RoleService } from "../../../services/role.service";

@Component({
  selector: 'app-ajout-role',
  templateUrl: './ajout-role.component.html',
  styleUrls: ['./ajout-role.component.css']
})
export class AjoutRoleComponent implements OnInit {

  addForm!: FormGroup;
  model = { option: 'option1' };
  unamePattern = "^[a-z][a-z_]{4,15}$";
  role: any;
  selecteduserall2: boolean = false;
  selecteduserall3: boolean = false;
  selecteduserall6: boolean = false;
  selecteduserall7: boolean = false;
  selecteduserall8: boolean = false;
  selecteduserall9: boolean = false;
  selecteduserall10: boolean = false;
  selecteduserall11: boolean = false;
  selecteduserall12: boolean = false;
  listca: any;
  constructor(private formBuilder: FormBuilder, private roleService: RoleService, private router: Router) {
    this.addForm = this.formBuilder.group({
      nom: ['', [Validators.required, Validators.pattern(this.unamePattern)]],
      slug: ['', [Validators.required]]
    })

  }
  list?: any[];
  rolelist?: any[];
  listclients?: any[];
  listfournisseurs?: any[];
  listdossiers?: any[];
  listcommandes?: any[];
  listcatalogues?: any[];
  listinventaires?: any[];
  liststocks?: any[];
  listrh?: any[];
  listroles?: any[];
  privilege: any[] = [];
  savedRoles: any;
  ngOnInit(): void {
    this.roleService.getAllRole().subscribe(data => {
      this.savedRoles = data
    })
    this.list = [
      {
        id: 1,
        title: 'Afficher',
        checked: false,
        show: false,
        privilege: "GET_ALL_USER"
      },
      {
        id: 2,
        title: 'Ajouter',
        checked: false,
        privilege: "ADD_USER"
      },
      {
        id: 3,
        title: 'Modifier',
        checked: false,
        privilege: "UPDATE_USER"
      },
      {
        id: 4,
        title: 'Supprimer',
        checked: false,
        privilege: "DELETE_USER"
      },
      {
        id: 5,
        title: 'Bloquer',
        checked: false,
        privilege: "BLOQUE_USER"
      }
    ]
    this.listca = [
      {
        id: "listca1",
        title: 'Afficher',
        checked: false,
        show: false,
        privilege: "GET_ALL_CA"

      },
      {
        id: "listca12",
        title: 'Ajouter',
        checked: false,
        privilege: "ADD_CA"
      },
      {
        id: "listca3",
        title: 'Modifier',
        checked: false,
        privilege: "UPDATE_CA"
      },
      {
        id: "listca14",
        title: 'Supprimer',
        checked: false,
        privilege: "DELETE_CA"

      }
    ]

    this.listclients = [
      {
        id: "client1",
        title: 'Afficher',
        checked: false,
        show: false,
        privilege: "GET_ALL_CLIENT"

      },
      {
        id: "client2",
        title: 'Ajouter',
        checked: false,
        privilege: "ADD_CLIENT"
      },
      {
        id: "client3",
        title: 'Modifier',
        checked: false,
        privilege: "UPDATE_CLIENT"

      },
      {
        id: "client4",
        title: 'Supprimer',
        checked: false,
        privilege: "DELETE_CLIENT"

      },
    ]

    this.listfournisseurs = [
      {
        id: "listfournisseur1",
        title: 'Afficher',
        checked: false,
        show: false,
        privilege: "GET_ALL_FOURNISSEUR"

      },
      {
        id: "listfournisseur12",
        title: 'Ajouter',
        checked: false,
        privilege: "ADD_FOURNISSEUR"
      },
      {
        id: "listfournisseur3",
        title: 'Modifier',
        checked: false,
        privilege: "UPDATE_FOURNISSEUR"
      },
      {
        id: "listfournisseur14",
        title: 'Supprimer',
        checked: false,
        privilege: "DELETE_FOURNISSEUR"

      }
    ]

    this.listdossiers = [
      {
        id: "listdossier1",
        title: 'Afficher',
        checked: false,
        show: false,
        privilege: "GET_ALL_DOSSIER"

      },
      {
        id: "listdossier12",
        title: 'Ajouter',
        checked: false,
        privilege: "ADD_DOSSIER"
      },
      {
        id: "listdossier3",
        title: 'Modifier',
        checked: false,
        privilege: "UPDATE_DOSSIER"
      },
      {
        id: "listdossier14",
        title: 'Supprimer',
        checked: false,
        privilege: "DELETE_DOSSIER"

      }
    ]
    this.listcommandes = [
      {
        id: "listcommande1",
        title: 'Afficher',
        checked: false,
        show: false,
        privilege: "GET_ALL_COMMANDE"

      },
      {
        id: "listcommande12",
        title: 'Ajouter',
        checked: false,
        privilege: "ADD_COMMANDE"
      },
      {
        id: "listcommande3",
        title: 'Modifier',
        checked: false,
        privilege: "UPDATE_COMMANDE"
      },
      {
        id: "listcommande14",
        title: 'Supprimer',
        checked: false,
        privilege: "DELETE_COMMANDE"

      }
    ]

    this.listcatalogues = [
      {
        id: "listcatalogue1",
        title: 'Afficher',
        checked: false,
        show: false,
        privilege: "GET_ALL_CATALOGUE"

      },
      {
        id: "listcatalogue12",
        title: 'Ajouter',
        checked: false,
        privilege: "ADD_CATALOGUE"
      },
      {
        id: "listcatalogue3",
        title: 'Modifier',
        checked: false,
        privilege: "UPDATE_CATALOGUE"
      },
      {
        id: "listcatalogue14",
        title: 'Supprimer',
        checked: false,
        privilege: "DELETE_CATALOGUE"

      }
    ]

    this.listinventaires = [
      {
        id: "listinventaire1",
        title: 'Afficher',
        checked: false,
        show: false,
        privilege: "GET_ALL_INVENTAIRE"

      },
      {
        id: "listinvenatire2",
        title: 'Ajouter',
        checked: false,
        privilege: "ADD_INVENTAIRE"
      },
      {
        id: "listinventaire3",
        title: 'Modifier',
        checked: false,
        privilege: "UPDATE_INVENTAIRE"
      },
      {
        id: "listinventaire14",
        title: 'Supprimer',
        checked: false,
        privilege: "DELETE_INVENTAIRE"

      }
    ]

    this.liststocks = [
      {
        id: "liststock1",
        title: 'Afficher',
        checked: false,
        show: false,
        privilege: "GET_ALL_STOCK"

      },
      {
        id: "liststock2",
        title: 'Ajouter',
        checked: false,
        privilege: "ADD_STOCK"
      },
      {
        id: "liststock3",
        title: 'Modifier',
        checked: false,
        privilege: "UPDATE_STOCK"
      },
      {
        id: "liststock14",
        title: 'Supprimer',
        checked: false,
        privilege: "DELETE_STOCK"

      }
    ]
    this.listrh = [
      {
        id: "listrh1",
        title: 'Afficher',
        checked: false,
        show: false,
        privilege: "GET_ALL_RH"

      },
      {
        id: "listrh2",
        title: 'Ajouter',
        checked: false,
        privilege: "ADD_RH"
      },
      {
        id: "listrh3",
        title: 'Modifier',
        checked: false,
        privilege: "UPDATE_RH"
      },
      {
        id: "listrh14",
        title: 'Supprimer',
        checked: false,
        privilege: "DELETE_RH"

      }
    ]

    this.listroles = [
      {
        id: "listrole1",
        title: 'Afficher',
        checked: false,
        show: false,
        privilege: "GET_ALL_ROLE"

      },
      {
        id: "listrole2",
        title: 'Ajouter',
        checked: false,
        privilege: "ADD_ROLE"
      },
      {
        id: "listrole3",
        title: 'Modifier',
        checked: false,
        privilege: "UPDATE_ROLE"
      },
      {
        id: "listrole14",
        title: 'Supprimer',
        checked: false,
        privilege: "DELETE_ROLE"

      }
    ]
  }
  changeitem4(id: any) {
    if (this.listclients![1].checked == true || this.listclients![2].checked == true || this.listclients![3].checked == true) {
      this.listclients![0].checked = true;
      this.listclients![0].show = true;
    }
    if (this.listclients![1].checked == false && this.listclients![2].checked == false && this.listclients![3].checked == false && this.listclients![0].show == true) {
      this.listclients![0].show = false;
      this.listclients![0].checked = false;
    }
  }
  add(form: NgForm) {

    if (this.addForm.invalid) {
      for (const control of Object.keys(this.addForm.controls)) {
        this.addForm.controls[control].markAsTouched();
      }
      return;
    }
    // search if role existe in db 
    let searchRoleDb = this.savedRoles.find(x => x.name == this.addForm.controls['nom'].value)
    if (searchRoleDb) {
      alert("existe")
      return false;
    }
    for (let i = 0; i < this.list!.length; i++) {
      if (this.list![i].checked == true) {
        const data = { name: this.list![i].privilege }
        this.privilege?.push(data!);
      }
    }
    for (let i = 0; i < this.listclients!.length; i++) {

      if (this.listclients![i].checked == true) {
        const data = { name: this.listclients![i].privilege }
          this.privilege?.push(data!);
      }
    }

    for (let i = 0; i < this.listfournisseurs!.length; i++) {

      if (this.listfournisseurs![i].checked == true) {
        const data = { name: this.listfournisseurs![i].privilege }
          this.privilege?.push(data!);
      }
    }

    for (let i = 0; i < this.listca!.length; i++) {

      if (this.listca![i].checked == true) {
        const data = { name: this.listca![i].privilege }
        this.privilege?.push(data!);
      }
    }

    for (let i = 0; i < this.listdossiers!.length; i++) {

      if (this.listdossiers![i].checked == true) {
        const data = { name: this.listdossiers![i].privilege }
          this.privilege?.push(data!);
      }
    }

    for (let i = 0; i < this.listcommandes!.length; i++) {

      if (this.listcommandes![i].checked == true) {
        const data = { name: this.listcommandes![i].privilege }
        this.privilege?.push(data!);
      }
    }

    for (let i = 0; i < this.listcatalogues!.length; i++) {

      if (this.listcatalogues![i].checked == true) {
        const data = { name: this.listcatalogues![i].privilege }
          this.privilege?.push(data!);
      }
    }

    for (let i = 0; i < this.listinventaires!.length; i++) {

      if (this.listinventaires![i].checked == true) {
        const data = { name: this.listinventaires![i].privilege }
          this.privilege?.push(data!);
      }
    }

    for (let i = 0; i < this.liststocks!.length; i++) {

      if (this.liststocks![i].checked == true) {
        const data = { name: this.liststocks![i].privilege }
          this.privilege?.push(data!);
      }
    }
    for (let i = 0; i < this.listrh!.length; i++) {

      if (this.listrh![i].checked == true) {
        const data = { name: this.listrh![i].privilege }
          this.privilege?.push(data!);
      }
    }
    for (let i = 0; i < this.listroles!.length; i++) {
      if (this.listroles![i].checked == true) {
        const data = { name: this.listroles![i].privilege }
          this.privilege?.push(data!);
      }
    }
    /**/
    if (!this.privilege || this.privilege && this.privilege.length < 1) {
      alert("Merci de choisir une permission !")
      this.privilege = []
      while (this.privilege.length > 0) {
        this.privilege.pop();
      }
      return false;
    }
    this.role = {
      name: this.addForm.controls['nom'].value,
      slug: this.addForm.controls['slug'].value,
      privileges: this.privilege,
    }    
    this.roleService.add(this.role).subscribe(data => {
      alert("alert alert-success alert-with-icon")
      this.router.navigate(['/role'])
    });

  }


  get f() {
    return this.addForm.controls;
  }
  selecteduserall4 = false

  selecteduserall = false
  changeitem(id: any) {
    if (this.list![1].checked == true || this.list![2].checked == true || this.list![3].checked == true || this.list![4].checked == true) {
      this.list![0].checked = true;
      this.list![0].show = true;
    }
    if (this.list![1].checked == false && this.list![2].checked == false && this.list![3].checked == false && this.list![4].checked == false && this.list![0].show == true) {
      this.list![0].show = false;
      this.list![0].checked = false;
    } 
  }

  changeall4() {

    if (this.selecteduserall4 == false) {
      for (let i = 0; i < this.listclients!.length; i++) {
        this.listclients![i].checked = true;
      }

    }
    else {
      for (let i = 0; i < this.listclients!.length; i++) {
        this.listclients![i].checked = false;
      }

    }
    this.selecteduserall4 = !this.selecteduserall4;
  }


  changeall() {

    if (this.selecteduserall == false) {
      this.list![0].show = true;
      for (let i = 0; i < this.list!.length; i++) {
        this.list![i].checked = true;
      }

    }
    else {
      for (let i = 0; i < this.list!.length; i++) {
        this.list![i].checked = false;
        this.list![0].show = false;

      }

    }
    this.selecteduserall = !this.selecteduserall;
  }
  change(id: any) {

    if (this.selecteduserall == true)
      this.selecteduserall = !this.selecteduserall;
    this.check();
  }

  changeall2() {

    if (this.selecteduserall2 == false) {
      this.listfournisseurs![0].show = true;
      for (let i = 0; i < this.listfournisseurs!.length; i++) {
        this.listfournisseurs![i].checked = true;
      }

    }
    else {
      for (let i = 0; i < this.listfournisseurs!.length; i++) {
        this.listfournisseurs![i].checked = false;
        this.listfournisseurs![0].show = false;

      }

    }
    this.selecteduserall2 = !this.selecteduserall2;
  }

  changeall6() {

    if (this.selecteduserall6 == false) {
      this.listdossiers![0].show = true;
      for (let i = 0; i < this.listdossiers!.length; i++) {
        this.listdossiers![i].checked = true;

      }

    }
    else {
      for (let i = 0; i < this.listdossiers!.length; i++) {
        this.listdossiers![i].checked = false;
        this.listdossiers![0].show = false;

      }

    }
    this.selecteduserall6 = !this.selecteduserall6;
  }

  changeall7() {

    if (this.selecteduserall7 == false) {
      this.listcommandes![0].show = true;
      for (let i = 0; i < this.listcommandes!.length; i++) {
        this.listcommandes![i].checked = true;

      }

    }
    else {
      for (let i = 0; i < this.listcommandes!.length; i++) {
        this.listcommandes![i].checked = false;
        this.listcommandes![0].show = false;

      }

    }
    this.selecteduserall7 = !this.selecteduserall7;
  }
  changeall8() {
    if (this.selecteduserall8 == false) {
      this.listcatalogues![0].show = true;
      for (let i = 0; i < this.listcatalogues!.length; i++) {
        this.listcatalogues![i].checked = true;
      }
    }
    else {
      for (let i = 0; i < this.listcatalogues!.length; i++) {
        this.listcatalogues![i].checked = false;
        this.listcatalogues![0].show = false;
      }
    }
    this.selecteduserall8 = !this.selecteduserall8;
  }

  changeall9() {
    if (this.selecteduserall9 == false) {
      this.listinventaires![0].show = true;
      for (let i = 0; i < this.listinventaires!.length; i++) {
        this.listinventaires![i].checked = true;
      }
    }
    else {
      for (let i = 0; i < this.listinventaires!.length; i++) {
        this.listinventaires![i].checked = false;
        this.listinventaires![0].show = false;
      }
    }
    this.selecteduserall9 = !this.selecteduserall9;
  }

  changeall10() {
    if (this.selecteduserall10 == false) {
      this.liststocks![0].show = true;
      for (let i = 0; i < this.liststocks!.length; i++) {
        this.liststocks![i].checked = true;
      }
    }
    else {
      for (let i = 0; i < this.liststocks!.length; i++) {
        this.liststocks![i].checked = false;
        this.liststocks![0].show = false;
      }
    }
    this.selecteduserall10 = !this.selecteduserall10;
  }

  changeall11() {
    if (this.selecteduserall11 == false) {
      this.listrh![0].show = true;
      for (let i = 0; i < this.listrh!.length; i++) {
        this.listrh![i].checked = true;
      }
    }
    else {
      for (let i = 0; i < this.listrh!.length; i++) {
        this.listrh![i].checked = false;
        this.listrh![0].show = false;
      }
    }
    this.selecteduserall11 = !this.selecteduserall11;
  }

  changeall12() {
    if (this.selecteduserall12 == false) {
      this.listroles![0].show = true;
      for (let i = 0; i < this.listroles!.length; i++) {
        this.listroles![i].checked = true;
      }
    }
    else {
      for (let i = 0; i < this.listroles!.length; i++) {
        this.listroles![i].checked = false;
        this.listroles![0].show = false;
      }
    }
    this.selecteduserall12 = !this.selecteduserall12;
  }
  changeitem2(id: any) {
    if (this.listfournisseurs![1].checked == true || this.listfournisseurs![2].checked == true || this.listfournisseurs![3].checked == true) {
      this.listfournisseurs![0].checked = true;
      this.listfournisseurs![0].show = true;
    }
    if (this.listfournisseurs![1].checked == false && this.listfournisseurs![2].checked == false && this.listfournisseurs![3].checked == false && this.listfournisseurs![0].show == true) {
      this.listfournisseurs![0].show = false;
      this.listfournisseurs![0].checked = false;
    }    
  }
  changeitem6(id: any) {
    if (this.listdossiers![1].checked == true || this.listdossiers![2].checked == true || this.listdossiers![3].checked == true) {
      this.listdossiers![0].checked = true;
      this.listdossiers![0].show = true;
    }
    if (this.listdossiers![1].checked == false && this.listdossiers![2].checked == false && this.listdossiers![3].checked == false && this.listdossiers![0].show == true) {
      this.listdossiers![0].show = false;
      this.listdossiers![0].checked = false;
    }    
  }
  changeitem7(id: any) {
    if (this.listcommandes![1].checked == true || this.listcommandes![2].checked == true || this.listcommandes![3].checked == true) {
      this.listcommandes![0].checked = true;
      this.listcommandes![0].show = true;
    }
    if (this.listcommandes![1].checked == false && this.listcommandes![2].checked == false && this.listcommandes![3].checked == false && this.listcommandes![0].show == true) {
      this.listcommandes![0].show = false;
      this.listcommandes![0].checked = false;
    }    
  }

  changeitem8(id: any) {
    if (this.listcatalogues![1].checked == true || this.listcatalogues![2].checked == true || this.listcatalogues![3].checked == true) {
      this.listcatalogues![0].checked = true;
      this.listcatalogues![0].show = true;
    }
    if (this.listcatalogues![1].checked == false && this.listcatalogues![2].checked == false && this.listcatalogues![3].checked == false && this.listcatalogues![0].show == true) {
      this.listcatalogues![0].show = false;
      this.listcatalogues![0].checked = false;
    }    
  }

  changeitem9(id: any) {
    if (this.listinventaires![1].checked == true || this.listinventaires![2].checked == true || this.listinventaires![3].checked == true) {
      this.listinventaires![0].checked = true;
      this.listinventaires![0].show = true;
    }
    if (this.listinventaires![1].checked == false && this.listinventaires![2].checked == false && this.listinventaires![3].checked == false && this.listinventaires![0].show == true) {
      this.listinventaires![0].show = false;
      this.listinventaires![0].checked = false;
    }    
  }

  changeitem10(id: any) {
    if (this.liststocks![1].checked == true || this.liststocks![2].checked == true || this.liststocks![3].checked == true) {
      this.liststocks![0].checked = true;
      this.liststocks![0].show = true;
    }
    if (this.liststocks![1].checked == false && this.liststocks![2].checked == false && this.liststocks![3].checked == false && this.liststocks![0].show == true) {
      this.liststocks![0].show = false;
      this.liststocks![0].checked = false;
    }    
  }

  changeitem11(id: any) {
    if (this.listrh![1].checked == true || this.listrh![2].checked == true || this.listrh![3].checked == true) {
      this.listrh![0].checked = true;
      this.listrh![0].show = true;
    }
    if (this.listrh![1].checked == false && this.listrh![2].checked == false && this.listrh![3].checked == false && this.listrh![0].show == true) {
      this.listrh![0].show = false;
      this.listrh![0].checked = false;
    }    
  }

  changeitem12(id: any) {
    if (this.listroles![1].checked == true || this.listroles![2].checked == true || this.listroles![3].checked == true) {
      this.listroles![0].checked = true;
      this.listroles![0].show = true;
    }
    if (this.listroles![1].checked == false && this.listroles![2].checked == false && this.listroles![3].checked == false && this.listroles![0].show == true) {
      this.listroles![0].show = false;
      this.listroles![0].checked = false;
    }    
  }
  annuler() {
    this.router.navigate(['/accueil'])
  }

  check() {
    for (let i = 1; i < this.list!.length; i++) {

      if (this.list![i].checked == true) {
        this.list![0].show = true;

        break;
      }
      this.list![0].show = false;

    }

  }
  changeall3() {

    if (this.selecteduserall3 == false) {
      this.listca![0].show = true;
      for (let i = 0; i < this.listca!.length; i++) {
        this.listca![i].checked = true;

      }

    }
    else {
      for (let i = 0; i < this.listca!.length; i++) {
        this.listca![i].checked = false;
        this.listca![0].show = false;

      }

    }
    this.selecteduserall3 = !this.selecteduserall3;

  }

  changeitem3(id: any) {
    if (this.selecteduserall3 == true)
      this.selecteduserall3 = !this.selecteduserall3;
    for (let i = 1; i < this.listca!.length; i++) {

      if (this.listca![i].checked == true) {
        this.listca![0].checked = true
        this.listca![0].show = true;



      }
    }
  }

  change3(id: any) {
    if (this.selecteduserall3 == true)
      this.selecteduserall3 = !this.selecteduserall3;
  }
}
