import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RhRoutingModule } from './rh-routing.module';
import { RhComponent } from './rh/rh.component';
import { AjoutSalarieComponent } from './ajout-salarie/ajout-salarie.component';
import { UpdateSalarieComponent } from './update-salarie/update-salarie.component';
import { DetailsSalarieComponent } from './details-salarie/details-salarie.component';
import { NavbarModule } from '../shared/navbar/navbar.module';
import { DecimalFormattModule } from '../shared/decimal-formatt/decimal-formatt.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ControleMessagesModule } from '../shared/controle-messages/controle-messages.module';
import { ControleMessagesRoutingModule } from '../shared/controle-messages/controle-messages-routing.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InternationalPhoneNumberModule } from 'ng-phone-number';
import { SearchSalariePipe } from 'src/app/search/searchSalarie.pipe';
import { RHGuard } from 'src/app/guards/rh.guard';
@NgModule({
  declarations: [RhComponent, AjoutSalarieComponent, SearchSalariePipe, UpdateSalarieComponent, DetailsSalarieComponent],
  imports: [
    CommonModule,
    RhRoutingModule,
    NavbarModule,
    FormsModule, ReactiveFormsModule,
    ControleMessagesModule,
    ControleMessagesRoutingModule,
    NgxPaginationModule,
    NgbModule,
    InternationalPhoneNumberModule
  ],
  providers:[RHGuard]
})
export class RhModule { }
