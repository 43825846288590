import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RhService } from "../../../services/rh.service";

@Component({
  selector: 'app-details-salarie',
  templateUrl: './details-salarie.component.html',
  styleUrls: ['../../utilisateur/details-utilisateur/details-utilisateur.component.css'],
})
export class DetailsSalarieComponent implements OnInit {
  salarie: any;
  showMyContainer = 1;
  enfantp = 1
  fp = 1
  sp = 1
  ddd = 1
  pc = 1
  constructor(private rhService: RhService,private activatedRoute: ActivatedRoute) {

  }
  ngOnInit() {
    let params: any = this.activatedRoute.snapshot.params;
    this.rhService.getSalarieByID(params.id).subscribe(salarie => {
      this.salarie = salarie
    },
      err => {
        return false;
      });
  }
  modifPart1(){
    this.showMyContainer = 1;
  }
  modifPart2(){
    this.showMyContainer = 2;
  }
  modifPart3(){
    this.showMyContainer = 3;
  }
  modifPart4(){
    this.showMyContainer = 4;
  }
  modifPart5(){
    this.showMyContainer = 5;
  }
  modifPart6(){
    this.showMyContainer = 6;
  }
  modifPart7(){
    this.showMyContainer = 7;
  }
  showPart() {
    return this.showMyContainer;
  }

}
