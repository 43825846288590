import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import * as XLSX from "xlsx";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
import { UserService } from 'src/app/services/user.service';
import { RoleService } from 'src/app/services/role.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
@Component({
  selector: 'app-utilisateur',
  templateUrl: './utilisateur.component.html',
  styleUrls: ['./utilisateur.component.css']
})

export class UtilisateurComponent implements OnInit {
  public search: any = '';
  utilisateurs:any;
  utilisateur:any;
  showSuccessUpdate = false;
  numberData = 25;
  listeutilisateursExcel:any[]=[]
  id:any;
  myuser:any;
  p = 1
  role:any;
  hasRoleDelete = false
  hasRoleBloque = false
  hasRoleUpdate = false;
  hasRoleAdd = false;
  constructor(private http: Http,private userService:UserService,private activatedRoute:ActivatedRoute,private roleService:RoleService,
    private router: Router) {

  }
  ngOnInit() {
    this.getAllUsers();

    this.id = localStorage.getItem('idUser')
    if (this.id) {
      this.userService.getUserByID(this.id).subscribe(utilisateur => {
        this.utilisateur = utilisateur;
        if(!this.utilisateur){
          localStorage.removeItem('idUser');
          localStorage.clear();
          this.router.navigate(['/login']);
        }
        this.roleService.getRoleById(this.utilisateur.idRole).subscribe(role => {
          this.role = role;
          if(this.role && this.role.privileges && this.role.privileges.length > 0){
            let founddeleteUtilisateur = this.role.privileges.find(x => x.name == "DELETE_USER")
            let foundbloqueUtilisateur = this.role.privileges.find(x => x.name == "BLOQUE_USER")
            let foundUPDATEUtilisateur = this.role.privileges.find(x => x.name == "UPDATE_USER")
            let foundAddtilisateur = this.role.privileges.find(x => x.name == "ADD_USER")
           if(founddeleteUtilisateur){
            this.hasRoleDelete = true;
           }
           if(foundbloqueUtilisateur){
            this.hasRoleBloque = true;
           }
           if(foundUPDATEUtilisateur){
            this.hasRoleUpdate = true;
           }
           if(foundAddtilisateur){
            this.hasRoleAdd = true;
           }
          }
        },
          err => {
            return false;
          });



      },
        err => {
          return false;
        });
    } else {
      return false;
    }
  }
// recuperer tous les utilisateurs
  getAllUsers() {
    this.userService.getAllUsers().then(data => {
      this.utilisateurs = data;
    },
      err => {
        return false;
      });
  }

  // Bloquer / débloquer un utilisateur
  bloquerUtilisateur(utilisateur){
    var newUtilisateur = {
          id:utilisateur.id,
          blocked:utilisateur.blocked
    }
    this.userService.editUtilisateur(newUtilisateur).subscribe(utilisateur => {
        this.utilisateur = utilisateur;
      },
      err => {
        return false;
      });
  }
  // supprimer un utilisateur
removeUtilisateur(utilisateur){
  Swal.fire({
        title: 'VOULEZ-VOUS SUPPRIMER CET UTILISATEUR?',
        imageUrl: 'assets/images/supp-modal.png',
        imageWidth: 100,
        imageHeight: 100,
        imageAlt: 'Custom image',
        showCancelButton: true,
        width: 800,
        confirmButtonColor: '#059e00',
        cancelButtonColor: '#FF9100',
        cancelButtonText: 'ANNULER',
        confirmButtonText: 'VALIDER'
      }).then((result) => {
        if (!result.dismiss) {
          Swal.fire( {
              imageUrl: 'assets/images/supp-modal.png',
              imageWidth: 100,
              imageHeight: 100,
              imageAlt: 'Custom image',
              width: 800,
              title: 'BIEN SUPPRIMÉ',
              timer: 1500,
              showConfirmButton: false
            }
          );
          this.userService.deleteUserById(utilisateur.id).then(data => {
           });
           this.utilisateurs.splice(this.utilisateurs.indexOf(utilisateur), 1);
        }
      } );
}
// // modifier un utilisateur
  updateUtilisateur(utilisateur) {

    this.userService.editInformationsUtilisateur(utilisateur).subscribe(utilisateur => {
    },
      err => {
        return false;
      });
      this.showSuccessUpdate = true;
      alert('La modification a été effectuée avec succès !')
  }
  toFileXLS() {

    for (var i = 0; i < this.utilisateurs.length; i++) {

      var devExcel = {
        "Nom": this.utilisateurs[i].nom,
        "Prénom": this.utilisateurs[i].prenom,
        "Poste": this.utilisateurs[i].poste.titre,
        "Email": this.utilisateurs[i].mail,
        "Numéro de téléphone": this.utilisateurs[i].tel,
        "Rôle": this.utilisateurs[i].role,
        "Nationalité": this.utilisateurs[i].nationalite,
        "Date de recrutement": this.utilisateurs[i].dateRec,
        "Date de création": this.utilisateurs[i].datec,
      }
      this.listeutilisateursExcel.push(devExcel)
    }

    const workBook = XLSX.utils.book_new(); // create a new blank book
    const workSheet = XLSX.utils.json_to_sheet(this.listeutilisateursExcel);

    XLSX.utils.book_append_sheet(workBook, workSheet, 'data'); // add the worksheet to the book
    XLSX.writeFile(workBook, 'VoWork-utilisateurs.xlsx'); // initiate a file download in browser
  }
}
