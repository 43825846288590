<app-navbar></app-navbar>
<br>
<ng-template #customLoadingTemplate></ng-template>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading>


<div id="content-nav-page" class="row">
  <div class=" col-3 titre-menu">
    <a routerLink="/stock" class=" icon-f-left"></a>
    <b style="margin-right: 10%">Stock Entrée</b>
    <img src="assets/images/add-catalogue.png">
  </div>
  <div class="col-9 sous-menu" *ngIf="utilisateur">
    <b class="sous-menu-active1 ml-4" routerLink="/stock">STOCK</b>
  </div>
</div>
<div class="card p-3">
  <div class="col d-flex justify-content-start">
    <b class="obligatoir-text">(*) Ce champ est obligatoire</b>
  </div>
  <div class="card-header " style="border: 1px solid green;margin-bottom: 1%;">
    <b style="color:grey">INFORMATIONS</b>
  </div>

  <div>
    <form [formGroup]="form" class="form-horizontal" role="form">
      <div class="card-body">
        <div class="row" style="margin-top: 20px">
          <div class="col-6" style="margin-left: 20%">
            <div class="title">
              <input class="font-weight-bold" type="date" placeholder="Date du stock*" [max]="getBigDate()" [(ngModel)]="dateDebut" formControlName="datec" id="datec" name="datec" [attr.disabled]="showArray == true ? true : null">
              <control-messages [control]="form.controls.datec"></control-messages>
            </div>
          </div>
        </div>
      </div>
      <div class=" col d-flex justify-content-center" style="padding-bottom: 1%">
        <div class="col-auto nbr-dossier ">
          <table>
            <tr>
              <td style="padding-right: 30px;">
                <button class="btn-annule" style="width: 200px;height: 50px;" routerLink="/stock">Annuler <img src="assets/images/btn-annule.png"> </button>

              </td>
              <td style="padding-right: 30px;">
                <button class="btn-valider" style="width: 200px;height: 50px;" (click)="showArrayFunction()" [disabled]="!form.valid">Valider <img src="assets/images/save.png"></button>

              </td>
            </tr>

          </table>
        </div>

      </div>
    </form>
    <div class="row" style="margin-bottom: -5%" *ngIf="showArray">
      <div class="col-12" style="padding-bottom: 2%;margin-top: 3%">
        <div>
          <div>
            <div style="margin-top: 30px">
              <div class="row ">
                <table class=" table-ligne table-responsive">
                  <tr>
                    <th class="text-center">DATE DU STOCK</th>
                    <th class="text-center">FOURNISSEUR</th>
                    <th class="text-center">RÉFÉRENCE</th>
                    <th class="text-center">DÉSIGNATION </th>
                    <th class="text-center">QUANTITÉ </th>
                    <th class="text-center ">ACTIONS</th>
                  </tr>
                  <tr *ngFor="let field of fieldArrayy; let i = index">
                    <td>
                      {{field.datec | date : 'dd/MM/yyyy'}}
                    </td>
                    <td>
                      {{field.denomination}}
                    </td>
                    <td>
                      {{field.refArt}}
                    </td>
                    <td>
                      {{field.designation}}
                    </td>
                    <td>
                      {{field.quantite | number: '1.2-2'| replace:',':' ' | replace:'.':',' | replace:',00':' '}}
                    </td>
                    <td>
                      <a title="Supprimer" (click)="deleteFieldValue(i)"><img src="assets/images/supp-modal.png" style="width: 27px;cursor: pointer;"></a>

                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{dateDebut | date : 'dd/MM/yyyy'}}
                    </td>
                    <td>

                    </td>
                    <td>
                      <input class="font-weight-bold" type="text" id="newAttributePrice" [(ngModel)]="newAttribute.refArt" name="newAttributerefArt" />
                    </td>
                    <td>

                    </td>
                    <td>
                      <input class="font-weight-bold" type="number" min="0" id="newAttributequantite" [(ngModel)]="newAttribute.quantite" name="newAttributequantite" value="0" />
                    </td>
                    <td>
                      <a  title="Ajouter" (click)="addFieldValue()"><img src="assets/images/add-cmd.png" style="cursor: pointer;"></a>
                    </td>
                  </tr>



                </table>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <p *ngIf="showErrorData" style="color:red;position: relative;top: 55px;"> Merci de vérifier la référence</p>
    <p *ngIf="showErrorDataQuantite" style="color:red;position: relative;top: 55px;"> Merci de vérifier la quantite</p>
    <div class=" col d-flex justify-content-center" style="padding-bottom: 1%" *ngIf="showArray">
      <div class="col-auto nbr-dossier ">
        <table>
          <tr>
            <td style="padding-right: 30px;">
              <button class="btn-annule" style="width: 200px;height: 50px;" routerLink="/stock">Annuler <img src="assets/images/btn-annule.png"> </button>

            </td>
            <td style="padding-right: 30px;">
              <button class="btn-valider" style="width: 200px;height: 50px;" (click)="displayTable()">Ajouter <img src="assets/images/save.png"></button>

            </td>
          </tr>

        </table>
      </div>

    </div>
  </div>
</div>

<!-- --------------------------modal scan ----------------------- -->

<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModal" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 1000px">
    <div class="modal-content modal-content-scan" style="width: 200%; top: -170px">
      <div class="modal-header d-flex justify-content-center">
        <h3 class="titre-modal-statut">
          NOUVELLE RÉFÉRENCE
        </h3>
      </div>
      <div class="modal-body">
        <div class="col d-flex justify-content-start">
          <b class="obligatoir-text">(*) Ce champ est obligatoire</b>
        </div>
        <div class="row" style="margin-top: 25px;">
          <div class="col-6">
            <div class="title">
              <input class="font-weight-bold" placeholder="Fournisseur*">
            </div>

            <br />
            <div class="title">
              <input class="font-weight-bold" placeholder="Référence*">
            </div>
          </div>

          <div class="col-6">
            <div class="title">
              <input class="font-weight-bold" placeholder="Désignation*">
            </div>
            <br />

            <div class="title">
              <input class="font-weight-bold" placeholder="Quantité*">
            </div>
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <div class=" col d-flex justify-content-center" style="padding-bottom: 1%; margin-bottom: 20px">
            <div class="col-auto nbr-dossier ">
              <table>
                <tr>
                  <td style="padding-right: 30px;">
                    <button class="btn-valider" style="width: 200px;height: 50px;" >Ajouter <img src="assets/images/save.png"></button>

                  </td>
                  <td style="padding-right: 30px;">
                    <button class="btn-annule" style="width: 200px;height: 50px;" >Annuler <img src="assets/images/btn-annule.png"> </button>

                  </td>

                </tr>

              </table>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
