<app-navbar></app-navbar>
<br>
<ng-template #customLoadingTemplate></ng-template>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading>
<div id="content-nav-page" class="row">
  <div class=" col-3 titre-menu">
    <a routerLink="/stock" class=" icon-f-left"></a>
    <b style="margin-right: 10%" >Armoires en FAB </b>
  </div>
  <div class="col-9 sous-menu" *ngIf="utilisateur">
    <b class="sous-menu-active2" routerLink="/inventaire" style="cursor: pointer"
      *ngIf="utilisateur.role === 'Super admin' || (utilisateur.role === 'Admin' && utilisateur.mail != 'suren.vijey@krendel.fr') || (utilisateur.role === 'Equipe Atelier' && inventaireConfigObj && !inventaireConfigObj.desactive)">INVENTAIRE</b>
    <b class="sous-menu-active1 ml-4" routerLink="/stock">STOCK</b>
  </div>
</div>
<div id="content-page" class="containe-fluid">
  <div class="row  ">
    <div class="col-lg-4 col-md-10 col-sm-10  search">
      <!--<input class="search-bar" type="search" placeholder="Rechercher.." [(ngModel)]="filterStock" name="filterStock">-->
    </div>
    <div class="col-lg-1 col-md-2 col-sm-2">
      <select class="select-input-nombre" name="numberData" [(ngModel)]="numberData">
        <option>5</option>
        <option selected>10</option>
        <option>25</option>
        <option>50</option>
      </select>
    </div>

    <div class=" col d-flex justify-content-end">
      <div class="col-auto nbr-dossier ">
        <table>
          <tr>
            <td>
              <b>Armoires</b>
            </td>
          </tr>
          <tr>
            <td>
              <b class="sous-nbr-dossier d-flex justify-content-center" *ngIf="armoires">{{armoires.length}}</b>
            </td>
          </tr>
        </table>
      </div>

      <!--<div class="col-auto btn-devis " >
        <a (click)="exportDataToExcel()" class="btn-connexion">Exporter<img class="ml-1" src="assets/images/excel.png"></a>
      </div>-->
    </div>
  </div>
  <!-- tableau -->
  <div class="row" style="overflow-x:auto;">
    <table class="table table-striped table-hover">
      <th class="left" id="firstThTable">DEVIS</th>
      <th class="left" >CLIENT</th>
      <th class="left">N° ARMOIRE</th>
      <tr class="text-center" *ngFor="let armoire of armoires | paginate: { itemsPerPage: numberData, currentPage: p } " [routerLink]="['/armoires/enfabrication',armoire._id,armoire.armoires.numero]">
        <td class="left">{{armoire.nom}} </td>
        <td  class="left">{{armoire.client.denomination}} </td>
        <td class="left" *ngIf="armoire.armoires">{{armoire.armoires.numero}}</td>
        </tr>
    </table>
  </div>
  <div style="padding-top: 50px;color: green">
    <pagination-controls previousLabel="Précédent" nextLabel="Suivant" (pageChange)="p = $event" #api></pagination-controls>
  </div>
</div>
