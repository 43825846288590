import {Injectable} from '@angular/core';
import {Router, CanActivate,ActivatedRouteSnapshot,RouterStateSnapshot} from '@angular/router';
import { UserService } from "../services/user.service";
import { Observable } from 'rxjs/Observable';
import { JwtHelper } from 'angular2-jwt';
@Injectable()
export class SupAuthGuard implements CanActivate{
  id:any;
  utilisateur:any;
  constructor(private router:Router, private userService:UserService){

  }

  canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean{
    this.id = localStorage.getItem('idUser')
    this.userService.getUserByID(this.id).subscribe(utilisateur => {
      this.utilisateur = utilisateur;
      if(!this.utilisateur){
        this.router.navigate(["login"]);
        return false;
      }
     if(this.utilisateur.role != "Super Super admin"){
       this.router.navigate(["accueil"]);
       return false;
     }
      //const url: string = state.url;
     return this.checkLogin();
    },
      err => {
        return true
      });
      return true
  }
  checkLogin(): boolean {
    if (this.isTokenExpired()) {
      // token expired
      localStorage.clear();
      // Navigate to the login page with extras
      this.router.navigate(['/login']);
      return false;
    } else {
      let token = localStorage.getItem('token')
      const helper = new JwtHelper();
      const decodedToken = helper.decodeToken(token!);
      const expirationDate = helper.getTokenExpirationDate(token!);
      const isExpired = helper.isTokenExpired(token!);
      // token valid
      return true;
    }
    // Store the attempted URL for redirecting

  }
  isTokenExpired() {
    let token = localStorage.getItem('token')
    if (token == null || !token || token == undefined)
      return true;
    try {
      const expiry = (JSON.parse(atob(token!.split('.')[1]))).exp;
      return (Math.floor((new Date).getTime() / 1000)) >= expiry;
    }catch (e) {
      return true;
    }
  }
}
//to protect routers
