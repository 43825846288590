import { Component, OnInit } from '@angular/core';
import * as XLSX from "xlsx";
import { Router, ActivatedRoute } from '@angular/router';
import { InventaireService } from "../../../services/inventaire.service";
import { UserService } from "../../../services/user.service";
import * as jsPDF from 'jspdf';
import * as moment from 'moment';
import { CatalogueService } from "../../../services/catalogue.service";
import { codesSchneider } from '../../../pays/codesSchneider';
import { FamilleService } from "../../../services/famille.service";
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'app-inventaire-by-year',
  templateUrl: './inventaire-by-year.component.html',
  styleUrls: ['../../utilisateur/utilisateur/utilisateur.component.css'],
})
export class InventaireByYearComponent implements OnInit {
  inventaires: any[] = [];
  id:any;
  utilisateur:any;
  listeInventaire: any
  loading = false;
  listeInventaireExcel:any[]=[]
  otherdata:any
  familles:any;
  p = 1
  hasRolestock = false;
  role:any;
  hasRoleAdd = false;
  constructor(private roleService:RoleService,private familleService:FamilleService,private catalogueService:CatalogueService,private inventaireService: InventaireService, private activatedRoute: ActivatedRoute,  private router: Router,private userService:UserService) {

  }
  ngOnInit() {
    this.loading = true;
    let params: any = this.activatedRoute.snapshot.params;
    this.id = localStorage.getItem('idUser')
    this.userService.getUserByID(this.id).subscribe(utilisateur => {
      this.utilisateur = utilisateur;
      if(!this.utilisateur){
        localStorage.removeItem('idUser');
        localStorage.clear();
        this.router.navigate(['/login']);
      }
      this.roleService.getRoleById(this.utilisateur.idRole).subscribe(role => {
        this.role = role;
        if (this.role && this.role.privileges && this.role.privileges.length > 0) {
          let founddeleteUtilisateur = this.role.privileges.find(x => x.name == "GET_ALL_STOCK")
          let founddeleteUtilisateuradd = this.role.privileges.find(x => x.name == "ADD_INVENTAIRE")
          if (founddeleteUtilisateur) {
            this.hasRolestock = true;
          }
          if (founddeleteUtilisateuradd) {
            this.hasRoleAdd = true;
          }
        }
      },
        err => {
          return false;
        }); 
      this.inventaireService.getinventaireactivationByDate(params.year)
          .then(data => {
            if(data && data["desactive"]){
              this.router.navigate(['/inventaire']);
            }
          }, err => {
          })
      
    },
      err => {
        return false;
      });

      this.familleService.getAllData().then(data => {
        this.familles = data
      },
        err => {
          return false;
        });
    this.inventaireService.filterInventaireBySingleDatec(params.year).then(data => {
      if(data && data["result"]){
          this.inventaires = data["result"]
          this.inventaires.sort(this.triYear);
          this.getAllMontantByFournisseur()
        }
    },
      err => {
        return false;
      });
  }

  triYear(a, b) {
    if (a._id.denomination < b._id.denomination) return -1;
    else if (a._id.denomination == b._id.denomination) return 0;
    else return 1;
  }
  goToInventaire(date,idFournisseur){
    if(!idFournisseur || idFournisseur == undefined){
      this.router.navigate(['/inventaire/fournisseur/list', date]);
      return false
    }else{
        this.router.navigate(['/inventaire', date,idFournisseur]);
        return false
    }
  }
  exportDataToExcel(){
    let params: any = this.activatedRoute.snapshot.params;
    this.inventaireService.getDataBydate(params.year).then(data => {
      this.loading = true;
      this.listeInventaire = data;
      if(this.listeInventaire && this.listeInventaire.length < 1){
        this.loading = false;
        return false;
      }
      this.loading = true;
      for(var i =0; i<this.listeInventaire.length;i++){
        if(this.listeInventaire[i].dateCommande){
          this.listeInventaire[i].dateCommande = moment(this.listeInventaire[i].dateCommande).format('DD/MM/YYYY')
        }
        if(this.listeInventaire[i].dateFacture){
          this.listeInventaire[i].dateFacture = moment(this.listeInventaire[i].dateFacture).format('DD/MM/YYYY')
        }
          var devExcel = {
            "Date de l'inventaire":moment(this.listeInventaire[i].datec).format('DD/MM/YYYY'),
            "Fournisseur":this.listeInventaire[i].denomination,
            "Référence":this.listeInventaire[i].refArt,
            "Désignation":this.listeInventaire[i].designation,
            "Prix Tarif":Math.round(this.listeInventaire[i].pritar * 100) / 100,
            "Coefficient d'achat	":Math.round(this.listeInventaire[i].coefAchat * 10000) / 10000,
            "Prix net	":Math.round(this.listeInventaire[i].prinet * 100) / 100,
            "Quantité emballée":this.listeInventaire[i].quantiteEmballe,
            "Quantité nom emballée":this.listeInventaire[i].quantiteNonEmballe,
            "Quantité":this.listeInventaire[i].quantite,
            "Étage":this.listeInventaire[i].etage,
            "Montant":Math.round(this.listeInventaire[i].montant * 100) / 100,
            "Numéro de commande":this.listeInventaire[i].numeroCommande,
            "Date de commande":this.listeInventaire[i].dateCommande,
            "Numéro de facture":this.listeInventaire[i].numeroFacture,
            "Date de facture":this.listeInventaire[i].dateFacture,
            "Observation": this.listeInventaire[i].observation,
            "Famille": this.listeInventaire[i].libelleFamille,
            "Code Famille": this.listeInventaire[i].codFamille,
            "Coef Famille": this.listeInventaire[i].coefFamille
          }
        this.listeInventaireExcel.push(devExcel)
      }
      const workBook = XLSX.utils.book_new(); // create a new blank book
      const workSheet = XLSX.utils.json_to_sheet(this.listeInventaireExcel);

      XLSX.utils.book_append_sheet(workBook, workSheet, 'data'); // add the worksheet to the book
      var mydate =  this.listeInventaire[0].datec[8]+this.listeInventaire[0].datec[9]+this.listeInventaire[0].datec[5]+this.listeInventaire[0].datec[6]+this.listeInventaire[0].datec[2]+this.listeInventaire[0].datec[3]
      XLSX.writeFile(workBook, 'VoWork-Inventaire-'+ mydate +'.xlsx'); // initiate a file download in browser
      this.loading = false
    },
      err => {
        return false;
      });

  }
  getAllMontantByFournisseur(){
    let params: any = this.activatedRoute.snapshot.params;
    this.inventaireService.getDataBydate(params.year).then(data => {
      this.otherdata = data
      this.filterMontant(0)
    },
      err => {
        return false;
      });
  }
filterMontant(counter){
  this.loading = true;
  if (counter === this.inventaires.length) {
    this.loading = false;
    return;
  }
  if (!this.inventaires[counter].totalmontantParFounrPardate) {
    this.inventaires[counter].totalmontantParFounrPardate = 0
  }
  if (!this.inventaires[counter].totalCommandeGPMI) {
    this.inventaires[counter].totalCommandeGPMI = 0
  }
  if (!this.inventaires[counter].totalFacture) {
    this.inventaires[counter].totalFacture = 0
  }
  for(var j=0;j<this.otherdata.length;j++){
    if(this.inventaires[counter]._id && this.otherdata[j].idFournisseur == this.inventaires[counter]._id.idFournisseur){
      this.inventaires[counter].totalmontantParFounrPardate = this.inventaires[counter].totalmontantParFounrPardate + Number(this.otherdata[j].prinet * this.otherdata[j].quantite )
      if(this.otherdata[j].numeroCommande){
        this.inventaires[counter].totalCommandeGPMI = this.inventaires[counter].totalCommandeGPMI + 1
      }
      if(this.otherdata[j].numeroFacture){
        this.inventaires[counter].totalFacture = this.inventaires[counter].totalFacture + 1
      }
    }

    if (j === Number(this.otherdata.length - 1)) {
      counter++
      this.filterMontant(counter)
      return true;
    }
  }
}

majfamille(){
  let params: any = this.activatedRoute.snapshot.params;
  this.inventaireService.getDataBydate(params.year).then(data => {
    this.loading = true;
    this.listeInventaire = data;
    if(this.listeInventaire && this.listeInventaire.length < 1){
      this.loading = false;
      return false;
    }
    this.startingMatchingWithCatalogue(0)
  },
    err => {
      return false;
    });
}
startingMatchingWithCatalogue(counter){
  if(counter == this.listeInventaire.length){
    console.log("say done");
    this.loading = false;
    return true;
  }

  this.catalogueService.getAllDataByRefOrEAN(this.listeInventaire[counter].refArt).then((data: any[]) => {
    if (data && data.length > 0) {
      this.listeInventaire[counter].mkt5 = data[data.length - 1].mkt5
      if( this.listeInventaire[counter].denomination == "SCHNEIDER ELECTRIC Tunisie"){
        if(!data[data.length - 1].mkt5 || data[data.length - 1].mkt5 == null || data[data.length - 1].mkt5 == ""){
          counter++
          this.startingMatchingWithCatalogue(counter)
          return true;
        }
        if(this.listeInventaire[counter].mkt5){
          var mymkt = this.listeInventaire[counter].mkt5
          var foundFamillee = codesSchneider.filter(x => x.refFamille.toLowerCase().startsWith(mymkt[0].toLowerCase() + mymkt[1].toLowerCase()))
          if(!foundFamillee){
            counter++;
            this.startingMatchingWithCatalogue(counter)
            return false;
          }
          if(foundFamillee && foundFamillee.length < 1){
            counter++;
            this.startingMatchingWithCatalogue(counter)
            return false;
          }
          if(foundFamillee && foundFamillee.length == 1){
            this.listeInventaire[counter].codFamille = foundFamillee[0].codeFamille
            this.listeInventaire[counter].libelleFamille = foundFamillee[0].libelle
            this.listeInventaire[counter].coefFamille = foundFamillee[0].coefFamille
            this.inventaireService.editInventairerById(this.listeInventaire[counter])
              .subscribe(resp => {
                counter++;
                this.startingMatchingWithCatalogue(counter)
                return true;
              }, err => {

              })
          }
          if(foundFamillee && foundFamillee.length > 1 && mymkt[2]){
            var foundFamilleSecond = codesSchneider.filter(x => x.refFamille.toLowerCase().startsWith(mymkt[0].toLowerCase() + mymkt[1].toLowerCase() + mymkt[2].toLowerCase()))
            if(!foundFamilleSecond){
              this.listeInventaire[counter].codFamille = foundFamillee[0].codeFamille
              this.listeInventaire[counter].libelleFamille = foundFamillee[0].libelle
              this.listeInventaire[counter].coefFamille = foundFamillee[0].coefFamille
              this.inventaireService.editInventairerById(this.listeInventaire[counter])
                .subscribe(resp => {
                  counter++;
                  this.startingMatchingWithCatalogue(counter)
                  return true;
                }, err => {

                })
            }
            if(foundFamilleSecond && foundFamilleSecond.length < 1){
              this.listeInventaire[counter].codFamille = foundFamillee[0].codeFamille
              this.listeInventaire[counter].libelleFamille = foundFamillee[0].libelle
              this.listeInventaire[counter].coefFamille = foundFamillee[0].coefFamille
              this.inventaireService.editInventairerById(this.listeInventaire[counter])
                .subscribe(resp => {
                  counter++;
                  this.startingMatchingWithCatalogue(counter)
                  return true;
                }, err => {

                })
            }
            if(foundFamilleSecond && foundFamilleSecond.length == 1){
              this.listeInventaire[counter].codFamille = foundFamilleSecond[0].codeFamille
              this.listeInventaire[counter].libelleFamille = foundFamilleSecond[0].libelle
              this.listeInventaire[counter].coefFamille = foundFamilleSecond[0].coefFamille
              this.inventaireService.editInventairerById(this.listeInventaire[counter])
                .subscribe(resp => {
                  counter++;
                  this.startingMatchingWithCatalogue(counter)
                  return true;
                }, err => {

                })
            }
            if(foundFamillee && foundFamillee.length > 1 && mymkt[3]){
              var foundFamilleThird = codesSchneider.filter(x => x.refFamille.toLowerCase().startsWith(mymkt[0].toLowerCase() + mymkt[1].toLowerCase() + mymkt[2].toLowerCase() + mymkt[3].toLowerCase()))
              if(!foundFamilleThird){
                this.listeInventaire[counter].codFamille = foundFamilleSecond[0].codeFamille
                this.listeInventaire[counter].libelleFamille = foundFamilleSecond[0].libelle
                this.listeInventaire[counter].coefFamille = foundFamilleSecond[0].coefFamille
                this.inventaireService.editInventairerById(this.listeInventaire[counter])
                  .subscribe(resp => {
                    counter++;
                    this.startingMatchingWithCatalogue(counter)
                    return true;
                  }, err => {

                  })
              }
              if(foundFamilleThird && foundFamilleThird.length < 1){
                if(foundFamilleSecond && foundFamilleSecond[0]){
                  this.listeInventaire[counter].codFamille = foundFamilleSecond[0].codeFamille
                  this.listeInventaire[counter].libelleFamille = foundFamilleSecond[0].libelle
                  this.listeInventaire[counter].coefFamille = foundFamilleSecond[0].coefFamille
                }
                this.inventaireService.editInventairerById(this.listeInventaire[counter])
                  .subscribe(resp => {
                    counter++;
                    this.startingMatchingWithCatalogue(counter)
                    return true;
                  }, err => {

                  })
              }
              if(foundFamilleThird && foundFamilleThird.length == 1){
                this.listeInventaire[counter].codFamille = foundFamilleThird[0].codeFamille
                this.listeInventaire[counter].libelleFamille = foundFamilleThird[0].libelle
                this.listeInventaire[counter].coefFamille = foundFamilleThird[0].coefFamille
                this.inventaireService.editInventairerById(this.listeInventaire[counter])
                  .subscribe(resp => {
                    counter++;
                    this.startingMatchingWithCatalogue(counter)
                    return true;
                  }, err => {

                  })
              }
            }
          }
        }
      }
      if(this.listeInventaire[counter] && this.listeInventaire[counter].denomination != "SCHNEIDER ELECTRIC Tunisie"  && this.listeInventaire[counter].denomination != "PHOENIX CONTACT"){
        if(this.listeInventaire[counter].denomination){
          var foundFamille = this.familles.filter(x => this.listeInventaire[counter].denomination.toLowerCase().indexOf(x.libelle.toLowerCase()) !== -1 )
        }
        if(!foundFamille){
          counter++;
          this.startingMatchingWithCatalogue(counter)
          return false;
        }
        if(foundFamille && foundFamille.length < 1){
          counter++;
          this.startingMatchingWithCatalogue(counter)
          return false;
        }
        if(foundFamille && foundFamille.length > 0){
          this.listeInventaire[counter].codFamille = foundFamille[0].codFamille
          this.listeInventaire[counter].libelleFamille = foundFamille[0].libelle
          this.listeInventaire[counter].coefFamille = foundFamille[0].coefFamille
          this.inventaireService.editInventairerById(this.listeInventaire[counter])
            .subscribe(resp => {
              counter++;
              this.startingMatchingWithCatalogue(counter)
              return true;
            }, err => {

            })
        }
      }
      if(this.listeInventaire[counter] && this.listeInventaire[counter].denomination == "PHOENIX CONTACT"){
        var foundFamille = this.familles.filter(x => x.libelle == "PHOENIX NET" )
        if(!foundFamille){
          counter++;
          this.startingMatchingWithCatalogue(counter)
          return false;
        }
        if(foundFamille && foundFamille.length < 1){
          counter++;
          this.startingMatchingWithCatalogue(counter)
          return false;
        }
        if(foundFamille && foundFamille.length > 0){
          this.listeInventaire[counter].codFamille = foundFamille[0].codFamille
          this.listeInventaire[counter].libelleFamille = foundFamille[0].libelle
          this.listeInventaire[counter].coefFamille = foundFamille[0].coefFamille
          this.inventaireService.editInventairerById(this.listeInventaire[counter])
            .subscribe(resp => {
              counter++;
              this.startingMatchingWithCatalogue(counter)
              return true;
            }, err => {

            })
        }
      }
      return true;
    } else {
      counter++
      this.startingMatchingWithCatalogue(counter)
      return true;
    }
  },
    err => {
      counter++
      this.startingMatchingWithCatalogue(counter)
      return false;
    });
}
}
