import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FournisseurGuard } from 'src/app/guards/fournisseur.guard';
import { AjoutFournisseurComponent } from './ajout-fournisseur/ajout-fournisseur.component';
import { DetailsFournisseurCommandeByMonthComponent } from './details-fournisseur-commande-by-month/details-fournisseur-commande-by-month.component';
import { DetailsFournisseurCommandesByYearAndMonthComponent } from './details-fournisseur-commandes-by-year-and-month/details-fournisseur-commandes-by-year-and-month.component';
import { DetailsFournisseurFactureByMonthComponent } from './details-fournisseur-facture-by-month/details-fournisseur-facture-by-month.component';
import { DetailsFournisseurFacturesByYearAndMonthComponent } from './details-fournisseur-factures-by-year-and-month/details-fournisseur-factures-by-year-and-month.component';
import { DetailsFournisseurComponent } from './details-fournisseur/details-fournisseur.component';
import { FournisseurComponent } from './fournisseur/fournisseur.component';
import { UpdateFournisseurComponent } from './update-fournisseur/update-fournisseur.component';


const routes: Routes = [
  { path: 'fournisseur', component: FournisseurComponent,canActivate: [FournisseurGuard]},
  { path: 'ajoutFournisseur', component: AjoutFournisseurComponent,canActivate: [FournisseurGuard]},
  { path: 'fournisseur/details/:id', component: UpdateFournisseurComponent,canActivate: [FournisseurGuard]},
  { path: 'details/fournisseur/:id', component: DetailsFournisseurComponent,canActivate: [FournisseurGuard]},
  { path: 'details/fournisseur/:id/:year', component: DetailsFournisseurCommandeByMonthComponent,canActivate: [FournisseurGuard]},
  { path: 'details/fournisseur/:id/:year/:month', component: DetailsFournisseurCommandesByYearAndMonthComponent,canActivate: [FournisseurGuard]},
  { path: 'details/fournisseur/factures/details/:id/:year', component: DetailsFournisseurFactureByMonthComponent,canActivate: [FournisseurGuard]},
  { path: 'details/fournisseur/factures/details/:id/:year/:month', component: DetailsFournisseurFacturesByYearAndMonthComponent,canActivate: [FournisseurGuard]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FournisseurRoutingModule { }
