<app-navbar></app-navbar>
<br>
<ng-template #customLoadingTemplate></ng-template>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading>
<div id="content-nav-page" class="row">
  <div class=" col-3 titre-menu">
    <a routerLink="/stock" class=" icon-f-left"></a>
    <b style="margin-right: 10%" *ngIf="listeStock && listeStock[0]">{{listeStock[0].dateInventaire | date : 'dd/MM/yyyy'}}   </b>
    <div class="dropdown" *ngIf="hasRoleAdd">
      <button class="btn icon-catalogue" type="button" id="dropdownMenuButton" data-toggle="dropdown">
      </button>
      <div class="dropdown-menu show-catalogue" aria-labelledby="dropdownMenuButton">
        <a class="dropdown-item drop-dup-option" [routerLink]="['/stock/scan/entree']">Entrée</a>
        <a class="dropdown-item drop-dup-option-b" [routerLink]="['/stock/scan/sortie']">Sortie</a>
      </div>
    </div>
  </div>
  <div class="col-9 sous-menu" *ngIf="utilisateur">
    <b class="sous-menu-active2" routerLink="/inventaire" style="cursor: pointer" *ngIf="hasRoleinventaire">INVENTAIRE</b>
    <b class="sous-menu-active1 ml-4" routerLink="/stock">STOCK</b>
  </div>
</div>
<div id="content-page" class="containe-fluid">
  <div class="row  ">
    <div class="col-lg-4 col-md-10 col-sm-10  search">
      <input class="search-bar" type="search" placeholder="Rechercher.." [(ngModel)]="search" name="search">
    </div>
    <div class="col-lg-1 col-md-2 col-sm-2">
      <select class="select-input-nombre" name="numberData" [(ngModel)]="numberData" >
        <option >5</option>
        <option selected >10</option>
        <option >25</option>
        <option >50</option>
      </select>
    </div>

    <div class=" col d-flex justify-content-end">
      <div class="col-auto nbr-dossier ">
        <table>
          <tr>
            <td>
              <b>Stock</b>
            </td>
          </tr>
          <tr>
            <td>
              <b class="sous-nbr-dossier d-flex justify-content-center" *ngIf="listeStock">{{listeStock.length}}</b>
            </td>
          </tr>
        </table>
      </div>

      <div class="col-auto btn-devis " >
        <a (click)="exportDataToExcel()" class="btn-connexion">Exporter<img class="ml-1" src="assets/images/excel.png"></a>
      </div>
    </div>
  </div>
  <!-- tableau -->
  <div class="row" style="overflow-x:auto;" >
    <table class="table table-striped table-hover" >
      <th  class="left"  id="firstThTable">DATE DU STOCK</th>
      <th class="left" >FOURNISSEUR</th>
      <th class="left"  >RÉFÉRENCE</th>
      <th class="left"  >DÉSIGNATION </th>
      <th class="right"  >PRIX TARIF </th>
      <th class="right"  >COEFFICIENT D'ACHAT </th>
      <th class="right"  >PRIX NET </th>
      <th class="right"  >EAN </th>
      <th class="right"  >QUANTITÉ INITIALE</th>
      <th class="right"  >QUANTITÉ ENTRÉE</th>
      <th class="right"  >QUANTITÉ SORTIE</th>
      <th class="right"  >QUANTITÉ FINALE</th>
      <th class="right"  >MONTANT </th>
      <th class="text-center"  >ÉTAT </th>
      <th class="left">ÉTAGE </th>
      <th class="left" >OBSERVATION </th>
      <th class="left" >DATE COMMANDE </th>
      <th class="left" >FAMILLE </th>
      <th class="left" >CODE FAMILLE </th>
      <th class="left" >COEF FAMILLE </th>
      <th class="text-center "  >RÉGLAGES</th>
      <tr class="text-center" *ngIf="showMulti">
            <td >
              <i class="fa fa-refresh"  style="font-size: 15px;margin-top: 5px;" aria-hidden="true" title="Réinitialiser le stock" (click)="resetStcok()"></i>
            </td>
            <td >
              <input type="text" [(ngModel)]="fournisseur" name="fournisseur" (valueChanged)="chooseFournisseur($event)"  (keyup)="valuechangeFournisseur($event)" min-chars="3" style="height: 32px;text-align: center;" no-match-found-text="Aucun élément"  max-num-list="10" ngui-auto-complete [source]="fournisseurs"  select-value-of="fournisseur" display-property-name="fournisseur"  class="form-control input-sm" id="fournisseur"
                 placeholder="Fournisseur">
            </td>
            <td >
                <input type="text" [(ngModel)]="refArt" name="refArt"  style="height: 32px;text-align: center;" (keyup)="valuechangeReference($event)"  class="form-control input-sm"  placeholder="Référence">
            </td>
            <td >
              <input type="text" [(ngModel)]="designation" name="designation"  style="height: 32px;text-align: center;" (keyup)="valuechangeDesignation($event)"  class="form-control input-sm"  placeholder="Désignation">
            </td>
            <td  >
              <ng5-slider [(value)]="value" (valueChange)="valueChangeRange($event)" [(highValue)]="highValue" [options]="options"></ng5-slider>

            </td>
            <td  ></td>
            <td  ></td>
            <td >
              <input type="text" [(ngModel)]="ean" name="ean"  style="height: 32px;text-align: center;" (keyup)="valuechangeean($event)"  class="form-control input-sm"  placeholder="EAN">
            </td>
            <td > </td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td  ></td>
            <td ></td>
            <td  ></td>
            <td  ></td>
            <td  ></td>
            <td ></td>
          </tr>
          <tr class="text-center" *ngFor="let stock of listeStock|filterstock  : search.toString().toLowerCase() | paginate: { itemsPerPage: numberData, currentPage: p } ">
            <td class="left" [routerLink]="['/details/stock', stock.id]">{{stock.dateInventaire | date : 'dd/MM/yyyy'}} </td>
            <td class="left" [routerLink]="['/details/stock', stock.id]" >{{stock.denomination}} </td>
            <td class="left" [routerLink]="['/details/stock', stock.id]">{{stock.refArt}}</td>
            <td class="left" [routerLink]="['/details/stock', stock.id]">{{stock.designation}}</td>
            <td class="right" [routerLink]="['/details/stock', stock.id]" >{{stock.pritar | number: '1.2-2'| replace:',':' ' | replace:'.':','}}</td>
            <td class="right" [routerLink]="['/details/stock', stock.id]" >{{stock.coefAchat | number: '1.4-4'| replace:',':' ' | replace:'.':','}}</td>
            <td class="right" [routerLink]="['/details/stock', stock.id]" >{{stock.prinet | number: '1.2-2'| replace:',':' ' | replace:'.':','}}</td>
            <td class="right" [routerLink]="['/details/stock', stock.id]" >{{stock.ean13Art}}</td>
            <td class="right" [routerLink]="['/details/stock', stock.id]" > <span *ngIf="stock.quantiteHis && stock.quantiteHis[0]"> {{stock.quantiteHis[0].initialQuantite | number: '1.2-2'| replace:',':' ' | replace:'.':',' | replace:',00':' '}}</span></td>
            <td class="right" [routerLink]="['/details/stock', stock.id]" > <span *ngIf="stock.quantiteHis && stock.quantiteHis[0]"> {{stock.quantiteHis[0].quantite | number: '1.2-2'| replace:',':' ' | replace:'.':',' | replace:',00':' '}}</span></td>
            <td class="right" [routerLink]="['/details/stock', stock.id]" > <span *ngIf="stock.quantiteHis && stock.quantiteHis[0]"> {{stock.quantiteHis[0].quantiteSortie | number: '1.2-2'| replace:',':' ' | replace:'.':',' | replace:',00':' '}}</span></td>
            <td class="right" [routerLink]="['/details/stock', stock.id]"> <span *ngIf="stock.quantiteHis && stock.quantiteHis[0]"> {{stock.quantiteHis[0].initialQuantite +  stock.quantiteHis[0].quantite - stock.quantiteHis[0].quantiteSortie | number: '1.2-2'| replace:',':' ' | replace:'.':',' | replace:',00':' '}}</span></td>
            <td class="right" [routerLink]="['/details/stock', stock.id]" >{{(stock.quantiteHis[0].initialQuantite +  stock.quantiteHis[0].quantite - stock.quantiteHis[0].quantiteSortie) * stock.prinet | number: '1.2-2'| replace:',':' ' | replace:'.':','}} </td>
            <td [routerLink]="['/details/stock', stock.id]" >
              <img *ngIf="stock.exist" src="assets/images/green.png" style="height: 20px">
              <img *ngIf="!stock.exist" src="assets/images/Rond_gris.png" style="height: 20px">
             </td>
             <td class="left" [routerLink]="['/details/stock', stock.id]">{{stock.etage}}</td>
             <td class="left" [routerLink]="['/details/stock', stock.id]">{{stock.observation}}</td>
             <td class="left" [routerLink]="['/details/stock', stock.id]">{{stock.dateCommande | date : 'dd/MM/yyyy'}}</td>
             <td class="left" [routerLink]="['/details/stock', stock.id]" >{{stock.libelleFamille}}</td>
             <td class="left" [routerLink]="['/details/stock', stock.id]" >{{stock.codFamille}}</td>
             <td class="left" [routerLink]="['/details/stock', stock.id]" >{{stock.coefFamille}}</td>
            <td >
              <div class="dropdown" >
                <button class="btn dropdown-toggle btn-options" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  OPTIONS
                </button>
                <div class="dropdown-menu reglageMenu" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item option"  [routerLink]="['/details/stock', stock.id]" >
                    <div class="options-show-icon" ></div>
                    <div class="option-text"> Afficher</div>
                  </a>

                  <a class="dropdown-item option"  *ngIf="hasRoleUpdate" [routerLink]="['/update/stock/artcile',stock.id]" title="Modifier">
                    <div class="options-modif-icon"></div>
                    <div class="option-text" > Modifier</div>
                  </a>
                  <a class="dropdown-item" *ngIf="hasRoleDelete" (click)="removebible(stock)" title="Supprimer">
                    <div class="options-supp-icon"></div>
                    <div class="option-text"> Supprimer</div>
                  </a>
                </div>
              </div>
            </td>
          </tr>

    </table>
  </div>
  <div  style="padding-top: 50px;color: green">
    <pagination-controls previousLabel="Précédent" nextLabel="Suivant" (pageChange)="p = $event" #api></pagination-controls>
  </div>
</div>
