import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';;
import { DossierGuard } from 'src/app/guards/dossier.guard';
import { AffectationDossierComponent } from './affectation-dossier/affectation-dossier.component';
import { AjoutDossierComponent } from './ajout-dossier/ajout-dossier.component';
import { DetailsDossierCommandeComponent } from './details-dossier-commande/details-dossier-commande.component';
import { DetailsDossierComponent } from './details-dossier/details-dossier.component';
import { DossierComponent } from './dossier/dossier.component';
import { UpdateDossierComponent } from './update-dossier/update-dossier.component';


const routes: Routes = [
  { path: 'dossier', component: DossierComponent, canActivate: [DossierGuard]},
  { path: 'ajoutDossier', component: AjoutDossierComponent,canActivate: [DossierGuard] },
  { path: 'dossier/details/:id', component: UpdateDossierComponent, canActivate: [DossierGuard]},
  { path: 'dossier/affectation/:id', component: AffectationDossierComponent,canActivate: [DossierGuard]},
  { path: 'details/dossier/:id', component: DetailsDossierComponent, canActivate: [DossierGuard]},
  { path: 'details/dossier/commande/:id/:idDossier', component: DetailsDossierCommandeComponent, canActivate: [DossierGuard]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DossierRoutingModule { }
