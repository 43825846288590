import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { RoleGuard } from 'src/app/guards/role.guard';
import { AjoutRoleComponent } from './ajout-role/ajout-role.component';
import { HomeComponent } from './home/home.component';
import { ModificationRoleComponent } from './modification-role/modification-role.component';
import { RoleComponent } from './role/role.component';


const routes: Routes = [
  { path: 'accueil', component: HomeComponent },
  { path: 'role', component: RoleComponent, canActivate: [RoleGuard] },
  { path: 'ajout/role', component: AjoutRoleComponent, canActivate: [RoleGuard] },
  { path: 'role/details/:id', component: ModificationRoleComponent, canActivate: [RoleGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
