import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { environment } from '../../../../environments/environment';
import { UserService } from "../../../services/user.service";
import { FormBuilder, FormArray, Validators } from '@angular/forms';
import { FournisseurService } from "../../../services/fournisseur.service";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { DossierService } from "../../../services/dossier.service";
import { CatalogueService } from "../../../services/catalogue.service";
import { CommandeService } from "../../../services/commande.service";
import { UploadFileService } from "../../../services/upload-file-service.service";
import { ValidationService } from '../../../services/validate/validate-error.service';
import { HistoriqueService } from "../../../services/historique.service";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';

@Component({
  selector: 'app-ajout-commande',
  templateUrl: './ajout-commande.component.html',
  styleUrls: ['../../utilisateur/ajout-utilisateur/ajout-utilisateur.component.scss', '../../dossier/ajout-dossier/ajout-dossier.component.css'],
})
export class AjoutCommandeComponent implements OnInit {
  utilisateur: any;
  id: any;
  form: any;
  dateLivraison = new Date().toISOString().split('T')[0]
  dateCommande = new Date().toISOString().split('T')[0]
  showArray = false;
   fieldArray: Array<any> = [];
   newAttribute: any = {};
  controle = false
  showedValue: any;
  fournisseurs: any;
  errorFournisseur = false;
  errorDate = false;
  dossiers: any;
  selectedFounrisseur: any;
  timeout: any = null;
  errorRef = false;
  lignes: any[] = []
  sommePrixNet = 0
  errorLignes = false;
  showErrorData = false
  sizeLimitOne = 10000000; //10MB
  myfiles: any;
  myfilesAccuse:any;
  myfilesFactures:any;
  myfilesBonLivraison:any;
  loading = false
  savedField:any;
  monthNumbers = ["01","02","03","04","05","06","07","08","09","10","11","12"]
  errorNumeroCommandeGPMI = false;
  errorDateFacture = false
  showMyContainer = 1;
  errorDuplicate = false;
  errorNumFacture = ""
  bigDate = "9999-12-31"
  constructor(private historiqueService:HistoriqueService,private http: Http, private router: Router, private catalogueService: CatalogueService, private userService: UserService, private commandeService: CommandeService, private uploadFileService: UploadFileService,
    private formBuilder: FormBuilder, private fournisseurService: FournisseurService, private _sanitizer: DomSanitizer, private dossierService: DossierService) {
    this.form = this.formBuilder.group({
      fournisseur: ['', Validators.required],
      numeroCommandeGPMI: ['', [Validators.required, Validators.minLength(2), ValidationService.validateEmptyField, Validators.maxLength(25)]],
      dateCommande: ['', [Validators.required,ValidationService.dateValidatorr]],
      dateLivraison: ['', [Validators.required,ValidationService.dateValidatorr]],
      factures: this.formBuilder.array([
        this.initFacture(),
      ])
    })
  }
  ngOnInit() {
    this.id = localStorage.getItem('idUser')
    this.userService.getUserByID(this.id).subscribe(utilisateur => {
      this.utilisateur = utilisateur;
      if(!this.utilisateur){
        localStorage.removeItem('idUser');
        localStorage.clear();
        this.router.navigate(['/login']);
      }
      if(this.utilisateur.sousRole == 'gr-acces'){
        this.router.navigate(["accueil"]);
        return false;
      }
    },
      err => {
        return false;
      });
    this.fournisseurService.getAllUsers().then(data => {
      this.fournisseurs = data;
    },
      err => {
        return false;
      });

    this.dossierService.getUniqueNumeros().then(data => {
      this.dossiers = data;
    },
      err => {
        return false;
      });

  }
  initFacture(){
    return this.formBuilder.group({
      numFacture: ['',Validators.maxLength(25)],
      dateFacture: ['',[ValidationService.dateValidatorr]]
    });
  }
  updateNumFacture(){
    this.errorNumFacture = ""
    if(this.form.value.factures && this.form.value.factures.length > 0){
      for(var j = 0; j < this.form.value.factures.length;j++){
        if(this.form.value.factures[j].numFacture && this.form.value.factures[j].numFacture.length > 25){
          this.errorNumFacture = "Merci de vérifier le(s) numéro(s) de facture(s), maximum 25 caractères"
          return false;
        }
      }
    }
    this.errorNumFacture = ""
  }
  getBigDate(): string {
      return this.bigDate
    }
  showArrayFunction() {
    this.errorNumFacture = ""
    if(this.form.value.factures && this.form.value.factures.length > 0){
      for(var j = 0; j < this.form.value.factures.length;j++){
        if(this.form.value.factures[j].numFacture && this.form.value.factures[j].numFacture.length > 25){
          this.errorNumFacture = "Merci de vérifier le(s) numéro(s) de facture(s), maximum 25 caractères"
          return false;
        }
      }
    }
    this.errorNumFacture = ""
    this.showArray = false;
    this.errorDate = false;
    this.errorFournisseur = false;
    this.errorDateFacture = false;
    this.errorNumeroCommandeGPMI = false;
    if (!this.selectedFounrisseur) {
      this.errorFournisseur = true;
      return false;
    }
    if (this.form.value.dateLivraison < this.form.value.dateCommande) {
      this.errorDate = true;
      return false;
    }
    if ((this.utilisateur && this.utilisateur.role === 'Super admin') || (this.utilisateur && this.utilisateur.role === 'Administration') || (this.utilisateur && this.utilisateur.role === 'Administration') || (this.utilisateur && this.utilisateur.role === 'Responsable commandes')) {
      for(var j=0;j<this.form.value.factures.length;j++){
        if(this.form.value.factures[j].dateFacture && this.form.value.factures[j].dateFacture != "" && this.form.value.factures[j].dateFacture < this.form.value.dateCommande){
          this.errorDateFacture = true;
          return false;
        }
      }
    }
    this.commandeService.verifyNumeroGPMI(this.form.value).subscribe(data => {
      if(data){
        this.errorNumeroCommandeGPMI = true;
        return false;
      }else{
      this.errorFournisseur = false;
      this.errorNumeroCommandeGPMI = false;
      this.errorDateFacture = false;
      this.showArray = true;
      this.errorDate = false;
      this.showedValue = this.selectedFounrisseur.denomination
      this.modifPart2()
      }
    })
  }

  myCallback(event) {
    this.selectedFounrisseur = this.form.value.fournisseur
  }
  valuechangee(event,field) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function() {
      if (event.keyCode != 13) {
        $this.getDossierByNumeroo(event,field)
      }
    }, 2000);
  }

  getDossierByNumeroo(event,field) {
    field.nomDossier = ""
    field.client = ""
    field.idClient = ""
    if (!event || event == "") {
      return false;
    }
    this.dossierService.getDetailDossierByNumero(event).then(dossier => {
      if (dossier && dossier["client"]) {
        field.nomDossier = dossier["nom"]
        field.client = dossier["client"]["denomination"]
        field.idClient = dossier["client"]["id"]
      }
    },
      err => {
        return false;
      });
  }


  valuechange(event) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function() {
      if (event.keyCode != 13) {
        $this.getDossierByNumero(event)
      }
    }, 2000);
  }

  getDossierByNumero(event) {
    this.newAttribute.nomDossier = ""
    this.newAttribute.client = ""
    this.newAttribute.idClient = ""
    if (!event || event == "") {
      return false;
    }
    this.dossierService.getDetailDossierByNumero(event).then(dossier => {
      if (dossier && dossier["client"]) {
        this.newAttribute.nomDossier = dossier["nom"]
        this.newAttribute.client = dossier["client"]["denomination"]
        this.newAttribute.idClient = dossier["client"]["id"]
      }
    },
      err => {
        return false;
      });
  }
  initiliaseDossier() {
    this.newAttribute.nomDossier = ""
    this.newAttribute.client = ""
    this.newAttribute.idClient = ""
  }
  initiliaseDossierr(field) {
    field.nomDossier = ""
    field.client = ""
    field.idClient = ""
  }
  valuechangeReference(event) {
    this.errorRef = false;
    this.newAttribute.coef = ""
    this.newAttribute.prinet = ""
    this.newAttribute.designation = ""
    this.newAttribute.pritar = ""
    if (!event) {
      return false;
    }
    if (event && event.length < 3) {
      return false;
    }
    var me = this;
    var splitDateCommande = this.form.value.dateCommande.split('-');
    var yearDateCommande = splitDateCommande[0]
    var monthDateCommandee = splitDateCommande[1]
    setTimeout(function() {
      me.pousuivreReferChange(event,me.form.value.dateCommande,monthDateCommandee)

    }, 2000);
  }
  pousuivreReferChange(event,yearDateCommande,monthDateCommandee) {
    this.catalogueService.getAllDataByRefOrEANAndFournisseurAndCommandeLimitDate(event, this.selectedFounrisseur.id,yearDateCommande).then((data: any[]) => {
      if (data && data.length > 0) {
        var catalogueMatching = data.find(x => x.datec <= this.form.value.dateCommande && x.dateFin >= this.form.value.dateCommande);
        if(catalogueMatching){
          this.newAttribute.designation = catalogueMatching.designation25
          this.newAttribute.idFournisseur = catalogueMatching.idFournisseur
          this.newAttribute.refArt = catalogueMatching.refCiel
          this.newAttribute.pritar = catalogueMatching.tarif
          this.errorRef = false;
          return true;
        }
        if(!catalogueMatching){
          this.errorRef = true;
          return true;
        }

      } else {
        this.errorRef = true;
        return true;
      }
    },
      err => {
        this.errorRef = false;
        return false;
      });
  }

enregisterField(field,i){
  this.showErrorData = false;
  if (!field.numero || field.numero == "" || !field.nomDossier || field.nomDossier == "" || !field.refArt || field.refArt == "" || !field.pritar || field.pritar == ""
    || !field.prinet || field.prinet == "" || !field.coef || field.coef == "" || !field.quantite || field.quantite == "") {
    this.showErrorData = true;
    return false;
  }
  field.prinetTotal =  field.prinet * field.quantite
  this.sommePrixNet = this.sommePrixNet + field.prinetTotal - this.savedField.prinetTotal
  this['demandeUpdate' + i] = !this['demandeUpdate' + i]
}
  valuechangeReferencee(event, field) {
    this.errorRef = false;
    field.coef = ""
    field.prinet = ""
    field.designation = ""
    field.pritar = ""
    field.quantite = ""
    field.prinetTotal = ""
    if (!event) {
      return false;
    }
    if (event && event.length < 3) {
      return false;
    }
    var me = this;
    var splitDateCommande = this.form.value.dateCommande.split('-');
    var yearDateCommande = splitDateCommande[0]
    var monthDateCommandee = splitDateCommande[1]
    setTimeout(function() {
      me.pousuivreReferChangee(event, field, me.form.value.dateCommande, monthDateCommandee)

    }, 2000);
  }
  pousuivreReferChangee(event, field, yearDateCommande, monthDateCommandee) {
    this.catalogueService.getAllDataByRefOrEANAndFournisseurAndCommandeLimitDate(event, field.idFournisseur, yearDateCommande).then((data: any[]) => {
      if (data && data.length > 0) {
        var catalogueMatching = data.find(x => x.datec <= this.form.value.dateCommande && x.dateFin >= this.form.value.dateCommande);
        if(catalogueMatching){
          field.designation = catalogueMatching.designation25
          field.idFournisseur = catalogueMatching.idFournisseur
          field.refArt = catalogueMatching.refCiel
          field.pritar = catalogueMatching.tarif
          this.errorRef = false;
          return true;
        }
        if(!catalogueMatching){
          this.errorRef = true;
          return true;
        }

      } else {
        this.errorRef = true;
        return true;
      }
    },
      err => {
        this.errorRef = false;
        return false;
      });
  }

  updatePrinNet(prinet) {
    if (!this.newAttribute.pritar || this.newAttribute.pritar == "") {
      this.newAttribute.prinet = ""
      return false;
    }
    if (!prinet) {
      return false;
    }
    if (prinet && prinet == "") {
      return false;
    }
    if (prinet < 0) {
      this.newAttribute.prinet = ""
      return false;
    }
    var me = this;
    setTimeout(function() {
      me.newAttribute.coef = Number((prinet / me.newAttribute.pritar).toFixed(4));
      if (me.newAttribute.coef < 0 || me.newAttribute.coef > 1) {
        me.newAttribute.coef = ""
      }
    }, 2000);

  }

  updatePrinNett(prinet, field) {
    if (!field.pritar || field.pritar == "") {
      field.prinet = ""
      return false;
    }
    if (!prinet) {
      return false;
    }
    if (prinet && prinet == "") {
      return false;
    }
    if (prinet < 0) {
      field.prinet = ""
      return false;
    }
    var me = this;
    setTimeout(function() {
      field.coef = Number((prinet / field.pritar).toFixed(4));
      field.prinetTotal = prinet * field.quantite
      if (field.coef < 0 || field.coef > 1) {
        field.coef = ""
      }
    }, 2000);

  }
  updateCoef(coef) {
    if (!this.newAttribute.pritar || this.newAttribute.pritar == "") {
      this.newAttribute.coef = ""
      return false;
    }
    if (!coef) {
      return false;
    }
    if (coef && coef == "") {
      return false;
    }
    if (coef < 0 || coef > 1) {
      this.newAttribute.coef = ""
      return false;
    }
    if(coef && coef.toString().length > 6 ){
      this.newAttribute.coef = ""
      return false;
    }
    var me = this;
    setTimeout(function() {
      me.newAttribute.prinet = (coef * me.newAttribute.pritar).toFixed(2);

    }, 2000);
  }
  updateCoeff(coef, field) {
    if (!field.pritar || field.pritar == "") {
      field.coef = ""
      return false;
    }
    if (!coef) {
      return false;
    }
    if (coef && coef == "") {
      return false;
    }
    if (coef < 0 || coef > 1) {
      field.coef = ""
      return false;
    }
    if(coef && coef.toString().length > 6 ){
      field.coef = ""
      return false;
    }
    var me = this;
    setTimeout(function() {
      field.prinet = (coef * field.pritar).toFixed(2);

    }, 2000);
  }
  updatequantite(quantite) {
    if (quantite < 0 || quantite > 99999) {
      this.newAttribute.quantite = ""
      return false;
    }
    if (!Number(quantite)) {
      this.newAttribute.quantite = ""
      return false;
    }
    if (quantite && (quantite.toString().indexOf(".") > -1 || quantite.toString().indexOf(",") > -1)) {
      this.newAttribute.quantite = ""
      return false;
    }
  }
  updatequantitee(quantite, field) {
    if (quantite < 0 || quantite > 99999) {
      field.quantite = ""
      return false;
    }
    if (!Number(quantite)) {
      field.quantite = ""
      return false;
    }
    if (quantite && (quantite.toString().indexOf(".") > -1 || quantite.toString().indexOf(",") > -1)) {
      field.quantite = ""
      return false;
    }
  }
  addFieldValue() {
    this.showErrorData = false;
    if (!this.newAttribute.numero || this.newAttribute.numero == "" || !this.newAttribute.nomDossier || this.newAttribute.nomDossier == "" || !this.newAttribute.refArt || this.newAttribute.refArt == "" || !this.newAttribute.pritar || this.newAttribute.pritar == ""
      || !this.newAttribute.prinet || this.newAttribute.prinet == "" || !this.newAttribute.coef || this.newAttribute.coef == "" || !this.newAttribute.quantite || this.newAttribute.quantite == "") {
      this.showErrorData = true;
      return false;
    }
    this.newAttribute.prinetTotal =  this.newAttribute.prinet * this.newAttribute.quantite
    this.lignes.push(this.newAttribute)
    this.sommePrixNet = this.sommePrixNet + Number(this.newAttribute.prinet * this.newAttribute.quantite)
    this.newAttribute = {};
  }

  deleteFieldValue(field) {
    this.sommePrixNet = this.sommePrixNet - Number(field.prinet * field.quantite)
    this.lignes.splice(this.lignes.indexOf(field), 1);
  }

  updateField(field, i) {
    this.savedField = {...field};
    this['demandeUpdate' + i] = !this['demandeUpdate' + i]
  }
  annulerField(field, i) {
     this.lignes[i] = this.savedField
    this['demandeUpdate' + i] = !this['demandeUpdate' + i]
  }
  //Enregistrer une commande
  saveCommande() {
    Swal.fire({
      title: 'VOULEZ-VOUS CONFIRMER?',
      imageUrl: 'assets/images/client-plus.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      showCancelButton: true,
      width: 800,
      confirmButtonColor: '#008000',
      cancelButtonColor: '#FFA500',
      confirmButtonText: 'CONFIRMER',

      cancelButtonText: 'ANNULER',
    }).then((result) => {
      if (!result.dismiss) {
        this.errorLignes = false;
        if (this.lignes.length < 1) {
          this.errorLignes = true;;
          return false;
        }
        var valueArr = this.form.value.factures.map(function(item){ return item.numFacture });
        var isDuplicate = valueArr.some(function(item, idx){
          return valueArr.indexOf(item) != idx
        });
        if(isDuplicate){
          this.errorDuplicate = true;
          return false;
        }
        this.errorDuplicate = false;
        for (var j = 0; j < this.lignes.length; j++) {
          if (!this.lignes[j].numero || this.lignes[j].numero == "" || !this.lignes[j].nomDossier || this.lignes[j].nomDossier == "" || !this.lignes[j].refArt || this.lignes[j].refArt == "" || !this.lignes[j].pritar || this.lignes[j].pritar == ""
            || !this.lignes[j].prinet || this.lignes[j].prinet == "" || !this.lignes[j].coef || this.lignes[j].coef == "" || !this.lignes[j].quantite || this.lignes[j].quantite == "") {
            this.showErrorData = true;
            return false;
          }
        }
        this.form.value.listfiles = []
        this.form.value.listfilesAccuse = []
        this.form.value.listfilesFactures = []
        this.form.value.listfilesBonLivraison = []
        this.errorLignes = false;
        this.form.value.idFournisseur = this.selectedFounrisseur.id
        this.form.value.denomination = this.selectedFounrisseur.denomination
        this.form.value.etat = "Brouillon";
        this.form.value.sommePrixNet = this.sommePrixNet
        this.form.value.montantTVA = this.form.value.sommePrixNet * 0.2
        this.form.value.sommePrixNetTTC = this.form.value.sommePrixNet + this.form.value.montantTVA
        this.form.value.lignes = this.lignes
        var dayMonth = this.form.value.dateCommande.split('-')
        this.form.value.numeroCommande = "CA" + dayMonth[2] + dayMonth[1] + dayMonth[0] + this.form.value.denomination[0].toUpperCase() + this.form.value.denomination[1].toUpperCase()
        if ((!this.myfiles || (this.myfiles && this.myfiles.length < 1)) && (!this.myfilesAccuse || (this.myfilesAccuse && this.myfilesAccuse.length < 1)) && (!this.myfilesFactures || (this.myfilesFactures && this.myfilesFactures.length < 1)) && (!this.myfilesBonLivraison || (this.myfilesBonLivraison && this.myfilesBonLivraison.length < 1)) ) {
          this.commandeService.addcommande(this.form.value).subscribe(resp => {
            if(resp){
              this.saveHistoriquesCommandes(resp)
            }
            this.form.reset();
            Swal.fire( {
                imageUrl: 'assets/images/client-plus.png',
                imageWidth: 100,
                imageHeight: 100,
                imageAlt: 'Custom image',
                width: 800,
                title: 'BIEN AJOUTÉ',
                timer: 1500,
                showConfirmButton: false
              }
            );
            this.router.navigateByUrl('/commande');
            return true;
          }, err => {
          })
        }else{
          // intégration des fichiers des commandes
          if (this.myfiles && this.myfiles.length > 0) {
            this.loading = true;
            for (var x = 0; x < this.myfiles.length; x++) {
              var fileExtensionn = this.myfiles[x].name.substr((this.myfiles[x].name.lastIndexOf('.') + 1));
              //var photo = this.utilisateur.id + i + '.' + fileExtension
              //var randomVariable = Math.floor(Math.random() * (999999999999 - 100000000000)) + 100000000000;
              var randomVariable = this.form.value.numeroCommandeGPMI + "_CMD("+x+")"
              if(x == 0){
                var randomVariable = this.form.value.numeroCommandeGPMI + "_CMD"
              }
               var imgObject = {
                randomVariable: randomVariable,
                name: this.myfiles[x].name,
                photo: environment.filesPath + randomVariable + "." + fileExtensionn
              }
              this.form.value.listfiles.push(imgObject)
              this.uploadFileService.pushFilesToStorage(this.myfiles[x], this.form.value.listfiles[x].randomVariable).subscribe(event => {
              }, err => {
              })
            }
         }
         // listfilesAccuse
         if (this.myfilesAccuse && this.myfilesAccuse.length > 0) {
           this.loading = true;
           for (var t = 0; t < this.myfilesAccuse.length; t++) {
             var fileExtensionn = this.myfilesAccuse[t].name.substr((this.myfilesAccuse[t].name.lastIndexOf('.') + 1));
             //var photo = this.utilisateur.id + i + '.' + fileExtension
             //var randomVariable = Math.floor(Math.random() * (999999999999 - 100000000000)) + 100000000000;
             var randomVariable = this.form.value.numeroCommandeGPMI + "_AR("+t+")"
             if(t == 0){
               var randomVariable = this.form.value.numeroCommandeGPMI + "_AR"
             }
             var imgObject = {
               randomVariable: randomVariable,
               name: this.myfilesAccuse[t].name,
               photo: environment.filesPath + randomVariable + "." + fileExtensionn
             }
             this.form.value.listfilesAccuse.push(imgObject)
             this.uploadFileService.pushFilesToStorage(this.myfilesAccuse[t], this.form.value.listfilesAccuse[t].randomVariable).subscribe(event => {
             }, err => {
             })
           }
        }

        // myfilesFactures
        if (this.myfilesFactures && this.myfilesFactures.length > 0) {
          this.loading = true;
          for (var h = 0; h < this.myfilesFactures.length; h++) {
            var fileExtensionn = this.myfilesFactures[h].name.substr((this.myfilesFactures[h].name.lastIndexOf('.') + 1));
            //var photo = this.utilisateur.id + i + '.' + fileExtension
            //var randomVariable = Math.floor(Math.random() * (999999999999 - 100000000000)) + 100000000000;
            var randomVariable = this.form.value.numeroCommandeGPMI + "_F("+h+")"
            if(h == 0){
              var randomVariable = this.form.value.numeroCommandeGPMI + "_F"
            }
            var imgObject = {
              randomVariable: randomVariable,
              name: this.myfilesFactures[h].name,
              photo: environment.filesPath + randomVariable + "." + fileExtensionn
            }
            this.form.value.listfilesFactures.push(imgObject)
            this.uploadFileService.pushFilesToStorage(this.myfilesFactures[h], this.form.value.listfilesFactures[h].randomVariable).subscribe(event => {
            }, err => {
            })
          }
       }

       // myfilesBonLivraison
       if (this.myfilesBonLivraison && this.myfilesBonLivraison.length > 0) {
         this.loading = true;
         for (var k = 0; k < this.myfilesBonLivraison.length; k++) {
           var fileExtensionn = this.myfilesBonLivraison[k].name.substr((this.myfilesBonLivraison[k].name.lastIndexOf('.') + 1));
           //var photo = this.utilisateur.id + i + '.' + fileExtension
           //var randomVariable = Math.floor(Math.random() * (999999999999 - 100000000000)) + 100000000000;
           var randomVariable = this.form.value.numeroCommandeGPMI + "_BL("+k+")"
           if(k == 0){
             var randomVariable = this.form.value.numeroCommandeGPMI + "_BL"
           }
           var imgObject = {
             randomVariable: randomVariable,
             name: this.myfilesBonLivraison[k].name,
             photo: environment.filesPath + randomVariable + "." + fileExtensionn
           }
           this.form.value.listfilesBonLivraison.push(imgObject)
           this.uploadFileService.pushFilesToStorage(this.myfilesBonLivraison[k], this.form.value.listfilesBonLivraison[k].randomVariable).subscribe(event => {
           }, err => {
           })
         }
      }
         // intégration des fichiers des commandes
         this.commandeService.addcommande(this.form.value).subscribe(resp => {
         }, err => {
         })
         setTimeout(() => {
           /** spinner ends after 6 seconds **/
           this.loading = false;
           Swal.fire( {
               imageUrl: 'assets/images/client-plus.png',
               imageWidth: 100,
               imageHeight: 100,
               imageAlt: 'Custom image',
               width: 800,
               title: 'BIEN AJOUTÉ',
               timer: 1500,
               showConfirmButton: false
             }
           );
           this.router.navigate(['/commande'])
           return true;
         }, 10000);
        }

      }
    } );


 }
  // controler les fichiers
  selectFiles(event) {
    this.myfiles = event.target.files
    if (this.myfiles) {
      if (this.myfiles.length > 5) {
        this.myfiles = undefined;
        Swal.fire({
          imageUrl: 'assets/images/error.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 600,
          title: 'Vous pouvez ajouter seulement 5 fichiers.',
          /*timer: 1500,*/
          showConfirmButton: true,
          confirmButtonColor: '#8B0000',
          confirmButtonWidth: 100,

        }
        );
        return false;
      }
      for (var j = 0; j < this.myfiles.length; j++) {
        if (this.myfiles[j].size > this.sizeLimitOne) {
          this.myfiles = undefined;
          Swal.fire({
            imageUrl: 'assets/images/error.png',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            width: 600,
            title: 'Tous les fichiers sélectionnés ne doivent pas dépasser 10 Mo.',
            /*timer: 1500,*/
            showConfirmButton: true,
            confirmButtonColor: '#8B0000',
            confirmButtonWidth: 100,

          }
          );
          return false;
        }
      }
    } else {
      return false;
    }
  }

  // controler les fichiers accuses
  selectFilesAccuse(event) {
    this.myfilesAccuse = event.target.files
    if (this.myfilesAccuse) {
      if (this.myfilesAccuse.length > 5) {
        this.myfilesAccuse = undefined;
        Swal.fire({
          imageUrl: 'assets/images/error.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 600,
          title: 'Vous pouvez ajouter seulement 5 fichiers.',
          /*timer: 1500,*/
          showConfirmButton: true,
          confirmButtonColor: '#8B0000',
          confirmButtonWidth: 100,

        }
        );
        return false;
      }
      for (var j = 0; j < this.myfilesAccuse.length; j++) {
        if (this.myfilesAccuse[j].size > this.sizeLimitOne) {
          this.myfilesAccuse = undefined;
          Swal.fire({
            imageUrl: 'assets/images/error.png',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            width: 600,
            title: 'Tous les fichiers sélectionnés ne doivent pas dépasser 10 Mo.',
            /*timer: 1500,*/
            showConfirmButton: true,
            confirmButtonColor: '#8B0000',
            confirmButtonWidth: 100,

          }
          );
          return false;
        }
      }
    } else {
      return false;
    }
  }

  // controler les fichiers factures
  selectFilesFactures(event) {
    this.myfilesFactures = event.target.files
    if (this.myfilesFactures) {
      if (this.myfilesFactures.length > 30) {
        this.myfilesFactures = undefined;
        Swal.fire({
          imageUrl: 'assets/images/error.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 600,
          title: 'Vous pouvez ajouter seulement 30 fichiers.',
          /*timer: 1500,*/
          showConfirmButton: true,
          confirmButtonColor: '#8B0000',
          confirmButtonWidth: 100,

        }
        );
        return false;
      }
      for (var j = 0; j < this.myfilesFactures.length; j++) {
        if (this.myfilesFactures[j].size > this.sizeLimitOne) {
          this.myfilesFactures = undefined;
          Swal.fire({
            imageUrl: 'assets/images/error.png',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            width: 600,
            title: 'Tous les fichiers sélectionnés ne doivent pas dépasser 10 Mo.',
            /*timer: 1500,*/
            showConfirmButton: true,
            confirmButtonColor: '#8B0000',
            confirmButtonWidth: 100,

          }
          );
          return false;
        }
      }
    } else {
      return false;
    }
  }

  selectFilesBonLivraison(event) {
    this.myfilesBonLivraison = event.target.files
    if (this.myfilesBonLivraison) {
      if (this.myfilesBonLivraison.length > 5) {
        this.myfilesBonLivraison = undefined;
        Swal.fire({
          imageUrl: 'assets/images/error.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 600,
          title: 'Vous pouvez ajouter seulement 5 fichiers.',
          /*timer: 1500,*/
          showConfirmButton: true,
          confirmButtonColor: '#8B0000',
          confirmButtonWidth: 100,

        }
        );
        return false;
      }
      for (var j = 0; j < this.myfilesBonLivraison.length; j++) {
        if (this.myfilesBonLivraison[j].size > this.sizeLimitOne) {
          this.myfilesBonLivraison = undefined;
          Swal.fire({
            imageUrl: 'assets/images/error.png',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            width: 600,
            title: 'Tous les fichiers sélectionnés ne doivent pas dépasser 10 Mo.',
            /*timer: 1500,*/
            showConfirmButton: true,
            confirmButtonColor: '#8B0000',
            confirmButtonWidth: 100,

          }
          );
          return false;
        }
      }
    } else {
      return false;
    }
  }

  getDateCommande(): string {
    return this.dateCommande
  }
  getDateFacture(): string {
    return this.dateCommande
  }
  addFacture(){
    const control = <FormArray>this.form.controls['factures'];
    control.push(this.initFacture());
  }
  removeFacture(i: number) {
    const control = <FormArray>this.form.controls['factures'];
    control.removeAt(i);
  }
  saveHistoriquesCommandes(resp){
    var newHistoroques = {
      "module":"commande",
      "type":"création",
      "date":new Date().toISOString(),
      "idModule": resp.id,
      "idUser":this.utilisateur.id,
    }
    this.historiqueService.addhistorique(newHistoroques)
      .subscribe(data => {

      }, err => {
      })
  }
  modifPart1() {
    this.showMyContainer = 1;
  }
  modifPart2(){
    this.showMyContainer = 2;
  }
  modifPart3(){
    this.showMyContainer = 3;
  }
  modifPart4(){
    this.showMyContainer = 4;
  }
  modifPart5(){
    this.showMyContainer = 5;
  }
  showPart() {
    return this.showMyContainer;
  }
}
