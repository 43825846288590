import { Component, OnInit } from '@angular/core';
import * as XLSX from "xlsx";
import { InventaireService } from "../../../services/inventaire.service";
import { Router, ActivatedRoute } from '@angular/router';
import { CatalogueService } from "../../../services/catalogue.service";
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { ValidationService } from '../../../services/validate/validate-error.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
import { FamilleService } from "../../../services/famille.service";
import { codesSchneider } from '../../../pays/codesSchneider';

@Component({
  selector: 'app-update-inventaire',
  templateUrl: './update-inventaire.component.html',
  styleUrls: ['../../utilisateur/update-utilisateur/update-utilisateur.component.scss']
})
export class UpdateInventaireComponent implements OnInit {
  inventaire: any;
  catalogue: any;
  form: any;
  errorChamp = false;
  timeout: any = null;
  fournisseurs:any[]=[]
  familles:any;
  errorQauntite = false;
  savedData:any;
  bigDate = "9999-12-31"
  errorType:any;
  constructor(private familleService:FamilleService,private router: Router, private formBuilder: FormBuilder, private inventaireService: InventaireService, private activatedRoute: ActivatedRoute, private catalogueService: CatalogueService) {
    this.form = this.formBuilder.group({
      datec: ['', [Validators.required]],
      refArt: ['', [Validators.required, Validators.minLength(2), ValidationService.validateEmptyField, Validators.maxLength(25)]],
      designation: ['', [Validators.required, Validators.minLength(2), ValidationService.validateEmptyField, Validators.maxLength(200)]],
      pritar: ['', [Validators.required, Validators.min(0), Validators.max(999999)]],
      coefAchat: ['', [Validators.required, Validators.min(0), Validators.max(10), ValidationService.numberValidator]],
      quantite: ['', [Validators.required, Validators.min(0), Validators.max(999999), ValidationService.numberValidator]],
      quantiteEmballe: ['', [Validators.required, Validators.min(0), Validators.max(999999), ValidationService.numberValidator]],
      quantiteNonEmballe: ['', [Validators.required, Validators.min(0), Validators.max(999999), ValidationService.numberValidator]],
      fournisseur: [''],
      etage: [''],
      observation: [''],
    })
  }
  ngOnInit() {
    let params: any = this.activatedRoute.snapshot.params;
    this.inventaireService.getArticleById(params.id).subscribe(data => {
      this.inventaire = data
      if (this.inventaire) {
        this.inventaire.fournisseur = {
          "idFournisseur":this.inventaire.idFournisseur,
          "denomination":this.inventaire.denomination
        }
      }
    },
      err => {
        return false;
      });

      this.familleService.getAllData().then(data => {
        this.familles = data
      },
        err => {
          return false;
        });
  }
  getBigDate(): string {
      return this.bigDate
    }
  updateInventaire(inventaire) {
    this.errorType = ""
    if(Number(inventaire.quantite) != Number(inventaire.quantiteNonEmballe + inventaire.quantiteEmballe)){
      this.errorQauntite = true;
      return false;
    }
    if(!this.form.value.datec){
      this.errorType = "Merci de vérifier le champ date"
      return false;
    }
    if(!this.form.value.refArt || (this.form.value.refArt && this.form.value.refArt.length < 2) || (this.form.value.refArt && this.form.value.refArt.length > 200)){
      this.errorType = "Merci de vérifier le champ référence"
      return false;
    }
    if(!this.form.value.designation || (this.form.value.designation && this.form.value.designation.length < 2) || (this.form.value.designation && this.form.value.designation.length > 200)){
      this.errorType = "Merci de vérifier le champ désignation"
      return false;
    }
    if(!this.form.value.pritar || !Number(this.form.value.pritar) || (this.form.value.pritar && this.form.value.pritar < 0) || (this.form.value.pritar && this.form.value.pritar > 999999)){
      this.errorType = "Merci de vérifier le champ prix tarif"
      return false;
    }
    if(!this.form.value.coefAchat || !Number(this.form.value.coefAchat) || (this.form.value.coefAchat && this.form.value.coefAchat < 0) || (this.form.value.coefAchat && this.form.value.coefAchat > 10)){
      this.errorType = "Merci de vérifier le champ coefficient"
      return false;
    }
    if(!this.form.value.quantite || !Number(this.form.value.quantite) || (this.form.value.quantite && this.form.value.quantite < 0) || (this.form.value.quantite && this.form.value.quantite > 999999)){
      this.errorType = "Merci de vérifier le champ quantité"
      return false;
    }
    if(!this.form.value.quantiteEmballe || !Number(this.form.value.quantiteEmballe) || (this.form.value.quantiteEmballe && this.form.value.quantiteEmballe < 0) || (this.form.value.quantiteEmballe && this.form.value.quantiteEmballe > 999999)){
      this.errorType = "Merci de vérifier le champ quantité emballée"
      return false;
    }
    if(!this.form.value.quantiteNonEmballe || !Number(this.form.value.quantiteNonEmballe) || (this.form.value.quantiteNonEmballe && this.form.value.quantiteNonEmballe < 0) || (this.form.value.quantiteNonEmballe && this.form.value.quantiteNonEmballe > 999999)){
      this.errorType = "Merci de vérifier le champ quantité non emballée"
      return false;
    }
    if(!this.inventaire.idFournisseur){
      this.errorType = "Merci de vérifier le champ fournisseur"
      return false;
    }
    this.errorType = ""
    this.errorQauntite = false;
    Swal.fire({
      title: 'VOULEZ-VOUS ENREGISTRER VOS MODIFICATIONS?',
      imageUrl: 'assets/images/modifier.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      showCancelButton: true,
      width: 800,
      confirmButtonColor: '#059e00',
      cancelButtonColor: '#FF9100',
      cancelButtonText: 'ANNULER',
      confirmButtonText: 'ENREGISTRER'
    }).then((result) => {
      if (!result.dismiss) {
        this.errorChamp = false;
        if (this.form.value.quantite.toString().indexOf(",") != -1 || this.form.value.quantite.toString().indexOf(".") != -1) {
          this.errorChamp = true;
          return false;
        }
        inventaire.prinet = Number(inventaire.pritar * inventaire.coefAchat.toString().replace(",", ".")).toFixed(2)
        inventaire.montant = Number(inventaire.prinet * inventaire.quantite).toFixed(2)
        this.inventaireService.editInventairerById(inventaire)
          .subscribe(resp => {
          }, err => {

          })

        Swal.fire( {
            imageUrl: 'assets/images/statut-valide-hover.png',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            width: 800,
            title: 'BIEN MODIFIÉ',
            timer: 1500,
            showConfirmButton: false
          }
        );
        this.router.navigate(['/inventaire', inventaire.datec, inventaire.idFournisseur]);
      }
    } );

  }
valuechange(event){
  clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.getArticleByRefArt(event.target.value)
      }
    },1000);
}

getArticleByRefArt(event){
  if(event == ""){
    return false;
  }
  this.inventaire.denomination = null
  this.inventaire.idFournisseur = null
  this.inventaire.exist = false;
  while (this.fournisseurs.length > 0) {
  this.fournisseurs.pop()
  }
  this.catalogueService.getAllDataByRefOrEAN(event).then((data: any[]) => {
    if (data && data.length > 0) {
      this.savedData = data
      var myresult = data.filter(x => x.datec <= this.inventaire.datec)
      myresult.sort(this.triYearCommande);
      this.inventaire.denomination = myresult[0].denomination
      this.inventaire.designation = myresult[0].designation25
      this.inventaire.idFournisseur = myresult[0].idFournisseur
      this.inventaire.refArt = myresult[0].refCiel
      this.inventaire.exist = true;
      this.inventaire.ean13Art = myresult[0].ean13
      this.inventaire.pritar = myresult[0].tarif
      this.inventaire.datecCatalogue = myresult[0].datec
      this.inventaire.dateFinCatalogue = myresult[0].dateFin
      this.inventaire.mkt5 = myresult[0].mkt5

      if( this.inventaire.denomination == "SCHNEIDER ELECTRIC Tunisie"){

        if(this.inventaire.mkt5){
          var mymkt = this.inventaire.mkt5
          var foundFamillee = codesSchneider.filter(x => x.refFamille.toLowerCase().startsWith(mymkt[0].toLowerCase() + mymkt[1].toLowerCase()))
          if(foundFamillee && foundFamillee.length == 1){
            this.inventaire.codFamille = foundFamillee[0].codeFamille
            this.inventaire.libelleFamille = foundFamillee[0].libelle
            this.inventaire.coefFamille = foundFamillee[0].coefFamille
          }
          if(foundFamillee && foundFamillee.length > 1 && mymkt[2]){
            var foundFamilleSecond = codesSchneider.filter(x => x.refFamille.toLowerCase().startsWith(mymkt[0].toLowerCase() + mymkt[1].toLowerCase() + mymkt[2].toLowerCase()))
            if(!foundFamilleSecond){
              this.inventaire.codFamille = foundFamillee[0].codeFamille
              this.inventaire.libelleFamille = foundFamillee[0].libelle
              this.inventaire.coefFamille = foundFamillee[0].coefFamille
            }
            if(foundFamilleSecond && foundFamilleSecond.length < 1){
              this.inventaire.codFamille = foundFamillee[0].codeFamille
              this.inventaire.libelleFamille = foundFamillee[0].libelle
              this.inventaire.coefFamille = foundFamillee[0].coefFamille
            }
            if(foundFamilleSecond && foundFamilleSecond.length == 1){
              this.inventaire.codFamille = foundFamilleSecond[0].codeFamille
              this.inventaire.libelleFamille = foundFamilleSecond[0].libelle
              this.inventaire.coefFamille = foundFamilleSecond[0].coefFamille
            }
            if(foundFamillee && foundFamillee.length > 1 && mymkt[3]){
              var foundFamilleThird = codesSchneider.filter(x => x.refFamille.toLowerCase().startsWith(mymkt[0].toLowerCase() + mymkt[1].toLowerCase() + mymkt[2].toLowerCase() + mymkt[3].toLowerCase()))
              if(!foundFamilleThird){
                this.inventaire.codFamille = foundFamilleSecond[0].codeFamille
                this.inventaire.libelleFamille = foundFamilleSecond[0].libelle
                this.inventaire.coefFamille = foundFamilleSecond[0].coefFamille
              }
              if(foundFamilleThird && foundFamilleThird.length < 1){
                if(foundFamilleSecond && foundFamilleSecond[0]){
                  this.inventaire.codFamille = foundFamilleSecond[0].codeFamille
                  this.inventaire.libelleFamille = foundFamilleSecond[0].libelle
                  this.inventaire.coefFamille = foundFamilleSecond[0].coefFamille
                }
              }
              if(foundFamilleThird && foundFamilleThird.length == 1){
                this.inventaire.codFamille = foundFamilleThird[0].codeFamille
                this.inventaire.libelleFamille = foundFamilleThird[0].libelle
                this.inventaire.coefFamille = foundFamilleThird[0].coefFamille
              }
            }
          }
        }
      }
      if(this.inventaire.denomination != "SCHNEIDER ELECTRIC Tunisie"  && this.inventaire.denomination != "PHOENIX CONTACT"){
        if(this.inventaire.denomination){
          var foundFamille = this.familles.filter(x => this.inventaire.denomination.toLowerCase().indexOf(x.libelle.toLowerCase()) !== -1 )
        }
        if(foundFamille && foundFamille.length > 0){
          this.inventaire.codFamille = foundFamille[0].codFamille
          this.inventaire.libelleFamille = foundFamille[0].libelle
          this.inventaire.coefFamille = foundFamille[0].coefFamille
        }
      }
      if(this.inventaire.denomination == "PHOENIX CONTACT"){
        var foundFamille = this.familles.filter(x => x.libelle == "PHOENIX NET" )
        if(foundFamille && foundFamille.length > 0){
          this.inventaire.codFamille = foundFamille[0].codFamille
          this.inventaire.libelleFamille = foundFamille[0].libelle
          this.inventaire.coefFamille = foundFamille[0].coefFamille
        }
      }

      this.inventaire.fournisseur = {
        "idFournisseur":myresult[0].idFournisseur,
        "denomination":myresult[0].denomination,
      }
      this.getAllFournisseurs(myresult)
    }
  },
    err => {
      return false;
    });
}
selectFournisseur(){
  if(this.inventaire.fournisseur){
    this.inventaire.designation = ""
    this.inventaire.pritar = 0
    if(this.savedData && this.savedData.length >0){
      var myresult = this.savedData.filter(x => x.datec <= this.inventaire.datec && x.idFournisseur == this.inventaire.fournisseur.idFournisseur)
       if(myresult && myresult.length > 0){
         this.inventaire.designation = myresult[0].designation25
         this.inventaire.pritar = myresult[0].tarif
       }
    }
    this.inventaire.denomination = this.inventaire.fournisseur.denomination
    this.inventaire.idFournisseur = this.inventaire.fournisseur.idFournisseur

  }
}
getAllFournisseurs(data){
  this.fournisseurs.push(this.inventaire.fournisseur)
  for(var j=0;j<data.length-1;j++){
    var newFournisseur = {
      "idFournisseur":data[j].idFournisseur,
      "denomination":data[j].denomination,
    }
    if(this.fournisseurs.length > 0){
      var foundFournisseur = this.fournisseurs.find(x => x.idFournisseur == newFournisseur.idFournisseur);
      if(!foundFournisseur){
        this.fournisseurs.push(newFournisseur)
      }
    }
    if(this.fournisseurs.length < 1){
      this.fournisseurs.push(newFournisseur)
    }

  }
}
updateQteEmballe(inventaire){
  if (!inventaire.quantiteEmballe || inventaire.quantiteEmballe == undefined || inventaire.quantiteEmballe == '' || inventaire.quantiteEmballe < 0 || inventaire.quantiteEmballe > 99999) {
    inventaire.quantiteEmballe = 0
    return false;
  }
  this.calculateQuantite(inventaire)
}

calculateQuantite(inventaire){
  if (!inventaire.quantiteNonEmballe || inventaire.quantiteNonEmballe == undefined || inventaire.quantiteNonEmballe == '' || inventaire.quantiteNonEmballe < 0 || inventaire.quantiteNonEmballe > 99999) {
    return false;
  }
  if (!inventaire.quantiteEmballe || inventaire.quantiteEmballe == undefined || inventaire.quantiteEmballe == '' || inventaire.quantiteEmballe < 0 || inventaire.quantiteEmballe > 99999) {
    return false;
  }
  inventaire.quantite = inventaire.quantiteEmballe + inventaire.quantiteNonEmballe
  inventaire.quantite = Math.round( inventaire.quantite * 100) / 100
}

updateQteNonEmballe(inventaire){
  if (!inventaire.quantiteNonEmballe || inventaire.quantiteNonEmballe == undefined || inventaire.quantiteNonEmballe == '' || inventaire.quantiteNonEmballe < 0 || inventaire.quantiteNonEmballe > 99999) {
    inventaire.quantiteNonEmballe = 0
    return false;
  }
  this.calculateQuantite(inventaire)
}

triYearCommande(a, b) {
  if (a.datec > b.datec) return -1;
  else if (a.datec == b.datec) return 0;
  else return 1;
}

}
