<app-navbar></app-navbar>
<br>
<div id="content-nav-page" class="row" *ngIf="client && utilisateur">
    <div class=" col-3 titre-menu">
      <a routerLink="/agence-voyage" class=" icon-f-left"></a>
      <b class="title-haut">{{client.denomination}}<img class="ml-2" src="assets/images/show-a.png"></b>
    </div>
    <div class="col-9 sous-menu">
      <b class="sous-menu-active1" *ngIf="utilisateur.role === 'Super admin' || utilisateur.role === 'Administration'|| utilisateur.role === 'Admin' || utilisateur.role === 'Utilisateur-client'" [routerLink]="['/agence-voyage']">AGENCES DE VOYAGE</b>
      
    </div>
  </div>
  <div class="row" *ngIf="client">
  
    <div class="col-xl-2 col-lg-3  col-md-3 col-sm-4 left-content-scrolbar ">
  
    <div class="col col-content ">
      <button class="tablink2 btn-liste"  id="defaultOpen2" (click)="modifPart1()">
        INFORMATIONS AGENCE DE VOYAGE</button>
    </div>
  
    <div class="col col-content">
      <button class="tablink2 btn-liste "  (click)="modifPart2()"> INFORMATIONS
        COMPLÉMENTAIRES </button>
    </div>
  
    <div class="col col-content">
      <button class="tablink2 btn-liste "  (click)="modifPart3()"> ÉTABLISSEMENTS
         </button>
    </div>
  
    <div class="col col-content" *ngIf="roleAdministration">
      <button class="tablink2 btn-liste "  (click)="modifPart4()"> COORDONNÉES BANCAIRES
         </button>
    </div>
    <div class="col col-content">
      <button class="tablink2 btn-liste "  (click)="modifPart5()"> STATISTIQUES
         </button>
    </div>
    </div>
  
  <div class="col-xl-10 col-lg-9  col-md-9 col-sm-8 right-content-scrolbar">
  <div class="card p-3">
    <div class="row">
      <div class="col-2" style="left: 70%">
        <div class="  card-header" style="border: 1px solid green;background-color: green; width: 220px;margin-bottom: 1%">
          <!--<div class="  card-header" style="border: 1px solid green;background-color: green; width: 220px;margin-left: 80%;margin-bottom: 1%">-->
          <div>
            <b class="d-flex justify-content-center"> CHARGÉS D'AFFAIRES </b>
          </div>
          <div>
            <b class="d-flex justify-content-center" *ngIf="idAllCA">{{idAllCA.length}}</b>
          </div>
        </div>
      </div>
    </div>
  
    <div *ngIf="showPart()==1" class="col-content-affiche tabcontent2" id="information" style="padding-top: 2%">
  
    <div >
    <div class="card-body">
  
      <div class="row">
        <div class="col-4">
          <div class="title">
            <h4>Identifiant unique</h4>
            <span class="font-weight-bold">{{client.siren[0]}}{{client.siren[1]}}{{client.siren[2]}}{{client.siren[3]}}{{client.siren[4]}}{{client.siren[5]}}{{client.siren[6]}}{{client.siren[7]}}{{client.siren[8]}} {{client.siren[9]}}{{client.siren[10]}}{{client.siren[11]}}{{client.siren[12]}}{{client.siren[13]}}
   </span>
          </div>
  
          <br/>
          <div class="title ">
            <h4>Acronyme</h4>
            <span class="font-weight-bold"> {{client.acronyme}}</span>
          </div>
          <br/>
  
          <div class="title">
            <h4>Enseigne</h4>
            <span class="font-weight-bold"> {{client.enseigne}}</span></div>
          <br/>
          <div class="title">
            <h4>Code activité</h4>
            <span class="font-weight-bold">{{client.indicatif}}</span>
  
          </div>
          <br/>
          <div class="title">
            <h4>Dénomination</h4>
            <span class="font-weight-bold">{{client.denomination}}</span>
  
          </div>
        </div>
        <div class="col-4 text-xl-center">
          <div class="text-center" >
            <img src="{{client.linkLogo}}" style="width: 50%;padding-top: 20%">
          </div>
        </div>
        <div class="col-4">
          <div class="title">
            <h4>Type</h4>
            <span class="font-weight-bold"> {{client.type}}</span>
          </div>
          <br/>
  
          <div class="title">
            <h4>Nom commercial</h4>
            <span class="font-weight-bold">{{client.nomc}} </span></div>
          <br/>
  
          <div class="title">
            <h4>Statut</h4>
            <span class="font-weight-bold"> {{client.statut}}</span></div>
          <br/>
  
          <div class="title"  *ngIf="client.statut === 'Radiée'">
            <h4 >Date de radiation</h4>
            <span class="font-weight-bold">{{client.dateRadiation | date : 'dd/MM/yyyy'}}</span></div>
  
          <br/>
        </div>
      </div>
    </div>
    </div>
    </div>
  
    <ng-template #customLoadingTemplate></ng-template>
    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading>
    <div *ngIf="showPart()==2" class="col-content-affiche tabcontent2" id="information_compl" style="padding-top: 2%">
  
    <div >
      <div class="card-body">
  
        <div class="row">
          <div class="col-4">
            <div class="title">
              <h4>Email</h4>
              <span class="font-weight-bold">{{client.mail}} </span>
            </div>
  
            <br/>
            <div class="title ">
              <h4>Téléphone</h4>
              <span class="font-weight-bold">{{client.tel}} </span>
            </div>
            <br/>
  
            <div class="title">
              <h4>Pays</h4>
              <span class="font-weight-bold">{{client.adresse.pays}}</span></div>
            <br/>
            <div class="title">
              <h4>Code postal</h4>
              <span class="font-weight-bold">{{client.adresse.codePostal}}</span>
  
            </div>
          </div>
          <div class="col-4">
            <div class="title">
              <h4>Rue</h4>
              <span class="font-weight-bold">{{client.adresse.rue}} </span>
            </div>
  
            <br/>
            <div class="title ">
              <h4>Numéro</h4>
              <span class="font-weight-bold">{{client.adresse.n}} </span>
            </div>
            <br/>
  
            <div class="title">
              <h4>Ville</h4>
              <span class="font-weight-bold">{{client.adresse.ville}}</span></div>
            <br/>
          </div>
  
          <div class="col-4">
            <div class="title">
              <h4>Facebook</h4>
              <span class="font-weight-bold"><a href="{{client.adresseWeb.facebook}}" target="_blank">Lien</a></span>
            </div>
            <br/>
  
            <div class="title">
              <h4>Linkedin</h4>
              <span class="font-weight-bold"><a href="{{client.adresseWeb.linkedin}}" target="_blank">Lien</a></span></div>
            <br/>
  
            <div class="title">
              <h4>RNE</h4>
              <span class="font-weight-bold"><a href="{{client.adresseWeb.infogreffe}}" target="_blank">Lien</a></span></div>
  
            <br/>
            <div class="title">
              <h4>Site web</h4>
              <span class="font-weight-bold"><a href="{{client.adresseWeb.siteWeb}}" target="_blank">Lien</a> </span>
            </div>
  
          </div>
        </div>
        <br><br><br>
        <div class="title">
          <h4>Historiques adresses</h4>
        </div>
        <div class="row ligne-table2  d-flex justify-content-center">
          <table class="table-responsive">
            <th></th>
            <th>Adresse</th>
            <tr class="text-center" *ngFor="let historique of client.historiquesAdresses| paginate: { id:'enfantsPagg',itemsPerPage: 25, currentPage: enfantpp };index as i">
              <td>{{i + 1}}</td>
              <td >{{historique.n}} {{historique.rue}} {{historique.codePostal}} {{historique.ville}} {{historique.pays}}</td>
            </tr>
          </table>
          <div class="row">
            <div class="col col-xs-6">
              <pagination-controls previousLabel="Précédent" nextLabel="Suivant" (pageChange)="enfantpp = $event" id="enfantsPagg"></pagination-controls>
            </div>
          </div>
  
  
        </div>
      </div>
    </div>
    </div>
  
    <div *ngIf="showPart()==3" class="col-content-affiche tabcontent2" id="etablissement" style="padding-top: 2%">
  
      <div >
        <div class="title">
          <h4>Multi établissement</h4>
          <span class="font-weight-bold"> {{client.multiEtablissement}} </span>
        </div>
        <div class="row ligne-table2  d-flex justify-content-center">
          <table class="table-responsive">
            <tr >
              <td class="col-3 p-l-0">
                Nom
              </td>
              <td class="col-3 p-l-0">
                Type d'établissement
              </td>
              <td class="col-3 p-l-0">
                Email
              </td>
              <td class="col-3 p-l-0">
               Téléphone
              </td>
              <td class="col-3 p-l-0">
                Statut
              </td>
              <td class="col-3 p-l-0">
                Date de radiation
              </td>
            </tr>
            <tr  *ngFor="let etablissement of client.etablissements| paginate: { itemsPerPage: 4, currentPage: p }">
            <td *ngIf="(etablissement.statutEtab != 'Radiée' && utilisateur && utilisateur.role != 'Super admin') || ( utilisateur && utilisateur.role === 'Super admin')">{{etablissement.nom}}</td>
            <td *ngIf="(etablissement.statutEtab != 'Radiée' && utilisateur && utilisateur.role != 'Super admin') || ( utilisateur && utilisateur.role === 'Super admin')">{{etablissement.type}}</td>
            <td *ngIf="(etablissement.statutEtab != 'Radiée' && utilisateur && utilisateur.role != 'Super admin') || ( utilisateur && utilisateur.role === 'Super admin')">{{etablissement.mail}}</td>
            <td *ngIf="(etablissement.statutEtab != 'Radiée' && utilisateur && utilisateur.role != 'Super admin') || ( utilisateur && utilisateur.role === 'Super admin')">{{etablissement.tel}}</td>
            <td *ngIf="(etablissement.statutEtab != 'Radiée' && utilisateur && utilisateur.role != 'Super admin') || ( utilisateur && utilisateur.role === 'Super admin')">{{etablissement.statutEtab}}</td>
            <td *ngIf="(etablissement.statutEtab != 'Radiée' && utilisateur && utilisateur.role != 'Super admin') || ( utilisateur && utilisateur.role === 'Super admin')"><span *ngIf="etablissement.statutEtab === 'Radiée'">{{etablissement.dateRadiationEtab | date : 'dd/MM/yyyy'}}</span></td>
          </tr>
  
          </table>
        </div>
  
      </div>
    </div>
  
  
    <div *ngIf="showPart()==4 && roleAdministration"   class="col-content-affiche tabcontent2" id="coordonnees_bancaires" style="padding-top: 2%">
  
    <div >
      <div class="card-body">
  
        <div class="row">
          <div class="col-6">
            <div class="title">
              <h4>Nom</h4>
              <span class="font-weight-bold">{{client.informationbancaire.nom}} </span>
            </div>
  
            <br/>
            <div class="title ">
              <h4>Swift</h4>
              <span class="font-weight-bold">{{client.informationbancaire.swift}} </span>
            </div>
            <br/>
  
            <div class="title">
              <h4>Bic</h4>
              <span class="font-weight-bold">{{client.informationbancaire.bic}}</span></div>
  
          </div>
  
          <div class="col-6">
  
            <div class="title">
              <h4>Iban</h4>
              <span class="font-weight-bold">{{client.informationbancaire.iban}}</span></div>
  
            <br/>
            <div class="title">
              <h4>Rib</h4>
              <span class="font-weight-bold">{{client.informationbancaire.rib}} </span>
            </div>
  
          </div>
        </div>
      </div>
    </div>
    </div>
  
    <div *ngIf="showPart()==5" class="col-content-affiche tabcontent2" id="static">
      <h3 align="center">{{client.denomination}} : <span *ngIf="clientAllDossiers">{{clientAllDossiers.length}}</span> Dossiers</h3>
      <div>
        <div class="card-body">
          <div>
            <div class="title">
              <h4>Nombre des dossiers par type et par année</h4>
            </div>
            <div class="row ligne-table2  d-flex justify-content-center table-padding">
              <table class="table-responsive" style="text-align: center;">
                <tr>
                  <td class="left">
                    Types
                  </td>
                  <td *ngFor="let year of allyears" class="right">
                    {{year}}
                  </td>
                </tr>
                <tr class="text-center">
                  <td class="left">Etude</td>
                  <td *ngFor="let year of allyears" class="right">
                    {{filterBytype[year]["Etude"].length }}
                  </td>
  
                </tr>
                <tr class="text-center">
                  <td class="left">Réalisation</td>
                  <td *ngFor="let year of allyears" class="right">
                    {{filterBytype[year]["Réalisation"].length }}
                  </td>
                </tr>
                <tr class="text-center">
                  <td class="left">Intervention</td>
                  <td *ngFor="let year of allyears" class="right">
                    {{filterBytype[year]["Intervention"].length }}
                  </td>
  
                </tr>
                <tr class="text-center">
                  <td class="left">Fournitures</td>
                  <td *ngFor="let year of allyears" class="right">
                    {{filterBytype[year]["Fournitures"].length }}
                  </td>
                </tr>
                <tr class="text-center">
                  <td></td>
                  <td></td>
                </tr>
                <tr class="text-center">
                  <td class="left">Total</td>
                  <td *ngFor="let year of allyears" class="right">
                    {{filterBytype[year]["Etude"].length + filterBytype[year]["Réalisation"].length + filterBytype[year]["Intervention"].length + filterBytype[year]["Fournitures"].length }}
                  </td>
  
                </tr>
              </table>
            </div>
          </div>
          <div style="align-items: center; padding-top: 50px;">
            <div class="title">
              <h4>Nombre des dossiers par statut et par année</h4>
            </div>
            <div class="row ligne-table2  d-flex justify-content-center table-padding">
              <table class="table-responsive">
                <tr>
                  <td class="left">Statut</td>
                  <td *ngFor="let year of allyears" class="right">{{year}}</td>
                </tr>
                <tr>
                  <td class="left">Non affecté</td>
                  <td *ngFor="let year of allyears" class="right">
                    {{filterByStatut[year]["01 - Non affecté"].length }}
                  </td>
                </tr>
                <tr>
                  <td class="left">Vide</td>
                  <td *ngFor="let year of allyears" class="right">
                    {{filterByStatut[year]["02- Vide"].length }}
                  </td>
                </tr>
                <tr>
                  <td class="left">NE</td>
                  <td *ngFor="let year of allyears" class="right">
                    {{filterByStatut[year]["03 - Null"].length }}
                  </td>
                </tr>
                <tr>
                  <td class="left">En cours</td>
                  <td *ngFor="let year of allyears" class="right">
                    {{filterByStatut[year]["04 - En cours"].length }}
                  </td>
                </tr>
                <tr>
                  <td class="left">En attente</td>
                  <td *ngFor="let year of allyears" class="right">
                    {{filterByStatut[year]["05 - En attente"].length }}
                  </td>
                </tr>
                <tr>
                  <td class="left">Envoyé</td>
                  <td *ngFor="let year of allyears" class="right">
                    {{filterByStatut[year]["06 - Envoyé"].length }}
                  </td>
                </tr>
                <tr>
                  <td class="left">Perdu</td>
                  <td *ngFor="let year of allyears" class="right">
                    {{filterByStatut[year]["07 - Perdu"].length }}
                  </td>
                </tr>
  
                <tr>
                  <td class="left">DM</td>
                  <td *ngFor="let year of allyears" class="right">
                    {{filterByStatut[year]["08 - DM"].length }}
                  </td>
                </tr>
                <tr>
                  <td class="left">CDE</td>
                  <td *ngFor="let year of allyears" class="right">
                    {{filterByStatut[year]["09 - CDE"].length }}
                  </td>
                </tr>
                <tr>
                  <td class="left">FAB</td>
                  <td *ngFor="let year of allyears" class="right">
                    {{filterByStatut[year]["10 - FAB"].length }}
                  </td>
                </tr>
                <tr>
                  <td class="left">Livrée</td>
                  <td *ngFor="let year of allyears" class="right">
                    {{filterByStatut[year]["11 - Livrée"].length }}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td class="left">Total</td>
                  <td *ngFor="let year of allyears" class="right">
                    {{filterByStatut[year]["01 - Non affecté"].length + filterByStatut[year]["02- Vide"].length + filterByStatut[year]["03 - Null"].length  + filterByStatut[year]["04 - En cours"].length
                      + filterByStatut[year]["05 - En attente"].length + filterByStatut[year]["06 - Envoyé"].length + filterByStatut[year]["07 - Perdu"].length +
                       filterByStatut[year]["08 - DM"].length + filterByStatut[year]["09 - CDE"].length + filterByStatut[year]["10 - FAB"].length + filterByStatut[year]["11 - Livrée"].length
  
                     }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
  
          <div style="align-items: center; padding-top: 50px;">
            <div class="title">
              <h4>Nombre des dossiers par chargé d'affaires et par année</h4>
            </div>
            <div class="row ligne-table2  d-flex justify-content-center table-padding">
              <table class="table-responsive">
                <tr>
                  <td class="left">Chargé d'affaires</td>
                  <td *ngFor="let year of allyears" class="right">{{year}}</td>
                </tr>
                <tr *ngFor="let idACA of idAllCA">
                  <td class="left">{{dossierbyidClientFilterBYCA[idACA][0].yeardateReception.prenomCA}} {{dossierbyidClientFilterBYCA[idACA][0].yeardateReception.nomCA}}</td>
                  <td *ngFor="let year of allyears;let i=index" class="right">
                    <span *ngFor="let liste of dossierbyidClientFilterBYCA[idACA]; let j = index">
                      <span *ngIf=" liste.yeardateReception.yeardateReception == allyears[i]">{{liste.number}}</span>
                    </span>
                  </td>
                </tr>
              </table>
            </div>
          </div>
  
          <div style="align-items: center; padding-top: 50px;">
            <div class="title">
              <h4>Nombre des dossiers par chiffreur et par année</h4>
            </div>
            <div class="row ligne-table2  d-flex justify-content-center table-padding">
              <table class="table-responsive">
                <tr>
                  <td class="left">Chiffreur</td>
                  <td *ngFor="let year of allyears" class="right">{{year}}</td>
                </tr>
                <tr *ngFor="let idACH of idAllCH">
                  <th  class="left">{{dossierbyidClientFilterBYCH[idACH][0].yeardateReception.prenomCH}} {{dossierbyidClientFilterBYCH[idACH][0].yeardateReception.nomCH}}</th>
                  <td *ngFor="let year of allyears;let i=index" class="right">
                    <span *ngFor="let liste of dossierbyidClientFilterBYCH[idACH]; let j = index">
                      <span *ngIf=" liste.yeardateReception.yeardateReception == allyears[i]">{{liste.number}}</span>
                    </span>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  
  </div>
  </div>
  </div>
  