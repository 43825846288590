import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarModule } from '../shared/navbar/navbar.module';
import { CLIENTRoutingModule } from './client-routing.module';
import { ClientComponent } from './client/client.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ControleMessagesRoutingModule } from '../shared/controle-messages/controle-messages-routing.module';
import { ControleMessagesModule } from '../shared/controle-messages/controle-messages.module';
import { InternationalPhoneNumberModule } from 'ng-phone-number';
import { NgxPaginationModule } from 'ngx-pagination';
import { SearchClientPipe } from 'src/app/search/searchClient.pipe';
import { AjoutClientComponent } from './ajout-client/ajout-client.component';
import { DetailsClientComponent } from './details-client/details-client.component';
import { UpdateClientComponent } from './update-client/update-client.component';
import { NgxLoadingModule } from "ngx-loading";
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { BsModalModule } from 'ng2-bs3-modal';
import { ClientGuard } from 'src/app/guards/client.guard';
@NgModule({
  declarations: [ClientComponent,SearchClientPipe, AjoutClientComponent, DetailsClientComponent, UpdateClientComponent],
  imports: [
    CommonModule,
    NavbarModule,
    FormsModule, ReactiveFormsModule,
    CLIENTRoutingModule,
    ControleMessagesModule,
    ControleMessagesRoutingModule,
    NgxPaginationModule,
    NgbModule,
    InternationalPhoneNumberModule,
    NgbModalModule,BsModalModule,
    NgxLoadingModule.forRoot({backdropBackgroundColour: 'rgba(0, 0, 0, 0.26)',
        fullScreenBackdrop:true,
        backdropBorderRadius: '4px',
        primaryColour: '#ffffff',
        secondaryColour: '#ffffff',
        tertiaryColour: '#ffffff'})
  ],
  providers:[ClientGuard]
})
export class CLIENTModule { }
