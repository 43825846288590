import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
const headers = new HttpHeaders({Authorization: 'Basic ' + btoa('UserkrendelAdminnew:krl2021!!??**SoC24-415fr-a410-ttx9J8NbPecEL6k4BkAXvGE3t8f3nuEZmXa5RNJBAdrnh6XqB2')})
.set('Content-Type', 'application/json')
.set('Accept', 'application/json');
headers.append('Access-Control-Allow-Origin', '*');
import 'rxjs/add/operator/map';

@Injectable({
  providedIn: 'root'
})
export class HistoriqueService {


  constructor(private http: HttpClient) {
  }

  saveAllData(client) {
    return this.http.post(environment.host + '/historique/addall', JSON.stringify(client), {headers: headers})
  }
  getAllData(){
    return new Promise(resolve => {
      this.http.get(environment.host + '/historique', {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }

  addhistorique(ihistorique) {
    return this.http.post(environment.host + '/historique/addOne', JSON.stringify(ihistorique), { headers: headers })
  }
  getDataByDateAndUser(date,user,type){
    return new Promise(resolve => {
      this.http.get(environment.host + '/historique/getDataByDateAndUser/'+date+'/'+user+'/'+type, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getDataByUser(user){
    return new Promise(resolve => {
      this.http.get(environment.host + '/historique/getDataByUser/'+user, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getAllDataByRefByYear(year){
    return new Promise(resolve => {
      this.http.get(environment.host + '/historique/getallhistoriqueByrefByYear/'+year, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  deleteAllData(){
    return new Promise(resolve => {
      this.http.delete(environment.host + '/historique/deleteall', {headers: headers} ).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  deleteArticleById(id){
    return new Promise(resolve => {
      this.http.delete(environment.host + '/historique/' + id,{headers: headers} ).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getArticleById(id){
    return this.http.get(environment.host + '/historique/'+id,{headers: headers})
  }
  getArticleByRefArt(refArt){
    return new Promise(resolve => {
      this.http.get(environment.host + '/historique/byRef/'+refArt, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }

  getAllFournisseurofArticles(){
    return new Promise(resolve => {
      this.http.get(environment.host + '/historique/groupedBy/fournisseurId', {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getAllFournisseurofArticlesWithYear(id){
    return new Promise(resolve => {
      this.http.get(environment.host + '/historique/groupedBy/fournisseurId/and/year/'+id, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }

  getAllDataByDateByFournisseur(id,date){
    return new Promise(resolve => {
      this.http.get(environment.host + '/historique/getByDateFournisseur/'+id+'/'+date, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getAllDataByDate(date){
    return new Promise(resolve => {
      this.http.get(environment.host + '/historique/getByDate/'+date, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }

  getihistoriqueactivationByDate(date){
    return new Promise(resolve => {
      this.http.get(environment.host + '/ihistoriqueactivation/getByDate/'+date, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }

  getAllDataByDateAndFilter(date){
    return new Promise(resolve => {
      this.http.get(environment.host + '/historique/filtre/getByDate/'+date, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  filterIhistoriqueByDatec(){
    return new Promise(resolve => {
      this.http.get(environment.host + '/historique/group/ihistorique', {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  filterIhistoriqueBySingleDatec(year){
    return new Promise(resolve => {
      this.http.get(environment.host + '/historique/group/ihistorique/singleDate/'+year, {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  editIhistoriquerById(ihistorique){
    return this.http.put(environment.host + '/historique/' + ihistorique.id, JSON.stringify(ihistorique), { headers: headers })
  }
}
