import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http } from '@angular/http';
import { environment } from '../../../../environments/environment';
import { UserService } from "../../../services/user.service";
import { CommandeService } from "../../../services/commande.service";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
import { FormBuilder, FormArray } from '@angular/forms';
import { ValidationService } from '../../../services/validate/validate-error.service';
import { UploadFileService } from "../../../services/upload-file-service.service";


@Component({
  selector: 'app-details-commande',
  templateUrl: './details-commande.component.html',
  styleUrls: ['../../utilisateur/details-utilisateur/details-utilisateur.component.css'],
})
export class DetailsCommandeComponent implements OnInit {
  utilisateur: any;
  id: any;
  numberData = 25;
  commande: any;
  showMyContainer = 1;
  mydate = new Date().toISOString().split('T')[0]
  dateInsertion = new Date().toISOString().split('T')[0]
  quantiteInsertion = 1
  errorMsg: any;
  selectedLigne: any;
  form: any;
  errorDateFacture = false;
  myfiles:any;
    sizeLimitOne = 10000000; //10MB
  allfiles:any[]=[]
  p = 1;
  constructor(private uploadFileService: UploadFileService, private formBuilder: FormBuilder, private http: Http, private router: Router, private userService: UserService, private commandeService: CommandeService, private activatedRoute: ActivatedRoute, ) {
    this.form = this.formBuilder.group({
      listfilesBonLivraison: this.formBuilder.array([
        this.initlistfilesBonLivraison(),
      ])
    })
  }
  ngOnInit() {
    let params: any = this.activatedRoute.snapshot.params;
    this.id = localStorage.getItem('idUser')
    this.userService.getUserByID(this.id).subscribe(utilisateur => {
      this.utilisateur = utilisateur;
      if(!this.utilisateur){
        localStorage.removeItem('idUser');
        localStorage.clear();
        this.router.navigate(['/login']);
      }
    },
      err => {
        return false;
      });

    this.commandeService.getArticleById(params.id).subscribe(data => {
      this.commande = data;
    },
      err => {
        return false;
      });
  }
  initlistfilesBonLivraison() {
    return this.formBuilder.group({
      file: [''],
      date: ['', [ValidationService.dateValidatorr]]
    });
  }
  modifPart1() {
    this.showMyContainer = 1;
  }
  modifPart2() {
    this.showMyContainer = 2;
  }
  modifPart3() {
    this.showMyContainer = 3;
  }
  modifPart4() {
    this.showMyContainer = 4;
  }
  modifPart5() {
    this.showMyContainer = 5;
  }
  modifPart6() {
    this.showMyContainer = 6;
  }
  modifPart7() {
    this.showMyContainer = 7;
  }
  showPart() {
    return this.showMyContainer;
  }
  updateQunatite(ligne) {
    ligne = this.selectedLigne
    //console.log(ligne.refArt)
    this.errorMsg = ""
    if (!ligne.quantiteRecue) {
      ligne.quantiteRecue = 0
    }
    if (!Number(this.quantiteInsertion) || this.quantiteInsertion < 1 || this.quantiteInsertion > 999999) {
      this.errorMsg = "Merci de vérifier la quantité ( entre 1 et 999999)"
      return false;
    }
    if (!this.dateInsertion || this.dateInsertion > this.mydate || this.dateInsertion < this.commande.dateCommande) {
      this.errorMsg = "Merci de vérifier la date"
      return false;
    }
    if (Number(ligne.quantite - ligne.quantiteRecue) < this.quantiteInsertion) {
      this.errorMsg = "Il vous reste " + Number(ligne.quantite - ligne.quantiteRecue) + " articles à insérer"
      return false;
    }
    this.errorMsg = ""
    ligne.quantiteRecue = Number(ligne.quantiteRecue + this.quantiteInsertion)
    ligne.repliquat = Number(ligne.quantite - ligne.quantiteRecue)
    if (!ligne.HistoriqueScan) {
      ligne.HistoriqueScan = []
    }
    ligne.HistoriqueScan.push({
      "quantiteInsertion": this.quantiteInsertion,
      "dateInsertion": this.dateInsertion,
      "dateSysteme": new Date().toISOString()
    })
    Swal.fire({
      imageUrl: 'assets/images/client-plus.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      width: 800,
      title: 'BIEN AJOUTÉ',
      timer: 1500,
      showConfirmButton: false
    }
    );
  }
  getDateCommande(): string {
    return this.mydate
  }
  chooseLigne(ligne) {
    this.errorMsg = ""
    this.dateInsertion = new Date().toISOString().split('T')[0]
    this.quantiteInsertion = 1
    this.selectedLigne = ligne
  }
  enregistrer() {
    Swal.fire({
      title: 'VOULEZ-VOUS ENREGISTRER VOS MODIFICATIONS?',
      imageUrl: 'assets/images/modifier.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      showCancelButton: true,
      width: 800,
      confirmButtonColor: '#059e00',
      cancelButtonColor: '#FF9100',
      cancelButtonText: 'ANNULER',
      confirmButtonText: 'ENREGISTRER'
    }).then((result) => {
      if (!result.dismiss) {
        var foundrepliquat = this.commande.lignes.find(x => x.repliquat != "0")
        if (foundrepliquat) {
          this.commande.etat = "Partiellement livrée"
        }
        if (!foundrepliquat) {
          this.commande.etat = "Soldée"
        }
        this.errorDateFacture = false;
        if (this.form.value.listfilesBonLivraison && this.form.value.listfilesBonLivraison.length > 0) {
          for (var j = 0; j < this.form.value.listfilesBonLivraison.length; j++) {
            if (this.form.value.listfilesBonLivraison[j].date && this.form.value.listfilesBonLivraison[j].date > this.mydate || this.form.value.listfilesBonLivraison[j].date && this.form.value.listfilesBonLivraison[j].date < this.commande.dateCommande) {
              this.errorDateFacture = true;
              return false;
            }
          }
        }
        this.errorDateFacture = false;
        var listfilesbl = []
        if (this.allfiles && this.allfiles.length > 0) {
          for (var k = 0; k < this.allfiles.length; k++) {
            if (this.allfiles[k][0].name) {
              var fileExtensionn = this.allfiles[k][0].name.substr((this.allfiles[k][0].name.lastIndexOf('.') + 1));
              var randomVariable = this.commande.numeroCommandeGPMI + "_BL(" + Number(k + this.commande.listfilesBonLivraison.length) + ")"
              if (k == 0) {
                var randomVariable = this.commande.numeroCommandeGPMI + "_BL(" + this.commande.listfilesBonLivraison.length + ")"
              }
              var imgObject = {
                randomVariable: randomVariable,
                name: this.allfiles[k][0].name,
                photo: environment.filesPath + randomVariable + "." + fileExtensionn,
                date: this.form.value.listfilesBonLivraison[k].date
              }

              this.commande.listfilesBonLivraison.push(imgObject)
              this.uploadFileService.pushFilesToStorage(this.allfiles[k][0], randomVariable).subscribe(event => {
              }, err => {
              })
            }
          }
        }
        this.commandeService.editIcommandesrById(this.commande).subscribe(resp => {
          setTimeout(() => {
            /** spinner ends after 6 seconds **/
            Swal.fire({
              imageUrl: 'assets/images/statut-valide-hover.png',
              imageWidth: 100,
              imageHeight: 100,
              imageAlt: 'Custom image',
              width: 800,
              title: 'BIEN ENREGISTRÉ',
              timer: 1500,
              showConfirmButton: false
            }
            );
            this.router.navigate(['/commande'])
          }, 5000);

        }, err => {
        })
      }
    });
  }
  getDateFacture(): string {
    return this.commande.dateCommande
  }
  addBonLivraison() {
    const control = <FormArray>this.form.controls['listfilesBonLivraison'];
    control.push(this.initlistfilesBonLivraison());
  }
  removeBonLivraison(i: number) {
    const control = <FormArray>this.form.controls['listfilesBonLivraison'];
    control.removeAt(i);
    this.allfiles.splice(i, 1);
  }
  selectFiles(event) {
    this.myfiles = event.target.files
    if (this.myfiles) {
        if (this.myfiles.size > this.sizeLimitOne) {
          this.myfiles = undefined;
          Swal.fire({
            imageUrl: 'assets/images/error.png',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            width: 600,
            title: 'Tous les fichiers sélectionnés ne doivent pas dépasser 10 Mo.',
            /*timer: 1500,*/
            showConfirmButton: true,
            confirmButtonColor: '#8B0000',
            confirmButtonWidth: 100,

          }
          );
          return false;
        }
        this.allfiles.push(this.myfiles)
    } else {
      return false;
    }
  }
  valuechangequantiteInsertion(event){


      setTimeout(() => {
        if(this.quantiteInsertion < 1){
      this.quantiteInsertion = 1
      }
      }, 5000);

  }
}

