<app-navbar></app-navbar>  
<br>
<div id="content-nav-page" class="row">
    <div class=" col-3 titre-menu">
      <a routerLink="/fournisseur" class=" icon-f-left"></a>
      <b style="margin-right: 10%" class="title-haut">Nouveau fournisseur</b>
      <img src="assets/images/client-plus.png">
    </div>
    <div class="col-9 sous-menu">
      <b class="sous-menu-active1"  [routerLink]="['/fournisseur']">FOURNISSEUR</b>
    </div>
  </div>
  <div class="card p-3">
    <div class="col d-flex justify-content-start">
      <b class="obligatoir-text">(*) Ce champ est obligatoire</b>
    </div>
    <div class="card-header " style="border: 1px solid green;margin-bottom: 1%;">
      <b style="color:grey">INFORMATIONS FOURNISSEUR</b>
    </div>
  
    <div *ngIf="showPart()==1" style="padding-top: 1%">
      <form [formGroup]="form" role="form">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-10">
              <div class="title">
                <select class="form-control input-sm select-options" id="type" formControlName="type" name="type" (change)="selectType()" [attr.disabled]="disabled ? '' : null">
                  <option>Particulier</option>
                  <option>Professionnel</option>
                </select>
              </div>
              <br />
            </div>
            <br>
            <div class="col-lg-6 col-md-6 col-sm-10">
              <div class="title">
                <input class="font-weight-bold" style="margin-top:50px" placeholder="Identifiant unique*" autocomplete="off" type="number" (keyup)="onKeyUp($event)" (change)="onKeyUp($event)" min="0" max="99999999999999" formControlName="siren" name="siren" [attr.disabled]="disabled ? '' : null">
                <control-messages [control]="form.controls.siren"></control-messages>
                <p *ngIf="errorOnchange" style="color:red">
                  14 chiffres maximum
                </p>
                <p style="color:red" *ngIf="errorSiret">L'dentifiant unique existe dèjà !</p>
                <p style="color:red" *ngIf="errorLengthSiren">L'dentifiant unique doit obligatoirement être composé de 14 chiffres !</p>
              </div>
            </div>
            <br>
          </div>
          <div class=" col d-flex justify-content-center">
            <div class="col-auto nbr-dossier ">
              <table>
                <tr>
                  <td style="padding-right: 30px;">
                    <button class="btn-valider" style="width: 200px;height: 50px;" *ngIf="!showChamps" (click)="verifySiret()">Vérifier<img src="assets/images/btn-valider.png"></button>
                  </td>
                  <td style="padding-right: 30px;">
                    <button class="btn-annule" style="width: 200px;height: 50px;" *ngIf="showAnnulationBouton" (click)="annulerSiret()">Annuler <img src="assets/images/btn-annule.png"></button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="row" *ngIf="showChamps">
            <div class="col-lg-6 col-md-6 col-sm-10">
              <br>
              <div class="title">
                <input class="font-weight-bold" formControlName="denomination" name="denomination" placeholder="Dénomination*">
                <control-messages [control]="form.controls.denomination"></control-messages>
                <p *ngIf="form.controls['denomination'].touched && form.controls['denomination'].hasError('maxlength')" style="color:red">
                  100 caractères maximum
                </p>
  
              </div>
              <br />
              <div class="title">
                <input class="font-weight-bold" placeholder="Nom commercial" formControlName="nomc" name="nomc">
                <control-messages [control]="form.controls.nomc"></control-messages>
              </div>
              <br />
              <div class="title">
                <select class="form-control input-sm select-options" id="formeJuridique" placeholder="Forme juridique*" formControlName="formeJuridique" name="formeJuridique">
                  <option>Société anonyme SA</option>
                  <option >Société anonyme à directoire et conseil de surveillance</option>
                  <option>Société à responsabilité limitée SARL</option>
                  <option>Société à responsabilité limitée à associé unique SARLAU</option>
                  <option>Société par actions simplifiée SAS</option>
                  <option>Société par actions simplifiée à associé unique SASAU</option>
                  <option>Entreprise unipersonnelle à responsabilité limitée EURL</option>
                  <option>Société coopérative à forme anonyme SCFA</option>
                </select>
                <control-messages [control]="form.controls.formeJuridique"></control-messages>
  
              </div>
              <br />
              <div class="title">
                <input class="font-weight-bold" type="date" formControlName="datec" name="datec" [max]="getdatenow()" placeholder="Immatriculé le">
                <control-messages [control]="form.controls.datec"></control-messages>
              </div>
              <br />
              <div class="title">
                <select class="form-control input-sm select-options" id="statut" formControlName="statut" name="statut" (change)="showAndHoideDateRadiationFunc()">
                  <option>Active</option>
                  <option>Radiée</option>
                </select>
                <control-messages [control]="form.controls.statut"></control-messages>
              </div>
            </div>
            <br>
            <div class="col-lg-6 col-md-6 col-sm-10">
              <br />
              <div class="title ">
                <input class="font-weight-bold" formControlName="acronyme" name="acronyme" placeholder="Acronyme">
                <control-messages [control]="form.controls.acronyme"></control-messages>
                <p *ngIf="form.controls['acronyme'].touched && form.controls['acronyme'].hasError('maxlength')" style="color:red">
                  100 caractères maximum
                </p>
              </div>
              <br />
              <div class="title">
                <input class="font-weight-bold" placeholder="Enseigne" formControlName="enseigne" name="enseigne">
                <control-messages [control]="form.controls.enseigne"></control-messages>
              </div>
              <br />
              <div class="title">
                <select class="form-control input-sm select-options" id="codeNaf" formControlName="codeNaf" name="codeNaf" (change)="showSociete()">
                  <option value="" disabled selected>Code activité*</option>
                  <option *ngFor="let code of codes" [ngValue]="code">{{code.fields.code_naf}}</option>
                </select>
                <control-messages [control]="form.controls.codeNaf"></control-messages>
                <p style="position: relative;top: -42px;margin-bottom: -55px;" *ngIf="secteur">{{secteur}}</p>
              </div>
              <br />
              <div class="title">
                <label for="file"></label>
                <input class="input-btn-file " (change)="selectFiles($event)" type="file" id="file" accept="image/*" placeholder="Logo" style="position: relative;top: -20px;">
                <a id="placeholderTel" style="top: -85px;left:100px;">Logo</a>
              </div>
              <br />
              <div class="title" *ngIf="showAndHoideDateRadiation">
                <input class="font-weight-bold" placeholder="Date de radiation" type="date" formControlName="dateRadiation" name="dateRadiation" [max]="getBigDate()">
                <control-messages [control]="form.controls.dateRadiation"></control-messages>
              </div>
            </div>
            <br>
          </div>
        </div>
        <div class="">
          <p *ngIf="errorFistPart" style="color:red;position: relative;left: 50px;top: 40px;">{{errorFistPart}}</p>
        </div>
        <div class=" col d-flex justify-content-center" *ngIf="showChamps">
          <div class="col-auto nbr-dossier ">
            <table>
              <tr>
                <td style="padding-right: 30px;">
                  <button class="btn-valider" style="width: 200px;height: 50px;"  (click)="modifPart2()">Suivant <img src="assets/images/btn-valider.png"></button>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <br><br>
      </form>
    </div>
    <div class="card-header " style="border: 1px solid green;margin-bottom: 1%;">
      <b style="color:grey">INFORMATIONS COMPLÉMENTAIRES</b>
    </div>
    <div *ngIf="showPart()==2" style="padding-top: 1%">
      <form [formGroup]="formCom" role="form">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-10">
            <div class="title ">
              <input class="font-weight-bold" formControlName="mail" name="mail" placeholder="Email*" style="color: #1b1e21">
              <control-messages [control]="formCom.controls.mail"></control-messages>
            </div>
            <br />
            <div class="title" style="margin-top: 2px;">
              <international-phone-number class="mystyle" formControlName="tel" placeholder="N° fixe*" [maxlength]="13" [defaultCountry]="'tn'" [locale]="'fr'" [required]="true" name="tel"></international-phone-number>
              <a id="placeholderTel">N° fixe*</a>
              <control-messages [control]="formCom.controls.tel"></control-messages>
            </div>
            
            <br />
          </div>
          <br>
          <div class="col-lg-4 col-md-6 col-sm-10" formGroupName="adresse" style="margin-top: -20px;">
            <div class="title">
              <select class="form-control input-sm select-options" id="pays" formControlName="pays" name="pays" style="width: 99%;">
                <option *ngFor="let py of pys" [attr.value]="py.name" [attr.selected]="py.name == 'Tunisie' ? true : null">{{py.name}}</option>
              </select>
              <control-messages [control]="formCom.controls.adresse.controls.pays"></control-messages>
  
            </div>
            <br />
            <div class="title" style="margin-top: -52px;">
              <input class="font-weight-bold" placeholder="Code postal" type="number" min="0" formControlName="codePostal" name="codePostal" style="color: #1b1e21">
            <control-messages [control]="formCom.controls.adresse.controls.codePostal"></control-messages>
          </div>
            <br />
  
  
            <div class="title">
              <input class="font-weight-bold" placeholder="Rue" formControlName="rue" name="rue" style="color: #1b1e21">
              <control-messages [control]="formCom.controls.adresse.controls.rue"></control-messages>
            </div>
  
            <br />
            <div class="title">
              <input class="font-weight-bold" type="number" min="0" formControlName="n" name="n" placeholder="Numéro" style="color: #1b1e21">
              <control-messages [control]="formCom.controls.adresse.controls.n"></control-messages>
            </div>
            <br />
            <div class="title">
              <select class="form-control input-sm select-options" id="ville" formControlName="ville" name="ville" style="width: 99%;margin-top: 39px;margin-bottom: 31px;">
                <option *ngFor="let ville of vlls" [attr.value]="ville.ville">{{ville.ville}}</option>
              </select>
              <control-messages [control]="formCom.controls.adresse.controls.ville"></control-messages>
            </div>
          </div>
          <br>
          <div class="col-lg-4 col-md-6 col-sm-10" formGroupName="adresseWeb">
            <div class="title">
              <input class="font-weight-bold" placeholder="Facebook" formControlName="facebook" name="facebook" style="color: #1b1e21">
            </div>
            <br />
  
            <div class="title">
              <input class="font-weight-bold" placeholder="Linkedin" formControlName="linkedin" name="linkedin" style="color: #1b1e21">
            </div>
            <br />
  
            <div class="title">
              <input class="font-weight-bold" formControlName="infogreffe" name="infogreffe" placeholder="RNE" style="color: #1b1e21">
            </div>
  
            <br />
            <div class="title">
              <input class="font-weight-bold" formControlName="siteWeb" name="siteWeb" placeholder="Site web" style="color: #1b1e21">
            </div>
  
          </div>
          <br>
        </div>
      </div>
      <div class="">
        <p *ngIf="errorSecondPart" style="color:red;position: relative;left: 50px;top: 40px;">{{errorSecondPart}}</p>
      </div>
      <div class=" col d-flex justify-content-center">
        <div class="col-auto nbr-dossier ">
          <table>
            <tr>
              <td style="padding-right: 30px;">
                <button class="btn-annule" style="width: 200px;height: 50px;" (click)="modifPart1()">Précédent <img src="assets/images/btn-annule.png"></button>
              </td>
              <td style="padding-right: 30px;">
                <button class="btn-valider" style="width: 200px;height: 50px;"(click)="modifPart4()">Suivant <img src="assets/images/btn-valider.png"></button>
              </td>
            </tr>
          </table>
          <br><br>
        </div>
  
      </div>
      </form>
    </div>
    <div class="card-header " style="border: 1px solid green;margin-bottom: 1%;">
      <b style="color:grey">ÉTABLISSEMENTS</b>
    </div>
    <div *ngIf="showPart()==4" style="padding-top: 1%">
      <form [formGroup]="formEtablissement"  role="form">
      <div class="row">
        <div  class="col-lg-6 col-md-6 col-sm-10" style="margin-left: 23%">
          <select class="form-control input-sm select-options" id="multiEtablissement" formControlName="multiEtablissement" name="multiEtablissement" style="width: 99%;" (change)="showEtablissment()">
                <option>Non</option>
                <option>Oui</option>
          </select>
          <control-messages [control]="formEtablissement.controls.multiEtablissement"></control-messages>
        </div>
         <br>
      </div>
      <div class="row" style="margin-bottom: -5%" *ngIf="addEtablissments">
        <div class="col-12" style="padding-bottom: 2%;margin-top: 3%">
          <div>
            <div>
              <div style="margin-top: 30px">
                <div class="row ">
                  <table class="table-ligne table-responsive">
                    <tr>
                      <th class="text-center">NOM</th>
                      <th class="text-center">TYPE D'ÉTABLISSEMENT</th>
                      <th class="text-center">EMAIL </th>
                      <th class="text-center">STATUT </th>
                      <th class="text-center ">ACTIONS</th>
                    </tr>
                    <tr class="text-center" *ngFor="let etablissement of etablissements"  >
                     <td>{{etablissement.nom}}</td>
                     <td>{{etablissement.type}}</td>
                     <td>{{etablissement.mail}}</td>
                     <td>{{etablissement.statutEtab}}</td>
                     <td>
                       <a  (click)="removeEtablissement(etablissement)" title="Supprimer"><img src="assets/images/supp-modal.png" class="delete-img" alt=""></a>
                       <!-- <a (click)="seteblissement(etablissement);updateModal.open(etablissement)" title="Modifier"><img src="assets/images/modif-hover.png" class="delete-img" alt=""></a> -->
  
                     </td>
                  </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td style="height: 76px;"><a href="#" title="Ajouter" data-toggle="modal" data-target="#myModal"><img src="assets/images/add-cmd.png"></a>
                      </td>
  
                    </tr>
  
                  </table>
                </div>
  
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div class=" col d-flex justify-content-center" style="margin-top: 25px;">
        <div class="col-auto nbr-dossier ">
          <table>
            <tr>
              <td>
                <button class="btn-annule" style="width: 200px;height: 50px;" (click)="modifPart2()">Précédent <img src="assets/images/btn-annule.png"> </button>
              </td>
              <td style="padding-right: 30px;">
                <button class="btn-valider" style="width: 200px;height: 50px;" (click)="modifPart3()">Suivant <img src="assets/images/btn-valider.png"></button>
  
              </td>
            </tr>
  
          </table>
          <br><br>
        </div>
  
      </div>
      </form>
    </div>
  
  
    <div class="card-header " style="border: 1px solid green;margin-bottom: 1%;">
      <b style="color:grey">COORDONNÉES BANCAIRES</b>
    </div>
  
  
  
    <div *ngIf="showPart()==3" style="padding-top: 1%">
      <form [formGroup]="formBancaire"  role="form">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-10">
            <div class="title">
              <input class="font-weight-bold" placeholder="Nom" style="color: #1b1e21" formControlName="nom" name="nom" >
            </div>
            <br />
            <div class="title ">
              <input class="font-weight-bold" placeholder="Swift" formControlName="swift" name="swift" style="color: #1b1e21">
            </div>
            <br />
  
            <div class="title">
              <input class="font-weight-bold" placeholder="Bic" formControlName="bic" name="bic" style="color: #1b1e21">
            </div>
          </div>
          <br>
          <div class="col-lg-6 col-md-6 col-sm-10">
            <div class="title">
              <input class="font-weight-bold" placeholder="Iban" formControlName="iban" name="iban" style="color: #1b1e21">
            </div>
  
            <br />
            <div class="title">
              <input class="font-weight-bold" placeholder="Rib" formControlName="rib" name="rib" style="color: #1b1e21">
            </div>
  
          </div>
          <br>
        </div>
      </div>
      <div class="">
        <p *ngIf="errorFourthPart" style="color:red;position: relative;left: 50px;top: 40px;">{{errorFourthPart}}</p>
      </div>
      <div class=" col d-flex justify-content-center">
        <div class="col-auto nbr-dossier ">
          <table>
            <tr>
              <td>
                <button class="btn-annule" style="width: 200px;height: 50px;" (click)="modifPart4()">Précédent <img src="assets/images/btn-annule.png"></button>
  
              </td>
              <td style="padding-right: 30px;">
                <button class="btn-valider" style="width: 200px;height: 50px;" (click)="onRegisterFournisseur()">Ajouter <img src="assets/images/save.png"></button>
              </td>
            </tr>
  
          </table>
        </div>
  
      </div>
      </form>
    </div>
  </div>
  <!-- --------------------------modal scan ----------------------- -->
  
  <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 1000px">
      <div class="modal-content modal-content-scan" style="width: 200%;top: -170px;height: 635px;">
        <div class="modal-header d-flex justify-content-center">
          <h3 class="titre-modal-statut">
            NOUVEL ÉTABLISSEMENT
          </h3>
        </div>
        <div class="modal-body" style="height: 400px;overflow: scroll;">
          <div class="col d-flex justify-content-start">
            <b class="obligatoir-text">(*) Ce champ est obligatoire</b>
          </div>
          <form [formGroup]="formAddEtablissement">
          <div class="row" style="margin-top: 25px;">
            <div class="col-lg-6 col-md-6 col-sm-10">
              <div class="title">
                <input class="font-weight-bold" placeholder="Nom*" formControlName="nom" name="nom">
                <control-messages [control]="formAddEtablissement.controls.nom"></control-messages>
              </div>
  
              <br />
              <div class="title">
                <select class="form-control input-sm select-options" id="etat" formControlName="type" name="type" >
                <option >Etablissement principale</option>
                <option> Etablissement secondaire</option>
              </select>
              <control-messages [control]="formAddEtablissement.controls.type"></control-messages>
              </div>
            </div>
            <br>
            <div class="col-lg-6 col-md-6 col-sm-10">
              <div class="title">
                <input class="font-weight-bold" placeholder="Email*" formControlName="mail" name="mail">
                <control-messages [control]="formAddEtablissement.controls.mail"></control-messages>
              </div>
              
              <br>
              <div class="title">
                <select class="form-control input-sm select-options" id="statut" formControlName="statutEtab" name="statutEtab" (change)="showAndHoideDateRadiationFuncEtab()">
                <option>Active</option>
                <option>Radiée</option>
              </select>
              </div>
            </div>
          </div>
          <div class="row" formGroupName="adresse">
            <div class="col-lg-6 col-md-6 col-sm-10">
              <div class="title" style="margin-top: -50px;">
                <select class="form-control input-sm select-options" id="etat" formControlName="pays" name="pays" >
                <option value="Tunisie" selected>Tunisie</option>
                <option *ngFor="let py of pys">{{py.name}}</option>
              </select>
              </div>
              <br />
              <div class="title" style="margin-top: -56px;">
                <input class="font-weight-bold" placeholder="Code postal" type="number" formControlName="codePostal" name="codePostal">
              </div>
              <br>
              <div class="title">
                <input class="font-weight-bold" placeholder="Rue" type="text" formControlName="rue" name="rue">
              </div>
            </div>
            <br>
            <div class="col-lg-6 col-md-6 col-sm-10">
              <div class="title" >
                <input class="font-weight-bold" placeholder="Numéro" type="number" formControlName="n" name="n">
              </div>
              <br>
              <div class="title" >
                <select class="form-control input-sm select-options" id="ville" formControlName="ville" name="ville" style="width: 99%;margin-top: 36px;margin-bottom: 31px;">
                  <option *ngFor="let ville of vlls" [attr.value]="ville.ville">{{ville.ville}}</option>
                </select>
              </div>
            </div>
            <br>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-10">
              <br>
              <div class="title" *ngIf="showAndHoideDateRadiationEtab">
                <input class="font-weight-bold" placeholder="Date de radiation" type="date" formControlName="dateRadiationEtab" name="dateRadiationEtab" [max]="getBigDate()">
              </div>
              <br>
            </div>
            <br>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <div class=" col d-flex justify-content-center" style="padding-bottom: 1%; margin-bottom: 20px">
              <div class="col-auto nbr-dossier " style="padding-top: 0px;">
                <table>
                  <tr>
                    <td style="padding-right: 30px;">
                      <button class="btn-valider" style="width: 200px;height: 50px;" data-dismiss="modal" (click)="addnewEtablissment()" [disabled]="!formAddEtablissement.valid">Ajouter <img src="assets/images/save.png"></button>
  
                    </td>
                    <td style="padding-right: 30px;">
                      <button class="btn-annule" style="width: 200px;height: 50px;" data-dismiss="modal">Annuler <img src="assets/images/btn-annule.png"> </button>
  
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  
  <!-- modal add nouvelle établissement -->
  <div class="row">
  
    <bs-modal #updateModal>
  
      <div class="modal-content" style="width: 200%;top: -15px;left: -120px;">
        <div class="col d-flex justify-content-start">
          <b class="obligatoir-text">(*) Ce champ est obligatoire</b>
        </div>
        <form [formGroup]="formAddEtablissementUpdate">
        <div class="row" style="margin-top: 25px;" *ngIf="etablissement">
          <div class="col-lg-6 col-md-6 col-sm-10">
            <div class="title">
              <input class="font-weight-bold" placeholder="Nom*" [(ngModel)]="etablissement.nom" name="etablissement.nom" value="etablissement.nom" formControlName="nom" >
              <control-messages [control]="formAddEtablissementUpdate.controls.nom"></control-messages>
            </div>
  
            <br />
            <div class="title">
              <select class="form-control input-sm select-options" id="etat" [(ngModel)]="etablissement.type" name="etablissement.type" value="etablissement.type" formControlName="type" >
              <option >Etablissement principale</option>
              <option> Etablissement secondaire</option>
            </select>
            <control-messages [control]="formAddEtablissementUpdate.controls.type"></control-messages>
            </div>
          </div>
          <br>
          <div class="col-lg-6 col-md-6 col-sm-10">
            <div class="title">
              <input class="font-weight-bold" placeholder="Email*" [(ngModel)]="etablissement.mail" name="etablissement.mail" value="etablissement.mail" formControlName="mail" >
              <control-messages [control]="formAddEtablissementUpdate.controls.mail"></control-messages>
            </div>
            <br />
            
            <div class="title">
              <select class="form-control input-sm select-options" id="statut" formControlName="statutEtab"  [(ngModel)]="etablissement.statutEtab" name="etablissement.statutEtab" value="etablissement.statutEtab">
              <option>Active</option>
              <option>Radiée</option>
            </select>
            </div>
          </div>
        </div>
        <div class="row" formGroupName="adresse" *ngIf="etablissement">
          <div class="col-lg-6 col-md-6 col-sm-10">
            <div class="title" style="margin-top: -120px;">
              <select class="form-control input-sm select-options" id="etat" formControlName="pays" [(ngModel)]="etablissement.adresse.pays" name="etablissement.adresse.pays" >
              <option value="Tunisie" selected>Tunisie</option>
              <option *ngFor="let py of pys">{{py.name}}</option>
            </select>
            </div>
            <br />
            <div class="title" style="margin-top: -56px;">
              <input class="font-weight-bold" placeholder="Code postal" type="number" formControlName="codePostal" [(ngModel)]="etablissement.adresse.codePostal" name="etablissement.adresse.codePostal" value="etablissement.adresse.codePostal" >
            </div>
            <br>
            <div class="title">
              <input class="font-weight-bold" placeholder="Rue" type="text" formControlName="rue" [(ngModel)]="etablissement.adresse.rue" name="etablissement.adresse.rue" value="etablissement.adresse.rue">
            </div>
          </div>
          <br>
          <div class="col-lg-6 col-md-6 col-sm-10">
            <div class="title" >
              <input class="font-weight-bold" placeholder="Numéro" type="number" formControlName="n" [(ngModel)]="etablissement.adresse.n" name="etablissement.adresse.n" value="etablissement.adresse.n">
            </div>
            <br>
            <div class="title" >
              <select class="form-control input-sm select-options" id="etat" formControlName="ville" [(ngModel)]="etablissement.adresse.ville" name="etablissement.adresse.ville">
                <option value="etablissement.adresse.ville" selected>{{etablissement.adresse.ville}}</option>
                <option *ngFor="let ville of vlls">{{ville.ville}}</option>
              </select>
            </div>
          </div>
          <br>
        </div>
        <div class="row" *ngIf="etablissement">
          <div class="col-lg-6 col-md-6 col-sm-10">
            <br>
            <div class="title" *ngIf="etablissement.statutEtab === 'Radiée'">
              <input class="font-weight-bold" placeholder="Date de radiation" type="date" formControlName="dateRadiationEtab" [max]="getBigDate()" [(ngModel)]="etablissement.dateRadiationEtab" name="etablissement.dateRadiationEtab" value="etablissement.dateRadiationEtab">
            </div>
            <br>
          </div>
          <br>
        </div>
        <div class="modal-footer d-flex justify-content-center" *ngIf="etablissement">
          <div class=" col d-flex justify-content-center" style="padding-bottom: 1%; margin-bottom: 20px">
            <div class="col-auto nbr-dossier ">
              <table>
                <tr>
                  <td style="padding-right: 30px;">
                    <button class="btn-valider" style="width: 200px;height: 50px;" data-dismiss="modal" (click)="editEtablissment(etablissement)" [disabled]="!formAddEtablissementUpdate.valid">Modifier <img src="assets/images/save.png"></button>
  
                  </td>
                  <td style="padding-right: 30px;">
                    <button class="btn-annule" style="width: 200px;height: 50px;" data-dismiss="modal">Annuler <img src="assets/images/btn-annule.png"> </button>
  
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        </form>
      </div>
  
  
    </bs-modal>
  </div>
  <!-- modal add nouvelle établissement -->
  