import { Component, OnInit } from '@angular/core';
import * as XLSX from "xlsx";
import { CatalogueService } from "../../../services/catalogue.service";
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { FournisseurService } from "../../../services/fournisseur.service";
import { Router, ActivatedRoute } from '@angular/router';
import { isNumeric } from 'rxjs/util/isNumeric';
import { ValidationService } from '../../../services/validate/validate-error.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
import { UserService } from "../../../services/user.service";

@Component({
  selector: 'app-ajout-catalogue',
  templateUrl: './ajout-catalogue.component.html',
  styleUrls: ['../../utilisateur/ajout-utilisateur/ajout-utilisateur.component.scss'],
})
export class AjoutCatalogueComponent implements OnInit {
  fileReaded: any;
  totalInventories: any;
  AOA: any;
  listeArtciels: any[] = []
  listeArtcielsSecond: any[] = []
  listeAllArtciels: any;
  loading = false;
  sizeLimitOne = 30000000; //30MB
  allowInformations = true;
  allowInformationsComp = false;
  numberData = 10;
  text: any;
  tarifGlobal = 0;
  addedLength = 0;
  errorObjet: any;
  fileToChoose: any;
  arraydesN = ["C", "U", "M"]
  filterArticle: any;
  form: any;
  fournisseurs: any;
  selectedFounisseur: any;
  errorDate = false
  dateDebut = new Date().toISOString().split('T')[0]
  showMyContainer = 1;
  id:any;
  bigDate = "9999-12-31"
  utilisateur:any;
  constructor(private userService:UserService,private catalogueService: CatalogueService, private formBuilder: FormBuilder, private fournisseurService: FournisseurService, private router: Router) {
    this.form = this.formBuilder.group({
      fournisseur: ['', Validators.required],
      datec: ['', [Validators.required, ValidationService.dateValidatorr]],
      dateFin: ['', [Validators.required, ValidationService.dateValidatorr]],
      file: ['']
    })
  }
  ngOnInit() {
    this.id = localStorage.getItem('idUser')
    this.userService.getUserByID(this.id).subscribe(utilisateur => {
      this.utilisateur = utilisateur;
      if(!this.utilisateur){
        localStorage.removeItem('idUser');
        localStorage.clear();
        this.router.navigate(['/login']);
      }
      if(this.utilisateur.sousRole == 'gr-acces'){
        this.router.navigate(["accueil"]);
        return false;
      }
    },
      err => {
        return false;
      });
    this.getAllFournisseurs()
    /*this.loading = true;
    this.catalogueService.getAllData().then(data => {
      this.listeAllArtciels = data
      this.getAllFournisseurs()
      this.loading = false;
    },
      err => {
        return false;
      });*/
  }
  getAllFournisseurs() {
    this.fournisseurService.getAllUsers().then(data => {
      this.fournisseurs = data;
    },
      err => {
        return false;
      });
  }
  getBigDate(): string {
      return this.bigDate
    }
  // get data
  onFileChange(file: any) {
    // Vider le tableau et réinitialiser l'affichage
    /* wire up file reader */

    var myfile = file.target.files.item(0)
    if (myfile) {
      this.fileToChoose = undefined
      this.listeArtciels = []
      this.listeArtcielsSecond = []
      this.tarifGlobal = 0;
      this.addedLength = 0;
      this.errorObjet = " "
      if (!myfile.type.match('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet|application/vnd.ms-excel')) {
        file = undefined
        Swal.fire({
          imageUrl: 'assets/images/error.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 600,
          title: 'Impossible de télécharger ce fichier.',
          /*timer: 1500,*/
          showConfirmButton: true,
          confirmButtonColor: '#8B0000',
          confirmButtonWidth: 100,

        }
        );
        return false;
      }
      if (myfile.size > this.sizeLimitOne) {
        file = undefined
        Swal.fire({
          imageUrl: 'assets/images/error.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 600,
          title: 'Un fichier sélectionné est trop volumineux.La taille maximale est 30 Mo.',
          /*timer: 1500,*/
          showConfirmButton: true,
          confirmButtonColor: '#8B0000',
          confirmButtonWidth: 100,

        }
        );
        return false;
      }
      this.loading = true;
      const target: DataTransfer = <DataTransfer>(file.target);
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        var data = (XLSX.utils.sheet_to_json(ws, { header: 1 }));
        for (var i = 1; i < data.length; i++) {
          var object = {
            "fournisseur": data[i][0], "mqe": data[i][1], "refCiel": data[i][2], "gamme": data[i][3], "designation25": data[i][4], "tarif": data[i][5], "qt": data[i][6], "hautp": data[i][7],
            "largp": data[i][8], "profp": data[i][9], "poidsp": data[i][10], "diam": data[i][11], "statut": data[i][12], "typ": data[i][13], "delaiLiv": data[i][14], "dateLancement": data[i][15],
            "dateArret": data[i][16], "ean13": data[i][17], "garantie": data[i][18], "edi": data[i][19], "dtDebut": data[i][20], "dtFin": data[i][21], "mkt1": data[i][22], "mktl1": data[i][23],
            "mkt2": data[i][24], "mktl2": data[i][25], "mkt3": data[i][26], "mktl3": data[i][27], "mkt4": data[i][28], "mktl4": data[i][29], "mkt5": data[i][30], "mktl5": data[i][31],
            "designation80": data[i][32], "datec": this.form.value.datec, "dateFin": this.form.value.dateFin,
            "denomination": this.form.value.fournisseur.denomination, "idFournisseur": this.form.value.fournisseur.id
          }
          if (object.refCiel && i < 30000) {
            //this.checkValidationObject(object, i);
            /*if (!isNumeric(object.tarif)) {
              this.errorObjet = " Merci de vérifier le prix tarif de la ligne " + Number(i + 1)
              this.tarifGlobal = 0;
              this.listeArtciels = [];
              this.listeArtcielsSecond = [];
              this.loading = false;
              return false;
            }*/
            this.listeArtciels.push(object)
            this.tarifGlobal = this.tarifGlobal + Number(data[i][5])
          }
          if (object.refCiel && i >= 30000) {
            //this.checkValidationObject(object, i);
            if (!isNumeric(object.tarif)) {
              this.errorObjet = " Merci de vérifier le prix tarif de la ligne " + Number(i + 1)
              this.tarifGlobal = 0;
              this.listeArtciels = [];
              this.listeArtcielsSecond = [];
              this.loading = false;
              return false;
            }
            this.listeArtcielsSecond.push(object)
            this.tarifGlobal = this.tarifGlobal + Number(data[i][5])
          }
        }
        this.addedLength = this.listeArtciels.length + this.listeArtcielsSecond.length
        this.loading = false;
      };
      if (target.files.length > 0) {

        reader.readAsBinaryString(target.files[0]);
      }
    } else {

      return false;
    }

  }
  // validation elements of file
  /*checkValidationObject(object, i) {
    if (object.refCiel) {
      this.listeAllArtciels.some((el) => {
        if (el.refCiel === object.refCiel) {
          this.errorObjet = " les refCiels de la ligne " + Number(i + 1) + " et la ligne " + Number(this.listeAllArtciels.indexOf(el) + 2) + " sont identiques";
          return false;
        }
      });
    }
    else if (object.mqe && object.mqe.length < 3) {
      this.errorObjet = " Vérifier MQE à la ligne " + Number(i + 1);
      return false;
    }
    else if (object.n && this.arraydesN.indexOf(object.n) < 0) {
      this.errorObjet = " Vérifier N à la ligne " + Number(i + 1);
      return false;
    }
  }*/

  saveData() {
    this.errorDate = false;
    if (this.loading) {
      Swal.fire({
        imageUrl: 'assets/images/error.png',
        imageWidth: 100,
        imageHeight: 100,
        imageAlt: 'Custom image',
        width: 600,
        title: 'L\'upload des données est en cours ! Veuillez patienter',
        /*timer: 1500,*/
        showConfirmButton: true,
        confirmButtonColor: '#8B0000',
        confirmButtonWidth: 100,

      }
      );
      return false;
    }
    if (this.listeArtciels.length < 1) {
      return false;
    }
    if (this.form.value.datec > this.form.value.dateFin) {
      this.errorDate = true;
      return false;
    }
    this.loading = true;
    this.catalogueService.saveAllData(this.listeArtciels)
      .subscribe(resp => {
        if (this.listeArtcielsSecond.length > 0) {
          this.catalogueService.saveAllData(this.listeArtcielsSecond)
            .subscribe(resp => {
              this.loading = false;
              Swal.fire({
                imageUrl: 'assets/images/statut-valide-hover.png',
                imageWidth: 100,
                imageHeight: 100,
                imageAlt: 'Custom image',
                width: 800,
                title: 'BIEN INTÉGRÉ',
                timer: 1500,
                showConfirmButton: false
              }
              );
              this.router.navigateByUrl('/catalogue');
              return true;
            }, err => {
              this.loading = false;
              return false;
            })
        }
        if (this.listeArtcielsSecond.length < 1) {
          this.loading = false;
          Swal.fire({
            imageUrl: 'assets/images/statut-valide-hover.png',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            width: 800,
            title: 'BIEN INTÉGRÉ',
            timer: 1500,
            showConfirmButton: false
          }
          );
          this.router.navigateByUrl('/catalogue');
          return false;
        }
      }, err => {
        this.loading = false;
        return false;
      })
    /*setTimeout(() => {
      /** spinner ends after 6 seconds **/
    /*console.log("hello")
  }, 50000);*/
    /*this.listeArtciels = []
    this.listeArtcielsSecond = []*/
    this.fileToChoose = undefined
  }
  selectFournisseur() {
    this.selectedFounisseur = this.form.value.fournisseur
  }
  getDateDebut(): string {
    return this.dateDebut
  }
  modifPart1() {
    this.showMyContainer = 1;
  }
  modifPart2(){
    this.showMyContainer = 2;
  }
  showPart() {
    return this.showMyContainer;
  }
}

