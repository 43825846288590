import { Component, OnInit } from '@angular/core';
import * as XLSX from "xlsx";
import { StockService } from "../../../services/stock.service";
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from "../../../services/user.service";
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { CatalogueService } from "../../../services/catalogue.service"
import { HistoriqueService } from "../../../services/historique.service";
import { ValidationService } from '../../../services/validate/validate-error.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
import { codesSchneider } from '../../../pays/codesSchneider';
import { FamilleService } from "../../../services/famille.service";

@Component({
  selector: 'app-stock-entree',
  templateUrl: './stock-entree.component.html',
  styleUrls: ['../../utilisateur/ajout-utilisateur/ajout-utilisateur.component.scss'],
})
export class StockEntreeComponent implements OnInit {
  form: any;
  dateDebut = new Date().toISOString().split('T')[0]
  showArray = false;
  fieldArray: Array<any> = [];
  newAttribute: any = {};
  loading = false;
  showErrorData = false
  showErrorDataQuantite = false
  fieldArrayy: Array<any> = [];
  id:any;
  utilisateur:any;
  unqieusdates:any;
  myDateInvetaire = new Date().toISOString().split('T')[0]
  familles:any;
  bigDate = "9999-12-31"
  inventaireConfigObj:any;
  constructor(private familleService:FamilleService,private stockService: StockService, private activatedRoute:ActivatedRoute,private userService:UserService,private catalogueService: CatalogueService,private historiqueService:HistoriqueService,
   private formBuilder: FormBuilder, private router: Router) {
    this.form = this.formBuilder.group({
      datec: ['', [Validators.required,ValidationService.dateValidatorr]]
    })
  }
  ngOnInit() {

    this.id = localStorage.getItem('idUser')
    this.userService.getUserByID(this.id).subscribe(utilisateur => {
      this.utilisateur = utilisateur;
      if(!this.utilisateur){
        localStorage.removeItem('idUser');
        localStorage.clear();
        this.router.navigate(['/login']);
      }
    },
      err => {
        return false;
      });

      this.familleService.getAllData().then(data => {
        this.familles = data
      },
        err => {
          return false;
        });

      this.stockService.getUniqueDateInventaire().then(data => {
        this.unqieusdates = data
        this.unqieusdates.sort(this.triYear)
        this.myDateInvetaire = this.unqieusdates[0]
      },
        err => {
          return false;
        });
  }
  triYear(a, b) {
    if (a > b) return -1;
    else if (a == b) return 0;
    else return 1;
  }
  getBigDate(): string {
      return this.bigDate
    }
  showArrayFunction() {
    this.showArray = true;
  }
  getDateDebut(): string {
    return this.dateDebut
  }
  addFieldValue() {
    this.newAttribute.datec = this.dateDebut
    this.newAttribute.exist = false;
    this.showErrorData = false;
    this.showErrorDataQuantite = false;
    if (!this.newAttribute.refArt || this.newAttribute.refArt == undefined || this.newAttribute.refArt == '' || this.newAttribute.refArt.length > 100) {
      this.showErrorData = true;
      return false;
    }
    if (!this.newAttribute.quantite || this.newAttribute.quantite == undefined || this.newAttribute.quantite == '' || this.newAttribute.quantite < 0 || this.newAttribute.quantite > 99999) {
      this.showErrorDataQuantite = true;
      return false;
    }
    if(this.newAttribute.quantite.toString().indexOf(",") != -1 || this.newAttribute.quantite.toString().indexOf(".") != -1){
      this.showErrorDataQuantite = true;
      return false;
    }
    this.showErrorData = false;
    this.showErrorDataQuantite = false;
    this.stockService.getArticleByRefArtAndDateInventaire(this.newAttribute.refArt,this.myDateInvetaire).then((dataInventiare: any[]) => {
      if (dataInventiare && dataInventiare.length > 0) {
        dataInventiare[dataInventiare.length - 1].quantiteHis[0].quantite = dataInventiare[dataInventiare.length - 1].quantiteHis[0].quantite + this.newAttribute.quantite
        dataInventiare[dataInventiare.length - 1].quantiteHis[0].quantiteFinale = dataInventiare[dataInventiare.length - 1].quantiteHis[0].quantiteFinale + this.newAttribute.quantite
        this.newAttribute.denomination = dataInventiare[dataInventiare.length - 1].denomination
        this.newAttribute.designation = dataInventiare[dataInventiare.length - 1].designation
        this.fieldArrayy.push(this.newAttribute)
        var hist = {
          "idUser": this.utilisateur.id,
          "date": new Date().toISOString().split('T')[0],
          "type": "Stock entrée",
          "dateInventaire":this.myDateInvetaire,
          "dataScan": [
            {
              "datec": this.newAttribute.datec,
              "quantite": this.newAttribute.quantite,
              "refArt": this.newAttribute.refArt,
              "designation": this.newAttribute.designation,

            }
          ]
        }
          this.stockService.editInventairerById(dataInventiare[dataInventiare.length - 1])
            .subscribe(resp => {
              this.historiqueService.getDataByDateAndUser(hist.date, hist.idUser,hist.type)
                .then((dataHist: any[]) => {
                  if (dataHist && dataHist.length > 0) {
                    dataHist[0].dataScan.push(...hist.dataScan);
                    this.historiqueService.editIhistoriquerById(dataHist[0])
                      .subscribe(resp => {
                      }, err => {
                      })
                  } else {
                    this.historiqueService.addhistorique(hist)
                      .subscribe(resp => {

                      }, err => {
                      })
                  }
                }, err => {
                })
            }, err => {

            })
        this.newAttribute = {};

      } else {
        this.catalogueService.getAllDataByRefOrEAN(this.newAttribute.refArt).then((data: any[]) => {
          if (data && data.length > 0) {
            data.sort(this.triData)
            this.newAttribute.denomination = data[0].denomination
            this.newAttribute.designation = data[0].designation25
            this.newAttribute.idFournisseur = data[0].idFournisseur
            this.newAttribute.refArt = data[0].refCiel
            this.newAttribute.exist = true;
            this.newAttribute.ean13Art = data[0].ean13
            this.newAttribute.pritar = data[0].tarif
            this.newAttribute.dateInventaire = this.myDateInvetaire
            this.newAttribute.quantiteHis = [];
            this.newAttribute.quantiteHis.push({date: this.newAttribute.datec, quantite: this.newAttribute.quantite,quantiteFinale: this.newAttribute.quantite, initialQuantite: 0,quantiteSortie: 0})
            this.newAttribute.mkt5 = data[0].mkt5
            if( this.newAttribute.denomination == "SCHNEIDER ELECTRIC Tunisie"){
              if(this.newAttribute.mkt5){
                var mymkt = this.newAttribute.mkt5
                var foundFamillee = codesSchneider.filter(x => x.refFamille.toLowerCase().startsWith(mymkt[0].toLowerCase() + mymkt[1].toLowerCase()))
                if(foundFamillee && foundFamillee.length == 1){
                  this.newAttribute.codFamille = foundFamillee[0].codeFamille
                  this.newAttribute.libelleFamille = foundFamillee[0].libelle
                  this.newAttribute.coefFamille = foundFamillee[0].coefFamille
                }
                if(foundFamillee && foundFamillee.length > 1 && mymkt[2]){
                  var foundFamilleSecond = codesSchneider.filter(x => x.refFamille.toLowerCase().startsWith(mymkt[0].toLowerCase() + mymkt[1].toLowerCase() + mymkt[2].toLowerCase()))
                  if(!foundFamilleSecond){
                    this.newAttribute.codFamille = foundFamillee[0].codeFamille
                    this.newAttribute.libelleFamille = foundFamillee[0].libelle
                    this.newAttribute.coefFamille = foundFamillee[0].coefFamille
                  }
                  if(foundFamilleSecond && foundFamilleSecond.length < 1){
                    this.newAttribute.codFamille = foundFamillee[0].codeFamille
                    this.newAttribute.libelleFamille = foundFamillee[0].libelle
                    this.newAttribute.coefFamille = foundFamillee[0].coefFamille
                  }
                  if(foundFamilleSecond && foundFamilleSecond.length == 1){
                    this.newAttribute.codFamille = foundFamilleSecond[0].codeFamille
                    this.newAttribute.libelleFamille = foundFamilleSecond[0].libelle
                    this.newAttribute.coefFamille = foundFamilleSecond[0].coefFamille
                  }
                  if(foundFamillee && foundFamillee.length > 1 && mymkt[3]){
                    var foundFamilleThird = codesSchneider.filter(x => x.refFamille.toLowerCase().startsWith(mymkt[0].toLowerCase() + mymkt[1].toLowerCase() + mymkt[2].toLowerCase() + mymkt[3].toLowerCase()))
                    if(!foundFamilleThird){
                      this.newAttribute.codFamille = foundFamilleSecond[0].codeFamille
                      this.newAttribute.libelleFamille = foundFamilleSecond[0].libelle
                      this.newAttribute.coefFamille = foundFamilleSecond[0].coefFamille
                    }
                    if(foundFamilleThird && foundFamilleThird.length < 1){
                      if(foundFamilleSecond && foundFamilleSecond[0]){
                        this.newAttribute.codFamille = foundFamilleSecond[0].codeFamille
                        this.newAttribute.libelleFamille = foundFamilleSecond[0].libelle
                        this.newAttribute.coefFamille = foundFamilleSecond[0].coefFamille
                      }
                    }
                    if(foundFamilleThird && foundFamilleThird.length == 1){
                      this.newAttribute.codFamille = foundFamilleThird[0].codeFamille
                      this.newAttribute.libelleFamille = foundFamilleThird[0].libelle
                      this.newAttribute.coefFamille = foundFamilleThird[0].coefFamille
                    }
                  }
                }
              }
            }
            if(this.newAttribute.denomination != "SCHNEIDER ELECTRIC Tunisie"  && this.newAttribute.denomination != "PHOENIX CONTACT"){
              if(this.newAttribute.denomination){
                var foundFamille = this.familles.filter(x => this.newAttribute.denomination.toLowerCase().indexOf(x.libelle.toLowerCase()) !== -1 )
              }
              if(foundFamille && foundFamille.length > 0){
                this.newAttribute.codFamille = foundFamille[0].codFamille
                this.newAttribute.libelleFamille = foundFamille[0].libelle
                this.newAttribute.coefFamille = foundFamille[0].coefFamille
              }
            }
            if(this.newAttribute.denomination == "PHOENIX CONTACT"){
              var foundFamille = this.familles.filter(x => x.libelle == "PHOENIX NET" )
              if(foundFamille && foundFamille.length > 0){
                this.newAttribute.codFamille = foundFamille[0].codFamille
                this.newAttribute.libelleFamille = foundFamille[0].libelle
                this.newAttribute.coefFamille = foundFamille[0].coefFamille
              }
            }
            this.fieldArrayy.push(this.newAttribute)
            this.fieldArray.push(this.newAttribute)
            this.newAttribute = {};
            return true;
          } else {
            this.newAttribute.quantiteHis = [];
            this.newAttribute.dateInventaire = this.myDateInvetaire
            this.newAttribute.quantiteHis.push({date: this.newAttribute.datec, quantite: this.newAttribute.quantite,quantiteFinale: this.newAttribute.quantite, initialQuantite: 0,quantiteSortie: 0})
            this.fieldArrayy.push(this.newAttribute)
            this.fieldArray.push(this.newAttribute)
            this.newAttribute = {};
            return true;
          }
        },
          err => {
            return false;
          });
      }
    },
      err => {
        return false;
      });


  }

  deleteFieldValue(index) {
    var filedToDelete = this.fieldArrayy[index]
    if (filedToDelete) {
      this.stockService.getArticleByRefArtAndDateInventaire(filedToDelete.refArt,this.myDateInvetaire).then((dataInventiaree: any[]) => {
        if (dataInventiaree && dataInventiaree.length > 0) {
          dataInventiaree[dataInventiaree.length - 1].quantiteHis[0].quantite = dataInventiaree[dataInventiaree.length - 1].quantiteHis[0].quantite - filedToDelete.quantite
          dataInventiaree[dataInventiaree.length - 1].quantiteHis[0].quantiteFinale = dataInventiaree[dataInventiaree.length - 1].quantiteHis[0].quantiteFinale - filedToDelete.quantite
          this.stockService.editInventairerById(dataInventiaree[dataInventiaree.length - 1])
            .subscribe(resp => {
              var hist = {
                "idUser": this.utilisateur.id,
                "date": new Date().toISOString().split('T')[0],
                "type": "Stock entrée",
                "dateInventaire":this.myDateInvetaire,
                "dataScan": [
                  {
                    "datec": dataInventiaree[dataInventiaree.length - 1].datec,
                    "quantite": "-" + filedToDelete.quantite,
                    "refArt": dataInventiaree[dataInventiaree.length - 1].refArt,
                    "designation": dataInventiaree[dataInventiaree.length - 1].designation,

                  }
                ]
              }
              this.historiqueService.getDataByDateAndUser(hist.date, hist.idUser,hist.type)
                .then((dataHist: any[]) => {
                  if (dataHist && dataHist.length > 0) {
                    dataHist[0].dataScan.push(...hist.dataScan);
                    this.historiqueService.editIhistoriquerById(dataHist[0])
                      .subscribe(resp => {
                      }, err => {
                      })
                  } else {
                    this.historiqueService.addhistorique(hist)
                      .subscribe(resp => {

                      }, err => {
                      })
                  }
                }, err => {
                })
            }, err => {

            })
        }

        }, err => {

        })
        var objectTodelete = this.fieldArray.find(x => x.refArt == filedToDelete.refArt && x.quantite == filedToDelete.quantite);
        if(objectTodelete){
          this.fieldArray.splice(this.fieldArray.indexOf(objectTodelete), 1);
        }

    }
    this.fieldArrayy.splice(index, 1);
  }
  displayTable() {
    var myArray = []
    if(this.fieldArray && this.fieldArray.length < 1 && this.fieldArrayy && this.fieldArrayy.length < 1){
      return false;
    }
    this.loading = true;
    if(this.fieldArray && this.fieldArray.length > 0){
      for(var j=0;j<this.fieldArray.length;j++){
        var myObj = myArray.find(article => article.refArt === this.fieldArray[j].refArt)
        if(!myObj){
          myArray.push(this.fieldArray[j])
        }
        if(myObj){
          myObj.quantiteHis[0].quantite =  myObj.quantiteHis[0].quantite + this.fieldArray[j].quantite
          myObj.quantiteHis[0].quantiteFinale =  myObj.quantiteHis[0].quantiteFinale + this.fieldArray[j].quantite
        }
        if(j == this.fieldArray.length - 1){

          this.stockService.saveAllData(myArray)
            .subscribe(resp => {

              var hist = {
                "idUser": this.utilisateur.id,
                "date": new Date().toISOString().split('T')[0],
                "type": "Stock entrée",
                "dateInventaire":this.myDateInvetaire,
                "dataScan": myArray
              }
              this.historiqueService.getDataByDateAndUser(hist.date, hist.idUser,hist.type)
                .then((dataHist: any[]) => {
                  if (dataHist && dataHist.length > 0) {
                    dataHist[0].dataScan.push(...hist.dataScan);
                    this.historiqueService.editIhistoriquerById(dataHist[0])
                      .subscribe(resp => {
                      }, err => {
                      })
                  } else {
                    this.historiqueService.addhistorique(hist)
                      .subscribe(resp => {

                      }, err => {
                      })
                  }
                }, err => {
                })

            }, err => {
            })
        }
      }

    }
    setTimeout(() => {
      /** spinner ends after 6 seconds **/
      this.loading = false;
      Swal.fire( {
            imageUrl: 'assets/images/add-catalogue.png',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            width: 800,
            title: 'BIEN INTÉGRÉ',
            timer: 1500,
            showConfirmButton: false
          }
        );
      this.router.navigateByUrl('/stock');
    }, 5000);
  }
  triData(a,b){
    if (a.datec > b.datec) return -1;
    else if (a.datec == b.datec) return 0;
    else return 1;
  }
}
