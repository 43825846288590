import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http} from '@angular/http';
import { RhService } from "../../../services/rh.service";
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { ValidationService } from '../../../services/validate/validate-error.service';
import { pays } from '../../../pays/pays';
import { tri } from '../../../pays/pays';
import { languages } from '../../../pays/langues';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
import { codepostale } from 'src/app/pays/villes';

@Component({
  selector: 'app-ajout-salarie',
  templateUrl: './ajout-salarie.component.html',
  styleUrls: ['../../utilisateur/ajout-utilisateur/ajout-utilisateur.component.scss'],
})
export class AjoutSalarieComponent implements OnInit {
  salarie: any;
  errorDate= false;
  errorEmail = false;
  pays: any[] = [];
  vlls: any[] = [];
  parcours: any[] = [];
  experiences: any[] = [];
  langues: any[] = [];
  languages: any[] = [];
  competences: any[] = [];
  competence: any;
  form: any;
  langue = "";
  formConjoint: any;
  formExperience: any;
  formParcours: any;
  addConjointAndEnfants = false;
  addEnfants = false;
  situationFamiliale = 'Célibataire';
  situationEnfant = 'Non';
  nom: any;
  prenom: any;
  dateNaissance: any;
  sexe = 'Masculin';
  errorDates = false;
  formClient: any;
  formEnfant: any;
  enfants: any[] = [];
  roleAdministration = true;
  role: any;
  showButtonClientForm = false;
  selectedClient: any;
  selectedClientExp:any;
  showErrorEtabClient = false;
  testEmail: any;
  showSuivantBouton = false;
  showErrorEmail = false;
  disabled = false;
  selectedEmail: any;
  etablissements: any[] = []
  etablissement: any;
  etablissementsExp: any[] = []
  etablissementExp: any;
  administration = false;
  dateFinPoste = new Date().toISOString().split('T')[0]
  dateNaissancee = new Date().toISOString().split('T')[0]
  showMyContainer = 1;
  errorLangue = ""
  languesChosed: any[] = []
  competencesChosed: any[] = []
  errorCompetence = ""
  bigDate = "9999-12-31"
  secondp = 1;
  thirdp = 1;
  p = 1;
  enfantp=1;
  
  constructor(private http: Http, private rhService: RhService,private formBuilder: FormBuilder,
    private router: Router) {
      this.form = this.formBuilder.group({
        nom: ['', [Validators.required, ValidationService.validateEmptyField, Validators.minLength(2)]],
        prenom: ['', [Validators.required, ValidationService.validateEmptyField, Validators.minLength(2)]],
        mailPro: ['', [Validators.required, ValidationService.emailValidator, ValidationService.nospaceValidator]],
        mailPerso: ['', [ValidationService.emailValidator, ValidationService.nospaceValidator]],
        telPro: ['+216', [Validators.required]],
        telPerso: ['+216', [Validators.required]],
        sexe: ['Masculin', Validators.required,],
        typeIdentite: ['CIN'],
        numIdentite: [''],
        nationalite: ['Tunisie',],
        dateNaissance: ['', ValidationService.dateValidator],
        adresse: this.formBuilder.group({
          pays: ['',],
          codePostal: ['', ValidationService.numberValidator],
          rue: ['',],
          n: ['', ValidationService.numberValidator],
          ville: ['',],
        }),
        posteObject: this.formBuilder.group({
          titre: [''],
          departement: [''],
          bureau: [''],
          dated: [''],
          datef: ['',]
        }),
        linkedin: ['']
      });
      const currentDate = new Date().toISOString().substring(0, 10);
      this.form.controls['posteObject'].controls['dated'].setValue(currentDate);
      this.formConjoint = this.formBuilder.group({
        nom: ['', [Validators.minLength(2)]],
        prenom: ['', [Validators.minLength(2)]],
        tel: ['+216',],
        mail: ['', [ValidationService.emailValidator, ValidationService.nospaceValidator]],
        OrganismeDeSante: ['',],
        nationalite: ['']
      })
      this.formParcours = this.formBuilder.group({
        etablissement: ['', [Validators.required, Validators.minLength(4), ValidationService.validateEmptyField]],
        diplomen: ['', [Validators.required, Validators.minLength(4), ValidationService.validateEmptyField]],
        diplomet: ['Ouvrier', Validators.required],
        date: ['', [ValidationService.dateValidator]],
        desc: ['',]
      })
      this.formExperience = this.formBuilder.group({
        entreprise: ['', Validators.required],
        poste: ['', [Validators.required, Validators.minLength(4), ValidationService.validateEmptyField]],
        dated: ['', [ValidationService.dateValidator]],
        datef: ['',ValidationService.dateValidatorYear],
        departement: [''],
        bureau: [''],
        desc: ['',]
      })
      this.formEnfant = this.formBuilder.group({
        nom: ['', [Validators.required, Validators.minLength(2), ValidationService.validateEmptyField]],
        prenom: ['', [Validators.required, Validators.minLength(2), ValidationService.validateEmptyField]],
        dateNaissance: ['', [Validators.required, ValidationService.dateValidator]],
        sexe: ['Masculin', [Validators.required]],
      })

  }
  ngOnInit() {
    this.languages = languages
    this.pays = pays.sort(tri);
    this.pays = this.pays.filter(x => x.name === "Tunisie")
    this.vlls = codepostale

  }
  getBigDate(): string {
      return this.bigDate
    }
registerSalarie(){
  var d = new Date();
  this.form.value.datec = d.toLocaleString();
  if (this.situationFamiliale === "Célibataire") {
    this.form.value.situationFamiliale = "Célibataire";
    this.enfants = [];
    this.form.value.conjoint = {
      nom: "",
      prenom: "",
      nationalite: "",
      tel: "",
      mail: "",
      OrganismeDeSante: ""
    }
  }
  else {
    if (this.situationEnfant === "Non") {
      this.enfants = [];
    }
    this.form.value.situationFamiliale = this.situationFamiliale
    this.form.value.conjoint = this.formConjoint.value;
  }
  this.errorDate = false;
  this.errorEmail =false;
  this.form.value.parcours = this.parcours;
  this.form.value.experiences = this.experiences;
  this.form.value.languages = this.langues;
  this.form.value.competences = this.competences;
  this.form.value.enfants = this.enfants;
  this.form.value.situationEnfant = this.situationEnfant;

  if(this.form.value.dateFin < this.form.value.dateEmbauche){
    this.errorDate = true;
    return false;
  }else{
    //this.form.value.age = this.getAge(this.form.value.dateNaissance)
    this.rhService.verifyEmail(this.form.value).subscribe(data => {
      if (data) {
        this.errorEmail = true;
        return false;
      }
      else {
        this.rhService.registerSalarie(this.form.value).subscribe(data => {
          Swal.fire( {
            imageUrl: 'assets/images/client-plus.png',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            width: 800,
            title: 'BIEN AJOUTÉ',
            timer: 1500,
            showConfirmButton: false
          }
        );
          this.router.navigateByUrl('/rh');
        });

      }
    })
  }
}
getdateFinPoste(){
  return this.dateFinPoste
}
selectEtablissement(){
  this.selectedClient = this.formClient.value.etablissement;
}

showOthersSituationEnfant() {
  if (this.situationEnfant === "Non") {
    this.addEnfants = false;
  }
  else {
    this.addEnfants = true;
  }
}

// add enfants
addnewEnfant() {
  this.enfants.push(this.formEnfant.value);
  this.formEnfant.reset();
}
removeEnfant(enfant) {
  if (window.confirm("Voulez vous vraiment supprimer cet enfant?")) {
    this.enfants.splice(this.enfants.indexOf(enfant), 1);
  }
}
showOthersSituation() {
  if (this.situationFamiliale === "Célibataire") {
    this.addConjointAndEnfants = false;
  }
  else {
    this.addConjointAndEnfants = true;
  }
}
// ajouter des parcours
addnewParcours() {
  this.parcours.push(this.formParcours.value);
  this.formParcours.reset();
}
// supprimer parcours
removeParcours(parcour) {
  Swal.fire({
        title: 'VOULEZ-VOUS SUPPRIMER CE PARCOURS?',
        imageUrl: 'assets/images/supp-modal.png',
        imageWidth: 100,
        imageHeight: 100,
        imageAlt: 'Custom image',
        showCancelButton: true,
        width: 800,
        confirmButtonColor: '#059e00',
        cancelButtonColor: '#FF9100',
        cancelButtonText: 'ANNULER',
        confirmButtonText: 'VALIDER'
      }).then((result) => {
        if (!result.dismiss) {
          Swal.fire( {
              imageUrl: 'assets/images/supp-modal.png',
              imageWidth: 100,
              imageHeight: 100,
              imageAlt: 'Custom image',
              width: 800,
              title: 'BIEN SUPPRIMÉ',
              timer: 1500,
              showConfirmButton: false
            }
          );
          this.parcours.splice(this.parcours.indexOf(parcour), 1);

        }
      } );

}

// ajouter des parcours
addnewExperience() {
  if (this.formExperience.value.dated >= this.formExperience.value.datef) {
    this.errorDates = true;
    return false;
  }
  if (this.formExperience.value.datef.toString().length > 10) {
    this.errorDates = true;
    return false;
  }
  this.experiences.push(this.formExperience.value);
  this.formExperience.reset();
  this.errorDates = false;
  this.etablissementExp = undefined
}
// supprimer parcours
removeExperience(experience) {
  Swal.fire({
        title: 'VOULEZ-VOUS SUPPRIMER CETTE EXPÉRIENCE?',
        imageUrl: 'assets/images/supp-modal.png',
        imageWidth: 100,
        imageHeight: 100,
        imageAlt: 'Custom image',
        showCancelButton: true,
        width: 800,
        confirmButtonColor: '#059e00',
        cancelButtonColor: '#FF9100',
        cancelButtonText: 'ANNULER',
        confirmButtonText: 'VALIDER'
      }).then((result) => {
        if (!result.dismiss) {
          Swal.fire( {
              imageUrl: 'assets/images/supp-modal.png',
              imageWidth: 100,
              imageHeight: 100,
              imageAlt: 'Custom image',
              width: 800,
              title: 'BIEN SUPPRIMÉ',
              timer: 1500,
              showConfirmButton: false
            }
          );
          this.experiences.splice(this.experiences.indexOf(experience), 1);

        }
      } );


}

addLangues(langue) {
  this.errorLangue = ""
  if (this.langues.indexOf(langue) > -1 || this.languesChosed.indexOf(langue) > -1) {
    this.errorLangue = "Cette langue existe déjà dans la liste"
    return false;
  } else {
    this.languesChosed.push(langue);
  }
}
resetErrorLangue() {
  this.languesChosed = []
  while (this.languesChosed.length > 0) {
    this.languesChosed.pop();
  }
  this.errorLangue = ""
  this.langue = ""
}
saveChoosedLangues() {
  this.langues.push(...this.languesChosed);
}
removeLangue(langue) {
  Swal.fire({
        title: 'VOULEZ-VOUS SUPPRIMER CETTE LANGUE?',
        imageUrl: 'assets/images/supp-modal.png',
        imageWidth: 100,
        imageHeight: 100,
        imageAlt: 'Custom image',
        showCancelButton: true,
        width: 800,
        confirmButtonColor: '#059e00',
        cancelButtonColor: '#FF9100',
        cancelButtonText: 'ANNULER',
        confirmButtonText: 'VALIDER'
      }).then((result) => {
        if (!result.dismiss) {
          Swal.fire( {
              imageUrl: 'assets/images/supp-modal.png',
              imageWidth: 100,
              imageHeight: 100,
              imageAlt: 'Custom image',
              width: 800,
              title: 'BIEN SUPPRIMÉ',
              timer: 1500,
              showConfirmButton: false
            }
          );
          this.langues.splice(this.langues.indexOf(langue), 1);

        }
      } );


}
addCompetences(competence) {
  this.errorCompetence = ""
if (this.competences.indexOf(competence) > -1 || this.competencesChosed.indexOf(competence) > -1) {
  this.errorCompetence = "Cette compétence existe déjà dans la liste"
  return false;
} else {
  this.competencesChosed.push(competence);
}
}
resetErrorCompetence() {
this.competencesChosed = []
while (this.competencesChosed.length > 0) {
  this.competencesChosed.pop();
}
this.errorCompetence = ""
this.competence = ""
}
saveChoosedCompetence() {
this.competences.push(...this.competencesChosed);
}
removeCompetence(competence) {
  Swal.fire({
        title: 'VOULEZ-VOUS SUPPRIMER CETTE COMPÉTENCE?',
        imageUrl: 'assets/images/supp-modal.png',
        imageWidth: 100,
        imageHeight: 100,
        imageAlt: 'Custom image',
        showCancelButton: true,
        width: 800,
        confirmButtonColor: '#059e00',
        cancelButtonColor: '#FF9100',
        cancelButtonText: 'ANNULER',
        confirmButtonText: 'VALIDER'
      }).then((result) => {
        if (!result.dismiss) {
          Swal.fire( {
              imageUrl: 'assets/images/supp-modal.png',
              imageWidth: 100,
              imageHeight: 100,
              imageAlt: 'Custom image',
              width: 800,
              title: 'BIEN SUPPRIMÉ',
              timer: 1500,
              showConfirmButton: false
            }
          );
          this.competences.splice(this.competences.indexOf(competence), 1);

        }
      } );


}
getDateNaissance(): string {
  return this.dateNaissancee
}
 getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}
modifPart1() {
  this.showMyContainer = 1;
}
modifPart2(){
  this.showMyContainer = 2;
}
modifPart3(){
  this.showMyContainer = 3;
}
modifPart4(){
  this.showMyContainer = 4;
}
modifPart5(){
  this.showMyContainer = 5;
}
showPart() {
  return this.showMyContainer;
}
}
