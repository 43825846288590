<app-navbar></app-navbar>
<br>

<div id="content-nav-page" class="row" *ngIf="article">
    <div class=" col-3 titre-menu">
      <a routerLink="/inventaire" class=" icon-f-left"></a>
      <b> {{article.refArt}}<img class="ml-2" src="assets/images/show-a.png"></b>
    </div>
    <div class="col-9 sous-menu">
      <b class="sous-menu-active1" routerLink="/inventaire">INVENTAIRE</b>
    </div>
  </div>
  <div class="card p-3" *ngIf="article">
    <div class="card-header " style="border: 1px solid green;margin-bottom: 1%;text-align: center" >
      <b style="color:grey;text-align: center" >INFORMATIONS </b>
    </div>
    <div >
      <div class="card-body">
        <div class="row" >
          <div class="col-4" style="margin-left: 12%;margin-right: 10%">
            <div class="title">
              <h4>Référence </h4>
              <span class="font-weight-bold">{{article.refArt}}</span>
            </div>
  
            <br/>
            <div class="title ">
              <h4>Désignation </h4>
              <span class="font-weight-bold">{{article.designation}} </span>
            </div>
            <br/>
  
            <div class="title" *ngIf="utilisateur && utilisateur.role === 'Super admin'">
              <h4>Prix Tarif </h4>
              <span class="font-weight-bold">{{article.pritar | number: '1.2-2'| replace:',':' ' | replace:'.':','}}</span>
            </div>
            <br/>
            <div class="title" *ngIf="utilisateur && utilisateur.role === 'Super admin'">
              <h4>Coefficient d'achat</h4>
              <span class="font-weight-bold">{{article.coefAchat | number: '1.4-4'| replace:',':' ' | replace:'.':','}}</span>
  
            </div>
            <br/>
            <div class="title" *ngIf="utilisateur && utilisateur.role === 'Super admin'">
              <h4>Prix Net</h4>
              <span class="font-weight-bold">{{article.prinet | number: '1.2-2'| replace:',':' ' | replace:'.':','}}</span>
  
            </div>
  
          </div>
  
          <div class="col-4">
  
            <div class="title">
              <h4>Quantité</h4>
              <span class="font-weight-bold">{{article.quantite | number: '1.2-2'| replace:',':' ' | replace:'.':',' | replace:',00':' '}}</span>
  
            </div>
            <br/>
            <div class="title" *ngIf="utilisateur && utilisateur.role === 'Super admin'">
              <h4>Montant</h4>
              <span class="font-weight-bold">{{article.montant | number: '1.2-2'| replace:',':' ' | replace:'.':','}} </span>
            </div>
            <br/>
  
            <div class="title">
              <h4>Date</h4>
              <span class="font-weight-bold">{{article.datec | date : 'dd/MM/yyyy'}}</span></div>
            <br/>
  
            <div class="title">
              <h4>Fournisseur</h4>
              <span class="font-weight-bold">{{article.denomination}}</span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  