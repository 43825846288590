<app-navbar></app-navbar>  
<br>
<div id="content-nav-page" class="row" *ngIf="utilisateur">
  <div class=" col-3 titre-menu">
    <a routerLink="/utilisateur" class=" icon-f-left"></a>
    <b class="title-haut">{{utilisateur.prenom}} {{utilisateur.nom}}<img class="ml-2" src="assets/images/show-a.png"></b>
  </div>
  <div class="col-9 sous-menu">
    <b class="sous-menu-active1" routerLink="/utilisateur">UTILISATEUR</b>
  </div>
</div>
<div class="row">

  <div class="col-xl-2 col-lg-3  col-md-3 col-sm-4 left-content-scrolbar ">

    <div class="col col-content ">
      <button class="tablink2 btn-liste" id="defaultOpen2" (click)="modifPart1()">
        INFORMATIONS</button>
    </div>

    <div class="col col-content">
      <button class="tablink2 btn-liste " (click)="modifPart2()"> LIGNES </button>
    </div>

    <div class="col col-content">
      <button class="tablink2 btn-liste " (click)="modifPart3()"> FICHIERS
      </button>
    </div>
  </div>




  <div class="col-xl-10 col-lg-9  col-md-9 col-sm-8 right-content-scrolbar" *ngIf="historique">
    <div class="card p-3">
      <div *ngIf="showPart()==1" class="col-content-affiche tabcontent2" id="information">
        <div>
          <div class="card-body">
            <div class="title">
              <h4>Nouvelle version</h4>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="title">
                  <h4>Numéro de commande</h4>
                  <span class="font-weight-bold">{{historique.modifications[0].nouveauModule.numeroCommande}}</span>
                </div>

                <br />
                <div class="title ">
                  <h4>Numéro de commande GPMI</h4>
                  <span class="font-weight-bold">{{historique.modifications[0].nouveauModule.numeroCommandeGPMI}} </span>
                </div>
                <br />

                <div class="title">
                  <h4>Fournisseur</h4>
                  <span class="font-weight-bold">{{historique.modifications[0].nouveauModule.denomination}}</span>
                </div>
                <br />
                <div class="title">
                  <h4>Date de commande</h4>
                  <span class="font-weight-bold">{{historique.modifications[0].nouveauModule.dateCommande}}</span>
                </div>
                <div class="title">
                  <h4>Date de livraison</h4>
                  <span class="font-weight-bold">{{historique.modifications[0].nouveauModule.dateLivraison}}</span>
                </div>
              </div>

              <div class="col-6">

                <div class="title">
                  <h4>Prix net total HT</h4>
                  <span class="font-weight-bold"
                    *ngIf="historique.modifications[0].nouveauModule.sommePrixNet && historique.modifications[0].nouveauModule.sommePrixNet != 'NaN'">{{historique.modifications[0].nouveauModule.sommePrixNet| number: '1.2-2'| replace:',':' ' | replace:'.':',' | replace:',00':''}}</span>

                </div>
                <br />
                <div class="title">
                  <h4>Montant de la TVA</h4>
                  <span class="font-weight-bold"
                    *ngIf="historique.modifications[0].nouveauModule.montantTVA && historique.modifications[0].nouveauModule.montantTVA != 'NaN'">{{historique.modifications[0].nouveauModule.montantTVA| number: '1.2-2'| replace:',':' ' | replace:'.':',' | replace:',00':''}}
                  </span>
                </div>
                <br />

                <div class="title">
                  <h4>Prix net total TTC</h4>
                  <span class="font-weight-bold"
                    *ngIf="historique.modifications[0].nouveauModule.sommePrixNetTTC && historique.modifications[0].nouveauModule.sommePrixNetTTC != 'NaN'">{{historique.modifications[0].nouveauModule.sommePrixNetTTC| number: '1.2-2'| replace:',':' ' | replace:'.':',' | replace:',00':''}}</span>
                </div>
                <br />
                <div class="title">
                  <h4>Statut</h4>
                  <span class="font-weight-bold">{{historique.modifications[0].nouveauModule.etat}}</span>
                </div>
                <div class="title">
                  <h4>Historiques des dates de livraison</h4>
                  <span class="font-weight-bold" *ngFor="let date of historique.modifications[0].nouveauModule.allDateLivraison">{{date}} <br></span>
                </div>
              </div>
            </div>
            <br><br>

            <div class="title">
              <h4>Ancienne version</h4>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="title">
                  <h4>Numéro de commande</h4>
                  <span class="font-weight-bold">{{historique.modifications[0].ancienModule.numeroCommande}}</span>
                </div>

                <br />
                <div class="title ">
                  <h4>Numéro de commande GPMI</h4>
                  <span class="font-weight-bold">{{historique.modifications[0].ancienModule.numeroCommandeGPMI}} </span>
                </div>
                <br />

                <div class="title">
                  <h4>Fournisseur</h4>
                  <span class="font-weight-bold">{{historique.modifications[0].ancienModule.denomination}}</span>
                </div>
                <br />
                <div class="title">
                  <h4>Date de commande</h4>
                  <span class="font-weight-bold">{{historique.modifications[0].ancienModule.dateCommande}}</span>
                </div>
                <div class="title">
                  <h4>Date de livraison</h4>
                  <span class="font-weight-bold">{{historique.modifications[0].ancienModule.dateLivraison}}</span>
                </div>
              </div>

              <div class="col-6">

                <div class="title">
                  <h4>Prix net total HT</h4>
                  <span class="font-weight-bold"
                    *ngIf="historique.modifications[0].ancienModule.sommePrixNet && historique.modifications[0].nouveauModule.sommePrixNet != 'NaN'">{{historique.modifications[0].ancienModule.sommePrixNet| number: '1.2-2'| replace:',':' ' | replace:'.':',' | replace:',00':''}}</span>

                </div>
                <br />
                <div class="title">
                  <h4>Montant de la TVA</h4>
                  <span class="font-weight-bold"
                    *ngIf="historique.modifications[0].ancienModule.montantTVA && historique.modifications[0].nouveauModule.montantTVA != 'NaN'">{{historique.modifications[0].ancienModule.montantTVA| number: '1.2-2'| replace:',':' ' | replace:'.':',' | replace:',00':''}}
                  </span>
                </div>
                <br />

                <div class="title">
                  <h4>Prix net total TTC</h4>
                  <span class="font-weight-bold"
                    *ngIf="historique.modifications[0].ancienModule.sommePrixNetTTC && historique.modifications[0].nouveauModule.sommePrixNetTTC != 'NaN'">{{historique.modifications[0].ancienModule.sommePrixNetTTC| number: '1.2-2'| replace:',':' ' | replace:'.':',' | replace:',00':''}}</span>
                </div>
                <br />
                <div class="title">
                  <h4>Statut</h4>
                  <span class="font-weight-bold">{{historique.modifications[0].ancienModule.etat}}</span>
                </div>
                <div class="title">
                  <h4>Historiques des dates de livraison</h4>
                  <span class="font-weight-bold" *ngFor="let date of historique.modifications[0].ancienModule.allDateLivraison">{{date}} <br></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div *ngIf="showPart()==2" class="col-content-affiche tabcontent2" id="information_compl">

        <div>
          <div class="card-body">
            <div>
              <div class="title">
                <h4>Les lignes - Nouvelle version</h4>
              </div>
              <div class="row ligne-table2  d-flex justify-content-center table-padding">
                <table class="table-responsive" style="text-align: center;">
                  <tr>
                    <td>Poste</td>
                    <td>Numéro du dossier</td>
                    <td>Nom du dossier</td>
                    <td>Client</td>
                    <td>Référence</td>
                    <td>Désignation</td>
                    <td>Prix tarif HT</td>
                    <td>Prix net HT</td>
                    <td>Coefficient d'achat</td>
                    <td>Quantité</td>
                    <td>Prix net HT Total</td>

                  </tr>
                  <tr class="text-center" *ngFor="let ligne of historique.modifications[0].nouveauModule.lignes| paginate: { id:'enfantsPag',itemsPerPage: 10, currentPage: enfantp }; let i = index ">
                    <td>{{i + 1}}0</td>
                    <td>{{ligne.numero}}</td>
                    <td>{{ligne.nomDossier}}</td>
                    <td>{{ligne.client}}</td>
                    <td>{{ligne.refArt}}</td>
                    <td>{{ligne.designation}}</td>
                    <td>{{ligne.pritar | number: '1.2-2'| replace:',':' ' | replace:'.':',' | replace:',00':''}}</td>
                    <td>{{ligne.prinet | number: '1.2-2'| replace:',':' ' | replace:'.':',' | replace:',00':''}}</td>
                    <td>{{ligne.coef}}</td>
                    <td>{{ligne.quantite}}</td>
                    <td><span *ngIf="ligne.prinetTotal && ligne.prinetTotal != 'NaN'">{{ligne.prinetTotal | number: '1.2-2'| replace:',':' ' | replace:'.':',' | replace:',00':''}}</span></td>
                  </tr>


                </table>
                <div class="row">
                  <div class="col col-xs-6">
                    <pagination-controls previousLabel="Précédent" nextLabel="Suivant" class=“custom-pagination” (pageChange)="enfantp = $event" id="enfantsPag"></pagination-controls>
                  </div>
                </div>

              </div>
            </div>
            <div style="align-items: center; padding-top: 50px;">
              <div class="title">
                <h4>Les lignes - Ancienne version</h4>
              </div>
              <div class="row ligne-table2  d-flex justify-content-center table-padding">
                <table class="table-responsive">
                  <tr>
                    <td>Poste</td>
                    <td>Numéro du dossier</td>
                    <td>Nom du dossier</td>
                    <td>Client</td>
                    <td>Référence</td>
                    <td>Désignation</td>
                    <td>Prix tarif HT</td>
                    <td>Prix net HT</td>
                    <td>Coefficient d'achat</td>
                    <td>Quantité</td>
                    <td>Prix net HT Total</td>

                  </tr>
                  <tr class="text-center" *ngFor="let ligne of historique.modifications[0].ancienModule.lignes| paginate: { id:'enfantsPagg',itemsPerPage: 10, currentPage: enfantpp }; let i = index ">
                    <td>{{i + 1}}0</td>
                    <td>{{ligne.numero}}</td>
                    <td>{{ligne.nomDossier}}</td>
                    <td>{{ligne.client}}</td>
                    <td>{{ligne.refArt}}</td>
                    <td>{{ligne.designation}}</td>
                    <td>{{ligne.pritar | number: '1.2-2'| replace:',':' ' | replace:'.':',' | replace:',00':''}}</td>
                    <td>{{ligne.prinet | number: '1.2-2'| replace:',':' ' | replace:'.':',' | replace:',00':''}}</td>
                    <td>{{ligne.coef}}</td>
                    <td>{{ligne.quantite}}</td>
                    <td><span *ngIf="ligne.prinetTotal && ligne.prinetTotal != 'NaN'">{{ligne.prinetTotal | number: '1.2-2'| replace:',':' ' | replace:'.':',' | replace:',00':''}}</span></td>
                  </tr>

                </table>
                <div class="row">
                  <div class="col col-xs-6">
                    <pagination-controls previousLabel="Précédent" nextLabel="Suivant" class=“custom-pagination” (pageChange)="enfantpp = $event" id="enfantsPagg"></pagination-controls>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="showPart()==3" class="col-content-affiche tabcontent2" id="etablissement">
        <div>
          <div class="card-body">
            <div>
              <div class="title">
                <h4>Les fichiers - Nouvelle version</h4>
              </div>
              <div class="row ligne-table2  d-flex justify-content-center table-padding">
                <div class="col-12 m-0 p-0">
                  <div class="col-6 m-t-lg m-t-md m-t-xs m-t-sm">
                    <h4>Commande(s) </h4>
                    <h6 *ngFor=" let file of historique.modifications[0].nouveauModule.listfiles"> <strong>Fichier {{historique.modifications[0].nouveauModule.listfiles.indexOf(file)+1}}</strong> <span><a href="{{file.photo}}"
                          style="cursor: pointer;text-decoration: underline;" target="_blank"><span *ngIf="!file.name">Lien 1</span> <span *ngIf="file.name">{{file.name}}</span></a></span> </h6>
                  </div>
                  <div class="col-6 m-t-lg m-t-md m-t-xs m-t-sm">
                    <h4>Accusé de réception </h4>
                    <h6 *ngFor=" let file of historique.modifications[0].nouveauModule.listfilesAccuse"> <strong>Fichier {{historique.modifications[0].nouveauModule.listfilesAccuse.indexOf(file)+1}}</strong> <span><a href="{{file.photo}}"
                          style="cursor: pointer;text-decoration: underline;" target="_blank"><span *ngIf="!file.name">Lien 1</span> <span *ngIf="file.name">{{file.name}}</span></a></span> </h6>
                  </div>
                </div>
                <div class="col-12 m-0 p-0">
                  <div class="col-6 m-t-lg m-t-md m-t-xs m-t-sm">
                    <h4>Facture(s) </h4>
                    <h6 *ngFor=" let file of historique.modifications[0].nouveauModule.listfilesFactures"> <strong>Fichier {{historique.modifications[0].nouveauModule.listfilesFactures.indexOf(file)+1}}</strong> <span><a href="{{file.photo}}"
                          style="cursor: pointer;text-decoration: underline;" target="_blank"><span *ngIf="!file.name">Lien 1</span> <span *ngIf="file.name">{{file.name}}</span></a></span> </h6>
                  </div>
                  <div class="col-6 m-t-lg m-t-md m-t-xs m-t-sm">
                    <h4>Bon(s) de livraison</h4>
                    <h6 *ngFor=" let file of historique.modifications[0].nouveauModule.listfilesBonLivraison"> <strong>Fichier {{historique.modifications[0].nouveauModule.listfilesBonLivraison.indexOf(file)+1}}</strong> <span><a href="{{file.photo}}"
                          style="cursor: pointer;text-decoration: underline;" target="_blank"><span *ngIf="!file.name">Lien 1</span> <span *ngIf="file.name">{{file.name}}</span></a></span> </h6>
                  </div>
                </div>
              </div>
            </div>
            <div style="align-items: center; padding-top: 50px;">
              <div class="title">
                <h4>Les fichiers - Ancienne version</h4>
              </div>
              <div class="row ligne-table2  d-flex justify-content-center table-padding">
                <div class="col-12 m-0 p-0">
                  <div class="col-6 m-t-lg m-t-md m-t-xs m-t-sm">
                    <h4>Commande(s) </h4>
                    <h6 *ngFor=" let file of historique.modifications[0].ancienModule.listfiles"> <strong>Fichier {{historique.modifications[0].ancienModule.listfiles.indexOf(file)+1}}</strong> <span><a href="{{file.photo}}"
                          style="cursor: pointer;text-decoration: underline;" target="_blank"><span *ngIf="!file.name">Lien 1</span> <span *ngIf="file.name">{{file.name}}</span></a></span> </h6>
                  </div>
                  <div class="col-6 m-t-lg m-t-md m-t-xs m-t-sm">
                    <h4>Accusé de réception </h4>
                    <h6 *ngFor=" let file of historique.modifications[0].ancienModule.listfilesAccuse"> <strong>Fichier {{historique.modifications[0].ancienModule.listfilesAccuse.indexOf(file)+1}}</strong> <span><a href="{{file.photo}}"
                          style="cursor: pointer;text-decoration: underline;" target="_blank"><span *ngIf="!file.name">Lien 1</span> <span *ngIf="file.name">{{file.name}}</span></a></span> </h6>
                  </div>
                </div>
                <div class="col-12 m-0 p-0">
                  <div class="col-6 m-t-lg m-t-md m-t-xs m-t-sm">
                    <h4>Facture(s) </h4>
                    <h6 *ngFor=" let file of historique.modifications[0].ancienModule.listfilesFactures"> <strong>Fichier {{historique.modifications[0].ancienModule.listfilesFactures.indexOf(file)+1}}</strong> <span><a href="{{file.photo}}"
                          style="cursor: pointer;text-decoration: underline;" target="_blank"><span *ngIf="!file.name">Lien 1</span> <span *ngIf="file.name">{{file.name}}</span></a></span> </h6>
                  </div>
                  <div class="col-6 m-t-lg m-t-md m-t-xs m-t-sm">
                    <h4>Bon(s) de livraison</h4>
                    <h6 *ngFor=" let file of historique.modifications[0].ancienModule.listfilesBonLivraison"> <strong>Fichier {{historique.modifications[0].ancienModule.listfilesBonLivraison.indexOf(file)+1}}</strong> <span><a href="{{file.photo}}"
                          style="cursor: pointer;text-decoration: underline;" target="_blank"><span *ngIf="!file.name">Lien 1</span> <span *ngIf="file.name">{{file.name}}</span></a></span> </h6>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
