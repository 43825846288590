import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from "../../../services/user.service";
import { DevisService } from "../../../services/devis.service";
@Component({
  selector: 'app-armoires-en-fabrication',
  templateUrl: './armoires-en-fabrication.component.html',
  styleUrls: ['../../utilisateur/utilisateur/utilisateur.component.css'],
})
export class ArmoiresEnFabricationComponent implements OnInit {
  armoires: any[]=[];
  id: any;
  utilisateur: any;
  loading = false;
  numberData = 25
  p = 1
  inventaireConfigObj:any;
  constructor(private router:Router,private devisService: DevisService, private userService: UserService) {


  }
  ngOnInit() {
    this.loading = true;
    this.id = localStorage.getItem('idUser')
    if (this.id) {
      this.userService.getUserByID(this.id).subscribe(utilisateur => {
        this.utilisateur = utilisateur;
        if(!this.utilisateur){
          localStorage.removeItem('idUser');
          localStorage.clear();
          this.router.navigate(['/login']);
        }
        if (!this.utilisateur) {
          this.router.navigateByUrl('/dossier');
        }
      },
        err => {
          return false;
        });
    } else {
      return false;
    }
    this.devisService.getArmoiresEnFabrication().then(data => {
      if(data && data["result"]){
        this.armoires = data["result"];
      }
        this.loading = false;

    },
      err => {
        return false;
      });
  }
}
