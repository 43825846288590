<app-navbar></app-navbar>
<br>
<div id="content-nav-page" class="row" *ngIf="article">
    <div class=" col-3 titre-menu">
      <a routerLink="/catalogue/2021/{{article.idFournisseur}}/{{article.datec}}/{{article.dateFin}}" class=" icon-f-left"></a>
      <b>{{article.denomination}} <img class="ml-2" src="assets/images/modif-hover.png"></b>
    </div>
    <div class="col-9 sous-menu" *ngIf="utilisateur">
      <b class="sous-menu-active1" routerLink="/catalogue">CATALOGUE</b>
    </div>
  </div>
  
  <ng-template #customLoadingTemplate></ng-template>
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading>
  <div class="card p-3" *ngIf="article">
    <div class="col d-flex justify-content-start">
      <b class="obligatoir-text">(*) Ce champ est obligatoire</b>
    </div>
    <div class="card-header " style="border: 1px solid green;margin-bottom: 1%;" >
      <b style="color:grey">INFORMATIONS</b>
    </div>
    <div *ngIf="showPart()==1">
      <form [formGroup]="form"  role="form">
        <div class="card-body">
        <div class="row">
          <div class="col-4" >
            <div class="title" style="margin-top: -46px;margin-bottom: -61px;">
              <select class="form-control input-sm select-options" id="type" formControlName="fournisseur"  [(ngModel)]="selectedFournisseur" name="fournisseur">
                <option [ngValue]="selectedFournisseur" disabled>{{selectedFournisseur.denomination}}</option>
                <option *ngFor="let fournisseur of fournisseurs" [ngValue]="fournisseur">{{fournisseur.denomination}} </option>
            </select>
            <control-messages [control]="form.controls.fournisseur"></control-messages>
  
            </div>
  
            <br/>
            <div class="title">
              <input class="font-weight-bold"  [(ngModel)]="article.mqe"  value="{{article.mqe}}" formControlName="mqe" id="mqe" name="mqe" placeholder="Marque" >
              <control-messages [control]="form.controls.mqe"></control-messages>
            </div>
            <br/>
            <div class="title">
              <input class="font-weight-bold"  placeholder="Référence*" [(ngModel)]="article.refCiel"  value="{{article.refCiel}}" formControlName="refCiel" id="refCiel" name="refCiel">
              <control-messages [control]="form.controls.refCiel"></control-messages>
  
            </div>
            <br/>
            <div class="title">
              <input class="font-weight-bold"  [(ngModel)]="article.gamme"  value="{{article.gamme}}" formControlName="gamme" id="gamme" name="gamme" placeholder="Gamme" >
              <control-messages [control]="form.controls.gamme"></control-messages>
            </div>
            <br/>
            <div class="title">
              <input class="font-weight-bold" [(ngModel)]="article.designation25"  value="{{article.designation25}}" formControlName="designation25" id="designation25" name="designation25" placeholder="Désignation*" >
              <control-messages [control]="form.controls.designation25"></control-messages>
            </div>
            <br/>
            <div class="title">
              <input class="font-weight-bold"  [(ngModel)]="article.typ"  value="{{article.typ}}" formControlName="typ" id="typ" name="typ" placeholder="Type" >
              <control-messages [control]="form.controls.typ"></control-messages>
            </div>
  
            <br/>
            <div class="title">
              <input class="font-weight-bold"  [(ngModel)]="article.dateArret"  value="{{article.dateArret}}" formControlName="dateArret" id="dateArret" name="dateArret" placeholder="Date arrêt" >
              <control-messages [control]="form.controls.dateArret"></control-messages>
            </div>
            <br/>
            <div class="title">
              <input class="font-weight-bold" formControlName="edi" id="edi" name="edi" [(ngModel)]="article.edi"  value="{{article.edi}}" placeholder="Edi" >
              <control-messages [control]="form.controls.edi"></control-messages>
            </div>
            <br/>
            <div class="title">
              <input class="font-weight-bold" formControlName="mkt2" id="mkt2" name="mkt2" placeholder="Mkt2" [(ngModel)]="article.mkt2"  value="{{article.mkt2}}">
              <control-messages [control]="form.controls.mkt2"></control-messages>
            </div>
            <br/>
            <div class="title">
              <input class="font-weight-bold" formControlName="mktl3" id="mktl3" name="mktl3" placeholder="Mktl3" [(ngModel)]="article.mktl3"  value="{{article.mktl3}}">
              <control-messages [control]="form.controls.mktl3"></control-messages>
            </div>
            <br/>
            <div class="title">
              <input class="font-weight-bold" formControlName="mktl5" id="mktl5" name="mktl5" placeholder="Mktl5" [(ngModel)]="article.mktl5"  value="{{article.mktl5}}">
              <control-messages [control]="form.controls.mktl5"></control-messages>
            </div>
          </div>
  
          <div class="col-4" style="margin-top:-30px">
            <div class="title">
              <input class="font-weight-bold input-show"   type="date" [(ngModel)]="article.datec" [max]="getBigDate()" value="{{article.datec}}" formControlName="datec" id="datec" name="datec" placeholder="Date de début*" >
              <control-messages [control]="form.controls.datec"></control-messages>
            </div>
            <br/>
  
            <div class="title" >
              <input class="font-weight-bold input-show" style="margin-top: 25px;" type="date"  [max]="getBigDate()" [min]="getDateDebut()" [(ngModel)]="article.dateFin"  value="{{article.dateFin}}" formControlName="dateFin" id="dateFin" name="dateFin" placeholder="Date de fin*" >
              <control-messages [control]="form.controls.dateFin"></control-messages>
            <p *ngIf="errorDate" style="color:red">Vérifie les dates !</p>
  
            </div>
            <br/>
  
            <div class="title">
              <input class="font-weight-bold"  type="number" min="0"  value="0" class="form-control input-sm"  [(ngModel)]="article.tarif"  value="{{article.tarif}}" formControlName="tarif" id="tarif" name="tarif" placeholder="Tarif*" >
              <control-messages [control]="form.controls.tarif"></control-messages>
            </div>
  
            <br/>
            <div class="title">
              <input class="font-weight-bold"  type="number" min="0"  value="0" [(ngModel)]="article.qt"  value="{{article.qt}}" formControlName="qt" id="qt" name="qt" placeholder="Quantité Catalogue*" >
              <control-messages [control]="form.controls.qt"></control-messages>
            </div>
          <br/>
            <div class="title">
              <input class="font-weight-bold"  [(ngModel)]="article.hautp"  value="{{article.hautp}}" formControlName="hautp" id="hautp" name="hautp" placeholder="Hautp" >
              <control-messages [control]="form.controls.hautp"></control-messages>
            </div>
            <br/>
            <div class="title">
              <input class="font-weight-bold"   formControlName="delaiLiv" id="delaiLiv" name="delaiLiv" [(ngModel)]="article.delaiLiv"  value="{{article.delaiLiv}}" placeholder="Delai Liv" >
              <control-messages [control]="form.controls.delaiLiv"></control-messages>
            </div>
            <br/>
            <div class="title">
              <input class="font-weight-bold"  formControlName="ean13" [(ngModel)]="article.ean13"  value="{{article.ean13}}" id="ean13" name="ean13" placeholder="Ean13" >
              <control-messages [control]="form.controls.ean13"></control-messages>
            </div>
            <br/>
            <div class="title">
              <input class="font-weight-bold"  formControlName="mkt1" id="mkt1" name="mkt1" [(ngModel)]="article.mkt1"  value="{{article.mkt1}}" placeholder="Mkt1" >
              <control-messages [control]="form.controls.mkt1"></control-messages>
            </div>
            <br/>
            <div class="title">
              <input class="font-weight-bold" formControlName="mktl2" id="mktl2" name="mktl2" [(ngModel)]="article.mktl2"  value="{{article.mktl2}}" placeholder="Mktl2" >
              <control-messages [control]="form.controls.mktl2"></control-messages>
            </div>
            <br/>
            <div class="title">
              <input class="font-weight-bold" formControlName="mkt4" id="mkt4" name="mkt4" placeholder="Mkt4" [(ngModel)]="article.mkt4"  value="{{article.mkt4}}">
              <control-messages [control]="form.controls.mkt4"></control-messages>
            </div>
            <br/>
            <div class="title">
              <input class="font-weight-bold" formControlName="mkt5" id="mkt5" name="mkt5" placeholder="Mkt5" [(ngModel)]="article.mkt5"  value="{{article.mkt5}}">
              <control-messages [control]="form.controls.mkt5"></control-messages>
            </div>
          </div>
          <div class="col-4">
            <div class="title">
              <input class="font-weight-bold"  formControlName="largp"  id="largp" name="largp" placeholder="Largp" >
              <control-messages [control]="form.controls.largp"></control-messages>
            </div>
            <br/>
  
            <div class="title">
              <input class="font-weight-bold"  [(ngModel)]="article.profp"  value="{{article.profp}}" formControlName="profp" id="profp" name="profp" placeholder="Profp" >
              <control-messages [control]="form.controls.profp"></control-messages>
            </div>
            <br/>
  
            <div class="title">
              <input class="font-weight-bold"  [(ngModel)]="article.poidsp"  value="{{article.poidsp}}" formControlName="poidsp" id="poidsp" name="poidsp" placeholder="Poidsp" >
              <control-messages [control]="form.controls.poidsp"></control-messages>
            </div>
  
            <br/>
            <div class="title">
              <input class="font-weight-bold"  [(ngModel)]="article.diam"  value="{{article.diam}}" formControlName="diam" id="diam" name="diam" placeholder="Diam" >
              <control-messages [control]="form.controls.diam"></control-messages>
            </div>
  
  
            <br/>
            <div class="title">
              <input class="font-weight-bold"  [(ngModel)]="article.statut"  value="{{article.statut}}" formControlName="statut" id="statut" name="statut" placeholder="Statut" >
              <control-messages [control]="form.controls.statut"></control-messages>
            </div>
            <br/>
            <div class="title">
              <input class="font-weight-bold" type="date" formControlName="dateLancement" id="dateLancement" [max]="getBigDate()" [(ngModel)]="article.dateLancement"  value="{{article.dateLancement}}" name="dateLancement" placeholder="Date lancement" >
              <control-messages [control]="form.controls.dateLancement"></control-messages>
            </div>
            <br/>
            <div class="title">
              <input class="font-weight-bold" formControlName="garantie" [(ngModel)]="article.garantie"  value="{{article.garantie}}" id="garantie" name="garantie" placeholder="Garantie" >
              <control-messages [control]="form.controls.garantie"></control-messages>
            </div>
            <br/>
            <div class="title">
              <input class="font-weight-bold"  formControlName="mktl1" id="mktl1" name="mktl1" placeholder="Mkt11" [(ngModel)]="article.mktl1"  value="{{article.mktl1}}">
              <control-messages [control]="form.controls.mktl1"></control-messages>
            </div>
            <br/>
            <div class="title">
              <input class="font-weight-bold" formControlName="mkt3" id="mkt3" name="mkt3" placeholder="Mkt3" [(ngModel)]="article.mkt3"  value="{{article.mkt3}}" >
              <control-messages [control]="form.controls.mkt3"></control-messages>
            </div>
            <br/>
            <div class="title">
              <input class="font-weight-bold" formControlName="mktl4" id="mktl4" name="mktl4" placeholder="Mktl4" [(ngModel)]="article.mktl4"  value="{{article.mktl4}}">
              <control-messages [control]="form.controls.mktl4"></control-messages>
            </div>
          </div>
        </div>
      </div>
      <div class=" col d-flex justify-content-center" style="padding-bottom: 1%">
        <div class="col-auto nbr-dossier " >
          <table>
            <tr>
              <td style="padding-right: 30px;" *ngIf="article">
                <button class="btn-annule" style="width: 200px;height: 50px;"  [routerLink]="['/catalogue']">Annuler <img src="assets/images/btn-annule.png"> </button>
  
              </td>
              <td style="padding-right: 30px;">
                <button class="btn-valider" style="width: 200px;height: 50px;" (click)="saveCatalogue(article)" [disabled]="!form.valid" >Modifier <img src="assets/images/save.png"></button>
  
              </td>
            </tr>
  
          </table>
        </div>
  
      </div>
  
      </form>
    </div>
  
  </div>
  