import { Component, OnInit } from '@angular/core';
import * as XLSX from "xlsx";
import { StockService } from "../../../services/stock.service";
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from "../../../services/user.service";
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { HistoriqueService } from "../../../services/historique.service";
@Component({
  selector: 'app-stock-sortie',
  templateUrl: './stock-sortie.component.html',
  styleUrls: ['../../utilisateur/ajout-utilisateur/ajout-utilisateur.component.scss'],
})
export class StockSortieComponent implements OnInit {
  form: any;
  dateDebut = new Date().toISOString().split('T')[0]
  showArray = false;
  fieldArray: Array<any> = [];
  newAttribute: any = {};
  loading = false;
  showErrorData = false
  showErrorDataQuantite = false;
  fieldArrayy: Array<any> = [];
  id:any;
  utilisateur:any;
  showErrorExistData = false;
  showErrorquantitetData = false;
  valueRef= 0
  unqieusdates:any;
  myDateInvetaire = new Date().toISOString().split('T')[0]
  bigDate = "9999-12-31"
  inventaireConfigObj:any;
  constructor(private stockService: StockService, private activatedRoute:ActivatedRoute,private userService:UserService,private historiqueService:HistoriqueService,
   private formBuilder: FormBuilder, private router: Router) {
    this.form = this.formBuilder.group({
      datec: ['', Validators.required]
    })
  }
  ngOnInit() {

    this.id = localStorage.getItem('idUser')
    this.userService.getUserByID(this.id).subscribe(utilisateur => {
      this.utilisateur = utilisateur;
      if(!this.utilisateur){
        localStorage.removeItem('idUser');
        localStorage.clear();
        this.router.navigate(['/login']);
      }
    },
      err => {
        return false;
      });
      this.stockService.getUniqueDateInventaire().then(data => {
        this.unqieusdates = data
        this.unqieusdates.sort(this.triYear)
        this.myDateInvetaire = this.unqieusdates[0]
      },
        err => {
          return false;
        });
  }
  getBigDate(): string {
      return this.bigDate
    }
  triYear(a, b) {
    if (a > b) return -1;
    else if (a == b) return 0;
    else return 1;
  }
  showArrayFunction() {
    this.showArray = true;
  }
  getDateDebut(): string {
    return this.dateDebut
  }
  addFieldValue() {
    this.showErrorExistData = false;
    this.newAttribute.datec = this.dateDebut
    this.newAttribute.exist = false;
    this.showErrorData = false;
    this.showErrorDataQuantite = false;
    this.showErrorquantitetData = false;
    if (!this.newAttribute.refArt || this.newAttribute.refArt == undefined || this.newAttribute.refArt == '' || this.newAttribute.refArt.length > 100) {
      this.showErrorData = true;
      return false;
    }
    if (!this.newAttribute.quantite || this.newAttribute.quantite == undefined || this.newAttribute.quantite == '' || this.newAttribute.quantite < 0 || this.newAttribute.quantite > 99999) {
      this.showErrorDataQuantite = true;
      return false;
    }
    if(this.newAttribute.quantite.toString().indexOf(",") != -1 || this.newAttribute.quantite.toString().indexOf(".") != -1){
      this.showErrorDataQuantite = true;
      return false;
    }
    this.showErrorData = false;
    this.showErrorDataQuantite = false;
    this.showErrorExistData = false;
    this.showErrorquantitetData = false;
    this.stockService.getArticleByRefArtAndDateInventaire(this.newAttribute.refArt,this.myDateInvetaire).then((dataInventiare: any[]) => {
      if (dataInventiare && dataInventiare.length > 0) {
        if(dataInventiare[dataInventiare.length - 1].quantiteHis[0].quantiteFinale < this.newAttribute.quantite){
          this.valueRef = this.newAttribute.quantite - dataInventiare[dataInventiare.length - 1].quantiteHis[0].quantiteFinale
          this.showErrorquantitetData = true;
          return false;
        }
        this.showErrorquantitetData = false;
        dataInventiare[dataInventiare.length - 1].quantiteHis[0].quantiteSortie =  dataInventiare[dataInventiare.length - 1].quantiteHis[0].quantiteSortie + this.newAttribute.quantite
        dataInventiare[dataInventiare.length - 1].quantiteHis[0].quantiteFinale =  dataInventiare[dataInventiare.length - 1].quantiteHis[0].quantiteFinale - this.newAttribute.quantite
        this.newAttribute.denomination = dataInventiare[dataInventiare.length - 1].denomination
        this.newAttribute.designation = dataInventiare[dataInventiare.length - 1].designation
        this.fieldArrayy.push(this.newAttribute)
        var hist = {
          "idUser": this.utilisateur.id,
          "date": new Date().toISOString().split('T')[0],
          "type": "Stock sortie",
          "dateInventaire":this.myDateInvetaire,
          "dataScan": [
            {
              "datec": this.newAttribute.datec,
              "quantite": this.newAttribute.quantite,
              "refArt": this.newAttribute.refArt,
              "designation": this.newAttribute.designation,

            }
          ]
        }
        this.stockService.editInventairerById(dataInventiare[dataInventiare.length - 1])
          .subscribe(resp => {
            this.historiqueService.getDataByDateAndUser(hist.date, hist.idUser,hist.type)
              .then((dataHist: any[]) => {
                if (dataHist && dataHist.length > 0) {
                  dataHist[0].dataScan.push(...hist.dataScan);
                  this.historiqueService.editIhistoriquerById(dataHist[0])
                    .subscribe(resp => {
                    }, err => {
                    })
                } else {
                  this.historiqueService.addhistorique(hist)
                    .subscribe(resp => {

                    }, err => {
                    })
                }
              }, err => {
              })
          }, err => {

          })
          this.newAttribute = {};
      }
      if(dataInventiare && dataInventiare.length < 1) {
        this.showErrorExistData = true;
        return false;
      }
    },
      err => {
        return false;
      });


  }

  deleteFieldValue(index) {
    var filedToDelete = this.fieldArrayy[index]
    if (filedToDelete) {
      this.stockService.getArticleByRefArtAndDateInventaire(filedToDelete.refArt,this.myDateInvetaire).then((dataInventiaree: any[]) => {
        if (dataInventiaree && dataInventiaree.length > 0) {
          dataInventiaree[dataInventiaree.length - 1].quantiteHis[0].quantiteSortie = dataInventiaree[dataInventiaree.length - 1].quantiteHis[0].quantiteSortie - filedToDelete.quantite
          dataInventiaree[dataInventiaree.length - 1].quantiteHis[0].quantiteFinale = dataInventiaree[dataInventiaree.length - 1].quantiteHis[0].quantiteFinale + filedToDelete.quantite
          this.stockService.editInventairerById(dataInventiaree[dataInventiaree.length - 1])
            .subscribe(resp => {
              var hist = {
                "idUser": this.utilisateur.id,
                "date": new Date().toISOString().split('T')[0],
                "type": "Stock sortie",
                "dateInventaire":this.myDateInvetaire,
                "dataScan": [
                  {
                    "datec": dataInventiaree[dataInventiaree.length - 1].datec,
                    "quantite": "-" + filedToDelete.quantite,
                    "refArt": dataInventiaree[dataInventiaree.length - 1].refArt,
                    "designation": dataInventiaree[dataInventiaree.length - 1].designation,

                  }
                ]
              }
              this.historiqueService.getDataByDateAndUser(hist.date, hist.idUser,hist.type)
                .then((dataHist: any[]) => {
                  if (dataHist && dataHist.length > 0) {
                    dataHist[0].dataScan.push(...hist.dataScan);
                    this.historiqueService.editIhistoriquerById(dataHist[0])
                      .subscribe(resp => {
                      }, err => {
                      })
                  } else {
                    this.historiqueService.addhistorique(hist)
                      .subscribe(resp => {

                      }, err => {
                      })
                  }
                }, err => {
                })
            }, err => {

            })
        }

        }, err => {

        })
    }
    this.fieldArrayy.splice(index, 1);
  }

}
