import { Component, OnInit } from '@angular/core';
import * as XLSX from "xlsx";
import { InventaireService } from "../../../services/inventaire.service";
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from "../../../services/user.service";
import * as moment from 'moment';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
import { codesSchneider } from '../../../pays/codesSchneider';
import { FamilleService } from "../../../services/famille.service";
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'app-inventaire',
  templateUrl: './inventaire.component.html',
  styleUrls: ['../../utilisateur/utilisateur/utilisateur.component.css'],
})
export class InventaireComponent implements OnInit {
  fileReaded: any;
  listeInventaire: any
  loading = false;
  sizeLimitOne = 30000000; //30MB
  numberData = 25;
  params:any;
  listeInventaireExcel:any[]=[]
  utilisateur:any;
  id:any;
  familles:any;
  selectedFamille:any;
  p = 1;
  filterinventaire:any;
  role:any;
  hasRolestock = false
  hasRoleAdd = false
  hasRoleUpdate = false
  hasRoleDelete = false
  constructor(private roleService:RoleService,private familleService:FamilleService,private inventaireService: InventaireService, private activatedRoute:ActivatedRoute,private router:Router,private userService:UserService) {

    this.params = this.activatedRoute.snapshot.params;
    this.id = localStorage.getItem('idUser')
    this.userService.getUserByID(this.id).subscribe(utilisateur => {
      this.utilisateur = utilisateur;
      if(!this.utilisateur){
        localStorage.removeItem('idUser');
        localStorage.clear();
        this.router.navigate(['/login']);
      }
      this.roleService.getRoleById(this.utilisateur.idRole).subscribe(role => {
        this.role = role;
        if (this.role && this.role.privileges && this.role.privileges.length > 0) {
          let founddeleteUtilisateur = this.role.privileges.find(x => x.name == "GET_ALL_STOCK")
          let founddeleteUtilisateuradd = this.role.privileges.find(x => x.name == "ADD_INVENTAIRE")
          let founddeleteUtilisateurupdate = this.role.privileges.find(x => x.name == "UPDATE_INVENTAIRE")
          let founddeleteUtilisateurdelete = this.role.privileges.find(x => x.name == "DELETE_INVENTAIRE")
          if (founddeleteUtilisateur) {
            this.hasRolestock = true;
          }
          if (founddeleteUtilisateuradd) {
            this.hasRoleAdd = true;
          }
          if (founddeleteUtilisateurupdate) {
            this.hasRoleUpdate = true;
          }
          if (founddeleteUtilisateurdelete) {
            this.hasRoleDelete = true;
          }
        }
      },
        err => {
          return false;
        }); 
        this.inventaireService.getinventaireactivationByDate(this.params.date)
          .then(data => {
            if(data && data["desactive"]){
              this.router.navigate(['/inventaire']);
            }
          }, err => {
          })
    },
      err => {
        return false;
      });
    this.loading = true;
    this.familleService.getAllData().then(data => {
      this.familles = data
      this.inventaireService.getAllDataByDateByFournisseur(this.params.id,this.params.date).then(data => {
        this.listeInventaire = data
        //this.startingAddingFamille(0)
        this.loading = false;
      },
        err => {
          return false;
        });
    },
      err => {
        return false;
      });

  }
  ngOnInit() {

  }
  startingAddingFamille(counter){
    if(counter === this.listeInventaire.length){
      //console.log("done")
      return true;
    }
    if( this.listeInventaire[counter].denomination == "SCHNEIDER ELECTRIC Tunisie"){
      if(this.listeInventaire[counter].catalogue && this.listeInventaire[counter].catalogue.length > 0 && this.listeInventaire[counter].catalogue[0].mkt5){
        var mymkt = this.listeInventaire[counter].catalogue[0].mkt5
        var foundFamillee = codesSchneider.filter(x => x.refFamille.toLowerCase().startsWith(mymkt[0].toLowerCase() + mymkt[1].toLowerCase()))
        if(!foundFamillee){
          counter++;
          this.startingAddingFamille(counter)
          return false;
        }
        if(foundFamillee && foundFamillee.length < 1){
          counter++;
          this.startingAddingFamille(counter)
          return false;
        }
        if(foundFamillee && foundFamillee.length == 1){
          this.listeInventaire[counter].codFamille = foundFamillee[0].codeFamille
          this.listeInventaire[counter].libelleFamille = foundFamillee[0].libelle
          counter++;
          this.startingAddingFamille(counter)
          return true;
        }
        if(foundFamillee && foundFamillee.length > 1 && mymkt[2]){
          var foundFamilleSecond = codesSchneider.filter(x => x.refFamille.toLowerCase().startsWith(mymkt[0].toLowerCase() + mymkt[1].toLowerCase() + mymkt[2].toLowerCase()))
          if(!foundFamilleSecond){
            this.listeInventaire[counter].codFamille = foundFamille[0].codeFamille
            this.listeInventaire[counter].libelleFamille = foundFamille[0].libelle
            counter++;
            this.startingAddingFamille(counter)
            return false;
          }
          if(foundFamilleSecond && foundFamilleSecond.length < 1){
            this.listeInventaire[counter].codFamille = foundFamille[0].codeFamille
            this.listeInventaire[counter].libelleFamille = foundFamille[0].libelle
            counter++;
            this.startingAddingFamille(counter)
            return false;
          }
          if(foundFamilleSecond && foundFamilleSecond.length == 1){
            this.listeInventaire[counter].codFamille = foundFamilleSecond[0].codeFamille
            this.listeInventaire[counter].libelleFamille = foundFamilleSecond[0].libelle
            counter++;
            this.startingAddingFamille(counter)
            return true;
          }
          if(foundFamille && foundFamille.length > 1 && mymkt[3]){
            var foundFamilleThird = codesSchneider.filter(x => x.refFamille.toLowerCase().startsWith(mymkt[0].toLowerCase() + mymkt[1].toLowerCase() + mymkt[2].toLowerCase() + mymkt[3].toLowerCase()))
            if(!foundFamilleThird){
              this.listeInventaire[counter].codFamille = foundFamilleSecond[0].codeFamille
              this.listeInventaire[counter].libelleFamille = foundFamilleSecond[0].libelle
              counter++;
              this.startingAddingFamille(counter)
              return true;
            }
            if(foundFamilleThird && foundFamilleThird.length < 1){
              this.listeInventaire[counter].codFamille = foundFamilleSecond[0].codeFamille
              this.listeInventaire[counter].libelleFamille = foundFamilleSecond[0].libelle
              counter++;
              this.startingAddingFamille(counter)
              return true;
            }
            if(foundFamilleThird && foundFamilleThird.length == 1){
              this.listeInventaire[counter].codFamille = foundFamilleThird[0].codeFamille
              this.listeInventaire[counter].libelleFamille = foundFamilleThird[0].libelle
              counter++;
              this.startingAddingFamille(counter)
              return true;
            }
          }
        }
      }
    }
    if(this.listeInventaire[counter] && this.listeInventaire[counter].denomination != "SCHNEIDER ELECTRIC Tunisie"  && this.listeInventaire[counter].denomination != "PHOENIX CONTACT"){
      var foundFamille = this.familles.filter(x => this.listeInventaire[counter].denomination.toLowerCase().indexOf(x.libelle.toLowerCase()) !== -1 )
      if(!foundFamille){
        counter++;
        this.startingAddingFamille(counter)
        return false;
      }
      if(foundFamille && foundFamille.length < 1){
        counter++;
        this.startingAddingFamille(counter)
        return false;
      }
      if(foundFamille && foundFamille.length > 0){
        this.listeInventaire[counter].codFamille = foundFamille[0].codFamille
        this.listeInventaire[counter].libelleFamille = foundFamille[0].libelle
        counter++;
        this.startingAddingFamille(counter)
        return true;
      }
    }
    if(this.listeInventaire[counter] && this.listeInventaire[counter].denomination == "PHOENIX CONTACT"){
      var foundFamille = this.familles.filter(x => x.libelle == "PHOENIX NET" )
      if(!foundFamille){
        counter++;
        this.startingAddingFamille(counter)
        return false;
      }
      if(foundFamille && foundFamille.length < 1){
        counter++;
        this.startingAddingFamille(counter)
        return false;
      }
      if(foundFamille && foundFamille.length > 0){
        this.listeInventaire[counter].codFamille = foundFamille[0].codFamille
        this.listeInventaire[counter].libelleFamille = foundFamille[0].libelle
        counter++;
        this.startingAddingFamille(counter)
        return true;
      }
    }
  }
  removebible(article) {
    if (!article.id) {
      Swal.fire({
      title: 'VOULEZ-VOUS SUPPRIMER CET ARTICLE?',
      imageUrl: 'assets/images/supp-modal.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      showCancelButton: true,
      width: 800,
      confirmButtonColor: '#059e00',
      cancelButtonColor: '#FF9100',
      cancelButtonText: 'ANNULER',
      confirmButtonText: 'VALIDER'
    }).then((result) => {
      if (!result.dismiss) {
        Swal.fire( {
            imageUrl: 'assets/images/supp-modal.png',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            width: 800,
            title: 'BIEN SUPPRIMÉ',
            timer: 1500,
            showConfirmButton: false
          }
        );
        this.listeInventaire.splice(this.listeInventaire.indexOf(article), 1);
        return true;
      }
    } );
    }
    Swal.fire({
    title: 'VOULEZ-VOUS SUPPRIMER CET ARTICLE?',
    imageUrl: 'assets/images/supp-modal.png',
    imageWidth: 100,
    imageHeight: 100,
    imageAlt: 'Custom image',
    showCancelButton: true,
    width: 800,
    confirmButtonColor: '#059e00',
    cancelButtonColor: '#FF9100',
    cancelButtonText: 'ANNULER',
    confirmButtonText: 'VALIDER'
  }).then((result) => {
    if (!result.dismiss) {
      Swal.fire( {
          imageUrl: 'assets/images/supp-modal.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 800,
          title: 'BIEN SUPPRIMÉ',
          timer: 1500,
          showConfirmButton: false
        }
      );
      this.inventaireService.deleteArticleById(article.id).then(data => {
      });
      this.listeInventaire.splice(this.listeInventaire.indexOf(article), 1);
    }
  } );
  }
exportDataToExcel(){
  if(this.listeInventaire.length < 1){
    return false;
  }
  this.loading = true;
  for(var i =0; i<this.listeInventaire.length;i++){
    if(this.listeInventaire[i].dateCommande){
      this.listeInventaire[i].dateCommande = moment(this.listeInventaire[i].dateCommande).format('DD/MM/YYYY')
    }
    if(this.listeInventaire[i].dateFacture){
      this.listeInventaire[i].dateFacture = moment(this.listeInventaire[i].dateFacture).format('DD/MM/YYYY')
    }
    var devExcel = {
      "Date de l'inventaire": moment(this.listeInventaire[i].datec).format('DD/MM/YYYY'),
      "Fournisseur":this.listeInventaire[i].denomination,
      "Référence":this.listeInventaire[i].refArt,
      "Désignation":this.listeInventaire[i].designation,
      "Prix Tarif":Math.round(this.listeInventaire[i].pritar * 100) / 100,
      "Coefficient d'achat	":Math.round(this.listeInventaire[i].coefAchat * 10000) / 10000,
      "Prix net	":Math.round(this.listeInventaire[i].prinet * 100) / 100,
      "Quantité emballée":this.listeInventaire[i].quantiteEmballe,
      "Quantité nom emballée":this.listeInventaire[i].quantiteNonEmballe,
      "Quantité":this.listeInventaire[i].quantite,
      "Étage":this.listeInventaire[i].etage,
      "Montant":Math.round(this.listeInventaire[i].montant * 100) / 100,
      "Numéro de commande":this.listeInventaire[i].numeroCommande,
      "Date de commande":this.listeInventaire[i].dateCommande,
      "Numéro de facture":this.listeInventaire[i].numeroFacture,
      "Date de facture": this.listeInventaire[i].dateFacture,
      "Observation": this.listeInventaire[i].observation,
      "Famille": this.listeInventaire[i].libelleFamille,
      "Code Famille": this.listeInventaire[i].codFamille,
      "Coef Famille": this.listeInventaire[i].coefFamille
    }
    this.listeInventaireExcel.push(devExcel)
  }
  const workBook = XLSX.utils.book_new(); // create a new blank book
  const workSheet = XLSX.utils.json_to_sheet(this.listeInventaireExcel);

  XLSX.utils.book_append_sheet(workBook, workSheet, 'data'); // add the worksheet to the book
  var mydate =  this.listeInventaire[0].datec[8]+this.listeInventaire[0].datec[9]+this.listeInventaire[0].datec[5]+this.listeInventaire[0].datec[6]+this.listeInventaire[0].datec[2]+this.listeInventaire[0].datec[3]
  XLSX.writeFile(workBook, 'VoWork-Inventaire-'+ mydate + '-' + this.listeInventaire[0].denomination +'.xlsx'); // initiate a file download in browser
  this.loading = false
}
/*exportDataToPDF(){
  if(this.listeInventaire.length < 1){
    return false;
  }
  this.loading = true;
  var doc = new jsPDF();
    var col = ["Date", "Four","Réf","PriTar","Coef","PriNet","Qté","Montant","N° commande","Date de commande","N° facture","Date de facture"];
    var rows = [];
    for(var i =0; i<this.listeInventaire.length;i++){
      var newObjet = [
        this.listeInventaire[i].datec,this.listeInventaire[i].denomination,
        this.listeInventaire[i].refArt,
        Number(this.listeInventaire[i].pritar).toFixed(2),Number(this.listeInventaire[i].coefAchat).toFixed(4),
        Number(this.listeInventaire[i].prinet).toFixed(2),this.listeInventaire[i].quantite,
        Number(this.listeInventaire[i].montant).toFixed(2),this.listeInventaire[i].numeroCommande,
        this.listeInventaire[i].dateCommande,
        this.listeInventaire[i].numeroFacture,
        this.listeInventaire[i].dateFacture
      ]
      rows.push(newObjet);
    }
    doc.autoTable(col, rows,{
       margin: {top: 10},
       theme: 'grid',

    });
    doc.save('MyVoguel-Inventaire-'+this.listeInventaire[0].datec + '-' + this.listeInventaire[0].denomination +'.pdf');
  this.loading = false
}*/

//appliquer formule de Schneider
getFamille(mkt5){
  this.selectedFamille = undefined
  if(mkt5[0] && mkt5[0]){

  }
}


}
