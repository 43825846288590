<app-navbar></app-navbar>  
<br>
<div id="content-nav-page" class="row" *ngIf="utilisateur">
    <div class=" col-3 titre-menu">
      <a routerLink="/utilisateur" class=" icon-f-left"></a>
      <b class="title-haut">{{utilisateur.prenom}} {{utilisateur.nom}}<img class="ml-2" src="assets/images/show-a.png"></b>
    </div>
    <div class="col-9 sous-menu">
      <b class="sous-menu-active1" routerLink="/utilisateur">UTILISATEUR</b>
    </div>
  </div>
  <div class="row">
  
    <div class="col-xl-2 col-lg-3  col-md-3 col-sm-4 left-content-scrolbar ">
  
      <div class="col col-content ">
        <button class="tablink2 btn-liste" id="defaultOpen2" (click)="modifPart1()">
          INFORMATIONS PERSONNELLES</button>
      </div>
  
      <div class="col col-content">
        <button class="tablink2 btn-liste " (click)="modifPart2()"> INFORMATIONS
          COMPLÉMENTAIRES </button>
      </div>
  
      <div class="col col-content">
        <button class="tablink2 btn-liste " (click)="modifPart3()"> PARCOURS ACADÉMIQUES
        </button>
      </div>
  
      <div class="col col-content">
        <button class="tablink2 btn-liste " (click)="modifPart4()"> ÉXPERIENCES PROFESSIONNELLES
        </button>
      </div>
      <div class="col col-content">
        <button class="tablink2 btn-liste " (click)="modifPart5()"> LANGUES ET COMPÉTENCES
        </button>
      </div>
  
      <div class="col col-content">
        <button class="tablink2 btn-liste " (click)="modifPart6()"> COORDONNÉES BANCAIRES
        </button>
      </div>
      <div class="col col-content">
        <button class="tablink2 btn-liste " (click)="modifPart7()"> STATISTIQUES
        </button>
      </div>
  
    </div>
  
  
  
  
    <div class="col-xl-10 col-lg-9  col-md-9 col-sm-8 right-content-scrolbar" *ngIf="utilisateur">
      <div class="card p-3">
        <div *ngIf="showPart()==1" class="col-content-affiche tabcontent2" id="information">
          <div>
            <div class="card-body">
  
              <div class="row">
                <div class="col-6">
                  <div class="title">
                    <h4>Nom</h4>
                    <span class="font-weight-bold">{{utilisateur.nom}}</span>
                  </div>
  
                  <br />
                  <div class="title ">
                    <h4>Prénom</h4>
                    <span class="font-weight-bold">{{utilisateur.prenom}} </span>
                  </div>
                  <br />
  
                  <div class="title">
                    <h4>Email</h4>
                    <span class="font-weight-bold">{{utilisateur.mail}}</span>
                  </div>
                  <br />
                  <div class="title">
                    <h4>Téléphone</h4>
                    <span class="font-weight-bold">{{utilisateur.tel}}</span>
  
                  </div>
  
                  <br />
                  <div class="title">
                    <h4>Numéro de pièce d'identité</h4>
                    <span class="font-weight-bold">{{utilisateur.numIdentite}}</span>
  
                  </div>
  
                </div>
  
                <div class="col-6">
  
                  <div class="title">
                    <h4>Sexe</h4>
                    <span class="font-weight-bold">{{utilisateur.sexe}}</span>
  
                  </div>
                  <br />
                  <div class="title">
                    <h4>Adresse</h4>
                    <span class="font-weight-bold">{{utilisateur.adresse.n}} {{utilisateur.adresse.rue}} {{utilisateur.adresse.codePostal}} {{utilisateur.adresse.ville}} </span>
                  </div>
                  <br />
  
                  <div class="title">
                    <h4>Pays</h4>
                    <span class="font-weight-bold">{{utilisateur.adresse.pays}}</span>
                  </div>
                  <br />
                  <div class="title">
                    <h4>Poste</h4>
                    <span class="font-weight-bold">{{utilisateur.poste.titre}} {{utilisateur.poste.departement}} {{utilisateur.poste.bureau}}</span>
                  </div>
                </div>
              </div>
              <br><br><br>
              <div class="title">
                <h4>Historiques</h4>
              </div>
              <div class="row ligne-table2  d-flex justify-content-center">
                <table class="table-responsive">
  
                    <th class="col-3 p-l-0">
                      Jour
                    </th>
                    <th class="col-3 p-l-0">
                      Module
                    </th>
                    <th class="col-3 p-l-0">
                      Type
                    </th>
                    <th class="col-3 p-l-0">
                      Date du stock / Commande
                    </th>
                    <th class="col-3 p-l-0">
                      Historiques
                    </th>
  
                  <tr  *ngFor="let historique of historiques| paginate: { id:'enfantsPagg',itemsPerPage: 25, currentPage: enfantpp }">
                    <td>{{historique.date | date : 'dd/MM/yyyy'}}</td>
                    <td style="text-transform: capitalize;"><span *ngIf="historique.module">{{historique.module}}</span><span *ngIf="!historique.module">Scan</span></td>
                    <td style="text-transform: capitalize;">{{historique.type}}</td>
                    <td><span *ngIf="historique.idModule" [routerLink]="['/details/commande', historique.idModule]">Lien</span><span *ngIf="!historique.idModule">{{historique.dateInventaire | date : 'dd/MM/yyyy'}}</span></td>
                    <td>
                      <span *ngIf="historique.dataScan">
                        <li style="list-style-type:none;">
                          Date | Référence | Quantité
                        </li>
                        <li *ngFor="let dataS of historique.dataScan">
                          {{dataS.datec | date : 'dd/MM/yyyy'}} | {{dataS.refArt}} | {{dataS.quantite}}
                        </li>
                      </span>
                      <span *ngIf="historique.modifications">
                        <li style="list-style-type:none;" [routerLink]="['/details/utilisateur/historique', historique.id]">
                          Voir plus
                        </li>
  
                      </span>
                    </td>
                  </tr>
  
                </table>
                <div class="row">
                  <div class="col col-xs-6">
                    <pagination-controls previousLabel="Précédent" nextLabel="Suivant" (pageChange)="enfantpp = $event" id="enfantsPagg"></pagination-controls>
                  </div>
                </div>
  
  
              </div>
            </div>
          </div>
        </div>
  
  
        <div *ngIf="showPart()==2" class="col-content-affiche tabcontent2" id="information_compl">
  
          <div>
            <div class="card-body">
  
              <div class="row">
                <div class="col-6">
                  <div class="title">
                    <h4>Situation familiale</h4>
                    <span class="font-weight-bold">{{utilisateur.situationFamiliale}} </span>
                  </div>
  
                  <br />
                  <div class="title " *ngIf="utilisateur.conjoint">
                    <h4>Nom du conjoint</h4>
                    <span class="font-weight-bold"> {{utilisateur.conjoint.nom}} </span>
                  </div>
                  <br />
  
                  <div class="title" *ngIf="utilisateur.conjoint">
                    <h4>Prénom du conjoint</h4>
                    <span class="font-weight-bold"> {{utilisateur.conjoint.prenom}} </span>
                  </div>
                  <br />
                  <div class="title" *ngIf="utilisateur.conjoint">
                    <h4>Email du conjoint</h4>
                    <span class="font-weight-bold">{{utilisateur.conjoint.mail}}</span>
  
                  </div>
                </div>
                <div class="col-6" *ngIf="utilisateur.conjoint">
                  <div class="title">
                    <h4>Téléphone du conjoint</h4>
                    <span class="font-weight-bold">{{utilisateur.conjoint.tel}}</span>
                  </div>
                  <br />
  
                  <div class="title" *ngIf="utilisateur.conjoint">
                    <h4>Nationalité du conjoint</h4>
                    <span class="font-weight-bold">{{utilisateur.conjoint.nationalite}}</span>
                  </div>
                  <br />
  
                  <div class="title" *ngIf="utilisateur.conjoint">
                    <h4>Organisme de santé du conjoint</h4>
                    <span class="font-weight-bold">{{utilisateur.conjoint.OrganismeDeSante}}</span>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="utilisateur.enfantss">
                <h3>Les enfants</h3>
                <table class="table table-striped table-hover" id="myTable">
                  <th>Nom</th>
                  <th>Prénom</th>
                  <th>Date de naissance</th>
                  <th>Sexe</th>
                  <tr class="text-center" *ngFor="let enfant of utilisateur.enfants| paginate: { id:'enfantsPag',itemsPerPage: 4, currentPage: enfantp }">
                    <td>{{enfant.nom}}</td>
                    <td>{{enfant.prenom}}</td>
                    <td>{{enfant.dateNaissance | date : 'dd/MM/yyyy'}}</td>
                    <td>{{enfant.sexe}}</td>
                  </tr>
                </table>
                <div class="row">
                  <div class="col col-xs-6">
                    <pagination-controls previousLabel="Précédent" nextLabel="Suivant" (pageChange)="enfantp = $event" id="enfantsPag"></pagination-controls>
                  </div>
                </div>
              </div>
  
            </div>
          </div>
        </div>
  
        <div *ngIf="showPart()==3" class="col-content-affiche tabcontent2" id="etablissement">
  
          <div>
            <div class="card-body">
              <!--<div class="title">
              <h4>Multi établissement</h4>
              <span class="font-weight-bold"> Non </span>
            </div>-->
              <div class="row ligne-table2  d-flex justify-content-center">
                <table class="table-responsive" *ngIf="utilisateur.parcours">
                  <tr >
                    <td class="col-3 p-l-0">
                      Nom d'établissement
                    </td>
                    <td class="col-3 p-l-0">
                      Nom du diplôme
                    </td>
                    <td class="col-3 p-l-0">
                      Type du diplôme
                    </td>
                    <td class="col-3 p-l-0">
                      Date d'obtention
                    </td>
                    <td class="col-3 p-l-0">
                      Description
                    </td>
                  </tr>
                  <tr  *ngFor="let parcour of utilisateur.parcours| paginate: {id:'first', itemsPerPage: 4, currentPage: fp }">
                    <td>{{parcour.etablissement}}</td>
                    <td>{{parcour.diplomen}}</td>
                    <td>{{parcour.diplomet}}</td>
                    <td>{{parcour.date | date : 'dd/MM/yyyy'}}</td>
                    <td>{{parcour.desc}}</td>
                  </tr>
                </table>
                <div class="row">
                  <div class="col col-xs-6">
                    <pagination-controls previousLabel="Précédent" nextLabel="Suivant" (pageChange)="fp = $event" id="first"></pagination-controls>
                  </div>
                </div>
  
              </div>
            </div>
          </div>
        </div>
  
  
        <div *ngIf="showPart()==4" class="col-content-affiche tabcontent2" id="comp">
  
          <div>
            <div class="card-body">
              <div class="row ligne-table2  d-flex justify-content-center">
                <table class="table-responsive" *ngIf="utilisateur.experiences">
                  <tr >
                    <td class="col-3 p-l-0">
                      Nom de l'entreprise
                    </td>
                    <td class="col-3 p-l-0">
                      Nom du poste
                    </td>
                    <td class="col-3 p-l-0">
                      Date de début
                    </td>
                    <td class="col-3 p-l-0">
                      Date de fin
                    </td>
                    <td class="col-3 p-l-0">
                      Description
                    </td>
                  </tr>
                  <tr  *ngFor="let experience of utilisateur.experiences| paginate: { id:'second',itemsPerPage: 4, currentPage: sp }">
                    <td>{{experience.entreprise}}</td>
                    <td>{{experience.poste}}</td>
                    <td>{{experience.dated| date: 'dd/MM/yyyy'}}</td>
                    <td>{{experience.datef| date: 'dd/MM/yyyy'}}</td>
                    <td>{{experience.desc}}</td>
                  </tr>
                </table>
                <div class="row">
                  <div class="col col-xs-6">
                    <pagination-controls previousLabel="Précédent" nextLabel="Suivant" (pageChange)="sp = $event" id="second"></pagination-controls>
                  </div>
                </div>
  
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="showPart()==5" class="col-content-affiche tabcontent2" id="com">
  
          <div>
            <div class="card-body">
              <div>
                <div class="title">
                  <h4>Langues</h4>
                </div>
                <div class="row ligne-table2  d-flex justify-content-center">
                  <table class="table-responsive" *ngIf="utilisateur.languages">
                    <tr>
                      <td class="col-12 p-l-0">
                        Les langues
                      </td>
                    </tr>
                    <tr  *ngFor="let langue of utilisateur.languages| paginate: {id:'third', itemsPerPage: 4, currentPage: ddd }">
                      <td style="text-transform: capitalize;">{{langue}}</td>
                    </tr>
                  </table>
                  <div class="row">
                    <div class="col col-xs-6">
                      <pagination-controls previousLabel="Précédent" nextLabel="Suivant" (pageChange)="ddd = $event" id="third"></pagination-controls>
                    </div>
                  </div>
  
                </div>
              </div>
              <div style="align-items: center; padding-top: 50px;">
                <div class="title">
                  <h4>Les compétences</h4>
                </div>
                <div class="row ligne-table2  d-flex justify-content-center">
                  <table class="table-responsive">
                    <tr>
                      <td class="col-12 p-l-0">
                        Compétences
                      </td>
                    </tr>
                    <tr  *ngFor="let competence of utilisateur.competences| paginate: { itemsPerPage: 4, currentPage: pc }">
                      <td>{{competence}}</td>
                    </tr>
                  </table>
                  <div class="row">
                    <div class="col col-xs-6">
                      <pagination-controls previousLabel="Précédent" nextLabel="Suivant" (pageChange)="pc = $event" #api></pagination-controls>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="showPart()==6" class="col-content-affiche tabcontent2" id="coordonnees_bancaires" style="padding-top: 2%">
          <div>
            <div class="card-body">
  
              <div class="row">
                <div class="col-6">
                  <div class="title" *ngIf="utilisateur.informationbancaire">
                    <h4>Nom</h4>
                    <span class="font-weight-bold">{{utilisateur.informationbancaire.nom}} </span>
                  </div>
  
                  <br />
                  <div class="title " *ngIf="utilisateur.informationbancaire">
                    <h4>Swift</h4>
                    <span class="font-weight-bold">{{utilisateur.informationbancaire.swift}} </span>
                  </div>
                  <br />
  
                  <div class="title" *ngIf="utilisateur.informationbancaire">
                    <h4>Bic</h4>
                    <span class="font-weight-bold">{{utilisateur.informationbancaire.bic}}</span>
                  </div>
  
                </div>
  
                <div class="col-6">
  
                  <div class="title" *ngIf="utilisateur.informationbancaire">
                    <h4>Iban</h4>
                    <span class="font-weight-bold">{{utilisateur.informationbancaire.iban}}</span>
                  </div>
  
                  <br />
                  <div class="title" *ngIf="utilisateur.informationbancaire">
                    <h4>Rib</h4>
                    <span class="font-weight-bold">{{utilisateur.informationbancaire.rib}} </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div *ngIf="showPart()==7" class="col-content-affiche tabcontent2" id="static">
          <h3 align="center">{{utilisateur.nom}} {{utilisateur.prenom}} : <span *ngIf="clientAllDossiers">{{clientAllDossiers.length}}</span> Dossiers</h3>
          <div>
            <div class="card-body">
              <div>
                <div class="title">
                  <h4>Nombre des dossiers par type et par année</h4>
                </div>
                <div class="row ligne-table2  d-flex justify-content-center table-padding">
                  <table class="table-responsive" >
                    <tr>
                      <td class="left">
                        Types
                      </td>
                      <td *ngFor="let year of allyears" class="right">
                        {{year}}
                      </td>
                    </tr>
                    <tr >
                      <td class="left">Etude</td>
                      <td *ngFor="let year of allyears" class="right">
                        {{filterBytype[year]["Etude"].length }}
                      </td>
  
                    </tr>
                    <tr >
                      <td class="left">Réalisation</td>
                      <td *ngFor="let year of allyears" class="right">
                        {{filterBytype[year]["Réalisation"].length }}
                      </td>
                    </tr>
                    <tr >
                      <td class="left">Intervention</td>
                      <td *ngFor="let year of allyears" class="right">
                        {{filterBytype[year]["Intervention"].length }}
                      </td>
  
                    </tr>
                    <tr >
                      <td class="left">Fournitures</td>
                      <td *ngFor="let year of allyears" class="right">
                        {{filterBytype[year]["Fournitures"].length }}
                      </td>
                    </tr>
                    <tr >
                      <td></td>
                      <td></td>
                    </tr>
                    <tr >
                      <td class="left">Total</td>
                      <td *ngFor="let year of allyears" class="right">
                        {{filterBytype[year]["Etude"].length + filterBytype[year]["Réalisation"].length + filterBytype[year]["Intervention"].length + filterBytype[year]["Fournitures"].length }}
                      </td>
  
                    </tr>
                  </table>
                </div>
              </div>
              <div style="align-items: center; padding-top: 50px;">
                <div class="title">
                  <h4>Nombre des dossiers par statut et par année</h4>
                </div>
                <div class="row ligne-table2  d-flex justify-content-center table-padding">
                  <table class="table-responsive">
                    <tr>
                      <td class="left">Statut</td>
                      <td *ngFor="let year of allyears">{{year}}</td>
                    </tr>
                    <tr>
                      <td class="left">Non affecté</td>
                      <td *ngFor="let year of allyears" class="right">
                        {{filterByStatut[year]["01 - Non affecté"].length }}
                      </td>
                    </tr>
                    <tr>
                      <td class="left">Vide</td>
                      <td *ngFor="let year of allyears" class="right">
                        {{filterByStatut[year]["02- Vide"].length }}
                      </td>
                    </tr>
                    <tr>
                      <td class="left">NE</td>
                      <td *ngFor="let year of allyears" class="right">
                        {{filterByStatut[year]["03 - Null"].length }}
                      </td>
                    </tr>
                    <tr>
                      <td class="left">En cours</td>
                      <td *ngFor="let year of allyears" class="right">
                        {{filterByStatut[year]["04 - En cours"].length }}
                      </td>
                    </tr>
                    <tr>
                      <td class="left">En attente</td>
                      <td *ngFor="let year of allyears" class="right">
                        {{filterByStatut[year]["05 - En attente"].length }}
                      </td>
                    </tr>
                    <tr>
                      <td class="left">Envoyé</td>
                      <td *ngFor="let year of allyears" class="right">
                        {{filterByStatut[year]["06 - Envoyé"].length }}
                      </td>
                    </tr>
                    <tr>
                      <td class="left">Perdu</td>
                      <td *ngFor="let year of allyears" class="right">
                        {{filterByStatut[year]["07 - Perdu"].length }}
                      </td>
                    </tr>
  
                    <tr>
                      <td class="left">DM</td>
                      <td *ngFor="let year of allyears" class="right">
                        {{filterByStatut[year]["08 - DM"].length }}
                      </td>
                    </tr>
                    <tr>
                      <td class="left">CDE</td>
                      <td *ngFor="let year of allyears" class="right">
                        {{filterByStatut[year]["09 - CDE"].length }}
                      </td>
                    </tr>
                    <tr>
                      <td class="left">FAB</td>
                      <td *ngFor="let year of allyears" class="right">
                        {{filterByStatut[year]["10 - FAB"].length }}
                      </td>
                    </tr>
                    <tr>
                      <td class="left">Livrée</td>
                      <td *ngFor="let year of allyears" class="right">
                        {{filterByStatut[year]["11 - Livrée"].length }}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td class="left">Total</td>
                      <td *ngFor="let year of allyears" class="right">
                        {{filterByStatut[year]["01 - Non affecté"].length + filterByStatut[year]["02- Vide"].length + filterByStatut[year]["03 - Null"].length  + filterByStatut[year]["04 - En cours"].length
                          + filterByStatut[year]["05 - En attente"].length + filterByStatut[year]["06 - Envoyé"].length + filterByStatut[year]["07 - Perdu"].length +
                           filterByStatut[year]["08 - DM"].length + filterByStatut[year]["09 - CDE"].length + filterByStatut[year]["10 - FAB"].length + filterByStatut[year]["11 - Livrée"].length
  
                         }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
  
              <div style="align-items: center; padding-top: 50px;">
                <div class="title">
                  <h4>Nombre des dossiers par chargé d'affaires et par année</h4>
                </div>
                <div class="row ligne-table2  d-flex justify-content-center table-padding">
                  <table class="table-responsive">
                    <tr>
                      <td class="left">Chargé d'affaires</td>
                      <td *ngFor="let year of allyears" class="right">{{year}}</td>
                    </tr>
                    <tr *ngFor="let idACA of idAllCA">
                      <td class="left">{{dossierbyidClientFilterBYCA[idACA][0].yeardateReception.prenomCA}} {{dossierbyidClientFilterBYCA[idACA][0].yeardateReception.nomCA}}</td>
                      <td *ngFor="let year of allyears;let i=index" class="right">
                        <span *ngFor="let liste of dossierbyidClientFilterBYCA[idACA]; let j = index">
                          <span *ngIf=" liste.yeardateReception.yeardateReception == allyears[i]">{{liste.number}}</span>
                        </span>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
  
              <div style="align-items: center; padding-top: 50px;">
                <div class="title">
                  <h4>Nombre des dossiers par client et par année</h4>
                </div>
                <div class="row ligne-table2  d-flex justify-content-center table-padding">
                  <table class="table-responsive">
                    <tr>
                      <td class="left">Client</td>
                      <td *ngFor="let year of allyears" class="right">{{year}}</td>
                    </tr>
                    <tr *ngFor="let idACH of idAllCH">
                      <td class="left">{{dossierbyidClientFilterBYCH[idACH][0].yeardateReception.nomClient}}</td>
                      <td *ngFor="let year of allyears;let i=index" class="right">
                        <span *ngFor="let liste of dossierbyidClientFilterBYCH[idACH]; let j = index">
                          <span *ngIf=" liste.yeardateReception.yeardateReception == allyears[i]">{{liste.number}}</span>
                        </span>
                      </td>
                    </tr>
  
  
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
  
      </div>
    </div>
  </div>
  
