<app-navbar></app-navbar>
<!-- liste des utilisateurs -->
<br><br>
<ng-template #customLoadingTemplate></ng-template>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading>

<div id="sevices-item-form" class="row">
  <p style="color:red" id="sevices-item-form">* : Les champs obligatoires</p>
  <br>
  <a style="color: #343434;font-size: 15px;cursor: pointer;text-decoration: underline;" href="assets/templates/inventaire.xlsx"> Télécharger le modèle</a>
  <br><br>
  <form [formGroup]="form" class="form-horizontal" role="form">
    <fieldset>

      <div class="form-group col-md-12 col-sm-12" >
        <div class="form-group col-md-6 col-sm-6" style="margin-right: 2px;">
          <label for="nom">Date* </label>
          <input type="date" class="form-control input-sm" [(ngModel)]="dateDebut" [max]="getBigDate()" formControlName="datec" id="datec" name="datec">
          <control-messages [control]="form.controls.datec"></control-messages>
        </div>
      </div>
      <div class="form-group col-md-12 col-sm-12" >
        <div class="form-group col-md-6 col-sm-6" style="margin-right: 2px;">
          <label for="nom">Fichier* </label>
          <input type="file" (change)="onFileChange($event)" formControlName="file" id="file" accept=".xlsx, .xls, .csv" [(ngModel)]="fileToChoose">
          <control-messages [control]="form.controls.file"></control-messages>
        </div>
      </div>
      <div class="form-group col-md-12 col-sm-12">
        <p *ngIf="addedLength > 0" style="font-size: 15px;font-weight: bold;">Le nombre des lignes du fichier ajouté est : {{addedLength | number}} lignes</p>
        <p *ngIf="tarifGlobal > 0" style="font-size: 15px;font-weight: bold;">Le tarif gloab du fichier ajouté est : {{tarifGlobal | number: '1.2-2'| replace:',':' ' | replace:'.':',' }} € </p>
        <p *ngIf="errorObjet" style="font-size: 15px;font-weight: bold;color:red;">{{errorObjet}}</p>
        <p *ngIf="numberErrorLine > 0" style="font-size: 15px;font-weight: bold;color:red;">Vous avez {{numberErrorLine | number }} articles inconnus</p>
      </div>
      <div class="col-sm-10">
          <button id="btn-inscrire-utilisateur"  type="button" name="button" (click)="saveData()" style="background-color: #50545a;font-size: 15px;font-weight: bold;margin-bottom: 30px;margin-top: 20px;color: white;border-color: #50545a;" [disabled]="!form.valid"  class="btn btn-default navbar-btn"
            >Enregistrer</button>
            <button id="btn-inscrire-utilisateur"  type="button" name="button" [routerLink]="['/inventaire']" style="background-color: #ffffff;font-size: 15px;font-weight: bold;margin-bottom: 30px;margin-top: 20px;color: #50545a;border-color: #50545a;"   class="btn btn-default navbar-btn"
              >Annuler</button>
      </div>
    </fieldset>
  </form>
</div>

