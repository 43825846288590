<app-navbar></app-navbar>
<br>
<div id="content-nav-page" class="row" *ngIf="commande">
    <div class=" col-3 titre-menu" *ngIf="dossier">
      <a [routerLink]="['/details/dossier', dossier.id]" class=" icon-f-left"></a>
      <b class="title-haut">{{commande.numeroCommande}} <img class="ml-2" src="assets/images/show-a.png"></b>
    </div>
    <div class="col-9 sous-menu" *ngIf="utilisateur">
      <b class="sous-menu-active1" [routerLink]="['/dossier']"
        *ngIf="utilisateur.role === 'Super admin' || utilisateur.role === 'Admin' || utilisateur.role === 'Equipe chiffrage' || utilisateur.role === 'Administration' || (utilisateur.role === 'Equipe Atelier' && utilisateur.sousRole === 'Responsable magasin') || utilisateur.role === 'Chiffreur'">DOSSIER</b>
      <b class="sous-menu-active2 ml-4" [routerLink]="['/commande']" style="cursor: pointer" *ngIf="utilisateur.mail != 'r.diop@krendel.fr'">COMMANDE</b>
      
    </div>
  </div>
  <div class="row" *ngIf="commande">
    <div class="col-xl-2 col-lg-3  col-md-3 col-sm-4 left-content-scrolbar ">
      <div class="col col-content ">
        <button class="tablink2 btn-liste" id="defaultOpen2" (click)="modifPart1()">
          INFORMATIONS</button>
      </div>
      <div class="col col-content">
        <button class="tablink2 btn-liste " (click)="modifPart2()"> LIGNES </button>
      </div>
      <div class="col col-content" *ngIf="( utilisateur &&  utilisateur.role != 'Equipe Atelier')">
        <button class="tablink2 btn-liste " (click)="modifPart3()"> FICHIERS
        </button>
      </div>
  
    </div>
    <div class="col-xl-10 col-lg-9  col-md-9 col-sm-8 right-content-scrolbar">
      <div class="card p-3">
        <div *ngIf="showPart()==1" class="col-content-affiche tabcontent2" id="information">
  
          <div>
            <div class="card-body">
  
              <div class="row">
                <div class="col-6">
                  <div class="title">
                    <h4>Numéro de commande</h4>
                    <span class="font-weight-bold">{{commande.numeroCommande}}</span>
                  </div>
  
                  <br />
                  <div class="title ">
                    <h4>Numéro de commande GPMI</h4>
                    <span class="font-weight-bold">{{commande.numeroCommandeGPMI}} </span>
                  </div>
                  <br />
  
                  <div class="title">
                    <h4>Fournisseur </h4>
                    <span class="font-weight-bold">{{commande.denomination}}</span>
                  </div>
                  <br />
                  <div class="title">
                    <h4>Date de commande</h4>
                    <span class="font-weight-bold">{{commande.dateCommande | date : 'dd/MM/yyyy'}}</span>
  
                  </div>
                  <br />
                  <div class="title">
                    <h4>Date de livraison</h4>
                    <span class="font-weight-bold">{{commande.dateLivraison | date : 'dd/MM/yyyy'}}</span>
  
                  </div>
  
                </div>
  
                <div class="col-6">
  
                  <div class="title" *ngIf="( utilisateur &&  utilisateur.role != 'Equipe Atelier')">
                    <h4>Prix net total HT</h4>
                    <span class="font-weight-bold" *ngIf="commande.sommePrixNet && commande.sommePrixNet != 'NaN'">{{commande.sommePrixNet| number: '1.2-2'| replace:',':' ' | replace:'.':','}}</span>
  
                  </div>
                  <br />
                  <div class="title" *ngIf="( utilisateur &&  utilisateur.role != 'Equipe Atelier')">
                    <h4>Montant de la TVA</h4>
                    <span class="font-weight-bold" *ngIf="commande.montantTVA && commande.montantTVA != 'NaN'">{{commande.montantTVA| number: '1.2-2'| replace:',':' ' | replace:'.':','}} </span>
                  </div>
                  <br />
  
                  <div class="title" *ngIf="( utilisateur &&  utilisateur.role != 'Equipe Atelier')">
                    <h4>Prix net total TTC</h4>
                    <span class="font-weight-bold" *ngIf="commande.sommePrixNetTTC && commande.sommePrixNetTTC != 'NaN'">{{commande.sommePrixNetTTC| number: '1.2-2'| replace:',':' ' | replace:'.':','}}</span>
                  </div>
                  <br />
  
                  <div class="title">
                    <h4>Statut </h4>
                    <span class="font-weight-bold">{{commande.etat}}</span>
                  </div>
                  <br />
                </div>
              </div>
              <br><br>
              <div class="row">
                <h5 style="text-align:center;color:green">Historiques des dates de livraison <br> <br><br> </h5>
                <div class="" style="color:green">
                  <br><span class="font-weight-bold" *ngFor="let date of commande.allDateLivraison">{{date}} <br></span>
  
                </div>
              </div>
              <br><br>
              <div class="row" *ngIf="(utilisateur && utilisateur.role === 'Super admin') || (utilisateur && utilisateur.role === 'Administration') ||  (utilisateur && utilisateur.role === 'Responsable commandes')">
                <div class="col-md-6 m-t-lg m-t-md m-t-xs m-t-sm">
                  <h5 *ngFor="let facture of commande.factures" style="text-align:center;color:green"> <strong>Numéro de facture</strong> <span>{{facture.numFacture}}</span> </h5>
                </div>
                <div class="col-md-6 m-t-lg m-t-md m-t-xs m-t-sm">
                  <h5 *ngFor="let facture of commande.factures" style="text-align:center;color:green"> <strong>Date de facture</strong> <span>{{facture.dateFacture | date : 'dd/MM/yyyy'}}</span> </h5>
                </div>
                <div class="col-md-6 m-t-lg m-t-md m-t-xs m-t-sm">
                  <h5 style="center;color:green"> <strong>Numéro de facture</strong> <span>{{commande.numFacture}}</span> </h5>
                </div>
                <div class="col-md-6 m-t-lg m-t-md m-t-xs m-t-sm">
                  <h5 style="center;color:green"> <strong>Date de facture</strong> <span>{{commande.dateFacture | date : 'dd/MM/yyyy'}}</span> </h5>
                </div>
  
              </div>
            </div>
          </div>
        </div>
  
  
        <div *ngIf="showPart()==2" class="col-content-affiche tabcontent2" id="information_compl">
  
          <div>
            <div class="col-xl-5 col-lg-8 col-md-10 col-sm-10">
              <div class="row mt-3">
                <div class="col-12">
                  <div class="row">
                    <div class="col-1"><img src="assets/images/partielLivre.png" style="height: 25px"></div>
                    <div class="col-6">
                      <p style="font-size: 13px !important;">PARTIELLEMENT LIVRÉE</p>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-1"><img src="assets/images/soldee.png" style="height: 25px"></div>
                    <div class="col-6">
                      <p style="font-size: 13px !important;">SOLDÉE</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
  
              <div class="col-content-affiche tabcontent2" id="lignes">
                <div class="row ">
                  <table class="table table-ligne table-responsive">
                    <tr>
                      <th class="left">POSTE</th>
                      <th class="left">N° DOSSIER</th>
                      <th class="left">NOM DOSSIER</th>
                      <th class="left">CLIENT</th>
                      <th class="left">RÉFÉRENCE</th>
                      <th class="left">DÉSIGNATION</th>
                      <th class="right" *ngIf="( utilisateur &&  utilisateur.role != 'Equipe Atelier')">PRIX TARIF HT</th>
                      <th class="right" *ngIf="( utilisateur &&  utilisateur.role != 'Equipe Atelier')">PRIX NET HT</th>
                      <th class="right" *ngIf="( utilisateur &&  utilisateur.role != 'Equipe Atelier')">COEF D'ACHAT</th>
                      <th class="right">QUANTITÉ </th>
                      <th class="right">QUANTITÉ REÇUE</th>
                      <th class="right">REPLIQUAT</th>
                      <th class="left">STATUT</th>
                      <th class="left" *ngIf="( utilisateur &&  utilisateur.role != 'Equipe Atelier')">PRIX NET HT TOTAL </th>
                      <th *ngIf="( (utilisateur &&  utilisateur.role == 'Super admin') || (utilisateur &&  utilisateur.role == 'Equipe Atelier'))"></th>
                    </tr>
                    <tr *ngFor="let ligne of filterLIgnes| paginate: { itemsPerPage: numberData, currentPage: p }; let i = index ">
                      <td class="left">{{i + 1}}0</td>
                      <td class="left">{{ligne.numero}}</td>
                      <td class="left">{{ligne.nomDossier}}</td>
                      <td class="left">{{ligne.client}}</td>
                      <td class="left">{{ligne.refArt}}</td>
                      <td class="left">{{ligne.designation}}</td>
                      <td class="right" *ngIf="( utilisateur &&  utilisateur.role != 'Equipe Atelier')"> <span *ngIf="ligne.pritar && ligne.pritar != 'NaN'">{{ligne.pritar | number: '1.2-2'| replace:',':' ' | replace:'.':','}}</span></td>
                      <td class="right" *ngIf="( utilisateur &&  utilisateur.role != 'Equipe Atelier')"> <span *ngIf="ligne.prinet && ligne.prinet != 'NaN'">{{ligne.prinet | number: '1.2-2'| replace:',':' ' | replace:'.':','}}</span></td>
                      <td class="right" *ngIf="( utilisateur &&  utilisateur.role != 'Equipe Atelier')">{{ligne.coef | number: '1.4-4'| replace:',':' ' | replace:'.':','}}</td>
                      <td class="right">{{ligne.quantite | number: '1.2-2'| replace:',':' ' | replace:'.':',' | replace:',00':' '}}</td>
                      <td class="right">{{ligne.quantiteRecue | number: '1.2-2'| replace:',':' ' | replace:'.':',' | replace:',00':' '}}</td>
                      <td class="right"> <span *ngIf="ligne.repliquat != 'NaN' ">{{ligne.repliquat | number: '1.2-2'| replace:',':' ' | replace:'.':',' | replace:',00':' '}}</span></td>
                      <td >
                        <img *ngIf="ligne.repliquat != '0'" src="assets/images/partielLivre.png" style="height: 30px">
                        <img *ngIf="ligne.repliquat == '0'" style="height: 30px" src="assets/images/soldee.png">
                      </td>
                      <td class="left" *ngIf="( utilisateur &&  utilisateur.role != 'Equipe Atelier')"><span *ngIf="ligne.prinetTotal && ligne.prinetTotal != 'NaN'">{{ligne.prinetTotal | number: '1.2-2'| replace:',':' ' | replace:'.':','}}</span>
                      </td>
                      <td *ngIf="( (utilisateur &&  utilisateur.role == 'Super admin') || (utilisateur &&  utilisateur.role == 'Equipe Atelier'))">
                        <img class="ml-1" src="assets/images/scan.png" (click)="chooseLigne(ligne)" data-toggle="modal" data-target="#myModal">
                      </td>
                      <!--  modal update  réponse client -->
                      <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModal" aria-hidden="true" style="overflow-y: hidden;">
                        <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 1000px">
                          <div class="modal-content modal-content-scan" style="width: 67%;top: 0px;left: 18%;">
                            <div class="modal-header d-flex justify-content-center">
                              <h3 class="titre-modal-statut">
                                Insérer une quantité
                              </h3>
                            </div>
                            <div class="modal-body">
                              <div class="col d-flex justify-content-start">
                                <b class="obligatoir-text" style="color: #FF9100;">(*) Ce champ est obligatoire</b>
                              </div>
                              <form *ngIf="ligne">
                                <p style="color:red;margin: 10px 0 10px 15px;">{{errorMsg}}</p>
                                <div class="col-md-12" style="margin-top: 15px;">
                                  <label for="prenom" class="bold" style="color:green">Quantité*</label>
                                  <input type="number" placeholder="Quantité" class="form-control input-sm select-modal-input" name="quantiteInsertion" [(ngModel)]="quantiteInsertion" value="1">
                                </div>
                                <br>
                                <div class="col-md-12" style="margin-top: 3px;">
                                  <label for="prenom" class="bold" style="color:green">Date*</label>
                                  <input type="date" placeholder="Date" class="form-control input-sm select-modal-input" name="dateInsertion" [max]="getDateCommande()" [(ngModel)]="dateInsertion">
                                </div>
                                <br>
                                <div class="form-group col-md-12 d-flex justify-content-center">
                                  <button type="button" class="btn btn-primary btn-connexion" style="background-color: #FF9100;text-transform: uppercase;border-radius: 8px;text-decoration: none;border-color: #FF9100;" data-dismiss="modal">Annuler <img
                                      src="assets/images/btn-annule.png" alt=""> </button>
                                  <button type="button" (click)="updateQunatite(ligne)" class="btn btn-primary btn-connexion"
                                    style="background-color: #067302;text-transform: uppercase;padding: 9px;margin-right: 10px;border-radius: 8px;border-color: #067302;margin-left: 10px;">Insérer <img src="assets/images/save.png" alt=""> </button>
                                </div>
                                <div class="modal-footer">
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- modal update  réponse client -->
  
                    </tr>
  
                  </table>
                  <div class="row">
                    <div class="col col-xs-6">
                      <pagination-controls previousLabel="Précédent" nextLabel="Suivant" (pageChange)="p = $event" #api></pagination-controls>
                    </div>
                  </div>
  
                </div>
                <form [formGroup]="form" role="form">
                  <h1 id="firsthcommande">Ajouter des bons de livraison</h1>
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-10">
                      <div class="col-lg-12 col-md-12 col-sm-12" style="margin-bottom: -2px;margin-top: -10px;"
                        *ngIf="( (utilisateur &&  utilisateur.role == 'Super admin') || (utilisateur &&  utilisateur.role == 'Equipe Atelier'))">
                        <img src="assets/images/add-cmd.png" style="margin-left: 25px;cursor: pointer;" *ngIf="form.controls.listfilesBonLivraison.controls.length < 10" (click)="addBonLivraison()" title="Ajouter un bon de livraison">
                      </div>
                      <div class="col-lg-12 col-md-12 col-sm-12" formArrayName="listfilesBonLivraison"
                        *ngIf="( (utilisateur &&  utilisateur.role == 'Super admin') || (utilisateur &&  utilisateur.role == 'Equipe Atelier'))" style="margin-bottom: -5px;">
                        <div class="col-lg-12 col-md-12 col-sm-12" *ngFor="let facture of form.controls.listfilesBonLivraison.controls; let i=index">
                          <div class="row" [formGroupName]="i">
                            <div class="col-lg-6 col-md-6 col-sm-10">
                              <input type="file" class="font-weight-bold" formControlName="file" (change)="selectFiles($event)" id="file" name="file"  placeholder="Bon de livraison" accept="application/pdf">
                              <img src="assets/images/supp-modal.png" title="Supprimer un bon de livraison" (click)="removeBonLivraison(i)" *ngIf="form.controls.listfilesBonLivraison.controls.length > 1" class="delete-img" style="width:4%">
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-10">
                              <input type="date" class="font-weight-bold" placeholder="Date" [min]="getDateFacture()" formControlName="date" id="date" name="date">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-12 col-md-12 col-sm-12" style="margin-top:-2%">
                        <p style="color:red;text-align: center;" *ngIf="errorDateFacture">Vérifier les dates</p>
                      </div>
                    </div>
                  </div>
                </form>
  
  
                <div class="form-group col-md-12 d-flex justify-content-center" style="margin-top: 50px;" *ngIf="( (utilisateur &&  utilisateur.role == 'Super admin') || (utilisateur &&  utilisateur.role == 'Equipe Atelier'))">
                  <br><br>
                  <button type="button" class="btn btn-primary btn-connexion" style="background-color: #FF9100;text-transform: uppercase;border-radius: 8px;text-decoration: none;border-color: #FF9100;" [routerLink]="['/commande']">Annuler <img
                      src="assets/images/btn-annule.png" alt=""> </button>
                  <button type="button" (click)="enregistrer()" class="btn btn-primary btn-connexion"
                    style="background-color: #067302;text-transform: uppercase;padding: 9px;margin-right: 10px;border-radius: 8px;border-color: #067302;margin-left: 10px;">ENREGISTRER<img src="assets/images/save.png" alt=""> </button>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div *ngIf="showPart()==3" class="col-content-affiche tabcontent2" id="etablissement">
          <div>
            <div class="card-body">
              <div class="row ">
                <div class="col fichier-commande ">
                  <div class="row d-flex justify-content-center titre-fichier-commande"> <b> Commande(s)</b></div>
                  <div class="row d-flex justify-content-center" *ngFor=" let file of commande.listfiles"> <b>Fichier {{commande.listfiles.indexOf(file)+1}} : </b>
                    <p><a href="{{file.photo}}" style="cursor: pointer;text-decoration: underline;" target="_blank"><span *ngIf="!file.name">Lien 1</span> <span *ngIf="file.name">{{file.name}}</span></a> </p>
                  </div>
  
                </div>
  
                <div class="col fichier-commande ">
                  <div class="row d-flex justify-content-center titre-fichier-commande"> <b> Accusé de réception</b></div>
                  <div class="row d-flex justify-content-center" *ngFor=" let file of commande.listfilesAccuse"> <b>Fichier {{commande.listfilesAccuse.indexOf(file)+1}} : </b>
                    <p><a href="{{file.photo}}" style="cursor: pointer;text-decoration: underline;" target="_blank"><span *ngIf="!file.name">Lien 1</span> <span *ngIf="file.name">{{file.name}}</span></a> </p>
                  </div>
                </div>
  
              </div>
  
              <div class="row ">
                <div class="col fichier-commande ">
                  <div class="row d-flex justify-content-center titre-fichier-commande"> <b> Facture(s)</b></div>
                  <div class="row d-flex justify-content-center" *ngFor=" let file of commande.listfilesFactures"> <b>Fichier {{commande.listfilesFactures.indexOf(file)+1}} : </b>
                    <p><a href="{{file.photo}}" style="cursor: pointer;text-decoration: underline;" target="_blank"><span *ngIf="!file.name">Lien 1</span> <span *ngIf="file.name">{{file.name}}</span></a> </p>
                  </div>
                </div>
  
                <div class="col fichier-commande ">
                  <div class="row d-flex justify-content-center titre-fichier-commande"> <b> Bon(s) de livraison</b></div>
                  <div class="row d-flex justify-content-center" *ngFor=" let file of commande.listfilesBonLivraison"> <b>Fichier {{commande.listfilesBonLivraison.indexOf(file)+1}} : </b>
                    <p><a href="{{file.photo}}" style="cursor: pointer;text-decoration: underline;" target="_blank"><span *ngIf="!file.name">Lien 1</span> <span *ngIf="file.name">{{file.name}}</span></a> </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- --------------------------modal scan ----------------------- -->
  
    <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content modal-content-scan">
          <div class="modal-header d-flex justify-content-center">
            <img src="assets/images/scan-modal.png">
  
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col">
                <input type="text" placeholder=" SCANNER L'ARTICLE">
              </div>
              <div class="col">
                <input type="number" placeholder="SAISIR LA QUANTITÉ">
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button type="button" class="btn-connexion" > ENREGISTRER <img src="assets/images/save.png"> </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  