import { Component, OnInit } from '@angular/core';
import { AuthentificationService } from '../services/authentification.service';
import {Router} from '@angular/router';
@Component({
  selector: 'logout',
  template: '',
  providers:[AuthentificationService]
})
export class LogoutComponent implements OnInit {

  constructor(private _authService: AuthentificationService, private router: Router) {}

  ngOnInit() {
    this._authService.logout();
    this.router.navigate(['/login']);
  }
}
