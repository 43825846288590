<app-navbar></app-navbar>  
<br>
<div id="content-nav-page" class="row" *ngIf="utilisateur">
    <div class=" col-3 titre-menu">
      <b style="margin-right: 10%" class="title-haut">CLIENT</b>
      <img src="assets/images/client-plus.png" *ngIf="hasRoleAdd" routerLink="/ajoutClient">
    </div>
    <div class="col-9 sous-menu">
      <b class="sous-menu-active1" style="cursor: pointer"  [routerLink]="['/client']">CLIENT</b>
      <b class="sous-menu-active2 ml-4" *ngIf="hasRoleca" [routerLink]="['/chargeAffaire']" style="cursor: pointer">CHARGÉ D'AFFAIRES</b>
    </div>
  </div>
  <div id="content-page" class="containe-fluid " *ngIf="utilisateur">
    <div class="row  ">
      <div class="col-lg-4 col-md-10 col-sm-10  search">
        <input class="search-bar" type="text" placeholder="Rechercher.." [(ngModel)]="searched" name="searched">
      </div>
      <div class="col-lg-1 col-md-2 col-sm-2">
        <select class="select-input-nombre" name="numberData" [(ngModel)]="numberData">
          <option>5</option>
          <option selected>10</option>
          <option>25</option>
          <option>50</option>
        </select>
      </div>
      <div class=" col d-flex justify-content-end">
        <div class="col-auto nbr-dossier ">
          <table>
            <tr>
              <td>
                <b>Clients</b>
              </td>
            </tr>
            <tr>
              <td>
                <b class="sous-nbr-dossier d-flex justify-content-center" *ngIf="clients">{{clients.length}}</b>
              </td>
            </tr>
          </table>
        </div>
        <div class="col-auto btn-devis ">
          <a (click)="toFileXLS()" *ngIf="utilisateur && utilisateur.role == 'Super admin'" class="btn-connexion">Exporter<img class="ml-1" src="assets/images/excel.png"></a>
        </div>
      </div>
    </div>
    <!-- tableau -->
    <div class="row" style="overflow-x:auto;">
      <table class="table table-striped table-hover">
        <th class="left" id="firstThTable">LOGO</th>
        <th class="left">IDENTIFIANT UNIQUE</th>
        <th class="left">DATE DE CRÉATION</th>
        <th class="left">DÉNOMINATION</th>
        <th class="left">CODE ACTIVITÉ</th>
        <th class="left" *ngIf="!administration && utilisateur && utilisateur.role != 'Admin' && utilisateur.role != 'Responsable finance'">RADIÉE</th>
        <th class="text-center " style="margin-left: 100px; text-align: right" *ngIf="!administration && utilisateur && utilisateur.role != 'Admin' && utilisateur.role != 'Responsable finance'">RÉGLAGES</th>
        <tr class="text-center" *ngFor="let client of clients|searchClient  : searched.toString().toLowerCase()| paginate: { itemsPerPage: numberData, currentPage: p }">
          <td class="left">
            <div >
              <img src="{{client.linkLogo}}" style="height: 60px;max-width: 80px">
            </div>
          </td>
          <td class="left" [routerLink]="['/details/client', client.id]" *ngIf="(client.statut != 'Radiée' && utilisateur.role != 'Super admin') || (utilisateur.role === 'Super admin')">{{client.siren[0]}}{{client.siren[1]}}{{client.siren[2]}}{{client.siren[3]}}{{client.siren[4]}}{{client.siren[5]}}{{client.siren[6]}}{{client.siren[7]}}{{client.siren[8]}} {{client.siren[9]}}{{client.siren[10]}}{{client.siren[11]}}{{client.siren[12]}}{{client.siren[13]}}</td>
            <td class="left" [routerLink]="['/details/client', client.id]" *ngIf="(client.statut != 'Radiée' && utilisateur.role != 'Super admin') || (utilisateur.role === 'Super admin')"> <span *ngIf="client.datec.indexOf('à') > -1">{{client.datec}}</span> <span *ngIf="client.datec.indexOf('à') < 0">{{client.datec | date : 'dd/MM/yyyy'}}</span></td>
            <td class="left" [routerLink]="['/details/client', client.id]" *ngIf="(client.statut != 'Radiée' && utilisateur.role != 'Super admin') || (utilisateur.role === 'Super admin')">{{client.denomination}}</td>
          <td class="left" [routerLink]="['/details/client', client.id]" *ngIf="(client.statut != 'Radiée' && utilisateur.role != 'Super admin') || (utilisateur.role === 'Super admin')">{{client.indicatif}}</td>
          <td  [routerLink]="['/details/client', client.id]" *ngIf="!administration && utilisateur && utilisateur.role != 'Admin' && utilisateur.role != 'Responsable finance'">
            <i class="" style="color:red;font-size: 20px;" *ngIf="client.statut === 'Radiée'" title="Radiée">★</i>
          </td>
          <td *ngIf="!administration && utilisateur && utilisateur.role != 'Admin' && utilisateur.role != 'Responsable finance'">
            <div class="dropdown">
              <button class="btn dropdown-toggle btn-options" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                OPTIONS
              </button>
              <div class="dropdown-menu reglageMenu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item option" [routerLink]="['/details/client', client.id]">
                  <div class="options-show-icon"></div>
                  <div class="option-text"> Afficher</div>
                </a>
  
                <a class="dropdown-item option" title="Modifier" [routerLink]="['/client/details', client.id]" title="Modifier" *ngIf="hasRoleUpdate" [ngStyle]="{'pointer-events': administration ? 'none' : null}">
                  <div class="options-modif-icon"></div>
                  <div class="option-text"> Modifier</div>
                </a>
                <a class="dropdown-item" title="Supprimer" (click)="removeClient(client)" title="Supprimer" *ngIf="hasRoleDelete" [ngStyle]="{'pointer-events': administration ? 'none' : null}">
                  <div class="options-supp-icon"></div>
                  <div class="option-text"> Supprimer</div>
                </a>
              </div>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div class="row">
     <div class="col col-xs-6">
          <pagination-controls previousLabel="Précédent" nextLabel="Suivant" (pageChange)="p = $event" #api></pagination-controls>
     </div>
   </div>
  </div>
  