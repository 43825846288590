import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http } from '@angular/http';
import { pays } from '../../../pays/pays';
import { tri } from '../../../pays/pays';
import { ValidateService } from '../../../services/validate/validate.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ValidationService } from '../../../services/validate/validate-error.service';
import { languages } from '../../../pays/langues';
import { ClientService } from "../../../services/client.service";
import { ChargeAffaireService } from "../../../services/charge-affaire.service";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
import { codepostale } from 'src/app/pays/villes';

@Component({
  selector: 'app-update-charge-affaire',
  templateUrl: './update-charge-affaire.component.html',
  styleUrls: ['../../utilisateur/update-utilisateur/update-utilisateur.component.scss'],
})
export class UpdateChargeAffaireComponent implements OnInit {

  chargeAffaire: any;
  clients: any;
  pys: any[] = [];
  vlls: any[] = [];
  languages: any[] = [];
  formParcours: any;
  formClient: any;
  addConjointAndEnfants: any;
  formExperience: any;
  competence: any;
  langue = "";
  errorDates = false;
  errorDateNaissance = false;
  fournisseur: any;
  fournisseurs: any;
  errorInformations = false;
  errorInformationsComp = false;
  addEnfants = false;
  formEnfant: any;
  roleAdministration = true;
  role: any;
  showSuivantBouton = true;
  showErrorEtabClient = false;
  selectedClient: any;
  etablissements: any[] = []
  administration = false;
  etablissementsExp: any[] = []
  etablissementExp: any;
  actualClient: any;
  actualEtablissement: any;
  actuelExperience: any;
  myClient: any;
  dateNaissancee = new Date().toISOString().split('T')[0]
  showMyContainer = 0;
  oldCLient: any;
  oldEtablissement: any;
  savedMail: any;
  errorLangue = ""
  languesChosed: any[] = []
  competencesChosed: any[] = []
  errorCompetence = ""
  bigDate = "9999-12-31"
  disabled :any;
  secondp = 1;
  thirdp = 1;
  p = 1;
  constructor(private clientService: ClientService, private formBuilder: FormBuilder, private http: Http, private activatedRoute: ActivatedRoute, private validateService: ValidateService, private chargeAffaireService: ChargeAffaireService, private router: Router) {

    this.formParcours = this.formBuilder.group({
      etablissement: ['', [Validators.required, Validators.minLength(2)]],
      diplomen: ['', [Validators.required, Validators.minLength(2)]],
      diplomet: ['Ouvrier', Validators.required],
      date: ['', [ValidationService.dateValidator]],
      desc: ['',]
    })
    this.formExperience = this.formBuilder.group({
      entreprise: ['', Validators.required],
      etablissementExp: ['', Validators.required],
      poste: ['', [Validators.required, Validators.minLength(4), ValidationService.validateEmptyField]],
      dated: ['', [ValidationService.dateValidator]],
      datef: ['',],
      departement: [''],
      bureau: [''],
      desc: ['',]
    })
    this.formClient = this.formBuilder.group({
      client: ['', Validators.required],
      etablissement: ['', Validators.required],
      mail: ['', [Validators.required, ValidationService.emailValidator, ValidationService.nospaceValidator]],
    })
    this.formEnfant = this.formBuilder.group({
      nom: ['', [Validators.required, Validators.minLength(2)]],
      prenom: ['', [Validators.required, Validators.minLength(2)]],
      dateNaissance: ['', [Validators.required, ValidationService.dateValidator]],
      sexe: ['Masculin', [Validators.required]],
    })
  }
  ngOnInit() {
    this.pys = pays.sort(tri);
    this.pys = this.pys.filter(x => x.name === "Tunisie")
    this.vlls = codepostale
    const currentDate = new Date().toISOString().substring(0, 10);
    this.languages = languages
    let params: any = this.activatedRoute.snapshot.params;
    this.chargeAffaireService.getChargeAffaireById(params.id).subscribe(chargeAffaire => {
      this.chargeAffaire = chargeAffaire
      if (this.chargeAffaire) {
        this.actualClient = this.chargeAffaire.client
        this.savedMail = this.chargeAffaire.mail
        this.oldCLient = Object.assign({}, this.chargeAffaire.client, {})
        if (this.chargeAffaire.client && this.chargeAffaire.client.etablissement) {
          this.oldEtablissement = Object.assign({}, this.chargeAffaire.client.etablissement, {})
        }
        this.chargeAffaire.poste.datef = currentDate
        if (this.chargeAffaire.situationFamiliale === "Célibataire") {
          this.addConjointAndEnfants = false;
        }
        else {
          this.addConjointAndEnfants = true;
        }
      }
      if (this.chargeAffaire) {
        if (!this.chargeAffaire.client.etablissement) {
          this.chargeAffaire.client.etablissement = {
            "nom": this.chargeAffaire.client.denomination,
            "type": "Etablissement principale",
            "adresse": this.chargeAffaire.adresse,
            "tel": this.chargeAffaire.tel,
            "mail": this.chargeAffaire.mail,
          }
        }
        this.actualEtablissement = this.chargeAffaire.client.etablissement
        this.actuelExperience = {
          entreprise: this.chargeAffaire.client.denomination,
          poste: this.chargeAffaire.poste.titre,
          departement: this.chargeAffaire.poste.departement,
          bureau: this.chargeAffaire.poste.bureau,
          dated: this.chargeAffaire.poste.dated,
          datef: currentDate,
          etablissement: this.chargeAffaire.client.etablissement,
          idClient: this.chargeAffaire.client.id,
          mail: this.chargeAffaire.mail,
          tel: this.chargeAffaire.tel,
          desc: "En cours"
        }
        this.chargeAffaire.experiences.push(this.actuelExperience);
      }
    },
      err => {
        return false;
      });
    this.getAllClients();
    this.role = localStorage.getItem('roleUser')
    if (this.role) {
      this.roleAdministration = false;
      this.administration = true;
    }
  }
  // recuperer tous les clients
  getAllClients() {
    this.clientService.getAllUsers().then(data => {
      this.clients = data;
      this.clients = this.clients.sort(this.triClient);
    },
      err => {
        return false;
      });
  }
  getBigDate(): string {
      return this.bigDate
    }
  // select client
  selectClient() {
    this.myClient = this.formClient.value.client
    this.showErrorEtabClient = false;
    this.chargeAffaire.client.etablissement = undefined
    while (this.etablissements.length > 0) {
      this.etablissements.pop();
    }
    if (this.formClient.value.client.etablissements.length < 1) {
      var newEtabl = {
        "nom": this.formClient.value.client.denomination,
        "type": "Etablissement principale",
        "adresse": this.formClient.value.client.adresse,
        "tel": this.formClient.value.client.tel,
        "mail": this.formClient.value.client.mail,
        "statutEtab": this.formClient.value.client.statut,
        "dateRadiationEtab": this.formClient.value.client.dateRadiation
      }
      this.etablissements.push(newEtabl)
      return true;
    }
    if (this.formClient.value.client.etablissements.length > 0) {
      var newEtabl = {
        "nom": this.formClient.value.client.denomination,
        "type": "Etablissement principale",
        "adresse": this.formClient.value.client.adresse,
        "tel": this.formClient.value.client.tel,
        "mail": this.formClient.value.client.mail,
        "statutEtab": this.formClient.value.client.statut,
        "dateRadiationEtab": this.formClient.value.client.dateRadiation
      }
      for (var i = 0; i < this.formClient.value.client.etablissements.length; i++) {
        this.etablissements.push(this.formClient.value.client.etablissements[i])
      }
      this.etablissements.push(newEtabl)
      return true;
    }
    else {
      this.showSuivantBouton = true;
    }
  }
  selectClientExp() {
    this.etablissementExp = undefined
    while (this.etablissementsExp.length > 0) {
      this.etablissementsExp.pop();
    }
    if (this.formExperience.value.entreprise.etablissements.length < 1) {
      var newEtabl = {
        "nom": this.formExperience.value.entreprise.denomination,
        "type": "Etablissement principale",
        "adresse": this.formExperience.value.entreprise.adresse,
        "tel": this.formExperience.value.entreprise.tel,
        "mail": this.formExperience.value.entreprise.mail,
      }
      this.etablissementsExp.push(newEtabl)
      return true;
    }
    if (this.formExperience.value.entreprise.etablissements.length > 0) {
      var newEtabl = {
        "nom": this.formExperience.value.entreprise.denomination,
        "type": "Etablissement principale",
        "adresse": this.formExperience.value.entreprise.adresse,
        "tel": this.formExperience.value.entreprise.tel,
        "mail": this.formExperience.value.entreprise.mail,
      }
      for (var i = 0; i < this.formExperience.value.entreprise.etablissements.length; i++) {
        this.etablissementsExp.push(this.formExperience.value.entreprise.etablissements[i])
      }
      this.etablissementsExp.push(newEtabl)
      return true;
    }
    else {
      return false;
    }
  }
  selectEtablissementExp() {
    this.etablissementExp = this.formExperience.value.etablissementExp;

  }
  selectEtablissement() {
    this.chargeAffaire.adresse = this.formClient.value.etablissement.adresse;
  }
  showOthersSituationEnfant() {
    if (this.chargeAffaire.situationEnfant === "Non") {
      this.addEnfants = false;
    }
    else {
      this.addEnfants = true;
    }
  }

  // add enfants
  addnewEnfant() {
    this.chargeAffaire.enfants.push(this.formEnfant.value);
    this.formEnfant.reset();
  }
  removeEnfant(enfant) {
    if (window.confirm("Voulez vous vraiment supprimer cet enfant?")) {
      this.chargeAffaire.enfants.splice(this.chargeAffaire.enfants.indexOf(enfant), 1);
    }
  }
  showOthersSituation() {
    if (this.chargeAffaire.situationFamiliale === "Célibataire") {
      this.addConjointAndEnfants = false;
    }
    else {
      this.addConjointAndEnfants = true;
    }
  }
  // ajouter des parcours
  addnewParcours() {
    this.chargeAffaire.parcours.push(this.formParcours.value);
    this.formParcours.reset();
  }
  // supprimer parcours
  removeParcours(parcour) {
    Swal.fire({
      title: 'VOULEZ-VOUS SUPPRIMER CE PARCOURS?',
      imageUrl: 'assets/images/supp-modal.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      showCancelButton: true,
      width: 800,
      confirmButtonColor: '#059e00',
      cancelButtonColor: '#FF9100',
      cancelButtonText: 'ANNULER',
      confirmButtonText: 'VALIDER'
    }).then((result) => {
      if (!result.dismiss) {
        Swal.fire({
          imageUrl: 'assets/images/supp-modal.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 800,
          title: 'BIEN SUPPRIMÉ',
          timer: 1500,
          showConfirmButton: false
        }
        );
        this.chargeAffaire.parcours.splice(this.chargeAffaire.parcours.indexOf(parcour), 1);

      }
    });
  }

  // ajouter des parcours
  addnewExperience() {
    if (this.formExperience.value.dated >= this.formExperience.value.datef) {
      this.errorDates = true;
      return false;
    }
    if (this.formExperience.value.datef.toString().length > 10) {
      this.errorDates = true;
      return false;
    }
    this.formExperience.value.idClient = this.formExperience.value.entreprise.id
    this.formExperience.value.entreprise = this.formExperience.value.entreprise.denomination
    this.formExperience.value.etablissement = this.formExperience.value.etablissementExp
    this.formExperience.value.etablissementExp = undefined
    this.chargeAffaire.experiences.push(this.formExperience.value);
    this.formExperience.reset();
    this.errorDates = false;
    this.etablissementExp = undefined
  }
  // supprimer parcours
  removeExperience(experience) {
    Swal.fire({
      title: 'VOULEZ-VOUS SUPPRIMER CETTE EXPÉRIENCE?',
      imageUrl: 'assets/images/supp-modal.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      showCancelButton: true,
      width: 800,
      confirmButtonColor: '#059e00',
      cancelButtonColor: '#FF9100',
      cancelButtonText: 'ANNULER',
      confirmButtonText: 'VALIDER'
    }).then((result) => {
      if (!result.dismiss) {
        Swal.fire({
          imageUrl: 'assets/images/supp-modal.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 800,
          title: 'BIEN SUPPRIMÉ',
          timer: 1500,
          showConfirmButton: false
        }
        );
        this.chargeAffaire.experiences.splice(this.chargeAffaire.experiences.indexOf(experience), 1);

      }
    });
  }

  addLangues(langue) {
    this.errorLangue = ""
    if (this.chargeAffaire.languages.indexOf(langue) > -1 || this.languesChosed.indexOf(langue) > -1) {
      this.errorLangue = "Cette langue existe déjà dans la liste"
      return false;
    } else {
      this.languesChosed.push(langue);
    }
  }
  resetErrorLangue() {
    this.languesChosed = []
    while (this.languesChosed.length > 0) {
      this.languesChosed.pop();
    }
    this.errorLangue = ""
    this.langue = ""
  }
  saveChoosedLangues() {
    this.chargeAffaire.languages.push(...this.languesChosed);
  }
  removeLangue(langue) {
    Swal.fire({
      title: 'VOULEZ-VOUS SUPPRIMER CETTE LANGUE?',
      imageUrl: 'assets/images/supp-modal.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      showCancelButton: true,
      width: 800,
      confirmButtonColor: '#059e00',
      cancelButtonColor: '#FF9100',
      cancelButtonText: 'ANNULER',
      confirmButtonText: 'VALIDER'
    }).then((result) => {
      if (!result.dismiss) {
        Swal.fire({
          imageUrl: 'assets/images/supp-modal.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 800,
          title: 'BIEN SUPPRIMÉ',
          timer: 1500,
          showConfirmButton: false
        }
        );
        this.chargeAffaire.languages.splice(this.chargeAffaire.languages.indexOf(langue), 1);
      }
    });
  }
  addCompetences(competence) {
    this.errorCompetence = ""
    if (this.chargeAffaire.competences.indexOf(competence) > -1 || this.competencesChosed.indexOf(competence) > -1) {
      this.errorCompetence = "Cette compétence existe déjà dans la liste"
      return false;
    } else {
      this.competencesChosed.push(competence);

    }
  }
  resetErrorCompetence() {
    this.competencesChosed = []
    while (this.competencesChosed.length > 0) {
      this.competencesChosed.pop();
    }
    this.errorCompetence = ""
    this.competence = ""
  }
  saveChoosedCompetence() {
    this.chargeAffaire.competences.push(...this.competencesChosed);
  }
  removeCompetence(competence) {
    Swal.fire({
      title: 'VOULEZ-VOUS SUPPRIMER CETTE COMPÉTENCE?',
      imageUrl: 'assets/images/supp-modal.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      showCancelButton: true,
      width: 800,
      confirmButtonColor: '#059e00',
      cancelButtonColor: '#FF9100',
      cancelButtonText: 'ANNULER',
      confirmButtonText: 'VALIDER'
    }).then((result) => {
      if (!result.dismiss) {
        Swal.fire({
          imageUrl: 'assets/images/supp-modal.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 800,
          title: 'BIEN SUPPRIMÉ',
          timer: 1500,
          showConfirmButton: false
        }
        );
        this.chargeAffaire.competences.splice(this.chargeAffaire.competences.indexOf(competence), 1);

      }
    });
  }
  // update chargeAffaire
  onUpdatechargeAffaire(chargeAffaire) {
    Swal.fire({
      title: 'VOULEZ-VOUS CONFIRMER?',
      imageUrl: 'assets/images/client-plus.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      showCancelButton: true,
      width: 800,
      confirmButtonColor: '#008000',
      cancelButtonColor: '#FFA500',
      confirmButtonText: 'CONFIRMER',

      cancelButtonText: 'ANNULER',
    }).then((result) => {
      if (!result.dismiss) {
        var today = new Date();
        var newClient = {
          "denomination": this.formClient.value.client.denomination,
          "acronyme": this.formClient.value.client.acronyme,
          "id": this.formClient.value.client.id
        }
        if (chargeAffaire.nom == undefined || chargeAffaire.nom.length < 2 || chargeAffaire.prenom == undefined || chargeAffaire.prenom.length < 2 || chargeAffaire.tel == undefined) {
          this.errorInformations = true;
          this.errorInformationsComp = false;
          this.errorDateNaissance = false;
          return false;
        }
        if (chargeAffaire.mail == undefined || !this.validateService.validateEmail(chargeAffaire.mail)) {
          this.errorInformationsComp = true;
          this.errorInformations = false;
          this.errorDateNaissance = false;
          return false;
        }
        if (new Date(chargeAffaire.dateNaissance) > today) {
          this.errorInformationsComp = false;
          this.errorInformations = false;
          this.errorDateNaissance = true;
          return false;
        }
        else {
          if (!chargeAffaire.historiquesCLient) {
            chargeAffaire.historiquesCLient = []
          }
          var founder = chargeAffaire.historiquesCLient.find(x => x.denomination == this.formClient.value.client.denomination && x.etablissement == this.formClient.value.etablissement.nom)
          if (!founder) {
            if (this.oldCLient && this.oldEtablissement) {
              var newObj = {
                "mail": this.savedMail,
                "denomination": this.oldCLient.denomination,
                "etablissement": this.oldEtablissement.nom
              }
            }

            chargeAffaire.historiquesCLient.push(newObj)
          }
          chargeAffaire.client = newClient
          chargeAffaire.client.etablissement = this.formClient.value.etablissement
          chargeAffaire.experiences.splice(chargeAffaire.experiences.indexOf(this.actuelExperience), 1)
          if (JSON.stringify(chargeAffaire.client) != JSON.stringify(this.actualClient) || JSON.stringify(chargeAffaire.client.etablissement) != JSON.stringify(this.actualEtablissement)) {
            chargeAffaire.experiences.splice(chargeAffaire.experiences.indexOf(this.actuelExperience), 1)
            this.actuelExperience.desc = ""
            chargeAffaire.experiences.push(this.actuelExperience);
          }
          this.chargeAffaireService.editChargeAffaireById(chargeAffaire)
            .subscribe(resp => {
            }, err => {

            })
          this.errorInformations = false;
          this.errorInformationsComp = false;
          this.errorDateNaissance = false;
          Swal.fire({
            imageUrl: 'assets/images/client-plus.png',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            width: 800,
            title: 'BIEN MODIFIÉ',
            timer: 1500,
            showConfirmButton: false
          }
          );
          this.router.navigateByUrl('/chargeAffaire');
        }
      }
    });

  }
  // trier le client
  triClient(a, b) {
    if (a.denomination.toLowerCase() < b.denomination.toLowerCase()) return -1;
    else if (a.denomination.toLowerCase() == b.denomination.toLowerCase()) return 0;
    else return 1;
  }
  getdateFinPoste() {
    return this.chargeAffaire.poste.datef
  }
  getDateNaissance(): string {
    return this.dateNaissancee
  }
  modifPart1() {
    if (this.showMyContainer !== 1) {
      this.showMyContainer = 1;
    }
    else {
      this.showMyContainer = 0;
    }
  }
  modifPart2() {
    if (this.showMyContainer !== 2) {
      this.showMyContainer = 2;
    }
    else {
      this.showMyContainer = 0;
    }
  }
  modifPart3() {
    if (this.showMyContainer !== 3) {
      this.showMyContainer = 3;
    }
    else {
      this.showMyContainer = 0;
    }
  }
  modifPart4() {
    if (this.showMyContainer !== 4) {
      this.showMyContainer = 4;
    }
    else {
      this.showMyContainer = 0;
    }
  }
  modifPart5() {
    if (this.showMyContainer !== 5) {
      this.showMyContainer = 5;
    }
    else {
      this.showMyContainer = 0;
    }
  }
  modifPart6() {
    if (this.showMyContainer !== 6) {
      this.showMyContainer = 6;
    }
    else {
      this.showMyContainer = 0;
    }
  }
  modifPart7() {
    if (this.showMyContainer !== 7) {
      this.showMyContainer = 7;
    }
    else {
      this.showMyContainer = 0;
    }
  }

  showPart() {
    return this.showMyContainer;
  }
}
