<app-navbar></app-navbar>
<br>
<div id="content-nav-page" class="row">
    <div class=" col-3 titre-menu" *ngIf="params">
      <a routerLink="/inventaire/fournisseurs/years/{{params.date}}" class=" icon-f-left"></a>
      <b style="margin-right: 10%" >Sans fournisseur</b>
      <!-- <img src="assets/images/add-catalogue.png" routerLink="/catalogue/ajout"  >-->
      <div class="dropdown" *ngIf="hasRoleAdd">
        <button class="btn icon-catalogue" type="button" id="dropdownMenuButton" data-toggle="dropdown">
        </button>
        <div class="dropdown-menu show-catalogue" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item drop-dup-option" routerLink="/ajoutInventaire/manuel">Manuel</a>
          <a class="dropdown-item drop-dup-option-b" routerLink="/ajoutInventaire">Fichier XLS</a>
        </div>
      </div>
    </div>
    <div class="col-9 sous-menu">
      <b class="sous-menu-active1" routerLink="/inventaire">INVENTAIRE</b>
      <b class="sous-menu-active2 ml-4" routerLink="/stock" *ngIf="hasRolestock" style="cursor: pointer">STOCK</b>
    </div>
  </div>
  <div id="content-page" class="containe-fluid">
  
    <div class="row  ">
      <div class="col-lg-4 col-md-10 col-sm-10  search">
        <input class="search-bar" type="search" placeholder="Rechercher.." [(ngModel)]="filterinventaire" name="filterinventaire">
      </div>
      <div class="col-lg-1 col-md-2 col-sm-2">
        <select class="select-input-nombre" name="numberData" [(ngModel)]="numberData">
          <option>5</option>
          <option selected>10</option>
          <option>25</option>
          <option>50</option>
        </select>
      </div>
  
      <div class=" col d-flex justify-content-end">
        <div class="col-auto nbr-dossier ">
          <table>
            <tr>
              <td>
                <b>Inventaires</b>
              </td>
            </tr>
            <tr>
              <td>
                <b class="sous-nbr-dossier d-flex justify-content-center" *ngIf="listeInventaire">{{listeInventaire.length}}</b>
              </td>
            </tr>
          </table>
        </div>
  
        <div class="col-auto btn-devis ">
          <a (click)="exportDataToExcel()" *ngIf="utilisateur && utilisateur.role == 'Super admin'" class="btn-connexion">Exporter<img class="ml-1" src="assets/images/excel.png"></a>
        </div>
      </div>
    </div>
    <!-- tableau -->
    <div class="row" style="overflow-x:auto;">
      <table class="table table-striped table-hover">
        <th class="left" id="firstThTable">DATE DE L'INVENTAIRE</th>
        <th class="left">FOURNISSEUR</th>
        <th class="left">RÉFÉRENCES</th>
        <th class="left">DÉSIGNATION </th>
        <th class="right" >PRIX TARIF </th>
        <th class="right" >COEFFICIENT D'ACHAT </th>
        <th class="right" >PRIX NET </th>
        <th class="right">QUANTITÉ EMBALLÉE</th>
        <th class="right">QUANTITÉ NON EMBALLÉE </th>
        <th class="right">QUANTITÉ </th>
        <th class="left">ÉTAGE </th>
        <th class="right" >MONTANT </th>
        <th class="text-center">ETAT</th>
        <th class="left" >NUMÉRO DE COMMANDE </th>
        <th class="left" >DATE DE COMMANDE </th>
        <th class="left" >NUMÉRO DE FACTURE </th>
        <th class="left" >DATE DE FACTURE </th>
        <th class="left" >OBSERVATION </th>
        <th class="text-center " >RÉGLAGES</th>
  
        <tr class="text-center" *ngFor="let inventaire of listeInventaire | paginate: { itemsPerPage: numberData, currentPage: p } ">
          <td class="left" [routerLink]="['/details/inventaire', inventaire.id]">{{inventaire.datec | date : 'dd/MM/yyyy' }} </td>
          <td class="left" [routerLink]="['/details/inventaire', inventaire.id]">{{inventaire.denomination}} </td>
          <td class="left" [routerLink]="['/details/inventaire', inventaire.id]">{{inventaire.refArt}}</td>
          <td class="left" [routerLink]="['/details/inventaire', inventaire.id]">{{inventaire.designation}}</td>
          <td class="right" [routerLink]="['/details/inventaire', inventaire.id]" >{{inventaire.pritar | number: '1.2-2'| replace:',':' ' | replace:'.':','}}</td>
          <td class="right" [routerLink]="['/details/inventaire', inventaire.id]" >{{inventaire.coefAchat | number: '1.4-4'| replace:',':' ' | replace:'.':','}}</td>
          <td class="right" [routerLink]="['/details/inventaire', inventaire.id]" >{{inventaire.prinet | number: '1.2-2'| replace:',':' ' | replace:'.':','}}</td>
          <td class="right" [routerLink]="['/details/inventaire', inventaire.id]">{{inventaire.quantiteEmballe | number: '1.2-2'| replace:',':' ' | replace:'.':',' | replace:',00':' '}}</td>
          <td class="right" [routerLink]="['/details/inventaire', inventaire.id]">{{inventaire.quantiteNonEmballe | number: '1.2-2'| replace:',':' ' | replace:'.':',' | replace:',00':' '}}</td>
          <td class="right" [routerLink]="['/details/inventaire', inventaire.id]">{{inventaire.quantite | number: '1.2-2'| replace:',':' ' | replace:'.':',' | replace:',00':' '}}</td>
          <td class="left" [routerLink]="['/details/inventaire', inventaire.id]">{{inventaire.etage}}</td>
          <td class="right" [routerLink]="['/details/inventaire', inventaire.id]" >{{inventaire.quantite * inventaire.prinet | number: '1.2-2'| replace:',':' ' | replace:'.':','}} </td>
          <td [routerLink]="['/details/inventaire', inventaire.id]">
            <img *ngIf="!inventaire.exist" src="assets/images/Rond_gris.png" style="height: 20px">
            <img *ngIf="inventaire.exist" src="assets/images/green.png" style="height: 20px">
          </td>
          <td class="left" [routerLink]="['/details/inventaire', inventaire.id]" >{{inventaire.numeroCommande}}</td>
          <td class="left" [routerLink]="['/details/inventaire', inventaire.id]" >{{inventaire.dateCommande | date : 'dd/MM/yyyy'}}</td>
          <td class="left" [routerLink]="['/details/inventaire', inventaire.id]" >{{inventaire.numeroFacture}}</td>
          <td class="left" [routerLink]="['/details/inventaire', inventaire.id]" >{{inventaire.dateFacture | date : 'dd/MM/yyyy'}}</td>
          <td class="left" [routerLink]="['/details/inventaire', inventaire.id]">{{inventaire.observation}}</td>
          <td >
            <div class="dropdown">
              <button class="btn dropdown-toggle btn-options" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                OPTIONS
              </button>
              <div class="dropdown-menu reglageMenu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item option" [routerLink]="['/details/inventaire', inventaire.id]">
                  <div class="options-show-icon"></div>
                  <div class="option-text"> Afficher</div>
                </a>
                <a class="dropdown-item option" *ngIf="hasRoleUpdate" title="Modifier" [routerLink]="['/update',inventaire.id]">
                  <div class="options-modif-icon"></div>
                  <div class="option-text"> Modifier</div>
                </a>
                <a class="dropdown-item" title="Supprimer" *ngIf="hasRoleDelete" (click)="removebible(inventaire)">
                  <div class="options-supp-icon"></div>
                  <div class="option-text"> Supprimer</div>
                </a>
              </div>
            </div>
  
          </td>
        </tr>
      </table>
    </div>
    <div style="padding-top: 50px;color: green">
      <pagination-controls previousLabel="Précédent" nextLabel="Suivant" (pageChange)="p = $event" #api></pagination-controls>
    </div>
  </div>
  