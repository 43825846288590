import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FournisseurService } from "../../../services/fournisseur.service";
import { CatalogueService } from "../../../services/catalogue.service";
import { UserService } from "../../../services/user.service";
import { CommandeService } from "../../../services/commande.service";

@Component({
  selector: 'app-details-fournisseur-commandes-by-year-and-month',
  templateUrl: './details-fournisseur-commandes-by-year-and-month.component.html',
  styleUrls: ['../../utilisateur/details-utilisateur/details-utilisateur.component.css'],
})
export class DetailsFournisseurCommandesByYearAndMonthComponent implements OnInit {
  fournisseur: any;
  roleAdministration = true;
  role: any;
  form:any;
  dateDebut = new Date().toISOString().split('T')[0]
  listeArtciels: any[]=[]
  listeAllArtciels:any;
  loading = false;
  sizeLimitOne = 30000000; //30MB
  tarifGlobal = 0;
  addedLength = 0;
  errorObjet: any;
  errorDate = false
  fileToChoose: any;
  id:any;
  utilisateur:any;
  years: any[] = [];
  lengthdata = 0
  showAddCatalogue = false;
  commandes:any;
  params:any;
  p=1
  constructor(private commandeService:CommandeService, private fournisseurService: FournisseurService, private activatedRoute: ActivatedRoute,private router:Router,private catalogueService:CatalogueService,private userService:UserService) {

  }
  ngOnInit() {
    this.role = localStorage.getItem('roleUser')
    if (this.role) {
      this.roleAdministration = false;
    }
    this.id = localStorage.getItem('idUser')
    this.userService.getUserByID(this.id).subscribe(utilisateur => {
      this.utilisateur = utilisateur;
      if(!this.utilisateur){
        localStorage.removeItem('idUser');
        localStorage.clear();
        this.router.navigate(['/login']);
      }
    },
      err => {
        return false;
      });
    this.params = this.activatedRoute.snapshot.params;
    this.fournisseurService.getFournisseurByID(this.params.id).subscribe(fournisseur => {
      this.fournisseur = fournisseur;
      if(this.fournisseur){
        this.getAllCommandesByFounrisseurAndYear(this.fournisseur.id, this.params.year, this.params.month)
      }
    },
      err => {
        return false;
      });
}
getAllCommandesByFounrisseurAndYear(idFournisseur, year,month){
  this.loading = true;
  this.commandeService.getAllCommandeByMonthAndYearFounisseur(idFournisseur,year,month).then(data => {
    if(data) {
    this.commandes = data
    this.commandes.sort(this.triCommandeByYearDesc)
    this.loading = false;
  }
  this.loading = false;
  },
    err => {
      return false;
    });
}
triCommandeByYearDesc(a, b) {
  if (a.dateCommande > b.dateCommande) return -1;
  else if (a.dateCommande == b.dateCommande) return 0;
  else return 1;
}

}

