<app-navbar></app-navbar>
<br>
<div id="content-nav-page" class="row" *ngIf="article">
    <div class=" col-3 titre-menu">
      <a routerLink="/catalogue/2021/{{article.idFournisseur}}/{{article.datec}}/{{article.dateFin}}" class=" icon-f-left"></a>
      <b>{{article.denomination}} <img class="ml-2" src="assets/images/show-a.png"></b>
    </div>
    <div class="col-9 sous-menu" *ngIf="utilisateur">
      <b class="sous-menu-active1" routerLink="/catalogue">CATALOGUE</b>
    </div>
  </div>
  <div class="row" *ngIf="article">
    <div class="col-xl-2 col-lg-3  col-md-3 col-sm-4 left-content-scrolbar ">
      <div class="col col-content ">
        <button class="tablink2 btn-liste"  id="defaultOpen2" (click)="modifPart1()">
          INFORMATIONS</button>
      </div>
  
      <div class="col col-content">
        <button class="tablink2 btn-liste "  (click)="modifPart2()"> INFORMATIONS COMPLÉMENTAIRES </button>
      </div>
    </div>
  
    <div class="col-xl-10 col-lg-9  col-md-9 col-sm-8 right-content-scrolbar">
      <div class="card p-3">
        <div *ngIf="showPart()==1" class="col-content-affiche tabcontent2" id="information">
  
          <div >
            <div class="card-body">
  
              <div class="row">
                <div class="col-4">
                  <div class="title">
                    <h4>Fournisseur</h4>
                    <span class="font-weight-bold">{{article.fournisseur}}</span>
                  </div>
  
                  <br/>
                  <div class="title ">
                    <h4>Dénomination</h4>
                    <span class="font-weight-bold">{{article.denomination}} </span>
                  </div>
                  <br/>
  
                  <div class="title">
                    <h4>Marque </h4>
                    <span class="font-weight-bold">{{article.mqe}}</span></div>
                  <br/>
                  <div class="title">
                    <h4>RefCial</h4>
                    <span class="font-weight-bold">{{article.refCiel}}</span>
  
                  </div>
                  <br/>
  
                  <div class="title">
                    <h4>Désignation</h4>
                    <span class="font-weight-bold">{{article.designation25}}</span>
  
                  </div>
  
                </div>
  
                <div class="col-4">
                  <div class="title">
                    <h4>Gamme</h4>
                    <span class="font-weight-bold">{{article.gamme}}</span>
  
                  </div>
                  <br/>
                  <div class="title">
                    <h4>Date de début</h4>
                    <span class="font-weight-bold">{{article.datec | date : 'dd/MM/yyyy'}}</span>
  
                  </div>
                  <br/>
                  <div class="title">
                    <h4>Date de fin</h4>
                    <span class="font-weight-bold">{{article.dateFin | date : 'dd/MM/yyyy'}} </span>
                  </div>
                  <br/>
  
                  <div class="title">
                    <h4>Tarif</h4>
                    <span class="font-weight-bold">{{article.tarif | number: '1.2-2'| replace:',':' ' | replace:'.':','}}</span></div>
                  <br/>
  
                  <div class="title">
                    <h4>Quantité Catalogue </h4>
                    <span class="font-weight-bold">{{article.qt}}</span></div>
                  <br/>
  
                  <div class="title">
                    <h4>Hautp </h4>
                    <span class="font-weight-bold">{{article.hautp}}</span></div>
  
  
                </div>
                <div class="col-4">
  
                  <div class="title">
                    <h4>Largp</h4>
                    <span class="font-weight-bold">{{article.largp}}</span>
  
                  </div>
                  <br/>
                  <div class="title">
                    <h4>Profp</h4>
                    <span class="font-weight-bold"> {{article.profp}}</span>
                  </div>
                  <br/>
  
                  <div class="title">
                    <h4>Poidsp</h4>
                    <span class="font-weight-bold">{{article.poidsp}}</span></div>
                  <br/>
  
                  <div class="title">
                    <h4>Diam </h4>
                    <span class="font-weight-bold">{{article.diam}}</span></div>
                  <br/>
  
                  <div class="title">
                    <h4>Statut </h4>
                    <span class="font-weight-bold">{{article.statut}}</span></div>
  
  
                </div>
              </div>
            </div>
          </div>
        </div>
  
  
        <div *ngIf="showPart()==2" class="col-content-affiche tabcontent2" id="information_compl">
  
          <div >
            <div class="card-body">
  
              <div class="row">
                <div class="col-4">
                  <div class="title">
                    <h4>Typ</h4>
                    <span class="font-weight-bold">{{article.typ}}</span>
                  </div>
  
                  <br/>
                  <div class="title ">
                    <h4>Dèlai de liv</h4>
                    <span class="font-weight-bold"> {{article.delaiLiv}}</span>
                  </div>
                  <br/>
  
                  <div class="title">
                    <h4>Date lancement </h4>
                    <span class="font-weight-bold">{{article.dateLancement | date : 'dd/MM/yyyy'}}</span></div>
                  <br/>
                  <div class="title">
                    <h4>Date arrêt</h4>
                    <span class="font-weight-bold">{{article.dateArret | date : 'dd/MM/yyyy'}}</span>
  
                  </div>
                  <br/>
  
                  <div class="title">
                    <h4>Ean13</h4>
                    <span class="font-weight-bold">{{article.ean13}}</span>
  
                  </div>
  
                </div>
  
                <div class="col-4">
                  <div class="title">
                    <h4>Garantie</h4>
                    <span class="font-weight-bold">{{article.garantie}}</span>
  
                  </div>
                  <br/>
                  <div class="title">
                    <h4>Edi</h4>
                    <span class="font-weight-bold">{{article.edi}}</span>
  
                  </div>
                  <br/>
                  <div class="title">
                    <h4>Mkt1</h4>
                    <span class="font-weight-bold">{{article.mkt1}}</span>
                  </div>
                  <br/>
                  <div class="title">
                    <h4>Mktl1</h4>
                    <span class="font-weight-bold">{{article.mktl1}}</span>
                  </div>
                  <br/>
                  <div class="title">
                    <h4>Mkt2</h4>
                    <span class="font-weight-bold">{{article.mkt2}}</span></div>
                  <br/>
  
                  <div class="title">
                    <h4>Mktl2 </h4>
                    <span class="font-weight-bold">{{article.mktl2}}</span></div>
                  <br/>
  
                  <div class="title">
                    <h4>Mkt3 </h4>
                    <span class="font-weight-bold">{{article.mkt3}}</span></div>
  
  
                </div>
                <div class="col-4">
  
                  <div class="title">
                    <h4>Mktl3</h4>
                    <span class="font-weight-bold">{{article.mktl3}}</span>
  
                  </div>
                  <br/>
                  <div class="title">
                    <h4>Mkt4</h4>
                    <span class="font-weight-bold">{{article.mkt4}} </span>
                  </div>
                  <br/>
  
                  <div class="title">
                    <h4>Mktl4</h4>
                    <span class="font-weight-bold">{{article.mktl4}}</span></div>
                  <br/>
  
                  <div class="title">
                    <h4>Mkt5 </h4>
                    <span class="font-weight-bold">{{article.mkt5}}</span></div>
                  <br/>
  
                  <div class="title">
                    <h4>Mktl5 </h4>
                    <span class="font-weight-bold">{{article.mktl5}}</span></div>
                    <br>
                    <div class="title">
                      <h4>Designation80 </h4>
                      <span class="font-weight-bold">{{article.designation80}}</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  