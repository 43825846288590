import { Component, OnInit,ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { UserService } from "../../../services/user.service";
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { ValidationService } from '../../../services/validate/validate-error.service';
import { pays } from '../../../pays/pays';
import { tri } from '../../../pays/pays';
import { languages } from '../../../pays/langues';
import { Md5 } from 'ts-md5/dist/md5';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
import {ValidateService} from '../../../services/validate/validate.service';
import { RoleService } from 'src/app/services/role.service';
@Component({
  selector: 'app-ajout-utilisateur',
  templateUrl: './ajout-utilisateur.component.html',
  styleUrls: ['./ajout-utilisateur.component.scss']
})

export class AjoutUtilisateurComponent implements OnInit {
  utilisateur: any;
  form: any;
  email: any;
  pays: any[] = [];
  users: any[] = [];
  enfants: any[] = [];
  successRegister = false;
  errorEmail = false;
  formConjoint: any;
  addConjointAndEnfants = false;
  addEnfants = false;
  situationFamiliale = 'Célibataire';
  situationEnfant = 'Non';
  formEnfant: any;
  formExperience: any;
  formParcours: any;
  parcours: any[] = [];
  experiences: any[] = [];
  langues: any[] = [];
  languages: any[] = [];
  competences: any[] = [];
  competence: any;
  langue = "";
  nom: any;
  prenom: any;
  dateNaissance: any;
  sexe = 'Masculin';
  errorDates = false;
  errorPassword = false;
  formBancaire: any;
  formConnexion: any;
  dateNaissancee = new Date().toISOString().split('T')[0]
  bigDate = "9999-12-31"
  today = new Date().toISOString().split('T')[0]
  showMyContainer = 1;
  myRole:any;
  id: any;
  errorLangue = ""
  errorCompetence = ""
  languesChosed: any[] = []
  savedRoles: any[] = []
  competencesChosed: any[] = []
  errorPartie1 = ""
  errorPartie2 = ""
  errorPartie3 = ""
  errorPartie4 = ""
  errorPartie5 = ""
  selectedParcours:any;
  selectedExperience:any;
  indexToUodate = 0
  @ViewChild('divClick') divClick: ElementRef;
  @ViewChild('divClickAddPArcours') divClickAddPArcours: ElementRef;
  @ViewChild('divClickAddExp') divClickAddExp: ElementRef;
  @ViewChild('divClickUpdateExp') divClickUpdateExp: ElementRef;
  secondp = 1;
  thirdp = 1;
  p = 1;
  
  constructor(private roleService:RoleService,private activatedRoute: ActivatedRoute, private http: Http, private userService: UserService, private formBuilder: FormBuilder,private validateService:ValidateService,
    private router: Router) {
    this.form = this.formBuilder.group({
      nom: ['', [Validators.required, Validators.minLength(2), ValidationService.validateEmptyField, Validators.maxLength(25)]],
      prenom: ['', [Validators.required, Validators.minLength(2), ValidationService.validateEmptyField, Validators.maxLength(25)]],
      dateNaissance: ['', ValidationService.dateValidator],
      dateRec: [''],
      tel: ['', Validators.required],
      telMobile: ['', Validators.required],
      sexe: ['Masculin', [Validators.required]],
      nationalite: ['Tunisie',],
      typeIdentite: ['CIN', [Validators.required]],
      numIdentite: ['', [Validators.required]],
      adresse: this.formBuilder.group({
        pays: ['Tunisie', [Validators.required]],
        codePostal: ['', [ValidationService.numberValidator, Validators.min(0), Validators.max(9999999)]],
        rue: ['',],
        n: ['', [ValidationService.numberValidator, Validators.min(0), Validators.max(9999999)]],
        ville: ['',],
      }),
      poste: this.formBuilder.group({
        titre: [''],
        departement: [''],
        bureau: [''],
      }),
    })
    this.form.value.tel = this.form.value.tel.replace(/\s/g, '');
    this.formConjoint = this.formBuilder.group({
      nom: ['', [Validators.minLength(2)]],
      prenom: ['', [Validators.minLength(2)]],
      tel: ['+216',],
      mail: ['', [ValidationService.emailValidator, ValidationService.nospaceValidator]],
      OrganismeDeSante: ['',],
      nationalite: ['Tunisie']
    })
    this.formParcours = this.formBuilder.group({
      etablissement: ['', [Validators.required, Validators.minLength(4), ValidationService.validateEmptyField]],
      diplomen: ['', [Validators.required, Validators.minLength(4), ValidationService.validateEmptyField]],
      diplomet: ['Ouvrier', Validators.required],
      date: ['', [ValidationService.dateValidator]],
      desc: ['',]
    })
    this.formExperience = this.formBuilder.group({
      entreprise: ['', [Validators.required, Validators.minLength(4), ValidationService.validateEmptyField]],
      poste: ['', [Validators.required, Validators.minLength(4), ValidationService.validateEmptyField]],
      dated: ['', [ValidationService.dateValidator]],
      datef: ['',],
      desc: ['',]
    })
    this.formBancaire = this.formBuilder.group({
      nom: ['',],
      iban: ['',],
      swift: ['',],
      rib: ['',],
      bic: ['',],
    });
    this.formConnexion = this.formBuilder.group({
      mail: ['', [Validators.required, ValidationService.emailValidator, ValidationService.nospaceValidator]],
      password: ['', [Validators.required, Validators.minLength(6), ValidationService.validateEmptyField]],
      passwordConfirmation: ['', [Validators.required, Validators.minLength(6), ValidationService.validateEmptyField]],
      role: ['Super admin', [Validators.required]],
      sousRole: ['']
    })
    this.formEnfant = this.formBuilder.group({
      nom: ['', [Validators.required, Validators.minLength(2), ValidationService.validateEmptyField]],
      prenom: ['', [Validators.required, Validators.minLength(2), ValidationService.validateEmptyField]],
      dateNaissance: ['', [Validators.required, ValidationService.dateValidator]],
      sexe: ['Masculin', [Validators.required]],
    })
  }
  ngOnInit() {
    this.pays = pays.sort(tri);
    this.languages = languages
    this.id = localStorage.getItem('idUser')
    if (this.id) {
      this.userService.getUserByID(this.id).subscribe(utilisateur => {
        this.utilisateur = utilisateur;
        if (!this.utilisateur) {
          localStorage.removeItem('idUser');
          localStorage.clear();
          this.router.navigate(['/login']);
        }
      },
        err => {
          return false;
        });
    } else {
      return false;
    }

    this.roleService.getAllRole().subscribe(data => {
      this.savedRoles = data
    })
  }
  resetall(){
    this.formExperience.reset()
    this.formParcours.reset()
    this.formParcours.get("diplomet").setValue("Ouvrier");
  }
  showOthersSituation() {
    if (this.situationFamiliale === "Célibataire") {
      this.addConjointAndEnfants = false;
    }
    else {
      this.addConjointAndEnfants = true;
    }
  }
  showOthersSituationEnfant() {
    if (this.situationEnfant === "Non") {
      this.addEnfants = false;
    }
    else {
      this.addEnfants = true;
    }
  }
  // ajouter des parcours
  addnewParcours() {
    this.parcours.push(this.formParcours.value);
    this.formParcours.reset();
    this.divClickAddPArcours.nativeElement.click();
  }
  // supprimer parcours
  removeParcours(parcour) {
    Swal.fire({
      title: 'VOULEZ-VOUS SUPPRIMER CE PARCOURS?',
      imageUrl: 'assets/images/supp-modal.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      showCancelButton: true,
      width: 800,
      confirmButtonColor: '#059e00',
      cancelButtonColor: '#FF9100',
      cancelButtonText: 'ANNULER',
      confirmButtonText: 'VALIDER'
    }).then((result) => {
      if (!result.dismiss) {
        Swal.fire({
          imageUrl: 'assets/images/supp-modal.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 800,
          title: 'BIEN SUPPRIMÉ',
          timer: 1500,
          showConfirmButton: false
        }
        );
        this.parcours.splice(this.parcours.indexOf(parcour), 1);

      }
    });

  }
  // add enfants
  addnewEnfant() {
    this.enfants.push(this.formEnfant.value);
    this.formEnfant.reset();
  }
  removeEnfant(enfant) {
    if (window.confirm("Voulez vous vraiment supprimer cet enfant?")) {
      this.enfants.splice(this.enfants.indexOf(enfant), 1);
    }
  }
  // remove enfant
  // ajouter des parcours
  addnewExperience() {
    if (this.formExperience.value.dated >= this.formExperience.value.datef) {
      this.errorDates = true;
      return false;
    }
    if (this.formExperience.value.datef && this.formExperience.value.datef.toString().length > 10) {
      this.errorDates = true;
      return false;
    }
    this.experiences.push(this.formExperience.value);
    this.formExperience.reset();
    this.errorDates = false;
    this.divClickAddExp.nativeElement.click();
  }
  // supprimer parcours
  removeExperience(experience) {
    Swal.fire({
      title: 'VOULEZ-VOUS SUPPRIMER CETTE EXPÉRIENCE?',
      imageUrl: 'assets/images/supp-modal.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      showCancelButton: true,
      width: 800,
      confirmButtonColor: '#059e00',
      cancelButtonColor: '#FF9100',
      cancelButtonText: 'ANNULER',
      confirmButtonText: 'VALIDER'
    }).then((result) => {
      if (!result.dismiss) {
        Swal.fire({
          imageUrl: 'assets/images/supp-modal.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 800,
          title: 'BIEN SUPPRIMÉ',
          timer: 1500,
          showConfirmButton: false
        }
        );
        this.experiences.splice(this.experiences.indexOf(experience), 1);

      }
    });


  }
  // AJOUET IUN LANGUE
  addLangues(langue) {
    this.errorLangue = ""
    if (this.langues.indexOf(langue) > -1 || this.languesChosed.indexOf(langue) > -1) {
      this.errorLangue = "Cette langue existe déjà dans la liste"
      return false;
    } else {
      this.languesChosed.push(langue);
    }
  }
  resetErrorLangue() {
    this.languesChosed = []
    while (this.languesChosed.length > 0) {
      this.languesChosed.pop();
    }
    this.errorLangue = ""
    this.langue = ""
  }
  saveChoosedLangues() {
    this.langues.push(...this.languesChosed);
  }
  // SUPPRIMER IUN LANGUE

  removeLangue(langue) {
    Swal.fire({
      title: 'VOULEZ-VOUS SUPPRIMER CETTE LANGUE?',
      imageUrl: 'assets/images/supp-modal.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      showCancelButton: true,
      width: 800,
      confirmButtonColor: '#059e00',
      cancelButtonColor: '#FF9100',
      cancelButtonText: 'ANNULER',
      confirmButtonText: 'VALIDER'
    }).then((result) => {
      if (!result.dismiss) {
        Swal.fire({
          imageUrl: 'assets/images/supp-modal.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 800,
          title: 'BIEN SUPPRIMÉ',
          timer: 1500,
          showConfirmButton: false
        }
        );
        this.langues.splice(this.langues.indexOf(langue), 1);

      }
    });


  }
  // AJOUET IUN competence

  addCompetences(competence) {
    this.errorCompetence = ""
  if (this.competences.indexOf(competence) > -1 || this.competencesChosed.indexOf(competence) > -1) {
    this.errorCompetence = "Cette compétence existe déjà dans la liste"
    return false;
  } else {
    this.competencesChosed.push(competence);
  }
}
resetErrorCompetence() {
  this.competencesChosed = []
  while (this.competencesChosed.length > 0) {
    this.competencesChosed.pop();
  }
  this.errorCompetence = ""
  this.competence = ""
}
saveChoosedCompetence() {
  this.competences.push(...this.competencesChosed);
}
  // supprimer IUN competence

  removeCompetence(competence) {
    Swal.fire({
      title: 'VOULEZ-VOUS SUPPRIMER CETTE COMPÉTENCE?',
      imageUrl: 'assets/images/supp-modal.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      showCancelButton: true,
      width: 800,
      confirmButtonColor: '#059e00',
      cancelButtonColor: '#FF9100',
      cancelButtonText: 'ANNULER',
      confirmButtonText: 'VALIDER'
    }).then((result) => {
      if (!result.dismiss) {
        Swal.fire({
          imageUrl: 'assets/images/supp-modal.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 800,
          title: 'BIEN SUPPRIMÉ',
          timer: 1500,
          showConfirmButton: false
        }
        );
        this.competences.splice(this.competences.indexOf(competence), 1);

      }
    });


  }
  test() {
    console.log("hello")
  }
  //ajouter un utilisateur
  onRegisterUtilisateur() {
    var d = new Date();
    this.errorEmail = false;
    this.form.value.datec = d.toLocaleString();
    if (this.situationFamiliale === "Célibataire") {
      this.form.value.situationFamiliale = "Célibataire";
      this.enfants = [];
      this.form.value.conjoint = {
        nom: "",
        prenom: "",
        nationalite: "",
        tel: "",
        mail: "",
        OrganismeDeSante: ""
      }
    }
    else {
      if (this.situationEnfant === "Non") {
        this.enfants = [];
      }
      this.form.value.situationFamiliale = this.situationFamiliale
      this.form.value.conjoint = this.formConjoint.value;
    }
    this.form.value.parcours = this.parcours;
    this.form.value.experiences = this.experiences;
    this.form.value.languages = this.langues;
    this.form.value.competences = this.competences;
    this.form.value.mail = this.formConnexion.value.mail;
    this.form.value.sousRole = this.formConnexion.value.sousRole;
    this.form.value.enfants = this.enfants;
    this.form.value.situationEnfant = this.situationEnfant
    Swal.fire({
      title: 'VOULEZ-VOUS CONFIRMER?',
      imageUrl: 'assets/images/client-plus.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      showCancelButton: true,
      width: 800,
      confirmButtonColor: '#008000',
      cancelButtonColor: '#FFA500',
      confirmButtonText: 'CONFIRMER',

      cancelButtonText: 'ANNULER',
    }).then((result) => {
      if (!result.dismiss) {
        if (this.formConnexion.value.password != this.formConnexion.value.passwordConfirmation) {
          this.errorEmail = false;
          this.successRegister = false;
          this.errorPassword = true;
          return false;
        }
        if(!this.formConnexion.value.mail || (this.formConnexion.value.mail && (this.formConnexion.value.mail.length <2 || this.formConnexion.value.mail.length > 100 || !this.validateService.validateEmail(this.formConnexion.value.mail)) )){
          this.errorPartie5 = "Merci de vérifier le champ Email"
          return false;
        }
        if(!this.formConnexion.value.password || (this.formConnexion.value.password && (this.formConnexion.value.password.length <6 || this.formConnexion.value.password.length > 100 ) )){
          this.errorPartie5 = "Merci de vérifier le champ Mot de passe (minimum 6 caractères)"
          return false;
        }
        if(!this.formConnexion.value.role ){
          this.errorPartie5 = "Merci de vérifier le champ rôle"
          return false;
        }
        this.errorPartie5 = ""
          this.errorPassword = false;
        this.form.value.password = Md5.hashAsciiStr(this.formConnexion.value.password);
        this.form.value.role = this.formConnexion.value.role;
        this.form.value.informationbancaire = this.formBancaire.value;
        this.form.value.role = this.myRole.name
        this.form.value.idRole = this.myRole.id
        this.userService.verifyEmail(this.form.value).subscribe(data => {
          if (data) {
            this.errorEmail = true;
            this.successRegister = false;
            this.errorPassword = false;
            return false;
          }
          else {
           
            this.form.value.age = this.getAge(this.form.value.dateNaissance)
            this.userService.registerUtilisateur(this.form.value).subscribe(data => {
              this.users.push(data);
              this.form.reset();
              this.formConjoint.reset();
              this.formParcours.reset();
              this.formExperience.reset();
              this.formBancaire.reset();
              this.formConnexion.reset();
              this.successRegister = true;
              this.errorEmail = false;
              this.errorPassword = false;
              Swal.fire({
                imageUrl: 'assets/images/client-plus.png',
                imageWidth: 100,
                imageHeight: 100,
                imageAlt: 'Custom image',
                width: 800,
                title: 'BIEN AJOUTÉ',
                timer: 1500,
                showConfirmButton: false
              }
              );
              this.router.navigateByUrl('/utilisateur');
            });

          }
        })
      }
    });

  }
  getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }
  getDateNaissance(): string {
    return this.dateNaissancee
  }
  getBigDate(): string {
    return this.bigDate
  }
  modifPart1() {
    this.showMyContainer = 1;
  }
  modifPart2() {
    if(!this.form.value.nom || (this.form.value.nom && (this.form.value.nom.length <2 || this.form.value.nom.length > 25) )){
      this.errorPartie1 = "Merci de vérifier le champ nom (minimum 2 caractères, maximum 25 caractères)"
      return false;
    }
    if(!this.form.value.prenom || (this.form.value.prenom && (this.form.value.prenom.length <2 || this.form.value.prenom.length > 25) )){
      this.errorPartie1 = "Merci de vérifier le champ prénom (minimum 2 caractères, maximum 25 caractères)"
      return false;
    }
    if( this.form.value.dateNaissance &&  this.form.value.dateNaissance >= this.today){
      this.errorPartie1 = "Merci de vérifier le champ date de naissance"
      return false;
    }
    if(!this.form.value.tel || (this.form.value.tel && this.form.value.tel.length != 12  )){
      this.errorPartie1 = "Merci de vérifier le champ N° fixe"
      return false;
    }
    if(!this.form.value.telMobile || (this.form.value.telMobile && this.form.value.telMobile.length != 12  )){
      this.errorPartie1 = "Merci de vérifier le champ N° mobile"
      return false;
    }
    if(!this.form.value.sexe ){
      this.errorPartie1 = "Merci de vérifier le champ sexe"
      return false;
    }
    if(!this.form.value.typeIdentite ){
      this.errorPartie1 = "Merci de vérifier le champ type d'identité"
      return false;
    }
    if(!this.form.value.numIdentite || (this.form.value.numIdentite && (this.form.value.numIdentite.length <6 || this.form.value.prenom.numIdentite > 12)) ){
      this.errorPartie1 = "Merci de vérifier le champ numéro de pièce d'identité"
      return false;
    }
    if((this.form.value.adresse.codePostal && (!Number(this.form.value.adresse.codePostal) || this.form.value.adresse.codePostal < 0 || this.form.value.adresse.codePostal > 99999 || this.form.value.adresse.codePostal.toString().indexOf('e') > -1)) ){
      this.errorPartie1 = "Merci de vérifier le champ code postal"
      return false;
    }
    if((this.form.value.adresse.n && (!Number(this.form.value.adresse.n) || this.form.value.adresse.n < 0 || this.form.value.adresse.n > 99999 || this.form.value.adresse.n.toString().indexOf('e') > -1 )) ){
      this.errorPartie1 = "Merci de vérifier le champ numéro"
      return false;
    }
    this.errorPartie1 = ""
    this.showMyContainer = 2;
  }
  modifPart3() {

    this.showMyContainer = 3;
  }
  modifPart4() {
    if(this.formConjoint.value.mail &&  !this.validateService.validateEmail(this.formConjoint.value.mail)){
      this.errorPartie2 = "Merci de vérifier le champ Email"
      return false;
    }
    this.errorPartie2 = ""
    this.showMyContainer = 4;
  }
  modifPart5() {
    if(this.formBancaire.value.nom &&  this.formBancaire.value.nom.length > 30){
      this.errorPartie4 = "Merci de vérifier le champ nom (maximum 30 caractères)"
      return false;
    }
    if(this.formBancaire.value.iban &&  this.formBancaire.value.iban.length > 30){
      this.errorPartie4 = "Merci de vérifier le champ iban (maximum 30 caractères)"
      return false;
    }
    if(this.formBancaire.value.swift &&  this.formBancaire.value.swift.length > 30){
      this.errorPartie4 = "Merci de vérifier le champ swift (maximum 30 caractères)"
      return false;
    }
    if(this.formBancaire.value.rib &&  this.formBancaire.value.rib.length > 30){
      this.errorPartie4 = "Merci de vérifier le champ rib (maximum 30 caractères)"
      return false;
    }
    if(this.formBancaire.value.bic &&  this.formBancaire.value.bic.length > 30){
      this.errorPartie4 = "Merci de vérifier le champ bic (maximum 30 caractères)"
      return false;
    }
    this.errorPartie4 = ""
    this.showMyContainer = 5;
  }
  showPart() {
    return this.showMyContainer;
  }
  valuechangeDatec($event){
    if(this.form.value.dateRec && this.form.value.dateRec.length > 10){
      this.form.get("dateRec").setValue(this.today);
    }
  }
  valuechangeDateObtion($event){
    if(this.formParcours.value.date && this.formParcours.value.date.length > 10){
      this.formParcours.get("date").setValue(this.today);
    }
  }
  valuechangeDatef($event){
    if(this.formExperience.value.datef && this.formExperience.value.datef.length > 10){
      this.formExperience.get("datef").setValue(this.today);
    }
  }
  valuechangeDated($event){
    if(this.formExperience.value.dated && this.formExperience.value.dated.length > 10){
      this.formExperience.get("dated").setValue(this.today);
    }
  }
  setParcours(parcour){
    this.indexToUodate = this.parcours.indexOf(parcour)
    this.selectedParcours = JSON.parse(JSON.stringify(parcour));
  }
  setExperience(experience){
    this.indexToUodate = this.experiences.indexOf(experience)
    this.selectedExperience = JSON.parse(JSON.stringify(experience));
  }
  updateParcours(selectedParcours){
     if(!selectedParcours.etablissement || (selectedParcours.etablissement && selectedParcours.etablissement.length < 4) || !selectedParcours.diplomen || (selectedParcours.diplomen && selectedParcours.diplomen.length < 4)){
       return false;
     }
     this.parcours[this.indexToUodate] = selectedParcours
     this.divClick.nativeElement.click();
  }
  valuechangeDateObtionUpdate($event){
    if(this.selectedParcours.date && this.selectedParcours.date.length > 10){
      this.selectedParcours.date = this.today;
    }
  }
  valuechangeDatefUpdate($event){
    if(this.selectedExperience.datef && this.selectedExperience.datef.length > 10){
      this.selectedExperience.datef = this.today;
    }
  }
  valuechangeDatedUpdate($event){
    if(this.selectedExperience.dated && this.selectedExperience.dated.length > 10){
      this.selectedExperience.dated = this.today;
    }
  }
  ModifierExperience(selectedExperience){
    if(!selectedExperience.entreprise || (selectedExperience.entreprise && selectedExperience.entreprise.length < 4) || !selectedExperience.poste || (selectedExperience.poste && selectedExperience.poste.length < 4)){
      return false;
    }
    if(selectedExperience.dated && selectedExperience.datef && selectedExperience.dated > selectedExperience.datef){
      return false;
    }
    this.experiences[this.indexToUodate] = selectedExperience
    this.divClickUpdateExp.nativeElement.click();
  }
}

