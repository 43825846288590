import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StockGuard } from 'src/app/guards/stock.guard';
import { ArmoiresEnFabricationComponent } from './armoires-en-fabrication/armoires-en-fabrication.component';
import { DetailsStockComponent } from './details-stock/details-stock.component';
import { StockByDateComponent } from './stock-by-date/stock-by-date.component';
import { StockEntreeComponent } from './stock-entree/stock-entree.component';
import { StockSortieComponent } from './stock-sortie/stock-sortie.component';
import { StockComponent } from './stock/stock.component';
import { UpdateStockComponent } from './update-stock/update-stock.component';


const routes: Routes = [
  { path: 'stock/:date', component: StockComponent, canActivate: [StockGuard]},
      { path: 'details/stock/:id', component: DetailsStockComponent, canActivate: [StockGuard]},
      { path: 'stock/scan/entree', component: StockEntreeComponent, canActivate: [StockGuard]},
      { path: 'stock/scan/sortie', component: StockSortieComponent, canActivate: [StockGuard]},
      { path: 'stock', component: StockByDateComponent, canActivate: [StockGuard]},
      { path: 'update/stock/artcile/:id', component: UpdateStockComponent, canActivate: [StockGuard]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StockRoutingModule { }
