import { Component, OnInit } from '@angular/core';
import * as XLSX from "xlsx";
import { StockService } from "../../../services/stock.service";
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from "../../../services/user.service";
import { CatalogueService } from "../../../services/catalogue.service";
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { ValidationService } from '../../../services/validate/validate-error.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
import { codesSchneider } from '../../../pays/codesSchneider';
import { FamilleService } from "../../../services/famille.service";

@Component({
  selector: 'app-update-stock',
  templateUrl: './update-stock.component.html',
  styleUrls: ['../../utilisateur/ajout-utilisateur/ajout-utilisateur.component.scss'],
})
export class UpdateStockComponent implements OnInit {
  article:any;
  id:any;
  utilisateur:any;
  form:any;
  errorChamp = false;
  timeout: any = null;
  familles:any;
  inventaireConfigObj:any;
  constructor(private familleService:FamilleService,private router: Router,private stockService: StockService,private activatedRoute: ActivatedRoute,private userService:UserService,private formBuilder: FormBuilder,private catalogueService:CatalogueService) {
    this.form = this.formBuilder.group({
      refArt: ['', [Validators.required, Validators.minLength(2), ValidationService.validateEmptyField, Validators.maxLength(25)]],
      designation: ['', [Validators.required, Validators.minLength(2), ValidationService.validateEmptyField, Validators.maxLength(200)]],
      pritar: ['', [Validators.required, Validators.min(0), Validators.max(999999)]],
    })
  }
  ngOnInit() {
    this.familleService.getAllData().then(data => {
      this.familles = data
    },
      err => {
        return false;
      });
    let params: any = this.activatedRoute.snapshot.params;
    this.stockService.getArticleById(params.id).subscribe(data => {
      this.article = data
    },
      err => {
        return false;
      });
  }

updatearticle(article){
  Swal.fire({
    title: 'VOULEZ-VOUS ENREGISTRER VOS MODIFICATIONS?',
    imageUrl: 'assets/images/modifier.png',
    imageWidth: 100,
    imageHeight: 100,
    imageAlt: 'Custom image',
    showCancelButton: true,
    width: 800,
    confirmButtonColor: '#059e00',
    cancelButtonColor: '#FF9100',
    cancelButtonText: 'ANNULER',
    confirmButtonText: 'ENREGISTRER'
  }).then((result) => {
    if (!result.dismiss) {
      this.stockService.editInventairerById(article)
        .subscribe(resp => {
        }, err => {

        })
        Swal.fire( {
            imageUrl: 'assets/images/statut-valide-hover.png',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            width: 800,
            title: 'BIEN MODIFIÉ',
            timer: 1500,
            showConfirmButton: false
          }
        );
      this.router.navigate(['/stock', article.dateInventaire]);
    }
  } );

}
  valuechange(event){
    clearTimeout(this.timeout);
      var $this = this;
      this.timeout = setTimeout(function () {
        if (event.keyCode != 13) {
          $this.getArticleByRefArt(event.target.value)
        }
      },1000);
  }

  getArticleByRefArt(event){
    this.article.denomination = null
    this.article.idFournisseur = null
    this.article.exist = false;
    this.catalogueService.getAllDataByRefOrEAN(event).then((data: any[]) => {
      if (data && data.length > 0) {
        data.sort(this.triData)
        this.article.denomination = data[0].denomination
        this.article.designation = data[0].designation25
        this.article.idFournisseur = data[0].idFournisseur
        this.article.refArt = data[0].refCiel
        this.article.exist = true;
        this.article.ean13Art = data[0].ean13
        this.article.pritar = data[0].tarif
        this.article.mkt5 = data[0].mkt5
        if( this.article.denomination == "SCHNEIDER ELECTRIC Tunisie"){
          if(this.article.mkt5){
            var mymkt = this.article.mkt5
            var foundFamillee = codesSchneider.filter(x => x.refFamille.toLowerCase().startsWith(mymkt[0].toLowerCase() + mymkt[1].toLowerCase()))
            if(foundFamillee && foundFamillee.length == 1){
              this.article.codFamille = foundFamillee[0].codeFamille
              this.article.libelleFamille = foundFamillee[0].libelle
              this.article.coefFamille = foundFamillee[0].coefFamille
            }
            if(foundFamillee && foundFamillee.length > 1 && mymkt[2]){
              var foundFamilleSecond = codesSchneider.filter(x => x.refFamille.toLowerCase().startsWith(mymkt[0].toLowerCase() + mymkt[1].toLowerCase() + mymkt[2].toLowerCase()))
              if(!foundFamilleSecond){
                this.article.codFamille = foundFamillee[0].codeFamille
                this.article.libelleFamille = foundFamillee[0].libelle
                this.article.coefFamille = foundFamillee[0].coefFamille

              }
              if(foundFamilleSecond && foundFamilleSecond.length < 1){
                this.article.codFamille = foundFamillee[0].codeFamille
                this.article.libelleFamille = foundFamillee[0].libelle
                this.article.coefFamille = foundFamillee[0].coefFamille

              }
              if(foundFamilleSecond && foundFamilleSecond.length == 1){
                this.article.codFamille = foundFamilleSecond[0].codeFamille
                this.article.libelleFamille = foundFamilleSecond[0].libelle
                this.article.coefFamille = foundFamilleSecond[0].coefFamille

              }
              if(foundFamillee && foundFamillee.length > 1 && mymkt[3]){
                var foundFamilleThird = codesSchneider.filter(x => x.refFamille.toLowerCase().startsWith(mymkt[0].toLowerCase() + mymkt[1].toLowerCase() + mymkt[2].toLowerCase() + mymkt[3].toLowerCase()))
                if(!foundFamilleThird){
                  this.article.codFamille = foundFamilleSecond[0].codeFamille
                  this.article.libelleFamille = foundFamilleSecond[0].libelle
                  this.article.coefFamille = foundFamilleSecond[0].coefFamille

                }
                if(foundFamilleThird && foundFamilleThird.length < 1){
                  if(foundFamilleSecond && foundFamilleSecond[0]){
                    this.article.codFamille = foundFamilleSecond[0].codeFamille
                    this.article.libelleFamille = foundFamilleSecond[0].libelle
                    this.article.coefFamille = foundFamilleSecond[0].coefFamille

                  }
                }
                if(foundFamilleThird && foundFamilleThird.length == 1){
                  this.article.codFamille = foundFamilleThird[0].codeFamille
                  this.article.libelleFamille = foundFamilleThird[0].libelle
                  this.article.coefFamille = foundFamilleThird[0].coefFamille

                }
              }
            }
          }
        }
        if(this.article.denomination != "SCHNEIDER ELECTRIC Tunisie"  && this.article.denomination != "PHOENIX CONTACT"){
          if(this.article.denomination){
            var foundFamille = this.familles.filter(x => this.article.denomination.toLowerCase().indexOf(x.libelle.toLowerCase()) !== -1 )
          }
          if(foundFamille && foundFamille.length > 0){
            this.article.codFamille = foundFamille[0].codFamille
            this.article.libelleFamille = foundFamille[0].libelle
            this.article.coefFamille = foundFamille[0].coefFamille
          }
        }
        if(this.article.denomination == "PHOENIX CONTACT"){
          var foundFamille = this.familles.filter(x => x.libelle == "PHOENIX NET" )
          if(foundFamille && foundFamille.length > 0){
            this.article.codFamille = foundFamille[0].codFamille
            this.article.libelleFamille = foundFamille[0].libelle
            this.article.coefFamille = foundFamille[0].coefFamille
          }
        }

      }
    },
      err => {
        return false;
      });
  }
  triData(a,b){
    if (a.datec > b.datec) return -1;
    else if (a.datec == b.datec) return 0;
    else return 1;
  }
}
