import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FournisseurService } from "../../../services/fournisseur.service";
import { CatalogueService } from "../../../services/catalogue.service";
import { UserService } from "../../../services/user.service";
import { CommandeService } from "../../../services/commande.service";


@Component({
  selector: 'app-details-fournisseur-commande-by-month',
  templateUrl: './details-fournisseur-commande-by-month.component.html',
  styleUrls: ['../../utilisateur/details-utilisateur/details-utilisateur.component.css'],
})
export class DetailsFournisseurCommandeByMonthComponent implements OnInit {
  fournisseur: any;
  roleAdministration = true;
  role: any;
  form:any;
  dateDebut = new Date().toISOString().split('T')[0]
  listeArtciels: any[]=[]
  listeAllArtciels:any;
  loading = false;
  sizeLimitOne = 30000000; //30MB
  tarifGlobal = 0;
  addedLength = 0;
  errorObjet: any;
  errorDate = false
  fileToChoose: any;
  id:any;
  utilisateur:any;
  years: any[] = [];
  lengthdata = 0
  showAddCatalogue = false;
  commandes:any;
  params:any;
  p = 1
  constructor(private commandeService:CommandeService, private fournisseurService: FournisseurService, private activatedRoute: ActivatedRoute,private router:Router,private catalogueService:CatalogueService,private userService:UserService) {

  }
  ngOnInit() {
    this.role = localStorage.getItem('roleUser')
    if (this.role) {
      this.roleAdministration = false;
    }
    this.id = localStorage.getItem('idUser')
    this.userService.getUserByID(this.id).subscribe(utilisateur => {
      this.utilisateur = utilisateur;
      if(!this.utilisateur){
        localStorage.removeItem('idUser');
        localStorage.clear();
        this.router.navigate(['/login']);
      }
    },
      err => {
        return false;
      });
    this.params = this.activatedRoute.snapshot.params;
    this.fournisseurService.getFournisseurByID(this.params.id).subscribe(fournisseur => {
      this.fournisseur = fournisseur;
      if(this.fournisseur){
        this.getAllCommandesByFounrisseurAndYear(this.fournisseur.id, this.params.year)
      }
    },
      err => {
        return false;
      });
}
getAllCommandesByFounrisseurAndYear(idFournisseur, year){
  this.commandeService.getAllCommandeCountByMonthFounisseur(idFournisseur,year).then(data => {
    if(data && data["result"]) {
    this.commandes = data["result"]
    this.commandes.sort(this.triCommandeByYearDesc)
    this.getAllCommandeByFournisseurAndYearMonth(this.commandes,idFournisseur,0,year)
  }
  },
    err => {
      return false;
    });
}
triCommandeByYearDesc(a, b) {
  if (a.yeardateCommande > b.yeardateCommande) return -1;
  else if (a.yeardateCommande == b.yeardateCommande) return 0;
  else return 1;
}
getAllCommandeByFournisseurAndYearMonth(commandes,idFournisseur,countme,year){
  if (countme === this.commandes.length) {
    return true;
  }
    this.commandes[countme].allCommandes = []
    this.commandes[countme].totalPrix = 0
    this.commandes[countme].totalmontantTVA = 0
    this.commandes[countme].totalSommePrixNet = 0
    this.commandeService.getAllCommandeByMonthAndYearFounisseur(idFournisseur,year,this.commandes[countme].yeardateCommande,).then(data => {
      this.commandes[countme].allCommandes = data
        for(var j=0;j<this.commandes[countme].allCommandes.length;j++){
          if(this.commandes[countme].allCommandes[j].sommePrixNetTTC != "NaN"){
          this.commandes[countme].totalPrix = this.commandes[countme].totalPrix + Number(this.commandes[countme].allCommandes[j].sommePrixNetTTC)
          this.commandes[countme].totalPrix = Math.round(this.commandes[countme].totalPrix*100)/100 // 2 chiffres apres la virgule*/
          }
          if(this.commandes[countme].allCommandes[j].montantTVA != "NaN"){
          this.commandes[countme].totalmontantTVA = this.commandes[countme].totalmontantTVA + Number(this.commandes[countme].allCommandes[j].montantTVA)
          this.commandes[countme].totalmontantTVA = Math.round(this.commandes[countme].totalmontantTVA*100)/100 // 2 chiffres apres la virgule*/
          }
          if(this.commandes[countme].allCommandes[j].sommePrixNet != "NaN"){
          this.commandes[countme].totalSommePrixNet = this.commandes[countme].totalSommePrixNet + Number(this.commandes[countme].allCommandes[j].sommePrixNet)
          this.commandes[countme].totalSommePrixNet = Math.round(this.commandes[countme].totalSommePrixNet*100)/100 // 2 chiffres apres la virgule*/
          }
          if(j == Number(this.commandes[countme].allCommandes.length - 1)){
            countme++
            this.getAllCommandeByFournisseurAndYearMonth(this.commandes,idFournisseur,countme,year)
            return true;
          }
        }
    },
      err => {
        return false;
      });
  }
}
