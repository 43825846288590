import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FournisseurService } from "../../../services/fournisseur.service";
import { UserService } from "../../../services/user.service";
import * as XLSX from "xlsx";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
import * as moment from 'moment';
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'app-fournisseur',
  templateUrl: './fournisseur.component.html',
  styleUrls: ['../../utilisateur/utilisateur/utilisateur.component.css'],
})
export class FournisseurComponent implements OnInit {

  public search: any = '';
  fournisseurs: any;
  fournisseur: any;
  numberData = 25;
  showSuccessUpdate = false;
  id:any;
  utilisateur:any;
  listefournExcel:any[]=[]
  dateClient:any;
  administration:any;
  p = 1
  role:any;
  hasRoleDelete = false;
  hasRoleUpdate = false;
  hasRoleAdd = false
  constructor( private fournisseurService: FournisseurService,private userService:UserService,private roleService:RoleService,
    private router: Router) {

  }
  ngOnInit() {
    this.id = localStorage.getItem('idUser')
    this.userService.getUserByID(this.id).subscribe(utilisateur => {
      this.utilisateur = utilisateur;
      if(!this.utilisateur || (this.utilisateur && !this.utilisateur.idRole)){
        localStorage.removeItem('idUser');
        localStorage.clear();
        this.router.navigate(['/login']);
      }
      this.roleService.getRoleById(this.utilisateur.idRole).subscribe(role => {
        this.role = role;
        if (this.role && this.role.privileges && this.role.privileges.length > 0) {
          let founddeleteUtilisateur = this.role.privileges.find(x => x.name == "DELETE_FOURNISSEUR")
          let foundupdateUtilisateur = this.role.privileges.find(x => x.name == "UPDATE_FOURNISSEUR")
          let foundupdateUtilisateuradd = this.role.privileges.find(x => x.name == "ADD_FOURNISSEUR")
          if (founddeleteUtilisateur) {
            this.hasRoleDelete = true;
          }
          if (foundupdateUtilisateur) {
            this.hasRoleUpdate = true;
          }
          if (foundupdateUtilisateuradd) {
            this.hasRoleAdd = true;
          }
        }
      },
        err => {
          return false;
        });
    },
      err => {
        return false;
      });
    this.getAllFournisseurs();

  }
  // recuperer tous les Fournisseurs
  getAllFournisseurs() {
    this.fournisseurService.getAllUsers().then(data => {
      this.fournisseurs = data;
      this.fournisseurs.sort(this.triFournisseur)
      //this.toFileXLS()
    },
      err => {
        return false;
      });
  }
  // supprimer un fournisseur
  removeFournisseur(fournisseur) {
     Swal.fire({
      title: 'VOULEZ-VOUS SUPPRIMER CE FOURNISSEUR?',
      imageUrl: 'assets/images/supp-modal.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      showCancelButton: true,
      width: 800,
      confirmButtonColor: '#059e00',
      cancelButtonColor: '#FF9100',
      cancelButtonText: 'ANNULER',
      confirmButtonText: 'VALIDER'
    }).then((result) => {
      if (!result.dismiss) {
        Swal.fire( {
            imageUrl: 'assets/images/supp-modal.png',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            width: 800,
            title: 'FOURNISSEUR SUPPRIMÉ',
            timer: 1500,
            showConfirmButton: false
          }
        );
        this.fournisseurService.deleteFournisseurById(fournisseur.id).then(data => {
         });
         this.fournisseurs.splice(this.fournisseurs.indexOf(fournisseur), 1);
      }
    } );
    }

    toFileXLS() {

      for (var i = 0; i < this.fournisseurs.length; i++) {
        if(this.fournisseurs[i].datec && this.fournisseurs[i].datec.indexOf('à') > -1){
          this.dateClient =  this.fournisseurs[i].datec.split(' à')[0]
        }
        if(this.fournisseurs[i].datec && this.fournisseurs[i].datec.indexOf('à') < 0){
          this.dateClient =  moment(this.fournisseurs[i].datec).format('DD/MM/YYYY');
        }
        var devExcel = {
          "SIRET": this.fournisseurs[i].siren,
          "Date de création": this.dateClient,
          "Dénomination": this.fournisseurs[i].denomination,
          "Acronyme": this.fournisseurs[i].acronyme,
          "Forme juridique": this.fournisseurs[i].formeJuridique,
          "Code NAF": this.fournisseurs[i].indicatif,
          "Type": this.fournisseurs[i].type,
          "Email": this.fournisseurs[i].mail,
          "Téléphone": this.fournisseurs[i].tel,
          "Adresse": this.fournisseurs[i].adresse.n+ ' '+this.fournisseurs[i].adresse.rue+' '+this.fournisseurs[i].adresse.codePostal,
          "Ville": this.fournisseurs[i].adresse.ville,
          "Pays":this.fournisseurs[i].adresse.pays
        }
        this.listefournExcel.push(devExcel)
      }

      const workBook = XLSX.utils.book_new(); // create a new blank book
      const workSheet = XLSX.utils.json_to_sheet(this.listefournExcel);

      XLSX.utils.book_append_sheet(workBook, workSheet, 'data'); // add the worksheet to the book
      var currentDate = new Date().toISOString().split('T')[0]
      var mydate =  currentDate[8]+currentDate[9]+currentDate[5]+currentDate[6]+currentDate[2]+currentDate[3]
      XLSX.writeFile(workBook, 'VoWork-Fournisseurs-'+mydate+'.xlsx'); // initiate a file download in browser
    }
    triFournisseur(a,b){
      if (a.denomination.toLowerCase() < b.denomination.toLowerCase()) return -1;
      else if (a.denomination.toLowerCase() == b.denomination.toLowerCase()) return 0;
      else return 1;
    }
}
