import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ClientGuard } from 'src/app/guards/client.guard';
import { AjoutClientComponent } from './ajout-client/ajout-client.component';
import { ClientComponent } from './client/client.component';
import { DetailsClientComponent } from './details-client/details-client.component';
import { UpdateClientComponent } from './update-client/update-client.component';


const routes: Routes = [
  { path: 'client', component: ClientComponent,canActivate: [ClientGuard]},
  { path: 'ajoutClient', component: AjoutClientComponent,canActivate: [ClientGuard]},
  { path: 'client/details/:id', component: UpdateClientComponent,canActivate: [ClientGuard]},
  { path: 'details/client/:id', component: DetailsClientComponent,canActivate: [ClientGuard]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CLIENTRoutingModule { }
