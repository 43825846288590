import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
const headers = new HttpHeaders({Authorization: 'Basic ' + btoa('UserkrendelAdminnew:krl2021!!??**SoC24-415fr-a410-ttx9J8NbPecEL6k4BkAXvGE3t8f3nuEZmXa5RNJBAdrnh6XqB2')})
.set('Content-Type', 'application/json')
.set('Accept', 'application/json');
headers.append('Access-Control-Allow-Origin', '*');
import 'rxjs/add/operator/map';

@Injectable({
  providedIn: 'root'
})
export class AgenceService {

  constructor(private http: HttpClient) {
  }


  addClient(client) {
    return this.http.post(environment.host + '/agences/newAgence', JSON.stringify(client), {headers: headers})
  }
  getAllUsers() {
    return new Promise(resolve => {
      this.http.get(environment.host + '/agences', {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getAllUsersNotRadie(){
    return new Promise(resolve => {
      this.http.get(environment.host + '/agences/active', {headers: headers}).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  getClientByID(id){
    return this.http.get(environment.host + '/agences/'+id, {headers: headers})
  }
  editClientById(client){
    return this.http.put(environment.host + '/agences/' + client.id, JSON.stringify(client), { headers: headers })
  }
  deleteClientById(id) {
    return new Promise(resolve => {
      this.http.delete(environment.host + '/agences/' + id, {headers: headers} ).subscribe(data => {
        resolve(data);
      }, err => {
      });
    });
  }
  // verifier existance de siret dans la BD
 verifySiret(client) {
   return this.http.post(environment.host + '/agences/verifiedSiret', JSON.stringify(client), { headers: headers })
  }
}