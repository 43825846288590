import { Component, OnInit } from '@angular/core';
import { InventaireService } from "../../../services/inventaire.service";
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from "../../../services/user.service";

@Component({
  selector: 'app-details-inventaire',
  templateUrl: './details-inventaire.component.html',
  styleUrls: ['../../utilisateur/details-utilisateur/details-utilisateur.component.css']
})
export class DetailsInventaireComponent implements OnInit {
  article:any;
  utilisateur:any;
  id:any;
  constructor(private router:Router,private inventaireService: InventaireService,private activatedRoute: ActivatedRoute,private userService:UserService) {

  }
  ngOnInit() {
    let params: any = this.activatedRoute.snapshot.params;
    this.inventaireService.getArticleById(params.id).subscribe(data => {
      this.article = data
    },
      err => {
        return false;
      });

      this.id = localStorage.getItem('idUser')
      this.userService.getUserByID(this.id).subscribe(utilisateur => {
        this.utilisateur = utilisateur;
        if(!this.utilisateur){
          localStorage.removeItem('idUser');
          localStorage.clear();
          this.router.navigate(['/login']);
        }
        if (this.utilisateur && this.utilisateur.mail == 'suren.vijey@krendel.fr') {
          this.router.navigateByUrl('/accueil');
          return false;
        }
      },
        err => {
          return false;
        });
  }



}
