import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DossierService } from "../../../services/dossier.service";
import { CommandeService } from "../../../services/commande.service";
import { UserService } from "../../../services/user.service";
import { DevisService } from "../../../services/devis.service";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
import * as moment from 'moment';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-details-dossier',
  templateUrl: './details-dossier.component.html',
  styleUrls: ['./details-dossier.component.css','../../utilisateur/details-utilisateur/details-utilisateur.component.css']
})
export class DetailsDossierComponent implements OnInit {
  dossier: any;
  commandes: any[] = [];
  lengthcommandes: any;
  showMyContainer = 1;
  id: any;
  utilisateur: any;
  loading = false;
  lengthDevis = 0;
  othersDevis: any;
  indiceDuplciation = '0'
  listeDevis: any[] = [];
  listeDevisExcel: any[] = [];
  arrayNewArticles: any[] = [];
  selectedDossier: any;
  errorDuplication: any;
  fieldnumero: any;
  timeout: any = null;
  dossiersSearch: any;
  styleOne = true;
  mydate = new Date().toISOString().split('T')[0]
  compteur: any;
  devisEnCde: any;
  displayLastDevisArray: any[] = []
  filterLIgnes:any;
  roleAdministration:any;
  p = 1
  constructor(private router: Router, private devisService: DevisService, private userService: UserService, private commandeService: CommandeService, private activatedRoute: ActivatedRoute, private dossierService: DossierService) {
  }
  ngOnInit() {
    this.loading = true;
    let params: any = this.activatedRoute.snapshot.params;
    this.dossierService.getDossierById(params.id).subscribe(data => {
      this.dossier = data;
      if (this.dossier) {
        this.dossier.delai = this.calculateBusinessDays(moment(this.dossier.dateReception), moment(this.dossier.datel))
        if (this.mydate <= this.dossier.datel) {
          this.compteur = "J-" + this.calculateBusinessDays(moment(this.mydate), moment(this.dossier.datel))
        }
        if (this.mydate > this.dossier.datel) {
          this.compteur = "J+" + this.calculateBusinessDays(moment(this.dossier.datel), moment(this.mydate))
        }
        this.loading = false
          this.commandeService.getAllCommandeByDossierCount(this.dossier.numero).then(dataa => {
            this.lengthcommandes = dataa;
            if (this.lengthcommandes) {
              this.getAllCommandeByDossier(this.dossier.numero, 0);
              return true;
            }
            if(!this.lengthcommandes){
              this.getOnlyDevis(this.dossier.id);
              return true;
            }
          },
            err => {
              return false;
            });
      }
    },
      err => {
        return false;
      });
    this.id = localStorage.getItem('idUser')
    if (this.id) {
      this.userService.getUserByID(this.id).subscribe(utilisateur => {
        this.utilisateur = utilisateur;
        if(!this.utilisateur){
          localStorage.removeItem('idUser');
          localStorage.clear();
          this.router.navigate(['/login']);
        }
      },
        err => {
          return false;
        });
    } else {
      return false;
    }
    this.dossierService.getUniqueNumeros().then(data => {
      this.dossiersSearch = data;
    },
      err => {
        return false;
      });
  }
  // get devis by dossier
  getDevis(id) {
    this.devisService.getAllDevisByDossier(id).then(data => {
      this.othersDevis = data;
      if (this.othersDevis && this.othersDevis.length > 0) {
        this.devisEnCde = this.othersDevis.find(x => x.etat == "D5 - CDE" || x.etat == "D6 - FAB" || x.etat == "D7 - Livrée")
        if (this.devisEnCde) {
          var looppp = 0
          while (this.displayLastDevisArray.length) {
            this.displayLastDevisArray.pop();
          }
          this.startdisplayLastDevis(looppp)
        }
      }
      this.listeDevis = this.othersDevis
      this.listeDevis.sort(this.triDevis)
      this.lengthDevis = this.listeDevis.length
      this.loading = false;
    },
      err => {
        this.loading = false;
        return false;
      });

  }
  getOnlyDevis(id){
    this.devisService.getAllDevisByDossier(id).then(data => {
      this.othersDevis = data;
      this.listeDevis = this.othersDevis
      this.listeDevis.sort(this.triDevis)
      this.lengthDevis = this.listeDevis.length
      this.loading = false;
    },
      err => {
        this.loading = false;
        return false;
      });
  }
  startdisplayLastDevis(countInventaire) {
    if (countInventaire === this.devisEnCde.armoires.length) {
      this.concatLignesCommandes()
      return true;
    }
    if (this.devisEnCde.armoires[countInventaire]) {
      for (var j = 0; j < this.devisEnCde.armoires[countInventaire].detailsDevis.length; j++) {
        var newDev = {
          "codArt": this.devisEnCde.armoires[countInventaire].detailsDevis[j].codArt,
          "refArt": this.devisEnCde.armoires[countInventaire].detailsDevis[j].refArt,
          "libDev": this.devisEnCde.armoires[countInventaire].detailsDevis[j].libDev,
          "quantites": [],
          "qteAchete": 0,
          "sum": 0
        }
        var selectednewDev = this.displayLastDevisArray.find(x => x.codArt === newDev.codArt)
        if (selectednewDev) {
          var quantiteParArmo = {
            "armoireNumero": this.devisEnCde.armoires[countInventaire].numero,
            "quantite": this.devisEnCde.armoires[countInventaire].detailsDevis[j].qteArt
          }
          var selectedQte = selectednewDev.quantites.find(x => x.armoireNumero === quantiteParArmo.armoireNumero)
          if (!selectedQte) {
            selectednewDev.sum = selectednewDev.sum + Number(quantiteParArmo.quantite)
            selectednewDev.quantites.push(quantiteParArmo)
          }
          if (selectedQte) {
            selectednewDev.sum = selectednewDev.sum + Number(quantiteParArmo.quantite)
            selectedQte.quantite = selectedQte.quantite + Number(quantiteParArmo.quantite)
          }
        }
        if (!selectednewDev) {
          var quantiteParArmo = {
            "armoireNumero": this.devisEnCde.armoires[countInventaire].numero,
            "quantite": this.devisEnCde.armoires[countInventaire].detailsDevis[j].qteArt
          }
          newDev.sum = newDev.sum + Number(quantiteParArmo.quantite)
          newDev.quantites.push(quantiteParArmo)
          this.displayLastDevisArray.push(newDev)
        }
        if (j === Number(this.devisEnCde.armoires[countInventaire].detailsDevis.length - 1)) {
          countInventaire++
          this.startdisplayLastDevis(countInventaire)
          return true;
        }
      }
    }
    if (!this.devisEnCde.armoires[countInventaire]) {
      countInventaire++
      this.startdisplayLastDevis(countInventaire)
      return true
    }
  }

  concatLignesCommandes() {
    var aarr = []
    for (var j = 0; j < this.commandes.length; j++) {
      if (this.commandes[j].lignes && this.commandes[j].lignes.length > 0) {
        this.filterLIgnes =  this.commandes[j].lignes.filter( x => x.numero == this.dossier.numero)
        aarr.push(...this.filterLIgnes)
      }
      if (j === Number(this.commandes.length - 1)) {
        const resp = aarr.reduce((acc, ele) => {
          const existing = acc.find(x => x.refArt === ele.refArt && x.numero == this.dossier.numero);
          if (!existing) return acc.concat(ele);
          return (existing.quantite += ele.quantite, acc);
        }, [])
        this.filterAndMatchingQauntite(resp)
        return true;
      }
    }
  }
  filterAndMatchingQauntite(resp){
    if(resp && resp.length >0){
      for(var j = 0; j<resp.length;j++){
        var existingRef = this.displayLastDevisArray.find(x=> x.refArt == resp[j].refArt)
        if(existingRef){
          existingRef.qteAchete = resp[j].quantite
        }
        if(!existingRef){
          this.arrayNewArticles.push(resp[j])
        }
      }
    }
  }
  getAllCommandeByDossier(numero, numberPage) {
    if (this.commandes.length === this.lengthcommandes) {
      this.getDevis(this.dossier.id)
      return true;
    }
    this.commandeService.getAllCommandeByDossier(numero, numberPage, 2000).then((data: any[]) => {
      this.commandes.push(...data)
      numberPage++
      this.getAllCommandeByDossier(numero, numberPage);
      return true;
    },
      err => {
        return false;
      });
  }
  modifPart1() {
    this.showMyContainer = 1;
  }
  modifPart2() {
    this.showMyContainer = 2;
  }
  modifPart3() {
    this.showMyContainer = 3;
  }
  modifPart4() {
    this.showMyContainer = 4;
  }
  modifPart5() {
    this.showMyContainer = 5;
  }
  modifPart6() {
    this.showMyContainer = 6;
  }
  modifPart7() {
    this.showMyContainer = 7;
  }
  showPart() {
    return this.showMyContainer;
  }
  removeDevis(devis) {
    Swal.fire({
      title: 'VOULEZ-VOUS SUPPRIMER CE DEVIS?',
      imageUrl: 'assets/images/supp-modal.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      showCancelButton: true,
      width: 800,
      confirmButtonColor: '#059e00',
      cancelButtonColor: '#FF9100',
      cancelButtonText: 'ANNULER',
      confirmButtonText: 'VALIDER'
    }).then((result) => {
      if (!result.dismiss) {
        Swal.fire({
          imageUrl: 'assets/images/supp-modal.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 800,
          title: 'BIEN SUPPRIMÉ',
          timer: 1500,
          showConfirmButton: false
        }
        );
        this.devisService.deleteDevisById(devis.id).then(data => {
        });
        this.listeDevis.splice(this.listeDevis.indexOf(devis), 1);
        this.lengthDevis = Number(this.lengthDevis - 1);
      }
    });

  }

  updateDevis(devis) {

    if (devis.etat === 'D4 - Envoyé') {
      this.dossier.statut = "06 - Envoyé";
    }
    if (devis.etat === 'D5 - CDE') {
      this.dossier.statut = "09 - CDE";
      devis.dateMiseEnProd = new Date().toISOString()
    }
    if (devis.etat === 'D5 - CDE') {
      this.dossier.statut = "09 - CDE";
      devis.dateMiseEnProd = new Date().toISOString()
    }
    if (devis.etat === "D6 - FAB") {
      this.dossier.statut = "10 - FAB";
      devis.dateMiseEnProd = new Date().toISOString()
    }
    if (devis.etat === "D7 - Livrée") {
      this.dossier.statut = "11 - Livrée";
      devis.dateMiseEnProd = new Date().toISOString()
    }
    devis.dateMod = moment().format('DD/MM/YYYY, H:mm:ss');
    this.devisService.editDevis(devis).subscribe(resp => {
    }, err => {
    })
    this.dossierService.editDossier(this.dossier)
      .subscribe(resp => {
      }, err => {

      })
    Swal.fire({
      imageUrl: 'assets/images/client-plus.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      width: 800,
      title: 'BIEN MODIFIÉ',
      timer: 1500,
      showConfirmButton: false
    }
    );
  }
  exportToExcel() {
    if (this.listeDevis.length < 1) {
      return false;
    }
    for (var i = 0; i < this.listeDevis.length; i++) {
      var creePar = ""
      if (this.listeDevis[i].user) {
        creePar = this.listeDevis[i].user.nom + ' ' + this.listeDevis[i].user.prenom
      }
      var devExcel = {
        "Nom": this.listeDevis[i].nom,
        "Nombre des armoires	": this.listeDevis[i].armoires.length,
        "Client": this.listeDevis[i].client.denomination,
        "Créé par": creePar,
        "Etat": this.listeDevis[i].etat,
        "Date de création": this.listeDevis[i].datec,
        "Date de modification": this.listeDevis[i].dateMod,
      }
      this.listeDevisExcel.push(devExcel)
    }
    const workBook = XLSX.utils.book_new(); // create a new blank book
    const workSheet = XLSX.utils.json_to_sheet(this.listeDevisExcel);

    XLSX.utils.book_append_sheet(workBook, workSheet, 'data'); // add the worksheet to the book
    XLSX.writeFile(workBook, 'VoWork-dossier-' + this.dossier.numero + '-devis.xlsx'); // initiate a file download in browser
  }
  startImprimer(type, devis) {
    localStorage.setItem('devis-pdf-krendel', JSON.stringify(devis));
    if (type === "admin") {
      this.router.navigate(["dossier/devis/pdf", devis.idDossier]);
    }
    if (type === "v1") {
      this.router.navigate(["dossier/devis/client/pdf", devis.idDossier]);
    }
    if (type === "v2") {
      this.router.navigate(["dossier/devis/client/pdf", devis.idDossier, "v2"]);
    }
    if (type === "v3") {
      this.router.navigate(["dossier/devis/client/pdf", devis.idDossier, "v3"]);
    }
    if (type === "v4") {
      this.router.navigate(["dossier/devis/client/pdf", devis.idDossier, "v4"]);
    }
  }
  startDupliquer(devis) {
    Swal.fire({
      title: 'VOULEZ-VOUS CONFIRMER?',
      imageUrl: 'assets/images/client-plus.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      showCancelButton: true,
      width: 800,
      confirmButtonColor: '#008000',
      cancelButtonColor: '#FFA500',
      confirmButtonText: 'CONFIRMER',

      cancelButtonText: 'ANNULER',
    }).then((result) => {
      if (!result.dismiss) {
        var objectTosave = JSON.parse(JSON.stringify(devis));
        objectTosave.datec = moment().format('DD/MM/YYYY, H:mm:ss');
        objectTosave.dateMod = undefined
        objectTosave.id = undefined
        objectTosave.indiceDevis = this.indiceDuplciation
        this.devisService.addDevis(objectTosave).subscribe(resp => {
          this.devisService.getAllDevisByDossier(this.dossier.id).then(data => {
            this.othersDevis = data;
            this.listeDevis = this.othersDevis
            this.lengthDevis = this.listeDevis.length
            Swal.fire({
              imageUrl: 'assets/images/client-plus.png',
              imageWidth: 100,
              imageHeight: 100,
              imageAlt: 'Custom image',
              width: 800,
              title: 'BIEN DUPLIQUÉ',
              timer: 1500,
              showConfirmButton: false
            }
            );
          },
            err => {
              this.loading = false;
              alert("Erreur !")
              return false;
            });
        }, err => {
        })

      }
    });


  }
  duplicateDevisOtherDossier(devis) {
    Swal.fire({
      title: 'VOULEZ-VOUS CONFIRMER?',
      imageUrl: 'assets/images/client-plus.png',
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      showCancelButton: true,
      width: 800,
      confirmButtonColor: '#008000',
      cancelButtonColor: '#FFA500',
      confirmButtonText: 'CONFIRMER',

      cancelButtonText: 'ANNULER',
    }).then((result) => {
      if (!result.dismiss) {
        if (!this.selectedDossier) {
          this.errorDuplication = "Tous les champs sont obligatoires !"
          return false;
        }
        this.errorDuplication = ""
        var objectTosave = JSON.parse(JSON.stringify(devis));
        objectTosave.datec = moment().format('DD/MM/YYYY, H:mm:ss');
        objectTosave.dateMod = undefined
        objectTosave.id = undefined
        objectTosave.idDossier = this.selectedDossier.id
        objectTosave.indiceDevis = this.indiceDuplciation
        this.devisService.addDevis(objectTosave).subscribe(resp => {
          Swal.fire({
            imageUrl: 'assets/images/client-plus.png',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            width: 800,
            title: 'BIEN DUPLIQUÉ',
            timer: 1500,
            showConfirmButton: false
          }
          );
          this.selectedDossier = undefined
          this.fieldnumero = undefined
          this.errorDuplication = ""
        }, err => {
        })

      }
    });
  }
  initiliaseDossierr() {
    this.selectedDossier = undefined
    this.fieldnumero = undefined
    this.errorDuplication = ""
  }
  // duplication armoire dans un autre devis
  valuechangee(event, field) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function() {
      if (event.keyCode != 13) {
        $this.getDossierByNumeroo(event)
      }
    }, 2000);
  }
  getDossierByNumeroo(event) {
    if (!event || event == "") {
      return false;
    }
    this.dossierService.getDetailDossierByNumero(event).then(dossier => {
      this.selectedDossier = dossier
    },
      err => {
        return false;
      });
  }
  doubleClick(j, devis) {
    if (devis.etat == "D4 - Envoyé" || devis.etat == "D5 - CDE" || devis.etat == "D6 - FAB" || devis.etat == "D7 - Livrée") {
      return false;
    }
    for (var i = 0; i < this.listeDevis.length; i++) {
      if (i != j) {
        this['demandeUpdate' + i] = false;
      }
    }
    this['demandeUpdate' + j] = !this['demandeUpdate' + j]
    return true;
  }
  doubleClickDateEnvoie(j, devis) {
    if (devis.etat == "D5 - CDE" || devis.etat == "D6 - FAB" || devis.etat == "D7 - Livrée") {
      return false;
    }
    for (var i = 0; i < this.listeDevis.length; i++) {
      if (i != j) {
        this['demandeUpdateDateEnvoie' + i] = false;
      }
    }
    this['demandeUpdateDateEnvoie' + j] = !this['demandeUpdateDateEnvoie' + j]
    return true;
  }
  updateDevisIndice(devis, j) {
    var $this = this;
    setTimeout(function() {
      $this.devisService.editDevis(devis).subscribe(resp => {
        Swal.fire({
          imageUrl: 'assets/images/client-plus.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 800,
          title: 'BIEN MODIFIÉ',
          timer: 1500,
          showConfirmButton: false
        }
        );
        $this['demandeUpdate' + j] = false;
        $this['demandeUpdateDateEnvoie' + j] = false;
      }, err => {
      })
    }, 2000);

  }

  triDevis(a, b) {
    if (a.datec > b.datec) return -1;
    else if (a.datec == b.datec) return 0;
    else return 1;
  }
  calculateBusinessDays(start_date, end_date) {
    const d1 = start_date.clone();
    let num_days = 0;
    while (end_date.diff(d1.add(1, 'days')) > 0) {
      if ([0, 6].includes(d1.day())) {
        // Don't count the days
      } else {
        num_days++;
      }
    }
    return num_days;
  }

}
