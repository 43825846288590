<app-navbar></app-navbar>
<br>
<ng-template #customLoadingTemplate></ng-template>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading>
<div id="content-nav-page" class="row">
  <div class=" col-3 titre-menu">
    <b style="margin-right: 10%">STOCK</b>
    <div class="dropdown" *ngIf="hasRoleAdd">
      <button class="btn icon-catalogue" type="button" id="dropdownMenuButton" data-toggle="dropdown">
      </button>
      <div class="dropdown-menu show-catalogue" aria-labelledby="dropdownMenuButton">
        <a class="dropdown-item drop-dup-option" [routerLink]="['/stock/scan/entree']">Entrée</a>
        <a class="dropdown-item drop-dup-option-b" [routerLink]="['/stock/scan/sortie']">Sortie</a>
      </div>
    </div>
  </div>
  <div class="col-9 sous-menu" *ngIf="utilisateur">
    <b class="sous-menu-active2" *ngIf="hasRoleinventaire" routerLink="/inventaire" >INVENTAIRE</b>
    <b class="sous-menu-active1 ml-4" routerLink="/stock">STOCK</b>
  </div>
</div>
<div class=" col d-flex justify-content-end">
  <div class="col-auto btn-devis " >

  </div>
</div>
<div id="content-page" class="containe-fluid">
  <div class="row" style="overflow-x:auto;">
    <table class="table table-striped table-hover">
      <th class="left" id="firstThTable">DATE DU STOCK</th>
      <th class="right">NOMBRE DES RÉFÉRENCES</th>
      <th class="text-center ">RÉGLAGES</th>
      <tr *ngFor="let stock of listeStock| paginate: { itemsPerPage: 25, currentPage: p } ;index as i" [routerLink]="['/stock',stock._id]">
        <td class="left" >
          {{stock._id | date : 'dd/MM/yyyy'}}
        </td>
        <td class="right" >
          {{stock.nArticles | number: '1.2-2'| replace:',':' ' | replace:'.':',' | replace:',00':' '}}
        </td>
        <td style="text-align: center;">
          <button class="btn-affiche"><a [routerLink]="['/stock',stock._id]"> <img src="assets/images/show-white.png"> Afficher </a> </button>
        </td>
      </tr>
    </table>
  </div>
  <div style="padding-top: 50px;color: green">
    <pagination-controls previousLabel="Précédent" nextLabel="Suivant" (pageChange)="p = $event" #api></pagination-controls>
  </div>
</div>
