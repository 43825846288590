import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http } from '@angular/http';
import { ChargeAffaireService } from "../../../services/charge-affaire.service";
import { ClientService } from "../../../services/client.service";
import * as XLSX from "xlsx";
import { UserService } from "../../../services/user.service";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'app-charge-affaire',
  templateUrl: './charge-affaire.component.html',
  styleUrls: ['../../utilisateur/utilisateur/utilisateur.component.css'],
})
export class ChargeAffaireComponent implements OnInit {
  chargeAffaires: any;
  public filterCA: any = '';
  roleAdministration = true;
  administration = false;
  role: any;
  clients: any;
  clientChargeAffaires: any[] = [];
  listeclientChargeAffairesExcel: any[] = [];
  pays:any;
  numberData = 25;
  adresse:any;
  id:any;
  utilisateur:any;
  dataa:any;
  pageNumber:any;
  loading = false;
  lenghtData:any;
  timeout: any = null;
  chargeAffairesToDwnload:any[]=[]
  p = 1
  hasRoleDelete = false
  hasRoleUpdate = false
  hasRoleAdd = false
  hasRoleclient = false;
  constructor(private roleService:RoleService,private clientService: ClientService, private http: Http, private chargeAffaireService: ChargeAffaireService,private userService:UserService,
    private router: Router) {

  }
  ngOnInit() {
    this.id = localStorage.getItem('idUser')
    this.userService.getUserByID(this.id).subscribe(utilisateur => {
      this.utilisateur = utilisateur;
      if(!this.utilisateur || (this.utilisateur && !this.utilisateur.idRole)){
        localStorage.removeItem('idUser');
        localStorage.clear();
        this.router.navigate(['/login']);
      }
      this.roleService.getRoleById(this.utilisateur.idRole).subscribe(role => {
        this.role = role;
        if (this.role && this.role.privileges && this.role.privileges.length > 0) {
          let founddeleteUtilisateur = this.role.privileges.find(x => x.name == "DELETE_CA")
          let foundupdateUtilisateur = this.role.privileges.find(x => x.name == "UPDATE_CA")
          let foundaddclinet  = this.role.privileges.find(x => x.name == "ADD_CA")
          let foundaddca = this.role.privileges.find(x => x.name == "GET_ALL_CLIENT")
          if (founddeleteUtilisateur) {
            this.hasRoleDelete = true;
          }
          if (foundupdateUtilisateur) {
            this.hasRoleUpdate = true;
          }
          if (foundaddclinet) {
            this.hasRoleAdd = true;
          }
          if (foundaddca) {
            this.hasRoleclient = true;
          }
        }
      },
        err => {
          return false;
        });
      this.getAllChargesAffaires();
      
    },
      err => {
        return false;
      });

    this.role = localStorage.getItem('roleUser')
    if (this.role) {
      this.roleAdministration = false;
      this.administration = true;
    }
    this.chargeAffaireService.getAllDataCount().then(data => {
      this.lenghtData = data
      //this.loading = false;
    },
      err => {
        return false;
      });
  }
  getAllChargesAffaires() {
    this.pageNumber = 0
    this.chargeAffaireService.getAllUsersByClientByPage(this.pageNumber, 10).then((data: any[])  => {
        this.chargeAffaires = data
    },
      err => {
        return false;
      });
  }


  getAllChargesAffairesNotRadies(){
    this.chargeAffaires = []
    this.pageNumber = 0
    this.chargeAffaireService.getAllUsersByClientByPage(this.pageNumber, 10).then((data: any[])  => {
      this.dataa = data
      if(this.dataa){
        for(var j=0;j<this.dataa.length;j++){
          if(this.dataa[j].clients[0] && this.dataa[j].clients[0].statut === 'Active'){
                if(this.dataa[j].clients[0].etablissements.length === 0){
                  this.chargeAffaires.push(this.dataa[j])
                }
                var existEtab = this.dataa[j].clients[0].etablissements.find(x => this.dataa[j].client.etablissement && JSON.stringify(x.adresse) === JSON.stringify(this.dataa[j].client.etablissement.adresse)  &&  x.mail == this.dataa[j].client.etablissement.mail &&  x.nom == this.dataa[j].client.etablissement.nom &&  x.tel == this.dataa[j].client.etablissement.tel)
                if(existEtab && existEtab.statutEtab === 'Active'){
                  this.chargeAffaires.push(this.dataa[j])
                }
          }
        }
      }
    },
      err => {
        return false;
      });
  }
  // en scrollant vers le bas

  onScroll() {
    setTimeout(() => {    //<<<---    using ()=> syntax
      this.addData();
    }, 1000);

  }

  addData() {
    if (this.chargeAffaires && this.lenghtData === this.chargeAffaires.length) {
      return false;
    }
    if(this.utilisateur && this.utilisateur.role === "Super admin"){
    this.pageNumber = this.pageNumber + 1
    this.chargeAffaireService.getAllUsersByClientByPage(this.pageNumber, 10).then((data: any[])  => {
      this.chargeAffaires.push(...data)
      return true;
    },
      err => {
        return false;
      });
    }else{
      this.pageNumber = this.pageNumber + 1
      this.chargeAffaireService.getAllUsersByClientByPage(this.pageNumber, 10).then((data: any[])  => {
        if(data){
          for(var j=0;j<data.length;j++){
            if(data[j].clients[0] && data[j].clients[0].statut === 'Active'){
                  if(data[j].clients[0].etablissements.length === 0){
                    this.chargeAffaires.push(data[j])
                  }
                  var existEtab = data[j].clients[0].etablissements.find(x => data[j].client.etablissement && JSON.stringify(x.adresse) === JSON.stringify(data[j].client.etablissement.adresse)  &&  x.mail == data[j].client.etablissement.mail &&  x.nom == data[j].client.etablissement.nom &&  x.tel == data[j].client.etablissement.tel)
                  if(existEtab && existEtab.statutEtab === 'Active'){
                    this.chargeAffaires.push(data[j])
                  }
            }
          }
        }
      },
        err => {
          return false;
        });
    }
  }

  // supprimer un chargAff
  removechargAff(chargeAff) {
    if (this.administration) {
      return false
    } else {
      Swal.fire({
            title: 'VOULEZ-VOUS SUPPRIMER CE CHARGÉ D\'AFFAIRE?',
            imageUrl: 'assets/images/supp-modal.png',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            showCancelButton: true,
            width: 800,
            confirmButtonColor: '#059e00',
            cancelButtonColor: '#FF9100',
            cancelButtonText: 'ANNULER',
            confirmButtonText: 'VALIDER'
          }).then((result) => {
            if (!result.dismiss) {
              Swal.fire( {
                  imageUrl: 'assets/images/supp-modal.png',
                  imageWidth: 100,
                  imageHeight: 100,
                  imageAlt: 'Custom image',
                  width: 800,
                  title: 'BIEN SUPPRIMÉ',
                  timer: 1500,
                  showConfirmButton: false
                }
              );
              this.chargeAffaireService.deleteChargeAffaireById(chargeAff.id).then(data => {
              });
              this.chargeAffaires.splice(this.chargeAffaires.indexOf(chargeAff), 1);
            }
          } );

    }
  }

  toFileXLS() {
    while (this.listeclientChargeAffairesExcel.length) {
      this.listeclientChargeAffairesExcel.pop();
    }
    if (this.chargeAffaires.length < 1) {
      return false;
    }
    this.chargeAffaireService.getAllUsersByClient().then((data: any[])  => {
        this.chargeAffairesToDwnload = data
        if(this.chargeAffairesToDwnload && this.chargeAffairesToDwnload.length > 0){
          this.pousuivreFileXLS();
          return true;
        }
      },
      err => {
        return false;
    });
  }
  pousuivreFileXLS(){
    for (var i = 0; i < this.chargeAffairesToDwnload.length; i++) {
      if(!this.chargeAffairesToDwnload[i].poste){
        this.chargeAffairesToDwnload[i].poste = {
          "titre":""
        }
      }

      if(this.chargeAffairesToDwnload[i].client.etablissement){
        if(this.chargeAffairesToDwnload[i].client.etablissement.id === undefined && this.chargeAffairesToDwnload[i].clients && this.chargeAffairesToDwnload[i].clients.length > 0){
          this.pays = this.chargeAffairesToDwnload[i].clients[0].adresse.pays[0].toUpperCase() + this.chargeAffairesToDwnload[i].clients[0].adresse.pays[1].toUpperCase();
          this.adresse =  this.chargeAffairesToDwnload[i].clients[0].adresse.n + " " + this.chargeAffairesToDwnload[i].clients[0].adresse.rue + " " +this.chargeAffairesToDwnload[i].clients[0].adresse.codePostal +" " + this.chargeAffairesToDwnload[i].clients[0].adresse.ville
        }
        if(this.chargeAffairesToDwnload[i].client.etablissement.id === null){
          this.pays = this.chargeAffairesToDwnload[i].client.etablissement.adresse.pays[0].toUpperCase() + this.chargeAffairesToDwnload[i].client.etablissement.adresse.pays[1].toUpperCase();
          this.adresse = this.chargeAffairesToDwnload[i].client.etablissement.adresse.n + " " +this.chargeAffairesToDwnload[i].client.etablissement.adresse.rue + " " +this.chargeAffairesToDwnload[i].client.etablissement.adresse.codePostal + " " +this.chargeAffairesToDwnload[i].client.etablissement.adresse.ville
        }
      }

       var clientDenomination = ""
       if(this.chargeAffairesToDwnload[i].clients && this.chargeAffairesToDwnload[i].clients[0]){
         clientDenomination = this.chargeAffairesToDwnload[i].clients[0].denomination
       }

      var devExcel = {
        "Nom": this.chargeAffairesToDwnload[i].nom,
        "Prénom": this.chargeAffairesToDwnload[i].prenom,
        "Client": clientDenomination,
        "Poste": this.chargeAffairesToDwnload[i].poste.titre,
        "Email": this.chargeAffairesToDwnload[i].mail,
        "Téléphone": this.chargeAffairesToDwnload[i].tel,
        "Adresse": this.adresse,
        "Pays": this.pays,
      }
      this.listeclientChargeAffairesExcel.push(devExcel)
    }

    const workBook = XLSX.utils.book_new(); // create a new blank book
    const workSheet = XLSX.utils.json_to_sheet(this.listeclientChargeAffairesExcel);

    XLSX.utils.book_append_sheet(workBook, workSheet, 'data'); // add the worksheet to the book
    var currentDate = new Date().toISOString().split('T')[0]
    var mydate =  currentDate[8]+currentDate[9]+currentDate[5]+currentDate[6]+currentDate[2]+currentDate[3]
    XLSX.writeFile(workBook, 'VoWork-CA-'+ mydate +'.xlsx'); // initiate a file download in browser
  }

  // filter and search CA
  valuechange(event){
    clearTimeout(this.timeout);
      var $this = this;
      this.timeout = setTimeout(function () {
        if (event.keyCode != 13) {
          $this.getRechercheResult(event.target.value.toUpperCase())
        }
      },1000);
  }

  getRechercheResult(event){
    this.loading = true;
    if(event && event.length >0 && event.length <3){
      this.loading = false;
      return false;
    }
    this.chargeAffaires = [];
    while (this.chargeAffaires.length) {
      this.chargeAffaires.pop();
    }
    if(!event || event === ""){
      this.pageNumber = 0
      if(this.utilisateur && this.utilisateur.role === "Super admin"){
        this.getAllChargesAffaires();
        this.loading = false;
        return true;
      }else{
        this.getAllChargesAffairesNotRadies();
        this.loading = false;
        return true;
      }
    }
    if(event && event.length >2){
      this.chargeAffaireService.getAllDataByPageAndRecherche(event).then((data: any[]) => {
        this.chargeAffaires = data
        this.loading = false;
          },
        err => {
          this.loading = false;
          return false;
        });
        return false;
    }

  }
}
