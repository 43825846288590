<app-navbar></app-navbar>
<br>
<div id="content-nav-page" class="row" *ngIf="inventaire">
    <div class=" col-3 titre-menu">
      <b> {{inventaire.refArt}} <img class="ml-2" src="assets/images/modif-hover.png"></b>
    </div>
    <div class="col-9 sous-menu">
      <b class="sous-menu-active1" routerLink="/inventaire">INVENTAIRE</b>
    </div>
  </div>
  <div class="card p-3" *ngIf="inventaire">
    <div class="col d-flex justify-content-start">
      <b class="obligatoir-text">(*) Ce champ est obligatoire</b>
    </div>
  
    <div class="card-header " style="border: 1px solid green;margin-bottom: 3%;text-align: center" >
      <b style="color:grey;text-align: center" >INFORMATIONS </b>
    </div>
  <form [formGroup]="form"  role="form" >
    <div class="card-body">
      <div class="row">
        <div class="col-6">
          <div class="title">
            <input class="font-weight-bold" [(ngModel)]="inventaire.datec" type="date" [max]="getBigDate()" value="{{inventaire.datec}}" formControlName="datec" name="datec" placeholder="Date de l'inventaire*" disabled>
            <control-messages [control]="form.controls.datec"></control-messages>
          </div>
          <br/>
          <div class="title ">
            <input class="font-weight-bold" formControlName="refArt" [(ngModel)]="inventaire.refArt" (keyup)="valuechange($event)" value="{{inventaire.refArt}}" name="refArt" placeholder="Référence*">
            <control-messages [control]="form.controls.refArt"></control-messages>
          </div>
          <br/>
          <div class="title">
            <select class="form-control input-sm select-options" id="type" (change)="selectFournisseur()" formControlName="fournisseur" name="fournisseur"  [(ngModel)]="inventaire.fournisseur" style="margin-top: 36px;margin-bottom: 25px;">
              <option value="" disabled>Fournisseur*</option>
              <option [ngValue]="inventaire.fournisseur" selected>{{inventaire.fournisseur.denomination}}</option>
              <option *ngFor="let fournisseur of fournisseurs" [ngValue]="fournisseur">{{fournisseur.denomination}} </option>
            </select>
            <control-messages [control]="form.controls.fournisseur"></control-messages>
          </div>
          <br/>
          <div class="title">
            <input class="font-weight-bold" formControlName="quantiteEmballe" (keyup)="updateQteEmballe(inventaire)" (change)="updateQteEmballe(inventaire)" type="number" min="0" [(ngModel)]="inventaire.quantiteEmballe"  value="{{inventaire.quantiteEmballe}}" name="quantiteEmballe"  id="quantiteEmballe" placeholder="Quantité emballée">
          </div>
          <br>
          <div class="title">
            <input class="font-weight-bold" type="number" min="0" type="number"  formControlName="quantite"  [(ngModel)]="inventaire.quantite"  value="{{inventaire.quantite}}" name="quantite"  id="prinet" placeholder="Quantité*">
            <control-messages [control]="form.controls.quantite"></control-messages>
            <p *ngIf="errorChamp" style="color:red">Champ invalide</p>
            <p *ngIf="errorQauntite" style="color:red">Il faudrait que la quantité = quantité emballée + quantité non emballée</p>
          </div>
          <br>
          <div class="title">
            <textarea  rows="3" placeholder="Observation" class="font-weight-bold" style="outline: none;border: none;border-bottom: 2px solid green;text-align: center;color: green;width: 100%;" [(ngModel)]="inventaire.observation" formControlName="observation" value="{{inventaire.observation}}"  name="observation" cols="73"></textarea>
          </div>
        </div>
        <div class="col-6">
          <div class="title">
            <input class="font-weight-bold" type="number" min="0" formControlName="pritar" [(ngModel)]="inventaire.pritar"  value="{{inventaire.pritar}}" name="pritar"  placeholder="Prix Tarif*">
            <control-messages [control]="form.controls.pritar"></control-messages>
          </div>
          <br/>
          <div class="title">
            <input class="font-weight-bold" type="number" min="0" formControlName="coefAchat" [(ngModel)]="inventaire.coefAchat"  value="{{inventaire.coefAchat}}" name="coefAchat" id="coefAchat" placeholder="Coefficient d'achat">
            <control-messages [control]="form.controls.coefAchat"></control-messages>
          </div>
          <br/>
          <div class="title">
            <input class="font-weight-bold" formControlName="designation" [(ngModel)]="inventaire.designation"  value="{{inventaire.designation}}" name="designation"  id="designation" placeholder="Désignation*">
          </div>
          <br/>
          <div class="title">
            <input class="font-weight-bold" style="position: relative;top: -5px;" formControlName="quantiteNonEmballe" type="number" min="0" (keyup)="updateQteNonEmballe(inventaire)" (change)="updateQteNonEmballe(inventaire)" [(ngModel)]="inventaire.quantiteNonEmballe"  value="{{inventaire.quantiteNonEmballe}}" name="quantiteNonEmballe"  id="quantiteNonEmballe" placeholder="Quantité non emballée">
          </div>
          <br/>
          <div class="title">
            <select class="form-control input-sm select-options" style="position: relative;top: -24px;" formControlName="etage" [(ngModel)]="inventaire.etage"  value="{{inventaire.etage}}" name="etage"  id="etage" placeholder="Étage" >
              <option>-2</option>
              <option>-1</option>
              <option>0</option>
              <option>1</option>
              <option>2</option>
              <option>3</option>
            </select>
          </div>
        </div>
      </div>
      <br><br>
      <div class="col-12" style="margin-left: 30px;margin-top: 10px;color: green;font-weight: bold;">
        <label for="prenom">Date de début du catalogue : {{inventaire.datecCatalogue | date : 'dd/MM/yyyy'}}</label><br>
          <label for="prenom">Date de fin du catalogue : {{inventaire.dateFinCatalogue | date : 'dd/MM/yyyy'}}</label>
      </div>
    </div>
    <div class="col-md-6" style="position: relative;left: 50px;">
      <p *ngIf="errorType" style="color:red">{{errorType}}</p>
    </div>
    <div class=" col d-flex justify-content-center" style="padding-bottom: 1%">
      <div class="col-auto nbr-dossier " >
        <table>
          <tr>
            <td>
              <button class="btn-annule" style="width: 200px;height: 50px;" [routerLink]="['/inventaire', inventaire.datec, inventaire.idFournisseur]"  >Annuler <img src="assets/images/btn-annule.png"> </button>
  
            </td>
            <td style="padding-right: 30px;">
              <button class="btn-valider" style="width: 200px;height: 50px;" (click)="updateInventaire(inventaire)" >Enregistrer<img src="assets/images/save.png"></button>
            </td>
          </tr>
  
        </table>
      </div>
  
    </div>
  
    </form>
  </div>
  