import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CatalogueGuard } from 'src/app/guards/catalogue.guard';
import { AjoutCatalogueManuelComponent } from './ajout-catalogue-manuel/ajout-catalogue-manuel.component';
import { AjoutCatalogueComponent } from './ajout-catalogue/ajout-catalogue.component';
import { CatalogueByFournisseurComponent } from './catalogue-by-fournisseur/catalogue-by-fournisseur.component';
import { CatalogueByYearComponent } from './catalogue-by-year/catalogue-by-year.component';
import { CatalogueComponent } from './catalogue/catalogue.component';
import { DetailsCatalogueComponent } from './details-catalogue/details-catalogue.component';
import { UpdateCatalogueComponent } from './update-catalogue/update-catalogue.component';


const routes: Routes = [
  //catalogue
  { path: 'catalogue/:years/:id/:datec/:dateFin', component: CatalogueComponent},
  { path: 'ajoutCatalogue', component: AjoutCatalogueComponent, canActivate: [CatalogueGuard]},
  { path: 'ajoutCatalogue/manuel', component: AjoutCatalogueManuelComponent, canActivate: [CatalogueGuard]},
  { path: 'details/article/:id', component: DetailsCatalogueComponent},
  { path: 'article/details/:id', component: UpdateCatalogueComponent, canActivate: [CatalogueGuard]},
  { path: 'catalogue', component: CatalogueByFournisseurComponent, canActivate: [CatalogueGuard]},
  { path: 'catalogue/fournisseurs/years/:id', component: CatalogueByYearComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CatalogueRoutingModule { }
