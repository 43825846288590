import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
 name: 'searchingCatalogueYear'
})
export class SearchCatalogueYearPipe implements PipeTransform {
  transform(value, args: string[]){
   if (!value) return value;
   if (value.filter((item) => item.year.toLowerCase().includes(args)) != '') {
     return value.filter((item) => item.year.toLowerCase().includes(args));}
     
 }}
